import styled, { css } from 'styled-components'

import { TamanhoInput } from '../input/tipos'
import { Medias } from '../styles/breakpoints'
import { Cores } from '../styles/cores'

export interface ComponenteProps {
  disabled?: boolean
  tamanho?: TamanhoInput
  posicao?: 'direita' | 'esquerda'
  maxWidthMenu?: string
}

export const Componente = styled.div<ComponenteProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ disabled, tamanho, posicao, maxWidthMenu }) => css`
    .react-select__control {
      outline: none;
      border: 1px solid ${Cores.CINZA_3_ESCURO} t;
      font-size: 14px;
      font-family: Saira, sans-serif;
      box-shadow: none !important;

      ${!tamanho
        ? css`
            padding-left: 13px;
            font-size: 14px;
            height: 48px;
            @media ${Medias.NOTEBOOK.MIN} {
              padding-left: 17px;
              font-size: 16px;
              height: 54px;
            }

            @media ${Medias.DESKTOP} {
              padding-left: 17px;
              font-size: 18px;
              height: 60px;
            }
          `
        : tamanho === 'S'
        ? css`
            padding-left: 13px;
            font-size: 14px;
            height: 48px;
          `
        : tamanho === 'M'
        ? css`
            padding-left: 17px;
            font-size: 16px;
            height: 54px;
          `
        : css`
            padding-left: 17px;
            font-size: 18px;
            height: 60px;
          `}

      [aria-live='polite'] {
        display: none;
      }

      .react-select__placeholder {
        color: ${Cores.CINZA_3_ESCURO};
      }

      .react-select__indicator-separator {
        display: none;
      }

      &:not(:placeholder-shown) {
        span {
          color: ${Cores.CINZA_2};
        }
      }

      &:hover {
        border: 1px solid ${Cores.PRIMARIA_1} !important;
      }

      &.react-select__control--is-focused {
        border: 1px solid ${Cores.PRIMARIA_2} !important;
        span {
          color: ${Cores.CINZA_2_ESCURO};
        }
      }

      .react-select__clear-indicator {
        display: none;
      }

      .react-select__loading-indicator {
        display: none;
      }

      .react-select__dropdown-indicator {
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
          fill: ${Cores.CINZA_2};
        }
      }

      .react-select__value-container {
        padding: 0 !important;
        height: 100%;
      }

      ${disabled &&
      css`
        pointer-events: none;
        cursor: not-allowed;
        color: ${Cores.CINZA_3_ESCURO};
        background-color: rgba(209, 211, 212, 0.36);
        border: 1px solid rgba(189, 191, 192, 0.36) !important;
      `}
    }

    .react-select__menu {
      transition: 0.17s;
      margin-top: 0;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.12) !important;
      border-radius: 0px 4px 4px 4px !important;
      border: 1px solid ${Cores.CINZA_3};
      z-index: 3 !important;

      ${maxWidthMenu &&
      css`
        width: fit-content;
        max-width: ${maxWidthMenu};
      `}

      ${posicao === 'esquerda' &&
      css`
        right: 0;
      `}
    }

    .react-select__menu-list::-webkit-scrollbar {
      width: 0.6rem;
    }

    .react-select__menu-list::-webkit-scrollbar-track {
      box-shadow: none;
    }

    .react-select__menu-list::-webkit-scrollbar-thumb {
      background-color: ${Cores.CINZA_3};
      outline: none;
      border-radius: 5px;
    }

    .react-select__option {
      font-family: Saira, sans-serif;
      font-size: 14px;
      color: ${Cores.CINZA_2_ESCURO};
      height: 40px;
      cursor: pointer;
      padding: 0 12px;
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:active {
        color: ${Cores.PRIMARIA_2};
        background: ${Cores.CINZA_3_CLARO};
      }
    }

    .react-select__option--is-selected {
      color: ${Cores.PRIMARIA_2};
      background: ${Cores.CINZA_3_CLARO};
    }

    .react-select__option--is-focused {
      background: ${Cores.CINZA_3_MAIS_CLARO};
      color: ${Cores.PRIMARIA_1};
    }

    .react-select__menu-notice {
      color: ${Cores.CINZA_3_ESCURO};
    }
  `}
`

export const Label = styled.label`
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  text-align: left;
  margin-bottom: 6px;
  line-height: 125%;
  color: ${Cores.CINZA_2};

  :first-letter {
    text-transform: uppercase;
  }
`

export const Grupo = styled.div<{ maxWidthInput?: string }>`
  position: relative;
  max-width: ${({ maxWidthInput }) => maxWidthInput || '100%'};

  input {
    caret-color: ${Cores.PRIMARIA_2};
  }

  .react-select__indicators > div {
    height: 100%;
    align-items: center;
  }

  .icone_carregando {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 32px;
    top: 0px;
    z-index: 0;
    color: ${Cores.BRANCO};
  }

  .icone_fechar {
    position: absolute;
    height: 100%;
    width: 13px;
    right: 33px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 0;
  }
`

export const ComponenteExemplo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .informacoes {
    margin-top: 10px;
    color: ${Cores.CINZA_2};
  }

  button {
    width: fit-content;
    margin-top: 15px;
  }
`
