import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'

import { ModalBase, Botao, IconeDeAvisoDeAdvertencia } from 'src/componentes'

import { Corpo, Texto, Titulo } from './styles'
import { ModalAvisoProps, ModalAvisoRef } from './tipos'

const ModalAviso: React.ForwardRefRenderFunction<
  ModalAvisoRef,
  ModalAvisoProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const history = useHistory()

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<ModalAvisoRef, ModalAvisoRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = () => {
    fechar()
    history.push('/')
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        {IconeDeAvisoDeAdvertencia}
        <Titulo>Aviso</Titulo>
        <Texto>
          Você já possui um usuário logado neste navegador, irá ser
          redirecionado para a tela inicial.
        </Texto>
        <Botao texto="OK" type="button" onClick={() => acaoSucesso()} />
      </Corpo>
    </ModalBase>
  )
}

export const ModalAvisoUsuarioLogado = forwardRef(ModalAviso)
