import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { uid } from 'react-uid'

import { Cores, IconeDeAlerta, Tooltip } from 'src/componentes'
import { RotasAcademico } from 'src/rotas'
import { TurmaProfessor } from 'src/tipos'

import {
  Container,
  Conteudo,
  Titulo,
  Descricao,
  VerMais,
  Alerta,
  DivisorItens
} from './styles'

const mensagem = 'Existem correções de avaliações pendentes para esta turma'
const id = uid('controle-de-turma')

export interface ControleDeTurmaProps {
  turma: TurmaProfessor
}

export const ControleDeTurma: FC<ControleDeTurmaProps> = ({
  turma
}: ControleDeTurmaProps) => {
  const history = useHistory()
  const deveMostrarAlerta = () => {
    if (turma.avaliacaoPendente) {
      return (
        <Alerta>
          <div data-tip={mensagem} data-for={id} children={IconeDeAlerta} />
          <Tooltip
            id={id}
            place={'right'}
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
          />
        </Alerta>
      )
    }
    return null
  }

  const verDetalhesTurma = (id: string) => {
    return history.push(`/academico/turmas/notas-presenca/${id}`)
  }

  return (
    <Container>
      <Conteudo>
        <Titulo>{turma.nome}</Titulo>
        <Descricao>{turma.codigo}</Descricao>
        <DivisorItens>
          <VerMais onClick={() => verDetalhesTurma(turma.id)}>
            Ver Cadastro de Notas
          </VerMais>
          <VerMais
            onClick={() =>
              history.push(`${RotasAcademico.GrupoDiscussao}/${turma.id}`)
            }
            onContextMenu={() => {
              const rota = window.open(
                `${RotasAcademico.GrupoDiscussao}/${turma.id}`
              )
              rota.focus()
            }}
          >
            Ver Fórum de dúvidas
          </VerMais>
          {deveMostrarAlerta()}
        </DivisorItens>
      </Conteudo>
    </Container>
  )
}
