import React, { FC } from 'react'

import { BarraDeProgressoDoCursoProps } from './barra-de-progresso-do-curso.props'
import {
  Container,
  Barra,
  Progresso,
  Texto
} from './barra-de-progresso-do-curso.style'

export const BarraDeProgressoDoCurso: FC<BarraDeProgressoDoCursoProps> = ({
  className,
  progresso
}) => {
  return (
    <Container className={className}>
      <Barra>
        <Progresso progresso={progresso} />
      </Barra>
      <Texto>{progresso}%</Texto>
    </Container>
  )
}
