import styled from 'styled-components'

import { Medias } from '../styles/breakpoints'
import { LayoutContainerProps } from './tipos'

export const Layout = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
`

export const Container = styled.div<LayoutContainerProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${({ expandido }) =>
    !expandido &&
    `
      @media ${Medias.NOTEBOOK.MIN} {
          margin-left: 72px;
          transition: margin-left 0.1s;
          width: calc(100vw - 72px);
      }
    `}
`

export const Conteudo = styled.main`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  word-break: break-word;
  padding: 24px;
  justify-content: center;
`

export const Centralizado = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1000px;

  @media ${Medias.TABLET.MAX} {
    max-width: 100%;
  }
`
