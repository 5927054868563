import { dataValidaAmericana } from 'src/componentes/funcoes/data-hora'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  data: Yup.mixed()
    .required('Informe a data corretamente')
    .test(
      'valor',
      'Formato de data inválida.',
      valor => valor.length === 10 && dataValidaAmericana(valor)
    ),
  cargaHoraria: Yup.string()
    .nullable()
    .matches(/^(0|[1-9]\d*)(\.\d{1,2})?$/, 'Digite um valor válido')
    .typeError('Digite um valor válido')
})
