export enum TipoMatricula {
  Normal,
  Ouvinte,
  Reingresso,
  Transferencia,
  TrocaDeCurso,
  ColaboradorIPGS,
  Cancelado7Dias,
  ErroMatricula
}

export enum TipoMatriculaPorExtenso {
  Normal = 'Normal',
  Ouvinte = 'Ouvinte',
  Reingresso = 'Reingresso',
  Transferencia = 'Transferência',
  TrocaDeCurso = 'Troca de Curso',
  ColaboradorIPGS = 'Colaborador IPGS',
  Cancelado7Dias = 'Cancelado 7 Dias',
  ErroMatricula = 'Erro Matrícula'
}
