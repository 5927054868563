import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CpfInputUnform, Tooltip } from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Breadcrumb } from 'src/componentes/breadcrumb'
import { Cabecalho } from 'src/componentes/cabecalho'
import { ContainerAcoes } from 'src/componentes/container-acoes'
import { Filtro } from 'src/componentes/filtro'
import { IconeEditar, IconeRedefinirSenha } from 'src/componentes/icones'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { InputUnform } from 'src/componentes/unform/input'
import { SelectUnform } from 'src/componentes/unform/select'
import { RotasUsuario } from 'src/rotas'
import { Api } from 'src/servicos'
import { TipoOrdenacao, UsuarioPerfil, UsuarioSituacao } from 'src/tipos'

import { Form, Container, Campo, Dados } from './styles'

interface DadosPesquisa {
  nome: string
  email: string
  situacao: keyof typeof UsuarioSituacao
  cpf: string
  docIdentidade: string
  perfis: Array<keyof typeof UsuarioPerfil>
}

export const PaginaUsuarios: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const history = useHistory()
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState(null)
  const [carregando, definirCarregando] = useState(false)

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.IrParaPagina()
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }
    definirDadosPesquisa(dados)
  }

  const acaoRedefinirSenha = async (usuario: string) => {
    try {
      definirCarregando(true)
      const token = await Api.RecuperarSenha(usuario)
      if (token) {
        toast('E-mail de redefinição de senha enviado!', { type: 'success' })
      }
    } finally {
      definirCarregando(false)
    }
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }
    const dados = await Api.RequisitarListaUsuarios(
      dadosPesquisa?.nome,
      dadosPesquisa?.email,
      dadosPesquisa?.situacao,
      dadosPesquisa?.cpf,
      dadosPesquisa?.docIdentidade,
      dadosPesquisa?.perfis,
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Usuários"
          atalhos={[
            {
              texto: 'Sistema'
            },
            {
              texto: 'Usuários'
            }
          ]}
        />
        <Botao
          type="button"
          texto="Incluir"
          tema="Secundario"
          onClick={() => history.push(RotasUsuario.Cadastro)}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: UsuarioSituacao[UsuarioSituacao.Todas]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nome"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <InputUnform
                type="email"
                name="email"
                label="E-mail"
                maxLength={200}
              />
            </Campo>
            <Campo>
              <CpfInputUnform type="text" name="cpf" label="CPF" />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="docIdentidade"
                label="RG"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_situacao"
                name="situacao"
                label="Situação"
                iconeFechar={false}
                opcoes={[
                  {
                    id: UsuarioSituacao[UsuarioSituacao.Todas],
                    texto: 'Todas'
                  },
                  {
                    id: UsuarioSituacao[UsuarioSituacao.Ativo],
                    texto: 'Ativo'
                  },
                  {
                    id: UsuarioSituacao[UsuarioSituacao.Inativo],
                    texto: 'Inativo'
                  }
                ]}
              />
            </Campo>
            <Campo>
              <SelectUnform
                multiplo
                id="slct_perfil"
                name="perfis"
                label="Perfil"
                placeholder="Selecione"
                opcoes={[
                  {
                    id: UsuarioPerfil[UsuarioPerfil.Academico],
                    texto: 'Acadêmico'
                  },
                  { id: UsuarioPerfil[UsuarioPerfil.Aluno], texto: 'Aluno' },
                  {
                    id: UsuarioPerfil[UsuarioPerfil.Comercial],
                    texto: 'Comercial'
                  },
                  {
                    id: UsuarioPerfil[UsuarioPerfil.Financeiro],
                    texto: 'Financeiro'
                  },
                  {
                    id: UsuarioPerfil[UsuarioPerfil.Gerencial],
                    texto: 'Gerencial'
                  },
                  {
                    id: UsuarioPerfil[UsuarioPerfil.Professor],
                    texto: 'Professor'
                  },
                  {
                    id: UsuarioPerfil[UsuarioPerfil.Parceiro],
                    texto: 'Parceiro'
                  }
                ]}
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nomeApresentacao',
              Texto: 'Nome',
              ComOrdenacao: true
            },
            {
              Chave: 'email',
              Texto: 'E-mail',
              ComOrdenacao: true
            },
            {
              Chave: 'perfis',
              Texto: 'Perfis',
              Renderizar: (linha: TabelaDados) => (
                <>
                  {linha.perfis.map(
                    (p: string, i: number) => `${i > 0 ? ',' : ''} ${p}`
                  )}
                </>
              )
            },
            {
              Chave: 'situacao',
              Texto: 'Situação',
              ComOrdenacao: true
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    data-tip="Editar"
                    data-for={`${linha.id}-tooltip`}
                    onClick={() =>
                      history.push(`${RotasUsuario.Edicao}/${linha.id}`)
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasUsuario.Edicao}/${linha.id}`
                      )
                      rota.focus()
                    }}
                  >
                    {IconeEditar}
                  </button>
                  <Botao
                    type="button"
                    carregando={carregando}
                    data-tip="Redefinir senha"
                    elemento={IconeRedefinirSenha}
                    tema="CinzaClaro"
                    data-for={`${linha.id}-tooltip`}
                    onClick={() => acaoRedefinirSenha(linha.email)}
                  ></Botao>
                  <Tooltip id={`${linha.id}-tooltip`} offset={{ top: 10 }} />
                </ContainerAcoes>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export { default as PaginaCadastroUsuarios } from './cadastro'
