import React, {
  useState,
  useEffect,
  useRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef
} from 'react'

import classNames from 'classnames'

import { Componente, CaixaBotao, Menu } from './styles'
import { MenuContextoProps } from './tipos'

export interface MenuContextoRef {
  abrir: () => void
  fechar: () => void
}

export const MenuComponente: ForwardRefRenderFunction<
  MenuContextoRef,
  MenuContextoProps
> = (
  { botao, posicao, children, desabilitado, abrirComClick, ...rest },
  ref
) => {
  const menuRef = useRef(null)
  const [aberto, definirAberto] = useState(false)

  const fechar = () => {
    definirAberto(false)
  }

  const abrir = () => {
    definirAberto(true)
  }

  useImperativeHandle<MenuContextoRef, MenuContextoRef>(ref, () => ({
    fechar,
    abrir
  }))

  const verificaClick = (evento: MouseEvent) => {
    if (!menuRef.current?.contains(evento.target)) {
      definirAberto(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', evento => {
      verificaClick(evento)
    })

    return () => {
      document.removeEventListener('mousedown', evento => {
        verificaClick(evento)
      })
    }
  })

  return (
    <Componente ref={menuRef}>
      <CaixaBotao
        className={classNames({
          mc_caixa_botao: true,
          aberto
        })}
        desabilitado={desabilitado}
        onClick={() => {
          if (!abrirComClick) return
          definirAberto(valorAnterior => !valorAnterior)
        }}
      >
        {botao}
      </CaixaBotao>
      <Menu
        className={classNames({
          menu_contexto: !abrirComClick,
          aberto
        })}
        {...rest}
        posicao={posicao}
        desabilitado={desabilitado}
      >
        {children}
      </Menu>
    </Componente>
  )
}

export const MenuContexto = forwardRef(MenuComponente)
