import React from 'react'

export default (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5866 9.4862C4.87029 9.1475 5.37485 9.1029 5.71358 9.3866L9.9999 12.9764L14.2863 9.3866C14.625 9.1029 15.1296 9.1475 15.4132 9.4862C15.6969 9.8249 15.6523 10.3295 15.3136 10.6132L10.5136 14.6332C10.2164 14.8821 9.7835 14.8821 9.4863 14.6332L4.68627 10.6132C4.34754 10.3295 4.30292 9.8249 4.5866 9.4862Z"
      fill="currentColor"
    />
    <path
      d="M9.1997 1.1999V13.1999C9.1997 13.6417 9.55787 13.9999 9.9997 13.9999C10.4415 13.9999 10.7997 13.6417 10.7997 13.1999V1.1999C10.7997 0.758074 10.4415 0.399902 9.9997 0.399902C9.55787 0.399902 9.1997 0.758074 9.1997 1.1999Z"
      fill="currentColor"
    />
    <path
      d="M18.7999 17.1999H1.1999C0.758075 17.1999 0.399903 17.5581 0.399903 17.9999C0.399903 18.4417 0.758075 18.7999 1.1999 18.7999H18.7999C19.2417 18.7999 19.5999 18.4417 19.5999 17.9999C19.5999 17.5581 19.2417 17.1999 18.7999 17.1999Z"
      fill="currentColor"
    />
    <path
      d="M18 13.9999V17.9999C18 18.4417 18.3581 18.7999 18.7999 18.7999C19.2417 18.7999 19.5999 18.4417 19.5999 17.9999L19.6 13.9999C19.6 13.5581 19.2418 13.1999 18.8 13.1999C18.3582 13.1999 18 13.5581 18 13.9999Z"
      fill="currentColor"
    />
    <path
      d="M0.399902 13.9999L0.399903 17.9999C0.399903 18.4417 0.758075 18.7999 1.1999 18.7999C1.64173 18.7999 1.9999 18.4417 1.9999 17.9999V13.9999C1.9999 13.5581 1.64173 13.1999 1.1999 13.1999C0.758076 13.1999 0.399902 13.5581 0.399902 13.9999Z"
      fill="currentColor"
    />
  </svg>
)
