import React from 'react'

export default (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13V5H12V13H1ZM4 3.5C4 3.64062 3.89063 3.75 3.75 3.75H3.25C3.10938 3.75 3 3.64062 3 3.5V1.25C3 1.10937 3.10938 0.999999 3.25 0.999999H3.75C3.89063 0.999999 4 1.10937 4 1.25V3.5ZM10 3.5C10 3.64062 9.89063 3.75 9.75 3.75H9.25C9.10938 3.75 9 3.64062 9 3.5V1.25C9 1.10937 9.10938 0.999999 9.25 0.999999H9.75C9.89063 0.999999 10 1.10937 10 1.25V3.5ZM13 3C13 2.45312 12.5469 2 12 2H11V1.25C11 0.562499 10.4375 -9.53674e-07 9.75 -9.53674e-07H9.25C8.5625 -9.53674e-07 8 0.562499 8 1.25V2H5V1.25C5 0.562499 4.4375 -9.53674e-07 3.75 -9.53674e-07H3.25C2.5625 -9.53674e-07 2 0.562499 2 1.25V2H1C0.453125 2 0 2.45312 0 3V13C0 13.5469 0.453125 14 1 14H12C12.5469 14 13 13.5469 13 13V3Z"
      fill="black"
    />
  </svg>
)
