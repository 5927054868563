import React, { useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'

import {
  Carregando,
  Cabecalho,
  Breadcrumb,
  Navegacao,
  Link,
  FormUnform,
  SubTitulo,
  ConteudoBotao,
  SelectUnform,
  Botao
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { IconeAdicionar, IconeAtencao, IconeBack } from 'src/componentes/icones'
import { OpcaoSelect } from 'src/componentes/select-async/tipos'
import { CursoLivre } from 'src/dto'
import { RotasAcademico } from 'src/rotas/academico'
import { Api } from 'src/servicos'
import { GrupoDiscussao, ModoPaginaCadastro } from 'src/tipos'
import * as Yup from 'yup'

import { GrupoDiscussaoComponente } from './componentes/grupo-discussao'
import { ModalIncluirTopico } from './componentes/grupo-discussao/modal-incluir-topico'
import { ModalIncluirTopicoRef } from './componentes/grupo-discussao/modal-incluir-topico/tipos'
import {
  Container,
  ContainerDados,
  ContainerLateral,
  ContainerLink,
  ContainerMain,
  ContainerIncluir,
  Conteudo,
  Secao,
  Botao as BotaoIcone
} from './styles'

export const schema = Yup.object().shape({})

export interface PaginaGrupoDiscussaoProps
  extends RouteComponentProps<{ id?: string }> {
  modo?: ModoPaginaCadastro
}

export interface OpcaoSelectDadosAdicionais<T> extends OpcaoSelect {
  dadosAdicionais: T
}
export enum Navegacoes {
  Topicos = 'Tópicos'
}

const Componente: React.FC<PaginaGrupoDiscussaoProps> = ({ match, modo }) => {
  const [pronto, definirPronto] = useState(false)
  const [curso, definirCurso] = useState<CursoLivre>(null)
  const [grupoDiscussao, definirGrupoDiscussao] = useState(null)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const modalRef = useRef<ModalIncluirTopicoRef>(null)
  const [id] = useState(match?.params?.id)
  const history = useHistory()

  const [titulo] = useState(
    `${
      modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'
    } de Fórum de dúvidas`
  )

  const carregarGrupoDiscussao = async (curso: CursoLivre) => {
    try {
      definirErro(undefined)
      definirGrupoDiscussao({} as GrupoDiscussao)

      const resposta = await Api.RequisitarGrupoDiscussaoLivre(curso?.id)

      definirGrupoDiscussao(resposta)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar o Fórum de dúvidas')
    }
  }

  const carregarCurso = async () => {
    try {
      const result = await Api.RequisitarCursoLivre(id)
      definirCurso(result)
      await carregarGrupoDiscussao(result)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar a turma')
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    carregarCurso()
  }, [id])

  return (
    <>
      {pronto && !erro ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.Cursos}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Acadêmico'
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <>
                <FormUnform
                  schema={schema}
                  dadosIniciais={{ curso: curso.nome }}
                  acaoSucesso={() => null}
                >
                  <Secao id={Navegacoes.Topicos}>
                    <SubTitulo texto="Tópicos" />
                    <SelectUnform
                      id="slct_modalidade"
                      name="curso"
                      label="Curso"
                      placeholder={curso.nome}
                      opcoes={[]}
                      obrigatorio
                      disabled
                      valorAlterado={() => null}
                    />
                    <ContainerIncluir>
                      <BotaoIcone
                        type="button"
                        onClick={() => modalRef.current?.abrir(curso?.id)}
                      >
                        <ConteudoBotao>
                          {IconeAdicionar} Incluir Tópico
                        </ConteudoBotao>
                      </BotaoIcone>
                    </ContainerIncluir>
                    <ContainerDados>
                      <GrupoDiscussaoComponente
                        grupoDiscussao={grupoDiscussao}
                        recarregar={() => carregarGrupoDiscussao(curso)}
                      />
                    </ContainerDados>
                    <ContainerIncluir>
                      <BotaoIcone
                        type="button"
                        onClick={() => history.push(RotasAcademico.Cursos)}
                      >
                        <ConteudoBotao>Voltar</ConteudoBotao>
                      </BotaoIcone>
                    </ContainerIncluir>
                  </Secao>
                </FormUnform>
              </>
            </Conteudo>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.Topicos,
                  nome: 'Tópicos'
                }
              ]}
            />
          </ContainerLateral>
        </Container>
      ) : !pronto && !erro ? (
        <Conteudo>
          <Carregando texto="Carregando Fórum de dúvidas ..." />
        </Conteudo>
      ) : (
        <Conteudo>
          <ContainerErroLayout>
            <div>
              {IconeAtencao}
              <p>{erro}</p>
              <div>
                <Botao
                  tema="Padrao"
                  texto="Tentar novamente"
                  onClick={() => carregarGrupoDiscussao(curso)}
                  tamanho="S"
                />
              </div>
            </div>
          </ContainerErroLayout>
        </Conteudo>
      )}

      <ModalIncluirTopico
        backdrop
        ref={modalRef}
        acaoSucesso={() => carregarGrupoDiscussao(curso)}
      />
    </>
  )
}

export default withRouter(Componente)
