import React from 'react'

import { Container } from './styles'
import { BarraProgressoProps } from './tipos'

export const BarraProgresso: React.FC<BarraProgressoProps> = ({
  className,
  width,
  percent
}) => {
  return (
    <Container className={className} width={width} percent={percent}>
      <div />
      {percent}%
    </Container>
  )
}
