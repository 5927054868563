import React, { useMemo } from 'react'

import {
  ContainerAcoes,
  IconeAdicionar,
  IconeLixeira,
  SubTitulo,
  Tooltip,
  Botao
} from 'src/componentes'
import { ModalidadeCursoPorExtenso } from 'src/tipos'

import PaginaCadastroTurmaContainer from '../../../container'
import { Tabela, TabelaLinha } from './styles'
import { ListaMateriaisProps } from './tipos'

export const ListaMateriais: React.FC<ListaMateriaisProps> = ({
  titulo,
  idDisciplina,
  modalidadeTurma
}) => {
  const {
    modalIncluirMaterialRef,
    modalIncluirMaterialTurmaRef,
    materiais,
    bloquearCampo,
    modalExcluirMaterialRef
  } = PaginaCadastroTurmaContainer.useContainer()

  const materiaisAtuais = useMemo(() => {
    return materiais.filter(
      x => x.idDisciplina === idDisciplina && !x.tipoMaterialComplementar
    )
  }, [materiais, idDisciplina])

  const abrirModalRemoverMaterial = (index: number) =>
    modalExcluirMaterialRef.current?.abrir(index, idDisciplina)

  return (
    <>
      <SubTitulo texto={titulo} />
      <div>
        <Tabela>
          {materiaisAtuais.map((material, index) => (
            <>
              <TabelaLinha key={index}>
                <>{material.titulo.split('.')[0]}</>
                {!bloquearCampo && (
                  <ContainerAcoes>
                    <button
                      data-for={`${material.id}-tooltip-excluir`}
                      data-tip="Remover"
                      type="button"
                      onClick={() => abrirModalRemoverMaterial(index)}
                    >
                      {IconeLixeira}
                    </button>
                  </ContainerAcoes>
                )}
              </TabelaLinha>
              <Tooltip
                id={`${material.id}-tooltip-excluir`}
                place="bottom"
                offset={{ top: 10 }}
              />
            </>
          ))}
        </Tabela>
        {!bloquearCampo && (
          <Botao
            type="button"
            onClick={() => {
              if (
                idDisciplina &&
                modalidadeTurma !==
                  ModalidadeCursoPorExtenso.PosGraduacaoPresencial
              ) {
                modalIncluirMaterialRef.current?.abrir(
                  materiaisAtuais.map(x => x.titulo),
                  null,
                  null,
                  idDisciplina
                )
              } else if (
                idDisciplina &&
                modalidadeTurma ===
                  ModalidadeCursoPorExtenso.PosGraduacaoPresencial
              ) {
                modalIncluirMaterialTurmaRef.current?.abrir(
                  materiaisAtuais.map(x => x.titulo),
                  null,
                  null,
                  idDisciplina
                )
              } else {
                modalIncluirMaterialTurmaRef.current?.abrir(
                  materiaisAtuais.map(x => x.titulo),
                  null,
                  null
                )
              }
            }}
            tema="Link"
            elemento={<>{IconeAdicionar} Incluir Material</>}
          />
        )}
      </div>
    </>
  )
}
