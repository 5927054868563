import { mensagemPadrao } from 'src/dados-estaticos'
import { FormaPagamentoCobranca } from 'src/tipos'
import * as Yup from 'yup'

const exigirCartao = (valor, metodo) => {
  return (
    valor &&
    metodo &&
    valor > 0 &&
    FormaPagamentoCobranca[metodo] ===
      FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito]
  )
}

const exigirBoleto = (valor, metodo) => {
  return (
    valor &&
    metodo &&
    valor > 0 &&
    FormaPagamentoCobranca[metodo] ===
      FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto]
  )
}

export const schema = Yup.object().shape({
  valorTCC: Yup.string().numero().nullable().required(mensagemPadrao),
  valorFinal: Yup.string().notRequired(),
  metodoPagamento: Yup.string()
    .nullable()
    .when('valorFinal', {
      is: valor => valor && valor > 0,
      then: field => field.required(mensagemPadrao)
    }),
  quantidadeParcelasTCC: Yup.string()
    .numero()
    .nullable()
    .when(['valorFinal', 'metodoPagamento'], {
      is: (valor, metodo) =>
        exigirCartao(valor, metodo) || exigirBoleto(valor, metodo),
      then: field => field.required(mensagemPadrao)
    }),
  diaVencimento: Yup.string()
    .numero()
    .nullable()
    .when(['valorFinal', 'metodoPagamento'], {
      is: (valor, metodo) => exigirBoleto(valor, metodo),
      then: field =>
        field
          .min(1)
          .max(31)
          .typeError(`O campo deve estar entre ${1} e ${31}`)
          .required('O campo é obrigatório')
    }),
  numeroCartao: Yup.string()
    .numero()
    .nullable()
    .when(['valorFinal', 'metodoPagamento'], {
      is: (valor, metodo) => exigirCartao(valor, metodo),
      then: field => field.required(mensagemPadrao)
    }),
  nomeCartao: Yup.string()
    .nullable()
    .when(['valorFinal', 'metodoPagamento'], {
      is: (valor, metodo) => exigirCartao(valor, metodo),
      then: field => field.required(mensagemPadrao)
    }),
  validadeCartao: Yup.string()
    .numero()
    .nullable()
    .when(['valorFinal', 'metodoPagamento'], {
      is: (valor, metodo) => exigirCartao(valor, metodo),
      then: field => field.required(mensagemPadrao)
    }),
  codigoSeguranca: Yup.string()
    .numero()
    .nullable()
    .when(['valorFinal', 'metodoPagamento'], {
      is: (valor, metodo) => exigirCartao(valor, metodo),
      then: field => field.required(mensagemPadrao)
    })
})
