import { mensagemPadrao } from 'src/dados-estaticos'
import { TipoDeAvaliacao } from 'src/dto'
import { ModalidadeCurso, TipoAvaliacao } from 'src/tipos'
import * as Yup from 'yup'

const caracter = Yup.string().required(mensagemPadrao)

const schemaEndereco = Yup.object().shape({
  local: Yup.string(),
  cep: caracter,
  logradouro: caracter,
  numero: caracter,
  complemento: Yup.string(),
  bairro: caracter,
  cidade: caracter,
  uf: caracter
})

const schemaAvaliacao = Yup.object().shape({
  temAvaliacao: Yup.boolean().required(),
  id: Yup.string(),
  nome: Yup.string().when('adicionarAvaliacao', {
    is: true,
    then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
  }),
  quantidadeTentativas: Yup.number()
    .nullable()
    .min(1, 'Este campo deve ser maior que 1.')
    .max(99, 'Este campo não deve possuir valor maior que 99.')
    .when('adicionarAvaliacao', {
      is: true,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  descricao: Yup.string(),
  tipo: Yup.string().when('adicionarAvaliacao', {
    is: true,
    then: (fieldSchema: any) => fieldSchema.required('Selecione uma opção')
  }),
  dataLiberacaoAvaliacao: Yup.string()
    .data()
    .when('adicionarAvaliacao', {
      is: true,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  dataRealizacaoAvaliacao: Yup.string().data(),
  arquivo: Yup.mixed().when(['tipo', 'adicionarAvaliacao'], {
    is: (tipo, temAvaliacao) =>
      tipo === TipoDeAvaliacao.AvaliacaoPorAquivo && temAvaliacao,
    then: (fieldSchema: any) =>
      fieldSchema
        .test('fileSize', 'Arquivo muito grande', value => {
          if (!value) {
            return false
          }

          if (typeof value === 'string') {
            return true
          }

          if (value.size <= 262144000) {
            return true
          }
        })
        .required(mensagemPadrao)
  })
})

const schemaAvaliacaoModuloTCC = Yup.object().shape({
  temAvaliacao: Yup.boolean(),
  id: Yup.string(),
  nome: Yup.string().required(mensagemPadrao),
  quantidadeTentativas: Yup.number()
    .nullable()
    .min(2, 'Este campo deve ser maior que 1.')
    .max(99, 'Este campo não deve possuir valor maior que 99.')
    .required(mensagemPadrao),
  descricao: Yup.string(),
  dataLiberacaoAvaliacao: Yup.string().data().required(mensagemPadrao),
  dataRealizacaoAvaliacao: Yup.string().data()
})

const schemaDadosBasicos = (modalidade?: keyof typeof ModalidadeCurso) =>
  Yup.object().shape({
    nome: caracter,
    codigo: Yup.string()
      .matches(
        /^[a-zA-Z0-9]*$/,
        'Código não deve conter espaços em branco nem caracteres especiais'
      )
      .required(mensagemPadrao),
    dataInicio: Yup.string().required(mensagemPadrao),
    dataFim:
      modalidade === ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad]
        ? Yup.string().required(mensagemPadrao)
        : Yup.string(),
    dataInicioInscricao: caracter,
    dataFimInscricao: caracter,
    turmaReingressoId: Yup.string()
      .nullable()
      .when('possuiReingresso', {
        is: true,
        then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
      }),
    cursoId: caracter,
    professorId: Yup.string().nullable(),
    situacaoTurma: Yup.string(),
    gradeCurricularId: caracter,
    grupoDiscussao: Yup.string()
  })

const schemaModuloTCC = Yup.object().shape({
  disciplina: Yup.array(
    Yup.object().shape({
      professoresOrientadoresIds: Yup.array().required(mensagemPadrao),
      professoresBancaIds: Yup.array()
        .required(mensagemPadrao)
        .notOneOf(
          [Yup.ref('professoresOrientadoresIds')],
          'Ao menos um professor deve ser diferente entre Orientadores e Banca.'
        ),
      avaliacao: schemaAvaliacaoModuloTCC
    })
  ),
  dataLiberacao: Yup.string()
})

const schemaTurmaEadModulo = Yup.object().shape({
  disciplina: Yup.array(
    Yup.object().shape({
      professorId: Yup.string(),
      avaliacao: schemaAvaliacao
    })
  ),
  dataLiberacao: Yup.string()
})

const schemaTurmaEadModuloAvaliacao = Yup.object().shape({
  disciplina: Yup.array(
    Yup.object().shape({
      professorId: Yup.string()
    })
  ),
  avaliacao: schemaAvaliacao,
  dataLiberacao: Yup.string()
})

const schemaTurmaEadAvaliacaoModulo = Yup.object().shape({
  modulo: Yup.array(schemaTurmaEadModuloAvaliacao),
  moduloTCC: schemaModuloTCC
})

const schemaTurmaEadAvaliacaoDisciplina = Yup.object().shape({
  modulo: Yup.array(schemaTurmaEadModulo),
  moduloTCC: schemaModuloTCC
})
const schemaTurmaPresencialModulo = Yup.object().shape({
  disciplina: Yup.array(
    Yup.object().shape({
      professorId: Yup.string().nullable(),
      avaliacao: schemaAvaliacao
    })
  ),
  dataLiberacao: Yup.string()
})

const schemaTurmaPresencialModuloAvaliacao = Yup.object().shape({
  disciplina: Yup.array(
    Yup.object().shape({
      professorId: caracter
    })
  ),
  avaliacao: schemaAvaliacao,
  dataLiberacao: Yup.string()
})

const schemaTurmaPresencialAvaliacaoModulo = schemaDadosBasicos().concat(
  Yup.object().shape({
    modulo: Yup.array(schemaTurmaPresencialModuloAvaliacao),
    localizacao: schemaEndereco,
    moduloTCC: schemaModuloTCC
  })
)

const schemaTurmaPresencialAvaliacaoDisciplina = schemaDadosBasicos().concat(
  Yup.object().shape({
    modulo: Yup.array(schemaTurmaPresencialModulo),
    localizacao: schemaEndereco,
    moduloTCC: schemaModuloTCC
  })
)
export const schema = (
  modalidade: keyof typeof ModalidadeCurso,
  tipoAvaliacao: keyof typeof TipoAvaliacao
): Yup.ObjectSchema => {
  if (
    modalidade === ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad] ||
    modalidade === ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex] ||
    modalidade === ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull]
  ) {
    if (tipoAvaliacao === TipoAvaliacao[TipoAvaliacao.AvaliacaoPorDisciplina]) {
      return schemaDadosBasicos(modalidade).concat(
        schemaTurmaEadAvaliacaoDisciplina
      )
    } else {
      return schemaDadosBasicos(modalidade).concat(
        schemaTurmaEadAvaliacaoModulo
      )
    }
  }

  if (tipoAvaliacao === TipoAvaliacao[TipoAvaliacao.AvaliacaoPorDisciplina]) {
    return schemaTurmaPresencialAvaliacaoDisciplina
  } else {
    return schemaTurmaPresencialAvaliacaoModulo
  }
}
