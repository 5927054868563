export const Cores = {
  PRIMARIA_1: '#0A192D',
  PRIMARIA_1_36: '#AFC2ED',
  PRIMARIA_1_CLARO: '#3C74D6',
  PRIMARIA_1_ESCURO: '#1551BA',
  PRIMARIA_2: '#0FC4C0',
  PRIMARIA_2_36: '#A0B7BB',
  PRIMARIA_2_CLARO: '#1253B2',
  SECUNDARIA_1: '#0ACCB9',
  PRIMARIA_2_ESCURO: '#2C6FC7',
  SECUNDARIA_1_CLARO: '#5BE8D7',
  SECUNDARIA_1_ESCURO: '#00A69C',
  SECUNDARIA_1_CLARA_ESCURA: '#34ACAC',
  SECUNDARIA_1_CLARA_ESCURA_50: 'rgba(52, 172, 172, 0.5)',
  SECUNDARIA_2: '#FF156B',
  SECUNDARIA_2_CLARO: '#FAF5B9',
  SECUNDARIA_2_ESCURO: '#009B9B',
  SECUNDARIA_2_ESCURO_40: 'rgba(0, 155, 155, 0.4)',
  SECUNDARIA_2_MAIS_ESCURO: '#006969',
  VERMELHO_CLARO: '#ff5b5b',
  SECUNDARIA_3: '#FF7179',
  SECUNDARIA_3_CLARO: '#CC1055',
  SECUNDARIA_3_ESCURO: '#06D15B',
  SECUNDARIA_3_MAIS_ESCURO: '#00ad49',
  VERDE: '#91C83E',
  CINZA_1: '#353535',
  CINZA_1_CLARO: '#DDDDDD',
  CINZA_1_ESCURO: '#B5C7C6',
  CINZA_2: '#666666',
  CINZA_2_MAIS_ESCURO: '#606060',
  CINZA_2_36: '#C6C6C6',
  CINZA_2_CLARO: '#909090',
  CINZA_2_ESCURO: '#303030',
  CINZA_3: '#D1D3D4',
  CINZA_3_36: '#D1D3D45C',
  CINZA_3_CLARO: '#E5E7E8',
  CINZA_3_MAIS_CLARO: '#f7f7f7',
  CINZA_3_ESCURO: '#BDBFC0',
  CINZA_HEAD_TABLE: '#D8D8D8',
  CINZA_4: '#BCC5D0',
  CINZA_MERCURY: '#E9E9E9',
  AZUL_CINZA: '#40597A',
  FUNDO: '#F3F5F9',
  BRANCO: '#FFFFFF',
  BRANCO_36: '#FFFFFF5C',
  PERIGO_ERRO: '#E6414B',
  PERIGO_ERRO_ESCURO: '#932819',
  PERIGO_ERRO_CLARO: '#D36556',
  PERIGO_ERRO_FUNDO: '#F1D0CB',
  PERIGO_ERRO_FUNDO_MAIS_CLARO: '#F8E7E5',
  ATENCAO: '#E6B617',
  ATENCAO_ESCURO: '#AF7903',
  ATENCAO_CLARO: '#F4BD44',
  ATENCAO_FUNDO: '#F3E2BD',
  SUCESSO: '#0C992F',
  SUCESSO_ESCURO: '#02700D',
  SUCESSO_CLARO: '#3DB64A',
  SUCESSO_FUNDO: '#B6E0BF',
  TRANSPARENTE: 'TRANSPARENT',
  PRETO: '#000000',
  NOTAS_APROVADO: '#CFE9D6',
  NOTAS_ABAIXO_MEDIA: '#F9E1E3',
  NOTAS_NAO_RESPONDIDA: '#F9F1D7',
  SOMBRA_PRIMARIA_2: 'rgba(1, 59, 154, 0.24)',
  BACKDROP: '#282C34',
  RENEGOCIADA: '#0000FF',
  NEGATIVADA: '#FF0000',
  PRORROGACAO_CORRETA: '#CFE9D6',
  PRORROGACAO_INCORRETA: '#F9E1E3'
}
