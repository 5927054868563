import React, { useMemo } from 'react'

import classNames from 'classnames'

import { Checkbox } from '../checkbox'
import { Cores } from '../styles/cores'
import { TabelaLinhaProps } from './tipos'

const Linha: React.FC<TabelaLinhaProps> = ({
  Id,
  Indice,
  ComMarcacao,
  ComMarcacaoCondicao,
  Colunas,
  Dados,
  MarcarDesmarcar,
  TemaLinha
}) => {
  const estiloDaLinha = TemaLinha ? { color: Cores[TemaLinha] } : {}

  const marcado = useMemo(() => Dados.marcado, [Dados.marcado])

  const comMarcacao = useMemo(
    () =>
      (ComMarcacao && !ComMarcacaoCondicao) ||
      (!!ComMarcacaoCondicao && ComMarcacaoCondicao(Dados)),
    [ComMarcacao, ComMarcacaoCondicao, Dados]
  )

  const manipularClick = () => {
    if (comMarcacao) {
      MarcarDesmarcar(!marcado, Indice)
    }
  }

  return (
    <tr
      className={classNames({
        par: (Indice + 1) % 2 === 0,
        marcado,
        comMarcacao: ComMarcacao
      })}
      style={estiloDaLinha}
      onClick={e => {
        e.stopPropagation()
        manipularClick()
      }}
    >
      {comMarcacao && (
        <td key={`linha-${Indice}-coluna-marcacao`}>
          <Checkbox
            htmlFor={Id ? `checkbox-${Indice}-${Id}` : `checkbox-${Indice}`}
            name={Id ? `checkbox-${Indice}-${Id}` : `checkbox-${Indice}`}
            tema="Secundario"
            tamanho="S"
            readOnly
          >
            <input
              name={Id ? `checkbox-${Indice}-${Id}` : `checkbox-${Indice}`}
              id={Id ? `checkbox-${Indice}-${Id}` : `checkbox-${Indice}`}
              type="checkbox"
              checked={marcado}
              readOnly
            />
          </Checkbox>
        </td>
      )}
      {ComMarcacao && !!ComMarcacaoCondicao && !ComMarcacaoCondicao(Dados) && (
        <td />
      )}
      {Colunas.map(
        (
          { Chave, Renderizar, Alinhamento, StyleColuna },
          indiceColuna: number
        ) => (
          <td
            key={`linha-${Indice}-coluna-${indiceColuna}`}
            align={Alinhamento || 'left'}
            style={StyleColuna}
          >
            {Renderizar ? (
              Renderizar(Dados)
            ) : (
              <span className={`${Chave}-${Indice}`}>{Dados[Chave]}</span>
            )}
          </td>
        )
      )}
    </tr>
  )
}

export default Linha
