import React from 'react'

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect
      x="3.66666"
      y="2.6665"
      width="12.7279"
      height="1.41421"
      rx="0.707107"
      transform="rotate(45 3.66666 2.6665)"
      fill="#C9412F"
    />
    <rect
      x="2.66666"
      y="11.6665"
      width="12.7279"
      height="1.41421"
      rx="0.707107"
      transform="rotate(-45 2.66666 11.6665)"
      fill="#C9412F"
    />
  </svg>
)
