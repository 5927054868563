import React from 'react'

export default (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.44209 10.9419C5.19801 10.6979 5.19801 10.3021 5.44209 10.0581L10.0582 5.44194C10.3023 5.19786 10.698 5.19786 10.9421 5.44194C11.1862 5.68602 11.1862 6.08175 10.9421 6.32582L6.32598 10.9419C6.0819 11.186 5.68617 11.186 5.44209 10.9419Z"
      fill="#E6414B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.55791 5.44194C5.80199 5.19786 6.19771 5.19786 6.44179 5.44194L11.0579 10.0581C11.302 10.3021 11.302 10.6979 11.0579 10.9419C10.8138 11.186 10.4181 11.186 10.174 10.9419L5.55791 6.32582C5.31383 6.08175 5.31383 5.68602 5.55791 5.44194Z"
      fill="#E6414B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#E6414B"
    />
  </svg>
)
