import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaValorParcelaTCCComDesconto = (): TabelaColuna => ({
  Chave: 'ValorParcelaTCCComDesconto',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 250 }}>Valor Parcela TCC Com Desconto</div>
  )
})
