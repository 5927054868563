import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  & > .rdw-editor-wrapper {
    width: 100%;

    & > .rdw-link-modal {
      height: unset;
    }
  }
`
