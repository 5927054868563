import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ContainerAcoes,
  Filtro,
  IconeEditar,
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  FormRow,
  Tooltip,
  IconeDeListaDeDocumentos,
  Cores,
  AutenticacaoContainer,
  formatarTelefone
} from 'src/componentes'
import { Spacer } from 'src/componentes/spacer'
import { InputUnform, SelectUnform } from 'src/componentes/unform'
import { ProfessorDto } from 'src/dto'
import { RotasProfessores } from 'src/rotas'
import { Api } from 'src/servicos'
import { Nacionalidade, TipoOrdenacao, UsuarioSituacao } from 'src/tipos'

import { Form, Dados, Container, ContainerTooltip } from './styles'

interface DadosPesquisa {
  nome: string
  situacao: UsuarioSituacao
  uf: string
  aptidoes: string
  cidade: string
}

export const PaginaProfessores: React.FC = () => {
  const history = useHistory()
  const tabelaRef = useRef<TabelaRef>()
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState(null)
  const { usuario } = AutenticacaoContainer.useContainer()

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.IrParaPagina()
    tabelaRef.current.CarregarDados(true)
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }
    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    pagina: number,
    quantidadeRegistros: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      pagina = paginas
    }

    const dados = await Api.ObterListaProfessores({
      nome: dadosPesquisa?.nome,
      situacao: dadosPesquisa?.situacao,
      uf: dadosPesquisa?.uf,
      aptidoes: dadosPesquisa?.aptidoes,
      cidade: dadosPesquisa?.cidade,
      pagina,
      quantidadeRegistros,
      chaveOrdenacao,
      ordem
    })

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados.opcoesPaginacao?.pagina,
        TotalRegistros: dados.opcoesPaginacao?.totalRegistros
      }
    }
  }

  return (
    <Container>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Professores"
          atalhos={[
            {
              texto: 'Acadêmico'
            },
            {
              texto: 'Professores'
            }
          ]}
        />
        <Botao
          type="button"
          texto="Incluir"
          tema="Secundario"
          onClick={() => history.push(RotasProfessores.Cadastro)}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: UsuarioSituacao[UsuarioSituacao.Todas]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <FormRow>
            <InputUnform
              type="text"
              name="nome"
              label="Nome"
              maxLength={200}
              className="sm"
            />
            <SelectUnform
              id="slct_situacao"
              name="situacao"
              label="Situação"
              iconeFechar={false}
              className="sm"
              opcoes={[
                {
                  id: UsuarioSituacao.Todas.toString(),
                  texto: 'Todas'
                },
                {
                  id: UsuarioSituacao.Ativo.toString(),
                  texto: 'Ativo'
                },
                {
                  id: UsuarioSituacao.Inativo.toString(),
                  texto: 'Inativo'
                }
              ]}
            />
            <InputUnform
              className="sm"
              type="text"
              name="aptidoes"
              label="Aptidões"
              maxLength={200}
            />
          </FormRow>
          <FormRow>
            <InputUnform
              className="sm"
              type="text"
              name="uf"
              label="UF"
              maxLength={10}
            />
            <InputUnform
              className="sm"
              type="text"
              name="cidade"
              label="Município"
              maxLength={200}
            />
            <Spacer className="sm" />
          </FormRow>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          MostrarCarregandoInstantaneo
          ref={tabelaRef}
          ComPaginacao
          ObterDados={obterDados}
          Colunas={[
            {
              Chave: 'nome',
              Texto: 'Nome',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.nomeSocial ?? linha.nome}</span>
              )
            },
            {
              Chave: 'endereco',
              Texto: 'UF - Município',
              ComOrdenacao: true
            },
            {
              Chave: 'email',
              Texto: 'E-mail',
              ComOrdenacao: true
            },
            {
              Chave: 'celular',
              Texto: 'Celular',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <> {formatarTelefone(linha.celular)} </>
              )
            },
            {
              Chave: 'aptidoes',
              Texto: 'Aptidões',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>
                  {linha.aptidoes.length > 30 ? (
                    <div data-tip={linha.aptidoes} data-for="exibir">
                      {linha.aptidoes.substring(0, 30)}...
                    </div>
                  ) : (
                    <> {linha.aptidoes} </>
                  )}
                  <ContainerTooltip>
                    <Tooltip id="exibir" offset={{ top: 10 }} />
                  </ContainerTooltip>
                </>
              )
            },
            {
              Chave: 'situacao',
              Texto: 'Status',
              ComOrdenacao: true
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    onClick={() =>
                      history.push(`${RotasProfessores.Edicao}/${linha.id}`)
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasProfessores.Edicao}/${linha.id}`
                      )
                      rota.focus()
                    }}
                  >
                    {IconeEditar}
                  </button>
                  <button
                    onClick={() => {
                      const professor = (linha as unknown) as ProfessorDto

                      const caminho =
                        usuario.nacionalidade === Nacionalidade[0]
                          ? RotasProfessores.DocumentosProfessorBrasileiro
                          : RotasProfessores.DocumentosProfessorEstrangeiro

                      const rota = `${caminho}/${professor.email}`

                      history.push(rota)
                    }}
                    onContextMenu={() => {
                      const professor = (linha as unknown) as ProfessorDto

                      const caminho =
                        usuario.nacionalidade === Nacionalidade[0]
                          ? RotasProfessores.DocumentosProfessorBrasileiro
                          : RotasProfessores.DocumentosProfessorEstrangeiro

                      const rota = window.open(`${caminho}/${professor.email}`)
                      rota.focus()
                    }}
                    data-tip={'Ver documentos do professor'}
                    data-for={`LISTA_DE_DOCUMENTOS_ID/${linha.idUsuario}`}
                  >
                    {IconeDeListaDeDocumentos}
                    <Tooltip
                      id={`LISTA_DE_DOCUMENTOS_ID/${linha.idUsuario}`}
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                      place={'right'}
                    />
                  </button>
                </ContainerAcoes>
              )
            }
          ]}
        />
      </Dados>
    </Container>
  )
}

export { default as PaginaCadastroProfessores } from './cadastro'
