import React, { useState } from 'react'

import { Guias } from './guias'
import { Componente, ContainerAbas, ContainerConteudo } from './styles'
import { AbasProps } from './tipos'

export const Abas: React.FC<AbasProps> = ({ paginas, dados }: AbasProps) => {
  const [paginaAtual, definePaginaAtual] = useState(paginas[0].titulo)

  return (
    <Componente>
      {paginas.length > 0 && (
        <>
          <Guias
            paginas={paginas.map(item => item.titulo)}
            paginaAtual={paginaAtual}
            definePaginaAtual={definePaginaAtual}
          />
          <ContainerConteudo data-testid="container-conteudo-abas">
            {paginas.find(item => item.titulo === paginaAtual)?.conteudo ?? (
              <></>
            )}
            {paginas
              .find(item => item.titulo === paginaAtual)
              .conteudoTabela?.map(({ Chave, Renderizar }, index: number) => (
                <ContainerAbas key={index}>
                  {Renderizar ? (
                    Renderizar(dados)
                  ) : (
                    <span className={`${Chave}-${index}`}>{dados[Chave]}</span>
                  )}
                </ContainerAbas>
              ))}
          </ContainerConteudo>
        </>
      )}
    </Componente>
  )
}
