import styled, { css } from 'styled-components'

import { Cores } from '../styles/cores'

interface ComponenteProps {
  readOnly?: boolean
}

export const Componente = styled.div<ComponenteProps>`
  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
    display: flex;
  }

  ${props =>
    !props.readOnly
      ? css`
          ul li label {
            border: 2px solid ${Cores.CINZA_2};
            border-left-width: 1px;
            border-right-width: 1px;
            width: 100%;
            height: 100%;
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
            color: ${Cores.CINZA_2};
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
          }

          ul li:first-child label {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            border: 2px solid ${Cores.CINZA_2};
            border-right-width: 1px;
            box-shadow: -2px 0px 0px 0px ${Cores.TRANSPARENTE};
          }

          ul li:last-child label {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            border: 2px solid ${Cores.CINZA_2};
            border-left-width: 1px;
            box-shadow: inset -2px 0px 0px 0px ${Cores.TRANSPARENTE};
          }

          ul li label:hover {
            background: ${Cores.PRIMARIA_2};
            color: ${Cores.BRANCO};
            border-color: ${Cores.PRIMARIA_2};
          }
        `
      : css`
          ul li label {
            width: 100%;
            height: 100%;
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
            color: ${Cores.CINZA_2};
            display: flex;
            align-items: center;
            text-align: center;
          }

          svg path {
            fill: ${Cores.CINZA_2};

            &:hover {
              fill: ${Cores.CINZA_2};
            }
          }

          ul > li > label > div {
            padding: 18px 0px;
          }

          ul > li + li {
            & > label > div {
              padding-left: 15px;
            }
          }

          ul > li > label > div:hover,
          ul > li > label > div.marcado {
            svg path {
              fill: ${Cores.CINZA_2};
            }
            &.comStroke {
              svg path {
                stroke: ${Cores.CINZA_2};
              }
            }
          }
        `};

  input {
    position: absolute;
    visibility: collapse;
  }

  & input:checked ~ label {
    ${props =>
      !props.readOnly &&
      css`
        background: ${Cores.PRIMARIA_2};
        border-color: ${Cores.PRIMARIA_2}!important;
        visibility: visible;
        color: ${Cores.BRANCO};
      `};
  }

  svg {
    margin-right: 6px;
  }
`

export const SeletorEstilo = styled.div<{ icone: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  font-family: 'Poppins-bold', sans-serif;
  ${({ icone }) => {
    if (icone) {
      return css`
        padding: 15px 24px;
        & i {
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }
      `
    }
  }}

  svg path {
    fill: ${Cores.CINZA_2};
  }

  &.comStroke {
    svg path {
      stroke: ${Cores.CINZA_2};
    }
  }

  & div {
    white-space: nowrap;
  }

  &:hover,
  &.marcado {
    svg path {
      fill: ${Cores.BRANCO};
    }

    &.comStroke {
      svg path {
        stroke: ${Cores.BRANCO};
      }
    }
  }
`

export const CampoLabel = styled.label`
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  text-align: left;
  margin-bottom: 6px;
  color: ${Cores.CINZA_2};

  :first-letter {
    text-transform: uppercase;
  }
`
