export enum FormaPagamentoCobranca {
  Boleto = 'Boleto',
  CartaoCredito = 'CartaoCredito',
  CupomDesconto = 'CupomDesconto',
  Dinheiro = 'Dinheiro',
  Pix = 'Pix',
  Deposito = 'Deposito',
  TED = 'TED',
  PagSeguro = 'PagSeguro',
  PayPal = 'PayPal',
  Cheque = 'Cheque'
}

export interface BaixaManualCobranca {
  dataPagamento: string
  valorPagamento: number
  formaPagamento: FormaPagamentoCobranca
  comprovante: File | string
}

export interface BaixaManualTaxaMatricula {
  codigoCupom: string
  formaPagamento: string
  dataPagamento: string
  valorPagamento: number
  comprovante: File | string
}

export interface BaixaManualTaxaReingresso {
  formaPagamento: string
  dataPagamento: string
  valorPagamento: number
  comprovante: File | string
}

export interface BaixaManualTaxaTrocaCurso {
  codigoCupom: string
  formaPagamento: string
  dataPagamento: string
  valorPagamento: number
  comprovante: File | string
}

export interface AlterarCobranca {
  observacao?: string
  observacaoInterna?: boolean
}

export interface NovaCompra {
  quantidade: number
  valorParcela: number
  observacao?: string
  primeiroVencimento: Date
  excluirCobrancas: boolean
  novaRecorrencia: boolean
}
