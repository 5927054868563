import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { IconeFechar, ModalBaseProps, Spacer } from 'src/componentes'
import { ModalBase } from 'src/componentes/modal-base'

import {
  BotaoFecharModal,
  ContainerBotaoFecharModal,
  ContainerInfo,
  Corpo
} from '../../style'
import { Modulo } from '../../tipos'
import { ModalVerMaisRef } from './tipos'

const ModalVerMais: React.ForwardRefRenderFunction<
  ModalVerMaisRef,
  ModalBaseProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [modulo, definirModulo] = useState<Modulo>()

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = (item: Modulo) => {
    definirModulo(item)
    definirAberta(true)
  }

  useImperativeHandle<ModalVerMaisRef, ModalVerMaisRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <ContainerBotaoFecharModal>
          <BotaoFecharModal
            onClick={() => {
              fechar()
            }}
          >
            {IconeFechar}
          </BotaoFecharModal>
        </ContainerBotaoFecharModal>
        <h5>{modulo?.nome}</h5>
        <Spacer padding="10px 0px" />
        <ContainerInfo>
          <p
            dangerouslySetInnerHTML={{
              __html:
                modulo?.informacoesAdicionais
                  ?.replaceAll('<p></p>', '<br />')
                  .replaceAll('\\n', '<br />') ?? ''
            }}
          />
        </ContainerInfo>
      </Corpo>
    </ModalBase>
  )
}
export const ModalVerMaisInformacoes = forwardRef(ModalVerMais)
