import React, { useEffect, useState, forwardRef, ChangeEvent } from 'react'

import { Input } from '.'
import { CpfInputProps } from './tipos'

const mascara = '999.999.999-99'

export const CpfInput: React.ForwardRefExoticComponent<CpfInputProps> = forwardRef<
  HTMLInputElement,
  CpfInputProps
>(({ value, onChange, ...rest }, ref?: any) => {
  const [valor, definirValor] = useState(value ?? '')

  const alterarValor = (valor: string) => {
    const valorFinal = valor ? valor.replace(/\D/g, '') || '' : ''

    definirValor(valorFinal)

    if (onChange) {
      onChange({
        target: { value: valorFinal }
      } as ChangeEvent<HTMLInputElement>)
    }
  }

  useEffect(() => {
    alterarValor(value)
  }, [value])

  const colar = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const valor = e.clipboardData.getData('Text').trim()
    alterarValor(valor)
  }

  return (
    <Input
      {...rest}
      ref={ref}
      mascara={mascara}
      value={valor}
      onChange={e => alterarValor(e.target.value)}
      onPaste={colar}
    />
  )
})
