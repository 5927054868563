import { Responsivo } from 'src/compartilhados'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ContainerErroLayout} > div {
    width: 100%;
  }
`

export const Conteudo = styled.div`
  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  border: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 4px 0;
`

export const Titulo = styled.h2`
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;

  ${props => Responsivo.Mobile(props)`
    font-size: 16px;
    line-height: 19px;
  `}

  ${props => Responsivo.Tablet(props)`
    font-style: italic;
    font-size: 25px;
    line-height: 29px;
  `}
`

export const SubTitulo = styled.h3`
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 12px;

  ${props => Responsivo.Mobile(props)`
    font-size: 14px;
    line-height: 19px;
  `}

  ${props => Responsivo.Tablet(props)`
    font-style: italic;
    font-size: 15px;
    line-height: 29px;
  `}
`
export const MensagemAviso = styled.div`
  margin-bottom: 10px;
  text-align: center;

  p {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 4px;

    ${props => Responsivo.Mobile(props)`
    font-size: 12px;
    line-height: 19px;
  `}

    ${props => Responsivo.Tablet(props)`
    font-style: italic;
    font-size: 15px;
    line-height: 29px;
  `}
  }
`

export const ContainerTela = styled.div`
  margin-top: 47px;
`
export const ContainerBotao = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  ${props => Responsivo.Mobile(props)`
     flex-direction: column;

     button {
       width: 100%;
     }
  `}
`
export const ContainerBotaoPesquisaSatisfacao = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
  width: 100%;

  button:first-child {
    margin-right: 10px;
  }
`

export const ContainerPergunta = styled.div``

export const Espaço = styled.div`
  margin-right: 10px;
`
