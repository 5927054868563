import styled from 'styled-components'

export const Tabela = styled.div`
  width: 100%;
`
export const TabelaLinha = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;

  &:not(:last-child) {
    padding-bottom: 13px;
  }
`
