import React, { FC, useEffect, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Carregando,
  ContainerAcoes,
  ErroContainerLayout,
  ErroLayoutContainer,
  FormUnform,
  IconeBack,
  InputUnform,
  Navegacao,
  SubTitulo,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  Filtro,
  FormRow,
  SelectUnform,
  IconeEditar,
  ModalRef
} from 'src/componentes'
import { Api } from 'src/servicos'
import { SituacaoFinanceira, SituacaoFinanceiraPorExtenso } from 'src/tipos'

import { Container, ContainerLateral, Conteudo } from '../../cadastro/styles'
import { ContainerMain } from '../../styles'
import { ContainerBotao } from '../disciplinas/styles'
import { NotasPresencaProps } from '../disciplinas/tipos'
import { AlunosPresencial } from '../tipos'
import { ModalConfirmacaoAproveitamentoDisciplina } from './componentes/modal-confirmacao-aproveitamento-disciplina'
import {
  CabecalhoComEstilos,
  ContainerLink,
  TabelaComEstilos,
  FormStyled,
  Voltar
} from './styles'
import { opcoesFiltros } from './tipos'

enum Navegacoes {
  Alunos = 'alunos'
}

const PaginaAproveitamentoDisciplina: React.FC<NotasPresencaProps> = ({
  match
}) => {
  const tabelaRef = useRef<TabelaRef>()

  const history = useHistory()
  const { limparErro, erro } = ErroLayoutContainer.useContainer()
  const [pronto, definirPronto] = useState<boolean>(false)
  const [dadosIniciais, definirDadosIniciais] = useState(null)
  const [turma, definirTurma] = useState(null)
  const [situacaoFiltro, definirSituacaoFiltro] = useState<SituacaoFinanceira>(
    null
  )
  const modalRefConfirmacaoAproveitamento = useRef<ModalRef>(null)
  const [salaAulaEadId, definirSalaAulaEadId] = useState(null)

  const idDaDisciplina = match.params.idDaDisciplina
  const idTurma = match.params.idDaTurma
  const idDoModulo = match.params.idDoModulo

  const turmaEhDisciplina = () =>
    idTurma !== 'undefined' && idDaDisciplina !== 'undefined'

  const obterTurma = async (): Promise<AlunosPresencial> => {
    return turmaEhDisciplina()
      ? await Api.ObterDetalhesDisciplinaPresencial(idDaDisciplina, idTurma)
      : await Api.ObterDetalhesModulo(idTurma, idDoModulo)
  }

  const carregarDisciplina = async () => {
    try {
      definirPronto(false)
      limparErro()

      const turma = await obterTurma()

      if (turma.alunos && turma.alunos.length > 0) {
        definirTurma(turma)
        definirDadosIniciais({
          turma: turma.nomeTurma,
          modulo: `${turma.nomeModulo} - ${turma.nomeDisciplina} `
        })
      } else {
        throw Error('A turma ainda não possui nenhum aluno matriculado.')
      }
    } catch (error) {
      toast('Ops! Houve algum problema no carregamento dos dados.', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const obterDados = async (): Promise<TabelaResposta | null> => {
    const alunosBase = turma && turma.alunos ? turma.alunos : []
    const dados = !situacaoFiltro
      ? alunosBase
      : alunosBase.filter(aluno => aluno.situacaoFinanceira === situacaoFiltro)

    return { Dados: dados } as TabelaResposta
  }

  const aproveitarDisciplina = async (aproveitamento: boolean) => {
    try {
      await Api.SalaAulaOutros.AproveitarDisciplina(
        salaAulaEadId,
        idDaDisciplina,
        aproveitamento
      )
      await carregarDisciplina()
      await tabelaRef.current.CarregarDados(true)
      toast('Aproveitamento salvo com sucesso.', {
        type: 'success'
      })
    } catch (error) {
      toast('Houve um problema ao aproveitar a disciplina.', {
        type: 'error'
      })
    }
  }

  // TO-DO: Falta implementação
  // eslint-disable-next-line
  const acaoSucessoFiltro = async dados => {}

  const filtroAlterado = async selecionado => {
    if (!selecionado) {
      await definirSituacaoFiltro(null)
    } else {
      const { id } = selecionado
      await definirSituacaoFiltro(id as SituacaoFinanceira)
    }
    await tabelaRef.current.CarregarDados(true)
  }

  useEffect(() => {
    carregarDisciplina()
  }, [])

  return pronto && !erro ? (
    <Container>
      <ContainerMain>
        <CabecalhoComEstilos>
          <ContainerLink>
            <Voltar
              onClick={() => {
                const rota = `/academico/turmas/notas-presenca/${idTurma}`
                history.push(rota)
              }}
            >
              {IconeBack} Voltar
            </Voltar>
          </ContainerLink>
          <Breadcrumb
            titulo="Aproveitamento da Disciplina"
            atalhos={[
              {
                texto: 'Acadêmico'
              },
              {
                texto: 'Notas e Presença'
              }
            ]}
          />
        </CabecalhoComEstilos>
        <Conteudo>
          <div id={Navegacoes.Alunos}>
            <SubTitulo texto="Alunos" />
            <FormUnform acaoSucesso={null} dadosIniciais={dadosIniciais}>
              <InputUnform name="turma" label="Turma" disabled />
              <InputUnform name="modulo" label="Módulo - Disciplina" disabled />
            </FormUnform>
            <>
              <Filtro>
                <FormStyled acaoSucesso={acaoSucessoFiltro}>
                  <FormRow>
                    <SelectUnform
                      className="sm"
                      id="slct_situacao"
                      name="situacao"
                      label="Situação"
                      iconeFechar
                      opcoes={opcoesFiltros}
                      valorAlterado={filtroAlterado}
                    ></SelectUnform>
                  </FormRow>
                </FormStyled>
              </Filtro>
              <TabelaComEstilos
                ref={tabelaRef}
                Colunas={[
                  {
                    Chave: 'nomeAluno',
                    Texto: 'Aluno',
                    Renderizar: (linha: TabelaDados) => <>{linha.nomeAluno}</>
                  },
                  {
                    Chave: 'situacaoFinanceira',
                    Texto: 'Situação',
                    Renderizar: (linha: TabelaDados) => (
                      <>
                        {SituacaoFinanceiraPorExtenso[linha.situacaoFinanceira]}
                      </>
                    )
                  },
                  {
                    Chave: 'acoes',
                    Alinhamento: 'center',
                    Renderizar: (linha: TabelaDados) => (
                      <>
                        <ContainerAcoes>
                          <button
                            type="button"
                            data-tip="Pontuar"
                            data-for={`${linha.id}-tooltip-editar`}
                            onClick={() => {
                              modalRefConfirmacaoAproveitamento?.current?.abrir()
                              definirSalaAulaEadId(linha.idSalaAula)
                            }}
                          >
                            {IconeEditar}
                          </button>
                        </ContainerAcoes>
                      </>
                    )
                  }
                ]}
                ObterDados={obterDados}
              />
            </>
          </div>
        </Conteudo>
        <ContainerBotao>
          <Botao
            type="button"
            texto="Voltar"
            tema="Secundario"
            onClick={() => history.goBack()}
          />
        </ContainerBotao>
      </ContainerMain>
      <ContainerLateral>
        <Navegacao
          itens={[
            {
              link: Navegacoes.Alunos,
              nome: 'Alunos'
            }
          ]}
        />
      </ContainerLateral>
      <ModalConfirmacaoAproveitamentoDisciplina
        ref={modalRefConfirmacaoAproveitamento}
        backdrop
        id="modal-confirmacao-aproveitamento"
        acaoSucesso={aproveitarDisciplina}
      />
    </Container>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados do aluno..." />
  ) : (
    <ErroContainerLayout />
  )
}

const withContainer: FC<NotasPresencaProps> = (props: NotasPresencaProps) => (
  <ErroLayoutContainer.Provider>
    <PaginaAproveitamentoDisciplina {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(withContainer)
