import React, { useRef, useEffect, useState } from 'react'
import { InputState } from 'react-input-mask'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { Input } from '../../input'
import { Componente, Erro } from './styles'
import { InputUnformProps } from './tipos'

export const TelefoneInputUnform: React.FC<
  Omit<InputUnformProps, 'mascara'>
> = ({ name, semMargemAbaixo, className, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name || '')

  const retornarMascaraPorValor = (valor: string) => {
    if (valor && valor.replace(/\D/g, '').length === 11) {
      return '(99) 99999 9999'
    }

    return '(99) 9999 9999'
  }

  type mascaras = '(99) 99999 9999' | '(99) 9999 9999'
  const [mascara, definirMascara] = useState<mascaras>(
    retornarMascaraPorValor(defaultValue)
  )
  const [valor, definirValor] = useState(defaultValue)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue({ value }) {
        return value && value.replace(/\D/g, '')
      },
      setValue(ref: any, value: string) {
        if (!value) return

        if (value.length === 11 && mascara !== '(99) 99999 9999') {
          definirMascara('(99) 99999 9999')
        } else if (value.length < 11 && mascara !== '(99) 9999 9999') {
          definirMascara('(99) 9999 9999')
        }

        ref.setInputValue(value)
        definirValor(value)
      },
      clearValue(ref: any) {
        ref.setInputValue('')
      }
    })
  }, [fieldName, registerField])

  const alterarValor = (
    e:
      | React.ClipboardEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>,
    valor: string
  ) => {
    const valorFinal = valor.replace(/\D/g, '') ?? ''
    if (valorFinal.length === 11 && mascara !== '(99) 99999 9999') {
      definirMascara('(99) 99999 9999')
    } else if (valorFinal.length < 11 && mascara !== '(99) 9999 9999') {
      definirMascara('(99) 9999 9999')
    }
    definirValor(valorFinal)
  }

  const colar = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const valor = e.clipboardData.getData('Text').trim()
    alterarValor(e, valor)
  }

  const antesDeAlterarOValor = (
    estadoNovo: InputState,
    estadoAntigo: InputState,
    valorUsuario: string
  ): InputState => {
    let { value: valorNovo, selection } = estadoNovo
    const { value: valorAntigo } = estadoAntigo

    if (
      !/\D/.test(valorUsuario) &&
      valorAntigo.replace(/\D/g, '').length === 10
    ) {
      valorNovo += valorUsuario
      selection = { start: selection.start + 1, end: selection.end + 1 }
    }

    return {
      value: valorNovo,
      selection
    }
  }

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames(className, {
        semMargemAbaixo
      })}
    >
      <Input
        {...rest}
        name={name}
        ref={inputRef}
        erro={!!error}
        className={classNames({
          error
        })}
        mascara={mascara}
        value={valor}
        onChange={e => alterarValor(e, e.target.value)}
        onPaste={colar}
        beforeMaskedValueChange={antesDeAlterarOValor}
        onKeyPress={clearError}
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </Componente>
  )
}
