import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ModalBase,
  ModalBaseProps,
  Botao,
  FuncoesDataHora
} from 'src/componentes'

import {
  ContainerAcoes,
  ContainerDados,
  ContainerModal,
  CorpoModal
} from './styles'
import { ModalAvisoTCCRef } from './tipos'

const ModalAvisoTCCComponent: React.ForwardRefRenderFunction<
  ModalAvisoTCCRef,
  ModalBaseProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [dataLimiteAvaliacao, definirDataLimiteAvaliaco] = useState<string>('')

  const fechar = () => {
    definirAberta(false)
    definirDataLimiteAvaliaco('')
  }

  const abrir = (dataLimiteAvaliacao?: string) => {
    definirAberta(true)
    definirDataLimiteAvaliaco(dataLimiteAvaliacao)
  }

  useImperativeHandle<ModalAvisoTCCRef, ModalAvisoTCCRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase id="modal-ultima-tentativa" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>ATENÇÃO!</h5>
          <ContainerDados>
            <p>
              A nota atribuída pelo orientador não foi suficiente para que seu
              trabalho fosse enviado para avaliação da banca. Acesse o feedback
              do orientador e efetue as alterações necessárias.
            </p>
          </ContainerDados>
          <ContainerDados>
            <p>
              Prazo para o novo envio da versão final:{' '}
              {FuncoesDataHora.dataToLocaleString(dataLimiteAvaliacao)}
            </p>
          </ContainerDados>
          <ContainerAcoes>
            <Botao id="confirmar" texto="Ok" onClick={() => fechar()} />
          </ContainerAcoes>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalAvisoTCC = forwardRef(ModalAvisoTCCComponent)
