export const formatarNome = (nomeCompleto: string): string => {
  if (!nomeCompleto) return '-'
  if (typeof nomeCompleto === 'undefined') return '-'

  const nomeSeparado = nomeCompleto.toLowerCase().split(' ')

  return nomeSeparado
    .map(nome => {
      return nome[0].toUpperCase() + nome.substring(1)
    })
    .join(' ')
}
