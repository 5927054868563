import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  Botao,
  Breadcrumb,
  Carregando,
  ContainerAcoes,
  Cores,
  ErroContainerLayout,
  ErroLayoutContainer,
  FormUnform,
  IconeBack,
  IconeBaixar,
  IconeCerto,
  IconeEditar,
  IconeErrado,
  InputUnform,
  Navegacao,
  SubTitulo,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  Filtro,
  FormRow,
  SelectUnform,
  Visao,
  IconeAlterar,
  IconeUploadNuvem
} from 'src/componentes'
import { TipoDeAvaliacao } from 'src/dto'
import { RotasAcademico } from 'src/rotas/academico'
import { RotasProfessor } from 'src/rotas/professor'
import { Api } from 'src/servicos'
import { getApiError } from 'src/servicos/instancias-api'
import {
  FeedbackTCC,
  ModalidadeCurso,
  SituacaoFinanceira,
  TipoProfessorModuloTCC
} from 'src/tipos'

import { ModalAlterarTituloTCC } from '.'

import {
  AlunosPresencialMock,
  Feedback,
  ModalNotaDisciplinaTCC,
  ModalNotaDisciplinaRef,
  NotaDisciplinaProps
} from '..'

import { Container, ContainerLateral, Conteudo } from '../../cadastro/styles'
import { ContainerMain } from '../../styles'
import { ContainerBotao } from '../disciplinas/styles'
import { NotasPresencaProps } from '../disciplinas/tipos'
import { AlunosPresencial } from '../tipos'
import { COLUNAS_DE_NOTAS_E_PRESENCA_NA_TURMA } from './componentes/colunas'
import { ModalAlterarTituloTCCRef } from './componentes/modal-alterar-titulo-tcc/tipos'
import {
  ModalDocumentoAproveitamentoTCC,
  ModalDocumentoAproveitamentoRef,
  ModalDocumentoAproveitamentoDados
} from './componentes/modal-documento-aproveitamento'
import {
  CabecalhoComEstilos,
  ContainerData,
  ContainerIcone,
  ContainerIcones,
  ContainerLink,
  TabelaComEstilos,
  FormStyled,
  Voltar
} from './styles'
import { opcoesFiltros } from './tipos'

enum Navegacoes {
  Alunos = 'alunos'
}

export const dataFormatadaComBarras = (data = new Date()): string => {
  const novaData = data.toISOString().split('-')

  const dia = novaData[2].slice(0, 2)
  const mes = novaData[1]

  return `${dia}/${mes}`
}

const PaginaVerAlunosPresencialTCC: React.FC<NotasPresencaProps> = ({
  match
}) => {
  const tabelaRef = useRef<TabelaRef>()
  const modalNotaDisciplinaRef = useRef<ModalNotaDisciplinaRef>(null)
  const modalNotaModuloRef = useRef<ModalNotaDisciplinaRef>(null)
  const modalAlterarTituloTCCRef = useRef<ModalAlterarTituloTCCRef>(null)
  const modalDocumentoAproveitamentoRef = useRef<ModalDocumentoAproveitamentoRef>(
    null
  )
  const history = useHistory()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()
  const { visao } = AutenticacaoContainer.useContainer()
  const visaoProfessor = useMemo(() => visao === Visao.Professor, [visao])
  const visaParceiro = useMemo(() => visao === Visao.Parceiro, [visao])
  const visaoIPGS = useMemo(() => visao === Visao.IPGS, [visao])
  const [pronto, definirPronto] = useState<boolean>(false)
  const tipoAvaliacao = AlunosPresencialMock.tipoArquivo
  const [dadosIniciais, definirDadosIniciais] = useState(null)
  const [turma, definirTurma] = useState<AlunosPresencial>(null)
  const [modalidade, definirModalidade] = useState(null)
  const [feedback, definirFeedback] = useState<Feedback>(null)
  const [situacaoFiltro, definirSituacaoFiltro] = useState<SituacaoFinanceira>(
    null
  )

  const idDaDisciplina = match.params.idDaDisciplina
  const idTurma = match.params.idDaTurma
  const ehPresencial = modalidade === ModalidadeCurso.PosGraduacaoPresencial

  const dadosIniciaisFiltro = useMemo(() => {
    return {
      situacao: visaoProfessor
        ? SituacaoFinanceira[SituacaoFinanceira.Matriculado]
        : null
    }
  }, [visaoProfessor])

  const possuiTurmaPresencialPorDisciplina = () =>
    idTurma !== 'undefined' && idDaDisciplina !== 'undefined'

  const obterTurma = async (): Promise<AlunosPresencial> => {
    return await Api.ObterDetalhesDisciplinaTCCPresencial(
      idDaDisciplina,
      idTurma
    )
  }

  const carregarDisciplina = async () => {
    try {
      definirPronto(false)
      limparErro()

      const modalidade = await Api.RequisitarTurmaPorId(idTurma)
      const turma = await obterTurma()

      if (turma.alunos && turma.alunos.length > 0) {
        definirModalidade(modalidade.modalidadeCurso)
        definirTurma(turma)
        definirDadosIniciais({
          turma: turma.nomeTurma,
          modulo: `${turma.nomeModulo} - ${turma.nomeDisciplina} `,
          professor: turma.tutorTurma
            ? turma.tutorTurma
            : turma.nomeProfessorDisciplina
        })
      } else {
        throw Error('A turma ainda não possui nenhum aluno matriculado.')
      }
    } catch (error) {
      getApiError(error).then(result => {
        const mensagens = result.join('\n')

        definirErro({
          mensagens: `Ops! Houve algum problema no carregamento dos dados.\n${mensagens}`,
          acaoVoltar: () =>
            history.push(
              visaoProfessor ? RotasProfessor.Dashboard : RotasAcademico.Turmas
            )
        })
      })
    } finally {
      definirPronto(true)
    }
  }

  const registrarFeedback = async (dados: FeedbackTCC) => {
    try {
      const alunoSelecionado = turma?.alunos?.find(
        x => x.avaliacao.id === dados.avaliacaoId
      )

      const professorOrientador = alunoSelecionado.professores.find(
        x => x.tipoProfessor === TipoProfessorModuloTCC.Orientador
      )

      const professorBanca = alunoSelecionado.professores.find(
        x => x.tipoProfessor === TipoProfessorModuloTCC.Banca
      )

      const professorOrientadorId = professorOrientador?.usuarioProfessorId
      const professorBancaId = professorBanca?.usuarioProfessorId
      const feedbackTCC = {
        ...dados,
        professorOrientadorId,
        professorBancaId
      }

      await Api.RegistrarFeedbackTCC(feedback.salaId, feedbackTCC)
      await carregarDisciplina()
      await tabelaRef.current.CarregarDados(true)
      toast('Feedback salvo com sucesso.', {
        type: 'success'
      })
    } catch (error) {
      toast('Houve um problema ao enviar o feedback', {
        type: 'error'
      })
    }
  }

  const alterarTituloTCC = async (idSalaAula: string, tituloTCC?: string) => {
    try {
      await Api.SalaAulaOutros.AlterarTituloTCC(idSalaAula, tituloTCC)
      await carregarDisciplina()
      await tabelaRef.current.CarregarDados(true)
      await tabelaRef.current.CarregarDados(true)
      toast('Título do TCC alterado com sucesso!', { type: 'success' })
    } catch (erro) {
      toast('Não foi possível alterar o(s) aluno(s).', { type: 'error' })
    }
  }

  const chamarModalPorContextoDeAvaliacao = (dados: NotaDisciplinaProps) => {
    possuiTurmaPresencialPorDisciplina()
      ? modalNotaDisciplinaRef?.current?.abrir(dados)
      : modalNotaModuloRef?.current?.abrir(dados)
  }

  const obterDados = async (): Promise<TabelaResposta | null> => {
    const alunosBase = turma && turma.alunos ? turma.alunos : []
    const dados = !situacaoFiltro
      ? alunosBase
      : alunosBase.filter(aluno => aluno.situacaoFinanceira === situacaoFiltro)

    return { Dados: dados } as TabelaResposta
  }

  // TO-DO: Falta implementação
  // eslint-disable-next-line
  const acaoSucessoFiltro = async dados => {}

  const filtroAlterado = async selecionado => {
    if (!selecionado) {
      await definirSituacaoFiltro(null)
    } else {
      const { id } = selecionado
      await definirSituacaoFiltro(id as SituacaoFinanceira)
    }
    await tabelaRef.current.CarregarDados(true)
  }
  const uploadArquivoAproveitamento = async (
    dados: ModalDocumentoAproveitamentoDados
  ) => {
    try {
      await Api.SalaAulaOutros.UploadAvaliacaoAproveitamentoTCC(
        dados.arquivo,
        dados.idSalaAula
      )
    } catch {
      toast('Erro ao enviar arquivo aproveitamento', { type: 'error' })
    }
  }

  useEffect(() => {
    carregarDisciplina()
  }, [])

  return pronto && !erro ? (
    <Container>
      <ContainerMain>
        <CabecalhoComEstilos>
          <ContainerLink>
            <Voltar
              onClick={() => {
                const rota = `/academico/turmas/notas-presenca/${idTurma}`

                history.push(rota)
              }}
            >
              {IconeBack} Voltar
            </Voltar>
          </ContainerLink>
          <Breadcrumb
            titulo="Cadastro de Notas e Presença na Turma"
            atalhos={[
              {
                texto: 'Acadêmico'
              },
              {
                texto: 'Notas e Presença'
              }
            ]}
          />
        </CabecalhoComEstilos>
        <Conteudo>
          <div id={Navegacoes.Alunos}>
            <SubTitulo texto="Alunos" />
            <FormUnform acaoSucesso={null} dadosIniciais={dadosIniciais}>
              <InputUnform name="turma" label="Turma" disabled={true} />
              <InputUnform name="modulo" label="Módulo - Disciplina" disabled />
              <InputUnform
                name="professor"
                label="Professor Responsável"
                disabled
              />
            </FormUnform>
            <>
              <Filtro iniciarAberto={visaoProfessor}>
                <FormStyled
                  acaoSucesso={acaoSucessoFiltro}
                  dadosIniciais={dadosIniciaisFiltro}
                >
                  <FormRow>
                    <SelectUnform
                      className="sm"
                      id="slct_situacao"
                      name="situacao"
                      label="Situação"
                      iconeFechar={true}
                      opcoes={opcoesFiltros}
                      valorAlterado={filtroAlterado}
                    ></SelectUnform>
                  </FormRow>
                </FormStyled>
              </Filtro>
              <TabelaComEstilos
                ref={tabelaRef}
                Colunas={[
                  ...COLUNAS_DE_NOTAS_E_PRESENCA_NA_TURMA,
                  ehPresencial && {
                    Chave: 'encontros',
                    StyleColuna: ehPresencial ? { width: 90 } : { width: 0 },
                    Alinhamento: 'center',
                    Renderizar: (linha: TabelaDados) => (
                      <ContainerIcones>
                        {linha.encontros.map(m =>
                          m.presenca === true ? (
                            <>
                              <ContainerIcone color={Cores.VERDE}>
                                {dataFormatadaComBarras(
                                  new Date(m.dataEncontro)
                                )}
                                {IconeCerto}
                              </ContainerIcone>
                            </>
                          ) : m.presenca === false ? (
                            <>
                              <ContainerIcone color={Cores.PERIGO_ERRO}>
                                {dataFormatadaComBarras(
                                  new Date(m.dataEncontro)
                                )}
                                {IconeErrado}
                              </ContainerIcone>
                            </>
                          ) : (
                            <>
                              <ContainerData>
                                {dataFormatadaComBarras(
                                  new Date(m.dataEncontro)
                                )}
                              </ContainerData>
                            </>
                          )
                        )}
                      </ContainerIcones>
                    ),
                    RenderizarCabecalho: () => (
                      <div style={{ width: 90 }}>Encontros Presenciais</div>
                    )
                  },
                  {
                    Chave: 'acoes',
                    Alinhamento: 'center',
                    Renderizar: (linha: TabelaDados) => (
                      <>
                        {linha.avaliacao.tipoAvaliacao ===
                          TipoDeAvaliacao.AvaliacaoPorAquivo &&
                        linha.avaliacao.id != null ? ( // eslint-disable-line
                          <ContainerAcoes>
                            {visaoIPGS && (
                              <button
                                type="button"
                                aria-label="Alterar Título TCC"
                                title="Alterar Título TCC"
                                onClick={() => {
                                  modalAlterarTituloTCCRef.current?.abrir(
                                    linha.idSalaAula,
                                    linha.tituloTCC
                                  )
                                }}
                              >
                                {IconeAlterar}
                              </button>
                            )}
                            {visaoIPGS && linha.aproveitada && (
                              <button
                                type="button"
                                aria-label="Documento de Aproveitamento"
                                title="Documento de Aproveitamento"
                                onClick={() => {
                                  modalDocumentoAproveitamentoRef.current?.abrir(
                                    linha.idSalaAula
                                  )
                                }}
                              >
                                {IconeUploadNuvem}
                              </button>
                            )}
                            <button
                              type="button"
                              aria-label="Baixar"
                              title="Baixar"
                              disabled={
                                linha.avaliacao
                                  ?.dataUltimaTentativaAvaliacao === null
                              }
                              onClick={async e => {
                                e.stopPropagation()
                                await Api.SalaAulaOutros.DownloadAvaliacaoTCCArquivoEnviado(
                                  linha.avaliacao.id
                                )
                              }}
                            >
                              {IconeBaixar}
                            </button>
                            <button
                              type="button"
                              aria-label="Editar"
                              title="Editar"
                              onClick={() => {
                                const dados = {
                                  avaliacaoId: linha.avaliacao.id,
                                  nome: linha.nomeAluno,
                                  disciplina: turma.nomeDisciplina,
                                  tentativas:
                                    linha.avaliacao.tentativasRestantes,
                                  encontros: linha.encontros,
                                  tipoAvaliacao: tipoAvaliacao,
                                  modalidade: modalidade,
                                  nota: linha.avaliacao.nota,
                                  professor: linha.professores
                                }
                                definirFeedback({
                                  avaliacaoId: linha.avaliacao.id,
                                  salaId: linha.idSalaAula
                                })
                                chamarModalPorContextoDeAvaliacao(dados)
                              }}
                              disabled={
                                visaParceiro ||
                                linha.avaliacao
                                  ?.dataUltimaTentativaAvaliacao === null ||
                                !linha.podeAvaliarTCC
                              }
                            >
                              {IconeEditar}
                            </button>
                          </ContainerAcoes> /* eslint-disable */
                        ) : (
                          <ContainerAcoes>
                            <button
                              type="button"
                              aria-label="Editar"
                              title="Editar"
                              onClick={() => {
                                const dados = {
                                  avaliacaoId: linha.avaliacao.id,
                                  nome: linha.nomeAluno,
                                  disciplina: turma.nomeDisciplina,
                                  tentativas:
                                    linha.avaliacao.tentativasRestantes,
                                  encontros: linha.encontros,
                                  tipoAvaliacao: linha.avaliacao.tipoAvaliacao,
                                  modalidade: modalidade,
                                  modulo: linha.nomeModulo,
                                  nota: linha.avaliacao.nota,
                                  professor: linha.professores
                                }
                                definirFeedback({
                                  avaliacaoId: linha.avaliacao.id,
                                  salaId: linha.idSalaAula
                                })

                                chamarModalPorContextoDeAvaliacao(dados)
                              }}
                              disabled={visaParceiro || !linha.podeAvaliarTCC}
                            >
                              {IconeEditar}
                            </button>
                          </ContainerAcoes>
                        )}
                      </> /* eslint-enable */
                    )
                  }
                ]}
                ObterDados={obterDados}
              />
            </>
          </div>
        </Conteudo>
        <ContainerBotao>
          <Botao
            type="button"
            texto="Voltar"
            tema="Secundario"
            onClick={() =>
              visaoProfessor ? history.push('/professor') : history.goBack()
            }
          />
        </ContainerBotao>
      </ContainerMain>
      <ContainerLateral>
        <Navegacao
          itens={[
            {
              link: Navegacoes.Alunos,
              nome: 'Alunos'
            }
          ]}
        />
      </ContainerLateral>
      <ModalNotaDisciplinaTCC
        ref={modalNotaDisciplinaRef}
        backdrop
        id="modal-nota-disciplina-tcc"
        acaoPrimaria={registrarFeedback}
        ehProfessor={visaoProfessor}
      />
      <ModalAlterarTituloTCC
        ref={modalAlterarTituloTCCRef}
        backdrop
        id="modal-alterar-titulo-tcc"
        acaoPrimaria={alterarTituloTCC}
      />
      <ModalDocumentoAproveitamentoTCC
        ref={modalDocumentoAproveitamentoRef}
        backdrop
        id="modal-documento-aproveitamento-tcc"
        acaoPrimaria={uploadArquivoAproveitamento}
      />
    </Container>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados do aluno..." />
  ) : (
    <ErroContainerLayout />
  )
}

const withContainer: FC<NotasPresencaProps> = (props: NotasPresencaProps) => (
  <ErroLayoutContainer.Provider>
    <PaginaVerAlunosPresencialTCC {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(withContainer)
