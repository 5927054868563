import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const ContainerAgenda = styled.div`
  width: 300px;
  flex-shrink: 0;

  & > div:last-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 3px;
    padding: 30px;
    background-color: ${Cores.CINZA_3_MAIS_CLARO};
    line-height: 18px;
    font-size: 12px;

    li {
      margin-bottom: 20px;
      list-style: none;
    }

    & > a,
    & > button {
      text-decoration: none;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: ${Cores.SECUNDARIA_2_ESCURO};
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
`
export const SubTituloContainer = styled.div`
  display: flex;
  margin-bottom: 9px;
`

export const SubTituloIcone = styled.div`
  display: flex;
  margin-right: 4px;
`

export const SubTitulo = styled.h3`
  font-size: 18px;
  font-weight: 300;
`
