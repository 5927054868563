import React, { useEffect, useRef, InputHTMLAttributes } from 'react'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

import { Erro } from './styles'

export const CheckboxInputUnform: React.FC<
  InputHTMLAttributes<HTMLInputElement>
> = ({ ...rest }) => {
  const inputRefs = useRef<HTMLInputElement>(null)
  const { fieldName, registerField, defaultValue, error } = useField(rest.name)

  useEffect(() => {
    registerField<boolean>({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (ref: HTMLInputElement) => ref.checked,
      clearValue: (ref: HTMLInputElement) => {
        ref.checked = false
      },
      setValue: (ref: HTMLInputElement, value: boolean) => {
        ref.checked = value
      }
    })
  }, [defaultValue, fieldName, registerField])

  return (
    <>
      <input
        {...rest}
        type="checkbox"
        id={rest.id}
        name={fieldName}
        defaultChecked={defaultValue}
        value={rest.id}
        ref={inputRefs}
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </>
  )
}

CheckboxInputUnform.propTypes = {
  name: PropTypes.string.isRequired
}
