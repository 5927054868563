import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo
} from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  SelectUnform,
  InputUnform,
  SelectOpcao,
  CheckboxUnform,
  Tooltip,
  IconeLixeira,
  NumeroInputUnform,
  MoedaInputUnform,
  formatarValorMoeda
} from 'src/componentes'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'
import { Api } from 'src/servicos'
import {
  FormaPagamentoCobranca,
  FormaPagamentoPorExtenso,
  PlanoPagamentoParcelasTransferencia,
  TipoTransferenciaAluno
} from 'src/tipos'

import { schemaTransferencia } from './schema'
import {
  ContainerAcoes,
  ContainerBotaoIcone,
  ContainerDados,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  ContainerInput,
  ContainerModal,
  CorpoModal,
  Tabela
} from './styles'
import {
  DadosModalTransferencia,
  ModalTransferenciaAlunoProps,
  ModalTransferenciaAlunoRef,
  TransferenciaAluno
} from './tipos'

const ModalTransferencia: React.ForwardRefRenderFunction<
  ModalTransferenciaAlunoRef,
  ModalTransferenciaAlunoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [dadosTurma, definirDadosTurma] = useState<DadosModalTransferencia>()
  const [
    tipoTransferencia,
    definirTipoTransferencia
  ] = useState<TipoTransferenciaAluno>(
    TipoTransferenciaAluno.TransferenciaTurma
  )

  const [opcoesTurmas, definirOpcoesTurmas] = useState<SelectOpcao[]>()
  const [
    planoPagamentoEspecifico,
    definirPlanoPagamentoEspecifico
  ] = useState<boolean>(false)

  const [taxaIsenta, definirTaxaIsenta] = useState<boolean>(false)

  const [parcelas, definirParcelas] = useState<
    PlanoPagamentoParcelasTransferencia[]
  >([])
  const [
    formaPagamentoEdicao,
    definirFormaPagamentoEdicao
  ] = useState<FormaPagamentoCobranca>(null)
  const [valorParcela, setValorParcela] = useState('')
  const [quantidade, setQuantidadeParcelas] = useState('')

  const dados = useMemo(() => {
    return {
      matriculaId: dadosTurma?.matriculaId,
      tipoTransferencia: tipoTransferencia
    }
  }, [dadosTurma, tipoTransferencia])

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
    definirPlanoPagamentoEspecifico(false)
    definirFormaPagamentoEdicao(null)
    setValorParcela('')
    setQuantidadeParcelas('')
    definirParcelas([])
  }

  const abrir = (dados: DadosModalTransferencia) => {
    definirDadosTurma(dados)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalTransferenciaAlunoRef, ModalTransferenciaAlunoRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoForm = async (dados: TransferenciaAluno) => {
    try {
      if (dados.addPlanoPagamento && (!parcelas || parcelas.length <= 0)) {
        toast('Ao menos um plano de pagamento deve ser cadastrado', {
          type: 'error'
        })
        return
      }

      definirCarregando(true)
      const dadosTransferencia = {
        ...dados,
        addPlanoPagamento: dados.addPlanoPagamento,
        planosPagamento:
          dados.addPlanoPagamento && parcelas?.length > 0 ? parcelas : null,
        matriculaId: dadosTurma?.matriculaId
      }

      await acaoPrimaria(dadosTransferencia)
      fechar()
    } finally {
      definirCarregando(false)
    }
  }

  const requisitarListaTurmasDisponiveisTransferencia = async () => {
    try {
      const turmas = await Api.RequisitarListaTurmasDisponiveisTransferencia(
        dados
      )

      definirOpcoesTurmas(turmas)
    } catch {
      toast('Erro ao carregar turmas de destino', { type: 'error' })
    }
  }

  const excluirParcela = async index => {
    definirParcelas(parcelas.filter((parcela, i) => i !== index))
  }

  const valorFormatado = useMemo(() => formatarValorMoeda(valorParcela), [
    valorParcela
  ])

  const adicionarParcela = async () => {
    if (!formaPagamentoEdicao || !quantidade || !valorFormatado) {
      toast(
        'Informe os dados: Forma de Pagamento, Valor da Parcela e Quantidade',
        {
          type: 'info'
        }
      )

      return
    }

    try {
      definirParcelas([
        ...parcelas,
        {
          quantidadeParcelas: Number(quantidade),
          valorParcela: Number(valorFormatado),
          formaPagamento: formaPagamentoEdicao
        }
      ])
    } catch (ex) {
      return []
    }
  }

  const setFormaPagamentoEdicao = async selecionado => {
    if (!selecionado) {
      definirFormaPagamentoEdicao(null)
    } else {
      const { id } = selecionado
      definirFormaPagamentoEdicao(FormaPagamentoCobranca[id])
    }
  }

  useEffect(() => {
    if (dados?.matriculaId) {
      requisitarListaTurmasDisponiveisTransferencia()
    }
  }, [dados])

  return (
    <ModalBase
      id="modal-transferencia-aluno"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          <h5>Transferência de Aluno</h5>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaTransferencia}
              ref={formRef}
            >
              <ContainerInput>
                <SelectUnform
                  id="slct_tipo_transferencia"
                  name="tipoTransferencia"
                  label="Selecione a opção"
                  opcoes={[
                    {
                      id:
                        TipoTransferenciaAluno[
                          TipoTransferenciaAluno.TransferenciaTurma
                        ],
                      texto: 'Transferência de Turma'
                    },
                    {
                      id:
                        TipoTransferenciaAluno[
                          TipoTransferenciaAluno.TrocaCurso
                        ],
                      texto: 'Troca de Curso'
                    }
                  ]}
                  valorAlterado={valor => {
                    definirTipoTransferencia(TipoTransferenciaAluno[valor?.id])
                  }}
                  obrigatorio
                />
              </ContainerInput>
              <ContainerInput>
                <InputUnform
                  id="turmaOrigem"
                  name="turmaOrigem"
                  label="Turma de origem"
                  value={dadosTurma.codigoTurma}
                  disabled
                  obrigatorio
                />
              </ContainerInput>
              <ContainerInput>
                <SelectUnform
                  id="slct_turma_destino"
                  name="turmaDestinoId"
                  label="Turma de destino"
                  opcoes={opcoesTurmas}
                  obrigatorio
                />
              </ContainerInput>
              {tipoTransferencia === TipoTransferenciaAluno.TrocaCurso && (
                <>
                  <CheckboxUnform
                    id="chk_taxa_isenta"
                    name="taxaIsenta"
                    texto={'Taxa troca de curso isenta'}
                    checked={taxaIsenta}
                    onChange={() => {
                      definirTaxaIsenta(old => !old)
                    }}
                  />
                  <CheckboxUnform
                    id="chk_plano_pagamento"
                    name="addPlanoPagamento"
                    texto={'Plano de Pagamento específico'}
                    checked={planoPagamentoEspecifico}
                    onChange={() => {
                      definirPlanoPagamentoEspecifico(old => !old)
                    }}
                  />
                  {planoPagamentoEspecifico && (
                    <>
                      <ContainerDados style={{ marginTop: '27px' }}>
                        <ContainerDadosMedio>
                          <SelectUnform
                            id="slct_formPagamentoParcela"
                            name="formPagamentoParcela"
                            label="Forma de Pagamento"
                            placeholder="Selecione"
                            valorAlterado={setFormaPagamentoEdicao}
                            opcoes={[
                              {
                                id:
                                  FormaPagamentoCobranca[
                                    FormaPagamentoCobranca.Boleto
                                  ],
                                texto: 'Boleto'
                              },
                              {
                                id:
                                  FormaPagamentoCobranca[
                                    FormaPagamentoCobranca.CartaoCredito
                                  ],
                                texto: 'Cartão de crédito'
                              }
                            ]}
                          />
                        </ContainerDadosMedio>
                        <ContainerDadosPequeno>
                          <MoedaInputUnform
                            name="valorParcela"
                            label="Valor da parcela"
                            onChange={e => setValorParcela(e.target.value)}
                          />
                        </ContainerDadosPequeno>
                        <ContainerDadosPequeno>
                          <NumeroInputUnform
                            name="quantidade"
                            max={9999}
                            maxLength={4}
                            label="Quantidade de parcelas"
                            onChange={e =>
                              setQuantidadeParcelas(e.target.value)
                            }
                          />
                          <Botao
                            texto="Adicionar parcelamento"
                            tema="Secundario"
                            type="button"
                            style={{ margin: '0 0 0 auto' }}
                            onClick={() => adicionarParcela()}
                          />
                        </ContainerDadosPequeno>
                      </ContainerDados>
                      <Tabela>
                        <thead>
                          <tr>
                            <th>Parcelas</th>
                            <th>Valor</th>
                            <th>Forma de Pagamento</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {parcelas?.map((parcela, index) => (
                            <tr key={index}>
                              <td align="center">
                                {parcela.quantidadeParcelas}
                              </td>
                              <td align="center">R$ {parcela.valorParcela}</td>
                              <td align="center">
                                {
                                  FormaPagamentoPorExtenso[
                                    parcela.formaPagamento
                                  ]
                                }{' '}
                              </td>
                              <td align="center">
                                <ContainerBotaoIcone>
                                  <button
                                    type="button"
                                    data-tip="Excluir parcela"
                                    data-for={'tooltip-lixeira-parcela'}
                                    onClick={() => excluirParcela(index)}
                                  >
                                    {IconeLixeira}
                                  </button>
                                  <Tooltip
                                    id={'tooltip-lixeira-parcela'}
                                    place="bottom"
                                    offset={{ top: 10 }}
                                  />
                                </ContainerBotaoIcone>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Tabela>
                    </>
                  )}

                  <ContainerInput>
                    <ArquivoInputUnform
                      label="Termo aditivo"
                      name="termo"
                      obrigatorio
                      tiposAceitos={[
                        '.pdf',
                        '.doc',
                        '.docx',
                        '.xls',
                        '.xlsx',
                        '.csv',
                        '.htm',
                        '.html'
                      ]}
                    />
                  </ContainerInput>
                </>
              )}
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Adicionar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalTransferenciaAluno = forwardRef(ModalTransferencia)
