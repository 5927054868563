import styled from 'styled-components'

import { Cores } from '../styles'

export const TituloAccordion = styled.div<{ inativo?: boolean }>`
  background-color: ${({ inativo }) =>
    inativo ? Cores.SECUNDARIA_1_CLARA_ESCURA_50 : Cores.SECUNDARIA_1_ESCURO};
  min-height: 50px;
  width: 100%;
  display: flex;
  border-radius: 5px;
`

export const Titulo = styled.span`
  flex: 1;
  color: ${Cores.BRANCO};
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
`

export const BotaoExpandir = styled.button`
  justify-self: flex-end;
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding: 10px 20px;

  & svg path {
    stroke: ${Cores.BRANCO};
  }
`

export const Linha = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 20px;
  width: 100%;
`

export const ContainerTitulo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ConteudoItem = styled.div`
  border-bottom: 0.25px solid ${Cores.CINZA_1_CLARO};
  width: 100%;
  padding: 30px 17px;
`

export const Conteudo = styled.div<{ expandido: boolean }>`
  width: 100%;
  border-left: 1px solid ${Cores.SECUNDARIA_1_ESCURO};
  border-right: 1px solid ${Cores.SECUNDARIA_1_ESCURO};
  border-bottom: 1px solid ${Cores.SECUNDARIA_1_ESCURO};
  opacity: ${({ expandido }) => (expandido ? 1 : 0)};
  height: ${({ expandido }) => (expandido ? 'auto' : 0)};
  display: ${({ expandido }) => (expandido ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`
