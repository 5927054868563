import React from 'react'

import { Container } from './styles'
import { SpacerProps } from './tipos'

export const Spacer: React.FC<SpacerProps> = ({
  padding,
  children,
  className
}) => {
  return (
    <Container className={className} padding={padding}>
      {children}
    </Container>
  )
}
