import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  Botao,
  Checkbox,
  IconeFechar,
  ModalBaseProps,
  Spacer
} from 'src/componentes'
import { ModalBase } from 'src/componentes/modal-base'

import {
  BotaoFecharModal,
  ContainerBotaoFecharModal,
  ContainerBotoesModalTrocaModulo,
  ContainerModalTrocaModulo,
  Corpo,
  ModulosFixos
} from '../../style'
import { ModalTrocaModuloRef } from './tipos'

const ModalTroca: React.ForwardRefRenderFunction<
  ModalTrocaModuloRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [moduloEscolhido, definirModuloEscolhido] = useState(undefined)
  const [moduloAntigo, definirModuloAntigo] = useState(undefined)
  const [aceitouTermos, definirAceitouTermos] = useState(false)

  const fechar = () => {
    definirAberta(false)
    definirAceitouTermos(false)
  }

  const abrir = (moduloEscolhido: any, moduloAntigo: any) => {
    definirModuloEscolhido(moduloEscolhido)
    definirModuloAntigo(moduloAntigo)
    definirAberta(true)
  }

  useImperativeHandle<ModalTrocaModuloRef, ModalTrocaModuloRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = async () => {
    acaoPrimaria(aceitouTermos)
    fechar()
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <ContainerBotaoFecharModal>
          <BotaoFecharModal
            onClick={() => {
              fechar()
            }}
          >
            {IconeFechar}
          </BotaoFecharModal>
        </ContainerBotaoFecharModal>
        <h5>Troca de Módulo</h5>
        <Spacer padding="10px 0px" />
        <p>
          Você está trocando o Módulo {moduloAntigo?.nome} pelo Módulo{' '}
          {moduloEscolhido?.nome}.
        </p>
        <ContainerModalTrocaModulo>
          <ModulosFixos>{moduloAntigo?.nome}</ModulosFixos>
          <span>⇄</span>
          <ModulosFixos>{moduloEscolhido?.nome}</ModulosFixos>
        </ContainerModalTrocaModulo>
        <Checkbox
          id={'chk_termos_recup'}
          texto="Estou ciente que a troca não poderá ser realizada novamente."
          htmlFor={'chk_aceitou_termos'}
          tamanho="M"
        >
          <input
            type="checkbox"
            id={'chk_aceitou_termos'}
            checked={aceitouTermos}
            onChange={() => definirAceitouTermos(!aceitouTermos)}
          />
        </Checkbox>
        <Spacer padding="20px 0px" />
        <ContainerBotoesModalTrocaModulo>
          <Botao
            type="button"
            texto="Cancelar"
            tema="Secundario"
            onClick={() => {
              fechar()
            }}
          />
          <Botao
            style={{ color: 'white' }}
            className="btn-salvar"
            type="button"
            texto="Confirmar"
            disabled={!aceitouTermos}
            onClick={acaoSucesso}
          />
        </ContainerBotoesModalTrocaModulo>
      </Corpo>
    </ModalBase>
  )
}
export const ModalTrocaDeModulo = forwardRef(ModalTroca)
