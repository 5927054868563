import styled from 'styled-components'

export const ContainerConteudo = styled.div`
  margin-top: 13px;
  margin-left: 31px;
  width: 100%;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;

  h2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 23px;
  }

  h3 {
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;

    margin-bottom: 5px;
  }

  h4 {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
  }
`

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerInfosMenor = styled.div`
  width: 40%;

  h4 {
    margin-top: 10px;
  }
`

export const ColunaDisciplinas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
  }

  .titulo-modulo {
    font-weight: 500;
  }
`
export const ContainerInfosValores = styled.div`
  margin-top: 10px;
  gap: 3px;
`

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
  }
`
export const Espacador = styled.div`
  margin-bottom: 20px;
`
