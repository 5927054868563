import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    align-self: flex-start;
    margin-bottom: 30px;

    & > svg {
      path {
        fill: ${Cores.PRIMARIA_1};
      }

      .comStroke {
        stroke: ${Cores.PRIMARIA_1};
      }
    }
  }
`

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 66.6667%;

  & > p {
    font-size: 15px;
    color: ${Cores.CINZA_2};
  }
`

export const ContainerTabela = styled.div`
  margin-top: 15px;
  padding: 0px 40px;
  width: 100%;

  & > table {
    width: 100%;
    font-size: 17px;

    &,
    th,
    td {
      border: 1px solid ${Cores.PRETO};
      border-collapse: collapse;
    }

    td {
      width: 50%;
      padding: 10px;
    }
  }
`
