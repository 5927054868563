import styled from 'styled-components'

export const Form = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 39px 39px 39px;
  width: 400px;

  h1 {
    margin-bottom: 30px;
  }

  form {
    width: 100%;
  }

  button[type='submit'] {
    width: 100%;
    margin-top: 40px;
  }
`

export const IconeBotao = styled.div`
  position: absolute;
  right: 60px;
`

export const ResumoUsuario = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 37px;

  & > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }

  & > h2 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;

    & > span {
      font-weight: 500;
    }
  }
`
