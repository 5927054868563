export interface RespostaPaginacao<T> {
  itens: Array<T>
  temProximaPagina: boolean
  quantidade: number
}

export enum TipoDocumentoAluno {
  RegistroCivil = 'RegistroCivil',
  Rg = 'Rg',
  RgVerso = 'RgVerso',
  Rne = 'Rne',
  Cpf = 'Cpf',
  DiplomaGraduacao = 'DiplomaGraduacao',
  DiplomaGraduacaoVerso = 'DiplomaGraduacaoVerso',
  HistoricoEscolar = 'HistoricoEscolar',
  CertidaoNascimento = 'CertidaoNascimento'
}

export enum SituacaoUpload {
  PendenteEnvio = 'PendenteEnvio',
  AguardandoAnalise = 'AguardandoAnalise',
  Aprovado = 'Aprovado',
  Recusado = 'Recusado'
}

export interface ResumoDocumentos {
  documentoId: string
  nomeArquivo: string
  tipo:
    | TipoDocumentoAluno
    | TipoDocumentoProfessor
    | TipoDocumentoResponsavelFinanceiro
  situacaoUpload: SituacaoUpload
  motivoRejeicao: string
  dataPostagem: string
  dataConferencia?: string
  dataNascimento?: string
}

export interface Documento {
  contentType: string
  base64: string
}

export interface DocumentosParaAprovacao {
  documentos: DocumentoParaAprovacao[]
}

export interface DocumentoParaAprovacao {
  documentoId: string
  situacao: SituacaoUpload
  motivoRejeicao: string
}

export interface ResultadoDaAprovacaoDeDocumentos {
  sucesso: boolean
}

export enum TipoDocumentoProfessor {
  RgCnh = 'RgCnh',
  RgCnhVerso = 'RgCnhVerso',
  DiplomaGraduacao = 'DiplomaGraduacao',
  DiplomaGraduacaoVerso = 'DiplomaGraduacaoVerso',
  DiplomaTitulacaoMaxima = 'DiplomaTitulacaoMaxima',
  DiplomaTitulacaoMaximaVerso = 'DiplomaTitulacaoMaximaVerso',
  Rne = 'Rne'
}

export interface ContratoResponsavelFinanceiro {
  usuarioId: string
  arquivo: File | string
}

export enum TipoDocumentoResponsavelFinanceiro {
  Identificacao = 'Identificacao'
}

export interface DataNascimentoDocumento {
  documentoId: string
  dataNascimento: string
}
