import { UtilizacaoCupom, UtilizacaoCupomPorExtenso } from 'src/tipos'

export const SELECT_UTILIZACOES_CUPOM = [
  {
    id: UtilizacaoCupom[UtilizacaoCupom.TaxaMatricula],
    texto: UtilizacaoCupomPorExtenso[UtilizacaoCupom.TaxaMatricula]
  },
  {
    id: UtilizacaoCupom[UtilizacaoCupom.PlanoPagamento],
    texto: UtilizacaoCupomPorExtenso[UtilizacaoCupom.PlanoPagamento]
  },
  {
    id: UtilizacaoCupom[UtilizacaoCupom.TCC],
    texto: UtilizacaoCupomPorExtenso[UtilizacaoCupom.TCC]
  },
  {
    id: UtilizacaoCupom[UtilizacaoCupom.TaxaTrocaCurso],
    texto: UtilizacaoCupomPorExtenso[UtilizacaoCupom.TaxaTrocaCurso]
  }
]
