import React, { useCallback, useEffect, useState } from 'react'
import { useSpring } from 'react-spring'

import { Botao, Carregando, IconeAtencao } from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Api } from 'src/servicos'
import { GrupoDiscussaoTCCAluno as GrupoDiscussaoType } from 'src/tipos/grupo-discussao'

import SalaAulaOutrosContainer from '../container'
import { Container, Conteudo, AnimatedDiv, ContainerTela } from './styles'
import { TopicoTCC } from './topico'

interface GrupoDiscussaoTCC {
  orientacaoEmAndamento: boolean
}

export const GrupoDiscussaoTCCComponente: React.FC<GrupoDiscussaoTCC> = ({
  orientacaoEmAndamento
}) => {
  const [grupoDiscussao, definirGrupoDiscussao] = useState<GrupoDiscussaoType>(
    {} as GrupoDiscussaoType
  )
  const [topicoSelecionado, definirTopicoSelecionado] = useState<string>(null)
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)

  const { salaAula } = SalaAulaOutrosContainer.useContainer()
  const { transform, opacity } = useSpring({
    opacity: topicoSelecionado ? 1 : 0,
    transform: `translate(${topicoSelecionado ? -180 : 0}px, 0px)`,
    config: { mass: 1, tension: 900, friction: 100 }
  })

  const carregarGrupoDiscussao = useCallback(async () => {
    try {
      definirPronto(false)
      definirErro(undefined)
      definirGrupoDiscussao({} as GrupoDiscussaoType)

      const resposta = await Api.RequisitarGrupoDiscussaoTCC(
        salaAula.turmaId,
        salaAula.id
      )

      definirGrupoDiscussao(resposta)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar o Fórum de dúvidas')
    } finally {
      definirPronto(true)
    }
  }, [salaAula.turmaId])

  useEffect(() => {
    carregarGrupoDiscussao()
  }, [carregarGrupoDiscussao])

  useEffect(() => {
    definirErro(undefined)
    if (grupoDiscussao?.topico) {
      definirTopicoSelecionado(grupoDiscussao.topico.topicoId)
    }
  }, [grupoDiscussao])

  return (
    <>
      {pronto && !erro ? (
        <Container>
          {topicoSelecionado && (
            <AnimatedDiv
              id="grupo-discussao-topico"
              style={{
                opacity,
                transform: transform.interpolate(
                  t => `${t} translate(180px, 0px)`
                )
              }}
            >
              <TopicoTCC
                idTopico={topicoSelecionado}
                idGrupoDiscussao={grupoDiscussao?.grupoDiscussaoId}
                orientacaoEmAndamento={orientacaoEmAndamento}
                onClick={() => definirTopicoSelecionado(null)}
              />
            </AnimatedDiv>
          )}
        </Container>
      ) : !pronto && !erro ? (
        <Conteudo>
          <Carregando texto="Carregando Fórum de dúvidas ..." />
        </Conteudo>
      ) : (
        <Conteudo>
          <ContainerErroLayout>
            <div>
              {IconeAtencao}
              <p>{erro}</p>
              <div>
                <Botao
                  tema="Padrao"
                  texto="Tentar novamente"
                  onClick={carregarGrupoDiscussao}
                  tamanho="S"
                />
              </div>
            </div>
          </ContainerErroLayout>
        </Conteudo>
      )}
    </>
  )
}

export const GrupoDiscussaoTCC: React.FC<GrupoDiscussaoTCC> = ({
  orientacaoEmAndamento
}) => {
  return (
    <ContainerTela>
      <GrupoDiscussaoTCCComponente
        orientacaoEmAndamento={orientacaoEmAndamento}
      />
    </ContainerTela>
  )
}
