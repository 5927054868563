import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  Botao,
  IconeFechar,
  IconeInformacao,
  IconeSetaDireita,
  ModalBaseProps,
  Spacer
} from 'src/componentes'
import { ModalBase } from 'src/componentes/modal-base'

import {
  BotaoFecharModal,
  ContainerBotaoFecharModal,
  ContainerBotoes,
  ContainerBotoesOrdenacao,
  ContainerListaModulos,
  Corpo,
  InformacaoVerMaisAviso,
  ListaOrdenacaoModulos
} from '../../style'
import { ModalOrdenarModulosRef } from './tipos'

const ModalOrdenarModulos: React.ForwardRefRenderFunction<
  ModalOrdenarModulosRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [modulosEscolhidos, definirModulosEscolhidos] = useState([])

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = (item: any) => {
    const itemComOrdem = item.map((item, index) => {
      return {
        ...item,
        ordem: index
      }
    })
    definirModulosEscolhidos(itemComOrdem)
    definirAberta(true)
  }

  useImperativeHandle<ModalOrdenarModulosRef, ModalOrdenarModulosRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucesso = async () => {
    acaoPrimaria(modulosEscolhidos)
    fechar()
  }

  const subirOrdem = (item: any) => {
    if (item.ordem !== 0) {
      const modulosReordenados = modulosEscolhidos.map(modulo => {
        if (modulo?.ordem === item?.ordem - 1) {
          return { ...modulo, ordem: modulo.ordem + 1 }
        } else if (modulo?.ordem === item?.ordem) {
          return { ...modulo, ordem: modulo.ordem - 1 }
        }
        return modulo
      })

      modulosReordenados.sort(function (a, b) {
        return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0
      })

      definirModulosEscolhidos(modulosReordenados)
    }
  }

  const descerOrdem = (item: any) => {
    if (item.ordem !== modulosEscolhidos.length - 1) {
      const modulosReordenados = modulosEscolhidos.map(modulo => {
        if (modulo?.ordem === item?.ordem + 1) {
          return { ...modulo, ordem: modulo.ordem - 1 }
        } else if (modulo?.ordem === item?.ordem) {
          return { ...modulo, ordem: modulo.ordem + 1 }
        }
        return modulo
      })

      modulosReordenados.sort(function (a, b) {
        return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0
      })

      definirModulosEscolhidos(modulosReordenados)
    }
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <ContainerBotaoFecharModal>
          <BotaoFecharModal
            onClick={() => {
              fechar()
            }}
          >
            {IconeFechar}
          </BotaoFecharModal>
        </ContainerBotaoFecharModal>
        <h5>Ordenação dos Módulos</h5>
        <Spacer padding="2px 0px" />
        <InformacaoVerMaisAviso>
          <span>{IconeInformacao}</span>
          <p>Os Módulos Flexíveis abaixo foram selecionados.</p>
        </InformacaoVerMaisAviso>

        <InformacaoVerMaisAviso>
          <span>{IconeInformacao}</span>
          <p>Você pode ordená-los conforme a preferência que deseja cursar.</p>
        </InformacaoVerMaisAviso>

        <Spacer padding="10px 0px" />
        <ContainerListaModulos>
          {modulosEscolhidos?.map((item, index) => (
            <ListaOrdenacaoModulos key={index}>
              <p>{item?.nome}</p>
              <ContainerBotoesOrdenacao>
                <span
                  style={
                    item?.ordem === 0
                      ? { cursor: 'not-allowed' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => subirOrdem(item)}
                >
                  {IconeSetaDireita}
                </span>
                <span
                  style={
                    item.ordem === modulosEscolhidos.length - 1
                      ? { cursor: 'not-allowed' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => descerOrdem(item)}
                >
                  {IconeSetaDireita}
                </span>
              </ContainerBotoesOrdenacao>
            </ListaOrdenacaoModulos>
          ))}
        </ContainerListaModulos>

        <ContainerBotoes>
          <Botao
            type="button"
            id="btn-cancelar"
            texto="Cancelar"
            tema="Secundario"
            onClick={() => fechar()}
          />
          <Botao
            type="submit"
            onClick={acaoSucesso}
            id="btn-salvar"
            texto="Salvar"
          />
        </ContainerBotoes>
      </Corpo>
    </ModalBase>
  )
}
export const ModalOrdenarModulosFlex = forwardRef(ModalOrdenarModulos)
