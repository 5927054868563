import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaNota = (): TabelaColuna => ({
  Chave: 'nota',
  Texto: 'Nota',
  StyleColuna: { width: 70, paddingInline: 10 },
  Renderizar: (linha: TabelaDados) => <>{linha.nota}</>
})
