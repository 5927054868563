import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { Scope } from '@unform/core'
import {
  Botao,
  Botao as FormBotao,
  ContainerAcoes,
  FormRef,
  FormRow,
  FormUnform,
  IconeAdicionar,
  IconeLixeira,
  ModalBase,
  TextAreaUnform,
  ConteudoBotao,
  CheckboxUnform,
  Input
} from 'src/componentes'
import { focarPrimeiroCampoComErro } from 'src/componentes/funcoes'
import { Pergunta, Resposta } from 'src/dto'
import { v4 } from 'uuid'
import * as Yup from 'yup'

import {
  ContainerBotoes,
  ModalCorpo,
  SubtituloModal,
  ContainerRespostas,
  ContainerErros,
  ContainerAnular,
  ContainerExplicacao
} from '../styles'
import { ModalIncluirPerguntaRef, ModalIncluirPerguntaProps } from '../tipos'
import { schema } from './schema'

const Componente: ForwardRefRenderFunction<
  ModalIncluirPerguntaRef,
  ModalIncluirPerguntaProps
> = ({ backdrop, acaoPrimaria, aberta, turma }, ref) => {
  const formRef = useRef<FormRef>()

  const [aberto, definirAberto] = useState(aberta)
  const [pergunta, definirPergunta] = useState<Pergunta>(null)
  const [respostas, definirRespostas] = useState<Resposta[]>([])
  const [referencia, definirReferencia] = useState<string>(null)
  const [erros, definirErros] = useState<string[]>([])
  const [modoEdicao, definirModoEdicao] = useState<boolean>(false)
  const [anular, definirAnular] = useState<boolean>(false)

  const mensagemAnularPadrao =
    'Esta questão foi anulada. A pontuação referente a esta questão foi adicionada a sua nota final'
  const dadosInicias = useMemo(() => {
    return {
      id: pergunta?.id,
      pergunta: pergunta?.pergunta,
      observacao: pergunta?.observacao,
      anular: pergunta?.anular,
      explicacao: pergunta?.explicacao
    }
  }, [aberta, pergunta])

  const limparErros = () => definirErros([])

  const adicionarResposta = () => {
    limparErros()
    definirRespostas(oldRespostas => {
      const novaLista = [
        ...oldRespostas,
        { id: v4(), texto: '', correta: false } as Resposta
      ]
      return novaLista
    })
  }

  const removerResposta = (index: number) => {
    definirRespostas(oldRespostas => {
      const novasRespostas = oldRespostas.filter((_, key) => key !== index)
      formRef?.current?.clearField(`respostas[${index}]`)

      novasRespostas.forEach((value, index) => {
        formRef?.current?.setFieldValue(
          `respostas[${index}].texto`,
          value.texto
        )

        formRef?.current?.setFieldValue(
          `respostas[${index}].correta`,
          value.correta
        )
      })

      return novasRespostas
    })
  }

  const alterarRespostaCorreta = (index: number) => {
    definirRespostas(oldRespostas => {
      const novasRespostas = oldRespostas.map((value, i) => {
        const correta = i === index ? !value.correta : false

        formRef?.current?.setFieldValue(`respostas[${i}].correta`, correta)

        return {
          id: value.id,
          texto: value.texto,
          correta
        }
      })

      return novasRespostas
    })
  }

  const abrir = (perguntaAtual: Pergunta, referencia?: string) => {
    definirModoEdicao(!!perguntaAtual)
    definirPergunta(perguntaAtual)
    definirRespostas([...(perguntaAtual?.respostas ?? ([] as Resposta[]))])
    definirReferencia(referencia)
    definirAberto(true)
  }
  const fechar = () => {
    formRef.current?.reset()
    definirPergunta(null)
    definirRespostas([])
    definirReferencia(null)
    definirAberto(false)
    definirModoEdicao(false)
    definirAnular(false)
  }

  const acaoSucesso = async (dados: any) => {
    const retorno = {
      ...dados,
      id: pergunta?.id,
      respostas: respostas
    } as Pergunta

    try {
      formRef.current.setErrors({})

      await schema.validate(retorno, {
        abortEarly: false
      })

      acaoPrimaria(retorno, referencia)
      fechar()
    } catch (err) {
      const validationErrors = {} as { respostas: string }

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: Yup.ValidationError) => {
          validationErrors[error.path] = error.message
        })

        formRef.current.setErrors(validationErrors)

        limparErros()
        if (validationErrors.respostas?.length > 0) {
          const mensagens = [validationErrors.respostas]
          definirErros(mensagens)
        }
        focarPrimeiroCampoComErro()
      }
    }
  }

  useImperativeHandle<ModalIncluirPerguntaRef, ModalIncluirPerguntaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const renderizarErros = () => {
    return erros?.map(e => <p key={e}>{e}</p>)
  }

  return (
    <ModalBase aberta={aberto} backdrop={backdrop} semLimiteAltura>
      <ModalCorpo id="corpo-modal-incluir-perguntas">
        <SubtituloModal>Incluir Pergunta</SubtituloModal>
        {aberto && (
          <FormUnform
            acaoSucesso={acaoSucesso}
            dadosIniciais={dadosInicias}
            ref={formRef}
          >
            <TextAreaUnform
              label="Pergunta"
              maxLength={4000}
              rows={4}
              name="pergunta"
              obrigatorio
            />
            <ContainerErros>{renderizarErros()}</ContainerErros>
            <ContainerRespostas>
              {respostas.map((value, key) => {
                return (
                  <FormRow key={value.id}>
                    <Scope path={`respostas[${key}]`}>
                      <Input
                        className="lg"
                        name="texto"
                        type="text"
                        obrigatorio
                        label={'Resposta'}
                        defaultValue={value.texto}
                        onBlur={e => {
                          limparErros()
                          definirRespostas(oldRespostas => {
                            const novasRespostas = oldRespostas
                            const respostaIndex = novasRespostas[key]
                            const novaResposta = {
                              id: respostaIndex.id,
                              correta: false,
                              texto: e.target.value
                            }
                            novasRespostas.splice(key, 1, novaResposta)

                            return novasRespostas
                          })
                        }}
                      />
                      <CheckboxUnform
                        id={`respostas[${key}]-correta`}
                        name={'correta'}
                        texto={'Correta'}
                        checked={value.correta}
                        onChange={() => {
                          limparErros()
                          alterarRespostaCorreta(key)
                        }}
                      />
                    </Scope>
                    <ContainerAcoes>
                      <button
                        type="button"
                        data-tip="Excluir"
                        onClick={() => removerResposta(key)}
                      >
                        {IconeLixeira}
                      </button>
                    </ContainerAcoes>
                  </FormRow>
                )
              })}
            </ContainerRespostas>
            {respostas.length < 5 && (
              <Botao
                type="button"
                onClick={adicionarResposta}
                disabled={respostas.length >= 5}
                tema="Link"
                elemento={
                  <ConteudoBotao>
                    {IconeAdicionar} Incluir Resposta
                  </ConteudoBotao>
                }
              />
            )}
            <ContainerExplicacao>
              <TextAreaUnform
                id="inp_explicacao"
                className="lg"
                name="explicacao"
                label="Explicação"
                maxLength={4000}
                tooltipLabel="Informe a explicação do porquê a alternativa correta é a resposta certa. Este texto será exibido no gabarito do aluno."
                tooltipModal={{
                  id: 'corpo-modal-incluir-perguntas',
                  fixo: true
                }}
              />
            </ContainerExplicacao>

            {turma && modoEdicao && (
              <ContainerAnular>
                <CheckboxUnform
                  id={'anular-pergunta'}
                  texto={'Anular envios anteriores'}
                  name={'anular'}
                  onChange={() => {
                    definirAnular(old => {
                      const anulada = !old
                      const mensagem = anulada ? mensagemAnularPadrao : ''
                      formRef?.current?.setFieldValue('observacao', mensagem)
                      return anulada
                    })
                  }}
                />
                <TextAreaUnform
                  label={'Observação'}
                  name={'observacao'}
                  maxLength={4000}
                  rows={4}
                  disabled={!anular}
                />
              </ContainerAnular>
            )}

            <ContainerBotoes>
              <FormBotao
                type="button"
                texto="Cancelar"
                tema="Secundario"
                onClick={fechar}
              />
              <FormBotao type="submit" texto="Salvar" className="btn-salvar" />
            </ContainerBotoes>
          </FormUnform>
        )}
      </ModalCorpo>
    </ModalBase>
  )
}

export const ModalIncluirPergunta = forwardRef(Componente)
