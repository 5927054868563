import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import jwtDecode from 'jwt-decode'
import { Api } from 'src/servicos'
import { UsuarioPerfil } from 'src/tipos'
import { createContainer } from 'unstated-next'

import { useBarraSuperior } from '../barra-superior-layout'
import { recuperarVisaoPorPerfis } from './funcoes'
import { AuthContainer, Perfil, Visao, Usuario } from './tipos'

const chaveUsuario = '@ipgs/usuario'
const chavePerfil = '@ipgs/perfil'
const chaveUrlRedirecionamento = '@ipgs/urlRedirecionamento'

const useAuth = () => {
  const history = useHistory()
  const semRedirecionamento = useRef(false)
  const barraSuperior = useBarraSuperior()

  const recuperarItemDoArmazenamentoLocal = (chave: string) => {
    try {
      const valor = localStorage.getItem(chave)
      return valor ? JSON.parse(valor) : null
    } catch {
      return null
    }
  }

  const recuperarUsuario = () => recuperarItemDoArmazenamentoLocal(chaveUsuario)

  const recuperarPerfil = () => recuperarItemDoArmazenamentoLocal(chavePerfil)

  const recuperarUrlRedirecionamento = () =>
    recuperarItemDoArmazenamentoLocal(chaveUrlRedirecionamento)

  const [estado, definirEstado] = useState<{
    usuario: Usuario | null
    perfil: Perfil | null
  }>({
    usuario: recuperarUsuario(),
    perfil: recuperarPerfil()
  })
  const [visao, definirVisao] = useState<Visao>(
    recuperarVisaoPorPerfis(recuperarPerfil()?.perfis)
  )

  const obterPerfil = async (token: string) => {
    try {
      const { sub: id, email, role } = jwtDecode<{
        sub: string
        email: string
        role: Array<keyof typeof UsuarioPerfil>
      }>(token)

      return {
        id,
        email,
        perfis: role
      } as Perfil
    } catch (_) {
      // logout
      return undefined
    }
  }

  const recuperarAutenticacaoDoEstado = (): boolean => {
    const usuario = recuperarUsuario()

    if (!usuario) return false

    const perfil = recuperarPerfil()

    if (!perfil) return false

    definirEstado({
      usuario,
      perfil
    })

    return true
  }

  useEffect(() => {
    if (!semRedirecionamento.current) {
      const urlRedirecionamento = recuperarUrlRedirecionamento()
      if (estado.perfil && urlRedirecionamento) {
        const url = urlRedirecionamento?.url ?? '/'
        localStorage.removeItem(chaveUrlRedirecionamento)

        history.replace(url === '/entrar' ? '/' : url)
      }
    } else {
      semRedirecionamento.current = false
    }
  }, [estado.perfil])

  const solicitarAutenticacao = async () => {
    localStorage.setItem(
      chaveUrlRedirecionamento,
      JSON.stringify({
        url: history.location.pathname
      })
    )

    if (recuperarAutenticacaoDoEstado()) return

    history.push('/entrar')
  }

  const atualizarUsuario = async (
    dados: { token: string },
    naoRedirecionar?: boolean
  ) => {
    semRedirecionamento.current = naoRedirecionar
    const perfil = await obterPerfil(dados.token)

    // TODO: Rever se é adequado manter essa requisição aqui
    const { data: novoUsuario } = await (
      await fetch(
        `${process.env.REACT_APP_URL_GATEWAY}/api/usuario/email/${perfil.email}`,
        {
          headers: {
            Authorization: `Bearer ${dados.token}`
          }
        }
      )
    ).json()

    const usuario = { ...dados, ...novoUsuario }

    localStorage.setItem(chaveUsuario, JSON.stringify(usuario))

    localStorage.setItem(chavePerfil, JSON.stringify(perfil))

    definirVisao(recuperarVisaoPorPerfis(perfil.perfis))
    definirEstado({
      usuario,
      perfil
    })
  }

  const sair = async (naoRedirecionar?: boolean) => {
    localStorage.removeItem(chaveUsuario)
    localStorage.removeItem(chavePerfil)
    localStorage.removeItem('@ipgs/responderDepoisPesquisa')
    barraSuperior.removerFoto()
    semRedirecionamento.current = false
    definirEstado({
      usuario: null,
      perfil: null
    })
    if (!naoRedirecionar) history.push('/')
  }

  const conferirUsuarioLogado = () => {
    try {
      const valor = localStorage.getItem(chavePerfil)
      const perfilUsuarioLogado = JSON.parse(valor)
      const email = perfilUsuarioLogado.email
      return email || null
    } catch {
      return null
    }
  }

  const autenticar = async (
    usuario: string,
    senha: string,
    naoRedirecionar?: boolean
  ): Promise<boolean> => {
    try {
      semRedirecionamento.current = naoRedirecionar ?? false

      const token = await Api.Entrar(usuario, senha)

      if (!token) {
        return false
      }

      const dadosUsuario = {
        token
      }

      await atualizarUsuario(dadosUsuario, naoRedirecionar ?? false)

      return true
    } catch {
      localStorage.removeItem(chaveUrlRedirecionamento)
      return false
    }
  }

  const alterarVisao = (visao: Visao) => definirVisao(visao)

  const validarPerfilUnico = (perfilVerificar: string) => {
    if (!perfilVerificar) return false

    const { perfis } = estado?.perfil

    if (perfis === null) {
      return false
    }

    if (Array.isArray(perfis)) {
      return perfis.every(p => p === perfilVerificar)
    }

    return perfis === perfilVerificar
  }

  return {
    solicitarAutenticacao,
    autenticar,
    sair,
    visao,
    alterarVisao,
    atualizarUsuario,
    validarPerfilUnico,
    recuperarPerfil,
    conferirUsuarioLogado,
    ...estado
  }
}

export default createContainer<AuthContainer>(useAuth)
