import React from 'react'

export default (
  <svg
    width="56"
    height="54"
    viewBox="0 0 56 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.0671 42.3571C9.0671 43.6027 8.06376 44.5714 6.85282 44.5714C5.60728 44.5714 4.63853 43.6027 4.63853 42.3571C4.63853 41.1462 5.60728 40.1429 6.85282 40.1429C8.06376 40.1429 9.0671 41.1462 9.0671 42.3571ZM14.6028 24.6429C14.6028 23.4319 13.5995 22.4286 12.3885 22.4286H2.42425C1.21331 22.4286 0.209961 23.4319 0.209961 24.6429V46.7857C0.209961 47.9967 1.21331 49 2.42425 49H12.3885C13.5995 49 14.6028 47.9967 14.6028 46.7857V24.6429ZM55.5671 24.6429C55.5671 21.0446 52.5225 18 48.9242 18H39.3405C39.6173 16.8929 39.9633 16.5469 40.3439 15.7857C41.278 13.9866 42.2814 11.9799 42.2814 9.14284C42.2814 6.47878 42.2814 0.285706 34.5314 0.285706C33.9432 0.285706 33.3896 0.527893 32.9745 0.943074C31.4867 2.39619 31.0716 4.54128 30.691 6.58258C30.2758 8.58927 29.8952 10.6652 28.5459 12.0145C27.4734 13.0871 26.297 14.5748 25.0515 16.1663C23.5292 18.173 20.2077 22.2902 18.9276 22.394C17.7859 22.4978 16.8171 23.4665 16.8171 24.6083V46.7857C16.8171 47.9967 17.8551 48.9654 19.0314 49C20.2423 49.0346 22.3182 49.7612 24.4979 50.5223C28.2345 51.8025 32.9053 53.4286 37.8528 53.4286H38.441H41.0705C41.6932 53.4286 42.1776 53.4286 42.316 53.4286C45.3606 53.394 47.6441 52.4944 49.1318 50.7299C50.4466 49.173 51.0347 47.0625 50.8272 44.4676C51.6921 43.6373 52.3495 42.4955 52.6955 41.2154C53.076 39.8315 53.076 38.4475 52.6955 37.1674C53.7334 35.7835 54.2524 34.1574 54.1832 32.4275C54.1832 31.9431 54.0448 30.9051 53.6642 29.798C54.8405 28.4487 55.5671 26.4766 55.5671 24.6429Z"
      fill="#06D15B"
    />
  </svg>
)
