import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const Icone = styled.div`
  margin-right: 4px;
`

export const Texto = styled.h3`
  font-size: 18px;
  font-weight: 300;
`
