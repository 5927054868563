import { mensagemPadrao } from 'src/dados-estaticos'
import { FormaPagamentoCobranca } from 'src/tipos'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  quantidadeParcelas: Yup.string().numero().nullable().required(mensagemPadrao),
  diaVencimento: Yup.string()
    .numero()
    .nullable()
    .when('metodoPagamento', {
      is: value =>
        value &&
        FormaPagamentoCobranca[value] ===
          FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto],
      then: field => field.required(mensagemPadrao)
    }),
  numeroCartao: Yup.string()
    .numero()
    .nullable()
    .when('metodoPagamento', {
      is: value =>
        value &&
        FormaPagamentoCobranca[value] ===
          FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito],
      then: field => field.required(mensagemPadrao)
    }),
  nomeCartao: Yup.string()
    .nullable()
    .when('metodoPagamento', {
      is: value =>
        value &&
        FormaPagamentoCobranca[value] ===
          FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito],
      then: field => field.required(mensagemPadrao)
    }),
  validadeCartao: Yup.string()
    .numero()
    .nullable()
    .when('metodoPagamento', {
      is: value =>
        value &&
        FormaPagamentoCobranca[value] ===
          FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito],
      then: field => field.required(mensagemPadrao)
    }),
  codigoSeguranca: Yup.string()
    .numero()
    .nullable()
    .when('metodoPagamento', {
      is: value =>
        value &&
        FormaPagamentoCobranca[value] ===
          FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito],
      then: field => field.required(mensagemPadrao)
    })
})
