import React, { useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { hotjar } from 'react-hotjar'
import { toast } from 'react-toastify'

import {
  Cores,
  IconeDeDocumentoAprovado,
  IconeDeDocumentoRecusado,
  IconeDeStatusDeEnvioComAdvertencia,
  IconeDeStatusDeEnvioComErro,
  IconeDeStatusDeEnvioComSucesso,
  IconeRevelarSenha,
  Tooltip
} from 'src/componentes'
import { Api } from 'src/servicos'
import { MatriculasAluno } from 'src/tipos'

import { formatadores } from '../painel-financeiro/pagina-financeiro/formatadores'
import {
  BotaoAcao,
  Container,
  ContainerAnexo,
  ContainerAviso,
  ContainerIP,
  Icone,
  NomeDocumento,
  OpcoesAcao,
  Status,
  StatusDocumento,
  SubTitulo
} from './styles'

interface UploadContratoAlunoProps {
  contratos: MatriculasAluno[]
  contratosMatricula: MatriculasAluno[]
  acaoEnvioAceito?: () => void
}

export const UploadContratoAluno: React.FC<UploadContratoAlunoProps> = ({
  contratos,
  contratosMatricula,
  acaoEnvioAceito
}) => {
  const ObterPdfContrato = async (id, nomeCurso) => {
    const contrato = await Api.RequisitarContratoAluno(id)

    const contratoGerado = window.open('')
    contratoGerado.document.write(
      `<iframe width='100%' height='100%' src=${contrato} frameborder='0' allowfullscreen/>`
    )
    contratoGerado.document.title = `Contrato - ${nomeCurso}`

    return contratoGerado
  }

  const ObterPdfContratoMatricula = async (id, nomeCurso) => {
    const contrato = await Api.RequisitarContratoAlunoMatricula(id)

    const contratoGerado = window.open('')
    contratoGerado.document.write(
      `<iframe width='100%' height='100%' src=${contrato} frameborder='0' allowfullscreen/>`
    )
    contratoGerado.document.title = `Contrato - ${nomeCurso}`

    return contratoGerado
  }

  const AceiteNovoContrato = async (id, contratoRejeitado) => {
    try {
      await Api.AceiteNovoContratoMatricula(id, contratoRejeitado)
      if (acaoEnvioAceito) {
        acaoEnvioAceito()
      }
    } catch (erro) {
      toast('Erro ao aceitar o contrato, tente novamente!', { type: 'error' })
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR, 6)
    }
  }, [])

  return (
    <>
      <SubTitulo>Meus Contratos</SubTitulo>
      <Scrollbars style={{ height: '280px' }}>
        {contratos?.map((contrato, index) => (
          <Container
            key={index}
            style={{ borderBottom: `1px solid ${Cores?.CINZA_MERCURY}` }}
          >
            <ContainerAnexo>
              <NomeDocumento>
                <p>Contrato - {contrato.curso.nome}</p>
                <p>{contrato.curso.sigla}</p>
              </NomeDocumento>
            </ContainerAnexo>
            <StatusDocumento>
              <ContainerAviso>
                <Icone>{IconeDeStatusDeEnvioComSucesso}</Icone>
                <Status style={{ color: `${Cores.CINZA_2}` }}>
                  Contrato aceito
                </Status>
              </ContainerAviso>
              <p title="Conferência">
                {formatadores.dataLocaleString(contrato.dataHoraAceiteTermos)}
              </p>
            </StatusDocumento>
            <ContainerIP>
              <p>IP - Aceite Contrato</p>
              <p>{contrato.ipAceiteTermos}</p>
            </ContainerIP>
            <OpcoesAcao>
              <BotaoAcao
                onClick={() =>
                  ObterPdfContrato(contrato.id, contrato.curso.nome)
                }
              >
                <div
                  data-tip="Visualizar contrato"
                  data-for="visualizar-contrato"
                >
                  {IconeRevelarSenha}
                </div>
                <Tooltip
                  id="visualizar-contrato"
                  place="top"
                  textColor={Cores.PRETO}
                  backgroundColor={Cores.CINZA_1_CLARO}
                  html={true}
                />
              </BotaoAcao>
            </OpcoesAcao>
          </Container>
        ))}
        {contratosMatricula?.map((contrato, index) => (
          <>
            {contrato.dataAceiteContrato && !contrato.contratoRejeitado && (
              <Container
                key={index}
                style={{ borderBottom: `1px solid ${Cores?.CINZA_MERCURY}` }}
              >
                <>
                  <ContainerAnexo>
                    <NomeDocumento>
                      <p>Contrato - {contrato.curso.nome}</p>
                      <p>{contrato.curso.sigla}</p>
                    </NomeDocumento>
                  </ContainerAnexo>
                  <StatusDocumento>
                    <ContainerAviso>
                      <Icone>{IconeDeStatusDeEnvioComSucesso}</Icone>
                      <Status style={{ color: `${Cores.CINZA_2}` }}>
                        Contrato aceito
                      </Status>
                    </ContainerAviso>
                    <p title="Conferência">
                      {formatadores.dataLocaleString(
                        contrato.dataAceiteContrato
                      )}
                    </p>
                  </StatusDocumento>
                  <ContainerIP>
                    <p>IP - Aceite Contrato</p>
                    <p>{contrato.ipAceiteContrato}</p>
                  </ContainerIP>
                  <OpcoesAcao>
                    <BotaoAcao
                      onClick={() =>
                        ObterPdfContratoMatricula(
                          contrato.id,
                          contrato.curso.nome
                        )
                      }
                    >
                      <div
                        data-tip="Visualizar contrato"
                        data-for="visualizar-contrato"
                      >
                        {IconeRevelarSenha}
                      </div>
                      <Tooltip
                        id="visualizar-contrato"
                        place="top"
                        textColor={Cores.PRETO}
                        backgroundColor={Cores.CINZA_1_CLARO}
                        html={true}
                      />
                    </BotaoAcao>
                  </OpcoesAcao>
                </>
              </Container>
            )}
          </>
        ))}
      </Scrollbars>
      <SubTitulo>Outros Contratos</SubTitulo>
      <Scrollbars style={{ height: '280px' }}>
        {contratosMatricula?.map((contrato, index) => (
          <>
            {!contrato.dataAceiteContrato && !contrato.contratoRejeitado && (
              <Container
                key={index}
                style={{ borderBottom: `1px solid ${Cores?.CINZA_MERCURY}` }}
              >
                <ContainerAnexo>
                  <NomeDocumento>
                    <p>Contrato - {contrato.curso.nome}</p>
                    <p>{contrato.curso.sigla}</p>
                  </NomeDocumento>
                </ContainerAnexo>
                <StatusDocumento>
                  <ContainerAviso>
                    <Icone>{IconeDeStatusDeEnvioComAdvertencia}</Icone>
                    <Status style={{ color: `${Cores.CINZA_2}` }}>
                      Aguardando análise
                    </Status>
                  </ContainerAviso>
                </StatusDocumento>
                <OpcoesAcao>
                  <BotaoAcao
                    onClick={() =>
                      ObterPdfContratoMatricula(
                        contrato.id,
                        contrato.curso.nome
                      )
                    }
                  >
                    <div
                      data-tip="Visualizar contrato"
                      data-for="visualizar-contrato"
                    >
                      {IconeRevelarSenha}
                    </div>
                    <Tooltip
                      id="visualizar-contrato"
                      place="top"
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                      html={true}
                    />
                  </BotaoAcao>
                  <BotaoAcao
                    onClick={() => AceiteNovoContrato(contrato.id, false)}
                  >
                    <div
                      data-tip={'Aprovar contrato'}
                      data-for={'APROVACAO_ID'}
                    >
                      {IconeDeDocumentoAprovado}
                    </div>
                    <Tooltip
                      id={'APROVACAO_ID'}
                      place={'top'}
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                      html={true}
                    />
                  </BotaoAcao>
                  <BotaoAcao
                    onClick={() => AceiteNovoContrato(contrato.id, true)}
                  >
                    <div data-tip={'Recusar contrato'} data-for={'REJEICAO_ID'}>
                      {IconeDeDocumentoRecusado}
                    </div>
                    <Tooltip
                      id={'REJEICAO_ID'}
                      place={'top'}
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                      html={true}
                    />
                  </BotaoAcao>
                </OpcoesAcao>
              </Container>
            )}
          </>
        ))}
      </Scrollbars>
      <SubTitulo>Contratos Recusados</SubTitulo>
      <Scrollbars style={{ maxHeight: '280px' }}>
        {contratosMatricula?.map((contrato, index) => (
          <>
            {contrato.contratoRejeitado && (
              <Container
                key={index}
                style={{ borderBottom: `1px solid ${Cores?.CINZA_MERCURY}` }}
              >
                <ContainerAnexo>
                  <NomeDocumento>
                    <p>Contrato - {contrato.curso.nome}</p>
                    <p>{contrato.curso.sigla}</p>
                  </NomeDocumento>
                </ContainerAnexo>
                <StatusDocumento>
                  <ContainerAviso>
                    <Icone>{IconeDeStatusDeEnvioComErro}</Icone>
                    <Status style={{ color: `${Cores.CINZA_2}` }}>
                      Contrato recusado
                    </Status>
                  </ContainerAviso>
                </StatusDocumento>
                <OpcoesAcao>
                  <BotaoAcao
                    onClick={() =>
                      ObterPdfContratoMatricula(
                        contrato.id,
                        contrato.curso.nome
                      )
                    }
                  >
                    <div
                      data-tip="Visualizar contrato"
                      data-for="visualizar-contrato"
                    >
                      {IconeRevelarSenha}
                    </div>
                    <Tooltip
                      id="visualizar-contrato"
                      place="top"
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                      html={true}
                    />
                  </BotaoAcao>
                </OpcoesAcao>
              </Container>
            )}
          </>
        ))}
      </Scrollbars>
    </>
  )
}
