import React from 'react'

export enum SituacaoWizard {
  DISPONIVEL = 'disponivel',
  PREENCHIDO = 'preenchido',
  BLOQUEADO = 'bloqueado'
}

export type DadosWizard = {
  id: string
  titulo: string
  icone: JSX.Element
  corpo: JSX.Element
  desabilitado?: boolean
}

export interface ModalPrePassoProps {
  aberta: boolean
  acaoConfirmar: () => void | Promise<void>
  acaoCancelar: () => void | Promise<void>
  acaoFechar: () => void | Promise<void>
}

export interface WizardProps extends React.HTMLAttributes<HTMLAllCollection> {
  listaWizard: DadosWizard[]
  name: string
  passo: number
  finalizado?: boolean
  passoCompleto?: number | undefined
  ativarPrePasso?: boolean
  ModalPrePasso?: JSX.Element
}

export interface WizardEstado {
  passo: number
  passoCompleto: number
  tamanhoWizard: number
  preEtapa?: number
  visibilidadePrePasso: boolean
  condicaoPrePasso?: () => boolean
  acaoConfirmarPrePasso?: () => Promise<boolean>
  acaoCancelarPrePasso?: (preEtapa?: number) => Promise<void>
}

export interface WizardProviderProps {
  children: React.ReactNode
  passoCompleto?: number
}
