import React, { ChangeEvent, useEffect, useRef } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'
import { InputArquivoProps } from 'src/componentes/input/input-arquivo'

import { InputArquivo } from './input'
import { Componente, Erro } from './styles'
import { InputArquivoRef } from './tipos'

export const AvaliacaoArquivoInput: React.FC<InputArquivoProps> = ({
  name,
  className,
  acaoAoAlterar,
  ...rest
}) => {
  const inputRef = useRef<InputArquivoRef>(null)
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField<File | string>({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return inputRef.current?.getValue() ?? null
      },
      setValue: (_, value) => {
        inputRef.current?.setValue(value)
      }
    })
  }, [fieldName, registerField])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames(className)}
    >
      <InputArquivo
        ref={inputRef}
        name={name}
        valorPadrao={defaultValue}
        comErro={!!error}
        acaoAoAlterar={(e: ChangeEvent<HTMLInputElement>) => {
          clearError()
          if (acaoAoAlterar) acaoAoAlterar(e)
        }}
        className={classNames({
          error
        })}
        tiposAceitos={[
          '.pdf',
          '.doc',
          '.docx',
          '.xls',
          '.xlsx',
          '.csv',
          '.ppt',
          '.pptx'
        ]}
        {...rest}
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </Componente>
  )
}
