import { Botao } from 'src/componentes/botao'
import styled from 'styled-components'

export const Container = styled.div`
  .itemDraggableContainer {
    border-left: 0.1px solid lightgrey;
    border-right: 0.1px solid lightgrey;
  }
`

export const LinhaTabela = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 480px;
  }

  .button:last-child {
    margin-right: 10px;
  }
`

export const BotaoEstilizado = styled(Botao)`
  min-width: 170px;
`
