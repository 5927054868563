import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ModalBase,
  Botao,
  ModalBaseProps,
  SubTitulo,
  IconeDecisao,
  FuncoesDataHora
} from 'src/componentes'
import {
  ContainerBotoes,
  ModalCorpo,
  ContainerModal,
  SubtituloModal
} from 'src/paginas/ipgs/turmas/styles'
import { EncontroPresencial } from 'src/tipos'

import PaginaCadastroTurmaContainer from '../../../container'
import { ModalRemoverEncontroRef } from './tipos'

const ModalExcluirComponente: React.ForwardRefRenderFunction<
  ModalRemoverEncontroRef,
  Omit<ModalBaseProps, 'acaoPrimaria'>
> = ({ backdrop, acaoSecundaria }, ref) => {
  const { removerEncontro } = PaginaCadastroTurmaContainer.useContainer()
  const [aberta, definirAberta] = useState(false)
  const [encontro, definirEncontro] = useState<EncontroPresencial | null>(null)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = (encontro: EncontroPresencial) => {
    definirEncontro(encontro)
    definirAberta(true)
  }

  useImperativeHandle<ModalRemoverEncontroRef, ModalRemoverEncontroRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase
      id="modal-confirmacao-cancelamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ModalCorpo>
        <ContainerModal>
          {IconeDecisao}
          <SubtituloModal>
            Deseja realmente remover o encontro do dia
            {encontro?.data &&
              ` ${FuncoesDataHora.dataFormatadaComBarras(
                new Date(encontro?.data)
              )}`}
            ?
          </SubtituloModal>
          <SubTitulo texto="Selecione uma opção" />
          <ContainerBotoes>
            <Botao
              type="button"
              texto="Não"
              tema="Secundario"
              onClick={() => {
                acaoSecundaria && acaoSecundaria()
                definirEncontro(null)
                fechar()
              }}
            />
            <Botao
              type="button"
              texto="Sim"
              id="btn-salvar"
              onClick={() => {
                removerEncontro(encontro.id)
                fechar()
              }}
            />
          </ContainerBotoes>
        </ContainerModal>
      </ModalCorpo>
    </ModalBase>
  )
}

export const ModalRemoverEncontro = forwardRef(ModalExcluirComponente)
