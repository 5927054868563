import React from 'react'

import {
  Botao,
  ContainerAcoes,
  IconeDownload,
  SubTitulo,
  Tooltip
} from 'src/componentes'

import { calcularDistanciaData } from '../funcoes/calcularDatas'
import { Container, ContainerBotao } from '../styles'
import { ContainerInfo, ContainerTabela, Tabela, TabelaLinha } from './styles'
import { SobreCursoProps } from './tipos'

export const SobreCurso: React.FC<SobreCursoProps> = ({
  informacoesGeraisCurso,
  progresso,
  materiaisTurma,
  dataInicio,
  acaoDownloadMaterial,
  acaoAvancar,
  children
}) => {
  const naoIniciouSalaAula = dataInicio
    ? calcularDistanciaData(dataInicio) <= 0
    : false

  return (
    <Container>
      {children}
      <ContainerInfo>
        <p
          dangerouslySetInnerHTML={{
            __html:
              informacoesGeraisCurso?.replaceAll('<p></p>', '<br />') ?? ''
          }}
        />
      </ContainerInfo>
      {materiaisTurma && (
        <ContainerTabela>
          <SubTitulo texto="Materiais do Curso" />
          <Tabela>
            {materiaisTurma.map((material, index) => (
              <>
                <TabelaLinha key={index}>
                  <>{material.titulo.split('.')[0]}</>
                  <ContainerAcoes>
                    <button
                      data-for={`${material.id}-tooltip-download`}
                      data-tip="Download"
                      type="button"
                      onClick={async () =>
                        await acaoDownloadMaterial(material.id)
                      }
                    >
                      {IconeDownload}
                    </button>
                  </ContainerAcoes>
                </TabelaLinha>
                <Tooltip
                  id={`${material.id}-tooltip-download`}
                  place="bottom"
                  offset={{ top: 10 }}
                />
              </>
            ))}
          </Tabela>
        </ContainerTabela>
      )}
      {progresso < 1 && (
        <ContainerBotao>
          <Botao
            texto="Iniciar Curso"
            onClick={acaoAvancar}
            disabled={naoIniciouSalaAula}
          />
        </ContainerBotao>
      )}
    </Container>
  )
}
