import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { ModalBase, Botao, ModalRef } from 'src/componentes'
import { ModalBaseProps } from 'src/componentes/modal-base/tipos'

import { CorpoModal, ContainerTextoModal, TituloModal } from './styles'

export interface ModalNotificacaoItemBloqueadoRef
  extends Omit<ModalRef, 'abrir'> {
  abrir: (texto: JSX.Element | string) => void
}

const Modal: React.ForwardRefRenderFunction<
  ModalNotificacaoItemBloqueadoRef,
  ModalBaseProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [texto, definirTexto] = useState(null)

  const fechar = () => {
    definirAberta(false)
    definirTexto(null)
  }

  const abrir = (texto: JSX.Element | string) => {
    definirAberta(true)
    definirTexto(texto)
  }

  useImperativeHandle<
    ModalNotificacaoItemBloqueadoRef,
    ModalNotificacaoItemBloqueadoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <TituloModal>Aviso!</TituloModal>
        <ContainerTextoModal>{texto ?? <p>Bloqueado</p>}</ContainerTextoModal>
        <Botao texto="Ok" type="button" onClick={fechar} />
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalNotificacaoMotivoBloqueio = forwardRef(Modal)
