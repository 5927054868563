import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaDocumentosAnalise = (): TabelaColuna => ({
  Chave: 'documentosAguardandoAnalise',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 100 }}>Doc. Aguard. Análise</div>
  )
})
