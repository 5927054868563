import React from 'react'

import { StepperContainer } from 'src/componentes'
import { Api } from 'src/servicos'

import { Material as MaterialComponente } from '../../shared'
import SalaAulaCursoLivreContainer from '../container'
import { MaterialProps } from './tipos'

export const Material: React.FC<MaterialProps> = ({
  id,
  tipo,
  url,
  ultimoMaterial,
  pdf
}) => {
  const { avancarStepper, voltarStepper } = StepperContainer.useContainer()
  const {
    salaAula,
    etapas,
    itensMarcados,
    definirItensMarcados,
    definirProgresso,
    definirPesquisaSatisfacaoLiberada
  } = SalaAulaCursoLivreContainer.useContainer()

  const carregarMaterialPorId = async () => {
    return await Api.SalaAulaCursoLivre.RequisitarMaterialPorId(id, salaAula.id)
  }

  const acaoAvancar = async () => {
    if (itensMarcados.some(i => i === id)) {
      avancarStepper()
      return
    }

    const resposta = await Api.SalaAulaCursoLivre.MarcarMaterial(
      id,
      salaAula.id,
      true
    )

    if (resposta) {
      definirProgresso(resposta.progresso)
      definirPesquisaSatisfacaoLiberada(resposta.pesquisaSatisfacaoLiberada)

      if (!itensMarcados.some(i => i === id)) {
        definirItensMarcados([...itensMarcados, id])
      }
      avancarStepper()
    }
  }

  return (
    <MaterialComponente
      id={id}
      tipo={tipo}
      url={url}
      salaAulaId={salaAula.id}
      ultimoMaterial={ultimoMaterial}
      etapas={etapas}
      acaoAvancar={acaoAvancar}
      acaoVoltar={voltarStepper}
      carregarMaterialPorId={carregarMaterialPorId}
      pdf={pdf}
      isAulaEad={false}
    />
  )
}
