import { animated } from 'react-spring'

import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 133px;
  overflow: hidden;

  margin-bottom: 24px;

  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  border: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 4px 0;
`

export const AnimatedDiv = styled(animated.div)`
  width: 100%;
  height: auto;
`

export const Conteudo = styled.div`
  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  border: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 4px 0;
`

export const CabecalhoListagem = styled.div`
  padding: 13px 20px 7px;
`

export const LinhaCabecalho = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
`

export const Titulo = styled.div`
  margin-left: 21px;

  h3 {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: ${Cores.SECUNDARIA_2_MAIS_ESCURO};
  }

  p {
    margin-top: 12px;

    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }
`

export const CabecalhoTopico = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  h3 {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }
`

export const ListaTopicos = styled.div`
  border-left: 4px solid ${Cores.SECUNDARIA_2};
`

export const ItemTopico = styled.div`
  cursor: pointer;
  transition: 0.2s all;

  & > div {
    display: flex;
    align-items: center;

    border-top: 1px solid ${Cores.CINZA_3_CLARO};
    border-bottom: 1px solid ${Cores.CINZA_3_CLARO};

    margin: 0px 16px 0px 20px;
    padding: 16px 0px;

    & > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 111.33%;
      letter-spacing: -0.02em;
      color: ${Cores.SECUNDARIA_2_ESCURO};

      margin-left: 12px;
    }
  }

  &:hover {
    background-color: ${Cores.CINZA_3_CLARO};
  }
`

export const ContainerTela = styled.div`
  margin-top: 47px;
`
