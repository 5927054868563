import React, {
  useEffect,
  useState,
  forwardRef,
  ChangeEvent,
  useImperativeHandle,
  useRef
} from 'react'
import { InputState } from 'react-input-mask'

import { Input } from '.'
import { CpfCnpjInputProps } from './tipos'

export const CpfCnpjInput: React.ForwardRefExoticComponent<CpfCnpjInputProps> = forwardRef<
  HTMLInputElement,
  CpfCnpjInputProps
>(({ value, onChange, ...rest }, ref?: any) => {
  const [valor, definirValor] = useState(value ?? '')
  const inputRef = useRef<HTMLInputElement>(null)
  type mascaras = '999.999.999-99' | '99.999.999/9999-99'
  const [mascara, definirMascara] = useState<mascaras>(
    value && value.replace(/\D/g, '').length === 14
      ? '99.999.999/9999-99'
      : '999.999.999-99'
  )

  const manipularMascara = (valor: string) => {
    if (valor.length > 11 && mascara !== '99.999.999/9999-99') {
      definirMascara('99.999.999/9999-99')
    } else if (valor.length <= 11 && mascara !== '999.999.999-99') {
      definirMascara('999.999.999-99')
    }
  }

  const alterarValor = (valor: string) => {
    const valorFinal = valor ? valor.replace(/\D/g, '') || '' : ''
    manipularMascara(valorFinal)
    definirValor(valorFinal)

    if (onChange) {
      onChange({
        target: { value: valorFinal }
      } as ChangeEvent<HTMLInputElement>)
    }
  }

  useEffect(() => {
    alterarValor(value)
  }, [value])

  const colar = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const valor = e.clipboardData.getData('Text').trim()
    alterarValor(valor)
  }

  const antesDeAlterarOValor = (
    estadoNovo: InputState,
    estadoAntigo: InputState,
    valorUsuario: string
  ): InputState => {
    let { value: valorNovo, selection } = estadoNovo
    const { value: valorAntigo } = estadoAntigo

    if (
      !/\D/.test(valorUsuario) &&
      valorAntigo.replace(/\D/g, '').length === 11
    ) {
      valorNovo += valorUsuario
      selection = { start: selection.start + 1, end: selection.end + 1 }
    }

    return {
      value: valorNovo,
      selection
    }
  }

  useImperativeHandle(ref, () => ({
    setInputValue: (value: string) => alterarValor(value),
    getInputValue: () => inputRef.current?.value
  }))

  return (
    <Input
      {...rest}
      ref={inputRef}
      mascara={mascara}
      value={valor}
      onChange={e => alterarValor(e.target.value)}
      onPaste={colar}
      beforeMaskedValueChange={antesDeAlterarOValor}
    />
  )
})
