import queryString from 'query-string'

import { gatewayApi } from '../instancias-api'
import { BancoResponse } from './tipos'

const urlBase = '/Banco'

export const ObterBancosAtivos = async (
  filtro?: string
): Promise<BancoResponse[]> => {
  const filtros = queryString.stringify({
    texto: filtro
  })

  const { data } = await gatewayApi.get<BancoResponse[]>(
    `${urlBase}?${filtros}`
  )

  return data
}

export const ObterBancoPorId = async (id: string): Promise<BancoResponse> => {
  const { data } = await gatewayApi.get(`${urlBase}/${id}`)
  return data
}
