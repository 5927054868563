import { Notificacao } from './tipos'

export const notificacoesMock = new Array<Notificacao>(
  {
    titulo: 'Dieta Infantil',
    data: new Date('10/01/20'),
    descricao: 'Curso livre disponível',
    lida: true
  } as Notificacao,
  {
    titulo: 'Indice Glicemico',
    data: new Date('07/01/20'),
    descricao: 'Curso livre disponível',
    lida: true
  } as Notificacao,
  {
    titulo: 'Glúten não é pra todos',
    data: new Date('05/01/20'),
    descricao: 'Curso livre disponível',
    lida: true
  } as Notificacao
)
