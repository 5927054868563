import { TipoUsuario, TipoUsuarioPorExtenso } from 'src/tipos/enums'

export const SELECT_TIPO_USUARIO = [
  {
    id: TipoUsuario[TipoUsuario.PF],
    texto: TipoUsuarioPorExtenso[TipoUsuario.PF]
  },
  {
    id: TipoUsuario[TipoUsuario.PJ],
    texto: TipoUsuarioPorExtenso[TipoUsuario.PJ]
  }
]
