import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { ModoAvaliacao } from 'src/tipos'

export const colunaModoAvaliacao = (): TabelaColuna => ({
  Chave: 'modoAvaliacao',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <>{ModoAvaliacao[linha.modoAvaliacao]}</>,
  RenderizarCabecalho: () => <div style={{ width: 100 }}>Modo Avaliação</div>
})
