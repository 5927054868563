import { Cores, Medias } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  max-width: 785px;
`

export const Cabecalho = styled.div`
  & > h1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
  }
  margin-bottom: 47px;

  @media ${Medias.MOBILE} {
    margin-bottom: 10px;
  }
`

export const Icone = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    fill: ${Cores.CINZA_2};
  }

  svg > path {
    fill: inherit;
  }
`

export const ContainerConteudo = styled.div`
  margin-top: 13px;
  margin-left: 31px;
  width: 100%;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;

  h2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 23px;
  }

  @media ${Medias.MOBILE} {
    margin: 0;
    width: 95%;

    h2 {
      font-size: 1.6em;
    }
  }
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerAcoes = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: space-between;

  @media ${Medias.MOBILE} {
    margin-top: 10px;
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`

export const ContainerCadastro = styled.div`
  width: 100%;

  & > p {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  & > form {
    display: flex;
    flex-direction: column;

    & > h4 {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;

      margin-top: 29px;
      margin-bottom: 36px;

      @media ${Medias.MOBILE} {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
  }

  @media ${Medias.MOBILE} {
    margin-top: 20px;
  }
`

export const Linha = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;

  @media ${Medias.MOBILE} {
    flex-direction: column;
  }
`

export const ContainerCheio = styled.div`
  width: 100%;
`

export const ContainerGrande = styled.div`
  width: 75%;

  & ~ div {
    margin-left: 18px;
  }

  @media ${Medias.MOBILE} {
    width: 100%;
    & ~ div {
      margin-left: 0;
    }
  }
`

export const ContainerMedio = styled.div`
  width: 50%;

  .tooltip-ipgs {
    max-width: 451px;
  }

  @media ${Medias.MOBILE} {
    width: 100%;
    & ~ div {
      margin-left: 0;
    }
  }
`

export const ContainerPequeno = styled.div`
  width: 25%;

  & ~ div {
    margin-left: 18px;
  }

  @media ${Medias.MOBILE} {
    width: 100%;
    & ~ div {
      margin-left: 0;
    }
  }
`

export const ContainerSenha = styled(ContainerPequeno)`
  width: 30%;

  @media ${Medias.MOBILE} {
    width: 100%;
  }
`

export const ContainerAcessoBotoes = styled.div`
  display: flex;
  margin-top: 37px;
  margin-bottom: 31px;

  button {
    padding: 0px;
    max-height: 15px;
    padding-left: 21px;
    line-height: unset;

    &:first-child {
      border-right: 1px solid ${Cores.CINZA_2};
      padding-right: 21px;
      padding-left: 0px;
    }
  }
`

export const ContainerAcesso = styled.div`
  margin-top: 61px;

  & > p {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
  }

  @media ${Medias.MOBILE} {
    margin-top: 20px;
  }
`

export const ContainerInfosPagamento = styled.div`
  width: 100%;
  display: flex;
  gap: 18px;

  @media ${Medias.MOBILE} {
    flex-direction: column;
  }
`

export const ContainerValorTotalCursos = styled(ContainerInfosPagamento)`
  padding-top: 30px;
`

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 43%;
  gap: 8px;

  @media ${Medias.MOBILE} {
    width: 100%;
  }
`

export const ContainerCupom = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  > div:first-child {
    margin-bottom: 20px;
  }

  @media ${Medias.MOBILE} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`

export const ContainerPossuiResponsavelFinanceiro = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  > div:first-child {
    margin-bottom: 20px;
  }

  @media ${Medias.MOBILE} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`

export const TituloInfo = styled.span`
  font-size: 16px;
  line-height: 100%;
  color: ${Cores.PRETO};
`

export const Info = styled.div<{ negrito?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: ${props => (props.negrito ? '500' : '300')};
    font-size: 14px;
    line-height: 16px;
    color: ${Cores.PRETO};
  }
`

export const ContainerInputCupom = styled.div`
  max-width: 80%;

  & > form > div {
    margin-bottom: 0;
  }

  @media ${Medias.MOBILE} {
    margin-top: 10px;
    max-width: 100%;
  }
`

export const TituloIsento = styled.h5`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${Cores.SECUNDARIA_2};
  padding-top: 10px;
`

export const TituloIsentoRecorrencia = styled.h5`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${Cores.SECUNDARIA_2};
  margin-top: 20px;
`
