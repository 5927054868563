import styled, { css } from 'styled-components'

import { Cores, Medias } from '../styles'
import {
  CampoInputProps,
  CampoInputLabelProps,
  CampoInputIcone,
  CampoInputDescricaoProps,
  CampoReadOnlyProps
} from './tipos'

export const Componente = styled.div<CampoInputProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  ${({ readOnly }) => readOnly && 'padding-right: 48px;'}

  input[type='number']::-webkit-inner-spin-button {
    appearance: none;
  }

  input[type='number'] {
    appearance: textfield;
  }

  input {
    ${props => `max-width: ${props.maxWidthInput};`}
    padding: ${props =>
      props.tamanho === 'M' || props.tamanho === 'L' ? '0px 17px' : '0px 13px'};

    ${props =>
      !props.tamanho &&
      css`
        @media ${Medias.NOTEBOOK.MIN} {
          padding: 17px;
        }
      `}
  }
`

export const CampoLabel = styled.label<CampoInputLabelProps>`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: left;
  line-height: 125%;
  margin-bottom: ${props => (props.temDescricao ? '3px' : '6px')};
  color: ${props =>
    props.readOnly ? Cores.CINZA_2_CLARO : Cores.CINZA_2_ESCURO};

  :first-letter {
    text-transform: uppercase;
  }
`

export const CampoDescricao = styled.div<CampoInputDescricaoProps>`
  margin-bottom: 6px;
  color: ${props => (props.readOnly ? Cores.CINZA_2_CLARO : Cores.CINZA_2)};
  p {
    font-size: 12px;
    line-height: 125%;

    b {
      font-family: 'Roboto', sans-serif;
    }
  }
`

export const CampoIcone = styled('div')<CampoInputIcone>`
  display: flex;
  width: ${props =>
    props.tamanho && props.tamanho === 'M'
      ? '40px'
      : props.tamanho === 'L'
      ? '40px'
      : '40px;'};
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Cores.CINZA_1_CLARO};
  background: ${Cores.FUNDO};
  box-sizing: border-box;
  cursor: ${props => (props.temAcaoClick ? 'pointer' : 'normal')};

  ${props =>
    !props.tamanho &&
    css`
      @media ${Medias.NOTEBOOK.MIN} {
        width: 54px;
      }

      @media ${Medias.DESKTOP} {
        width: 60px;
      }
    `}
`

export const CampoInput = styled('div')<CampoInputProps>`
  ${({ ladoIcone }) => {
    if (ladoIcone === 'esquerda') {
      return css`
        ${CampoIcone} {
          order: 0;
          border-radius: 4px 0px 0px 4px;
          border-right: none;
        }

        input {
          order: 1;
          border-radius: 0px 4px 4px 0px;

          input:hover .icone {
            border: 1px solid ${Cores.PRIMARIA_1};
          }

          &:focus + .icone {
            border: 1px solid ${Cores.PRIMARIA_2};
          }
        }
      `
    } else {
      return css`
        flex-direction: row;

        ${CampoIcone} {
          border-radius: 0px 4px 4px 0px;
          border-left: none;
          width: 53px;
        }

        input {
          border-radius: 4px 0px 0px 4px;
        }
      `
    }
  }};
  max-width: ${props => (props.maxWidthInput ? props.maxWidthInput : '100%')};
  position: relative;
  display: flex;
  height: ${props =>
    props.theme.ModoVisualizacao
      ? 'unset'
      : props.tamanho && props.tamanho === 'M'
      ? '40px'
      : props.tamanho === 'L'
      ? '40px'
      : '40px'};

  ${props =>
    !props.tamanho &&
    !props.theme.ModoVisualizacao &&
    css`
      @media ${Medias.NOTEBOOK.MIN} {
        height: 40px;
      }

      @media ${Medias.DESKTOP} {
        height: 40px;
      }
    `}

  input {
    outline: none;
    width: 100px;
    height: 100%;
    border: 1px solid ${Cores.CINZA_1_CLARO};
    font-family: 'Roboto', sans-serif;
    font-size: ${props =>
      props.tamanho === 'M' ? '12px' : props.tamanho === 'L' ? '12px' : '12px'};
    color: ${Cores.CINZA_2_ESCURO};
    transition: 0.15s;

    ${props => !props.comIcone && 'border-radius: 4px'};

    ${props =>
      !props.tamanho &&
      css`
        @media ${Medias.NOTEBOOK.MIN} {
          font-size: 12px;
        }

        @media ${Medias.DESKTOP} {
          font-size: 12px;
        }
      `}

    &::placeholder {
      color: ${Cores.CINZA_3_ESCURO};
      font-size: ${props =>
        props.tamanho === 'M'
          ? '12px'
          : props.tamanho === 'L'
          ? '12px'
          : `12px
      `};

      ${props =>
        !props.tamanho &&
        css`
          @media ${Medias.NOTEBOOK.MIN} {
            font-size: 12px;
          }

          @media ${Medias.DESKTOP} {
            font-size: 12px;
          }
        `}
    }

    &:not(:placeholder-shown) {
      color: ${Cores.CINZA_2};
    }

    ${({ theme: { Desabilitado } }) =>
      !Desabilitado &&
      css`
        &:hover {
          border: 1px solid ${Cores.PRIMARIA_1};
        }

        &:focus {
          caret-color: ${Cores.PRIMARIA_2};
          border: 1px solid ${Cores.PRIMARIA_2};
          color: ${Cores.CINZA_2_ESCURO};
        }
      `}
  }

  ${({ theme: { ModoVisualizacao } }) =>
    ModoVisualizacao &&
    css`
      input {
        outline: none;
        width: 100%;
        height: 100%;
        padding: 0;
        border: 0px;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        color: ${Cores.CINZA_2_ESCURO};
        background-color: unset;
        text-overflow: ellipsis;

        &:hover {
          border: 0px;
        }

        &:active {
          border: 0px;
        }

        &:focus {
          border: 0px;
        }
      }
    `}

  ${({ theme: { Desabilitado, ModoVisualizacao } }) =>
    Desabilitado &&
    !ModoVisualizacao &&
    css`
      .icone {
        background-color: rgba(243, 245, 249, 0.36);
        border-color: rgba(189, 191, 192, 0.36);
        svg {
          opacity: 36%;
        }
      }

      input {
        cursor: not-allowed;
        color: ${Cores.CINZA_3_ESCURO};
        background-color: rgba(209, 211, 212, 0.36);
        border-color: rgba(189, 191, 192, 0.36);
      }
    `}

  ${({ theme: { Erro, ModoVisualizacao } }) =>
    Erro &&
    !ModoVisualizacao &&
    css`
      input {
        border: 1px solid ${Cores.PERIGO_ERRO};
      }
    `}

    ${({ theme: { Sucesso, ModoVisualizacao } }) =>
    Sucesso &&
    !ModoVisualizacao &&
    css`
      input {
        border: 1px solid ${Cores.SUCESSO};
      }
    `}

  ${({ theme: { Icone, ModoVisualizacao } }) =>
    !Icone &&
    !ModoVisualizacao &&
    css`
      input {
        width: 100%;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
    `}
`

export const CampoReadOnly = styled('span')<CampoReadOnlyProps>`
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: ${({ temValor }) =>
    temValor ? Cores.CINZA_2_ESCURO : Cores.CINZA_3_ESCURO};
  background-color: unset;

  &:hover {
    border: 0px;
  }

  &:active {
    border: 0px;
  }

  &:focus {
    border: 0px;
  }
`

export const BotaoContainer = styled.div<CampoInputIcone>`
  order: 3;
  position: absolute;
  top: calc(50% - 15px);
  right: 1rem;

  svg {
    ${props =>
      props.tamanho === 'M' || props.tamanho === 'L'
        ? css`
            width: 25px;
            height: 16px;
          `
        : css`
            width: 22px;
            height: 15px;
          `}
    ${props =>
      !props.tamanho &&
      css`
        @media ${Medias.NOTEBOOK.MIN} {
          width: 25px;
          height: 16px;
        }
      `}
  }

  ${props =>
    props.desabilitado &&
    css`
      pointer-events: none;
      svg {
        path {
          fill: ${Cores.CINZA_2_36};
        }
      }
    `}
`

export const Obrigatorio = styled.span`
  color: ${Cores.PERIGO_ERRO};
  margin-right: 5px;
`

export const Validacoes = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`

export const ItemValidacao = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @keyframes animar {
    0% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  i {
    width: 14px;
    height: 14px;
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 14px;
      height: 14px;

      path {
        fill: ${Cores.CINZA_2_CLARO};
      }
    }
  }

  p {
    margin-left: 5px;
    color: ${Cores.CINZA_2_CLARO};
    font-size: 10px;
  }

  &.certo {
    svg path {
      fill: ${Cores.SUCESSO};
    }

    svg {
      animation: animar 0.2s linear;
    }

    p {
      color: ${Cores.SUCESSO};
      animation: animar 0.2s linear;
    }
  }
`
