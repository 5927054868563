import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  ModalBase,
  Botao,
  IconeDecisao,
  FormRef,
  FormUnform,
  InputUnform
} from 'src/componentes'

import { ContainerModal, Subtitulo } from '../../styles'
import { ModalCopiaTurmaRef } from '../../tipos'
import { schema } from './schema'
import { ContainerInput, CorpoModal, ContainerBotoes } from './styles'
import { CopiaTurmaDados, ModalCopiaTurmaProps } from './tipos'

const ModalCopiarTurma: React.ForwardRefRenderFunction<
  ModalCopiaTurmaRef,
  ModalCopiaTurmaProps
> = ({ backdrop, acaoPrimaria, acaoSecundaria }, ref) => {
  const [carregando, definirCarregando] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const [id, definirId] = useState<string>(null)
  const formRef = useRef<FormRef>(null)

  const fechar = () => {
    definirId(null)
    definirAberta(false)
  }

  const abrir = (id: string) => {
    definirId(id)
    definirAberta(true)
  }

  useImperativeHandle<ModalCopiaTurmaRef, ModalCopiaTurmaRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = async (dados: CopiaTurmaDados) => {
    definirCarregando(true)
    try {
      await acaoPrimaria(id, dados.codigo)
      fechar()
    } catch {
      toast('Erro ao copiar turma', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  return (
    <ModalBase
      id="modal-confirmacao-cancelamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          {IconeDecisao}
          <h5>Deseja realmente gerar uma cópia desta turma?</h5>
          <Subtitulo>
            <p>Todos os dados da turma serão copiados para um novo registro.</p>
            <p> Selecione uma opção.</p>
          </Subtitulo>
          <FormUnform
            ref={formRef}
            dadosIniciais={{}}
            schema={schema}
            acaoSucesso={acaoSucesso}
          >
            <ContainerInput>
              <InputUnform
                name="codigo"
                label="Código turma cópia"
                obrigatorio
                maxLength={100}
              />
            </ContainerInput>

            <ContainerBotoes>
              <Botao
                type="button"
                id="btn-cancelar"
                texto="Não"
                tema="Secundario"
                onClick={() => {
                  acaoSecundaria && acaoSecundaria()
                  fechar()
                }}
              />
              <Botao
                type="submit"
                id="btn-salvar"
                texto="Sim"
                carregando={carregando}
              />
            </ContainerBotoes>
          </FormUnform>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const CopiarTurma = forwardRef(ModalCopiarTurma)
