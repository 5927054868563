import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 47px;
  display: flex;
  flex-direction: column;

  ${ContainerErroLayout} > div {
    width: 100%;
  }
`

export const ContainerBreadcrumb = styled.div`
  background: ${Cores.BRANCO};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  padding: 13px 0;
  margin-top: 16px;
`

export const ContainerBotao = styled.div`
  align-self: center;
  justify-self: center;
  margin-top: 12px;
`

export const ContainerBotaoPesquisaSatisfacao = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
  width: 100%;

  button:first-child {
    margin-right: 10px;
  }
`

export const ContainerBotoesStep = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 503px) {
    flex-direction: column;
    align-items: center;

    & > div {
      flex: 1;
      width: 100%;

      & > button {
        width: 100%;
      }
    }

    & > div:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`

export const ContainerBotaoLink = styled.div`
  width: fit-content;
  margin-bottom: 20.7px;

  button {
    padding-left: 0px;
  }
`

export const ConteudoBotaoLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  & > svg {
    margin-right: 10px;
  }

  & > svg:last-child {
    margin-right: 0px;
    margin-left: 10px;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }

  & > p {
    text-align: left;
    font-size: 12px;
    line-height: 125%;
  }
`
