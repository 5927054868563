import React, { useState, forwardRef } from 'react'

import { Input } from '../index'
import { mesAnoEhValido } from './core'
import { Componente } from './styles'
import { InputMesAnoProps } from './tipos'

export const InputMesAno: React.ForwardRefExoticComponent<InputMesAnoProps> = forwardRef<
  HTMLInputElement,
  InputMesAnoProps
>(
  (
    {
      id,
      onChange,
      tamanho,
      label,
      descricao,
      erro,
      disabled,
      placeholder,
      maxWidthInput,
      readOnly,
      onClickIcone,
      textoTooltip,
      defaultValue = '',
      formatoAno = 'AAAA',
      ...rest
    },
    ref?: any
  ) => {
    const [mesAno, defineMesAno] = useState(defaultValue)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let valor = event.target.value

      if (!valor) {
        defineMesAno('')
        return
      }

      if (valor === mesAno || valor.length > 7 || parseInt(valor[0]) > 1) {
        return
      }
      if (mesAnoEhValido(valor, formatoAno)) {
        if (
          valor.length === 2 &&
          mesAno.length !== 3 &&
          valor.indexOf('/') === -1
        ) {
          valor = valor + '/'
        }
        if (valor.length === 2 && mesAno.length === 3) {
          valor = valor.slice(0, 1)
        }

        defineMesAno(valor)
        if (onChange) onChange(event)
      }
    }

    return (
      <Componente>
        <Input
          ref={ref}
          textoTooltip={textoTooltip}
          onClickIcone={onClickIcone}
          readOnly={readOnly}
          maxWidthInput={maxWidthInput}
          tamanho={tamanho}
          erro={erro}
          id={`input-${id}`}
          label={label}
          descricao={descricao}
          type="tel"
          disabled={disabled}
          placeholder={placeholder}
          value={mesAno}
          onChange={handleChange}
          {...rest}
        />
      </Componente>
    )
  }
)
