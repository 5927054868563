import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PesquisaSatisfacaoInstitucional } from 'src/paginas'
import {
  PaginaCupons,
  PaginaCadastroCupons,
  PaginaDisciplinas,
  PaginaCadastroDisciplinas,
  PaginaInicialIpgs,
  PaginaCursos,
  PaginaCadastroCursos,
  PaginaTurmas,
  PaginaCadastroTurma,
  PaginaAlunos,
  PaginaNotasPresenca,
  PaginaVerAlunos,
  PaginaVerAlunosPresencial,
  PaginaVerAlunosTCC,
  PaginaVerAlunosPresencialTCC,
  PaginaSituacaoFinanceiraAlunos,
  PaginaMatriculadosTurma,
  PaginaGrupoDiscussao,
  PaginaGrupoDiscussaoLivre,
  VisualizarAluno,
  PaginaCadastroProfessores,
  PaginaProfessores,
  PaginaPesquisaSatisfacao,
  PaginaCadastroPesquisaSatisfacao,
  PaginaAproveitamentoDisciplina,
  PaginaFilasTrabalho,
  PaginaHistoricoTicket,
  PaginaDocumentosAluno,
  PaginaCuponsDesconto,
  PaginaMatriculadosCursosLivres,
  PaginaProrrogacao,
  PaginaHistoricoEscolar,
  DocumentosResponsavelFinanceiro,
  PaginaFinalizacao,
  PaginaAcoesFinalizacao,
  PaginaEnvioNotasTCC,
  PaginaRecorrencia,
  PaginaRelatorioFinanceiroGeral,
  PaginaRelatorioPesquisaSatisfacao,
  PaginaNotas,
  PaginaCadastroEmentasIPGS,
  PaginaSplitPagamento,
  PaginaCadastroSplitPagamento
} from 'src/paginas/ipgs'
import {
  DocumentosAlunoBrasileiro,
  DocumentosAlunoEstrangeiro,
  DocumentosProfessorBrasileiro,
  DocumentosProfessorEstrangeiro
} from 'src/paginas/ipgs/alunos/documentos'
import { PaginaRelatorioComercial } from 'src/paginas/ipgs/relatorios/comercial'
import { ModoPaginaCadastro } from 'src/tipos'

const RotaBase = 'academico'

export const RotasAcademico = {
  Dashboard: `/${RotaBase}`,
  Cupons: `/${RotaBase}/cupons`,
  Disciplinas: `/${RotaBase}/disciplinas`,
  Professores: `/${RotaBase}/professores`,
  Cursos: `/${RotaBase}/cursos`,
  Turmas: `/${RotaBase}/turmas`,
  Alunos: `/${RotaBase}/alunos`,
  GrupoDiscussao: `/${RotaBase}/grupo-discussao`,
  GrupoDiscussaoLivre: `/${RotaBase}/grupo-discussao/livre`,
  PesquisaSatisfacao: `/${RotaBase}/pesquisa-satisfacao`,
  FilasTrabalho: `/${RotaBase}/filas-trabalho`,
  HistoricoTicket: `/${RotaBase}/fila-trabalho-historico`,
  ResponsavelFinanceiro: `/${RotaBase}/responsavel-financeiro`,
  SplitPagamento: `/${RotaBase}/split-pagamento`
}

export const RotasRelatorios = {
  DocumentosAluno: `/${RotaBase}/relatorio/documentos-aluno`,
  EnviosNotasTCC: `/${RotaBase}/relatorio/envios-notas-tcc`,
  Finalizacao: `/${RotaBase}/relatorio/finalizacao`,
  HistoricoEscolar: `/${RotaBase}/relatorio/historico-escolar`,
  PesquisaSatisfacao: `/${RotaBase}/relatorio/pesquisa-satisfacao`,
  Prorrogacao: `/${RotaBase}/relatorio/prorrogacao`,
  Recorrencia: `/${RotaBase}/relatorio/recorrencia`,
  SituacaoFinanceiraAlunos: `/${RotaBase}/relatorio/situacao-financeira`,
  SituacaoFinanceiraAlunosGeral: `/${RotaBase}/relatorio/situacao-geral-financeira`,
  CuponsDesconto: `/${RotaBase}/relatorio/relatorio-cupons`,
  RelatorioComercial: `/${RotaBase}/relatorio/comercial`,
  MatriculadosCursoLivre: `/${RotaBase}/relatorio/matriculados-cursos-livres`,
  Notas: `/${RotaBase}/relatorio/notas`,
  MatriculadosTurma: `/${RotaBase}/relatorio/matriculados-turmas`
}

export const RotasSplitPagamento = {
  Cadastro: `${RotasAcademico.SplitPagamento}/cadastro`,
  Edicao: `${RotasAcademico.SplitPagamento}/edicao`
}

export const RotasCupons = {
  Cadastro: `${RotasAcademico.Cupons}/cadastro`,
  Edicao: `${RotasAcademico.Cupons}/edicao`
}

export const RotasDisciplina = {
  Cadastro: `${RotasAcademico.Disciplinas}/cadastro`,
  Edicao: `${RotasAcademico.Disciplinas}/edicao`
}

export const RotasProfessores = {
  Cadastro: `${RotasAcademico.Professores}/cadastro`,
  Edicao: `${RotasAcademico.Professores}/edicao`,
  DocumentosProfessorBrasileiro: `${RotasAcademico.Professores}/documentos/brasileiro`,
  DocumentosProfessorEstrangeiro: `${RotasAcademico.Professores}/documentos/estrangeiro`
}

export const RotasCursos = {
  Cadastro: `${RotasAcademico.Cursos}/cadastro`,
  Edicao: `${RotasAcademico.Cursos}/edicao`
}

export const RotasTurmas = {
  Cadastro: `${RotasAcademico.Turmas}/cadastro`,
  Edicao: `${RotasAcademico.Turmas}/edicao`,
  NotasPresenca: `${RotasAcademico.Turmas}/notas-presenca`,
  VerTurmasPresenciais: `${RotasAcademico.Turmas}/alunos`,
  VerTurmasEad: `${RotasAcademico.Turmas}/alunos-ead`,
  VerTurmasPresenciaisTCC: `${RotasAcademico.Turmas}/alunos/tcc`,
  VerTurmasEadTCC: `${RotasAcademico.Turmas}/alunos-ead/tcc`,
  AproveitamentoDisciplina: `${RotasAcademico.Turmas}/alunos/aproveitamento-disciplina`,
  AcoesFinalizacao: `${RotasAcademico.Turmas}/finalizacao`
}

export const RotasAlunos = {
  Cadastro: `${RotasAcademico.Alunos}/cadastro`,
  Edicao: `${RotasAcademico.Alunos}/edicao`,
  Visualizar: `${RotasAcademico.Alunos}/aluno`,
  DocumentosAlunoBrasileiro: `${RotasAcademico.Alunos}/documentos/brasileiro`,
  DocumentosAlunoEstrangeiro: `${RotasAcademico.Alunos}/documentos/estrangeiro`
}

export const RotasRequerimentos = {
  CadastroEmentasDisciplinas: `${RotasAcademico.FilasTrabalho}/requerimentos/ementa-disciplina`
}

export const RotasPesquisaSatisfacao = {
  Cadastro: `${RotasAcademico.PesquisaSatisfacao}/cadastro`,
  Edicao: `${RotasAcademico.PesquisaSatisfacao}/edicao`,
  Institucional: `${RotasAcademico.PesquisaSatisfacao}/institucional`
}

export const RotasResponsavelFinanceiro = {
  Documentos: `${RotasAcademico.ResponsavelFinanceiro}/documentos`
}

export const AcademicoRotas: React.FC = () => {
  return (
    <Switch>
      <Route exact path={RotasAcademico.Dashboard}>
        <PaginaInicialIpgs />
      </Route>
      <Route exact path={RotasAcademico.Cupons}>
        <PaginaCupons />
      </Route>
      <Route exact path={RotasAcademico.SplitPagamento}>
        <PaginaSplitPagamento />
      </Route>
      <Route path={RotasSplitPagamento.Cadastro}>
        <PaginaCadastroSplitPagamento modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route path={`${RotasSplitPagamento.Edicao}/:id`}>
        <PaginaCadastroSplitPagamento modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route path={RotasCupons.Cadastro}>
        <PaginaCadastroCupons modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route path={`${RotasCupons.Edicao}/:id`}>
        <PaginaCadastroCupons modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route exact path={RotasAcademico.Disciplinas}>
        <PaginaDisciplinas />
      </Route>
      <Route path={RotasDisciplina.Cadastro}>
        <PaginaCadastroDisciplinas modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route path={`${RotasDisciplina.Edicao}/:id`}>
        <PaginaCadastroDisciplinas modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route exact path={RotasAcademico.Professores}>
        <PaginaProfessores />
      </Route>
      <Route path={RotasProfessores.Cadastro}>
        <PaginaCadastroProfessores modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route path={`${RotasProfessores.Edicao}/:id`}>
        <PaginaCadastroProfessores modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route exact path={RotasAcademico.Cursos}>
        <PaginaCursos />
      </Route>
      <Route path={RotasCursos.Cadastro}>
        <PaginaCadastroCursos modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route path={`${RotasCursos.Edicao}/:id/:modalidade?`}>
        <PaginaCadastroCursos modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route exact path={RotasAcademico.Turmas}>
        <PaginaTurmas />
      </Route>
      <Route path={`${RotasTurmas.Cadastro}`}>
        <PaginaCadastroTurma modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route exact path={`${RotasTurmas.Edicao}/:id`}>
        <PaginaCadastroTurma modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route exact path={RotasAcademico.Alunos}>
        <PaginaAlunos />
      </Route>
      <Route exact path={`${RotasTurmas.NotasPresenca}/:idDaTurma`}>
        <PaginaNotasPresenca />
      </Route>
      <Route
        exact
        path={`${RotasTurmas.VerTurmasPresenciais}/:idDaDisciplina/:idDaTurma/:idDoModulo`}
      >
        <PaginaVerAlunosPresencial />
      </Route>
      <Route
        exact
        path={`${RotasTurmas.VerTurmasEad}/:idDaDisciplina/:idDaTurma/:idDoModulo`}
      >
        <PaginaVerAlunos />
      </Route>
      <Route
        exact
        path={`${RotasTurmas.VerTurmasPresenciaisTCC}/:idDaDisciplina/:idDaTurma/:idDoModulo`}
      >
        <PaginaVerAlunosPresencialTCC />
      </Route>
      <Route
        exact
        path={`${RotasTurmas.VerTurmasEadTCC}/:idDaDisciplina/:idDaTurma/:idDoModulo`}
      >
        <PaginaVerAlunosTCC />
      </Route>
      <Route path={`${RotasAlunos.Visualizar}/:id`}>
        <VisualizarAluno />
      </Route>
      <Route exact path={`${RotasAlunos.DocumentosAlunoBrasileiro}/:email`}>
        <DocumentosAlunoBrasileiro />
      </Route>
      <Route exact path={`${RotasAlunos.DocumentosAlunoEstrangeiro}/:email`}>
        <DocumentosAlunoEstrangeiro />
      </Route>
      <Route exact path={`${RotasAcademico.GrupoDiscussao}/:id`}>
        <PaginaGrupoDiscussao />
      </Route>
      <Route exact path={`${RotasAcademico.GrupoDiscussaoLivre}/:id`}>
        <PaginaGrupoDiscussaoLivre />
      </Route>
      <Route exact path={RotasRelatorios.SituacaoFinanceiraAlunos}>
        <PaginaSituacaoFinanceiraAlunos />
      </Route>
      <Route exact path={RotasRelatorios.Recorrencia}>
        <PaginaRecorrencia />
      </Route>
      <Route exact path={RotasRelatorios.MatriculadosTurma}>
        <PaginaMatriculadosTurma />
      </Route>
      <Route exact path={RotasRelatorios.MatriculadosCursoLivre}>
        <PaginaMatriculadosCursosLivres />
      </Route>
      <Route exact path={RotasRelatorios.RelatorioComercial}>
        <PaginaRelatorioComercial />
      </Route>
      <Route exact path={RotasAcademico.PesquisaSatisfacao}>
        <PaginaPesquisaSatisfacao />
      </Route>
      <Route path={RotasPesquisaSatisfacao.Cadastro}>
        <PaginaCadastroPesquisaSatisfacao modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route path={`${RotasPesquisaSatisfacao.Edicao}/:id`}>
        <PaginaCadastroPesquisaSatisfacao modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route exact path={`${RotasPesquisaSatisfacao.Institucional}/:id`}>
        <PesquisaSatisfacaoInstitucional />
      </Route>
      <Route
        exact
        path={`${RotasTurmas.AproveitamentoDisciplina}/:idDaDisciplina/:idDaTurma/:idDoModulo`}
      >
        <PaginaAproveitamentoDisciplina />
      </Route>
      <Route exact path={RotasRelatorios.CuponsDesconto}>
        <PaginaCuponsDesconto />
      </Route>
      <Route
        exact
        path={`${RotasProfessores.DocumentosProfessorBrasileiro}/:email`}
      >
        <DocumentosProfessorBrasileiro />
      </Route>
      <Route
        exact
        path={`${RotasProfessores.DocumentosProfessorEstrangeiro}/:email`}
      >
        <DocumentosProfessorEstrangeiro />
      </Route>
      <Route exact path={RotasAcademico.FilasTrabalho}>
        <PaginaFilasTrabalho />
      </Route>
      <Route exact path={`${RotasAcademico.HistoricoTicket}/:idTicket`}>
        <PaginaHistoricoTicket />
      </Route>
      <Route exact path={RotasRelatorios.DocumentosAluno}>
        <PaginaDocumentosAluno />
      </Route>
      <Route exact path={RotasRelatorios.Prorrogacao}>
        <PaginaProrrogacao />
      </Route>
      <Route exact path={RotasRelatorios.HistoricoEscolar}>
        <PaginaHistoricoEscolar />
      </Route>
      <Route exact path={`${RotasResponsavelFinanceiro.Documentos}/:email`}>
        <DocumentosResponsavelFinanceiro />
      </Route>
      <Route exact path={RotasRelatorios.Finalizacao}>
        <PaginaFinalizacao />
      </Route>
      <Route path={`${RotasTurmas.AcoesFinalizacao}/:id`}>
        <PaginaAcoesFinalizacao />
      </Route>
      <Route exact path={RotasRelatorios.EnviosNotasTCC}>
        <PaginaEnvioNotasTCC />
      </Route>
      <Route exact path={RotasRelatorios.PesquisaSatisfacao}>
        <PaginaRelatorioPesquisaSatisfacao />
      </Route>
      <Route exact path={RotasRelatorios.SituacaoFinanceiraAlunosGeral}>
        <PaginaRelatorioFinanceiroGeral />
      </Route>
      <Route exact path={RotasRelatorios.Notas}>
        <PaginaNotas />
      </Route>
      <Route path={`${RotasRequerimentos.CadastroEmentasDisciplinas}/:id`}>
        <PaginaCadastroEmentasIPGS />
      </Route>
    </Switch>
  )
}
