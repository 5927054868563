import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  display: flex;
  height: 13px;
`

export const Barra = styled.div`
  flex-grow: 1;
  height: 100%;
  background-color: ${Cores.CINZA_1_ESCURO};
  border-radius: 6px;
  margin-right: 5px;
`

export const Progresso = styled.div<{ progresso: number }>`
  width: ${({ progresso }) => progresso}%;
  height: 100%;
  background-color: ${Cores.SECUNDARIA_3_ESCURO};
  border-radius: 6px;
`

export const Texto = styled.div`
  flex-shrink: 0;
  font-size: 12px;
  color: ${Cores.CINZA_1_ESCURO};
`
