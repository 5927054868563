import React from 'react'
import { useHistory } from 'react-router-dom'

import { RotasAluno } from 'src/rotas/aluno'
import { StatusCarrinhoCompra } from 'src/tipos'

import { Botao } from '..'

import {
  Container,
  ContainerDoLink,
  ContainerSiglas,
  TextoSituacao
} from './styles'
import { ItemCarrinhoCursoLivreProps } from './tipos'

export const ItemCarrinhoCursoLivre: React.FC<ItemCarrinhoCursoLivreProps> = ({
  id,
  className,
  category,
  main,
  statusCarrinhoCompra,
  siglasCursos,
  aguardandoPagamento
}) => {
  const history = useHistory()
  const resolveLinkFinalizarMatricula = (siglasCursos: string[]) => {
    if (aguardandoPagamento) {
      return `${RotasAluno.FinanceiroCursoLivre}/carrinho-compra/${id}`
    } else {
      const siglasQuery = siglasCursos.map(s => `sigla=${s}`)
      return `/matricula/curso-livre/carrinho?${siglasQuery.join('&')}`
    }
  }

  const finalizarMatricula = () => {
    history.push(resolveLinkFinalizarMatricula(siglasCursos))
  }

  const linkDeAcesso = () => {
    const ehMatriculaFinalizada = (): boolean => {
      if (
        statusCarrinhoCompra ===
        StatusCarrinhoCompra[StatusCarrinhoCompra.Finalizado]
      ) {
        return true
      }
      return false
    }

    const paraTextoApresentacao = (statusCarrinhoCompra: string): string => {
      switch (statusCarrinhoCompra) {
        case StatusCarrinhoCompra[StatusCarrinhoCompra.Finalizado]:
          return 'Finalizado'
        case StatusCarrinhoCompra[StatusCarrinhoCompra.Aberto]:
          return 'Aguardando'
        default:
          return StatusCarrinhoCompra[statusCarrinhoCompra]
      }
    }

    return ehMatriculaFinalizada() ? (
      <TextoSituacao>
        {paraTextoApresentacao(statusCarrinhoCompra)}
      </TextoSituacao>
    ) : (
      <Botao
        type="button"
        id="btn-finalizar-matricula"
        texto="Clique aqui e finalize sua matrícula"
        tema="Link"
        style={{ padding: '4px 0' }}
        onClick={() => finalizarMatricula()}
      />
    )
  }

  return (
    <Container className={className} main={main}>
      <div className="conteudo">
        <ContainerSiglas>
          <h4>{category}</h4>
          {siglasCursos.map(s => (
            <h5 key={s} className="sigla">
              {s}
            </h5>
          ))}
        </ContainerSiglas>
        <ContainerDoLink>{linkDeAcesso()}</ContainerDoLink>
      </div>
    </Container>
  )
}
