import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const ContainerMain = styled.div`
  width: 97%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const Conteudo = styled.div`
  margin-top: 18px;
  margin-left: 30px;
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDadosGrande = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosMedio = styled.div`
  width: 50%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosPequeno = styled.div`
  max-width: 25%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosSelectReingresso = styled.div`
  width: 75%;
`

export const ContainerDadosCheckboxReingresso = styled.div`
  width: 23%;
  margin-right: 2%;
  display: flex;
  align-items: center;
`

export const ContainerDadosReingresso = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  height: 61px;
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 19px;
  }
`

export const ContainerLateral = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 63px;
`
export const Secao = styled.section`
  margin-top: 30px;
`

export const Linha = styled.div`
  display: flex;

  & > div:not(:first-child) {
    margin-left: 10px;
  }

  .sm {
    flex: 1;
  }

  .md {
    flex: 2;
  }

  .lg {
    flex: 3;
  }
`
export const ContainerTextArea = styled.div`
  label {
    display: flex;
    div {
      display: flex;
      svg {
        margin-left: 10px;
        margin-top: 1px;
      }
    }
  }
`

export const LabelPesquisaSatisfacaoProfessores = styled.label`
  display: flex;

  > div {
    margin-left: 10px;
  }
`
