import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect
} from 'react'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { isEqualWith, isNull } from 'lodash'
import * as Yup from 'yup'

import { FormUnformProps, FormRef } from './tipos'

export const FormUnform: React.ForwardRefExoticComponent<FormUnformProps> = forwardRef<
  FormRef,
  React.HTMLAttributes<HTMLFormElement> & FormUnformProps
>(
  (
    { schema, children, acaoSucesso, acaoFalha, dadosIniciais, ...rest },
    ref: any
  ) => {
    const unformRef = useRef<FormHandles>(null)
    const dadosUnform = useRef(null)

    useEffect(() => {
      dadosUnform.current = dadosIniciais ?? unformRef.current.getData()
    }, [])

    const limparItensNulosEmArrays = (dados: any) => {
      Object.keys(dados).forEach(propriedade => {
        if (dados[propriedade] instanceof Array) {
          dados[propriedade] = (dados[propriedade] as any[]).filter(i => i)
        }
      })
      return dados
    }

    const manipularEnvio = async (dados: any): Promise<void> => {
      if (!schema) {
        acaoSucesso(dados)
        return
      }

      try {
        unformRef.current.setErrors({})

        await schema.validate(dados, {
          abortEarly: false
        })

        const dadosAtualizados = limparItensNulosEmArrays(dados)

        await Promise.all([acaoSucesso(dadosAtualizados)])

        dadosUnform.current = dadosAtualizados
      } catch (err) {
        const validationErrors = {}

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message
          })

          unformRef.current.setErrors(validationErrors)

          if (acaoFalha) {
            acaoFalha(validationErrors)
          }
        }
      }
    }

    const CompararNuloEStringVazia = (a: any, b: any) => {
      if ((isNull(a) || a === '') && (isNull(b) || b === '')) return true

      // deve retornar undefined
    }

    const formAlterado = (): boolean =>
      !isEqualWith(
        dadosUnform.current,
        unformRef.current.getData(),
        CompararNuloEStringVazia
      )

    useImperativeHandle<FormRef, FormRef>(ref, () => ({
      ...unformRef.current,
      hasChanges: formAlterado
    }))

    return (
      <Form
        ref={unformRef}
        onSubmit={manipularEnvio}
        initialData={dadosIniciais}
        noValidate={true}
        {...rest}
      >
        {children}
      </Form>
    )
  }
)
