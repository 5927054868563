import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaFeedBackProfessor = (): TabelaColuna => ({
  Chave: 'feedBackProfessor',
  ComOrdenacao: true,
  StyleColuna: { textAlign: 'justify' },
  RenderizarCabecalho: () => <div style={{ width: 400 }}>FeedBack</div>
})
