import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'

const caracter = Yup.string().required(mensagemPadrao)

export const schema = Yup.object().shape({
  cpf: Yup.string().cpf().required(mensagemPadrao),
  situacao: Yup.number().required(mensagemPadrao),
  nome: caracter,
  nomeSocial: Yup.string()
    .nullable()
    .when('possuiNomeSocial', {
      is: true,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required(mensagemPadrao),
  perfis: Yup.array().required(mensagemPadrao)
})

export const schemaEdicao = Yup.object().shape({
  cpf: Yup.string().cpf().notRequired(),
  situacao: Yup.number().required(mensagemPadrao),
  nome: caracter,
  nomeSocial: Yup.string()
    .nullable()
    .when('possuiNomeSocial', {
      is: true,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required(mensagemPadrao),
  perfis: Yup.array().required(mensagemPadrao)
})
