import React, { useMemo } from 'react'

import {
  DragAndDrop,
  IconeAdicionar,
  SubTitulo,
  Informacao,
  Spacer
} from 'src/componentes'
import { Material } from 'src/tipos'

import PaginaCadastroTurmaContainer from '../../../container'
import { Linha } from '../../../styles'
import { BotaoEstilizado, Container, LinhaTabela } from './styles'
import { ListaMateriaisProps, MaterialDraggable } from './tipos'

export const ListaMateriais: React.FC<ListaMateriaisProps> = ({
  idDisciplina,
  tipoMaterialComplementar
}) => {
  const {
    modalIncluirMaterialRef,
    modalExcluirMaterialRef,
    materiais,
    bloquearCampo,
    definirMateriais,
    adicionarAlteracaoMaterial
  } = PaginaCadastroTurmaContainer.useContainer()

  const materiaisAtuais = useMemo(() => {
    return materiais
      .filter(
        x =>
          x.idDisciplina === idDisciplina ||
          (x.tipoMaterialComplementar &&
            x.tipoMaterialComplementar === tipoMaterialComplementar)
      )
      .sort((a, b) => a.ordem - b.ordem)
  }, [materiais, idDisciplina, tipoMaterialComplementar])

  const outrosMateriais = useMemo(() => {
    if (tipoMaterialComplementar) {
      return materiais.filter(x => x.tipoMaterialComplementar !== idDisciplina)
    } else {
      return materiais.filter(x => x.idDisciplina !== idDisciplina)
    }
  }, [materiais, idDisciplina])

  const renderMaterial = (material: Material) => {
    return (
      <LinhaTabela>
        <p>{material.titulo}</p>
      </LinhaTabela>
    )
  }

  const adicionarMaterial = (items: MaterialDraggable[]) => {
    definirMateriais([
      ...outrosMateriais,
      ...items.map((x, i) => ({ ...x, ordem: i }))
    ])
    adicionarAlteracaoMaterial(idDisciplina, tipoMaterialComplementar)
  }

  const editarMaterial = (index: number) => {
    const item = materiaisAtuais[index]
    modalIncluirMaterialRef.current?.abrir(
      materiaisAtuais.map(x => x.titulo).filter(x => x !== item.titulo),
      item as Material,
      index,
      idDisciplina,
      tipoMaterialComplementar
    )
  }

  const removerMaterial = (index: number) => {
    modalExcluirMaterialRef.current?.abrir(
      index,
      idDisciplina,
      tipoMaterialComplementar
    )
  }

  return (
    <Container>
      <SubTitulo texto="Materiais da Disciplina" />
      <Linha>
        <Informacao>
          Arraste e solte os itens para ordená-los. Esta será a ordem de
          exibição dos materiais na sala de aula virtual.
        </Informacao>
        {!bloquearCampo && (
          <BotaoEstilizado
            type="button"
            onClick={() =>
              modalIncluirMaterialRef.current?.abrir(
                materiaisAtuais.map(x => x.titulo),
                null,
                materiaisAtuais?.length ?? 0,
                idDisciplina,
                tipoMaterialComplementar
              )
            }
            elemento={<>{IconeAdicionar}Incluir Material</>}
            tema="Secundario"
          />
        )}
      </Linha>
      <Spacer padding="0px 0px 20px" />
      <DragAndDrop<MaterialDraggable>
        render={item => renderMaterial(item)}
        items={(materiaisAtuais as MaterialDraggable[]).map((x, i) => {
          x.draggableId = x.id

          if (x.ordem === null || x.ordem === undefined) {
            x.ordem = i
          }

          return x
        })}
        setItems={bloquearCampo ? undefined : adicionarMaterial}
        editar={bloquearCampo ? undefined : editarMaterial}
        remover={bloquearCampo ? undefined : removerMaterial}
      />
    </Container>
  )
}
