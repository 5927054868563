import styled, { css } from 'styled-components'

import { Cores } from '../styles/cores'
import { CheckboxTemas, CheckboxTamanhos } from './tipos'

export interface ContainerProps {
  tema: CheckboxTemas | string
  tamanho: CheckboxTamanhos | string
  readOnly: boolean
  disabled: boolean
}

const Padrao = css`
  & input:disabled ~ label .seleciona-checkbox {
    background: ${Cores.CINZA_3_CLARO};
    border: 1px solid ${Cores.CINZA_3};
    opacity: 0.36;
    cursor: not-allowed;
  }

  & input:disabled:active ~ label .seleciona-checkbox {
    background: ${Cores.CINZA_3_CLARO};
    border: 1px solid ${Cores.CINZA_3};
  }

  & input:focus ~ label .seleciona-checkbox {
    border: 1px solid ${Cores.PRIMARIA_2};
    box-shadow: inset 0 0 0 1px ${Cores.CINZA_3_ESCURO};
    background: ${Cores.BRANCO};
  }

  & input:active ~ label .seleciona-checkbox {
    border: 1px solid ${Cores.TRANSPARENTE};
    background: ${Cores.SECUNDARIA_3_ESCURO};
  }

  & input:checked ~ label .seleciona-checkbox:hover {
    visibility: visible;
    background: ${Cores.SECUNDARIA_3_ESCURO};

    .icone-check {
      svg > path {
        fill: ${Cores.BRANCO};
      }
    }
  }

  & input:checked ~ label .icone-check {
    visibility: visible;
  }

  & input:checked ~ label .seleciona-checkbox {
    background: ${Cores.BRANCO};
    border: 1px solid ${Cores.CINZA_3};
    visibility: visible;
    transition: 0.15s;

    .icone-check {
      svg > path {
        fill: ${Cores.SECUNDARIA_3_ESCURO};
      }
    }

    &:hover .icon-check {
      visibility: visible;
    }
  }

  .seleciona-checkbox:hover {
    background: ${Cores.CINZA_3_CLARO};
  }
`

const Secundario = css`
  &:hover {
    .seleciona-checkbox {
      .check {
        display: block;
        background: ${Cores.CINZA_3};
      }
    }
  }

  & input:disabled ~ label .seleciona-checkbox {
    opacity: 0.36;
    pointer-events: none;
    cursor: not-allowed;
  }

  & input:checked {
    & ~ label .seleciona-checkbox {
      border: 1px solid ${Cores.PRIMARIA_2_ESCURO};

      .check {
        display: block;
        background: ${Cores.PRIMARIA_2_ESCURO};
      }
    }
  }

  &:hover input:checked {
    & ~ label .seleciona-checkbox {
      border: 1px solid ${Cores.PRIMARIA_1};

      .check {
        display: block;
        background: ${Cores.PRIMARIA_1};
      }
    }
  }
`

const Outro = css`
  & > label > span {
    padding: 13px;
  }

  & input:disabled ~ label .seleciona-checkbox {
    background: ${Cores.BRANCO};
    border: 1px solid ${Cores.PRETO};
    opacity: 0.36;
    cursor: not-allowed;
  }

  & input:disabled:active ~ label .seleciona-checkbox {
    background: ${Cores.BRANCO};
    border: 1px solid ${Cores.PRETO};
  }

  & input:focus ~ label .seleciona-checkbox {
    border: 1px solid ${Cores.VERDE};
    box-shadow: inset 0 0 0 1px ${Cores.BRANCO};
    background: ${Cores.VERDE};

    .icone-check {
      svg > path {
        fill: ${Cores.BRANCO};
      }
    }
  }

  & input:active ~ label .seleciona-checkbox {
    border: 1px solid ${Cores.PRETO};
    background: ${Cores.VERDE};
  }

  & input:checked ~ label .seleciona-checkbox:hover {
    visibility: visible;
    background: ${Cores.VERDE};

    .icone-check {
      svg > path {
        fill: ${Cores.BRANCO};
      }
    }
  }

  & input:checked ~ label .icone-check {
    visibility: visible;
  }

  & input:checked ~ label .seleciona-checkbox {
    background: ${Cores.VERDE};
    border: 1px solid ${Cores.VERDE};
    visibility: visible;
    transition: 0.15s;

    .icone-check {
      svg > path {
        fill: ${Cores.BRANCO};
      }
    }

    &:hover .icon-check {
      visibility: visible;
    }
  }

  .seleciona-checkbox:hover {
    background: ${Cores.BRANCO};
  }
`

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ readOnly }) =>
    readOnly &&
    `
      pointer-events: none;
    `}

  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
    `}

  & input {
    margin: 0;
    display: none;
  }

  label {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    font-family: 'Saira', sans-serif;
    font-size: 14px;
    line-height: 100%;
    color: ${Cores.CINZA_2};
    cursor: pointer;
  }

  ${({ tema }) => {
    switch (tema) {
      case 'Padrao':
        return Padrao
      case 'Secundario':
        return Secundario
      case 'Outro':
        return Outro
      default:
        return Padrao
    }
  }}

  .seleciona-checkbox {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px
      ${props => (props.tema === 'Outro' ? Cores.PRETO : Cores.CINZA_3_ESCURO)};
    border-radius: ${props => (props.tema === 'Outro' ? '50%' : '4px')};
    margin-right: 8px;
    background: ${Cores.BRANCO};
    transition: 0.15s;

    .icone-check svg {
      position: ${props => (props.tema === 'Outro' ? 'unset' : 'absolute')};
    }

    ${({ tamanho }) => {
      switch (tamanho) {
        case 'S':
          return css`
            width: 24px;
            height: 24px;

            .icone-check {
              width: 18px;
              height: 12px;
            }

            .icone-check svg {
              width: 18px;
              height: 12px;
            }

            .check {
              display: none;
              border-radius: 4px;
              width: 16px;
              height: 16px;
            }
          `
        case 'M':
          return css`
            width: 27px;
            height: 27px;

            .icone-check {
              width: 19px;
              height: 14px;
            }

            .icone-check svg {
              width: 19px;
              height: 14px;
            }

            .check {
              display: none;
              border-radius: 4px;
              width: 18px;
              height: 18px;
            }
          `
        default:
          return css`
            width: 30px;
            height: 30px;

            .icone-check {
              height: 14px;
              width: 20px;
              visibility: hidden;
            }

            .check {
              display: none;
              border-radius: 4px;
              width: 21px;
              height: 20px;
            }
          `
      }
    }}

    @media screen and (max-width: 1200px) {
      .seleciona-checkbox {
        width: 27px;
        height: 27px;
      }

      .icone-check {
        width: 19px;
        height: 14px;
      }

      .icone-check svg {
        width: 19px;
        height: 14px;
      }
    }

    @media screen and (max-width: 768px) {
      .seleciona-checkbox {
        width: 24px;
        height: 24px;
      }

      .icone-check {
        width: 18px;
        height: 12px;
      }

      .icone-check svg {
        width: 18px;
        height: 12px;
      }
    }
  }
`
