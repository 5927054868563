import axios from 'axios'
import { Endereco, EnderecoViaCepDTO, PaisResidencia } from 'src/tipos'

export const BuscarCep = async (cep: string): Promise<Endereco> => {
  const novoCep = cep.replace('-', '')
  if (novoCep.length !== 8) {
    return null
  }

  const { data } = await axios.get<EnderecoViaCepDTO>(
    `https://viacep.com.br/ws/${novoCep}/json/`
  )

  if (data.uf === undefined) {
    return null
  }

  return {
    cep: data.cep,
    bairro: data.bairro,
    cidade: data.localidade,
    logradouro: data.logradouro,
    complemento: data.complemento,
    uf: data.uf,
    pais: PaisResidencia.Brasil,
    numero: ''
  }
}
