import { animated } from 'react-spring'

import styled, { css } from 'styled-components'

import { Cores } from '../styles/cores'
import { BotaoProps } from './tipos'

export const Container = styled.div`
  position: relative;
  display: inline-block;
`

export const CampoImagem = styled.span<CampoImagemProps>`
  pointer-events: none;

  ${({ PossuiElemento, PossuiTexto }) => css`
    & > svg {
      display: ${PossuiElemento ? 'block' : 'none'};
      margin-right: ${PossuiTexto ? '6px' : '0px'};
    }
  `}
`

export const ConteudoBotao = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ComponenteBotao = styled('button')<BotaoProps>`
  position: relative;
  border: 2px solid ${Cores.TRANSPARENTE};
  border-radius: 3px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 40px;
  padding: 17px 18px;

  ${({ tamanho: Tamanho, texto: Texto, tema: Tema }) => {
    if (Texto) {
      switch (Tamanho) {
        case 'S':
          return css`
            padding: 14px 18px;
            font-size: ${Tema === 'Link' ? '14px' : '14px'};
          `
        case 'M':
          return css`
            padding: 16.5px 20px;
            font-size: ${Tema === 'Link' ? '14px' : '14px'};
          `
        default:
          return css`
            max-height: 40px;
            padding: 14px 18px;
            font-size: ${Tema === 'Link' ? '14px' : '14px'};
            line-height: 14px; ;
          `
      }
    }
  }}

  ${({ tema: Tema, carregando: Carregando }) => {
    switch (Tema) {
      case 'Secundario':
        return css`
          border: 2px solid
            ${Carregando ? Cores.SECUNDARIA_1 : Cores.SECUNDARIA_1};
          background: ${Cores.BRANCO};
          color: ${Cores.SECUNDARIA_1};

          &:hover:not(.sucesso):not(.erro) {
            color: ${Cores.BRANCO};
            background: ${Carregando ? Cores.BRANCO : Cores.SECUNDARIA_1};
            border: 2px solid ${Cores.SECUNDARIA_1};
            outline: 0;

            & > ${CampoImagem} > svg {
              fill: ${Cores.BRANCO};

              & path {
                fill: ${Cores.BRANCO};
              }

              & rect {
                fill: ${Cores.BRANCO};
              }
            }
          }

          &:focus:not(.sucesso):not(.erro) {
            color: ${Cores.SECUNDARIA_1};
            border: 2px solid ${Cores.SECUNDARIA_1};
            box-shadow: inset 0 0 0 2px ${Cores.CINZA_2};
            outline: 0;

            &:hover {
              color: ${Cores.BRANCO};
              border: 2px solid ${Cores.SECUNDARIA_1};
            }
          }

          &:active:not(.sucesso):not(.erro) {
            color: ${Cores.BRANCO};
            background: ${Cores.CINZA_2_ESCURO};
            border: 2px solid ${Cores.TRANSPARENTE};
            box-shadow: inset 0 0 0 2px ${Cores.TRANSPARENTE};
            outline: 0;
          }

          &:disabled:not(.sucesso):not(.erro) {
            color: ${Cores.CINZA_2_36};
            border: 2px solid ${Cores.CINZA_2_36};
            cursor: not-allowed;

            & > ${CampoImagem} > svg {
              fill: ${Cores.CINZA_2_36};
            }

            & > span {
              visibility: visible;
            }

            & > i {
              visibility: hidden;
            }

            &:active:not(.sucesso):not(.erro) {
              border: 2px solid ${Cores.CINZA_2_36};
              pointer-events: none;
            }
          }

          &[disabled]:hover:not(.sucesso):not(.erro) {
            border: 2px solid ${Cores.TRANSPARENTE};
            color: ${Cores.CINZA_2_36};
          }

          & i svg circle {
            stroke: ${Cores.CINZA_2};
          }

          & > ${CampoImagem} > svg {
            fill: ${Cores.CINZA_2};
          }

          &.sucesso {
            border: 2px solid ${Cores.SUCESSO};
            background: ${Cores.TRANSPARENTE};

            .icone_feedback > svg > path {
               {
                fill: ${Cores.SUCESSO};
              }
            }
            pointer-events: none;
          }

          &.erro {
            border: 2px solid ${Cores.PERIGO_ERRO};
            background: ${Cores.TRANSPARENTE};

            .icone_feedback > svg > path {
               {
                fill: ${Cores.PERIGO_ERRO};
              }
            }
            pointer-events: none;
          }
        `
      case 'SecundarioBranco':
        return css`
          border: 2px solid ${Carregando ? Cores.BRANCO_36 : Cores.BRANCO};
          background: ${Cores.TRANSPARENTE};
          color: ${Cores.BRANCO};

          &:hover:not(.sucesso):not(.erro) {
            & > ${CampoImagem} > svg {
              fill: ${Cores.PRIMARIA_2};

              & path {
                fill: ${Cores.PRIMARIA_2};
              }

              & rect {
                fill: ${Cores.PRIMARIA_2};
              }
            }
            color: ${Cores.PRIMARIA_2};
            background: ${Carregando ? Cores.TRANSPARENTE : Cores.BRANCO};
            border: 2px solid ${Cores.TRANSPARENTE};
          }

          &:focus:not(.sucesso):not(.erro) {
            color: ${Cores.BRANCO};
            border: 2px solid ${Cores.SECUNDARIA_2_ESCURO};
            box-shadow: inset 0 0 0 2px ${Cores.BRANCO};
            outline: 0;

            &:hover {
              color: ${Cores.PRIMARIA_2};
              border: 2px solid ${Cores.TRANSPARENTE};
            }
          }

          &:active:not(.sucesso):not(.erro) {
            color: ${Cores.PRIMARIA_2_ESCURO};
            background: ${Cores.SECUNDARIA_1};

            ${CampoImagem} {
              color: ${Cores.PRIMARIA_2_ESCURO};
            }

            ${CampoImagem} > svg {
              fill: ${Cores.PRIMARIA_2_ESCURO};

              & path {
                fill: ${Cores.PRIMARIA_2_ESCURO};
              }

              & rect {
                fill: ${Cores.PRIMARIA_2_ESCURO};
              }
            }

            &:hover {
              ${CampoImagem} {
                color: ${Cores.PRIMARIA_2_ESCURO};
              }
            }
          }

          &:disabled:not(.sucesso):not(.erro) {
            color: ${Cores.BRANCO_36};
            border: 2px solid ${Cores.BRANCO_36};
            cursor: not-allowed;

            & > ${CampoImagem} > svg {
              fill: ${Cores.BRANCO_36};
            }

            & > span {
              visibility: visible;
            }

            & > i {
              visibility: hidden;
            }

            &:active:not(.sucesso):not(.erro) {
              background: ${Cores.TRANSPARENTE};
              pointer-events: none;
            }
          }

          &[disabled]:hover:not(.sucesso):not(.erro) {
            background: ${Cores.TRANSPARENTE};
            color: ${Cores.BRANCO_36};
          }

          & i svg circle {
            stroke: ${Cores.BRANCO};
          }

          & > ${CampoImagem} > svg {
            fill: ${Cores.BRANCO};

            & path {
              fill: ${Cores.BRANCO};
            }

            & rect {
              fill: ${Cores.BRANCO};
            }
          }

          &.sucesso {
            border: 2px solid ${Cores.SUCESSO};
            background: ${Cores.TRANSPARENTE};

            .icone_feedback > svg > path {
               {
                fill: ${Cores.BRANCO};
              }
            }
            pointer-events: none;
          }

          &.erro {
            border: 2px solid ${Cores.PERIGO_ERRO};
            background: ${Cores.TRANSPARENTE};

            .icone_feedback > svg > path {
               {
                fill: ${Cores.BRANCO};
              }
            }
            pointer-events: none;
          }
        `
      case 'Outro':
        return css`
          border: 2px solid ${Cores.TRANSPARENTE};
          background: ${Cores.TRANSPARENTE};
          color: ${Cores.CINZA_2};
          border-radius: 0;
          padding: 6px;

          &:hover:not(.sucesso):not(.erro) {
            border: 2px solid ${Cores.TRANSPARENTE};
            color: ${Cores.CINZA_2_CLARO};

            & > ${CampoImagem} > svg {
              fill: ${Cores.CINZA_2_CLARO};

              & path {
                fill: ${Cores.CINZA_2_CLARO};
              }

              & rect {
                fill: ${Cores.CINZA_2_CLARO};
              }
            }
          }

          &:focus:not(.sucesso):not(.erro) {
            color: ${Cores.CINZA_2};
            border: 2px solid ${Cores.PRIMARIA_2};
            outline: 0;

            &:hover {
              ${CampoImagem} > svg {
                fill: ${Cores.CINZA_2};
              }
            }
          }

          &:active:not(.sucesso):not(.erro) {
            color: ${Cores.CINZA_2_ESCURO};
            border: 2px solid ${Cores.TRANSPARENTE};

            ${CampoImagem} {
              color: ${Cores.CINZA_2_ESCURO};
            }

            ${CampoImagem} > svg {
              fill: ${Cores.CINZA_2_ESCURO};

              & path {
                fill: ${Cores.CINZA_2_ESCURO};
              }

              & rect {
                fill: ${Cores.CINZA_2_ESCURO};
              }
            }

            &:hover {
              ${CampoImagem} {
                color: ${Cores.CINZA_2_ESCURO};
              }
            }
          }

          &:disabled:not(.sucesso):not(.erro) {
            color: ${Cores.CINZA_2_36};
            border: 2px solid ${Cores.TRANSPARENTE};
            cursor: not-allowed;

            & > ${CampoImagem} > svg {
              fill: ${Cores.CINZA_2_36};
            }

            & > span {
              visibility: visible;
            }

            & > i {
              visibility: hidden;
            }

            &:active:not(.sucesso):not(.erro) {
              border: 2px solid ${Cores.CINZA_2_36};
              pointer-events: none;
            }
          }

          & i svg circle {
            stroke: ${Cores.CINZA_2};
          }

          & > ${CampoImagem} > svg {
            fill: ${Cores.CINZA_2};
          }

          &.sucesso {
            .icone_feedback > svg > path {
               {
                fill: ${Cores.SUCESSO};
              }
            }
            pointer-events: none;
          }

          &.erro {
            .icone_feedback > svg > path {
               {
                fill: ${Cores.PERIGO_ERRO};
              }
            }
            pointer-events: none;
          }
        `
      case 'Link':
        return css`
          border: none;
          background: ${Cores.TRANSPARENTE};
          color: ${Cores.PRIMARIA_2};
          border-radius: 0;
          padding: 6px;
          text-decoration: none;
          line-height: 12px;

          &:hover {
            color: ${Cores.SECUNDARIA_2_ESCURO};
          }

          &:active {
            color: ${Cores.SECUNDARIA_2_ESCURO};
          }

          &:disabled {
            opacity: 0.36;
          }

          &:focus:not(.sucesso):not(.erro) {
            color: ${Cores.SECUNDARIA_2_ESCURO};
          }
        `
      case 'CinzaClaro':
        return css`
          padding: 11px 13px;
          justify-content: flex-start;
          background: ${Cores.CINZA_3_MAIS_CLARO};
          border: 1px dashed ${Cores.CINZA_2_CLARO};
          box-sizing: border-box;
          color: ${Cores.CINZA_2_CLARO};
          border-radius: 8px;
          display: flex;
          align-items: left;
          font-family: 'Poppins-bold', sans-serif;
          font-size: 14px;
          width: 100%;

          & > ${CampoImagem} > svg {
            fill: ${Cores.CINZA_2_CLARO};
          }
        `
      default:
        return css`
          letter-spacing: 0.05em;
          background: ${Carregando
            ? Cores.SECUNDARIA_2_ESCURO_40
            : Cores.SECUNDARIA_2_ESCURO};
          color: ${Cores.BRANCO};

          &:hover:not(.sucesso):not(.erro) {
            color: ${Cores.BRANCO};
            background: ${Cores.SECUNDARIA_2_ESCURO};

            & > ${CampoImagem} > svg {
              fill: ${Cores.BRANCO};
            }
          }

          &:focus:not(.sucesso):not(.erro) {
            border: 2px solid ${Cores.PRIMARIA_2_ESCURO};
            outline: none;
            box-shadow: none !important;
          }

          &:active:not(.sucesso):not(.erro) {
            background: ${Cores.SECUNDARIA_2_ESCURO_40};
            color: ${Cores.PRIMARIA_2_ESCURO};
            border: 2px solid ${Cores.TRANSPARENTE};

            ${CampoImagem} {
              color: ${Cores.BRANCO};
            }

            ${CampoImagem} > svg {
              fill: ${Cores.BRANCO};

              & path {
                fill: ${Cores.BRANCO};
              }

              & rect {
                fill: ${Cores.BRANCO};
              }
            }

            &:hover {
              ${CampoImagem} {
                color: ${Cores.BRANCO};
              }
            }
          }

          &:disabled:not(.sucesso):not(.erro) {
            background: ${Cores.SECUNDARIA_2_ESCURO_40};
            color: ${Cores.PRIMARIA_2_36};
            cursor: not-allowed;

            & > ${CampoImagem} > svg {
              fill: ${Cores.BRANCO};
            }

            & > span {
              visibility: visible;
            }

            & > i {
              visibility: hidden;
            }

            &:active:not(.sucesso):not(.erro) {
              border: 2px solid ${Cores.TRANSPARENTE};
              pointer-events: none;
            }
          }

          & > ${CampoImagem} > svg {
            fill: ${Cores.BRANCO};

            & path {
              fill: ${Cores.BRANCO};
            }

            & rect {
              fill: ${Cores.BRANCO};
            }
          }

          &.sucesso {
            background: ${Cores.SUCESSO};
            pointer-events: none;
          }

          &.erro {
            background: ${Cores.PERIGO_ERRO};
            pointer-events: none;
          }
        `
    }
  }}

  ${({ carregando: Carregando, tema }) => css`
    pointer-events: ${Carregando ? 'none' : 'unset'};

    .loader {
      display: ${Carregando ? 'block' : 'none'};
      z-index: 2;
      position: absolute;
      left: calc(50% - 12px);
      top: calc(50% - 12px);
      pointer-events: ${Carregando ? 'none' : 'unset'};
      color: ${tema === 'Link' ? Cores.PRIMARIA_2 : Cores.BRANCO};
    }
  `}


  ${({ feedback, carregando }) => css`
    & > ${CampoImagem} {
      opacity: ${feedback || carregando ? '0' : '1'};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
  `}
`

export const CampoFeedback = styled(animated.span)`
  display: block;
  position: absolute;
  left: calc(50% - 8px);
  top: calc(50% - 6px);
`

export interface CampoImagemProps {
  PossuiElemento: boolean
  PossuiTexto: boolean
}
