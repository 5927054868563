export const diaSemanaAbreviado = 'dom_seg_ter_qua_qui_sex_sab'.split('_')

export const diaSemanaPrimeiraLetra = 'd_s_t_q_q_s_s'.split('_')

export const mesesDoAno = 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split(
  '_'
)

export const messesDoAnoAbreviado = 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split(
  '_'
)

export const diaSemana = 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split(
  '_'
)

export const dataFormatadaComBarras = (data = new Date()): string => {
  const novaData = data.toISOString().split('-')

  const dia = novaData[2].slice(0, 2)
  const mes = novaData[1]
  const ano = novaData[0]
  return dia + '/' + mes + '/' + ano
}

export const dataStringFormatadaComBarras = (data: string): string => {
  if (!data || data?.length <= 0) {
    return '-'
  }

  const novaData = data.split('-')

  const dia = novaData[2] ? novaData[2].slice(0, 2) : '-'
  const mes = novaData[1] ?? '-'
  const ano = novaData[0] ?? '-'
  return dia + '/' + mes + '/' + ano
}

export const dataFormatadaDiaMes = (data: string | number): string => {
  const novaData = new Date(data).toISOString().split('-')

  const dia = novaData[2].slice(0, 2)
  const mes = novaData[1]

  return `${dia}/${mes}`
}

export const dataFormatadaAmericana = (data = new Date()): string => {
  const novaData = data.toISOString().split('-')

  const dia = novaData[2].slice(0, 2)
  const mes = novaData[1]
  const ano = novaData[0]

  return `${ano}-${mes}-${dia}`
}

export const dataFormatadaMesAno = (data: Date = new Date()): string => {
  const mes = data.getMonth().toString()
  const anoF = data.getFullYear()
  return `${mesesDoAno[mes]} ${anoF}`
}

export const dataFormatadaCalendario = (data: Date): string => {
  const novaData = data.toISOString().split('-')

  const dia = novaData[2].slice(0, 2)
  const mes = novaData[1]
  const diaDaSemana = data.getUTCDay()
  return `${diaSemanaAbreviado[diaDaSemana]}, ${dia} ${
    messesDoAnoAbreviado[Number(mes) - 1]
  }.`
}

export const horaFormatada24H = (data: Date): string => {
  return data.toLocaleTimeString('pt-PT', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  })
}

export const dataValida = (data: string): boolean => {
  const dataRegex = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
  if (!dataRegex.test(data)) return false
  return true
}

export const novaData = (date: string | number): string => {
  const novaData = new Date(date)
  return dataFormatadaComBarras(novaData)
}

export const novaDataAmericana = (date: string | number): string => {
  return dataFormatadaAmericana(new Date(date))
}

export const transformarPadraoAmericano = (dataBrasileira: string): string => {
  const data = dataBrasileira.split('/')
  return `${data[2]}-${data[1]}-${data[0]}`
}

export const dataValidaAmericana = (data: string): boolean => {
  const dataRegex = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
  if (!dataRegex.test(data)) {
    return false
  }
  return true
}

export const transformarDataAmericanaParaDate = (data: string): Date => {
  const novaData = data.split('-')

  const dia = Number(novaData[2].slice(0, 2))
  const mes = Number(novaData[1]) - 1
  const ano = Number(novaData[0])

  return new Date(ano, mes, dia)
}

export const dataToLocaleString = (date: string): string =>
  new Date(date).toLocaleDateString('pt-Br')

export const dataHoraToLocaleString = (date: string): string =>
  new Date(date).toLocaleString('pt-Br')

export const dataFormatadaFormatoAmericano = (data: Date): string => {
  const novaData = data.toISOString().split('-')

  const dia = novaData[2].slice(0, 2)
  const mes = novaData[1]
  const ano = novaData[0]

  return `${ano}-${mes}-${dia}`
}

export const dataMesRelatorio = (ultimoDia?: boolean): string => {
  const data = new Date()
  const ano = data.getFullYear()
  const mes = data.getMonth() + 1
  const ultimoDiaMes = new Date(ano, mes, 0).getDate()

  return `${ano}-${mes}-${ultimoDia ? ultimoDiaMes : 1}`
}

export const dataValidaNullable = (data: string): boolean => {
  const dataRegex = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
  if (data.length < 1) return true
  else if (!dataRegex.test(data)) return false
  return true
}

export const dataFormatadaMesEscrito = (data: Date = new Date()): string => {
  const dia = data.getDate()
  const mes = data.getMonth().toString()
  const ano = data.getFullYear()
  return `${dia} de ${mesesDoAno[mes]} de ${ano}`
}

export const dataFormatadaFormatoAmericanoParaBrasileiro = (
  data: string
): string => {
  const novaDataSeparada = data.split(' ')

  const novaData = novaDataSeparada[0].split('/')

  const dia = novaData[1]
  const mes = novaData[0]
  const ano = novaData[2]

  return `${dia}/${mes}/${ano}`
}
