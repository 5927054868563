import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CheckboxUnform, CpfInputUnform } from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Breadcrumb } from 'src/componentes/breadcrumb'
import { Cabecalho } from 'src/componentes/cabecalho'
import { focarPrimeiroCampoComErro } from 'src/componentes/funcoes'
import { IconeBack } from 'src/componentes/icones'
import { Link } from 'src/componentes/link'
import { Modal } from 'src/componentes/modal'
import { ModalRef } from 'src/componentes/modal/tipos'
import { Navegacao } from 'src/componentes/navegacao'
import { SubTitulo } from 'src/componentes/subtitulo'
import { CalendarioUnform } from 'src/componentes/unform/calendario'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { MoedaInputUnform } from 'src/componentes/unform/input/moeda'
import { NumeroInputUnform } from 'src/componentes/unform/input/numero'
import { SelectUnform, SelectAsyncUnform } from 'src/componentes/unform/select'
import { SelectOpcao } from 'src/componentes/unform/select/tipos'
import { SELECT_MODALIDADES } from 'src/dados-estaticos/modalidades'
import { PesquisaCursoDTO } from 'src/dto'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  ModoPaginaCadastro,
  Cupom,
  TipoDescontoCupom,
  ModalidadeCurso,
  FormaPagamentoCobranca,
  UtilizacaoCupom
} from 'src/tipos'

import { schema } from './schema'
import {
  Container,
  ContainerMain,
  ContainerLink,
  Conteudo,
  ContainerDados,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  ContainerAcoes,
  ContainerLateral,
  SubTituloAviso,
  ContainerValidoAVista
} from './styles'
import { PaginaCupomProps } from './tipos'

enum Navegacoes {
  DadosCupom = 'dados-cupom'
}

const PaginaCupom: React.FC<PaginaCupomProps> = ({ match, modo }) => {
  const [titulo] = useState(
    `${
      modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'
    } de Cupom de Desconto`
  )
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [cupom, definirCupom] = useState<Cupom | undefined>(undefined)
  const modalRef = useRef<ModalRef>(null)
  const [tipoCupom, definirTipoCupom] = useState<TipoDescontoCupom>(null)
  const history = useHistory()
  const [cursos, definirCursos] = useState<PesquisaCursoDTO[]>([])
  const [modalidade, definirModalidade] = useState<ModalidadeCurso>(null)
  const [modalidadeAlterada, definirModalidadeAlterada] = useState<boolean>(
    false
  )

  const [
    cursosSelecionadosAlterado,
    definirCursosSelecionadosAlterado
  ] = useState<boolean>(false)
  const [
    formaPagamentoAlterada,
    definirFormaPagamentoAlterada
  ] = useState<boolean>(false)

  const [planosPagamento, definirPlanosPagamento] = useState<SelectOpcao[]>([])
  const [cursosSelecionados, definirCursosSelecionados] = useState<string[]>([])
  const [
    formaPagamento,
    definirFormaPagamento
  ] = useState<FormaPagamentoCobranca>(null)

  const [bloqueioEdicao, definirBloqueioEdicao] = useState<boolean>(false)
  const [
    utilizacaoCupomSelecionada,
    definirUtilizacaoCupomSelecionada
  ] = useState<UtilizacaoCupom>(null)

  const dadosIniciais = useMemo(() => {
    return cupom
      ? {
          ...cupom,
          porcentagemDesconto: cupom.valorDesconto
        }
      : {}
  }, [cupom])

  const utilizacaoEhPlanoPagamento = useMemo(() => {
    return utilizacaoCupomSelecionada === UtilizacaoCupom.PlanoPagamento
  }, [utilizacaoCupomSelecionada])

  const carregarCursos = async () => {
    try {
      const cursos = await Api.RequisitarCursosPorModalidade(modalidade)
      definirCursos(cursos ?? [])
    } catch {
      definirCursos([])
    }
  }

  const carregarPlanosPagamentoCursos = async () => {
    try {
      const planos = await Api.RequisitarPlanoPagamentosCursos(
        cursosSelecionados,
        formaPagamento
      )

      definirPlanosPagamento(planos)
    } catch {
      definirPlanosPagamento([])
    }
  }

  const carregarCupom = async () => {
    try {
      if (modo === ModoPaginaCadastro.Edicao) {
        const novoCupom = await Api.RequisitarCupom(match.params.id)

        await carregarCursos()
        await carregarPlanosPagamentoCursos()
        definirTipoCupom(novoCupom.tipoDescontoCupom)
        definirCupom(novoCupom)
        definirModalidade(ModalidadeCurso[novoCupom.modalidade])
        definirCursosSelecionados(novoCupom.cursosId ?? [])
        definirFormaPagamento(FormaPagamentoCobranca[novoCupom.formaPagamento])
        definirBloqueioEdicao(novoCupom.utilizado)
        definirUtilizacaoCupomSelecionada(
          UtilizacaoCupom[novoCupom.utilizacaoCupom]
        )
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados do cupom', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucesso = async (dados: Cupom) => {
    try {
      definirCarregando(true)
      const dadosEnvio = {
        ...dados,
        validoAVista: utilizacaoEhPlanoPagamento ? dados.validoAVista : false
      }

      if (modo === ModoPaginaCadastro.Inclusao) {
        await Api.InserirCupom(dadosEnvio)
      } else {
        await Api.EditarCupom(match.params.id, dadosEnvio)
      }

      toast(
        `Cupom ${
          modo === ModoPaginaCadastro.Inclusao ? 'cadastrado' : 'editado'
        } com sucesso`,
        {
          type: 'success'
        }
      )

      history.push(RotasAcademico.Cupons)
    } finally {
      definirCarregando(false)
    }
  }

  const opcoesCursos = useMemo(() => {
    return (
      cursos?.map(x => {
        return { id: x.id, texto: x.nome } as SelectOpcao
      }) ?? []
    )
  }, [cursos])

  const opcoesPlanosPagamento = useMemo(() => {
    return (
      planosPagamento?.map(p => {
        return { id: p.id, texto: p.texto } as SelectOpcao
      }) ?? []
    )
  }, [planosPagamento])

  const carregarUtilizacoesPorId = async (
    id: string
  ): Promise<Array<SelectOpcao>> => {
    try {
      const dado = await Api.RequisitarListaUtilizacoesPorId({ id })

      return [dado]
    } catch {
      return []
    }
  }

  const carregarUtilizacoesOpcoes = async (
    texto: string
  ): Promise<Array<SelectOpcao>> => {
    try {
      const dados = await Api.RequisitarListaUtilizacoesPorTexto({
        texto
      })

      return dados
    } catch (err) {
      return []
    }
  }

  const modalidadeCursoCupomAlterada = (opcao: SelectOpcao) => {
    if (opcao) {
      definirModalidade(ModalidadeCurso[opcao.id])
    } else {
      definirModalidade(null)
    }

    definirModalidadeAlterada(true)
  }

  const formaPagamentoCupomAlterada = (opcao: SelectOpcao) => {
    if (opcao) {
      const formaPagamentoMudou =
        formaPagamento !== FormaPagamentoCobranca[opcao.id]
      definirFormaPagamento(FormaPagamentoCobranca[opcao.id])
      if (formaPagamentoMudou) {
        definirFormaPagamentoAlterada(true)
      }
    } else {
      definirFormaPagamento(null)
      definirFormaPagamentoAlterada(true)
    }
  }

  const tipoCupomAlterado = (opcao: SelectOpcao) => {
    if (!opcao?.id) return

    definirTipoCupom(TipoDescontoCupom[opcao?.id])
  }

  const cursosCupomAlterado = (valores: SelectOpcao[]) => {
    if (valores?.length > 0) {
      const novosCursosSelecionados = valores?.map(v => v.id)
      const cursosRemovidos = cursosSelecionados.filter(
        item => !novosCursosSelecionados.includes(item)
      )
      definirCursosSelecionados(novosCursosSelecionados)
      if (cursosRemovidos?.length > 0) {
        definirCursosSelecionadosAlterado(true)
      }
    } else {
      definirCursosSelecionados([])
      definirCursosSelecionadosAlterado(true)
    }
  }

  const deveLimparCursos = useCallback(() => {
    const alteracaoModalidade = modalidadeAlterada
    definirModalidadeAlterada(false)
    return !modalidade || alteracaoModalidade
  }, [modalidade])

  const deveLimparPlanosPagamento = useCallback(() => {
    const cursoMudou = cursosSelecionadosAlterado
    const formaPagamentoMudou = formaPagamentoAlterada

    definirCursosSelecionadosAlterado(false)
    definirFormaPagamentoAlterada(false)
    return cursoMudou || formaPagamentoMudou
  }, [cursosSelecionadosAlterado, formaPagamentoAlterada])

  useEffect(() => {
    carregarCupom()
  }, [])

  useEffect(() => {
    if (modalidade) {
      carregarCursos()
    }
  }, [modalidade])

  useEffect(() => {
    carregarPlanosPagamentoCursos()
  }, [cursosSelecionados])

  useEffect(() => {
    carregarPlanosPagamentoCursos()
  }, [formaPagamento])

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.Cupons}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Financeiro'
                  },
                  {
                    texto: 'Cupons',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <FormUnform
                schema={schema}
                dadosIniciais={dadosIniciais}
                acaoSucesso={acaoSucesso}
                acaoFalha={focarPrimeiroCampoComErro}
              >
                <div id={Navegacoes.DadosCupom}>
                  {bloqueioEdicao && (
                    <SubTituloAviso texto="O Cupom já foi utilizado e não pode ser editado!" />
                  )}
                  <SubTitulo texto="Dados do Cupom" />
                  <InputUnform
                    name="nome"
                    label="Nome do Cupom"
                    disabled={bloqueioEdicao}
                    obrigatorio
                  />
                  <ContainerDados>
                    <ContainerDadosMedio>
                      <InputUnform
                        name="codigoCupom"
                        label="Código do Cupom"
                        disabled={bloqueioEdicao}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                    <ContainerDadosMedio>
                      <SelectUnform
                        id="slct_modalidade"
                        name="modalidade"
                        label="Modalidade do Curso"
                        placeholder="Selecione"
                        obrigatorio
                        disabled={bloqueioEdicao}
                        valorAlterado={modalidadeCursoCupomAlterada}
                        opcoes={SELECT_MODALIDADES}
                      />
                    </ContainerDadosMedio>
                  </ContainerDados>
                  <ContainerDados>
                    <ContainerDadosPequeno>
                      <SelectUnform
                        id="slct_tipo"
                        name="tipoDescontoCupom"
                        label="Tipo do Cupom"
                        placeholder="Selecione"
                        disabled={bloqueioEdicao}
                        valorAlterado={tipoCupomAlterado}
                        opcoes={[
                          {
                            id: `${
                              TipoDescontoCupom[TipoDescontoCupom.Porcentagem]
                            }`,
                            texto: 'Porcentagem'
                          },
                          {
                            id: `${TipoDescontoCupom[TipoDescontoCupom.Valor]}`,
                            texto: 'Valor'
                          }
                        ]}
                        obrigatorio
                      />
                    </ContainerDadosPequeno>
                    <ContainerDadosPequeno>
                      {tipoCupom === TipoDescontoCupom.Valor ? (
                        <MoedaInputUnform
                          name="valorDesconto"
                          label="Valor do Desconto"
                          disabled={bloqueioEdicao}
                          obrigatorio
                        />
                      ) : (
                        <NumeroInputUnform
                          name="porcentagemDesconto"
                          label="Valor do Desconto"
                          disabled={bloqueioEdicao}
                          isDecimal
                          obrigatorio
                          maxLength={10}
                        />
                      )}
                    </ContainerDadosPequeno>
                    <ContainerDadosPequeno>
                      <NumeroInputUnform
                        maxLength={10}
                        name="limitePorCupom"
                        label="Limite por Cupom"
                        disabled={bloqueioEdicao}
                      />
                    </ContainerDadosPequeno>
                    <ContainerDadosPequeno>
                      <NumeroInputUnform
                        maxLength={10}
                        name="limitePorCliente"
                        label="Limite por Cliente"
                        disabled={bloqueioEdicao}
                      />
                    </ContainerDadosPequeno>
                  </ContainerDados>
                  <ContainerDados>
                    <ContainerDadosPequeno>
                      <CalendarioUnform
                        className="sm"
                        id="ipt_data_inicio"
                        name="dataInicio"
                        label="Data de Início"
                        obrigatorio
                        disabled={bloqueioEdicao}
                      />
                    </ContainerDadosPequeno>
                    <ContainerDadosPequeno>
                      <CalendarioUnform
                        className="sm"
                        id="ipt_data_fim"
                        name="dataFim"
                        obrigatorio
                        label="Data de Fim"
                        disabled={bloqueioEdicao}
                      />
                    </ContainerDadosPequeno>
                    <ContainerDadosMedio>
                      <SelectAsyncUnform
                        id="slct_utilizacao"
                        name="utilizacaoCupom"
                        label="Utilização"
                        placeholder="Selecione"
                        buscarPorId={carregarUtilizacoesPorId}
                        buscarPorTexto={carregarUtilizacoesOpcoes}
                        disabled={bloqueioEdicao}
                        obrigatorio
                        valorAlterado={valor => {
                          definirUtilizacaoCupomSelecionada(
                            UtilizacaoCupom[valor?.id]
                          )
                        }}
                      />
                    </ContainerDadosMedio>
                  </ContainerDados>
                  <ContainerDados>
                    <ContainerDadosPequeno>
                      <SelectUnform
                        id="slct_formaPagamento"
                        name="formaPagamento"
                        label="Forma de Pagamento"
                        placeholder="Selecione"
                        opcoes={[
                          {
                            id:
                              FormaPagamentoCobranca[
                                FormaPagamentoCobranca.Boleto
                              ],
                            texto: FormaPagamentoCobranca.Boleto
                          },
                          {
                            id:
                              FormaPagamentoCobranca[
                                FormaPagamentoCobranca.CartaoCredito
                              ],
                            texto: FormaPagamentoCobranca.CartaoCredito
                          }
                        ]}
                        valorAlterado={formaPagamentoCupomAlterada}
                        iconeFechar={true}
                        disabled={bloqueioEdicao}
                      />
                    </ContainerDadosPequeno>
                    <ContainerDadosPequeno>
                      <CpfInputUnform
                        id="ipt_cpf"
                        name="cpf"
                        label="CPF"
                        disabled={bloqueioEdicao}
                      />
                    </ContainerDadosPequeno>
                    <ContainerValidoAVista>
                      <CheckboxUnform
                        id="chk_valido_AVista"
                        name="validoAVista"
                        texto="Valido para pagamento à vista"
                        disabled={bloqueioEdicao || !utilizacaoEhPlanoPagamento}
                      ></CheckboxUnform>
                    </ContainerValidoAVista>
                  </ContainerDados>
                  <SelectUnform
                    id="cursosId"
                    name="cursosId"
                    label="Cursos"
                    placeholder="Selecione"
                    opcoes={opcoesCursos}
                    multiplo={true}
                    disabled={!modalidade || bloqueioEdicao}
                    deveLimpar={deveLimparCursos}
                    valorAlteradoMulti={cursosCupomAlterado}
                  />
                  <SelectUnform
                    id="slct_planoPagamento"
                    name="planosPagamentoId"
                    label="Plano de Pagamento"
                    placeholder="Selecione"
                    opcoes={opcoesPlanosPagamento}
                    multiplo={true}
                    deveLimpar={deveLimparPlanosPagamento}
                    iconeFechar={true}
                    disabled={bloqueioEdicao}
                  />
                </div>
                <ContainerAcoes>
                  <Botao
                    texto="Cancelar"
                    tema="Secundario"
                    type="button"
                    disabled={carregando}
                    onClick={() => modalRef?.current?.abrir()}
                  />
                  <Botao
                    texto="Salvar"
                    carregando={carregando}
                    disabled={bloqueioEdicao}
                  />
                </ContainerAcoes>
              </FormUnform>
            </Conteudo>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.DadosCupom,
                  nome: 'Dados do Cupom'
                }
              ]}
            />
          </ContainerLateral>
          <Modal
            ref={modalRef}
            backdrop
            id="modal-confirmacao-cancelamento"
            icone={<></>}
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalRef?.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
        </Container>
      ) : (
        <></>
      )}
    </>
  )
}

export default withRouter(PaginaCupom)
