import {
  SituacaoFinanceira,
  TipoAvaliacao,
  TipoProfessorModuloTCC
} from 'src/tipos'

export enum TipoArquivo {
  Upload = 'Upload',
  Optativa = 'Optativa',
  AvaliacaoPorAquivo = 'AvaliacaoPorAquivo'
}
export enum TipoTurma {
  EAD,
  PRESENCIAL
}
export interface Avaliacao {
  id: string
  tipo: TipoAvaliacao
  tipoArquivo: TipoArquivo.Upload
}
export interface Professor {
  nome: string
  id: string
}
export interface Disciplina {
  disciplinaId?: string
  nome: string
  professor: Professor
}

export interface Modulo {
  moduloId?: string
  nome: string
  disciplinas: Disciplina[]
  avaliacao?: Avaliacao
}

export interface Turma {
  id: string
  nome: string
  modulos: Modulo[]
  tipoTurma: TipoTurma
}

export interface ProfessorTCC {
  professorId: string
  usuarioProfessorId: string
  tipoProfessor: TipoProfessorModuloTCC
}

export interface Aluno {
  id: string
  nome: string
  nota?: number
  tentativas?: number
  situacaoFinanceira: SituacaoFinanceira
}

export interface Alunos {
  nomeTurma: string
  nomeModulo: string
  tutor: string
  tipoArquivo?: TipoArquivo
  alunos?: Aluno[]
}
export interface EncontroPresencial {
  id: string
  dataEncontro: string
  presenca?: boolean
}
export interface AlunoPresencial {
  id: string
  nome: string
  nota?: number
  tentativas?: number
  encontros: EncontroPresencial[]
}
export interface AlunosPresencial {
  nomeTurma: string
  nomeModulo: string
  nomeDisciplina: string
  nomeProfessorDisciplina?: string
  tutorTurma?: string
  tipoArquivo?: TipoArquivo
  alunos?: AlunoAvaliacaoTCC[]
}

export interface AlunoAvaliacaoTCC {
  aproveitamento: string
  avaliacao: AvaliacaoTCC
  encontros: EncontroPresencial[]
  idSalaAula: string
  nomeAluno: string
  professores?: ProfessorTCC[]
  situacaoFinanceira: SituacaoFinanceira
  usuarioId: string
  dispensada: boolean
  aproveitada: boolean
}

export interface AvaliacaoTCC {
  feedback: string
  id: string
  nota: number
  observacao: string
  tentativasRestantes: number
  tipoArquivo: TipoArquivo.AvaliacaoPorAquivo
}
