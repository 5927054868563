import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaTentativasRestantes = (): TabelaColuna => ({
  Chave: 'tentativasRestantes',
  Alinhamento: 'center',
  Renderizar: (linha: TabelaDados) => (
    <span>{linha.avaliacao.tentativasRestantes}</span>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 90 }}>Tentativas restantes</div>
  )
})
