import { saveAs } from 'file-saver'
import { nomeArquivoContentDisposition } from 'src/componentes'
import { SalaAulaCursoLivre } from 'src/tipos'

import { gatewayApi } from '../../instancias-api'
import { DadosCertificado, EnviarAvaliacaoResposta } from './tipos'

export const RequisitarSalaAulaPorId = async (
  id: string
): Promise<SalaAulaCursoLivre> => {
  const { data: salaAula } = await gatewayApi.get<SalaAulaCursoLivre>(
    `/salaAula/curso-livre/${id}`
  )

  return salaAula
}

export const MarcarMaterial = async (
  materialId: string,
  idSalaAula: string,
  lido: boolean
): Promise<{ progresso: number; pesquisaSatisfacaoLiberada: boolean }> => {
  const resposta = await gatewayApi.put<{
    progresso: number
    pesquisaSatisfacaoLiberada: boolean
  }>(`/salaAula/curso-livre/${idSalaAula}/materiais`, { materialId, lido })

  return resposta.data
}

export const DownloadMaterial = async (
  id: string,
  idSalaAula: string,
  nomeArquivo: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAula/curso-livre/${idSalaAula}/${id}/arquivo`,
    {
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    saveAs(resposta.data, nomeArquivo)
  }
}

export const RequisitarMaterialPorId = async (
  id: string,
  idSalaAula: string
): Promise<string> => {
  const resposta = await gatewayApi.get(
    `/salaAula/curso-livre/${idSalaAula}/${id}/arquivo`,
    {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const EnviarAvaliacao = async (
  id: string,
  payload: {
    perguntasComRespostas: { idPergunta: string; idResposta: string }[]
  }
): Promise<EnviarAvaliacaoResposta> => {
  const { data } = await gatewayApi.post<EnviarAvaliacaoResposta>(
    `/salaAula/curso-livre/${id}/avaliacao`,
    payload
  )

  return data
}

export const AtualizarTempoVideo = async (
  materialId: string,
  idSalaAula: string,
  url: string,
  isAulaEad: boolean
): Promise<SalaAulaCursoLivre> => {
  const resposta = await gatewayApi.put(
    `/salaAula/curso-livre/${idSalaAula}/atualizar-video`,
    { materialId, url, isAulaEad }
  )

  return resposta.data
}

export const getMaterialVideoUrl = async (
  materialId: string,
  idSalaAula: string
): Promise<string> => {
  const result = await gatewayApi.get(
    `/salaAula/curso-livre/${idSalaAula}/${materialId}/obter-url`
  )

  return result.data
}

export const RequisitarDadosCertificado = async (
  idMatricula: string
): Promise<DadosCertificado> => {
  const resposta = await gatewayApi.get(
    `salaAula/curso-livre/${idMatricula}/certificado`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const DownloadAvaliacaoArquivoEnviado = async (
  id: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo/obter-ultima-avaliacao-enviada`,
    {
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}
