import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 13px 20px 7px;
`

export const TituloTopico = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }

  svg {
    margin: 0 6px;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #2bb8d3;
  }
`

export const Conteudo = styled.div`
  display: flex;
  flex: 1;
  margin-top: 22px;

  & > .textarea-container {
    flex: 1;
    margin-bottom: 12px;
  }
`

export const ImagemUsuario = styled.div`
  margin-right: 7px;

  & > img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`

export const ContainerBotao = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 19px;
  height: 40px;
`

export const ListaComentarios = styled.div`
  border-left: 4px solid ${Cores.SECUNDARIA_2};
`
export const ContainerPrivado = styled.div`
  display: flex;
  align-items: center;

  label {
    color: #666666;
    font-size: 14px;
    line-height: 100%;
  }
`
