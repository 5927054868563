import React, { useReducer } from 'react'

import PropTypes from 'prop-types'

import { CalendarioContext, CalendarioAcoesContext } from './calendario-context'
import calendarioReducer from './calendario-reducer'

export const CalendarioProvider: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(calendarioReducer, {
    data: '',
    dataSugestao: new Date().toDateString(),
    aberto: false
  })

  return (
    <CalendarioContext.Provider value={state}>
      <CalendarioAcoesContext.Provider value={dispatch}>
        {children}
      </CalendarioAcoesContext.Provider>
    </CalendarioContext.Provider>
  )
}

CalendarioProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
