import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { ModalBase, Botao } from 'src/componentes'

import { Corpo, Texto, Titulo } from './styles'
import { ModalFeedbackProps, ModalFeedbackRef } from './tipos'

const ModalFeedback: React.ForwardRefRenderFunction<
  ModalFeedbackRef,
  ModalFeedbackProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [feedback, definirFeedback] = useState<string>(null)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = (feedback: string) => {
    definirFeedback(feedback)
    definirAberta(true)
  }

  useImperativeHandle<ModalFeedbackRef, ModalFeedbackRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = () => {
    fechar()
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <Titulo>Feedback</Titulo>
        <Texto>{feedback}</Texto>
        <Botao texto="OK" type="button" onClick={() => acaoSucesso()} />
      </Corpo>
    </ModalBase>
  )
}

export const ModalFeedbackProfessor = forwardRef(ModalFeedback)
