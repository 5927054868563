import { AxiosRequestConfig } from 'axios'
import queryString from 'query-string'
import { SelectOpcao } from 'src/componentes'
import { EnvioValoresRecuperacaoManual } from 'src/paginas/ipgs/alunos/edicao/componentes/modal-recuperacao-manual/tipos'
import { NovoRelatorio } from 'src/paginas/ipgs/turmas/componentes/modal-inserir-relatorio'
import {
  CalculoDescontoRenegociacao,
  CobrarMatriculaPorBoleto,
  Curso,
  DadosFinanceirosDoCurso,
  DadosFinanceirosDoCursoLivre,
  PainelFinanceiro,
  PlanoDePagamento,
  PlanoPagamentoMatricula,
  TermosCondicoesMatricula,
  TipoDescontoCalculo,
  RenegociacaoMatricula,
  RespostaPadraoAPI,
  CursosCarrinho,
  CarrinhoCompraCursoLivre,
  DadosFinanceirosCarrinhoCompra,
  VerificarCarrinhoCompraExistenteUsuario,
  MetodosPagamento,
  FormaPagamentoCobranca,
  OpcoesTransferenciaAluno,
  TipoTransferenciaAluno,
  NovaMatriculaTurmas,
  TipoCobranca,
  ResumoContratos,
  ResponsavelFinanceiro,
  AceiteResponsavelFinanceiro,
  Renegociacoes,
  PlanoPagamentoParcelasTransferencia,
  OpcoesAlteracaoTCCDto,
  OpcaoAlteracaoTCCPorExtenso,
  InfoRecuperacao,
  RecuperacaoManual,
  TipoRecorrencia
} from 'src/tipos'
import { PegarQrCodeBoleto } from 'src/tipos/pegar-qrcode-boleto'

import { gatewayApi } from '../instancias-api'
import {
  BoletoGerado,
  CadastrarUsuarioCursoLivrePayload,
  CadastrarUsuarioOutrosCursosPayload,
  CadastrarUsuarioAutenticadoCursoLivrePayload,
  CadastrarUsuarioAutenticadoOutrosCursosPayload,
  GerarBoletoMatriculaParams,
  AvancarPagamentoMatriculaPayload,
  DadosMatricula,
  EditarUsuarioCursoLivrePayload,
  ValidarCupomResposta,
  EditarUsuarioOutrosCursosPayload,
  DadosCartao,
  NovoContrato,
  CriarMatriculaUsuarioExistenteResposta,
  CriarMatriculaComNovoUsuarioResposta,
  AvancarPagamentoCarrinhoCompraCursoLivrePayload,
  GerarBoletoCarrinhoCompraParams,
  TransferenciaAlunoPayload,
  GerarBoletoTaxaTrocaCursoParams,
  AvancarPagamentoTaxaTrocaCursoPayload,
  AvancarDadosPessoaisResponse,
  TipoDdosPagamentoRecuperacao,
  ObterMateriasENotasResponse
} from './tipos'

export const RequisitarMatriculaPorIdCurso = async (
  id: string
): Promise<DadosMatricula> => {
  const { data: matricula } = await gatewayApi.get<DadosMatricula>(
    `/matricula/curso/${id}`
  )

  return matricula
}

export const RequisitarMatriculaCursosPorSigla = async (
  siglas: string[]
): Promise<CursosCarrinho> => {
  const codigos = queryString.stringify({
    Sigla: [...siglas]
  })

  const { data: curso } = await gatewayApi.get<CursosCarrinho>(
    `/matricula/curso-livre/carrinho?${codigos}`
  )

  return curso
}

export const RequisitarMatriculaPorIdTurma = async (
  id: string
): Promise<DadosMatricula> => {
  const { data: matricula } = await gatewayApi.get<DadosMatricula>(
    `/matricula/turma/${id}`
  )

  return matricula
}

export const RequisitarMatriculaCursoPorSigla = async (
  sigla: string
): Promise<Curso> => {
  const { data: curso } = await gatewayApi.get<Curso>(
    `/matricula/curso/${sigla}`
  )

  return curso
}

export const RequisitarMatriculaCursoPorTurma = async (
  codigoTurma: string
): Promise<Curso> => {
  const { data: curso } = await gatewayApi.get<Curso>(
    `/matricula/turma/${codigoTurma}`
  )

  return curso
}

export const CadastrarUsuarioCursoLivre = async (
  payload: CadastrarUsuarioCursoLivrePayload
): Promise<CriarMatriculaComNovoUsuarioResposta> => {
  const {
    data: success
  } = await gatewayApi.post<CriarMatriculaComNovoUsuarioResposta>(
    '/matricula/curso-livre/criar',
    payload
  )

  return success
}

export const CadastrarUsuarioAutenticadoCursoLivre = async (
  payload: CadastrarUsuarioAutenticadoCursoLivrePayload
): Promise<CriarMatriculaUsuarioExistenteResposta> => {
  const {
    data: success
  } = await gatewayApi.patch<CriarMatriculaUsuarioExistenteResposta>(
    '/matricula/curso-livre/usuario/criar',
    payload
  )

  return success
}

export const EditarUsuarioCursoLivre = async (
  payload: EditarUsuarioCursoLivrePayload
): Promise<AvancarDadosPessoaisResponse> => {
  const { data } = await gatewayApi.patch<AvancarDadosPessoaisResponse>(
    `/matricula/${payload.matriculaId}/curso-livre/dados-pessoais/avancar`,
    payload
  )

  return data
}

export const GerarBoletoMatricula = async ({
  idMatricula,
  diaVencimento,
  cupomId,
  pix
}: GerarBoletoMatriculaParams): Promise<BoletoGerado> => {
  const { data: boleto } = await gatewayApi.post<BoletoGerado>(
    `/matricula/${idMatricula}/boleto/cobrar`,
    {
      diaVencimento,
      cupomId,
      pix
    }
  )

  return boleto
}

export const CobrarTaxaDeMatriculaPorBoleto = async (
  matriculaId: string,
  cupomId: string,
  diaVencimento: number,
  pix: string
): Promise<CobrarMatriculaPorBoleto> => {
  const url = `/matricula/${matriculaId}/boleto/cobrar`
  const data = { matriculaId, cupomId, diaVencimento, pix }
  const resposta = await gatewayApi.post<CobrarMatriculaPorBoleto>(url, data)

  return resposta.data
}

export const pegarQrCodeBoleto = async (
  boletoId: string
): Promise<PegarQrCodeBoleto> => {
  const { data: pixCopiaECola } = await gatewayApi.get<PegarQrCodeBoleto>(
    `/boleto/pegarQrCode/${boletoId}`
  )
  return pixCopiaECola
}

export const GerarBoletoTaxaTrocaCurso = async ({
  idMatricula,
  diaVencimento,
  cupomId
}: GerarBoletoTaxaTrocaCursoParams): Promise<BoletoGerado> => {
  const { data: boleto } = await gatewayApi.post<BoletoGerado>(
    `/matricula/${idMatricula}/taxa-troca-curso/boleto/cobrar`,
    {
      diaVencimento,
      cupomId
    }
  )

  return boleto
}

export const GerarBoletoCarrinhoCompra = async ({
  carrinhoCompraId,
  diaVencimento,
  cupons
}: GerarBoletoCarrinhoCompraParams): Promise<BoletoGerado> => {
  const { data: boleto } = await gatewayApi.post<BoletoGerado>(
    '/matricula/boleto/carrinho-compra/cobrar',
    {
      carrinhoCompraCursoLivreId: carrinhoCompraId,
      diaVencimento,
      cupons
    }
  )

  return boleto
}

export const AvancarPagamentoMatriculaCursoLivre = async ({
  matriculaId,
  cupomId,
  dadosCartao
}: AvancarPagamentoMatriculaPayload): Promise<void> => {
  await gatewayApi.post(
    `/matricula/${matriculaId}/curso-livre/pagamento/avancar`,
    {
      cupomId,
      dadosCartao
    }
  )
}

export const AvancarPagamentoCarrinhoComprasCursoLivre = async ({
  carrinhoCompraId,
  cupons,
  dadosCartao
}: AvancarPagamentoCarrinhoCompraCursoLivrePayload): Promise<void> => {
  await gatewayApi.post('/matricula/curso-livre/pagamento/carrinho/avancar', {
    carrinhoCompraCursoLivreId: carrinhoCompraId,
    cupons,
    dadosCartao
  })
}

export const CadastrarUsuarioOutrosCursos = async (
  payload: CadastrarUsuarioOutrosCursosPayload
): Promise<{ matriculaId: string; token: string }> => {
  const { data: success } = await gatewayApi.post<{
    matriculaId: string
    token: string
  }>('/matricula/outros-cursos/criar', payload)

  return success
}

export const CadastrarUsuarioAutenticadoOutrosCursos = async (
  payload: CadastrarUsuarioAutenticadoOutrosCursosPayload
): Promise<{
  matriculaId: string
  pendencias: boolean
  cursosPendentes?: string[]
}> => {
  const { data: success } = await gatewayApi.patch<{
    matriculaId: string
    pendencias: boolean
    cursosPendentes?: string[]
  }>('/matricula/outros-cursos/usuario/criar', payload)

  return success
}

export const EditarUsuarioOutrosCursos = async (
  payload: EditarUsuarioOutrosCursosPayload
): Promise<{ matriculaId: string }> => {
  const { data: success } = await gatewayApi.patch<{
    matriculaId: string
  }>(
    `/matricula/${payload.matriculaId}/outros-cursos/dados-pessoais/avancar`,
    payload
  )

  return success
}

export const AvancarTermosCondicoesOutrosCursos = async (
  idMatricula: string,
  termosCondicoes: Omit<TermosCondicoesMatricula, 'urlTermosCondicoes'>
): Promise<void> => {
  await gatewayApi.post(
    `/matricula/${idMatricula}/outros-cursos/termo-condicao/avancar`,
    termosCondicoes
  )
}

export const AvancarPlanoPagamentoOutrosCursos = async (
  matriculaId: string,
  planoPagamento: Pick<
    PlanoPagamentoMatricula,
    'planoPagamentoId' | 'idCupom' | 'diaVencimento' | 'pagamentoRecorrente'
  >,
  responsavelFinanceiro: ResponsavelFinanceiro
): Promise<ResponsavelFinanceiro> => {
  const { data: dados } = await gatewayApi.post<ResponsavelFinanceiro>(
    `/matricula/${matriculaId}/outros-cursos/plano-pagamento/avancar`,
    {
      cupomId: planoPagamento.idCupom,
      planoPagamentoId: planoPagamento.planoPagamentoId,
      diaVencimentoParcela: planoPagamento.diaVencimento,
      pagamentoRecorrente: planoPagamento.pagamentoRecorrente,
      responsavelFinanceiro: responsavelFinanceiro
    }
  )

  return dados
}

export const AvancarPagamentoMatriculaOutrosCursos = async ({
  matriculaId,
  cupomId,
  dadosCartao
}: AvancarPagamentoMatriculaPayload): Promise<void> => {
  await gatewayApi.post(
    `/matricula/${matriculaId}/outros-cursos/pagamento/avancar`,
    {
      cupomId,
      dadosCartao
    }
  )
}

export const AvancarPagamentoTaxaTrocaCursoOutrosCursos = async ({
  matriculaId,
  cupomId,
  dadosCartao
}: AvancarPagamentoTaxaTrocaCursoPayload): Promise<void> => {
  await gatewayApi.post(
    `/matricula/${matriculaId}/outros-cursos/pagamento/taxa-troca-curso/avancar`,
    {
      cupomId,
      dadosCartao
    }
  )
}

export const RequisitarValidarCupom = async (
  idMatricula: string,
  codigo: string,
  utilizacaoCupom:
    | 'TaxaMatricula'
    | 'PlanoPagamento'
    | 'TCC'
    | 'TaxaTrocaCurso',
  formaPagamento?: MetodosPagamento | FormaPagamentoCobranca,
  planoPagamentoId?: string
): Promise<ValidarCupomResposta> => {
  try {
    const queryParams = queryString.stringify({
      Codigo: codigo,
      UtilizacaoCupom: utilizacaoCupom,
      formaPagamento: formaPagamento,
      planoPagamentoId
    })
    const { data } = await gatewayApi.get<ValidarCupomResposta>(
      `/matricula/${idMatricula}/cupom/validar?${queryParams}`
    )

    return data
  } catch {
    return null
  }
}

export const RequisitarValidarCupomTCC = async (
  idMatricula: string,
  codigo: string
): Promise<ValidarCupomResposta> => {
  try {
    const queryParams = queryString.stringify({
      Codigo: codigo
    })

    const { data } = await gatewayApi.get<ValidarCupomResposta>(
      `/matricula/${idMatricula}/cupom/tcc/validar?${queryParams}`
    )

    return data
  } catch {
    return null
  }
}

export const RequisitarListaPlanosPagamento = async ({
  idMatricula,
  nome,
  idPlanoPagamento,
  idCupom,
  formaPagamento
}: {
  idMatricula: string
  nome?: string
  idPlanoPagamento?: string
  idCupom?: string
  formaPagamento?: string
}): Promise<PlanoDePagamento[]> => {
  let url = `/matricula/${idMatricula}/planos-pagamento?nome=`

  if (nome) {
    url += nome
  }

  if (idPlanoPagamento) {
    url += `&planoPagamentoId=${idPlanoPagamento}`
  }

  if (idCupom) {
    url += `&cupomId=${idCupom}`
  }

  if (formaPagamento) {
    url += `&formaPagamento=${formaPagamento}`
  }

  const resposta = await gatewayApi.get<{ id: string; texto: string }[]>(url)

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const RequisitarContatoTermoCondicoes = async (
  idMatricula: string
): Promise<string> => {
  const resposta = await gatewayApi.get(`/matricula/${idMatricula}/contrato`, {
    headers: {
      Accept: 'application/pdf'
    },
    responseType: 'blob'
  })

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const RequisitarContratoAluno = async (
  idMatricula: string
): Promise<string> => {
  const resposta = await gatewayApi.get(
    `/matricula/${idMatricula}/contrato-aluno`,
    {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const RequisitarContratoAlunoMatricula = async (
  contratoId: string
): Promise<string> => {
  const resposta = await gatewayApi.get(
    `/matricula/contrato-aluno-matricula/${contratoId}`,
    {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const ObterDadosFinanceirosDoAluno = async (
  idDoUsuario: string
): Promise<PainelFinanceiro> => {
  const url = `/matricula/${idDoUsuario}/dados-financeiros-aluno`
  const { data } = await gatewayApi.get<PainelFinanceiro>(url)

  return data
}

export const ObterDadosFinanceirosDoCursoLivre = async (
  idDaMatricula: string
): Promise<DadosFinanceirosDoCursoLivre> => {
  const url = `/matricula/${idDaMatricula}/dados-financeiros-aluno/curso-livre`
  const { data } = await gatewayApi.get<DadosFinanceirosDoCursoLivre>(url)

  return data
}

export const ObterDadosFinanceirosDoCurso = async (
  idDaMatricula: string
): Promise<DadosFinanceirosDoCurso> => {
  const url = `/matricula/${idDaMatricula}/dados-financeiros-aluno/detalhes-curso`
  const { data } = await gatewayApi.get<DadosFinanceirosDoCurso>(url)

  return data
}

export const AlterarPlanoDePagamento = async (
  matriculaId: string,
  planoPagamentoId: string,
  diaVencimento: number
): Promise<boolean> => {
  const url = `/matricula/${matriculaId}/alterar-plano-pagamento`
  const data = { matriculaId, planoPagamentoId, diaVencimento }
  const resposta = await gatewayApi.put(url, data)

  return Boolean(resposta.status < 300)
}

export const AlterarDataCobranca = async (
  matriculaId: string,
  dataCobranca: string,
  idsCobrancas: string[]
): Promise<boolean> => {
  const data = {
    alteracoesDataCobranca: idsCobrancas.map(cobrancaId => ({
      cobrancaId,
      dataVencimento: dataCobranca
    }))
  }

  const resposta = await gatewayApi.put(
    `/matricula/${matriculaId}/alterar-data-cobranca`,
    data
  )

  return resposta.status === 200 || resposta.status === 204
}

export const CalcularValorDescontoRenegociacao = async (
  quantidadeParcelas: number,
  valorTotal: number,
  tipoCalculo: TipoDescontoCalculo,
  valorDesconto: number
): Promise<number> => {
  const data = {
    quantidadeParcelas,
    valorTotal,
    tipoCalculo,
    valorDesconto
  }

  const resposta = await gatewayApi.post<CalculoDescontoRenegociacao>(
    '/matricula/calcular-valor-desconto',
    data
  )

  return resposta.data.valorParcelas
}

export const Renegociacao = async (
  matriculaId: string,
  renegociacoes: Renegociacoes[],
  cobrancasRenegociadas: string[]
): Promise<string> => {
  const data = {
    renegociacoes,
    cobrancasRenegociadas
  }

  const resposta = await gatewayApi.post<RenegociacaoMatricula>(
    `matricula/${matriculaId}/renegociacao`,
    data
  )

  return resposta.data.matriculaId
}

export const NovaCompra = async (
  matriculaId: string,
  quantidade: number,
  valorParcela: number,
  primeiroVencimento: Date,
  observacao?: string,
  excluir?: boolean,
  cobrancasExcluir?: string[],
  novaRecorrencia?: boolean,
  permitirParcelamento?: boolean,
  tipoCobrancaSelecionado?: TipoCobranca,
  tipoRecorrencia?: TipoRecorrencia
): Promise<boolean> => {
  const resposta = await gatewayApi.post(
    `matricula/${matriculaId}/nova-compra`,
    {
      quantidade,
      valorParcela,
      primeiroVencimento,
      observacao,
      excluir,
      cobrancasExcluir,
      novaRecorrencia,
      permitirParcelamento,
      tipoCobranca: tipoCobrancaSelecionado,
      tipoRecorrencia
    }
  )

  return resposta.status === 200 || resposta.status === 204
}

export const RequisitarValidarCupomTaxaMatricula = async (
  idMatricula: string,
  codigo: string,
  valorPagamento: number,
  formaPagamento?: FormaPagamentoCobranca
): Promise<ValidarCupomResposta> => {
  try {
    const params = {
      codigo,
      utilizacaoCupom: 'TaxaMatricula',
      valorPagamento,
      formaPagamento
    }

    const { data } = await gatewayApi.post<ValidarCupomResposta>(
      `/matricula/${idMatricula}/cupom-taxa-matricula/validar`,
      params
    )

    return data
  } catch {
    return null
  }
}

export const RequisitarValidarCupomTaxaTrocaDeCurso = async (
  idMatricula: string,
  codigo: string,
  valorPagamento: number,
  formaPagamento?: FormaPagamentoCobranca
): Promise<ValidarCupomResposta> => {
  try {
    const params = {
      codigo,
      utilizacaoCupom: 'TaxaTrocaCurso',
      valorPagamento,
      formaPagamento
    }

    const { data } = await gatewayApi.post<ValidarCupomResposta>(
      `/matricula/${idMatricula}/cupom-taxa-troca-curso/validar`,
      params
    )

    return data
  } catch {
    return null
  }
}

export const UltimoAcessoCurso = async (
  matriculaId: string
): Promise<boolean> => {
  const resposta = await gatewayApi.put(
    `/matricula/${matriculaId}/atualizar-ultimo-acesso`,
    matriculaId
  )

  return resposta.status === 200 || resposta.status === 204
}

export const ObterSeEhPagamentoRecorrente = async (
  id: string
): Promise<{ pagamentoRecorrente: boolean }> => {
  const { data: pagamentoRecorrente } = await gatewayApi.get<{
    pagamentoRecorrente
  }>(`/matricula/${id}/pagamento-recorrente`)

  return pagamentoRecorrente
}

export const EnviarPagamentoRecorrente = async (
  matriculaId: string,
  dadosCartao: DadosCartao
): Promise<void> => {
  await gatewayApi.post(`/matricula/${matriculaId}/pagamento-recorrente`, {
    dadosCartao
  })
}

export const AtualizarCartaoPagamentoRecorrente = async (
  matriculaId: string,
  dadosCartao: DadosCartao
): Promise<void> => {
  await gatewayApi.put(`/matricula/${matriculaId}/pagamento-recorrente`, {
    dadosCartao
  })
}

export const AdicionarNovoContrato = async (
  payload: NovoContrato
): Promise<boolean> => {
  if (payload.contrato && payload.contrato instanceof File) {
    const formData = new FormData()
    formData.append('contrato', payload.contrato, payload.contrato.name)
    await gatewayApi.post<RespostaPadraoAPI>(
      `matricula/${payload.matriculaId}/adicionar-novo-contrato`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
  return true
}

export const AceiteNovoContratoMatricula = async (
  contratoId: string,
  contratoRejeitado: boolean
): Promise<void> => {
  await gatewayApi.post(`/matricula/${contratoId}/aceite-novo-contrato`, {
    contratoRejeitado
  })
}

export const CancelarRecorrencia = async (
  matriculaId: string
): Promise<void> => {
  await gatewayApi.patch(`matricula/${matriculaId}/recorrencia`)
}

export const ObterCarrinhoCompraCursoLivre = async (
  carrinhoCompraId: string
): Promise<CarrinhoCompraCursoLivre> => {
  const { data } = await gatewayApi.get<CarrinhoCompraCursoLivre>(
    `matricula/curso-livre/carrinho-compras/${carrinhoCompraId}`
  )

  return data
}

export const ObterDadosFinanceirosCarrinhoCompraCursoLivre = async (
  carrinhoCompraCursoLivreId: string
): Promise<DadosFinanceirosCarrinhoCompra> => {
  const url = `/matricula/dados-financeiros-aluno/curso-livre/carrinho-compra/${carrinhoCompraCursoLivreId}/`
  const { data } = await gatewayApi.get<DadosFinanceirosCarrinhoCompra>(url)

  return data
}

export const VerificarMatriculasCursosCarrinhoCursoLivre = async (
  cursosIds: string[],
  carrinhoAtualId?: string
): Promise<VerificarCarrinhoCompraExistenteUsuario> => {
  const ids = queryString.stringify({
    CursoId: [...cursosIds],
    CarrinhoAtualId: carrinhoAtualId
  })
  const url = `/matricula/curso-livre/carrinho-compra/verificar-abertos?${ids}`
  const {
    data
  } = await gatewayApi.get<VerificarCarrinhoCompraExistenteUsuario>(url)

  return data
}

export const CancelarCarrinhoCompraCursoLivre = async (
  carrinhoCompraCursoLivreId: string
): Promise<void> => {
  await gatewayApi.patch(
    `/matricula/curso-livre/carrinho-compra/${carrinhoCompraCursoLivreId}/cancelar`
  )
}

export const RequisitarListaTurmasDisponiveisTransferencia = async (
  payload: TransferenciaAlunoPayload
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({
      TipoTransferencia: payload.tipoTransferencia
    })

    const {
      data: { turmas }
    } = await gatewayApi.get<OpcoesTransferenciaAluno>(
      `/matricula/${payload.matriculaId}/transferencia?${filtros}`
    )

    return turmas.map(response => ({
      id: response.turmaId,
      texto: response.codigoTurma
    }))
  } catch {
    return null
  }
}

export const TransferirAluno = async (
  idMatricula: string,
  turmaDestinoId: string,
  tipoTransferencia: TipoTransferenciaAluno,
  termoAditivo: File | string,
  addPlanoPagamento?: boolean,
  planosPagamento?: PlanoPagamentoParcelasTransferencia[],
  taxaIsenta?: boolean
): Promise<boolean> => {
  const payload = {
    turmaDestinoId,
    tipoTransferencia,
    addPlanoPagamento,
    planosPagamento,
    taxaIsenta
  }

  const {
    data: { matriculaId, moduloAproveitado }
  } = await gatewayApi.post<{
    matriculaId: string
    moduloAproveitado: boolean
  }>(`/matricula/${idMatricula}/transferencia`, payload)

  if (
    tipoTransferencia === TipoTransferenciaAluno.TrocaCurso &&
    termoAditivo &&
    termoAditivo instanceof File
  ) {
    const formData = new FormData()
    formData.append('termo', termoAditivo, termoAditivo.name)
    formData.append('matriculaOrigemId', idMatricula)

    await gatewayApi.post<RespostaPadraoAPI>(
      `/matricula/${matriculaId}/transferencia/upload-termo`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  return moduloAproveitado
}

export const AlterarProfessoresTCC = async (
  matriculaId: string,
  professorOrientadorId: string,
  professorBancaId: string
): Promise<void> => {
  const payload = {
    professorOrientadorId,
    professorBancaId
  }

  await gatewayApi.patch(`/matricula/${matriculaId}/professor/tcc`, payload)
}

export const ObterTurmasNovaMatricula = async (
  alunoId: string
): Promise<SelectOpcao[]> => {
  const filtro = queryString.stringify({
    alunoId
  })
  const { data } = await gatewayApi.get<NovaMatriculaTurmas>(
    `/matricula/nova/turmas?${filtro}`
  )
  return data?.turmas?.map(t => {
    return { id: t.turmaId, texto: t.codigoTurma } as SelectOpcao
  })
}

export const PostNovaMatricula = async (
  alunoId: string,
  turmaId: string
): Promise<void> => {
  await gatewayApi.post('/matricula/nova', { alunoId, turmaId })
}

export const PostNovaNota = async (
  idModulo: string,
  nota: number,
  matriculaId: string
): Promise<void> => {
  await gatewayApi.put(`/matricula/${matriculaId}/inserir-notas-modulos`, {
    idModulo,
    nota
  })
}

export const PostNovoRelatorio = async (
  ArquivoExcel: NovoRelatorio
): Promise<void> => {
  const formData = new FormData()
  formData.append('ArquivoExcel', ArquivoExcel.arquivo)

  await gatewayApi.post('/matricula/processar/excel', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const RealizarBaixasManual = async () => {
  const resultado = await gatewayApi.get('/matricula/BackgroundService')
  return resultado.data
}

export const RealizarVinculoPagamentos = async (
  matriculaId: string
): Promise<any> => {
  const resultado = await gatewayApi.get(
    `/matricula/BackgroundService/recorrencia/${matriculaId}`
  )
  return resultado.data
}

export const ObterMateriasENotas = async (
  alunoId: string
): Promise<ObterMateriasENotasResponse> => {
  const rota = `matricula/${alunoId}/obter-modulos-notas`
  const resposta = await gatewayApi.get(rota)
  return resposta.data
}

export const ObterResumoContratosAdendo = async (
  matriculaId: string
): Promise<ResumoContratos> => {
  const { data } = await gatewayApi.get<ResumoContratos>(
    `matricula/${matriculaId}/resumo-contratos`
  )

  if (!data) {
    return null as ResumoContratos
  }

  return data
}

export const ObterDownloadContratosAdendo = async (
  matriculaId: string,
  contratoMatriculaId: string
): Promise<void> => {
  const rota = `matricula/${matriculaId}/contrato-adendo/${contratoMatriculaId}/download`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    saveAs(resposta.data)
  }
}

export const ObterDownloadContratoMatricula = async (
  matriculaId: string
): Promise<void> => {
  const rota = `matricula/${matriculaId}/contrato/download`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    saveAs(resposta.data)
  }
}

export const ObterContratosAdendo = async (
  matriculaId: string,
  contratoMatriculaId: string
): Promise<string> => {
  const rota = `matricula/${matriculaId}/contrato-adendo/${contratoMatriculaId}/download`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const ObterContratoMatricula = async (
  matriculaId: string
): Promise<string> => {
  const rota = `matricula/${matriculaId}/contrato/download`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const ObterPainelInicialResponsavelFinanceiro = async (
  idUsuario: string
): Promise<PainelFinanceiro> => {
  const url = `/matricula/${idUsuario}/dados-financeiros-responsavel`
  const { data } = await gatewayApi.get<PainelFinanceiro>(url)

  return data
}

export const AceiteResponsavel = async (
  matriculaId: string,
  aceiteResponsavelFinanceiro?: AceiteResponsavelFinanceiro
): Promise<boolean> => {
  const resposta = await gatewayApi.post(
    `matricula/${matriculaId}/responsavel-financeiro`,
    aceiteResponsavelFinanceiro
  )

  return resposta.status === 200 || resposta.status === 204
}

export const ObterOpcoesAlteracaoTCC = async (
  matriculaId: string
): Promise<SelectOpcao[]> => {
  const { data } = await gatewayApi.get<OpcoesAlteracaoTCCDto>(
    `/matricula/${matriculaId}/alteracao-tcc/opcoes`
  )

  return data?.opcoes?.map(x => {
    return { id: x, texto: OpcaoAlteracaoTCCPorExtenso[x] }
  })
}

export const CancelamentoTCC = async (matriculaId: string): Promise<void> => {
  await gatewayApi.put(`/matricula/${matriculaId}/alteracao-tcc/cancelamento`)
}

export const DispensaTCC = async (matriculaId: string): Promise<void> => {
  await gatewayApi.put(`/matricula/${matriculaId}/alteracao-tcc/dispensa`)
}

export const AproveitamentoTCC = async (
  matriculaId: string,
  instituicao: string,
  turma: string,
  nota: string
): Promise<void> => {
  await gatewayApi.put(
    `/matricula/${matriculaId}/alteracao-tcc/aproveitamento`,
    {
      instituicao,
      turma,
      nota
    }
  )
}

export const InclusaoTCC = async (
  matriculaId: string,
  valor: number,
  quantidadeParcelas: number,
  diaVencimento: number,
  professorOrientadorId: string
): Promise<void> => {
  await gatewayApi.put(`/matricula/${matriculaId}/alteracao-tcc/inclusao`, {
    valor,
    quantidadeParcelas,
    diaVencimento,
    professorOrientadorId
  })
}

export const ObterInformacoesRecuperacao = async (
  matriculaId: string
): Promise<InfoRecuperacao> => {
  const { data } = await gatewayApi.get(`/matricula/${matriculaId}/recuperacao`)
  return data
}

export const EnviarInformacoesPagamentoRecuperacao = async (
  matriculaId: string,
  dados: TipoDdosPagamentoRecuperacao
): Promise<boolean> => {
  const data = await gatewayApi.post(
    `/matricula/${matriculaId}/recuperacao`,
    dados
  )
  return data.status === 200
}

export const ObterInformacoesRecuperacaoManual = async (
  matriculaId: string
): Promise<RecuperacaoManual> => {
  const { data } = await gatewayApi.get(
    `/matricula/${matriculaId}/recuperacao/manual`
  )
  return data
}

export const EnviarInformacoesRecuperacaoManual = async (
  matriculaId: string,
  dados: EnvioValoresRecuperacaoManual
): Promise<boolean> => {
  const data = await gatewayApi.post(
    `/matricula/${matriculaId}/recuperacao/manual`,
    {
      valor: dados.valorTotal,
      dataVencimento: dados.dataPrimeiroVencimento,
      quantidadeParcelas: dados.quantidadeParcelas
    }
  )
  return data.status === 200
}
