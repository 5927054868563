import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  Botao,
  Breadcrumb,
  Carregando,
  Cores,
  FormUnform,
  IconeBack,
  IconeDoAvatarBusca,
  IconeTurma,
  InputUnform,
  Link,
  Navegacao,
  SubTitulo,
  Tooltip,
  Visao
} from 'src/componentes'
import { Conteudo } from 'src/paginas/aluno/editar-dados/styles'
import { RotasAcademico, RotasTurmas } from 'src/rotas'
import { RotasProfessor } from 'src/rotas/professor'
import { Api } from 'src/servicos'
import {
  DisciplinaTurma,
  ModalidadeCurso,
  TipoAvaliacao,
  Turma
} from 'src/tipos'

import {
  Container,
  ContainerBotao,
  ContainerLateral,
  ContainerMain,
  ContainerModulo,
  ContainerDisciplinas,
  BotaoTabela,
  CabecalhoComEstilos,
  ContainerLink
} from './styles'
import { NotasPresencaProps } from './tipos'
enum Navegacoes {
  Disciplinas = 'disciplinas'
}
const PaginaNotasPresenca: React.FC<NotasPresencaProps> = ({ match }) => {
  const [turma, definirTurma] = useState(null)
  const [pronto, definirPronto] = useState(false)
  const history = useHistory()
  const [dadosIniciais, definirDadosIniciais] = useState(null)
  const [avaliacao, definirAvaliacao] = useState(null)
  const { visao } = AutenticacaoContainer.useContainer()
  const ipgs = useMemo(() => visao === Visao.IPGS, [visao])
  const parceiro = useMemo(() => visao === Visao.Parceiro, [visao])
  const professor = useMemo(() => visao === Visao.Professor, [visao])
  const visaoDiferenteAluno = useMemo(() => visao !== Visao.Aluno, [visao])

  const ehAvaliacaoModulo = avaliacao === TipoAvaliacao[0]

  const carregarTurma = async () => {
    try {
      const id = match.params.idDaTurma
      if (id) {
        const turma = await Api.ObterDetalhesTurmaPresencial(id)
        turma.modulos = turma.modulos.sort((a, b) => a.ordem - b.ordem)

        definirTurma(turma)
        definirAvaliacao(turma.tipoAvaliacaoCurso)
        definirDadosIniciais({ nome: turma.nomeTurma })
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados da Disciplina', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const verTurmas = ({
    idDaDisciplina,
    idDoModulo
  }: {
    idDaDisciplina?: string
    idDoModulo?: string
  }) => {
    const { idDaTurma } = match.params
    const parametros = `${idDaDisciplina}/${idDaTurma}/${idDoModulo}`

    const rota =
      turma.modalidade === ModalidadeCurso.PosGraduacaoPresencial ||
      ModalidadeCurso.Graduacao
        ? `${RotasTurmas.VerTurmasPresenciais}/${parametros}`
        : `${RotasTurmas.VerTurmasEad}/${parametros}`

    history.push(rota)
  }

  const verTurmasTCC = ({
    idDaDisciplina,
    idDoModulo
  }: {
    idDaDisciplina?: string
    idDoModulo?: string
  }) => {
    const { idDaTurma } = match.params
    const parametros = `${idDaDisciplina}/${idDaTurma}/${idDoModulo}`

    const rota =
      turma.modalidade === ModalidadeCurso.PosGraduacaoPresencial ||
      ModalidadeCurso.Graduacao
        ? `${RotasTurmas.VerTurmasPresenciaisTCC}/${parametros}`
        : `${RotasTurmas.VerTurmasEadTCC}/${parametros}`

    history.push(rota, { ehAvaliacaoModulo: ehAvaliacaoModulo })
  }

  const irParaAproveitamentoDisciplina = ({
    idDaDisciplina,
    idDoModulo
  }: {
    idDaDisciplina?: string
    idDoModulo?: string
  }) => {
    const { idDaTurma } = match.params
    const parametros = `${idDaDisciplina}/${idDaTurma}/${idDoModulo}`

    history.push(`${RotasTurmas.AproveitamentoDisciplina}/${parametros}`)
  }

  const desabilitarBotoes = (
    tutorTurma: boolean,
    tutorDisciplina = false
  ): boolean => {
    if (ipgs) return false
    if (parceiro) return false
    if (professor && tutorTurma) return false
    if (professor && tutorDisciplina) return false
    return true
  }

  const desabilitarBotaoAproveitamento = (): boolean => ipgs || parceiro

  const resolverProfessorDisciplina = (
    disciplina: DisciplinaTurma,
    turma: Turma
  ) => {
    let professor = ''

    if (disciplina.professores?.length > 0) {
      professor = disciplina.professores.map(x => x.nome).join(', ')
    } else {
      professor = turma.tutor
    }

    return professor
  }

  useEffect(() => {
    carregarTurma()
  }, [])

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerMain>
            <CabecalhoComEstilos>
              <ContainerLink>
                <Link
                  texto="Voltar"
                  href={
                    professor ? RotasProfessor.Dashboard : RotasAcademico.Turmas
                  }
                  icone={IconeBack}
                />
              </ContainerLink>
              <Breadcrumb
                titulo="Cadastro de Notas e Presença na Turma"
                atalhos={[
                  {
                    texto: 'Acadêmico'
                  },
                  {
                    texto: 'Notas e Presença'
                  }
                ]}
              />
            </CabecalhoComEstilos>
            <Conteudo>
              <SubTitulo texto="Disciplinas" />
              <FormUnform acaoSucesso={null} dadosIniciais={dadosIniciais}>
                <InputUnform name="nome" label="Turma" disabled={true} />
              </FormUnform>
              {turma?.modulos?.map(item => (
                <>
                  <ContainerModulo>
                    <p>{item.nomeModulo}</p>
                    {ehAvaliacaoModulo && visaoDiferenteAluno && (
                      <button
                        type="button"
                        data-tip="Ver Lista de Alunos"
                        data-for="tooltip-alunos"
                        disabled={desabilitarBotoes(turma.tutorTurma)}
                        onClick={() =>
                          verTurmas({
                            idDoModulo: item.idModulo
                          })
                        }
                      >
                        {IconeDoAvatarBusca}
                      </button>
                    )}
                  </ContainerModulo>
                  <ContainerDisciplinas>
                    <table>
                      <thead>
                        <th align="left">Disciplinas</th>
                        <th align="left">Professor Responsável/Tutor</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {item.disciplinas.map(disciplina => (
                          <tr key={disciplina.disciplinaId}>
                            <td align="left" width="300px">
                              {disciplina.nome}
                            </td>
                            <td align="left">
                              {resolverProfessorDisciplina(disciplina, turma)}
                            </td>
                            {/* TO-DO: Implementar funcionalidade */}
                            {/* {professor &&
                              (disciplina.professor.id === usuario.id ? (
                                <BotaoTabela color={Cores.CINZA_2}>
                                  <button
                                    type="button"
                                    data-tip="buscar"
                                    onClick={() =>
                                      history.push(
                                        `${RotasTurmas.VerTurmasPresenciais}/${TurmaMock.id}`
                                      )
                                    }
                                  >
                                    {IconeDoAvatarBusca}
                                  </button>
                                </BotaoTabela>
                              ) : disciplina.professor.id !== usuario.id ? (
                                <BotaoTabela color={Cores.CINZA_1_CLARO}>
                                  <button
                                    disabled={true}
                                    type="button"
                                    data-tip="buscar"
                                    onClick={() =>
                                      history.push(
                                        `${RotasTurmas.VerTurmasPresenciais}/${TurmaMock.id}`
                                      )
                                    }
                                  >
                                    {IconeDoAvatarBusca}
                                  </button>
                                </BotaoTabela>
                              ) : null)} */}
                            {!ehAvaliacaoModulo && visaoDiferenteAluno && (
                              <BotaoTabela color={Cores.CINZA_2_MAIS_ESCURO}>
                                <button
                                  type="button"
                                  data-tip="Ver Lista de Alunos"
                                  data-for="tooltip-alunos"
                                  disabled={desabilitarBotoes(
                                    turma.tutorTurma,
                                    disciplina.tutorDisciplina
                                  )}
                                  onClick={() =>
                                    verTurmas({
                                      idDaDisciplina: disciplina.disciplinaId
                                    })
                                  }
                                >
                                  {IconeDoAvatarBusca}
                                </button>
                              </BotaoTabela>
                            )}
                            <BotaoTabela color={Cores.CINZA_2_MAIS_ESCURO}>
                              <button
                                type="button"
                                data-tip="Dispensar alunos"
                                data-for="tooltip-dispensa-alunos"
                                // disabled={desabilitarBotaoAproveitamento()}
                                disabled={!desabilitarBotaoAproveitamento()}
                                onClick={() =>
                                  irParaAproveitamentoDisciplina({
                                    idDoModulo: item.idModulo,
                                    idDaDisciplina: disciplina.disciplinaId
                                  })
                                }
                              >
                                {IconeTurma}
                              </button>
                            </BotaoTabela>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </ContainerDisciplinas>
                </>
              ))}
            </Conteudo>
            <Conteudo>
              {turma?.moduloTCC && (
                <>
                  <ContainerModulo>
                    <p>{turma?.moduloTCC?.nome}</p>
                  </ContainerModulo>
                  <ContainerDisciplinas>
                    <table>
                      <thead>
                        <th align="left">Disciplinas</th>
                        <th align="left">Professor Orientador</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {turma?.moduloTCC?.disciplinas.map(disciplina => (
                          <tr key={disciplina.disciplinaId}>
                            <td align="left" width="300px">
                              {disciplina.nome}
                            </td>
                            <td align="left">
                              {disciplina?.professorOrientador?.nome}
                            </td>
                            <BotaoTabela color={Cores.CINZA_2_MAIS_ESCURO}>
                              <button
                                type="button"
                                data-tip="Ver Lista de Alunos"
                                data-for="tooltip-alunos"
                                disabled={desabilitarBotoes(
                                  turma.tutorTurma,
                                  disciplina.tutorDisciplina
                                )}
                                onClick={() =>
                                  verTurmasTCC({
                                    idDaDisciplina: disciplina.disciplinaId
                                  })
                                }
                              >
                                {IconeDoAvatarBusca}
                              </button>
                            </BotaoTabela>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </ContainerDisciplinas>
                </>
              )}
            </Conteudo>
            <ContainerBotao>
              <Botao
                type="button"
                texto="Voltar"
                tema="Secundario"
                onClick={() =>
                  professor ? history.push('/professor') : history.goBack()
                }
              />
            </ContainerBotao>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.Disciplinas,
                  nome: 'Disciplinas'
                }
              ]}
            />
          </ContainerLateral>
          <Tooltip
            id="tooltip-alunos"
            place="right"
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
          />
          <Tooltip
            id="tooltip-dispensa-alunos"
            place="right"
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
          />
        </Container>
      ) : (
        <>
          <Carregando></Carregando>
        </>
      )}
    </>
  )
}

export default withRouter(PaginaNotasPresenca)
