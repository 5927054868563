import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  FormUnform,
  IconeInformacao,
  ModalBase,
  ModalBaseProps,
  ModalRef,
  SelectAsyncUnform,
  SelectOpcao,
  SelectUnform
} from 'src/componentes'
import { mensagemPadrao } from 'src/dados-estaticos'
import { Api } from 'src/servicos'
import * as Yup from 'yup'

import {
  ContainerDoConteudo,
  ContainerDosBotoes,
  ContainerModal,
  Informacao,
  TituloPrincipal
} from './style'

const dias = Array.from({ length: 31 }, (x, i) => {
  const contador = `${i + 1}`
  return { id: contador, texto: contador } as SelectOpcao
})

export interface ModalAtualizarPlanoDePagamentoRef
  extends Omit<ModalRef, 'abrir'> {
  abrir: (index?: number) => void
}

export interface ModalAtualizarPlanoDePagamentoProps extends ModalBaseProps {
  idMatricula: string
  nome?: string
  idPlanoPagamento?: string
  idCupom?: string
}

export interface ModalAtualizarPlanoDePagamentoDados {
  planoDePagamento: string
  diaDePagamento: string
}

export const EsquemaDeValidacao = Yup.object().shape({
  planoDePagamento: Yup.string().required(mensagemPadrao),
  diaDePagamento: Yup.string().required(mensagemPadrao)
})

const Modal: React.ForwardRefRenderFunction<
  ModalAtualizarPlanoDePagamentoRef,
  ModalAtualizarPlanoDePagamentoProps
> = ({ backdrop, acaoPrimaria, idMatricula }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const fechar = () => definirAberta(false)
  const abrir = () => definirAberta(true)

  useImperativeHandle<
    ModalAtualizarPlanoDePagamentoRef,
    ModalAtualizarPlanoDePagamentoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (
    dados: ModalAtualizarPlanoDePagamentoDados
  ) => {
    acaoPrimaria(dados)
    fechar()
    formRef.current?.reset()
  }

  const obterPlanosDePagamento = async (): Promise<SelectOpcao[]> => {
    try {
      const resposta = await Api.RequisitarListaPlanosPagamento({ idMatricula })
      return resposta.map(({ id, texto }) => ({ id, texto } as SelectOpcao))
    } catch (erro) {
      return [] as SelectOpcao[]
    }
  }

  const dadosIniciais = useMemo(() => {
    return {
      planoDePagamento: null,
      diaDePagamento: null
    }
  }, [])

  return (
    <ModalBase
      id="modal-atualizar-plano-de-pagamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Atualizar Plano de Pagamento</TituloPrincipal>
        <FormUnform
          ref={formRef}
          acaoSucesso={acaoSucessoForm}
          schema={EsquemaDeValidacao}
          dadosIniciais={dadosIniciais}
        >
          <ContainerDoConteudo>
            <span>
              <b>*</b> Selecione o Plano de Pagamento do Curso
            </span>
            <SelectAsyncUnform
              id="ipt_plano_de_pagamento"
              name="planoDePagamento"
              buscarPorTexto={obterPlanosDePagamento}
              buscarPorId={obterPlanosDePagamento}
            />
            <span>
              <b>*</b> Selecione o Dia de Vencimento Desejado para a Parcela
            </span>
            <SelectUnform
              id="ipt_dia_de_pagamento"
              name="diaDePagamento"
              opcoes={dias}
            />
            <Informacao>
              {IconeInformacao}
              <p>
                O cupom de desconto utilizado na matrícula será contabilizado
                nas novas parcelas
              </p>
            </Informacao>
          </ContainerDoConteudo>
          <ContainerDosBotoes>
            <Botao
              texto="Cancelar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Salvar" />
          </ContainerDosBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalAtualizarPlanoDePagamento = forwardRef(Modal)
