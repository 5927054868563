import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { Disciplina } from 'src/tipos'

import {
  ModalBase,
  Botao,
  ModalBaseProps,
  SubTitulo,
  IconeDecisao
} from '../../../../../../../src/componentes'
import { ModalVincularRef } from '../../../../../../componentes/modal-vincular-disciplina/tipos'
import { ContainerBotoes, ContainerModal, CorpoModal } from '../../styles'

const ModalDesvincularDisciplina: React.ForwardRefRenderFunction<
  ModalVincularRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria, acaoSecundaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [indexModulo, definirIndexModulo] = useState(null)
  const [disciplina, definirDisciplina] = useState<Disciplina>(null)

  const fechar = () => {
    definirIndexModulo(null)
    definirDisciplina(null)
    definirAberta(false)
  }

  const abrir = (disciplina?: Disciplina, indexModulo?: number) => {
    definirIndexModulo(indexModulo)
    definirDisciplina(disciplina)
    definirAberta(true)
  }

  useImperativeHandle<ModalVincularRef, ModalVincularRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-confirmacao-cancelamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          {IconeDecisao}
          <h5> Deseja realmente desvincular esta disciplina do curso? </h5>
          <SubTitulo texto="Selecione uma opção" />
          <ContainerBotoes>
            <Botao
              type="button"
              id="btn-cancelar"
              texto="Não"
              tema="Secundario"
              onClick={() => {
                acaoSecundaria && acaoSecundaria()
                fechar()
              }}
            />
            <Botao
              type="button"
              id="btn-salvar"
              texto="Sim"
              onClick={() => {
                acaoPrimaria({ disciplina, indexModulo })
                fechar()
              }}
            />
          </ContainerBotoes>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const DesvincularDisciplina = forwardRef(ModalDesvincularDisciplina)
