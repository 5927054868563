import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#606060"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16302 16.4133C7.7969 16.0471 7.7969 15.4535 8.16302 15.0874L15.0872 8.16326C15.4533 7.79714 16.0469 7.79714 16.413 8.16326C16.7791 8.52938 16.7791 9.12297 16.413 9.48908L9.48884 16.4133C9.12272 16.7794 8.52913 16.7794 8.16302 16.4133Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33698 8.16326C8.7031 7.79714 9.29669 7.79714 9.66281 8.16326L16.587 15.0874C16.9531 15.4536 16.9531 16.0471 16.587 16.4133C16.2209 16.7794 15.6273 16.7794 15.2612 16.4133L8.33698 9.48909C7.97087 9.12297 7.97087 8.52938 8.33698 8.16326Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.125C17.5919 22.125 22.125 17.5919 22.125 12C22.125 6.40812 17.5919 1.875 12 1.875C6.40812 1.875 1.875 6.40812 1.875 12C1.875 17.5919 6.40812 22.125 12 22.125ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16302 16.4133C7.7969 16.0471 7.7969 15.4536 8.16302 15.0874L15.0872 8.16326C15.4533 7.79714 16.0469 7.79714 16.413 8.16326C16.7791 8.52938 16.7791 9.12297 16.413 9.48908L9.48884 16.4133C9.12272 16.7794 8.52913 16.7794 8.16302 16.4133Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33698 8.16326C8.7031 7.79714 9.29669 7.79714 9.66281 8.16326L16.587 15.0874C16.9531 15.4536 16.9531 16.0471 16.587 16.4133C16.2209 16.7794 15.6273 16.7794 15.2612 16.4133L8.33698 9.48909C7.97087 9.12297 7.97087 8.52938 8.33698 8.16326Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.125C17.5919 22.125 22.125 17.5919 22.125 12C22.125 6.40812 17.5919 1.875 12 1.875C6.40812 1.875 1.875 6.40812 1.875 12C1.875 17.5919 6.40812 22.125 12 22.125ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="currentColor"
    />
  </svg>
)
