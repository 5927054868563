import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  FormUnform,
  ModalBase,
  NumeroInputUnform,
  TextAreaUnform,
  CheckboxUnform,
  AutenticacaoContainer,
  Visao
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
import { ModalidadeCurso } from 'src/tipos'

import {
  Dados,
  ModalNotaModuloProps,
  ModalNotaModuloRef,
  NotaModuloProps,
  schemaComUpload
} from '.'
import { EncontroPresencial, TipoArquivo } from '../../tipos'
import { dataFormatadaComBarras } from '../turma-presencial'
import {
  ContainerAcoes,
  ContainerModal,
  CorpoModal,
  ContainerDadosGrande,
  ContainerInformacoes,
  ContainerCheckbox
} from './styles'

export interface TipoDeOpcoesDeEntro {
  id: number
  dataEncontro: string
  presente: boolean
}

const ModalModulo: React.ForwardRefRenderFunction<
  ModalNotaModuloRef,
  ModalNotaModuloProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [informacoes, definirInformacoes] = useState<NotaModuloProps>({
    avaliacaoId: null,
    nome: '',
    modulo: ''
  })
  const [encontros, definirEncontros] = useState<TipoDeOpcoesDeEntro[]>([])
  const { visao } = AutenticacaoContainer.useContainer()
  const visaoIPGS = useMemo(() => visao === Visao.IPGS, [visao])

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = (dados: NotaModuloProps) => {
    definirInformacoes(dados)
    definirPronto(true)
    definirAberta(true)

    const reunioes = dados.encontros.map(
      (encontro: EncontroPresencial, indice: number) =>
        ({
          id: indice,
          dataEncontro: encontro.dataEncontro,
          presente: encontro.presenca || false
        } as TipoDeOpcoesDeEntro)
    )

    definirEncontros(reunioes)
  }

  const ehPresencial =
    informacoes.modalidade === ModalidadeCurso.PosGraduacaoPresencial

  const avaliacaoUpload = informacoes.tipoAvaliacao === TipoArquivo.Upload

  useImperativeHandle<ModalNotaModuloRef, ModalNotaModuloRef>(ref, () => ({
    fechar,
    abrir
  }))

  const dadosIniciais = useMemo(() => {
    return {
      nota: informacoes.nota,
      quantidadeTentativas: informacoes.tentativas,
      feedback: informacoes.feedback,
      observacao: informacoes.observacao
    }
  }, [informacoes])

  const acaoSucessoForm = async (dados: Dados) => {
    if (ehPresencial) {
      acaoPrimaria({
        avaliacaoId: dados.avaliacaoId,
        encontros: encontros,
        feedback: dados.feedback,
        nota: dados.nota
      })
    } else {
      acaoPrimaria(dados)
    }
    fechar()
  }

  const atualizarEncontros = (id: number, presente: boolean) => {
    const reunioes = encontros.map(encontro => {
      if (encontro.id === id) {
        return {
          ...encontro,
          presente
        }
      }

      return encontro
    })

    definirEncontros(reunioes)
  }

  return (
    <ModalBase id="modal-nota-modulo" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Nota do Módulo</h5>
          <ContainerInformacoes>
            <h4>
              {'Aluno(a): '}
              {informacoes.nome}
            </h4>
          </ContainerInformacoes>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              ref={formRef}
              dadosIniciais={dadosIniciais}
              schema={schemaComUpload}
            >
              <ContainerDadosGrande>
                <NumeroInputUnform
                  id="ipt_tentativas_restantes"
                  name="quantidadeTentativas"
                  label="Tentativas Restantes"
                  disabled={!visaoIPGS}
                  isDecimal
                  readOnly
                />
              </ContainerDadosGrande>
              <ContainerDadosGrande>
                <NumeroInputUnform
                  id="ipt_nota"
                  name="nota"
                  label={
                    avaliacaoUpload
                      ? 'Informe a nota da avaliação (de 0 a 10)'
                      : 'Nota na Avaliação Optativa'
                  }
                  obrigatorio={avaliacaoUpload}
                  isDecimal
                />
              </ContainerDadosGrande>
              <ContainerDadosGrande>
                {avaliacaoUpload && (
                  <TextAreaUnform
                    id="ipt_feedback"
                    name="feedback"
                    label="Preencha o feedback da avaliação"
                    obrigatorio
                  />
                )}
              </ContainerDadosGrande>
              <ContainerDadosGrande>
                <TextAreaUnform
                  id="ipt_observacao"
                  name="observacao"
                  label="Observação"
                  disabled={!visaoIPGS}
                />
              </ContainerDadosGrande>
              {ehPresencial && (
                <>
                  <CampoLabel>
                    Marque os encontros em que o aluno esteve presente
                  </CampoLabel>
                  <ContainerCheckbox>
                    {informacoes.encontros.map(
                      (encontro: EncontroPresencial, indice: number) => (
                        <div key={encontro.dataEncontro}>
                          <CampoLabel>
                            {dataFormatadaComBarras(
                              new Date(encontro.dataEncontro)
                            )}
                          </CampoLabel>
                          <CheckboxUnform
                            id={`data-${encontro.dataEncontro}`}
                            name={`data-${encontro.dataEncontro}`}
                            checked={encontro.presenca}
                            onChange={() => {
                              atualizarEncontros(indice, !encontro.presenca)
                              encontro.presenca = !encontro.presenca
                            }}
                          />
                        </div>
                      )
                    )}
                  </ContainerCheckbox>
                </>
              )}
              <div style={{ marginTop: '35px' }}>
                <ContainerAcoes>
                  <Botao
                    texto="Cancelar"
                    tema="Secundario"
                    type="button"
                    disabled={carregando}
                    onClick={() => fechar()}
                  />
                  <Botao texto="Salvar" carregando={carregando} />
                </ContainerAcoes>
              </div>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalNotaModulo = forwardRef(ModalModulo)
