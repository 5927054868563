import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ModalBase,
  Botao,
  ModalBaseProps,
  SubTitulo,
  IconeDecisao
} from 'src/componentes'

import { TipoMaterialComplementar } from '..'

import { ContainerBotoes, ContainerModal, CorpoModal } from '../styles'
import { ModalExcluirMaterialRef } from './tipos'

const ModalExcluirComponente: React.ForwardRefRenderFunction<
  ModalExcluirMaterialRef,
  Omit<ModalBaseProps, 'acaoPrimaria'> & {
    acaoPrimaria: (
      index?: number,
      referencia?: string,
      tipoMaterialComplementar?: TipoMaterialComplementar
    ) => void
  }
> = ({ backdrop, acaoPrimaria, acaoSecundaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [materialIndex, definirMaterialIndex] = useState<number | null>(null)
  const [referencia, definirReferencia] = useState<string | null>(null)
  const [
    tipoMaterialComplementar,
    definirTipoMaterialComplementar
  ] = useState<TipoMaterialComplementar | null>(null)

  const fechar = () => {
    definirReferencia(null)
    definirMaterialIndex(null)
    definirAberta(false)
    definirTipoMaterialComplementar(null)
  }

  const abrir = (
    index?: number,
    referencia?: string,
    tipoMaterialComplementar?: TipoMaterialComplementar
  ) => {
    definirAberta(true)
    definirMaterialIndex(index)
    definirReferencia(referencia)
    definirTipoMaterialComplementar(tipoMaterialComplementar)
  }

  useImperativeHandle<ModalExcluirMaterialRef, ModalExcluirMaterialRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase
      id="modal-confirmacao-cancelamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          {IconeDecisao}
          <h5>Deseja realmente excluir este material?</h5>
          <SubTitulo texto="Selecione uma opção" />
          <ContainerBotoes>
            <Botao
              type="button"
              id="btn-cancelar"
              texto="Não"
              tema="Secundario"
              onClick={() => {
                acaoSecundaria && acaoSecundaria()
                definirMaterialIndex(null)
                fechar()
              }}
            />
            <Botao
              type="button"
              id="btn-salvar"
              texto="Sim"
              onClick={() => {
                acaoPrimaria(
                  materialIndex,
                  referencia,
                  tipoMaterialComplementar
                )
                definirMaterialIndex(null)
                fechar()
              }}
            />
          </ContainerBotoes>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalExcluirMaterial = forwardRef(ModalExcluirComponente)
