import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { SituacaoFinanceiraPorExtenso } from 'src/tipos'

export const colunaSituacaoFinanceira = (): TabelaColuna => ({
  Chave: 'SituacaoFinanceira',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <>{SituacaoFinanceiraPorExtenso[linha.SituacaoFinanceira]}</>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 150 }}>Situação Matrícula</div>
  )
})
