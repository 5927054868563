import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaTentativaNumero = (): TabelaColuna => ({
  Chave: 'tentativaNumero',
  Alinhamento: 'center',
  ComOrdenacao: true,
  RenderizarCabecalho: () => <div style={{ width: 90 }}>Tentativa número</div>
})
