import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'
export const CorpoModal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 75%;
`

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  padding: 40px 36px 40px 46px;
  height: 100%;
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0px 10px 0px 0px;

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  & > span {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 9px;

    & > b {
      color: ${Cores.PERIGO_ERRO};
    }
  }
`

export const TituloPrincipal = styled.h2`
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 30px;
`

export const ContainerDosBotoes = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  & > button:nth-of-type(1) {
    margin-right: 16px;
  }
`
export const Linha = styled.div`
  display: flex;
  flex-direction: row;
`

export const Coluna = styled.div`
  flex: 1;

  & + & {
    margin-left: 5px;
  }
`
export const IconeLabel = styled.div`
  position: absolute;
  right: 58%;
  top: 0;

  svg {
    min-width: 12px;
    min-height: 12px;
  }
`
export const IconeLabelExcluir = styled.div`
  display: flex;
  position: inherit;
  margin-bottom: 10px;
  right: 58%;
  top: 0;

  svg {
    min-width: 12px;
    min-height: 12px;
    margin-right: 5px;
  }
`

export const TextoSubTotal = styled.p`
  & > a {
    text-decoration: underline;
  }
`
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 25px;
  p {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
    b {
      opacity: 1;
      color: #3d3d3d;
      font-weight: 400;
    }
  }
  span {
    margin-right: 5px;
    margin-top: 2px;
  }
`

export const ContainerCheckbox = styled.div`
  margin-bottom: 15px;

  label,
  a {
    font-family: 'Saira', sans-serif;
    font-size: 14px;
    line-height: 100%;

    cursor: pointer;
  }

  label {
    color: ${Cores.CINZA_2};
  }
`
export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`
