import { mensagemPadrao } from 'src/dados-estaticos'
import { TipoMaterial } from 'src/tipos'
import * as Yup from 'yup'

export const createSchema = (
  titulosCadastrados: string[],
  edicao?: boolean,
  exibirTipoMaterial?: boolean
): Yup.ObjectSchema =>
  Yup.object().shape({
    titulo: Yup.string()
      .required(mensagemPadrao)
      .notOneOf(titulosCadastrados, 'Titulo do material deve ser único'),
    tipo: exibirTipoMaterial
      ? Yup.string().required()
      : Yup.string().nullable(),
    url: Yup.string()
      .url('Url inválida')
      .nullable()
      .when('tipo', {
        is: value =>
          value === TipoMaterial.Video || value === TipoMaterial.HiperLink,
        then: field => field.required(mensagemPadrao)
      }),
    arquivo: Yup.string()
      .nullable()
      .when('tipo', {
        is: value => value === TipoMaterial.Anexo && !edicao,
        then: field => field.required(mensagemPadrao)
      })
  })
