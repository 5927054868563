import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  Botao,
  FormUnform,
  ModalBaseProps,
  NumeroInputUnform,
  Spacer
} from 'src/componentes'
import { ModalBase } from 'src/componentes/modal-base'
import { PlanoPagamentoReingresso } from 'src/tipos'

import { Corpo, ContainerBotoes } from '../../styles'
import { ModalIncluirPlanoReingressoRef } from '../tipos'

const ModalIncluir: React.ForwardRefRenderFunction<
  ModalIncluirPlanoReingressoRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [pronto, definirPronto] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const [, definirDados] = useState<PlanoPagamentoReingresso>(null)
  const [
    planoPagamento,
    definirPlanoPagamento
  ] = useState<PlanoPagamentoReingresso>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = planoPagamento => {
    definirPlanoPagamento(planoPagamento)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<
    ModalIncluirPlanoReingressoRef,
    ModalIncluirPlanoReingressoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = async (dados: PlanoPagamentoReingresso) => {
    const novaParcela = {
      id: planoPagamento.id,
      quantidade: dados.quantidade,
      formaPagamento: planoPagamento.formaPagamento
    }
    definirDados(dados)
    acaoPrimaria(novaParcela)
    fechar()
  }

  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo>
          <h5 style={{ margin: 'auto' }}> Editar Parcela </h5>
          <Spacer padding="10px 0px" />
          {pronto && (
            <FormUnform
              dadosIniciais={planoPagamento}
              acaoSucesso={acaoSucesso}
            >
              <NumeroInputUnform
                name="quantidade"
                max={9999}
                maxLength={4}
                label="Quantidade de parcelas"
              />
              <Spacer padding="10px 0px" />
              <ContainerBotoes>
                <Botao
                  type="button"
                  id="btn-cancelar"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                />
                <Botao id="btn-salvar" texto="Salvar" type="submit" />
              </ContainerBotoes>
            </FormUnform>
          )}
        </Corpo>
      </ModalBase>
    </>
  )
}
export const ModalIncluirPlanoReingresso = forwardRef(ModalIncluir)
