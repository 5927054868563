import { ModalBaseProps, ModalRef } from 'src/componentes'
import { Material } from 'src/tipos'

export interface ModalIncluirProps
  extends Omit<ModalBaseProps, 'acaoPrimaria'> {
  dados?: Material
  acaoPrimaria: (
    any,
    index?: number,
    referencia?: string,
    tipoMaterialComplementar?: TipoMaterialComplementar
  ) => void
  exibirTipoMaterial: boolean
}

export interface ModalMaterialRef extends Omit<ModalRef, 'abrir'> {
  abrir: (
    titulosUtilizados: string[],
    material?: Material,
    index?: number,
    referencia?: string,
    tipoMaterialComplementar?: TipoMaterialComplementar
  ) => void
}

export enum TipoMaterialComplementar {
  Artigos = 'Artigos',
  Encontros = 'Encontros',
  GestaoDeCarreira = 'GestaoDeCarreira'
}

export enum TipoMaterialComplementarPorExtenso {
  Artigos = 'Artigos',
  Encontros = 'Aulas',
  GestaoDeCarreira = 'Gestão de carreira'
}
