import styled, { css } from 'styled-components'

import { Cores } from '../../styles'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.CINZA_1_CLARO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 11px 7px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 25px;

  ${({ theme: { ComErro } }) =>
    ComErro &&
    css`
      border: 1px solid ${Cores.PERIGO_ERRO};

      span {
        opacity: 1;
        bottom: -20px;
      }
    `}
`

export const Enunciado = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  white-space: pre-line;
  color: ${Cores.CINZA_2};
  margin-bottom: 10px;
`

export const Conteudo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 150px;

  textarea {
    min-height: 100px;
  }
`

export const Erro = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  white-space: pre-line;
  opacity: 0;
  transition: opacity 0.2s ease-in, bottom 0.3s ease-in;
`
