import { toast } from 'react-toastify'

import { saveAs } from 'file-saver'
import qs from 'qs'
import { nomeArquivoContentDisposition } from 'src/componentes'
import {
  BaixaManualCobranca as BaixaManual,
  BaixaManualTaxaMatricula,
  BaixaManualTaxaReingresso,
  BaixaManualTaxaTrocaCurso,
  FormaPagamentoCobranca
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'

export const BaixaManualCobranca = async (
  payload: BaixaManual,
  idsCobrancas: string[]
): Promise<boolean> => {
  const parametros = qs.stringify({
    ...payload,
    cobrancaId: idsCobrancas
  })

  const requisicao = {
    url: `/cobranca/realizar-baixa-manual?${parametros}`,
    data: null,
    config: null
  }

  if (payload.comprovante && payload.comprovante instanceof File) {
    const formData = new FormData()

    formData.append('arquivo', payload.comprovante, payload.comprovante.name)
    requisicao.data = formData
    requisicao.config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  }

  const resposta = await gatewayApi.post(
    requisicao.url,
    requisicao.data,
    requisicao.config
  )

  return resposta.status === 200 || resposta.status === 204
}

export const BaixaManualMatricula = async (
  payload: BaixaManualTaxaMatricula,
  idCobranca: string
): Promise<boolean> => {
  const parametros = qs.stringify({
    cobrancaId: idCobranca,
    codigoCupom: payload.codigoCupom,
    formaPagamento: payload.formaPagamento,
    dataPagamento: payload.dataPagamento,
    valorPagamento: payload.valorPagamento
  })

  const requisicao = {
    url: `/cobranca/realizar-baixa-manual-taxa-matricula?${parametros}`,
    data: null,
    config: null
  }

  if (payload.comprovante && payload.comprovante instanceof File) {
    const formData = new FormData()

    formData.append('arquivo', payload.comprovante, payload.comprovante.name)
    requisicao.data = formData
    requisicao.config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  }

  const resposta = await gatewayApi.post(
    requisicao.url,
    requisicao.data,
    requisicao.config
  )

  return resposta.status === 200 || resposta.status === 204
}

export const BaixaManualTrocaCurso = async (
  payload: BaixaManualTaxaTrocaCurso,
  idCobranca: string
): Promise<boolean> => {
  const parametros = qs.stringify({
    cobrancaId: idCobranca,
    codigoCupom: payload.codigoCupom,
    formaPagamento: payload.formaPagamento,
    dataPagamento: payload.dataPagamento,
    valorPagamento: payload.valorPagamento
  })

  const requisicao = {
    url: `/cobranca/realizar-baixa-manual-taxa-troca-curso?${parametros}`,
    data: null,
    config: null
  }

  if (payload.comprovante && payload.comprovante instanceof File) {
    const formData = new FormData()

    formData.append('arquivo', payload.comprovante, payload.comprovante.name)
    requisicao.data = formData
    requisicao.config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  }

  const resposta = await gatewayApi.post(
    requisicao.url,
    requisicao.data,
    requisicao.config
  )

  return resposta.status === 200 || resposta.status === 204
}

export const BaixarComprovanteCobranca = async (
  cobrancaId: string,
  formaPagamento: FormaPagamentoCobranca
): Promise<any> => {
  try {
    const resposta = await gatewayApi.get(
      `/cobranca/obter-comprovante/${cobrancaId}/${formaPagamento}`,
      { responseType: 'blob' }
    )

    if (resposta?.status !== 200) return null

    if (resposta.data) {
      const nomeArquivo = nomeArquivoContentDisposition(
        resposta.headers['content-disposition']
      )

      saveAs(resposta.data, nomeArquivo)
    }
  } catch (error) {
    return toast('Comprovante de pagamento não encontrado.', { type: 'error' })
  }
}

export const CancelarParcelas = async (
  cobrancasId: string[],
  observacao?: string
): Promise<boolean> => {
  const resposta = await gatewayApi.put('/cobranca/cancelar-parcela', {
    cobrancasId,
    observacao
  })

  return resposta.status === 200 || resposta.status === 204
}
export const ReembolsoParcela = async (
  cobrancasId: string[],
  observacao?: string
): Promise<boolean> => {
  const resposta = await gatewayApi.put('/cobranca/reembolso-parcela', {
    cobrancasId,
    observacao
  })

  return resposta.status === 200 || resposta.status === 204
}

export const BaixaManualCarrinhoCompraCursoLivre = async (
  payload: BaixaManualTaxaMatricula,
  idCarrinhoCompra: string
): Promise<boolean> => {
  const parametros = qs.stringify({
    carrinhoCompraId: idCarrinhoCompra,
    codigoCupom: payload.codigoCupom,
    formaPagamento: payload.formaPagamento,
    dataPagamento: payload.dataPagamento,
    valorPagamento: payload.valorPagamento
  })

  const requisicao = {
    url: `/cobranca/realizar-baixa-manual-taxa-matricula/carrinho-compra?${parametros}`,
    data: null,
    config: null
  }

  if (payload.comprovante && payload.comprovante instanceof File) {
    const formData = new FormData()

    formData.append('arquivo', payload.comprovante, payload.comprovante.name)
    requisicao.data = formData
    requisicao.config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  }

  const resposta = await gatewayApi.post(
    requisicao.url,
    requisicao.data,
    requisicao.config
  )

  return resposta.status === 200 || resposta.status === 204
}

export const AlterarObservacao = async (
  cobrancasId: string[],
  observacao?: string,
  observacaoInterna?: boolean
): Promise<boolean> => {
  const resposta = await gatewayApi.put('/cobranca/alterar-observacao', {
    cobrancasId,
    observacao,
    observacaoInterna
  })

  return resposta.status === 200 || resposta.status === 204
}

export const BaixaTaxaReingresso = async (
  payload: BaixaManualTaxaReingresso,
  idCobranca: string
): Promise<boolean> => {
  const parametros = qs.stringify({
    cobrancaId: idCobranca,
    formaPagamento: payload.formaPagamento,
    dataPagamento: payload.dataPagamento,
    valorPagamento: payload.valorPagamento
  })

  const requisicao = {
    url: `/cobranca/realizar-baixa-manual-taxa-reingresso?${parametros}`,
    data: null,
    config: null
  }

  if (payload.comprovante && payload.comprovante instanceof File) {
    const formData = new FormData()

    formData.append('arquivo', payload.comprovante, payload.comprovante.name)
    requisicao.data = formData
    requisicao.config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  }

  const resposta = await gatewayApi.post(
    requisicao.url,
    requisicao.data,
    requisicao.config
  )

  return resposta.status === 200 || resposta.status === 204
}
