import styled, { css } from 'styled-components'

import { ComponenteBotao } from '../../botao/styles'
import { TamanhoInput } from '../../input/tipos'
import { Cores } from '../../styles/cores'

export interface ComponenteProps {
  erro: string
  readOnly?: boolean
  disabled?: boolean
  tamanho?: TamanhoInput
  posicao?: 'direita' | 'esquerda'
  maxWidthMenu?: string
  margemInferior?: boolean
}

export const Clipboard = styled.div`
  margin-left: 12px;
  align-items: center;
  max-height: 24px;
  display: none;
  justify-content: center;
`

export const Composicao = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: fit-content;
  cursor: pointer;
  height: 24px;

  ${ComponenteBotao} {
    border: none !important;
    padding: 0;

    &:focus:not(.sucesso):not(.erro) {
      border: none !important;
      outline: 0;
    }
  }

  & input {
    cursor: pointer;
  }

  &:hover > ${Clipboard} {
    display: flex;
  }
`

export const CampoReadOnly = styled('div')<{
  temValor?: boolean
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  height: 100%;
  padding: 0;
  border: 0px;
  font-family: Saira, sans-serif;
  font-size: 16px;
  color: ${({ temValor }) =>
    temValor ? Cores.CINZA_2_ESCURO : Cores.CINZA_3_ESCURO};
  background-color: unset;

  &:hover {
    border: 0px;
  }

  &:active {
    border: 0px;
  }

  &:focus {
    border: 0px;
  }

  & > input {
    order: unset;
  }

  ${ComponenteBotao} {
    margin-left: 12px;
    border: none !important;
    padding: 0;

    &:focus:not(.sucesso):not(.erro) {
      border: none !important;
      outline: 0;
    }
  }

  &:hover > ${Clipboard} {
    display: flex;
  }
`

export const Componente = styled.div<ComponenteProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ margemInferior }) =>
    margemInferior &&
    css`
      margin-bottom: 25px;
    `}

  ${({ erro }) =>
    erro &&
    css`
      margin-bottom: 25px;

      span {
        opacity: 1;
        bottom: -20px;
      }
    `}

  ${({ erro, readOnly, disabled, tamanho, maxWidthMenu, posicao }) => css`
    .react-select__control {
      outline: none;
      border: 1px solid ${Cores.CINZA_3_ESCURO} t;
      font-size: 14px;
      font-family: Saira, sans-serif;
      box-shadow: none !important;

      ${!tamanho
        ? css`
            padding-left: 13px;
            font-size: 14px;
            height: 40px;
          `
        : tamanho === 'S'
        ? css`
            padding-left: 13px;
            font-size: 14px;
            height: 48px;
          `
        : tamanho === 'M'
        ? css`
            padding-left: 17px;
            font-size: 16px;
            height: 54px;
          `
        : css`
            padding-left: 17px;
            font-size: 18px;
            height: 60px;
          `}

      ${readOnly &&
      css`
        padding-left: 0px !important;
        border: 0px;
        background-color: unset;
        height: fit-content !important;
        min-height: 22px;
      `}

      [aria-live='polite'] {
        display: none;
      }

      .react-select__placeholder {
        color: ${Cores.CINZA_3_ESCURO};
      }

      .react-select__indicator-separator {
        display: none;
      }

      &:not(:placeholder-shown) {
        span {
          color: ${Cores.CINZA_2};
        }
      }

      &:hover {
        border: ${readOnly
          ? '0px !important'
          : `1px solid ${Cores.PRIMARIA_1} !important`};
      }

      ${erro &&
      css`
        border: 1px solid ${Cores.PERIGO_ERRO} !important;
      `}

      &.react-select__control--is-focused {
        border: ${readOnly
          ? '0px !important'
          : `1px solid ${Cores.PRIMARIA_2} !important`};
        span {
          color: ${Cores.CINZA_2_ESCURO};
        }
      }

      .react-select__clear-indicator {
        display: none;
      }

      .react-select__loading-indicator {
        display: none;
      }

      .react-select__dropdown-indicator {
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
          fill: ${Cores.CINZA_2};
        }
      }

      .react-select__value-container {
        padding: 0 !important;
        height: 100%;
      }

      ${disabled &&
      css`
        pointer-events: none;
        cursor: not-allowed;
        color: ${Cores.CINZA_3_ESCURO};
        background-color: rgba(209, 211, 212, 0.36);
        border: 1px solid rgba(189, 191, 192, 0.36) !important;
      `}
    }

    .react-select__menu {
      margin-top: 0;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.12) !important;
      border-radius: 0px 4px 4px 4px !important;
      border: 1px solid ${Cores.CINZA_3};
      z-index: 3 !important;

      ${maxWidthMenu &&
      css`
        width: fit-content;
        max-width: ${maxWidthMenu};
      `}

      ${posicao === 'esquerda' &&
      css`
        right: 0;
      `}
    }

    .react-select__menu-list::-webkit-scrollbar {
      width: 0.6rem;
    }

    .react-select__menu-list::-webkit-scrollbar-track {
      box-shadow: none;
    }

    .react-select__menu-list::-webkit-scrollbar-thumb {
      background-color: ${Cores.CINZA_3};
      outline: none;
      border-radius: 5px;
    }

    .react-select__option {
      font-family: Saira, sans-serif;
      font-size: 14px;
      color: ${Cores.CINZA_2_ESCURO};
      height: 40px;
      cursor: pointer;
      padding: 0 12px;
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:active {
        color: ${Cores.PRIMARIA_2};
        background: ${Cores.CINZA_3_CLARO};
      }
    }

    .react-select__option--is-selected {
      color: ${Cores.PRIMARIA_2};
      background: ${Cores.CINZA_3_CLARO};
    }

    .react-select__option--is-focused {
      background: ${Cores.CINZA_3_MAIS_CLARO};
      color: ${Cores.PRIMARIA_1};
    }

    .react-select__menu-notice {
      color: ${Cores.CINZA_3_ESCURO};
    }
  `}
`

export const Label = styled.label<{ readOnly?: boolean }>`
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  text-align: left;
  margin-bottom: 6px;
  line-height: 125%;
  color: ${({ readOnly }) =>
    readOnly ? Cores.CINZA_2_CLARO : Cores.CINZA_2_ESCURO};

  :first-letter {
    text-transform: uppercase;
  }
`

export const Grupo = styled.div<{
  maxWidthInput?: string
  readOnly: boolean
  comCopia: boolean
  id?: string
}>`
  position: relative;
  max-width: ${({ maxWidthInput }) => maxWidthInput || '100%'};

  input {
    caret-color: ${Cores.PRIMARIA_2};
  }
  ${({ id, readOnly, comCopia }) => {
    return css`
      #${id} {
        display: ${readOnly && comCopia ? 'none' : 'block'};
      }
    `
  }};

  .react-select__indicators > div {
    height: 100%;
    align-items: center;
  }

  .icone_carregando {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 32px;
    top: 0px;
    z-index: 0;
    color: ${Cores.BRANCO};
  }

  .icone_fechar {
    position: absolute;
    height: 100%;
    width: 13px;
    right: 33px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 0;
  }
`

export const Erro = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  white-space: pre-line;
  opacity: 0;
  transition: opacity 0.2s ease-in, bottom 0.3s ease-in;
`

export const Obrigatorio = styled.span`
  color: ${Cores.PERIGO_ERRO};
  margin-right: 5px;
`
