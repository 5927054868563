import { isString } from 'lodash'
import { nomeArquivoContentDisposition } from 'src/componentes'
import { TipoGrupoDiscussao } from 'src/tipos'
import {
  GrupoDiscussao,
  Topico,
  GrupoDiscussaoTCCAluno
} from 'src/tipos/grupo-discussao'

import { gatewayApi } from '../instancias-api'
import { CriarTopicoPayload, CriarTopicoLivrePayload } from './tipos'

export const RequisitarGrupoDiscussao = async (
  idTurma: string
): Promise<GrupoDiscussao> => {
  const resposta = await gatewayApi.get<GrupoDiscussao>(
    `/grupoDiscussao/${idTurma}`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const CriarTopico = async (
  payload: CriarTopicoPayload
): Promise<void> => {
  await gatewayApi.post('/grupoDiscussao/criacao-topico', payload)
}

export const RequisitarTopicoPorId = async (
  idTopico: string
): Promise<Topico> => {
  const resposta = await gatewayApi.get<Topico>(
    `/grupoDiscussao/topico/${idTopico}`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const CriarComentario = async (
  idGrupoDiscussao: string,
  idTopico: string,
  texto: string,
  privado: boolean,
  arquivo?: File | string
): Promise<void> => {
  const bodyFormData = new FormData()

  if (arquivo && !isString(arquivo)) {
    const arquivoLocal = arquivo as File
    bodyFormData.append('arquivo', arquivo, arquivoLocal.name)
  }

  bodyFormData.append('comentario', texto)
  bodyFormData.append('privado', `${privado}`)
  await gatewayApi.post(
    `/grupoDiscussao/${idGrupoDiscussao}/${idTopico}/inclusao-comentario-topico`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const CriarRespostaComentario = async (
  grupo: string,
  topico: string,
  comentario: string,
  texto: string,
  privado: boolean,
  arquivo?: File | string
): Promise<void> => {
  const bodyFormData = new FormData()

  if (arquivo && !isString(arquivo)) {
    const arquivoLocal = arquivo as File
    bodyFormData.append('arquivo', arquivo, arquivoLocal.name)
  }

  bodyFormData.append('comentario', texto)
  bodyFormData.append('privado', `${privado}`)
  await gatewayApi.post(
    `/grupoDiscussao/${grupo}/${topico}/${comentario}/inclusao-comentario-topico`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const RequisitarGrupoDiscussaoLivre = async (
  cursoId: string
): Promise<GrupoDiscussao> => {
  const resposta = await gatewayApi.get<GrupoDiscussao>(
    `/grupoDiscussao/livre/${cursoId}`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const CriarTopicoLivre = async (
  payload: CriarTopicoLivrePayload
): Promise<void> => {
  await gatewayApi.post('/grupoDiscussao/livre/criacao-topico', payload)
}

export const DeletarComentario = async (
  grupoDiscussaoId: string,
  topicoId: string,
  comentarioId: string
): Promise<void> => {
  await gatewayApi.post(
    `/grupoDiscussao/${grupoDiscussaoId}/${topicoId}/${comentarioId}/deletar-comentario`
  )
}

export const DeletarRespostaComentario = async (
  grupoDiscussaoId: string,
  topicoId: string,
  comentarioId: string,
  respostaComentarioId: string
): Promise<void> => {
  await gatewayApi.post(
    `/grupoDiscussao/${grupoDiscussaoId}/${topicoId}/${comentarioId}/${respostaComentarioId}/deletar-resposta`
  )
}

export const RequisitarGrupoDiscussaoTCC = async (
  idTurma: string,
  salaAulaEadId: string
): Promise<GrupoDiscussaoTCCAluno> => {
  const resposta = await gatewayApi.get<GrupoDiscussaoTCCAluno>(
    `/grupoDiscussao/${idTurma}/sala/${salaAulaEadId}`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}
export const RequisitarGrupoDiscussaoPorTipo = async (
  idTurma: string,
  tipoGrupoDiscussao: TipoGrupoDiscussao
): Promise<GrupoDiscussao> => {
  const resposta = await gatewayApi.get<GrupoDiscussao>(
    `/grupoDiscussao/${idTurma}/tipo?tipo=${tipoGrupoDiscussao}`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const DownloadArquivoComentario = async (
  topicoId: string,
  comentarioId: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/grupoDiscussao/topico/${topicoId}/comentario/${comentarioId}/arquivo`,
    {
      responseType: 'blob'
    }
  )
  if (resposta?.status !== 200) return null
  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )
    saveAs(resposta.data, nomeArquivo)
  }
}

export const DownloadArquivoComentarioResposta = async (
  topicoId: string,
  comentarioId: string,
  comentarioRespostaId: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/grupoDiscussao/topico/${topicoId}/comentario/${comentarioId}/resposta/${comentarioRespostaId}/arquivo`,
    {
      responseType: 'blob'
    }
  )
  if (resposta?.status !== 200) return null
  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )
    saveAs(resposta.data, nomeArquivo)
  }
}

export const DeletarTopico = async (
  grupoDiscussaoId: string,
  topicoId: string
): Promise<void> => {
  await gatewayApi.post(
    `/grupoDiscussao/${grupoDiscussaoId}/${topicoId}/deletar-topico`
  )
}

export const FixarTopico = async (
  grupoDiscussaoId: string,
  topicoId: string,
  fixar: boolean,
  ordem: string
): Promise<void> => {
  await gatewayApi.put(
    `/grupoDiscussao/${grupoDiscussaoId}/${topicoId}/fixar-topico`,
    { fixar, ordem }
  )
}
