import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  Botao,
  CartaoCreditoInputUnform,
  Checkbox,
  Cores,
  FormUnform,
  IconeCartaoAmex,
  IconeCartaoDiners,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoMaster,
  IconeCartaoVisa,
  IconeInformacao,
  InputMesAnoUnform,
  InputUnform,
  ModalBase,
  MoedaInputUnform,
  regExpAmex,
  regExpDiners,
  regExpElo,
  regExpHiper,
  regExpJcb,
  regExpMaster,
  regExpVisa,
  SelectOpcao,
  SelectUnform,
  Tooltip
} from 'src/componentes'
import { FormaPagamentoCobranca } from 'src/tipos'

import { schemaBoleto, schemaCartao } from './schema'
import {
  ContainerModal,
  TituloPrincipal,
  ContainerBotoes,
  Linha,
  ContainerMedio,
  ContainerPequeno,
  LinhaBandeiras,
  ContainerBandeiras,
  IconeLabel,
  Aviso,
  CorpoModal
} from './styles'
import {
  ModalInscricaoRecuperacaoProps,
  ModalInscricaoRecuperacaoRef,
  ValoresRecuperacao
} from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalInscricaoRecuperacaoRef,
  ModalInscricaoRecuperacaoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [numeroCartao, definirNumeroCartao] = useState('')
  const [aceitouTermos, definirAceitouTermos] = useState(false)
  const [metodoPagamento, definirMetodoPagamento] = useState(null)
  const [quantidadeMaximaParcelas, definirQuantidadeMaximaParcelas] = useState(
    1
  )
  const [
    limparCamposFormulario,
    definirLimparCamposFormulario
  ] = useState<boolean>()
  const [carregando, definirCarregando] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const atualizarValorFormulario = async (campo: string, valor: any) => {
    if (!valor) return
    formRef?.current.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const limparForm = () => {
    definirLimparCamposFormulario(false)
    definirMetodoPagamento(null)
    definirAceitouTermos(false)
  }

  const fechar = () => {
    limparForm()
    definirAberta(false)
    formRef.current?.reset()
  }

  const abrir = (valorTotal: number, quantidadeParcelas: number) => {
    definirLimparCamposFormulario(true)
    if (valorTotal) atualizarValorFormulario('valorTotal', valorTotal)

    definirQuantidadeMaximaParcelas(quantidadeParcelas)
    definirAberta(true)
  }

  const acaoSucessoForm = async (dados: ValoresRecuperacao) => {
    try {
      definirCarregando(true)
      await acaoPrimaria(dados)
      fechar()
      formRef.current?.reset()
    } catch (error) {
      toast('Erro ao enviar os dados do pagamento da recuperação', {
        type: 'error'
      })
    } finally {
      limparForm()
      definirCarregando(false)
    }
  }

  const schema = useMemo(
    () =>
      metodoPagamento ===
      FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito]
        ? schemaCartao
        : schemaBoleto,
    [metodoPagamento]
  )

  useImperativeHandle<
    ModalInscricaoRecuperacaoRef,
    ModalInscricaoRecuperacaoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const parcelas = useMemo(() => {
    if (
      metodoPagamento === FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto]
    ) {
      return [{ id: '1', texto: '1x (à vista)' }]
    } else if (
      metodoPagamento ===
      FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito]
    ) {
      return Array.from({ length: quantidadeMaximaParcelas }, (x, i) => {
        const contador = `${i + 1}`
        return { id: contador, texto: `${contador}x` }
      }) as SelectOpcao[]
    }
    return []
  }, [quantidadeMaximaParcelas, metodoPagamento])

  useEffect(() => {
    if (
      metodoPagamento === FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto]
    ) {
      atualizarValorFormulario('parcelas', '1')
    }
  }, [metodoPagamento])

  return (
    <ModalBase
      id="modal-inscricao-recuperacao"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          <TituloPrincipal>Inscrição na Recuperação</TituloPrincipal>
          <p>
            O pagamento da Recuperação ficará disponível no seu Painel
            Financeiro do Curso.
          </p>
          <FormUnform
            ref={formRef}
            acaoSucesso={acaoSucessoForm}
            schema={schema}
          >
            <Linha>
              <ContainerPequeno>
                <MoedaInputUnform
                  label="Valor Total à Pagar"
                  name="valorTotal"
                  disabled
                />
              </ContainerPequeno>
            </Linha>
            {limparCamposFormulario && (
              <Linha>
                <ContainerMedio>
                  <SelectUnform
                    id="slct_formaPagamento"
                    name="formaPagamento"
                    label="Forma de Pagamento"
                    placeholder="Selecione"
                    obrigatorio
                    valorAlterado={valor => {
                      definirMetodoPagamento(valor.id)
                      definirNumeroCartao('')

                      formRef.current?.setErrors({})
                    }}
                    opcoes={[
                      {
                        id:
                          FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto],
                        texto: 'Boleto'
                      },
                      {
                        id:
                          FormaPagamentoCobranca[
                            FormaPagamentoCobranca.CartaoCredito
                          ],
                        texto: 'Cartão de Crédito'
                      }
                    ]}
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <SelectUnform
                    id="slc_parcelas"
                    name="parcelas"
                    label="Quantidade de Parcelas"
                    disabled={
                      metodoPagamento ===
                      FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto]
                    }
                    obrigatorio
                    opcoes={parcelas}
                  />
                </ContainerMedio>
              </Linha>
            )}

            {metodoPagamento ===
              FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito] && (
              <>
                <LinhaBandeiras>
                  <ContainerBandeiras
                    selecionado={
                      !!numeroCartao.match(regExpVisa) &&
                      !numeroCartao.match(regExpElo)
                    }
                  >
                    {IconeCartaoVisa}
                  </ContainerBandeiras>
                  <ContainerBandeiras
                    selecionado={!!numeroCartao.match(regExpMaster)}
                  >
                    {IconeCartaoMaster}
                  </ContainerBandeiras>
                  <ContainerBandeiras
                    selecionado={!!numeroCartao.match(regExpAmex)}
                  >
                    {IconeCartaoAmex}
                  </ContainerBandeiras>
                  <ContainerBandeiras
                    selecionado={!!numeroCartao.match(regExpElo)}
                  >
                    {IconeCartaoElo}
                  </ContainerBandeiras>
                  <ContainerBandeiras
                    selecionado={!!numeroCartao.match(regExpHiper)}
                  >
                    {IconeCartaoHiper}
                  </ContainerBandeiras>
                  <ContainerBandeiras
                    selecionado={!!numeroCartao.match(regExpJcb)}
                  >
                    {IconeCartaoJcb}
                  </ContainerBandeiras>
                  <ContainerBandeiras
                    selecionado={!!numeroCartao.match(regExpDiners)}
                  >
                    {IconeCartaoDiners}
                  </ContainerBandeiras>
                </LinhaBandeiras>
                <CartaoCreditoInputUnform
                  id="ipt_numero_cartao"
                  name="numeroCartao"
                  label="Número do Cartão"
                  obrigatorio
                  onChange={e => {
                    const valor = e.target.value.replaceAll(' ', '')
                    definirNumeroCartao(valor)
                  }}
                />
                <InputUnform
                  id="ipt_nome_cartao"
                  name="nomeCartao"
                  label="Nome Conforme Consta no Cartão"
                  obrigatorio
                />
                <Linha>
                  <ContainerMedio>
                    <InputMesAnoUnform
                      id="ipt_validade_cartao"
                      name="validadeCartao"
                      label="Validade do Cartão"
                      placeholder="mm/aaaa"
                      formatoAno="AAAA"
                      obrigatorio
                    />
                  </ContainerMedio>
                  <ContainerMedio>
                    <InputUnform
                      id="ipt_codigo_seguranca"
                      name="codigoSeguranca"
                      label={
                        <>
                          Código de Segurança
                          <IconeLabel
                            data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                            data-for="tooltip-cvv"
                          >
                            {IconeInformacao}
                          </IconeLabel>
                        </>
                      }
                      obrigatorio
                      maxLength={4}
                    />
                    <Tooltip
                      id="tooltip-cvv"
                      place="right"
                      className="tooltip-ipgs"
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                    />
                  </ContainerMedio>
                </Linha>
              </>
            )}

            <Linha>
              <Aviso>
                Esta ação é irreversível e não será possível alterar a opção
                escolhida no futuro.
              </Aviso>
            </Linha>

            <LinhaBandeiras>
              <Checkbox
                id={'chk_termos_recup'}
                texto="Estou ciente da minha escolha e aceito os termos de realização da Recuperação"
                htmlFor={'chk_aceitou_termos'}
                tamanho="L"
              >
                <input
                  type="checkbox"
                  id={'chk_aceitou_termos'}
                  checked={aceitouTermos}
                  onChange={() => definirAceitouTermos(!aceitouTermos)}
                />
              </Checkbox>
            </LinhaBandeiras>

            <ContainerBotoes>
              <Botao
                texto="Cancelar"
                tema="Secundario"
                type="button"
                onClick={() => fechar()}
              />
              <Botao
                texto="Salvar"
                disabled={!aceitouTermos}
                carregando={carregando}
              />
            </ContainerBotoes>
          </FormUnform>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalInscricaoRecuperacao = forwardRef(Modal)
