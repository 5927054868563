import React, { useState, useMemo, useRef, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { Scope } from '@unform/core'
import {
  AutenticacaoContainer,
  Botao,
  BotaoStep,
  Informacao,
  ModalLogin,
  WizardContainer,
  ModalLoginRef,
  CheckboxUnform,
  FormUnform,
  InputUnform,
  CpfInputUnform,
  TelefoneInputUnform,
  SelectUnform,
  FuncoesDataHora,
  ResumoCurso,
  FormRef,
  scrollParaTopo,
  focarPrimeiroCampoComErro
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
import {
  SELECT_ESCOLARIDADE,
  SELECT_FORMACAO,
  SELECT_NACIONALIDADES,
  SELECT_PAISES,
  SELECT_RACA
} from 'src/dados-estaticos'
import { Api } from 'src/servicos'
import { Escolaridade, FormacaoPorExtenso, PaisResidencia } from 'src/tipos'

import MatriculaContainer from '../../container'
import {
  schemaPosGraduacaoDadosBasicos,
  schemaPosGraduacaoDadosBasicosComSenha
} from '../../schema'
import {
  ContainerConteudo,
  Opcao,
  OpcaoDados,
  ContainerCadastro,
  ContainerCheio,
  ContainerGrande,
  ContainerMedio,
  ContainerPequeno,
  Linha,
  ContainerAcesso,
  ContainerAcessoBotoes,
  ContainerSenha
} from '../../styles'
import { DadosPessoaisForm } from '../../tipos'
import {
  ModalAvisoPendenciaFinanceira,
  ModalAvisoPendenciaFinanceiraRef
} from '../modal-matriculas-pendentes'
import { ContainerAcoes } from './styles'

export const DadosPessoais: React.FC = () => {
  const formRef = useRef<FormRef>(null)
  const {
    matricula,
    curso,
    definirPasso,
    definirDadosPessoais
  } = MatriculaContainer.useContainer()
  const { usuario, atualizarUsuario } = AutenticacaoContainer.useContainer()
  const { mudarEtapa, passo } = WizardContainer.useContainer()
  const modalLogin = useRef<ModalLoginRef>(null)
  const modalPendenciaFinanceiroRef = useRef<ModalAvisoPendenciaFinanceiraRef>(
    null
  )
  const [abrirCadastro, definirAbrirCadastro] = useState(!!usuario)
  const [possuiNomeSocial, definirPossuiNomeSocial] = useState(
    matricula.dadosPessoais?.possuiNomeSocial ??
      !!(usuario && usuario.nomeSocial) ??
      false
  )
  const [nacionalidade, definirNacionalidade] = useState(
    SELECT_NACIONALIDADES[0].id
  )
  const [escolaridade, definirEscolaridade] = useState(
    SELECT_ESCOLARIDADE[0].id
  )
  const [pais, definirPais] = useState<string>(PaisResidencia.Brasil)
  const [carregando, definirCarregando] = useState(false)
  const [formacaoSelecionada, definirFormacaoSelecionada] = useState(null)

  const dadosIniciais = useMemo(() => {
    if (matricula.dadosPessoais) {
      const {
        nome,
        nomeSocial,
        cpf,
        email,
        nacionalidade,
        celular,
        corRaca,
        escolaridade,
        dataNascimento,
        possuiNomeSocial,
        documentoIdentidade,
        codigoConselho,
        mesAnoColacaoGrau,
        profissao,
        profissaoOutros,
        endereco
      } = matricula.dadosPessoais

      return {
        nome,
        nomeSocial: nomeSocial || '',
        cpf,
        email,
        nacionalidade: nacionalidade || SELECT_NACIONALIDADES[0].id,
        celular,
        corRaca,
        escolaridade,
        dataNascimento: dataNascimento
          ? FuncoesDataHora.novaData(dataNascimento)
          : '',
        mesAnoColacaoGrau:
          mesAnoColacaoGrau?.length > 1
            ? FuncoesDataHora.novaData(mesAnoColacaoGrau)
            : '',
        possuiNomeSocial,
        documentoIdentidade: documentoIdentidade || '',
        codigoConselho: codigoConselho || '',
        profissao,
        profissaoOutros: profissaoOutros || '',
        endereco: {
          ...endereco,
          cep: endereco.cep ?? '',
          pais: endereco.pais || PaisResidencia.Brasil
        }
      }
    } else if (usuario) {
      const {
        nome,
        nomeSocial,
        cpf,
        documentoIdentidade,
        codigoConselho,
        email,
        nacionalidade,
        celular,
        corRaca,
        escolaridade,
        dataNascimento,
        endereco,
        mesAnoColacaoGrau,
        profissao,
        profissaoOutros
      } = usuario

      return {
        nome,
        nomeSocial: nomeSocial || '',
        cpf,
        documentoIdentidade: documentoIdentidade || '',
        codigoConselho: codigoConselho,
        email,
        nacionalidade: nacionalidade || SELECT_NACIONALIDADES[0].id,
        celular,
        corRaca,
        escolaridade,
        dataNascimento: dataNascimento
          ? FuncoesDataHora.novaData(dataNascimento)
          : '',
        mesAnoColacaoGrau:
          mesAnoColacaoGrau?.length > 1
            ? FuncoesDataHora.novaData(mesAnoColacaoGrau)
            : '',
        profissao,
        profissaoOutros: profissaoOutros,
        endereco: {
          ...endereco,
          cep: endereco && endereco.cep ? endereco.cep : '',
          pais:
            endereco && endereco.paisResidencia
              ? endereco.paisResidencia
              : PaisResidencia.Brasil
        }
      }
    } else {
      return {
        nacionalidade: SELECT_NACIONALIDADES[0].id,
        endereco: { pais: PaisResidencia.Brasil }
      }
    }
  }, [usuario, matricula.dadosPessoais])

  const acaoSucesso = async (dados: DadosPessoaisForm) => {
    try {
      definirCarregando(true)

      let resposta = {} as {
        matriculaId: string
        token?: string
        pendencias?: boolean
        cursosPendentes?: string[]
      }

      if (!matricula.id) {
        const payload = {
          ...dados,
          dataNascimento: FuncoesDataHora.transformarPadraoAmericano(
            dados.dataNascimento
          ),
          mesAnoColacaoGrau:
            dados.mesAnoColacaoGrau?.length > 1
              ? FuncoesDataHora.transformarPadraoAmericano(
                  dados.mesAnoColacaoGrau
                )
              : '',
          cursoId: curso.id,
          turmaId: curso.turmaId,
          senha: dados.novaSenha,
          endereco: {
            ...dados.endereco,
            cep: dados.endereco?.cep?.replaceAll('-', '')
          }
        }

        let token = null

        if (usuario && usuario.id) {
          resposta = await Api.CadastrarUsuarioAutenticadoOutrosCursos(payload)

          if (resposta && resposta.pendencias) {
            modalPendenciaFinanceiroRef?.current?.abrir(
              usuario.nomeApresentacao,
              resposta.cursosPendentes
            )
            return
          }

          token = usuario.token
        } else {
          const now = new Date()
          resposta = await Api.CadastrarUsuarioOutrosCursos({
            ...payload,
            mesAnoColacaoGrau:
              dados.mesAnoColacaoGrau?.length > 1
                ? FuncoesDataHora.transformarPadraoAmericano(
                    dados.mesAnoColacaoGrau
                  )
                : '',
            cpf: dados.cpf,
            nacionalidade: dados.nacionalidade,
            nome: dados.nome,
            senha: dados.novaSenha,
            repetirSenha: dados.repetirSenha,
            dataPrimeiroAcesso: now
          })

          token = resposta.token
        }

        await atualizarUsuario({ token }, true)
      } else {
        const payload = {
          mesAnoColacaoGrau:
            dados.mesAnoColacaoGrau?.length > 1
              ? FuncoesDataHora.transformarPadraoAmericano(
                  dados.mesAnoColacaoGrau
                )
              : '',
          endereco: {
            ...dados.endereco,
            cep: dados.endereco?.cep?.replaceAll('-', '')
          },
          email: dados.email,
          documentoIdentidade: dados.documentoIdentidade,
          codigoConselho: dados.codigoConselho,
          nomeSocial: dados.nomeSocial,
          celular: dados.celular,
          escolaridade: dados.escolaridade,
          matriculaId: matricula.id,
          cursoId: curso.id,
          turmaId: curso.turmaId,
          profissaoOutros: dados.profissaoOutros
        }

        await Api.EditarUsuarioOutrosCursos(payload)

        resposta = { matriculaId: payload.matriculaId }
      }

      if (resposta) {
        definirDadosPessoais(resposta.matriculaId, {
          ...dados,
          mesAnoColacaoGrau:
            dados.mesAnoColacaoGrau?.length > 1
              ? FuncoesDataHora.transformarPadraoAmericano(
                  dados.mesAnoColacaoGrau
                )
              : '',
          dataNascimento: FuncoesDataHora.transformarPadraoAmericano(
            dados.dataNascimento
          )
        })

        mudarEtapa(2)
        definirPasso(2)
      }
    } finally {
      definirCarregando(false)
    }

    if (process.env.REACT_APP_GTM) {
      if (!usuario && !matricula.dadosPessoais) {
        const dadosNovoCadastro = {
          email: dados.email,
          nome: dados.nome,
          nomeSocial: dados.nomeSocial,
          celular: dados.celular,
          endereco: dados.endereco,
          nacionalidade: dados.nacionalidade
        }
        const tagManagerArgs = {
          gtmId: 'GTM-NRDM9FL',
          dataLayer: {
            event: `Novo cadastro de aluno ${curso.modalidade}`,
            detalhesNovoUsuario: dadosNovoCadastro
          }
        }
        TagManager.initialize(tagManagerArgs)
      }
    }
  }

  const buscarCep = async (cep: string) => {
    if (pais !== PaisResidencia.Brasil) {
      return
    }

    const endereco = await Api.BuscarCep(cep)

    if (endereco) {
      formRef.current?.setFieldValue('endereco.bairro', endereco.bairro)
      formRef.current?.setFieldValue('endereco.logradouro', endereco.logradouro)
      formRef.current?.setFieldValue('endereco.cidade', endereco.cidade)
      formRef.current?.setFieldValue('endereco.uf', endereco.uf)
      formRef.current?.setFieldValue('endereco.numero', endereco.numero)
      document.getElementById('ipt_numero')?.focus()
    } else {
      formRef.current?.setFieldValue('endereco.bairro', '')
      formRef.current?.setFieldValue('endereco.logradouro', '')
      formRef.current?.setFieldValue('endereco.cidade', '')
      formRef.current?.setFieldValue('endereco.uf', '')
      formRef.current?.setFieldValue('endereco.complemento', '')
      formRef.current?.setFieldValue('endereco.numero', '')
    }
  }

  useEffect(() => {
    scrollParaTopo()
  }, [passo])

  useEffect(() => {
    formRef.current?.setData(dadosIniciais)
  }, [dadosIniciais])

  useEffect(() => {
    if (formacaoSelecionada !== FormacaoPorExtenso.Outra) {
      formRef.current?.setFieldValue('profissaoOutros', null)
    }
  }, [formacaoSelecionada])

  const manipularAbrirCadastro = () => {
    definirAbrirCadastro(true)

    window.setTimeout(() => {
      document
        .querySelectorAll('#secao-dados-basicos')
        ?.item(0)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
    }, 200)
  }

  return (
    <>
      <ContainerConteudo>
        <h2>Curso</h2>
        <ResumoCurso curso={curso} />
        {!usuario && !matricula.dadosPessoais && (
          <ContainerAcesso>
            <p>Para continuar sua matrícula, identifique-se:</p>
            <ContainerAcessoBotoes>
              <Botao
                texto="Desejo me cadastrar"
                tema="Link"
                tamanho="S"
                onClick={manipularAbrirCadastro}
              />
              <Botao
                texto="Já possuo cadastro no iPGS Education"
                tema="Link"
                onClick={() => {
                  modalLogin?.current?.abrir()
                }}
              />
            </ContainerAcessoBotoes>
            <Informacao>
              Os dados de login podem ser diferentes dos que você utiliza para
              acessar o site do iPGS. Se ainda não possui cadastro no iPGS
              Education, clique acima para se cadastrar.
            </Informacao>
          </ContainerAcesso>
        )}
        {abrirCadastro && (
          <ContainerCadastro id="secao-dados-basicos">
            {!usuario && !matricula.dadosPessoais && (
              <p>Para continuar sua matrícula, identifique-se:</p>
            )}
            <FormUnform
              ref={formRef}
              dadosIniciais={dadosIniciais}
              schema={
                usuario
                  ? schemaPosGraduacaoDadosBasicos
                  : schemaPosGraduacaoDadosBasicosComSenha
              }
              acaoSucesso={acaoSucesso}
              acaoFalha={focarPrimeiroCampoComErro}
            >
              <h4>Dados Pessoais</h4>
              <Linha>
                <ContainerMedio>
                  <SelectUnform
                    id="slc_nacionalidade"
                    name="nacionalidade"
                    label="Nacionalidade"
                    placeholder="Selecione"
                    obrigatorio
                    opcoes={SELECT_NACIONALIDADES}
                    disabled={!!usuario || !!matricula.dadosPessoais}
                    valorAlterado={valor => definirNacionalidade(valor.id)}
                  />
                </ContainerMedio>
                {nacionalidade === SELECT_NACIONALIDADES[0].id && (
                  <ContainerMedio>
                    <CpfInputUnform
                      id="ipt_cpf"
                      name="cpf"
                      label="CPF"
                      obrigatorio
                      disabled={!!usuario || !!matricula.dadosPessoais}
                    />
                  </ContainerMedio>
                )}
                <ContainerMedio>
                  <InputUnform
                    id="ipt_documento_identidade"
                    name="documentoIdentidade"
                    label="Documento de Identidade"
                    obrigatorio
                    disabled={
                      !!(
                        (usuario && usuario.documentoIdentidade) ||
                        (matricula.dadosPessoais &&
                          matricula.dadosPessoais?.documentoIdentidade)
                      )
                    }
                    maxLength={20}
                    bloquearCaracteresEspeciais
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    id="ipt_data_nascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    placeholder="__/__/____"
                    mascara="99/99/9999"
                    obrigatorio
                    disabled={
                      dadosIniciais.dataNascimento
                        ? !!usuario || !!matricula.dadosPessoais
                        : false
                    }
                  />
                </ContainerMedio>
              </Linha>
              <Linha>
                <ContainerCheio>
                  <InputUnform
                    id="ipt_nome_completo"
                    name="nome"
                    label="Nome Completo (Atenção: Nome que será utilizado na emissão de certificados, diplomas e demais documentos oficiais da Instituição)"
                    obrigatorio
                    disabled={!!usuario || !!matricula.dadosPessoais}
                    maxLength={200}
                    style={{ textTransform: 'uppercase' }}
                  />
                </ContainerCheio>
              </Linha>
              <Opcao>
                <CampoLabel>Possui nome social?</CampoLabel>
                <OpcaoDados>
                  <CheckboxUnform
                    id="chk_possui_nome_social"
                    name="possuiNomeSocial"
                    onChange={() => definirPossuiNomeSocial(old => !old)}
                    checked={possuiNomeSocial}
                  />
                  <p>
                    <b>Sim</b>{' '}
                    <i>
                      (Nome social é a adoção de nome diferente do oficialmente
                      registrado, de modo a identificar adequadamente sua
                      identidade de gênero - Decreto nº 8727/2016)
                    </i>
                  </p>
                </OpcaoDados>
              </Opcao>
              {possuiNomeSocial ? (
                <Linha>
                  <ContainerCheio>
                    <InputUnform
                      name="nomeSocial"
                      label="Nome Social"
                      maxLength={200}
                      obrigatorio
                      style={{ textTransform: 'uppercase' }}
                    />
                  </ContainerCheio>
                </Linha>
              ) : (
                <></>
              )}
              <Linha>
                <ContainerMedio>
                  <SelectUnform
                    id="slc_cor_raca"
                    name="corRaca"
                    label="Cor/Raça"
                    placeholder="Selecione"
                    obrigatorio
                    opcoes={SELECT_RACA}
                    disabled={
                      !!(
                        (usuario && usuario.corRaca) ||
                        (matricula.dadosPessoais &&
                          matricula.dadosPessoais?.corRaca)
                      )
                    }
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    id="ipt_email"
                    name="email"
                    label="E-mail"
                    obrigatorio
                    disabled={!!usuario}
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <TelefoneInputUnform
                    id="ipt_celular"
                    name="celular"
                    label="Celular"
                    obrigatorio
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    id="ipt_condigo_conselho"
                    name="codigoConselho"
                    label="Código do conselho"
                    maxLength={20}
                    bloquearCaracteresEspeciais
                  />
                </ContainerMedio>
              </Linha>
              <Linha>
                <ContainerMedio>
                  <SelectUnform
                    id="slc_escolaridade"
                    name="escolaridade"
                    label="Escolaridade"
                    placeholder="Selecione"
                    obrigatorio
                    iconeFechar
                    opcoes={SELECT_ESCOLARIDADE}
                    valorAlterado={valor =>
                      definirEscolaridade(valor ? valor.id : '')
                    }
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <SelectUnform
                    className="lg"
                    id="formacao"
                    name="profissao"
                    label="Formação"
                    placeholder="Selecione"
                    valorAlterado={valor => {
                      if (valor && valor.texto) {
                        definirFormacaoSelecionada(valor.texto)
                      } else {
                        definirFormacaoSelecionada(null)
                      }
                    }}
                    opcoes={SELECT_FORMACAO}
                    obrigatorio
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    type="text"
                    name="profissaoOutros"
                    label="Qual?"
                    disabled={formacaoSelecionada !== FormacaoPorExtenso.Outra}
                    maxLength={200}
                    obrigatorio={
                      formacaoSelecionada === FormacaoPorExtenso.Outra
                    }
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    id="ipt_data_colacao_grau"
                    name="mesAnoColacaoGrau"
                    placeholder="__/__/____"
                    mascara="99/99/9999"
                    label={
                      escolaridade ===
                      Escolaridade[
                        Escolaridade.EnsinoSuperiorEmAndamento
                      ].toString()
                        ? 'Data Previsão da Col. de Grau'
                        : 'Data da Colação de Grau'
                    }
                    obrigatorio={
                      escolaridade ===
                      Escolaridade[
                        Escolaridade.EnsinoSuperiorEmAndamento
                      ].toString()
                    }
                    disabled={
                      dadosIniciais?.mesAnoColacaoGrau
                        ? !!usuario || !!matricula.dadosPessoais
                        : false
                    }
                  />
                </ContainerMedio>
              </Linha>

              <h4>Residência</h4>
              <Scope path="endereco">
                <Linha>
                  <ContainerMedio>
                    <SelectUnform
                      id="slc_pais"
                      name="pais"
                      label="País"
                      placeholder="Selecione"
                      obrigatorio
                      opcoes={SELECT_PAISES}
                      valorAlterado={opcao =>
                        definirPais(opcao ? opcao.id : '')
                      }
                    />
                  </ContainerMedio>
                  <ContainerMedio>
                    <Linha>
                      <ContainerMedio>
                        <InputUnform
                          id="ipt_cep"
                          name="cep"
                          label="CEP"
                          obrigatorio={pais === PaisResidencia.Brasil}
                          mascara="99999-999"
                          onBlur={event => buscarCep(event.target.value)}
                        />
                      </ContainerMedio>
                    </Linha>
                  </ContainerMedio>
                </Linha>
                <Linha>
                  <ContainerGrande>
                    <InputUnform
                      id="ipt_logradouro"
                      name="logradouro"
                      label="Logradouro"
                      obrigatorio
                      maxLength={200}
                    />
                  </ContainerGrande>
                  <ContainerPequeno>
                    <InputUnform
                      id="ipt_numero"
                      name="numero"
                      label="Número"
                      obrigatorio
                      maxLength={10}
                    />
                  </ContainerPequeno>
                </Linha>
                <Linha>
                  <ContainerMedio>
                    <InputUnform
                      id="ipt_complemento"
                      name="complemento"
                      label="Complemento"
                      maxLength={200}
                    />
                  </ContainerMedio>
                  <ContainerMedio>
                    <InputUnform
                      id="ipt_bairro"
                      name="bairro"
                      label="Bairro"
                      obrigatorio
                      maxLength={200}
                    />
                  </ContainerMedio>
                </Linha>
                <Linha>
                  <ContainerMedio>
                    <InputUnform
                      id="ipt_cidade"
                      name="cidade"
                      label="Cidade"
                      obrigatorio
                      maxLength={200}
                    />
                  </ContainerMedio>
                  <ContainerMedio>
                    <InputUnform id="ipt_uf" name="uf" label="UF" obrigatorio />
                  </ContainerMedio>
                </Linha>
              </Scope>
              {!usuario && !matricula.dadosPessoais ? (
                <>
                  <h4>Senha</h4>
                  <Linha>
                    <ContainerSenha>
                      <InputUnform
                        id="ipt_senha"
                        name="novaSenha"
                        label="Senha"
                        type="password"
                        obrigatorio
                        autoComplete="new-password"
                      />
                    </ContainerSenha>
                    <ContainerSenha>
                      <InputUnform
                        id="ipt_repetir_senha"
                        name="repetirSenha"
                        label="Repetir Senha"
                        type="password"
                        obrigatorio
                        autoComplete="new-password"
                        semValidacaoSenha
                      />
                    </ContainerSenha>
                  </Linha>
                </>
              ) : (
                <></>
              )}
              <ContainerAcoes>
                <BotaoStep
                  texto={usuario ? 'Ir para' : 'Finalizar cadastro e ir para'}
                  textoNegrito="Plano de Pagamento do Curso"
                  carregando={carregando}
                  type="submit"
                />
              </ContainerAcoes>
            </FormUnform>
          </ContainerCadastro>
        )}
      </ContainerConteudo>
      <ModalLogin
        ref={modalLogin}
        backdrop
        acaoSucesso={() => {
          definirAbrirCadastro(true)
        }}
        acaoCadastrar={() => {
          modalLogin.current?.fechar()
          manipularAbrirCadastro()
        }}
      />
      <ModalAvisoPendenciaFinanceira
        id="modal-aviso-pendencial-financeira"
        ref={modalPendenciaFinanceiroRef}
        backdrop
      />
    </>
  )
}
