import queryString from 'query-string'
import { PesquisaAlunoDTO, PesquisaAlunosResposta } from 'src/dto'
import {
  CursoLivre,
  CursoOutro
} from 'src/paginas/aluno/painel-financeiro/tipos'
import {
  Aluno as AlunoEditarSituacao,
  AlunoEditado
} from 'src/paginas/ipgs/alunos/edicao/tipos'
import {
  Aluno,
  AlunoNotas,
  AlunoSituacao,
  TipoOrdenacao,
  ModalidadeCurso,
  ContratosDoAluno
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'
import { AlunoEditadoComSucesso } from './tipos'

const urlBase = '/aluno'

export interface DadoFinanceiroDto {
  dadosFinanceiros: CursoDto[]
}

export interface CursoDto {
  matriculaId: string
  modalidadeCurso: keyof typeof ModalidadeCurso
  nomeCurso: string
  saldoDevedor: number
  valorTotalCurso: number
}

export const ObterAluno = async (): Promise<Aluno> => {
  const resultado = await gatewayApi.get<Aluno>(urlBase)
  return resultado.data
}

export const EditarAluno = async (
  aluno: Aluno
): Promise<AlunoEditadoComSucesso> => {
  const resultado = await gatewayApi.put<AlunoEditadoComSucesso>(urlBase, aluno)
  return resultado.data
}

export const RequisitarListaAlunos = async (
  nome: string,
  cpf: string,
  email: string,
  situacao: keyof typeof AlunoSituacao,
  turmaId: string,
  cursoId: string,
  situacaoMatricula: string,
  dataInicioMatricula: string,
  dataFimMatricula: string,
  pagina: number,
  quantidadeRegistros: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaAlunoDTO[]
  opcoesPaginacao: {
    pagina: number
    tamanhoPagina: number
    totalRegistros: number
    totalPaginas: number
  }
}> => {
  try {
    const ordem =
      tipoOrdenacao === TipoOrdenacao.Crescente
        ? chaveOrdenacao
        : chaveOrdenacao?.concat('_Desc')

    const filtros = queryString.stringify(
      {
        nome,
        cpf: cpf ? cpf.replaceAll('.', '').replaceAll('-', '') : null,
        email,
        situacao: situacao === 'Todas' ? null : situacao,
        turmaId,
        cursoId,
        pagina,
        quantidadeRegistros,
        ordem,
        situacaoFinanceira:
          situacaoMatricula === 'Todas' ? null : situacaoMatricula,
        dataInicioMatricula,
        dataFimMatricula
      },
      {
        skipNull: true,
        skipEmptyString: true
      }
    )

    const {
      data: { itens, quantidade }
    } = await gatewayApi.get<PesquisaAlunosResposta>(
      `${urlBase}/listar?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }

    return { registros: itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const RequisitarNotasAluno = async (
  idMatricula: string
): Promise<AlunoNotas> => {
  const { data } = await gatewayApi.get<AlunoNotas>(
    `${urlBase}/retornar-avaliacao-aluno/${idMatricula}`
  )

  return data
}

export const carregarDadosPainelFinanceiro = async (
  id: string
): Promise<CursoLivre[] | CursoOutro[]> => {
  const { data } = await gatewayApi.get<DadoFinanceiroDto>(
    `Matricula/${id}/dados-financeiros-aluno`
  )

  return data?.dadosFinanceiros?.map(x => ({
    id: x.matriculaId,
    nome: x.nomeCurso,
    saldoDevedor: x.saldoDevedor,
    modalidade: x.modalidadeCurso,
    valor: x.valorTotalCurso
  })) as CursoLivre[] | CursoOutro[]
}

export const ObterAlunoPorId = async (
  id: string
): Promise<AlunoEditarSituacao> => {
  const { data } = await gatewayApi.get<AlunoEditarSituacao>(`${urlBase}/${id}`)

  return data
}

export const EditarSituacaoAluno = async (
  id: string,
  aluno: AlunoEditado
): Promise<AlunoEditado> => {
  const resultado = await gatewayApi.put<AlunoEditado>(
    `${urlBase}/atualizar-situacao/${id}`,
    aluno
  )
  return resultado.data
}

export const ObterContratosAluno = async (
  idUsuario: string
): Promise<ContratosDoAluno> => {
  const url = `${urlBase}/meus-contratos/${idUsuario}`
  const { data } = await gatewayApi.get<ContratosDoAluno>(url)

  return data
}

export const ObterContratosAlunoMatricula = async (
  idUsuario: string
): Promise<ContratosDoAluno> => {
  const url = `${urlBase}/meus-contratos-matricula/${idUsuario}`
  const { data } = await gatewayApi.get<ContratosDoAluno>(url)

  return data
}

export const AtualizarDataColacaoGrau = async (
  usuarioId: string,
  mesAnoColacaoGrau: string
): Promise<void> => {
  await gatewayApi.put(`${urlBase}/${usuarioId}/mes-ano-colacao-grau`, {
    mesAnoColacaoGrau
  })
}
