export enum SetorTicket {
  Gerencial = 'Gerencial',
  Financeiro = 'Financeiro',
  Academico = 'Academico',
  Comercial = 'Comercial'
}

export enum TipoTicket {
  Informacao = 'Informacao',
  Solicitacao = 'Solicitacao',
  Manifestacao = 'Manifestacao'
}

export enum CategoriaTicket {
  Documentacao = 'Documentacao',
  AtividadesComplementares = 'AtividadesComplementares',
  DocumentacaoResponsavelFinanceiro = 'DocumentacaoResponsavelFinanceiro',
  PrazoNotaTCC = 'PrazoNotaTCC',
  Reingresso = 'Reingresso',
  ServicoFinalizacao = 'ServicoFinalizacao',
  CopiaEmentasDisciplina = 'CopiaEmentasDisciplina'
}

export enum StatusTicket {
  Aberto = 'Aberto',
  Pendente = 'Pendente',
  Finalizado = 'Finalizado'
}

export enum TipoTicketPorExtenso {
  Informacao = 'Informação',
  Solicitacao = 'Solicitação',
  Manifestacao = 'Manifestação'
}

export enum CategoriaTicketPorExtenso {
  Documentacao = 'Documentação',
  AtividadesComplementares = 'Atividades Complementares',
  DocumentacaoResponsavelFinanceiro = 'Documentação Responsável',
  PrazoNotaTCC = 'Prazo envio nota do TCC',
  Reingresso = 'Reingresso',
  ServicoFinalizacao = 'Serviço Finalização',
  CopiaEmentasDisciplina = 'Ementas de Disciplina'
}

export enum SetorTicketPorExtenso {
  Gerencial = 'Gerencial',
  Financeiro = 'Financeiro',
  Academico = 'Acadêmico',
  Comercial = 'Comercial'
}

export enum UsuarioPerfilPorExtenso {
  Aluno = 'Aluno',
  Academico = 'Acadêmico',
  Professor = 'Professor',
  Gerencial = 'Gerencial',
  Financeiro = 'Financeiro',
  Comercial = 'Comercial',
  Parceiro = 'Parceiro'
}
