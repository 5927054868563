import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform,
  SelectOpcao,
  DataInputUnform,
  formatarCpf
} from 'src/componentes'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { SelectOpcaoCursos } from 'src/dto'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import { RequisitarListaTurmasPorTexto } from 'src/servicos/api'
import {
  AlunoSituacao,
  SituacaoFinanceiraPorExtenso,
  TipoOrdenacao,
  SituacaoFinanceiraFiltro,
  FormaPagamentoCobranca,
  ModalidadeCursoPorExtenso,
  TipoMatriculaPorExtenso
} from 'src/tipos'

import { Form, Container, Campo, Dados, ContainerExportar } from './styles'
import { DadosPesquisa } from './tipos'

const SELECT_SITUACAO_FINANCEIRA = [
  {
    id:
      SituacaoFinanceiraFiltro[
        SituacaoFinanceiraFiltro.AguardandoCartaoPagamentoRecorrente
      ],
    texto:
      SituacaoFinanceiraFiltro[
        SituacaoFinanceiraFiltro.AguardandoCartaoPagamentoRecorrente
      ]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Cancelado],
    texto: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Cancelado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.ContratoRescindido],
    texto:
      SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.ContratoRescindido]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Finalizado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Finalizado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Inativo],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Inativo]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Matriculado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Matriculado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reprovado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Reprovado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reservado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Reservado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Transferido],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Transferido]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Recuperacao],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Recuperacao]
  }
]

export const PaginaMatriculadosTurma: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [cursoSelecionado, definirCursoSelecionado] = useState<string>(null)
  const [carregando, definirCarregando] = useState(false)
  const [
    selecionadosSituacaoFinanceira,
    definirSelecionadosSituacaoFinanceira
  ] = useState([])

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioMatriculadosTurma(
      {
        inicioDataMatricula: dadosPesquisa?.inicioDataMatricula,
        fimDataMatricula: dadosPesquisa?.fimDataMatricula,
        nomeAluno: dadosPesquisa?.nomeAluno,
        situacoesFinanceiras: dadosPesquisa?.situacoesFinanceiras,
        cursoId: cursoSelecionado,
        turmaId: dadosPesquisa?.turma,
        dataRescisao: dadosPesquisa?.dataRescisao
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioMatriculadosTurma({
        inicioDataMatricula: dadosPesquisa?.inicioDataMatricula,
        fimDataMatricula: dadosPesquisa?.fimDataMatricula,
        nomeAluno: dadosPesquisa?.nomeAluno,
        situacoesFinanceiras: dadosPesquisa?.situacoesFinanceiras,
        cursoId: cursoSelecionado,
        turmaId: dadosPesquisa?.turma,
        dataRescisao: dadosPesquisa?.dataRescisao
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const cursoAlterado = (opcao: SelectOpcao) => {
    definirCursoSelecionado(opcao?.id)
  }

  const obterCursos = async (nome: string): Promise<SelectOpcaoCursos[]> => {
    const result = await Api.RequisitarListaCursoOutros(nome)
    return result.map(x => ({ id: x.id, texto: x.nome }))
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const situacaoFinanceiraSelecionada = useMemo(() => {
    return selecionadosSituacaoFinanceira?.some(
      e => e?.id === 'ContratoRescindido'
    )
  }, [selecionadosSituacaoFinanceira])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Matriculados por Turma"
          atalhos={[
            {
              texto: 'Relatórios Gerais'
            },
            {
              texto: 'Matriculados por Turma'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: AlunoSituacao[AlunoSituacao.Todas]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container pb>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                buscarPorTexto={obterCursos}
                buscarPorId={obterCursos}
                valorAlterado={cursoAlterado}
              />
            </Campo>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                multiplo
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr style={{ marginBottom: '25px' }}>
              <SelectUnform
                id="slct_situacao_financeira"
                name="situacoesFinanceiras"
                label="Situação Financeira"
                valorAlteradoMulti={e =>
                  definirSelecionadosSituacaoFinanceira(e)
                }
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={SELECT_SITUACAO_FINANCEIRA}
              />
            </Campo>
            {situacaoFinanceiraSelecionada && (
              <Campo mr>
                <DataInputUnform name="dataRescisao" label="Data de Rescisão" />
              </Campo>
            )}
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="inicioDataMatricula"
                label="Início Período Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="fimDataMatricula"
                label="Fim Período Matrícula"
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nomeAluno',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 200 }}>Nome</div>
            },
            {
              Chave: 'email',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 200 }}>E-mail</div>
              )
            },
            {
              Chave: 'cpf',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatarCpf(linha.cpf)}</span>
              ),
              RenderizarCabecalho: () => <div style={{ width: 100 }}>CPF</div>
            },
            {
              Chave: 'celular',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 125 }}>Celular</div>
              )
            },
            {
              Chave: 'endereco',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 200 }}>Endereço</div>
              )
            },
            {
              Chave: 'modalidade',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{ModalidadeCursoPorExtenso[linha.modalidade]}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 130 }}>Modalidade</div>
              )
            },
            {
              Chave: 'curso',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 250 }}>Curso</div>
            },
            {
              Chave: 'turma',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 250 }}>Turma</div>
            },
            {
              Chave: 'situacaoMatricula',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {SituacaoFinanceiraPorExtenso[linha.situacaoMatricula]}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Situação Matrícula</div>
              )
            },
            {
              Chave: 'tipoMatricula',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{TipoMatriculaPorExtenso[linha.tipoMatricula]}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Tipo Matrícula</div>
              )
            },
            {
              Chave: 'dataMatricula',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataMatricula)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Matrícula</div>
              )
            },
            {
              Chave: 'dataInicio',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.dataLocaleString(linha.dataInicio)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Início</div>
              )
            },
            {
              Chave: 'formaPagamento',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{FormaPagamentoCobranca[linha.formaPagamento]}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 125 }}>Forma de Pagamento</div>
              )
            },
            {
              Chave: 'valorParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorParcela)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor da Parcela</div>
              )
            },
            {
              Chave: 'quantidadeParcelas',
              Alinhamento: 'right',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Quantidade Parcelas</div>
              )
            },
            {
              Chave: 'cupom',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 100 }}>Cupom</div>
            },
            {
              Chave: 'desconto',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Desconto</div>
              )
            },
            {
              Chave: 'valorParcelaComDesconto',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorParcelaComDesconto)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor Parcela C/ Desconto</div>
              )
            },
            {
              Chave: 'valorTotal',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorTotal)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor Total</div>
              )
            },
            {
              Chave: 'valorFinal',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorFinal)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor Final</div>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaMatriculadosTurma />
  </ErroLayoutContainer.Provider>
)
