import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { AlterarEmail } from 'src/paginas/alterar-email'
import { AlterarEmailToken } from 'src/paginas/alterar-email-token'
import ValidacaoCertificado from 'src/paginas/validacao-certificado'

import { RotaPrivada } from '../componentes/autenticacao'
import { Layout } from '../componentes/layout'
import { LayoutMatricula } from '../componentes/layout-matricula'
import { Entrar, PrimeiroAcesso, RecuperarSenha } from '../paginas'
import { AcademicoRotas } from './academico'
import { AlunoRotas } from './aluno'
import { MatriculaRotas } from './matricula'
import { ProfessorRotas } from './professor'
import { Redirecionador } from './redirecionador'
import { ReingressoRotas } from './reingresso'
import { ResponsavelFinanceiroRotas } from './responsavel-financeiro'
import { SalaAulaRotas } from './sala-aula'
import { SistemaRotas } from './sistema'

export enum RotasApp {
  Home = '/',
  Academico = '/academico',
  Aluno = '/aluno',
  Sistema = '/sistema',
  Matricula = '/matricula',
  SalaAula = '/sala-aula',
  Professor = '/professor',
  Entrar = '/entrar',
  RecuperarSenha = '/recuperar-senha',
  ValidacaoCertificado = '/validacao-certificado',
  PrimeiroAcesso = '/primeiro-acesso',
  ResponsavelFinanceiro = '/responsavel-financeiro',
  AlterarEmail = '/alterar-email',
  AlterarEmailToken = '/alterar-dados-usuario',
  Reingresso = '/reingresso'
}

export const Rotas: React.FC = () => {
  return (
    <Switch>
      <RotaPrivada exact path={RotasApp.Home}>
        <Redirecionador />
      </RotaPrivada>
      <RotaPrivada path={RotasApp.Academico}>
        <Layout>
          <AcademicoRotas />
        </Layout>
      </RotaPrivada>
      <RotaPrivada path={RotasApp.Reingresso}>
        <Layout>
          <ReingressoRotas />
        </Layout>
      </RotaPrivada>
      <RotaPrivada path={RotasApp.Sistema}>
        <Layout>
          <SistemaRotas />
        </Layout>
      </RotaPrivada>
      <RotaPrivada path={RotasApp.Aluno}>
        <Layout>
          <AlunoRotas />
        </Layout>
      </RotaPrivada>
      <RotaPrivada path={RotasApp.Professor}>
        <Layout>
          <ProfessorRotas />
        </Layout>
      </RotaPrivada>
      <Route path={RotasApp.Matricula}>
        <LayoutMatricula>
          <MatriculaRotas />
        </LayoutMatricula>
      </Route>
      <RotaPrivada path={RotasApp.SalaAula}>
        <Layout>
          <SalaAulaRotas />
        </Layout>
      </RotaPrivada>
      <Route path={`${RotasApp.ValidacaoCertificado}/:idMatricula`}>
        <LayoutMatricula>
          <ValidacaoCertificado />
        </LayoutMatricula>
      </Route>
      <Route path={RotasApp.Entrar}>
        <Entrar />
      </Route>
      <Route path={RotasApp.RecuperarSenha}>
        <RecuperarSenha />
      </Route>
      <Route path={RotasApp.PrimeiroAcesso}>
        <PrimeiroAcesso />
      </Route>
      <RotaPrivada path={RotasApp.ResponsavelFinanceiro}>
        <Layout>
          <ResponsavelFinanceiroRotas />
        </Layout>
      </RotaPrivada>
      <Route path={RotasApp.AlterarEmail}>
        <AlterarEmail />
      </Route>
      <Route path={RotasApp.AlterarEmailToken}>
        <AlterarEmailToken />
      </Route>
      <Route path="*">
        <Redirect to={RotasApp.Home} />
      </Route>
    </Switch>
  )
}

export * from './academico'
export * from './sistema'
export * from './matricula'
