import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { BarraSuperior } from '../barra-superior'
import { useResponsivoState } from '../contextos/responsivo'
import { Logo } from '../imagens'
import { ContainerBarra, Container, Conteudo, ComponenteLayout } from './styles'

export const LayoutAuth: React.FC = ({ children }) => {
  const { desktop } = useResponsivoState()

  return (
    <ComponenteLayout>
      <BarraSuperior>
        <ContainerBarra>
          {Logo(desktop ? 'Padrao' : 'Secundario')}
        </ContainerBarra>
      </BarraSuperior>
      <Container>
        <Scrollbars style={{ height: 'calc(100vh - 72px)' }}>
          <Conteudo>{children}</Conteudo>
        </Scrollbars>
      </Container>
    </ComponenteLayout>
  )
}
