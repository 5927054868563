import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { PaginaReingresso } from 'src/paginas'
import { ModoReingresso } from 'src/paginas/reingresso/tipos'
export const ReingressoRotas: React.FC = () => {
  return (
    <Switch>
      <Route path={'/reingresso/:id'}>
        <PaginaReingresso modo={ModoReingresso.Pos} />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}
