import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform,
  DataInputUnform
} from 'src/componentes'
import { formatarCpf } from 'src/componentes/funcoes/cpf'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import { RequisitarListaCursosLivrePorTextoFiltro } from 'src/servicos/api'
import {
  AlunoSituacao,
  ModalidadeLivre,
  SituacaoFinanceira,
  SituacaoFinanceiraPorExtenso,
  TipoOrdenacao
} from 'src/tipos'

import { Form, Container, Campo, Dados, ContainerExportar } from './styles'
import { DadosPesquisa } from './tipos'

export const PaginaMatriculadosCursosLivres: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioMatriculadosCursosLivres(
      {
        cursosId: dadosPesquisa?.curso,
        inicioQuitacao: dadosPesquisa?.inicioQuitacao,
        fimQuitacao: dadosPesquisa?.fimQuitacao,
        inicioMatricula: dadosPesquisa?.inicioMatricula,
        fimMatricula: dadosPesquisa?.fimMatricula,
        situacoesFinanceiras: dadosPesquisa?.situacoesFinanceiras,
        nomeAluno: dadosPesquisa?.nomeAluno
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioMatriculadosCursosLivres(
        {
          cursosId: dadosPesquisa?.curso,
          inicioQuitacao: dadosPesquisa?.inicioQuitacao,
          fimQuitacao: dadosPesquisa?.fimQuitacao,
          inicioMatricula: dadosPesquisa?.inicioMatricula,
          fimMatricula: dadosPesquisa?.fimMatricula,
          situacoesFinanceiras: dadosPesquisa?.situacoesFinanceiras,
          nomeAluno: dadosPesquisa?.nomeAluno
        }
      )

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Matriculados por Curso Livre"
          atalhos={[
            {
              texto: 'Relatórios Gerais'
            },
            {
              texto: 'Matriculados por Curso Livre'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: AlunoSituacao[AlunoSituacao.Todas]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                multiplo
                buscarPorTexto={RequisitarListaCursosLivrePorTextoFiltro}
                buscarPorId={RequisitarListaCursosLivrePorTextoFiltro}
              />
            </Campo>
            <Campo style={{ marginBottom: '25px' }}>
              <SelectUnform
                id="slct_situacao_financeira"
                name="situacoesFinanceiras"
                label="Situação Matricula"
                iconeFechar={true}
                margemInferior={false}
                opcoes={[
                  {
                    id: 'Todas',
                    texto: 'Todas'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Cancelado],
                    texto: 'Cancelado'
                  },
                  {
                    id:
                      SituacaoFinanceira[SituacaoFinanceira.ContratoRescindido],
                    texto: 'Contrato Rescindido'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Finalizado],
                    texto: 'Finalizado'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Inativo],
                    texto: 'Inativo'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Matriculado],
                    texto: 'Matriculado'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Reprovado],
                    texto: 'Reprovado'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Reservado],
                    texto: 'Reservado'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Transferido],
                    texto: 'Transferido'
                  },
                  {
                    id: SituacaoFinanceira[SituacaoFinanceira.Recuperacao],
                    texto: 'Recuperação'
                  },
                  {
                    id:
                      SituacaoFinanceira[
                        SituacaoFinanceira.AguardandoCartaoPagamentoRecorrente
                      ],
                    texto: 'Aguardando Pag. Recorrente'
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="inicioMatricula"
                label="Início Período Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="fimMatricula"
                label="Fim Período Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="inicioQuitacao"
                label="Início Período Quitação"
              />
            </Campo>
            <Campo>
              <DataInputUnform
                name="fimQuitacao"
                label="Fim Período Quitação"
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nomeAluno',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.nomeAluno}</span>
              ),
              RenderizarCabecalho: () => <div style={{ width: 200 }}>Nome</div>
            },
            {
              Chave: 'emailAluno',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 200 }}>E-mail</div>
              )
            },
            {
              Chave: 'cpf',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatarCpf(linha.cpf)}</span>
              ),
              RenderizarCabecalho: () => <div style={{ width: 100 }}>CPF</div>
            },
            {
              Chave: 'docIdentidade_OrgaoEmissor',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Doc. Orgão Emissor</div>
              )
            },
            {
              Chave: 'celular',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Celular</div>
              )
            },
            {
              Chave: 'cidade',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Cidade</div>
              )
            },
            {
              Chave: 'uf',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 35 }}>UF</div>
            },
            {
              Chave: 'curso',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 250 }}>Curso</div>
            },
            {
              Chave: 'modalidadeLivre',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{ModalidadeLivre[linha.modalidadeLivre]}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Modalidade Livre</div>
              )
            },
            {
              Chave: 'area',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 100 }}>Área</div>
            },
            {
              Chave: 'situacao',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>{SituacaoFinanceiraPorExtenso[linha.situacao]}</>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Situação</div>
              )
            },
            {
              Chave: 'dataMatricula',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataMatricula)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Matrícula</div>
              )
            },
            {
              Chave: 'dataInicioCurso',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataInicioCurso)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Inicio Curso</div>
              )
            },
            {
              Chave: 'dataInicio',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.dataLocaleString(linha.dataInicio)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Início</div>
              )
            },
            {
              Chave: 'dataLimiteAcesso',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataLimiteAcesso)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Limite Acesso</div>
              )
            },
            {
              Chave: 'dataPagamento',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataPagamento)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Pagamento</div>
              )
            },
            {
              Chave: 'valorRecebido',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorRecebido)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor Recebido</div>
              )
            },
            {
              Chave: 'cupom',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 150 }}>Cupom</div>
            },
            {
              Chave: 'descontoCupom',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Desconto Cupom</div>
              )
            },
            {
              Chave: 'progressoCurso',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Progresso Curso</div>
              )
            },
            {
              Chave: 'avaliacaoNome',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 130 }}>Avaliação Nome</div>
              )
            },
            {
              Chave: 'nota',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 60 }}>Nota</div>
            },
            {
              Chave: 'dataPrimeiraGeracaoCertificado',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(
                    linha.dataPrimeiraGeracaoCertificado
                  )}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data 1ª Geração Certificado</div>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaMatriculadosCursosLivres />
  </ErroLayoutContainer.Provider>
)
