import { Responsivo } from 'src/compartilhados'
import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

export const ContainerCursos = styled.div`
  margin-right: 69px;
  width: 70%;

  ${props => Responsivo.Mobile(props)`
    width: 100%;
    margin-right: 0;
  `}

  ${props => Responsivo.Tablet(props)`
    width: 100%;
    margin-right: 0;
  `}
`

export const LinkRodapeContainer = styled.div`
  margin-top: 20px;
`

export const ListaCursos = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);

  ${props => Responsivo.Mobile(props)`
    display: flex;
    flex-direction: column;
  `}

  ${props => Responsivo.Tablet(props)`
    display: flex;
    flex-direction: column;
  `}
`

export const SubTituloContainer = styled.div`
  display: flex;
  margin-bottom: 9px;

  ${props => Responsivo.Mobile(props)`margin-bottom: 19px;`}
  ${props => Responsivo.Tablet(props)`margin-bottom: 25px;`}
`

export const SubTituloIcone = styled.div`
  display: flex;
  margin-right: 4px;

  ${props => Responsivo.Tablet(props)`
    & > svg {
      width: 24px;
      height: 24px;
    }
  `}
`

export const SubTitulo = styled.h3`
  font-size: 18px;
  font-weight: 300;

  ${props => Responsivo.Mobile(props)`
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  `}

  ${props => Responsivo.Tablet(props)`
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
  `}
`

const TextoDecorativo = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: black;
  box-sizing: border-box;
  padding-top: 24px;
`

export const CursosNaoEncontrados = styled(TextoDecorativo)`
  color: ${Cores.SECUNDARIA_2_ESCURO};
`

export const RequisicaoComErros = styled(TextoDecorativo)`
  color: ${Cores.SECUNDARIA_3_CLARO};
`

export const CarregandoCursos = styled(TextoDecorativo)`
  color: ${Cores.SECUNDARIA_2_ESCURO};
`
