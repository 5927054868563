import React from 'react'

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 20"
    fill="none"
  >
    <path
      d="M9.17 2L14 6.83V18H2V2H9.17ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6L10 0ZM8 12C9.1 12 10 11.1 10 10C10 8.9 9.1 8 8 8C6.9 8 6 8.9 6 10C6 11.1 6.9 12 8 12ZM12 15.43C12 14.62 11.52 13.9 10.78 13.58C9.93 13.21 8.99 13 8 13C7.01 13 6.07 13.21 5.22 13.58C4.48 13.9 4 14.62 4 15.43V16H12V15.43Z"
      fill="#666666"
      fillOpacity="0.8"
    />
  </svg>
)
