import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  IconeGerarBoleto,
  IconePagamentoMatricula,
  IconeRecibo,
  ModalRef,
  AutenticacaoContainer,
  ModalVisualizacaoPdf,
  ModalVisualizacaoPdfRef
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { getApiError } from 'src/servicos/instancias-api'
import {
  BaixaManualTaxaMatricula,
  DadosFinanceirosCarrinhoCompra,
  MetodosPagamento,
  SituacaoDaCompraComCartaoDeCredito
} from 'src/tipos'

import { ModalPagamentoCartao } from '../componentes'
import {
  ContainerBotao,
  ContainerIcones,
  ConteudoBotao,
  CursoExpandido
} from '../styles'
import { FinanceiroCarrinhoCompraProps } from '../tipos'
import {
  ModalBaixaManualTaxaMatricula,
  ModalBaixaManualTaxaMatriculaRef
} from './componentes/modal-baixa-manual-taxa-matricula'
import { formatadores } from './formatadores'
import { FormularioDePagamentoCarrinhoCompraMapper } from './formulario-de-pagamento-mapper'
import { ContainerTitulo } from './styles'

const mensagemDeErroDoCartaoDeCredito =
  'Não foi possível efeturar o pagamento, tente novamente.'

const mensagemDeErroBoleto = 'Erro ao obter boleto'

const mensagemDeErroRecibo = 'Erro ao obter recibo'

const CarrinhoCompra: FC<FinanceiroCarrinhoCompraProps> = ({ match }) => {
  const [
    cenarioFinanceiro,
    definirCenarioFinanceiro
  ] = useState<DadosFinanceirosCarrinhoCompra>()
  const { perfil } = AutenticacaoContainer.useContainer()

  const [pronto, definirPronto] = useState(false)
  const [carregamento, definirCarregando] = useState<boolean>(false)
  const history = useHistory()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()
  const modalRef = useRef<ModalRef>(null)
  const modalSucesso = useRef<ModalRef>(null)

  const { carrinhoCompraId } = match.params
  const modalBaixaManualTaxaMatriculaRef = useRef<ModalBaixaManualTaxaMatriculaRef>(
    null
  )
  const modalVisualizacaoRef = useRef<ModalVisualizacaoPdfRef>(null)
  const ehPerfilFinanceiro = useMemo(
    () => [...perfil?.perfis].findIndex(p => p === 'Financeiro') > -1,
    [perfil?.perfis]
  )

  const obterCenarioFinanceiro = async (
    carrinhoCompraId: string
  ): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const resposta = await Api.ObterDadosFinanceirosCarrinhoCompraCursoLivre(
        carrinhoCompraId
      )
      definirCenarioFinanceiro(resposta)
    } catch (erro) {
      const mensagens = 'Ops! Houve algum problema no carregamento dos dados.'
      const acaoVoltar = () => history.push(RotasAluno.FinanceiroCursoLivre)
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const gerarBoleto = async (): Promise<void> => {
    try {
      definirPronto(false)
      const resposta = await Api.ObterBoletoParaPagamentoCarrinhoCompraCursoLivre(
        carrinhoCompraId
      )

      if (resposta) {
        window.open(resposta.linkBoleto)
      }
      definirPronto(true)
    } catch (erro) {
      definirPronto(true)
      toast(mensagemDeErroBoleto, { type: 'error' })
    }
  }

  const abrirModalBaixaManualTaxaMatricula = async () => {
    const valorTotal = cenarioFinanceiro.saldoDevedor
    modalBaixaManualTaxaMatriculaRef.current?.abrir(
      valorTotal,
      carrinhoCompraId
    )
  }

  const baixaManualTaxaCobranca = async (
    dados: BaixaManualTaxaMatricula
  ): Promise<void> => {
    try {
      const resposta = await Api.BaixaManualCarrinhoCompraCursoLivre(
        dados,
        cenarioFinanceiro.carrinhoCompraCursoLivreId
      )

      if (!resposta) {
        throw new Error(
          'Não foi possível realizar a baixa da taxa da matrícula'
        )
      }

      await obterCenarioFinanceiro(carrinhoCompraId)
      toast('Baixa realizada com sucesso.', {
        type: 'success'
      })
    } catch (erro) {
      const mensagem =
        getApiError(erro as Error) || 'Não foi possível realizar a baixa.'

      toast(mensagem, { type: 'error' })
    }
  }

  const pagamentoComCartaoDeCredito = async (dadosDoCartao: {
    [key: string]: string
  }) => {
    try {
      definirPronto(false)
      const pagamento = FormularioDePagamentoCarrinhoCompraMapper(
        carrinhoCompraId,
        dadosDoCartao
      )

      const {
        situacao,
        valorPago
      } = await Api.EfetuarPagamentoCarrinhoCompraComCartaoDeCredito(pagamento)

      const pagamentoAprovado =
        situacao === SituacaoDaCompraComCartaoDeCredito.Ativo && valorPago > 0

      pagamentoAprovado
        ? modalSucesso?.current?.abrir()
        : toast(mensagemDeErroDoCartaoDeCredito, { type: 'error' })

      definirPronto(true)
      obterCenarioFinanceiro(carrinhoCompraId)
    } catch (erro) {
      definirPronto(true)
      toast(mensagemDeErroDoCartaoDeCredito, { type: 'error' })
    }
  }

  const obterRecibo = async () => {
    try {
      definirCarregando(true)
      const arquivo = await Api.ObterArquivoReciboCarrinhoCompra(
        cenarioFinanceiro.carrinhoCompraCursoLivreId
      )
      if (arquivo) {
        modalVisualizacaoRef?.current?.abrir(arquivo)
      }
    } catch (erro) {
      toast(mensagemDeErroRecibo, { type: 'error' })
      const acaoVoltar = () => history.push(RotasAluno.FinanceiroCursoLivre)
      definirErro({ mensagens: mensagemDeErroRecibo, acaoVoltar })
    } finally {
      definirCarregando(false)
    }
  }

  const atalhos = [
    {
      texto: 'Financeiro',
      acao: () => history.goBack()
    },
    {
      texto: 'Painel Financeiro'
    }
  ]

  useEffect(() => {
    obterCenarioFinanceiro(carrinhoCompraId)
  }, [match])

  return pronto && !erro ? (
    <>
      {!carregamento ? (
        <>
          <ContainerTitulo>
            <Breadcrumb
              titulo={'Carrinho Compra'}
              atalhos={atalhos}
            ></Breadcrumb>
          </ContainerTitulo>
          <CursoExpandido>
            <div>
              <h4>Carrinho Compra</h4>
              <p>
                <b>Valor: </b>{' '}
                {formatadores.moeda(cenarioFinanceiro.valorTotal)}
              </p>
              <p>
                <b>Valor desconto: </b>{' '}
                {formatadores.moeda(cenarioFinanceiro.valorTotalDesconto)}
              </p>
              <p>
                <b> Saldo devedor: </b>
                {formatadores.moeda(cenarioFinanceiro.saldoDevedor)}
              </p>
              {cenarioFinanceiro.saldoDevedor === 0 ? (
                <p>
                  <b>Data de pagamento: </b>{' '}
                  {formatadores.data(cenarioFinanceiro.dataPagamento)}
                </p>
              ) : (
                <p>
                  <b>Data de vencimento: </b>{' '}
                  {formatadores.data(cenarioFinanceiro.dataVencimento)}
                </p>
              )}
              <p>
                <b>Forma de pagamento: </b>{' '}
                {formatadores.pagamento(cenarioFinanceiro.formaPagamento)}
              </p>
            </div>
            <ContainerIcones>
              {cenarioFinanceiro.saldoDevedor === 0 ? (
                <div>
                  {cenarioFinanceiro.formaPagamento !==
                    MetodosPagamento.Isencao && (
                    <Botao
                      type="button"
                      tema="Link"
                      elemento={
                        <>
                          {IconeRecibo}
                          <ConteudoBotao>Imprimir Recibo</ConteudoBotao>
                        </>
                      }
                      onClick={obterRecibo}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div>
                    {ehPerfilFinanceiro && (
                      <Botao
                        type="button"
                        tema="Link"
                        elemento={
                          <>
                            {IconeGerarBoleto}
                            <ConteudoBotao>
                              Baixa manual da cobrança
                            </ConteudoBotao>
                          </>
                        }
                        onClick={abrirModalBaixaManualTaxaMatricula}
                      />
                    )}
                  </div>
                  <div>
                    <Botao
                      type="button"
                      tema="Link"
                      elemento={
                        <>
                          {IconeGerarBoleto}
                          <ConteudoBotao>Gerar o Boleto</ConteudoBotao>
                        </>
                      }
                      onClick={gerarBoleto}
                    />
                  </div>
                  <div>
                    <Botao
                      type="button"
                      tema="Link"
                      elemento={
                        <>
                          {IconePagamentoMatricula}
                          <ConteudoBotao>
                            Pagar com cartão de crédito
                          </ConteudoBotao>
                        </>
                      }
                      onClick={() => {
                        modalRef?.current?.abrir()
                      }}
                    />
                  </div>
                </>
              )}
            </ContainerIcones>
          </CursoExpandido>
          {cenarioFinanceiro.itens?.map(i => {
            return (
              <CursoExpandido key={i.nomeCurso}>
                <div>
                  <h4>{i.nomeCurso}</h4>
                  <p>
                    <b>Valor: </b> {formatadores.moeda(i.valorTotalCurso)}
                  </p>
                  <p>
                    <b>Valor desconto: </b>{' '}
                    {formatadores.moeda(i.valorCupomDesconto)}
                  </p>
                  {cenarioFinanceiro.saldoDevedor > 0 ? (
                    <p>
                      <b>Data de vencimento: </b>{' '}
                      {formatadores.data(i.dataVencimento)}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </CursoExpandido>
            )
          })}
          <ContainerBotao>
            <Botao
              type="button"
              texto="Voltar"
              tema="Secundario"
              onClick={() => history.goBack()}
            />
          </ContainerBotao>
        </>
      ) : (
        <Carregando texto="Carregando dados financeiros..." />
      )}
      <ModalPagamentoCartao
        ref={modalRef}
        backdrop
        id="modal-pagamento"
        acaoPrimaria={pagamentoComCartaoDeCredito}
      />
      <ModalBaixaManualTaxaMatricula
        ref={modalBaixaManualTaxaMatriculaRef}
        backdrop
        id="modal-baixa-manual-taxa-matricula"
        acaoPrimaria={baixaManualTaxaCobranca}
        carrinhoCompra
      />
      <ModalVisualizacaoPdf
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento-aluno"
      />
    </>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados financeiros..." />
  ) : (
    <ErroContainerLayout />
  )
}

const CarrinhoCompraCursoLivre: FC<FinanceiroCarrinhoCompraProps> = (
  props: FinanceiroCarrinhoCompraProps
) => (
  <ErroLayoutContainer.Provider>
    <CarrinhoCompra {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(CarrinhoCompraCursoLivre)
