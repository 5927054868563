import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  DocumentosResponsavelFinanceiro,
  PaginaInicialResponsavelFinanceiro,
  PaginaEdicaoResponsavelFinanceiro
} from 'src/paginas/responsavel-financeiro'

const RotaBase = 'responsavel-financeiro'

export const RotasResponsavelFinanceiro = {
  Dashboard: `/${RotaBase}`,
  MeuCadastro: `/${RotaBase}/meu-cadastro`,
  Documentos: `/${RotaBase}/documentos`
}

export const ResponsavelFinanceiroRotas: React.FC = () => {
  return (
    <Switch>
      <Route exact path={RotasResponsavelFinanceiro.Dashboard}>
        <PaginaInicialResponsavelFinanceiro />
      </Route>
      <Route path={RotasResponsavelFinanceiro.MeuCadastro}>
        <PaginaEdicaoResponsavelFinanceiro />
      </Route>
      <Route path={RotasResponsavelFinanceiro.Documentos}>
        <DocumentosResponsavelFinanceiro />
      </Route>
    </Switch>
  )
}
