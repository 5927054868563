import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
  useMemo
} from 'react'
import { toast } from 'react-toastify'

import classNames from 'classnames'

import { AutenticacaoContainer } from '../autenticacao'
import { Botao } from '../botao'
import { Informacao } from '../informacao'
import { ModalBase } from '../modal-base'
import { ConteudoModalRequisitarSenha } from '../modal-requisitar-senha'
import { FormUnform } from '../unform/form'
import { InputUnform } from '../unform/input'
import { schema } from './schema'
import { ContainerBotoes, Corpo, Conteudo, Link } from './styles'
import { ModalLoginProps, ModalLoginRef, Dados } from './tipos'

const ModalLoginComponente: React.ForwardRefRenderFunction<
  ModalLoginRef,
  ModalLoginProps
> = ({ backdrop, acaoSucesso, acaoCadastrar }, ref) => {
  const primeiroRender = useRef(true)
  const [aberta, definirAberta] = useState(false)
  const [login, definirLogin] = useState(true)
  const [removerConteudoLogin, definirRemoverConteudoLogin] = useState(false)
  const [requisitarSenha, definirRequisitarSenha] = useState(false)
  const [removerConteudoSenha, definirRemoverConteudoSenha] = useState(true)
  const [emailCpf, definirEmailCpf] = useState<string>(null)
  const { autenticar } = AutenticacaoContainer.useContainer()

  const dadosIniciais = useMemo(() => {
    return { usuario: emailCpf }
  }, [emailCpf])

  const fechar = () => {
    definirAberta(false)
    definirLogin(true)
    definirRemoverConteudoLogin(false)
    definirRequisitarSenha(false)
    definirRemoverConteudoSenha(true)
    definirEmailCpf(null)
  }

  const abrir = (emailCpf?: string) => {
    definirAberta(true)
    definirEmailCpf(emailCpf)
  }

  useImperativeHandle<ModalLoginRef, ModalLoginRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async ({ usuario, senha }: Dados) => {
    const logado = await autenticar(usuario, senha, true)

    if (logado) {
      fechar()
      if (acaoSucesso) acaoSucesso()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      primeiroRender.current = !aberta
    }, 500)
  }, [aberta])

  useEffect(() => {
    if (!primeiroRender.current) {
      setTimeout(() => {
        if (login) {
          definirRemoverConteudoSenha(true)
        } else {
          definirRemoverConteudoSenha(false)
        }
      }, 200)
    }
  }, [login])

  useEffect(() => {
    if (!primeiroRender.current) {
      setTimeout(() => {
        if (requisitarSenha) {
          definirRemoverConteudoLogin(true)
        } else {
          definirRemoverConteudoLogin(false)
        }
      }, 200)
    }
  }, [requisitarSenha])

  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo>
          {aberta && (
            <>
              {!removerConteudoLogin && (
                <Conteudo
                  className={classNames({
                    animate__animated: true,
                    animate__fadeIn: primeiroRender.current,
                    animate__fadeInLeft: !primeiroRender.current && login,
                    animate__fadeOutLeft:
                      !primeiroRender.current && requisitarSenha
                  })}
                >
                  <h5>Realize seu Login</h5>
                  <FormUnform
                    schema={schema}
                    acaoSucesso={acaoSucessoForm}
                    dadosIniciais={dadosIniciais}
                  >
                    <InputUnform
                      id="ipt_usuario"
                      label="Informe seu CPF ou E-mail"
                      name="usuario"
                      maxWidthInput="100%"
                      obrigatorio
                    />
                    <InputUnform
                      type="password"
                      id="ipt_senha"
                      label="Informe sua Senha"
                      name="senha"
                      autoComplete="off"
                      maxWidthInput="100%"
                      obrigatorio
                      semValidacaoSenha
                    />

                    <Informacao>
                      Os dados de login podem ser diferentes dos que você
                      utiliza para acessar o site do iPGS. Se ainda não possui
                      cadastro no <strong>IPGS Education</strong>,{' '}
                      <a onClick={acaoCadastrar}>clique aqui</a> para se
                      cadastrar.
                    </Informacao>

                    <ContainerBotoes>
                      <Botao
                        type="button"
                        id="btn-cancelar"
                        texto="Cancelar"
                        tema="Secundario"
                        onClick={() => {
                          fechar()
                        }}
                      />
                      <Botao type="submit" id="btn-logar" texto="Logar" />
                    </ContainerBotoes>

                    <Link>
                      <Botao
                        type="button"
                        id="btn-esqueceu-senha"
                        texto="Esqueceu a senha?"
                        tema="Link"
                        onClick={() => {
                          if (!primeiroRender.current) {
                            definirLogin(false)
                            definirRequisitarSenha(true)
                          }
                        }}
                      />
                    </Link>
                  </FormUnform>
                </Conteudo>
              )}
              {!removerConteudoSenha && (
                <ConteudoModalRequisitarSenha
                  className={classNames({
                    animate__animated: true,
                    animate__fadeInRight: requisitarSenha,
                    animate__fadeOutRight: login,
                    semWidth: true
                  })}
                  acaoSucesso={() => {
                    toast('Sucesso! Confira seu e-mail.', { type: 'success' })
                  }}
                  fechar={() => {
                    if (!primeiroRender.current) {
                      definirLogin(true)
                      definirRequisitarSenha(false)
                    }
                  }}
                />
              )}
            </>
          )}
        </Corpo>
      </ModalBase>
    </>
  )
}

export const ModalLogin = forwardRef(ModalLoginComponente)
