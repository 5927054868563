import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'
const caracter = Yup.string().required(mensagemPadrao)
const codigoSegurancaMensagem = 'CVV inválido'

export const schemaPagamentoCartaoCredito = Yup.object().shape({
  numero: Yup.string().cartaoCredito().required(mensagemPadrao),
  nomeImpresso: caracter,
  validade: Yup.string().validadeCartaoCredito().required(mensagemPadrao),
  codigo: Yup.string()
    .nullable()
    .min(3, codigoSegurancaMensagem)
    .max(4, codigoSegurancaMensagem)
    .required(mensagemPadrao)
})
