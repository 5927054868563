import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { NotaAlteracaoTCC } from 'src/tipos'

import { AlunoAvaliacaoTCC } from '../../../tipos'

const resolverNota = (aluno: AlunoAvaliacaoTCC) => {
  if (aluno.dispensada) return NotaAlteracaoTCC.DISP
  if (aluno.aproveitada) return NotaAlteracaoTCC.APRO
  return aluno.avaliacao.nota
}

export const colunaNota = (): TabelaColuna => ({
  Chave: 'nota',
  Alinhamento: 'center',
  Renderizar: (linha: TabelaDados) => (
    <span>
      <>{resolverNota((linha as unknown) as AlunoAvaliacaoTCC)}</>
    </span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 50 }}>Nota</div>
})
