import { Cores } from 'src/componentes/styles/cores'
import styled, { css } from 'styled-components'

import { TipoDeAviso } from './tipo-de-aviso.enum'

const alturaDaBarraSuperior = '72px'
const espacamentoDoContainer = '24px'

const obterEstiloDoAviso = (tipo: TipoDeAviso) => {
  switch (tipo) {
    case TipoDeAviso.Advertencia:
      return css`
        border: 1px solid ${Cores.ATENCAO};
        background-color: ${Cores.ATENCAO_FUNDO};
      `
    case TipoDeAviso.Erro:
      return css`
        border: 1px solid ${Cores.PERIGO_ERRO};
        background-color: ${Cores.PERIGO_ERRO_FUNDO};
      `
    default:
      return css`
        border: 1px solid ${Cores.SUCESSO_ESCURO};
        background-color: ${Cores.SUCESSO_FUNDO};
      `
  }
}

const obterEstadoDoAviso = (ativo: boolean) =>
  ativo
    ? css`
        transform: scale(1);
        opacity: 1;
      `
    : css`
        transform: scale(0.9);
        opacity: 0;
      `

export const ContainerDoAviso = styled.div<{
  tipo: TipoDeAviso
  ativo: boolean
}>`
  display: flex;
  padding: 14px;
  border-radius: 4px;
  position: fixed;
  top: calc(${alturaDaBarraSuperior}+${espacamentoDoContainer});
  right: ${espacamentoDoContainer};
  transition: all 0.25s ease-in-out;
  ${({ ativo }) => obterEstadoDoAviso(ativo)}
  ${({ tipo }) => obterEstiloDoAviso(tipo)}
`

export const Icone = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 14px;
`

export const Mensagem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: black;
`
