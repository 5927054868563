import React from 'react'

import { StepperContainer, FuncoesDataHora } from 'src/componentes'
import { ModalidadeCurso } from 'src/tipos'

import { SobreCurso as SobreCursoComponente } from '../../shared'
import SalaAulaOutrosContainer from '../container'
import { ListaInfos, ItemInfo } from './styles'

export const SobreCurso: React.FC = () => {
  const { avancarStepper } = StepperContainer.useContainer()
  const { salaAula, downloadMaterial } = SalaAulaOutrosContainer.useContainer()

  return (
    <SobreCursoComponente
      informacoesGeraisCurso={salaAula.informacoesGeraisCurso}
      progresso={salaAula.progresso}
      materiaisTurma={salaAula.materiaisTurma}
      acaoDownloadMaterial={downloadMaterial}
      acaoAvancar={avancarStepper}
    >
      {salaAula.modalidadeCurso === ModalidadeCurso.PosGraduacaoPresencial ? (
        <ListaInfos>
          <ItemInfo>Carga horária do curso: {salaAula.cargaHoraria}h</ItemInfo>
          {salaAula.duracaoCurso > 0 ? (
            <ItemInfo>Duração do curso: {salaAula.duracaoCurso} meses</ItemInfo>
          ) : (
            <></>
          )}
          <ItemInfo>Turma: {salaAula.turmaNome}</ItemInfo>
          {salaAula.dataFimTurma ? (
            <ItemInfo>
              Data de início da turma:{' '}
              {FuncoesDataHora.novaData(salaAula.dataInicioTurma)}
            </ItemInfo>
          ) : (
            <></>
          )}
          {salaAula.dataFimTurma ? (
            <ItemInfo>
              Data de fim da turma:{' '}
              {FuncoesDataHora.novaData(salaAula.dataFimTurma)}
            </ItemInfo>
          ) : (
            <></>
          )}
          {salaAula.localTurma ? (
            <ItemInfo>Local: {salaAula.localTurma}</ItemInfo>
          ) : (
            <></>
          )}
        </ListaInfos>
      ) : undefined}
    </SobreCursoComponente>
  )
}
