import React, { useEffect, useRef } from 'react'

import { useField } from '@unform/core'
import { v4 } from 'uuid'

import { Radio } from '../../radio'
import { PerguntaProps } from '../tipos'
import { Container, Conteudo, Enunciado, Erro } from './styles'

export const PerguntaRespostaUnica: React.FC<PerguntaProps> = ({
  pergunta,
  name
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField, defaultValue, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find(ref => ref.checked)?.value ?? ''
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], value: string) => {
        refs.forEach(ref => {
          if (value === ref.value) {
            ref.checked = true
          }
        })
      }
    })
  }, [fieldName, registerField])

  return (
    <Container id={pergunta.id} theme={{ ComErro: !!error }}>
      <Enunciado>{pergunta.texto}</Enunciado>
      <Conteudo>
        {pergunta.alternativas?.map((item, index) => (
          <Radio
            ref={ref => {
              if (ref) {
                inputRefs.current[index] = ref as HTMLInputElement
              }
            }}
            key={pergunta.id}
            id={`${v4()}`}
            value={item.id}
            texto={item.texto}
            name={fieldName}
            tamanho="S"
            defaultChecked={defaultValue === item.id}
          />
        ))}
      </Conteudo>
      <Erro id={`erro-${pergunta.id}`}>{error}</Erro>
    </Container>
  )
}
