export enum StatusFinanceiro {
  Inadimplente = 'Inadimplente',
  EmDia = 'EmDia',
  Quitado = 'Quitado',
  Adimplente = 'Adimplente'
}

export enum StatusFinanceiroPorExtenso {
  Inadimplente = 'Inadimplente',
  EmDia = 'Em dia',
  Quitado = 'Quitado',
  Adimplente = 'Adimplente'
}
