import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  Botao,
  Carregando,
  FuncoesDataHora,
  IconeAtencao
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { TipoDeAvaliacao } from 'src/dto'
import { Api } from 'src/servicos'
import {
  SalaAulaOutrosArquivo,
  SalaAulaOutrosAvaliacao,
  SalaAulaOutrosEnvioAvaliacaoArquivo
} from 'src/tipos'

import Arquivo from '../../shared/avaliacao/arquivo'
import {
  ModalAvisoTCC,
  ModalAvisoTCCRef
} from '../../shared/avaliacao/componentes/modal-ultima-tentativa'
import Questao from '../../shared/avaliacao/questao'
import { Container } from '../../shared/styles'
import SalaAulaOutrosContainer from '../container'
import { DadosAvaliacao } from './tipo'

export const Avaliacao: React.FC<{
  idDisciplina?: string
  idModulo: string
  tcc?: boolean
  idAvaliacao: string
}> = ({ idDisciplina, idModulo, tcc, idAvaliacao }) => {
  const {
    salaAula,
    atualizarRecarregarSala,
    atualizarPassoAoAtualizar
  } = SalaAulaOutrosContainer.useContainer()
  const ModalAvisoTCCRef = useRef<ModalAvisoTCCRef>(null)

  const [dadosAvaliacao, definirDadosAvaliacao] = useState<DadosAvaliacao>(
    {} as DadosAvaliacao
  )
  const [avaliacao, definirAvaliacao] = useState({} as SalaAulaOutrosAvaliacao)
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const MEDIA_APROVACAO = 7

  const perguntas = useMemo(
    () =>
      avaliacao && avaliacao.perguntas
        ? avaliacao.perguntas.map(p => ({ ...p, respostas: p.alternativas }))
        : [],
    [avaliacao]
  )

  const ehAvaliacaoPorArquivo = useMemo(
    () => dadosAvaliacao.tipoAvaliacao === TipoDeAvaliacao.AvaliacaoPorAquivo,
    [dadosAvaliacao.tipoAvaliacao]
  )

  const arquivo = useMemo(() => {
    if (!avaliacao.arquivos || avaliacao.arquivos.length < 1) {
      return {} as SalaAulaOutrosArquivo
    }

    return avaliacao.arquivos[0]
  }, [avaliacao])

  const arquivoTCC = useMemo(() => {
    if (!tcc) return null

    if (
      !avaliacao.arquivos ||
      !avaliacao.arquivos[0]?.envios ||
      avaliacao.arquivos[0]?.envios?.length < 1
    ) {
      return {} as SalaAulaOutrosEnvioAvaliacaoArquivo
    }

    return avaliacao?.arquivos[0]?.envios[0]
  }, [tcc, avaliacao])

  const nomeArquivo = useMemo(() => {
    if (!arquivo) return null

    return arquivo.urlCaminhoArquivo
      ? arquivo.urlCaminhoArquivo.slice(
          arquivo.urlCaminhoArquivo.lastIndexOf('/') + 1
        )
      : null
  }, [arquivo])

  const ultimaTentativaAvaliacao = useMemo(() => {
    return avaliacao && avaliacao.ultimaTentativaAvaliacao
      ? avaliacao.ultimaTentativaAvaliacao.respostas
      : undefined
  }, [avaliacao])

  const nota = useMemo(() => {
    return avaliacao && avaliacao.ultimaTentativaAvaliacao
      ? avaliacao.ultimaTentativaAvaliacao.nota
      : undefined
  }, [avaliacao])

  const notaAproveitada = useMemo(() => {
    return avaliacao?.notaAproveitada ?? false
  }, [avaliacao])

  const notaAproveitamento = useMemo(() => {
    return notaAproveitada ? avaliacao.notaAproveitamento : undefined
  }, [avaliacao, notaAproveitada])

  const notaFinal = useMemo(() => {
    return notaAproveitada ? notaAproveitamento : nota
  }, [notaAproveitada, nota, notaAproveitamento])

  const notaTCC = useMemo(() => {
    return avaliacao && avaliacao.ultimaTentativa ? avaliacao.nota : undefined
  }, [avaliacao])

  const carregarAvaliacao = async (dados: DadosAvaliacao) => {
    try {
      definirPronto(false)
      if (!dados.tipoAvaliacao) return

      let resposta = {} as SalaAulaOutrosAvaliacao

      if (tcc) {
        resposta = await Api.SalaAulaOutros.RequisitarAvaliacaoTCCArquivoPorId(
          dados.id
        )
      } else {
        if (
          dados.tipoAvaliacao === TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha
        ) {
          resposta = await Api.SalaAulaOutros.RequisitarAvaliacaoMultiplaEscolhaPorId(
            dados.id
          )
        } else {
          resposta = await Api.SalaAulaOutros.RequisitarAvaliacaoArquivoPorId(
            dados.id
          )
        }
      }

      definirAvaliacao(resposta)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar a avaliação')
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucessoPergunta = async (
    payload: {
      idPergunta: string
      idResposta: string
    }[]
  ) => {
    const resposta = await Api.SalaAulaOutros.EnviarAvaliacaoPorPerguntas(
      avaliacao.id,
      salaAula.id,
      {
        perguntasComRespostas: payload
      }
    )

    definirAvaliacao(old => ({
      ...old,
      quantidadeTentativasAvaliacaoRealizada:
        old.quantidadeMaximaTentativasAvaliacao - resposta.tentativasRestantes,
      ultimaTentativaAvaliacao: {
        nota: resposta.nota,
        respostas: resposta.perguntasRespondidas,
        notaAproveitamento: false
      },
      dataUltimaTentativaAvaliacao: FuncoesDataHora.dataFormatadaAmericana(),
      ultimaTentativa: resposta.ultimaTentativa
    }))

    atualizarRecarregarSala(true)
    atualizarPassoAoAtualizar(idAvaliacao)
  }

  const acaoSucessoArquivo = async (arquivo: File) => {
    let resposta: { dataHoraEnvioAvaliacao: string } = null

    if (tcc) {
      resposta = await Api.SalaAulaOutros.UploadAvaliacaoTCCArquivo(
        arquivo,
        salaAula.id,
        avaliacao.id
      )
    } else {
      resposta = await Api.SalaAulaOutros.UploadAvaliacaoArquivo(
        arquivo,
        salaAula.id,
        avaliacao.id
      )
    }

    definirAvaliacao(old => ({
      ...old,
      arquivos: old.arquivos.map(a => ({
        ...a,
        feedBackProfessor: null,
        dataHoraEnvioAvaliacao: resposta.dataHoraEnvioAvaliacao
      }))
    }))
    atualizarRecarregarSala(true)
    atualizarPassoAoAtualizar(idAvaliacao)
  }

  const resolveDownloadArquivoAvaliacao = async (id: string) => {
    if (tcc) {
      return Api.SalaAulaOutros.DownloadAvalicaoTCCPorArquivo(id)
    } else {
      return Api.SalaAulaOutros.DownloadAvalicaoPorArquivo(id)
    }
  }

  const resolveDownloadArquivoEnviado = async (id: string) => {
    if (tcc) {
      return Api.SalaAulaOutros.DownloadAvaliacaoTCCArquivoEnviado(id)
    } else {
      return Api.SalaAulaOutros.DownloadAvaliacaoArquivoEnviado(id)
    }
  }

  useEffect(() => {
    const novaAvaliacao = {} as DadosAvaliacao
    if (tcc) {
      const { moduloTCC } = salaAula
      if (moduloTCC) {
        if (idDisciplina) {
          const disciplina = moduloTCC.disciplinas.find(
            d => d.id === idDisciplina
          )
          if (disciplina) {
            novaAvaliacao.id = disciplina.avaliacaoId
            novaAvaliacao.tipoAvaliacao = disciplina.tipoAvaliacao
          }
        }
      }
    } else {
      const modulo = salaAula.modulos.find(s => s.id === idModulo)
      if (modulo) {
        if (idDisciplina) {
          const disciplina = modulo.disciplinas.find(d => d.id === idDisciplina)

          if (disciplina) {
            novaAvaliacao.id = disciplina.avaliacaoId
            novaAvaliacao.tipoAvaliacao = disciplina.tipoAvaliacao
          }
        } else {
          novaAvaliacao.id = modulo.avaliacaoId
          novaAvaliacao.tipoAvaliacao = modulo.tipoAvaliacao
        }
      }
    }

    definirDadosAvaliacao(novaAvaliacao)

    if (idDisciplina || idModulo) {
      carregarAvaliacao(novaAvaliacao)
    }
  }, [salaAula.modulos, salaAula.moduloTCC, idModulo, idDisciplina])

  useEffect(() => {
    if (
      avaliacao?.quantidadeTentativasAvaliacaoRealizada >= 1 &&
      notaTCC < MEDIA_APROVACAO &&
      tcc &&
      !(avaliacao?.arquivos[0]?.envios.length > 1)
    ) {
      ModalAvisoTCCRef.current?.abrir(avaliacao?.dataLimiteAvaliacao)
    }
  }, [avaliacao, notaFinal, salaAula])

  return (
    <Container>
      {pronto && !erro ? (
        <>
          {!ehAvaliacaoPorArquivo ? (
            <Questao
              quantidadeMaximaTentativas={
                avaliacao.quantidadeMaximaTentativasAvaliacao
              }
              quantidadeTentativasRealizadas={
                avaliacao.quantidadeTentativasAvaliacaoRealizada
              }
              nomeAvaliacao={avaliacao.nomeAvaliacao}
              descricaoArquivo={avaliacao.descricaoAvaliacao}
              dataUltimaTentativa={avaliacao.dataUltimaTentativaAvaliacao}
              ultimaTentativaAvaliacao={ultimaTentativaAvaliacao}
              nota={notaFinal}
              aprovado={notaFinal ? notaFinal >= salaAula.media : undefined}
              media={salaAula.media}
              idSala={salaAula.id}
              perguntas={perguntas}
              acaoSucesso={acaoSucessoPergunta}
              notaAproveitamento={notaAproveitada}
              recuperacao={salaAula.periodoRecuperacao}
            />
          ) : (
            <Arquivo
              id={avaliacao.id}
              quantidadeMaximaTentativas={
                avaliacao.quantidadeMaximaTentativasAvaliacao
              }
              quantidadeTentativasRealizadas={
                avaliacao.quantidadeTentativasAvaliacaoRealizada
              }
              nomeAvaliacao={avaliacao.nomeAvaliacao}
              descricaoArquivo={avaliacao.descricaoAvaliacao}
              dataHoraEnvioAvaliacao={
                tcc
                  ? arquivoTCC.dataHoraEnvioAvaliacao
                  : arquivo.dataHoraEnvioAvaliacao
              }
              feedBackProfessor={arquivo.feedBackProfessor}
              nota={notaFinal}
              envios={arquivo.envios}
              nomeArquivo={nomeArquivo}
              media={salaAula.media}
              downloadArquivo={resolveDownloadArquivoAvaliacao}
              downloadArquivoAvaliacao={resolveDownloadArquivoEnviado}
              acaoSucesso={acaoSucessoArquivo}
              notaAproveitamento={notaAproveitada}
              tcc={tcc ?? false}
              notaTCC={avaliacao.nota}
              dataLimiteAvaliacao={avaliacao.dataLimiteAvaliacao}
            />
          )}
        </>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando avaliação ..." />
      ) : (
        <ContainerErroLayout>
          <div>
            {IconeAtencao}
            <p>{erro}</p>
            <div>
              <Botao
                tema="Padrao"
                texto="Tentar novamente"
                onClick={() => carregarAvaliacao(dadosAvaliacao)}
                tamanho="S"
              />
            </div>
          </div>
        </ContainerErroLayout>
      )}
      <ModalAvisoTCC ref={ModalAvisoTCCRef} backdrop />
    </Container>
  )
}
