import React from 'react'

import { Container } from './styles'

export interface CabecalhoProps {
  className?: string
}

export const Cabecalho: React.FC<CabecalhoProps> = ({
  children,
  className
}) => <Container className={className}>{children}</Container>
