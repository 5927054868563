import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .lg {
    flex: 3;
  }

  .md {
    flex: 2;
  }

  .sm {
    flex: 1;
  }
`

export const FormRow = styled(Row)`
  & > div:not(:first-child) {
    margin-left: 20px;
  }
`
