import React from 'react'

export default (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5.52996C-1.20706e-08 5.04534 0.447715 4.65247 1 4.65247H11C11.5523 4.65247 12 5.04534 12 5.52996C12 6.01459 11.5523 6.40746 11 6.40746H1C0.447715 6.40746 1.20706e-08 6.01459 0 5.52996Z"
      fill="#009B9B"
      fillOpacity="0.8"
    />
    <path
      d="M6 10.7949C5.44771 10.7949 5 10.402 5 9.91737L5 1.14245C5 0.65782 5.44771 0.264954 6 0.264954C6.55228 0.264954 7 0.657821 7 1.14245L7 9.91737C7 10.402 6.55228 10.7949 6 10.7949Z"
      fill="#009B9B"
      fillOpacity="0.8"
    />
  </svg>
)
