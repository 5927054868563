import React from 'react'

export default (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9.48528L5.24264 5.24264L1 1"
      stroke="#606060"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
