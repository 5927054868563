export enum TipoCobranca {
  TaxaMatricula = 'TaxaMatricula',
  Curso = 'Curso',
  DescontoPlanoPagamento = 'DescontoPlanoPagamento',
  TCC = 'TCC',
  DescontoTCC = 'DescontoTCC',
  TaxaTrocaDeCurso = 'TaxaTrocaDeCurso',
  ProrrogacaoMatricula = 'ProrrogacaoMatricula',
  Recuperacao = 'Recuperacao'
}
