import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import {
  Botao,
  FormUnform,
  QuestaoUnform,
  IconeCalendarioPequeno,
  IconeRelogioPequeno,
  FormRef,
  FuncoesDataHora,
  Cores,
  scrollParaTopo,
  focarPrimeiroCampoComErroLista
} from 'src/componentes'

import {
  ContainerTitulo,
  Titulo,
  Mensagem,
  Descricao,
  ContainerQuestao,
  ContainarStatus,
  ContainerBotao,
  Tentativas
} from '../../../shared/avaliacao/styles'
import { avaliacaoSchema } from './schema'
import { QuestaoProps } from './tipos'

const Questao: React.FC<QuestaoProps> = ({
  ultimaTentativaAvaliacao,
  quantidadeMaximaTentativas,
  quantidadeTentativasRealizadas,
  media,
  aprovado,
  nomeAvaliacao,
  descricaoArquivo,
  dataUltimaTentativa,
  nota,
  perguntas,
  botaoAvancar,
  acaoSucesso,
  tentativa,
  notaAproveitamento,
  recuperacao
}) => {
  const formRef = useRef<FormRef>()
  const [carregando, definirCarregando] = useState(false)
  const [tentarNovamente, definirTentarNovamente] = useState(tentativa ?? false)

  const dadosIniciais = useMemo(() => {
    if (tentarNovamente) return

    if (!ultimaTentativaAvaliacao || ultimaTentativaAvaliacao.length < 1) {
      return
    }

    const dados: string[] = []
    perguntas.forEach(pergunta => {
      const indice = ultimaTentativaAvaliacao.findIndex(
        tent => tent.idPergunta === pergunta.id
      )

      if (indice !== -1) {
        dados.push(ultimaTentativaAvaliacao[indice].idAlternativa)
      } else {
        dados.push(undefined)
      }
    })

    return { questoes: dados }
  }, [ultimaTentativaAvaliacao, tentarNovamente])

  useEffect(() => {
    formRef.current?.setData(dadosIniciais)
  }, [dadosIniciais])

  const tentativasRestantes = useMemo(() => {
    return quantidadeMaximaTentativas - quantidadeTentativasRealizadas
  }, [quantidadeMaximaTentativas, quantidadeTentativasRealizadas])

  const descricao = useMemo(() => {
    if (notaAproveitamento && nota !== undefined && nota !== null) {
      return 'Parabéns! Você atingiu a média necessária por meio do aproveitamento na transferência de turmas.'
    }

    if (!dataUltimaTentativa || tentarNovamente) {
      return 'Selecione a alternativa correta para cada uma das questões e, após, clique em “Enviar Avaliação”.'
    }

    if (nota !== undefined && nota !== null) {
      if (nota < media) {
        return `Infelizmente você não atingiu a média necessária${
          tentativasRestantes === 0
            ? 'e já encerrou suas tentativas. Mas fique tranquilo, que ao final do curso você poderá contratar recuperação de até 02 módulos.'
            : '. Mas não se preocupe, você pode tentar novamente. '
        }`
      }

      return 'Parabéns! Você atingiu a média necessária.'
    }

    return 'Aguarde o feedback do professor.'
  }, [nota, media, dataUltimaTentativa, tentativasRestantes, tentarNovamente])

  const corDescricao = useMemo(() => {
    if (descricao.includes('Infelizmente')) return Cores.PERIGO_ERRO
    if (descricao.includes('Parabéns')) return Cores.SECUNDARIA_3_ESCURO

    return Cores.CINZA_2_MAIS_ESCURO
  }, [descricao])

  const enviarAvaliacao = async (dados: { questoes: string[] }) => {
    try {
      definirCarregando(true)

      const payload = dados.questoes.map((questao, indice) => ({
        idPergunta: perguntas[indice].id,
        idResposta: questao || null
      }))

      await acaoSucesso(payload)

      definirTentarNovamente(false)
      scrollParaTopo()
    } finally {
      definirCarregando(false)
    }
  }

  const botaoTentarNovamente = useMemo(() => {
    return quantidadeTentativasRealizadas > 0 && !tentarNovamente
  }, [quantidadeTentativasRealizadas, tentarNovamente])

  const botaoTentarNovamenteRecuperacao = useMemo(() => {
    return (
      recuperacao &&
      quantidadeTentativasRealizadas <= 0 &&
      !!ultimaTentativaAvaliacao &&
      !tentarNovamente
    )
  }, [
    recuperacao,
    quantidadeTentativasRealizadas,
    tentarNovamente,
    ultimaTentativaAvaliacao
  ])

  const renderizarBotaoEnviar = useCallback(() => {
    if (!notaAproveitamento && !aprovado && tentativasRestantes > 0) {
      return (
        <>
          <ContainerBotao>
            <Botao
              carregando={carregando}
              texto={
                botaoTentarNovamente || botaoTentarNovamenteRecuperacao
                  ? 'Tentar Novamente'
                  : 'Enviar Avaliação'
              }
              onClick={async () => {
                if (
                  !botaoTentarNovamenteRecuperacao &&
                  (quantidadeTentativasRealizadas < 1 || tentarNovamente)
                ) {
                  formRef.current?.submitForm()
                  return
                }

                definirTentarNovamente(true)
                formRef.current?.reset()
              }}
            />
          </ContainerBotao>
          {quantidadeTentativasRealizadas > 0 && (
            <Tentativas>
              Quantidade de tentativas restantes: {tentativasRestantes}
            </Tentativas>
          )}
        </>
      )
    }

    if (aprovado && botaoAvancar) {
      return botaoAvancar
    }

    return <></>
  }, [
    tentativasRestantes,
    quantidadeTentativasRealizadas,
    carregando,
    tentarNovamente
  ])

  const respostaQuestao = (idPergunta: string) => {
    const novaResposta = ultimaTentativaAvaliacao.find(
      a => a.idPergunta === idPergunta
    )

    if (novaResposta) {
      return novaResposta.correta
    }
  }

  const questaoAnulada = (idPergunta: string) => {
    const novaResposta = ultimaTentativaAvaliacao?.find(
      a => a.idPergunta === idPergunta
    )

    return novaResposta?.anulada ?? false
  }

  const questaoObservacao = (idPergunta: string) => {
    const novaResposta = ultimaTentativaAvaliacao?.find(
      a => a.idPergunta === idPergunta
    )

    return botaoTentarNovamente && (novaResposta?.anulada ?? false)
      ? novaResposta?.observacao
      : null
  }

  const questaoExplicacao = (idPergunta: string) => {
    const novaExplicacao = ultimaTentativaAvaliacao?.find(
      a => a.idPergunta === idPergunta
    )

    return novaExplicacao?.explicacao
  }

  return (
    <>
      <ContainerTitulo>
        <Titulo>{nomeAvaliacao}</Titulo>
        {!!descricaoArquivo && (
          <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
            {descricaoArquivo}
          </Descricao>
        )}
        {nota !== undefined && nota !== null && !tentarNovamente && (
          <Mensagem abaixoMedia={nota < media}>
            Sua nota foi:{' '}
            {nota.toLocaleString('pt-BR', {
              minimumSignificantDigits: 2
            })}
            !
          </Mensagem>
        )}
        <Descricao color={corDescricao}>{descricao}</Descricao>
      </ContainerTitulo>
      {!notaAproveitamento && (
        <FormUnform
          ref={formRef}
          schema={avaliacaoSchema}
          acaoSucesso={enviarAvaliacao}
          acaoFalha={focarPrimeiroCampoComErroLista}
        >
          {perguntas.map((pergunta, indice) => (
            <ContainerQuestao key={indice} className={`questoes[${indice}]`}>
              <span>Questão {indice + 1}</span>
              <QuestaoUnform
                id={pergunta.id}
                name={`questoes[${indice}]`}
                enunciado={pergunta.texto}
                alternativas={pergunta.respostas}
                resposta={
                  !!ultimaTentativaAvaliacao && !tentarNovamente
                    ? respostaQuestao(pergunta.id)
                    : undefined
                }
                anulada={questaoAnulada(pergunta.id)}
                observacao={questaoObservacao(pergunta.id)}
                explicacao={questaoExplicacao(pergunta.id)}
              />
            </ContainerQuestao>
          ))}
        </FormUnform>
      )}

      {dataUltimaTentativa && !tentarNovamente && (
        <ContainarStatus>
          <span>Avaliação realizada em</span>
          {IconeCalendarioPequeno}
          <span>{FuncoesDataHora.novaData(dataUltimaTentativa)}</span>
          {IconeRelogioPequeno}
          <span>
            {FuncoesDataHora.horaFormatada24H(new Date(dataUltimaTentativa))}
          </span>
        </ContainarStatus>
      )}
      {renderizarBotaoEnviar()}
    </>
  )
}

export default Questao
