import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const Secao = styled.section`
  margin-top: 30px;

  .react-select__control {
    min-height: 48px;
    height: auto;
  }
`

export const ContainerMain = styled.div`
  width: 70%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const Conteudo = styled.div`
  margin-top: 18px;
  margin-left: 30px;
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDadosMedio = styled.div`
  width: 50%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosTelefone = styled.div`
  width: 25%;

  input {
    max-width: 100% !important;
  }
  div {
    max-width: 100% !important;
  }
`

export const ContainerDadosPequeno = styled.div`
  width: 25%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosBotao = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  padding-bottom: 4px;
  align-items: center;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  & > button:last-child {
    margin-left: 19px;
  }
`

export const ContainerLateral = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 58px;
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`
