import { Cores, Medias } from 'src/componentes/styles'
import styled from 'styled-components'

import { ContainerMedio } from '../../styles'

export const ContainerValores = styled.div`
  margin-bottom: 45px;

  @media ${Medias.MOBILE} {
    margin-bottom: 20px;
  }
`

export const ContainerOpcoes = styled.div`
  ${ContainerMedio} {
    margin-top: 10px;
  }
`

export const Erro = styled.p`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
  white-space: pre-line;

  margin-top: 5px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${Medias.MOBILE} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`
export const CupomContainer = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin: 0px 0px 25px 10px;
  }
`
