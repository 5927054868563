import React from 'react'

export default (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99958 1L4.88184 5.11775L8.99958 9.2355"
      stroke="#009B9B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.11775 1L1 5.11775L5.11775 9.2355"
      stroke="#009B9B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
