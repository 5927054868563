import { RouteProps } from 'react-router-dom'

import {
  Endereco,
  UsuarioPerfilPorExtenso,
  Usuario as UsuarioTipo,
  UsuarioPerfil
} from 'src/tipos'

export enum Visao {
  IPGS,
  Aluno,
  Professor,
  Parceiro,
  ResponsavelFinanceiro
}

export interface Usuario extends Omit<UsuarioTipo, 'perfis' | 'endereco'> {
  token: string
  endereco?: Omit<Endereco, 'pais'> & { paisResidencia: string }
  perfis?: UsuarioPerfilPorExtenso[]
}

export interface Perfil {
  [key: string]: unknown
  id: string
  email: string
  perfis: Array<keyof typeof UsuarioPerfil>
}

export interface GuardaProps {
  visaoSelecionada: Visao
  perfisComAcesso: Array<keyof typeof UsuarioPerfil>
}

export interface AuthContainer {
  solicitarAutenticacao: () => Promise<void>
  alterarVisao: (visao: Visao) => void
  autenticar: (
    usuario: string,
    senha: string,
    semRedirecionamento?: boolean
  ) => Promise<boolean>
  sair: (semRedirecionamento?: boolean) => Promise<void>
  atualizarUsuario: (
    dados: { token: string },
    naoRedirecionar?: boolean
  ) => Promise<void>
  validarPerfilUnico: (perfilVerificar: string) => boolean
  usuario: Usuario | null
  perfil: Perfil | null
  visao: Visao
  conferirUsuarioLogado: () => void
}

export type RotaPrivadaProps = RouteProps
