import Dompurify from 'isomorphic-dompurify'

export const sanitizeUtil = (html: string): string => {
  if (!html) {
    return ''
  }

  const result = Dompurify.sanitize(html, { FORCE_BODY: true })
  return result
}
