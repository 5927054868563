import {
  novaData,
  dataToLocaleString,
  dataHoraToLocaleString
} from 'src/componentes/funcoes/data-hora'
import { formatarMoeda } from 'src/componentes/funcoes/moeda'
import { ModalidadeCurso, ModalidadeCursoPorExtenso } from 'src/tipos'

import { MetodosPagamento } from '../tipos'

const valorPadrao = '-'

const data = (data: Date): string =>
  data ? novaData(data.toString()) : valorPadrao

const moeda = (valor: number): string => formatarMoeda(valor || 0)

const pagamento = (pagamento: MetodosPagamento): string =>
  pagamento ? MetodosPagamento[pagamento] : valorPadrao

const curso = (curso: ModalidadeCurso): string =>
  curso ? ModalidadeCursoPorExtenso[curso] : valorPadrao

const padrao = (valor: string): string => valor || valorPadrao

const dataLocaleString = (data: string): string =>
  data ? dataToLocaleString(data) : valorPadrao

const dataHoraLocaleString = (data: string): string =>
  data ? dataHoraToLocaleString(data) : valorPadrao

export const formatadores = {
  data,
  moeda,
  pagamento,
  curso,
  padrao,
  dataLocaleString,
  dataHoraLocaleString
}
