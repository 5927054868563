/* eslint-disable indent */
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Carregando,
  ModalIncluirMaterial,
  ModalExcluirMaterial,
  Tooltip,
  ModalVincular
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Breadcrumb } from 'src/componentes/breadcrumb'
import { Cabecalho } from 'src/componentes/cabecalho'
import { focarPrimeiroCampoComErro } from 'src/componentes/funcoes'
import { IconeAdicionar, IconeBack, IconeLixeira } from 'src/componentes/icones'
import { Link } from 'src/componentes/link'
import { Modal } from 'src/componentes/modal'
import { ModalExcluirMaterialRef } from 'src/componentes/modal-material/modal-excluir-material/tipos'
import { ModalMaterialRef } from 'src/componentes/modal-material/modal-incluir-material/tipos'
import { ModalRef } from 'src/componentes/modal/tipos'
import { Navegacao } from 'src/componentes/navegacao'
import { SubTitulo } from 'src/componentes/subtitulo'
import { TextAreaUnform } from 'src/componentes/unform'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { NumeroInputUnform } from 'src/componentes/unform/input/numero'
import { SelectUnform } from 'src/componentes/unform/select'
import { SelectOpcao } from 'src/componentes/unform/select/tipos'
import { SeletorUnform } from 'src/componentes/unform/seletor'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  EditarDisciplina,
  InserirDisciplina
} from 'src/servicos/api/disciplina'
import {
  ModoPaginaCadastro,
  Disciplina,
  DisciplinaSituacao,
  Material,
  ModalidadeCurso,
  TipoDisciplina,
  validaEhModalidadeLivreOuEvento
} from 'src/tipos'

import { DesvincularPreRequisito, ModalPreRequisitoRef } from './componentes'
import { ListaMaterial } from './componentes/lista-materiais'
import { schema } from './schema'
import {
  Container,
  ContainerAcoes,
  ContainerDados,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  ContainerLateral,
  ContainerLink,
  ContainerMain,
  ContainerPreRequisitos,
  ContainerPreRequisitosBotaoIcone,
  Conteudo,
  Secao,
  SemDadosPreReq,
  Botao as BotaoIcone
} from './styles'
import { MaterialDraggable, PaginaDisciplinaProps } from './tipos'

enum Navegacoes {
  DadosDisciplina = 'dados-disciplina',
  Informacoes = 'info',
  PreRequisitos = 'pre-reqs',
  Materiais = 'materiais'
}

export interface PreRequisito {
  id: string
  nome: string
  cargaHorario: number
  deletado: boolean
  preRequisitoDisciplinaId?: string
}

const PaginaDisciplinas: React.FC<PaginaDisciplinaProps> = ({
  match,
  modo
}) => {
  const modalPreReqRef = useRef<ModalRef>(null)
  const modalDesvincularPreReqref = useRef<ModalPreRequisitoRef>(null)
  const modalCancelamentoRef = useRef<ModalRef>(null)
  const modalIncluirMaterialRef = useRef<ModalMaterialRef>(null)
  const modalExcluirMaterialRef = useRef<ModalExcluirMaterialRef>(null)
  const [titulo] = useState(
    `${
      modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'
    } de Disciplina`
  )

  const DisciplinaModalidade = [
    {
      id: 'Outra',
      texto: 'Outra'
    },
    {
      id: 'Livre',
      texto: 'Livre'
    },
    {
      id: 'Evento',
      texto: 'Evento'
    }
  ]
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [disciplina, definirDisciplina] = useState<Disciplina | undefined>(
    undefined
  )
  const [contaDelete, definircontaDelete] = useState(0)
  const [preRequisitos, definirPreRequisitos] = useState<PreRequisito[]>([])
  const history = useHistory()
  const [modalidadeCurso, definirModalidade] = useState<string>(null)
  const [materiais, definirMateriais] = useState<Material[]>([])
  const [tipoDisciplina, definirTipoDisciplina] = useState<TipoDisciplina>(null)

  const ehCursoLivreOuEvento = useMemo(
    () => validaEhModalidadeLivreOuEvento(ModalidadeCurso[modalidadeCurso]),
    [modalidadeCurso]
  )
  const dadosIniciais = useMemo(() => {
    return disciplina
      ? {
          ...disciplina,
          modalidadeCurso: !disciplina.modalidadeCurso
            ? ModalidadeCurso.Outra
            : disciplina.modalidadeCurso
        }
      : {
          ...disciplina,
          situacao: 0,
          quantidadeCreditos: null,
          quantidadeEncontros: null
        }
  }, [disciplina])

  const carregarDisciplina = async () => {
    try {
      const id = match.params.id
      if (id) {
        const novaDisciplina = await Api.RequisitarDisciplina(id)
        definirDisciplina({
          ...novaDisciplina,
          modalidadeCurso: novaDisciplina.modalidadeCurso,
          situacao: novaDisciplina.situacao
        })

        definirTipoDisciplina(TipoDisciplina[novaDisciplina.tipo])

        novaDisciplina.materiais?.map(x => {
          const material = x as MaterialDraggable
          material.draggableId = x.titulo
          material.tipo = x.tipo
          return material
        })

        definirMateriais(novaDisciplina.materiais)

        novaDisciplina.preRequisitos?.forEach(async e => {
          const preReq = await Api.RequisitarDisciplina(e.id)
          definirPreRequisitos(prereq => [
            ...prereq,
            {
              id: preReq.id,
              nome: preReq.nome,
              cargaHorario: preReq.cargaHoraria,
              deletado: false
            }
          ])
        })
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados da Disciplina', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    carregarDisciplina()
  }, [])

  const acaoSucesso = async (dados: any) => {
    definirCarregando(true)
    try {
      const dadosValidos = {
        ...dados,
        materiais
      }

      let preReqValido = []
      if (dadosValidos.modalidadeCurso === ModalidadeCurso.Outra) {
        preReqValido = preRequisitos.filter(p => !p.deletado).map(p => p.id)
        dadosValidos.materiais = []
      }
      if (modo === ModoPaginaCadastro.Inclusao) {
        await InserirDisciplina(dadosValidos, preReqValido)
      } else {
        await EditarDisciplina(match.params.id, dadosValidos, preReqValido)
      }

      toast(
        `Disciplina ${
          modo === ModoPaginaCadastro.Inclusao ? 'cadastrada' : 'editada'
        } com sucesso`,
        {
          type: 'success'
        }
      )
      history.push(RotasAcademico.Disciplinas)
    } catch (error) {
      toast('Ops! Aconteceu um erro. Tente novamente.', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const modalidadeAlterada = (opcao: SelectOpcao) => {
    definirModalidade(opcao?.id)
  }

  const excluiPreRequisitosClick = (dado: PreRequisito[], ind: number) => {
    definirPreRequisitos(
      dado.map((p: PreRequisito, indice: number) => {
        if (ind === indice) {
          p.deletado = true
        }
        return p
      })
    )
    definircontaDelete(preRequisitos.filter(e => e.deletado === true).length)
  }

  const abrirModalExcluirPreRequisitos = (index: number) => {
    modalDesvincularPreReqref?.current?.abrir(index)
  }

  const adicionaPreRequisitosClick = (dados: Disciplina) => {
    if (dados.id === match.params.id) {
      toast(
        'Uma Disciplina não pode ser adicionada como seu próprio pré-requisito.',
        {
          type: 'info'
        }
      )
      return
    }

    if (!dados) {
      toast('Selecione a Disciplina de pré-requisito.', {
        type: 'info'
      })
      return
    }

    if (preRequisitos.find(p => p.id === dados.id && !p.deletado)) {
      toast('Esta Disciplina já foi adicionada como pré-requisito.', {
        type: 'info'
      })

      return
    }

    definirPreRequisitos(old => [
      ...old,
      {
        id: dados.id,
        nome: dados.nome,
        cargaHorario: dados.cargaHoraria,
        deletado: false
      }
    ])

    modalPreReqRef?.current?.fechar()
  }

  const adicionarMaterial = (data: MaterialDraggable, index?: number) => {
    data.draggableId = data.titulo
    if (index !== null && index !== undefined) {
      const items = [...materiais]
      items.splice(index, 1, data)
      definirMateriais(items)
    } else {
      definirMateriais([...materiais, data])
    }
  }

  const abrirModalAdicionarMaterial = () =>
    modalIncluirMaterialRef?.current?.abrir(materiais.map(x => x.titulo))

  const editarMaterial = (index: number) => {
    const item = materiais[index]
    modalIncluirMaterialRef?.current?.abrir(
      materiais.map(x => x.titulo).filter(x => x !== item.titulo),
      item,
      index
    )
  }

  const abrirModalRemoverMaterial = (index: number) => {
    modalExcluirMaterialRef?.current?.abrir(index)
  }

  const removerMaterial = (index: number) => {
    definirMateriais(materiais.filter((x, i) => i !== index))
  }

  const tipoDisciplinaAlterado = (opcao: SelectOpcao) => {
    definirTipoDisciplina(TipoDisciplina[opcao.id])
  }

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href="/academico/disciplinas"
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Acadêmico'
                  },
                  {
                    texto: 'Disciplinas',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <FormUnform
                schema={schema}
                dadosIniciais={{ ...dadosIniciais }}
                acaoSucesso={acaoSucesso}
                acaoFalha={focarPrimeiroCampoComErro}
              >
                <>
                  <Secao id={Navegacoes.DadosDisciplina}>
                    <SubTitulo texto="Dados da Disciplina" />
                    <InputUnform
                      name="nome"
                      label="Nome da Disciplina"
                      maxLength={200}
                      obrigatorio
                    />
                    <ContainerDados>
                      <ContainerDadosMedio>
                        <InputUnform
                          name="codigo"
                          label="Código da Disciplina"
                          maxLength={10}
                          disabled={modo !== ModoPaginaCadastro.Inclusao}
                          obrigatorio
                        />
                      </ContainerDadosMedio>
                      <ContainerDadosMedio>
                        <NumeroInputUnform
                          name="cargaHoraria"
                          label="Carga Horária (em horas)"
                          maxLength={9}
                          obrigatorio
                        />
                      </ContainerDadosMedio>
                    </ContainerDados>
                    <ContainerDados>
                      <ContainerDadosMedio>
                        <SelectUnform
                          id="slct_modalidade"
                          name="modalidadeCurso"
                          label="Modalidade do Curso"
                          placeholder="Selecione"
                          obrigatorio
                          disabled={modo === ModoPaginaCadastro.Edicao}
                          valorAlterado={modalidadeAlterada}
                          opcoes={DisciplinaModalidade}
                        />
                      </ContainerDadosMedio>
                      <ContainerDadosPequeno>
                        <SeletorUnform
                          name="situacao"
                          tipo="radio"
                          label="Situação"
                          seletores={[
                            {
                              id: DisciplinaSituacao[DisciplinaSituacao.Ativo],
                              texto: 'Ativo'
                            },
                            {
                              id:
                                DisciplinaSituacao[DisciplinaSituacao.Inativo],
                              texto: 'Inativo'
                            }
                          ]}
                        />
                      </ContainerDadosPequeno>
                      {modalidadeCurso === ModalidadeCurso.Outra && (
                        <>
                          <ContainerDadosPequeno>
                            <SelectUnform
                              id="slct_tipo"
                              name="tipo"
                              label="Tipo Disciplina"
                              placeholder="Selecione"
                              obrigatorio
                              disabled={
                                modalidadeCurso !== ModalidadeCurso.Outra ||
                                modo === ModoPaginaCadastro.Edicao
                              }
                              opcoes={[
                                {
                                  id: TipoDisciplina[TipoDisciplina.Curso],
                                  texto: TipoDisciplina.Curso
                                },
                                {
                                  id: TipoDisciplina[TipoDisciplina.TCC],
                                  texto: TipoDisciplina.TCC
                                }
                              ]}
                              valorAlterado={tipoDisciplinaAlterado}
                            ></SelectUnform>
                          </ContainerDadosPequeno>
                        </>
                      )}
                    </ContainerDados>
                    <ContainerDados>
                      <ContainerDadosPequeno>
                        <NumeroInputUnform
                          name="quantidadeCreditos"
                          label="Quantidade de Créditos"
                          maxLength={9}
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosPequeno>
                        <NumeroInputUnform
                          name="quantidadeEncontros"
                          label="Quantidade de Encontros"
                          maxLength={9}
                        />
                      </ContainerDadosPequeno>
                    </ContainerDados>
                    <Secao id={Navegacoes.Informacoes}>
                      <SubTitulo texto="Informações" />
                      <TextAreaUnform
                        label="Ementa"
                        name="ementa"
                        id="txt_ementa"
                        maxLength={10000}
                        rows={4}
                      />
                      <TextAreaUnform
                        label="Objetivos"
                        name="objetivos"
                        id="txt_objetivos"
                        maxLength={10000}
                        rows={4}
                      />
                      <TextAreaUnform
                        label="Conteúdo Programático"
                        name="conteudoProgramatico"
                        id="txt_conteudoprogramatico"
                        maxLength={10000}
                        rows={4}
                      />
                      <TextAreaUnform
                        label="Abordagem Metodológica"
                        name="abordagemMetodologica"
                        id="txt_abordagemmetodologica"
                        maxLength={10000}
                        rows={4}
                      />
                      <TextAreaUnform
                        label="Processo de Avaliação"
                        name="processoAvaliacao"
                        id="txt_processoavaliacao"
                        maxLength={10000}
                        rows={4}
                      />
                      <TextAreaUnform
                        label="Referência Bibliográfica"
                        name="referenciaBibliografica"
                        id="txt_referenciabibliografica"
                        maxLength={10000}
                        rows={4}
                      />
                    </Secao>
                    {modalidadeCurso === ModalidadeCurso.Outra &&
                    tipoDisciplina === TipoDisciplina.Curso ? (
                      <Secao id={Navegacoes.PreRequisitos}>
                        <ContainerPreRequisitos>
                          <h2>Pré-requisitos</h2>
                          <ContainerAcoes>
                            <BotaoIcone
                              id="btn_adicionar_prereq"
                              type="button"
                              onClick={() => {
                                modalPreReqRef?.current?.abrir()
                              }}
                            >
                              {IconeAdicionar} Vincular Pré-requisito
                            </BotaoIcone>
                          </ContainerAcoes>
                          <table>
                            <thead>
                              <th align="center">Disciplina</th>
                              <th align="center">Carga Horária</th>
                              <th align="center"></th>
                            </thead>
                            <tbody>
                              {contaDelete < preRequisitos.length ? (
                                preRequisitos.map(
                                  (preReq, i) =>
                                    !preReq.deletado && (
                                      <tr key={`prereq-${i}`}>
                                        <td align="center">{preReq.nome}</td>
                                        <td align="center">
                                          {preReq.cargaHorario
                                            .toString()
                                            .replace('.', ',')}
                                        </td>
                                        <td>
                                          <ContainerPreRequisitosBotaoIcone>
                                            <button
                                              type="button"
                                              data-tip="Desvincular"
                                              data-for={'tooltip-lixeira'}
                                              onClick={() => {
                                                abrirModalExcluirPreRequisitos(
                                                  i
                                                )
                                              }}
                                            >
                                              {IconeLixeira}
                                            </button>
                                            <Tooltip
                                              id={'tooltip-lixeira'}
                                              place="bottom"
                                              offset={{ top: 10 }}
                                            />
                                          </ContainerPreRequisitosBotaoIcone>
                                        </td>
                                      </tr>
                                    )
                                )
                              ) : (
                                <tr>
                                  <td colSpan={3}>
                                    <SemDadosPreReq>
                                      Nenhum pré-requisito vinculado.
                                    </SemDadosPreReq>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </ContainerPreRequisitos>
                      </Secao>
                    ) : (
                      <></>
                    )}
                    {ehCursoLivreOuEvento ? (
                      <Secao id={Navegacoes.Materiais}>
                        <ListaMaterial
                          adicionar={abrirModalAdicionarMaterial}
                          setMateriais={item => definirMateriais(item)}
                          materiais={materiais as MaterialDraggable[]}
                          editar={editarMaterial}
                          remover={abrirModalRemoverMaterial}
                        />
                      </Secao>
                    ) : (
                      <></>
                    )}
                  </Secao>
                  <ContainerAcoes>
                    <Botao
                      type="button"
                      texto="Cancelar"
                      tema="Secundario"
                      disabled={carregando}
                      onClick={() => modalCancelamentoRef?.current?.abrir()}
                    />
                    <Botao
                      type="submit"
                      texto="Salvar"
                      carregando={carregando}
                    />
                  </ContainerAcoes>
                </>
              </FormUnform>
            </Conteudo>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.DadosDisciplina,
                  nome: 'Dados da Disciplina'
                },
                { link: Navegacoes.Informacoes, nome: 'Informações' },
                modalidadeCurso === ModalidadeCurso.Outra &&
                  tipoDisciplina === TipoDisciplina.Curso && {
                    link: Navegacoes.PreRequisitos,
                    nome: 'Pré-requisitos'
                  },
                ehCursoLivreOuEvento && {
                  link: Navegacoes.Materiais,
                  nome: 'Materiais'
                }
              ]}
            />
          </ContainerLateral>
          <Modal
            ref={modalCancelamentoRef}
            backdrop
            id="modal-confirmacao-cancelamento"
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalCancelamentoRef?.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
          <ModalVincular
            ref={modalPreReqRef}
            acaoPrimaria={dados => {
              adicionaPreRequisitosClick(dados)
            }}
            backdrop
            modalidade={ModalidadeCurso.Outra}
            titulo="Vincular Pré-requisito"
            label="Selecione a Disciplina para Vincular como Pré-requisito"
            tipo={TipoDisciplina.Curso}
          />
          <DesvincularPreRequisito
            ref={modalDesvincularPreReqref}
            backdrop={true}
            acaoPrimaria={index =>
              excluiPreRequisitosClick(preRequisitos, index)
            }
          />
          <ModalIncluirMaterial
            ref={modalIncluirMaterialRef}
            backdrop
            acaoPrimaria={(data: MaterialDraggable, index?: number) =>
              adicionarMaterial(data, index)
            }
            exibirTipoMaterial={true}
          />
          <ModalExcluirMaterial
            backdrop
            ref={modalExcluirMaterialRef}
            acaoPrimaria={(index: number) => removerMaterial(index)}
          />
        </Container>
      ) : (
        <>
          <Carregando></Carregando>
        </>
      )}
    </>
  )
}

export default withRouter(PaginaDisciplinas)
