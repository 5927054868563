import styled from 'styled-components'

import { Cores, Medias } from '../styles'

export const Corpo = styled.div`
  max-width: 574px;
  min-width: unset !important;
  width: 90%;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;

  .semWidth {
    width: unset;
  }

  @media ${Medias.MOBILE} {
    padding: 20px 10px;
  }
`

export const Conteudo = styled.div`
  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 60px;
  }

  form {
    width: 100%;
  }

  @media ${Medias.MOBILE} {
    h5 {
      font-size: 2em;
      margin-bottom: 20px;
    }
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 23px;

  & > #btn-logar {
    margin-left: 19px;
    font-weight: 500;
    width: 180px;
  }

  @media ${Medias.MOBILE} {
    & > #btn-logar {
      width: 50%;
    }
  }
`

export const Link = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 27px;

  button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
  }
`
