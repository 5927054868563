import React from 'react'

export default (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.815905 10.9698C0.3653 10.5192 0.365301 9.78861 0.815906 9.338L9.33797 0.815943C9.78857 0.365338 10.5191 0.365338 10.9698 0.815943C11.4204 1.26655 11.4204 1.99712 10.9698 2.44773L2.44769 10.9698C1.99709 11.4204 1.26651 11.4204 0.815905 10.9698Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.03021 0.815906C1.48081 0.365301 2.21139 0.365301 2.66199 0.815906L11.1841 9.33797C11.6347 9.78857 11.6347 10.5191 11.1841 10.9698C10.7335 11.4204 10.0029 11.4204 9.55227 10.9698L1.03021 2.44769C0.579604 1.99709 0.579604 1.26651 1.03021 0.815906Z"
      fill="white"
    />
  </svg>
)
