import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  DataInputUnform,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectOpcao,
  SelectUnform
} from 'src/componentes'
import { Tabela, TabelaRef, TabelaResposta } from 'src/componentes/tabela'
import { Api } from 'src/servicos'
import {
  RequisitarListaCursosPorTextoFiltro,
  RequisitarListaTurmasPorTexto
} from 'src/servicos/api'
import {
  ModalidadeCurso,
  TipoOrdenacao,
  TipoPesquisaSatisfacao
} from 'src/tipos'

import { COLUNAS_PESQUISA_SATISFACAO } from './componentes'
import { Form, Container, Campo, ContainerExportar, Dados } from './styles'
import { DadosPesquisa } from './tipos'

export const OPCOES_MODALIDADE = [
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
    texto: 'Pós Graduação EAD'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
    texto: 'Pós Graduação EAD Flex'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
    texto: 'Pós Graduação EAD Full'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
    texto: 'Pós Graduação Presencial'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
    texto: 'Graduação'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Outra],
    texto: 'Outra'
  }
]

export const OPCOES_TIPO_PESQUISA = [
  {
    id: TipoPesquisaSatisfacao[TipoPesquisaSatisfacao.Curso],
    texto: 'Curso'
  },
  {
    id: TipoPesquisaSatisfacao[TipoPesquisaSatisfacao.Institucional],
    texto: 'Institucional'
  },
  {
    id: TipoPesquisaSatisfacao[TipoPesquisaSatisfacao.Professores],
    texto: 'Professores'
  }
]

export const PaginaRelatorioPesquisaSatisfacao: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)
  const [valorCurso, definirValorCurso] = useState([])
  const [valorModalidade, definirValorModalidade] = useState([])
  const [opcoesProfessores, definirOpcoesProfessores] = useState([])
  const [tipoPesquisaSelecionado, definirTipoPesquisaSelecionado] = useState(
    null
  )

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioPesquisaSatisfacao(
      {
        tipoPesquisa: dadosPesquisa?.tipoPesquisa,
        codigoPesquisa: dadosPesquisa?.codigoPesquisa,
        dataInicioResposta: dadosPesquisa?.dataInicio,
        dataFimResposta: dadosPesquisa?.dataFim,
        dataInicioPesquisaInstitucional: dadosPesquisa?.dataInicioInstitucional,
        dataFimPesquisaInstitucional: dadosPesquisa?.dataFimInstitucional,
        turma: dadosPesquisa?.turma,
        curso: dadosPesquisa?.curso,
        areaCurso: dadosPesquisa?.areaCurso,
        modalidade: dadosPesquisa?.modalidade,
        professorId: dadosPesquisa?.nomeProfessor
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioPesquisaSatisfacao({
        tipoPesquisa: dadosPesquisa?.tipoPesquisa,
        codigoPesquisa: dadosPesquisa?.codigoPesquisa,
        dataInicioResposta: dadosPesquisa?.dataInicio,
        dataFimResposta: dadosPesquisa?.dataFim,
        dataInicioPesquisaInstitucional: dadosPesquisa?.dataInicioInstitucional,
        dataFimPesquisaInstitucional: dadosPesquisa?.dataFimInstitucional,
        turma: dadosPesquisa?.turma,
        curso: dadosPesquisa?.curso,
        areaCurso: dadosPesquisa?.areaCurso,
        modalidade: dadosPesquisa?.modalidade,
        professorId: dadosPesquisa?.nomeProfessor
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const obterAreas = async (texto: string): Promise<Array<SelectOpcao>> => {
    try {
      const dados = await Api.RequisitarAreas(texto)

      return dados?.map(a => {
        return { id: a.id, texto: a.nome } as SelectOpcao
      })
    } catch {
      return []
    }
  }

  const obterAreaPorId = async (id: string): Promise<Array<SelectOpcao>> => {
    try {
      const dados = await Api.RequisitarAreaPorId(id)

      return [
        {
          id: dados.id,
          texto: dados.nome
        }
      ]
    } catch {
      return []
    }
  }

  const buscarProfessores = useCallback(async () => {
    try {
      const response = await Api.ObterProfessoresNome()
      const professoresSelect = response.map(professor => {
        return {
          id: professor.id,
          texto: professor.nome
        }
      })
      definirOpcoesProfessores(professoresSelect)
    } catch (error) {
      toast('Erro ao listar os professores do filtro!', { type: 'error' })
    }
  }, [Api.ObterProfessoresNome])

  useEffect(() => {
    buscarProfessores()
  }, [buscarProfessores])

  const modalidadeAoVivoSelecionada = () => {
    const possuiModalidadeAoVivo = valorModalidade.find(
      e => e?.id === 'PosGraduacaoEad'
    )

    return possuiModalidadeAoVivo
  }

  const cursoLivreSelecionado = () => {
    const possuiCursoLivre = valorCurso.find(
      e => e?.dadosAdicionais?.modalidade === 'Livre'
    )

    return possuiCursoLivre
  }

  const tipoInstitucionalSelecionado = useMemo(() => {
    return tipoPesquisaSelecionado === TipoPesquisaSatisfacao.Institucional
  }, [tipoPesquisaSelecionado])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Satisfação"
          atalhos={[
            {
              texto: 'Relatórios Acadêmicos'
            },
            {
              texto: 'Pesquisa de Satisfação'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo mr>
              <SelectUnform
                id="slct_tipoPesquisa"
                name="tipoPesquisa"
                label="Tipo de Pesquisa"
                iconeFechar
                opcoes={OPCOES_TIPO_PESQUISA}
                valorAlterado={valor => {
                  definirTipoPesquisaSelecionado(valor?.id)
                }}
              />
            </Campo>
            <Campo mr>
              <InputUnform
                type="text"
                name="codigoPesquisa"
                label="Código da Pesquisa"
                maxLength={100}
              />
            </Campo>
            <Campo style={{ marginBottom: '25px' }} mr>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                multiplo
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
              />
            </Campo>
          </Container>
          <Container>
            {tipoInstitucionalSelecionado && (
              <>
                <Campo mr>
                  <DataInputUnform
                    name="dataInicioInstitucional"
                    label="Início Período Institucional"
                  />
                </Campo>
                <Campo mr>
                  <DataInputUnform
                    name="dataFimInstitucional"
                    label="Fim Período Institucional"
                  />
                </Campo>
              </>
            )}
            <Campo mr>
              <DataInputUnform name="dataInicio" label="Data Início Resposta" />
            </Campo>
            <Campo mr>
              <DataInputUnform name="dataFim" label="Data Fim Resposta" />
            </Campo>
          </Container>
          <Container pb>
            <Campo style={{ marginBottom: '25px' }} mr>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                valorAlteradoMulti={e => {
                  definirValorCurso(e)
                }}
                multiplo
                buscarPorTexto={RequisitarListaCursosPorTextoFiltro}
                buscarPorId={RequisitarListaCursosPorTextoFiltro}
              />
            </Campo>
            {cursoLivreSelecionado() && (
              <Campo style={{ marginBottom: '25px' }} mr>
                <SelectAsyncUnform
                  id="slct_area_curso"
                  name="areaCurso"
                  label="Área do Curso"
                  buscarPorId={obterAreaPorId}
                  buscarPorTexto={obterAreas}
                  placeholder="Selecione"
                />
              </Campo>
            )}
            <Campo mr>
              <SelectUnform
                valorAlteradoMulti={e => {
                  definirValorModalidade(e)
                }}
                id="slct_modalidade"
                name="modalidade"
                label="Modalidade"
                iconeFechar
                multiplo
                opcoes={OPCOES_MODALIDADE}
              />
            </Campo>
            {modalidadeAoVivoSelecionada() && (
              <Campo mr>
                <SelectUnform
                  id="slct_nome_professor"
                  name="nomeProfessor"
                  label="Nome Professor"
                  iconeFechar
                  opcoes={opcoesProfessores}
                />
              </Campo>
            )}
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={COLUNAS_PESQUISA_SATISFACAO}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaRelatorioPesquisaSatisfacao />
  </ErroLayoutContainer.Provider>
)
