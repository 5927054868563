export enum ArquivosAceitos {
  XLS = '.xls',
  XLSX = '.xlsx',
  CSV = '.csv'
}

export const arquivosAceitos = Object.entries(ArquivosAceitos)
  .map(x => {
    const [, texto] = x
    return texto
  })
  .join(', ')
