import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useEffect
} from 'react'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  SelectUnform,
  SelectOpcao,
  InputUnform
} from 'src/componentes'
import { Api } from 'src/servicos'

import { schemaInserirNota } from './schema'
import { ContainerAcoes, ContainerModal, CorpoModal } from './style'
import { ModalInserirNotaRef, ModalInserirNotaProps, NovaNota } from './tipos'
const Modal: React.ForwardRefRenderFunction<
  ModalInserirNotaRef,
  ModalInserirNotaProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const [aberta, definirAberta] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)

  const [alunoId, definirAlunoId] = useState<string>(null)
  const [opcoesModulo, setOpcoesModulo] = useState<SelectOpcao[]>([])
  const [currentMatriculaId, setCurrentMatriculaId] = useState<string>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
    definirAlunoId(null)
    setCurrentMatriculaId(null)
  }

  const abrir = (alunoId: string) => {
    definirAlunoId(alunoId)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalInserirNotaRef, ModalInserirNotaRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: NovaNota) => {
    try {
      const dadosInserirNota = {
        ...dados,
        matriculaId: currentMatriculaId
      }

      await acaoPrimaria(dadosInserirNota)
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  const requisitarNotasAluno = async () => {
    const { moduloDto, matriculaId } = await Api.ObterMateriasENotas(alunoId)
    setOpcoesModulo(moduloDto)
    setCurrentMatriculaId(matriculaId)
  }

  useEffect(() => {
    if (alunoId) {
      requisitarNotasAluno()
    }
  }, [alunoId])

  return (
    <ModalBase id="modal-inserir-nota" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Adicionar Nota</h5>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaInserirNota}
              ref={formRef}
            >
              <SelectUnform
                id="slct_id_modulo"
                name="idModulo"
                label="Selecionar Modulos"
                opcoes={opcoesModulo}
                obrigatorio
              />
              <InputUnform
                id="slct_nota"
                name="nota"
                label="Inserir Nota"
                obrigatorio
              />
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Adicionar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}
export const ModalInserirNota = forwardRef(Modal)
