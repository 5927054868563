import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { Cores } from 'src/componentes'

import { Checkbox } from '../../checkbox'
import {
  IconeSetaDropDown,
  IconeDownload,
  IconeCarregando,
  IconeSeta
} from '../../icones'
import StepperContainer from '../container'
import { StepperItem } from '../tipos'
import { ContainerItem, BotaoDownload } from './styles'

interface SubItemProps {
  subItem: StepperItem
  aberto: boolean
  manipularVisibilidadeItens: (item: StepperItem) => void
}

export const SubItem: React.FC<SubItemProps> = ({
  subItem,
  aberto,
  manipularVisibilidadeItens
}) => {
  const {
    passo,
    itensMarcados,
    onClickConcluir,
    onClickDownload,
    onClickGetBlob,
    definirPassoStepper
  } = StepperContainer.useContainer()

  const history = useHistory()
  const { id } = useParams<{ id?: string }>()

  const [carregandoDownload, definirCarregandoDownload] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  return (
    <ContainerItem
      ativo={passo === subItem.passo}
      aberto={aberto}
      tipo={subItem.tipo}
      bloqueado={subItem.bloqueado}
      data-for={subItem.bloqueado ? 'tooltip-stepper' : undefined}
      data-tip={
        subItem.bloqueado && subItem.mensagemBloqueado
          ? subItem.mensagemBloqueado
          : undefined
      }
      onClick={async e => {
        e.stopPropagation()

        if (subItem.bloqueado) return

        if (subItem.tipo === 'url') {
          return window.open(subItem.url, '_blank')
        }

        if (
          subItem?.componente?.props?.tipo &&
          subItem?.componente?.props?.tipo === 'Anexo' &&
          onClickGetBlob
        ) {
          setLoadingFile(true)
          const result = await onClickGetBlob(subItem)
          setLoadingFile(false)

          const windowResult = window.open(result, '_blank')

          if (windowResult == null || typeof windowResult === 'undefined') {
            alert(
              'O bloqueador de pop-up está ativado, para que o site funcione perfeitamente, clique em sempre permitir ou desbloquear os pop-ups desse site.'
            )
          }
          return windowResult
        }

        if (subItem.tipo === 'trocaModuloFlex') {
          history.push(`/sala-aula/troca-modulo/${subItem?.idModulo}/${id}`)
        }

        if (subItem.tipo === 'download' && onClickDownload) {
          definirCarregandoDownload(true)
          const result = await onClickDownload(
            subItem.id,
            subItem.tcc,
            subItem.complementar
          )
          definirCarregandoDownload(false)
          return result
        }

        if (subItem.passo) {
          definirPassoStepper(subItem.passo)
        }
        if (subItem.subItens) {
          manipularVisibilidadeItens(subItem)
        }
      }}
      ehFilho
      temSubItens={!!subItem.subItens}
    >
      {subItem.icone && (
        <div className="stepper-item-icone">{subItem.icone}</div>
      )}
      <div className="stepper-item-titulo">
        <p>{subItem.titulo}</p>
      </div>
      {subItem.tipo === 'check' ? (
        loadingFile ? (
          IconeCarregando
        ) : (
          <div
            data-for={
              !itensMarcados.some(i => i === subItem.id) &&
              (subItem.mensagemCheck ? 'tooltip-stepper-check' : undefined)
            }
            data-tip={subItem.mensagemCheck}
          >
            <Checkbox
              className="checkbox-concluido"
              id={`checkbox-concluido-${subItem.id}`}
              tamanho="S"
              tema="Outro"
              disabled={subItem.bloqueado}
              onClick={async e => {
                e.stopPropagation()
                if (onClickConcluir) {
                  await Promise.all([
                    onClickConcluir(
                      subItem.id,
                      !itensMarcados.some(i => i === subItem.id),
                      itensMarcados,
                      subItem.tcc,
                      subItem.complementar
                    )
                  ])

                  ReactTooltip.hide()
                }
              }}
            >
              <input
                type="checkbox"
                name={`checkbox-concluido-${subItem.id}`}
                disabled={subItem.bloqueado}
                defaultChecked={itensMarcados.some(i => i === subItem.id)}
              />
            </Checkbox>
            <ReactTooltip
              id="tooltip-stepper-check"
              place="left"
              effect="solid"
              backgroundColor={Cores.CINZA_1_CLARO}
              textColor={Cores.PRETO}
            />
          </div>
        )
      ) : subItem.tipo === 'download' ? (
        <BotaoDownload>
          {carregandoDownload ? IconeCarregando : IconeDownload}
        </BotaoDownload>
      ) : subItem.tipo === 'url' ? (
        <>{IconeSeta}</>
      ) : (
        <></>
      )}
      {!!subItem.subItens && (
        <div className="stepper-item-icone">{IconeSetaDropDown}</div>
      )}
    </ContainerItem>
  )
}
