import React from 'react'

import { Botao } from '../botao'
import { IconeAtencao } from '../icones'
import ErroContainerLayoutContainer from './container'
import { Container } from './styles'

export const ErroContainerLayout: React.FC = () => {
  const { erro } = ErroContainerLayoutContainer.useContainer()

  return erro ? (
    <Container>
      <div>
        {IconeAtencao}
        {erro.mensagens.split('\n').map((mensagem, i) => (
          <p key={`${mensagem}-${i}`}>{mensagem}</p>
        ))}
        <div>
          {erro.acaoVoltar && (
            <Botao
              tema="Secundario"
              texto="Voltar"
              tamanho="S"
              onClick={erro.acaoVoltar}
            />
          )}
          {!!erro.textoConfirmar && !!erro.acaoConfirmar && (
            <Botao
              tema="Padrao"
              texto={erro.textoConfirmar}
              onClick={erro.acaoConfirmar}
              tamanho="S"
            />
          )}
        </div>
      </div>
    </Container>
  ) : (
    <></>
  )
}
