import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#34ACAC" />
    <path
      d="M15.0222 9.13333V7L20 11.9778L15.0222 16.9556V14.8222L17.8667 11.9778L15.0222 9.13333ZM11.8222 9.84444V7L16.8 11.9778L11.8222 16.9556V14.04C8.26667 14.04 5.77778 15.1778 4 17.6667C4.71111 14.1111 6.84444 10.5556 11.8222 9.84444Z"
      fill="#006969"
    />
  </svg>
)
