import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { SituacaoFinanceiraPorExtenso } from 'src/tipos'

export const colunaSituacaoMatricula = (): TabelaColuna => ({
  Chave: 'situacaoMatricula',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <>{SituacaoFinanceiraPorExtenso[linha.situacaoMatricula]}</>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 100 }}>Situação Matrícula</div>
  )
})
