import React from 'react'

export default (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M10.625 0H2.125C0.95625 0 0 0.9 0 2V18C0 19.1 0.95625 20 2.125 20H14.875C16.0438 20 17 19.1 17 18V6L10.625 0ZM2.125 18V2H9.5625V6H14.875V18H2.125ZM7.4375 17H9.5625V16H10.625C11.2094 16 11.6875 15.55 11.6875 15V12C11.6875 11.45 11.2094 11 10.625 11H7.4375V10H11.6875V8H9.5625V7H7.4375V8H6.375C5.79063 8 5.3125 8.45 5.3125 9V12C5.3125 12.55 5.79063 13 6.375 13H9.5625V14H5.3125V16H7.4375V17Z"
      fill="#009B9B"
      fillOpacity="0.8"
    />
  </svg>
)
