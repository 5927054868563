import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSpring } from 'react-spring'

import {
  AutenticacaoContainer,
  Botao,
  Carregando,
  FuncoesDataHora,
  IconeAdicionar,
  IconeAtencao,
  IconeCalendarioPequeno,
  IconeDesafixar,
  IconeGrupoDiscussao,
  IconeRelogioPequeno,
  IconeTopico,
  ModalRef,
  Visao
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Api } from 'src/servicos'
import { GrupoDiscussao as GrupoDiscussaoType } from 'src/tipos/grupo-discussao'

import SalaAulaCursoLivreContainerEstado from '../container'
import { ModalIncluirTopico } from './modal-incluir-topico'
import {
  Container,
  Conteudo,
  CabecalhoListagem,
  LinhaCabecalho,
  Titulo,
  CabecalhoTopico,
  ListaTopicos,
  ItemTopico,
  AnimatedDiv,
  ContainerTela,
  ContainerInformacoes,
  ContainerTitulo,
  ContainerDescricao,
  ContainerSubInformacoes,
  ContainerFixado
} from './styles'
import { Topico } from './topico'

export const GrupoDiscussaoComponente: React.FC = () => {
  const modalRef = useRef<ModalRef>(null)

  const [grupoDiscussao, definirGrupoDiscussao] = useState<GrupoDiscussaoType>(
    {} as GrupoDiscussaoType
  )
  const [topicoSelecionado, definirTopicoSelecionado] = useState<string>(null)
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const { visao } = AutenticacaoContainer.useContainer()
  const visaoAluno = useMemo(() => visao === Visao.Aluno, [visao])

  const { salaAula } = SalaAulaCursoLivreContainerEstado.useContainer()
  const { transform, opacity } = useSpring({
    opacity: topicoSelecionado ? 1 : 0,
    transform: `translate(${topicoSelecionado ? -180 : 0}px, 0px)`,
    config: { mass: 1, tension: 900, friction: 100 }
  })

  const carregarGrupoDiscussao = useCallback(async () => {
    try {
      definirPronto(false)
      definirErro(undefined)
      definirGrupoDiscussao({} as GrupoDiscussaoType)

      const resposta = await Api.RequisitarGrupoDiscussaoLivre(salaAula.cursoId)

      definirGrupoDiscussao(resposta)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar o Fórum de dúvidas')
    } finally {
      definirPronto(true)
    }
  }, [salaAula.cursoId])

  useEffect(() => {
    carregarGrupoDiscussao()
  }, [carregarGrupoDiscussao])

  return (
    <>
      {pronto && !erro ? (
        <Container>
          {!topicoSelecionado ? (
            <AnimatedDiv
              id="grupo-discussao-listagem"
              style={{
                opacity: opacity.interpolate(o => 1 - Number(o)),
                transform
              }}
            >
              <CabecalhoListagem>
                <LinhaCabecalho>
                  {IconeGrupoDiscussao}
                  <Titulo>
                    <h3>Fórum de dúvidas</h3>
                    <p>{grupoDiscussao.descricao}</p>
                  </Titulo>
                </LinhaCabecalho>
                <LinhaCabecalho>
                  <CabecalhoTopico>
                    <h3>Tópicos</h3>
                    {!visaoAluno && (
                      <Botao
                        tema="Secundario"
                        elemento={<>{IconeAdicionar} Incluir Tópico</>}
                        onClick={() => modalRef.current?.abrir()}
                      />
                    )}
                  </CabecalhoTopico>
                </LinhaCabecalho>
              </CabecalhoListagem>
              {grupoDiscussao.topicos.length > 0 && (
                <ListaTopicos>
                  {grupoDiscussao.topicos.map(topico => (
                    <ItemTopico
                      key={topico.topicoId}
                      onClick={() => {
                        definirTopicoSelecionado(topico.topicoId)
                      }}
                    >
                      <ContainerInformacoes>
                        <ContainerSubInformacoes>
                          <ContainerTitulo>
                            {IconeTopico}
                            <h4>{topico.titulo}</h4>
                          </ContainerTitulo>
                          <ContainerDescricao>
                            <span>Criado em </span>
                            {IconeCalendarioPequeno}
                            <span>
                              {FuncoesDataHora.novaData(topico?.dataInclusao)}
                            </span>
                            {IconeRelogioPequeno}
                            <span>
                              {FuncoesDataHora.horaFormatada24H(
                                new Date(topico?.dataInclusao)
                              )}
                            </span>
                          </ContainerDescricao>
                        </ContainerSubInformacoes>
                        {topico.fixado && (
                          <ContainerFixado>{IconeDesafixar}</ContainerFixado>
                        )}
                      </ContainerInformacoes>
                    </ItemTopico>
                  ))}
                </ListaTopicos>
              )}
            </AnimatedDiv>
          ) : (
            <AnimatedDiv
              id="grupo-discussao-topico"
              style={{
                opacity,
                transform: transform.interpolate(
                  t => `${t} translate(180px, 0px)`
                )
              }}
            >
              <Topico
                idTopico={topicoSelecionado}
                idGrupoDiscussao={grupoDiscussao?.grupoDiscussaoId}
                onClick={() => definirTopicoSelecionado(null)}
              />
            </AnimatedDiv>
          )}
        </Container>
      ) : !pronto && !erro ? (
        <Conteudo>
          <Carregando texto="Carregando Fórum de dúvidas ..." />
        </Conteudo>
      ) : (
        <Conteudo>
          <ContainerErroLayout>
            <div>
              {IconeAtencao}
              <p>{erro}</p>
              <div>
                <Botao
                  tema="Padrao"
                  texto="Tentar novamente"
                  onClick={carregarGrupoDiscussao}
                  tamanho="S"
                />
              </div>
            </div>
          </ContainerErroLayout>
        </Conteudo>
      )}
      <ModalIncluirTopico
        backdrop
        ref={modalRef}
        acaoSucesso={() => carregarGrupoDiscussao()}
      />
    </>
  )
}

export const GrupoDiscussao: React.FC = () => {
  return (
    <ContainerTela>
      <GrupoDiscussaoComponente />
    </ContainerTela>
  )
}
