/* eslint-disable indent */
import React, { useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Breadcrumb,
  Cores,
  ErroLayoutContainer,
  IconeAdd,
  IconeBack,
  IconeRevelarSenha,
  Link,
  Modal,
  ModalRef,
  Spacer,
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  Tooltip
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Api } from 'src/servicos'
import { Documento, TipoOrdenacao } from 'src/tipos'

import { COLUNAS_EMENTA_DISCIPLINA } from './colunas'
import {
  ModalAdicionarUpload,
  ModalVisualizacaoContratoRef
} from './modal-adicionar-upload'
import {
  BotaoDeSalvar,
  ContainerAcoes,
  ContainerBreadcrumbs,
  ContainerDaPagina,
  ContainerDosBotoes,
  ContainerLink,
  IconeAcao
} from './style'

export const PaginaCadastroEmentasIPGS: React.FC = () => {
  const history = useHistory()
  const tabelaRefPdf = useRef<TabelaRef>()
  const [carregando, definirCarregando] = useState<boolean>(false)
  const [carregandoPDF, definirCarregandoPDF] = useState<boolean>(false)
  const modalAnexarEmentaRef = useRef<ModalVisualizacaoContratoRef>(null)
  const modalCancelamentoRef = useRef<ModalRef>(null)
  const [quantidadeItens, definirQuantidadeItens] = useState(0)

  const [
    ementaDisciplinasSolicitadasId,
    definirEmentaDisciplinasSolicitadasId
  ] = useState('')
  const [listaUploadArquivos, definirListaUploadArquivos] = useState([])
  const [listaArquivos, definirListaArquivos] = useState({})
  const [
    bloqueioPorFinalizado,
    definirBloqueioPorFinalizado
  ] = useState<boolean>(false)

  const { id } = useParams<{ id?: string }>()

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    const dados = await Api.ObterInformacaoTicketEmenta(id)

    definirQuantidadeItens(dados?.itens?.length)

    const teste = dados?.itens?.reduce((prev, curr) => {
      if (curr?.arquivoEmenta?.length > 0) {
        definirBloqueioPorFinalizado(true)
        return {
          ...prev,
          [curr?.copiaEmentaDisciplinasSolicitadasId]: curr?.arquivoEmenta
        }
      }
      return prev
    }, {})

    definirListaArquivos(teste)

    if (!dados) return null
    return {
      Dados: dados?.itens
    } as TabelaResposta
  }

  const anexandoNoArray = async (dado: any) => {
    const formdata = new FormData()
    formdata.append(ementaDisciplinasSolicitadasId, dado)
    await Api.InserirDocumentoEmenta(
      id,
      ementaDisciplinasSolicitadasId,
      formdata
    )
    toast('Ementa anexada com sucesso!', { type: 'success' })

    definirListaArquivos(() => ({
      ...listaArquivos,
      [ementaDisciplinasSolicitadasId]: dado?.name
    }))

    const verificacao = listaUploadArquivos.includes(
      ementaDisciplinasSolicitadasId
    )

    if (!verificacao) {
      definirListaUploadArquivos([
        ...listaUploadArquivos,
        ementaDisciplinasSolicitadasId
      ])
    }
    modalAnexarEmentaRef?.current?.fechar()
  }

  const eventoDeUpload = async () => {
    try {
      definirCarregando(true)
      await Api.RegistrarDocumentoEmenta(id, listaUploadArquivos)
      toast('Ementas anexas com sucesso!', { type: 'success' })
      history.goBack()
    } catch (error) {
    } finally {
      definirCarregando(false)
    }
  }

  const abrirImagemNovaAba = (
    documentoAluno: Documento,
    nomeDisciplina: string
  ) => {
    const novaAba = window.open('')
    if (documentoAluno.contentType === 'application/pdf') {
      novaAba.document.write(
        `<iframe width='100%' height='100%' src='data:${documentoAluno?.contentType};base64, ` +
          encodeURI(documentoAluno?.base64) +
          "' />"
      )
    } else {
      const img = `<img src='${`data:${documentoAluno?.contentType};base64, ${documentoAluno?.base64}`}' />`
      novaAba.document.write(img)
    }
    novaAba.document.title = `Disciplina: ${nomeDisciplina}`
  }

  const visualizarDocumento = async (
    documentoId: string,
    nomeDisciplina: string
  ) => {
    try {
      const novoDocumento = await Api.RequisitarDocumentoEmenta(id, documentoId)

      if (novoDocumento) {
        abrirImagemNovaAba(novoDocumento, nomeDisciplina)
      }
    } catch (error) {}
  }

  const visualizacao = async (documentoId: string, nomeDisciplina: string) => {
    try {
      definirCarregandoPDF(true)
      await visualizarDocumento(documentoId, nomeDisciplina)
    } finally {
      definirCarregandoPDF(false)
    }
  }

  return (
    <ContainerDaPagina>
      <ContainerLink>
        <Link
          texto="Voltar"
          acaoVoltar={() => history.goBack()}
          icone={IconeBack}
        />
      </ContainerLink>
      <ContainerBreadcrumbs>
        <Breadcrumb
          titulo={'Ementa de Disciplinas'}
          atalhos={[
            {
              texto: 'Filas de trabalho'
            },
            {
              texto: 'Ementa de Disciplina'
            }
          ]}
        />
      </ContainerBreadcrumbs>
      <Tabela
        ref={tabelaRefPdf}
        Colunas={[
          {
            Chave: 'nomeCurso',
            Renderizar: (linha: TabelaDados) => <span>{linha.nomeCurso}</span>,
            RenderizarCabecalho: () => <div style={{ width: 150 }}>Curso</div>
          },
          ...COLUNAS_EMENTA_DISCIPLINA,
          {
            Chave: 'anexo',
            Renderizar: (linha: TabelaDados) => (
              <span>
                {listaArquivos[linha?.copiaEmentaDisciplinasSolicitadasId]}
              </span>
            ),
            RenderizarCabecalho: () => <div style={{ width: 100 }}>Anexo</div>
          },
          {
            Chave: 'acoes',
            RenderizarCabecalho: () => <div style={{ width: 100 }}>Ações</div>,

            Renderizar: (linha: TabelaDados) => {
              return (
                <ContainerAcoes>
                  <IconeAcao
                    data-tip={
                      linha?.arquivoEmenta?.length > 0
                        ? 'Não é possível anexar o arquivo'
                        : 'Inserir Download'
                    }
                    data-for={'icone-inserir-download'}
                    onClick={() => {
                      const validacao = linha?.arquivoEmenta?.length > 0
                      if (!validacao) {
                        definirEmentaDisciplinasSolicitadasId(
                          linha?.copiaEmentaDisciplinasSolicitadasId
                        )
                        modalAnexarEmentaRef?.current?.abrir(
                          listaArquivos[
                            linha?.copiaEmentaDisciplinasSolicitadasId
                          ]
                        )
                      }
                    }}
                    disabled={linha?.arquivoEmenta?.length > 0}
                  >
                    {IconeAdd}
                    <Tooltip
                      id={'icone-inserir-download'}
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                      place="bottom"
                    />
                  </IconeAcao>
                  <IconeAcao
                    onClick={() => {
                      if (linha?.arquivoEmenta !== null) {
                        visualizacao(
                          linha?.copiaEmentaDisciplinasSolicitadasId,
                          linha?.nomeDisciplina
                        )
                      }
                    }}
                    disabled={linha?.arquivoEmenta === null || carregandoPDF}
                    data-tip={
                      linha?.arquivoEmenta === null
                        ? 'Não há documento para ser visualizado.'
                        : 'Visualizar Documento'
                    }
                    data-for={'icone-visualizar-documento'}
                  >
                    {IconeRevelarSenha}
                    <Tooltip
                      id={'icone-visualizar-documento'}
                      textColor={Cores.PRETO}
                      backgroundColor={Cores.CINZA_1_CLARO}
                      place="bottom"
                    />
                  </IconeAcao>
                </ContainerAcoes>
              )
            }
          }
        ]}
        ObterDados={obterDados}
      />
      <Spacer padding="20px 0px" />

      <ContainerDosBotoes>
        <Botao
          texto="Cancelar"
          tema="Secundario"
          type="button"
          onClick={() => modalCancelamentoRef?.current?.abrir()}
        />
        <div
          data-tip={
            bloqueioPorFinalizado
              ? 'Já foi feito o anexo de todas ementas.'
              : quantidadeItens !== listaUploadArquivos?.length
              ? 'É necessário anexar ementa em todas disciplinas.'
              : 'Bloqueado'
          }
          data-for={'botao-salvar'}
        >
          <BotaoDeSalvar
            texto="Salvar"
            type="button"
            onClick={() => eventoDeUpload()}
            disabled={
              bloqueioPorFinalizado ||
              quantidadeItens !== listaUploadArquivos?.length
            }
            carregando={carregando}
          />
          {(bloqueioPorFinalizado ||
            quantidadeItens !== listaUploadArquivos?.length) && (
            <Tooltip
              id={'botao-salvar'}
              place={'top'}
              textColor={Cores.PRETO}
              backgroundColor={Cores.CINZA_1_CLARO}
              html={true}
            />
          )}
        </div>
      </ContainerDosBotoes>
      <Modal
        ref={modalCancelamentoRef}
        backdrop
        id="modal-confirmacao-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.goBack()
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalCancelamentoRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
      <ModalAdicionarUpload
        ref={modalAnexarEmentaRef}
        backdrop
        id="modal-anexar-ementa"
        acaoPrimaria={anexandoNoArray}
      />
    </ContainerDaPagina>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaCadastroEmentasIPGS />
  </ErroLayoutContainer.Provider>
)
