import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaCargaHoraria = (): TabelaColuna => ({
  Chave: 'cargaHoraria',
  Texto: 'Carga Horária',
  StyleColuna: { width: 100, paddingInline: 10 },
  Renderizar: (linha: TabelaDados) => <>{linha.cargaHoraria}</>
})
