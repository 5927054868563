import React from 'react'

export default (
  <svg
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.269 31.0644H4.03029C2.35675 31.0644 1 29.7168 1 28.0546V4.27757C1 2.61537 2.35675 1.26782 4.03029 1.26782H44.269C45.9425 1.26782 47.2993 2.61537 47.2993 4.27757V28.0547C47.2993 29.7169 45.9425 31.0644 44.269 31.0644Z"
      fill="#F9F9F9"
    />
    <path
      d="M13.7784 17.8812C13.7917 17.9517 13.8095 18.0213 13.8666 18.1058C14.8563 15.623 15.8456 13.1407 16.8336 10.6617C17.8384 10.6617 18.8325 10.6617 19.8507 10.6617C19.7962 10.7988 19.7509 10.9194 19.7006 11.0383C18.2259 14.5346 16.7495 18.0307 15.2779 21.5284C15.2215 21.6617 15.1544 21.7124 15.0039 21.7113C14.1127 21.7035 13.2209 21.7051 12.3295 21.7105C12.1951 21.7116 12.1383 21.6786 12.1023 21.5396C11.3578 18.6607 10.6005 15.7848 9.86273 12.9045"
      fill="#0052CC"
    />
    <path
      d="M9.86273 12.9042C9.73028 12.3873 9.46896 12.0301 8.97256 11.8106C8.15487 11.4494 7.30497 11.1977 6.43672 10.9965C6.39869 10.9875 6.36199 10.9727 6.32471 10.9607C6.32471 10.9341 6.32471 10.9082 6.32471 10.8817C6.35141 10.6645 6.48924 10.6446 6.68119 10.6455C8.19349 10.6535 9.70597 10.6501 11.2184 10.6504C11.9694 10.6504 12.4846 11.0661 12.6245 11.7993C13.0116 13.826 13.3942 15.8535 13.7784 17.8809"
      fill="#FFC601"
    />
    <path
      d="M30.1841 10.4487C30.5075 10.5015 30.8328 10.5428 31.1527 10.6112C31.4676 10.6788 31.7775 10.772 32.0887 10.8576C32.1639 10.8784 32.2352 10.9121 32.3233 10.9459C32.1564 11.7189 31.9916 12.4847 31.8265 13.2505C31.4476 13.1318 31.0853 12.9968 30.7115 12.9065C30.0391 12.7442 29.3527 12.6746 28.6754 12.8557C28.4304 12.9213 28.184 13.0583 27.9924 13.2245C27.6675 13.5059 27.667 13.9171 27.9806 14.2109C28.1942 14.411 28.4448 14.5783 28.6983 14.7275C29.2034 15.0239 29.7338 15.2783 30.232 15.5854C30.9018 15.9984 31.4594 16.5254 31.6827 17.3108C32.0072 18.452 31.6527 19.9732 30.3734 20.8905C29.6371 21.418 28.8041 21.6943 27.9175 21.8072C26.5226 21.9851 25.1536 21.8492 23.8204 21.3946C23.7463 21.3695 23.6748 21.3364 23.5806 21.2982C23.753 20.4957 23.9236 19.6993 24.0989 18.8847C24.2117 18.9332 24.3086 18.9719 24.4024 19.0163C25.2273 19.4049 26.097 19.591 27.0084 19.5804C27.4266 19.5757 27.8363 19.5116 28.2137 19.3135C28.903 18.952 28.9941 18.2502 28.3869 17.7729C28.0899 17.539 27.7486 17.3553 27.4104 17.1805C26.8474 16.8895 26.2908 16.5946 25.8012 16.1858C24.4048 15.0208 24.5832 13.3922 25.3388 12.2964C25.9607 11.3955 26.8749 10.9188 27.9139 10.6581C28.2449 10.5746 28.589 10.544 28.9262 10.4875C28.9765 10.4791 29.025 10.4619 29.0745 10.4487C29.4443 10.4487 29.8143 10.4487 30.1841 10.4487Z"
      fill="#0052CC"
    />
    <path
      d="M41.9744 21.6969C41.0728 21.6969 40.2089 21.6969 39.3218 21.6969C39.238 21.3034 39.151 20.9096 39.0702 20.5145C38.9759 20.0507 38.9781 20.0504 38.4944 20.0504C37.4843 20.0504 36.4742 20.0531 35.4637 20.0476C35.3229 20.0468 35.2544 20.087 35.2083 20.2233C35.0563 20.6695 34.8936 21.1123 34.7243 21.5526C34.7004 21.6147 34.6162 21.6969 34.559 21.6976C33.6022 21.7065 32.6453 21.7039 31.6673 21.7039C31.692 21.6347 31.7079 21.5799 31.7301 21.5276C33.1371 18.1891 34.5448 14.8504 35.9535 11.5119C36.1921 10.946 36.6337 10.6543 37.2467 10.6534C38.0386 10.6527 38.8305 10.6531 39.6514 10.6531C40.4248 14.3298 41.1973 18.0023 41.9744 21.6969ZM38.4971 17.7949C38.2067 16.4129 37.9227 15.0586 37.6383 13.7048C37.6234 13.7029 37.6087 13.701 37.594 13.6989C37.0988 15.0568 36.6039 16.4141 36.1 17.7949C36.9122 17.7949 37.6817 17.7949 38.4971 17.7949Z"
      fill="#0052CC"
    />
    <path
      d="M18.7189 21.7069C19.5171 18.0046 20.3085 14.3319 21.0999 10.6617C22.0626 10.6617 23.0053 10.6617 23.9718 10.6617C23.8952 11.0268 23.8227 11.3782 23.7475 11.7288C23.0503 14.9682 22.3506 18.207 21.6606 21.4481C21.6165 21.6555 21.5419 21.7163 21.3288 21.7124C20.5431 21.698 19.7572 21.7069 18.9713 21.7069C18.8993 21.7069 18.8275 21.7069 18.7189 21.7069Z"
      fill="#0052CC"
    />
    <path
      d="M44.269 31.0644H4.03029C2.35675 31.0644 1 29.7168 1 28.0546V4.27757C1 2.61537 2.35675 1.26782 4.03029 1.26782H44.269C45.9425 1.26782 47.2993 2.61537 47.2993 4.27757V28.0547C47.2993 29.7169 45.9425 31.0644 44.269 31.0644Z"
      stroke="#DDDDDD"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
