import { Cores, Medias } from 'src/componentes/styles'
import styled, { css } from 'styled-components'

export const CorpoModal = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 78%;
`

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  margin-bottom: 10px;
  padding: 30px 36px 40px 46px;
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0px 10px 0px 0px;

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  p {
    margin-bottom: 20px;
  }
`

export const TituloPrincipal = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 36px;
`
export const Linha = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const ContainerMedio = styled.div`
  width: 50%;

  & ~ div {
    margin-left: 18px;
  }

  p {
    color: #e6414b;
    text-align: center;
  }
`

export const ContainerGrande = styled.div`
  width: 100%;
`

export const ContainerCupom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5%;

  @media ${Medias.MOBILE} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`

export const ContainerInputCupom = styled.div`
  max-width: 80%;

  & > form > div {
    margin-bottom: 0;
  }

  @media ${Medias.MOBILE} {
    margin-top: 10px;
    max-width: 100%;
  }
`

export const AvisoFinanceiro = styled.p`
  color: #e6414b;
  text-align: center;
`

export const Aviso = styled.p`
  text-align: center;
  padding-bottom: 5%;
`

export const LinhaBandeiras = styled(Linha)`
  margin-bottom: 25px;

  @media ${Medias.MOBILE} {
    flex-direction: row;
  }
`

export const ContainerBandeiras = styled(Linha)<{ selecionado: boolean }>`
  width: 25%;
  transition: filter 0.2s;
  svg {
    box-shadow: 1px 1px 10px ${Cores.CINZA_1_CLARO};
    transition: box-shadow 0.2s;
  }

  ${({ selecionado }) =>
    !selecionado &&
    css`
      filter: grayscale(100%);

      svg {
        box-shadow: none;
      }
    `}
`

export const IconeLabel = styled.div`
  position: absolute;
  left: 90%;
  top: 0;

  svg {
    min-width: 12px;
    min-height: 12px;
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`
