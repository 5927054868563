import React, { useEffect, useState } from 'react'
import { useHistory, useParams, withRouter } from 'react-router-dom'

import {
  ErroLayoutContainer,
  Carregando,
  ErroContainerLayout,
  FuncoesDataHora,
  IconeCertificates
} from 'src/componentes'
import { RotasApp } from 'src/rotas'
import { Api } from 'src/servicos'
import { DadosCertificado } from 'src/servicos/api/sala-aula/tipos'

import { Container, Conteudo, ContainerTabela } from './styles'

const PaginaValidacaoCertificado: React.FC = () => {
  const parametros = useParams<{ idMatricula: string }>()
  const history = useHistory()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const [pronto, definirPronto] = useState(false)
  const [certificado, definirCertificado] = useState<DadosCertificado>(
    {} as DadosCertificado
  )

  const carregarDados = async () => {
    try {
      definirPronto(false)
      limparErro()

      const dados = await Api.SalaAulaCursoLivre.RequisitarDadosCertificado(
        parametros.idMatricula
      )

      definirCertificado(dados)
      definirPronto(true)
    } catch {
      definirErro({
        mensagens:
          'Ops! Houve algum problema ao carregar os dados do certificado',
        textoConfirmar: 'Tentar de novo',
        acaoConfirmar: carregarDados,
        acaoVoltar: () => history.push(RotasApp.Home)
      })
    }
  }

  useEffect(() => {
    carregarDados()
  }, [parametros.idMatricula])

  return pronto && !erro ? (
    <Container>
      <h1>{IconeCertificates} Certificado</h1>
      <Conteudo>
        <p>
          IPGS atesta e reconhece que <strong>{certificado.nome} </strong>
          completou o curso <strong>{certificado.nomeCurso} </strong> com
          sucesso!
        </p>
        <ContainerTabela>
          <table>
            <tbody>
              <tr>
                <td align="right">
                  <strong>Aluno</strong>
                </td>
                <td>{certificado.nome}</td>
              </tr>
              <tr>
                <td align="right">
                  <strong>Curso</strong>
                </td>
                <td>{certificado.nomeCurso}</td>
              </tr>
              <tr>
                <td align="right">
                  <strong>Início em</strong>
                </td>
                <td>{FuncoesDataHora.novaData(certificado.dataInscricao)}</td>
              </tr>
              <tr>
                <td align="right">
                  <strong>Termino em</strong>
                </td>
                <td>{FuncoesDataHora.novaData(certificado.dataAprovacao)}</td>
              </tr>
              <tr>
                <td align="right">
                  <strong>Carga Horária</strong>
                </td>
                <td>
                  {certificado.cargaHoraria} hora
                  {certificado.cargaHoraria > 0 ? 's' : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </ContainerTabela>
      </Conteudo>
    </Container>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados do certificado ..." />
  ) : (
    <ErroContainerLayout />
  )
}

const ValidacaoCertificado: React.FC = () => {
  return (
    <ErroLayoutContainer.Provider>
      <PaginaValidacaoCertificado />
    </ErroLayoutContainer.Provider>
  )
}

export default withRouter(ValidacaoCertificado)
