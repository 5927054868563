import styled from 'styled-components'

import { Cores } from '../styles/cores'

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 23px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`

export const Corpo = styled.div`
  width: 574px;
  height: 470px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  form {
    width: 100%;
  }
`
export const CorpoModal = styled(Corpo)`
  padding: 41px 0px;
`
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
