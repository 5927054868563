import { useState } from 'react'

import { ControleDeCarregamento } from './controle-de-carregamento.interface'
import { ControleDeCarregamentoProps } from './controle-de-carregamento.props'
import { TiposDeControleDeCarregamento } from './tipos-de-controle-de-carregamento.enum'

const configuracaoPadrao = {
  valorInicial: TiposDeControleDeCarregamento.Inativo
}

export function useControleDeCarregamento({
  valorInicial
}: ControleDeCarregamentoProps = configuracaoPadrao): ControleDeCarregamento {
  const [estado, mudarEstado] = useState<TiposDeControleDeCarregamento>(
    valorInicial
  )

  const alterarPara = (estado: TiposDeControleDeCarregamento): void =>
    mudarEstado(estado)

  const alterarParaInativo = (): void =>
    mudarEstado(TiposDeControleDeCarregamento.Inativo)

  const alterarParaIniciado = (): void =>
    mudarEstado(TiposDeControleDeCarregamento.Iniciado)

  const alterarParaConcluido = (): void =>
    mudarEstado(TiposDeControleDeCarregamento.Concluido)

  const estaInativo = (): boolean =>
    estado === TiposDeControleDeCarregamento.Inativo

  const foiIniciado = (): boolean =>
    estado === TiposDeControleDeCarregamento.Iniciado

  const foiConcluido = (): boolean =>
    estado === TiposDeControleDeCarregamento.Concluido

  return {
    alterarParaInativo,
    alterarParaIniciado,
    alterarParaConcluido,
    alterarPara,
    estaInativo,
    foiIniciado,
    foiConcluido
  }
}
