import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PaginaFAQ } from 'src/paginas/faq'
import {
  PaginaCadastroUsuarios,
  PaginaUsuarios
} from 'src/paginas/ipgs/usuarios'
import { ModoPaginaCadastro } from 'src/tipos'

const RotaBase = 'sistema'

export const RotasSistema = {
  Usuarios: `/${RotaBase}/usuarios`
}

export const RotasUsuario = {
  Cadastro: `${RotasSistema.Usuarios}/cadastro`,
  Edicao: `${RotasSistema.Usuarios}/edicao`
}

export const SistemaRotas: React.FC = () => {
  return (
    <Switch>
      <Route exact path={RotasSistema.Usuarios}>
        <PaginaUsuarios />
      </Route>
      <Route path={RotasUsuario.Cadastro}>
        <PaginaCadastroUsuarios modo={ModoPaginaCadastro.Inclusao} />
      </Route>
      <Route path={`${RotasUsuario.Edicao}/:id`}>
        <PaginaCadastroUsuarios modo={ModoPaginaCadastro.Edicao} />
      </Route>
      <Route path={`/${RotaBase}/FAQ/:unityId?/:topicId?/:qnaId?`}>
        <PaginaFAQ />
      </Route>
    </Switch>
  )
}
