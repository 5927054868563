import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5866 11.4863C6.87029 11.1476 7.37485 11.103 7.71358 11.3867L11.9999 14.9765L16.2863 11.3867C16.625 11.103 17.1296 11.1476 17.4132 11.4863C17.6969 11.825 17.6523 12.3296 17.3136 12.6133L12.5136 16.6333C12.2164 16.8822 11.7835 16.8822 11.4863 16.6333L6.68627 12.6133C6.34754 12.3296 6.30292 11.825 6.5866 11.4863Z"
      fill="#828282"
    />
    <path
      d="M11.1997 3.19999V15.2C11.1997 15.6418 11.5579 16 11.9997 16C12.4415 16 12.7997 15.6418 12.7997 15.2V3.19999C12.7997 2.75817 12.4415 2.39999 11.9997 2.39999C11.5579 2.39999 11.1997 2.75817 11.1997 3.19999Z"
      fill="#828282"
    />
    <path
      d="M20.7999 19.2H3.1999C2.75808 19.2 2.3999 19.5582 2.3999 20C2.3999 20.4418 2.75808 20.8 3.1999 20.8H20.7999C21.2417 20.8 21.5999 20.4418 21.5999 20C21.5999 19.5582 21.2417 19.2 20.7999 19.2Z"
      fill="#828282"
    />
    <path
      d="M20 16V20C20 20.4418 20.3581 20.8 20.7999 20.8C21.2417 20.8 21.5999 20.4418 21.5999 20L21.6 16C21.6 15.5582 21.2418 15.2 20.8 15.2C20.3582 15.2 20 15.5582 20 16Z"
      fill="#828282"
    />
    <path
      d="M2.3999 16L2.3999 20C2.3999 20.4418 2.75808 20.8 3.1999 20.8C3.64173 20.8 3.9999 20.4418 3.9999 20V16C3.9999 15.5582 3.64173 15.2 3.1999 15.2C2.75808 15.2 2.3999 15.5582 2.3999 16Z"
      fill="#828282"
    />
  </svg>
)
