import React from 'react'
import { Link as LinkRoute } from 'react-router-dom'

import { Container } from './styles'

interface LinkProps {
  texto: string
  icone?: JSX.Element
  href?: string
  acaoVoltar?: () => void
}

export const Link: React.FC<LinkProps> = ({
  href,
  texto,
  icone,
  acaoVoltar
}) => {
  return (
    <Container>
      {icone}
      <LinkRoute to={href} onClick={acaoVoltar}>
        {texto}
      </LinkRoute>
    </Container>
  )
}
