import React from 'react'

export default (
  <svg
    width="12"
    height="30"
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.11611 1.1209C1.27091 0.959701 1.52189 0.959701 1.67669 1.1209L10.8839 10.7086C11.0387 10.8698 11.0387 11.1311 10.8839 11.2923C10.7291 11.4535 10.4781 11.4535 10.3233 11.2923L1.11611 1.70465C0.961306 1.54345 0.961306 1.2821 1.11611 1.1209Z"
      fill="white"
    />
    <path
      d="M1.1161 20.8791C0.9613 20.7179 0.9613 20.4566 1.1161 20.2954L10.3215 10.7096C10.4763 10.5484 10.7272 10.5484 10.882 10.7096C11.0368 10.8708 11.0368 11.1322 10.882 11.2933L1.67668 20.8791C1.52188 21.0403 1.2709 21.0403 1.1161 20.8791Z"
      fill="white"
    />
    <path
      d="M1.11611 1.1209C1.27091 0.959701 1.52189 0.959701 1.67669 1.1209L10.8839 10.7086C11.0387 10.8698 11.0387 11.1311 10.8839 11.2923C10.7291 11.4535 10.4781 11.4535 10.3233 11.2923L1.11611 1.70465C0.961306 1.54345 0.961306 1.2821 1.11611 1.1209Z"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M1.1161 20.8791C0.9613 20.7179 0.9613 20.4566 1.1161 20.2954L10.3215 10.7096C10.4763 10.5484 10.7272 10.5484 10.882 10.7096C11.0368 10.8708 11.0368 11.1322 10.882 11.2933L1.67668 20.8791C1.52188 21.0403 1.2709 21.0403 1.1161 20.8791Z"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
)
