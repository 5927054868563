import React, { useEffect, useRef, useMemo, useState } from 'react'

import { useField } from '@unform/core'
import { IconeCerto, IconeErrado } from 'src/componentes/icones'
import { RadioTema } from 'src/componentes/radio/tipos'
import { Cores } from 'src/componentes/styles'
import { v4 } from 'uuid'

import { Radio } from '../../radio'
import {
  Container,
  Conteudo,
  Enunciado,
  Erro,
  ObservacaoAnulada,
  Atencao,
  ContainerExplicacao,
  ContainerIcone
} from './styles'
import { QuestaoProps } from './tipos'

export const QuestaoUnform: React.FC<QuestaoProps> = ({
  id,
  name,
  enunciado,
  alternativas,
  resposta,
  anulada,
  observacao,
  explicacao
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField, defaultValue, error } = useField(name)
  const [primeiroRender, definirPrimeiroRender] = useState(true)

  const temaAlternativas = useMemo(() => {
    const retorno: RadioTema[] = []

    const valor = inputRefs.current?.find(ref => ref.checked)?.value ?? ''

    alternativas.forEach(alt => {
      if (!primeiroRender && valor && valor === alt.id) {
        retorno.push(resposta ? 'Padrao' : 'Vermelho')
      } else {
        retorno.push(undefined)
      }
    })

    return retorno
  }, [resposta, primeiroRender])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find(ref => ref.checked)?.value ?? ''
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], value: string) => {
        refs.forEach(ref => {
          if (value === ref.value) {
            ref.checked = true
          }
        })
      }
    })
  }, [fieldName, registerField])

  return (
    <Container id={id} theme={{ ComErro: !!error }}>
      {anulada && !!observacao && (
        <ObservacaoAnulada>
          <Atencao>*</Atencao>
          {observacao}
        </ObservacaoAnulada>
      )}
      <Enunciado>{enunciado}</Enunciado>
      <Conteudo>
        {alternativas.map((item, index) => (
          <Radio
            ref={ref => {
              if (ref) {
                inputRefs.current[index] = ref as HTMLInputElement

                if (index === alternativas.length - 1) {
                  definirPrimeiroRender(false)
                }
              }
            }}
            key={item.id}
            id={`${v4()}`}
            value={item.id}
            texto={item.texto}
            tema={temaAlternativas[index]}
            estilizarTexto={temaAlternativas[index] !== undefined}
            name={fieldName}
            readOnly={resposta !== undefined}
            defaultChecked={defaultValue === item.id}
          />
        ))}
        {explicacao && (
          <ContainerExplicacao tema={resposta ? 'SUCESSO' : 'ERRO'}>
            <p>
              <ContainerIcone
                color={resposta ? Cores.SUCESSO : Cores.PERIGO_ERRO}
              >
                {resposta ? IconeCerto : IconeErrado}
              </ContainerIcone>
              {explicacao}
            </p>
          </ContainerExplicacao>
        )}
      </Conteudo>
      <Erro id={`erro-${id}`}>{error}</Erro>
    </Container>
  )
}
