import React, { useState, useEffect, useCallback } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Obrigatorio } from '../input/styles'
import { Componente, CampoLabel, SeletorEstilo } from './styles'
import { SeletorProps, SeletorValor, SeletorOpcoes } from './tipos'

export function Seletor<T extends SeletorValor>({
  seletores,
  tipo,
  name,
  valorAlterado,
  valores,
  inputRefs,
  defaultValue,
  label,
  obrigatorio,
  disabled,
  readOnly,
  ...rest
}: SeletorProps<T>): JSX.Element {
  const [valoresMarcados, definirValoresMarcados] = useState<T[] | T>(
    defaultValue || valores
  )

  useEffect(() => {
    definirValoresMarcados(valores)
  }, [valores])

  const manipularAlteracao = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: T
  ) => {
    if (!readOnly && !disabled) {
      let novosValores: T[] | T
      if (e.target.checked) {
        if (tipo === 'checkbox') {
          novosValores = [...(valoresMarcados as T[]), id]
        } else {
          novosValores = id
        }
      } else {
        if (tipo === 'checkbox') {
          novosValores = (valoresMarcados as T[]).filter(i => i !== id)
        } else {
          novosValores = undefined
        }
      }

      definirValoresMarcados(novosValores)

      if (valorAlterado) valorAlterado(novosValores)
    }
  }

  const valorMarcado = (id: T) => {
    if (tipo === 'checkbox') {
      return (valoresMarcados as T[]).includes(id)
    }

    return id === (valoresMarcados as T)
  }

  const renderizarItem = useCallback(
    (id, texto, comStroke, icone) => {
      return (
        <SeletorEstilo
          icone={icone}
          className={classNames({
            marcado: valorMarcado(id),
            comStroke
          })}
        >
          {icone}
          <div>{texto}</div>
        </SeletorEstilo>
      )
    },
    [valoresMarcados]
  )

  const Seletor = useCallback(
    ({ id, texto, comStroke, icone }: SeletorOpcoes<T>, indice) => (
      <li key={`${name}-${id}`}>
        <input
          {...rest}
          data-testid={`${name}-${id}`}
          id={`${name}-${id}`}
          ref={ref => {
            if (inputRefs) {
              inputRefs.current[indice] = ref as HTMLInputElement
            }
          }}
          type={tipo}
          name={name}
          value={id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            manipularAlteracao(e, id)
          }
          checked={valorMarcado(id)}
          defaultChecked={defaultValue?.find(
            (idOpcao: string) => idOpcao === id
          )}
        />
        <label id={`${name}-${id}-label`} htmlFor={`${name}-${id}`}>
          {renderizarItem(id, texto, comStroke, icone)}
        </label>
      </li>
    ),
    [inputRefs, tipo, name, renderizarItem, manipularAlteracao, valorMarcado]
  )

  const renderizarSeletores = useCallback(
    () =>
      seletores.map(({ id, texto, comStroke, icone }, indice) => {
        if (readOnly) {
          if (valorMarcado(id)) {
            return Seletor({ id, texto, comStroke, icone }, indice)
          }
          return <></>
        }

        return Seletor({ id, texto, comStroke, icone }, indice)
      }),
    [readOnly, valores, valoresMarcados, Seletor]
  )

  return (
    <Componente readOnly={readOnly}>
      {label && (
        <CampoLabel>
          {obrigatorio ? <Obrigatorio>*</Obrigatorio> : <></>}
          {label}
        </CampoLabel>
      )}
      <ul>{renderizarSeletores()}</ul>
    </Componente>
  )
}

Seletor.defaultProps = {
  valorAlterado: undefined,
  valores: [],
  inputRefs: undefined,
  defaultValue: undefined
}

Seletor.propTypes = {
  name: PropTypes.string.isRequired,
  seletores: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      texto: PropTypes.string,
      comStroke: PropTypes.bool,
      icone: PropTypes.element
    })
  ).isRequired,
  tipo: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  valorAlterado: PropTypes.func,
  valores: PropTypes.any,
  inputRefs: PropTypes.any,
  defaultValue: PropTypes.any
}
