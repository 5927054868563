import { MutableRefObject } from 'react'

import { ModalExcluirMaterialRef, ModalMaterialRef } from 'src/componentes'
import { ModalIncluirPerguntaRef } from 'src/componentes/perguntas'
import { TipoDeAvaliacao, Pergunta } from 'src/dto'
import { DisciplinaComplementar } from 'src/servicos/api/tipos'
import {
  EncontroPresencial,
  ModoPaginaCadastro,
  SituacaoDaTurma,
  TipoMaterial,
  Turma as TurmaTipo
} from 'src/tipos'

import { ModalIncluirEncontroRef } from './componentes/presencial/modal-incluir-encontro/tipos'
import { ModalRemoverEncontroRef } from './componentes/presencial/modal-remover-encontro/tipos'

export interface PaginaTurmaProps {
  modo: ModoPaginaCadastro
}

export type Turma = Omit<TurmaTipo, 'materiaisTurma' | 'cursoId' | 'nomeCurso'>

export interface MateriaisAtualizados {
  idDisciplina?: string
  tipoMaterialComplementar?: TipoMaterialComplementar
}

export interface MaterialTurma {
  id?: string
  titulo: string
  url?: string
  tipo?: TipoMaterial
  arquivo?: File | string
  ordem?: number
  idDisciplina?: string
  tipoMaterialComplementar?: TipoMaterialComplementar
}

export interface EncontroPresencialTurma extends EncontroPresencial {
  idDisciplina: string
}

export interface PerguntaTurma extends Pergunta {
  idReferencia?: string
}

export enum Navegacoes {
  DadosTurma = 'dados-turma',
  Curso = 'curso',
  Localizacao = 'localizacao',
  GrupoDiscussao = 'grupo-discussao',
  MateriaisCurso = 'materiais-curso',
  PesquisaSatisfacao = 'pesquisa-satisfacao',
  MaterialComplementar = 'material-complementar'
}

export interface TurmaContainerEstado {
  modo: ModoPaginaCadastro
  perguntas: PerguntaTurma[]
  materiais: MaterialTurma[]
  encontros: EncontroPresencialTurma[]
  turma?: Omit<Turma, 'materiaisTurma' | 'cursoId' | 'nomeCurso'>
  cursoId?: string
  materiaisAtualizados: MateriaisAtualizados[]
  disciplinasComplementares: DisciplinaComplementar[]
  modalIncluirPerguntaRef: MutableRefObject<ModalIncluirPerguntaRef>
  modalIncluirMaterialRef: MutableRefObject<ModalMaterialRef>
  modalIncluirMaterialTurmaRef: MutableRefObject<ModalMaterialRef>
  modalExcluirMaterialRef: MutableRefObject<ModalExcluirMaterialRef>
  modalAdicionarEncontroRef: MutableRefObject<ModalIncluirEncontroRef>
  modalRemoverEncontroRef: MutableRefObject<ModalRemoverEncontroRef>
}

export interface TurmaContainerEstadoInicial {
  modo: ModoPaginaCadastro
  modalIncluirPerguntaRef: MutableRefObject<ModalIncluirPerguntaRef>
  modalIncluirMaterialRef: MutableRefObject<ModalMaterialRef>
  modalIncluirMaterialTurmaRef: MutableRefObject<ModalMaterialRef>
  modalExcluirMaterialRef: MutableRefObject<ModalExcluirMaterialRef>
  modalAdicionarEncontroRef: MutableRefObject<ModalIncluirEncontroRef>
  modalRemoverEncontroRef: MutableRefObject<ModalRemoverEncontroRef>
}

interface FormularioAvaliacao {
  id?: string
  temAvaliacao: boolean
  adicionarAvaliacao: boolean
  nome: string
  quantidadeTentativas: number
  descricao?: string
  tipo: TipoDeAvaliacao
  dataLiberacaoAvaliacao?: string
  dataRealizacaoAvaliacao?: string
  arquivo?: File | string
}

export interface FormularioModulo {
  id: string
  disciplina?: {
    id: string
    professoresIds?: string[]
    avaliacao?: FormularioAvaliacao
    temAvaliacao?: boolean
  }[]
  avaliacao?: FormularioAvaliacao
  dataLiberacao?: string
  temAvaliacao?: boolean
}

export interface FormularioModuloTCC {
  id: string
  disciplina?: {
    id: string
    professoresOrientadoresIds?: string[]
    professoresBancaIds?: string[]
    avaliacao?: FormularioAvaliacao
  }[]
  avaliacao?: FormularioAvaliacao
  dataLiberacao?: string
}

interface FormularioEndereco {
  local?: string
  cep: string
  logradouro: string
  numero: string
  complemento?: string
  bairro: string
  cidade: string
  uf: string
}

export interface FormularioTurma {
  nome: string
  codigo: string
  dataInicio?: string
  dataFim?: string
  dataInicioInscricao?: string
  dataFimInscricao?: string
  cursoId: string
  professorId: string
  situacaoTurma?: SituacaoDaTurma
  gradeCurricularId: string
  grupoDiscussao?: string
  modulo: FormularioModulo[]
  localizacao?: FormularioEndereco
  moduloTCC: FormularioModuloTCC
  pesquisaSatisfacaoId?: string
  pesquisaSatisfacaoProfessoresId?: string
  turmaReingressoId?: string
  sincronizarCrm?: boolean
  turmaSoCertificacao?: boolean
  turmaSplit?: boolean
}

export enum TipoMaterialComplementar {
  Artigos = 'Artigos',
  Encontros = 'Encontros',
  GestaoDeCarreira = 'GestaoDeCarreira'
}
