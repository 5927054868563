import { SituacaoUpload, TipoDocumentoAluno } from 'src/tipos'

import { UploadDocumentos } from '../tipos'

export const RelacaoDocumentosLicencaAluno = new Array<UploadDocumentos>({
  id: 1,
  tipo: TipoDocumentoAluno.CertidaoNascimento,
  status: SituacaoUpload.PendenteEnvio,
  ordemExibicao: 1,
  multiplo: true
} as UploadDocumentos)
