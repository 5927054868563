import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'

import { AutenticacaoContainer } from 'src/componentes'
import { recuperarRotaBasePorVisao } from 'src/componentes/autenticacao/funcoes'

export const Redirecionador: React.FC = () => {
  const {
    perfil: { perfis }
  } = AutenticacaoContainer.useContainer()

  const recuperarRotaDaVisao = useMemo(() => {
    return recuperarRotaBasePorVisao(perfis)
  }, [perfis])

  return <Redirect to={recuperarRotaDaVisao} />
}
