import React, { useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  DataInputUnform,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectOpcao,
  SelectUnform
} from 'src/componentes'
import { Tabela, TabelaRef, TabelaResposta } from 'src/componentes/tabela'
import { Api } from 'src/servicos'
import {
  RequisitarListaCursosPorTextoFiltro,
  RequisitarListaTurmasPorTexto
} from 'src/servicos/api'
import {
  TipoOrdenacao,
  SituacaoFinanceiraFiltro,
  SituacaoParcelaFiltro,
  SituacaoFinanceiraPorExtenso,
  FormaPagamentoCobranca,
  StatusRecorrenciaPorExtenso,
  StatusRecorrencia,
  TipoRecorrenciaAcaoPorExtenso,
  TipoRecorrencia
} from 'src/tipos'

import { COLUNAS_RECORRENCIA } from './componentes'
import { SELECT_MODALIDADES } from './opcoes'
import { Form, Container, Campo, ContainerExportar, Dados } from './styles'
import { DadosPesquisa } from './tipos'

const TIPOS_RECORRENCIA_SELECT = [
  {
    id: TipoRecorrencia[TipoRecorrencia.NegociacaoEspecial],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.NegociacaoEspecial]
  },
  {
    id: TipoRecorrencia[TipoRecorrencia.TrocaParcelamento],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.TrocaParcelamento]
  },
  {
    id: TipoRecorrencia[TipoRecorrencia.CartaoVencido],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.CartaoVencido]
  },
  {
    id: TipoRecorrencia[TipoRecorrencia.Transferencia],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.Transferencia]
  }
] as SelectOpcao[]

export const PaginaRecorrencia: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const deveLimparSituacaoParcela = () => {
    return false
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioRecorrencia(
      {
        inicioPagamento: dadosPesquisa?.inicioPagamento,
        fimPagamento: dadosPesquisa?.fimPagamento,
        inicioVencimento: dadosPesquisa?.inicioVencimento,
        fimVencimento: dadosPesquisa?.fimVencimento,
        formaPagamento: dadosPesquisa?.formaPagamento,
        modalidade: dadosPesquisa?.modalidade,
        turmaId: dadosPesquisa?.turma,
        cursoId: dadosPesquisa?.curso,
        statusRecorrencia: dadosPesquisa?.statusRecorrencia,
        situacaoFinanceira: dadosPesquisa?.situacaoFinanceira,
        tipoRecorrencia: dadosPesquisa?.tipoRecorrencia,
        situacaoCobranca: dadosPesquisa?.situacaoCobranca,
        nome: dadosPesquisa?.nomeAluno,
        inicioMatricula: dadosPesquisa?.inicioMatricula,
        fimMatricula: dadosPesquisa?.fimMatricula
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioRecorrencia({
        inicioPagamento: dadosPesquisa?.inicioPagamento,
        fimPagamento: dadosPesquisa?.fimPagamento,
        inicioVencimento: dadosPesquisa?.inicioVencimento,
        fimVencimento: dadosPesquisa?.fimVencimento,
        formaPagamento: dadosPesquisa?.formaPagamento,
        modalidade: dadosPesquisa?.modalidade,
        turmaId: dadosPesquisa?.turma,
        cursoId: dadosPesquisa?.curso,
        statusRecorrencia: dadosPesquisa?.statusRecorrencia,
        situacaoFinanceira: dadosPesquisa?.situacaoFinanceira,
        tipoRecorrencia: dadosPesquisa?.tipoRecorrencia,
        situacaoCobranca: dadosPesquisa?.situacaoCobranca,
        nome: dadosPesquisa?.nomeAluno,
        inicioMatricula: dadosPesquisa?.inicioMatricula,
        fimMatricula: dadosPesquisa?.fimMatricula
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Recorrência"
          atalhos={[
            {
              texto: 'Relatórios Financeiros'
            },
            {
              texto: 'Recorrência'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="inicioPagamento"
                label="Início Período Pagamento"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="fimPagamento"
                label="Fim Período Pagamento"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="inicioVencimento"
                label="Início Período Vencimento"
              />
            </Campo>
            <Campo>
              <DataInputUnform
                name="fimVencimento"
                label="Fim Período Vencimento"
              />
            </Campo>
          </Container>
          <Container pb>
            <Campo mr>
              <SelectUnform
                id="slct_formaPagamento"
                name="formaPagamento"
                label="Forma de Pagamento"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto],
                    texto: FormaPagamentoCobranca.Boleto
                  },
                  {
                    id:
                      FormaPagamentoCobranca[
                        FormaPagamentoCobranca.CartaoCredito
                      ],
                    texto: FormaPagamentoCobranca.CartaoCredito
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Cheque],
                    texto: FormaPagamentoCobranca.Cheque
                  },
                  {
                    id:
                      FormaPagamentoCobranca[
                        FormaPagamentoCobranca.CupomDesconto
                      ],
                    texto: FormaPagamentoCobranca.CupomDesconto
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Deposito],
                    texto: FormaPagamentoCobranca.Deposito
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Dinheiro],
                    texto: FormaPagamentoCobranca.Dinheiro
                  },
                  {
                    id:
                      FormaPagamentoCobranca[FormaPagamentoCobranca.PagSeguro],
                    texto: FormaPagamentoCobranca.PagSeguro
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.PayPal],
                    texto: FormaPagamentoCobranca.PayPal
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Pix],
                    texto: FormaPagamentoCobranca.Pix
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.TED],
                    texto: FormaPagamentoCobranca.TED
                  }
                ]}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_modalidadade"
                name="modalidade"
                label="Modalidade"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={SELECT_MODALIDADES}
              />
            </Campo>
          </Container>
          <Container pb>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
                multiplo
              />
            </Campo>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                buscarPorTexto={RequisitarListaCursosPorTextoFiltro}
                buscarPorId={RequisitarListaCursosPorTextoFiltro}
                multiplo
              />
            </Campo>
            <Campo>
              <SelectUnform
                className="selectSemMarginBottom"
                id="slct_tipoRecorrencia"
                name="tipoRecorrencia"
                label="Tipo Recorrência"
                opcoes={TIPOS_RECORRENCIA_SELECT}
                multiplo
              />
            </Campo>
          </Container>

          <Container pb>
            <Campo mr>
              <SelectUnform
                id="slct_statusRecorrencia"
                name="statusRecorrencia"
                label="Status da Recorrência"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id: StatusRecorrencia[StatusRecorrencia.Ativo],
                    texto: StatusRecorrenciaPorExtenso.Ativo
                  },
                  {
                    id: StatusRecorrencia[StatusRecorrencia.Finalizado],
                    texto: StatusRecorrenciaPorExtenso.Finalizado
                  },
                  {
                    id: StatusRecorrencia[StatusRecorrencia.DesativadaLojista],
                    texto: StatusRecorrenciaPorExtenso.DesativadaLojista
                  },
                  {
                    id:
                      StatusRecorrencia[
                        StatusRecorrencia.DesativadaNumeroTentativas
                      ],
                    texto:
                      StatusRecorrenciaPorExtenso.DesativadaNumeroTentativas
                  },
                  {
                    id:
                      StatusRecorrencia[
                        StatusRecorrencia.DesativadaCartaoVencido
                      ],
                    texto: StatusRecorrenciaPorExtenso.DesativadaCartaoVencido
                  }
                ]}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_situacao_parcela"
                name="situacaoCobranca"
                label="Situação Parcela"
                iconeFechar
                margemInferior={false}
                deveLimpar={deveLimparSituacaoParcela}
                multiplo
                opcoes={[
                  {
                    id: 'Todas',
                    texto: 'Todas'
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.AVencer],
                    texto: SituacaoParcelaFiltro.AVencer
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Cancelado],
                    texto: SituacaoParcelaFiltro.Cancelado
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Negativado],
                    texto: SituacaoParcelaFiltro.Negativado
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Pago],
                    texto: SituacaoParcelaFiltro.Pago
                  },
                  {
                    id:
                      SituacaoParcelaFiltro[SituacaoParcelaFiltro.Renegociado],
                    texto: SituacaoParcelaFiltro.Renegociado
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Vencido],
                    texto: SituacaoParcelaFiltro.Vencido
                  },
                  {
                    id:
                      SituacaoParcelaFiltro[
                        SituacaoParcelaFiltro.AguardandoPagamentoAutomatico
                      ],
                    texto: SituacaoParcelaFiltro.AguardandoPagamentoAutomatico
                  }
                ]}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_situacaoFinanceira"
                name="situacaoFinanceira"
                label="Situação Matrícula"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro
                          .AguardandoCartaoPagamentoRecorrente
                      ],
                    texto:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro
                          .AguardandoCartaoPagamentoRecorrente
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Cancelado
                      ],
                    texto:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Cancelado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.ContratoRescindido
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.ContratoRescindido
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Finalizado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Finalizado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Inativo
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Inativo
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Matriculado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Matriculado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Reprovado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Reprovado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Reservado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Reservado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Transferido
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Transferido
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Recuperacao
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Recuperacao
                      ]
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="inicioDataMatricula"
                label="Início Período Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="fimDataMatricula"
                label="Fim Período Matrícula"
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={COLUNAS_RECORRENCIA}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaRecorrencia />
  </ErroLayoutContainer.Provider>
)
