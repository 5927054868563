import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaValorParcelaProrrogacao = (): TabelaColuna => ({
  Chave: 'ValorParcelaProrrogacao',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 250 }}>Valor Parcela Prorrogação</div>
  )
})
