import queryString from 'query-string'
import {
  HistoricoCursoLivreResposta,
  HistoricoCursoOutrosResposta,
  DeclaracaoResposta
} from 'src/dto'
import { DeclaracoesCursoOutros } from 'src/tipos'

import { gatewayApi } from '../instancias-api'

const urlBase = '/historico'

export const DadosHistoricoCursoOutros = async (
  usuarioId: string
): Promise<HistoricoCursoOutrosResposta | null> => {
  const rota = `${urlBase}/${usuarioId}/curso-outros`
  const resposta = await gatewayApi.get<HistoricoCursoOutrosResposta>(rota)

  if (!resposta || resposta.status !== 200) return null

  return resposta.data
}

export const DadosHistoricoCursoLivre = async (
  usuarioId: string
): Promise<HistoricoCursoLivreResposta | null> => {
  const rota = `${urlBase}/${usuarioId}/curso-livre`
  const resposta = await gatewayApi.get<HistoricoCursoLivreResposta>(rota)

  if (!resposta || resposta.status !== 200) return null

  return resposta.data
}
export const ObterDeclaracoesDisponiveis = async (
  salaAulaId: string
): Promise<DeclaracoesCursoOutros[] | null> => {
  const rota = `${urlBase}/curso-outros/${salaAulaId}/declaracoes`
  const resposta = await gatewayApi.get<DeclaracoesCursoOutros[]>(rota)

  if (!resposta || resposta.status !== 200) return null

  return resposta.data
}
export const ObterDeclaracao = async (
  salaAulaId: string
): Promise<DeclaracaoResposta | null> => {
  const rota = `${urlBase}/curso-outros/${salaAulaId}/declaracao-model`
  const resposta = await gatewayApi.get<DeclaracaoResposta>(rota)

  if (!resposta || resposta.status !== 200) return null

  return resposta.data
}

export const ObterArquivoDeclaracao = async (
  salaAulaId: string,
  declaracao: number
): Promise<string> => {
  const queryParams = queryString.stringify({
    declaracao
  })

  const resposta = await gatewayApi.get(
    `${urlBase}/curso-outros/${salaAulaId}/declaracao?${queryParams}`,
    {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}
