import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaQuantidadeCobrancasNaoPagas = (): TabelaColuna => ({
  Chave: 'QtdCobrancasNaoPagas',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 250 }}>Quantidade Cobranças Não Pagas</div>
  )
})
