import React, { FC } from 'react'

import { Container, Icone, Texto } from './styles'

export interface TituloDecoradoProps {
  icone: JSX.Element
  texto: string
}

export const TituloDecorado: FC<TituloDecoradoProps> = ({ icone, texto }) => {
  return (
    <Container>
      <Icone>{icone}</Icone>
      <Texto>{texto}</Texto>
    </Container>
  )
}
