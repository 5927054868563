import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo
} from 'react'

import { isString } from 'lodash'
import { ModalBase, Botao } from 'src/componentes'
import { FormUnform, InputUnform, SelectUnform } from 'src/componentes/unform'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'
import { Material, TipoMaterial } from 'src/tipos'

import { ContainerBotoes, Corpo } from '../styles'
import { createSchema } from './schema'
import { ModalMaterialRef, ModalIncluirProps } from './tipos'

import { TipoMaterialComplementar } from '..'

const ModalIncluirComponente: React.ForwardRefRenderFunction<
  ModalMaterialRef,
  ModalIncluirProps
> = ({ backdrop, acaoPrimaria, dados, exibirTipoMaterial }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [material, definirMaterial] = useState<Material | null>(dados)
  const [titulosUtilizados, definirTitulosUtilizados] = useState<string[]>([])
  const [index, definirIndex] = useState<number | null>(null)
  const [referencia, definirReferencia] = useState<string | null>(null)
  const [
    tipoMaterialComplementar,
    definirTipoMaterialComplementar
  ] = useState<TipoMaterialComplementar>(null)

  const obterArquivoInicial = (arquivo: File | string) => {
    if (typeof arquivo !== 'string') {
      return (arquivo as File).name
    }

    return arquivo
  }

  const dadosIniciais = useMemo(() => {
    return material
      ? { ...material, arquivo: obterArquivoInicial(material.arquivo) }
      : {
          tipo: TipoMaterial.Anexo,
          arquivo: null
        }
  }, [material])

  const [tipoMaterial, definirTipoMaterial] = useState<TipoMaterial>(
    TipoMaterial.Anexo
  )

  const fechar = () => {
    definirMaterial(null)
    definirTitulosUtilizados([])
    definirIndex(null)
    definirReferencia(null)
    definirTipoMaterial(TipoMaterial.Anexo)
    definirAberta(false)
    definirPronto(false)
  }

  const abrir = (
    titulosUtilizados: string[],
    material?: Material,
    index?: number,
    referencia?: string,
    tipoMaterialComplementar?: TipoMaterialComplementar
  ) => {
    if (material) {
      if (!material.arquivo) {
        material.arquivo = ''
      }
      definirMaterial(material)

      if (material.tipo) {
        definirTipoMaterial(material?.tipo ?? TipoMaterial.Anexo)
      }
    }

    definirTitulosUtilizados(titulosUtilizados)
    definirIndex(index)
    definirReferencia(referencia)
    definirTipoMaterialComplementar(tipoMaterialComplementar)
    definirAberta(true)
    definirPronto(true)
  }

  useImperativeHandle<ModalMaterialRef, ModalMaterialRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = async (dados: Material) => {
    const id = material?.id
    if (isString(dados.arquivo) && !isString(material?.arquivo)) {
      dados.arquivo = material.arquivo
    }

    definirMaterial(null)
    acaoPrimaria(
      {
        ...dados,
        tipo: tipoMaterial,
        id: id,
        tipoMaterialComplementar: tipoMaterialComplementar
      },
      index,
      referencia
    )
    fechar()
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <h5>{!material ? 'Incluir' : 'Editar'} Material </h5>
        {pronto && (
          <FormUnform
            schema={createSchema(
              titulosUtilizados,
              !!material?.id,
              exibirTipoMaterial
            )}
            acaoSucesso={acaoSucesso}
            dadosIniciais={dadosIniciais}
          >
            <InputUnform
              label="Informe o Título de Exibição do Material"
              name="titulo"
              maxWidthInput="100%"
              obrigatorio
            />
            {exibirTipoMaterial ? (
              <SelectUnform
                id="slct_situacao"
                name="tipo"
                label="Tipo de material"
                iconeFechar={false}
                disabled={!!material}
                valorAlterado={valor => {
                  definirTipoMaterial(valor.id as TipoMaterial)
                }}
                opcoes={[
                  {
                    id: TipoMaterial.Anexo,
                    texto: 'Anexar material de aula'
                  },
                  {
                    id: TipoMaterial.HiperLink,
                    texto: 'Anexar hiperlink de aula'
                  },
                  {
                    id: TipoMaterial.Video,
                    texto: 'Informar URL do vídeo'
                  }
                ]}
              />
            ) : (
              <SelectUnform
                id="slct_situacao"
                name="tipo"
                label="Material"
                disabled
                iconeFechar={false}
                valorAlterado={valor => {
                  definirTipoMaterial(valor.id as TipoMaterial)
                }}
                opcoes={[
                  {
                    id: TipoMaterial.Anexo,
                    texto: 'Material de aula'
                  }
                ]}
              />
            )}
            {tipoMaterial === TipoMaterial.Video && (
              <InputUnform
                label="Informar URL do vídeo"
                name="url"
                maxWidthInput="100%"
                obrigatorio={!material}
                disabled={!!material}
              />
            )}
            {tipoMaterial === TipoMaterial.Anexo && (
              <ArquivoInputUnform
                label="Selecione o Arquivo"
                name="arquivo"
                obrigatorio={!material}
                disabled={!!material}
                tiposAceitos={[
                  '.pdf',
                  '.doc',
                  '.docx',
                  '.xls',
                  '.xlsx',
                  '.csv',
                  '.ppt',
                  '.pptx'
                ]}
              />
            )}
            {tipoMaterial === TipoMaterial.HiperLink && (
              <InputUnform
                label="Informar URL"
                name="url"
                maxWidthInput="100%"
                obrigatorio={!material}
                disabled={!!material}
              />
            )}
            <ContainerBotoes>
              <Botao
                type="button"
                id="btn-cancelar"
                texto="Cancelar"
                tema="Secundario"
                onClick={() => {
                  fechar()
                }}
              />
              <Botao type="submit" id="btn-salvar" texto="Salvar" />
            </ContainerBotoes>
          </FormUnform>
        )}
      </Corpo>
    </ModalBase>
  )
}

export const ModalIncluirMaterial = forwardRef(ModalIncluirComponente)
