import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useMemo
} from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  InputUnform,
  CpfInputUnform,
  ModalRef,
  AutenticacaoContainer,
  CpfCnpjInputUnform
} from 'src/componentes'
import { Api } from 'src/servicos'
import { TipoUsuario } from 'src/tipos'

import { schema } from './schema'
import { Botoes, ContainerInput, Corpo, Texto, Titulo, Aviso } from './styles'
import { DadosAlteracaoCPFCNPJ, ModalAlteracaoCPFCNPJ } from './tipos'

const ModalAlteracaoCPFCNPJComponente: React.ForwardRefRenderFunction<
  ModalRef,
  ModalAlteracaoCPFCNPJ
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [carregando, definirCarregando] = useState(false)
  const [cpfExistente, definirCpfExistente] = useState(false)
  const { usuario } = AutenticacaoContainer.useContainer()
  const tipoUsuarioPF = useMemo(() => usuario.tipoUsuario === TipoUsuario.PF, [
    usuario.tipoUsuario
  ])

  const abrir = () => definirAberta(true)
  const fechar = () => definirAberta(false)

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  const verificarCPFValido = async () => {
    const cpf = formRef?.current?.getFieldValue('cpf')

    if (cpf.length !== 11) return

    try {
      definirCarregando(true)
      const cpfExistente = await Api.VerificarUsuarioPorCpf(cpf)
      definirCpfExistente(cpfExistente)
    } finally {
      definirCarregando(false)
    }
  }

  const acaoSalvar = async (dados: DadosAlteracaoCPFCNPJ) => {
    try {
      definirCarregando(true)
      await Api.AtualizarCpfCnpjUsuario(dados)
      if (tipoUsuarioPF) {
        toast('CPF atualizado com sucesso!', { type: 'success' })
      } else {
        toast('CNPJ atualizado com sucesso!', { type: 'success' })
      }
      acaoPrimaria(dados)
      fechar()
    } catch {
      if (tipoUsuarioPF) {
        toast('Erro ao atualizar CPF.', { type: 'error' })
      } else {
        toast('Erro ao atualizar CNPJ.', { type: 'error' })
      }
    } finally {
      definirCarregando(false)
    }
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <Titulo>Alteração de CPF/CNPJ</Titulo>
        <Texto>
          Para realizar a alteração do número deste documento, informe a sua
          senha de login.
        </Texto>
        <FormUnform ref={formRef} acaoSucesso={acaoSalvar} schema={schema}>
          <ContainerInput>
            {tipoUsuarioPF ? (
              <>
                <CpfInputUnform
                  id="ipt_cpf"
                  name="cpf"
                  label="Novo CPF"
                  onBlur={verificarCPFValido}
                  obrigatorio
                />
                {cpfExistente && <Aviso>CPF já cadastrado.</Aviso>}
              </>
            ) : (
              <>
                <CpfCnpjInputUnform
                  id="ipt_cnpj"
                  name="cnpj"
                  label="Novo CNPJ"
                  obrigatorio
                />
              </>
            )}
            <InputUnform
              id="ipt_senha"
              name="senha"
              label="Informe sua Senha"
              type="password"
              obrigatorio
              autoComplete="off"
              semValidacaoSenha
            />
          </ContainerInput>
          <Botoes>
            <Botao
              texto="Cancelar"
              type="button"
              tema="Secundario"
              onClick={() => fechar()}
            />
            <Botao texto="Salvar" type="submit" carregando={carregando} />
          </Botoes>
        </FormUnform>
      </Corpo>
    </ModalBase>
  )
}

export const ModalAlteracaoCPFCPNJ = forwardRef(ModalAlteracaoCPFCNPJComponente)
