import React from 'react'

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="10"
    fill="none"
    viewBox="0 0 516.375 516.375"
  >
    <g>
      <g>
        <path
          d="M258.188,286.875c-26.775,0-47.812,21.037-47.812,47.812c0,15.3,7.65,28.688,19.125,38.25v38.25
          c0,15.3,13.388,28.688,28.688,28.688s28.688-13.388,28.688-28.688v-38.25c11.475-9.562,19.125-22.95,19.125-38.25
          C306,307.912,284.963,286.875,258.188,286.875z M267.75,361.463v49.725c0,5.737-3.825,9.562-9.562,9.562s-9.562-3.825-9.562-9.562
          v-49.725c-11.475-3.825-19.125-15.301-19.125-26.775c0-15.3,13.388-28.688,28.688-28.688s28.688,13.388,28.688,28.688
          C286.875,348.075,279.225,357.638,267.75,361.463z"
          fill="#606060"
        />
        <path
          d="M401.625,210.375v-66.938C401.625,65.025,336.6,0,258.188,0C179.775,0,114.75,65.025,114.75,143.438v66.938
            c-32.513,0-57.375,24.862-57.375,57.375V459c0,32.513,24.862,57.375,57.375,57.375h286.875C434.138,516.375,459,491.513,459,459
            V267.75C459,237.15,434.138,210.375,401.625,210.375z M133.875,143.438c0-68.85,55.462-124.312,124.312-124.312
            c68.85,0,124.312,55.462,124.312,124.312v66.938h-19.125v-66.938c0-57.375-47.812-105.188-105.188-105.188S153,86.062,153,143.438
            v66.938h-19.125V143.438z M344.25,143.438v66.938H172.125v-66.938c0-47.812,38.25-86.062,86.062-86.062
            S344.25,95.625,344.25,143.438z M439.875,459c0,21.037-17.213,38.25-38.25,38.25H114.75c-21.038,0-38.25-17.213-38.25-38.25
            V267.75c0-21.038,17.212-38.25,38.25-38.25h286.875c21.037,0,38.25,17.212,38.25,38.25V459z"
          fill="#606060"
        />
      </g>
    </g>
  </svg>
)
