import { useState } from 'react'

import { getApiError } from 'src/servicos/instancias-api'
import { createContainer } from 'unstated-next'

import { Erro, ErroContainerLayoutEstado } from './tipos'

const useErroContainerLayout = () => {
  const [estado, definirEstado] = useState<ErroContainerLayoutEstado>({
    erro: undefined
  })

  const definirErro = (erro: Erro) => {
    definirEstado({ erro })
  }

  const limparErro = () => {
    definirEstado({ erro: undefined })
  }

  return { definirErro, limparErro, getApiError, ...estado }
}

export default createContainer(useErroContainerLayout)
