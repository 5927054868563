import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  renegociacoes: Yup.array(
    Yup.object().shape({
      quantidade: Yup.string().numero().nullable().required(mensagemPadrao),
      valorParcela: Yup.string().numero().nullable().required(mensagemPadrao),
      valorTotal: Yup.string().nullable().required(mensagemPadrao),
      primeiroVencimento: Yup.string()
        .nullable()
        .data()
        .required(mensagemPadrao)
    })
  )
})
