import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  DataInputUnform,
  SelectUnform
} from 'src/componentes'
import { Tabela, TabelaRef, TabelaResposta } from 'src/componentes/tabela'
import { SelectOpcaoCursos } from 'src/dto'
import { Api } from 'src/servicos'
import { RequisitarListaTurmasPorTexto } from 'src/servicos/api'
import { TipoOrdenacao } from 'src/tipos'

import { COLUNAS_PRORROGACAO } from './componentes'
import { OPCOES_SITUACAO_FINANCEIRA, SELECT_MODALIDADES } from './opcoes'
import { Form, Container, Campo, Dados, ContainerExportar } from './styles'
import { DadosPesquisa } from './tipos'

export const PaginaProrrogacao: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioProrrogacao(
      {
        nomeAluno: dadosPesquisa?.nomeAluno,
        modalidade: dadosPesquisa?.modalidade,
        cursosId: dadosPesquisa?.curso,
        turmasId: dadosPesquisa?.turma,
        dataInicioMatricula: dadosPesquisa?.dataInicioMatricula,
        dataFimMatricula: dadosPesquisa?.dataFimMatricula,
        dataInicioConclusao: dadosPesquisa?.dataInicioConclusao,
        dataFimConclusao: dadosPesquisa?.dataFimConclusao,
        dataInicioProrrogacao: dadosPesquisa?.dataInicioProrrogacao,
        dataFimProrrogacao: dadosPesquisa?.dataFimProrrogacao,
        situacoes: dadosPesquisa?.situacoes
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioProrrogacao({
        nomeAluno: dadosPesquisa?.nomeAluno,
        modalidade: dadosPesquisa?.modalidade,
        cursosId: dadosPesquisa?.curso,
        turmasId: dadosPesquisa?.turma,
        dataInicioMatricula: dadosPesquisa?.dataInicioMatricula,
        dataFimMatricula: dadosPesquisa?.dataFimMatricula,
        dataInicioConclusao: dadosPesquisa?.dataInicioConclusao,
        dataFimConclusao: dadosPesquisa?.dataFimConclusao,
        dataInicioProrrogacao: dadosPesquisa?.dataInicioProrrogacao,
        dataFimProrrogacao: dadosPesquisa?.dataFimProrrogacao,
        situacoes: dadosPesquisa?.situacoes
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const requisitarListaCursosOutrosPorTextoFiltro = async (
    nome: string
  ): Promise<SelectOpcaoCursos[]> => {
    const result = await Api.RequisitarListaCursoOutros(nome)
    return result.map(x => ({ id: x.id, texto: x.nome }))
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Prorrogação"
          atalhos={[
            {
              texto: 'Relatórios Acadêmicos'
            },
            {
              texto: 'Prorrogação'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_modalidade"
                name="modalidade"
                label="Modalidade"
                iconeFechar
                multiplo
                opcoes={SELECT_MODALIDADES}
              />
            </Campo>
            <Campo mr style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                multiplo
                buscarPorTexto={requisitarListaCursosOutrosPorTextoFiltro}
                buscarPorId={requisitarListaCursosOutrosPorTextoFiltro}
              />
            </Campo>
            <Campo style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                multiplo
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="dataInicioMatricula"
                label="Data Início Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataFimMatricula"
                label="Data Fim Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataInicioConclusao"
                label="Data Início Conclusão"
              />
            </Campo>
            <Campo>
              <DataInputUnform
                name="dataFimConclusao"
                label="Data Fim Conclusão"
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="dataInicioProrrogacao"
                label="Data Início Prorrogação"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataFimProrrogacao"
                label="Data Fim Prorrogação"
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_situacao_financeira"
                name="situacoes"
                label="Situação Matricula"
                iconeFechar
                multiplo
                opcoes={OPCOES_SITUACAO_FINANCEIRA}
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={COLUNAS_PRORROGACAO}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaProrrogacao />
  </ErroLayoutContainer.Provider>
)
