import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaInicioOrientacaoTCC = (): TabelaColuna => ({
  Chave: 'dataInicioOrientacao',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataInicioOrientacao)}</span>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 100 }}>Início Orient. TCC</div>
  )
})
