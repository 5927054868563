import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  DataInputUnform,
  FormUnform,
  ModalBase,
  ModalBaseProps,
  ModalRef
} from 'src/componentes'

import { schema } from './schema'
import {
  ContainerDoConteudo,
  ContainerDosBotoes,
  ContainerModal,
  TituloPrincipal
} from './styles'

export interface ModalAtualizarDataCobrancaDados {
  dataCobranca: string
}

export type ModalAtualizarDataCobrancaProps = Omit<
  ModalBaseProps,
  'acaoPrimaria'
> & {
  acaoPrimaria: (dados: any) => Promise<void>
}

const Modal: React.ForwardRefRenderFunction<
  ModalRef,
  ModalAtualizarDataCobrancaProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const fechar = () => definirAberta(false)
  const abrir = () => definirAberta(true)

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: ModalAtualizarDataCobrancaDados) => {
    await acaoPrimaria(dados)
    fechar()
    formRef.current?.reset()
  }

  return (
    <ModalBase
      id="modal-atualizar-data-cobranca"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Atualizar Data de Cobrança</TituloPrincipal>
        <FormUnform
          ref={formRef}
          acaoSucesso={acaoSucessoForm}
          schema={schema}
          dadosIniciais={{
            dataCobranca: null
          }}
        >
          <ContainerDoConteudo>
            <DataInputUnform label="Data de Cobrança" name="dataCobranca" />
          </ContainerDoConteudo>
          <ContainerDosBotoes>
            <Botao
              texto="Cancelar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Salvar" />
          </ContainerDosBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalAtualizarDataCobranca = forwardRef(Modal)
