import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { AutenticacaoContainer, Botao } from '../'
import { Logo } from '../imagens'
import {
  Centralizado,
  Container,
  Conteudo,
  Layout as ComponenteLayout,
  BarraSuperior,
  BarraLateral
} from './styles'

export const LayoutMatricula: React.FC = ({ children }) => {
  const { usuario, sair } = AutenticacaoContainer.useContainer()

  return (
    <ComponenteLayout>
      <BarraLateral />
      <BarraSuperior>
        {Logo()}
        {!!usuario && (
          <Botao
            onClick={() => {
              sair(true)
              window.location.reload()
            }}
            texto="Sair"
            tema="Link"
          />
        )}
      </BarraSuperior>
      <Container>
        <Scrollbars style={{ height: 'calc(100vh - 72px)' }}>
          <Conteudo>
            <Centralizado>{children}</Centralizado>
          </Conteudo>
        </Scrollbars>
      </Container>
    </ComponenteLayout>
  )
}
