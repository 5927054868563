import { BoletoGerado } from 'src/servicos/api/tipos'

import { MetodosPagamento } from './enums'
import { Usuario } from './usuario'

export enum EtapaReingresso {
  Regulamento = 1,
  Financeiro = 2,
  PlanoPagamento = 3,
  TermosECondicoes = 4,
  DadosPagamento = 5,
  AguardandoPagamento = 6,
  Concluido = 7
}

export interface Reingresso {
  id?: string
  etapaMatricula: keyof typeof EtapaReingresso
  situacaoMatricula: SituacaoReingresso
  termosCondicoes?: TermosCondicoesReingresso
  responsavelFinanceiro?: ResponsavelFinanceiro
  pagamentoReingresso?: PagamentoReingresso
}

export enum SituacaoReingresso {
  EmailEnviado = 'EmailEnviado',
  TicketCriado = 'TicketCriado',
  Cancelado = 'Cancelado',
  RegulamentoAceito = 'RegulamentoAceito',
  PendenciaFinanceira = 'PendenciaFinanceira',
  FinanceiroAceito = 'FinanceiroAceito',
  Finalizado = 'Finalizado',
  SemTurmaReingresso = 'SemTurmaReingresso'
}

export interface TermosCondicoesReingresso {
  urlTermosCondicoes?: string
  liAceitoTermos: boolean
  estouCiente: boolean
}

export interface ResponsavelFinanceiro {
  possuiResponsavelFinanceiro: boolean
  idUsuarioResponsavel?: string
  enderecoUsuarioMatricula?: boolean
  usuarioResponsavel?: Usuario
}

export interface PagamentoReingresso {
  metodoPagamento: MetodosPagamento
  numeroCartao?: string
  nomeCartao?: string
  validadeCartao?: string
  codigoSeguranca?: string
  parcelas?: string
  diaVencimento?: string
  idCupom?: string
  codigoCupom?: string
  valor?: number
  valorDesconto?: number
  valorTotal?: number
  boletoGerado?: BoletoGerado
}
