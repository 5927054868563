import { Cores } from 'src/componentes/styles'
import { FormUnform } from 'src/componentes/unform'
import styled, { css } from 'styled-components'

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Dados = styled.div`
  margin-top: 25px;
`
export const Subtitulo = styled.div`
  margin: 4px;
  text-align: center;
`

export const ModalCorpo = styled.div`
  width: 700px;
  min-height: 470px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  overflow-y: auto;
  max-height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  form {
    width: 100%;
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;

  button {
    width: 100px;
  }

  & > .btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`

export const SubtituloModal = styled.h5`
  margin-bottom: 60px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-top: 10px;
`
export const Linha = styled.div<{
  justifyContent?: 'flex-end' | 'space-between'
}>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  ${({ justifyContent }) => {
    return css`
      justify-content: ${justifyContent ?? 'space-between'};
    `
  }}
`
export const Titulo = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
`
export const Botao = styled.button<{
  semBorda?: boolean
  semEfeitoHover?: boolean
}>`
  color: ${Cores.SECUNDARIA_2_ESCURO};
  background-color: white;
  min-width: 160px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 15px 0 15px;
  cursor: pointer;
  transition: all 0.15s ease-in;
  border: ${({ semBorda }) =>
    semBorda ? 'none' : `2px solid ${Cores.SECUNDARIA_2_ESCURO}`};

  ${({ semEfeitoHover, semBorda }) =>
    !semEfeitoHover &&
    css`
      &:hover {
        color: ${Cores.BRANCO};
        background: ${Cores.SECUNDARIA_2_ESCURO};
        border: ${semBorda ? 'none' : `2px solid ${Cores.SECUNDARIA_2_ESCURO}`};
        outline: 0;
      }

      &:hover svg path {
        fill: ${Cores.BRANCO};
      }
    `}
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ContainerRespostasErradas = styled.div`
  padding-bottom: 10px;
`

export const ContainerRespostas = styled.div`
  padding-bottom: 10px;

  div {
    margin-bottom: 4px;

    :nth-child(2) > label:last-child {
      width: 50px;
    }
  }
`

export const ContainerErros = styled.div`
  padding-bottom: 10px;
  color: red;
`

export const Erro = styled.span<{ sucesso?: boolean }>`
  color: ${props => (props.sucesso ? Cores.SUCESSO : Cores.PERIGO_ERRO)};
  font-size: 10px;
  margin-top: 5px;
`
export const ContainerTooltip = styled.div`
  div {
    max-width: 250px;
  }
`
export const ContainerAnular = styled.div`
  .textarea-container {
    padding-top: 20px;
  }
`
export const ContainerExplicacao = styled.div`
  label {
    display: flex;
    div {
      display: flex;
      svg {
        margin-left: 10px;
        margin-top: 1px;
      }
    }
  }
`
