import styled from 'styled-components'

import { Cores } from '../../styles'

export const Componente = styled.span`
  width: 100%;
  input {
    min-width: 66px;
  }

  svg {
    circle {
      stroke: ${Cores.CINZA_2};
    }
    path {
      fill: ${Cores.CINZA_2};
    }
  }
`
