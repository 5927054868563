import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect
} from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { toast } from 'react-toastify'

import { FormHandles, Scope } from '@unform/core'
import {
  Botao,
  Cores,
  FormUnform,
  ModalBase,
  ModalBaseProps,
  ModalRef,
  Tooltip,
  CheckboxUnform,
  NumeroInputUnform,
  MoedaInputUnform,
  SeletorUnform,
  DataInputUnform,
  TextAreaUnform,
  FuncoesDataHora,
  IconeAdicionar,
  IconeInformacao,
  InputUnform
} from 'src/componentes'
import { Api } from 'src/servicos'
import { ModalRenegociacaoDados, TipoDescontoCalculo } from 'src/tipos'

import { formatadores } from '../../formatadores'
import { schema } from './schema'
import {
  CheckboxWrapper,
  Conteudo,
  ContainerBotoes,
  ContainerModal,
  TituloPrincipal,
  Container,
  Linha,
  Coluna,
  TextoSubTotal,
  ContainerBotao,
  IconeLabel
} from './styles'
import { ModalRenegociacaoRef, Renegociacao } from './tipos'

interface CampoDesconto {
  index: number
  atualizou: boolean
}

export type ModalRenegociacaoProps = Omit<ModalBaseProps, 'acaoPrimaria'> & {
  acaoPrimaria: (dados: any) => Promise<void>
}

const Modal: React.ForwardRefRenderFunction<
  ModalRenegociacaoRef,
  ModalRenegociacaoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const primeiroItem = 0
  const [aberta, definirAberta] = useState(false)
  const [valorMultaJuros, definirValorMultaJuros] = useState<number>(0)
  const [valorParcela, definirValorParcela] = useState<number>()
  const [renegociacoes, definirRenegociacoes] = useState<Renegociacao[]>([])
  const [, definirAtualizouCheckVencimento] = useState<boolean>(false)
  const [atualizouCampoDesconto, definirAtualizouCampoDesconto] = useState<
    CampoDesconto[]
  >([])

  const [
    valorQuantidadeMensagem,
    definirValorQuantidadeMensagem
  ] = useState<number>(0)

  const atualizarValorCampoFormulario = (campo: string, valor: any) => {
    formRef?.current.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const atualizarValorFormulario = (campo: string, valor: any) => {
    if (!valor) return
    atualizarValorCampoFormulario(campo, valor)
  }

  const fechar = () => {
    definirAberta(false)
    definirRenegociacoes([])
  }

  const abrir = (quantidade = 0, valorParcela = 0, valorMultaJuros = 0) => {
    definirAberta(true)
    definirValorParcela(valorParcela)
    definirValorMultaJuros(valorMultaJuros)
    definirValorQuantidadeMensagem(quantidade)

    definirRenegociacoes([
      {
        quantidade: quantidade,
        valorParcela: valorParcela
      } as Renegociacao
    ])
  }

  useEffect(() => {
    atualizarValorFormulario(
      'renegociacoes[0].quantidade',
      valorQuantidadeMensagem
    )
    atualizarValorFormulario('renegociacoes[0].valorParcela', valorParcela)
    atualizarValorFormulario(
      'renegociacoes[0].valorTotal',
      valorParcela * valorQuantidadeMensagem
    )
    atualizarValorFormulario(
      'renegociacoes[0].calculoTotal[0]',
      `${valorQuantidadeMensagem}X ${formatadores.moeda(
        valorParcela
      )} = ${formatadores.moeda(valorQuantidadeMensagem * valorParcela)}`
    )
  }, [aberta])

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  const adicionarNovaRenegociacao = () => {
    definirRenegociacoes(renegociacoes => {
      const listaRenegociacoes = [
        ...renegociacoes,
        {
          quantidade: 0,
          valorParcela: valorParcela
        } as Renegociacao
      ]
      return listaRenegociacoes
    })
  }

  const atualizarCampoValorParcela = async (
    valorParcela: number,
    index: number
  ) => {
    atualizarValorCampoFormulario(
      `renegociacoes[${index}].valorParcela`,
      valorParcela
    )
  }

  const alterarObservacao = (index: number) => {
    let mensagem
    const tipoDesconto = Number(
      formRef?.current?.getFieldValue(
        `renegociacoes[${index}].tipoValorDesconto[${index}]`
      )
    )

    const valorDesconto = Number(
      formRef?.current?.getFieldValue(`renegociacoes[${index}].valorDesconto`)
    )

    if (valorDesconto > 0) {
      if (tipoDesconto === TipoDescontoCalculo.Valor) {
        mensagem = `Desconto de R$ ${valorDesconto} concedido na renegociação realizada dia ${FuncoesDataHora.dataFormatadaComBarras(
          new Date()
        )}`
      } else {
        mensagem = `Desconto de ${valorDesconto}% concedido na renegociação realizada dia ${FuncoesDataHora.dataFormatadaComBarras(
          new Date()
        )}`
      }

      atualizarValorCampoFormulario(
        `renegociacoes[${index}].observacao`,
        mensagem
      )
    } else {
      atualizarValorCampoFormulario(`renegociacoes[${index}].observacao`, '')
    }
  }

  const calcularValorTotal = (index: number) => {
    const quantidadeParcelas = Number(
      formRef?.current?.getFieldValue(`renegociacoes[${index}].quantidade`)
    )
    const valorParcela = Number(
      formRef?.current?.getFieldValue(`renegociacoes[${index}].valorParcela`)
    )

    if (quantidadeParcelas && valorParcela) {
      atualizarValorFormulario(
        `renegociacoes[${index}].calculoTotal[${index}]`,
        `${quantidadeParcelas}X ${formatadores.moeda(
          valorParcela
        )} = ${formatadores.moeda(quantidadeParcelas * valorParcela)}`
      )
    }
  }

  const obterValorDesconto = async (index: number) => {
    try {
      const quantidadeParcelas = Number(
        formRef?.current?.getFieldValue(`renegociacoes[${index}].quantidade`)
      )
      const valorTotal = Number(
        formRef?.current?.getFieldValue(`renegociacoes[${index}].valorTotal`)
      )

      let tipoDesconto
      let valorDesconto

      valorDesconto = Number(
        formRef?.current?.getFieldValue(`renegociacoes[${index}].valorDesconto`)
      )

      tipoDesconto = Number(
        formRef?.current?.getFieldValue(
          `renegociacoes[${index}].tipoValorDesconto[${index}]`
        )
      )

      if (!tipoDesconto) tipoDesconto = 0
      if (!valorDesconto) valorDesconto = 0

      if (!quantidadeParcelas || !valorTotal) {
        return
      }

      const resposta = await Api.CalcularValorDescontoRenegociacao(
        quantidadeParcelas,
        valorTotal,
        tipoDesconto,
        valorDesconto
      )

      atualizarCampoValorParcela(resposta, index)
      calcularValorTotal(index)
    } catch (erro) {
      toast('Houve um erro ao calcular valor desconto!')
    }
  }

  const acaoSucessoForm = async (dados: ModalRenegociacaoDados) => {
    let renegociacoes = []

    dados.renegociacoes?.forEach(renegociacao => {
      renegociacoes = [
        ...renegociacoes,
        {
          primeiroVencimento: renegociacao.primeiroVencimento,
          quantidadeParcelas: renegociacao.quantidade,
          valorParcelas: renegociacao.valorParcela,
          observacao: renegociacao.observacao
        }
      ]
    })

    await acaoPrimaria(renegociacoes)
    fechar()
    formRef.current?.reset()
  }

  const limparValorDesconto = (index: number) => {
    atualizarValorCampoFormulario(`renegociacoes[${index}].valorDesconto`, '')
  }

  const considerarMultaJuros = async (index: number) => {
    const valorTotalCampo = Number(
      formRef?.current?.getFieldValue(`renegociacoes[${index}].valorTotal`)
    )
    const checkJuros = formRef?.current?.getFieldValue(
      `renegociacoes[${index}].ignorarMultaJuros[${index}]`
    )
    const quantidadeParcelas = Number(
      formRef?.current?.getFieldValue(`renegociacoes[${index}].quantidade`)
    )

    let novoValor = valorTotalCampo

    if (checkJuros) {
      novoValor = valorTotalCampo - valorMultaJuros
    } else {
      novoValor = valorTotalCampo + valorMultaJuros
    }

    await obterValorDesconto(index)
    atualizarValorFormulario(`renegociacoes[${index}].valorTotal`, novoValor)
    atualizarValorFormulario(
      `renegociacoes[${index}].valorParcela`,
      novoValor / quantidadeParcelas
    )
    atualizarValorFormulario(
      `renegociacoes[${index}].calculoTotal[${index}]`,
      `${quantidadeParcelas}X ${formatadores.moeda(
        novoValor / quantidadeParcelas
      )} = ${formatadores.moeda(
        quantidadeParcelas * (novoValor / quantidadeParcelas)
      )}`
    )
  }

  const atualizarVencimento = async (index: number) => {
    const vencimentoAnterior = formRef?.current?.getFieldValue(
      `renegociacoes[${index - 1}].primeiroVencimento`
    )
    const quantidadeParcelasAnterior = formRef?.current?.getFieldValue(
      `renegociacoes[${index - 1}].quantidade`
    )

    if (vencimentoAnterior) {
      const novaData = new Date(vencimentoAnterior)
      const dataMesSeguinte = new Date(
        novaData.setMonth(novaData.getMonth() + quantidadeParcelasAnterior)
      )
      const dataMesSeguinteFormatada = FuncoesDataHora.dataFormatadaFormatoAmericano(
        dataMesSeguinte
      )

      atualizarValorFormulario(
        `renegociacoes[${index}].primeiroVencimento`,
        dataMesSeguinteFormatada
      )
    }
  }

  const desabilitarPrimeiroVencimento = (index: number) => {
    const checkVencimentoAutomatico = formRef?.current?.getFieldValue(
      `renegociacoes[${index}].vencimentoAutomatico[${index}]`
    )
    if (checkVencimentoAutomatico) return true
    return false
  }

  const alterarCampoDesconto = (index?: number) => {
    const tipoDesconto = Number(
      formRef?.current?.getFieldValue(
        `renegociacoes[${index}].tipoValorDesconto[${index}]`
      )
    )

    if (tipoDesconto === TipoDescontoCalculo.Porcentagem) return true
    return false
  }

  useEffect(() => {
    const atualizados = atualizouCampoDesconto.filter(x => x.atualizou)
    for (const atualizado of atualizados) {
      limparValorDesconto(atualizado.index)
      obterValorDesconto(atualizado.index)
      alterarObservacao(atualizado.index)
    }

    if (atualizados?.length > 0) {
      definirAtualizouCampoDesconto([])
    }
  }, [atualizouCampoDesconto])

  return (
    <>
      <ModalBase id="modal-renegociacoes" aberta={aberta} backdrop={backdrop}>
        <ContainerModal>
          <Scrollbars>
            <Container>
              <TituloPrincipal>Renegociação</TituloPrincipal>
              <FormUnform
                ref={formRef}
                acaoSucesso={acaoSucessoForm}
                schema={schema}
              >
                <Conteudo>
                  {renegociacoes.map((valor, index) => {
                    return (
                      <Scope key={index} path={`renegociacoes[${index}]`}>
                        <Linha>
                          <Coluna>
                            <NumeroInputUnform
                              name="quantidade"
                              label="Quantidade"
                              obrigatorio
                              onChange={event => {
                                obterValorDesconto(index)
                                definirValorQuantidadeMensagem(
                                  Number(event.target.value)
                                )
                                calcularValorTotal(index)
                              }}
                            />
                          </Coluna>
                          <Coluna>
                            <MoedaInputUnform
                              name="valorTotal"
                              label="Valor Total"
                              obrigatorio
                              onChange={() => {
                                obterValorDesconto(index)
                              }}
                            />
                          </Coluna>
                        </Linha>
                        <Linha>
                          <Coluna>
                            <SeletorUnform
                              name={`tipoValorDesconto[${index}]`}
                              label="Desconto"
                              tipo="radio"
                              seletores={[
                                {
                                  id: TipoDescontoCalculo.Valor,
                                  texto: 'Valor'
                                },
                                {
                                  id: TipoDescontoCalculo.Porcentagem,
                                  texto: 'Porcentagem'
                                }
                              ]}
                              valorAlterado={() => {
                                definirAtualizouCampoDesconto(old => {
                                  const filtrado = old.filter(
                                    x => x.index !== index
                                  )
                                  return [
                                    ...filtrado,
                                    { index, atualizou: true }
                                  ]
                                })
                              }}
                            />
                          </Coluna>
                          <Coluna>
                            {alterarCampoDesconto(index) ? (
                              <NumeroInputUnform
                                name="valorDesconto"
                                label="Valor Desconto"
                                max={99}
                                maxLength={2}
                                min={1}
                                onChange={() => {
                                  obterValorDesconto(index)
                                  alterarObservacao(index)
                                }}
                              />
                            ) : (
                              <MoedaInputUnform
                                name="valorDesconto"
                                label="Valor Desconto"
                                onChange={() => {
                                  obterValorDesconto(index)
                                  alterarObservacao(index)
                                }}
                              />
                            )}
                          </Coluna>
                        </Linha>
                        <CheckboxWrapper>
                          <CheckboxUnform
                            id={`chk-ignorar-multa-juros[${index}]`}
                            name={`ignorarMultaJuros[${index}]`}
                            disabled={valorMultaJuros <= 0}
                            onChange={() => {
                              considerarMultaJuros(index)
                            }}
                          />
                          <label htmlFor="chk-ignorar-multa-juros">
                            Ignorar multa e juros
                          </label>
                        </CheckboxWrapper>
                        <MoedaInputUnform
                          name="valorParcela"
                          label="Valor da Parcela"
                          obrigatorio
                          disabled
                        />
                        <TextoSubTotal>
                          <InputUnform
                            id={`calculoTotal[${index}]`}
                            name={`calculoTotal[${index}]`}
                            disabled
                          />
                        </TextoSubTotal>
                        <Linha>
                          <DataInputUnform
                            id="ipt-primeiro-vencimento"
                            name="primeiroVencimento"
                            obrigatorio
                            disabled={desabilitarPrimeiroVencimento(index)}
                            label={
                              <>
                                Primeiro Vencimento
                                <IconeLabel
                                  data-tip="Caso haja mais de uma parcela, os próximos vencimentos ocorrerão nos meses subsequentes"
                                  data-for={`tooltip-vencimento[${index}]`}
                                >
                                  {IconeInformacao}
                                </IconeLabel>
                              </>
                            }
                          />
                          {index > primeiroItem && (
                            <CheckboxWrapper>
                              <CheckboxUnform
                                id={`chk-vencimento-automatico[${index}]`}
                                name={`vencimentoAutomatico[${index}]`}
                                onChange={() => {
                                  atualizarVencimento(index)
                                  definirAtualizouCheckVencimento(old => !old)
                                }}
                              />
                              <label htmlFor="chk-vencimento-automatico">
                                Primeiro vencimento após término de negociação
                                anterior
                              </label>
                            </CheckboxWrapper>
                          )}
                        </Linha>
                        <TextAreaUnform
                          id="ipt_observacao"
                          name="observacao"
                          label="Observação"
                        />
                        <Tooltip
                          id={`tooltip-vencimento[${index}]`}
                          place="right"
                          textColor={Cores.PRETO}
                          backgroundColor={Cores.CINZA_1_CLARO}
                        />
                      </Scope>
                    )
                  })}
                  <ContainerBotao>
                    <Botao
                      type="button"
                      onClick={adicionarNovaRenegociacao}
                      tema="Link"
                      elemento={<>{IconeAdicionar} Inserir outra negociação</>}
                    />
                  </ContainerBotao>
                </Conteudo>
                <ContainerBotoes>
                  <Botao
                    texto="Cancelar"
                    tema="Secundario"
                    type="button"
                    onClick={() => fechar()}
                  />
                  <Botao texto="Salvar" />
                </ContainerBotoes>
              </FormUnform>
            </Container>
          </Scrollbars>
        </ContainerModal>
      </ModalBase>
    </>
  )
}

export const ModalRenegociacao = forwardRef(Modal)
