import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { Input } from '../../input'
import { Componente, Erro } from './styles'
import { InputUnformProps } from './tipos'

export const InputUnform: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & InputUnformProps
> = ({
  name,
  label,
  ladoIcone,
  semMargemAbaixo,
  className,
  sucesso,
  mascara,
  bloquearCaracteresEspeciais,
  naoDeveLimpar,
  esconder,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name || '')

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames(className, {
        semMargemAbaixo
      })}
      esconder={esconder}
    >
      <Input
        {...rest}
        mascara={mascara}
        name={name}
        defaultValue={defaultValue}
        ref={inputRef}
        erro={!!error}
        sucesso={sucesso}
        className={classNames({
          error
        })}
        label={label}
        ladoIcone={ladoIcone}
        onKeyPress={() => {
          !naoDeveLimpar && clearError()
        }}
        bloquearCaracteresEspeciais={bloquearCaracteresEspeciais}
      />
      <Erro id={`erro-${rest.id}`} sucesso={sucesso}>
        {error}
      </Erro>
    </Componente>
  )
}
