import { Tabela } from 'src/componentes/tabela'
import styled from 'styled-components'

export const TabelaComEstilos = styled(Tabela)`
  & > table {
    & > tbody > tr {
      & > td:nth-last-of-type(1) {
        display: flex;
      }
    }
  }
`
