import { useState } from 'react'

import { ControladorDeConteudosConfiguracao } from './controlador-de-conteudos.config'
import { ControladorDeConteudos } from './controlador-de-conteudos.interface'

export function useControladorDeConteudos<TConteudo>(
  conteudosIniciais: Array<TConteudo>,
  configuracao: ControladorDeConteudosConfiguracao = {
    disposicaoInicial: 3,
    passos: 3
  }
): ControladorDeConteudos<TConteudo> {
  const [passos, definirPassos] = useState<number>(configuracao.passos)
  const [conteudos, definirConteudos] = useState(conteudosIniciais)
  const [acumulador, definirAcumulador] = useState(
    configuracao.disposicaoInicial
  )

  const atualizar = (conteudos: Array<TConteudo>, passos: number): void => {
    definirConteudos(conteudos)
    definirPassos(passos)
  }

  const tamanho = (): number => {
    return conteudos.length
  }

  const exibir = (): Array<TConteudo> => {
    return conteudos.slice(0, acumulador)
  }

  const proximo = (): void => {
    if (acumulador < tamanho()) {
      definirAcumulador(Math.min(acumulador + passos, tamanho()))
    }
  }

  const concluido = (): boolean =>
    tamanho() <= passos || acumulador === tamanho()

  const obterAcumulador = (): number => {
    return +acumulador
  }

  const obterPassos = (): number => {
    return +passos
  }

  return {
    exibir,
    proximo,
    concluido,
    obterAcumulador,
    obterPassos,
    tamanho,
    atualizar
  }
}
