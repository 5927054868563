import React, { forwardRef } from 'react'

import assinatura from 'src/assets/imagens/declaracao-assinatura-fink.png'
import header from 'src/assets/imagens/header-logo.png'
import { formatarCpf } from 'src/componentes'
import { mesesDoAno } from 'src/componentes/funcoes/data-hora'
import { DeclaracaoResposta } from 'src/dto'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { ModalidadeCursoDeclaracaoPorExtenso } from 'src/tipos'

import { Declaracoes } from '../tipos'
import {
  AssinaturaImg,
  AssinaturaText,
  Container,
  ContainerDeDescricao,
  ContainerAssinatura,
  Linha,
  Header,
  ParagrafoDaColuna,
  ParagrafoDaDataAtual,
  ParagrafoPrincipal,
  PrimeiraColuna,
  SegundaColuna,
  TerceiraColuna,
  Titulo
} from './styles'

const dataAtual = new Date()

const titulo = (tipoDeclaracao: Declaracoes) => {
  return tipoDeclaracao === Declaracoes.matricula
    ? 'DECLARAÇÃO DE MATRÍCULA'
    : 'DECLARAÇÃO DE CONCLUSÃO'
}
export const Declaracao = forwardRef<
  HTMLDivElement,
  {
    tipoDeclaracao: Declaracoes
    model: DeclaracaoResposta
  }
>(({ tipoDeclaracao, model }, ref) => {
  return (
    <div ref={ref}>
      <Container>
        <Header src={header} />
        <ContainerDeDescricao>
          <Titulo>{titulo(tipoDeclaracao)}</Titulo>
          {model !== null && (
            <>
              <ParagrafoPrincipal>
                {tipoDeclaracao === Declaracoes.matricula ? (
                  <>
                    A Faculdade IPGS, credenciada pela portaria do MEC n°
                    656/2018, declara para devidos fins que o(a) aluno(a){' '}
                    <b>{model.nomeApresentacao}</b>,{' '}
                    {model.dataNascimento !== null ? (
                      <>
                        nascido(a) ao(s){' '}
                        {new Date(model.dataNascimento).getDate()} dia(s) do mês
                        de{' '}
                        {mesesDoAno[new Date(model.dataNascimento).getMonth()]}{' '}
                        de {new Date(model.dataNascimento).getFullYear()},
                      </>
                    ) : (
                      <></>
                    )}{' '}
                    portador(a) do CPF: {formatarCpf(model.cpf)}, está
                    devidamente matriculado(a) no Curso de{' '}
                    <b>
                      {
                        ModalidadeCursoDeclaracaoPorExtenso[
                          model.modalidadeCurso
                        ]
                      }{' '}
                      em {model.nomeTurma}
                    </b>
                    , com carga horária total de {model.cargaHoraria} horas e
                    teve início em{' '}
                    <b>
                      {formatadores.dataLocaleString(`${model.dataInicio}`)}
                    </b>{' '}
                    e previsão de término em{' '}
                    <b>
                      {formatadores.dataLocaleString(`${model.dataTermino}`)}
                    </b>
                  </>
                ) : (
                  <>
                    Atestado para os devidos fins que{' '}
                    <b>{model.nomeApresentacao}</b> concluiu com aprovação o
                    curso de Pós-graduação <i>Lato Sensu</i> em{' '}
                    <b>{model.nomeCurso}</b>, promovido pela Faculdade IPGS,
                    credenciada pela Portaria MEC - parecer CNE/CES 227/2018,
                    com validade nacional e cumprem as disposições vigentes da
                    resolução CNE/CES nº 1, de 03 de abril de 2001 e resolução
                    CNE/CES nº 1, de 08 de junho de 2007, do Ministério da
                    Educação (MEC), com início em{' '}
                    {formatadores.dataLocaleString(`${model.dataInicio}`)} e
                    término em{' '}
                    {formatadores.dataLocaleString(`${model.dataTermino}`)},
                    totalizando {model.cargaHoraria} horas.
                  </>
                )}
              </ParagrafoPrincipal>
              <ParagrafoDaDataAtual>
                Porto Alegre, {dataAtual.getDate()} de{' '}
                {mesesDoAno[dataAtual.getMonth()]} do ano de{' '}
                {dataAtual.getFullYear()}
              </ParagrafoDaDataAtual>
              <ContainerAssinatura>
                <AssinaturaImg src={assinatura} />
                <AssinaturaText>
                  <p>
                    <b>Maria Eduarda Fink</b>
                  </p>
                  {tipoDeclaracao === Declaracoes.matricula ? (
                    <p>Coordenadora Geral - IPGS </p>
                  ) : (
                    <p>Coordenadora Geral - Faculdade iPGS </p>
                  )}
                </AssinaturaText>
              </ContainerAssinatura>
              <Linha>
                <PrimeiraColuna>
                  <ParagrafoDaColuna>
                    Av. Cristovão Colombo, 203
                    <br />
                    Floresta
                  </ParagrafoDaColuna>
                </PrimeiraColuna>
                <SegundaColuna>
                  <ParagrafoDaColuna>
                    Porto Alegre | RS
                    <br />
                    CEP 90560-003
                  </ParagrafoDaColuna>
                </SegundaColuna>
                <TerceiraColuna>
                  <ParagrafoDaColuna>
                    51 3062.9322
                    <br />
                    ipgs@ipgs.com.br
                  </ParagrafoDaColuna>
                </TerceiraColuna>
              </Linha>
            </>
          )}
        </ContainerDeDescricao>
      </Container>
    </div>
  )
})
