import React from 'react'

import { FuncoesDataHora } from 'src/componentes'

import { MatriculaAluno } from '../../tipos'
import { ColunaInformacoesAcesso } from './styles'

interface AbaInformacoesAcessoProps {
  informacoes: MatriculaAluno
}

export const AbaInformacoesAcesso: React.FC<AbaInformacoesAcessoProps> = ({
  informacoes
}) => {
  return (
    <ColunaInformacoesAcesso>
      <p>IP: {informacoes.ipAceiteTermos ?? 'Não existe aceite.'}</p>
      <p>
        Data Aceite Termos:{' '}
        {informacoes.dataHoraAceiteTermos
          ? FuncoesDataHora.dataToLocaleString(informacoes.dataHoraAceiteTermos)
          : 'Não existe aceite.'}
      </p>
      <p>
        Data Último Acesso:{' '}
        {informacoes.dataUltimoAcesso
          ? FuncoesDataHora.dataToLocaleString(informacoes.dataUltimoAcesso)
          : 'Não existe acesso.'}
      </p>
      <p>
        Data Fim Turma:{' '}
        {informacoes?.dataFimTurma
          ? FuncoesDataHora.dataToLocaleString(informacoes.dataFimTurma)
          : 'Não existe data de finalização.'}
      </p>
      <p>
        Data Fim da Prorrogação:{' '}
        {informacoes?.dataFimProrrogacao
          ? FuncoesDataHora.dataToLocaleString(informacoes.dataFimProrrogacao)
          : 'Não existe data de prorrogação.'}
      </p>
    </ColunaInformacoesAcesso>
  )
}
