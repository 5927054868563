import React from 'react'

import { Titulo } from './styles'

export const SubTitulo: React.FC<{
  id?: string
  texto: string
  className?: string
}> = ({ id, texto, className }) => (
  <Titulo className={className} id={id}>
    {texto}
  </Titulo>
)
