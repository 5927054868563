import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  DataInputUnform,
  SelectUnform
} from 'src/componentes'
import { Tabela, TabelaRef, TabelaResposta } from 'src/componentes/tabela'
import { SelectOpcaoCursos } from 'src/dto'
import { Api } from 'src/servicos'
import { RequisitarListaTurmasPorTexto } from 'src/servicos/api'
import { TipoOrdenacao } from 'src/tipos'

import { COLUNAS_FINALIZACAO } from './componentes'
import { OPCOES_SITUACAO_FINANCEIRA } from './opcoes'
import { Form, Container, Campo, Dados, ContainerExportar } from './styles'
import { DadosPesquisa } from './tipos'

export const PaginaFinalizacao: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)
  const [selecionadoOpcaoTcc, definirSelecionadoOpcaoTcc] = useState('')

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioFinalizacao(
      {
        nomeAluno: dadosPesquisa?.nomeAluno,
        cursosId: dadosPesquisa?.curso,
        turmasId: dadosPesquisa?.turma,
        dataInicioMatricula: dadosPesquisa?.dataInicioMatricula,
        dataFimMatricula: dadosPesquisa?.dataFimMatricula,
        dataInicioRecuperacao: dadosPesquisa?.dataInicioRecuperacao,
        dataFimRecuperacao: dadosPesquisa?.dataFimRecuperacao,
        situacoes: dadosPesquisa?.situacoes,
        dataInicioOpcaoTcc: dadosPesquisa?.dataInicioOpcaoTcc,
        dataFimOpcaoTcc: dadosPesquisa?.dataFimOpcaoTcc,
        opcaoTcc: dadosPesquisa?.opcaoTcc,
        dataInicioConclusao: dadosPesquisa?.dataInicioConclusao,
        dataFimConclusao: dadosPesquisa?.dataFimConclusao
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioFinalizacao({
        nomeAluno: dadosPesquisa?.nomeAluno,
        cursosId: dadosPesquisa?.curso,
        turmasId: dadosPesquisa?.turma,
        dataInicioMatricula: dadosPesquisa?.dataInicioMatricula,
        dataFimMatricula: dadosPesquisa?.dataFimMatricula,
        dataInicioRecuperacao: dadosPesquisa?.dataInicioRecuperacao,
        dataFimRecuperacao: dadosPesquisa?.dataFimRecuperacao,
        situacoes: dadosPesquisa?.situacoes,
        dataInicioOpcaoTcc: dadosPesquisa?.dataInicioOpcaoTcc,
        dataFimOpcaoTcc: dadosPesquisa?.dataFimOpcaoTcc,
        opcaoTcc: dadosPesquisa?.opcaoTcc,
        dataInicioConclusao: dadosPesquisa?.dataInicioConclusao,
        dataFimConclusao: dadosPesquisa?.dataFimConclusao
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const requisitarListaCursosOutrosPorTextoFiltro = async (
    nome: string
  ): Promise<SelectOpcaoCursos[]> => {
    const result = await Api.RequisitarListaCursoOutros(nome)
    return result.map(x => ({ id: x.id, texto: x.nome }))
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Finalização"
          atalhos={[
            {
              texto: 'Relatórios Acadêmicos'
            },
            {
              texto: 'Finalização'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                multiplo
                buscarPorTexto={requisitarListaCursosOutrosPorTextoFiltro}
                buscarPorId={requisitarListaCursosOutrosPorTextoFiltro}
              />
            </Campo>
            <Campo style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                multiplo
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="dataInicioConclusao"
                label="Data Início Conclusão"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataFimConclusao"
                label="Data Fim Conclusão"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataInicioMatricula"
                label="Data Início Matrícula"
              />
            </Campo>
            <Campo>
              <DataInputUnform
                name="dataFimMatricula"
                label="Data Fim Matrícula"
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="dataInicioRecuperacao"
                label="Data Início Recuperação"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataFimRecuperacao"
                label="Data Fim Recuperação"
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_situacao_financeira"
                name="situacoes"
                label="Situação Matricula"
                iconeFechar
                multiplo
                opcoes={OPCOES_SITUACAO_FINANCEIRA}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_opcao_tcc"
                name="opcaoTcc"
                label="Opção TCC"
                iconeFechar
                valorAlterado={e => definirSelecionadoOpcaoTcc(e?.id)}
                opcoes={[
                  {
                    id: 'sim',
                    texto: 'Sim'
                  },
                  {
                    id: 'nao',
                    texto: 'Não'
                  }
                ]}
              />
            </Campo>
          </Container>
          {selecionadoOpcaoTcc === 'sim' && (
            <Container>
              <Campo mr>
                <DataInputUnform
                  name="dataInicioOpcaoTcc"
                  label="Data Início Opção TCC"
                />
              </Campo>
              <Campo>
                <DataInputUnform
                  name="dataFimOpcaoTcc"
                  label="Data Fim Opção TCC"
                />
              </Campo>
            </Container>
          )}
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={COLUNAS_FINALIZACAO}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaFinalizacao />
  </ErroLayoutContainer.Provider>
)
