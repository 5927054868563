import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useMemo
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  FormUnform,
  IconeInformacao,
  ModalBase,
  ModalBaseProps,
  ModalRef,
  NumeroInputUnform,
  MoedaInputUnform,
  TextAreaUnform,
  formatarValorMoeda,
  CheckboxUnform,
  DataInputUnform,
  SelectUnform,
  SelectOpcao
} from 'src/componentes'
import { TipoRecorrencia, TipoRecorrenciaAcaoPorExtenso } from 'src/tipos'

import {
  TipoCobrancaAcao,
  TipoCobrancaAcaoPorExtenso,
  DadosNovaCompra
} from '../../../tipos'
import { formatadores } from '../../formatadores'
import { schema } from './schema'
import {
  ContainerDosBotoes,
  ContainerModal,
  TituloPrincipal,
  Linha,
  Coluna,
  IconeLabel,
  TextoSubTotal,
  ContainerInfo,
  ContainerCheckbox,
  Opcao,
  OpcaoDados,
  IconeLabelExcluir,
  CorpoModal
} from './styles'

export interface ModalNovaCompraRef extends Omit<ModalRef, 'abrir'> {
  abrir: (
    excluirHabilitado: boolean,
    possuiRecorrencia?: boolean,
    tipoCobrancaAcao?: TipoCobrancaAcao
  ) => void
}

export type ModalNovaCompraProps = Omit<ModalBaseProps, 'acaoPrimaria'> & {
  acaoPrimaria: (dados: DadosNovaCompra) => Promise<void>
}

const TIPOS_COBRANCA_SELECT = [
  {
    id: TipoCobrancaAcao[TipoCobrancaAcao.Curso],
    texto: TipoCobrancaAcaoPorExtenso[TipoCobrancaAcao.Curso]
  },
  {
    id: TipoCobrancaAcao[TipoCobrancaAcao.TCC],
    texto: TipoCobrancaAcaoPorExtenso[TipoCobrancaAcao.TCC]
  },
  {
    id: TipoCobrancaAcao[TipoCobrancaAcao.Recuperacao],
    texto: TipoCobrancaAcaoPorExtenso[TipoCobrancaAcao.Recuperacao]
  },
  {
    id: TipoCobrancaAcao[TipoCobrancaAcao.Prorrogacao],
    texto: TipoCobrancaAcaoPorExtenso[TipoCobrancaAcao.Prorrogacao]
  }
] as SelectOpcao[]

const TIPOS_RECORRENCIA_SELECT = [
  {
    id: TipoRecorrencia[TipoRecorrencia.NegociacaoEspecial],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.NegociacaoEspecial]
  },
  {
    id: TipoRecorrencia[TipoRecorrencia.TrocaParcelamento],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.TrocaParcelamento]
  },
  {
    id: TipoRecorrencia[TipoRecorrencia.CartaoVencido],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.CartaoVencido]
  },
  {
    id: TipoRecorrencia[TipoRecorrencia.Transferencia],
    texto: TipoRecorrenciaAcaoPorExtenso[TipoRecorrencia.Transferencia]
  }
] as SelectOpcao[]

const Modal: React.ForwardRefRenderFunction<
  ModalNovaCompraRef,
  ModalNovaCompraProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const [aberta, definirAberta] = useState(false)
  const [valorParcelaForm, definirValorParcelaForm] = useState('')
  const [quantidade, definirQuantidade] = useState(0)
  const [excluirHabilitado, definirExcluirHabilitado] = useState(false)
  const [desabilitarSalvar, definirDesabilitarSalvar] = useState(false)
  const valorParcela = valorParcelaForm && formatarValorMoeda(valorParcelaForm)
  const [possuiRecorrencia, definirPossuiRecorrencia] = useState(false)

  const [
    tipoCobrancaAcao,
    definirTipoCobrancaAcao
  ] = useState<TipoCobrancaAcao>(null)

  const [permitirParcelamento, definirPermitirParcelamento] = useState(false)
  const [novaRecorrencia, definirNovaRecorrencia] = useState(false)

  const fechar = () => {
    formRef.current?.reset()
    definirAberta(false)
    definirNovaRecorrencia(false)
    definirPermitirParcelamento(false)
  }

  const abrir = (
    excluirHabilitado: boolean,
    possuiRecorrencia?: boolean,
    tipoCobrancaAcao?: TipoCobrancaAcao
  ) => {
    definirAberta(true)
    definirPossuiRecorrencia(possuiRecorrencia ?? false)
    definirExcluirHabilitado(excluirHabilitado)
    definirDesabilitarSalvar(false)
    definirTipoCobrancaAcao(tipoCobrancaAcao)

    if (tipoCobrancaAcao) {
      formRef?.current?.setFieldValue('tipoCobranca', tipoCobrancaAcao)
    }
  }

  useImperativeHandle<ModalNovaCompraRef, ModalNovaCompraRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: DadosNovaCompra) => {
    await definirDesabilitarSalvar(true)
    await acaoPrimaria(dados)
    fechar()
  }

  const dadosIniciais = useMemo(() => {
    return {
      quantidade: null,
      valorParcela: null,
      observacao: null,
      tipoCobranca: null
    }
  }, [tipoCobrancaAcao])

  const novaRecorrenciaVisivel = useMemo(
    () => !possuiRecorrencia && tipoCobrancaAcao === TipoCobrancaAcao.Curso,
    [possuiRecorrencia, tipoCobrancaAcao]
  )

  return (
    <ModalBase id="modal-nova-compra" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <FormUnform
            ref={formRef}
            acaoSucesso={acaoSucessoForm}
            schema={schema}
            dadosIniciais={dadosIniciais}
          >
            <TituloPrincipal>Nova Compra</TituloPrincipal>
            <Linha>
              <Coluna>
                <SelectUnform
                  id="slct_tipoCobranca"
                  name="tipoCobranca"
                  opcoes={TIPOS_COBRANCA_SELECT}
                  label={'Tipo de Parcela'}
                  obrigatorio
                  disabled={excluirHabilitado}
                  valorAlterado={valor => {
                    if (valor) {
                      definirTipoCobrancaAcao(valor.id as TipoCobrancaAcao)
                    } else {
                      definirTipoCobrancaAcao(null)
                    }
                  }}
                  deveLimpar={() => !aberta}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna>
                <NumeroInputUnform
                  name="quantidade"
                  label="Quantidade"
                  obrigatorio
                  onChange={event => {
                    definirQuantidade(Number(event.target.value))
                  }}
                />
              </Coluna>
              <Coluna>
                <MoedaInputUnform
                  name="valorParcela"
                  label="Valor Parcela"
                  obrigatorio
                  onChange={event => {
                    definirValorParcelaForm(event.target.value)
                  }}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna>
                <DataInputUnform
                  id="ipt-primeiro-vencimento-nova-compra"
                  name="primeiroVencimento"
                  obrigatorio
                  label={
                    <>
                      Primeiro Vencimento
                      <IconeLabel
                        data-tip="Caso haja mais de uma parcela, os próximos vencimentos ocorrerão nos meses subsequentes"
                        data-for="tooltip-vencimento"
                      >
                        {IconeInformacao}
                      </IconeLabel>
                    </>
                  }
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna>
                {!excluirHabilitado && (
                  <IconeLabelExcluir
                    data-tip="Selecione as parcelas que deseja excluir"
                    data-for="tooltip-excluir"
                  >
                    {IconeInformacao}{' '}
                    <p style={{ opacity: '0.5' }}>
                      Selecione as parcelas que deseja excluir
                    </p>
                  </IconeLabelExcluir>
                )}
                <ContainerCheckbox>
                  <Opcao>
                    <OpcaoDados>
                      <CheckboxUnform
                        id="chk-excluir-cobrancas"
                        name="excluirCobrancas"
                        disabled={!excluirHabilitado}
                        texto={'Excluir Parcela'}
                      />
                    </OpcaoDados>
                  </Opcao>
                </ContainerCheckbox>
                <ContainerCheckbox>
                  <Opcao>
                    <OpcaoDados>
                      <CheckboxUnform
                        id="chk-permitir-parcelamento-doze-vezes"
                        name="permitirParcelamento"
                        texto={'Permitir parcelamento em 12x'}
                        disabled={novaRecorrencia}
                        onChange={() => {
                          definirPermitirParcelamento(old => {
                            const novoValor = !old
                            if (novoValor) definirNovaRecorrencia(false)
                            return novoValor
                          })
                        }}
                        checked={permitirParcelamento}
                      />
                    </OpcaoDados>
                  </Opcao>
                </ContainerCheckbox>
                {novaRecorrenciaVisivel && (
                  <ContainerCheckbox>
                    <Opcao>
                      <OpcaoDados>
                        <CheckboxUnform
                          id="chk-nova-recorrencia"
                          name="novaRecorrencia"
                          checked={novaRecorrencia}
                          disabled={possuiRecorrencia || permitirParcelamento}
                          texto="Nova Recorrência"
                          onChange={() =>
                            definirNovaRecorrencia(old => {
                              const novoValor = !old

                              if (novoValor) definirPermitirParcelamento(false)
                              return novoValor
                            })
                          }
                        />
                      </OpcaoDados>
                    </Opcao>
                  </ContainerCheckbox>
                )}
                {novaRecorrencia && (
                  <SelectUnform
                    id="slct_tipoRecorrencia"
                    name="tipoRecorrencia"
                    opcoes={TIPOS_RECORRENCIA_SELECT}
                    label={'Tipo de Recorrência'}
                    obrigatorio
                    deveLimpar={() => !aberta}
                  />
                )}
              </Coluna>
            </Linha>
            <Linha>
              <Coluna>
                <ContainerInfo>
                  <TextoSubTotal>
                    {quantidade && valorParcela ? (
                      `${quantidade}X ${formatadores.moeda(
                        Number(valorParcela)
                      )} = ${formatadores.moeda(
                        quantidade * Number(valorParcela)
                      )}`
                    ) : (
                      <></>
                    )}
                  </TextoSubTotal>
                </ContainerInfo>
              </Coluna>
            </Linha>
            <Linha>
              <Coluna>
                <TextAreaUnform name="observacao" label="Observação" />
              </Coluna>
            </Linha>
            <ContainerDosBotoes>
              <Botao
                texto="Cancelar"
                tema="Secundario"
                type="button"
                onClick={() => fechar()}
              />
              <Botao
                texto="Salvar"
                id="submit-nova-compra"
                disabled={desabilitarSalvar}
              />
            </ContainerDosBotoes>
          </FormUnform>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalNovaCompra = forwardRef(Modal)
