import React from 'react'

export default (
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79883 8.57728V14.8893C8.79883 15.7627 9.50497 16.4709 10.3759 16.4709L15.8379 16.4937L19.0375 18.796V16.4937H21.2999C22.1708 16.4937 22.877 15.7855 22.877 14.912V8.60007C22.877 7.7266 22.1708 7.01839 21.2999 7.01839L10.3762 6.99561C9.50497 6.99561 8.79883 7.70381 8.79883 8.57728Z"
      fill="#006969"
    />
    <path
      d="M17.5977 1.9771V9.86706C17.5977 10.9589 16.715 11.8442 15.6263 11.8442L8.79883 11.8726L4.79936 14.7505V11.8726H1.97134C0.882682 11.8726 0 10.9874 0 9.89554V2.00558C0 0.913742 0.882682 0.0284792 1.97134 0.0284792L15.6259 0C16.715 0 17.5977 0.885262 17.5977 1.9771Z"
      fill="#009B9B"
    />
    <ellipse cx="4.91327" cy="5.85683" rx="1.19549" ry="1.18764" fill="white" />
    <ellipse cx="8.79901" cy="5.85683" rx="1.19549" ry="1.18764" fill="white" />
    <ellipse cx="12.6838" cy="5.85683" rx="1.19549" ry="1.18764" fill="white" />
  </svg>
)
