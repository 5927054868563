import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  Botao,
  ModalBase,
  ModalBaseProps,
  ModalRef,
  PdfViewer
} from 'src/componentes'

import { ContainerDosBotoes, CorpoModal } from './styles'
export interface ModalVisualizacaoDocumentoRef extends Omit<ModalRef, 'abrir'> {
  abrir: (url: string) => void
}

const ModalVisualizacaoComponent: React.ForwardRefRenderFunction<
  ModalVisualizacaoDocumentoRef,
  ModalBaseProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [documento, definirDocumento] = useState<string>(null)

  const abrir = (url: string) => {
    definirDocumento(url)
    definirAberta(true)
  }

  const fechar = () => {
    definirAberta(false)
    definirDocumento(null)
  }

  useImperativeHandle<
    ModalVisualizacaoDocumentoRef,
    ModalVisualizacaoDocumentoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-visualizacao-documento"
      backdrop={backdrop}
      aberta={aberta}
    >
      <CorpoModal>
        <ContainerDosBotoes>
          <Botao
            texto="Fechar"
            tema="Secundario"
            type="button"
            onClick={() => fechar()}
          />
        </ContainerDosBotoes>
        {documento && (
          <PdfViewer
            url={documento}
            descricaoMobile="Documento"
            nomeArquivo="documento"
            altura="100%"
          ></PdfViewer>
        )}
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalVisualizacaoDocumento = forwardRef(ModalVisualizacaoComponent)
