import {
  IconeArquivo,
  IconeDocumento,
  IconeRevelarSenha,
  IconeVideo
} from 'src/componentes'
import { StepperItem } from 'src/componentes/stepper/tipos'
import { SalaAulaMaterial, SalaAulaTipoMaterial } from 'src/tipos'

const renderizarIconeMaterial = (
  tipo: SalaAulaTipoMaterial,
  extensao: string
): JSX.Element => {
  if (tipo === SalaAulaTipoMaterial.Anexo && extensao !== '.pdf') {
    return IconeArquivo
  }

  switch (tipo) {
    case SalaAulaTipoMaterial.Anexo:
      return IconeDocumento
    case SalaAulaTipoMaterial.Video:
      return IconeVideo
    case SalaAulaTipoMaterial.HiperLink:
      return IconeRevelarSenha
  }
}

export const itemStepperMaterial = (
  material: SalaAulaMaterial,
  componente: JSX.Element,
  passo: number,
  bloqueado?: boolean,
  mensagemBloqueado?: string,
  tcc = false,
  complementar = false
): StepperItem => {
  const ehMaterialAnexoOuVideoOuUrl =
    (material.tipoMaterial === SalaAulaTipoMaterial.Anexo &&
      material.extensao === '.pdf') ||
    material.tipoMaterial === SalaAulaTipoMaterial.Video ||
    material.tipoMaterial === SalaAulaTipoMaterial.HiperLink
  const ehUrl = material.tipoMaterial === SalaAulaTipoMaterial.HiperLink

  return {
    id: material.id,
    url: material.linkMaterial ?? '',
    titulo: material.nomeMaterial,
    tipo: ehUrl ? 'url' : !ehMaterialAnexoOuVideoOuUrl ? 'download' : 'check',
    icone: renderizarIconeMaterial(material.tipoMaterial, material.extensao),
    passo: !ehMaterialAnexoOuVideoOuUrl ? undefined : passo,
    mensagemCheck: `Marcar como ${
      material.tipoMaterial === SalaAulaTipoMaterial.Anexo
        ? 'lido'
        : 'assistido'
    }`,
    componente: ehMaterialAnexoOuVideoOuUrl ? componente : undefined,
    bloqueado: bloqueado,
    mensagemBloqueado,
    tcc: tcc,
    complementar: complementar
  }
}
