import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { NotaPorAproveitamento } from 'src/tipos'

export const colunaNotaAproveitamento = (): TabelaColuna => ({
  Chave: 'notaPorAproveitamento',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <>{NotaPorAproveitamento[linha.notaPorAproveitamento]}</>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 110 }}>Nota Por Aproveitamento</div>
  )
})
