import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { StatusRecorrenciaPorExtenso } from 'src/tipos'

export const colunaStatusRecorrencia = (): TabelaColuna => ({
  Chave: 'statusRecorrencia',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{StatusRecorrenciaPorExtenso[linha.statusRecorrencia]}</span>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 140 }}>Status Recorrencia</div>
  )
})
