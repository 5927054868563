import styled, { css } from 'styled-components'

import { Cores, Medias } from '../styles'
import { SituacaoWizard } from './tipos'

interface SituacaoProps {
  situacao: SituacaoWizard | string
}

export const Lista = styled.ul`
  position: relative;
  z-index: 0;
  display: flex;
  list-style-type: none;
  justify-content: center;

  & > li {
    width: 100%;
    max-width: 234px;

    @media ${Medias.MOBILE} {
      max-width: auto;
      width: auto;
    }
  }

  input {
    display: none;
  }
`

export const Icone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${Cores.CINZA_2};

    path,
    rect {
      fill: ${Cores.CINZA_2};
    }
  }

  @media ${Medias.MOBILE} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`

export const Circle = styled.label`
  width: 63px;
  height: 63px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    bottom: -10px;
    border-right: 9px solid ${Cores.TRANSPARENTE};
    border-left: 9px solid ${Cores.TRANSPARENTE};
    border-top: 13px solid ${Cores.TRANSPARENTE};
  }

  @media ${Medias.MOBILE} {
    width: 42px;
    height: 42px;

    &::after {
      bottom: -8px;
      border-right: 6px solid ${Cores.TRANSPARENTE};
      border-left: 6px solid ${Cores.TRANSPARENTE};
      border-top: 10px solid ${Cores.TRANSPARENTE};
    }
  }
`

export const Barra = styled.div`
  width: 100%;
  height: 1px;
  transition: all 0.5s cubic-bezier(0, -0.01, 1, 0.98);
  position: absolute;
  top: 33%;
  left: calc(49% + 12px);
  z-index: 1;
  background: ${Cores.CINZA_4};
`

export const Box = styled.div<SituacaoProps>`
  position: relative;
  width: 100%;
  max-width: 234px;
  height: 96px;
  padding: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${({ situacao }) => {
    switch (situacao) {
      case SituacaoWizard.BLOQUEADO:
        return css`
          span {
            color: ${Cores.CINZA_4};
            font-weight: 300;
          }

          & > ${Circle} {
            cursor: not-allowed;
            background: ${Cores.BRANCO};
            border: 1px solid ${Cores.CINZA_4};

            & > div > div > svg {
              transition: all 0.5s linear;
              fill: ${Cores.CINZA_4};

              path,
              rect {
                fill: ${Cores.CINZA_4};
              }
            }
          }
        `
      case SituacaoWizard.PREENCHIDO:
        return css`
          span {
            color: ${Cores.PRIMARIA_1};
            font-weight: 300;
          }

          ${Circle} {
            background: ${Cores.SECUNDARIA_3_ESCURO};
            transition: all 0.15s linear;

            &:hover {
              background: ${Cores.SECUNDARIA_3_MAIS_ESCURO};

              &::after {
                transition: all 0.15s linear;
              }
            }

            &:hover ~ span {
              transition: all 0.15s linear;
              color: ${Cores.PRIMARIA_1};
            }

            & > div > div > svg {
              transition: all 0.5s linear;
              fill: ${Cores.BRANCO};

              path,
              rect {
                fill: ${Cores.BRANCO};
              }
            }
          }
        `
      case SituacaoWizard.DISPONIVEL:
        return css`
          span {
            color: ${Cores.PRIMARIA_1};
            font-weight: 300;
          }

          ${Circle} {
            background: ${Cores.BRANCO};
            border: 1px solid ${Cores.SECUNDARIA_3_ESCURO};
            transition: all 0.15s linear;

            &:hover {
              border: 1px solid ${Cores.PRETO};

              &::after {
                transition: all 0.15s linear;
              }
            }

            &:hover ~ span {
              transition: all 0.15s linear;
              color: ${Cores.PRETO};
            }

            & > div > div > svg {
              transition: all 0.5s linear;
              fill: ${Cores.SECUNDARIA_3_ESCURO};

              path,
              rect {
                fill: ${Cores.SECUNDARIA_3_ESCURO};
              }
            }
          }
        `
    }
  }}

  &.checked {
    span {
      color: ${Cores.SECUNDARIA_2};
      font-weight: 400;
    }

    ${Circle} {
      background: ${Cores.SECUNDARIA_2};
      border: none;
      transition: all 0.15s linear;

      &::after {
        transition: all 0.15s linear;
        border-top-color: ${Cores.SECUNDARIA_2};
      }

      &:hover {
        background: ${Cores.SECUNDARIA_3_CLARO};

        &::after {
          border-top-color: ${Cores.SECUNDARIA_3_CLARO};
        }
      }

      &:hover ~ span {
        transition: all 0.15s linear;
        color: ${Cores.SECUNDARIA_3_CLARO};
      }

      & > div > div > svg {
        transition: all 0.5s linear;
        fill: ${Cores.BRANCO};

        path,
        rect {
          fill: ${Cores.BRANCO};
        }
      }
    }
  }

  span {
    height: 14px;
    margin-top: 17px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    max-width: 75px;

    @media ${Medias.MOBILE} {
      font-size: 10px;
    }
  }

  @media ${Medias.MOBILE} {
    padding: 12px 0;
  }
`

export const Componente = styled.div`
  width: 100%;
  margin-bottom: 35px;

  @media ${Medias.MOBILE} {
    margin-bottom: 10px;
  }
`

export const Corpo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media ${Medias.MOBILE} {
    align-items: center;
  }
`
