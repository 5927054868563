import React from 'react'

export default (
  <svg
    width="19"
    height="23"
    viewBox="0 0 19 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.625 -0.0400391H2.375C1.06875 -0.0400391 0 0.996761 0 2.26396V20.696C0 21.9632 1.06875 23 2.375 23H16.625C17.9312 23 19 21.9632 19 20.696V2.26396C19 0.996761 17.9312 -0.0400391 16.625 -0.0400391ZM5.9375 2.26396H8.3125V8.02396L7.125 7.15996L5.9375 8.02396V2.26396ZM16.625 20.696H2.375V2.26396H3.5625V12.632L7.125 10.04L10.6875 12.632V2.26396H16.625V20.696Z"
      fill="currentColor"
      fillOpacity="0.8"
    />
  </svg>
)
