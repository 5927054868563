import React from 'react'

import styled from 'styled-components'

import { Cores } from '../styles/cores'

const Container = styled.div`
  position: relative;
`

const Badge = styled.div`
  position: absolute;
  padding: 6px;
  border-radius: 50%;
  background: ${Cores.SECUNDARIA_3};
  top: -3px;
  right: -3px;
`

const Notificacao = ({ badge }: { badge?: boolean }): JSX.Element => {
  return (
    <Container>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8594 16.4727C21.1055 16.7461 21.2285 17.0469 21.2285 17.375C21.2285 17.7305 21.1055 18.0449 20.8594 18.3184C20.6133 18.5645 20.2988 18.6875 19.916 18.6875H4.16602C3.91992 18.6875 3.6875 18.6328 3.46875 18.5234C3.27734 18.3867 3.12695 18.2227 3.01758 18.0312C2.9082 17.8125 2.85352 17.5938 2.85352 17.375C2.85352 17.0469 2.97656 16.7461 3.22266 16.4727C3.25 16.4453 3.29102 16.3906 3.3457 16.3086C3.75586 15.8984 4.07031 15.5293 4.28906 15.2012C4.53516 14.873 4.79492 14.2578 5.06836 13.3555C5.3418 12.4258 5.47852 11.3594 5.47852 10.1562C5.47852 8.57031 5.9707 7.18945 6.95508 6.01367C7.93945 4.83789 9.19727 4.09961 10.7285 3.79883V2.9375C10.7285 2.58203 10.8516 2.28125 11.0977 2.03516C11.3711 1.76172 11.6855 1.625 12.041 1.625C12.3965 1.625 12.6973 1.76172 12.9434 2.03516C13.2168 2.28125 13.3535 2.58203 13.3535 2.9375V3.79883C14.3652 3.99023 15.2676 4.38672 16.0605 4.98828C16.8535 5.58984 17.4688 6.3418 17.9062 7.24414C18.3711 8.14648 18.6035 9.11719 18.6035 10.1562C18.6035 11.3594 18.7402 12.4258 19.0137 13.3555C19.2871 14.2578 19.5332 14.873 19.752 15.2012C19.998 15.5293 20.3262 15.8984 20.7363 16.3086C20.791 16.3906 20.832 16.4453 20.8594 16.4727ZM5.64258 16.7188H18.4395C17.2363 15.1055 16.6348 12.9316 16.6348 10.1973C16.6348 10.1699 16.6348 10.1562 16.6348 10.1562C16.6348 8.89844 16.1836 7.81836 15.2812 6.91602C14.3789 6.01367 13.2988 5.5625 12.041 5.5625C10.7832 5.5625 9.70312 6.01367 8.80078 6.91602C7.89844 7.81836 7.44727 8.89844 7.44727 10.1562C7.44727 10.1562 7.44727 10.1699 7.44727 10.1973C7.44727 12.9316 6.8457 15.1055 5.64258 16.7188ZM13.8867 21.8457C13.3672 22.3652 12.752 22.625 12.041 22.625C11.3301 22.625 10.7148 22.3652 10.1953 21.8457C9.67578 21.3535 9.41602 20.7383 9.41602 20H14.666C14.666 20.7383 14.4062 21.3535 13.8867 21.8457Z"
          fill="currentColor"
        />
      </svg>
      {badge && <Badge />}
    </Container>
  )
}

export default Notificacao
