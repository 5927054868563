import React from 'react'

export default (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8047 3.73437C13.6875 3.57812 13.5234 3.46875 13.3438 3.39844C13.3516 3.53906 13.3516 3.69531 13.3047 3.84375L10.9609 11.5547C10.875 11.8359 10.5391 12 10.25 12H3.03906C2.60938 12 2.07031 11.9062 1.91406 11.4531C1.85156 11.2812 1.85938 11.2109 1.92188 11.1172C1.99219 11.0234 2.11719 11 2.21875 11H9.00781C10 11 10.2031 10.7344 10.5703 9.53125L12.7109 2.45312C12.8203 2.08594 12.7734 1.71875 12.5703 1.4375C12.375 1.16406 12.0469 0.999999 11.6797 0.999999H5.73438C5.60156 0.999999 5.46875 1.03906 5.33594 1.07031L5.34375 1.04687C4.35156 0.765625 4.28906 1.83594 3.96094 2.32031C3.83594 2.5 3.64844 2.65625 3.61719 2.80469C3.59375 2.94531 3.67188 3.07812 3.65625 3.21094C3.60938 3.625 3.29688 4.36719 3.10938 4.625C2.99219 4.78125 2.82031 4.85937 2.76563 5.03906C2.71875 5.16406 2.79688 5.34375 2.78125 5.50781C2.74219 5.875 2.46875 6.57031 2.25781 6.92969C2.17969 7.07031 2.03125 7.17969 1.99219 7.32812C1.95313 7.46094 2.03125 7.63281 1.99219 7.78125C1.90625 8.1875 1.64063 8.82031 1.40625 9.21094C1.28125 9.42187 1.11719 9.55469 1.08594 9.74219C1.07031 9.83594 1.14063 9.9375 1.13281 10.0625C1.125 10.25 1.09375 10.4062 1.07813 10.5625C0.96875 10.8594 0.96875 11.2031 1.09375 11.5547C1.38281 12.3672 2.23438 13 3.03125 13H10.2422C10.9141 13 11.6016 12.4844 11.7969 11.8203L13.9453 4.74219C14.0547 4.38281 14 4.01562 13.8047 3.73437ZM5.49219 3.75L5.65625 3.25C5.70313 3.10937 5.85156 3 5.98438 3H10.7344C10.875 3 10.9531 3.10937 10.9063 3.25L10.7422 3.75C10.6953 3.89062 10.5469 4 10.4141 4H5.66406C5.52344 4 5.44531 3.89062 5.49219 3.75ZM4.84375 5.75L5.00781 5.25C5.05469 5.10937 5.20313 5 5.33594 5H10.0859C10.2266 5 10.3047 5.10937 10.2578 5.25L10.0938 5.75C10.0469 5.89062 9.89844 6 9.76563 6H5.01563C4.875 6 4.79688 5.89062 4.84375 5.75Z"
      fill="black"
    />
  </svg>
)
