import React, { useEffect, useRef, useState } from 'react'
import { hotjar } from 'react-hotjar'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  StepperContainer,
  ErroLayoutContainer,
  Carregando,
  ErroContainerLayout,
  ModalRef
} from 'src/componentes'
import { RotasApp, RotasPesquisaSatisfacao } from 'src/rotas'
import { Api } from 'src/servicos'
import { DadosCartao } from 'src/servicos/api/tipos'

import SalaAulaOutrosContainer from './container'
import { ModalConclusao } from './modal-conclusao-curso/modal-conclusao-curso'
import { ModalPagamentoCartaoRecorrente } from './modal-pagamento-recorrente/modal-pagamento-recorrente'
import { ComponenteStepper } from './stepper'

const mensagemErroCartaoCredito =
  'Erro ao efetivar pagamento recorrente, informe os dados do cartão novamente.'

const mensagemSucessoCartaoCredito = 'Cartão salvo com sucesso.'

const SalaAula: React.FC = () => {
  const {
    salaAula,
    idMatricula,
    definirSalaAula,
    definirItensMarcados,
    recarregarSala,
    atualizarRecarregarSala,
    fecharModal
  } = SalaAulaOutrosContainer.useContainer()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()
  const history = useHistory()
  const modalRef = useRef<ModalRef>(null)
  const modalConclusaoRef = useRef<ModalRef>(null)

  const [pronto, definirPronto] = useState(false)
  const responderDepoisPesquisa = localStorage.getItem(
    '@ipgs/responderDepoisPesquisa'
  )

  const carregarSalaAula = async () => {
    try {
      definirPronto(false)
      limparErro()

      const salaAula = await Api.SalaAulaOutros.RequisitarSalaAulaPorId(
        idMatricula
      )

      if (
        salaAula.pesquisaSatisfacaoInstitucionalId &&
        !salaAula.turmaSoCertificacao &&
        responderDepoisPesquisa !== 'true'
      ) {
        history.push(
          `${RotasPesquisaSatisfacao.Institucional}/${salaAula.pesquisaSatisfacaoInstitucionalId}`
        )
      }

      const materiaisLidos: string[] = []

      if (salaAula.modulos.length > 0) {
        salaAula.modulos.forEach(mod => {
          mod.disciplinas.forEach(disc => {
            disc.materiais.forEach(mat => {
              if (mat.lido) materiaisLidos.push(mat.id)
            })
          })
        })
      }

      if (salaAula.moduloTCC) {
        salaAula.moduloTCC.disciplinas?.forEach(d => {
          d.materiais?.forEach(m => {
            if (m.lido) materiaisLidos.push(m.id)
          })
        })
      }

      if (salaAula.materiaisComplementares) {
        salaAula.materiaisComplementares?.forEach(m => {
          if (m.lido) materiaisLidos.push(m.id)
        })
      }

      definirItensMarcados(materiaisLidos)
      definirSalaAula(salaAula)
      definirPronto(true)
    } catch {
      definirErro({
        mensagens: 'Ops! Houve algum problema ao carregar a sala de aula',
        textoConfirmar: 'Tentar de novo',
        acaoConfirmar: carregarSalaAula,
        acaoVoltar: () => history.push(RotasApp.Home)
      })
    }
  }

  const pagamentoComCartaoDeCredito = async (dadosDoCartao: DadosCartao) => {
    try {
      await Api.EnviarPagamentoRecorrente(idMatricula, dadosDoCartao)
      toast(mensagemSucessoCartaoCredito, { type: 'success' })
    } catch (erro) {
      toast(mensagemErroCartaoCredito, { type: 'error' })
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR, 6)
    }
    carregarSalaAula()
  }, [idMatricula])

  useEffect(() => {
    if (recarregarSala) carregarSalaAula()
    atualizarRecarregarSala(false)
  }, [recarregarSala])

  useEffect(() => {
    if (!fecharModal && salaAula?.exigirCartao) {
      modalRef?.current?.abrir()
    }
  }, [fecharModal, salaAula?.exigirCartao])

  useEffect(() => {
    if (salaAula.progresso === 100) {
      modalConclusaoRef?.current?.abrir()
    }
  }, [carregarSalaAula])

  return (
    <>
      {pronto && !erro ? (
        <>
          <ComponenteStepper />
        </>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando sala de aula ..." />
      ) : (
        <ErroContainerLayout />
      )}
      <ModalPagamentoCartaoRecorrente
        ref={modalRef}
        backdrop
        id="modal-pagamento"
        acaoPrimaria={pagamentoComCartaoDeCredito}
      />
      <ModalConclusao
        ref={modalConclusaoRef}
        dataFinalizacao={salaAula.dataFimSalaAula}
        backdrop
        id="modal-conclusao"
      />
    </>
  )
}

export const PaginaSalaAulaOutros: React.FC = props => {
  const { id } = useParams<{ id: string }>()

  return (
    <StepperContainer.Provider>
      <SalaAulaOutrosContainer.Provider initialState={{ idMatricula: id }}>
        <ErroLayoutContainer.Provider>
          <SalaAula {...props} />
        </ErroLayoutContainer.Provider>
      </SalaAulaOutrosContainer.Provider>
    </StepperContainer.Provider>
  )
}
