import {
  DadosDoCartaoDeCredito,
  PagamentoComCartaoDeCredito,
  PagamentoComCartaoCreditoCarrinhoCompra
} from 'src/tipos'

const numeroDeParcelaFixasDoCartaoDeCredito = 1

export function FormularioDePagamentoMapper(
  matriculaId: string,
  cobrancaId: string,
  form: {
    [key: string]: string
  }
): PagamentoComCartaoDeCredito {
  if (!form) {
    return {} as PagamentoComCartaoDeCredito
  }

  return {
    matriculaId,
    cobrancaId,
    dadosCartao: {
      nomeImpresso: form.nomeCartao,
      numero: form.numeroCartao,
      validade: form.validadeCartao,
      codigo: form.codigoSeguranca,
      parcelas: form.quantidadeParcelas ?? numeroDeParcelaFixasDoCartaoDeCredito
    } as DadosDoCartaoDeCredito
  } as PagamentoComCartaoDeCredito
}

export function FormularioDePagamentoCarrinhoCompraMapper(
  carrinhoCompraId: string,
  form: { [key: string]: string }
): PagamentoComCartaoCreditoCarrinhoCompra {
  if (!form) return {} as PagamentoComCartaoCreditoCarrinhoCompra

  return {
    carrinhoCompraId,
    dadosCartao: {
      nomeImpresso: form.nomeCartao,
      numero: form.numeroCartao,
      validade: form.validadeCartao,
      codigo: form.codigoSeguranca,
      parcelas: form.quantidadeParcelas ?? numeroDeParcelaFixasDoCartaoDeCredito
    } as DadosDoCartaoDeCredito
  } as PagamentoComCartaoCreditoCarrinhoCompra
}
