import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'
const caracter = Yup.string().required(mensagemPadrao)
const codigoSegurancaMensagem = 'CVV inválido'

export const schemaPagamentoCartaoCredito = Yup.object().shape({
  numeroCartao: Yup.string().cartaoCredito().required(mensagemPadrao),
  nomeCartao: caracter,
  quantidadeParcelas: Yup.string()
    .nullable()
    .when('permitidoParcelamento', {
      is: value => value && value === 'true',
      then: field => field.required(mensagemPadrao)
    }),
  validadeCartao: Yup.string().validadeCartaoCredito().required(mensagemPadrao),
  codigoSeguranca: Yup.string()
    .nullable()
    .min(3, codigoSegurancaMensagem)
    .max(4, codigoSegurancaMensagem)
    .required(mensagemPadrao)
})
