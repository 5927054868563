import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  SelectOpcao,
  MoedaInputUnform,
  DataInputUnform,
  SelectUnform,
  Accordion
} from 'src/componentes'
import { Api } from 'src/servicos'
import { RecuperacaoManual, SolicitacaoRecuperacaoManual } from 'src/tipos'

import { schema } from './schema'
import {
  CelulaNome,
  CelulaNota,
  ContainerAccordion,
  ContainerAcoes,
  ContainerMedio,
  ContainerModal,
  CorpoModal,
  Linha,
  LinhaTabela,
  Nome,
  Nota,
  Tabela,
  Titulo
} from './styles'
import {
  EnvioValoresRecuperacaoManual,
  ModalRecuperacaoManualProps,
  ModalRecuperacaoManualRef
} from './tipos'

const ModalRecuperacaoManualComponente: React.ForwardRefRenderFunction<
  ModalRecuperacaoManualRef,
  ModalRecuperacaoManualProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [matriculaId, definirMatriculaId] = useState('')
  const [carregando, definirCarregando] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [dados, definirDados] = useState<RecuperacaoManual>()

  const possuiAvaliacoesDeDisciplina = useMemo(
    () => dados?.disciplinas?.length > 0,
    [dados?.disciplinas]
  )

  const formRef = useRef<FormHandles>(null)

  const atualizarValorFormulario = async (campo: string, valor: any) => {
    if (!valor) return
    formRef?.current?.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const carregarOpcoes = async (matriculaId: string) => {
    try {
      definirPronto(false)
      const resposta = await Api.ObterInformacoesRecuperacaoManual(matriculaId)
      definirDados(resposta)

      const valor = resposta?.valorRecuperacao * resposta?.quantidade
      atualizarValorFormulario('valorTotal', valor)
      atualizarValorFormulario(
        'dataNovaFinalizacao',
        resposta?.dataFimRecuperacao
      )
      if (
        resposta?.solicitacao ===
        SolicitacaoRecuperacaoManual.FinanceiroPendente
      ) {
        toast('O usuario possui pendências financeiras', { type: 'error' })
        // eslint-disable-next-line
        fechar()
      }
    } catch {
      toast('Erro ao carregar informações', { type: 'error' })
    } finally {
      definirPronto(true)
    }
  }

  const abrir = async (matriculaId: string) => {
    definirAberta(true)
    definirMatriculaId(matriculaId)
    await carregarOpcoes(matriculaId)
  }

  const fechar = () => {
    definirMatriculaId(null)
    definirAberta(false)
  }

  useImperativeHandle<ModalRecuperacaoManualRef, ModalRecuperacaoManualRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoForm = async (dados: EnvioValoresRecuperacaoManual) => {
    try {
      definirCarregando(true)
      const payload = { ...dados, matriculaId }
      await acaoPrimaria(payload)
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  const parcelas = useMemo(() => {
    return Array.from({ length: dados?.quantidadeMaximaParcelas }, (x, i) => {
      const contador = `${i + 1}`
      return { id: contador, texto: `${contador}x` }
    }) as SelectOpcao[]
  }, [dados?.quantidadeMaximaParcelas])

  return (
    <ModalBase
      id="modal-recuperacao-manual"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          <Titulo>Recuperação Manual</Titulo>
          <ContainerAccordion>
            {possuiAvaliacoesDeDisciplina && (
              <Accordion titulo={'Disciplinas a recuperar'}>
                <Tabela>
                  <thead>
                    <tr>
                      <CelulaNome>Disciplina</CelulaNome>
                      <CelulaNota>Nota</CelulaNota>
                    </tr>
                  </thead>
                  <tbody>
                    {dados?.disciplinas?.map((disciplina, ind) => (
                      <LinhaTabela key={ind}>
                        <Nome>{disciplina?.nome}</Nome>
                        <Nota>{disciplina?.nota}</Nota>
                      </LinhaTabela>
                    ))}
                  </tbody>
                </Tabela>
              </Accordion>
            )}
            {!possuiAvaliacoesDeDisciplina && (
              <Accordion titulo={'Módulos a recuperar'}>
                <thead>
                  <tr>
                    <CelulaNome>Módulo</CelulaNome>
                    <CelulaNota>Nota</CelulaNota>
                  </tr>
                </thead>
                <tbody>
                  {dados?.modulos?.map((modulo, ind) => (
                    <LinhaTabela key={ind}>
                      <Nome>{modulo?.nome}</Nome>
                      <Nota>{modulo?.nota}</Nota>
                    </LinhaTabela>
                  ))}
                </tbody>
              </Accordion>
            )}
          </ContainerAccordion>

          <FormUnform
            acaoSucesso={acaoSucessoForm}
            schema={schema}
            ref={formRef}
          >
            <Linha>
              <ContainerMedio>
                <DataInputUnform
                  id="ipt_data_finalizacao"
                  name="dataNovaFinalizacao"
                  label="Nova Data de Finalização"
                  disabled={true}
                  obrigatorio
                />
              </ContainerMedio>
              <ContainerMedio>
                <MoedaInputUnform
                  name="valorTotal"
                  id="mipt_valor_total"
                  label="Valor Total a Pagar"
                  disabled={!pronto}
                  obrigatorio
                />
              </ContainerMedio>
            </Linha>
            <Linha>
              <ContainerMedio>
                <DataInputUnform
                  id="ipt_data_primeiro_vencimento"
                  name="dataPrimeiroVencimento"
                  label="Data do Primeiro Vencimento"
                  disabled={!pronto}
                  obrigatorio
                />
              </ContainerMedio>
              <ContainerMedio>
                <SelectUnform
                  id={'slc_quantidade_parcelas'}
                  name="quantidadeParcelas"
                  label="Quantidade de Parcelas"
                  disabled={!pronto}
                  obrigatorio
                  opcoes={parcelas}
                />
              </ContainerMedio>
            </Linha>
            <ContainerAcoes>
              <Botao
                texto="Cancelar"
                tema="Secundario"
                type="button"
                disabled={carregando}
                onClick={() => fechar()}
              />
              <Botao texto="Salvar" carregando={carregando} />
            </ContainerAcoes>
          </FormUnform>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalRecuperacaoManual = forwardRef(
  ModalRecuperacaoManualComponente
)
