import { Medias } from 'src/compartilhados'
import { CampoInputLabelProps, SelectProps } from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Modal } from 'src/componentes/modal'
import { Spacer } from 'src/componentes/spacer'
import { SpacerProps } from 'src/componentes/spacer/tipos'
import { Cores } from 'src/componentes/styles/cores'
import { SubTitulo } from 'src/componentes/subtitulo'
import { SelectUnform } from 'src/componentes/unform/select'
import styled, { css } from 'styled-components'

import { Navegacoes } from './tipos'

const estilosPorIdDaSecao = (
  id: string,
  navegacao: Navegacoes,
  callback: () => any
) => (id && id === navegacao ? callback() : null)

export const SubTituloComEstilos = styled(SubTitulo)``

export const SpacerComEstilos = styled(Spacer)<SpacerProps>``

export const SelectUnformComEstilos = styled(SelectUnform)<SelectProps>``

export const BotaoComEstilos = styled(Botao)``

export const ModalComEstilos = styled(Modal)`
  ${Medias.Mobile} {
    > div {
      width: 90%;
      min-width: initial;
      padding: 30px 15px;
    }
  }
`

export const ParagrafoComEstilos = styled.p`
  ${Medias.Mobile} {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
  }
`

export const Conteudo = styled.div`
  margin-top: 18px;
  margin-left: 30px;

  ${Medias.PontoMaximoParaTablets} {
    margin: 0 auto;
  }
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;

  ${Medias.PontoMaximoParaTablets} {
    width: 100%;
    flex-direction: column;
  }
`

export const ContainerDadosMedio = styled.div`
  width: 50%;

  &:not(:last-child) {
    margin-right: 19px;
  }

  ${Medias.PontoMaximoParaTablets} {
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
`
export const ContainerMain = styled.div`
  width: 81%;

  ${Medias.PontoMaximoParaTablets} {
    width: 100%;
  }
`
export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`
export const CampoLabel = styled.label<CampoInputLabelProps>`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: left;
  line-height: 125%;
  margin-bottom: ${props => (props.temDescricao ? '3px' : '6px')};
  color: ${props =>
    props.readOnly ? Cores.CINZA_2_CLARO : Cores.CINZA_2_ESCURO};

  :first-letter {
    text-transform: uppercase;
  }
`
export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 19px;
  }

  ${Medias.Mobile} {
    flex-direction: column;

    ${BotaoComEstilos} {
      width: 100%;
    }

    ${BotaoComEstilos}:first-child {
      order: 2;
    }

    ${BotaoComEstilos}:last-child {
      margin-bottom: 15px;
    }
  }
`
export const ContainerLateral = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${Medias.PontoMaximoParaTablets} {
    display: none;
  }
`

const TextoDecorativo = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: black;
  box-sizing: border-box;
  padding-top: 24px;
`

export const RequisicaoComErros = styled(TextoDecorativo)`
  color: ${Cores.SECUNDARIA_3_CLARO};
`

export const CarregandoCursos = styled(TextoDecorativo)`
  color: ${Cores.SECUNDARIA_2_ESCURO};
`

export const TituloPrincipal = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;

  ${Medias.Mobile} {
    font-size: 16px;
    line-height: 19px;
  }

  ${Medias.Tablet} {
    font-size: 22px;
  }
`

export const ContainerDadosPequeno = styled.div`
  width: 25%;

  &:not(:last-child) {
    margin-right: 19px;
  }

  ${Medias.PontoMaximoParaTablets} {
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`

export const ContainerDoUploadDeImagem = styled.div`
  display: flex;
  justify-content: flex-start;

  ${Medias.Tablet} {
    justify-content: center;
  }
`

const SecaoDadosPessoaisComEstilosMobile = id =>
  estilosPorIdDaSecao(id, Navegacoes.DadosPessoais, () => {
    return css`
      ${SubTituloComEstilos} {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
      }

      ${SpacerComEstilos} {
        padding: 5px;
      }

      ${SelectUnformComEstilos} {
        & > label {
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          text-align: left;
          line-height: 125%;
          margin-bottom: 6px;
        }
      }
    `
  })

const SecaoContatoComEstilosMobile = id =>
  estilosPorIdDaSecao(id, Navegacoes.Contato, () => {
    return css`
      &:not(:first-of-type) {
        margin-top: 0;
      }
    `
  })

const SecaoResidenciaComEstilosMobile = id =>
  estilosPorIdDaSecao(id, Navegacoes.Residencia, () => {
    return css`
      &:not(:first-of-type) {
        margin-top: 0;
      }

      ${SelectUnformComEstilos} {
        & > label {
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          text-align: left;
          line-height: 125%;
          margin-bottom: 6px;
        }
      }
    `
  })

export const Secao = styled.section<{ id?: string }>`
  &:not(:first-of-type) {
    margin-top: 30px;
  }

  ${Medias.PontoMaximoParaTablets} {
    ${props => SecaoDadosPessoaisComEstilosMobile(props.id)}
    ${props => SecaoContatoComEstilosMobile(props.id)}
    ${props => SecaoResidenciaComEstilosMobile(props.id)}
  }
`

export const ContainerCPF = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  > div {
    flex: 1;
  }

  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 28px;
      height: 28px;
      fill: #666666;
    }

    &:hover {
      background: #f7f7f7;

      svg {
        fill: #0accb9;

        path {
          fill: #0accb9;
        }
      }
    }
  }
`
