export * from './cupom'
export * from './usuario'
export * from './disciplina'
export * from './professor'
export * from './turma'
export * from './aluno'
export * from './curso'
export * from './historico'
export * from './relatorio'
export * from './ticket'
