import { Cores, Medias } from 'src/componentes/styles'
import styled from 'styled-components'

export const CorpoModal = styled.div`
  width: 480px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70%;
  max-height: 70%;

  @media ${Medias.MOBILE} {
    width: 90%;
  }

  @media ${Medias.MOBILE} {
    height: 600px;
  }

  &:first-child {
    margin-top: 20px;

    @media ${Medias.MOBILE} {
      margin-top: 0px;
    }
  }

  form {
    width: 100%;
  }
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;

  @media ${Medias.MOBILE} {
    min-width: 90%;
  }

  > form > div {
    margin-bottom: 12px;
  }
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
  }

  & > button:not(:first-child) {
    margin-left: 20px;
    @media ${Medias.MOBILE} {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  button {
    @media ${Medias.MOBILE} {
      width: 100%;
    }
  }
`

export const Linha = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;

  @media ${Medias.MOBILE} {
    flex-direction: column;
  }
`

export const ContainerMedio = styled.div`
  width: 50%;
  display: flex;

  @media ${Medias.MOBILE} {
    width: 100%;
    & ~ div {
      margin-left: 0;
    }
  }

  .slcDiaVencimento {
    width: 90%;
  }
`
export const ContainerGrande = styled.div`
  width: 100%;
`

export const ContainerOpcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 20px;

  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
  }

  & > button:last-child {
    margin-left: 20px;
    @media ${Medias.MOBILE} {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  button {
    @media ${Medias.MOBILE} {
      width: 100%;
    }
  }
`
export const Titulo = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 20px;

  @media ${Medias.MOBILE} {
    font-size: 20px;
  }
`
export const TextoSemOpcoes = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
`
export const ContainerInput = styled.div`
  padding-top: 10px;
  margin-bottom: 0px;
`
export const ContainerAccordion = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

export const Tabela = styled.div`
  max-height: 120px;
  overflow-y: auto;

  padding: 0px 10px 0px 0px;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &,
  th,
  td {
    border: none;
    border-collapse: collapse;
  }
`

export const CelulaNome = styled.th`
  font-size: 12px;
  line-height: 125%;
  font-weight: normal;
  color: ${Cores.PRETO};
  background-color: ${Cores.CINZA_HEAD_TABLE};
  text-align: left;
  padding: 5px 210px 5px 10px;
`

export const CelulaNota = styled.th`
  font-size: 12px;
  line-height: 125%;
  font-weight: normal;
  color: ${Cores.PRETO};
  background-color: ${Cores.CINZA_HEAD_TABLE};
  padding: 5px 15px;
  text-align: center;
  white-space: nowrap;
`
export const LinhaTabela = styled.tr`
  font-weight: 300;
  font-size: 12px;
  line-height: 125%;
  color: ${Cores.PRETO};
  background: ${Cores.CINZA_3_MAIS_CLARO};
`

export const Nota = styled.td`
  padding: 5px 10px;
  text-align: center;
`

export const Nome = styled.td`
  margin-right: 200px;
  padding: 5px 10px 5px 10px;
`
