import React from 'react'

export default (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#E6B617"
    />
    <path
      d="M9.09439 3.276L8.85439 9.024H7.11439L6.87439 3.276H9.09439ZM8.01439 12.096C7.65439 12.096 7.35839 11.992 7.12639 11.784C6.90239 11.568 6.79039 11.304 6.79039 10.992C6.79039 10.672 6.90239 10.404 7.12639 10.188C7.35839 9.972 7.65439 9.864 8.01439 9.864C8.36639 9.864 8.65439 9.972 8.87839 10.188C9.11039 10.404 9.22639 10.672 9.22639 10.992C9.22639 11.304 9.11039 11.568 8.87839 11.784C8.65439 11.992 8.36639 12.096 8.01439 12.096Z"
      fill="#E6B617"
    />
  </svg>
)
