import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 40px 46px;

  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const TituloPrincipal = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 36px;
  text-align: center;
`

export const ContainerConteudo = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 9px;

    & > b {
      color: ${Cores.PERIGO_ERRO};
    }
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 16px;
`

export const ContainerCheckbox = styled.div`
  label,
  a {
    font-family: 'Saira', sans-serif;
    font-size: 14px;
    line-height: 100%;

    cursor: pointer;
  }

  label {
    color: ${Cores.CINZA_2};
  }
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;

  label {
    max-width: 360px;
  }
`
