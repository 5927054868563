import React, { useState, useMemo, useRef, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import {
  AutenticacaoContainer,
  Botao,
  BotaoStep,
  Informacao,
  ModalLogin,
  ModalLoginRef,
  CheckboxUnform,
  FormUnform,
  InputUnform,
  CpfInputUnform,
  TelefoneInputUnform,
  SelectUnform,
  WizardContainer,
  ResumoCurso,
  FormRef,
  scrollParaTopo,
  focarPrimeiroCampoComErro,
  // useResponsivoState,
  DataInputUnform
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
// import { ContainerDadosPequeno } from 'src/componentes/styles/filtro'
import { SELECT_FORMACAO, SELECT_NACIONALIDADES } from 'src/dados-estaticos'
import { Api } from 'src/servicos'
import { CursosCarrinho, FormacaoPorExtenso } from 'src/tipos'

import MatriculaContainer from '../../container'
import {
  schemaCursoLivreDadosBasicos,
  schemaCursoLivreDadosBasicosComSenha
} from '../../schema'
import {
  ContainerConteudo,
  Opcao,
  OpcaoDados,
  ContainerCadastro,
  ContainerCheio,
  ContainerMedio,
  Linha,
  ContainerAcesso,
  ContainerAcessoBotoes,
  ContainerSenha
} from '../../styles'
import { DadosPessoaisForm } from '../../tipos'
import { ContainerAcoes } from './styles'

export const DadosPessoais: React.FC = () => {
  const {
    matricula,
    curso,
    carrinhoCurso,
    definirPasso,
    definirDadosPessoais,
    possuiCarrinho,
    definirCursosCarrinho,
    definirMatriculaCursosCarrinho
  } = MatriculaContainer.useContainer()
  const { usuario, atualizarUsuario } = AutenticacaoContainer.useContainer()
  const { mudarEtapa, passo } = WizardContainer.useContainer()
  const modalLogin = useRef<ModalLoginRef>(null)
  const formRef = useRef<FormRef>(null)
  const [abrirCadastro, definirAbrirCadastro] = useState(!!usuario)
  const [possuiNomeSocial, definirPossuiNomeSocial] = useState(
    matricula.dadosPessoais?.possuiNomeSocial ??
      !!(usuario && usuario.nomeSocial) ??
      false
  )
  const [nacionalidade, definirNacionalidade] = useState(
    SELECT_NACIONALIDADES[0].id
  )
  const [carregando, definirCarregando] = useState(false)
  // const { mobile } = useResponsivoState()
  const [formacaoSelecionada, definirFormacaoSelecionada] = useState(null)

  const dadosIniciais = useMemo(() => {
    if (matricula.dadosPessoais) {
      const {
        nome,
        nomeSocial,
        cpf,
        dataNascimento,
        profissao,
        profissaoOutros,
        codigoConselho,
        email,
        nacionalidade,
        celular
      } = matricula.dadosPessoais
      return {
        nome,
        nomeSocial,
        cpf,
        dataNascimento,
        profissao,
        profissaoOutros,
        codigoConselho,
        email,
        nacionalidade: nacionalidade || SELECT_NACIONALIDADES[0].id,
        celular
      }
    } else if (usuario) {
      const {
        nome,
        nomeSocial,
        cpf,
        dataNascimento,
        profissao,
        profissaoOutros,
        codigoConselho,
        email,
        nacionalidade,
        celular
      } = usuario

      return {
        nome,
        nomeSocial,
        cpf,
        dataNascimento,
        profissao,
        profissaoOutros,
        codigoConselho,
        email,
        nacionalidade: nacionalidade || SELECT_NACIONALIDADES[0].id,
        celular
      }
    } else {
      return { nacionalidade: SELECT_NACIONALIDADES[0].id }
    }
  }, [usuario, matricula.dadosPessoais])

  const acaoSucesso = async (dados: DadosPessoaisForm) => {
    try {
      definirCarregando(true)

      let resposta = {} as {
        matriculasIds: string[]
        token?: string
        carrinhoCompraId?: string
      }

      if (!matricula.id) {
        const ehCarrinho = possuiCarrinho()
        const payload = {
          email: dados.email,
          nomeSocial: dados.nomeSocial,
          celular: dados.celular,
          codigoConselho: dados.codigoConselho,
          profissao: dados.profissao,
          profissaoOutros: dados.profissaoOutros,
          dataNascimento: dados.dataNascimento,
          ehCarrinho,
          cursosIds: ehCarrinho
            ? carrinhoCurso.cursos.map(c => c.id)
            : [curso.id]
        }

        let token = null

        if (usuario && usuario.id) {
          const respostaApi = await Api.CadastrarUsuarioAutenticadoCursoLivre(
            payload
          )
          if (respostaApi) {
            resposta = {
              matriculasIds: respostaApi.matriculas.map(x => x.matriculaId),
              token: usuario.token,
              carrinhoCompraId: respostaApi.carrinhoCompraId
            }
          }
          token = usuario.token
        } else {
          const respostaApi = await Api.CadastrarUsuarioCursoLivre({
            ...payload,
            cpf: dados.cpf,
            nacionalidade: dados.nacionalidade,
            dataNascimento: dados.dataNascimento,
            profissao: dados.profissao,
            profissaoOutros: dados.profissaoOutros,
            codigoConselho: dados.codigoConselho,
            nome: dados.nome,
            senha: dados.novaSenha,
            repetirSenha: dados.repetirSenha
          })

          if (respostaApi) {
            resposta = {
              matriculasIds: respostaApi.matriculas.map(x => x.matriculaId),
              token: respostaApi.token,
              carrinhoCompraId: respostaApi.carrinhoCompraId
            }
            token = respostaApi.token
          }
        }

        await atualizarUsuario({ token }, true)
      } else {
        const payload = {
          email: dados.email,
          nomeSocial: dados.nomeSocial,
          celular: dados.celular,
          matriculaId: matricula.id,
          dataNascimento: dados.dataNascimento,
          profissao: dados.profissao,
          profissaoOutros: dados.profissaoOutros,
          codigoConselho: dados.codigoConselho,
          cursosIds: carrinhoCurso?.cursos?.map(x => x.id)
        }
        const repostasMatriculas = await Api.EditarUsuarioCursoLivre(payload)

        resposta = { matriculasIds: repostasMatriculas.matriculasIds }
      }

      if (resposta) {
        definirDadosPessoais(resposta.matriculasIds[0], {
          ...dados
        })

        if (resposta.carrinhoCompraId) {
          const dadosCarrinho = await Api.ObterCarrinhoCompraCursoLivre(
            resposta.carrinhoCompraId
          )
          definirMatriculaCursosCarrinho({
            ...carrinhoCurso,
            matriculas: dadosCarrinho.matriculas?.map(m => {
              const {
                matriculaId,
                situacaoMatricula,
                etapaMatricula,
                nomeSocial,
                ...dadosPessoais
              } = m
              return {
                id: matriculaId,
                situacaoMatricula,
                etapaMatricula,
                planoPagamento: undefined,
                termosCondicoes: undefined,
                dadosPessoais: {
                  nome: dadosPessoais.nomeCompleto,
                  documentoIdentidade: dadosPessoais.rg,
                  nomeSocial: nomeSocial,
                  possuiNomeSocial: !!nomeSocial,
                  endereco: undefined,
                  ...dadosPessoais
                },
                cursoId: m.cursoId
              }
            }),
            carrinhoCompraId: dadosCarrinho.carrinhoCompraId
          } as CursosCarrinho)
        }

        mudarEtapa(2)
        definirPasso(2)
      }
    } finally {
      definirCarregando(false)
    }

    if (process.env.REACT_APP_GTM) {
      if (!usuario && !matricula.dadosPessoais) {
        const dadosNovoCadastro = {
          email: dados.email,
          nome: dados.nome,
          nomeSocial: dados.nomeSocial,
          celular: dados.celular,
          endereco: dados.endereco,
          nacionalidade: dados.nacionalidade
        }
        const tagManagerArgs = {
          gtmId: 'GTM-NRDM9FL',
          dataLayer: {
            event: 'Novo cadastro de aluno (Curso Livre)',
            detalhesNovoUsuario: dadosNovoCadastro
          }
        }
        TagManager.initialize(tagManagerArgs)
      }
    }
  }

  useEffect(() => {
    scrollParaTopo()
  }, [passo])

  useEffect(() => {
    formRef.current?.setData(dadosIniciais)
  }, [dadosIniciais])

  const manipularAbrirCadastro = () => {
    definirAbrirCadastro(true)

    window.setTimeout(() => {
      document
        .querySelectorAll('#secao-dados-basicos')
        ?.item(0)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
    }, 200)
  }

  const eventoExcluirCursoCarrinho = (cursoId: string) => {
    if (!possuiCarrinho()) return

    const cursosFiltrados = carrinhoCurso.cursos?.filter(c => c.id !== cursoId)
    definirCursosCarrinho({ ...carrinhoCurso, cursos: cursosFiltrados })
  }

  useEffect(() => {
    if (formacaoSelecionada !== FormacaoPorExtenso.Outra) {
      formRef.current?.setFieldValue('profissaoOutros', null)
    }
  }, [formacaoSelecionada])

  return (
    <>
      <ContainerConteudo>
        <h2>{possuiCarrinho() ? 'Cursos' : 'Curso'}</h2>
        {possuiCarrinho() ? (
          carrinhoCurso.cursos.map(curso => (
            <ResumoCurso
              key={curso.id}
              curso={curso}
              eventoExcluirCarrinho={
                carrinhoCurso?.cursos?.length > 1 &&
                !carrinhoCurso?.boletoGerado
                  ? eventoExcluirCursoCarrinho
                  : null
              }
            />
          ))
        ) : (
          <ResumoCurso key={curso.id} curso={curso} />
        )}

        {!usuario && !matricula.dadosPessoais && (
          <ContainerAcesso>
            <p>Para continuar sua matrícula, identifique-se:</p>
            <ContainerAcessoBotoes>
              <Botao
                texto="Desejo me cadastrar"
                tema="Link"
                tamanho="S"
                onClick={manipularAbrirCadastro}
              />
              <Botao
                texto="Já possuo cadastro no iPGS Education"
                tema="Link"
                onClick={() => {
                  modalLogin?.current?.abrir()
                }}
              />
            </ContainerAcessoBotoes>
            <Informacao>
              Os dados de login podem ser diferentes dos que você utiliza para
              acessar o site do iPGS. Se ainda não possui cadastro no iPGS
              Education, clique acima para se cadastrar.
            </Informacao>
          </ContainerAcesso>
        )}
        {abrirCadastro || matricula.dadosPessoais ? (
          <ContainerCadastro id="secao-dados-basicos">
            {!usuario && !matricula.dadosPessoais && (
              <p>Para continuar sua matrícula, identifique-se:</p>
            )}
            <FormUnform
              ref={formRef}
              dadosIniciais={dadosIniciais}
              schema={
                !usuario && !matricula.dadosPessoais
                  ? schemaCursoLivreDadosBasicosComSenha
                  : schemaCursoLivreDadosBasicos
              }
              acaoSucesso={acaoSucesso}
              acaoFalha={focarPrimeiroCampoComErro}
            >
              <h4>Dados Pessoais</h4>
              <Linha>
                <ContainerMedio>
                  <SelectUnform
                    id="slc_nacionalidade"
                    name="nacionalidade"
                    label="Nacionalidade"
                    placeholder="Selecione"
                    obrigatorio
                    opcoes={SELECT_NACIONALIDADES}
                    disabled={!!usuario || !!matricula.dadosPessoais}
                    valorAlterado={valor => {
                      if (valor) {
                        definirNacionalidade(valor.id)
                      }
                    }}
                  />
                </ContainerMedio>
                {nacionalidade === SELECT_NACIONALIDADES[0].id && (
                  <ContainerMedio>
                    <CpfInputUnform
                      id="ipt_cpf"
                      name="cpf"
                      label="CPF"
                      obrigatorio
                      disabled={!!usuario || !!matricula.dadosPessoais}
                    />
                  </ContainerMedio>
                )}
                <ContainerMedio>
                  <DataInputUnform
                    className="sm"
                    id="ipt_data_nascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    placeholder="__/__/____"
                    obrigatorio
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    id="ipt_condigo_conselho"
                    name="codigoConselho"
                    label="Código do Conselho"
                    maxLength={20}
                    bloquearCaracteresEspeciais
                  />
                </ContainerMedio>
              </Linha>
              <Linha>
                <ContainerCheio>
                  <InputUnform
                    id="ipt_nome_completo"
                    name="nome"
                    label="Nome Completo (Atenção: Nome que será utilizado na emissão de certificados, diplomas e demais documentos oficiais da Instituição)"
                    obrigatorio
                    disabled={!!usuario || !!matricula.dadosPessoais}
                    maxLength={200}
                    style={{ textTransform: 'uppercase' }}
                  />
                </ContainerCheio>
              </Linha>
              <Opcao>
                <CampoLabel>Possui nome social?</CampoLabel>
                <OpcaoDados>
                  <CheckboxUnform
                    id="chk_possui_nome_social"
                    name="possuiNomeSocial"
                    onChange={() => definirPossuiNomeSocial(old => !old)}
                    checked={possuiNomeSocial}
                  />
                  <p>
                    <b>Sim</b>{' '}
                    <i>
                      (Nome social é a adoção de nome diferente do oficialmente
                      registrado, de modo a identificar adequadamente sua
                      identidade de gênero - Decreto nº 8727/2016)
                    </i>
                  </p>
                </OpcaoDados>
              </Opcao>
              {possuiNomeSocial ? (
                <Linha>
                  <ContainerCheio>
                    <InputUnform
                      name="nomeSocial"
                      label="Nome Social"
                      maxLength={200}
                      obrigatorio
                      style={{ textTransform: 'uppercase' }}
                    />
                  </ContainerCheio>
                </Linha>
              ) : (
                <></>
              )}
              <Linha>
                <ContainerMedio>
                  <InputUnform
                    id="ipt_email"
                    name="email"
                    label="E-mail"
                    obrigatorio
                    disabled={!!usuario}
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <TelefoneInputUnform
                    id="ipt_celular"
                    name="celular"
                    label="Celular"
                    obrigatorio
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <SelectUnform
                    className="lg"
                    id="formacao"
                    name="profissao"
                    label="Formação"
                    placeholder="Selecione"
                    valorAlterado={valor => {
                      if (valor && valor.texto) {
                        definirFormacaoSelecionada(valor.texto)
                      } else {
                        definirFormacaoSelecionada(null)
                      }
                    }}
                    opcoes={SELECT_FORMACAO}
                    obrigatorio
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    type="text"
                    name="profissaoOutros"
                    label="Qual?"
                    disabled={formacaoSelecionada !== FormacaoPorExtenso.Outra}
                    maxLength={200}
                    obrigatorio={
                      formacaoSelecionada === FormacaoPorExtenso.Outra
                    }
                  />
                </ContainerMedio>
              </Linha>
              {!usuario && !matricula.dadosPessoais ? (
                <>
                  <h4>Senha</h4>
                  <Linha>
                    <ContainerSenha>
                      <InputUnform
                        id="ipt_senha"
                        name="novaSenha"
                        label="Senha"
                        type="password"
                        obrigatorio
                        autoComplete="new-password"
                      />
                    </ContainerSenha>
                    <ContainerSenha>
                      <InputUnform
                        id="ipt_repetir_senha"
                        name="repetirSenha"
                        label="Repetir Senha"
                        type="password"
                        obrigatorio
                        autoComplete="new-password"
                        semValidacaoSenha
                      />
                    </ContainerSenha>
                  </Linha>
                </>
              ) : (
                <></>
              )}
              <ContainerAcoes>
                <BotaoStep
                  texto={
                    !usuario && !matricula.dadosPessoais
                      ? 'Finalizar cadastro e ir para'
                      : 'Ir para'
                  }
                  textoNegrito="Pagamento do Curso"
                  type="submit"
                  carregando={carregando}
                />
              </ContainerAcoes>
            </FormUnform>
          </ContainerCadastro>
        ) : (
          <></>
        )}
      </ContainerConteudo>
      <ModalLogin
        ref={modalLogin}
        backdrop
        acaoSucesso={() => {
          definirAbrirCadastro(true)
        }}
        acaoCadastrar={() => {
          modalLogin.current?.fechar()
          manipularAbrirCadastro()
        }}
      />
    </>
  )
}
