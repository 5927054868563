import { Cabecalho } from 'src/componentes/cabecalho'
import styled from 'styled-components'

import { Cores } from '../../../../../componentes/styles'

export const ContainerModulo = styled.div`
  width: 100%;
  display: flex;
  background: ${Cores.AZUL_CINZA};
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
  p {
    color: ${Cores.BRANCO};
    font-size: 12px;
    font-weight: 400;
  }
  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 50px;
  }
`
export const ContainerLateral = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0px;
`
export const ContainerMain = styled.div`
  width: 70%;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
`
export const ContainerBotao = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
export const ContainerDisciplinas = styled.div`
  display: flex;
  flex-direction: column;

  & h2 {
    margin-bottom: 30px;
  }
  table {
    font-family: 'Saira', sans-serif;
    font-size: 12px;
    color: #191414;
    border-collapse: collapse;
    border: 1px solid ${Cores.CINZA_3_CLARO};
    width: 100%;

    thead {
      th {
        background: ${Cores.CINZA_HEAD_TABLE};
        color: ${Cores.PRETO};
        font-family: 'Saira-Semi-Bold', sans-serif;
        font-weight: 600;
        font-size: 14px;
        padding: 12px;
        border-bottom: none;
      }
    }
  }

  tbody {
    line-height: 125%;

    tr:first-child {
      border-top: 2px solid ${Cores.CINZA_3_CLARO};
    }

    tr {
      height: 48px;
      border: 1px solid ${Cores.FUNDO};
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid ${Cores.FUNDO};
      }

      &.par {
        background: ${Cores.FUNDO};
      }

      &.marcado {
        background: #afc2ed;
        border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
      }

      &.comMarcacao {
        cursor: pointer;
      }

      td,
      th {
        padding: 0 12px;
      }

      td > .situacao-ativo {
        color: ${Cores.SUCESSO_CLARO};
      }

      td > .situacao-inativo {
        color: ${Cores.CINZA_1_CLARO};
      }

      &:hover {
        border: 1px solid ${Cores.SECUNDARIA_1};
      }
    }
  }
`
export const BotaoTabela = styled.td`
  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    svg {
      & path {
        fill: ${props => props.color};
      }
    }
  }

  button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const ContainerLink = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const CabecalhoComEstilos = styled(Cabecalho)`
  flex-direction: column;
`
