import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ContainerAcoes,
  Filtro,
  IconeEditar,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  Tooltip
} from 'src/componentes'
import {
  Container,
  Form,
  ContainerDados25,
  Dados,
  ContainerDados50
} from 'src/componentes/styles/filtro'
import {
  DataInputUnform,
  InputUnform,
  SelectUnform
} from 'src/componentes/unform'
import { RotasPesquisaSatisfacao } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  TipoOrdenacao,
  ModalidadeCursoPorExtenso,
  ModalidadeCurso,
  TipoPesquisaSatisfacao,
  SituacaoPesquisaSatisfacao,
  DadosPesquisaSatisfacao
} from 'src/tipos'

import { TabelaComEstilos } from './styles'

export const PaginaPesquisaSatisfacao: React.FC = () => {
  const history = useHistory()
  const [
    dadosPesquisa,
    definirDadosPesquisa
  ] = useState<DadosPesquisaSatisfacao>(null)
  const tabelaRef = useRef<TabelaRef>()
  const [paginas, definirPaginas] = useState(null)

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.IrParaPagina()
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisaSatisfacao) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }
    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.RequisitarListaPesquisasSatisfacao(
      dadosPesquisa?.codigo,
      dadosPesquisa?.nome,
      dadosPesquisa?.modalidade,
      dadosPesquisa?.situacao,
      dadosPesquisa?.tipo,
      dadosPesquisa?.dataInicioPesquisaSatisfacao,
      dadosPesquisa?.dataFimPesquisaSatisfacao,
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisas de Satisfação"
          atalhos={[
            {
              texto: 'Acadêmico'
            },
            {
              texto: 'Pesquisas de Satisfação'
            }
          ]}
        />
        <Botao
          type="button"
          texto="Incluir"
          tema="Secundario"
          onClick={() => history.push(RotasPesquisaSatisfacao.Cadastro)}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao:
              SituacaoPesquisaSatisfacao[SituacaoPesquisaSatisfacao.Todos],
            modalidade: 'Todas'
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <ContainerDados25>
              <InputUnform type="text" name="codigo" label="Código" />
            </ContainerDados25>
            <ContainerDados50>
              <InputUnform
                type="text"
                name="nome"
                label="Nome da Pesquisa de Satisfação"
              />
            </ContainerDados50>
            <ContainerDados25>
              <SelectUnform
                id="slct_modalidade"
                name="modalidade"
                label="Modalidade do Curso"
                placeholder="Selecione"
                opcoes={[
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Livre],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
                    texto:
                      ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoEadFlex
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoEadFull
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoPresencial
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Graduacao]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Evento],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Evento]
                  },
                  {
                    id: 'Todas',
                    texto: 'Todas'
                  }
                ]}
              />
            </ContainerDados25>
          </Container>
          <Container>
            <ContainerDados25>
              <SelectUnform
                id="slct_situacao"
                name="situacao"
                label="Situação"
                placeholder="Selecione"
                iconeFechar={false}
                opcoes={[
                  {
                    id:
                      SituacaoPesquisaSatisfacao[
                        SituacaoPesquisaSatisfacao.Ativo
                      ],
                    texto: 'Ativo'
                  },
                  {
                    id:
                      SituacaoPesquisaSatisfacao[
                        SituacaoPesquisaSatisfacao.Inativo
                      ],
                    texto: 'Inativo'
                  },
                  {
                    id:
                      SituacaoPesquisaSatisfacao[
                        SituacaoPesquisaSatisfacao.Todos
                      ],
                    texto: 'Todas'
                  }
                ]}
              />
            </ContainerDados25>
            <ContainerDados25>
              <SelectUnform
                id="slct_tipo_pesquisa"
                name="tipo"
                label="Tipo"
                placeholder="Selecione"
                opcoes={[
                  {
                    id: TipoPesquisaSatisfacao[TipoPesquisaSatisfacao.Curso],
                    texto: 'Curso'
                  },
                  {
                    id:
                      TipoPesquisaSatisfacao[
                        TipoPesquisaSatisfacao.Institucional
                      ],
                    texto: 'Institucional'
                  },
                  {
                    id:
                      TipoPesquisaSatisfacao[
                        TipoPesquisaSatisfacao.Professores
                      ],
                    texto: 'Professores'
                  }
                ]}
              />
            </ContainerDados25>
            <ContainerDados25>
              <DataInputUnform
                name="dataInicioPesquisaSatisfacao"
                label="Data Início"
              />
            </ContainerDados25>
            <ContainerDados25>
              <DataInputUnform
                name="dataFimPesquisaSatisfacao"
                label="Data Fim"
              />
            </ContainerDados25>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <TabelaComEstilos
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'codigo',
              Texto: 'Código',
              ComOrdenacao: true
            },
            {
              Chave: 'nome',
              Texto: 'Nome',
              ComOrdenacao: true
            },
            {
              Chave: 'modalidade',
              Texto: 'Modalidade',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) =>
                ModalidadeCursoPorExtenso[linha.modalidade]
            },
            {
              Chave: 'tipo',
              Texto: 'Tipo',
              ComOrdenacao: true
            },
            {
              Chave: 'situacao',
              Texto: 'Situação',
              ComOrdenacao: true
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    data-tip="Editar"
                    data-for={`${linha.id}-tooltip-editar`}
                    onClick={() =>
                      history.push(
                        `${RotasPesquisaSatisfacao.Edicao}/${linha.id}`,
                        {
                          modalidade: linha.modalidade
                        }
                      )
                    }
                  >
                    {IconeEditar}
                  </button>
                  <Tooltip
                    id={`${linha.id}-tooltip-editar`}
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                </ContainerAcoes>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export { default as PaginaCadastroPesquisaSatisfacao } from './cadastro'
