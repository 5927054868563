import React from 'react'

export default (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="ocultarPassword"
  >
    <path
      d="M4.17519 13.8105C3.92207 13.5574 3.92207 13.1455 4.17519 12.8924L16.8739 0.193743C17.127 -0.0593726 17.5388 -0.0593726 17.7919 0.193743C18.0451 0.446858 18.0451 0.858707 17.7919 1.11182L5.09327 13.8105C4.83586 14.0636 4.4283 14.0636 4.17519 13.8105Z"
      fill="#606060"
    />
    <path
      d="M21.824 6.57387C21.721 6.4323 20.3525 4.58756 18.2031 2.89297C18.1302 2.8372 17.8814 2.86294 17.7913 2.91013C17.5939 3.01739 17.2464 3.34343 17.152 3.54936C17.1134 3.63945 17.0963 3.87969 17.1778 3.93976C18.6707 5.09808 19.7905 6.35937 20.3181 6.99859C19.1941 8.37142 15.3888 12.5543 10.9829 12.5543C10.2707 12.5543 9.57574 12.4427 8.9022 12.254C8.78637 12.2196 8.49035 12.2711 8.37023 12.3355C8.13856 12.4556 7.75245 12.8374 7.63662 13.0691C7.6023 13.1377 7.58943 13.3479 7.67523 13.378C8.71343 13.7641 9.82457 14 10.9829 14C16.9547 14 21.6309 7.69359 21.824 7.4276C22.0127 7.17448 22.0127 6.83128 21.824 6.57387Z"
      fill="#606060"
    />
    <path
      d="M7.25133 11.6176C4.54428 10.3005 2.43784 7.95812 1.64847 7.00143C2.77247 5.63289 6.57349 1.45005 10.9837 1.45005C12.7469 1.45005 14.4158 2.1236 15.853 3.01593L16.9083 1.96057C15.218 0.862308 13.1888 0 10.9837 0C5.0119 0 0.3357 6.30644 0.138355 6.57242C-0.0461185 6.82554 -0.0461185 7.17303 0.138355 7.42615C0.275638 7.61491 2.66522 10.8368 6.16594 12.6944L7.25133 11.6176Z"
      fill="#606060"
    />
    <path
      d="M8.38834 10.4798L9.43083 9.43732C8.6243 8.9225 8.09233 8.02159 8.09233 6.99625C8.09233 5.40034 9.39222 4.10044 10.9881 4.10044C12.0135 4.10044 12.9144 4.6367 13.4292 5.43895L14.4717 4.39646C13.678 3.33681 12.4124 2.65039 10.9924 2.65039C8.59856 2.65039 6.64656 4.59809 6.64656 6.99625C6.63798 8.42485 7.3244 9.69043 8.38834 10.4798Z"
      fill="#606060"
    />
  </svg>
)
