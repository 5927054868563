import styled from 'styled-components'

import { Cores } from '../styles'
import { InformacaoProps } from './tipos'

export const Container = styled.div<InformacaoProps>`
  display: flex;
  flex-direction: row;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: ${Cores.CINZA_2};
  align-items: flex-start;

  .informacao-icone {
    order: ${props => (props.invertido ? 1 : 0)};

    svg {
      margin-top: 2px;
      min-width: 12px;
      min-height: 12px;
    }
  }

  .informacao-desc {
    order: ${props => (props.invertido ? 0 : 1)};
    ${props => (props.invertido ? 'margin-right: 7px;' : 'margin-left: 7px;')};

    strong {
      font-weight: 400;
    }

    a {
      cursor: pointer;
    }
  }

  .informacao-tooltip {
    max-width: 451px;
  }
`
