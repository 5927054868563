import React, { useMemo } from 'react'

import { FuncoesMoeda, Informacao } from 'src/componentes'

import {
  ContainerConteudo,
  ContainerInfosPagamento,
  ContainerInfos,
  Info,
  TituloInfo
} from './styles'
import { InfoValoresCursoProps } from './tipos'

export const InfoValoresCurso: React.FC<InfoValoresCursoProps> = ({
  valorTaxa,
  recorrencia
}) => {
  const valorFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(valorTaxa)
  }, [valorTaxa])

  const valorPagarFormatado = useMemo(() => {
    return recorrencia
      ? FuncoesMoeda.formatarMoeda(0)
      : FuncoesMoeda.formatarMoeda(valorTaxa)
  }, [valorTaxa, recorrencia])
  return (
    <ContainerConteudo>
      <h2>Matrícula</h2>
      <ContainerInfosPagamento>
        <ContainerInfos>
          <TituloInfo>Valores da Taxa de Reingresso</TituloInfo>
          <Info>
            <p>Valor</p>
            <p>{valorFormatado}</p>
          </Info>
          <Info negrito>
            <p>
              Valor a Pagar
              <sup> 1</sup>
            </p>
            <p>{valorPagarFormatado}</p>
          </Info>
          <Info>
            <Informacao
              invertido
              id="informacao-valor-nao-reembolsavel"
              textoTooltip="De acordo com a política interna do iPGS, quando o aluno desiste de participar do curso, o valor da taxa de reingresso não é reembolsável."
            >
              <sup>1</sup> Valor não reembolsável em caso de desistência
            </Informacao>
          </Info>
        </ContainerInfos>
      </ContainerInfosPagamento>
    </ContainerConteudo>
  )
}
