import { AgregacaoDeUploads, UploadDocumentos } from '../tipos'

export function selecaoDeDocumentosParaEnvio(
  documentos: UploadDocumentos[]
): AgregacaoDeUploads {
  const controle = {
    edicoes: [],
    criacoes: [],
    analises: [],
    contador: 0
  } as AgregacaoDeUploads

  const redutor = (
    agregacao: AgregacaoDeUploads,
    documento: UploadDocumentos
  ) => {
    // Info: Editado
    if (documento.editado) {
      agregacao.edicoes.push(documento)
      agregacao.contador++

      // Info: Criado
    } else if (documento.nome && documento.arquivo) {
      agregacao.criacoes.push(documento)
      agregacao.contador++
    }

    // Info: Aprovado/Rejeitado
    if (documento.analisado) {
      agregacao.analises.push(documento)
      agregacao.contador++
    }

    return agregacao
  }

  return documentos.reduce(redutor, controle)
}
