import { Cores } from 'src/componentes/styles'
import styled, { css } from 'styled-components'

export const Tabela = styled.table`
  width: 100%;

  &,
  th,
  td {
    border: none;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 17px 20px;
  }
`

export const CelulaModulo = styled.th`
  height: 100%;
  font-size: 12px;
  line-height: 125%;
  font-weight: normal;
  color: ${Cores.BRANCO};
  background-color: ${Cores.AZUL_CINZA};
`

export const CelulaDisciplina = styled.th`
  font-size: 12px;
  line-height: 125%;
  font-weight: normal;
  color: ${Cores.PRETO};
  background-color: ${Cores.CINZA_HEAD_TABLE};
  text-align: left;
`

export const CelulaNota = styled(CelulaDisciplina)`
  text-align: center;
  white-space: nowrap;
`

export const CelulaData = styled(CelulaDisciplina)`
  text-align: center;
`

export const CelulaEncontro = styled(CelulaDisciplina)`
  text-align: center;
`

export const Data = styled.td`
  text-align: center;
`

export const Linha = styled.tr<{ cor?: string }>`
  font-weight: 300;
  font-size: 12px;
  line-height: 125%;
  color: ${Cores.PRETO};

  ${({ cor }) => {
    switch (cor) {
      case 'verde':
        return css`
          background: ${Cores.NOTAS_APROVADO};
        `
      case 'amarelo':
        return css`
          background: ${Cores.NOTAS_NAO_RESPONDIDA};
        `
      case 'vermelho':
        return css`
          background: ${Cores.NOTAS_ABAIXO_MEDIA};
        `
      default:
        return css`
          background: ${Cores.CINZA_3_MAIS_CLARO};
        `
    }
  }}
`

export const Nota = styled.td<{ aprovado?: boolean }>`
  text-align: center;

  ${props =>
    !props.aprovado &&
    css`
      color: ${Cores.PERIGO_ERRO};
    `}
`

export const CelulaEncontros = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Encontro = styled.span`
  display: flex;

  :not(:last-child) {
    margin-right: 33px;
  }

  & > svg {
    margin-left: 5px;
  }
`
