import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'
export const CorpoModal = styled.div`
  width: 471px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 90px 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ContainerInput = styled.div`
  padding-top: 10px;
`
export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`
