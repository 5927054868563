import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { RequisitarGradePorTexto } from 'src/servicos/api'

import {
  Botao,
  FormUnform,
  IconeAtencao,
  InputUnform,
  Modal,
  ModalRef,
  SelectAsyncUnform
} from '../../../../../../componentes/'
import { ModalBase } from '../../../../../../componentes/modal-base'
import { CursoSituacao } from '../../../../../../tipos'
import { Corpo, ContainerBotoes } from '../../styles'
import { Grade, ModalCopiarGradeRef, ModalCopiarProps } from '../tipos'
import { schema } from './schema'

const ModalCopiarGrade: React.ForwardRefRenderFunction<
  ModalCopiarGradeRef,
  ModalCopiarProps
> = ({ backdrop, acaoPrimaria, decisao }, ref) => {
  const [pronto, definirPronto] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const modalDecisaoRef = useRef<ModalRef>(null)
  const [dados, definirDados] = useState<Grade>(null)
  const [nomeGradeAtiva, definirNomeGradeAtiva] = useState<string>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }
  const abrir = nomeGradeAtiva => {
    definirNomeGradeAtiva(nomeGradeAtiva)
    definirPronto(true)
    definirAberta(true)
  }
  useImperativeHandle<ModalCopiarGradeRef, ModalCopiarGradeRef>(ref, () => ({
    fechar,
    abrir
  }))
  const acaoSucesso = async (dados: Grade) => {
    definirDados(dados)
    if (!decisao) {
      acaoPrimaria(dados)
    } else {
      modalDecisaoRef?.current?.abrir()
    }
    fechar()
  }
  const dadosIniciais = useMemo(() => {
    return { situacao: CursoSituacao[CursoSituacao.Ativo] }
  }, [])
  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo>
          <h5> Copiar Grade de Outro Curso </h5>
          {pronto && (
            <>
              <FormUnform
                schema={schema}
                dadosIniciais={dadosIniciais}
                acaoSucesso={acaoSucesso}
              >
                <SelectAsyncUnform
                  id="slct_grade"
                  name="grade"
                  label="Selecione a Grade Curricular"
                  obrigatorio
                  placeholder="Selecione"
                  buscarPorId={RequisitarGradePorTexto}
                  buscarPorTexto={RequisitarGradePorTexto}
                />
                <InputUnform id="situacao" name="situacao" type="hidden" />
                <ContainerBotoes>
                  <Botao
                    type="button"
                    id="btn-cancelar"
                    texto="Cancelar"
                    tema="Secundario"
                    onClick={() => {
                      fechar()
                    }}
                  />
                  <Botao id="btn-salvar" texto="Salvar" type="submit" />
                </ContainerBotoes>
              </FormUnform>
            </>
          )}
        </Corpo>
      </ModalBase>
      <Modal
        ref={modalDecisaoRef}
        backdrop
        id="modal-confirmacao"
        icone={IconeAtencao}
        titulo={'Já existe uma grade curricular ativa para este curso'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'submit',
          acao: () => {
            acaoPrimaria(dados)
            modalDecisaoRef.current?.fechar()
          }
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDecisaoRef.current?.fechar()
        }}
      >
        <p>{`Deseja desativar a grade "${nomeGradeAtiva}"?`}</p>
      </Modal>
    </>
  )
}
export const ModalCopiar = forwardRef(ModalCopiarGrade)
