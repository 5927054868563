import { useControleDeCarregamento } from '../controle-de-carregamento'
import { useControleDeErros } from '../controle-de-erros'
import { useControleDeRespostas } from '../controle-de-respostas'
import { ControleDeRequisicao } from './controle-de-requisicao.interface'

export const useControleDeRequisicao = <
  TResposta,
  TErro
>(): ControleDeRequisicao<TResposta, TErro> => {
  const carregador = useControleDeCarregamento()
  const erros = useControleDeErros<TErro>()
  const resposta = useControleDeRespostas<TResposta>()

  return {
    carregador,
    erros,
    resposta
  }
}
