import { useState } from 'react'

import { createContainer } from 'unstated-next'

import { WizardEstado } from './tipos'

const useWizard = (initialState?: { passoCompleto?: number }) => {
  const [estado, definirEstado] = useState<WizardEstado>({
    ...initialState,
    passoCompleto: initialState.passoCompleto ?? 1,
    tamanhoWizard: 1,
    passo: 1,
    visibilidadePrePasso: false
  } as WizardEstado)

  const definirTamanhoWizard = (tamanhoWizard: number) => {
    definirEstado(old => ({ ...old, tamanhoWizard }))
  }

  const mudarEtapaWizard = (etapa: number) => {
    definirEstado(old => ({
      ...old,
      passo: etapa,
      passoCompleto: old.passoCompleto < etapa ? etapa : old.passoCompleto,
      preEtapa: undefined,
      visibilidadePrePasso: false,
      condicaoPrePasso: () => false,
      acaoCancelarPrePasso: () => null,
      acaoConfirmarPrePasso: () => null
    }))
  }

  const mudarEtapaCompletaWizard = (etapa: number) => {
    definirEstado(old => ({
      ...old,
      passoCompleto: etapa
    }))
  }

  const voltarWizard = () => {
    definirEstado(old => {
      if (old.passo === 1) return old

      return {
        ...old,
        passo: old.passo - 1,
        visibilidadePrePasso: false,
        condicaoPrePasso: () => false,
        acaoCancelarPrePasso: () => null,
        acaoConfirmarPrePasso: () => null
      }
    })
  }

  const avancarWizard = () => {
    definirEstado(old => ({
      ...old,
      passo: old.passo + 1,
      passoCompleto: old.passoCompleto + 1,
      visibilidadePrePasso: false,
      condicaoPrePasso: () => false,
      acaoCancelarPrePasso: () => null,
      acaoConfirmarPrePasso: () => null
    }))
  }

  const salvarPrePassoWizard = (
    condicaoPrePasso: () => boolean,
    acaoConfirmarPrePasso: () => Promise<boolean>,
    acaoCancelarPrePasso?: (preEtapa?: number) => Promise<void>
  ) => {
    definirEstado(old => ({
      ...old,
      condicaoPrePasso,
      acaoConfirmarPrePasso,
      acaoCancelarPrePasso
    }))
  }

  const mudarVisibilidadePrePasso = (visibilidadePrePasso: boolean) => {
    definirEstado(old => ({
      ...old,
      visibilidadePrePasso
    }))
  }

  const salvarPreEtapa = (preEtapa?: number) => {
    definirEstado(old => ({ ...old, preEtapa }))
  }

  const mudarEtapa = (etapa: number) => {
    if (!etapa || estado.tamanhoWizard < etapa || etapa === estado.passo) return

    if (estado.condicaoPrePasso && estado.condicaoPrePasso()) {
      salvarPreEtapa(etapa)
      mudarVisibilidadePrePasso(true)
      return
    }

    mudarEtapaWizard(etapa)
  }

  return {
    ...estado,
    definirTamanhoWizard,
    mudarEtapaWizard,
    mudarEtapaCompletaWizard,
    avancarWizard,
    salvarPrePassoWizard,
    mudarVisibilidadePrePasso,
    salvarPreEtapa,
    mudarEtapa,
    voltarWizard
  }
}

export default createContainer(useWizard)
