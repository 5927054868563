import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ModalBase,
  Botao,
  ModalBaseProps,
  SubTitulo,
  IconeDecisao
} from 'src/componentes'

import { ContainerBotoes, ContainerModal, CorpoModal } from '../../styles'
import { ModalPreRequisitoRef } from '../tipos'

const ModalDesvincularPreRequisito: React.ForwardRefRenderFunction<
  ModalPreRequisitoRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria, acaoSecundaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [index, definirIndex] = useState(null)

  const fechar = () => {
    definirIndex(null)
    definirAberta(false)
  }

  const abrir = (index?: number) => {
    definirIndex(index)
    definirAberta(true)
  }

  useImperativeHandle<ModalPreRequisitoRef, ModalPreRequisitoRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-confirmacao-cancelamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          {IconeDecisao}
          <h5>
            {' '}
            Deseja realmente desvincular este pré-requisito da disciplina?{' '}
          </h5>
          <SubTitulo texto="Selecione uma opção" />
          <ContainerBotoes>
            <Botao
              type="button"
              id="btn-cancelar"
              texto="Não"
              tema="Secundario"
              onClick={() => {
                acaoSecundaria && acaoSecundaria()
                fechar()
              }}
            />
            <Botao
              type="button"
              id="btn-salvar"
              texto="Sim"
              onClick={() => {
                acaoPrimaria(index)
                fechar()
              }}
            />
          </ContainerBotoes>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const DesvincularPreRequisito = forwardRef(ModalDesvincularPreRequisito)
