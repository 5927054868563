import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  ErroLayoutContainer,
  Botao,
  Carregando,
  ModalRef,
  Modal,
  ErroContainerLayout
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import {
  FormaPagamentoCobranca,
  InfoRecuperacao,
  SolicitacaoRecuperacao
} from 'src/tipos'

import { ModalInscricaoRecuperacao } from './componentes/modal-inscricao-recuperacao'
import {
  ModalInscricaoRecuperacaoRef,
  ValoresRecuperacao
} from './componentes/modal-inscricao-recuperacao/tipos'
import {
  Titulo,
  Tabela,
  CelulaModuloENota,
  Linha,
  Modulo,
  Nota,
  ComponenteBotoes,
  ComponenteInformacoes,
  ConteudoModalInadimplente,
  CorpoModalIndisponivel
} from './styles'

const Recuperacao: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const modalCancelamentoRef = useRef<ModalRef>(null)
  const modalAvisoInadimplenteRef = useRef<ModalRef>(null)
  const modalDataRecuperacaoIndisponivelRef = useRef<ModalRef>(null)
  const modalInscricaoRecuperacaoRef = useRef<ModalInscricaoRecuperacaoRef>(
    null
  )
  const [pronto, definirPronto] = useState(false)
  const [dadosRecuperacao, definirDadosRecuperacao] = useState<InfoRecuperacao>(
    null
  )
  const { erro, definirErro } = ErroLayoutContainer.useContainer()

  const obterDadosRecuperacao = async () => {
    try {
      const dados = await Api.ObterInformacoesRecuperacao(id)
      definirDadosRecuperacao(dados)
      definirPronto(true)

      if (
        dados.solicitacao ===
        SolicitacaoRecuperacao[SolicitacaoRecuperacao.ForaDoPrazo]
      ) {
        modalDataRecuperacaoIndisponivelRef?.current?.abrir()
      } else if (
        dados.solicitacao ===
        SolicitacaoRecuperacao[SolicitacaoRecuperacao.FinanceiroPendente]
      ) {
        modalAvisoInadimplenteRef?.current?.abrir()
      }
    } catch (error) {
      definirErro({
        mensagens: 'Erro ao carregar a recuperação.'
      })
      definirPronto(true)
    }
  }

  useEffect(() => {
    obterDadosRecuperacao()
  }, [])

  const aceiteInscricaoRecuperacao = async (dados: ValoresRecuperacao) => {
    try {
      const payload = {
        formaPagamento: dados.formaPagamento,
        dadosCartao:
          dados.formaPagamento ===
          FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito]
            ? {
                nomeImpresso: dados.nomeCartao,
                numero: dados.numeroCartao,
                validade: dados.validadeCartao,
                codigo: dados.codigoSeguranca,
                parcelas: dados.parcelas
              }
            : null
      }

      await Api.EnviarInformacoesPagamentoRecuperacao(id, payload)

      toast('Inscrição feita com sucesso!', { type: 'success' })
      history.push(`/sala-aula/outros-cursos/${id}`)
    } catch (erro) {
      toast('Erro ao fazer inscrição na recuperação.', { type: 'error' })
    }
  }

  return (
    <>
      {pronto && !erro ? (
        <>
          <>
            <h1>{dadosRecuperacao?.nomeCurso}</h1>
            {dadosRecuperacao?.disciplinas?.length > 0 ? (
              <>
                <Titulo>
                  <h3>Recuperação de Disciplinas</h3>
                  <p>
                    • Esta modalidade é exclusiva para alunos reprovados em até
                    40% das disciplinas;
                  </p>
                  <p>
                    • Você terá acesso exclusivamente às disciplinas que
                    precisam ser finalizados, não sendo possível realizar
                    atividades de outras disciplinas;
                  </p>
                  <p>
                    • O tempo para realizar cada disciplina contratado será de{' '}
                    {dadosRecuperacao?.tempoRecuperacao} meses, não sendo
                    possível, em hipótese alguma, estender o prazo estipulado;
                  </p>
                  <p>
                    • O prazo máximo para a realização da atividade avaliativa
                    da disciplina será a data fim da recuperação;
                  </p>
                  <p>
                    • Em caso de pendências em atividades complementares, estas
                    devem ser anexadas em um único arquivo igualmente até o
                    término do período;
                  </p>
                  <p>
                    • Caso haja nova reprovação será necessário reingressar em
                    uma nova turma para concluir o curso;
                  </p>

                  <h2>
                    Atenção! Após a confirmação da solicitação, não haverá como
                    cancelar o pedido de recuperação e o pagamento deverá
                    realizado de forma integral.
                  </h2>
                </Titulo>

                <Tabela>
                  <thead>
                    <tr>
                      <CelulaModuloENota>Disciplina</CelulaModuloENota>
                      <CelulaModuloENota>Nota</CelulaModuloENota>
                    </tr>
                  </thead>
                  <tbody>
                    {dadosRecuperacao?.disciplinas?.map((disciplina, index) => (
                      <Linha key={index}>
                        <Modulo>{disciplina.nome}</Modulo>
                        <Nota>{disciplina.nota ?? 'N/A'}</Nota>
                      </Linha>
                    ))}
                  </tbody>
                </Tabela>

                <ComponenteInformacoes>
                  <p>
                    <span>
                      Valor da Recuperação para cada 20% das Disciplinas:
                    </span>{' '}
                    R$ {dadosRecuperacao?.valorRecuperacao}
                  </p>
                  <p>
                    <span>Valor total:</span> R${' '}
                    {dadosRecuperacao?.valorRecuperacao *
                      dadosRecuperacao?.quantidade}
                  </p>
                  <p>
                    <span>
                      Tempo para cursar a recuperação de cada Disciplina:
                    </span>{' '}
                    {dadosRecuperacao?.tempoRecuperacao} meses
                  </p>
                  <p>
                    <span>Tempo total:</span>{' '}
                    {dadosRecuperacao?.tempoRecuperacao *
                      dadosRecuperacao?.quantidade}{' '}
                    meses
                  </p>
                </ComponenteInformacoes>
              </>
            ) : (
              <>
                <Titulo>
                  <h3>Recuperação de Módulos</h3>
                  <p>
                    • Esta modalidade é exclusiva para alunos reprovados em até
                    02 módulos;
                  </p>
                  <p>
                    • Você terá acesso exclusivamente aos módulos que precisam
                    ser finalizados, não sendo possível realizar atividades de
                    outros módulos;
                  </p>
                  <p>
                    • O tempo para realizar cada módulo contratado será de{' '}
                    {dadosRecuperacao?.tempoRecuperacao}
                    meses, não sendo possível, em hipótese alguma, estender o
                    prazo estipulado;
                  </p>
                  <p>
                    • O prazo máximo para a realização da atividade avaliativa
                    do módulo será a data fim da recuperação;
                  </p>
                  <p>
                    • Em caso de pendências em atividades complementares, estas
                    devem ser anexadas em um único arquivo igualmente até o
                    término do período;
                  </p>
                  <p>
                    • Caso haja nova reprovação será necessário reingressar em
                    uma nova turma para concluir o curso;
                  </p>

                  <h2>
                    Atenção! Após a confirmação da solicitação, não haverá como
                    cancelar o pedido de recuperação e o pagamento deverá
                    realizado de forma integral.
                  </h2>
                </Titulo>

                <Tabela>
                  <thead>
                    <tr>
                      <CelulaModuloENota>Módulo</CelulaModuloENota>
                      <CelulaModuloENota>Nota</CelulaModuloENota>
                    </tr>
                  </thead>
                  <tbody>
                    {dadosRecuperacao?.modulos?.map((modulo, index) => (
                      <Linha key={index}>
                        <Modulo>{modulo.nome}</Modulo>
                        <Nota>{modulo.nota ?? 'N/A'}</Nota>
                      </Linha>
                    ))}
                  </tbody>
                </Tabela>

                <ComponenteInformacoes>
                  <p>
                    <span>Valor da Recuperação por Módulo:</span> R${' '}
                    {dadosRecuperacao?.valorRecuperacao}
                  </p>
                  <p>
                    <span>Valor total:</span> R${' '}
                    {dadosRecuperacao?.valorRecuperacao *
                      dadosRecuperacao?.quantidade}
                  </p>
                  <p>
                    <span>Tempo para cursar a recuperação de cada Módulo:</span>{' '}
                    {dadosRecuperacao?.tempoRecuperacao} meses
                  </p>
                  <p>
                    <span>Tempo total:</span>{' '}
                    {dadosRecuperacao?.tempoRecuperacao *
                      dadosRecuperacao?.quantidade}{' '}
                    meses
                  </p>
                </ComponenteInformacoes>
              </>
            )}

            <ComponenteBotoes>
              <Botao
                texto="Ir para o curso"
                tema="Link"
                type="button"
                onClick={() => history.push(`/sala-aula/outros-cursos/${id}`)}
              />
              <Botao
                texto="Não"
                tema="Secundario"
                type="button"
                onClick={() => {
                  modalCancelamentoRef?.current?.abrir()
                }}
              />
              <Botao
                texto="Sim"
                tema="Primario"
                type="button"
                onClick={() =>
                  modalInscricaoRecuperacaoRef?.current?.abrir(
                    dadosRecuperacao?.valorRecuperacao *
                      dadosRecuperacao?.quantidade,
                    dadosRecuperacao?.quantidadeMaximaParcelas
                  )
                }
              />
            </ComponenteBotoes>
            <Modal
              ref={modalCancelamentoRef}
              backdrop
              id="modal-aviso-data-recuperacao"
              titulo="Atenção"
              acaoPrimaria={{
                titulo: 'OK',
                tipo: 'button',
                acao: () => history.push(RotasAluno.Dashboard)
              }}
              acaoSecundario={{
                titulo: 'Cancelar',
                tipo: 'button',
                acao: () => modalCancelamentoRef?.current?.fechar()
              }}
            >
              <p style={{ textAlign: 'justify' }}>
                Você escolheu não realizar a recuperação neste momento. Caso
                você mude de ideia, é possível retornar para essa página através
                o link enviado no seu e-mail ou pelo seu curso na página
                inicial.
              </p>
            </Modal>
            <ModalInscricaoRecuperacao
              ref={modalInscricaoRecuperacaoRef}
              backdrop
              id="modal-de-cancelamento"
              acaoPrimaria={aceiteInscricaoRecuperacao}
            />
            <Modal
              ref={modalAvisoInadimplenteRef}
              backdrop
              id="modal-aviso-inadimplente"
              titulo="Aviso"
              acaoPrimaria={{
                titulo: 'OK',
                tipo: 'button',
                acao: () => history.push(RotasAluno.Dashboard)
              }}
            >
              <ConteudoModalInadimplente>
                <p>
                  Contratação não disponível para alunos com pendências
                  financeiras. Para realização desta modalidade, é necessário
                  estar com as suas parcelas em dia.
                </p>
                <p>
                  Converse com nosso time Financeiro através do telefone
                  (51)3062-9322 ou{' '}
                  <Link to={'/aluno/painel-financeiro'}>
                    <span>Clique aqui</span>
                  </Link>{' '}
                  para ir para o painel financeiro.
                </p>
                <p>
                  Se você já efetuou o pagamento via boleto, após a compensação
                  do pagamento a modalidade ficará disponível para contratação.
                </p>
              </ConteudoModalInadimplente>
            </Modal>
          </>
          <Modal
            ref={modalDataRecuperacaoIndisponivelRef}
            backdrop
            id="modal-recuperacao-indisponivel"
            titulo="Inscrição indisponível"
            acaoPrimaria={{
              titulo: 'OK',
              tipo: 'button',
              acao: () => history.push(RotasAluno.Dashboard)
            }}
            acaoSecundario={{
              titulo: 'Reingresso',
              tipo: 'button',
              acao: () =>
                history.push(`/reingresso/${dadosRecuperacao?.reingressoId}`),
              desabilitado: dadosRecuperacao?.reingressoId == null
            }}
          >
            <CorpoModalIndisponivel>
              <p>
                A contratação da recuperação não está disponível para esta
                matrícula.
              </p>
              <p>Verifique a data de finalização do seu curso.</p>
            </CorpoModalIndisponivel>
          </Modal>
        </>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando dados da recuperação..." />
      ) : (
        <ErroContainerLayout />
      )}
    </>
  )
}

export const PaginaRecuperacao: React.FC = props => (
  <ErroLayoutContainer.Provider>
    <Recuperacao {...props} />
  </ErroLayoutContainer.Provider>
)
