import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaProrrogacaoMatricula = (): TabelaColuna => ({
  Chave: 'prorrogacaoMatricula',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <span>{linha.prorrogacaoMatricula}</span>,
  RenderizarCabecalho: () => (
    <div style={{ maxWidth: 200, minWidth: 100 }}>Prorrogação Matrícula</div>
  )
})
