import styled from 'styled-components'

import { Cores, Medias } from '../styles'

export const Barra = styled.header`
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 72px;
  background-color: ${Cores.BRANCO};
  display: flex;
  align-items: center;

  @media ${Medias.TABLET.MAX} {
    background-color: ${Cores.SECUNDARIA_2_ESCURO};
  }
`
