import React from 'react'

import {
  DragAndDrop,
  IconeAdicionar,
  IconeInformacao,
  SubTitulo
} from 'src/componentes'

import {
  BotaoIcone,
  ContainerLinha,
  ContainerListaMaterial,
  LinhaComponenteDraggable,
  LinhaInformacoesDraggable,
  LinhaInformacoesTituloDraggable,
  SemDados
} from '../../styles'
import { DisciplinaDraggable } from '../../tipos'

interface IListaMaterialProps {
  materiais: DisciplinaDraggable[]
  setMateriais: (items: DisciplinaDraggable[]) => void
  remover: (item: number) => void
  adicionar: () => void
}

export const ListaDisciplina: React.FC<IListaMaterialProps> = ({
  materiais,
  setMateriais,
  adicionar,
  remover
}) => {
  const renderMaterial = (material: DisciplinaDraggable) => {
    return (
      <LinhaInformacoesDraggable>
        <p>{material.nome}</p>
        <p>{material.cargaHoraria}</p>
      </LinhaInformacoesDraggable>
    )
  }

  return (
    <ContainerListaMaterial>
      <SubTitulo texto="Disciplinas" />
      <ContainerLinha>
        <LinhaComponenteDraggable>
          <span>{IconeInformacao} </span>
          <span>
            Arraste e solte os itens abaixo para ordená-los. Esta será a ordem
            de exibição das disciplinas no curso livre ou evento.
          </span>
          <BotaoIcone type="button" onClick={adicionar}>
            {IconeAdicionar} Vincular Disciplina
          </BotaoIcone>
        </LinhaComponenteDraggable>
      </ContainerLinha>
      <LinhaInformacoesTituloDraggable>
        <p>Disciplina</p>
        <p>Carga Horária</p>
      </LinhaInformacoesTituloDraggable>

      {materiais.length > 0 ? (
        <DragAndDrop<DisciplinaDraggable>
          render={item => renderMaterial(item)}
          items={materiais.map((x, i) => {
            x.ordem = i
            return x
          })}
          setItems={setMateriais}
          remover={remover}
        />
      ) : (
        <SemDados>Nenhuma disciplina vinculada.</SemDados>
      )}
    </ContainerListaMaterial>
  )
}
