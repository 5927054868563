import { Cores } from 'src/componentes/styles/cores'
import { Tooltip } from 'src/componentes/tooltip'
import { TooltipProps } from 'src/componentes/tooltip/tipos'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Icone = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

export const Status = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: ${Cores.CINZA_2};
  margin-right: 8px;
`

export const TooltipComEstilo = styled(Tooltip)<TooltipProps>`
  max-width: 350px;
`
