import { AxiosRequestConfig } from 'axios'
import { saveAs } from 'file-saver'
import { nomeArquivoContentDisposition, SelectOpcao } from 'src/componentes'
import { gatewayApi } from 'src/servicos/instancias-api'
import {
  SalaAulaOutros,
  SalaAulaOutrosAvaliacao,
  NotasAvaliacaoTCC,
  OpcaoTCC,
  DadosDoCartaoDeCredito,
  FormaPagamentoCobranca
} from 'src/tipos'

import {
  EnviarAvaliacaoResposta,
  Notas,
  InfoTCC,
  InfoProrrogacao,
  ProrrogacaoManual,
  ProfessoresPesquisaSatisfacaoDisciplina,
  PossuiArquivoAproveitamentoTCC
} from './tipos'

export const RequisitarSalaAulaPorId = async (
  id: string
): Promise<SalaAulaOutros> => {
  const { data: salaAula } = await gatewayApi.get<SalaAulaOutros>(
    `/salaAulaEad/curso-ead/${id}`
  )

  return salaAula
}

export const MarcarMaterial = async (
  materialId: string,
  idSalaAula: string,
  lido: boolean
): Promise<{ progresso: number }> => {
  const resposta = await gatewayApi.put<{ progresso: number }>(
    `/salaAulaEad/curso-ead/${idSalaAula}/materiais`,
    { materialId, lido }
  )

  return resposta.data
}

export const AtualizarTempoVideoEad = async (
  materialId: string,
  idSalaAula: string,
  url: string,
  isAulaEad: boolean
): Promise<SalaAulaOutros> => {
  const resposta = await gatewayApi.put(
    `/salaAulaEad/curso-ead/${idSalaAula}/atualizar-video`,
    { materialId, url, isAulaEad }
  )

  return resposta.data
}

export const getMaterialVideoUrlEad = async (
  materialId: string,
  salaAulaId: string
): Promise<string> => {
  const result = await gatewayApi.get<string>(
    `/salaAulaEad/curso-ead/${salaAulaId}/${materialId}/obter-url`
  )

  return result.data
}

export const DownloadMaterial = async (
  id: string,
  idSalaAula: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${idSalaAula}/${id}/arquivo`,
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf'
      }
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}

export const DownloadMaterialTCC = async (
  id: string,
  idSalaAula: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${idSalaAula}/${id}/arquivo-tcc`,
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf'
      }
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}

export const DownloadMaterialComplementar = async (
  id: string,
  idSalaAula: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${idSalaAula}/complementar/${id}/arquivo`,
    {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}

export const DownloadAvalicaoPorArquivo = async (id: string): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo/obter-arquivo`,
    {
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}

export const DownloadAvaliacaoArquivoEnviado = async (
  id: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo/obter-ultima-avaliacao-enviada`,
    {
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}

export const RequisitarAvaliacaoMultiplaEscolhaPorId = async (
  id: string
): Promise<SalaAulaOutrosAvaliacao> => {
  const resposta = await gatewayApi.get<SalaAulaOutrosAvaliacao>(
    `salaAulaEad/curso-ead/${id}/avaliacao-por-pergunta`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const RequisitarAvaliacaoArquivoPorId = async (
  id: string
): Promise<SalaAulaOutrosAvaliacao> => {
  const resposta = await gatewayApi.get<SalaAulaOutrosAvaliacao>(
    `salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const RequisitarMaterialPorId = async (
  id: string,
  idSalaAula: string
): Promise<string> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${idSalaAula}/${id}/arquivo`,
    {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const RequisitarMaterialTCCPorId = async (
  id: string,
  idSalaAula: string
): Promise<string> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${idSalaAula}/${id}/arquivo-tcc`,
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf'
      }
    }
  )

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const UploadAvaliacaoArquivo = async (
  arquivo: File,
  idSalaAula: string,
  id: string
): Promise<{ dataHoraEnvioAvaliacao: string }> => {
  const bodyFormData = new FormData()
  bodyFormData.append('arquivo', arquivo)

  const { data } = await gatewayApi.post<{
    dataHoraEnvioAvaliacao: string
    sucesso: boolean
  }>(
    `/salaAulaEad/curso-ead/${idSalaAula}/${id}/upload-avaliacao-por-arquivo`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )

  return data
}

export const EnviarAvaliacaoPorPerguntas = async (
  id: string,
  idSalaAula: string,
  payload: {
    perguntasComRespostas: { idPergunta: string; idResposta: string }[]
  }
): Promise<EnviarAvaliacaoResposta> => {
  const { data } = await gatewayApi.post<EnviarAvaliacaoResposta>(
    `/salaAulaEad/curso-ead/${idSalaAula}/${id}/avaliacao-por-pergunta`,
    payload
  )

  return data
}

export const RequisitarNotas = async (idMatricula: string): Promise<Notas> => {
  const { data } = await gatewayApi.get<Notas>(
    `salaAulaEad/curso-ead/${idMatricula}/notas`
  )

  return data
}

export const RequisitarInformacoesTCC = async (
  salaAulaId: string
): Promise<InfoTCC> => {
  const { data } = await gatewayApi.get<InfoTCC>(
    `salaAulaEad/curso-ead/${salaAulaId}/tcc`
  )

  return data
}

export const EnviarAceiteTCC = async (
  salaAulaId: string,
  quantidadeParcelas: number,
  professorOrientador: string,
  professorCoorientador: string,
  diaVencimento: number,
  opcaoTCC: OpcaoTCC,
  cupomId?: string,
  formaPagamento?: FormaPagamentoCobranca,
  dadosCartao?: DadosDoCartaoDeCredito
): Promise<boolean> => {
  const payload = {
    quantidadeParcelas,
    professorOrientadorId: professorOrientador,
    professorBancaId: professorCoorientador,
    diaVencimento,
    opcaoTCC,
    cupomId,
    formaPagamento,
    dadosCartao
  }

  const resposta = await gatewayApi.post(
    `salaAulaEad/curso-ead/${salaAulaId}/tcc`,
    payload
  )

  return resposta.status === 200
}

export const RequisitarAvaliacaoTCCArquivoPorId = async (
  id: string
): Promise<SalaAulaOutrosAvaliacao> => {
  const resposta = await gatewayApi.get<SalaAulaOutrosAvaliacao>(
    `salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo/tcc`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const UploadAvaliacaoTCCArquivo = async (
  arquivo: File,
  idSalaAula: string,
  id: string
): Promise<{ dataHoraEnvioAvaliacao: string }> => {
  const bodyFormData = new FormData()
  bodyFormData.append('arquivo', arquivo)

  const { data } = await gatewayApi.post<{
    dataHoraEnvioAvaliacao: string
    sucesso: boolean
  }>(
    `/salaAulaEad/curso-ead/${idSalaAula}/${id}/upload-avaliacao-por-arquivo/tcc`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )

  return data
}

export const DownloadAvaliacaoTCCArquivoEnviado = async (
  id: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo/obter-ultima-avaliacao-enviada/tcc`,
    {
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}

export const MarcarMaterialTCC = async (
  materialId: string,
  idSalaAula: string,
  lido: boolean
): Promise<{ progresso: number }> => {
  const resposta = await gatewayApi.put<{ progresso: number }>(
    `/salaAulaEad/curso-ead/${idSalaAula}/materiais-tcc`,
    { materialId, lido }
  )

  return resposta.data
}

export const DownloadAvalicaoTCCPorArquivo = async (
  id: string
): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo/obter-arquivo/tcc`,
    {
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}

export const EnviarRecusaTCC = async (
  salaAulaId: string,
  opcaoTCC: OpcaoTCC
): Promise<boolean> => {
  const payload = { opcaoTCC: opcaoTCC }
  const resposta = await gatewayApi.post(
    `salaAulaEad/curso-ead/${salaAulaId}/tcc-recusa`,
    payload
  )

  return resposta.status === 200
}

export const ObterNotasPorAvaliacaoTCC = async (
  avaliacaoId: string
): Promise<NotasAvaliacaoTCC> => {
  const { data } = await gatewayApi.get<NotasAvaliacaoTCC>(
    `salaAulaEad/curso-ead/${avaliacaoId}/avaliacao-por-arquivo/obter-feedback-avaliacao-enviada/tcc`
  )
  return data
}

export const AproveitarDisciplina = async (
  salaAulaEadId: string,
  disciplinaId: string,
  aproveitamento: boolean
): Promise<{ progresso: number }> => {
  const resposta = await gatewayApi.post<{ progresso: number }>(
    `/salaAulaEad/curso-ead/${salaAulaEadId}/aproveitamento-disciplina`,
    { disciplinaId, aproveitamento }
  )

  return resposta.data
}

export const RequisitarMaterialComplementarPorId = async (
  id: string,
  idSalaAula: string
): Promise<string> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${idSalaAula}/complementar/${id}/arquivo`,
    {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }
  )

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const MarcarMaterialComplementar = async (
  materialId: string,
  idSalaAula: string,
  lido: boolean
): Promise<{ progresso: number }> => {
  const resposta = await gatewayApi.put<{ progresso: number }>(
    `/salaAulaEad/curso-ead/${idSalaAula}/materiais-complementares`,
    { materialId, lido }
  )

  return resposta.data
}

export const AtualizarVisualizacaoNotaTCC = async (
  matriculaId: string
): Promise<void> =>
  await gatewayApi.post(
    `/salaAulaEad/curso-ead/${matriculaId}/nota-tcc-visualizada`
  )

export const EnviarAceiteProrrogacao = async (
  salaAulaId: string,
  opcaoProrrogacaoId: string,
  quantidadeParcelas: number,
  formaPagamento: FormaPagamentoCobranca,
  diaVencimento?: number,
  dadosCartao?: DadosDoCartaoDeCredito
): Promise<boolean> => {
  const payload = {
    prorrogacaoCursoId: opcaoProrrogacaoId,
    quantidadeParcelas,
    diaVencimento,
    formaPagamento,
    dadosCartao
  }

  const resposta = await gatewayApi.post(
    `salaAulaEad/curso-ead/${salaAulaId}/prorrogacao`,
    payload
  )

  return resposta.status === 200
}

export const RequisitarInformacoesProrrogacao = async (
  salaAulaId: string
): Promise<InfoProrrogacao> => {
  const { data } = await gatewayApi.get<InfoProrrogacao>(
    `salaAulaEad/curso-ead/${salaAulaId}/prorrogacao`
  )

  return data
}

export const AlterarTituloTCC = async (
  idSalaAula: string,
  tituloTCC: string
): Promise<EnviarAvaliacaoResposta> => {
  const { data } = await gatewayApi.post<EnviarAvaliacaoResposta>(
    `salaAulaEad/curso-ead/${idSalaAula}/tituloTCC`,
    tituloTCC
  )

  return data
}

export const RealizarProrrogacaoManual = async (
  matriculaId: string,
  payload: ProrrogacaoManual
): Promise<boolean> => {
  const resposta = await gatewayApi.post(
    `salaAulaEad/curso-ead/${matriculaId}/prorrogacao-manual`,
    payload
  )

  return resposta.status === 200
}

export const CarregarProfessoresPesquisaSatisfacaoDisciplina = async (
  salaAulaEadId: string,
  disciplinaId: string
): Promise<SelectOpcao[]> => {
  try {
    const {
      data
    } = await gatewayApi.get<ProfessoresPesquisaSatisfacaoDisciplina>(
      `salaAulaEad/curso-ead/${salaAulaEadId}/${disciplinaId}/pesquisa-satisfacao-professores`
    )

    return (
      data?.professores?.map(x => {
        return {
          id: x.professorId,
          texto: x.nome
        }
      }) ?? []
    )
  } catch {
    return []
  }
}

export const ObterPossuiArquivoAproveimentoTCC = async (
  idSalaAula: string
): Promise<boolean> => {
  const { data } = await gatewayApi.get<PossuiArquivoAproveitamentoTCC>(
    `salaAulaEad/curso-ead/${idSalaAula}/arquivo-aproveitamento-tcc`
  )

  return data.possui
}
export const UploadAvaliacaoAproveitamentoTCC = async (
  arquivo: File | string,
  idSalaAula: string
): Promise<boolean> => {
  const bodyFormData = new FormData()
  bodyFormData.append('arquivo', arquivo)

  const resultado = await gatewayApi.put(
    `/salaAulaEad/curso-ead/${idSalaAula}/arquivo-aproveitamento-tcc`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )

  return resultado.status === 200
}

export const ObterDownloadArquivoAproveitamentoTCC = async (
  idSalaAula: string
): Promise<void> => {
  const rota = `/salaAulaEad/curso-ead/${idSalaAula}/arquivo-aproveitamento-tcc/download`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )

    saveAs(resposta.data, nomeArquivo)
  }
}
