import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaDisciplinasAproveitadas = (): TabelaColuna => ({
  Chave: 'disciplinasAproveitadas',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 100 }}>Disciplinas Aproveitadas</div>
  )
})
