import { Medias } from 'src/compartilhados/modulos/responsivo'
import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px 0;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    ${Medias.Mobile} {
      width: 100%;
    }

    & > svg {
      width: 64px;
      height: 64px;
    }

    & > p {
      margin-top: 29px;
      color: ${Cores.CINZA_2};
      font-weight: 300;
      font-size: 20px;
      line-height: 35px;
      text-align: center;
    }

    & > p + div {
      margin-top: 29px;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      & > button + button {
        margin-left: 24px;
      }
    }
  }
`
