import React from 'react'

import PropTypes from 'prop-types'

import { useTema } from '../contextos/tema'
import { Barra, Fundo } from './styles'
import { BarraLateralProps } from './tipos'

export const BarraLateral: React.FC<
  React.HtmlHTMLAttributes<HTMLDivElement> & BarraLateralProps
> = ({ children, ...rest }) => {
  const [{ menuExpandido }, dispatchTema] = useTema()

  const retrairMenu = () => dispatchTema({ tipo: 'expandir-retrair-menu' })

  return (
    <>
      <Barra expandido={menuExpandido} {...rest}>
        {children}
      </Barra>
      <Fundo expandido={menuExpandido} onClick={retrairMenu} />
    </>
  )
}

BarraLateral.propTypes = {
  children: PropTypes.element.isRequired
}
