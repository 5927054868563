import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Filtro,
  InputUnform,
  Tabela,
  TabelaRef,
  TabelaResposta,
  TabelaDados,
  formatarCNPJ,
  ContainerAcoes,
  Tooltip,
  IconeEditar
} from 'src/componentes'
import { CnpjInputUnform } from 'src/componentes/unform/input/cnpj'
import { RotasSplitPagamento } from 'src/rotas/academico'
import { Api } from 'src/servicos'
import { TipoOrdenacao } from 'src/tipos'

import { Campo, Container, Dados, Form } from './styles'

interface DadosPesquisa {
  nome: string
  cnpj: string
}

export const PaginaSplitPagamento: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const [dadosPesquisa, setDadosPesquisa] = useState<DadosPesquisa | null>(null)
  const history = useHistory()
  const [paginas, setPaginas] = useState<number | null>(null)

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current?.IrParaPagina()
    tabelaRef.current?.CarregarDados()
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      setPaginas(1)
    }
    setDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    qtdRegistros: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterDetalhesTurmaSplit(
      dadosPesquisa?.nome,
      dadosPesquisa?.cnpj,
      numeroPaginas,
      qtdRegistros,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    setPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados?.opcoesPaginacao?.pagina || 1,
        TotalRegistros: dados?.opcoesPaginacao?.totalRegistros || 1
      }
    } as TabelaResposta
  }

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa Parceiros Split"
          atalhos={[
            {
              texto: 'Financeiro'
            },
            {
              texto: 'Parceiros Split'
            }
          ]}
        />
        <Botao
          type="button"
          texto="Incluir"
          tema="Secundario"
          onClick={() => history.push(RotasSplitPagamento.Cadastro)}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo>
              <InputUnform
                id="nome"
                name="nome"
                label="Nome"
                type="text"
                maxLength={200}
              />
            </Campo>
            <Campo>
              <CnpjInputUnform type="text" name="cnpj" label="CNPJ" />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nome',
              Texto: 'Nome',
              ComOrdenacao: true
            },
            {
              Chave: 'cnpj',
              Texto: 'CNPJ',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>{formatarCNPJ(linha.cnpj)}</>
              )
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    data-tip="Editar"
                    data-for={`${linha.id}-tooltip-editar`}
                    onClick={() =>
                      history.push(`${RotasSplitPagamento.Edicao}/${linha.id}`)
                    }
                  >
                    {IconeEditar}
                  </button>
                  <Tooltip
                    id={`${linha.id}-tooltip-editar`}
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                </ContainerAcoes>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export { default as PaginaCadastroSplitPagamento } from './cadastro'
