import { TipoOrdenacao } from 'src/tipos'

export interface TabelaDados {
  [key: string]: any
}

export interface TabelaPaginacao {
  Pagina: number
  TotalRegistros: number
}

export interface TabelaResposta {
  Dados: TabelaDados[]
  Paginacao?: TabelaPaginacao
}

export interface TabelaColuna {
  Chave: string
  Texto?: string
  ComOrdenacao?: boolean
  RenderizarCabecalho?: () => JSX.Element
  Renderizar?: (linha: TabelaDados) => JSX.Element
  Alinhamento?: 'left' | 'center' | 'right' | 'justify' | 'char'
  StyleColuna?: React.CSSProperties
  ComLimparTodosFiltros?: {
    Disabled: () => boolean
    Acao: () => Promise<void>
  }
}

export interface TabelaRef {
  ObterDados: () => TabelaDados[]
  CarregarDados: (carregandoInstantaneo?: boolean) => Promise<void>
  DesmarcarTodos: () => void
  IrParaPagina: (pagina?: number) => void
}

export interface TabelaLinhaProps {
  Id?: string
  Indice: number
  Colunas: TabelaColuna[]
  Dados: TabelaDados
  ComMarcacao: boolean
  ComMarcacaoCondicao?: (linha: TabelaDados) => boolean
  MarcarDesmarcar?: (marcado: boolean, indice: number) => void | undefined
  TemaLinha: TemaLinha
}

export type TabelaTamanhoPagina = 10 | 25 | 50 | 100

export type TabelaEstilosPaginacao = 'Simples' | 'Complexo'

export interface TabelaPaginacaoEstado {
  Pagina: number
  TamanhoPagina: TabelaTamanhoPagina
  TotalRegistros: number
  TotalPaginas: number
  Estilo: TabelaEstilosPaginacao
}

export type TabelaPaginacaoAcoes =
  | { tipo: 'proxima-pagina' }
  | { tipo: 'pagina-anterior' }
  | { tipo: 'ir-para-pagina'; pagina: number }
  | { tipo: 'alterar-tamanho-pagina'; tamanhoPagina: number }
  | { tipo: 'alterar-totais'; totalRegistros: number }

export type TabelaPaginacaoDispatch = (acao: TabelaPaginacaoAcoes) => void

export interface TabelaPaginacaoProviderProps {
  TamanhoPagina: TabelaTamanhoPagina
  Estilo: TabelaEstilosPaginacao
  children: React.ReactElement
}

export enum TemaLinha {
  Padrao = 'PRIMARIA_1',
  Atencao = 'ATENCAO',
  Sucesso = 'SUCESSO_ESCURO',
  Erro = 'PERIGO_ERRO',
  Pago = 'PRIMARIA_2',
  Renegociado = 'RENEGOCIADA',
  Negativado = 'NEGATIVADA'
}

export interface TabelaProps {
  Id?: string
  Colunas: TabelaColuna[]
  ObterDados: (
    pagina: number,
    tamanhoPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ) => Promise<TabelaResposta | null>
  RenderizarSemDados?: () => JSX.Element
  ComMarcacao?: boolean
  ComMarcacaoCondicao?: (linha: TabelaDados) => boolean
  OnChangeMarcacao?: (dados: TabelaDados[]) => void
  LinhaPreSelecionada?: (linha: TabelaDados) => boolean
  ComPaginacao?: boolean
  TamanhoPagina?: TabelaTamanhoPagina
  EstiloPaginacao?: TabelaEstilosPaginacao
  ref?: any
  MostrarCarregandoInstantaneo?: boolean
  TextoSemResultados?: string
  TemaLinha?: (linha: TabelaDados) => TemaLinha
  className?: string
  desmarcarTodos?: boolean
}
