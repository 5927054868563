import { useRef, useState } from 'react'

import { createContainer } from 'unstated-next'

import { ModalRef } from '../modal/tipos'
import { StepperEstado, StepperItem } from './tipos'

const useStepper = (initialState?: { passo?: number }) => {
  const [estado, definirEstado] = useState<StepperEstado>({
    passo: 1,
    ...initialState,
    itensMarcados: [],
    passosBloqueados: [],
    modalRef: useRef<ModalRef>(null)
  } as StepperEstado)

  const voltarPrimeiroItemStepper = () => {
    definirEstado(old => ({
      ...old,
      passo: 1
    }))
  }

  const voltarStepper = () => {
    const naoPodeVoltar = estado.passosBloqueados.some(
      p => p === estado.passo - 1
    )

    if (!naoPodeVoltar) {
      definirEstado(old => ({
        ...old,
        passo: old.passo - 1
      }))
    }
  }

  const avancarStepper = () => {
    const naoPodeAvancar = estado.passosBloqueados.some(
      p => p === estado.passo + 1
    )

    if (!naoPodeAvancar) {
      definirEstado(old => ({
        ...old,
        passo: old.passo + 1
      }))
    }
  }

  const definirPassoStepper = (passo: number) => {
    definirEstado(old => ({
      ...old,
      passo
    }))
  }

  const definirOnClickConcluir = (
    onClickConcluir: (
      id: string,
      concluido: boolean,
      itens: string[],
      tcc?: boolean,
      complementar?: boolean
    ) => void | Promise<void>
  ) => {
    definirEstado(old => ({
      ...old,
      onClickConcluir
    }))
  }

  const definirOnClickDownload = (
    onClickDownload: (
      id: string,
      tcc?: boolean,
      complementar?: boolean
    ) => void | Promise<void>
  ) => {
    definirEstado(old => ({
      ...old,
      onClickDownload
    }))
  }

  const definirOnClickGetBlob = (
    onClickGetBlob: (subItem: StepperItem) => Promise<string>
  ) => {
    definirEstado(old => ({
      ...old,
      onClickGetBlob
    }))
  }

  const definirItensMarcados = (itens: string[]) => {
    definirEstado(old => ({
      ...old,
      itensMarcados: itens
    }))
  }
  const definirPassosBloqueados = (itens: number[]) => {
    definirEstado(old => ({
      ...old,
      passosBloqueados: itens
    }))
  }

  const abrirModalNotificacaoMotivoBloqueio = (texto: JSX.Element | string) => {
    estado?.modalRef?.current?.abrir(texto)
  }

  const fecharModalNotificacaoMotivoBloqueio = () => {
    estado?.modalRef?.current?.fechar()
  }
  return {
    ...estado,
    voltarPrimeiroItemStepper,
    voltarStepper,
    avancarStepper,
    definirPassoStepper,
    definirOnClickConcluir,
    definirOnClickDownload,
    definirOnClickGetBlob,
    definirItensMarcados,
    definirPassosBloqueados,
    abrirModalNotificacaoMotivoBloqueio,
    fecharModalNotificacaoMotivoBloqueio
  }
}

export default createContainer(useStepper)
