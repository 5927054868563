import { Botao } from 'src/componentes/botao'
import { Cores } from 'src/componentes/styles/cores'
import styled, { css } from 'styled-components'

export const ContainerDaPagina = styled.div``

export const TituloPrincipal = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
`

export const ContainerDosBotoes = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const SubMenu = styled.h1`
  margin-top: 5px;
  margin-bottom: 15px;

  font-style: normal;
  font-size: 20px;
  line-height: 35px;
`
export const ContainerAcoes = styled.div`
  display: flex;
  align-items: center;
`

export const IconeAcao = styled.div<{ disabled?: boolean }>`
  display: flex;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-right: 15px;

  svg {
    width: 23px;
    height: 23px;
  }

  svg path {
    fill: rgb(102, 102, 102);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const Container = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 0px 30px 0px;
`

export const TextoSelect = styled.p<{ ml?: boolean }>`
  font-size: 18px;
  margin-right: 10px;
  ${({ ml }) =>
    ml &&
    css`
      margin-left: 20px;
    `}
`

export const Campo = styled.div`
  flex: 1;
`
export const ContainerRequerimentos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const RequerimentoDisponivel = styled.button`
  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;

  div {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    padding: 10px 30px 10px 10px;
  }

  span {
    font-size: 20px;
    font-weight: 400;
    color: white;
    border-radius: 50%;
    padding: 6px 6.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Cores.SECUNDARIA_2_ESCURO};
  }

  p {
    font-size: 18px;
  }
`

export const ComponenteDetalhesRequerimentos = styled.button`
  display: flex;
  color: white;
  background-color: ${Cores.SECUNDARIA_2_ESCURO};
  padding: 2px 4px;
  border: none;
  cursor: pointer;
  padding: 10px;

  p {
    font-size: 16px;
  }
`

export const ContainerInformacoes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    text-align: justify;
    font-size: 16px;
  }
`

export const ContainerInformacoesObservacao = styled.div`
  width: 350px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    text-align: justify;
    font-size: 16px;
  }
`

export const CorpoModal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  padding: 30px 40px;
`

export const ContainerTabela = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding-right: 5px;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`
