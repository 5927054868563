import styled from 'styled-components'

export const ColunaInformacoesAcesso = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
  }
`

export const ContainerAbaDivisao = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`

export const DivisorAba = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
  }

  h5 {
    font-weight: 500;
  }
`

export const ColunaNotas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  flex: 1;
  overflow: auto;

  p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
  }

  .titulo-modulo {
    font-weight: 500;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const ColunaContratos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    flex: 2;
  }

  .titulo {
    font-weight: 500;
  }

  li {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ul:not(:last-child) {
    padding: 8px 0;
    border-bottom: 1px solid rgb(233, 233, 233);
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export const BotaoAcao = styled.button`
  background: transparent;
  border: none;
  opacity: 1;
  cursor: pointer;

  svg path {
    fill: #888;
  }
`
