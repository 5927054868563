import { UploadDocumentos } from '../tipos'

export function ResumoDeDocumentosDeSaidaMapper(
  documentos: UploadDocumentos[]
): FormData {
  if (documentos === null || documentos.length === 0) {
    return new FormData()
  }

  const form = new FormData()

  documentos.forEach(({ tipo, arquivo, nome, id }) => {
    if (nome && arquivo) {
      form.append(`${tipo}_${id}`, arquivo, nome)
    }
  })

  return form
}
