import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  tempoProrrogacao: Yup.number()
    .nullable()
    .required(mensagemPadrao)
    .positive('A quantidade de dias deve ser maior que zero'),
  valorProrrogacao: Yup.string()
    .nullable()
    .when('isento', {
      is: value => !value,
      then: field => field.required(mensagemPadrao)
    }),
  quantidadeParcelas: Yup.number()
    .nullable()
    .when('isento', {
      is: value => !value,
      then: field => field.required(mensagemPadrao)
    }),
  dataPrimeiroVencimento: Yup.string()
    .nullable()
    .when('isento', {
      is: value => !value,
      then: field => field.data().required(mensagemPadrao)
    })
})
