export interface Dados {
  usuario: string
  senha: string
}

export enum SituacaoDadosAlterado {
  Novo = 'Novo',
  Enviado = 'Enviado',
  Reenviar = 'Reenviar',
  Expirado = 'Expirado',
  Confirmado = 'Confirmado',
  Cancelado = 'Cancelado'
}

export interface UsuarioAlteracaoDados {
  nome: string
  celular: string
  situacao: SituacaoDadosAlterado
}
