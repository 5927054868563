import React, { useEffect, useRef, useState, useMemo } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'

import {
  Carregando,
  Cabecalho,
  Breadcrumb,
  Navegacao,
  Link,
  FormUnform,
  SubTitulo,
  ConteudoBotao,
  SelectUnform,
  Botao
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { IconeAdicionar, IconeAtencao, IconeBack } from 'src/componentes/icones'
import { OpcaoSelect } from 'src/componentes/select-async/tipos'
import { RotasAcademico } from 'src/rotas/academico'
import { Api } from 'src/servicos'
import {
  GrupoDiscussao,
  ModoPaginaCadastro,
  Turma,
  TipoGrupoDiscussao
} from 'src/tipos'
import * as Yup from 'yup'

import { GrupoDiscussaoComponente } from './componentes'
import { ModalIncluirTopico } from './componentes/grupo-discussao/modal-incluir-topico'
import { ModalIncluirTopicoRef } from './componentes/grupo-discussao/modal-incluir-topico/tipos'
import {
  Container,
  ContainerDados,
  ContainerLateral,
  ContainerLink,
  ContainerMain,
  ContainerIncluir,
  Conteudo,
  Secao,
  Botao as BotaoIcone
} from './styles'

export const schema = Yup.object().shape({})

export interface PaginaGrupoDiscussaoProps
  extends RouteComponentProps<{ id?: string }> {
  modo?: ModoPaginaCadastro
}

export interface OpcaoSelectDadosAdicionais<T> extends OpcaoSelect {
  dadosAdicionais: T
}
export enum Navegacoes {
  Topicos = 'Tópicos'
}

const Componente: React.FC<PaginaGrupoDiscussaoProps> = ({ match, modo }) => {
  const [pronto, definirPronto] = useState(false)
  const [turma, definirTurma] = useState<Turma>(null)
  const [grupoDiscussao, definirGrupoDiscussao] = useState(null)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const modalRef = useRef<ModalIncluirTopicoRef>(null)
  const [id] = useState(match?.params?.id)
  const history = useHistory()
  const [
    tipoGrupoDiscussao,
    definirTipoGrupoDiscussao
  ] = useState<TipoGrupoDiscussao>(TipoGrupoDiscussao.SalaAula)
  const [titulo] = useState(
    `${
      modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'
    } de Fórum de dúvidas`
  )

  const carregarGrupoDiscussao = async (turma: Turma) => {
    try {
      definirErro(undefined)
      definirGrupoDiscussao({} as GrupoDiscussao)

      const resposta = await Api.RequisitarGrupoDiscussaoPorTipo(
        turma?.id,
        tipoGrupoDiscussao
      )

      definirGrupoDiscussao(resposta)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar o Fórum de dúvidas')
    }
  }

  const carregarTurma = async () => {
    try {
      const result = await Api.RequisitarTurmaPorId(id)
      definirTurma(result)
      await carregarGrupoDiscussao(result)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar a turma')
    } finally {
      definirPronto(true)
    }
  }

  const grupoDiscussaoTCC = useMemo(() => {
    return (
      grupoDiscussao?.tipoGrupoDiscussao === TipoGrupoDiscussao.TCC ?? false
    )
  }, [grupoDiscussao])

  useEffect(() => {
    carregarTurma()
  }, [id])

  useEffect(() => {
    if (turma) {
      carregarGrupoDiscussao(turma)
    }
  }, [tipoGrupoDiscussao])

  return (
    <>
      <Container>
        <ContainerMain>
          <ContainerLink>
            <Link
              texto="Voltar"
              href={RotasAcademico.Turmas}
              icone={IconeBack}
            />
          </ContainerLink>
          <Cabecalho>
            <Breadcrumb
              titulo={titulo}
              atalhos={[
                {
                  texto: 'Acadêmico'
                },
                {
                  texto: titulo
                }
              ]}
            />
          </Cabecalho>
          <Conteudo>
            <>
              <FormUnform
                schema={schema}
                dadosIniciais={{ turma: turma?.nome }}
                acaoSucesso={() => null}
              >
                <Secao id={Navegacoes.Topicos}>
                  <SubTitulo texto="Tópicos" />
                  <SelectUnform
                    id="slct_modalidade"
                    name="turma"
                    label="Turma"
                    placeholder={turma?.nome}
                    opcoes={[]}
                    obrigatorio
                    disabled
                    valorAlterado={() => null}
                  />
                  <SelectUnform
                    id="slct_tipo_grupo_discussao"
                    name="tipoGrupoDiscussao"
                    placeholder="Selecione"
                    label="Tipo Grupo Discussão"
                    opcoes={[
                      {
                        id: TipoGrupoDiscussao.SalaAula,
                        texto: 'Fórum de dúvidas'
                      },
                      {
                        id: TipoGrupoDiscussao.TCC,
                        texto: 'Trabalho de Conclusão de Curso'
                      }
                    ]}
                    valorAlterado={valor => {
                      definirTipoGrupoDiscussao(TipoGrupoDiscussao[valor.id])
                    }}
                  />
                  {pronto && !erro ? (
                    <>
                      {!grupoDiscussaoTCC ? (
                        <>
                          <ContainerIncluir>
                            <BotaoIcone
                              type="button"
                              onClick={() => modalRef.current?.abrir(turma?.id)}
                            >
                              <ConteudoBotao>
                                {IconeAdicionar} Incluir Tópico
                              </ConteudoBotao>
                            </BotaoIcone>
                          </ContainerIncluir>
                          <ContainerDados>
                            <GrupoDiscussaoComponente
                              grupoDiscussao={grupoDiscussao}
                              recarregar={() => carregarGrupoDiscussao(turma)}
                            />
                          </ContainerDados>
                        </>
                      ) : (
                        <ContainerDados>
                          <GrupoDiscussaoComponente
                            grupoDiscussao={grupoDiscussao}
                          ></GrupoDiscussaoComponente>
                        </ContainerDados>
                      )}
                    </>
                  ) : !pronto && !erro ? (
                    <Conteudo>
                      <Carregando texto="Carregando Fórum de dúvidas ..." />
                    </Conteudo>
                  ) : (
                    <Conteudo>
                      <ContainerErroLayout>
                        <div>
                          {IconeAtencao}
                          <p>{erro}</p>
                          <div>
                            <Botao
                              tema="Padrao"
                              texto="Tentar novamente"
                              onClick={() => carregarGrupoDiscussao(turma)}
                              tamanho="S"
                            />
                          </div>
                        </div>
                      </ContainerErroLayout>
                    </Conteudo>
                  )}

                  <ContainerIncluir>
                    <BotaoIcone
                      type="button"
                      onClick={() => history.push(RotasAcademico.Turmas)}
                    >
                      <ConteudoBotao>Voltar</ConteudoBotao>
                    </BotaoIcone>
                  </ContainerIncluir>
                </Secao>
              </FormUnform>
            </>
          </Conteudo>
        </ContainerMain>
        <ContainerLateral>
          <Navegacao
            itens={[
              {
                link: Navegacoes.Topicos,
                nome: 'Tópicos'
              }
            ]}
          />
        </ContainerLateral>
      </Container>

      <ModalIncluirTopico
        backdrop
        ref={modalRef}
        acaoSucesso={() => carregarGrupoDiscussao(turma)}
      />
    </>
  )
}

export default withRouter(Componente)
