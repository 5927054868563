import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaModuloOrdemNome = (): TabelaColuna => ({
  Chave: 'moduloOrdemNome',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <span>{linha.moduloOrdemNome}</span>,
  RenderizarCabecalho: () => (
    <div style={{ width: 150 }}>Módulo Ordem e Nome</div>
  )
})
