import React from 'react'

import { Cores } from '../styles'

export default (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00007 1L4.88232 5.11775L9.00007 9.2355"
      stroke={Cores.SECUNDARIA_2_ESCURO}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.11775 1L1 5.11775L5.11775 9.2355"
      stroke={Cores.SECUNDARIA_2_ESCURO}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
