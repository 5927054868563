import { Medias } from 'src/compartilhados'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 50px;
`

export const TituloPrincipal = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;

  ${Medias.Tablet} {
    font-size: 22px;
  }

  ${Medias.Mobile} {
    font-size: 16px;
    line-height: 19px;
  }
`

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 38px;

  ${Medias.Mobile} {
    margin-left: 0px;
  }
`

export const Secao = styled.section`
  &:not(:first-of-type) {
    margin-top: 35px;
  }
`

export const ContainerUploadImagem = styled.div`
  display: flex;
  justify-content: flex-start;

  ${Medias.Tablet} {
    justify-content: center;
  }
`

export const Linha = styled.div`
  display: flex;
  flex: 1;
  gap: 19px;

  ${Medias.PontoMaximoParaTablets} {
    gap: 15px;
    flex-direction: column;
  }
`

export const ContainerDadosGrande = styled.div`
  flex: 3;
`

export const ContainerDadosMedio = styled.div`
  flex: 2;
`

export const ContainerDadosPequeno = styled.div`
  flex: 1;

  ${Medias.PontoMaximoParaTablets} {
    width: 100%;
  }
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerPrincipal = styled.div`
  flex: 4;

  ${Medias.PontoMaximoParaTablets} {
    width: 100%;
  }
`

export const ContainerLateral = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;

  ${Medias.PontoMaximoParaTablets} {
    display: none;
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
`

export const ContainerCPF = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  > div {
    flex: 1;
  }

  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 28px;
      height: 28px;
      fill: #666666;
    }

    &:hover {
      background: #f7f7f7;

      svg {
        fill: #0accb9;

        path {
          fill: #0accb9;
        }
      }
    }
  }
`
