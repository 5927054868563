import styled from 'styled-components'

export const Tabela = styled.div<{ isDraggingOver: boolean }>`
  width: 250;
`

export const DraggableContainer = styled.div<{
  isDragging: boolean
}>`
  background: ${({ isDragging }) => (isDragging ? 'lightgreen' : 'inherit')};
  min-width: 20px;
  border-top: 0.1px solid lightgrey;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  & > svg:first-child {
    margin-right: 10px;
  }

  &:last-child {
    border-bottom: 0.1px solid lightgrey;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
