import React from 'react'

export default (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#91C83E" />
    <path
      d="M11.834 5.54007L10.5796 4.28567L6.42778 8.38841L4.4679 6.42853L3.21349 7.68293L6.42778 10.8972L11.834 5.54007Z"
      fill="white"
    />
  </svg>
)
