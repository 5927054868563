import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react'

import { FormHandles } from '@unform/core'
import { ModalBase, Botao, FormUnform, TextAreaUnform } from 'src/componentes'

import { ContainerAcoes, ContainerModal, CorpoModal } from './styles.ts'
import {
  ModalFinalizarTicketRef,
  ModalFinalizarTicketProps,
  FinalizarTicket
} from './tipos'
const Modal: React.ForwardRefRenderFunction<
  ModalFinalizarTicketRef,
  ModalFinalizarTicketProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [idTicket, definirIdTicket] = useState<string>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = (idTicket: string) => {
    definirIdTicket(idTicket)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalFinalizarTicketRef, ModalFinalizarTicketRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoForm = async (dados: FinalizarTicket) => {
    try {
      definirCarregando(true)
      const dadosFinalizarTicket = {
        ...dados,
        idTicket
      }

      await acaoPrimaria(dadosFinalizarTicket)
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  return (
    <ModalBase id="modal-finalizar-ticket" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Finalizar Ticket</h5>
          {pronto && (
            <FormUnform acaoSucesso={acaoSucessoForm} ref={formRef}>
              <TextAreaUnform
                id="ipt_observacao"
                name="observacao"
                label="Observação"
              />
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Finalizar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}
export const ModalFinalizarTicket = forwardRef(Modal)
