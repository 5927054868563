import React, { useRef, useEffect, useState } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { Input } from '../../input'
import { Componente, Erro } from './styles'
import { InputUnformProps } from './tipos'

export const NumeroInputUnform: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & InputUnformProps
> = ({
  name,
  label,
  ladoIcone,
  semMargemAbaixo,
  maxLength,
  isDecimal,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name || '')
  const [valor, definirValor] = useState(defaultValue ?? '')

  useEffect(() => {
    registerField<number>({
      name: fieldName,
      ref: inputRef.current,
      getValue({ value }) {
        if (value === '') return null
        value = value.replace(',', '.')
        return Number(value)
      },
      setValue(_, value: number) {
        definirValor(value)
      }
    })
  }, [fieldName, registerField, isDecimal])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames({
        semMargemAbaixo
      })}
    >
      <Input
        {...rest}
        name={name}
        ref={inputRef}
        erro={!!error}
        className={classNames({
          error
        })}
        label={label}
        ladoIcone={ladoIcone}
        type="tel"
        maxLength={maxLength}
        value={valor}
        onChange={e => {
          const valor =
            e.target.value?.replace(isDecimal ? /[^0-9,.]+/g : /\D/g, '') ?? ''
          definirValor(valor)
          onChange && onChange(e)
        }}
        onKeyPress={clearError}
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </Componente>
  )
}
