import React, {
  ChangeEvent,
  forwardRef,
  useMemo,
  useState,
  useImperativeHandle,
  useRef
} from 'react'

import {
  ContainerAcoes,
  IconeAdicionar,
  IconeLixeira,
  Botao
} from 'src/componentes'

import { Apresentacao, CampoInput, Container } from './styles'
import { InputArquivoProps, InputArquivoRef } from './tipos'

export const InputArquivoComentario: React.ForwardRefExoticComponent<InputArquivoProps> = forwardRef<
  InputArquivoRef,
  InputArquivoProps
>(
  (
    {
      id,
      name,
      comErro,
      valorPadrao,
      tiposAceitos,
      acaoAoAlterar,
      obrigatorio,
      ...rest
    },
    ref?: any
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [arquivo, definirArquivo] = useState<File | string>(valorPadrao)

    const obterTipo = useMemo(() => tiposAceitos.join(','), [tiposAceitos])
    const nomeArquivo = useMemo(
      () => (typeof arquivo === 'string' ? arquivo : arquivo?.name),
      [arquivo]
    )

    const limparInput = () => {
      definirArquivo(null)
    }

    const anexarArquivo = () => inputRef.current?.click()

    const manipularAlteracao = (e: ChangeEvent<HTMLInputElement>) => {
      acaoAoAlterar && acaoAoAlterar(e)
      const arquivo = e.target.files?.[0]

      definirArquivo(arquivo ?? null)
    }

    useImperativeHandle<InputArquivoRef, InputArquivoRef>(
      ref,
      () => ({
        setValue: (novoArquivo: File | string) => definirArquivo(novoArquivo),
        getValue: () => arquivo,
        limpar: () => limparInput()
      }),
      [arquivo]
    )

    return (
      <Container>
        <input
          key={nomeArquivo}
          ref={inputRef}
          type="file"
          {...rest}
          id={id}
          name={name}
          accept={obterTipo}
          onChange={event => manipularAlteracao(event)}
          hidden
          required={obrigatorio}
        />
        <Apresentacao>
          {arquivo ? (
            <>
              <CampoInput
                type="text"
                value={nomeArquivo}
                comErro={comErro}
                readOnly
              />
              <ContainerAcoes>
                <button type="button" data-tip="Excluir" onClick={limparInput}>
                  {IconeLixeira}
                </button>
              </ContainerAcoes>
            </>
          ) : (
            <>
              <Botao
                type="button"
                onClick={anexarArquivo}
                tema="Link"
                elemento={<>{IconeAdicionar} Anexar Arquivo</>}
              />
            </>
          )}
        </Apresentacao>
      </Container>
    )
  }
)
