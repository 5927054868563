import { Cores, Medias } from 'src/componentes/styles'
import styled, { css } from 'styled-components'

import { ContainerMedio, Linha } from '../../styles'

export const LinhaBandeiras = styled(Linha)`
  margin-bottom: 25px;

  @media ${Medias.MOBILE} {
    flex-direction: row;
  }
`

export const ContainerBandeiras = styled(Linha)<{ selecionado: boolean }>`
  width: 25%;
  transition: filter 0.2s;
  svg {
    box-shadow: 1px 1px 10px ${Cores.CINZA_1_CLARO};
    transition: box-shadow 0.2s;
  }

  ${({ selecionado }) =>
    !selecionado &&
    css`
      filter: grayscale(100%);

      svg {
        box-shadow: none;
      }
    `}
`

export const ContainerBotaoBoleto = styled(ContainerMedio)`
  display: flex;
  align-items: flex-end;
  margin: auto;
  margin-bottom: 25px;

  button {
    width: 182px;
  }

  @media ${Medias.MOBILE} {
    margin-bottom: 20px;
    justify-content: center;
  }
`

export const ContainerBoleto = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;

  h5 {
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 19px;
    margin-bottom: 8px;
  }
`

export const IconeLabel = styled.div`
  position: absolute;
  left: 90%;
  top: 0;

  svg {
    min-width: 12px;
    min-height: 12px;
  }
`

export const ContainerCupom = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  > div:first-child {
    margin-bottom: 20px;
  }

  @media ${Medias.MOBILE} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`
export const ContainerInputCupom = styled.div`
  max-width: 80%;

  & > form > div {
    margin-bottom: 0;
  }

  @media ${Medias.MOBILE} {
    margin-top: 10px;
    max-width: 100%;
  }
`

export const CupomContainer = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin: 0px 0px 25px 10px;
  }
`
export const TituloIsentoRecorrencia = styled.h5`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${Cores.SECUNDARIA_2};
  margin-top: 20px;
`
