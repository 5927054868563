import * as Icones from 'src/componentes/icones'

import barraDeProgresso from './imagens/barraDeProgresso.png'
import documentos from './imagens/documentos.png'
import forumDeDuvidas from './imagens/forumDeDuvidas.png'
import reingresso from './imagens/reingresso.png'

type QuestionsAndAwnserType = {
  id: string
  title: string
  response?: string
  videoUrl?: string
  imageUrl?: Array<string>
}

type TopicType = {
  id: string
  title: string
  icon: any
  description: string
  questionsAndAwnsers: Array<QuestionsAndAwnserType>
}

type BusinessUnity = Array<{
  id: string
  title: string
  topics: Array<TopicType>
}>

export const businessUnitys: BusinessUnity = [
  {
    id: '3a023e16-d1fa-46e4-a0d4-a126381d458c',
    title: 'PÓS-GRADUAÇÃO',
    topics: [
      {
        id: '7a6a4840-52aa-43fc-aa9f-18a433488f1a',
        title: 'Meu cadastro',
        icon: Icones.IconeFileAluno,
        description:
          '<div>A atualização cadastral é importante que seja feito com cuidado e que as informações fornecidas sejam precisas e atualizadas para garantir a segurança e a eficácia do processo.</div> </br> <div>Abaixo apresentamos as funcionalidades em “Meu Cadastro.”</div>',
        questionsAndAwnsers: [
          {
            id: '0e143a50-1707-4095-b769-c54aee8cf86e',
            title: 'Meu cadastro',
            response:
              '<div>Entre em Perfil -> Meu Cadastro (canto direito da tela), e prontinho, poderá alterar as informações nos ícones Dados Pessoais; Contato; e Endereço.</div> </br>',
            videoUrl: 'https://vimeo.com/868423873?share=copy',
            imageUrl: null
          }
        ]
      },
      {
        id: '8c87e3ef-d1c7-44eb-ba5c-4d4d2e5b8483',
        title: 'Primeiros passos',
        icon: Icones.IconeVideo,
        description:
          '<div>Etapas iniciais que irão te preparar para prosseguir com sucesso na jornada acadêmica. Separamos uma apresentação de apoio:</div>',
        questionsAndAwnsers: [
          {
            id: '1acbe0e1-3681-4f5e-ba56-be54749061ea',
            title: 'Meu mural e Materiais do curso',
            response:
              '<div>Em “Meu Mural” aparecerá todos os cursos que você adquiriu na iPGS. Para localizar os “Materiais do curso,” basta selecionar o curso desejado em seu mural e mais abaixo na página do curso os materiais estão disponíveis.</div></br>',
            videoUrl: 'https://vimeo.com/868424502?share=copy',
            imageUrl: null
          }
        ]
      },
      {
        id: '6dab6ee2-80ad-4079-b56f-4edbc4447dc9',
        title: 'Jornada acadêmica',
        icon: Icones.IconeAcademico,
        description:
          'Confira alguns materiais que podem te ajudar no entendimento das funcionalidades no ambiente virtual de aprendizagem.',
        questionsAndAwnsers: [
          {
            id: '2159cb3d-0d27-4989-9532-52cdf4d9d7b0',
            title: 'Sala de aula: conheça as modalidades',
            response: `
                <div><strong>Modalidade FULLTIME</strong></div>
                </br>
                <div>A modalidade Fulltime são turmas contínuas, ou seja, turmas que os alunos podem realizar a Pós-Graduação conforme a disponibilidade, sem a necessidade de uma data fixa para assistir as aulas e entregar a avaliação do módulo. Na modalidade Fulltime, o aluno tem o período entre 9, 10 ou 12 meses para concluir a Pós-Graduação, conforme sua rotina.</div>
                </br>

                <div><strong>Modalidade EAD</strong></div>
                </br>
                <div>A modalidade EAD são turmas fechadas, ou seja, turmas que possuem data de início em um determinado período do ano, com número específico de alunos para compor a turma. Na modalidade EAD, o aluno tem o período entre 9, 10 ou 12 meses para concluir a Pós-Graduação, conforme sua rotina.</div>
                </br>
                
                <div><strong>Modalidade FLEX</strong></div>
                </br>
                <div>A modalidade Flex, possui o mesmo formato da modalidade Fulltime, a diferença está na distribuição dos módulos. São 2 módulos fixos e o aluno pode escolher 3 módulos, conforme sua necessidade, para compor a grade curricular de 5 módulos na Pós-Graduação. Na modalidade Flex, o aluno pode realizar o curso conforme a disponibilidade da sua rotina, e tem o período de 12 meses para concluir a Pós-Graduação.</div>
                </br>

                <div><strong>Modalidade AO VIVO</strong></div>
                </br>
                <div>A modalidade Ao Vivo, são turmas fechadas, com aulas online ao vivo e ocorrem uma vez por mês no turno da noite. As turmas possuem data de início em um determinado período do ano, com número específico de alunos para compor a turma. Na modalidade ao vivo, o aluno tem o período entre 9, 10 ou 12 meses para concluir a Pós-Graduação.</div>
                </br>
              `,
            videoUrl: null,
            imageUrl: null
          },
          {
            id: '5baee6dc-24be-44c1-a5a3-fd44026178d3',
            title: 'Avaliação do Módulo',
            response:
              '<div>No ícone Avaliação do Módulo, você encontra as orientações necessárias para realizar a prova juntamente com os pré-requisitos para aprovação.</div></br> <div>Sala de Aula -> Módulo -> Avaliação do Módulo.</div></br>',
            videoUrl: 'https://vimeo.com/868425216?share=copy',
            imageUrl: null
          },
          {
            id: '09b59bc2-342d-4230-816c-ff2c13237fe2',
            title: 'Nota',
            response:
              '<div>No vídeo abaixo, apresentamos na prática como funciona para entregar a prova e realizar uma nova tentativa para substituição de nota.</div></br>',
            videoUrl: 'https://vimeo.com/868425958?share=copy',
            imageUrl: null
          },
          {
            id: '1397a8b4-6c40-416f-b6de-96ccfcb327e4',
            title: 'Barra de Progresso',
            response: null,
            videoUrl: null,
            imageUrl: [barraDeProgresso]
          },
          {
            id: '7ed7a370-d88b-4a2f-9e5e-b5f26beeeec0',
            title: 'Aprenda Mais',
            response:
              '<div>O módulo Aprenda Mais é um conteúdo extra que ocorre mensalmente ao vivo online para complementar seus estudos. Caso não consiga participar do encontro, o conteúdo ficará gravado no ambiente virtual, no ícone Aprenda Mais.</div></br> <div>Sala de Aula - Módulos -> Aprenda Mais.</div></br>',
            videoUrl: 'https://vimeo.com/868437667?share=copy',
            imageUrl: null
          },
          {
            id: 'e8511e4a-c903-47ce-ab86-dd2e88c20922',
            title: 'Fórum de dúvidas',
            response:
              '<div>No ícone Fórum de dúvidas, é o momento em que você pode tirar suas dúvidas com nosso prof tutor para auxiliar na sua jornada acadêmica.</div></br> <div>Sala de Aula -> Módulos -> Fórum de Dúvidas.</div></br>',
            videoUrl: null,
            imageUrl: [forumDeDuvidas]
          },
          {
            id: '06167d15-3bf9-4a53-86ef-382555a16ac2',
            title: 'TCC',
            response:
              '<div>No ícone TCC, você encontra as informações sobre obrigatoriedade do artigo científico simples.</div></br> <div>Sala de Aula -> Módulos -> TCC.</div></br>',
            videoUrl: 'https://vimeo.com/868444261?share=copy',
            imageUrl: null
          },
          {
            id: 'ee288f00-b7a4-4cb3-b769-c79e8dead6f1',
            title: 'Prorrogação',
            response:
              '<div>No item Prorrogação, você terá as informações para prolongar o término do curso pelo período de 30, 60 ou 90 dias.</div></br> <div>Sala de Aula -> Módulos -> Prorrogação. </div></br>',
            videoUrl: 'https://vimeo.com/868444312?share=copy',
            imageUrl: null
          },
          {
            id: '082bde52-50eb-43ce-a58e-f187b00514b6',
            title: 'Reingresso',
            response:
              '<div>O reingresso é uma funcionalidade para o aluno que reprovou ou que não conseguiu finalizar a Pós-Graduação dentro do período vigente do curso e que deseja finalizar a pós. Após reprovação, o reingresso ficará disponível no AVA juntamente com o regulamento para aceite.</div></br>',
            videoUrl: null,
            imageUrl: [reingresso]
          }
        ]
      },
      {
        id: '11b8f052-4402-4eb2-83e4-ff0c36f4f1e8',
        title: 'Financeiro',
        icon: Icones.IconeDeFinancasDoAluno,
        description:
          'Confira alguns materiais que podem te ajudar no entendimento das funcionalidades no ambiente financeiro.',
        questionsAndAwnsers: [
          {
            id: '4567dcd2-0b21-47da-b106-b6acca35fe9e',
            title: 'Emissão do boleto e Recibo de pagamento.',
            response:
              '<div>Veja no vídeo como emitir o boleto da parcela juntamente com o recibo de pagamento </div></br>',
            videoUrl: 'https://vimeo.com/868445112?share=copy',
            imageUrl: null
          },
          {
            id: '14587872-3080-4054-859a-9d1b8208dbe7',
            title: 'Alteração do cartão de crédito e Recibo de pagamento.',
            response:
              '<div>Veja no vídeo como alterar o cartão de crédito juntamente com o recibo de pagamento.</div> </br>',
            videoUrl: 'https://vimeo.com/868445140?share=copy',
            imageUrl: null
          }
        ]
      },
      {
        id: '73b9d4c6-f78a-4ade-a5cd-9f2cdb43f1f2',
        title: 'Documentos',
        icon: Icones.IconeMeusDocumentos,
        description:
          'Confira alguns materiais que podem te ajudar no entendimento do anexo dos documentos.',
        questionsAndAwnsers: [
          {
            id: 'a2efdec8-bcc8-4c3e-9b26-85d9cd1d87d6',
            title: 'Documentos',
            response: null,
            videoUrl: null,
            imageUrl: [documentos]
          },
          {
            id: '13bbce0f-cce1-4252-89c4-a57339fbefed',
            title: 'Contrato de Prestação de Serviços',
            response:
              '<div>O Contrato de Prestação de Serviços fica disponível para realizar o download a qualquer momento.</div></br>',
            videoUrl: 'https://vimeo.com/876445119?share=copy',
            imageUrl: null
          }
        ]
      },
      {
        id: '7465e393-53b7-41c3-8448-59d2535b45a2',
        title: 'Históricos e Declarações',
        icon: Icones.IconeMeusHistoricosDeclaracoes,
        description:
          'Confira alguns materiais que podem te ajudar no entendimento da emissão do histórico escolar.',
        questionsAndAwnsers: [
          {
            id: '55acf874-af8d-40f8-b6fa-3d1c2d20fbc2',
            title: 'Históricos e Declarações',
            response:
              '<div>No item históricos e Declarações, você consegue emitir o comprovante de matrícula e o histórico de nota.</div></br>',
            videoUrl: 'https://vimeo.com/868445194?share=copy',
            imageUrl: null
          }
        ]
      }
    ]
  },
  {
    id: 'e68ddd34-4a76-42fb-be6c-5ffe7f44a217',
    title: 'CURSOS LIVRES',
    topics: [
      {
        id: '73b9d4c6-f78a-4ade-a5cd-9f2cdb43f122',
        title: 'Em breve',
        icon: null,
        description: null,
        questionsAndAwnsers: null
      }
    ]
  }
]
