import React from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import { PaginaMatricula } from 'src/paginas'
import { ModoMatricula } from 'src/paginas/matricula/tipos'

export const MatriculaRotas: React.FC = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/curso-livre/:sigla`}>
        <PaginaMatricula modo={ModoMatricula.Livre} />
      </Route>
      <Route path={`${url}/evento/:sigla`}>
        <PaginaMatricula modo={ModoMatricula.Evento} />
      </Route>
      <Route path={`${url}/turma/:codigoTurma`}>
        <PaginaMatricula modo={ModoMatricula.Pos} />
      </Route>
      <Route path={`${url}/curso-livre/carrinho:sigla`}>
        <PaginaMatricula modo={ModoMatricula.Livre} />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}
