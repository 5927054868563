import { AxiosRequestConfig } from 'axios'
import { saveAs } from 'file-saver'
import {
  AceiteDocumentoResponsavelFinanceiro,
  DocumentoResponsavelFinanceiro,
  RespostaPadraoAPI
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'

const urlBase = '/documentoResponsavelFinanceiro'
const headers = { headers: { 'Content-Type': 'multipart/form-data' } }

export const EnviarDocumentoResponsavel = async (
  formData: FormData
): Promise<boolean> => {
  await gatewayApi.post<RespostaPadraoAPI>(urlBase, formData, headers)

  return true
}

export const ObterDownloadDocumentoResponsavel = async (
  documentoId: string
): Promise<void> => {
  const rota = `${urlBase}/${documentoId}/download`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    saveAs(resposta.data)
  }
}

export const ObterArquivoDocumentoResponsavel = async (
  documentoId: string
): Promise<string> => {
  const rota = `${urlBase}/${documentoId}/download`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null
  return URL.createObjectURL(resposta.data)
}

export const AceiteDocumentoResponsavel = async (
  payload?: AceiteDocumentoResponsavelFinanceiro
): Promise<boolean> => {
  const resposta = await gatewayApi.post(`${urlBase}/aceite`, payload)

  return resposta.status === 200 || resposta.status === 204
}

export const ObterDocumentoResponsavelFinanceiro = async (
  idUsuarioResponsavel: string
): Promise<DocumentoResponsavelFinanceiro> => {
  const url = `${urlBase}/${idUsuarioResponsavel}`
  const { data } = await gatewayApi.get<DocumentoResponsavelFinanceiro>(url)

  return data
}
