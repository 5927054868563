import React, { useState, useEffect, useCallback } from 'react'

import classNames from 'classnames'
import { UsuarioPerfil } from 'src/tipos'

import { AutenticacaoContainer, Visao } from '../autenticacao'
import { useResponsivoState } from '../contextos/responsivo'
import { useTemaState } from '../contextos/tema'
import { MenuBotao } from './menu-botao'
import { MenuSubItem } from './menu-sub-item'
import { Lista, Item, SubLista } from './styles'
import { MenuProps, MenuItem, MenuSubItem as MenuSubItemProps } from './tipos'

export const Menu: React.FC<MenuProps> = ({ Itens }) => {
  const {
    perfil: { perfis },
    visao
  } = AutenticacaoContainer.useContainer()

  const { desktop } = useResponsivoState()
  const { menuExpandido } = useTemaState()

  const [retraido, definirRetraido] = useState<boolean>(undefined)
  const [passou, definirPassou] = useState<number>()
  const [itemAberto, definirItemAberto] = useState<number | undefined>(
    undefined
  )

  const podeVisualizar = useCallback(
    (
      visaoPermitida?: Visao,
      perfisComAcesso?: Array<keyof typeof UsuarioPerfil>
    ) => {
      return (
        (visaoPermitida === undefined || visaoPermitida === visao) &&
        (perfisComAcesso === undefined ||
          perfisComAcesso.some(p => perfis.includes(p)))
      )
    },
    [visao, perfis]
  )

  useEffect(() => {
    if (desktop) definirItemAberto(undefined)
    definirRetraido(!menuExpandido && desktop)
  }, [desktop, menuExpandido])

  const abrirItem = (indice: number, item: MenuItem) => {
    if (!item.SubItens || item.SubItens.length === 0 || desktop) {
      return
    }

    definirItemAberto(itemAberto !== indice ? indice : undefined)
  }

  const renderizarSubItens = (
    indice: number,
    subItens: MenuSubItemProps[],
    renderizarSubMenu?: boolean
  ) => {
    if (!subItens || subItens?.length === 0) {
      return undefined
    }

    return (
      <SubLista
        subMenu={renderizarSubMenu}
        selecionado={renderizarSubMenu && indice === passou}
        className={classNames({
          retraido
        })}
      >
        {subItens
          .sort((a, b) => a.Titulo.localeCompare(b.Titulo))
          .map((subItem: MenuSubItemProps, indiceSubItem: number) =>
            subItem.ehSubMenu ? (
              desktop ? (
                <Item
                  onMouseEnter={() => {
                    definirPassou(indiceSubItem)
                  }}
                  key={`item-${indice}`}
                  className={classNames({
                    aberto: itemAberto === indice
                  })}
                >
                  <MenuBotao
                    paraDesktop={false}
                    item={subItem}
                    retraido={false}
                    indice={indice}
                    abrirItem={abrirItem}
                  />
                  {renderizarSubItens(indiceSubItem, subItem.SubItens, true)}
                  <MenuBotao
                    paraDesktop={true}
                    item={subItem}
                    retraido={false}
                    indice={indice}
                    abrirItem={abrirItem}
                  />
                </Item>
              ) : (
                subItem.SubItens.map(a => (
                  <MenuSubItem
                    key={`subitem-${indice}-${indiceSubItem}`}
                    item={a}
                  />
                ))
              )
            ) : (
              <MenuSubItem
                key={`subitem-${indice}-${indiceSubItem}`}
                item={subItem}
              />
            )
          )}
      </SubLista>
    )
  }

  return (
    retraido !== undefined &&
    (menuExpandido || desktop) && (
      <Lista
        className={classNames({
          retraido
        })}
      >
        {Itens.map(
          (item, indice) =>
            item &&
            podeVisualizar(item.Visao, item.PerfisComAcesso) && (
              <Item
                key={`item-${indice}`}
                className={classNames({
                  aberto: itemAberto === indice
                })}
              >
                <MenuBotao
                  paraDesktop={false}
                  item={item}
                  retraido={retraido}
                  indice={indice}
                  abrirItem={abrirItem}
                />
                {renderizarSubItens(indice, item.SubItens)}
                <MenuBotao
                  paraDesktop={true}
                  item={item}
                  retraido={retraido}
                  indice={indice}
                  abrirItem={abrirItem}
                />
              </Item>
            )
        )}
      </Lista>
    )
  )
}
