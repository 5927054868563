import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaProfessoresTCC = (): TabelaColuna => ({
  Chave: 'professoresTCC',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <span>{linha.professoresTCC}</span>,
  RenderizarCabecalho: () => <div style={{ width: 200 }}>Professores TCC</div>
})
