import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaFormaPagamento = (): TabelaColuna => ({
  Chave: 'formaPagamento',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <>{linha.formaPagamento}</>,
  RenderizarCabecalho: () => (
    <div style={{ width: 130 }}>Forma de pagamento</div>
  )
})
