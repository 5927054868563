import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import { Botao, FormUnform, ModalBase, InputUnform } from 'src/componentes'

import { schema } from './schema'
import {
  ContainerConteudo,
  ContainerModal,
  TituloPrincipal,
  ContainerBotoes
} from './styles'
import { ModalAlterarTituloTCCProps, ModalAlterarTituloTCCRef } from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalAlterarTituloTCCRef,
  ModalAlterarTituloTCCProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [idSalaAula, definirIdSalaAula] = useState('')
  const formRef = useRef<FormHandles>(null)

  const atualizarValorFormulario = async (campo: string, valor: any) => {
    if (!valor) return
    formRef?.current.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const fechar = () => {
    definirAberta(false)
    formRef.current?.reset()
  }

  const abrir = (idSalaAula: string, tituloTCC?: string) => {
    definirAberta(true)
    definirIdSalaAula(idSalaAula)
    atualizarValorFormulario('tituloTCC', tituloTCC)
  }

  const acaoSucessoForm = async (tituloTCC?: string) => {
    await acaoPrimaria(idSalaAula, tituloTCC)
    fechar()
    formRef.current?.reset()
  }

  useImperativeHandle<ModalAlterarTituloTCCRef, ModalAlterarTituloTCCRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase
      id="modal-alterar-titulo-tcc"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Alterar Título do TCC</TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm} schema={schema}>
          <ContainerConteudo>
            <InputUnform
              type="text"
              name="tituloTCC"
              label="Título do TCC"
              maxLength={200}
              obrigatorio
            />
          </ContainerConteudo>
          <ContainerBotoes>
            <Botao
              texto="Voltar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Enviar" />
          </ContainerBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalAlterarTituloTCC = forwardRef(Modal)
