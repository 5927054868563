import React from 'react'

import { IconeInformacao } from '../icones'
import { Cores } from '../styles'
import { Tooltip } from '../tooltip'
import { Container } from './styles'
import { InformacaoProps } from './tipos'

export const Informacao: React.FC<InformacaoProps> = ({
  children,
  invertido,
  textoTooltip,
  id,
  semIcone,
  className
}) => {
  return (
    <Container invertido={invertido} className={className}>
      {!semIcone && (
        <div className="informacao-icone" data-tip={textoTooltip} data-for={id}>
          {IconeInformacao}
        </div>
      )}
      <div className="informacao-desc">{children}</div>
      {textoTooltip && (
        <Tooltip
          id={id}
          place={!invertido ? 'left' : 'right'}
          className="informacao-tooltip"
          textColor={Cores.PRETO}
          backgroundColor={Cores.CINZA_1_CLARO}
        />
      )}
    </Container>
  )
}
