export enum ModoPaginaCadastro {
  Inclusao,
  Edicao
}
export interface RespostaPadraoAPI {
  success: boolean
}

export interface RespostaPadraoDadosAPI<T> {
  success: boolean
  data: T
}

export interface RespostaPadraoPaginacaoDadosAPI<T> {
  success: boolean
  data: PaginacaoPadraoDadosAPI<T>
}
export interface PaginacaoPadraoDadosAPI<T> {
  itens: Array<T>
  quantidade: number
  temProximaPagina: boolean
}

export interface RespostaPadraoApiComErros extends RespostaPadraoAPI {
  errors: string[]
}
