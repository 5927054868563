import React from 'react'

export default (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M21.2,21H2.8C1.2,21,0,19.8,0,18.2V5.7C0,4.2,1.2,3,2.8,3H13c0.4,0,0.8,0.3,0.8,0.8S13.4,4.5,13,4.5H2.8
   c-0.7,0-1.3,0.6-1.3,1.2v12.5c0,0.7,0.6,1.3,1.3,1.3h18.5c0.7,0,1.3-0.6,1.3-1.3V5.7c0-0.7-0.6-1.2-1.3-1.2H11
   c-0.4,0-0.8-0.3-0.8-0.8S10.6,3,11,3h10.2C22.8,3,24,4.2,24,5.7v12.5C24,19.8,22.8,21,21.2,21z"
      />
      <path
        d="M7.5,12C6.1,12,5,10.9,5,9.5C5,8.1,6.1,7,7.5,7S10,8.1,10,9.5C10,10.9,8.9,12,7.5,12z M7.5,8.5c-0.6,0-1,0.4-1,1
   c0,0.6,0.4,1,1,1s1-0.4,1-1C8.5,8.9,8.1,8.5,7.5,8.5z"
      />
      <path
        d="M11.3,17c-0.4,0-0.8-0.3-0.8-0.8v-0.5c0-0.7-0.6-1.3-1.3-1.3H5.8c-0.7,0-1.3,0.6-1.3,1.3v0.5c0,0.4-0.3,0.8-0.8,0.8
   S3,16.7,3,16.3v-0.5C3,14.2,4.2,13,5.8,13h3.5c1.5,0,2.8,1.2,2.8,2.8v0.5C12,16.7,11.7,17,11.3,17z"
      />
      <path d="M20.3,9h-5.5C14.3,9,14,8.7,14,8.2s0.3-0.8,0.8-0.8h5.5c0.4,0,0.8,0.3,0.8,0.8S20.7,9,20.3,9z" />
      <path d="M20.3,13h-5.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h5.5c0.4,0,0.8,0.3,0.8,0.8S20.7,13,20.3,13z" />
      <path d="M20.3,17h-5.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h5.5c0.4,0,0.8,0.3,0.8,0.8S20.7,17,20.3,17z" />
    </g>
  </svg>
)
