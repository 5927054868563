import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaQuantidadeCobrancasPagas = (): TabelaColuna => ({
  Chave: 'QtdCobrancasPagas',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 250 }}>Quantidade Cobranças Pagas</div>
  )
})
