import { colunaCelular } from './celular'
import { colunaCpf } from './cpf'
import { colunaCurso } from './curso'
import { colunaDataMatricula } from './data-matricula'
import { colunaDataPagamento } from './data-pagamento'
import { colunaDataVencimento } from './data-vencimento'
import { colunaEmail } from './email'
import { colunaFormaPagamento } from './forma-pagamento'
import { colunaIdRecorrencia } from './id-recorrencia'
import { colunaModalidade } from './modalidade'
import { colunaMotivoTentativas } from './motivo-tentativas'
import { colunaNomeAluno } from './nome-aluno'
import { colunaObservacao } from './observacao'
import { colunaObservacaoInterna } from './observacao-interna'
import { colunaQuantidadeTentativas } from './quantidade-tentativas'
import { colunaSituacaoFinanceira } from './situacao-financeira'
import { colunaStatusParcela } from './status-parcela'
import { colunaStatusRecorrencia } from './status-recorrencia'
import { colunaTID } from './TID'
import { colunaTipoRecorrencia } from './tipo-recorrencia'
import { colunaTurma } from './turma'
import { colunaValorEmAberto } from './valor-em-aberto'
import { colunaValorPago } from './valor-pago'

export const COLUNAS_RECORRENCIA = [
  colunaNomeAluno(),
  colunaCpf(),
  colunaEmail(),
  colunaCelular(),
  colunaDataVencimento(),
  colunaDataPagamento(),
  colunaValorPago(),
  colunaValorEmAberto(),
  colunaFormaPagamento(),
  colunaStatusParcela(),
  colunaQuantidadeTentativas(),
  colunaMotivoTentativas(),
  colunaTID(),
  colunaIdRecorrencia(),
  colunaObservacao(),
  colunaObservacaoInterna(),
  colunaSituacaoFinanceira(),
  colunaCurso(),
  colunaTurma(),
  colunaModalidade(),
  colunaTipoRecorrencia(),
  colunaDataMatricula(),
  colunaStatusRecorrencia()
]
