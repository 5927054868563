import React, { useRef, useEffect, ChangeEvent } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { InputArquivo, InputArquivoProps } from '../../input/input-arquivo'
import { Componente, Erro } from './styles'

export const ArquivoInputUnform: React.FC<InputArquivoProps> = ({
  name,
  className,
  acaoAoAlterar,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField<File | string>({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return (
          inputRef.current.files?.[0] || inputRef.current.value || defaultValue
        )
      }
    })
  }, [fieldName, registerField])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames(className)}
    >
      <InputArquivo
        ref={inputRef}
        name={name}
        valorPadrao={defaultValue}
        comErro={!!error}
        acaoAoAlterar={(e: ChangeEvent<HTMLInputElement>) => {
          clearError()
          acaoAoAlterar && acaoAoAlterar(e)
        }}
        className={classNames({
          error
        })}
        {...rest}
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </Componente>
  )
}
