import React from 'react'

export default (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1825 0.667511C12.1825 0.298851 11.8836 0 11.515 0H0.667564C0.530106 0 0.395966 0.0424606 0.283541 0.121531C-0.0180014 0.333614 -0.0905005 0.750022 0.121584 1.05156L4.05459 6.64316L4.06563 10.7111C4.06632 10.965 4.14182 11.2131 4.28266 11.4244C4.67854 12.0183 5.48088 12.1787 6.07473 11.7828L7.47947 10.8464C7.8527 10.5975 8.07605 10.1779 8.07395 9.72933L8.05968 6.66775L12.0586 1.05485C12.1392 0.941737 12.1825 0.806345 12.1825 0.667511ZM6.72269 6.24355L6.73896 9.73549L5.4005 10.6278L5.38851 6.21902L1.95321 1.33502H10.2198L6.72269 6.24355Z"
      fill="#C9412F"
    />
    <rect
      x="11"
      y="8"
      width="12.7275"
      height="1.41417"
      rx="0.707086"
      transform="rotate(45 11 8)"
      fill="#C9412F"
    />
    <rect
      x="10"
      y="17"
      width="12.7275"
      height="1.41417"
      rx="0.707086"
      transform="rotate(-45 10 17)"
      fill="#C9412F"
    />
  </svg>
)
