import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const ContainerInfo = styled.div`
  background-color: ${Cores.BRANCO};
  border: 1px solid ${Cores.SECUNDARIA_2_ESCURO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 19px 31px;

  ul li {
    list-style: square;
  }

  ol li {
    list-style: decimal;
  }
`

export const ContainerTabela = styled.div`
  margin-top: 16px;
`

export const Tabela = styled.div`
  width: fit-content;
`

export const TabelaLinha = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  font-weight: 300;
  font-size: 14px;
  line-height: 140%;

  &:not(:last-child) {
    padding-bottom: 13px;
  }

  button {
    margin-left: 10px;
  }

  svg {
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }
`
