import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Titulo = styled.div`
  h3 {
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    color: ${Cores.SECUNDARIA_2_MAIS_ESCURO};
  }

  p {
    margin-top: 12px;
    font-size: 16px;
    text-align: justify;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }

  h2 {
    margin: 24px 0;
    font-size: 18px;
    text-align: justify;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
    font-weight: bold;
  }
`

export const Tabela = styled.table`
  width: 90%;
  margin-left: 5%;

  &,
  th,
  td {
    border: none;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 17px 20px;
  }
`

export const CelulaModuloENota = styled.th`
  font-size: 16px;
  font-weight: bold;
  color: ${Cores.PRETO};
  background-color: ${Cores.CINZA_HEAD_TABLE};
  text-align: center;
`

export const Linha = styled.tr`
  font-size: 13px;
  color: ${Cores.PRETO};
  border: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
`

export const Modulo = styled.td`
  text-align: center;
`

export const Nota = styled.td`
  text-align: center;
`
export const ComponenteBotoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 24px 0;

  p {
    font-size: 14px;
    color: ${Cores.SECUNDARIA_1};
  }
`
export const ComponenteInformacoes = styled.div`
  margin-top: 24px;

  p {
    font-size: 16px;
    margin-bottom: 6px;
  }

  span {
    font-weight: bold;
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 23px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`

export const Corpo = styled.div`
  width: 574px;
  height: 470px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  form {
    width: 100%;
  }
`
export const CorpoModal = styled(Corpo)`
  padding: 41px 0px;
`
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
export const ConteudoModalInadimplente = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 14px;
    text-align: justify;
  }
  span {
    font-size: 14px;
  }
`
export const CorpoModalIndisponivel = styled.div`
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
    margin-top: 5px;
  }
`
