import { TipoDeAvaliacao } from 'src/dto'

export interface AvaliacaoResposta {
  idQuestao: string
  idAlternativa: string
  correto: boolean
}

export enum SalaAulaTipoMaterial {
  Anexo = 'Anexo',
  Video = 'Video',
  HiperLink = 'HiperLink'
}

export interface SalaAulaMaterial {
  id: string
  lido: boolean
  nomeMaterial: string
  ordem: number
  tipoMaterial: SalaAulaTipoMaterial
  extensao?: string
  linkMaterial: string
}

export interface SalaAulaDisciplina {
  id: string
  nomeDisciplina: string
  materiais: SalaAulaMaterial[]
  nota?: number
  avaliacaoId?: string
  dataLiberacaoAvaliacao?: string
  dataLimiteAvaliacao?: string
  dataUltimaTentativaAvaliacao?: string
  tipoAvaliacao?: TipoDeAvaliacao
  aproveitamento?: boolean
  pesquisaSatisfacaoId?: string
  exibirPesquisaSatisfacao: boolean
  bloqueadoPesquisaSatisfacaoPendente: boolean
  avaliacaoBloqueadaPesquisaSatisfacaoPendente: boolean
  recuperacao: boolean
  reingresso: boolean
}

export interface SalaAulaTCCDisciplina {
  id: string
  nomeDisciplina: string
  materiais: SalaAulaMaterial[]
  nota?: number
  avaliacaoId?: string
  dataLiberacaoAvaliacao?: string
  dataLimiteAvaliacao?: string
  dataUltimaTentativaAvaliacao?: string
  tipoAvaliacao?: TipoDeAvaliacao
}

export interface SalaAulaModulo {
  id: string
  nomeModulo: string
  dataLiberacao: string
  nota?: number
  avaliacaoId?: string
  avaliacaoNome?: string
  dataUltimaTentativaAvaliacao?: string
  dataLiberacaoAvaliacao?: string
  dataLimiteAvaliacao?: string
  tipoAvaliacao?: TipoDeAvaliacao
  disciplinas: SalaAulaDisciplina[]
  pesquisaSatisfacaoId?: string
  bloqueadoPesquisaSatisfacaoPendente: boolean
  exibirPesquisaSatisfacao: boolean
  avaliacaoBloqueadaPesquisaSatisfacaoPendente: boolean
  recuperacao: boolean
  reingresso: boolean
  flex?: boolean
  podeRealizarTrocaModulo?: boolean
}

export interface SalaAulaTCCModulo {
  id: string
  nomeModulo: string
  dataLiberacao: string
  nota?: number
  disciplinas: SalaAulaTCCDisciplina[]
  possuiGrupoDiscussaoTCC: boolean
}
export interface SalaAulaQuestaoResposta {
  idPergunta: string
  idAlternativa: string
  correta: boolean
  anulada?: boolean
  observacao?: string
  explicacao?: string
}

export interface SalaAulaQuestaoAlternativa {
  id: string
  texto: string
}

export interface SalaAulaQuestao {
  id: string
  texto: string
  respostas: SalaAulaQuestaoAlternativa[]
  explicacao?: string
}

export interface SalaAula {
  id: string
  dataInicio: string
  nomeCurso: string
  cargaHoraria: number
  progresso: number
  aprovado: boolean
  dataAprovacao?: string
  dataLiberacao: string
  media: number
  primeiroMaterialNaoLido: string
  informacoesGeraisCurso?: string
  tempoDisponibilidade?: number
}
