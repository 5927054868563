import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { CartaoCreditoInput } from '../../input/cartao-credito'
import { Componente, Erro } from './styles'
import { InputUnformProps } from './tipos'

export const CartaoCreditoInputUnform: React.FC<
  Omit<InputUnformProps, 'mascara'>
> = ({ name, semMargemAbaixo, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name || '')

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue({ value }) {
        return value && value.replace(/\D/g, '')
      },
      setValue(ref: any, value: string) {
        ref.setInputValue(value)
      },
      clearValue(ref: any) {
        ref.setInputValue('')
      }
    })
  }, [fieldName, registerField])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames({
        semMargemAbaixo
      })}
    >
      <CartaoCreditoInput
        {...rest}
        name={name}
        ref={inputRef}
        erro={!!error}
        className={classNames({
          error
        })}
        value={defaultValue}
        onKeyPress={clearError}
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </Componente>
  )
}
