import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  CursoLivre,
  PaginaInicialAluno,
  PainelFinanceiro,
  CursoOutros,
  PaginaEdicaoAluno,
  ContratosAluno,
  DocumentosAlunoBrasileiro,
  DocumentosAlunoEstrangeiro,
  HistoricoCursos,
  CarrinhoCompraCursoLivre,
  // PaginaRequerimentosAluno,
  PaginaEmentaDisciplina
} from 'src/paginas/aluno/'

const RotaBase = 'aluno'

export const RotasAluno = {
  Dashboard: `/${RotaBase}`,
  Edicao: `/${RotaBase}/edicao`,
  PainelFinanceiro: `/${RotaBase}/painel-financeiro`,
  FinanceiroCursoOutro: `/${RotaBase}/financeiro`,
  FinanceiroCursoLivre: `/${RotaBase}/financeiro-livre`,
  MeusContratos: `/${RotaBase}/meus-contratos`,
  MeusDocumentosBrasileiro: `/${RotaBase}/meus-documentos/brasileiro`,
  MeusDocumentosEstrangeiro: `/${RotaBase}/meus-documentos/estrangeiro`,
  HistoricoCursos: `/${RotaBase}/historico-cursos`,
  Requerimentos: `/${RotaBase}/requerimentos`,
  EmentaDisciplina: `/${RotaBase}/requerimentos/ementa-disciplina`
}

export const AlunoRotas: React.FC = () => {
  return (
    <Switch>
      <Route exact path={RotasAluno.Dashboard}>
        <PaginaInicialAluno />
      </Route>
      <Route path={`${RotasAluno.Edicao}`}>
        <PaginaEdicaoAluno />
      </Route>
      <Route
        path={[
          `${RotasAluno.PainelFinanceiro}/:usuarioId`,
          RotasAluno.PainelFinanceiro
        ]}
      >
        <PainelFinanceiro />
      </Route>
      <Route
        path={[
          `${RotasAluno.FinanceiroCursoLivre}/:matricula/:modalidade/:nomeDoCurso/:usuarioId`,
          `${RotasAluno.FinanceiroCursoLivre}/:matricula/:modalidade/:nomeDoCurso`
        ]}
      >
        <CursoLivre />
      </Route>
      <Route
        path={`${RotasAluno.FinanceiroCursoOutro}/:matricula/:modalidade/:nomeDoCurso`}
      >
        <CursoOutros />
      </Route>
      <Route path={RotasAluno.MeusContratos}>
        <ContratosAluno />
      </Route>
      <Route path={RotasAluno.MeusDocumentosBrasileiro}>
        <DocumentosAlunoBrasileiro />
      </Route>
      <Route path={RotasAluno.MeusDocumentosEstrangeiro}>
        <DocumentosAlunoEstrangeiro />
      </Route>
      <Route path={RotasAluno.HistoricoCursos}>
        <HistoricoCursos />
      </Route>
      <Route
        path={`${RotasAluno.FinanceiroCursoLivre}/carrinho-compra/:carrinhoCompraId`}
      >
        <CarrinhoCompraCursoLivre />
      </Route>
      {/* <Route exact path={RotasAluno.Requerimentos}>
        <PaginaRequerimentosAluno />
      </Route> */}
      <Route exact path={RotasAluno.EmentaDisciplina}>
        <PaginaEmentaDisciplina />
      </Route>
    </Switch>
  )
}
