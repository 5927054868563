import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'

import {
  IconeSetaAvancar,
  Botao,
  IconeSetaDuplaRetornar,
  FormUnform,
  IconeDoAvatarDoUsuario,
  FormRef,
  Carregando,
  AutenticacaoContainer,
  CheckboxUnform,
  Tooltip
} from 'src/componentes'
import { CHAVE_AVATAR_ALUNO } from 'src/componentes/barra-superior-layout/providers/barra-superior.provider'
import { EditorUnform } from 'src/componentes/unform/editor'
import { sanitizeUtil } from 'src/componentes/utils'
import { Api } from 'src/servicos'
import * as Storage from 'src/servicos/local-storage'
import { Topico as TopicoType } from 'src/tipos/'

import { Comentario } from './comentario'
import { schema } from './schema'
import {
  Container,
  ContainerBotao,
  ContainerPrivado,
  Conteudo,
  ImagemUsuario,
  ListaComentarios,
  TituloTopico
} from './styles'
import { TopicoProps } from './tipos'

const avatar = Storage.obter(CHAVE_AVATAR_ALUNO) as { foto: string }

export const Topico: React.FC<TopicoProps> = ({
  onClick,
  idTopico,
  idGrupoDiscussao
}) => {
  const formRef = useRef<FormRef>(null)
  const [topico, definirTopico] = useState({} as TopicoType)
  const [carregandoTopico, definirCarregandoTopico] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const { usuario } = AutenticacaoContainer.useContainer()

  const ehUsuarioCriador = useMemo(() => {
    return usuario.id === topico?.usuarioId
  }, [usuario, topico])

  const comentariosBloqueados = useMemo(() => {
    return topico?.comentariosBloqueados ?? false
  }, [topico])

  const carregarTopico = useCallback(async () => {
    try {
      definirCarregandoTopico(true)
      const resposta = await Api.RequisitarTopicoPorId(idTopico)

      definirTopico(resposta)
    } catch {
      definirTopico(null)
    } finally {
      definirCarregandoTopico(false)
    }
  }, [idTopico])

  const acaoSucesso = async (payload: {
    comentario: string
    privado: boolean
  }) => {
    try {
      definirCarregando(true)

      await Api.CriarComentario(
        idGrupoDiscussao,
        idTopico,
        sanitizeUtil(payload.comentario),
        payload.privado ?? false
      )

      formRef.current?.clearField('comentario')
      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    carregarTopico()
  }, [carregarTopico])

  return (
    <>
      <Container>
        <TituloTopico>
          <h3>Fórum de dúvidas</h3>
          {IconeSetaAvancar}
          <span>{topico.titulo}</span>
        </TituloTopico>
        <Botao
          elemento={<>{IconeSetaDuplaRetornar} Voltar para os tópicos</>}
          elementoInvertido
          tema="Link"
          onClick={onClick}
        />
      </Container>
      {carregandoTopico ? (
        <Carregando texto="Carregando comentários ..." />
      ) : (
        <ListaComentarios>
          {topico && topico?.comentarios && (
            <Comentario
              tipo="comentario"
              comentarios={topico?.comentarios}
              topico={topico?.topicoId}
              grupo={idGrupoDiscussao}
              carregarTopico={carregarTopico}
            />
          )}
        </ListaComentarios>
      )}
      {(ehUsuarioCriador || !comentariosBloqueados) && (
        <FormUnform ref={formRef} acaoSucesso={acaoSucesso} schema={schema}>
          <>
            <Conteudo>
              <ImagemUsuario>
                {avatar && avatar.foto ? (
                  <img width="40" height="40" src={avatar.foto} />
                ) : (
                  IconeDoAvatarDoUsuario
                )}
              </ImagemUsuario>
              <EditorUnform
                id="comentario"
                name="comentario"
                label="Adicionar um comentário"
              />
            </Conteudo>
            <ContainerBotao>
              <ContainerPrivado>
                <CheckboxUnform id="chk_privado" name="privado" />
                <label
                  id={'lbl_privado'}
                  data-tip="Somente o criador do tópico e você poderão ver este comentário"
                  data-for={`${idTopico}-tooltip-comentario-privado`}
                >
                  Privado
                </label>
                <Tooltip
                  id={`${idTopico}-tooltip-comentario-privado`}
                  place="right"
                  offset={{ top: 5 }}
                />
              </ContainerPrivado>
              <Botao
                texto="Enviar Comentário"
                carregando={carregando}
                type="button"
                onClick={() => formRef?.current?.submitForm()}
              />
            </ContainerBotao>
          </>
        </FormUnform>
      )}
    </>
  )
}
