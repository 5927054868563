import { Cores } from 'src/componentes/styles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`

export const CampoLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: left;
  line-height: 125%;
  margin-bottom: 6px;
  color: ${Cores.CINZA_2_ESCURO};

  :first-letter {
    text-transform: uppercase;
  }
`

export const Apresentacao = styled.div`
  display: flex;
`

export const CampoInput = styled.input<{ comErro: boolean }>`
  outline: none;
  border: 1px solid ${Cores.CINZA_1_CLARO};
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: ${Cores.CINZA_2_ESCURO};
  transition: 0.15s;
  position: relative;
  height: 40px;
  border-right-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0px 17px;
  flex: 1;

  ${({ comErro }) =>
    comErro &&
    css`
      border: 1px solid ${Cores.PERIGO_ERRO};
    `}

  &::placeholder {
    color: ${Cores.CINZA_3_ESCURO};
    font-size: 12px;
  }

  &:not(:placeholder-shown) {
    color: ${Cores.CINZA_2};
  }

  ${({ theme: { Desabilitado } }) =>
    !Desabilitado &&
    css`
      &:hover {
        border: 1px solid ${Cores.PRIMARIA_1};
      }

      &:focus {
        caret-color: ${Cores.PRIMARIA_2};
        border: 1px solid ${Cores.PRIMARIA_2};
        color: ${Cores.CINZA_2_ESCURO};
      }
    `}
`

export const BotaoSelecao = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  width: 70px;
  border: 1px solid ${Cores.CINZA_1_CLARO};
  border-left-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${Cores.CINZA_2};
  outline: none;
  cursor: pointer;
`
