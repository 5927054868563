import React from 'react'

export default (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6103 0.385114C20.1299 0.898597 20.1299 1.73112 19.6103 2.2446L8.10478 13.6149C7.58519 14.1284 6.74278 14.1284 6.22319 13.6149L0.389715 7.86774C-0.129872 7.35426 -0.129874 6.52174 0.389711 6.00826C0.909297 5.49478 1.75171 5.49477 2.2713 6.00826L7.16399 10.8257L17.7287 0.385109C18.2483 -0.128371 19.0907 -0.12837 19.6103 0.385114Z"
      fill="white"
    />
  </svg>
)
