import { useState } from 'react'

import { ControleDeRespostas } from './controle-de-respostas.interface'

export const useControleDeRespostas = <
  TResposta
>(): ControleDeRespostas<TResposta> => {
  const [resposta, alterarResposta] = useState<TResposta>()

  const possuiResposta = (): boolean => Boolean(resposta !== undefined)

  const adicionarResposta = (resposta: TResposta): void =>
    alterarResposta(resposta)

  const limparResposta = (): void => alterarResposta(undefined)

  const obterResposta = (): TResposta => resposta ?? ({} as TResposta)

  return {
    possuiResposta,
    adicionarResposta,
    limparResposta,
    obterResposta
  }
}
