import React, { FC } from 'react'

import { Cores, IconeInformacaoAzul, Tooltip } from 'src/componentes'

import { ContainerDoIcone } from './styles'

export interface TooltipParcelaObservacao {
  id: string
  observacao: string
  interna?: boolean
  className?: string
}

export const TooltipParcelaObservacao: FC<TooltipParcelaObservacao> = ({
  id,
  observacao,
  interna,
  className
}) => {
  const mensagem = interna
    ? `<p>Observação Interna:</p><p>${observacao}</p>`
    : `<p>Observação:</p><p>${observacao}</p>`

  return (
    <>
      <ContainerDoIcone className={className} data-tip={mensagem} data-for={id}>
        {IconeInformacaoAzul}
      </ContainerDoIcone>
      <Tooltip
        id={id}
        place={'right'}
        textColor={Cores.PRETO}
        backgroundColor={Cores.CINZA_1_CLARO}
        html={true}
      />
    </>
  )
}
