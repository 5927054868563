import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  DataInputUnform,
  FormUnform,
  ModalBase,
  ModalRef,
  MoedaInputUnform,
  SelectOpcao,
  SelectUnform
} from 'src/componentes'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'
import { FormaPagamentoCobranca, BaixaManualCobranca } from 'src/tipos'

import { schema } from './schema'
import {
  ContainerDoConteudo,
  ContainerDosBotoes,
  ContainerModal,
  TituloPrincipal
} from './styles'
import { ModalBaixaManualCobrancaProps } from './tipos'

export interface ModalBaixaManualCobrancaRef extends Omit<ModalRef, 'abrir'> {
  abrir: (valorPagamento: number) => void
}

const Modal: React.ForwardRefRenderFunction<
  ModalBaixaManualCobrancaRef,
  ModalBaixaManualCobrancaProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [carregando, definirCarregando] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)

  const atualizarValorFormulario = async (campo: string, valor: any) => {
    if (!valor) return
    formRef?.current.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const fechar = () => definirAberta(false)
  const abrir = (valorPagamento: number) => {
    if (valorPagamento) {
      atualizarValorFormulario('valorPagamento', valorPagamento)
    }

    definirAberta(true)
  }

  const formasPagamento = useMemo(() => {
    return [
      { id: FormaPagamentoCobranca.Boleto, texto: 'Boleto' },
      { id: FormaPagamentoCobranca.CartaoCredito, texto: 'Cartão de Crédito' },
      { id: FormaPagamentoCobranca.Cheque, texto: 'Cheque' },
      { id: FormaPagamentoCobranca.CupomDesconto, texto: 'Cupom de Desconto' },
      { id: FormaPagamentoCobranca.Deposito, texto: 'Depósito' },
      { id: FormaPagamentoCobranca.Dinheiro, texto: 'Dinheiro' },
      { id: FormaPagamentoCobranca.PagSeguro, texto: 'PagSeguro' },
      { id: FormaPagamentoCobranca.PayPal, texto: 'PayPal' },
      { id: FormaPagamentoCobranca.Pix, texto: 'Pix' },
      { id: FormaPagamentoCobranca.TED, texto: 'TED' }
    ] as SelectOpcao[]
  }, [])

  useImperativeHandle<ModalBaixaManualCobrancaRef, ModalBaixaManualCobrancaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoForm = async (dados: BaixaManualCobranca) => {
    try {
      definirCarregando(true)
      await acaoPrimaria(dados)
      fechar()
      formRef.current?.reset()
    } finally {
      definirCarregando(false)
    }
  }

  return (
    <ModalBase
      id="modal-atualizar-data-cobranca"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Realizar baixa manual de cobrança</TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm} schema={schema}>
          <ContainerDoConteudo>
            <SelectUnform
              id="slct-forma-pagamento"
              label="Forma de Pagamento"
              name="formaPagamento"
              opcoes={formasPagamento}
            />
            <DataInputUnform label="Data de Pagamento" name="dataPagamento" />
            <MoedaInputUnform
              label="Valor de Pagamento"
              name="valorPagamento"
            />
            <ArquivoInputUnform
              label="Comprovante de Pagamento"
              name="comprovante"
              tiposAceitos={['.pdf', '.jpg', '.png']}
            />
          </ContainerDoConteudo>
          <ContainerDosBotoes>
            <Botao
              texto="Cancelar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao carregando={carregando} texto="Salvar" />
          </ContainerDosBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalBaixaManualCobranca = forwardRef(Modal)
