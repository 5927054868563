/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { toast } from 'react-toastify'

import axios, { AxiosError, AxiosInstance } from 'axios'
import http from 'http'
import https from 'https'

let gatewayApi: AxiosInstance
const httpAgent = new http.Agent({ keepAlive: true })
const httpsAgent = new https.Agent({ keepAlive: true })

type ApiErro = AxiosError<
  {
    mensagem: string
  }[]
>

const isApiError = (err: ApiErro | Error): err is ApiErro => {
  return (err as ApiErro).response !== undefined
}

export const getApiError = (err: ApiErro | Error): Promise<string[]> => {
  if (isApiError(err)) {
    if (err && err.config.responseType === 'blob') {
      return new Promise(resolve => {
        const fr = new FileReader()

        let resposta = null

        fr.onload = function () {
          resposta = JSON.parse(this.result as string)
        }

        fr.onloadend = function () {
          if (resposta) {
            resolve(resposta.map(p => p.mensagem))
          }
        }

        fr.readAsText((err.response?.data as unknown) as Blob)
      })
    }

    if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data.length > 0
    ) {
      return new Promise(resolve =>
        resolve(err.response.data.map(p => p.mensagem))
      )
    }
  }

  if (err.message) {
    return new Promise(resolve => resolve([err.message]))
  }

  return new Promise(resolve => resolve([]))
}

const instanciarApi = (
  baseURL: string,
  token: string,
  sair: (semRedirecionamento?: boolean) => Promise<void>
) => {
  const instancia = axios.create({
    baseURL,
    responseType: 'json',
    httpAgent,
    httpsAgent
  })

  instancia.defaults.headers.Authorization = `Bearer ${token}`

  instancia.interceptors.response.use(
    respostaApi => respostaApi,
    erro => {
      const axiosError = erro as ApiErro
      if (axiosError.isAxiosError) {
        switch (axiosError.response?.status) {
          case 400: {
            getApiError(axiosError).then(mensagens => {
              if (mensagens.length > 0) {
                for (const mensagem of mensagens) {
                  toast(mensagem, {
                    type: 'error'
                  })
                }
              } else {
                toast('Erro ao salvar os dados', {
                  type: 'error'
                })
              }
            })
            break
          }
          case 401:
            if (window.location.pathname !== '/entrar') {
              sair(!!window.location.pathname.startsWith('/matricula'))
            }
            break
          case 403:
            window.location.href = '/403'
            break
          case 500:
            toast('Houve um problema ao salvar os dados', {
              type: 'error'
            })
            break
          default:
            break
        }
      }
      throw erro
    }
  )

  return instancia
}

const criar = (
  token: string,
  sair: (semRedirecionamento?: boolean) => Promise<void> | undefined
) => {
  gatewayApi = instanciarApi(
    `${process.env.REACT_APP_URL_GATEWAY}/api`,
    token,
    sair
  )
}

export { criar, gatewayApi }
