import React, { useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  Botao,
  BotaoStep,
  Checkbox,
  TextArea,
  WizardContainer
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { SituacaoReingresso } from 'src/tipos/reingresso'

import ReingressoContainer from '../container'
import { ModalReingressoInadimplente } from '../modal-inadimplente'
import { ModalReingressoInadimplenteRef } from '../modal-inadimplente/tipos'
import {
  ContainerCheckboxs,
  ContainerConteudo,
  ContainerAcoes,
  TextoRegulamento,
  ContainerRegulamento
} from './styles'

export const RegulamentoReingresso: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const { dados, definirPasso } = ReingressoContainer.useContainer()

  const [resposta, definirResposta] = useState('')
  const [motivo, definirMotivo] = useState(null)
  const [mensagem, definirMensagem] = useState(null)
  const [carregando, definirCarregando] = useState(false)
  const { mudarEtapa } = WizardContainer.useContainer()
  const modalInadimplenteRef = useRef<ModalReingressoInadimplenteRef>(null)
  const history = useHistory()

  const rotaFinanceiro = useMemo(() => {
    return `${RotasAluno.FinanceiroCursoOutro}/${dados?.matriculaOrigem?.id}/${dados?.matriculaOrigem?.modalidade}/${dados?.matriculaOrigem?.nomeCurso}`
  }, [dados.matriculaOrigem])

  const acaoSucesso = async () => {
    try {
      definirCarregando(true)
      const dados = await Api.AvancarRegulamentoReingresso(
        id,
        resposta,
        motivo,
        mensagem
      )
      if (
        dados.situacao === SituacaoReingresso.PendenciaFinanceira &&
        dados.protocolo
      ) {
        modalInadimplenteRef?.current?.abrir(dados.protocolo)
        return
      }

      mudarEtapa(2)
      definirPasso(2)
    } finally {
      definirCarregando(false)
    }
  }

  return (
    <ContainerConteudo>
      <h2>Regulamento</h2>

      <ContainerRegulamento>
        <TextoRegulamento>
          <h4>REGULAMENTO – REINGRESSO </h4>
          <h4>PÓS-GRADUAÇÃO IPGS </h4>
          <p>
            Na hipótese da oferta do mesmo curso, poderá o ALUNO reprovado ou
            que não tenha finalizado o curso anteriormente, solicitar o
            reingresso em nova turma, mediante nova matricula, com
            aproveitamento de disciplinas que obteve aprovação anterior,{' '}
            <span>
              sendo reservada à IPGS a avaliação daquelas que efetivamente
              poderão ser aproveitadas, e do desconto, a seu critério,
            </span>{' '}
            que poderá ser aplicado sobre o valor total do novo curso de
            matrícula.
          </p>
          <h4>COMO FUNCIONA O REINGRESSO? </h4>
          <p>
            1. Nesta circunstância, fica ciente o ALUNO que novas turmas poderão
            ter grade curricular diferente daquela em que cursou anteriormente,
            com majoração do número de disciplinas ou mesmo diminuição, a
            critério da IPGS;
          </p>
          <p>
            2. A aprovação do reingresso está do ALUNO está condicionada ao
            IPGS,
            <span>
              {' '}
              mediante pagamento da taxa administrativa no valor de R$
              {dados?.valorTaxaReingresso},00 a título de reingresso.
            </span>{' '}
            Condições de pagamento detalhadas no item “2.TAXA - ENCARGO
            ADMINISTRATIVO”;
          </p>
          <p>
            3. No caso de existência de pendência financeiras na turma original,
            fica ciente o ALUNO da{' '}
            <span>
              unificação dos valores da turma original com os valores da nova
              turma;
            </span>
          </p>
          <p>
            4. Em caso de <span>renegociação de dívidas da turma original</span>{' '}
            nos últimos 2 meses, ele somente poderá realizar o reingresso,
            mediante pagamento de pelo menos uma das parcelas da dívida
            renegociada;
          </p>
          <p>
            5. O aluno somente poderá solicitar reingresso em outra turma do
            mesmo curso ao qual reprovou ou rescindiu;
          </p>
          <p>
            6. O aluno fica isento de pagar taxa de matricula. Sendo necessário
            somente a quitação da taxa de reingresso no ato de rematrícula;
          </p>
          <p>
            7. Poderá aproveitar módulos idênticos já cursados, média acima de
            7,00;
          </p>
          <p>
            8. O aluno{' '}
            <span>
              não poderá aproveitar valores já pagos na turma original,
            </span>{' '}
            visto que eles foram usufruídos no período no qual estava
            matriculado na turma original;
          </p>
          <p>
            9. Será realizado um novo contrato financeiro com valor vigente do
            curso de reingresso.
          </p>
          <p>
            10. O aluno de reingresso não precisa necessariamente permanecer na
            turma durante todo período de duração da mesma.{' '}
            <span>
              Caso consiga concluir com aprovação os módulos pendentes antes do
              tempo de duração do curso,
            </span>{' '}
            cumprindo o tempo mínimo de 30 dias, poderá comunicar, via e-mail
            (academico@ipgs.com.br), o setor acadêmico sobre a finalização e
            solicitar o encerramento da mesma. Neste caso, o setor responsável
            estará realizando os processos necessários referente ao encerramento
            e elaboração do certificado de conclusão de curso.
          </p>
          <h4>TAXA – ENCARGO ADMINISTRATIVO:</h4>
          <p>Valor: R${dados?.valorTaxaReingresso},00</p>
          <p>
            Forma de pagamento: Boleto com vencimento para 02 dias e
            Parcelamento via Cartão de Crédito direto pela plataforma.
          </p>
          <p>Regras:</p>
          <p>
            <span>
              Caso o aluno opte pelo pagamento do valor total final do
              reingresso via BOLETO BANCÁRIO,
            </span>{' '}
            à aprovação de seu reingresso fica condicionado ao pagamento desta
            taxa de encargo administrativo conforme especificado no parágrafo
            acima.
          </p>
          <p>
            <span>
              O aluno somente ficará isento do pagamento desta taxa quando:
              Optar pelo pagamento do valor total final do reingresso via CARTÃO
              DE CRÉDITO RECORRENTE,
            </span>{' '}
            sendo que, está deve ser a condição escolhida no processo de
            rematrícula sendo validada após inserção dos dados do cartão, com
            sucesso, ao final do processo.
          </p>
        </TextoRegulamento>
      </ContainerRegulamento>
      <ContainerCheckboxs>
        <Checkbox
          texto="ESTOU CIENTE E QUERO DAR ANDAMENTO"
          htmlFor="DarAndamento"
          tamanho="L"
        >
          <input
            type="radio"
            id="DarAndamento"
            name="aceitar_recusar"
            onChange={valor => {
              definirResposta(valor.target.id)
              definirMotivo(null)
              definirMensagem(null)
            }}
          />
        </Checkbox>
        <Checkbox
          texto="ESTOU CIENTE E NÃO QUERO DAR ANDAMENTO"
          htmlFor="NaoDarAndamento"
          tamanho="L"
        >
          <input
            type="radio"
            id="NaoDarAndamento"
            name="aceitar_recusar"
            onChange={valor => {
              definirResposta(valor.target.id)
              definirMotivo(null)
              definirMensagem(null)
            }}
          />
        </Checkbox>
      </ContainerCheckboxs>

      {resposta === 'NaoDarAndamento' && (
        <>
          <h3>Selecione o motivo:</h3>
          <ContainerCheckboxs>
            <Checkbox texto="Financeiro" htmlFor="financeiro" tamanho="S">
              <input
                type="radio"
                id="financeiro"
                name="motivos_recusar"
                onChange={value => {
                  definirMensagem(null)
                  definirMotivo(value.target.id)
                }}
              />
            </Checkbox>
            <Checkbox texto="Disciplinas/Notas" htmlFor="notas" tamanho="S">
              <input
                type="radio"
                id="notas"
                name="motivos_recusar"
                onChange={value => {
                  definirMensagem(null)
                  definirMotivo(value.target.id)
                }}
              />
            </Checkbox>
            <Checkbox texto="Outros" htmlFor="outros" tamanho="S">
              <input
                type="radio"
                id="outros"
                name="motivos_recusar"
                onChange={value => {
                  definirMensagem(null)
                  definirMotivo(value.target.id)
                }}
              />
            </Checkbox>
          </ContainerCheckboxs>
          {motivo === 'outros' && (
            <TextArea
              minLength={10}
              value={mensagem}
              height={'200'}
              onChange={index => definirMensagem(index.target.value)}
            />
          )}

          <Botao
            texto="Confirmar"
            disabled={motivo === 'outros' && mensagem.length < 10}
            tema="Secundario"
            onClick={acaoSucesso}
          />
        </>
      )}

      {resposta === 'DarAndamento' && (
        <ContainerAcoes>
          <BotaoStep
            disabled={false}
            texto="Ir para"
            textoNegrito="Informações do reingresso"
            carregando={carregando}
            onClick={acaoSucesso}
          />
        </ContainerAcoes>
      )}
      <ModalReingressoInadimplente
        ref={modalInadimplenteRef}
        backdrop
        acaoPrimaria={() => {
          history.push(rotaFinanceiro)
        }}
      />
    </ContainerConteudo>
  )
}
