import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaQuantidadeTentativas = (): TabelaColuna => ({
  Chave: 'quantidadeTentativas',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 90 }}>Quantidade tentativas</div>
  )
})
