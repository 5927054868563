import React, { useCallback, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import AutenticacaoContainer from './container'
import { RotaPrivadaProps } from './tipos'

const RotaPrivada: React.FC<RotaPrivadaProps> = ({ children, ...rest }) => {
  const {
    solicitarAutenticacao,
    perfil,
    sair
  } = AutenticacaoContainer.useContainer()
  const [temPermissao, definirTemPermissao] = useState(false)

  useEffect(() => {
    if (!perfil) {
      solicitarAutenticacao()
    }
  }, [perfil]) // eslint-disable-line

  useEffect(() => {
    if (perfil) {
      const permiteAcessar = !!perfil // TODO: implementar permissão
      if (!permiteAcessar) {
        sair() // TODO: sugerimos redicionar para 403
        return
      }
      definirTemPermissao(true)
    }
  }, [perfil])

  const renderizarRota = useCallback(
    () => (temPermissao ? <Route {...rest}>{children}</Route> : <></>),
    [temPermissao, rest, children]
  )

  const renderizar = useCallback(() => {
    return perfil ? renderizarRota() : <h1>Aguardando ...</h1>
  }, [perfil, renderizarRota])

  return renderizar()
}

export default RotaPrivada
