import styled from 'styled-components'

import { Cores } from '../styles'

export const Titulo = styled.h3`
  font-size: 18px;
  font-weight: 300;
  color: ${Cores.PRETO};
  margin-bottom: 27px;
`
