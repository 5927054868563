import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaPeriodoInicio = (): TabelaColuna => ({
  Chave: 'dataInicioPesquisa',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataInicioPesquisa)}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 200 }}>Periodo Inicio</div>
})
