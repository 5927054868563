import React from 'react'

export default (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3825 6.16765C18.5719 2.10051 14.6177 -0.53932 10.5506 0.271288C7.57584 0.864183 5.24985 3.18871 4.65513 6.16307C1.7714 6.49541 -0.297143 9.10246 0.0350106 11.9864C0.340797 14.6405 2.58878 16.6429 5.26047 16.6411H9.01487C9.4296 16.6411 9.76578 16.3049 9.76578 15.8902C9.76578 15.4754 9.4296 15.1393 9.01487 15.1393H5.26047C3.18681 15.127 1.51597 13.4362 1.52805 11.3627C1.54032 9.28923 3.23112 7.61821 5.30479 7.6303C5.68748 7.63048 6.00901 7.34245 6.05112 6.96214C6.43235 3.66643 9.41312 1.30382 12.7088 1.68504C15.4796 2.00566 17.6653 4.19121 17.9858 6.96214C18.0487 7.34923 18.3842 7.63286 18.7764 7.6303C20.8501 7.6303 22.531 9.3112 22.531 11.3849C22.531 13.4584 20.8501 15.1393 18.7764 15.1393H15.022C14.6073 15.1393 14.2711 15.4754 14.2711 15.8902C14.2711 16.3049 14.6073 16.6411 15.022 16.6411H18.7766C21.6794 16.623 24.018 14.2552 24 11.3523C23.9836 8.70879 22.0064 6.48882 19.3825 6.16765Z"
      fill="#606060"
    />
    <path
      d="M14.6728 13.5999C14.961 13.8984 15.4364 13.9066 15.7347 13.6184C16.0329 13.3304 16.0412 12.855 15.7531 12.5567C15.7471 12.5503 15.7409 12.5443 15.7347 12.5382L12.5501 9.35295C12.2573 9.05925 11.7818 9.0587 11.4881 9.35148L11.4868 9.35295L8.30222 12.5382C8.00394 12.8263 7.9957 13.3016 8.28373 13.5999C8.57193 13.8982 9.04728 13.9064 9.34556 13.6184C9.35178 13.6123 9.35801 13.6061 9.36405 13.5999L11.2676 11.6956V21.1464C11.2676 21.5611 11.6038 21.8973 12.0185 21.8973C12.4331 21.8973 12.7693 21.5611 12.7693 21.1464V11.6956L14.6728 13.5999Z"
      fill="#606060"
    />
  </svg>
)
