import React from 'react'

import {
  DragAndDrop,
  IconeAdicionar,
  IconeInformacao,
  SubTitulo
} from 'src/componentes'
import { Material } from 'src/tipos'

import {
  Linha,
  Botao,
  ContainerLinha,
  ContainerListaMaterial
} from '../../styles'
import { MaterialDraggable } from '../../tipos'

interface IListaMaterialProps {
  materiais: MaterialDraggable[]
  setMateriais: (items: MaterialDraggable[]) => void
  editar: (item: number) => void
  remover: (item: number) => void
  adicionar: () => void
}

export const ListaMaterial: React.FC<IListaMaterialProps> = ({
  materiais,
  setMateriais,
  editar,
  adicionar,
  remover
}) => {
  const renderMaterial = (material: Material) => {
    return (
      <Linha>
        <p>{material.titulo}</p>
      </Linha>
    )
  }

  return (
    <ContainerListaMaterial>
      <SubTitulo texto="Materiais da Disciplina" />
      <ContainerLinha>
        <Linha>
          <span>{IconeInformacao} </span>
          <span>
            Arraste e solte os itens para ordená-los. Esta será a ordem de
            exibição dos materiais na sala de aula virtual.
          </span>
          <Botao type="button" onClick={adicionar}>
            {IconeAdicionar} Incluir Material
          </Botao>
        </Linha>
      </ContainerLinha>
      <DragAndDrop<MaterialDraggable>
        render={item => renderMaterial(item)}
        items={materiais.map((x, i) => {
          x.ordem = i
          return x
        })}
        setItems={setMateriais}
        editar={editar}
        remover={remover}
      />
    </ContainerListaMaterial>
  )
}
