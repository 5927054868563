import React, { FC } from 'react'

import { useControladorDeConteudos, Evento } from 'src/compartilhados'
import { IconeSchedule, ItemAgenda } from 'src/componentes'

import { AgendaDoMuralProps } from './agenda-do-mural.props'
import {
  ContainerAgenda,
  SubTituloContainer,
  SubTituloIcone,
  SubTitulo
} from './styles'
import { TipoDaAgendaDoMural } from './tipo-da-agenda-do-mural.enum'

export const AgendaDoMural: FC<AgendaDoMuralProps> = ({
  eventos,
  configuracao = { tipo: TipoDaAgendaDoMural.Completo }
}: AgendaDoMuralProps) => {
  const controlador = useControladorDeConteudos(eventos)

  const listarEventos = () => {
    const mapper = (evento: Evento) => {
      const titulo =
        configuracao.tipo === TipoDaAgendaDoMural.Completo
          ? evento.titulo
          : null

      return (
        <ItemAgenda
          key={evento.id}
          data={evento.data.toString()}
          titulo={titulo}
          mensagem={evento.mensagem}
          link="#"
        />
      )
    }

    return controlador.exibir().map(mapper)
  }

  const botaoVerMais = () => {
    if (!controlador.concluido()) {
      const acao = () => controlador.proximo()
      return <button onClick={acao}>Ver mais</button>
    }

    return null
  }

  return (
    <ContainerAgenda>
      <SubTituloContainer>
        <SubTituloIcone>{IconeSchedule}</SubTituloIcone>
        <SubTitulo>Agenda</SubTitulo>
      </SubTituloContainer>
      <div>
        <ul>{listarEventos()}</ul>
        {botaoVerMais()}
      </div>
    </ContainerAgenda>
  )
}
