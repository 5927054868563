import { Medias } from 'src/compartilhados/modulos/responsivo'
import styled from 'styled-components'

import { BarraDeProgressoDoCurso } from '../barra-de-progresso-do-curso'
import { Cores } from '../styles'

interface ContainerProps {
  main?: boolean
  overdue?: boolean
}

const espacamentoQuandoNaoExisteBarra = ({ overdue, main }: ContainerProps) =>
  main && overdue ? '73px' : '30px'

export const Container = styled.div<ContainerProps>`
  padding: 30px;
  grid-column: span 2;
  width: 100%;
  background-color: #f7f7f7;
  position: relative;
  border-radius: 3px;
  padding-top: ${espacamentoQuandoNaoExisteBarra};

  ${Medias.Mobile} {
    padding-top: 30px;
  }

  ${Medias.Tablet} {
    padding-top: 30px;
  }

  & > .conteudo {
    display: flex;
    flex-direction: column;
    border-left: 4px solid;
    padding-left: 17px;

    h4 {
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;

      ${Medias.Mobile} {
        font-size: 12px;
        margin-bottom: 8px;
      }

      ${Medias.Tablet} {
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    h5 {
      flex-grow: 1;
      height: ${props => (props.main ? '80px' : '24px')};
      line-height: ${props => (props.main ? '27px' : '22px')};
      font-size: ${props => (props.main ? '18px' : '14px')};
      font-weight: 300;

      ${Medias.Mobile} {
        height: 80px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 27px;
      }

      ${Medias.Tablet} {
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
      }
    }

    .progressbar {
      position: absolute;
      top: ${props => (props.main ? '30px' : 'auto')};
      bottom: ${props => (props.main ? 'auto' : '30px')};
      right: ${props => (props.main ? 'auto' : '30px')};
      left: ${props => (props.main ? '30px' : 'auto')};
    }

    a {
      font-size: ${props => (props.main ? '16px' : '12px')};
      font-weight: ${props => (props.main ? '500' : '400')};
      color: ${Cores.SECUNDARIA_2_ESCURO};

      ${Medias.Tablet} {
        font-style: italic;
      }

      &.disabled {
        opacity: 0.4;
        cursor: default;
        pointer-events: none;

        ${Medias.Mobile} {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        ${Medias.Tablet} {
          font-style: italic;
        }
      }
    }
  }

  &.postgraduate {
    grid-row: span 2;

    & > .conteudo {
      border-color: ${Cores.SECUNDARIA_2};

      h4 {
        color: ${Cores.SECUNDARIA_2};

        ${Medias.Tablet} {
          margin-bottom: 8px;
        }
      }

      .progressbar {
        ${Medias.Tablet} {
          width: 70%;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      a {
        ${Medias.Mobile} {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        ${Medias.Tablet} {
          font-style: italic;
        }
      }
    }
  }

  &.freecourse > div {
    border-color: ${Cores.SECUNDARIA_3_CLARO};

    h4 {
      color: ${Cores.SECUNDARIA_3_CLARO};
    }

    h5 {
      ${Medias.Tablet} {
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
`

export const BarraDeProgressoComEstilos = styled(BarraDeProgressoDoCurso)`
  ${Medias.Mobile} {
    height: 10px;
  }
`

export const ContainerDaBarra = styled.div`
  margin-bottom: 30px;

  ${Medias.Mobile} {
    margin-bottom: 15px;
  }

  ${Medias.Tablet} {
    display: flex;
    justify-content: center;

    ${BarraDeProgressoComEstilos} {
      width: 75%;
    }
  }
`

export const ContainerDoLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 140%;
  margin-top: 20px;

  ${Medias.Tablet} {
    width: 60%;
  }
`

export const ContainerDaBarraCompacta = styled.div`
  width: 95px;
`

export const ContainerTurma = styled.div`
  margin-top: 20px;

  h6 {
    flex-grow: 1;
    height: 30px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 300;

    ${Medias.Mobile} {
      height: 40px;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 27px;
    }

    ${Medias.Tablet} {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
    }

    :first-child {
      color: ${Cores.SECUNDARIA_2};
      font-weight: 500;
      height: 25px;
    }
  }
`

export const ContainerDatas = styled.div`
  margin-top: 28px;

  p {
    flex-grow: 1;
    height: 15px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 300;

    b {
      color: ${Cores.SECUNDARIA_3_CLARO};
      font-weight: 500;
      height: 20px;
    }
  }
`

export const TextoSituacao = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${Cores.SECUNDARIA_2};
`
