import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { ModalBase, Botao, Checkbox } from 'src/componentes'
import { OpcaoTCC } from 'src/tipos'

import { Botoes, Corpo, Texto, Titulo } from './styles'
import { ModalConfirmacaoNaoTCCProps, ModalConfirmacaoNaoTCCRef } from './tipos'

const ModalConfirmacaoNao: React.ForwardRefRenderFunction<
  ModalConfirmacaoNaoTCCRef,
  ModalConfirmacaoNaoTCCProps
> = ({ backdrop, acaoSucesso }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [ehRecusaAlunoTCC, definirEhRecusaAlunoTCC] = useState(false)
  const [carregando, definirCarregando] = useState(false)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<ModalConfirmacaoNaoTCCRef, ModalConfirmacaoNaoTCCRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoRecusa = async (opcaoTCC: OpcaoTCC) => {
    try {
      definirCarregando(true)
      await acaoSucesso(opcaoTCC)
      fechar()
    } finally {
      definirCarregando(false)
    }
  }

  const acaoCancelar = () => {
    definirEhRecusaAlunoTCC(false)
    fechar()
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <Titulo>Realização do TCC</Titulo>
        <Texto>
          Você tem certeza que deseja optar pela <b>NÃO</b> realização do
          Trabalho de Conclusão de Curso?
        </Texto>
        <Texto>
          Ao confirmar esta ação, não será mais possível alterar a opção e
          realizar a inscrição no TCC.
        </Texto>
        <Checkbox
          texto="Sim, tenho certeza e quero prosseguir com esta escolha."
          htmlFor="chk_confirmacao_nao_tcc"
          tamanho="L"
        >
          <input
            type="checkbox"
            id="chk_confirmacao_nao_tcc"
            checked={ehRecusaAlunoTCC}
            onChange={() => {
              definirEhRecusaAlunoTCC(old => !old)
            }}
          />
        </Checkbox>
        <Botoes>
          <Botao
            texto="Cancelar"
            type="button"
            tema="Secundario"
            onClick={() => acaoCancelar()}
          />
          <Botao
            texto="Salvar"
            type="button"
            onClick={() => acaoSucessoRecusa(OpcaoTCC.Nao)}
            disabled={!ehRecusaAlunoTCC}
            carregando={carregando}
          />
        </Botoes>
      </Corpo>
    </ModalBase>
  )
}

export const ModalConfirmacaoNaoTCC = forwardRef(ModalConfirmacaoNao)
