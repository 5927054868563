import styled from 'styled-components'

import { Medias } from '../styles/breakpoints'
import { Cores } from '../styles/cores'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: 'Roboto', sans-serif;

  @media ${Medias.TABLET.MAX} {
    flex-direction: column;
  }
`

export const Conteudo = styled.section`
  width: 100%;
  opacity: 1;
  margin-right: 31px;

  @media ${Medias.TABLET.MAX} {
    margin-bottom: 31px;
    margin-right: 0px;
  }
`

export const Aside = styled.aside`
  max-width: 281px;
  min-width: 281px;
  display: flex;
  flex-direction: column;

  @media ${Medias.TABLET.MAX} {
    max-width: unset;
    width: 100%;
  }
`

export const ContainerProgresso = styled.div`
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.SECUNDARIA_1_CLARA_ESCURA};
  padding: 4px 12px 9px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${Cores.CINZA_2};

    margin-bottom: 7px;
  }
`

export const CorpoModal = styled.div`
  width: 400px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TituloModal = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
`

export const ContainerTextoModal = styled.div`
  & p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    margin-bottom: 8px;
  }

  margin-bottom: 8px;
`
