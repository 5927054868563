import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m 7.8072265,3.6074348 c -0.9938708,0 -1.799568,0.8057692 -1.799568,1.7997358 0,0.9939548 0.8056972,1.799724 1.799568,1.799724 h 8.3979755 c 0.993955,0 1.799556,-0.8057692 1.799556,-1.799724 0,-0.9939666 -0.805601,-1.7997358 -1.799556,-1.7997358 z M 21.591394,2.5177196 C 21.496376,1.1129862 20.327025,0.00296013 18.898298,0.00296013 H 5.1017261 L 4.9169117,0.00918673 C 3.5122982,0.10416716 2.4023681,1.2737346 2.4023681,2.702558 v 18.594908 l 0.00621,0.184754 c 0.094985,1.404735 1.2644322,2.514819 2.6931357,2.514819 H 18.898298 l 0.184754,-0.0062 c 1.404613,-0.095 2.51458,-1.264472 2.51458,-2.693319 V 2.702558 Z M 5.1017261,1.8026962 H 18.898298 l 0.122129,0.0082 c 0.439092,0.059592 0.777649,0.4360826 0.777649,0.8916581 V 21.297466 l -0.0083,0.12213 c -0.0595,0.439211 -0.435973,0.777768 -0.8915,0.777768 H 5.1017261 l -0.122094,-0.0083 C 4.5404445,22.129433 4.201936,21.752969 4.201936,21.297441 V 2.702558 L 4.210146,2.5804521 C 4.2697298,2.1412285 4.6461963,1.8026962 5.101724,1.8026962 Z" />
  </svg>
)
