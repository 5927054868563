import React, { createContext, useContext, useState } from 'react'

import { Breakpoints } from '../../styles/breakpoints'
import { ResponsivoEstado } from './tipos'

const ResponsivoContext = createContext<ResponsivoEstado | undefined>(undefined)

export const ResponsivoProvider: React.FC = ({ children }) => {
  const ehDesktop = () => window.innerWidth > Breakpoints.TABLET

  const ehTablet = () =>
    window.innerWidth > Breakpoints.MOBILE &&
    window.innerWidth <= Breakpoints.TABLET

  const ehMobile = () => window.innerWidth <= Breakpoints.MOBILE

  const [largura, definirLargura] = useState(window.innerWidth)
  const [altura, definirAltura] = useState(window.innerHeight)
  const [desktop, definirDesktop] = useState<boolean>(ehDesktop())
  const [tablet, definirTablet] = useState<boolean>(ehTablet())
  const [mobile, definirMobile] = useState<boolean>(ehMobile())

  const manipularRedimensionar = () => {
    definirLargura(window.innerWidth)
    definirAltura(window.innerHeight)
    definirDesktop(ehDesktop())
    definirTablet(ehTablet())
    definirMobile(ehMobile())
  }

  React.useEffect(() => {
    window.addEventListener('resize', manipularRedimensionar)
    return () => window.removeEventListener('resize', manipularRedimensionar)
    // eslint-disable-next-line
  }, [])

  return (
    <ResponsivoContext.Provider
      value={{ largura, altura, desktop, tablet, mobile }}
    >
      {children}
    </ResponsivoContext.Provider>
  )
}

export const useResponsivoState = (): ResponsivoEstado => {
  const contexto = useContext(ResponsivoContext)
  if (contexto === undefined) {
    throw new Error(
      'useResponsivoState deve ser usado dentro de um ResponsivoProvider'
    )
  }
  return contexto
}
