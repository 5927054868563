import { TipoDeAvaliacao } from 'src/dto'
import {
  DisciplinaComplementar,
  ModuloTCCPayload,
  TurmaEadAvaliacaoDisciplinaPayload,
  TurmaEadAvaliacaoModuloPayload,
  TurmaPresencialAvaliacaoDisciplinaPayload,
  TurmaPresencialAvaliacaoModuloPayload
} from 'src/servicos/api/tipos'
import {
  Pergunta,
  Turma,
  PaisResidencia,
  TipoProfessorModuloTCC
} from 'src/tipos'
import { Ordinal } from 'src/tipos/ordinal'

import {
  EncontroPresencialTurma,
  FormularioTurma,
  PerguntaTurma
} from '../../../tipos'
const moduloTCCTurma = (
  turma: Omit<Turma, 'materiaisTurma' | 'cursoId' | 'nomeCurso'>,
  dados: FormularioTurma
): ModuloTCCPayload => {
  if (!turma.moduloTCC) {
    return null
  }

  const { moduloTCC } = dados
  const disciplinasModulo = []

  turma.moduloTCC?.disciplinas?.forEach(d => {
    const disciplinaEdicao = moduloTCC?.disciplina.find(
      x => x.id === d.disciplinaId
    )

    const professorOrientadores =
      disciplinaEdicao?.professoresOrientadoresIds?.length > 0
        ? disciplinaEdicao.professoresOrientadoresIds.map(p => {
            return { professorId: p, tipo: TipoProfessorModuloTCC.Orientador }
          })
        : []

    const professorBanca =
      disciplinaEdicao?.professoresBancaIds?.length > 0
        ? disciplinaEdicao.professoresBancaIds.map(p => {
            return { professorId: p, tipo: TipoProfessorModuloTCC.Banca }
          })
        : []

    const professores = [...professorOrientadores, ...professorBanca]

    const disciplinaMapeada = {
      disciplinaId: disciplinaEdicao?.id,
      professores: professores?.length > 0 ? professores : null
    }

    disciplinasModulo.push(
      disciplinaEdicao.avaliacao?.temAvaliacao
        ? {
            ...disciplinaMapeada,
            avaliacao: {
              nome: disciplinaEdicao.avaliacao?.nome,
              quantidadeTentativas:
                disciplinaEdicao.avaliacao?.quantidadeTentativas,
              descricao: disciplinaEdicao.avaliacao?.descricao,
              dataLiberacao: disciplinaEdicao.avaliacao?.dataLiberacaoAvaliacao,
              dataLimite: disciplinaEdicao.avaliacao?.dataRealizacaoAvaliacao,
              tipoAvaliacaoTurma: TipoDeAvaliacao.AvaliacaoPorAquivo
            }
          }
        : disciplinaMapeada
    )
  })

  return {
    moduloTCCTurmaId: turma.moduloTCC?.moduloId,
    nome: turma.moduloTCC?.nome,
    dataLiberacao: moduloTCC?.dataLiberacao,
    disciplinas: disciplinasModulo
  }
}

export const turmaEadAvaliacaoModuloMapper = (
  turma: Omit<Turma, 'materiaisTurma' | 'cursoId' | 'nomeCurso'>,
  perguntas: PerguntaTurma[],
  dados: FormularioTurma,
  todasDisciplinasPossuemProfessor: boolean,
  disciplinasComplementares?: DisciplinaComplementar[]
): TurmaEadAvaliacaoModuloPayload => {
  const {
    codigo,
    nome,
    dataInicio,
    dataFim,
    dataInicioInscricao,
    dataFimInscricao,
    cursoId,
    gradeCurricularId,
    professorId,
    modulo,
    grupoDiscussao,
    pesquisaSatisfacaoId,
    pesquisaSatisfacaoProfessoresId,
    turmaReingressoId,
    sincronizarCrm,
    turmaSoCertificacao,
    turmaSplit
  } = dados

  const modulosTurma = turma.modulos.map((m, indice) => {
    const moduloAtual = modulo[indice]
    let perguntasModulo: Pergunta[] = null

    if (
      moduloAtual.avaliacao?.adicionarAvaliacao &&
      moduloAtual.avaliacao?.tipo ===
        TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha
    ) {
      perguntasModulo = perguntas
        .filter(p => p.idReferencia === m.moduloId)
        .map(p => ({
          id: p.id,
          texto: p.pergunta,
          respostas: [...p.respostas],
          anular: p.anular,
          observacao: p.observacao,
          explicacao: p.explicacao
        }))
    }

    const moduloMapeado = {
      moduloTurmaId: m.moduloId,
      nome: m.nome ?? `${Ordinal[m.ordem + 1]} Módulo`,
      ordem: m.ordem,
      tipoModulo: m.tipoModulo,
      informacoesAdicionais: m.informacoesAdicionais,
      dataLiberacao: moduloAtual.dataLiberacao,
      disciplinas: moduloAtual.disciplina.map(d => ({
        disciplinaId: d.id,
        professoresIds: d.professoresIds?.length > 0 ? d.professoresIds : null
      })),
      possuiAvaliacao: moduloAtual.avaliacao?.temAvaliacao
    }

    return {
      ...moduloMapeado,
      avaliacao:
        moduloAtual.avaliacao?.adicionarAvaliacao && moduloAtual.avaliacao
          ? {
              id: moduloAtual.avaliacao?.id,
              nome: moduloAtual.avaliacao?.nome,
              quantidadeTentativas: moduloAtual.avaliacao?.quantidadeTentativas,
              descricao: moduloAtual.avaliacao?.descricao,
              dataLiberacao: moduloAtual.avaliacao?.dataLiberacaoAvaliacao,
              dataLimite: moduloAtual.avaliacao?.dataRealizacaoAvaliacao,
              tipoAvaliacaoTurma: moduloAtual.avaliacao?.tipo,
              perguntas: perguntasModulo
            }
          : null
    }
  })

  const payload: TurmaEadAvaliacaoModuloPayload = {
    dadosBasicos: {
      codigo,
      nome,
      dataInicio,
      dataFim,
      dataInicioInscricao,
      dataFimInscricao,
      cursoId,
      gradeCurricularId,
      grupoDiscussao,
      pesquisaSatisfacaoId,
      pesquisaSatisfacaoProfessoresId: todasDisciplinasPossuemProfessor
        ? pesquisaSatisfacaoProfessoresId
        : null,
      disciplinasComplementares,
      turmaReingressoId,
      sincronizarCrm,
      turmaSoCertificacao,
      turmaSplit
    },
    professorId,
    modulosTurma,
    moduloTCC: moduloTCCTurma(turma, dados)
  }

  return payload
}

export const turmaEadAvaliacaoDisciplinaMapper = (
  turma: Omit<Turma, 'materiaisTurma' | 'cursoId' | 'nomeCurso'>,
  perguntas: PerguntaTurma[],
  dados: FormularioTurma,
  todasDisciplinasPossuemProfessor: boolean,
  disciplinasComplementares?: DisciplinaComplementar[]
): TurmaEadAvaliacaoDisciplinaPayload => {
  const {
    codigo,
    nome,
    dataInicio,
    dataFim,
    dataInicioInscricao,
    dataFimInscricao,
    cursoId,
    gradeCurricularId,
    professorId,
    modulo,
    grupoDiscussao,
    pesquisaSatisfacaoId,
    pesquisaSatisfacaoProfessoresId,
    turmaReingressoId,
    sincronizarCrm,
    turmaSoCertificacao,
    turmaSplit
  } = dados

  const modulosTurma = turma.modulos.map(m => {
    const moduloAtual = modulo.find(x => x.id === m.moduloId)
    const disciplinasModulo = []

    m.disciplinas.forEach(d => {
      const disciplinaAtual = moduloAtual.disciplina.find(
        x => x.id === d.disciplinaId
      )

      const disciplinaMapeada = {
        disciplinaId: disciplinaAtual.id,
        professoresIds:
          disciplinaAtual.professoresIds?.length > 0
            ? disciplinaAtual.professoresIds
            : null,
        possuiAvaliacao: disciplinaAtual.avaliacao?.temAvaliacao
      }

      let perguntasMapeadas = []

      if (
        disciplinaAtual.avaliacao?.adicionarAvaliacao &&
        disciplinaAtual.avaliacao?.tipo ===
          TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha
      ) {
        perguntasMapeadas = perguntas
          .filter(p => p.idReferencia === d.disciplinaId)
          .map(p => ({
            id: p.id,
            texto: p.pergunta,
            respostas: [...p.respostas],
            anular: p.anular,
            observacao: p.observacao,
            explicacao: p.explicacao
          }))
      }

      disciplinasModulo.push({
        ...disciplinaMapeada,
        avaliacao:
          disciplinaAtual.avaliacao?.adicionarAvaliacao &&
          disciplinaAtual.avaliacao
            ? {
                id: disciplinaAtual.avaliacao?.id,
                nome: disciplinaAtual.avaliacao?.nome,
                quantidadeTentativas:
                  disciplinaAtual.avaliacao?.quantidadeTentativas,
                descricao: disciplinaAtual.avaliacao?.descricao,
                dataLiberacao:
                  disciplinaAtual.avaliacao?.dataLiberacaoAvaliacao,
                dataLimite: disciplinaAtual.avaliacao?.dataRealizacaoAvaliacao,
                tipoAvaliacaoTurma: disciplinaAtual.avaliacao?.tipo,
                perguntas: perguntasMapeadas
              }
            : null
      })
    })

    return {
      moduloTurmaId: m.moduloId,
      nome: m.nome ?? `${Ordinal[m.ordem + 1]} Módulo`,
      ordem: m.ordem,
      tipoModulo: m.tipoModulo,
      informacoesAdicionais: m.informacoesAdicionais,
      dataLiberacao: moduloAtual.dataLiberacao,
      disciplinas: disciplinasModulo
    }
  })

  const payload: TurmaEadAvaliacaoDisciplinaPayload = {
    dadosBasicos: {
      codigo,
      nome,
      dataInicio,
      dataFim,
      dataInicioInscricao,
      dataFimInscricao,
      cursoId,
      gradeCurricularId,
      grupoDiscussao,
      pesquisaSatisfacaoId,
      pesquisaSatisfacaoProfessoresId: todasDisciplinasPossuemProfessor
        ? pesquisaSatisfacaoProfessoresId
        : null,
      disciplinasComplementares,
      turmaReingressoId,
      sincronizarCrm,
      turmaSoCertificacao,
      turmaSplit
    },
    professorId,
    modulosTurma,
    moduloTCC: moduloTCCTurma(turma, dados)
  }

  return payload
}

export const turmaPresencialAvaliacaoModuloMapper = (
  turma: Omit<Turma, 'materiaisTurma' | 'cursoId' | 'nomeCurso'>,
  perguntas: PerguntaTurma[],
  encontros: EncontroPresencialTurma[],
  dados: FormularioTurma,
  todasDisciplinasPossuemProfessor: boolean,
  disciplinasComplementares?: DisciplinaComplementar[]
): TurmaPresencialAvaliacaoModuloPayload => {
  const {
    codigo,
    nome,
    dataInicio,
    dataFim,
    dataInicioInscricao,
    dataFimInscricao,
    cursoId,
    gradeCurricularId,
    modulo,
    grupoDiscussao,
    localizacao,
    pesquisaSatisfacaoId,
    pesquisaSatisfacaoProfessoresId,
    turmaReingressoId
  } = dados

  const modulosTurma = turma.modulos.map((m, indice) => {
    const moduloAtual = modulo[indice]
    let perguntasModulo: Pergunta[] = null

    if (
      moduloAtual.avaliacao?.adicionarAvaliacao &&
      moduloAtual.avaliacao?.tipo ===
        TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha
    ) {
      perguntasModulo = perguntas
        .filter(p => p.idReferencia === m.moduloId)
        .map(p => ({
          id: p.id,
          texto: p.pergunta,
          respostas: [...p.respostas],
          anular: p.anular,
          observacao: p.observacao,
          explicacao: p.explicacao
        }))
    }

    const moduloMapeado = {
      moduloTurmaId: m.moduloId,
      nome: m.nome ?? `${Ordinal[m.ordem + 1]} Módulo`,
      ordem: m.ordem,
      dataLiberacao: moduloAtual.dataLiberacao,
      disciplinas: moduloAtual.disciplina.map(d => ({
        disciplinaId: d.id,
        professoresIds: d.professoresIds?.length > 0 ? d.professoresIds : null,
        encontros: encontros
          .filter(e => e.idDisciplina === d.id)
          .map(e => ({
            cargaHoraria: e.cargaHoraria,
            data: e.data
          }))
      })),
      possuiAvaliacao: moduloAtual.avaliacao?.temAvaliacao
    }

    return {
      ...moduloMapeado,
      avaliacao:
        moduloAtual.avaliacao?.adicionarAvaliacao && moduloAtual.avaliacao
          ? {
              id: moduloAtual.avaliacao?.id,
              nome: moduloAtual.avaliacao?.nome,
              quantidadeTentativas: moduloAtual.avaliacao?.quantidadeTentativas,
              descricao: moduloAtual.avaliacao?.descricao,
              dataLiberacao: moduloAtual.avaliacao?.dataLiberacaoAvaliacao,
              dataLimite: moduloAtual.avaliacao?.dataRealizacaoAvaliacao,
              tipoAvaliacaoTurma: moduloAtual.avaliacao?.tipo,
              perguntas: perguntasModulo
            }
          : null
    }
  })

  const payload: TurmaPresencialAvaliacaoModuloPayload = {
    dadosBasicos: {
      codigo,
      nome,
      dataInicio,
      dataFim,
      dataInicioInscricao,
      dataFimInscricao,
      cursoId,
      gradeCurricularId,
      grupoDiscussao,
      pesquisaSatisfacaoId,
      pesquisaSatisfacaoProfessoresId: todasDisciplinasPossuemProfessor
        ? pesquisaSatisfacaoProfessoresId
        : null,
      disciplinasComplementares,
      turmaReingressoId
    },
    modulosTurma,
    localizacao: { ...localizacao, pais: PaisResidencia.Brasil },
    moduloTCC: moduloTCCTurma(turma, dados)
  }

  return payload
}

export const turmaPresencialAvaliacaoDisciplinaMapper = (
  turma: Omit<Turma, 'materiaisTurma' | 'cursoId' | 'nomeCurso'>,
  perguntas: PerguntaTurma[],
  encontros: EncontroPresencialTurma[],
  dados: FormularioTurma,
  todasDisciplinasPossuemProfessor: boolean,
  disciplinasComplementares?: DisciplinaComplementar[]
): TurmaPresencialAvaliacaoDisciplinaPayload => {
  const {
    codigo,
    nome,
    dataInicio,
    dataFim,
    dataInicioInscricao,
    dataFimInscricao,
    cursoId,
    gradeCurricularId,
    localizacao,
    modulo,
    grupoDiscussao,
    pesquisaSatisfacaoId,
    pesquisaSatisfacaoProfessoresId,
    turmaReingressoId
  } = dados

  const modulosTurma = turma.modulos.map(m => {
    const moduloAtual = modulo.find(x => x.id === m.moduloId)
    const disciplinasModulo = []

    m.disciplinas.forEach(d => {
      const disciplinaAtual = moduloAtual.disciplina.find(
        x => x.id === d.disciplinaId
      )

      const disciplinaMapeada = {
        disciplinaId: disciplinaAtual.id,
        professoresIds:
          disciplinaAtual.professoresIds?.length > 0
            ? disciplinaAtual.professoresIds
            : null,
        encontros: encontros
          .filter(e => e.idDisciplina === disciplinaAtual.id)
          .map(e => ({
            cargaHoraria: e.cargaHoraria,
            data: e.data
          })),
        possuiAvaliacao: disciplinaAtual.avaliacao?.temAvaliacao
      }

      let perguntasMapeadas = []

      if (
        disciplinaAtual.avaliacao?.adicionarAvaliacao &&
        disciplinaAtual.avaliacao?.tipo ===
          TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha
      ) {
        perguntasMapeadas = perguntas
          .filter(p => p.idReferencia === d.disciplinaId)
          .map(p => ({
            id: p.id,
            texto: p.pergunta,
            respostas: [...p.respostas],
            anular: p.anular,
            observacao: p.observacao,
            explicacao: p.explicacao
          }))
      }

      disciplinasModulo.push({
        ...disciplinaMapeada,
        avaliacao:
          disciplinaAtual.avaliacao?.adicionarAvaliacao &&
          disciplinaAtual.avaliacao
            ? {
                id: disciplinaAtual.avaliacao?.id,
                nome: disciplinaAtual.avaliacao?.nome,
                quantidadeTentativas:
                  disciplinaAtual.avaliacao?.quantidadeTentativas,
                descricao: disciplinaAtual.avaliacao?.descricao,
                dataLiberacao:
                  disciplinaAtual.avaliacao?.dataLiberacaoAvaliacao,
                dataLimite: disciplinaAtual.avaliacao?.dataRealizacaoAvaliacao,
                tipoAvaliacaoTurma: disciplinaAtual.avaliacao?.tipo,
                perguntas: perguntasMapeadas
              }
            : null
      })
    })

    return {
      moduloTurmaId: m.moduloId,
      nome: m.nome ?? `${Ordinal[m.ordem + 1]} Módulo`,
      ordem: m.ordem,
      dataLiberacao: moduloAtual.dataLiberacao,
      disciplinas: disciplinasModulo
    }
  })

  const payload: TurmaPresencialAvaliacaoDisciplinaPayload = {
    dadosBasicos: {
      codigo,
      nome,
      dataInicio,
      dataFim,
      dataInicioInscricao,
      dataFimInscricao,
      cursoId,
      gradeCurricularId,
      grupoDiscussao,
      pesquisaSatisfacaoId,
      pesquisaSatisfacaoProfessoresId: todasDisciplinasPossuemProfessor
        ? pesquisaSatisfacaoProfessoresId
        : null,
      disciplinasComplementares,
      turmaReingressoId
    },
    modulosTurma,
    localizacao: { ...localizacao, pais: PaisResidencia.Brasil },
    moduloTCC: moduloTCCTurma(turma, dados)
  }

  return payload
}
