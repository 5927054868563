import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import { Botao, Carregando, FormUnform, ModalBase } from 'src/componentes'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'
import { Api } from 'src/servicos'

import { schema } from './schema'
import {
  ContainerBotoes,
  ContainerConteudo,
  ContainerModal,
  TituloPrincipal
} from './styles'
import {
  ModalDocumentoAproveitamentoDados,
  ModalDocumentoAproveitamentoProps,
  ModalDocumentoAproveitamentoRef
} from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalDocumentoAproveitamentoRef,
  ModalDocumentoAproveitamentoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [idSalaAula, definirIdSalaAula] = useState('')
  const [possuiArquivo, definirPossuiArquivo] = useState(false)
  const formRef = useRef<FormHandles>(null)

  const fechar = () => {
    formRef?.current?.reset()
    definirPossuiArquivo(false)
    definirIdSalaAula(null)
    definirAberta(false)
  }

  const carregarPossuiArquivoAproveitamento = async (salaAulaId: string) => {
    try {
      definirPronto(false)
      const resultado = await Api.SalaAulaOutros.ObterPossuiArquivoAproveimentoTCC(
        salaAulaId
      )
      definirPossuiArquivo(resultado)
    } catch {
      toast('Erro ao carregar informações arquivo', { type: 'error' })
      fechar()
    } finally {
      definirPronto(true)
    }
  }

  const downloadArquivoAproveitamento = async () => {
    try {
      definirCarregando(true)
      await Api.SalaAulaOutros.ObterDownloadArquivoAproveitamentoTCC(idSalaAula)
    } finally {
      definirCarregando(false)
    }
  }

  const abrir = (idSalaAula: string) => {
    definirIdSalaAula(idSalaAula)
    carregarPossuiArquivoAproveitamento(idSalaAula)
    definirAberta(true)
  }

  const acaoSucessoForm = async (data: ModalDocumentoAproveitamentoDados) => {
    try {
      await acaoPrimaria({ ...data, idSalaAula })
    } finally {
      fechar()
    }
  }
  useImperativeHandle<
    ModalDocumentoAproveitamentoRef,
    ModalDocumentoAproveitamentoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-documento-aproveitamento-tcc"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Documento de aproveitamento</TituloPrincipal>
        {pronto ? (
          <>
            {!possuiArquivo ? (
              <FormUnform
                ref={formRef}
                acaoSucesso={acaoSucessoForm}
                schema={schema}
              >
                <ContainerConteudo>
                  <ArquivoInputUnform
                    label="Arquivo"
                    name="arquivo"
                    tiposAceitos={['.pdf', '.jpg', '.png']}
                  />
                </ContainerConteudo>
                <ContainerBotoes>
                  <Botao
                    texto="Voltar"
                    tema="Secundario"
                    type="button"
                    onClick={() => fechar()}
                  />
                  <Botao texto="Enviar" />
                </ContainerBotoes>
              </FormUnform>
            ) : (
              <ContainerBotoes>
                <Botao
                  texto="Voltar"
                  tema="Secundario"
                  type="button"
                  onClick={() => fechar()}
                />
                <Botao
                  texto="Baixar"
                  type="button"
                  carregando={carregando}
                  onClick={downloadArquivoAproveitamento}
                />
              </ContainerBotoes>
            )}
          </>
        ) : (
          <Carregando texto="Carregando dados arquivo aproveitamento..." />
        )}
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalDocumentoAproveitamentoTCC = forwardRef(Modal)
export * from './tipos'
