import React from 'react'

export default (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.20838 1.17957V0H8.28982V1.17957H8.83053C9.42493 1.17957 9.91153 1.71034 9.91153 2.35913V10.8204C9.91153 11.4692 9.42493 12 8.83053 12H1.08143C0.486601 12 0 11.4692 0 10.8204V2.35913C0 1.71034 0.486601 1.17957 1.08143 1.17957H1.62171V0H2.70314V1.17957H7.20838ZM8.83053 10.8204H1.08143V4.12845H8.83053V10.8204ZM8.10939 7.08518H5.40625V10.0341H8.10939V7.08518Z"
      fill="black"
      fillOpacity="0.3"
    />
  </svg>
)
