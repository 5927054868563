export enum CorRaca {
  NaoInformado = 0,
  Branca = 1,
  Preta = 2,
  Parda = 3,
  Indigena = 4,
  Amarela = 5
}

export enum Raca {
  NaoInformado = 'Não informado',
  Amarela = 'Amarela',
  Branca = 'Branca',
  Indigena = 'Indígena',
  Parda = 'Parda',
  Preta = 'Preta'
}

export enum RacaTexto {
  NaoInformado = 'NaoInformado',
  Amarela = 'Amarela',
  Branca = 'Branca',
  Indigena = 'Indigena',
  Parda = 'Parda',
  Preta = 'Preta'
}
