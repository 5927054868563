import styled from 'styled-components'

export const ContainerModal = styled.div`
  background: white;
  height: 300px;
  overflow-y: auto;
  min-width: 550px !important;

  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 46px;
`

export const TituloPrincipal = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 36px;
  text-align: center;
`

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
`

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
`

export const Coluna = styled.div`
  flex: 1;

  & + & {
    margin-left: 5px;
  }
`

export const IconeLabel = styled.div`
  position: absolute;
  right: 58%;
  top: 0;

  svg {
    min-width: 12px;
    min-height: 12px;
  }
`
export const ContainerBotoes = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;

  & > button:nth-of-type(1) {
    margin-right: 16px;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 25px;
  p {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
    b {
      opacity: 1;
      color: #3d3d3d;
      font-weight: 400;
    }
  }
  span {
    margin-right: 5px;
    margin-top: 2px;
  }
`
