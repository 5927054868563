import React, { useState, useMemo, useEffect } from 'react'

import { Scope } from '@unform/core'
import {
  Accordion,
  InputUnform,
  SelectAsyncUnform,
  SelectOpcao,
  Spacer
} from 'src/componentes'
import { Api } from 'src/servicos'
import {
  ModalidadeCurso,
  Professor,
  TipoAvaliacao,
  TipoProfessorModuloTCC
} from 'src/tipos'

import PaginaCadastroTurmaContainer from '../../../container'
import { ListaMateriaisEad } from '../../ead'
import { ListaEncontrosPresencial } from '../../presencial'
import { Avaliacao } from '../avaliacao'
import { ListaMateriais } from '../lista-materiais'
import { SelectOpcaoProfessor, AccordionDisciplinaProps } from './tipos'

interface ProfessorDisciplina extends Professor {
  tipo?: TipoProfessorModuloTCC
}

export const AccordionDisciplina: React.FC<AccordionDisciplinaProps> = ({
  disciplina,
  indice,
  modalidade,
  ehModuloTCC,
  professores,
  deveExpandir,
  professoresAlterados
}) => {
  const { turma, ehModalidadeEad } = PaginaCadastroTurmaContainer.useContainer()
  const [professoresDisciplina, definirProfessoresDisciplina] = useState<
    ProfessorDisciplina[]
  >([])

  const obterProfessoresNome = async (
    nome: string
  ): Promise<SelectOpcaoProfessor[]> => {
    const professoresSelecionados = professores.filter(professor => {
      return nome.toUpperCase() === professor.nome.substring(0, nome.length)
    })

    return professoresSelecionados.map(professor => ({
      id: professor.id,
      texto: professor.nome,
      professor: professor
    }))
  }

  const obterProfessorId = async (
    ids: string[] | string
  ): Promise<SelectOpcaoProfessor[]> => {
    const params = ids instanceof Array ? ids : [ids]

    const result = []
    for (const id of params) {
      result.push(await Api.ObterProfessor(id))
    }

    return result.map(x => ({ id: x.id, texto: x.nome, professor: x }))
  }

  const exibirListaEncontros = useMemo(
    () =>
      turma.modalidadeCurso !== ModalidadeCurso.PosGraduacaoEad &&
      turma.modalidadeCurso !== ModalidadeCurso.PosGraduacaoEadFlex &&
      turma.modalidadeCurso !== ModalidadeCurso.PosGraduacaoEadFull,
    [turma.modalidadeCurso]
  )

  const subtitulo = useMemo(() => {
    if (professoresDisciplina && !ehModalidadeEad) {
      const primeiroProfessor = professoresDisciplina[0]
      if (primeiroProfessor) {
        return `Professor Responsável: ${primeiroProfessor?.nome}`
      }
    }
    return null
  }, [professoresDisciplina])

  const atualizarProfessores = (
    opcoes: SelectOpcao[],
    tipo?: TipoProfessorModuloTCC
  ) => {
    const idsSelecionados = opcoes?.map(x => x.id)
    const professoresSelecionados = professores.filter(x =>
      idsSelecionados.some(y => y === x.id)
    )

    if (tipo) {
      const filtrado = professoresDisciplina.filter(x => x.tipo !== tipo)
      const selecionados = (professoresSelecionados?.map(x => {
        return {
          ...x,
          tipo: tipo
        }
      }) as unknown) as ProfessorDisciplina[]

      definirProfessoresDisciplina([...filtrado, ...selecionados])
    } else {
      definirProfessoresDisciplina(professoresSelecionados)
    }
  }

  useEffect(() => {
    if (professoresAlterados) {
      professoresAlterados(
        disciplina.disciplinaId,
        professoresDisciplina?.length > 0 ?? false
      )
    }
  }, [professoresDisciplina])

  return (
    <Accordion
      titulo={disciplina?.nome}
      subtitulo={subtitulo}
      deveExpandir={deveExpandir}
    >
      <InputUnform name="id" esconder />

      {ehModuloTCC ? (
        <>
          <SelectAsyncUnform
            name="professoresOrientadoresIds"
            multiplo
            id={`slct_professor_orientados_${indice}`}
            label="Professor Orientador da Disciplina"
            placeholder="Selecione"
            buscarPorTexto={obterProfessoresNome}
            buscarPorId={obterProfessorId}
            valorAlteradoMulti={opcoes =>
              atualizarProfessores(opcoes, TipoProfessorModuloTCC.Orientador)
            }
          />
          <Spacer padding="5px 5px" />
          <SelectAsyncUnform
            name="professoresBancaIds"
            multiplo
            id={`slct_professor_banca_${indice}`}
            label="Professor da Banca da Disciplina"
            placeholder="Selecione"
            buscarPorTexto={obterProfessoresNome}
            buscarPorId={obterProfessorId}
            valorAlteradoMulti={opcoes =>
              atualizarProfessores(opcoes, TipoProfessorModuloTCC.Banca)
            }
          />
        </>
      ) : (
        <SelectAsyncUnform
          name="professoresIds"
          id={`slct_professor_${indice}`}
          label="Professor Responsável pela Disciplina"
          placeholder="Selecione"
          buscarPorTexto={obterProfessoresNome}
          buscarPorId={obterProfessorId}
          multiplo
          valorAlteradoMulti={opcoes => atualizarProfessores(opcoes)}
        />
      )}

      <Spacer padding="10px 0px" />
      {exibirListaEncontros && (
        <>
          <ListaEncontrosPresencial idDisciplina={disciplina.disciplinaId} />
          <Spacer padding="10px 0px" />
        </>
      )}

      {(turma.tipoAvaliacaoCurso ===
        TipoAvaliacao[TipoAvaliacao.AvaliacaoPorDisciplina] ||
        ehModuloTCC) && (
        <Scope path="avaliacao">
          <Avaliacao
            indice={indice}
            ehModuloTCC={ehModuloTCC}
            idReferencia={disciplina.disciplinaId}
            tipo={disciplina.avaliacao?.tipoAvaliacaoTurma}
            contemAvaliacao={!!disciplina.avaliacao}
            possuiAvaliacao={disciplina.possuiAvaliacao}
          />
          <Spacer padding="20px 0px" />
        </Scope>
      )}
      {ehModalidadeEad ? (
        <>
          <Spacer padding="10px 0px" />
          <ListaMateriaisEad idDisciplina={disciplina.disciplinaId} />
        </>
      ) : (
        <ListaMateriais
          titulo="Materiais da Disciplina"
          modalidadeTurma={modalidade}
          idDisciplina={disciplina.disciplinaId}
        />
      )}
    </Accordion>
  )
}
