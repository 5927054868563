import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  CheckboxUnform,
  MoedaInputUnform,
  DataInputUnform,
  NumeroInputUnform
} from 'src/componentes'
import {
  dataValidaAmericana,
  transformarPadraoAmericano
} from 'src/componentes/funcoes/data-hora'
import { mensagemPadrao } from 'src/dados-estaticos'
import {
  somarDias,
  subtrairDatas
} from 'src/paginas/sala-aula/shared/funcoes/calcularDatas'
import { TipoProrrogacao } from 'src/tipos'
import * as Yup from 'yup'

import { schema } from './schema'
import {
  ContainerAcoes,
  ContainerMedio,
  ContainerModal,
  ContainerOpcoes,
  CorpoModal,
  Linha
} from './styles'
import {
  ModalProrrogacaoManualRef,
  ModalProrrogacaoManualProps,
  ProrrogacaoManual
} from './tipos'

const ModalProrrogacaoManualAluno: React.ForwardRefRenderFunction<
  ModalProrrogacaoManualRef,
  ModalProrrogacaoManualProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const formLicencaRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [ehIsento, definirIsento] = useState(false)
  const [matriculaId, definirMatriculaId] = useState<string>()
  const [dataMinima, definirDataMinima] = useState<Date>()
  const [tiposProrrogacao, definirTiposProrrogacao] = useState<
    TipoProrrogacao[]
  >([])

  const [tipoProrrogacao, definirTipoProrrogacao] = useState<TipoProrrogacao>(
    TipoProrrogacao.Padrao
  )

  const [dataNascimento, definirDataNascimento] = useState<Date>()
  const [
    tempoProrrogacaoLicenca,
    definirTempoProrrogacaoLicenca
  ] = useState<number>(0)

  const dadosIniciais = useMemo(() => {
    return tipoProrrogacao === TipoProrrogacao.Padrao
      ? {}
      : {
          dataFinalizacaoOriginal: dataMinima,
          novaDataFinalizacao: dataMinima
            ? somarDias(dataMinima, tempoProrrogacaoLicenca)
            : null,
          dataNascimento: dataNascimento
        }
  }, [tipoProrrogacao, dataMinima, dataNascimento, tempoProrrogacaoLicenca])
  const fechar = () => {
    definirDataMinima(null)
    definirMatriculaId(null)
    definirTiposProrrogacao([])
    definirTipoProrrogacao(TipoProrrogacao.Padrao)
    definirDataNascimento(null)
    definirTempoProrrogacaoLicenca(0)
    definirPronto(false)
    definirAberta(false)
    definirIsento(false)
  }

  const abrir = (
    matriculaId: string,
    dataFinalizacaoAtual: string,
    tipos: TipoProrrogacao[],
    dataNascimento?: string,
    tempoProrrogacaoLicenca?: number
  ) => {
    definirDataMinima(new Date(`${dataFinalizacaoAtual}Z`))
    definirMatriculaId(matriculaId)
    definirTiposProrrogacao(tipos)
    definirDataNascimento(new Date(`${dataNascimento}Z`))
    definirTempoProrrogacaoLicenca(tempoProrrogacaoLicenca)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalProrrogacaoManualRef, ModalProrrogacaoManualRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoForm = async (dados: ProrrogacaoManual) => {
    try {
      definirCarregando(true)
      const payload = {
        matriculaId,
        tipo: tipoProrrogacao,
        tempoProrrogacao:
          tipoProrrogacao === TipoProrrogacao.Licenca
            ? tempoProrrogacaoLicenca
            : dados.tempoProrrogacao,
        isento: tipoProrrogacao === TipoProrrogacao.Licenca || dados.isento,
        ...dados
      } as ProrrogacaoManual
      await acaoPrimaria(payload)
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  const limparCampoForm = (campo: string) => {
    formRef?.current?.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, null)
  }

  const alterarCampoForm = (campo: string, valor: any) => {
    formRef?.current?.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const schemaDinamico = useMemo(() => {
    return schema.concat(
      Yup.object().shape({
        novaDataFinalizacao: Yup.string()
          .dataMaiorQue(dataMinima)
          .required(mensagemPadrao)
      })
    )
  }, [dataMinima, schema])
  return (
    <ModalBase
      id="modal-prorrogacao-manual"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          <h5>Prorrogação Manual</h5>
          {tiposProrrogacao.some(x => x === TipoProrrogacao.Licenca) && (
            <ContainerOpcoes>
              <Botao
                texto="Prorrogação Manual"
                tema={
                  tipoProrrogacao === TipoProrrogacao.Padrao
                    ? 'Primario'
                    : 'Secundario'
                }
                type="button"
                disabled={carregando}
                onClick={() => definirTipoProrrogacao(TipoProrrogacao.Padrao)}
              />
              <Botao
                texto="Licença Maternidade"
                tema={
                  tipoProrrogacao === TipoProrrogacao.Licenca
                    ? 'Primario'
                    : 'Secundario'
                }
                carregando={carregando}
                onClick={() => definirTipoProrrogacao(TipoProrrogacao.Licenca)}
              />
            </ContainerOpcoes>
          )}
          {pronto && tipoProrrogacao === TipoProrrogacao.Padrao && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaDinamico}
              ref={formRef}
            >
              <Linha>
                <ContainerMedio>
                  {/* TO-DO: Conferir se esse é o melhor jeito de somar os dias */}
                  <NumeroInputUnform
                    id="tempoProrrogacao"
                    name="tempoProrrogacao"
                    label="Tempo da Prorrogação (dias)"
                    onChange={input => {
                      const valor = input.currentTarget.value
                      alterarCampoForm('novaDataFinalizacao', '')
                      if (valor && Number(valor) > 0) {
                        alterarCampoForm(
                          'novaDataFinalizacao',
                          somarDias(dataMinima, +valor)
                        )
                      }
                    }}
                    obrigatorio
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <DataInputUnform
                    id="novaDataFinalizacao"
                    name="novaDataFinalizacao"
                    label="Nova data de finalização"
                    obrigatorio
                    onChange={input => {
                      alterarCampoForm('tempoProrrogacao', '')
                      const valor = input.target.value
                      if (valor) {
                        const padraoAmericado = transformarPadraoAmericano(
                          valor
                        )

                        if (dataValidaAmericana(padraoAmericado)) {
                          const dias = subtrairDatas(
                            dataMinima,
                            new Date(`${padraoAmericado} 00:00:00`)
                          )

                          alterarCampoForm('tempoProrrogacao', dias)
                        }
                      }
                    }}
                  />
                </ContainerMedio>
              </Linha>
              <Linha>
                <ContainerMedio>
                  <CheckboxUnform
                    id="chk-isento"
                    name="isento"
                    texto="Isento"
                    onChange={() => {
                      definirIsento(old => !old)

                      limparCampoForm('valorProrrogacao')
                      limparCampoForm('quantidadeParcelas')
                      limparCampoForm('dataPrimeiroVencimento')
                    }}
                  />
                </ContainerMedio>
                {!ehIsento && (
                  <ContainerMedio>
                    <MoedaInputUnform
                      id="valorProrrogacao"
                      name="valorProrrogacao"
                      label="Valor da Prorrogação"
                      obrigatorio
                    />
                  </ContainerMedio>
                )}
              </Linha>
              {!ehIsento && (
                <Linha>
                  <ContainerMedio>
                    <NumeroInputUnform
                      id="quantidadeParcelas"
                      name="quantidadeParcelas"
                      label="Quantidade de Parcelas"
                      obrigatorio
                    />
                  </ContainerMedio>
                  <ContainerMedio>
                    <DataInputUnform
                      id="dataPrimeiroVencimento"
                      name="dataPrimeiroVencimento"
                      label="Data Primeiro Vencimento"
                      obrigatorio
                    />
                  </ContainerMedio>
                </Linha>
              )}
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Salvar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
          {pronto && tipoProrrogacao === TipoProrrogacao.Licenca && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              dadosIniciais={dadosIniciais}
              ref={formLicencaRef}
            >
              <Linha>
                <ContainerMedio>
                  <DataInputUnform
                    id="dataFinalizacaoOriginal"
                    name="dataFinalizacaoOriginal"
                    label="Data de finalização original"
                    disabled={true}
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <DataInputUnform
                    id="novaDataFinalizacao"
                    name="novaDataFinalizacao"
                    label="Nova data de finalização"
                    disabled={true}
                  />
                </ContainerMedio>
              </Linha>
              <Linha>
                <DataInputUnform
                  id="dataNascimento"
                  name="dataNascimento"
                  label="Data de Nascimento"
                  disabled={true}
                />
              </Linha>
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Salvar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalProrrogacaoManual = forwardRef(ModalProrrogacaoManualAluno)
