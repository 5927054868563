import React, { Suspense, useMemo } from 'react'

import { Botao } from 'src/componentes'
import { Api } from 'src/servicos'

import { Container, ContainerBotao } from '../../shared/styles'
import SalaAulaCursoLivreContainer from '../container'

const Questao = React.lazy(() => import('../../shared/avaliacao/questao'))

export const Avaliacao: React.FC = () => {
  const {
    salaAula,
    definirSalaAula,
    definirProgresso,
    emitirCertificado
  } = SalaAulaCursoLivreContainer.useContainer()

  const acaoSucesso = async (
    payload: {
      idPergunta: string
      idResposta: string
    }[]
  ) => {
    const resposta = await Api.SalaAulaCursoLivre.EnviarAvaliacao(salaAula.id, {
      perguntasComRespostas: payload
    })

    definirSalaAula({
      ...salaAula,
      avaliacao: {
        ...salaAula.avaliacao,
        nota: resposta.nota,
        dataUltimaTentativa: String(new Date())
      },
      aprovado: resposta.aprovado,
      quantidadeTentativasAvaliacaoRealizada:
        salaAula.quantidadeMaximaTentativasAvaliacao -
        resposta.tentativasRestantes,
      ultimaTentativaAvaliacao: resposta.perguntasRespondidas
    })

    if (resposta.aprovado) definirProgresso(100)
  }

  const botaoAvancar = useMemo(() => {
    return (
      <ContainerBotao>
        <Botao texto="Emitir Certificado" onClick={emitirCertificado} />
      </ContainerBotao>
    )
  }, [])

  return (
    <Container>
      <Suspense fallback={<>Carregando...</>}>
        <Questao
          idSala={salaAula.id}
          ultimaTentativaAvaliacao={salaAula.ultimaTentativaAvaliacao}
          quantidadeMaximaTentativas={
            salaAula.quantidadeMaximaTentativasAvaliacao
          }
          quantidadeTentativasRealizadas={
            salaAula.quantidadeTentativasAvaliacaoRealizada
          }
          aprovado={salaAula.aprovado}
          media={salaAula.media}
          nota={salaAula.avaliacao?.nota}
          dataUltimaTentativa={salaAula.avaliacao?.dataUltimaTentativa}
          nomeAvaliacao={salaAula.avaliacao?.nome}
          perguntas={salaAula.avaliacao?.perguntas}
          acaoSucesso={acaoSucesso}
          botaoAvancar={botaoAvancar}
          notaAproveitamento={false}
          descricaoArquivo={salaAula.avaliacao.descricao}
        />
      </Suspense>
    </Container>
  )
}
