import { Cores } from 'src/componentes/styles'
import { FormUnform } from 'src/componentes/unform'
import styled, { css } from 'styled-components'

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Dados = styled.div`
  margin-top: 25px;
  button:disabled {
    opacity: 0.2;
  }
`

export const Subtitulo = styled.div`
  margin: 4px;
  text-align: center;
`

export const ModalCorpo = styled.div`
  width: 574px;
  min-height: 470px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  form {
    width: 100%;
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`

export const SubtituloModal = styled.h5`
  margin-bottom: 60px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-top: 10px;
`

export const Linha = styled.div<{
  justifyContent?: 'flex-end' | 'space-between'
}>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  ${({ justifyContent }) => {
    return css`
      justify-content: ${justifyContent ?? 'space-between'};
    `
  }}
`
export const Titulo = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ContainerRespostasErradas = styled.div`
  padding-bottom: 20px;
`
export const ContainerMain = styled.div`
  width: 98%;
`
