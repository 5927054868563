import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  background: ${Cores.BRANCO};
  border-radius: 5px;

  & > h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  & > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }
`

export const ContainerNomeBotao = styled.div`
  display: flex;
  margin-top: 37px;
  margin-bottom: 31px;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  button {
    border: none;
    background: none;
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }
`
