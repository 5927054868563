import React from 'react'

import {
  Accordion,
  InputUnform,
  TipoMaterialComplementar,
  TipoMaterialComplementarPorExtenso
} from 'src/componentes'

import { ListaMateriaisEad } from '../../ead'
import { AccordionMaterialComplementarProps } from './tipos'

export const AccordionMaterialComplementar: React.FC<AccordionMaterialComplementarProps> = ({
  tipoMaterialComplementar,
  definirNome
}) => {
  return (
    <Accordion
      titulo={TipoMaterialComplementarPorExtenso[tipoMaterialComplementar]}
    >
      {TipoMaterialComplementar.Encontros ===
        TipoMaterialComplementar[tipoMaterialComplementar] && (
        <InputUnform
          name={`nomePersonalizado${tipoMaterialComplementar}`}
          label="Nome Personalizado"
          onChange={valor =>
            definirNome &&
            definirNome(tipoMaterialComplementar, valor.target.value)
          }
          maxLength={200}
        />
      )}

      <ListaMateriaisEad
        tipoMaterialComplementar={tipoMaterialComplementar}
        idDisciplina={tipoMaterialComplementar}
      />
    </Accordion>
  )
}
