import React, { useEffect, useState, forwardRef, ChangeEvent } from 'react'
import { InputState } from 'react-input-mask'

import { Input } from '.'
import { CartaoCreditoInputProps } from './tipos'

export const CartaoCreditoInput: React.ForwardRefExoticComponent<CartaoCreditoInputProps> = forwardRef<
  HTMLInputElement,
  CartaoCreditoInputProps
>(({ value, onChange, ...rest }, ref?: any) => {
  const [valor, definirValor] = useState(value ?? '')
  type mascaras =
    | '9999 999999 9999'
    | '9999 999999 99999'
    | '9999 9999 9999 9999'
  const [mascara, definirMascara] = useState<mascaras>('9999 999999 99999')

  const alterarValor = (valor: string) => {
    const valorFinal = valor ? valor.replace(/\D/g, '') || '' : ''

    if (valorFinal.length <= 14 && mascara !== '9999 999999 9999') {
      definirMascara('9999 999999 9999')
    } else if (valorFinal.length === 15 && mascara !== '9999 999999 99999') {
      definirMascara('9999 999999 99999')
    } else if (valorFinal.length > 15 && mascara !== '9999 9999 9999 9999') {
      definirMascara('9999 9999 9999 9999')
    }

    definirValor(valorFinal)

    if (onChange) {
      onChange({
        target: { value: valorFinal }
      } as ChangeEvent<HTMLInputElement>)
    }
  }

  useEffect(() => {
    alterarValor(value)
  }, [value])

  useEffect(() => {
    if (value) {
      const valorLimpo = value.replace(/\D/g, '')

      if (valorLimpo.length === 16) {
        definirMascara('9999 9999 9999 9999')
      } else if (valorLimpo.length === 15) {
        definirMascara('9999 999999 99999')
      } else if (valorLimpo.length <= 14) {
        definirMascara('9999 999999 9999')
      }
    }
  }, [])

  const colar = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const valor = e.clipboardData.getData('Text').trim()
    alterarValor(valor)
  }

  const antesDeAlterarOValor = (
    estadoNovo: InputState,
    estadoAntigo: InputState,
    valorUsuario: string
  ): InputState => {
    let { value: valorNovo, selection } = estadoNovo
    const { value: valorAntigo } = estadoAntigo

    if (
      !/\D/.test(valorUsuario) &&
      (valorAntigo.replace(/\D/g, '').length === 14 ||
        valorAntigo.replace(/\D/g, '').length === 15)
    ) {
      valorNovo += valorUsuario
      selection = { start: selection.start + 1, end: selection.end + 1 }
    }

    return {
      value: valorNovo,
      selection
    }
  }

  return (
    <Input
      {...rest}
      ref={ref}
      mascara={mascara}
      value={valor}
      onChange={e => alterarValor(e.target.value)}
      onPaste={colar}
      beforeMaskedValueChange={antesDeAlterarOValor}
    />
  )
})
