import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'
const caracter = Yup.string().required(mensagemPadrao)

export const schemaProfessorTCC = Yup.object().shape({
  professorOrientadorId: caracter,
  professorBancaId: caracter.notOneOf(
    [Yup.ref('professorOrientador')],
    'Professor Banca deve ser diferente de Orientador.'
  )
})
