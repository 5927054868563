import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast, ToastOptions } from 'react-toastify'

import {
  AvisoContainer,
  RelacaoDocumentosAlunoEstrangeiro,
  RelacaoDocumentosLicencaAluno,
  ResumoDeDocumentosDeSaidaMapper,
  ResumoDeDocumentosMultiplosDeEntradaMapper,
  selecaoDeDocumentosParaEnvio,
  TipoDeAviso,
  UploadDeDocumentos,
  UploadDocumentos,
  useRelacaoDeDocumentos
} from 'src/compartilhados'
import {
  AutenticacaoContainer,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  FuncoesDataHora,
  Modal,
  ModalRef
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { Documento, SituacaoUpload, TipoDocumentoAluno } from 'src/tipos'

import {
  BotaoDeSalvar,
  ContainerDaPagina,
  ContainerDosBotoes,
  Dicas,
  TituloComplementar,
  TituloPrincipal
} from './style'

const documentosSalvosComSucesso = 'Documentos salvos com sucesso.'
const documentosPendentes = 'Você ainda possui documentos pendentes.'
const documentosSalvosComPendencia = `Documentos salvos com sucesso. ${documentosPendentes}`
const erroAoEnviarDocumentos = 'Erro ao enviar documentos.'
const erroAoBaixarDocumentos = 'Erro ao baixar documentos.'
const erroAoAtualizarDocumentos = 'Erro ao atualizar documentos.'
const erroAoProcessarDocumentos = 'Erro ao processar documentos.'
const ErroAoObterDocumentos = 'Não foi possível obter os documentos do aluno.'
const erroAoEnviarMotivoAlteracaoAprovado = 'Erro ao enviar motivo alteração'

const toastOptions = { type: 'error' } as ToastOptions

const DocumentosAlunoEstrangeiro: FC = () => {
  const history = useHistory()
  const { documentos, atualizar, reiniciar } = useRelacaoDeDocumentos(
    RelacaoDocumentosAlunoEstrangeiro
  )
  const [pronto, definirPronto] = useState<boolean>()
  const [carregando, definirCarregando] = useState<boolean>()
  const modalDeCancelamento = useRef<ModalRef>(null)

  const aviso = AvisoContainer.useContainer()
  const { usuario } = AutenticacaoContainer.useContainer()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const tiposLicenca = useMemo(
    () => RelacaoDocumentosLicencaAluno.map(x => x.tipo),
    [RelacaoDocumentosLicencaAluno]
  )

  const obterResumoDeDocumentos = async (idDoUsuario: string) => {
    try {
      definirPronto(false)
      limparErro()
      const resposta = await Api.ObterResumoDeDocumentos(idDoUsuario)

      let resultado = ResumoDeDocumentosMultiplosDeEntradaMapper(
        documentos,
        resposta
      )

      const duplicadosAprovados = RelacaoDocumentosAlunoEstrangeiro.filter(
        x =>
          x.multiplo &&
          resultado
            .filter(y => y.tipo === x.tipo)
            .every(z => z.status === SituacaoUpload.Aprovado)
      )

      if (duplicadosAprovados?.length) {
        const duplicados = duplicadosAprovados.map(x => {
          return RelacaoDocumentosAlunoEstrangeiro.find(y => y.tipo === x.tipo)
        })

        resultado = [...resultado, ...duplicados]
      }

      reiniciar(resultado)
    } catch (erro) {
      const mensagens = ErroAoObterDocumentos
      const acaoVoltar = () => history.goBack()
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const verificacaoDePreenchimento = (): void => {
    const documentosPreenchidos = documentos.every(documento => documento.nome)

    documentosPreenchidos
      ? aviso.criar(documentosSalvosComSucesso, TipoDeAviso.Sucesso)
      : aviso.criar(documentosSalvosComPendencia, TipoDeAviso.Advertencia)

    history.push(RotasAluno.Dashboard)
  }

  const uploadDeCriacao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EnviarDocumentosDoAluno(usuario.id, formulario)

      if (resposta) {
        verificacaoDePreenchimento()
      } else {
        toast(erroAoEnviarDocumentos, toastOptions)
      }
    } catch (erro) {
      toast(erroAoEnviarDocumentos, toastOptions)
    }
  }

  const uploadDeEdicao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EditarDocumentosDoAluno(usuario.id, formulario)

      if (resposta) {
        const documentosAprovados = documentos.filter(
          x => x.motivoAlteracaoAprovado
        )

        if (documentosAprovados?.length > 0) {
          try {
            await Api.AlteracaoAprovado(usuario.id, documentosAprovados)
          } catch {
            toast(erroAoEnviarMotivoAlteracaoAprovado, toastOptions)
          }
        }
        verificacaoDePreenchimento()
      } else {
        toast(erroAoAtualizarDocumentos, toastOptions)
      }
    } catch (erro) {
      toast(erroAoAtualizarDocumentos, toastOptions)
    }
  }

  const uploadDeDocumentos = async (
    edicoes: UploadDocumentos[],
    criacoes: UploadDocumentos[]
  ): Promise<void> => {
    try {
      definirCarregando(true)
      if (edicoes.length > 0) await uploadDeEdicao(edicoes)
      if (criacoes.length > 0) await uploadDeCriacao(criacoes)
    } catch (erro) {
      toast(erroAoProcessarDocumentos, toastOptions)
    } finally {
      definirCarregando(false)
    }
  }

  const abrirImagemNovaAba = (documentoAluno: Documento) => {
    const novaAba = window.open('')
    if (documentoAluno.contentType === 'application/pdf') {
      novaAba.document.write(
        `<iframe width='100%' height='100%' src='data:${documentoAluno?.contentType};base64, ` +
          encodeURI(documentoAluno?.base64) +
          "' />"
      )
    } else {
      const img = `<img src='${`data:${documentoAluno?.contentType};base64, ${documentoAluno?.base64}`}' />`
      novaAba.document.write(img)
    }
    novaAba.document.title = `Documento de ${usuario.nome}`
  }

  const visualizarDocumento = async (documentoId: string) => {
    try {
      const novoDocumento = await Api.RequisitarDocumentoAluno(
        usuario.id,
        documentoId
      )
      if (novoDocumento) {
        abrirImagemNovaAba(novoDocumento)
      }
    } catch (error) {
      toast(ErroAoObterDocumentos, toastOptions)
    }
  }

  const eventoDeUpload = async (): Promise<void> => {
    const { edicoes, criacoes, contador } = selecaoDeDocumentosParaEnvio(
      documentos
    )

    if (contador === 0) history.push(RotasAluno.Dashboard)
    else await uploadDeDocumentos(edicoes, criacoes)
  }

  const obterDownloadDeDocumentos = async (
    documentoId: string
  ): Promise<void> => {
    try {
      await Api.ObterDownloadDeDocumentos(usuario.id, documentoId)
    } catch (erro) {
      toast(erroAoBaixarDocumentos, toastOptions)
    }
  }

  const documentosAluno = useMemo(() => {
    return documentos.filter(x => !tiposLicenca.some(y => y === x.tipo))
  }, [documentos])

  const documentosLicenca = useMemo(() => {
    return documentos.filter(x => tiposLicenca.some(y => y === x.tipo))
  }, [documentos])

  useEffect(() => {
    obterResumoDeDocumentos(usuario.id)
  }, [])

  return pronto && !erro ? (
    <ContainerDaPagina>
      <TituloPrincipal>Envio de Documentos</TituloPrincipal>
      <TituloComplementar>Meus documentos</TituloComplementar>
      <Dicas>
        O envio dos documentos é obrigatório para emissão do certificado do(s)
        curso(s)
      </Dicas>
      <Dicas>
        Atenção! Enquanto o Histórico da Graduação não for anexado e aprovado,
        todos os atestados serão emitidos como curso de Extensão e não de
        Pós-Graduação
      </Dicas>
      {documentosAluno.map(documento => (
        <UploadDeDocumentos
          key={documento.id}
          documento={documento}
          eventoDeMudanca={atualizar}
          eventoDeDownload={obterDownloadDeDocumentos}
          eventoDeVisualizacao={visualizarDocumento}
        />
      ))}
      <TituloComplementar>Licença Maternidade/Paternidade</TituloComplementar>
      <Dicas>
        Documento obrigatório apenas para solicitação de licença
        maternidade/paternidade
      </Dicas>
      {documentosLicenca
        .sort((d1, d2) => d1.ordemExibicao - d2.ordemExibicao)
        .map((documento, indice) => (
          <UploadDeDocumentos
            key={`licenca-${indice}`}
            documento={documento}
            eventoDeMudanca={atualizar}
            eventoDeDownload={obterDownloadDeDocumentos}
            eventoDeVisualizacao={visualizarDocumento}
            exibirDataNascimento={
              documento.tipo === TipoDocumentoAluno.CertidaoNascimento
            }
            dataNascimento={
              documento.dataNascimento
                ? FuncoesDataHora.novaData(documento.dataNascimento)
                : null
            }
          />
        ))}
      <ContainerDosBotoes>
        <Botao
          texto="Cancelar"
          tema="Secundario"
          type="button"
          onClick={() => modalDeCancelamento?.current?.abrir()}
        />
        <BotaoDeSalvar
          texto="Salvar"
          type="button"
          onClick={eventoDeUpload}
          carregando={carregando}
        />
      </ContainerDosBotoes>
      <Modal
        ref={modalDeCancelamento}
        backdrop
        id="modal-de-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.push(RotasAluno.Dashboard)
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamento?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
    </ContainerDaPagina>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando documentos do aluno..." />
  ) : (
    <ErroContainerLayout />
  )
}

const Container: FC = () => (
  <ErroLayoutContainer.Provider>
    <DocumentosAlunoEstrangeiro />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
