import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { TipoRecorrenciaAcaoPorExtenso } from 'src/tipos'

export const colunaTipoRecorrencia = (): TabelaColuna => ({
  Chave: 'tipoRecorrencia',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{TipoRecorrenciaAcaoPorExtenso[linha.tipoRecorrencia]}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 160 }}>Tipo Recorrência</div>
})
