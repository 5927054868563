import React, { FC } from 'react'

import {
  IconeDeAvisoDeAdvertencia,
  IconeDeAvisoDeErro,
  IconeDeAvisoDeSucesso
} from 'src/componentes'

import AvisoContainer from './aviso.container'
import { ContainerDoAviso, Icone, Mensagem } from './aviso.style'
import { TipoDeAviso } from './tipo-de-aviso.enum'

const obterIconeDeAviso = (tipo: TipoDeAviso) => {
  switch (tipo) {
    case TipoDeAviso.Advertencia:
      return IconeDeAvisoDeAdvertencia
    case TipoDeAviso.Erro:
      return IconeDeAvisoDeErro
    default:
      return IconeDeAvisoDeSucesso
  }
}

export const Aviso: FC = () => {
  const {
    ativo,
    configuracao: { mensagem, tipo }
  } = AvisoContainer.useContainer()

  const icone = obterIconeDeAviso(tipo)

  return (
    <ContainerDoAviso tipo={tipo} ativo={ativo}>
      <Icone>{icone}</Icone>
      <Mensagem>{mensagem}</Mensagem>
    </ContainerDoAviso>
  )
}
