import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaDataUltimoAcessoSala = (): TabelaColuna => ({
  Chave: 'dataUltimoAcessoSala',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataUltimoAcessoSala)}</span>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 90 }}>Data Últi. Aces. Sala</div>
  )
})
