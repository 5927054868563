import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { Breakpoints, Cores, Medias } from '../styles'
import { ModalBaseProps } from './tipos'

export const Componente = styled.div<ModalBaseProps>`
  ${props =>
    props.backdrop &&
    props.aberta &&
    css`
      display: flex;
      position: fixed;
      z-index: 9;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${transparentize(0.4, Cores.BACKDROP)};
    `}

  transition: opacity 0.2s;

  ${props =>
    props.aberta
      ? css`
          opacity: 1;
          transition-timing-function: ease-in;
        `
      : css`
          opacity: 0;
          transition-timing-function: ease-out;
        `}

  & > div {
    background: ${Cores.BRANCO};
    display: none;
    flex-direction: column;
    min-width: ${Breakpoints.MOBILE}px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opactity 0.2s ease-out;
    opacity: 0;
    z-index: 10;

    @media ${Medias.MOBILE} {
      width: 100%;
      height: 100%;
      justify-content: center;
      padding: 0 20px;
    }

    ${({ aberta }) =>
      aberta &&
      css`
        display: flex;
        opacity: 1;
        transition: all 0.2s ease-in;
      `}
  }
`
