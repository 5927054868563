import React, { useRef, useEffect, useState } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { Input } from '../../input'
import { Componente, Erro } from './styles'
import { InputUnformProps } from './tipos'

export const MoedaInputUnform: React.FC<Omit<InputUnformProps, 'mascara'>> = ({
  name,
  semMargemAbaixo,
  onChange,
  ...rest
}) => {
  const config = { style: 'currency', currency: 'BRL' }
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name || '')
  const [valor, definirValor] = useState(
    defaultValue
      ?.toString()
      ?.replace('.', ',')
      ?.toLocaleString('pt-BR', config) ?? null
  )

  const alterarValor = (valor: string) => {
    if (valor !== '') {
      let numero: number
      if (typeof valor === 'string') {
        const valorSemR$ = valor
          .replace('R$', '')
          .replace(' ', '')
          .replace(/&nbsp;/gi, '')
          .replace(/[.]/g, '')
          .replace(',', '.')
        numero = Number(valorSemR$)
      }

      let novoValorFormatado = numero.toLocaleString('pt-BR', config)
      if (numero <= 1000) {
        novoValorFormatado = novoValorFormatado.toString().replace('.', ',')
      }
      definirValor(novoValorFormatado)
    } else {
      definirValor(' ')
    }
  }

  useEffect(() => {
    registerField<number>({
      name: fieldName,
      ref: inputRef.current,
      getValue({ inputElement }) {
        if (!inputElement?.value) return null

        return Number(
          inputElement.value
            .replace('R$', '')
            .replace(' ', '')
            .replace(/\./g, '')
            .replace(/&nbsp;/gi, '')
            .replaceAll('.', '')
            .replace(',', '.')
        )
      },
      setValue(ref: any, value: number) {
        if (value === 0 || !value) {
          definirValor(0)
        } else {
          alterarValor(`${value}`.replace('.', ','))
        }
      },
      clearValue(ref: any) {
        ref.value = ''
        definirValor('')
      }
    })
  }, [fieldName, registerField])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames({
        semMargemAbaixo
      })}
    >
      <Input
        {...rest}
        inputMode="numeric"
        name={name}
        onChange={e => {
          const valor = alterarValor(e.target.value ?? '')
          definirValor(valor)
          onChange && onChange(e)
        }}
        value={valor}
        ref={inputRef}
        erro={!!error}
        className={classNames({
          error
        })}
        onKeyPress={clearError}
        moeda
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </Componente>
  )
}
