import styled from 'styled-components'

import { Cores } from '../../styles'

export const Input = styled.textarea<{ height?: string }>`
  resize: none;
  outline: none;
  width: 100%;
  height: ${props => props.height ?? '100%'};
  border: 1px solid ${Cores.CINZA_3_ESCURO};
  font-family: 'Roboto', sans-serif;
  font-size: '12px';
  color: ${Cores.CINZA_2};
  transition: 0.15s;
  border-radius: 4px 4px 4px 4px;
  padding: 13px;

  &:hover {
    border: 1px solid ${Cores.PRIMARIA_1};
  }

  &:focus {
    caret-color: ${Cores.PRIMARIA_2};
    border: 1px solid ${Cores.PRIMARIA_2};
    color: ${Cores.CINZA_2_ESCURO};
  }

  &:invalid {
    border: 1px solid ${Cores.SECUNDARIA_2};
  }
`
