import React from 'react'

export default (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 4L6 8L10 4H2Z" fill="#909090" />
  </svg>
)
