import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {
  AutenticacaoContainer,
  Botao,
  Cores,
  ErroLayoutContainer,
  IconeAdicionar,
  Modal,
  ModalRef
} from 'src/componentes'
import { Api } from 'src/servicos'

import { ModalTrocaDeModulo } from './componentes/modal-troca-modulo'
import { ModalTrocaModuloRef } from './componentes/modal-troca-modulo/tipos'
import { ModalVerMaisInformacoes } from './componentes/modal-ver-mais'
import { ModalVerMaisRef } from './componentes/modal-ver-mais/tipos'
import {
  BotaoVerMais,
  ContainerBotao,
  ContainerBotaoConfirmar,
  ContainerConteudo,
  ContainerConteudoDois,
  ContainerModulosFlex,
  InformacoesSuperior,
  ModulosFlex,
  NomeModulo
} from './style'
import { Modulo } from './tipos'

export const PaginaTrocaModuloFlex: React.FC = () => {
  const { idSala, idModulo } = useParams<{
    idSala?: string
    idModulo?: string
  }>()

  const { usuario } = AutenticacaoContainer.useContainer()
  const [modulosFlex, definirModulosFlex] = useState<Modulo[]>([])
  const [carregando, definirCarregando] = useState(false)
  const [confirmacao, definirConfirmacao] = useState(false)
  const [moduloAntigo, definirModuloAntigo] = useState(undefined)
  const [moduloEscolhido, definirModuloEscolhido] = useState(undefined)
  const modalVerMaisRef = useRef<ModalVerMaisRef>(null)
  const modalTrocaModuloRef = useRef<ModalTrocaModuloRef>(null)
  const modalDeCancelamento = useRef<ModalRef>(null)

  const history = useHistory()

  const obterDados = async () => {
    try {
      const resposta = await Api.ObterModulosFlexiveis(idSala)

      if (!resposta?.permitidoTrocaModuloFlex) {
        history.push('/aluno')
      }

      const flex = []

      resposta?.modulos?.forEach(modulo => {
        if (modulo?.moduloSalaId === idModulo) {
          definirModuloAntigo({ id: idModulo, nome: modulo.nome })
        } else if (modulo?.tipoModulo === 'Flex') {
          flex.push(modulo)
        }
      })

      definirModulosFlex(flex)
    } catch (error) {}
  }

  const handleCheck = event => {
    if (event.target.checked) {
      const moduloComIdENome = { id: event.target.id, nome: event.target.name }

      definirModuloEscolhido(moduloComIdENome)
    } else {
      definirModuloEscolhido(undefined)
    }
  }

  const estaSelecionado = (id: string) => {
    const retorno = moduloEscolhido?.id === id
    return retorno
  }

  const deveBloquearModuloFlex = (id: string) => {
    const retorno = moduloEscolhido?.id && !estaSelecionado(id)

    return retorno
  }

  const acaosucesso = async () => {
    try {
      definirCarregando(true)
      const payload = {
        moduloAntigo: moduloAntigo?.id,
        moduloEscolhido: moduloEscolhido?.id
      }

      await Api.EditarModulosFlexiveis(payload, idSala)

      history.push(`/sala-aula/outros-cursos/${idSala}`)
    } catch (error) {
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    obterDados()
  }, [])

  return (
    <>
      <ContainerConteudo>
        <h2>Olá, {usuario.nomeApresentacao}!</h2>
        <h4>
          Antes de realizarmos a troca, queremos lembrá-lo de algumas condições:
        </h4>
        <p>
          • Após a escolha dos Módulos Flex, é permitido realizar a troca
          somente uma vez;
        </p>
        <p>
          • Caso efetive a troca de módulo, não poderá escolher novamente o
          módulo a qual solicitou a troca.
        </p>
        <ContainerBotao>
          <Botao
            disabled={confirmacao}
            tema="Secundario"
            id="btn_abandonar"
            texto="Abandonar etapa"
            onClick={() => history.goBack()}
            carregando={carregando}
          />
          <Botao
            style={{ color: 'white' }}
            disabled={confirmacao}
            id="btn_aceitar"
            texto="Estou ciente e quero continuar"
            onClick={() => definirConfirmacao(true)}
            carregando={carregando}
          />
        </ContainerBotao>
      </ContainerConteudo>
      {confirmacao && (
        <ContainerConteudoDois>
          <h2>Troca de Módulos Flexíveis</h2>
          <h3>Escolha qual modulo você deseja dos disponíveis abaixo:</h3>
          <ContainerModulosFlex>
            {modulosFlex.map(modulo => (
              <div key={modulo.moduloId} style={{ position: 'relative' }}>
                <ModulosFlex
                  selecionado={estaSelecionado(modulo.moduloId)}
                  bloqueado={deveBloquearModuloFlex(modulo.moduloId)}
                  htmlFor={modulo.moduloId}
                  data-for={'tooltip-stepper'}
                  data-tip={
                    !deveBloquearModuloFlex(modulo.moduloId)
                      ? ''
                      : 'Número máximo de módulos já selecionados.'
                  }
                >
                  <InformacoesSuperior>
                    <input
                      onChange={handleCheck}
                      type="checkbox"
                      name={modulo.nome}
                      disabled={deveBloquearModuloFlex(modulo.moduloId)}
                      id={modulo.moduloId}
                    />
                  </InformacoesSuperior>
                  <NomeModulo>
                    <p>{modulo.nome}</p>
                  </NomeModulo>
                  <ReactTooltip
                    effect="solid"
                    backgroundColor={Cores.CINZA_1_CLARO}
                    textColor={Cores.PRETO}
                    id={'tooltip-stepper'}
                    place="top"
                  />
                </ModulosFlex>
                <BotaoVerMais
                  onClick={() => modalVerMaisRef?.current?.abrir(modulo)}
                  data-for={'tooltip-stepper'}
                  data-tip={''}
                >
                  {IconeAdicionar}Ver mais
                </BotaoVerMais>
              </div>
            ))}
          </ContainerModulosFlex>
          <ContainerBotaoConfirmar>
            <Botao
              tema="Secundario"
              id="btn_cancelar"
              texto="Cancelar"
              onClick={() => modalDeCancelamento?.current?.abrir()}
              carregando={carregando}
            />
            <Botao
              style={{ color: 'white' }}
              id="btn_confirmar"
              texto="Confirmar"
              onClick={() =>
                modalTrocaModuloRef?.current?.abrir(
                  moduloEscolhido,
                  moduloAntigo
                )
              }
              disabled={!moduloEscolhido?.id}
              carregando={carregando}
            />
          </ContainerBotaoConfirmar>
          <ModalVerMaisInformacoes
            id="modal-ver-mais"
            backdrop
            ref={modalVerMaisRef}
          />
          <ModalTrocaDeModulo
            id="modal-troca-modulo"
            backdrop
            ref={modalTrocaModuloRef}
            acaoPrimaria={acaosucesso}
          />
          <Modal
            ref={modalDeCancelamento}
            backdrop
            id="modal-de-cancelamento"
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalDeCancelamento?.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
        </ContainerConteudoDois>
      )}
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaTrocaModuloFlex />
  </ErroLayoutContainer.Provider>
)
