import React from 'react'

export default (
  <svg
    width="11"
    height="6"
    viewBox="0 0 11 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.26729 5.19775C1.19476 5.12809 1.19476 5.01515 1.26729 4.94549L5.58175 0.802245C5.65429 0.732585 5.7719 0.732585 5.84444 0.802246C5.91697 0.871906 5.91697 0.984848 5.84444 1.05451L1.52998 5.19775C1.45744 5.26741 1.33983 5.26741 1.26729 5.19775Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M10.1585 5.19775C10.0859 5.26742 9.96834 5.26742 9.8958 5.19775L5.58221 1.05534C5.50967 0.985682 5.50921 0.871905 5.58175 0.802245C5.65429 0.732585 5.7719 0.732585 5.84444 0.802246L10.1585 4.94549C10.231 5.01515 10.231 5.12809 10.1585 5.19775Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M5.58175 0.802245L1.26729 4.94549C1.19476 5.01515 1.19476 5.12809 1.26729 5.19775C1.33983 5.26741 1.45744 5.26741 1.52998 5.19775L5.84444 1.05451C5.91697 0.984848 5.91697 0.871906 5.84444 0.802246M5.58175 0.802245C5.65429 0.732585 5.7719 0.732585 5.84444 0.802246M5.58175 0.802245C5.50921 0.871906 5.50967 0.985682 5.58221 1.05534L9.8958 5.19775C9.96834 5.26742 10.0859 5.26742 10.1585 5.19775C10.231 5.12809 10.231 5.01515 10.1585 4.94549L5.84444 0.802246"
      stroke="white"
      strokeOpacity="0.8"
      strokeWidth="0.5"
    />
  </svg>
)
