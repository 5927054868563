import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from './styles'
import { ItemAgendaProps } from './tipos'

export const ItemAgenda: React.FC<ItemAgendaProps> = ({
  data,
  titulo,
  mensagem,
  link
}) => {
  return (
    <Container>
      <time>{data}</time>
      {titulo && <h3 children={titulo} />}
      <Link to={link}>{mensagem}</Link>
    </Container>
  )
}
