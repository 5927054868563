import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from './styles'

export interface LinkRodapeProps {
  acao?: () => void
  href?: string
  texto: string
  className?: string
}

export const LinkRodape: React.FC<LinkRodapeProps> = ({
  texto,
  href = '#',
  acao,
  className
}) => {
  if (acao) {
    const execucao = () => acao()

    return (
      <Container className={className}>
        <button onClick={execucao}>
          <span>{texto}</span>
        </button>
      </Container>
    )
  }

  return (
    <Container className={className}>
      <Link to={href}>
        <span>{texto}</span>
      </Link>
    </Container>
  )
}
