import { FormUnform } from 'src/componentes/unform'
import styled from 'styled-components'

export const Container = styled.div``

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Dados = styled.div`
  margin-top: 25px;
`
export const ContainerTooltip = styled.div`
  div {
    max-width: 250px;
  }
`
