import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  & > a,
  & > button {
    position: relative;
    display: block;
    width: 100%;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: ${Cores.SECUNDARIA_2_ESCURO};
    cursor: pointer;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      content: '';
      width: calc(50% - 40px);
      height: 1px;
      background-color: ${Cores.SECUNDARIA_2_ESCURO};
    }

    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      content: '';
      width: calc(50% - 50px);
      height: 1px;
      background-color: ${Cores.SECUNDARIA_2_ESCURO};
    }

    span {
      position: relative;
      display: block;

      &::after {
        position: absolute;
        top: calc(50% - 4px);
        right: calc(50% - 37px);
        transform: rotate(45deg);
        display: block;
        content: '';
        border: 0.5px solid #009b9b;
        border-top: none;
        border-left: none;
        width: 5px;
        height: 5px;
      }
    }
  }

  & > button {
    background-color: transparent;
    border: none;
  }
`
