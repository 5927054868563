import React, { useEffect, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast, ToastOptions } from 'react-toastify'

import {
  AvisoContainer,
  RelacaoDocumentosProfessorBrasileiro,
  ResumoDeDocumentosDeEntradaMapper,
  ResumoDeDocumentosDeSaidaMapper,
  selecaoDeDocumentosParaEnvio,
  TipoDeAviso,
  UploadDeDocumentos,
  UploadDocumentos,
  useRelacaoDeDocumentos
} from 'src/compartilhados'
import {
  AutenticacaoContainer,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  Modal,
  ModalRef
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { RotasProfessor } from 'src/rotas/professor'
import { Api } from 'src/servicos'
import { Documento } from 'src/tipos'

import {
  BotaoDeSalvar,
  ContainerDaPagina,
  ContainerDosBotoes,
  TituloComplementar,
  TituloPrincipal
} from './style'

const DocumentosProfessorBrasileiro: React.FC = () => {
  const history = useHistory()
  const { documentos, atualizar, reiniciar } = useRelacaoDeDocumentos(
    RelacaoDocumentosProfessorBrasileiro
  )

  const [pronto, definirPronto] = useState<boolean>()
  const [carregando, definirCarregando] = useState<boolean>()
  const modalDeCancelamento = useRef<ModalRef>(null)
  const documentosSalvosComPendencia =
    'Documentos salvos com sucesso. Você ainda possui documentos pendentes.'
  const aviso = AvisoContainer.useContainer()
  const { usuario } = AutenticacaoContainer.useContainer()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const obterResumoDeDocumentos = async (idDoUsuario: string) => {
    try {
      definirPronto(false)
      limparErro()
      const resposta = await Api.ObterResumoDocumentosProfessor(idDoUsuario)
      const resultado = ResumoDeDocumentosDeEntradaMapper(documentos, resposta)
      reiniciar(resultado)
    } catch (erro) {
      const mensagens = 'Não foi possível obter os documentos do professor.'
      const acaoVoltar = () => history.goBack()
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const verificacaoDePreenchimento = () => {
    const documentosPreenchidos = documentos.every(documento => documento.nome)

    documentosPreenchidos
      ? aviso.criar('Documentos salvos com sucesso.', TipoDeAviso.Sucesso)
      : aviso.criar(documentosSalvosComPendencia, TipoDeAviso.Advertencia)

    history.push(RotasProfessor.Dashboard)
  }

  const abrirImagemNovaAba = (documentoProfessor: Documento) => {
    const novaAba = window.open('')
    if (documentoProfessor.contentType === 'application/pdf') {
      novaAba.document.write(
        `<iframe width='100%' height='100%' src='data:${documentoProfessor?.contentType};base64, ` +
          encodeURI(documentoProfessor?.base64) +
          "' />"
      )
    } else {
      const img = `<img src='${`data:${documentoProfessor?.contentType};base64, ${documentoProfessor?.base64}`}' />`
      novaAba.document.write(img)
    }
    novaAba.document.title = `Documento de ${usuario.nome}`
  }

  const visualizarDocumento = async (documentoId: string) => {
    try {
      const novoDocumento = await Api.RequisitarDocumentoProfessor(
        usuario.id,
        documentoId
      )
      if (novoDocumento) {
        abrirImagemNovaAba(novoDocumento)
      }
    } catch (error) {
      toast('Não foi possível obter os documentos do professor.', ({
        type: 'error'
      } as { type: 'error' }) as ToastOptions)
    }
  }

  const uploadDeCriacao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const documentosEnviar = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EnviarDocumentosProfessor(
        usuario.id,
        documentosEnviar
      )

      if (resposta) {
        verificacaoDePreenchimento()
      } else {
        toast('Erro ao enviar documentos.', ({ type: 'error' } as {
          type: 'error'
        }) as ToastOptions)
      }
    } catch (erro) {
      toast('Erro ao enviar documentos.', ({ type: 'error' } as {
        type: 'error'
      }) as ToastOptions)
    }
  }

  const uploadDeEdicao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const documentosEnviar = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EditarDocumentoProfessor(
        usuario.id,
        documentosEnviar
      )

      if (resposta) {
        verificacaoDePreenchimento()
      } else {
        toast('Erro ao atualizar documentos.', {
          type: 'error'
        } as ToastOptions)
      }
    } catch (erro) {
      toast('Erro ao atualizar documentos.', ({ type: 'error' } as {
        type: 'error'
      }) as ToastOptions)
    }
  }

  const uploadDeDocumentos = async (
    edicoes: UploadDocumentos[],
    criacoes: UploadDocumentos[]
  ): Promise<void> => {
    try {
      definirCarregando(true)
      if (edicoes.length > 0) await uploadDeEdicao(edicoes)
      if (criacoes.length > 0) await uploadDeCriacao(criacoes)
    } catch (erro) {
      toast('Erro ao processar documentos.', { type: 'error' } as ToastOptions)
    } finally {
      definirCarregando(false)
    }
  }

  const eventoDeUpload = async (): Promise<void> => {
    const { edicoes, criacoes, contador } = selecaoDeDocumentosParaEnvio(
      documentos
    )

    if (contador === 0) history.push(RotasProfessor.Dashboard)
    else await uploadDeDocumentos(edicoes, criacoes)
  }

  const obterDownloadDeDocumentos = async (
    documentoId: string
  ): Promise<void> => {
    try {
      await Api.ObterDownloadDocumentoProfessor(usuario.id, documentoId)
    } catch (erro) {
      toast('Erro ao baixar documentos.', { type: 'error' } as ToastOptions)
    }
  }

  useEffect(() => {
    obterResumoDeDocumentos(usuario.id)
  }, [])

  return pronto && !erro ? (
    <ContainerDaPagina>
      <TituloPrincipal>Envio de Documentos</TituloPrincipal>
      <TituloComplementar>Meus documentos</TituloComplementar>
      {documentos
        .sort((doc1, doc2) => doc1.ordemExibicao - doc2.ordemExibicao)
        .map(documento => (
          <UploadDeDocumentos
            key={documento.id}
            documento={documento}
            eventoDeMudanca={atualizar}
            eventoDeDownload={obterDownloadDeDocumentos}
            eventoDeVisualizacao={visualizarDocumento}
          />
        ))}
      <ContainerDosBotoes>
        <Botao
          texto="Cancelar"
          tema="Secundario"
          type="button"
          onClick={() => modalDeCancelamento?.current?.abrir()}
        />
        <BotaoDeSalvar
          texto="Salvar"
          type="button"
          onClick={eventoDeUpload}
          carregando={carregando}
        />
      </ContainerDosBotoes>
      <Modal
        ref={modalDeCancelamento}
        backdrop
        id="modal-de-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.push(RotasProfessor.Dashboard)
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamento?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
    </ContainerDaPagina>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando documentos do professor..." />
  ) : (
    <ErroContainerLayout />
  )
}

const Container: React.FC = () => (
  <ErroLayoutContainer.Provider>
    <DocumentosProfessorBrasileiro />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
