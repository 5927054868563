import { RotasAcademico } from 'src/rotas'
import { RotasAluno } from 'src/rotas/aluno'
import { RotasProfessor } from 'src/rotas/professor'
import { RotasResponsavelFinanceiro } from 'src/rotas/responsavel-financeiro'
import { UsuarioPerfil } from 'src/tipos'

import { Visao } from './tipos'

export const recuperarVisaoPorPerfis = (
  perfis: Array<keyof typeof UsuarioPerfil>
): Visao => {
  if (!perfis) return null

  const arrayPerfis = Array.isArray(perfis) ? [...perfis] : [perfis]
  if (
    arrayPerfis.includes('Academico') ||
    arrayPerfis.includes('Comercial') ||
    arrayPerfis.includes('Financeiro') ||
    arrayPerfis.includes('Gerencial')
  ) {
    return Visao.IPGS
  }

  if (arrayPerfis.includes('Aluno')) return Visao.Aluno

  if (arrayPerfis.includes('Professor')) return Visao.Professor

  if (arrayPerfis.includes('Parceiro')) return Visao.Parceiro

  if (arrayPerfis.includes('ResponsavelFinanceiro')) {
    return Visao.ResponsavelFinanceiro
  }

  return null
}

export const opcoesDeVisao = (
  perfis: Array<keyof typeof UsuarioPerfil>
): Array<Visao> => {
  const arrayPerfis = Array.isArray(perfis) ? [...perfis] : [perfis]
  const perfisParaSelecao = []
  if (
    arrayPerfis.includes('Academico') ||
    arrayPerfis.includes('Comercial') ||
    arrayPerfis.includes('Financeiro') ||
    arrayPerfis.includes('Gerencial')
  ) {
    perfisParaSelecao.push(Visao.IPGS)
  }

  if (arrayPerfis.includes('Aluno')) perfisParaSelecao.push(Visao.Aluno)

  if (arrayPerfis.includes('Professor')) {
    perfisParaSelecao.push(Visao.Professor)
  }

  if (arrayPerfis.includes('Parceiro')) {
    perfisParaSelecao.push(Visao.Parceiro)
  }

  if (arrayPerfis.includes('ResponsavelFinanceiro')) {
    perfisParaSelecao.push(Visao.ResponsavelFinanceiro)
  }

  return perfisParaSelecao
}

export const recuperarRotaBasePorVisao = (
  perfis: Array<keyof typeof UsuarioPerfil>
): string => {
  switch (recuperarVisaoPorPerfis(perfis)) {
    case Visao.IPGS:
    case Visao.Parceiro:
      return RotasAcademico.Dashboard
    case Visao.Aluno:
      return RotasAluno.Dashboard
    case Visao.Professor:
      return RotasProfessor.Dashboard
    case Visao.ResponsavelFinanceiro:
      return RotasResponsavelFinanceiro.Dashboard
  }
}
