import { RacaTexto, Escolaridade, EstadoCivilTexto, TipoConta } from 'src/tipos'

export const OPCOES_ESCOLARIDADE = [
  {
    id: `${Escolaridade.Doutorado}`,
    texto: 'Doutorado'
  },
  {
    id: `${Escolaridade.Especializacao}`,
    texto: 'Especialização'
  },
  {
    id: `${Escolaridade.Mestrado}`,
    texto: 'Mestrado'
  },
  {
    id: `${Escolaridade.PosDoutorado}`,
    texto: 'Pós Doutorado'
  }
]

export const OPCOES_CIVIL = [
  {
    id: `${EstadoCivilTexto.NaoInformado}`,
    texto: 'Não informado'
  },
  {
    id: `${EstadoCivilTexto.Casado}`,
    texto: 'Casado(a)'
  },
  {
    id: `${EstadoCivilTexto.Divorciado}`,
    texto: 'Divorciado(a)'
  },
  {
    id: `${EstadoCivilTexto.Separado}`,
    texto: 'Separado(a)'
  },
  {
    id: `${EstadoCivilTexto.Solteiro}`,
    texto: 'Solteiro(a)'
  },
  {
    id: `${EstadoCivilTexto.Viuvo}`,
    texto: 'Viúvo(a)'
  }
]

export const OPCOES_COR = [
  {
    id: `${RacaTexto.NaoInformado}`,
    texto: 'Não informado'
  },
  {
    id: `${RacaTexto.Amarela}`,
    texto: 'Amarela'
  },
  {
    id: `${RacaTexto.Branca}`,
    texto: 'Branca'
  },
  {
    id: `${RacaTexto.Indigena}`,
    texto: 'Indígena'
  },
  {
    id: `${RacaTexto.Parda}`,
    texto: 'Parda'
  },
  {
    id: `${RacaTexto.Preta}`,
    texto: 'Preta'
  }
]

export const OPCOES_TIPO_CONTA = [
  {
    id: `${TipoConta.ContaCorrente}`,
    texto: 'Conta Corrente'
  },
  {
    id: `${TipoConta.ContaPoupanca}`,
    texto: 'Conta Poupança'
  },
  {
    id: `${TipoConta.ContaSalario}`,
    texto: 'Conta Salário'
  }
]
