import React from 'react'

export default (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.64444 1.734V0L14 4.046L9.64444 8.092V6.358L12.1333 4.046L9.64444 1.734ZM6.84444 2.312V0L11.2 4.046L6.84444 8.092V5.7222C3.73333 5.7222 1.55556 6.647 0 8.67C0.622222 5.78 2.48889 2.89 6.84444 2.312Z"
      fill="#009B9B"
    />
  </svg>
)
