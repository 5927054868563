import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaAnoFaturamento = (nome: string): TabelaColuna => ({
  Chave: nome,
  ComOrdenacao: false,
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.moeda(linha[nome])}</span>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 100 }}>Ano Faturamento {nome}</div>
  )
})
