import styled, { css } from 'styled-components'

import { Cores } from '../styles/cores'

interface ContainerProps {
  preencherEspacoItem?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  ${props =>
    props.preencherEspacoItem &&
    css`
      & .menu_contexto,
      & .menu_contexto > ul,
      & .menu_contexto > ul > li > button {
        width: 100%;
      }
    `}
`

export const Label = styled.label`
  font-family: 'Saira-Semi-Bold', sans-serif;
  text-align: left;
  font-size: 12px;
  margin-bottom: 6px;
  color: ${Cores.CINZA_2};

  :first-letter {
    text-transform: uppercase;
  }
`

export const Seletor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px 0 10px;
  border: none;
  border: 1px solid ${Cores.CINZA_3_ESCURO};
  border-radius: 4px;
  font-family: Saira, sans-serif;
  font-size: 14px;
  color: ${Cores.CINZA_2_ESCURO};
  background: ${Cores.BRANCO};
  cursor: pointer;
  height: 40px;

  &:hover {
    border: 1px solid ${Cores.PRIMARIA_1};
  }
`

export const Icones = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Limpar = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
  margin-left: 6px;

  &.visivel {
    opacity: 1;
    transition: all 0.1s ease-in;
    pointer-events: unset;
    cursor: pointer;
  }
`

export const Seta = styled.span`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 10px;
    height: 10px;
    margin-bottom: 4px;
  }
`

export const Lista = styled.ul`
  list-style: none;
  padding: 6px 0;
`

export const Item = styled.li`
  button {
    background: transparent;
    border: none;
    min-width: 263px;
    height: 40px;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color: ${Cores.CINZA_2};
    font-family: 'Poppins-Bold', sans-serif;

    &:hover {
      background: ${Cores.CINZA_3_MAIS_CLARO};
      color: ${Cores.PRIMARIA_2};

      .check {
        display: block;
        background: ${Cores.CINZA_3};
      }
    }
  }

  &.marcado {
    button {
      background: ${Cores.CINZA_3_CLARO};
      color: ${Cores.PRIMARIA_2};
    }
  }
`
