import React, { useEffect, useState } from 'react'
import { toast, ToastOptions } from 'react-toastify'

import {
  AgendaDoMural,
  AutenticacaoContainer,
  Carregando,
  OcultarFuncionalidade,
  SaudacoesDoMural,
  TipoDaAgendaDoMural
} from 'src/componentes'
import { Api } from 'src/servicos'
import { TurmaProfessor } from 'src/tipos'

import { MinhasTurmas } from './componentes'
import { agendaMock } from './mocks'
import { Container, Conteudo } from './styles'

export const PaginaIncialProfessor: React.FC = () => {
  const { usuario } = AutenticacaoContainer.useContainer()
  const [pronto, definirPronto] = useState<boolean>()
  const [turmasProfessor, definirTurmasProfessor] = useState<TurmaProfessor[]>(
    []
  )

  const obterTurmasProfessorLogado = async () => {
    try {
      definirPronto(false)
      const resposta = await Api.ObterTurmasProfessor()
      definirTurmasProfessor(resposta)
    } catch (erro) {
      toast('Erro ao selecionar turmas do professor.', {
        type: 'error'
      } as ToastOptions)
    } finally {
      definirPronto(true)
    }
  }

  const configDaAgenda = {
    eventos: agendaMock,
    configuracao: {
      tipo: TipoDaAgendaDoMural.Compacto
    }
  }

  useEffect(() => {
    obterTurmasProfessorLogado()
  }, [])

  return pronto ? (
    <>
      <Container>
        <SaudacoesDoMural usuario={usuario} />
        <Conteudo>
          <MinhasTurmas turmas={turmasProfessor} />
          <OcultarFuncionalidade>
            <AgendaDoMural {...configDaAgenda} />
          </OcultarFuncionalidade>
        </Conteudo>
      </Container>
    </>
  ) : (
    <Carregando texto="Carregando turmas do professor..." />
  )
}
