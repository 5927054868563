import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  CheckboxUnform,
  FormUnform,
  ModalBase,
  TextAreaUnform
} from 'src/componentes'
import { AceiteDocumentoResponsavelFinanceiro, SituacaoUpload } from 'src/tipos'

import {
  ContainerConteudo,
  ContainerModal,
  TituloPrincipal,
  ContainerBotoes,
  ContainerCheckbox,
  Opcao,
  OpcaoDados
} from './styles'
import {
  ModalRecusaDocumentoResponsavelProps,
  ModalRecusaDocumentoResponsavelRef
} from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalRecusaDocumentoResponsavelRef,
  ModalRecusaDocumentoResponsavelProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [aceiteResponsavel, definirAceiteResponsavel] = useState(false)
  const [documentoId, definirDocumentoId] = useState('')
  const formRef = useRef<FormHandles>(null)

  const fechar = () => {
    definirAberta(false)
    definirAceiteResponsavel(false)
    formRef.current?.reset()
  }

  const abrir = (documentoId?: string) => {
    definirDocumentoId(documentoId)
    definirAberta(true)
  }

  const acaoSucessoForm = async (
    dados: AceiteDocumentoResponsavelFinanceiro
  ) => {
    const payload = {
      documentoId: documentoId,
      situacao: SituacaoUpload.Recusado,
      motivoRejeicao: dados.motivoRejeicao
    }

    await acaoPrimaria(payload)
    fechar()
    formRef.current?.reset()
  }

  useImperativeHandle<
    ModalRecusaDocumentoResponsavelRef,
    ModalRecusaDocumentoResponsavelRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-recusa-documento-responsavel"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>
          Recusa Documento - Responsável Financeiro
        </TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm}>
          <ContainerConteudo>
            <TextAreaUnform name="motivoRejeicao" label="Motivo" />
            <ContainerCheckbox>
              <Opcao>
                <OpcaoDados>
                  <CheckboxUnform
                    id="chk_recusa_documento_responsavel"
                    name="recusaDocumentoResponsavel"
                    onChange={() => definirAceiteResponsavel(old => !old)}
                  />
                  <label htmlFor="chk_recusa_documento_responsavel">
                    Confirmo que recuso este documento do Responsável
                    Financeiro.
                  </label>
                </OpcaoDados>
              </Opcao>
            </ContainerCheckbox>
          </ContainerConteudo>
          <ContainerBotoes>
            <Botao
              texto="Voltar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Enviar" disabled={!aceiteResponsavel} />
          </ContainerBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}
export const ModalRecusaDocumentoResponsavel = forwardRef(Modal)
export * from './tipos'
