export enum EstadoCivil {
  NaoInformado,
  Solteiro,
  Casado,
  Divorciado,
  Separado,
  Viuvo
}

export enum EstadoCivilPorExtenso {
  NaoInformado = 'Não informado',
  Solteiro = 'Solteiro',
  Casado = 'Casado',
  Divorciado = 'Divorciado',
  Separado = 'Separado',
  Viuvo = 'Viúvo'
}

export enum EstadoCivilTexto {
  NaoInformado = 'NaoInformado',
  Solteiro = 'Solteiro',
  Casado = 'Casado',
  Divorciado = 'Divorciado',
  Separado = 'Separado',
  Viuvo = 'Viuvo'
}
