import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaNomeAluno = (): TabelaColuna => ({
  Chave: 'nomeAluno',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <span>{linha.nomeAluno}</span>,
  RenderizarCabecalho: () => <div style={{ width: 200 }}>Nome</div>
})
