import { Medias } from 'src/componentes/styles'
import { SubTitulo } from 'src/componentes/subtitulo'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const ContainerMain = styled.div`
  width: 70%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const Conteudo = styled.div`
  margin-top: 18px;
  margin-left: 30px;
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDadosMedio = styled.div`
  width: 50%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosPequeno = styled.div`
  width: 25%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`
export const ContainerValidoAVista = styled.div`
  width: 50%;
  align-self: center;
`
export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 19px;
  }
`

export const ContainerLateral = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 58px;
`

export const SubTituloAviso = styled(SubTitulo)`
  color: red;
`

export const Linha = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;

  @media ${Medias.MOBILE} {
    flex-direction: column;
  }
`

export const ContainerGrande = styled.div`
  width: 75%;

  @media ${Medias.MOBILE} {
    width: 100%;
    & ~ div {
      margin-left: 0;
    }
  }
`

export const ContainerMedio = styled.div`
  width: 50%;

  .tooltip-ipgs {
    max-width: 451px;
  }

  @media ${Medias.MOBILE} {
    width: 100%;
    & ~ div {
      margin-left: 0;
    }
  }
`

export const ContainerPequeno = styled.div`
  width: 25%;

  @media ${Medias.MOBILE} {
    width: 100%;
    & ~ div {
      margin-left: 0;
    }
  }
`

export const Secao = styled.section`
  margin-top: 30px;
`
