import React from 'react'

import { Breadcrumb, FuncoesDataHora, Stepper } from 'src/componentes'
import { StepperItem } from 'src/componentes/stepper/tipos'
import { Api } from 'src/servicos'
import { OpcaoTCC } from 'src/tipos'

import { calcularDistanciaData } from '../../shared/funcoes/calcularDatas'
import SalaAulaOutrosContainer from '../container'

export const ComponenteStepper: React.FC = () => {
  const {
    salaAula,
    etapas,
    itensMarcados,
    passoAoAtualizarSala,
    definirItensMarcados,
    downloadMaterial
  } = SalaAulaOutrosContainer.useContainer()

  const primeiroItem = salaAula.aprovado
    ? 'minhas-notas'
    : passoAoAtualizarSala ?? salaAula.primeiroMaterialNaoLido

  const irItemTCC =
    calcularDistanciaData(salaAula.dataBloqueioSalaAula) > 0 &&
    OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Sim] &&
    OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Nao] &&
    OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.NaoAutomatico] &&
    OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Cancelamento] &&
    OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Dispensa] &&
    OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Aproveitamento]

  const marcarConcluido = async (
    id: string,
    concluido: boolean,
    itens: string[],
    tcc?: boolean,
    complementar?: boolean
  ) => {
    let resposta = { progresso: 0 }
    if (tcc) {
      resposta = await Api.SalaAulaOutros.MarcarMaterialTCC(
        id,
        salaAula.id,
        concluido
      )
    } else if (complementar) {
      resposta = await Api.SalaAulaOutros.MarcarMaterialComplementar(
        id,
        salaAula.id,
        concluido
      )
    } else {
      resposta = await Api.SalaAulaOutros.MarcarMaterial(
        id,
        salaAula.id,
        concluido
      )
    }

    if (resposta) {
      if (concluido) {
        definirItensMarcados([...itens, id])
      } else {
        const indice = itens.findIndex(i => i === id)
        if (indice !== -1) {
          const novosItens = itens.filter((_, i) => i !== indice)
          definirItensMarcados(novosItens)
        }
      }
    }
  }

  const naoIniciouSalaAula = salaAula?.dataInicio
    ? calcularDistanciaData(salaAula?.dataInicio) <= 0
    : false

  const getMaterialBlob = async (subItem: StepperItem) => {
    const { id, complementar } = subItem

    if (complementar) {
      return await Api.SalaAulaOutros.RequisitarMaterialComplementarPorId(
        id,
        salaAula.id
      )
    } else {
      return await Api.SalaAulaOutros.RequisitarMaterialPorId(id, salaAula.id)
    }
  }

  const mensagemSubTitulo = naoIniciouSalaAula
    ? `Curso inicia em ${FuncoesDataHora.dataToLocaleString(
        salaAula?.dataInicio
      )}.`
    : `Seu curso iniciou em ${FuncoesDataHora.dataToLocaleString(
        salaAula?.dataInicio
      )}.
        Você tem até ${FuncoesDataHora.dataToLocaleString(
          salaAula.dataFimSalaAula
        )} para finalizá-lo.`

  return (
    <Stepper
      idItemSelecionado={irItemTCC ? 'opte-tcc' : primeiroItem}
      progresso={salaAula.progresso}
      itens={etapas}
      itensMarcados={itensMarcados}
      onClickConcluir={marcarConcluido}
      onClickDownload={downloadMaterial}
      onClickGetBlob={getMaterialBlob}
    >
      <Breadcrumb
        titulo={`${salaAula.nomeCurso} (${salaAula.cargaHoraria}h)`}
        atalhos={[
          {
            texto: 'Sala de Aula'
          }
        ]}
      />
      <p style={{ marginTop: '20px' }}>{mensagemSubTitulo}</p>
    </Stepper>
  )
}
