import { mensagemPadrao } from 'src/dados-estaticos'
import { TipoDescontoCupom } from 'src/tipos'
import * as Yup from 'yup'

const caracter = Yup.string().required(mensagemPadrao)

export const schema = Yup.object().shape({
  nome: caracter,
  codigoCupom: caracter,
  tipoDescontoCupom: caracter,
  valorDesconto: Yup.number().when(['tipoCupom'], {
    is: TipoDescontoCupom.Valor.toString(),
    then: (fieldSchema: any) =>
      fieldSchema
        .test(
          'valorDesconto',
          'O valor deve ser maior que 0',
          (valor: number) => valor && valor > 0
        )
        .required(mensagemPadrao)
        .typeError(mensagemPadrao)
  }),
  porcentagemDesconto: Yup.number()
    .nullable()
    .when(['tipoCupom'], {
      is: TipoDescontoCupom.Porcentagem.toString(),
      then: (fieldSchema: any) =>
        fieldSchema
          .test(
            'valorDesconto',
            'O valor deve estar entre 1 e 100',
            (valor: number) => valor && valor >= 1 && valor <= 100
          )
          .required(mensagemPadrao)
          .typeError(mensagemPadrao)
    }),
  utilizacaoCupom: caracter,
  dataInicio: Yup.string().data().required(mensagemPadrao),
  dataFim: Yup.string().data().required(mensagemPadrao),
  modalidade: caracter
})
