import { mensagemPadrao } from 'src/dados-estaticos'
import { OpcaoAlteracaoTCC } from 'src/tipos'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  alteracao: Yup.string().required(mensagemPadrao),
  nota: Yup.number()
    .nullable()
    .when('alteracao', {
      is: value =>
        value &&
        OpcaoAlteracaoTCC[value] ===
          OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Aproveitamento],
      then: field => field.required(mensagemPadrao)
    }),
  instituicao: Yup.string()
    .nullable()
    .when('alteracao', {
      is: value =>
        value &&
        OpcaoAlteracaoTCC[value] ===
          OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Aproveitamento],
      then: field => field.required(mensagemPadrao)
    }),
  turma: Yup.string()
    .nullable()
    .when('alteracao', {
      is: value =>
        value &&
        OpcaoAlteracaoTCC[value] ===
          OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Aproveitamento],
      then: field => field.required(mensagemPadrao)
    }),
  valor: Yup.string()
    .numero()
    .nullable()
    .when('alteracao', {
      is: value =>
        value &&
        OpcaoAlteracaoTCC[value] ===
          OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Inclusao],
      then: field => field.required(mensagemPadrao)
    }),
  quantidadeParcelas: Yup.string()
    .numero()
    .nullable()
    .when('alteracao', {
      is: value =>
        value &&
        OpcaoAlteracaoTCC[value] ===
          OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Inclusao],
      then: field => field.required(mensagemPadrao)
    }),
  diaVencimento: Yup.string()
    .numero()
    .nullable()
    .when('alteracao', {
      is: value =>
        value &&
        OpcaoAlteracaoTCC[value] ===
          OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Inclusao],
      then: field =>
        field
          .min(1)
          .max(31)
          .typeError(`O campo deve estar entre ${1} e ${31}`)
          .required('O campo é obrigatório')
    }),
  professorOrientadorId: Yup.string()
    .nullable()
    .when('alteracao', {
      is: value =>
        value &&
        OpcaoAlteracaoTCC[value] ===
          OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Inclusao],
      then: field => field.required(mensagemPadrao)
    })
})
