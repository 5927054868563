import { createContext } from 'react'

import { TabelaPaginacaoEstado, TabelaPaginacaoDispatch } from './tipos'

const TabelaPaginacaoEstadoContext = createContext<TabelaPaginacaoEstado>({
  Pagina: 1,
  TamanhoPagina: 10,
  TotalPaginas: 0,
  TotalRegistros: undefined,
  Estilo: 'Simples'
})

const TabelaPaginacaoAcoesContext = createContext<TabelaPaginacaoDispatch>(
  {} as TabelaPaginacaoDispatch
)

export { TabelaPaginacaoEstadoContext, TabelaPaginacaoAcoesContext }
