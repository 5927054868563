import React from 'react'

export default (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6883 0.304505C16.1039 0.71051 16.1039 1.36877 15.6882 1.77478L6.48382 10.7651C6.06816 11.1711 5.39422 11.1711 4.97856 10.7651L0.311772 6.22093C-0.103898 5.81493 -0.103899 5.15666 0.311769 4.75066C0.727437 4.34465 1.40137 4.34465 1.81704 4.75066L5.73119 8.55973L14.183 0.304501C14.5986 -0.101502 15.2726 -0.1015 15.6883 0.304505Z"
      fill="white"
    />
  </svg>
)
