import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState
} from 'react'

import * as Storage from 'src/servicos/local-storage'

import { ContextoDaBarraSuperior } from '../contexts'

export const CHAVE_AVATAR_ALUNO = '@ipgs/avatarAluno'

export const ProviderDaBarraSuperior: FC<PropsWithChildren<ReactNode>> = ({
  children
}) => {
  const [foto, definirFoto] = useState<string>()

  const atualizarFoto = (foto: string): void => {
    Storage.definir(CHAVE_AVATAR_ALUNO, { foto })
    definirFoto(foto)
  }

  const obterFoto = (): string => {
    if (!foto) {
      const resposta = Storage.obter(CHAVE_AVATAR_ALUNO)

      if (resposta) {
        atualizarFoto(resposta.foto)
      }

      return foto
    }

    return foto
  }

  const removerFoto = (): void => {
    Storage.remover(CHAVE_AVATAR_ALUNO)
    atualizarFoto(null)
  }

  useEffect(() => {
    obterFoto()
  }, [])

  return (
    <ContextoDaBarraSuperior.Provider
      value={{
        obterFoto,
        atualizarFoto,
        removerFoto
      }}
      children={children}
    />
  )
}
