import React, { useEffect, useRef, useState } from 'react'

import { IconeSetaDropDown, IconeSetaVertical } from '../icones'
import {
  BotaoExpandir,
  Conteudo,
  Linha,
  TituloAccordion,
  Titulo,
  ConteudoItem,
  ContainerTitulo
} from './styles'
import { AccordionProps } from './tipos'

export const Accordion: React.FC<AccordionProps> = ({
  titulo,
  subtitulo,
  iniciarAberto,
  inativo,
  children,
  desabilitado,
  deveExpandir,
  classe,
  desativarScrollExpandir
}) => {
  const [expandido, definirExpandido] = useState<boolean>(iniciarAberto)
  const ref = useRef<HTMLDivElement>(null)

  const acaoExpandido = (expandido: boolean) => {
    definirExpandido(expandido)
    if (!desativarScrollExpandir) {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  useEffect(() => {
    if (deveExpandir) {
      if (deveExpandir()) {
        definirExpandido(true)
      }
    }
  }, [deveExpandir])

  useEffect(() => {
    if (desabilitado) definirExpandido(false)
  }, [desabilitado])

  return (
    <>
      <div className={classe}>
        <TituloAccordion ref={ref} inativo={inativo}>
          <Linha>
            <ContainerTitulo>
              <Titulo> {titulo} </Titulo>
              {subtitulo && <Titulo> {subtitulo} </Titulo>}
            </ContainerTitulo>
            {!desabilitado && (
              <BotaoExpandir
                onClick={() => acaoExpandido(!expandido)}
                type="button"
              >
                {expandido ? IconeSetaVertical : IconeSetaDropDown}
              </BotaoExpandir>
            )}
          </Linha>
        </TituloAccordion>
        <Conteudo expandido={expandido} id="accordion-conteudo">
          <ConteudoItem>{children}</ConteudoItem>
        </Conteudo>
      </div>
    </>
  )
}
