import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { ModalidadeCursoPorExtenso } from 'src/tipos'

export const colunaModalidade = (): TabelaColuna => ({
  Chave: 'modalidade',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{ModalidadeCursoPorExtenso[linha.modalidade]}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 100 }}>Modalidade</div>
})
