import { useState } from 'react'

import {
  Curso,
  CursosCarrinho,
  DadosPessoaisMatricula,
  Matricula,
  ModalidadeCurso,
  PagamentoMatricula,
  PlanoPagamentoMatricula,
  ResponsavelFinanceiro,
  TermosCondicoesMatricula,
  validaEhModalidadeLivreOuEvento
} from 'src/tipos'
import { createContainer } from 'unstated-next'

import {
  MatriculaContainer,
  MatriculaContainerProps,
  MatriculaContainerEstado,
  ModoMatricula
} from './tipos'

const useMatricula = (initialState?: MatriculaContainerProps) => {
  const [estado, definirEstado] = useState<MatriculaContainerEstado>({
    curso: {} as Curso,
    carrinhoCurso: {} as CursosCarrinho,
    modo: ModoMatricula.Livre,
    matricula: {} as Matricula,
    ...initialState
  })

  const definirPasso = (passo: number) => {
    definirEstado(old => ({ ...old, passo }))
  }

  const definirCurso = (curso: Curso) => {
    definirEstado(old => ({
      ...old,
      curso,
      modo: validaEhModalidadeLivreOuEvento(curso.modalidade as ModalidadeCurso)
        ? ModoMatricula.Livre
        : ModoMatricula.Pos
    }))
  }

  const definirCursosCarrinho = (carrinhoCurso: CursosCarrinho) => {
    definirEstado(old => ({
      ...old,
      carrinhoCurso: {
        ...old.carrinhoCurso,
        cursos: carrinhoCurso.cursos
      },
      modo: ModoMatricula.Livre
    }))
  }

  const definirDadosPessoais = (
    id: string,
    dadosPessoais: DadosPessoaisMatricula
  ) => {
    definirEstado(old => ({
      ...old,
      matricula: { ...old.matricula, id, dadosPessoais }
    }))
  }

  const definirPagamento = (pagamentoMatricula: PagamentoMatricula) => {
    definirEstado(old => ({
      ...old,
      matricula: { ...old.matricula, pagamentoMatricula }
    }))
  }

  const definirPlanoPagamento = (planoPagamento: PlanoPagamentoMatricula) => {
    definirEstado(old => ({
      ...old,
      matricula: {
        ...old.matricula,
        planoPagamento: { ...old.matricula?.planoPagamento, ...planoPagamento }
      }
    }))
  }

  const definirTermosCondicoes = (
    termosCondicoes: Omit<TermosCondicoesMatricula, 'urlTermosCondicoes'>
  ) => {
    definirEstado(old => ({
      ...old,
      matricula: {
        ...old.matricula,
        termosCondicoes: {
          ...old.matricula?.termosCondicoes,
          ...termosCondicoes
        }
      }
    }))
  }

  const definirMatricula = (matricula: Matricula) => {
    definirEstado(old => ({
      ...old,
      matricula
    }))
  }

  const possuiCarrinho = (): boolean =>
    estado?.carrinhoCurso?.cursos?.length > 0

  const possuiMatriculaCarrinho = (): boolean =>
    estado?.carrinhoCurso?.matriculas?.length > 0

  const obterCursoEstado = (): Curso => {
    if (estado.curso?.id) return estado.curso
    if (estado.carrinhoCurso?.cursos) return estado.carrinhoCurso?.cursos[0]
    return {} as Curso
  }

  const definirMatriculaCursosCarrinho = (carrinhoCompra: CursosCarrinho) => {
    definirEstado(old => ({
      ...old,
      carrinhoCurso: {
        cursos: carrinhoCompra.cursos ?? old.carrinhoCurso.cursos,
        matriculas: carrinhoCompra.matriculas ?? [],
        carrinhoCompraId: carrinhoCompra.carrinhoCompraId,
        boletoGerado: carrinhoCompra.boletoGerado
      },
      modo: ModoMatricula.Livre
    }))
  }

  const obterMatriculas = (): Matricula[] => {
    if (possuiMatriculaCarrinho() && possuiCarrinho()) {
      return estado.carrinhoCurso?.matriculas
    }
    if (estado.matricula) return [estado.matricula]
    return []
  }

  const definirBoletoGeradoCarrinho = (boletoGerado: boolean) => {
    definirEstado(old => ({
      ...old,
      carrinhoCurso: {
        ...old.carrinhoCurso,
        boletoGerado: boletoGerado
      }
    }))
  }

  const definirPagamentoMatriculasCarrinho = (
    pagamentoMatricula: PagamentoMatricula
  ) => {
    definirEstado(old => ({
      ...old,
      carrinhoCurso: {
        ...old.carrinhoCurso,
        matriculas:
          old.carrinhoCurso?.matriculas?.map(m => {
            return {
              ...m,
              pagamentoMatricula
            }
          }) ?? []
      }
    }))
  }

  const definirResponsavelFinanceiro = (responsavel: ResponsavelFinanceiro) => {
    definirEstado(old => ({
      ...old,
      matricula: {
        ...old.matricula,
        responsavelFinanceiro: responsavel
      }
    }))
  }

  return {
    ...estado,
    definirPasso,
    definirCurso,
    definirCursosCarrinho,
    definirMatricula,
    definirDadosPessoais,
    definirPagamento,
    definirTermosCondicoes,
    definirPlanoPagamento,
    possuiCarrinho,
    obterCursoEstado,
    definirMatriculaCursosCarrinho,
    obterMatriculas,
    possuiMatriculaCarrinho,
    definirBoletoGeradoCarrinho,
    definirPagamentoMatriculasCarrinho,
    definirResponsavelFinanceiro
  }
}

export default createContainer<MatriculaContainer, MatriculaContainerProps>(
  useMatricula
)
