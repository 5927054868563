import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Carregando,
  ContainerAcoes,
  Cores,
  ErroContainerLayout,
  ErroLayoutContainer,
  Filtro,
  FormRow,
  FormUnform,
  IconeBack,
  IconeBaixar,
  IconeCerto,
  IconeEditar,
  IconeErrado,
  InputUnform,
  Link,
  Navegacao,
  SubTitulo,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  SelectUnform,
  AutenticacaoContainer,
  Visao
} from 'src/componentes'
import { TipoDeAvaliacao } from 'src/dto'
import { RotasAcademico } from 'src/rotas/academico'
import { Api } from 'src/servicos'
import { getApiError } from 'src/servicos/instancias-api'
import { ModalidadeCurso, SituacaoFinanceira } from 'src/tipos'

import {
  Feedback,
  ModalNotaDisciplina,
  ModalNotaDisciplinaRef,
  ModalNotaModulo,
  NotaDisciplinaProps
} from '..'

import {
  Container,
  ContainerLateral,
  Conteudo,
  ContainerMain
} from '../../cadastro/styles'
import { ContainerBotao } from '../disciplinas/styles'
import { NotasPresencaEadProps } from '../disciplinas/tipos'
import { AlunosPresencial } from '../tipos'
import { COLUNAS_DE_NOTAS_E_PRESENCA_NA_TURMA } from './componentes/colunas'
import {
  CabecalhoComEstilos,
  ContainerData,
  ContainerIcone,
  ContainerIcones,
  ContainerLink,
  FormStyled,
  TabelaComEstilos
} from './styles'
import { opcoesFiltros } from './tipos'
import { dataFormatadaComBarras } from './turma-presencial'
enum Navegacoes {
  Alunos = 'alunos'
}
const PaginaVerAlunos: React.FC<NotasPresencaEadProps> = ({ match }) => {
  const tabelaRef = useRef<TabelaRef>()
  const modalNotaDisciplinaRef = useRef<ModalNotaDisciplinaRef>(null)
  const modalNotaModuloRef = useRef<ModalNotaDisciplinaRef>(null)

  const history = useHistory()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const [pronto, definirPronto] = useState<boolean>(false)
  const [feedback, definirFeedback] = useState<Feedback>(null)
  const [turma, definirTurma] = useState(null)
  const [dadosIniciais, definirDadosIniciais] = useState(null)
  const [modalidade, definirModalidade] = useState(null)
  const [situacaoFiltro, definirSituacaoFiltro] = useState<SituacaoFinanceira>(
    null
  )
  const { visao } = AutenticacaoContainer.useContainer()

  const visaoParceiro = useMemo(() => visao === Visao.Parceiro, [visao])
  const visaoProfessor = useMemo(() => visao === Visao.Professor, [visao])
  const ehPresencial = modalidade === ModalidadeCurso.PosGraduacaoPresencial
  const idDaDisciplina = match.params.idDaDisciplina
  const idTurma = match.params.idDaTurma
  const idDoModulo = match.params.idDoModulo

  const dadosIniciaisFiltro = useMemo(() => {
    return {
      situacao: visaoProfessor
        ? SituacaoFinanceira[SituacaoFinanceira.Matriculado]
        : null
    }
  }, [visaoProfessor])

  const possuiTurmaPresencialPorDisciplina = () =>
    idTurma !== 'undefined' && idDaDisciplina !== 'undefined'

  const obterTurma = async (): Promise<AlunosPresencial> => {
    return possuiTurmaPresencialPorDisciplina()
      ? await Api.ObterDetalhesDisciplinaPresencial(idDaDisciplina, idTurma)
      : await Api.ObterDetalhesModulo(idTurma, idDoModulo)
  }

  const carregarModulo = async () => {
    try {
      definirPronto(false)
      limparErro()

      const modalidade = await Api.RequisitarTurmaPorId(idTurma)
      const turma = await obterTurma()

      if (turma.alunos && turma.alunos.length > 0) {
        definirModalidade(modalidade.modalidadeCurso)
        definirTurma(turma)
        definirDadosIniciais({
          turma: turma.nomeTurma,
          modulo: turma.nomeModulo,
          tutor: turma.tutorTurma
        })
      } else {
        throw Error('A turma ainda não possui nenhum aluno matriculado.')
      }
    } catch (error) {
      getApiError(error).then(result => {
        const mensagens = result.join('\n')

        definirErro({
          mensagens: `Ops! Houve algum problema no carregamento dos dados.\n${mensagens}`,
          acaoVoltar: () => history.push(RotasAcademico.Turmas)
        })
      })
    } finally {
      definirPronto(true)
    }
  }

  const registrarFeedback = async dados => {
    try {
      const novosDados = { ...dados, avaliacaoId: feedback.avaliacaoId }
      await Api.RegistrarFeedbackProfessor(feedback.salaId, novosDados)
      await carregarModulo()
      await tabelaRef.current.CarregarDados(true)
      toast('Feedback salvo com sucesso.', {
        type: 'success'
      })
    } catch (error) {
      toast('Houve um problema ao enviar o feedback', {
        type: 'error'
      })
    }
  }

  const chamarModalPorContextoDeAvaliacao = (dados: NotaDisciplinaProps) => {
    possuiTurmaPresencialPorDisciplina()
      ? modalNotaDisciplinaRef?.current?.abrir(dados)
      : modalNotaModuloRef?.current?.abrir(dados)
  }

  const obterDados = async (): Promise<TabelaResposta | null> => {
    const alunosBase = turma && turma.alunos ? turma.alunos : []
    const dados = !situacaoFiltro
      ? alunosBase
      : alunosBase.filter(aluno => aluno.situacaoFinanceira === situacaoFiltro)

    return { Dados: dados } as TabelaResposta
  }

  // TO-DO: Falta implementação
  // eslint-disable-next-line
  const acaoSucessoFiltro = async dados => {}

  const filtroAlterado = async selecionado => {
    if (!selecionado) {
      await definirSituacaoFiltro(null)
    } else {
      const { id } = selecionado
      await definirSituacaoFiltro(id as SituacaoFinanceira)
    }
    await tabelaRef.current.CarregarDados(true)
  }

  useEffect(() => {
    carregarModulo()
  }, [])

  return pronto && !erro ? (
    <Container>
      <ContainerMain>
        <CabecalhoComEstilos>
          <ContainerLink>
            <Link
              texto="Voltar"
              href={RotasAcademico.Turmas}
              icone={IconeBack}
            />
          </ContainerLink>
          <Breadcrumb
            titulo="Cadastro de Notas e Presença na Turma"
            atalhos={[
              {
                texto: 'Acadêmico'
              },
              {
                texto: 'Notas e Presença'
              }
            ]}
          />
        </CabecalhoComEstilos>
        <Conteudo>
          <div id={Navegacoes.Alunos}>
            <SubTitulo texto="Alunos" />
            <FormUnform acaoSucesso={null} dadosIniciais={dadosIniciais}>
              <InputUnform name="turma" label="Turma" disabled={true} />
              <InputUnform name="modulo" label="Módulo" disabled={true} />
              <InputUnform name="tutor" label="Tutor" disabled={true} />
            </FormUnform>
            <>
              <Filtro iniciarAberto={visaoProfessor}>
                <FormStyled
                  acaoSucesso={acaoSucessoFiltro}
                  dadosIniciais={dadosIniciaisFiltro}
                >
                  <FormRow>
                    <SelectUnform
                      className="sm"
                      id="slct_situacao"
                      name="situacao"
                      label="Situação"
                      iconeFechar={true}
                      opcoes={opcoesFiltros}
                      valorAlterado={filtroAlterado}
                    ></SelectUnform>
                  </FormRow>
                </FormStyled>
              </Filtro>
              <TabelaComEstilos
                ref={tabelaRef}
                Colunas={[
                  ...COLUNAS_DE_NOTAS_E_PRESENCA_NA_TURMA,
                  ehPresencial && {
                    Chave: 'encontros',
                    StyleColuna: ehPresencial ? { width: 90 } : { width: 0 },
                    Alinhamento: 'center',
                    Renderizar: (linha: TabelaDados) => (
                      <ContainerIcones>
                        {linha.encontros.map(m =>
                          m.presenca === true ? (
                            <>
                              <ContainerIcone color={Cores.VERDE}>
                                {dataFormatadaComBarras(
                                  new Date(m.dataEncontro)
                                )}
                                {IconeCerto}
                              </ContainerIcone>
                            </>
                          ) : m.presenca === false ? (
                            <>
                              <ContainerIcone color={Cores.PERIGO_ERRO}>
                                {dataFormatadaComBarras(
                                  new Date(m.dataEncontro)
                                )}
                                {IconeErrado}
                              </ContainerIcone>
                            </>
                          ) : (
                            <>
                              <ContainerData>
                                {dataFormatadaComBarras(
                                  new Date(m.dataEncontro)
                                )}
                              </ContainerData>
                            </>
                          )
                        )}
                      </ContainerIcones>
                    ),
                    RenderizarCabecalho: () => (
                      <div style={{ width: 90 }}>Encontros Presenciais</div>
                    )
                  },
                  {
                    Chave: 'acoes',
                    Alinhamento: 'center',
                    Renderizar: (linha: TabelaDados) => (
                      <td>
                        {linha.avaliacao.tipoAvaliacao ===
                        TipoDeAvaliacao.AvaliacaoPorAquivo ? (
                          <ContainerAcoes>
                            <button
                              type="button"
                              aria-label="Baixar"
                              title="Baixar"
                              onClick={async e => {
                                e.stopPropagation()
                                await Api.DownloadUltimaAvaliacao(
                                  linha.avaliacao.id
                                )
                              }}
                              disabled={
                                visaoParceiro ||
                                linha.avaliacao.dataUltimaTentativaAvaliacao ===
                                  null
                              }
                            >
                              {IconeBaixar}
                            </button>
                            <button
                              type="button"
                              aria-label="Editar"
                              title="Editar"
                              onClick={() => {
                                const dados = {
                                  avaliacaoId: linha.avaliacao.id,
                                  nome: linha.nomeAluno,
                                  modulo: turma.nomeModulo,
                                  encontros: linha.encontros,
                                  tentativas:
                                    linha.avaliacao.tentativasRestantes,
                                  modalidade: modalidade,
                                  tipoAvaliacao: linha.avaliacao.tipoAvaliacao,
                                  nota: linha.avaliacao.nota,
                                  observacao: linha.avaliacao.observacao,
                                  feedback: linha.avaliacao.feedback
                                }

                                definirFeedback({
                                  avaliacaoId: linha.avaliacao.id,
                                  salaId: linha.idSalaAula
                                })

                                chamarModalPorContextoDeAvaliacao(dados)
                              }}
                              disabled={visaoParceiro}
                            >
                              {IconeEditar}
                            </button>
                          </ContainerAcoes> /* eslint-disable */
                        ) : (
                          <ContainerAcoes>
                            <button
                              type="button"
                              aria-label="Editar"
                              title="Editar"
                              onClick={() => {
                                const dados = {
                                  avaliacaoId: linha.avaliacao.id,
                                  nome: linha.nomeAluno,
                                  disciplina: turma.nomeDisciplina,
                                  tentativas:
                                    linha.avaliacao.tentativasRestantes,
                                  encontros: linha.encontros,
                                  tipoAvaliacao: linha.avaliacao.tipoAvaliacao,
                                  nota: linha.avaliacao.nota,
                                  observacao: linha.avaliacao.observacao,
                                  feedback: linha.avaliacao.feedback
                                }

                                definirFeedback({
                                  avaliacaoId: linha.avaliacao.id,
                                  salaId: linha.idSalaAula
                                })

                                chamarModalPorContextoDeAvaliacao(dados)
                              }}
                              disabled={visaoParceiro}
                            >
                              {IconeEditar}
                            </button>
                          </ContainerAcoes>
                        )}
                      </td>
                    )
                  }
                ]}
                /* eslint-enable */
                ObterDados={obterDados}
              />
            </>
          </div>
        </Conteudo>
        <ContainerBotao>
          <Botao
            type="button"
            texto="Voltar"
            tema="Secundario"
            onClick={() => history.goBack()}
          />
        </ContainerBotao>
      </ContainerMain>
      <ContainerLateral>
        <Navegacao
          itens={[
            {
              link: Navegacoes.Alunos,
              nome: 'Alunos'
            }
          ]}
        />
      </ContainerLateral>
      <ModalNotaDisciplina
        ref={modalNotaDisciplinaRef}
        backdrop
        id="modal-nota-disciplina"
        acaoPrimaria={registrarFeedback}
      />
      <ModalNotaModulo
        ref={modalNotaModuloRef}
        backdrop
        id="modal-nota-modulo"
        acaoPrimaria={registrarFeedback}
      />
    </Container>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados dos alunos da turma..." />
  ) : (
    <ErroContainerLayout />
  )
}

const withContainer: FC<NotasPresencaEadProps> = (
  props: NotasPresencaEadProps
) => (
  <ErroLayoutContainer.Provider>
    <PaginaVerAlunos {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(withContainer)
