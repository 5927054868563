import queryString from 'query-string'
import { SelectOpcao } from 'src/componentes/unform/select/tipos'
import { SELECT_UTILIZACOES_CUPOM } from 'src/dados-estaticos'
import { PesquisaCupomDTO, PesquisaCupomResposta } from 'src/dto'
import {
  Cupom,
  RespostaPadraoAPI,
  RespostaPadraoDadosAPI,
  TipoDescontoCupom
} from 'src/tipos'
import { TipoOrdenacao } from 'src/tipos/ordenacao'
import { OpcoesPaginacao } from 'src/tipos/paginacao'

import { gatewayApi } from '../instancias-api'

const urlBase = '/cupomdesconto'

export const RequisitarListaCupons = async (
  nome: string,
  codigoCupom: string,
  tipoDescontoCupom: keyof typeof TipoDescontoCupom,
  pagina: number,
  quantidadeRegistros: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaCupomDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      nome,
      codigoCupom,
      tipoCupom:
        tipoDescontoCupom === TipoDescontoCupom[TipoDescontoCupom.Todos]
          ? null
          : tipoDescontoCupom,
      quantidadeRegistros,
      pagina,
      ordem
    })
    const {
      data: {
        data: { itens, quantidade }
      }
    } = await gatewayApi.get<PesquisaCupomResposta>(`${urlBase}?${filtros}`)

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }

    return new Promise(resolve =>
      resolve({ registros: itens, opcoesPaginacao })
    )
  } catch {
    return null
  }
}

export const RequisitarCupom = async (id: string): Promise<Cupom> => {
  const {
    data: { data }
  } = await gatewayApi.get<RespostaPadraoDadosAPI<Cupom>>(`${urlBase}/${id}`)

  return {
    ...data,
    limitePorCliente:
      data.limitePorCliente === 0 ? null : data.limitePorCliente,
    limitePorCupom: data.limitePorCupom === 0 ? null : data.limitePorCupom
  }
}

export const RequisitarListaUtilizacoesPorTexto = async ({
  texto
}: {
  texto: string
}): Promise<SelectOpcao[]> => {
  return new Promise<SelectOpcao[]>(resolve =>
    resolve(SELECT_UTILIZACOES_CUPOM.filter(t => t.texto.includes(texto)))
  )
}

export const RequisitarListaUtilizacoesPorId = async ({
  id
}: {
  id: string
}): Promise<SelectOpcao> => {
  const resposta = SELECT_UTILIZACOES_CUPOM.find(item => item.id === id)
  return new Promise<SelectOpcao>(resolve => resolve(resposta))
}

export const InserirCupom = async (
  payload: Omit<Cupom, 'id'>
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.post<RespostaPadraoAPI>(`${urlBase}`, {
    ...payload,
    dataFim: payload.dataFim === '' ? null : payload.dataFim,
    valorDesconto: payload.porcentagemDesconto ?? payload.valorDesconto
  })

  return success
}

export const EditarCupom = async (
  id: string,
  payload: Cupom
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.put<RespostaPadraoAPI>(`${urlBase}/${id}`, {
    ...payload,
    dataFim: payload.dataFim === '' ? null : payload.dataFim,
    valorDesconto: payload.porcentagemDesconto ?? payload.valorDesconto
  })

  return success
}
