import styled, { css } from 'styled-components'

import { Cores } from '../styles'
import { MenuContextoProps } from './tipos'

export type MenuContextoMenuProps = Omit<MenuContextoProps, 'botao'>

const menuAberto = css`
  display: block;
  opacity: 1;
  top: 100%;
  transition: all 0.2s ease-in;
`

export const Componente = styled.div`
  position: relative;
`

export const CaixaBotao = styled.div<
  Pick<MenuContextoProps, 'desabilitado' | 'abrirComClick'>
>`
  &.aberto {
    ~ div.menu_contexto {
      ${menuAberto}
    }
  }
`

export const Menu = styled.div<MenuContextoMenuProps>`
  position: absolute;
  top: 110%;
  background: ${Cores.BRANCO};
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.12);
  border: 1px solid ${Cores.CINZA_1_CLARO};
  border-top: 0px;
  transition: all 0.1s ease-in-out;
  display: none;
  opacity: 0;
  z-index: 3;
  width: auto;
  white-space: nowrap;
  border-radius: 3px;
  border-top-right-radius: 2px;

  ${({ posicao }) => {
    switch (posicao) {
      case 'direita':
        return css`
          right: -1px;
        `
      default:
        return css`
          left: -1px;
        `
    }
  }}

  &.aberto {
    ${menuAberto}
  }
`

export const Opcao = styled.button`
  font-family: 'Poppins-Semi-Bold', sans-serif;
  font-size: 1.2rem;
  color: ${Cores.CINZA_2};
  flex: 1;
  padding: 1.5rem 2.4rem;
  border: none;
  background: ${Cores.BRANCO};
  text-align: left;
  cursor: pointer;

  &:hover {
    background: ${Cores.CINZA_3_MAIS_CLARO};
    color: ${Cores.PRIMARIA_1};
  }

  &:disabled {
    background: ${Cores.BRANCO};
    color: ${Cores.CINZA_2_CLARO};
    cursor: not-allowed;
  }
`
