import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { Botao, ModalBase, ModalBaseProps, ModalRef } from 'src/componentes'

import {
  ContainerDoConteudo,
  ContainerDosBotoes,
  ContainerModal,
  TituloPrincipal
} from './styles'

export interface ModalAvisoAlteracaoEmailRef extends Omit<ModalRef, 'abrir'> {
  abrir: (conteudo: React.ReactElement) => void
}

export type ModalAvisoAlteracaoEmailProps = Omit<
  ModalBaseProps,
  'acaoPrimaria'
> & {
  acaoPrimaria: () => Promise<void> | void
}

const Modal: React.ForwardRefRenderFunction<
  ModalAvisoAlteracaoEmailRef,
  ModalAvisoAlteracaoEmailProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [conteudo, definirConteudo] = useState(null)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = (conteudo: React.ReactElement) => {
    definirConteudo(conteudo)
    definirAberta(true)
  }

  useImperativeHandle<ModalAvisoAlteracaoEmailRef, ModalAvisoAlteracaoEmailRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase
      id="modal-status-alteracao-email"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Status alteração de email</TituloPrincipal>
        <ContainerDoConteudo>{conteudo ?? <></>}</ContainerDoConteudo>
        <ContainerDosBotoes>
          <Botao texto="OK" id="btn_ok" onClick={acaoPrimaria} />
        </ContainerDosBotoes>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalAvisoAlteracaoEmail = forwardRef(Modal)
