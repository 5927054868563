import React, { useMemo, useState } from 'react'
import { useSpring } from 'react-spring'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  FuncoesDataHora,
  IconeCalendarioPequeno,
  IconeDesafixar,
  IconeFixar,
  IconeRelogioPequeno,
  IconeTopico,
  SeletorUnform,
  Tooltip,
  Visao
} from 'src/componentes'
import { Api } from 'src/servicos'
import { GrupoDiscussao } from 'src/tipos/grupo-discussao'

import {
  Container,
  CabecalhoListagem,
  ListaTopicos,
  ItemTopico,
  AnimatedDiv,
  ContainerInformacoesTopico,
  ContainerTopico,
  ContainerBotoesFixarTopico
} from './styles'
import { Topico } from './topico'

interface GrupoDiscussaoComponenteProps {
  grupoDiscussao: GrupoDiscussao
  recarregar?: () => Promise<void>
}

export const GrupoDiscussaoComponente: React.FC<GrupoDiscussaoComponenteProps> = ({
  grupoDiscussao,
  recarregar
}) => {
  const [carregando, definirCarregando] = useState(false)
  const [topicoSelecionado, definirTopicoSelecionado] = useState<string>(null)
  const [confirmacaoFixarTopico, definirConfirmacaoFixarTopico] = useState(
    false
  )
  const [ordemFixar, definirOrdemFixar] = useState('')
  const [topicoFixarId, definirTopicoFixarId] = useState('')
  const { visao } = AutenticacaoContainer.useContainer()
  const visaoIPGS = useMemo(() => {
    return visao === Visao.IPGS
  }, [visao])
  const visaoParceiro = useMemo(() => visao === Visao.Parceiro, [visao])
  const podeFixarTopico = useMemo(() => visaoIPGS || visaoParceiro, [
    visaoIPGS,
    visaoParceiro
  ])
  const { transform, opacity } = useSpring({
    opacity: topicoSelecionado ? 1 : 0,
    transform: `translate(${topicoSelecionado ? -180 : 0}px, 0px)`,
    config: { mass: 1, tension: 900, friction: 100 }
  })

  const fixarTopico = async (topicoId: string, fixar: boolean) => {
    if (fixar && (!ordemFixar || ordemFixar === '')) {
      toast('A ordem de exibição precisa ser definida.', { type: 'error' })
      return
    }

    try {
      definirCarregando(true)
      await Api.FixarTopico(
        grupoDiscussao.grupoDiscussaoId,
        topicoId,
        fixar,
        ordemFixar
      )

      if (recarregar) await recarregar()

      definirConfirmacaoFixarTopico(false)
      definirTopicoFixarId(null)

      definirCarregando(false)
    } finally {
      definirCarregando(false)
    }
  }

  const toggleFixar = topicoId => {
    definirConfirmacaoFixarTopico(true)
    definirTopicoFixarId(topicoId)
    definirOrdemFixar('')
  }

  return (
    <>
      <Container>
        {!topicoSelecionado ? (
          <AnimatedDiv
            id="grupo-discussao-listagem"
            style={{
              opacity: opacity.interpolate(o => 1 - Number(o)),
              transform
            }}
          >
            <CabecalhoListagem></CabecalhoListagem>
            {grupoDiscussao?.topicos?.length > 0 && (
              <ListaTopicos>
                {grupoDiscussao.topicos.map((topico, index) => (
                  <ContainerTopico key={index}>
                    <ItemTopico
                      key={topico.topicoId}
                      onClick={() => {
                        definirTopicoSelecionado(topico.topicoId)
                      }}
                    >
                      <ContainerInformacoesTopico>
                        {IconeCalendarioPequeno}
                        <span>
                          {FuncoesDataHora.novaData(topico?.dataInclusao)}
                        </span>
                        {IconeRelogioPequeno}
                        <span>
                          {FuncoesDataHora.horaFormatada24H(
                            new Date(topico?.dataInclusao)
                          )}
                        </span>
                      </ContainerInformacoesTopico>
                      <div>
                        {IconeTopico}
                        <span>{topico.titulo}</span>
                      </div>
                    </ItemTopico>
                    {podeFixarTopico && (
                      <>
                        <button
                          type="button"
                          data-tip={
                            !topico.fixado ? 'Fixar tópico' : 'Desafixar'
                          }
                          data-for={`${topico.topicoId}-tooltip-fixar-topico`}
                          onClick={() => {
                            toggleFixar(topico.topicoId)
                          }}
                          disabled={carregando}
                        >
                          {!topico.fixado ? IconeFixar : IconeDesafixar}
                        </button>
                        <Tooltip
                          id={`${topico.topicoId}-tooltip-fixar-topico`}
                          place="left"
                          className="tooltipFixar"
                        />
                        {confirmacaoFixarTopico &&
                          topicoFixarId === topico.topicoId && (
                            <ContainerBotoesFixarTopico>
                              {!topico.fixado && (
                                <SeletorUnform
                                  id={`${topico.topicoId}-slc-ordem-fixar`}
                                  name={`${topico.topicoId}-slc-ordem-fixar`}
                                  tipo="radio"
                                  label="Ordem"
                                  seletores={[
                                    {
                                      id: '1',
                                      texto: '1'
                                    },
                                    {
                                      id: '2',
                                      texto: '2'
                                    },
                                    {
                                      id: '3',
                                      texto: '3'
                                    }
                                  ]}
                                  valorAlterado={opcao => {
                                    definirOrdemFixar(opcao?.toString())
                                  }}
                                />
                              )}
                              <button
                                type="button"
                                onClick={() => {
                                  definirConfirmacaoFixarTopico(false)
                                }}
                              >
                                Cancelar
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  fixarTopico(topico.topicoId, !topico.fixado)
                                }
                              >
                                Confirmar
                              </button>
                            </ContainerBotoesFixarTopico>
                            // eslint-disable-next-line
                          )}
                      </>
                    )}
                  </ContainerTopico>
                ))}
              </ListaTopicos>
            )}
          </AnimatedDiv>
        ) : (
          <AnimatedDiv
            id="grupo-discussao-topico"
            style={{
              opacity,
              transform: transform.interpolate(
                t => `${t} translate(180px, 0px)`
              )
            }}
          >
            <Topico
              idTopico={topicoSelecionado}
              idGrupoDiscussao={grupoDiscussao?.grupoDiscussaoId}
              onClick={() => definirTopicoSelecionado(null)}
            />
          </AnimatedDiv>
        )}
      </Container>
    </>
  )
}
