import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const ListaInfos = styled.ul`
  list-style: none;
  color: ${Cores.CINZA_2_MAIS_ESCURO};
  margin-bottom: 20px;
`

export const ItemInfo = styled.li`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 200%;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`
