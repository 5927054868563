import styled, { css } from 'styled-components'

import { Medias } from '../styles/breakpoints'
import { Cores } from '../styles/cores'

const itemRetraidoAnimado = css`
  .item-retraido {
    opacity: 1;
    z-index: 1;
    width: 240px;
    transition: all 0.1s linear;
    background: ${Cores.CINZA_3_CLARO};
    color: ${Cores.PRIMARIA_1};

    svg.seta {
      transition: all 0.2s ease-in;
      transform: rotate(90deg);
    }

    .icone-titulo {
      svg,
      span {
        display: unset;
      }
    }
  }
`

export const SubLista = styled.ul<{ selecionado?: boolean; subMenu: boolean }>`
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 46px;
  width: 100%;
  background: ${Cores.BRANCO};
  pointer-events: none;

  &:hover {
    ~ button {
      cursor: pointer;
      background: ${Cores.CINZA_3_CLARO};
      color: ${Cores.PRIMARIA_1};

      svg:first-child path {
        fill: ${Cores.PRIMARIA_1};
      }

      &.iconeComStroke {
        svg:first-child {
          path {
            stroke: ${Cores.PRIMARIA_1};
          }
        }
      }

      svg.seta path {
        stroke: ${Cores.PRIMARIA_1};
      }
    }

    ~ .retraido {
      ${itemRetraidoAnimado}
    }
  }

  @media ${Medias.NOTEBOOK.MIN} {
    position: absolute;
    left: 238px;
    top: 0;
    padding: 0;
    width: auto;
    border: 1px solid ${Cores.CINZA_3_ESCURO};
    border-radius: 4px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.12);
    z-index: 2;
    white-space: nowrap;

    &.retraido {
      top: 54px;
      left: 72px;
      min-width: 166px;

      ${({ subMenu }) =>
        subMenu &&
        css`
          display: none;
          left: 192px !important;
          top: 0px !important;
        `}

      ${({ selecionado }) =>
        selecionado &&
        css`
          display: block;
        `}
    }
  }
`

export const Lista = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 240px;

  &.retraido {
    width: 72px;

    button {
      svg:first-child {
        margin-right: 0;
      }
    }
  }

  .clicavel {
    cursor: pointer;
  }
`

export const Item = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Cores.CINZA_2};
  font-size: 14px;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    margin: 0;
    background: transparent;
    width: 100%;
    text-align: left;
    padding: 0 24px;
    height: 54px;
    color: ${Cores.CINZA_2};
    font-size: 14px;

    div.icone-titulo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      svg:first-child {
        margin-right: 12px;
      }
    }

    svg:first-child {
      width: 24px;
      height: 24px;

      path {
        fill: ${Cores.BRANCO};
      }
    }

    &.iconeComStroke {
      svg:first-child {
        path {
          stroke: ${Cores.BRANCO};
        }
      }
    }

    svg.seta {
      transition: all 0.1s ease-in-out;
      transform: rotate(0);

      path {
        stroke: ${Cores.BRANCO};
      }
    }

    &:hover,
    &:hover > .animado {
      background: ${Cores.CINZA_3_CLARO};
      color: ${Cores.PRIMARIA_1};

      svg:first-child path {
        fill: ${Cores.PRIMARIA_1};
      }

      &.iconeComStroke {
        svg:first-child {
          path {
            stroke: ${Cores.PRIMARIA_1};
          }
        }
      }

      svg.seta path {
        stroke: ${Cores.PRIMARIA_1};
      }
    }

    &.ativo,
    &.ativo > .item-retraido {
      background: ${Cores.SECUNDARIA_2_ESCURO} !important;
      color: ${Cores.BRANCO} !important;

      svg:first-child path {
        fill: ${Cores.BRANCO} !important;
      }

      svg.seta path {
        stroke: ${Cores.BRANCO} !important;
      }
    }

    &.retraido:hover {
      ${itemRetraidoAnimado}
    }
  }

  & > ul {
    display: block;
    height: auto;
    max-height: 0;
    transition: max-height 0.1s ease-in-out;
  }

  &:hover {
    ${SubLista} {
      pointer-events: unset;
    }
  }

  &.aberto {
    > button {
      background: ${Cores.CINZA_3_CLARO};
      color: ${Cores.PRIMARIA_1};

      svg:first-child path {
        fill: ${Cores.PRIMARIA_1};
      }

      svg.seta {
        transition: all 0.1s ease-in;
        transform: rotate(90deg);

        path {
          stroke: ${Cores.SECUNDARIA_2_ESCURO} !important;
        }
      }
    }

    > ul {
      max-height: 999px;
      transition: max-height 0.2s ease-in;
    }
  }

  @media ${Medias.NOTEBOOK.MIN} {
    &.aberto > ul {
      display: none;
      max-height: 0;
    }

    & > ul {
      transition: opacity 0.1s ease-out;
      max-width: 0;
      max-height: 0;
      opacity: 0;
    }

    &:hover {
      & > ul {
        transition: max-width 0.2s ease-in;
        opacity: 1;
        max-width: 999px;
        max-height: 999px;

        &.retraido {
          transition: all 0.2s ease-in;
          transition-delay: 0.1s;
        }
      }
    }
  }

  @media ${Medias.TABLET.MAX} {
    color: ${Cores.PRETO};

    & > ul {
      overflow: hidden;
    }

    button {
      color: ${Cores.PRETO};

      svg:first-child {
        path {
          fill: ${Cores.PRETO};
        }
      }

      &.iconeComStroke {
        svg:first-child {
          path {
            stroke: ${Cores.PRETO};
          }
        }
      }
    }
  }
`

export const SubItem = styled.li`
  font-size: 13px;
  padding: 0;

  button {
    height: 36px;
    width: 100%;
    padding: 0 15px;
    font-family: 'Poppins-Semi-Bold', sans-serif;
    white-space: normal;

    &.ativo {
      background: ${Cores.CINZA_3_CLARO};
      color: ${Cores.SECUNDARIA_2_ESCURO};
    }
  }

  @media ${Medias.NOTEBOOK.MIN} {
    button {
      width: 100%;
      white-space: nowrap;
      cursor: pointer;
    }

    &.retraido button {
      min-width: 166px;
      width: auto;
    }
  }
`

export const ItemRetraido = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  white-space: nowrap;
  width: 100%;
  height: 54px;
  opacity: 0;

  svg.seta {
    transition: all 0.1s ease-in-out;
    transform: rotate(0);

    path {
      stroke: ${Cores.SECUNDARIA_2_ESCURO};
    }
  }

  .icone-titulo {
    svg,
    span {
      display: none;
    }
  }
`
