import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState
} from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { Camera, Shadow } from './assets'
import {
  Circle,
  InputCamera,
  SmallCircleContainer,
  FotoLabel,
  CircleContainer,
  Error,
  MaxSizeLabel,
  Container
} from './styled'

interface FotoProps {
  name: string
  valorAlterado?: (valor: string) => void
  label?: boolean
  value?: string
  disabled?: boolean
  // maxSize in MegaBytes
  maxSize?: number
}

type InputProps = JSX.IntrinsicElements['input'] & FotoProps

export const ImageInput: React.FC<InputProps> = ({
  name,
  label,
  valorAlterado,
  value,
  disabled,
  maxSize,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const inputApresentacaoRef = useRef<HTMLInputElement>(null)
  const {
    fieldName,
    registerField,
    error,
    defaultValue,
    clearError
  } = useField(name)
  const [preview, setPreview] = useState(value ?? Shadow)

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    clearError()

    if (!e.target.files || e.target.files.length === 0) return

    const file = e.target.files[0]

    // Bloquear caso ultrapasse maxSize ou se for .svg
    if (
      (!!maxSize && file.size / (1024 * 1024) > maxSize) ||
      file.name.toLowerCase().indexOf('.svg') > -1
    ) {
      inputApresentacaoRef.current.value = ''
      setPreview(value ?? Shadow)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setPreview(reader.result as string)
      reader.onloadend = () => {
        valorAlterado && valorAlterado(reader.result as string)
        inputRef.current.value = reader.result as string
      }
    }
    reader.readAsDataURL(file)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  useEffect(() => {
    defaultValue && setPreview(defaultValue)
  }, [defaultValue])

  return (
    <Container>
      {label ? <FotoLabel htmlFor="foto" /> : null}
      <CircleContainer>
        <Circle size="big" alignContent="bottom">
          <img
            width="100%"
            height="100%"
            src={preview}
            alt="Imagem de Perfil"
            className={classNames({
              'rounded-circle': true,
              error
            })}
          />
        </Circle>
        <SmallCircleContainer
          onClick={() => inputApresentacaoRef.current.click()}
        >
          <Circle size="small">
            <img src={Camera} alt=""></img>
          </Circle>
          <InputCamera
            ref={inputApresentacaoRef}
            accept="image/jpeg, image/png, image/jpg"
            type="file"
            name="foto_apresentacao"
            onChange={handlePreview}
            disabled={disabled}
          />
          <input
            {...rest}
            ref={inputRef}
            type="text"
            name={name}
            defaultValue={defaultValue}
            hidden
          />
        </SmallCircleContainer>
      </CircleContainer>
      {!!maxSize && <MaxSizeLabel>Tamanho max. {maxSize}MB</MaxSizeLabel>}
      {error && <Error id={`erro-${rest.id}`}>{error}</Error>}
    </Container>
  )
}
