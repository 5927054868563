import { Responsivo } from 'src/compartilhados'
import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const Conteudo = styled.div`
  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  border: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 4px 0;
`

export const Titulo = styled.h2`
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;

  ${props => Responsivo.Mobile(props)`
    font-size: 16px;
    line-height: 19px;
  `}

  ${props => Responsivo.Tablet(props)`
    font-style: italic;
    font-size: 25px;
    line-height: 29px;
  `}
`

export const SubTitulo = styled.h3`
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;

  ${props => Responsivo.Mobile(props)`
    font-size: 10px;
    line-height: 19px;
  `}

  ${props => Responsivo.Tablet(props)`
    font-style: italic;
    font-size: 15px;
    line-height: 29px;
  `}
`

export const ContainerTela = styled.div`
  margin-top: 47px;
`
export const ContainerBotao = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  ${props => Responsivo.Mobile(props)`
     flex-direction: column;

     button {
       width: 100%;
     }
  `}
`

export const ContainerPergunta = styled.div``
