import styled from 'styled-components'

import { Breakpoints } from '../styles/breakpoints'
import { Cores } from '../styles/cores'

export const Componente = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
`

export const ContainerConteudo = styled.div`
  display: flex;
  z-index: 1;
  margin-top: 56px;
  width: 100%;
  padding: 24px;
  border: 1px solid ${Cores.CINZA_3};
  border-radius: 8px;
  background-color: ${Cores.BRANCO};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);

  @media (max-width: ${Breakpoints.MOBILE}px) {
    margin-top: 50px;
  }
`

export const ContainerExemplo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Cores.CINZA_2_CLARO};
`

export const ContainerAbas = styled.div`
  display: flex;
  flex: 1;
`
