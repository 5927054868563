import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  LayoutAuth
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { Api } from 'src/servicos'

import {
  ModalAvisoAlteracaoEmail,
  ModalAvisoAlteracaoEmailRef
} from './componentes/modal-aviso'
import { schema } from './schema'
import { Form, ResumoUsuario } from './styles'
import { SituacaoDadosAlterado, UsuarioAlteracaoDados } from './tipos'

const PaginaAlterarEmailToken: React.FC = () => {
  const [carregando, definirCarregando] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [dadosUsuario, definirDadosUsuario] = useState(
    {} as UsuarioAlteracaoDados
  )
  const [token, definirToken] = useState(null)
  const [idUsuario, definirIdUsuario] = useState(null)
  const [chaveAcesso, definirChaveAcesso] = useState(null)
  const [finalTentativas, definirFinalTentativas] = useState(false)
  const modalAvisoRef = useRef<ModalAvisoAlteracaoEmailRef>(null)

  const { erro, definirErro } = ErroLayoutContainer.useContainer()
  const { sair } = AutenticacaoContainer.useContainer()
  const history = useHistory()

  const mensagemModal = (situacao: SituacaoDadosAlterado) => {
    if (
      SituacaoDadosAlterado[SituacaoDadosAlterado.Expirado] ===
      SituacaoDadosAlterado[situacao]
    ) {
      return (
        <>
          <p>Esta solicitação já expirou.</p>
          <p>
            Você tem 15 minutos a partir da solicitação para confirmar o token.
          </p>
          <p>Se você deseja tentar novamente, entre em contato com o IPGS.</p>
        </>
      )
    }

    if (
      SituacaoDadosAlterado[SituacaoDadosAlterado.Cancelado] ===
      SituacaoDadosAlterado[situacao]
    ) {
      return (
        <>
          <p>Esta solicitação foi cancelada.</p>
          <p>Se você deseja tentar novamente, entre em contato com o IPGS.</p>
        </>
      )
    }

    if (
      SituacaoDadosAlterado[SituacaoDadosAlterado.Confirmado] ===
      SituacaoDadosAlterado[situacao]
    ) {
      return (
        <>
          <p>Esta solicitação já foi finalizada.</p>
          <p>Os dados ja foram alterados.</p>
        </>
      )
    }

    return <></>
  }

  const mensagemSucesso = () => {
    return (
      <>
        <p>Alteração efetivada com sucesso!</p>
        <p>Você será redirecionado para a tela de login.</p>
      </>
    )
  }

  const mensagemSemTentativas = () => {
    return (
      <>
        <p>Token incorreto!</p>
        <p>Você não possui mais tentativas restantes.</p>
        <p>A solicitação de alteração dos dados foi cancelada.</p>
        <p>Se você deseja tentar novamente, entre em contato com o IPGS.</p>
      </>
    )
  }

  const trataSituacaoAlteracao = (situacao: SituacaoDadosAlterado) => {
    if (
      SituacaoDadosAlterado[SituacaoDadosAlterado.Novo] !==
      SituacaoDadosAlterado[situacao]
    ) {
      modalAvisoRef?.current?.abrir(mensagemModal(situacao))
    }
  }

  const carregarSituacaoAlteracao = async () => {
    try {
      definirPronto(false)
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const id = urlParams.get('id')
      const novoToken = urlParams.get('token')
      const chaveAcesso = urlParams.get('chaveAcesso')
      definirIdUsuario(id)
      definirToken(novoToken)
      definirChaveAcesso(chaveAcesso)
      if (!id || !novoToken || !chaveAcesso) {
        throw new Error(
          'Página não encontrada, verifique se você copiou todo o caminho.'
        )
      }
      const dados = await Api.RequisitarDadosAlteracao(id, chaveAcesso)
      definirDadosUsuario(dados)
      trataSituacaoAlteracao(dados.situacao)
    } catch (error) {
      definirErro({
        mensagens: 'Algo deu errado!'
      })
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucesso = async ({ codigo }: { codigo: string }): Promise<void> => {
    try {
      definirCarregando(true)

      const retorno = await Api.RequisitarAlteracaoDados(
        idUsuario,
        codigo,
        chaveAcesso,
        token
      )

      if (!retorno || !retorno.sucesso) {
        if (retorno.tentativasRestantes > 0) {
          toast(
            `Token incorreto! Você tem mais ${retorno.tentativasRestantes} tentativa(s).`,
            { type: 'error' }
          )
        } else {
          definirFinalTentativas(true)
          modalAvisoRef?.current?.abrir(mensagemSemTentativas())
        }
      } else {
        modalAvisoRef?.current?.abrir(mensagemSucesso())
      }
    } finally {
      definirCarregando(false)
    }
  }

  const acaoModal = useMemo(
    () => (finalTentativas ? () => history.push('/') : () => sair(false)),
    [finalTentativas]
  )
  useEffect(() => {
    carregarSituacaoAlteracao()
    definirPronto(true)
  }, [])

  return (
    <LayoutAuth>
      {pronto && !erro ? (
        <Form>
          <h1>Confirmar Alteração de Dados</h1>
          <ResumoUsuario>
            <h2>
              Token enviado para o celular com o final:{' '}
              <span>{dadosUsuario?.celular}</span>
            </h2>
            <p>Nome: {dadosUsuario?.nome}</p>
          </ResumoUsuario>
          <FormUnform schema={schema} acaoSucesso={acaoSucesso}>
            <InputUnform
              type="text"
              id="ipt_codigo"
              label="Digite o Token recebido"
              name="codigo"
              autoComplete="off"
              maxWidthInput="100%"
              obrigatorio
            />
            <Botao
              type="submit"
              id="btn-enviar"
              texto="Confirmar alteração"
              carregando={carregando}
            />
          </FormUnform>
        </Form>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando ..." />
      ) : (
        <ErroContainerLayout />
      )}
      <ModalAvisoAlteracaoEmail
        ref={modalAvisoRef}
        acaoPrimaria={acaoModal}
        id="modal-alteracao-email"
        backdrop
      />
    </LayoutAuth>
  )
}

export const AlterarEmailToken: React.FC = props => {
  return (
    <ErroLayoutContainer.Provider>
      <PaginaAlterarEmailToken {...props} />
    </ErroLayoutContainer.Provider>
  )
}
