import React from 'react'

export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4286 23.2632L7 16.8989L8.81286 15.1042L13.4286 19.661L23.1871 10L25 11.8075L13.4286 23.2632Z"
      fill="#0C992F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29.5C23.4558 29.5 29.5 23.4558 29.5 16C29.5 8.54416 23.4558 2.5 16 2.5C8.54416 2.5 2.5 8.54416 2.5 16C2.5 23.4558 8.54416 29.5 16 29.5ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#0C992F"
    />
  </svg>
)
