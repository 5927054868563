import { css } from 'styled-components'

export const Tipografia = css`
  body {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 30px;
    line-height: 35px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 140%;
    margin: 0;
    padding: 0;
  }

  a {
    font-family: 'Roboto', sans-serif;
    text-decoration-line: underline;
    font-size: 12px;
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  button {
    font-family: 'Roboto', sans-serif;
    line-height: 100%;
  }
`
