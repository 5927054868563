import React, { forwardRef } from 'react'

import { Container } from './styles'
import { RadioProps } from './tipos'

export const Radio: React.ForwardRefExoticComponent<RadioProps> = forwardRef<
  HTMLInputElement,
  RadioProps
>(
  (
    {
      id,
      texto,
      tamanho,
      readOnly,
      tema = 'Padrao',
      estilizarTexto,
      valorAlterado,
      ...rest
    },
    ref?: any
  ) => {
    return (
      <>
        <Container
          id={`radio_${id}`}
          className="radio-input"
          estilizarTexto={estilizarTexto}
          tema={tema}
          tamanho={tamanho}
          readOnly={readOnly}
        >
          <input
            {...rest}
            ref={ref}
            type="radio"
            id={id}
            onChange={e => {
              if (valorAlterado) valorAlterado(e.target.value)
            }}
          />
          <label htmlFor={id}>
            <span className="seleciona-radio">
              <span className="icone-radio"></span>
            </span>
          </label>
          {texto && <label htmlFor={id}>{texto}</label>}
        </Container>
      </>
    )
  }
)
