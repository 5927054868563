import { colunaCorreta } from './correta'
import { colunaDataTentativa } from './data-tentativa'
import { colunaDisciplinaNome } from './disciplina-nome'
import { colunaMarcou } from './marcou'
import { colunaModuloNome } from './modulo-nome'
import { colunaPergunta } from './pergunta'
import { colunaTentativasRealizadas } from './tentativas-realizadas'

export const COLUNAS_EXPORTACAO_PDF = [
  colunaModuloNome(),
  colunaDisciplinaNome(),
  colunaTentativasRealizadas(),
  colunaDataTentativa(),
  colunaPergunta(),
  colunaCorreta(),
  colunaMarcou()
]
