import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  nome: Yup.string().required(mensagemPadrao),
  sexo: Yup.string().required(mensagemPadrao),
  dataNascimento: Yup.string().required(mensagemPadrao),
  nomeSocial: Yup.string()
    .nullable()
    .when('possuiNomeSocial', {
      is: true,
      then: field => field.required(mensagemPadrao)
    }),
  corRaca: Yup.string().required(mensagemPadrao),
  estadoCivil: Yup.string().required(mensagemPadrao),
  escolaridade: Yup.string().required(mensagemPadrao),
  celular: Yup.string().telefone().required(mensagemPadrao),
  paisResidencia: Yup.string().required(mensagemPadrao),
  cep: Yup.string()
    .nullable()
    .when('paisResidencia', {
      is: 'Brasil',
      then: field => field.required(mensagemPadrao)
    }),
  logradouro: Yup.string().required(mensagemPadrao),
  numero: Yup.string().required(mensagemPadrao),
  bairro: Yup.string().required(mensagemPadrao),
  cidade: Yup.string().required(mensagemPadrao),
  uf: Yup.string().required(mensagemPadrao),
  lattes: Yup.string().url('Link inválido').nullable(),
  aptidoes: Yup.string().required(mensagemPadrao)
})
