import React, { useEffect, useRef } from 'react'

import { useField } from '@unform/core'

import { Radio } from '../../radio'
import { Componente, Erro } from './styles'
import { RadioInputUnformProps } from './tipos'

export const RadioInputUnform: React.FC<RadioInputUnformProps> = ({
  opcoes,
  id,
  name,
  disabled,
  valorAlterado,
  opcoesHorizontal
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField, defaultValue = [], error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find(ref => ref.checked)?.value ?? ''
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], value: string) => {
        refs.forEach(ref => {
          if (value === ref.value) {
            ref.checked = true
          }
        })
      }
    })
  }, [defaultValue, fieldName, registerField])

  return (
    <Componente
      id={id}
      theme={{ ComErro: !!error }}
      opcoesHorizontal={opcoesHorizontal}
    >
      {opcoes.map((item, index) => (
        <Radio
          key={item.id}
          id={item.id}
          value={item.valor}
          texto={item.texto}
          name={fieldName}
          defaultChecked={defaultValue === item.valor}
          ref={ref => {
            if (ref) {
              inputRefs.current[index] = ref as HTMLInputElement
            }
          }}
          valorAlterado={valorAlterado}
          disabled={disabled}
        />
      ))}
      <Erro id={`erro-${id}`}>{error}</Erro>
    </Componente>
  )
}
