import { mensagemPadrao } from 'src/dados-estaticos'
import { TipoPesquisaSatisfacaoPergunta } from 'src/tipos'
import * as Yup from 'yup'
export const schema = Yup.object().shape({
  texto: Yup.string().required(mensagemPadrao),
  tipo: Yup.string().required(mensagemPadrao),
  inicioEscalaLinear: Yup.number()
    .nullable()
    .when('tipo', {
      is: TipoPesquisaSatisfacaoPergunta.EscalaLinear,
      then: field => field.required(mensagemPadrao)
    }),
  finalEscalaLinear: Yup.number()
    .nullable()
    .when('tipo', {
      is: TipoPesquisaSatisfacaoPergunta.EscalaLinear,
      then: field =>
        field
          .required(mensagemPadrao)
          .moreThan(
            Yup.ref('inicioEscalaLinear'),
            'Final deve ser maior que início'
          )
    })
})
