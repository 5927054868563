import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  & > svg {
    margin-right: 4px;
  }

  & > a {
    width: 100%;
    line-height: 30px;
    font-size: 12px;
    font-weight: 400;
    color: ${Cores.SECUNDARIA_2_ESCURO};
  }
`
