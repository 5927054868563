import { SELECT_NACIONALIDADES } from 'src/dados-estaticos'
import {
  Endereco,
  Escolaridade,
  EstadoCivil,
  PaisResidencia,
  Raca,
  Sexo,
  Usuario
} from 'src/tipos'

import { TipoDadosDoFormulario } from './tipos'

export const normalizacaoDeDadosParaFormulario = (
  usuario: Usuario,
  callback: (form: TipoDadosDoFormulario) => TipoDadosDoFormulario
): TipoDadosDoFormulario => {
  const formulario = {
    foto: usuario.foto,
    nacionalidade: usuario.nacionalidade || SELECT_NACIONALIDADES[0].id,
    cpf: usuario.cpf,
    rg: usuario.documentoIdentidade,
    orgao: usuario.orgaoEmissor,
    nome: usuario.nome,
    nomeSocial: usuario.nomeSocial,
    dataNascimento: usuario.dataNascimento,
    raca: usuario.corRaca || Raca.NaoInformado,
    estadoCivil: usuario.estadoCivil || EstadoCivil[EstadoCivil.NaoInformado],
    profissao: usuario.profissao,
    escolaridade: usuario.escolaridade || Escolaridade.EnsinoMedio,
    email: usuario.email,
    celular: usuario.celular,
    telefone: usuario.telefone ?? '',
    paisResidencia:
      !usuario.endereco || !usuario.endereco.pais
        ? PaisResidencia.Brasil
        : usuario.endereco.pais,
    cep: usuario.endereco?.cep,
    logradouro: usuario.endereco?.logradouro,
    numero: usuario.endereco?.numero,
    complemento: usuario.endereco?.complemento,
    bairro: usuario.endereco?.bairro,
    cidade: usuario.endereco?.cidade,
    uf: usuario.endereco?.uf,
    sexo: usuario.sexo || Sexo[Sexo.NaoInformado],
    generoOutros: usuario.generoOutros,
    tipoUsuario: usuario.tipoUsuario,
    cnpj: usuario.cnpj,
    inscricaoEstadual: usuario.inscricaoEstadual,
    inscricaoMunicipal: usuario.inscricaoMunicipal
  }

  return callback(formulario)
}

export function formularioDeEdicaoMapper(
  formulario: TipoDadosDoFormulario
): Usuario {
  if (formulario === null) {
    return {} as Usuario
  }

  return ({
    cpf: formulario.cpf,
    cnpj: formulario.cnpj,
    inscricaoEstadual: formulario.inscricaoEstadual,
    inscricaoMunicipal: formulario.inscricaoMunicipal,
    documentoIdentidade: formulario.rg,
    nacionalidade: formulario.nacionalidade,
    orgaoEmissor: formulario.orgao,
    nome: formulario.nome,
    nomeSocial: formulario.nomeSocial,
    dataNascimento: formulario.dataNascimento,
    sexo: formulario.sexo,
    generoOutros: formulario.generoOutros,
    estadoCivil: formulario.estadoCivil,
    profissao: formulario.profissao,
    escolaridade: formulario.escolaridade,
    telefone: formulario.telefone,
    celular: formulario.celular,
    email: formulario.email,
    corRaca: formulario.raca,
    foto: formulario.foto,
    endereco: {
      pais: formulario.paisResidencia,
      cep: formulario.cep,
      logradouro: formulario.logradouro,
      numero: formulario.numero,
      complemento: formulario.complemento,
      bairro: formulario.bairro,
      cidade: formulario.cidade,
      uf: formulario.uf
    } as Endereco
  } as unknown) as Usuario
}
