import { ButtonHTMLAttributes, LabelHTMLAttributes } from 'react'

import { Cores } from 'src/componentes/styles/cores'
import { SituacaoUpload } from 'src/tipos/resumo-de-documentos'
import styled from 'styled-components'

export type BotaoDeAcaoProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  status: SituacaoUpload
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Cores.CINZA_MERCURY};
  padding: 10px 0;

  @media (max-width: 425px) {
    flex-direction: column;
    border-bottom: none;
    gap: 12px;

    :nth-child(odd) {
      background-color: ${Cores.CINZA_3_MAIS_CLARO};
    }
  }
`

export const NomeDoDocumento = styled.div<{ desabilitado?: boolean }>`
  width: 300px;
  font-style: normal;
  font-weight: 300;
  color: black;
  cursor: ${({ desabilitado }) => (desabilitado ? 'default' : 'pointer')};

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }

  @media (max-width: 425px) {
    text-align: center;
  }
`

export const StatusDoDocumento = styled.div`
  display: flex;
  flex-direction: column;
  align-items: inherit;
  width: 200px;

  p {
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }
`

export const BotaoDeAcao = styled.button<BotaoDeAcaoProps>`
  background: transparent;
  border: none;
  opacity: 1;
  cursor: pointer;
  pointer-events: initial;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
  }
`

export const OpcoesDeAcao = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;

  ${BotaoDeAcao} + ${BotaoDeAcao} {
    margin-left: 6px;
  }
`

export const ContainerDoAnexo = styled.label<
  LabelHTMLAttributes<HTMLLabelElement>
>`
  display: flex;
  justify-content: flex-start;
  width: 300px;
  position: relative;
  cursor: pointer;

  & > input[type='file'] {
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }

  @media (max-width: 425px) {
    display: flex;
    justify-content: center;
  }
`

export const IconeDoAnexo = styled.div`
  margin-right: 8px;
`

export const TipoDoAnexo = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${Cores.SECUNDARIA_2_ESCURO};
  margin-right: 8px;
`

export const ContainerColacaoGrau = styled.div`
  width: 150px;
  margin-left: 10px;

  label {
    display: flex;
  }
`
