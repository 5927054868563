import * as Yup from 'yup'

import { mensagemPadrao } from '../../../../../../dados-estaticos'

export const createSchema = (
  nomesGradesExistentes: string[]
): Yup.ObjectSchema =>
  Yup.object().shape({
    nome: Yup.string()
      .required(mensagemPadrao)
      .notOneOf(
        nomesGradesExistentes,
        'Já existe um módulo com este nome nesta grade curricular'
      ),
    informacoesAdicionais: Yup.string().when('tipoModulo', {
      is: tipoModulo => tipoModulo === 'Flex',
      then: field => field.required(mensagemPadrao)
    })
  })
