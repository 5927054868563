import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4284 12.0002C18.0339 12.0002 17.7141 12.32 17.7141 12.7145V19.8574C17.7141 20.2519 17.3943 20.5717 16.9998 20.5717H4.14263C3.74812 20.5717 3.42832 20.2519 3.42832 19.8574V5.57158C3.42832 5.17708 3.74812 4.85728 4.14263 4.85728H12.7141C13.1086 4.85728 13.4284 4.53748 13.4284 4.14297C13.4284 3.74847 13.1086 3.42871 12.7141 3.42871H4.14263C2.95915 3.42871 1.99976 4.38811 1.99976 5.57158V19.8574C1.99976 21.0409 2.95915 22.0002 4.14263 22.0002H16.9999C18.1833 22.0002 19.1427 21.0409 19.1427 19.8574V12.7145C19.1427 12.32 18.8229 12.0002 18.4284 12.0002Z"
      fill="#666666"
      fillOpacity="0.8"
    />
    <path
      d="M21.1995 2.80099C20.6866 2.28804 19.991 1.99993 19.2656 2.00001C18.5399 1.99792 17.8435 2.28654 17.3321 2.80145L7.9234 12.2093C7.84534 12.288 7.78646 12.3836 7.75126 12.4886L6.32269 16.7744C6.19801 17.1486 6.40037 17.5531 6.77466 17.6777C6.84728 17.7019 6.92332 17.7143 6.99983 17.7144C7.07651 17.7142 7.15272 17.7019 7.22554 17.678L11.5113 16.2494C11.6165 16.2142 11.7122 16.1551 11.7906 16.0765L21.1992 6.66791C22.2671 5.60016 22.2672 3.86887 21.1995 2.80099ZM20.1892 5.65859L10.9034 14.9444L8.12911 15.8708L9.05268 13.1L18.342 3.81429C18.8527 3.30461 19.6799 3.30545 20.1896 3.81614C20.4331 4.0601 20.5703 4.39036 20.5713 4.73502C20.5722 5.08152 20.4346 5.414 20.1892 5.65859Z"
      fill="#666666"
      fillOpacity="0.8"
    />
  </svg>
)
