import React from 'react'

export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29.5C23.4558 29.5 29.5 23.4558 29.5 16C29.5 8.54416 23.4558 2.5 16 2.5C8.54416 2.5 2.5 8.54416 2.5 16C2.5 23.4558 8.54416 29.5 16 29.5ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#E6B617"
    />
    <path
      d="M18.1888 6.552L17.7088 18.048H14.2288L13.7488 6.552H18.1888ZM16.0288 24.192C15.3088 24.192 14.7168 23.984 14.2528 23.568C13.8048 23.136 13.5808 22.608 13.5808 21.984C13.5808 21.344 13.8048 20.808 14.2528 20.376C14.7168 19.944 15.3088 19.728 16.0288 19.728C16.7328 19.728 17.3088 19.944 17.7568 20.376C18.2208 20.808 18.4528 21.344 18.4528 21.984C18.4528 22.608 18.2208 23.136 17.7568 23.568C17.3088 23.984 16.7328 24.192 16.0288 24.192Z"
      fill="#E6B617"
    />
  </svg>
)
