import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  Botao,
  BotaoStep,
  FuncoesMoeda,
  Accordion,
  WizardContainer
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { DadosReprovacaoReingresso } from 'src/servicos/api/tipos'

import ReingressoContainer from '../container'
import { ModalReingressoInadimplente } from '../modal-inadimplente'
import { ModalReingressoInadimplenteRef } from '../modal-inadimplente/tipos'
import { ContainerAcoes } from '../style'
import {
  ColunaDisciplinas,
  ContainerConteudo,
  ContainerInfos,
  ContainerInfosMenor,
  ContainerInfosValores,
  Espacador,
  Info
} from './styles'
export const InformacoesReingresso: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const {
    definirPasso,
    dados,
    definirMatriculaDestino
  } = ReingressoContainer.useContainer()
  const [carregando, definirCarregando] = useState(false)
  const [carregandoVoltar, definirCarregandoVoltar] = useState(false)
  const modalInadimplenteRef = useRef<ModalReingressoInadimplenteRef>(null)
  const history = useHistory()

  const { mudarEtapa, voltarWizard } = WizardContainer.useContainer()

  const [
    dadosIniciais,
    definirDadosIniciais
  ] = useState<DadosReprovacaoReingresso>()

  const obterDados = useCallback(async () => {
    try {
      const dados = await Api.RequisitarInformacoesReprovacaoReingresso(id)
      definirDadosIniciais(dados)
    } catch (error) {}
  }, [Api.RequisitarInformacoesReprovacaoReingresso])

  useEffect(() => {
    obterDados()
  }, [obterDados])

  const avancarPlanoPagamento = async () => {
    try {
      definirCarregando(true)
      const dados = await Api.AvancarFinanceiroReingresso(id)

      if (!dados.matriculaDestino && dados.protocolo) {
        modalInadimplenteRef?.current?.abrir(dados.protocolo)
        return
      }

      definirMatriculaDestino(dados.matriculaDestino)
      mudarEtapa(3)
      definirPasso(3)
    } finally {
      definirCarregando(false)
    }
  }

  const rotaFinanceiro = useMemo(() => {
    return `${RotasAluno.FinanceiroCursoOutro}/${dadosIniciais?.matriculaOrigemId}/${dados?.matriculaOrigem?.modalidade}/${dados?.matriculaOrigem?.nomeCurso}`
  }, [dadosIniciais, dados.matriculaOrigem])

  return (
    <ContainerConteudo>
      <h2>Informações</h2>
      <ContainerInfos>
        <h3>Curso de origem</h3>
        <h4>• {dadosIniciais?.nomeCursoOrigem}</h4>
      </ContainerInfos>
      <Espacador />
      {dadosIniciais?.modulosAproveitados.length > 0 && (
        <>
          <Accordion
            titulo={
              dadosIniciais?.modulosAproveitados[0]?.disciplinas?.length === 0
                ? 'Módulos aproveitados'
                : 'Disciplinas aproveitadas'
            }
          >
            <ContainerInfos>
              <ColunaDisciplinas>
                {dadosIniciais?.modulosAproveitados.map((modulo, index) => (
                  <>
                    <li key={index}>
                      <ul>
                        <p className={'titulo-modulo'}>Módulo: {modulo.nome}</p>
                      </ul>
                    </li>
                    {modulo.disciplinas.map((disciplina, index) => (
                      <li key={index}>
                        <ul>
                          <p>• {disciplina}</p>
                        </ul>
                      </li>
                    ))}
                  </>
                ))}
              </ColunaDisciplinas>
            </ContainerInfos>
          </Accordion>
          <Espacador />
        </>
      )}
      {dadosIniciais?.modulosACursar.length > 0 && (
        <>
          <Accordion
            titulo={
              dadosIniciais?.modulosACursar[0]?.disciplinas?.length === 0
                ? 'Módulos a cursar'
                : 'Disciplinas a cursar'
            }
          >
            <ContainerInfos>
              <ColunaDisciplinas>
                {dadosIniciais?.modulosACursar.map((modulo, index) => (
                  <>
                    <li key={index}>
                      <ul>
                        <p className={'titulo-modulo'}>Módulo: {modulo.nome}</p>
                      </ul>
                    </li>
                    {modulo.disciplinas.map((disciplina, index) => (
                      <li key={index}>
                        <ul>
                          <p>• {disciplina}</p>
                        </ul>
                      </li>
                    ))}
                  </>
                ))}
              </ColunaDisciplinas>
            </ContainerInfos>
          </Accordion>
          <Espacador />
        </>
      )}

      <ContainerInfos>
        <h3>Situação financeira na matrícula de origem</h3>
        <ContainerInfosMenor>
          <Botao
            texto="Veja os detalhes do parcelamento aqui"
            tema="Link"
            tamanho="L"
            onClick={() => window.open(rotaFinanceiro, '_blank')}
          />
          <ContainerInfosValores>
            <Info>
              <p>Valor a vencer - Turma de Origem: </p>
              <p>
                {FuncoesMoeda.formatarMoeda(dadosIniciais?.valorTotalPendente)}
              </p>
            </Info>
            <Info>
              <p>Valor do Reingresso: </p>
              <p>
                {FuncoesMoeda.formatarMoeda(dadosIniciais?.valorReingresso)}
              </p>
            </Info>
            <Info>
              <p>Valor Total a pagar: </p>
              <p>
                {FuncoesMoeda.formatarMoeda(dadosIniciais?.valorTotalAPagar)}
              </p>
            </Info>
          </ContainerInfosValores>
        </ContainerInfosMenor>
      </ContainerInfos>
      <Espacador />
      <ContainerInfos>
        <h3>Opções de pagamento</h3>
        <h4>
          Boleto em até 01x com vencimento para 30 dias e Cartão de Crédito
          Recorrente em até 24x, sendo que a parcela mínima é de R$ 300,00.
        </h4>
      </ContainerInfos>
      <ContainerAcoes>
        <BotaoStep
          invertido
          texto="Voltar para"
          textoNegrito="Regulamento do reingresso"
          tema="Secundario"
          type="button"
          disabled={carregandoVoltar || !!dados?.matriculaDestino}
          onClick={() => {
            definirCarregandoVoltar(true)
            voltarWizard()
          }}
        />
        <BotaoStep
          texto="Ir para"
          textoNegrito="Plano de pagamento"
          carregando={carregando}
          type="submit"
          onClick={avancarPlanoPagamento}
        />
      </ContainerAcoes>
      <ModalReingressoInadimplente
        ref={modalInadimplenteRef}
        backdrop
        acaoPrimaria={() => {
          history.push(rotaFinanceiro)
        }}
      />
    </ContainerConteudo>
  )
}
