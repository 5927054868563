import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { date } from '@storybook/addon-knobs'
import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform,
  DataInputUnform
} from 'src/componentes'
import { SelectOpcaoCursos } from 'src/dto'
import { Api } from 'src/servicos'
import {
  RequisitarListaCursosPorTextoFiltro,
  RequisitarListaTurmasPorTexto
} from 'src/servicos/api'
import {
  AlunoSituacao,
  StatusFinanceiro,
  StatusFinanceiroPorExtenso
} from 'src/tipos'

import { Form, Container, Campo, Dados, ContainerExportar } from './styles'
import { DadosPesquisa } from './tipos'

export const PaginaRelatorioComercial: React.FC = () => {
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [carregando, definirCarregando] = useState(false)
  const [paginas, definirPaginas] = useState<number>(null)

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }
  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDowloandRelatorioComercial({
        nomeAluno: dadosPesquisa?.nomeAluno,
        email: dadosPesquisa?.email,
        curso: dadosPesquisa?.curso,
        UF: dadosPesquisa?.UF,
        profissao: dadosPesquisa?.profissao,
        DataInicio: dadosPesquisa?.inicioMatricula,
        DataFim: dadosPesquisa?.fimMatricula,
        situacoesFinanceiras: dadosPesquisa?.situacoesFinanceiras
      })
      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }
  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Relatorio Comercial"
          atalhos={[
            {
              texto: 'Relatórios Gerais'
            },
            {
              texto: 'Relatorio Comercial'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: AlunoSituacao[AlunoSituacao.Todas]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <InputUnform
                type="text"
                name="email"
                label="Email"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <InputUnform type="text" name="UF" label="UF" maxLength={200} />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                type="text"
                name="inicioMatricula"
                label="Data Inicio Matricula"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                type="text"
                name="fimMatricula"
                label="Data Fim Matricula"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <InputUnform
                type="text"
                name="profissao"
                label="profissao"
                maxLength={200}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <SelectUnform
                id="slct_status_financeiro"
                name="situacoesFinanceiras"
                label="Status Financeiro"
                iconeFechar
                multiplo
                margemInferior={false}
                opcoes={[
                  {
                    id: StatusFinanceiro[StatusFinanceiro.Adimplente],
                    texto:
                      StatusFinanceiroPorExtenso[StatusFinanceiro.Adimplente]
                  },
                  {
                    id: StatusFinanceiro[StatusFinanceiro.Quitado],
                    texto: StatusFinanceiroPorExtenso[StatusFinanceiro.Quitado]
                  },
                  {
                    id: StatusFinanceiro[StatusFinanceiro.Inadimplente],
                    texto:
                      StatusFinanceiroPorExtenso[StatusFinanceiro.Inadimplente]
                  }
                ]}
              />
            </Campo>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                buscarPorTexto={RequisitarListaCursosPorTextoFiltro}
                buscarPorId={RequisitarListaTurmasPorTexto}
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaRelatorioComercial />
  </ErroLayoutContainer.Provider>
)
