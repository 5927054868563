import React from 'react'

import { Accordion, formatarCpf, formatarTelefone } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import {
  EscolaridadePorExtenso,
  EstadoCivilPorExtenso,
  FormacaoPorExtenso,
  NacionalidadePorExtenso,
  Raca,
  SexoPorExtenso
} from 'src/tipos'

import { ColunaInformacoes, ContainerInformacoes } from './styles'
import { AccordionInformacoesAlunoProps } from './tipos'

export const AccordionInformacoesAluno: React.FC<AccordionInformacoesAlunoProps> = ({
  aluno,
  ehEstrangeiro
}) => {
  return (
    <Accordion titulo="Informações do Aluno" iniciarAberto={false}>
      <ContainerInformacoes>
        <ColunaInformacoes>
          <p>Nome: {aluno.nome}</p>
          {aluno.nomeSocial && <p>Nome Social: {aluno.nomeSocial}</p>}
          <p>Nacionalidade: {NacionalidadePorExtenso[aluno.nacionalidade]}</p>
          {!ehEstrangeiro && <p>CPF: {formatarCpf(aluno.cpf)}</p>}
          <p>Documento de Identidade: {aluno.documentoIdentidade}</p>
          <p>Órgão Emissor: {aluno.orgaoEmissor ?? 'Não informado'}</p>
          <p>Código do Conselho: {aluno.codigoConselho ?? 'Não informado'}</p>
          <p>
            Data de Nascimento:{' '}
            {formatadores.dataLocaleString(aluno.dataNascimento?.toString())}
          </p>
          <p>
            Gênero:{' '}
            {SexoPorExtenso[aluno.sexo] === SexoPorExtenso.Outros
              ? aluno.generoOutros
              : SexoPorExtenso[aluno.sexo]}
          </p>
          <p>Estado Civil: {EstadoCivilPorExtenso[aluno.estadoCivil]}</p>
          <p>Cor/Raça: {Raca[aluno.corRaca]}</p>
          <p>
            Profissão:{' '}
            {aluno.profissao === FormacaoPorExtenso.Outra
              ? `${aluno.profissao} - ${aluno.profissaoOutros}`
              : aluno.profissao}
          </p>
          <p>Escolaridade: {EscolaridadePorExtenso[aluno.escolaridade]}</p>
          <p>
            Data da Colação de Grau:{' '}
            {aluno.mesAnoColacaoGrau
              ? formatadores.dataLocaleString(
                  aluno.mesAnoColacaoGrau?.toString()
                )
              : 'Não informado'}
          </p>
          <p>
            Data do Último Acesso:{' '}
            {formatadores.dataHoraLocaleString(
              aluno.dataUltimoAcesso ?? 'Não houve acesso'
            )}
          </p>
        </ColunaInformacoes>
        <ColunaInformacoes>
          <div>
            <p>E-mail: {aluno.email}</p>
            <p>Celular: {aluno.celular && formatarTelefone(aluno.celular)}</p>
            <p>
              Telefone:{' '}
              {aluno.telefone
                ? formatarTelefone(aluno.telefone)
                : 'Não informado'}
            </p>
          </div>
          <div>
            {aluno.endereco && (
              <ColunaInformacoes>
                <p>País de Residência: {aluno.endereco.pais}</p>
                {!ehEstrangeiro && <p>CEP: {aluno.endereco.cep}</p>}
                <p>Logradouro: {aluno.endereco.logradouro}</p>
                <p>Número: {aluno.endereco.numero}</p>
                <p>
                  Complemento: {aluno.endereco.complemento ?? 'Não informado'}
                </p>
                <p>Bairro: {aluno.endereco.bairro}</p>
                <p>Cidade: {aluno.endereco.cidade}</p>
                <p>UF: {aluno.endereco.uf}</p>
              </ColunaInformacoes>
            )}
          </div>
        </ColunaInformacoes>
      </ContainerInformacoes>
    </Accordion>
  )
}
