import { ModalCorpo as Corpo } from 'src/paginas/ipgs/turmas/styles'
import styled from 'styled-components'

export const ModalCorpo = styled(Corpo)`
  min-height: unset;
  width: 500px;
`

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
`
