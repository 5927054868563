/* eslint-disable indent */
import React, { useRef, useState, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  FuncoesDataHora,
  ModalRef,
  StepperContainer,
  Tooltip
} from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import { InfoTCC } from 'src/servicos/api/sala-aula/tipos'
import { Aluno, SalaAulaOutrosAceiteTCC, OpcaoTCC } from 'src/tipos'

import { calcularDistanciaData } from '../../shared/funcoes/calcularDatas'
import SalaAulaOutrosContainer from '../container'
import { ModalConfirmacaoNaoTCC } from './modal-confirmacao-nao-tcc'
import { ModalOpteTCC } from './modal-opte-tcc'
import {
  Container,
  CabecalhoListagem,
  Titulo,
  ContainerTela,
  Botoes
} from './styles'
import { OpteTCCProps } from './tipos'

export const OpteTCCComponente: React.FC<OpteTCCProps> = ({
  salaAulaId,
  opcaoTCC,
  dataLiberacaoTCC,
  dataBloqueioTCC,
  idMatricula,
  podeSolicitarTCC,
  ehReingresso,
  dataCriacaoSalaDeAula,
  dataFimSalaDeAula
}) => {
  const modalRef = useRef<ModalOpteTCC>(null)
  const modalRefConfirmacaoNaoTCC = useRef<ModalRef>(null)
  const [infoTCC, definirInfoTCC] = useState<InfoTCC>(null)
  const { voltarPrimeiroItemStepper } = StepperContainer.useContainer()
  const { atualizarRecarregarSala } = SalaAulaOutrosContainer.useContainer()
  const [aluno, definirAluno] = useState<Aluno>(null)
  const usuarioNaoDecidiuTCC = useMemo(() => {
    const opcao = OpcaoTCC[opcaoTCC]
    return (
      opcao !== OpcaoTCC[OpcaoTCC.Nao] &&
      opcao !== OpcaoTCC[OpcaoTCC.Sim] &&
      opcao !== OpcaoTCC[OpcaoTCC.NaoAutomatico] &&
      opcao !== OpcaoTCC[OpcaoTCC.Cancelamento] &&
      opcao !== OpcaoTCC[OpcaoTCC.Dispensa]
    )
  }, [opcaoTCC])

  const carregarAluno = async () => {
    try {
      const novoAluno = await Api.ObterAluno()
      definirAluno(novoAluno)
    } catch (error) {
      toast('Houve um problema ao obter os dados do aluno', {
        type: 'error'
      })
    }
  }

  const carregarInformacaoTCC = async () => {
    try {
      const informacoes = await Api.SalaAulaOutros.RequisitarInformacoesTCC(
        salaAulaId
      )

      definirInfoTCC(informacoes)
    } catch (erro) {
      toast('Erro ao carregar informações dos valores do TCC')
    }
  }

  const enviarAceiteTCC = async (
    dados: SalaAulaOutrosAceiteTCC
  ): Promise<void> => {
    try {
      const resultado = await Api.SalaAulaOutros.EnviarAceiteTCC(
        salaAulaId,
        dados.quantidadeParcelasTCC,
        dados.orientadorTCC,
        dados.coorientadorTCC,
        dados.diaVencimento,
        dados.opcaoTCC,
        dados.cupomId,
        dados.metodoPagamento,
        dados.dadosCartao
      )

      if (resultado) {
        atualizarRecarregarSala(true)
        voltarPrimeiroItemStepper()
      } else {
        toast('Erro ao enviar informações TCC, tente novamente mais tarde.', {
          type: 'error'
        })
      }
    } catch {
      toast('Erro ao enviar informações TCC, tente novamente mais tarde.', {
        type: 'error'
      })
    }
  }

  const enviarRecusaTCC = async (opcaoTCC: OpcaoTCC): Promise<void> => {
    try {
      await Api.SalaAulaOutros.EnviarRecusaTCC(salaAulaId, opcaoTCC)
      if (opcaoTCC === OpcaoTCC.Nao) {
        atualizarRecarregarSala(true)
        voltarPrimeiroItemStepper()
      }
    } catch {
      toast('Erro ao enviar informações TCC, tente novamente mais tarde.', {
        type: 'error'
      })
    }
  }

  useEffect(() => {
    carregarInformacaoTCC()
  }, [])

  useEffect(() => {
    if (opcaoTCC === null) {
      enviarRecusaTCC(OpcaoTCC.Depois)
    }
  }, [])

  useEffect(() => {
    carregarAluno()
  }, [])

  return (
    <>
      <Container>
        <CabecalhoListagem>
          {usuarioNaoDecidiuTCC ? (
            <>
              <Titulo>
                <h3>Trabalho de Conclusão de Curso</h3>
                <p>
                  A partir da resolução CNE/CES 01 de 06/04/2018, o Ministério
                  da Educação (MEC) definiu novas normas e diretrizes sobre o
                  desenvolvimento do Trabalho de Conclusão de Curso. A
                  realização do TCC passa a ser um componente curricular não
                  obrigatório em cursos de Pós-Graduação <i>Lato Sensu</i>. De
                  acordo com as novas regras, no iPGS você decide se opta por
                  realizar o trabalho ou não.
                </p>
                <p>
                  Aqui vão algumas informações para te ajudar a tomar esta
                  decisão importante:
                </p>
                <p>
                  O trabalho será um artigo de revisão simples e o seu
                  orientador será escolhido pelo iPGS. O contato com seu
                  orientador será exclusivamente através da plataforma
                  Education. Não será necessária apresentação de forma
                  presencial ou online de seu trabalho, solicitamos apenas o
                  envio da versão escrita para correção.
                </p>
                <p>
                  Há uma taxa administrativa no valor de{' '}
                  {formatadores.moeda(infoTCC?.precoBaseTCC)} que poderá ser
                  parcelada em até {infoTCC?.quantidadeParcela ?? 1}x. O
                  pagamento ocorre à parte e não está incluso em seu plano de
                  pagamento atual. Um novo contrato financeiro será lançado em
                  seu portal do aluno ao início da orientação.
                </p>
                <p>
                  Salientamos que mesmo que opte pela não realização do
                  trabalho, seu certificado terá a mesma validade. Alunos que
                  desejam prestar concurso público ou ingressar em programas de
                  mestrado devem observar o Edital pois, em alguns casos,
                  pode-se requerer o trabalho para validação de título.
                </p>
                <p>
                  O período de orientação iniciará 4 meses antes da finalização
                  do seu curso e você poderá optar pela realização, ou não, do
                  trabalho entre os dias{' '}
                  {formatadores.dataLocaleString(dataLiberacaoTCC)} e{' '}
                  {formatadores.dataLocaleString(dataBloqueioTCC)}, diretamente
                  nesta tela.
                </p>
                <p>
                  Em caso de dúvidas adicionais, nosso time está disponível para
                  te ajudar através do e-mail academico@ipgs.com.br
                </p>
                <p>Bons estudos!</p>
              </Titulo>
              {OpcaoTCC[opcaoTCC] !== OpcaoTCC[OpcaoTCC.Aproveitamento] ? (
                <Botoes>
                  <Botao
                    tema="Link"
                    texto="Definir depois"
                    onClick={() => voltarPrimeiroItemStepper()}
                  />
                  <div
                    data-tip={`A opção de TCC será liberada na data de ${FuncoesDataHora.dataToLocaleString(
                      dataLiberacaoTCC
                    )}`}
                    data-for="liberacao-tcc"
                  >
                    <Botao
                      tema="Secundario"
                      texto="Não"
                      disabled={!podeSolicitarTCC}
                      onClick={() =>
                        modalRefConfirmacaoNaoTCC?.current?.abrir()
                      }
                    />
                    <Botao
                      tema="Padrao"
                      texto="Sim"
                      disabled={!podeSolicitarTCC}
                      onClick={() =>
                        modalRef.current?.abrir(
                          infoTCC?.precoBaseTCC ?? 0,
                          infoTCC?.quantidadeParcela ?? 0,
                          salaAulaId,
                          idMatricula
                        )
                      }
                      data-for="visualizar-contrato"
                      data-tip="Visualizar contrato"
                    />
                  </div>
                  {!podeSolicitarTCC && (
                    <Tooltip
                      id="liberacao-tcc"
                      place="top"
                      textColor={'#000'}
                      backgroundColor={'#DDDDDD'}
                      html
                    />
                  )}
                </Botoes>
              ) : (
                <p className="informe-alteracao">
                  ALUNO REALIZOU APROVEITAMENTO DA DISCIPLINA TCC.
                </p>
              )}
            </>
          ) : (
            <></>
          )}
          {OpcaoTCC[opcaoTCC] === OpcaoTCC[OpcaoTCC.Nao] && (
            <Titulo>
              <h3>Trabalho de Conclusão de Curso</h3>
              <p>
                {aluno?.nome}, registramos sua opção por não realizar o TCC em{' '}
                {formatadores.dataLocaleString(infoTCC?.dataHoraOpcaoTCC)}.
              </p>
              <p>
                Estamos na reta final de seu curso e sugerimos que aproveite
                este momento livre para revisar conteúdos ou finalizar
                atividades pendentes.
              </p>
              <p>Bons estudos!</p>
            </Titulo>
          )}
          {(OpcaoTCC[opcaoTCC] === OpcaoTCC[OpcaoTCC.Sim] ||
            OpcaoTCC[opcaoTCC] === OpcaoTCC[OpcaoTCC.Dispensa]) && (
            <Titulo>
              <h3>Trabalho de Conclusão de Curso</h3>
              {ehReingresso ? (
                <p>
                  {aluno?.nome}, seguem orientações gerais para a realização do
                  seu trabalho de conclusão de curso;
                </p>
              ) : (
                <p>
                  {aluno?.nome}, registramos sua opção por realizar o TCC em{' '}
                  {formatadores.dataLocaleString(infoTCC?.dataHoraOpcaoTCC)}.
                </p>
              )}

              <p>
                O trabalho será um artigo de revisão simples e o seu orientador
                será escolhido pelo iPGS. O contato com seu orientador será
                exclusivamente através da plataforma Education. Não será
                necessária apresentação de forma presencial ou online de seu
                trabalho, solicitamos apenas o envio da versão escrita para
                correção.
              </p>
              {calcularDistanciaData(dataBloqueioTCC) <= 0 && !ehReingresso && (
                <p>
                  Estamos preparando este ambiente com os melhores conteúdos
                  para te ajudar no desenvolvimento e realização de seu
                  trabalho.
                </p>
              )}
              <p>
                A partir de{' '}
                {ehReingresso
                  ? formatadores.dataLocaleString(dataCriacaoSalaDeAula)
                  : formatadores.dataLocaleString(
                      infoTCC?.dataHoraInicioOrientacaoTCC
                    )}{' '}
                você poderá acessar as videoaulas da disciplina e o chat com o
                professor orientador.
              </p>
              <p>
                A versão final do Trabalho de Conclusão de Curso deverá ser
                enviada até o dia{' '}
                {ehReingresso
                  ? formatadores.dataLocaleString(dataFimSalaDeAula)
                  : formatadores.dataLocaleString(
                      infoTCC?.dataLimiteAvaliacao
                    )}{' '}
                pela avaliação da disciplina do TCC.
              </p>
              <p>Bons estudos!</p>
              {OpcaoTCC[opcaoTCC] === OpcaoTCC[OpcaoTCC.Dispensa] && (
                <p className="informe-alteracao">
                  ALUNO DISPENSADO DE REALIZAR DISCIPLINA TCC.
                </p>
              )}
            </Titulo>
          )}
          {OpcaoTCC[opcaoTCC] === OpcaoTCC[OpcaoTCC.NaoAutomatico] && (
            <Titulo>
              <h3>Trabalho de Conclusão de Curso</h3>
              <p>Olá, {aluno?.nome}.</p>
              <p>
                O período para optar pela realização do TCC ocorreu entre os
                dias {formatadores.dataLocaleString(dataLiberacaoTCC)} e{' '}
                {formatadores.dataLocaleString(dataBloqueioTCC)}.
              </p>
              <p>
                A orientação já iniciou e não é mais possível ingressar nesta
                disciplina. Sua dispensa foi realizada.
              </p>
              <p>
                Estamos na reta final de seu curso e sugerimos que aproveite
                este momento para revisar os conteúdos ou finalizar atividades
                que ainda estejam pendentes.
              </p>

              <p>Bons estudos!</p>
              <p>Abraços</p>
              <p>Equipe Acadêmica | Faculdade iPGS</p>
            </Titulo>
          )}
          {OpcaoTCC[opcaoTCC] === OpcaoTCC[OpcaoTCC.Cancelamento] && (
            <Titulo>
              <h3>Trabalho de Conclusão de Curso</h3>
              <p>
                {aluno?.nome}, registramos sua opção por realizar o TCC em{' '}
                {formatadores.dataLocaleString(infoTCC?.dataHoraOpcaoTCC)} e o
                cancelamento em{' '}
                {formatadores.dataLocaleString(infoTCC?.dataHoraAlteracaoTCC)}.
              </p>
              <p>
                Estamos na reta final de seu curso e sugerimos que aproveite
                este momento livre para revisar conteúdos ou finalizar
                atividades pendentes.
              </p>
              <p>Bons estudos!</p>
            </Titulo>
          )}
        </CabecalhoListagem>
      </Container>
      <ModalOpteTCC backdrop ref={modalRef} acaoSucesso={enviarAceiteTCC} />
      <ModalConfirmacaoNaoTCC
        backdrop
        ref={modalRefConfirmacaoNaoTCC}
        acaoSucesso={enviarRecusaTCC}
      />
    </>
  )
}

export const OpteTCC: React.FC<OpteTCCProps> = ({
  salaAulaId,
  opcaoTCC,
  dataLiberacaoTCC,
  dataBloqueioTCC,
  idMatricula,
  podeSolicitarTCC,
  ehReingresso,
  dataCriacaoSalaDeAula,
  dataFimSalaDeAula
}) => {
  return (
    <ContainerTela>
      <OpteTCCComponente
        salaAulaId={salaAulaId}
        opcaoTCC={opcaoTCC}
        dataLiberacaoTCC={dataLiberacaoTCC}
        dataBloqueioTCC={dataBloqueioTCC}
        idMatricula={idMatricula}
        podeSolicitarTCC={podeSolicitarTCC}
        ehReingresso={ehReingresso}
        dataCriacaoSalaDeAula={dataCriacaoSalaDeAula}
        dataFimSalaDeAula={dataFimSalaDeAula}
      />
    </ContainerTela>
  )
}
