export const inputTextoNaoInformado = 'Não informado'

export const diaSemanaAbreviado = [
  'dom',
  'seg',
  'ter',
  'qua',
  'qui',
  'sex',
  'sab'
]

export const diaSemanaPrimeiraLetra = ['d', 's', 't', 'q', 'q', 's', 's']

export const mesesDoAno = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro'
]

export const mesesDoAnoAbreviado = [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez'
]
