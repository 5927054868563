export enum DisciplinaSituacao {
  Ativo,
  Inativo,
  Todas
}

export enum TipoDisciplina {
  Curso = 'Curso',
  TCC = 'TCC'
}
