import queryString, { stringify } from 'query-string'
import { SelectOpcao } from 'src/componentes'
import { onlyNumbers } from 'src/componentes/utils'

import { PesquisaProfessoresResposta, ProfessorDto } from '../../dto'
import {
  Nacionalidade,
  OpcoesPaginacao,
  Professor,
  RespostaCriarProfessorAPI,
  RespostaPadraoDadosAPI,
  TurmasProfessorLogado,
  TipoOrdenacao,
  TipoOrdenacaoApi,
  TurmaProfessor,
  UsuarioPerfil,
  UsuarioSituacao,
  TurmaQueProfessorEhTutor,
  TurmaSituacao,
  PesquisaTurmaQueProfessorEhTutor,
  RespostaPadraoPaginacaoDadosAPI,
  TipoProfessorModuloTCC,
  ProfessorModuloTCC,
  ProfessoresTCCDisponiveisTroca,
  IntegracaoOMIE
} from '../../tipos'
import { gatewayApi } from '../instancias-api'
import { ObterListaProfessoresParams } from './tipos'

const urlBase = '/professor'

const mapEndereco = data => {
  const {
    paisResidencia,
    cep,
    logradouro,
    numero,
    complemento,
    bairro,
    cidade,
    uf
  } = data
  return {
    paisResidencia: paisResidencia,
    cep: cep && onlyNumbers(cep),
    logradouro,
    numero,
    complemento,
    bairro,
    cidade,
    uf
  }
}

const mapProfessor = data => {
  const { curriculo, lattes, aptidoes, situacao } = data
  return {
    curriculo,
    lattes,
    aptidoes,
    situacao
  }
}

const mapUsuario = data => {
  const {
    foto,
    cpf,
    documentoIdentidade,
    situacao,
    nome,
    sexo,
    generoOutros,
    dataNascimento,
    possuiNomeSocial,
    nomeSocial,
    corRaca,
    estadoCivil,
    escolaridade,
    profissao,
    telefone,
    celular,
    email
  } = data
  return {
    foto,
    cpf,
    documentoIdentidade,
    situacao,
    nome,
    sexo,
    generoOutros,
    dataNascimento,
    possuiNomeSocial,
    nomeSocial,
    corRaca,
    estadoCivil: !estadoCivil ? null : estadoCivil,
    escolaridade,
    profissao,
    nacionalidade: Nacionalidade[Nacionalidade.brasileira],
    telefone: telefone && onlyNumbers(telefone),
    celular: onlyNumbers(celular),
    email,
    perfis: [UsuarioPerfil.Professor]
  }
}

const mapDadosFinanceiros = data => {
  const {
    bancoId,
    agencia,
    conta,
    tipoConta,
    chavePix,
    valorHora,
    observacoes,
    titularConta,
    nomeTitular,
    cpfTitular
  } = data.dadosFinanceirosProfessor
  return {
    bancoId,
    agencia,
    conta,
    tipoConta,
    chavePix,
    valorHora,
    observacoes,
    titularConta: !titularConta,
    nomeTitular,
    cpfTitular
  }
}

const mapData = data => {
  return {
    endereco: mapEndereco(data),
    professor: mapProfessor(data),
    usuario: mapUsuario(data),
    dadosFinanceirosProfessor: mapDadosFinanceiros(data)
  }
}

export const InserirProfessor = async (
  payload: Professor
): Promise<RespostaCriarProfessorAPI> => {
  const {
    data: { success, data }
  } = await gatewayApi.post<RespostaCriarProfessorAPI>(
    urlBase,
    mapData(payload)
  )
  return { success, data }
}

export const EditarProfessor = async (
  id: string,
  payload: Professor
): Promise<{ integracao: IntegracaoOMIE; dados: string[] }> => {
  const payloadAlterado = {
    ...payload,
    nomeCompleto: payload.nome,
    pais: payload.paisResidencia,
    dadosFinanceirosProfessor: {
      ...payload.dadosFinanceirosProfessor,
      titularConta: !payload.dadosFinanceirosProfessor.titularConta
    }
  }

  delete payload.nome
  delete payload.paisResidencia

  const { data } = await gatewayApi.put<
    RespostaPadraoDadosAPI<{ integracao: IntegracaoOMIE; dados: string[] }>
  >(`${urlBase}/${id}`, payloadAlterado)

  return data.data
}

export const ObterProfessor = async (id: string): Promise<Professor> => {
  const {
    data: { data }
  } = await gatewayApi.get<RespostaPadraoDadosAPI<Professor>>(
    `${urlBase}/${id}`
  )

  return data
}

export const ObterListaProfessores = async ({
  ordem,
  situacao,
  ...filtrosParams
}: ObterListaProfessoresParams): Promise<{
  registros: ProfessorDto[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const filtros: Omit<ObterListaProfessoresParams, 'ordem' | 'situacao'> & {
      situacao?: UsuarioSituacao
      ordem?: string
    } = filtrosParams

    if (ordem) {
      let campoOrdenacao = filtros.chaveOrdenacao

      if (campoOrdenacao === 'nomeApresentacao') campoOrdenacao = 'nome'
      if (campoOrdenacao === 'endereco') campoOrdenacao = 'UfCidade'

      filtros.ordem = campoOrdenacao
        ? `${campoOrdenacao}${TipoOrdenacaoApi[TipoOrdenacao[ordem]]}`
        : undefined
    }

    if (situacao) {
      filtros.situacao =
        UsuarioSituacao[situacao] === UsuarioSituacao[UsuarioSituacao.Todas]
          ? null
          : situacao
    }

    const {
      data: {
        data: { itens, quantidade }
      }
    } = await gatewayApi.get<PesquisaProfessoresResposta>(
      `${urlBase}?${stringify(filtros, {
        skipNull: true,
        skipEmptyString: true
      })}`
    )

    const opcoesPaginacao = {
      pagina: filtros.pagina,
      tamanhoPagina: filtros.quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / filtros.quantidadeRegistros)
    }

    return { registros: itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterTodosProfessores = async (): Promise<Professor[]> => {
  try {
    const {
      data: { data }
    } = await gatewayApi.get<RespostaPadraoDadosAPI<Professor[]>>(
      `${urlBase}/todos`
    )

    return data
  } catch {
    return []
  }
}

export const ObterTurmasProfessor = async (): Promise<TurmaProfessor[]> => {
  const { data } = await gatewayApi.get<TurmasProfessorLogado>(
    `${urlBase}/turmas`
  )
  return data.turmas
}

export const ObterTurmasQueProfessorEhTutor = async (
  nome?: string,
  codigo?: string,
  uf?: string,
  cidade?: string,
  situacao?: keyof typeof TurmaSituacao,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: TurmaQueProfessorEhTutor[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      nome,
      codigo,
      uf,
      cidade,
      situacao:
        situacao === TurmaSituacao[TurmaSituacao.Todos] ? null : situacao,
      qtdRegistros,
      pagina,
      ordem
    })

    const {
      data: { itens, quantidade }
    } = await gatewayApi.get<PesquisaTurmaQueProfessorEhTutor>(
      `${urlBase}/turmas-professor-tutor?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / qtdRegistros)
    }

    return { registros: itens, opcoesPaginacao }
  } catch {
    return null
  }
}
export const ObterProfessoresNome = async (
  nome?: string
): Promise<Professor[]> => {
  try {
    const filtros = queryString.stringify({
      nome,
      qtdRegistros: 100
    })

    const { data } = await gatewayApi.get<
      RespostaPadraoPaginacaoDadosAPI<Professor>
    >(`${urlBase}?${filtros}`)

    return data.data.itens
  } catch {
    return []
  }
}

export const ObterProfessoresSalaAula = async (
  salaAulaId: string,
  tipo?: TipoProfessorModuloTCC
): Promise<ProfessorModuloTCC[]> => {
  try {
    const {
      data: { data }
    } = await gatewayApi.get<RespostaPadraoDadosAPI<ProfessorModuloTCC[]>>(
      `${urlBase}/salaAulaEad/${salaAulaId}?tipo=${tipo}`
    )

    return data
  } catch {
    return []
  }
}

export const RequisitarListaProfessoresTCC = async (
  turmaId: string,
  tipoProfessorTCC: TipoProfessorModuloTCC
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({
      TipoProfessor: tipoProfessorTCC
    })
    const {
      data: { professores }
    } = await gatewayApi.get<ProfessoresTCCDisponiveisTroca>(
      `${urlBase}/tcc/turma/${turmaId}?${filtros}`
    )

    return professores.map(response => ({
      id: response.id,
      texto: response.nome
    }))
  } catch {
    return null
  }
}

export const ObterProfessorPorIdUsuario = async (
  id: string
): Promise<Professor> => {
  const {
    data: { data }
  } = await gatewayApi.get<RespostaPadraoDadosAPI<Professor>>(
    `${urlBase}/meu-cadastro/${id}`
  )

  return data
}

export const EditarProfessorPorIdUsuario = async (
  id: string,
  payload: Professor
): Promise<IntegracaoOMIE> => {
  const payloadAlterado = {
    ...payload,
    nomeCompleto: payload.nome,
    pais: payload.paisResidencia,
    dadosFinanceirosProfessor: {
      ...payload.dadosFinanceirosProfessor,
      titularConta: !payload.dadosFinanceirosProfessor.titularConta
    }
  }

  delete payload.nome
  delete payload.paisResidencia

  const { data } = await gatewayApi.put<RespostaPadraoDadosAPI<IntegracaoOMIE>>(
    `${urlBase}/meu-cadastro/${id}`,
    payloadAlterado
  )

  return data.data
}
