import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'animate.css'

import WebFont from 'webfontloader'

import {
  AutenticacaoContainer,
  ProviderDaBarraSuperior,
  NotificacoesContainer
} from './componentes'
import { ResponsivoProvider, TemaProvider } from './componentes/contextos'
import { ControladorInstanciasApis } from './componentes/controlador-instancias-api'
import { Estilo } from './componentes/styles/estilo'
import { Rotas } from './rotas'

import 'react-toastify/dist/ReactToastify.css'

if (process.env.REACT_APP_GTM) {
  const tagManagerArgs = {
    gtmId: 'GTM-NRDM9FL'
  }
  TagManager.initialize(tagManagerArgs)
}

WebFont.load({
  google: {
    families: ['Roboto', 'Exo 2', 'sans-serif']
  }
})

ReactDOM.render(
  <StrictMode>
    <Estilo />
    <ResponsivoProvider>
      <TemaProvider>
        <Router>
          <ProviderDaBarraSuperior>
            <NotificacoesContainer.Provider>
              <AutenticacaoContainer.Provider>
                <ControladorInstanciasApis>
                  <Rotas />
                </ControladorInstanciasApis>
              </AutenticacaoContainer.Provider>
            </NotificacoesContainer.Provider>
          </ProviderDaBarraSuperior>
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
      </TemaProvider>
    </ResponsivoProvider>
  </StrictMode>,
  document.getElementById('root')
)
