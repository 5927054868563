import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaProrrogacaoMatricula = (): TabelaColuna => ({
  Chave: 'prorrogacaoMatricula',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ maxWidth: 200, minWidth: 100 }}>Prorrogação Matrícula</div>
  )
})
