import styled, { css } from 'styled-components'

import { Cores } from '../../styles'

export const Componente = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  &.semMargemAbaixo {
    margin-bottom: 0px;
  }

  ${({ theme: { ComErro } }) =>
    ComErro &&
    css`
      span {
        opacity: 1;
        bottom: -20px;
      }
    `}
`

export const CampoLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: left;
  line-height: 125%;
  margin-bottom: 6px;
  color: ${Cores.CINZA_2_ESCURO};

  display: flex;

  :first-letter {
    text-transform: uppercase;
  }

  .informacao-icone {
    margin-left: 5px;
  }
`

export const Erro = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  white-space: pre-line;
  opacity: 0;
  transition: opacity 0.1s ease-in, bottom 0.2s ease-in;
`
