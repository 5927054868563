import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { Botao } from '..'

import { Api } from 'src/servicos'

import { ModalBase } from '../modal-base'
import { OpcaoSelect } from '../select-async/tipos'
import { Spacer } from '../spacer'
import { FormUnform, SelectAsyncUnform } from '../unform'
import { schema } from './schema'
import {
  ContainerBotoes,
  FormContent,
  ContainerSelect,
  CorpoModal,
  LabelSelect,
  Obrigatorio
} from './styles'
import {
  ModalVincularProps,
  ModalVincularRef,
  OpcaoSelectDadosAdicionais
} from './tipos'

const ModalVincularDisciplina: React.ForwardRefRenderFunction<
  ModalVincularRef,
  ModalVincularProps
> = (
  { backdrop, acaoPrimaria, acaoSecundaria, titulo, label, modalidade, tipo },
  ref
) => {
  const [aberta, definirAberta] = useState(false)
  const [selecionado, definirSelecionado] = useState(null)
  const [pronto, definirPronto] = useState(false)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = () => {
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalVincularRef, ModalVincularRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = () => {
    acaoPrimaria(selecionado)
    fechar()
  }
  const carregarOpcoesPorTexto = async (
    texto: string
  ): Promise<Array<OpcaoSelect>> => {
    const resposta = await Api.ObterDisciplinaPorTexto(modalidade, texto, tipo)
    return resposta.registros.map(r => ({
      id: r.id,
      texto: `${r.codigo} - ${r.nome}`,
      dadosAdicionais: r
    }))
  }
  const carregarOpcoes = async (): Promise<Array<OpcaoSelect>> => {
    const resposta = await Api.ObterDisciplinaListaCombo(modalidade, tipo)
    return resposta.map(r => ({
      id: r.id,
      texto: `${r.codigo} - ${r.nome}`,
      dadosAdicionais: r
    }))
  }

  return (
    <ModalBase id="modal-cadastro-prereq" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <h5> {titulo} </h5>
        {pronto && (
          <FormUnform acaoSucesso={acaoSucesso} schema={schema}>
            <FormContent>
              <ContainerSelect>
                <LabelSelect>
                  <Obrigatorio>*</Obrigatorio>
                  <span>{label}</span>
                </LabelSelect>
                <SelectAsyncUnform
                  valorAlterado={valor =>
                    definirSelecionado(
                      (valor as OpcaoSelectDadosAdicionais)?.dadosAdicionais ??
                        null
                    )
                  }
                  id="disciplina-select"
                  name="disciplina"
                  key={`select-async-${modalidade}`}
                  tamanho="S"
                  buscarPorTexto={carregarOpcoesPorTexto}
                  buscarPorId={carregarOpcoes}
                />
              </ContainerSelect>
              <Spacer padding="20px 0px" />
              <ContainerBotoes>
                <Botao
                  type="button"
                  id="btn-cancelar"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    acaoSecundaria && acaoSecundaria()
                    fechar()
                  }}
                />
                <Botao type="submit" id="btn-salvar" texto="Vincular" />
              </ContainerBotoes>
            </FormContent>
          </FormUnform>
        )}
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalVincular = forwardRef(ModalVincularDisciplina)
