import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'

import {
  Botao,
  FormUnform,
  IconeInformacao,
  InputUnform,
  ModalBaseProps,
  SeletorUnform,
  Spacer
} from 'src/componentes'
import { Obrigatorio } from 'src/componentes/input/styles'
import { ModalBase } from 'src/componentes/modal-base'
import { EditorUnform } from 'src/componentes/unform/editor'

import { Corpo, ContainerBotoes, InformacaoVerMaisAviso } from '../../styles'
import { createSchema } from './schema'
import { ModalEditarModuloRef } from './tipos'

const ModalEditar: React.ForwardRefRenderFunction<
  ModalEditarModuloRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [tipoModulo, definirTipoModulo] = useState<any>()

  const [moduloEditavel, definirModuloEditavel] = useState<any>()

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
    definirModuloEditavel(undefined)
  }

  const abrir = (item: any) => {
    definirModuloEditavel(item)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalEditarModuloRef, ModalEditarModuloRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = async (dados: any) => {
    acaoPrimaria(dados)
    fechar()
  }

  const dadosIniciais = useMemo(() => {
    definirTipoModulo(moduloEditavel?.tipoModulo)
    return {
      nome: moduloEditavel?.nome,
      tipoModulo: moduloEditavel?.tipoModulo,
      informacoesAdicionais: moduloEditavel?.informacoesAdicionais
    }
  }, [moduloEditavel])

  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo>
          <h5>Editar Módulo</h5>
          <Spacer padding="10px 0px" />
          {pronto && (
            <FormUnform
              schema={createSchema()}
              dadosIniciais={dadosIniciais}
              acaoSucesso={acaoSucesso}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '15px'
                }}
              >
                <div style={{ width: '75%' }}>
                  <InputUnform
                    name="nome"
                    label="Nome do Módulo"
                    obrigatorio
                    disabled
                    maxLength={200}
                  />
                </div>

                <SeletorUnform
                  name="tipoModulo"
                  tipo="radio"
                  obrigatorio
                  label="Tipo Módulo"
                  valorAlterado={e => definirTipoModulo(e)}
                  seletores={[
                    {
                      id: 'Fixo',
                      texto: 'Fixo'
                    },
                    {
                      id: 'Flex',
                      texto: 'Flex'
                    }
                  ]}
                />
              </div>
              <Spacer padding="10px 0px" />
              <>
                <p>
                  {tipoModulo === 'Flex' ? <Obrigatorio>*</Obrigatorio> : <></>}
                  Informações Ver Mais
                </p>
                {tipoModulo === 'Fixo' && (
                  <InformacaoVerMaisAviso>
                    <span>{IconeInformacao}</span>
                    <span>
                      Campo obrigatório apenas quando o Tipo do Módulo
                      selecionado for Flex.
                    </span>
                  </InformacaoVerMaisAviso>
                )}
                <Spacer padding="2px 0px" />
                <EditorUnform
                  id="informacoesAdicionais"
                  name="informacoesAdicionais"
                  obrigatorio={tipoModulo === 'Flex'}
                />
              </>
              <ContainerBotoes>
                <Botao
                  type="button"
                  id="btn-cancelar"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                />
                <Botao id="btn-salvar" texto="Salvar" type="submit" />
              </ContainerBotoes>
            </FormUnform>
          )}
        </Corpo>
      </ModalBase>
    </>
  )
}
export const ModalEditarModulo = forwardRef(ModalEditar)
