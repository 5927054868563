import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormHandles, Scope } from '@unform/core'
import {
  AutenticacaoContainer,
  Botao,
  Cabecalho,
  Carregando,
  CheckboxUnform,
  CpfInputUnform,
  DadosAlteracaoCPFCNPJ,
  DataInputUnform,
  focarPrimeiroCampoComErro,
  FormUnform,
  IconeEditar,
  IconeModalSucesso,
  ImageInput,
  InputUnform,
  Modal,
  ModalAlteracaoCPFCPNJ,
  ModalRef,
  MoedaInputUnform,
  Navegacao,
  SelectAsyncUnform,
  SelectUnform,
  Spacer,
  SubTitulo,
  TelefoneInputUnform,
  TextAreaUnform
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
import { SELECT_PAISES, SELECT_SEXO } from 'src/dados-estaticos'
import { RotasProfessor } from 'src/rotas/professor'
import { Api } from 'src/servicos'
import { PaisResidencia, Professor, SexoPorExtenso } from 'src/tipos'

import {
  OPCOES_CIVIL,
  OPCOES_COR,
  OPCOES_ESCOLARIDADE,
  OPCOES_TIPO_CONTA
} from './opcoes'
import { schema } from './schema'
import {
  Container,
  ContainerBotoes,
  Linha,
  ContainerDadosGrande,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  ContainerLateral,
  ContainerPrincipal,
  ContainerUploadImagem,
  Conteudo,
  Opcao,
  OpcaoDados,
  Secao,
  TituloPrincipal,
  ContainerCPF
} from './styles'
import { Navegacoes } from './tipos'

export const PaginaMeuCadastroProfessor: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const modalAvisoEmailRef = useRef<ModalRef>(null)
  const modalAlteracaoCPF = useRef<ModalRef>()
  const { usuario } = AutenticacaoContainer.useContainer()
  const history = useHistory()
  const [dadosIniciais, definirDadosIniciais] = useState<Professor>(null)
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)

  const [generoProfessor, definirGeneroProfessor] = useState<string>('')
  const [possuiNomeSocial, definirPossuiNomeSocial] = useState(false)
  const [contaTerceiro, definirContaTerceiro] = useState(false)
  const [paisResidencia, definirPaisResidencia] = useState<string>(
    dadosIniciais?.paisResidencia
  )
  const [emailAlterado, definirEmailAlterado] = useState(false)

  const buscarCep = async (cep: string) => {
    const endereco = await Api.BuscarCep(cep)

    if (endereco) {
      formRef.current?.setFieldValue('bairro', endereco.bairro)
      formRef.current?.setFieldValue('logradouro', endereco.logradouro)
      formRef.current?.setFieldValue('cidade', endereco.cidade)
      formRef.current?.setFieldValue('uf', endereco.uf)
      formRef.current?.setFieldValue('numero', endereco.numero)
      document.getElementById('ipt_numero')?.focus()
    } else {
      formRef.current?.setFieldValue('bairro', '')
      formRef.current?.setFieldValue('logradouro', '')
      formRef.current?.setFieldValue('cidade', '')
      formRef.current?.setFieldValue('uf', '')
      formRef.current?.setFieldValue('complemento', '')
      formRef.current?.setFieldValue('numero', '')
    }
  }

  const acaoSucesso = async (dados: Professor) => {
    definirCarregando(true)
    try {
      await Api.EditarProfessorPorIdUsuario(usuario.id, dados)
      toast('Dados editados com sucesso!', {
        type: 'success'
      })
      if (emailAlterado) modalAvisoEmailRef?.current?.abrir()
      if (!emailAlterado) history.push(RotasProfessor.Dashboard)
    } catch (erro) {
      toast('Ops! Aconteceu um erro. Tente novamente.', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const obterProfessor = async (): Promise<void> => {
    definirPronto(false)
    try {
      const resposta = await Api.ObterProfessorPorIdUsuario(usuario.id)
      definirDadosIniciais(resposta)
      definirContaTerceiro(!resposta?.dadosFinanceirosProfessor?.titularConta)
      definirPossuiNomeSocial(!!resposta.nomeSocial)
    } catch {
      toast('Erro ao obter dados do usuário.', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const atualizarDadosFormulario = (dados: DadosAlteracaoCPFCNPJ) => {
    if (dados.cpf) formRef.current?.setFieldValue('cpf', dados.cpf)
  }

  const carregarOpcoesBancos = async (filtro: string) => {
    try {
      const bancos = await Api.ObterBancosAtivos(filtro)
      return (
        bancos?.map(b => {
          return { id: b.id, texto: `${b.codigo ?? ' '} - ${b.nome}` }
        }) ?? []
      )
    } catch (erro) {
      toast('Erro ao carregar opções de bancos.', { type: 'error' })
    }
  }

  const carregarBancoPorId = async (id: string) => {
    try {
      const banco = await Api.ObterBancoPorId(id)
      return [{ id: banco.id, texto: `${banco.codigo ?? ' '} - ${banco.nome}` }]
    } catch (erro) {
      toast('Erro ao carregar opções de bancos.', { type: 'error' })
    }
  }

  useEffect(() => {
    obterProfessor()
  }, [])

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerPrincipal>
            <Cabecalho>
              <TituloPrincipal>Atualização de Cadastro</TituloPrincipal>
            </Cabecalho>
            <Conteudo>
              <FormUnform
                ref={formRef}
                acaoSucesso={acaoSucesso}
                acaoFalha={focarPrimeiroCampoComErro}
                dadosIniciais={dadosIniciais}
                schema={schema}
              >
                <Secao id={Navegacoes.DadosPessoais}>
                  <SubTitulo texto="Dados Pessoais" />
                  <ContainerUploadImagem>
                    <ImageInput id="foto" name="foto" label maxSize={2} />
                  </ContainerUploadImagem>
                  <Spacer padding="13px 0px" />
                  <Linha>
                    <ContainerDadosPequeno>
                      <ContainerCPF>
                        <CpfInputUnform
                          id="cpf"
                          name="cpf"
                          label="CPF"
                          disabled
                        />
                        <button
                          type="button"
                          data-tip="Editar"
                          disabled={carregando}
                          onClick={() => modalAlteracaoCPF?.current?.abrir()}
                        >
                          {IconeEditar}
                        </button>
                      </ContainerCPF>
                    </ContainerDadosPequeno>
                    <ContainerDadosPequeno>
                      <InputUnform id="rg" name="rg" label="RG" obrigatorio />
                    </ContainerDadosPequeno>
                  </Linha>
                  <Linha>
                    <ContainerDadosGrande>
                      <InputUnform
                        id="nome"
                        name="nome"
                        label="Nome"
                        maxLength={200}
                        obrigatorio
                      />
                    </ContainerDadosGrande>
                  </Linha>
                  <Opcao>
                    <CampoLabel>Possui nome social?</CampoLabel>
                    <OpcaoDados>
                      <CheckboxUnform
                        id="chk_possui_nome_social"
                        name="possuiNomeSocial"
                        onChange={() => definirPossuiNomeSocial(old => !old)}
                        checked={possuiNomeSocial}
                      />
                      <p>
                        <b>Sim</b>
                        <i>
                          (Nome social é a adoção de nome diferente do
                          oficialmente registrado, de modo a identificar
                          adequadamente sua identidade de gênero - Decreto nº
                          8727/2016)
                        </i>
                      </p>
                    </OpcaoDados>
                  </Opcao>
                  {possuiNomeSocial && (
                    <Linha>
                      <ContainerDadosGrande>
                        <InputUnform
                          type="text"
                          name="nomeSocial"
                          label="Nome Social"
                          maxLength={200}
                          obrigatorio
                        />
                      </ContainerDadosGrande>
                    </Linha>
                  )}
                  <Linha>
                    <ContainerDadosPequeno>
                      <SelectUnform
                        id="sexo"
                        name="sexo"
                        label="Gênero"
                        placeholder="Selecione"
                        opcoes={SELECT_SEXO}
                        valorAlterado={valor =>
                          definirGeneroProfessor(valor?.texto)
                        }
                        obrigatorio
                      />
                    </ContainerDadosPequeno>
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        id="generoOutros"
                        name="generoOutros"
                        label="Qual?"
                        disabled={generoProfessor !== SexoPorExtenso.Outros}
                        maxLength={200}
                      />
                    </ContainerDadosMedio>
                    <ContainerDadosPequeno>
                      <DataInputUnform
                        id="dataNascimento"
                        name="dataNascimento"
                        label="Data de Nascimento"
                        obrigatorio
                      />
                    </ContainerDadosPequeno>
                  </Linha>
                  <Linha>
                    <ContainerDadosMedio>
                      <SelectUnform
                        id="corRaca"
                        name="corRaca"
                        label="Raça"
                        placeholder="Selecione"
                        opcoes={OPCOES_COR}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                    <ContainerDadosMedio>
                      <SelectUnform
                        id="estadoCivil"
                        name="estadoCivil"
                        label="Estado Civil"
                        placeholder="Selecione"
                        opcoes={OPCOES_CIVIL}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                  </Linha>
                  <Linha>
                    <ContainerDadosMedio>
                      <SelectUnform
                        id="escolaridade"
                        name="escolaridade"
                        label="Escolaridade"
                        placeholder="Selecione"
                        opcoes={OPCOES_ESCOLARIDADE}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        name="profissao"
                        label="Profissão"
                        maxLength={200}
                      />
                    </ContainerDadosMedio>
                  </Linha>
                </Secao>
                <Secao id={Navegacoes.Contato}>
                  <SubTitulo texto="Contato" />
                  <Linha>
                    <ContainerDadosMedio>
                      <InputUnform
                        id="email"
                        type="email"
                        name="email"
                        label="E-mail"
                        maxLength={200}
                        onChange={() => definirEmailAlterado(true)}
                      />
                    </ContainerDadosMedio>
                    <ContainerDadosPequeno>
                      <TelefoneInputUnform
                        id="celular"
                        name="celular"
                        label="Celular"
                        maxLength={11}
                        obrigatorio
                      />
                    </ContainerDadosPequeno>
                    <ContainerDadosPequeno>
                      <TelefoneInputUnform
                        name="telefone"
                        label="Telefone"
                        maxLength={11}
                      />
                    </ContainerDadosPequeno>
                  </Linha>
                </Secao>
                <Secao id={Navegacoes.DadosFinanceiros}>
                  <SubTitulo texto="Dados Financeiros" />
                  <Scope path="dadosFinanceirosProfessor">
                    <Linha>
                      <ContainerDadosMedio>
                        <SelectAsyncUnform
                          id="ipt_banco_id"
                          name="bancoId"
                          label="Banco"
                          buscarPorTexto={carregarOpcoesBancos}
                          buscarPorId={carregarBancoPorId}
                        />
                      </ContainerDadosMedio>
                      <ContainerDadosMedio>
                        <SelectUnform
                          id="tipoConta"
                          name="tipoConta"
                          label="Tipo Conta"
                          placeholder="Selecione"
                          opcoes={OPCOES_TIPO_CONTA}
                        />
                      </ContainerDadosMedio>
                    </Linha>
                    <Linha>
                      <ContainerDadosPequeno>
                        <InputUnform
                          id="agencia"
                          type="agencia"
                          name="agencia"
                          label="Agência"
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosPequeno>
                        <InputUnform
                          id="conta"
                          type="conta"
                          name="conta"
                          label="Conta"
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosMedio>
                        <InputUnform
                          id="chavePix"
                          type="chavePix"
                          name="chavePix"
                          label="Chave PIX"
                        />
                      </ContainerDadosMedio>
                    </Linha>
                    <Linha>
                      <ContainerDadosGrande>
                        <InputUnform
                          id="observacoes"
                          type="observacoes"
                          name="observacoes"
                          label="Observações"
                          maxLength={100}
                        />
                      </ContainerDadosGrande>
                      <ContainerDadosMedio>
                        <MoedaInputUnform
                          id="valorHora"
                          type="valorHora"
                          name="valorHora"
                          label="Valor Hora"
                        />
                      </ContainerDadosMedio>
                    </Linha>
                    <Linha>
                      <ContainerDadosPequeno>
                        <Opcao>
                          <CampoLabel>Conta de Terceiro</CampoLabel>
                          <OpcaoDados>
                            <CheckboxUnform
                              id="chk_titular_conta"
                              name="titularConta"
                              onChange={() => definirContaTerceiro(old => !old)}
                              checked={contaTerceiro}
                            />
                          </OpcaoDados>
                        </Opcao>
                      </ContainerDadosPequeno>
                      {contaTerceiro && (
                        <ContainerDadosMedio>
                          <InputUnform
                            id="nomeTitular"
                            name="nomeTitular"
                            label="Nome do Titular"
                            maxLength={200}
                          />
                        </ContainerDadosMedio>
                      )}
                      {contaTerceiro && (
                        <ContainerDadosMedio>
                          <CpfInputUnform
                            id="cpfTitular"
                            name="cpfTitular"
                            label="CPF do Titular"
                          />
                        </ContainerDadosMedio>
                      )}
                    </Linha>
                  </Scope>
                </Secao>
                <Secao id={Navegacoes.Residencia}>
                  <SubTitulo texto="Residência" />
                  <ContainerDadosGrande>
                    <SelectUnform
                      name="paisResidencia"
                      id="paisResidencia"
                      label="País de Residência"
                      valorAlterado={opcao =>
                        definirPaisResidencia(opcao ? opcao.id : '')
                      }
                      opcoes={SELECT_PAISES}
                      obrigatorio
                    />
                  </ContainerDadosGrande>
                  {paisResidencia === PaisResidencia.Brasil ? (
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        name="cep"
                        label="CEP"
                        obrigatorio={paisResidencia === PaisResidencia.Brasil}
                        mascara="99999999"
                        onBlur={event => buscarCep(event.target.value)}
                      />
                    </ContainerDadosMedio>
                  ) : null}
                  <ContainerDadosGrande>
                    <InputUnform
                      type="text"
                      name="logradouro"
                      label="Logradouro"
                      maxLength={200}
                      obrigatorio
                    />
                  </ContainerDadosGrande>
                  <Linha>
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        name="numero"
                        id="ipt_numero"
                        label="Número"
                        maxLength={200}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        name="complemento"
                        label="Complemento"
                        maxLength={200}
                      />
                    </ContainerDadosMedio>
                  </Linha>
                  <Linha>
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        name="bairro"
                        label="Bairro"
                        maxLength={200}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        name="cidade"
                        label="Cidade"
                        maxLength={200}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                  </Linha>
                  <Linha>
                    <ContainerDadosMedio>
                      <InputUnform
                        type="text"
                        name="uf"
                        label="UF"
                        maxLength={10}
                        obrigatorio
                      />
                    </ContainerDadosMedio>
                  </Linha>
                </Secao>
                <Secao id={Navegacoes.ResumoAcademico}>
                  <SubTitulo texto="Resumo Acadêmico" />
                  <ContainerDadosGrande>
                    <InputUnform
                      type="text"
                      name="lattes"
                      label="Link do Lattes"
                      defaultValue="http://lattes.cnpq.br"
                      maxLength={200}
                    />
                  </ContainerDadosGrande>
                  <ContainerDadosGrande>
                    <TextAreaUnform
                      name="curriculo"
                      label="Resumo do Currículo"
                      maxLength={200}
                    />
                  </ContainerDadosGrande>
                  <ContainerDadosGrande>
                    <TextAreaUnform
                      name="aptidoes"
                      label="Aptidões"
                      maxLength={200}
                      obrigatorio
                    />
                  </ContainerDadosGrande>
                  <ContainerDadosGrande>
                    <TextAreaUnform
                      name="informacoesAdicionais"
                      label="Informações Adicionais"
                      placeholder="Aqui você pode informar detalhes de contato e disponibilidade para viagens..."
                      maxLength={200}
                    />
                  </ContainerDadosGrande>
                </Secao>
                <ContainerBotoes>
                  <Botao
                    type="button"
                    texto="Cancelar"
                    tema="Secundario"
                    disabled={carregando}
                    onClick={() => history.goBack()}
                  />
                  <Botao type="submit" texto="Salvar" carregando={carregando} />
                </ContainerBotoes>
              </FormUnform>
            </Conteudo>
          </ContainerPrincipal>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.DadosPessoais,
                  nome: 'Dados Pessoais'
                },
                {
                  link: Navegacoes.Contato,
                  nome: 'Contato'
                },
                {
                  link: Navegacoes.DadosFinanceiros,
                  nome: 'Dados Financeiros'
                },
                {
                  link: Navegacoes.Residencia,
                  nome: 'Residência'
                },
                {
                  link: Navegacoes.ResumoAcademico,
                  nome: 'Resumo Acadêmico'
                }
              ]}
            />
          </ContainerLateral>
        </Container>
      ) : (
        <Carregando texto="Carregando dados do usuário..." />
      )}
      <Modal
        ref={modalAvisoEmailRef}
        backdrop
        id="modal-aviso-email"
        icone={<>{IconeModalSucesso}</>}
        titulo="E-mail alterado!"
        acaoPrimaria={{
          titulo: 'OK',
          tipo: 'button',
          acao: () => {
            modalAvisoEmailRef?.current?.fechar()
            history.push(RotasProfessor.Dashboard)
          }
        }}
      >
        <p style={{ textAlign: 'center' }}>
          A solicitação do endereço de E-mail foi gerada. Acesse o novo endereço
          de e-mail cadastrado e confirme a alteração pelo link enviado.
        </p>
      </Modal>
      <ModalAlteracaoCPFCPNJ
        ref={modalAlteracaoCPF}
        acaoPrimaria={atualizarDadosFormulario}
        backdrop
      />
    </>
  )
}
