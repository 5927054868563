import React from 'react'

import { formatarCpf, TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaCpf = (): TabelaColuna => ({
  Chave: 'Cpf',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <span>{formatarCpf(linha.Cpf)}</span>,
  RenderizarCabecalho: () => <div style={{ width: 100 }}>CPF</div>
})
