import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaValorParcelaCursoComDesconto = (): TabelaColuna => ({
  Chave: 'ValorParcelaCursoComDesconto',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 250 }}>Valor Parcela Curso Com Desconto</div>
  )
})
