import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  Botao,
  FormUnform,
  ModalBase,
  ModalBaseProps,
  SelectUnform,
  SelectOpcao
} from 'src/componentes'
import {
  ModalVisualizacaoDocumento,
  ModalVisualizacaoDocumentoRef
} from 'src/paginas/ipgs/alunos/documentos/componentes'
import { Api } from 'src/servicos'

import { schema } from './schema'
import {
  ContainerModal,
  TituloPrincipal,
  Container,
  Conteudo,
  ContainerBotoes
} from './styles'
import { ModalDeclaracoesRef } from './tipos'
export type ModalDeclaracoesProps = Omit<ModalBaseProps, 'acaoPrimaria'> & {
  acaoPrimaria: (dados: any) => Promise<void>
}

export interface ModalDeclaracoesDados {
  tipoDeclaracao: number
}

const Modal: React.ForwardRefRenderFunction<
  ModalDeclaracoesRef,
  ModalDeclaracoesProps
> = ({ backdrop }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const modalVisualizacaoRef = useRef<ModalVisualizacaoDocumentoRef>(null)
  const [aberta, definirAberta] = useState(false)
  const [declaracoesDisponiveis, definirDeclaracoesDisponiveis] = useState([])
  const [declaracaoEscolhida, definirDeclaracaoEscolhida] = useState<number>(
    null
  )

  const [salaAulaId, definirSalaAulaId] = useState<string>(null)
  const [carregando, definirCarregando] = useState<boolean>(false)

  const carregarDeclaracoesDisponiveis = async (salaAulaEadId: string) => {
    try {
      definirCarregando(true)
      const resposta = await Api.ObterDeclaracoesDisponiveis(salaAulaEadId)

      definirDeclaracoesDisponiveis(resposta)
    } catch (error) {
      toast(
        'Houve um erro ao carregar declarações disponiveis para a matrícula!'
      )
    } finally {
      definirCarregando(false)
    }
  }

  const obterArquivoDeclaracao = async () => {
    try {
      definirCarregando(true)
      const retorno = await Api.ObterArquivoDeclaracao(
        salaAulaId,
        declaracaoEscolhida
      )
      modalVisualizacaoRef?.current?.abrir(retorno)
    } catch (erro) {
      toast('Erro ao gerar declaração.')
    } finally {
      definirCarregando(false)
    }
  }
  const fechar = () => {
    definirAberta(false)
    definirSalaAulaId(null)
    definirDeclaracoesDisponiveis([])
  }

  const abrir = async (salaAulaId: string) => {
    definirAberta(true)
    definirSalaAulaId(salaAulaId)
    await carregarDeclaracoesDisponiveis(salaAulaId)
  }

  useImperativeHandle<ModalDeclaracoesRef, ModalDeclaracoesRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <>
      <ModalBase id="modal-declaracoes" aberta={aberta} backdrop={backdrop}>
        <ContainerModal>
          <Scrollbars>
            <Container>
              <TituloPrincipal>Declarações</TituloPrincipal>
              <FormUnform
                ref={formRef}
                acaoSucesso={obterArquivoDeclaracao}
                schema={schema}
              >
                <Conteudo>
                  <SelectUnform
                    id="select-tipo-declaracao"
                    name="tipoDeclaracao"
                    label="Tipo Declaração"
                    maxWidthInput={'100%'}
                    opcoes={declaracoesDisponiveis as Array<SelectOpcao>}
                    valorAlterado={valor => {
                      if (valor) {
                        definirDeclaracaoEscolhida(Number(valor.id))
                      } else {
                        definirDeclaracaoEscolhida(null)
                      }
                    }}
                    deveLimpar={() => !aberta}
                  ></SelectUnform>
                </Conteudo>
                <ContainerBotoes>
                  <Botao
                    texto="Cancelar"
                    tema="Secundario"
                    type="button"
                    onClick={() => fechar()}
                  ></Botao>
                  <Botao
                    texto="Gerar Declaração"
                    disabled={declaracaoEscolhida === null}
                    carregando={carregando}
                  ></Botao>
                </ContainerBotoes>
              </FormUnform>
            </Container>
          </Scrollbars>
        </ContainerModal>
        <ModalVisualizacaoDocumento
          ref={modalVisualizacaoRef}
          backdrop
          id="modal-visualizacao-declaracao-aluno"
        />
      </ModalBase>
    </>
  )
}

export const ModalDeclaracoes = forwardRef(Modal)
