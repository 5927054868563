import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'

import {
  Botao,
  FormUnform,
  IconeInformacao,
  InputUnform,
  ModalBaseProps,
  SeletorUnform,
  Spacer
} from 'src/componentes'
import { Obrigatorio } from 'src/componentes/input/styles'
import { ModalBase } from 'src/componentes/modal-base'
import { EditorUnform } from 'src/componentes/unform/editor'
import { GradeCurricularSituacao } from 'src/tipos'

import { Corpo, ContainerBotoes, InformacaoVerMaisAviso } from '../../styles'
import { createSchema } from './schema'
import { ModalIncluirModuloRef } from './tipos'

const ModalIncluir: React.ForwardRefRenderFunction<
  ModalIncluirModuloRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [nomesExistentes, definirNomesExistentes] = useState<string[]>([])
  const [tipoModulo, definirTipoModulo] = useState<any>()
  const [tcc, definirTCC] = useState<boolean>(false)
  const [ehModalidadeFlex, definirEhModalidadeFlex] = useState(false)
  const fechar = () => {
    definirNomesExistentes([])
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = (
    nomesExistentes: string[],
    tcc: boolean,
    ehModalidadeFlex: boolean
  ) => {
    definirNomesExistentes(nomesExistentes ?? [])
    definirTipoModulo('Fixo')
    definirPronto(true)
    definirAberta(true)
    definirTCC(tcc)
    definirEhModalidadeFlex(ehModalidadeFlex)
  }

  useImperativeHandle<ModalIncluirModuloRef, ModalIncluirModuloRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucesso = async (dados: any) => {
    acaoPrimaria(dados)
    fechar()
  }

  const dadosIniciais = useMemo(() => {
    return {
      situacaoGrade: GradeCurricularSituacao[GradeCurricularSituacao.Ativo],
      tipoModulo: 'Fixo'
    }
  }, [])

  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo
          style={ehModalidadeFlex ? { width: '700px' } : { width: '500px' }}
        >
          <h5>Incluir Módulo {tcc && 'TCC'}</h5>
          <Spacer padding="10px 0px" />
          {pronto && (
            <FormUnform
              schema={createSchema(nomesExistentes)}
              dadosIniciais={dadosIniciais}
              acaoSucesso={acaoSucesso}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '15px'
                }}
              >
                <div
                  style={
                    ehModalidadeFlex ? { width: '75%' } : { width: '100%' }
                  }
                >
                  <InputUnform
                    name="nome"
                    label="Nome do Módulo"
                    obrigatorio
                    maxLength={200}
                  />
                </div>
                {ehModalidadeFlex && (
                  <SeletorUnform
                    name="tipoModulo"
                    tipo="radio"
                    valorAlterado={e => definirTipoModulo(e)}
                    obrigatorio
                    label="Tipo Módulo"
                    seletores={[
                      {
                        id: 'Fixo',
                        texto: 'Fixo'
                      },
                      {
                        id: 'Flex',
                        texto: 'Flex'
                      }
                    ]}
                  />
                )}
              </div>
              <Spacer padding="10px 0px" />
              {ehModalidadeFlex && (
                <>
                  <p>
                    {tipoModulo === 'Flex' ? (
                      <Obrigatorio>*</Obrigatorio>
                    ) : (
                      <></>
                    )}
                    Informações Ver Mais
                  </p>
                  {tipoModulo === 'Fixo' && (
                    <InformacaoVerMaisAviso>
                      <span>{IconeInformacao}</span>
                      <span>
                        Campo obrigatório apenas quando o Tipo do Módulo
                        selecionado for Flex.
                      </span>
                    </InformacaoVerMaisAviso>
                  )}
                  <Spacer padding="2px 0px" />
                  <EditorUnform
                    id="informacoesAdicionais"
                    name="informacoesAdicionais"
                    obrigatorio={tipoModulo === 'Flex'}
                  />
                </>
              )}
              <ContainerBotoes>
                <Botao
                  type="button"
                  id="btn-cancelar"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                />
                <Botao id="btn-salvar" texto="Salvar" type="submit" />
              </ContainerBotoes>
            </FormUnform>
          )}
        </Corpo>
      </ModalBase>
    </>
  )
}
export const ModalIncluirModulo = forwardRef(ModalIncluir)
