import React, { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'

import { IconeBloqueado } from 'src/componentes'

import { ContainerIconeBloqueado } from './styles'
import { TooltipProps } from './tipos'

export const Tooltip: React.FC<TooltipProps> = ({
  bloqueado,
  html,
  className,
  modal,
  ...props
}) => {
  const offset = useMemo(() => {
    if (bloqueado) {
      switch (props.place) {
        case 'right':
          return { right: 5 }
        case 'left':
          return { left: 5 }
        case 'top':
          return { top: 5 }
        case 'bottom':
          return { bottom: 5 }
      }
    }
    return undefined
  }, [props.place, bloqueado])

  const posicaoFixa = ({ left, top }) => {
    const modalElement = document.getElementById(modal.id)
    const scroll = modalElement?.scrollTop ?? 0
    top = top + scroll

    return {
      top,
      left
    }
  }

  return (
    <ReactTooltip
      className={className}
      effect={props.effect || 'solid'}
      place={props.place || 'bottom'}
      offset={offset}
      resizeHide
      clickable
      backgroundColor={props.backgroundColor || 'rgba(48, 48, 48, .85)'}
      overridePosition={modal?.fixo ? posicaoFixa : null}
      getContent={content => (
        <>
          {html ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            content
          )}
          {bloqueado && (
            <ContainerIconeBloqueado place={props.place || 'bottom'}>
              {IconeBloqueado}
            </ContainerIconeBloqueado>
          )}
        </>
      )}
      {...props}
    />
  )
}
