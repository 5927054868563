import styled from 'styled-components'

export const Corpo = styled.div`
  width: 471px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
  }
`
export const ContainerInput = styled.div`
  width: 100%;
`

export const Titulo = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
`

export const Texto = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 8px;
`

export const Botoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;

  button:last-child {
    margin-left: 20px;
  }
`
