import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { useResponsivoState, useTema } from '../contextos'
import { IconeSeta } from '../icones'
import { ItemRetraido } from './styles'
import { MenuItem, MenuSubItem } from './tipos'

export const MenuBotao: React.FC<{
  abrirItem: (indice: number, item: MenuItem) => void
  paraDesktop: boolean
  indice: number
  item: MenuItem | MenuSubItem
  retraido: boolean
}> = ({ abrirItem, paraDesktop, indice, item, retraido }) => {
  const { desktop } = useResponsivoState()
  const history = useHistory()

  const [, dispatchTema] = useTema()
  const retrairMenu = () => dispatchTema({ tipo: 'retrair-menu' })

  const temFilhoAtivoRelatorios = (path: string) => {
    if (item.Titulo === 'Relatórios') {
      const contemSubItem = item.SubItens.map(
        si =>
          si.SubItens.filter(sa => sa.Caminho && path.startsWith(sa.Caminho))
            .length > 0
      ).find(a => a === true)

      return contemSubItem
    }
  }

  const temFilhoAtivo = (path: string) => {
    const contemSubItem =
      item.SubItens &&
      item.SubItens.filter(si => si.Caminho && path.startsWith(si.Caminho))
        .length > 0

    return contemSubItem
  }

  const compararCaminhoDaRotaComCaminhoDoItem = (path: string) =>
    item.ComparacaoExata ? path === item.Caminho : path.startsWith(item.Caminho)

  const itemAtivo = (path: string) => {
    return (
      (item.Caminho && compararCaminhoDaRotaComCaminhoDoItem(path)) ||
      temFilhoAtivo(path) ||
      temFilhoAtivoRelatorios(path)
    )
  }

  const [ativo, definirAtivo] = useState(itemAtivo(history.location.pathname))

  useEffect(() => {
    return history.listen(location => {
      definirAtivo(itemAtivo(location.pathname))
      retrairMenu()
    })
  }, [history])

  const manipularClick = (indice: number, item: MenuItem | MenuSubItem) => {
    abrirItem(indice, item)

    if (item.Caminho) history.push(item.Caminho)
  }

  return (
    desktop === paraDesktop && (
      <button
        className={classNames({
          clicavel: !!item.Caminho,
          ativo,
          iconeComStroke: item.IconeComStroke,
          retraido
        })}
        type="button"
        onClick={() => manipularClick(indice, item)}
      >
        {!retraido && (
          <>
            <div className="icone-titulo">
              {item.Icone}
              <span>{item.Titulo}</span>
            </div>
            {item.SubItens &&
              item.SubItens?.length > 0 &&
              item.Icone &&
              IconeSeta}
          </>
        )}
        {retraido && (
          <>
            {item.Icone}
            <ItemRetraido className="item-retraido">
              <div className="icone-titulo">
                {item.Icone}
                <span>{item.Titulo}</span>
              </div>
              {item.SubItens &&
                item.SubItens?.length > 0 &&
                item.Icone &&
                IconeSeta}
            </ItemRetraido>
          </>
        )}
      </button>
    )
  )
}
