import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ModalBase,
  Botao,
  ModalBaseProps,
  SubTitulo,
  IconeDecisao,
  ModalRef
} from 'src/componentes'

import {
  ContainerBotoes,
  ModalCorpo,
  ContainerModal,
  SubtituloModal
} from './styles'

export type ModalCancelarRecorrenciaProps = Omit<
  ModalBaseProps,
  'acaoPrimaria'
> & {
  acaoPrimaria: () => Promise<void>
}

const ModalCancelarRecorrenciaComponent: React.ForwardRefRenderFunction<
  ModalRef,
  ModalCancelarRecorrenciaProps
> = ({ backdrop, acaoPrimaria, acaoSecundaria }, ref) => {
  const [aberta, definirAberta] = useState(false)

  const fechar = () => definirAberta(false)
  const abrir = () => definirAberta(true)

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-confirmacao-cancelamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ModalCorpo>
        <ContainerModal>
          {IconeDecisao}
          <SubtituloModal>
            Deseja realmente cancelar a recorrência?
          </SubtituloModal>
          <SubTitulo texto="Selecione uma opção" />
          <ContainerBotoes>
            <Botao
              type="button"
              texto="Não"
              tema="Secundario"
              onClick={() => {
                acaoSecundaria && acaoSecundaria()
                fechar()
              }}
            />
            <Botao
              className="btn-salvar"
              type="button"
              texto="Sim"
              onClick={() => {
                acaoPrimaria()
                fechar()
              }}
            />
          </ContainerBotoes>
        </ContainerModal>
      </ModalCorpo>
    </ModalBase>
  )
}
export const ModalCancelarRecorrencia = forwardRef(
  ModalCancelarRecorrenciaComponent
)
