import styled from 'styled-components'

import { Cores } from '../styles'

export const ContainerItemAcoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: ${Cores.CINZA_2};
    }

    &:hover {
      background: ${Cores.CINZA_3_MAIS_CLARO};

      svg {
        fill: ${Cores.SECUNDARIA_1};

        path {
          fill: ${Cores.SECUNDARIA_1};
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`
