import { Botao } from 'src/componentes/botao'
import { Cores } from 'src/componentes/styles/cores'
import styled, { css } from 'styled-components'

export const ContainerDaPagina = styled.div``

export const TituloPrincipal = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 30px;
`
export const ContainerDosBotoes = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const BotaoDeSalvar = styled(Botao)`
  padding: 14px 60px;
  margin-left: 20px;
`

export const MensagemDeErro = styled.span`
  display: flex;
  color: ${Cores.PERIGO_ERRO};
  font-size: 12px;
  margin-top: 30px;
`

export const ContainerLink = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const ContainerBreadcrumbs = styled.div`
  display: flex;
  margin-bottom: 22px;
`

export const ContainerAcoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconeAcao = styled.div<{ disabled?: boolean }>`
  display: flex;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-right: 15px;

  svg {
    width: 23px;
    height: 23px;
  }

  svg path {
    fill: rgb(102, 102, 102);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const CorpoModal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 250px;
`
export const ContainerInputFile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
`

export const CampoLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: left;
  line-height: 125%;
  margin-bottom: 6px;
  color: ${Cores.CINZA_2_ESCURO};

  :first-letter {
    text-transform: uppercase;
  }
`

export const Apresentacao = styled.div`
  display: flex;
`

export const CampoInput = styled.input`
  outline: none;
  border: 1px solid ${Cores.CINZA_1_CLARO};
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: ${Cores.CINZA_2_ESCURO};
  transition: 0.15s;
  position: relative;
  height: 40px;
  border-right-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0px 17px;
  flex: 1;

  &::placeholder {
    color: ${Cores.CINZA_3_ESCURO};
    font-size: 12px;
  }

  &:not(:placeholder-shown) {
    color: ${Cores.CINZA_2};
  }

  ${({ theme: { Desabilitado } }) =>
    !Desabilitado &&
    css`
      &:hover {
        border: 1px solid ${Cores.PRIMARIA_1};
      }

      &:focus {
        caret-color: ${Cores.PRIMARIA_2};
        border: 1px solid ${Cores.PRIMARIA_2};
        color: ${Cores.CINZA_2_ESCURO};
      }
    `}
`

export const BotaoSelecao = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  width: 70px;
  border: 1px solid ${Cores.CINZA_1_CLARO};
  border-left-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${Cores.CINZA_2};
  outline: none;
  cursor: pointer;
`
