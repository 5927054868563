import React, { useRef, useEffect } from 'react'

import {
  IconeAdicionar,
  TabelaRef,
  Tabela,
  TabelaDados,
  ContainerAcoes,
  IconeLixeira,
  Tooltip,
  IconeEditar
} from 'src/componentes'
import { Pergunta } from 'src/dto'

import { ModalRemoverPergunta } from '../remover-pergunta'
import { Botao, ContainerTooltip, Linha } from '../styles'
import { ModalRemoverPerguntaRef, ListaPerguntaProps } from '../tipos'

export const ListaPerguntas: React.FC<ListaPerguntaProps> = ({
  adicionar,
  remover,
  items,
  disabled
}) => {
  const tabelaRef = useRef<TabelaRef>(null)
  const modalRemoverRef = useRef<ModalRemoverPerguntaRef>(null)
  const tamanhoMaximoPergunta = 200

  const obterDados = () => Promise.resolve({ Dados: items })
  const abrirModalRemoverPergunta = (pergunta: Pergunta) =>
    modalRemoverRef?.current?.abrir(pergunta)

  useEffect(() => {
    tabelaRef?.current?.CarregarDados(true)
  }, [items])

  return (
    <>
      <Linha justifyContent="flex-end">
        {!disabled && (
          <Botao
            type="button"
            onClick={() => adicionar(null)}
            disabled={disabled}
          >
            {IconeAdicionar} Incluir Pergunta
          </Botao>
        )}
      </Linha>
      <div>
        <Tabela
          TextoSemResultados="Nenhuma pergunta cadastrada"
          ObterDados={obterDados}
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'pergunta',
              Texto: 'PERGUNTA',
              Renderizar: (linha: TabelaDados) => {
                return (
                  <>
                    {linha.pergunta.length > tamanhoMaximoPergunta ? (
                      <div
                        data-tip={linha.pergunta}
                        data-for={`exibir-${linha.id}`}
                      >
                        {linha.pergunta.substring(0, tamanhoMaximoPergunta)}...
                      </div>
                    ) : (
                      <>{linha.pergunta}</>
                    )}
                    <ContainerTooltip>
                      <Tooltip id={`exibir-${linha.id}`} offset={{ top: 10 }} />
                    </ContainerTooltip>
                  </>
                )
              }
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) =>
                !disabled ? (
                  <ContainerAcoes>
                    <button
                      type="button"
                      data-tip="Editar"
                      data-for={`${linha.id}-tooltip-editar`}
                      onClick={() => adicionar(linha as Pergunta)}
                    >
                      {IconeEditar}
                    </button>
                    {/* TO-DO: Analisar Tooltip */}
                    {/* <Tooltip
                      id={`${linha.id}-tooltip-editar`}
                      place="bottom"
                      offset={{ top: 10 }}
                    /> */}
                    <button
                      type="button"
                      data-tip="Remover"
                      data-for={`${linha.id}-tooltip-excluir`}
                      onClick={() =>
                        abrirModalRemoverPergunta(linha as Pergunta)
                      }
                    >
                      {IconeLixeira}
                    </button>
                    {/* TO-DO: Analisar Tooltip */}
                    {/* <Tooltip
                      id={`${linha.id}-tooltip-excluir`}
                      place="bottom"
                      offset={{ top: 10 }}
                    /> */}
                  </ContainerAcoes>
                ) : (
                  <></>
                )
            }
          ]}
        />
        <ModalRemoverPergunta
          ref={modalRemoverRef}
          backdrop
          acaoPrimaria={remover}
        />
      </div>
    </>
  )
}
