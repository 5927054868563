import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export enum StatusPorExtenso {
  Solicitado = 'Solicitado',
  EmAndamento = 'Em Andamento',
  Concluido = 'Concluído',
  Cancelado = 'Cancelado'
}

export const colunaSituacao = (): TabelaColuna => ({
  Chave: 'situacao',
  Alinhamento: 'center',
  Renderizar: (linha: TabelaDados) => <>{StatusPorExtenso[linha.situacao]}</>,
  RenderizarCabecalho: () => <div style={{ width: 100 }}>Status</div>
})
