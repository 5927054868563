import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  SelectAsyncUnform,
  SelectOpcao
} from 'src/componentes'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'

import { schemaPagamentoCartaoCredito } from './schema'
import { ContainerAcoes, ContainerModal, CorpoModal } from './styles'
import {
  ModalInserirNovoDocumentoProps,
  ModalInserirNovoDocumentoRef,
  NovoContrato
} from './tipos'

const ModalInserirDocumento: React.ForwardRefRenderFunction<
  ModalInserirNovoDocumentoRef,
  ModalInserirNovoDocumentoProps
> = ({ backdrop, acaoPrimaria, matriculas }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = () => {
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<
    ModalInserirNovoDocumentoRef,
    ModalInserirNovoDocumentoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: NovoContrato) => {
    acaoPrimaria(dados)
    fechar()
  }

  const obterCursos = async (): Promise<SelectOpcao[]> => {
    try {
      return matriculas.map(d => ({ id: d.matriculaId, texto: d.nomeCurso }))
    } catch (erro) {
      return [] as SelectOpcao[]
    }
  }

  return (
    <ModalBase
      id="modal-inserir-novo-documento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModal>
        <ContainerModal>
          <h5>Adicionar Novo Contrato</h5>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaPagamentoCartaoCredito}
              ref={formRef}
            >
              <SelectAsyncUnform
                id="slct_curso"
                name="matriculaId"
                label="Selecione o curso"
                buscarPorTexto={obterCursos}
                buscarPorId={obterCursos}
                obrigatorio
              />
              <ArquivoInputUnform
                label="Selecione o arquivo"
                name="contrato"
                obrigatorio
                tiposAceitos={['.pdf', '.jpg', '.jpeg', '.png']}
              />
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Adicionar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalInserirNovoDocumento = forwardRef(ModalInserirDocumento)
