import {
  ModalidadeCurso,
  SituacaoFinanceiraFiltro,
  SituacaoUpload
} from 'src/tipos'

export const OPCOES_MODALIDADE = [
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
    texto: 'Pós Graduação EAD'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
    texto: 'Pós Graduação EAD Flex'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
    texto: 'Pós Graduação EAD Full'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
    texto: 'Pós Graduação Presencial'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
    texto: 'Graduação'
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Outra],
    texto: 'Outra'
  }
]

export const OPCOES_SITUACAO_UPLOAD = [
  {
    id: SituacaoUpload[SituacaoUpload.AguardandoAnalise],
    texto: 'Aguardando Análise'
  },
  {
    id: SituacaoUpload[SituacaoUpload.Aprovado],
    texto: 'Aprovado'
  },
  {
    id: SituacaoUpload[SituacaoUpload.Recusado],
    texto: 'Recusado'
  }
]

export const OPCOES_SITUACAO_FINANCEIRA = [
  {
    id: 'Todas',
    texto: 'Todas'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Cancelado],
    texto: 'Cancelado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.ContratoRescindido],
    texto: 'Contrato Rescindido'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Finalizado],
    texto: 'Finalizado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Inativo],
    texto: 'Inativo'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Matriculado],
    texto: 'Matriculado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reprovado],
    texto: 'Reprovado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reservado],
    texto: 'Reservado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Transferido],
    texto: 'Transferido'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Recuperacao],
    texto: 'Recuperação'
  },
  {
    id:
      SituacaoFinanceiraFiltro[
        SituacaoFinanceiraFiltro.AguardandoCartaoPagamentoRecorrente
      ],
    texto: 'Aguardando Pag. Recorrente'
  }
]
