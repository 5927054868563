import { ReactNode } from 'react'

import { TipoOrdenacao } from 'src/tipos'

export interface TabelaDados {
  [key: string]: any
}

export interface TabelaProps {
  Id?: string
  Colunas: TabelaColuna[]
  Abas: TabelaAba[]
  ObterDados: (
    pagina: number,
    tamanhoPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ) => Promise<TabelaResposta | null>
  RenderizarSemDados?: () => JSX.Element
  ComPaginacao?: boolean
  TamanhoPagina?: TabelaTamanhoPagina
  EstiloPaginacao?: TabelaEstilosPaginacao
  ref?: any
  MostrarCarregandoInstantaneo?: boolean
  TextoSemResultados?: string
  TemaLinha?: (linha: TabelaDados) => TemaLinha
  className?: string
}

export interface TabelaLinhaProps {
  Id?: string
  Indice: number
  Colunas: TabelaColuna[]
  AbasTabela: TabelaAba[]
  Dados: TabelaDados
  TemaLinha: TemaLinha
}

export interface TabelaAba {
  titulo: string
  conteudo?: ReactNode
  conteudoTabela?: TabelaColuna[]
}

export interface TabelaPaginacao {
  Pagina: number
  TotalRegistros: number
}

export interface TabelaResposta {
  Dados: TabelaDados[]
  Paginacao?: TabelaPaginacao
}

export interface TabelaColuna {
  Chave?: string
  Texto?: string
  ComOrdenacao?: boolean
  RenderizarCabecalho?: () => JSX.Element
  Renderizar?: (linha: TabelaDados) => JSX.Element
  Alinhamento?: 'left' | 'center' | 'right' | 'justify' | 'char'
  StyleColuna?: React.CSSProperties
}

export interface TabelaDinamicaRef {
  ObterDados: () => TabelaDados[]
  CarregarDados: (carregandoInstantaneo?: boolean) => Promise<void>
  IrParaPagina: (pagina?: number) => void
}

export type TabelaTamanhoPagina = 10 | 25 | 50 | 100

export type TabelaEstilosPaginacao = 'Simples' | 'Complexo'

export enum TemaLinha {
  Padrao = 'PRIMARIA_1',
  Atencao = 'ATENCAO',
  Sucesso = 'SUCESSO_ESCURO',
  Erro = 'PERIGO_ERRO',
  Pago = 'PRIMARIA_2',
  Renegociado = 'RENEGOCIADA',
  Negativado = 'NEGATIVADA'
}
