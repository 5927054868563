import React, { FC } from 'react'

import { Cores, IconeInformacao, Tooltip } from 'src/componentes'
import { DetalhesDaParcelaPosVencimento } from 'src/tipos'

import { formatadores } from '../../formatadores'
import { ContainerDoIcone } from './styles'

export interface TooltipParcelaEmAtraso {
  id: string
  detalhes: DetalhesDaParcelaPosVencimento
  className?: string
}

export const TooltipParcelaEmAtraso: FC<TooltipParcelaEmAtraso> = ({
  id,
  detalhes: { valorMulta, valorJuros, valorTotalMultaJuros },
  className
}) => {
  const { moeda } = formatadores
  const multa = moeda(valorMulta)
  const juros = moeda(valorJuros)
  const valorTotal = moeda(valorTotalMultaJuros)
  const mensagem = `<p>Parcela em atraso.</p><p>Multa: ${multa}</p><p>Juros: ${juros}</p><p>Total: ${valorTotal}</p>`

  return (
    <>
      <ContainerDoIcone className={className} data-tip={mensagem} data-for={id}>
        {IconeInformacao}
      </ContainerDoIcone>
      <Tooltip
        id={id}
        place={'right'}
        textColor={Cores.PRETO}
        backgroundColor={Cores.CINZA_1_CLARO}
        html={true}
      />
    </>
  )
}
