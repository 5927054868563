import { mensagemPadrao } from 'src/dados-estaticos'
import { TipoTransferenciaAluno } from 'src/tipos'
import * as Yup from 'yup'
const caracter = Yup.string().required(mensagemPadrao)

export const schemaTransferencia = Yup.object().shape({
  tipoTransferencia: caracter,
  turmaOrigem: caracter,
  turmaDestinoId: caracter,
  termo: Yup.string()
    .nullable()
    .when('tipoTransferencia', {
      is: value => value === TipoTransferenciaAluno.TrocaCurso,
      then: field => field.required(mensagemPadrao)
    })
})
