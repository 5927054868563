import React from 'react'
import ReactPlayer from 'react-player'

export interface ComeceAquiProps {
  informacoesIniciaisCurso: string
}

export const ComeceAqui: React.FC<ComeceAquiProps> = ({
  informacoesIniciaisCurso
}) => {
  const videoRef = React.createRef<ReactPlayer>()

  return (
    <ReactPlayer
      ref={videoRef}
      className="player"
      url={informacoesIniciaisCurso}
      width="100%"
      height="100%"
      config={{ vimeo: { playerOptions: { controls: true } } }}
      onProgress={segundos => segundos.playedSeconds}
      onDuration={() => videoRef?.current.seekTo(0, 'seconds')}
    />
  )
}
