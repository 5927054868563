const mesEhValido = (mes: number) => {
  return Number.isInteger(mes) && mes >= 0 && mes <= 12
}

const anoEhValido = (ano: number) => {
  return Number.isInteger(ano) || Number.isNaN(ano)
}

export const mesAnoEhValido = (tempo: string, formatoAno = 'AAAA'): boolean => {
  const regexp =
    formatoAno === 'AAAA' ? /^\d{0,2}?\/?\d{0,4}$/ : /^\d{0,2}?\/?\d{0,2}$/
  const [mesStr, anoStr] = tempo.split('/')

  if (!regexp.test(tempo)) return false

  const mes = Number(mesStr)
  const ano = Number(anoStr)

  if (
    mesStr === '00' ||
    !mesEhValido(mes) ||
    !anoEhValido(ano) ||
    (formatoAno === 'AAAA' && anoStr && Number(anoStr[0]) > 2)
  ) {
    return false
  }

  const mesAnoFragmentado =
    tempo.indexOf('/') !== -1 ? tempo.split('/') : [tempo]

  const minAno = formatoAno === 'AAAA' ? 1 : 0
  const maxAno = formatoAno === 'AAAA' ? 2999 : 99

  if (
    (mesAnoFragmentado[0] &&
      mesAnoFragmentado[0].length &&
      (parseInt(mesAnoFragmentado[0], 10) < 0 ||
        parseInt(mesAnoFragmentado[0], 10) > 12)) ||
    (mesAnoFragmentado[1] &&
      mesAnoFragmentado[1].length &&
      (parseInt(mesAnoFragmentado[1], 10) < minAno ||
        parseInt(mesAnoFragmentado[1], 10) > maxAno))
  ) {
    return false
  }

  return true
}
