import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  Botao,
  CheckboxUnform,
  FormUnform,
  ModalBase,
  NumeroInputUnform,
  TextAreaUnform,
  AutenticacaoContainer,
  Visao
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
import { Api } from 'src/servicos'
import {
  ModalidadeCurso,
  NotasAvaliacaoTCC,
  TipoProfessorModuloTCC
} from 'src/tipos'

import {
  schemaNotasTCC,
  Dados,
  ModalNotaDisciplinaProps,
  ModalNotaDisciplinaRef,
  NotaDisciplinaProps
} from '.'
import { ProfessorTCC } from '../../tipos'
import { dataFormatadaComBarras } from '../turma-presencial-tcc'
import {
  ContainerAcoes,
  ContainerModal,
  CorpoModalTCC,
  ContainerDadosGrande,
  ContainerInformacoes,
  ContainerCheckbox
} from './styles'

const ModalDisciplinaTCC: React.ForwardRefRenderFunction<
  ModalNotaDisciplinaRef,
  ModalNotaDisciplinaProps
> = ({ backdrop, acaoPrimaria, ehProfessor }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const { visao, usuario } = AutenticacaoContainer.useContainer()
  const [informacoes, definirInformacoes] = useState<NotaDisciplinaProps>({
    avaliacaoId: null,
    nome: '',
    disciplina: ''
  })

  const [notas, definirNotas] = useState<NotasAvaliacaoTCC>(null)
  const [professores, definirProfessores] = useState<ProfessorTCC[]>()
  const [encontro, definirEncontro] = useState(null)
  const [podeAvaliarTCC, definirPodeAvaliarTCC] = useState(false)

  const ehPresencial =
    informacoes.modalidade === ModalidadeCurso.PosGraduacaoPresencial ||
    informacoes.modalidade === ModalidadeCurso.Graduacao

  const obterNotas = async (avaliacaoId: string) => {
    try {
      if (!avaliacaoId) {
        return
      }
      const dados = await Api.SalaAulaOutros.ObterNotasPorAvaliacaoTCC(
        avaliacaoId
      )
      definirNotas(dados)
    } catch {
      toast('Erro ao carregar notas', { type: 'error' })
    }
  }

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = (dados: NotaDisciplinaProps) => {
    definirProfessores(dados.professor)
    obterNotas(dados?.avaliacaoId)
      .then(() => {
        definirInformacoes(dados)
        definirPronto(true)
        definirAberta(true)
      })
      .catch(() => {
        toast('Erro ao carregar notas', { type: 'error' })
      })
  }

  useImperativeHandle<ModalNotaDisciplinaRef, ModalNotaDisciplinaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const visaoIPGS = useMemo(() => {
    return visao === Visao.IPGS
  }, [visao])

  const notaProfessorOrientador = useMemo(() => {
    const nota = {
      nota: null,
      feedback: null,
      professorId: null,
      usuarioProfessorId: null
    }

    if (!notas || !notas.feedbacks) {
      return nota
    }

    for (const f of notas.feedbacks) {
      if (f.envios?.length) {
        const ordenados = f.envios?.sort((a, b) => a.tentativa - b.tentativa)
        const ultimoEnvio = ordenados[ordenados?.length - 1]

        for (const n of ultimoEnvio.notas) {
          if (n.tipoProfessor === TipoProfessorModuloTCC.Orientador) {
            nota.nota = n.nota
            nota.feedback = n.feedBack
            nota.professorId = n.professorId
            nota.usuarioProfessorId = n.usuarioProfessorId
          }
        }
      }
    }

    return nota
  }, [notas])

  const notaProfessorBanca = useMemo(() => {
    const nota = {
      nota: null,
      feedback: null,
      professorId: null,
      usuarioProfessorId: null
    }

    if (!notas || !notas.feedbacks) {
      return nota
    }

    for (const f of notas.feedbacks) {
      if (f.envios?.length) {
        const ordenados = f.envios?.sort((a, b) => a.tentativa - b.tentativa)
        const ultimoEnvio = ordenados[ordenados?.length - 1]
        for (const n of ultimoEnvio.notas) {
          if (n.tipoProfessor === TipoProfessorModuloTCC.Banca) {
            nota.nota = n.nota
            nota.feedback = n.feedBack
            nota.professorId = n.professorId
            nota.usuarioProfessorId = n.usuarioProfessorId
          }
        }

        definirPodeAvaliarTCC(ultimoEnvio.podeAvaliarTCC)
      }
    }

    return nota
  }, [notas])

  const acaoSucessoForm = async (dados: Dados) => {
    if (ehPresencial) {
      acaoPrimaria({
        avaliacaoId: informacoes?.avaliacaoId,
        encontros: encontro,
        feedback: dados.feedback,
        nota: dados.nota
      })
    } else {
      acaoPrimaria({
        ...dados,
        avaliacaoId: informacoes?.avaliacaoId
      })
    }
    fechar()
  }

  const ehProfessorOrientador = useMemo(() => {
    return (
      ehProfessor &&
      !!professores?.find(
        x =>
          x.usuarioProfessorId === usuario.id &&
          x.tipoProfessor === TipoProfessorModuloTCC.Orientador
      )
    )
  }, [professores])

  const ehProfessorBanca = useMemo(() => {
    return (
      ehProfessor &&
      !!professores?.find(
        x =>
          x.usuarioProfessorId === usuario.id &&
          x.tipoProfessor === TipoProfessorModuloTCC.Banca
      )
    )
  }, [professores])

  const dadosIniciais = useMemo(() => {
    return {
      notaOrientador: notaProfessorOrientador?.nota,
      feedbackOrientador: notaProfessorOrientador?.feedback,
      notaBanca: notaProfessorBanca?.nota,
      feedbackBanca: notaProfessorBanca?.feedback
    }
  }, [notaProfessorOrientador, notaProfessorBanca])

  return (
    <ModalBase
      id="modal-nota-disciplina-tcc"
      aberta={aberta}
      backdrop={backdrop}
    >
      <CorpoModalTCC>
        <ContainerModal>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              ref={formRef}
              dadosIniciais={dadosIniciais}
              schema={schemaNotasTCC(ehProfessorOrientador, visaoIPGS)}
            >
              <h5>Nota da Disciplina e Presença</h5>
              <ContainerInformacoes>
                <h4>
                  {'Aluno(a): '}
                  {informacoes.nome}
                </h4>
                <h4>Disciplina: {informacoes.disciplina}</h4>
                <h4>Tentativas Restantes: {informacoes.tentativas}</h4>
              </ContainerInformacoes>

              <ContainerDadosGrande>
                <NumeroInputUnform
                  id="ipt_nota_orientador"
                  name="notaOrientador"
                  label="Orientador - Informe a nota da avaliação (de 0 a 10)"
                  isDecimal
                  disabled={
                    (!visaoIPGS && !ehProfessorOrientador) ||
                    notaProfessorOrientador?.nota
                  }
                />
              </ContainerDadosGrande>
              <ContainerDadosGrande>
                <NumeroInputUnform
                  id="ipt_nota_banca"
                  name="notaBanca"
                  label="Banca - Informe a nota da avaliação (de 0 a 10)"
                  isDecimal
                  disabled={
                    (!visaoIPGS && !ehProfessorBanca) ||
                    !podeAvaliarTCC ||
                    notaProfessorBanca?.nota
                  }
                />
              </ContainerDadosGrande>
              <ContainerDadosGrande>
                <TextAreaUnform
                  id="ipt_feedback_orientador"
                  name="feedbackOrientador"
                  label="Orientador - Preencha o feedback da avaliação"
                  disabled={!visaoIPGS && !ehProfessorOrientador}
                  minLength={50}
                />
              </ContainerDadosGrande>
              <ContainerDadosGrande>
                <TextAreaUnform
                  id="ipt_feedback"
                  name="feedbackBanca"
                  label="Banca - Preencha o feedback da avaliação"
                  disabled={
                    (!visaoIPGS && !ehProfessorBanca) || !podeAvaliarTCC
                  }
                  minLength={50}
                />
              </ContainerDadosGrande>

              {ehPresencial && (
                <>
                  <CampoLabel>
                    Marque os encontros em que o aluno esteve presente
                  </CampoLabel>
                  <ContainerCheckbox>
                    {informacoes.encontros.map(m => (
                      <div key={m.dataEncontro}>
                        <CampoLabel>
                          {dataFormatadaComBarras(new Date(m.dataEncontro))}
                        </CampoLabel>
                        <CheckboxUnform
                          id={`data-${m.dataEncontro}`}
                          name={`data-${m.dataEncontro}`}
                          checked={m.presenca}
                          onChange={() => {
                            definirEncontro([
                              {
                                dataEncontro: m.dataEncontro,
                                presente: !m.presenca
                              }
                            ])
                            m.presenca = !m.presenca
                          }}
                        />
                      </div>
                    ))}
                  </ContainerCheckbox>
                </>
              )}
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Salvar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModalTCC>
    </ModalBase>
  )
}

export const ModalNotaDisciplinaTCC = forwardRef(ModalDisciplinaTCC)
