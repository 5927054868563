import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  DocumentosProfessorBrasileiro,
  DocumentosProfessorEstrangeiro,
  PaginaMeuCadastroProfessor,
  PaginaIncialProfessor
} from 'src/paginas/professor'

const RotaBase = 'professor'

export const RotasProfessor = {
  Dashboard: `/${RotaBase}`,
  MeuCadastro: `/${RotaBase}/meu-cadastro`,
  MeusDocumentosBrasileiro: `/${RotaBase}/meus-documentos/brasileiro`,
  MeusDocumentosEstrangeiro: `/${RotaBase}/meus-documentos/estrangeiro`
}

export const ProfessorRotas: React.FC = () => {
  return (
    <Switch>
      <Route exact path={RotasProfessor.Dashboard}>
        <PaginaIncialProfessor />
      </Route>
      <Route path={RotasProfessor.MeuCadastro}>
        <PaginaMeuCadastroProfessor />
      </Route>
      <Route path={RotasProfessor.MeusDocumentosBrasileiro}>
        <DocumentosProfessorBrasileiro />
      </Route>
      <Route path={RotasProfessor.MeusDocumentosEstrangeiro}>
        <DocumentosProfessorEstrangeiro />
      </Route>
    </Switch>
  )
}
