import React, { useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  DataInputUnform,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform
} from 'src/componentes'
import { Tabela, TabelaRef, TabelaResposta } from 'src/componentes/tabela'
import { SelectOpcaoCursos } from 'src/dto'
import { Api } from 'src/servicos'
import { RequisitarListaTurmasPorTexto } from 'src/servicos/api'
import { TipoOrdenacao } from 'src/tipos'

import { COLUNAS_ENVIOS_NOTAS_TCC } from './componentes'
import { OPCOES_SITUACAO_FINANCEIRA } from './opcoes'
import { Form, Container, Campo, ContainerExportar, Dados } from './styles'
import { DadosPesquisa } from './tipos'
export const PaginaEnvioNotasTCC: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioEnvioNotasTCC(
      {
        nomeAluno: dadosPesquisa?.nomeAluno,
        cursosId: dadosPesquisa?.curso,
        turmasId: dadosPesquisa?.turma,
        situacoes: dadosPesquisa?.situacoes,
        dataInicioInscricaoTCC: dadosPesquisa?.dataInicioInscricaoTCC,
        dataFimInscricaoTCC: dadosPesquisa?.dataFimInscricaoTCC,
        dataInicioEnvio: dadosPesquisa?.dataInicioEnvio,
        dataFimEnvio: dadosPesquisa?.dataFimEnvio
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatoriosEnvioNotasTCC({
        nomeAluno: dadosPesquisa?.nomeAluno,
        cursosId: dadosPesquisa?.curso,
        turmasId: dadosPesquisa?.turma,
        situacoes: dadosPesquisa?.situacoes,
        dataInicioInscricaoTCC: dadosPesquisa?.dataInicioInscricaoTCC,
        dataFimInscricaoTCC: dadosPesquisa?.dataFimInscricaoTCC,
        dataInicioEnvio: dadosPesquisa?.dataInicioEnvio,
        dataFimEnvio: dadosPesquisa?.dataFimEnvio
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const requisitarListaCursosOutrosPorTextoFiltro = async (
    nome: string
  ): Promise<SelectOpcaoCursos[]> => {
    const result = await Api.RequisitarListaCursoOutros(nome)
    return result.map(x => ({ id: x.id, texto: x.nome }))
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Envio e Notas de TCC"
          atalhos={[
            {
              texto: 'Relatórios Acadêmicos'
            },
            {
              texto: 'Envio e Notas de TCC'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome do Aluno"
                maxLength={200}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_situacao_financeira"
                name="situacoes"
                label="Situação Matricula"
                iconeFechar
                multiplo
                opcoes={OPCOES_SITUACAO_FINANCEIRA}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                multiplo
                buscarPorTexto={requisitarListaCursosOutrosPorTextoFiltro}
                buscarPorId={requisitarListaCursosOutrosPorTextoFiltro}
              />
            </Campo>
            <Campo style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                multiplo
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="dataInicioInscricaoTCC"
                label="Início Período Inscrição no TCC"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataFimInscricaoTCC"
                label="Fim Período Inscrição no TCC"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataInicioEnvio"
                label="Início Período Envio"
              />
            </Campo>
            <Campo>
              <DataInputUnform name="dataFimEnvio" label="Fim Período Envio" />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={COLUNAS_ENVIOS_NOTAS_TCC}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaEnvioNotasTCC />
  </ErroLayoutContainer.Provider>
)
