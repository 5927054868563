import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useParams } from 'react-router-dom'

import {
  Botao,
  BotaoStep,
  IconeCartaoAmex,
  IconeCartaoMaster,
  IconeCartaoVisa,
  ModalRef,
  PdfViewer,
  FormUnform,
  FormRef,
  InputUnform,
  SelectUnform,
  WizardContainer,
  FuncoesMoeda,
  InputMesAnoUnform,
  IconeInformacao,
  Tooltip,
  Cores,
  scrollParaTopo,
  CartaoCreditoInputUnform,
  focarPrimeiroCampoComErro,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoDiners,
  regExpDiners,
  regExpJcb,
  regExpHiper,
  regExpElo,
  regExpAmex,
  regExpMaster,
  regExpVisa,
  SelectOpcao
} from 'src/componentes'
import {
  SELECT_DIAS_DIAS_BOLETO_AUTOMATICO,
  SELECT_METODOSPAGAMENTO
} from 'src/dados-estaticos'
import { Api } from 'src/servicos'
import { BoletoGerado } from 'src/servicos/api/tipos'
import { PagamentoMatricula } from 'src/tipos'
import { v4 } from 'uuid'

import ReingressoContainer from '../container'
import {
  schemaPagamentoBoleto,
  schemaPagamentoCartaoCredito,
  schemaPagamentoCartaoRecorrencia
} from '../schema'
import {
  ContainerConteudo,
  ContainerAcoes,
  ContainerCadastro,
  Linha,
  ContainerMedio,
  TituloIsento,
  TituloIsentoRecorrencia,
  LinhaBandeiras,
  ContainerBandeiras,
  ContainerBotaoBoleto,
  ContainerBoleto,
  IconeLabel
} from '../style'
import { InfoValoresCurso } from './info-valores-curso'
import { ModalPagamentoReingresso } from './modal-pagamento-reingresso'

export const PagamentoReingresso: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const modalRef = useRef<ModalRef>(null)
  const formRef = useRef<FormRef>(null)

  const { dados, definirPagamento } = ReingressoContainer.useContainer()
  const { voltarWizard, passo } = WizardContainer.useContainer()

  const [carregandoBoleto, definirCarregandoBoleto] = useState(false)
  const [carregandoFinalizar, definirCarregandoFinalizar] = useState(false)
  const [carregandoVoltar, definirCarregandoVoltar] = useState(false)

  const [metodoPagamento, definirMetedoPagamento] = useState(
    SELECT_METODOSPAGAMENTO[0].id
  )
  const [boleto, definirBoleto] = useState<undefined | BoletoGerado>(undefined)
  const [numeroCartao, definirNumeroCartao] = useState('')

  const [ehPagamentoRecorrente, definirEhPagamentoRecorrente] = useState(false)

  const obterDados = async () => {
    try {
      const dados = await Api.RequisitarInformacoesTaxaMatriculaReingresso(id)
      definirEhPagamentoRecorrente(dados.pagamentoRecorrente)
    } catch (error) {}
  }

  const valorTotal = useMemo(() => {
    return ehPagamentoRecorrente ? 0 : dados?.valorTaxaReingresso
  }, [dados?.valorTaxaReingresso, ehPagamentoRecorrente])

  const parcelasCartaoCredito = useMemo(() => {
    const quantidade = Array.from(
      { length: dados?.quantidadeMaximaParcelas },
      (x, i) => {
        const contador = `${i + 1}`
        return {
          id: contador,
          texto: `${contador}x de ${FuncoesMoeda.formatarMoeda(
            valorTotal / +contador
          )}`
        } as SelectOpcao
      }
    )

    return quantidade
  }, [valorTotal, dados])

  const acaoSucesso = async (dadosPagamento: PagamentoMatricula) => {
    if (metodoPagamento !== SELECT_METODOSPAGAMENTO[0].id) {
      try {
        definirCarregandoBoleto(true)
        const boletoGerado = await Api.GerarBoletoMatriculaReingresso(id)

        definirBoleto(boletoGerado)
        const pagamento = {
          ...dadosPagamento,
          boletoGerado,
          valorTotal: valorTotal,
          valor: valorTotal
        }
        definirPagamento(pagamento)
      } finally {
        definirCarregandoBoleto(false)
      }
    } else {
      try {
        definirCarregandoFinalizar(true)
        const dadosCartao = {
          nomeImpresso: dadosPagamento.nomeCartao,
          numero: dadosPagamento.numeroCartao.replaceAll(' ', ''),
          validade: dadosPagamento.validadeCartao,
          codigo: dadosPagamento.codigoSeguranca,
          parcelas: Number(dadosPagamento.parcelas ?? 1)
        }

        await Api.AvancarPagamentoMatriculaReingresso(id, dadosCartao)
        modalRef?.current?.abrir()

        const pagamento = {
          ...dadosPagamento,
          valorTotal: valorTotal,
          valor: dados?.valorTaxaReingresso
        }
        definirPagamento(pagamento)
      } finally {
        definirCarregandoFinalizar(false)
      }
    }

    if (process.env.REACT_APP_GTM) {
      const cursosGTM = []
      const cursoGTM = {
        id: v4(),
        name: dados?.matriculaDestino?.nomeCurso,
        category: dados?.matriculaDestino?.modalidade,
        price: dados?.valorTaxaReingresso,
        position: cursosGTM?.length + 1,
        metric1: 0,
        metric2: valorTotal,
        metric3: dadosPagamento.parcelas,
        metric4: dadosPagamento.diaVencimento
      }

      cursosGTM.push(cursoGTM)

      const tagManagerArgs = {
        gtmId: 'GTM-NRDM9FL',
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: v4(),
                option: dadosPagamento.metodoPagamento,
                step: 'Pagamento do Curso',
                revenue: valorTotal
              },
              products: cursosGTM
            }
          }
        }
      }
      TagManager.initialize(tagManagerArgs)
    }
  }

  const acaoFinalizar = async () => {
    try {
      if (
        metodoPagamento === SELECT_METODOSPAGAMENTO[1].id ||
        (valorTotal < 1 && !ehPagamentoRecorrente)
      ) {
        definirCarregandoFinalizar(true)
        await Api.AvancarPagamentoMatriculaReingresso(id)

        modalRef?.current?.abrir()
      } else {
        formRef.current?.submitForm()
      }
    } finally {
      definirCarregandoFinalizar(false)
    }
  }

  const renderizarBoleto = useCallback(
    () =>
      metodoPagamento === SELECT_METODOSPAGAMENTO[1].id && boleto ? (
        <Linha>
          <ContainerBoleto>
            <h5>
              Boleto gerado com sucesso!
              <br />
              Realize o pagamento do boleto em qualquer banco até a data de
              vencimento
            </h5>
            <p>{boleto.linhaDigitavel}</p>
            <Botao
              tema="Link"
              texto="Copiar Código do Boleto"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(boleto.linhaDigitavel)
              }}
            />
            {!!boleto.linkBoleto && <PdfViewer url={boleto.linkBoleto} />}
          </ContainerBoleto>
        </Linha>
      ) : (
        <></>
      ),
    [metodoPagamento, boleto]
  )

  const tituloPagamento = useMemo(() => {
    if (ehPagamentoRecorrente) {
      return 'Cartão Pagamento Recorrente'
    }

    return 'Pagamento do Reingresso'
  }, [ehPagamentoRecorrente])

  const schema = useMemo(() => {
    if (ehPagamentoRecorrente) {
      return schemaPagamentoCartaoRecorrencia
    }

    return metodoPagamento === SELECT_METODOSPAGAMENTO[0].id
      ? schemaPagamentoCartaoCredito
      : schemaPagamentoBoleto
  }, [ehPagamentoRecorrente, metodoPagamento])

  const exibirCamposCartao = useMemo(() => {
    return valorTotal > 0 || ehPagamentoRecorrente
  }, [valorTotal, ehPagamentoRecorrente])

  const textoIsencao = useMemo(() => {
    return ehPagamentoRecorrente
      ? 'ISENTO: Não há parcelas do Plano de Pagamento a pagar.'
      : ' ISENTO: Não há valores de reingresso a pagar.'
  }, [ehPagamentoRecorrente])

  useEffect(() => {
    scrollParaTopo()
  }, [passo])

  useEffect(() => {
    if (metodoPagamento === SELECT_METODOSPAGAMENTO[1].id) {
      formRef.current?.setFieldValue(
        'diaVencimento',
        new Date(new Date().setDate(new Date().getDate() + 2))
          .getDate()
          .toString()
      )
    }
  }, [metodoPagamento])

  useEffect(() => {
    obterDados()
  }, [id])

  return (
    <>
      <InfoValoresCurso
        key={dados?.id}
        valorTaxa={dados?.valorTaxaReingresso ?? 0}
        recorrencia={ehPagamentoRecorrente}
      />

      <ContainerConteudo>
        <ContainerCadastro>
          <FormUnform
            ref={formRef}
            schema={schema}
            acaoSucesso={acaoSucesso}
            acaoFalha={focarPrimeiroCampoComErro}
          >
            {ehPagamentoRecorrente && (
              <TituloIsentoRecorrencia>
                ISENTO: Não há taxa a pagar por optar pelo pagamento recorrente.
              </TituloIsentoRecorrencia>
            )}

            {exibirCamposCartao ? (
              <>
                <h4>{tituloPagamento}</h4>
                <Linha>
                  <ContainerMedio>
                    {valorTotal > 0 && (
                      <SelectUnform
                        id="slc_metodo_pagamento"
                        name="metodoPagamento"
                        label="Selecione a Forma de Pagamento"
                        obrigatorio
                        opcoes={SELECT_METODOSPAGAMENTO}
                        valorAlterado={valor => {
                          definirMetedoPagamento(valor.id)
                          definirNumeroCartao('')

                          formRef.current?.setErrors({})
                        }}
                        disabled={!!boleto}
                      />
                    )}
                    {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id && (
                      <>
                        <LinhaBandeiras>
                          <ContainerBandeiras
                            selecionado={
                              !!numeroCartao.match(regExpVisa) &&
                              !numeroCartao.match(regExpElo)
                            }
                          >
                            {IconeCartaoVisa}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpMaster)}
                          >
                            {IconeCartaoMaster}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpAmex)}
                          >
                            {IconeCartaoAmex}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpElo)}
                          >
                            {IconeCartaoElo}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpHiper)}
                          >
                            {IconeCartaoHiper}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpJcb)}
                          >
                            {IconeCartaoJcb}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpDiners)}
                          >
                            {IconeCartaoDiners}
                          </ContainerBandeiras>
                        </LinhaBandeiras>
                        <CartaoCreditoInputUnform
                          id="ipt_numero_cartao"
                          name="numeroCartao"
                          label="Número do Cartão"
                          obrigatorio
                          onChange={e => {
                            const valor = e.target.value.replaceAll(' ', '')
                            definirNumeroCartao(valor)
                          }}
                        />
                        <InputUnform
                          id="ipt_nome_cartao"
                          name="nomeCartao"
                          label="Nome Conforme Consta no Cartão"
                          obrigatorio
                        />
                        <Linha>
                          <ContainerMedio>
                            <InputMesAnoUnform
                              id="ipt_validade_cartao"
                              name="validadeCartao"
                              label="Validade do Cartão"
                              placeholder="mm/aaaa"
                              formatoAno="AAAA"
                              obrigatorio
                            />
                          </ContainerMedio>
                          <ContainerMedio>
                            <InputUnform
                              id="ipt_codigo_seguranca"
                              name="codigoSeguranca"
                              label={
                                <>
                                  Código de Segurança
                                  <IconeLabel
                                    data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                                    data-for="tooltip-cvv"
                                  >
                                    {IconeInformacao}
                                  </IconeLabel>
                                </>
                              }
                              obrigatorio
                              maxLength={4}
                            />
                            <Tooltip
                              id="tooltip-cvv"
                              place="right"
                              className="tooltip-ipgs"
                              textColor={Cores.PRETO}
                              backgroundColor={Cores.CINZA_1_CLARO}
                            />
                          </ContainerMedio>
                        </Linha>
                      </>
                    )}
                  </ContainerMedio>
                  <ContainerMedio>
                    {!ehPagamentoRecorrente && (
                      <>
                        {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id ? (
                          <SelectUnform
                            id="slc_parcelas"
                            name="parcelas"
                            label="Selecione a Quantidade de Parcelas"
                            obrigatorio
                            iconeFechar
                            opcoes={parcelasCartaoCredito}
                          />
                        ) : (
                          <>
                            <Linha>
                              <ContainerMedio>
                                <SelectUnform
                                  id="slc_dia_vencimento"
                                  name="diaVencimento"
                                  label="Dia de Vencimento"
                                  placeholder="Selecione"
                                  obrigatorio
                                  opcoes={SELECT_DIAS_DIAS_BOLETO_AUTOMATICO}
                                  disabled
                                />
                              </ContainerMedio>
                              {!boleto && (
                                <ContainerBotaoBoleto>
                                  <Botao
                                    texto="Gerar o Boleto"
                                    tema="Secundario"
                                    carregando={carregandoBoleto}
                                  />
                                </ContainerBotaoBoleto>
                              )}
                              {boleto && (
                                <ContainerBotaoBoleto>
                                  <BotaoStep
                                    texto="Concluir"
                                    tamanho={'S'}
                                    textoNegrito="Matrícula"
                                    type="button"
                                    carregando={carregandoFinalizar}
                                    disabled={
                                      metodoPagamento ===
                                        SELECT_METODOSPAGAMENTO[1].id &&
                                      !boleto &&
                                      valorTotal > 0
                                    }
                                    onClick={acaoFinalizar}
                                    textoTooltip={
                                      metodoPagamento ===
                                        SELECT_METODOSPAGAMENTO[1].id &&
                                      !boleto &&
                                      valorTotal > 0
                                        ? 'É necessário realizar a geração do boleto para prosseguir com o pagamento'
                                        : undefined
                                    }
                                  />
                                </ContainerBotaoBoleto>
                              )}
                            </Linha>
                          </>
                        )}
                      </>
                    )}
                  </ContainerMedio>
                </Linha>
              </>
            ) : (
              <TituloIsento>{textoIsencao}</TituloIsento>
            )}
            {renderizarBoleto()}
          </FormUnform>
          <ContainerAcoes>
            <BotaoStep
              invertido
              texto="Voltar para"
              textoNegrito="Termos e Condições"
              tema="Secundario"
              disabled={carregandoVoltar}
              onClick={() => {
                definirCarregandoVoltar(true)
                voltarWizard()
              }}
            />
            {!boleto && (
              <BotaoStep
                texto="Concluir"
                textoNegrito="Matrícula"
                type="button"
                carregando={carregandoFinalizar}
                disabled={
                  metodoPagamento === SELECT_METODOSPAGAMENTO[1].id &&
                  !boleto &&
                  valorTotal > 0
                }
                onClick={acaoFinalizar}
                textoTooltip={
                  metodoPagamento === SELECT_METODOSPAGAMENTO[1].id &&
                  !boleto &&
                  valorTotal > 0
                    ? 'É necessário realizar a geração do boleto para prosseguir com o pagamento'
                    : undefined
                }
              />
            )}
          </ContainerAcoes>
        </ContainerCadastro>
      </ContainerConteudo>
      <ModalPagamentoReingresso ehBoleto={boleto} backdrop ref={modalRef} />
    </>
  )
}
