import { Entidade } from 'src/compartilhados'

import { ModalidadeCurso } from './curso'
import {
  SituacaoTransferencia,
  StatusCarrinhoCompra,
  TipoMatricula,
  TipoTransferenciaAluno
} from './enums'
import { SituacaoReingresso } from './reingresso'

export enum EtapasDaMatricula {
  DadosPessoais = 'DadosPessoais',
  DadosPlanoPagamento = 'DadosPlanoPagamento',
  TermosECondicoes = 'TermosECondicoes',
  DadosDePagamento = 'DadosDePagamento',
  AguardandoPagamento = 'AguardandoPagamento',
  Concluida = 'Concluida'
}

export enum SituacaoDaMatricula {
  Rascunho = 'Rascunho',
  Reservada = 'Reservada',
  Finalizada = 'Finalizada'
}

export enum SituacaoDaTurma {
  Aberta = 'Aberta',
  AguardandoInicio = 'AguardandoInicio',
  EmAndamento = 'EmAndamento',
  Cancelada = 'Cancelada',
  Finalizada = 'Finalizada'
}

export interface CursoDoPainelDoAluno extends Entidade<string> {
  nome: string
  modalidade: ModalidadeCurso
  tempoDisponibilidade: number
  sigla: string
}

export interface TurmaDoPainelDoAluno extends Entidade<string> {
  codigo: string
  nome: string
  dataInicio: Date
  dataFim: Date
  situacaoTurma: SituacaoDaTurma
}

export interface ReingressoDoPainelDoAluno extends Entidade<string> {
  situacaoReingresso: SituacaoReingresso
}

export interface TransferenciaDoPainelDoAluno extends Entidade<string> {
  situacaoTransferencia: SituacaoTransferencia
  tipoTransferencia: TipoTransferenciaAluno
}

export interface MatriculaDoPainelDoAluno extends Entidade<string> {
  etapa: EtapasDaMatricula
  situacaoMatricula: SituacaoDaMatricula
  progresso: number
  curso: CursoDoPainelDoAluno
  turma: TurmaDoPainelDoAluno
  situacaoFinanceira: string
  precisaEscolherModulosFlex?: boolean
  transferenciaOrigem: TransferenciaDoPainelDoAluno
  transferenciaDestino: TransferenciaDoPainelDoAluno
  dataCriacao: string
  recuperacaoDisponivel?: boolean
  reingressoDestino: ReingressoDoPainelDoAluno
  reingressoOrigem: ReingressoDoPainelDoAluno
  tipo: TipoMatricula
}

export interface CarrinhoCompraDoPainelDoAluno {
  carrinhoCompraId: string
  statusCarrinho: StatusCarrinhoCompra
  aguardandoPagamento?: boolean
  matriculas: Array<MatriculaDoPainelDoAluno>
}

export interface PainelMatriculasDoAluno {
  matriculas: Array<MatriculaDoPainelDoAluno>
  carrinhosCompra: Array<CarrinhoCompraDoPainelDoAluno>
}

export interface PainelDoAluno {
  idUsuario: string
  foto: string
  matriculas: Array<MatriculaDoPainelDoAluno>
  carrinhosCompra: Array<CarrinhoCompraDoPainelDoAluno>
}

export interface PainelInicialDoAluno {
  idAluno: string
  existeDocumentoPendente: boolean
  existeAtualizacaoNotaTCC: boolean
  painelAluno: PainelDoAluno
}
