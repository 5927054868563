import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ModalBase,
  Botao,
  ModalBaseProps,
  SubTitulo,
  IconeDecisao
} from 'src/componentes'
import { Pergunta } from 'src/dto'

import {
  ContainerBotoes,
  ModalCorpo,
  ContainerModal,
  SubtituloModal
} from '../styles'
import { ModalRemoverPerguntaRef } from '../tipos'

const ModalExcluirComponente: React.ForwardRefRenderFunction<
  ModalRemoverPerguntaRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria, acaoSecundaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [pergunta, definirPergunta] = useState<Pergunta>(null)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = (index: Pergunta) => {
    definirPergunta(index)
    definirAberta(true)
  }

  useImperativeHandle<ModalRemoverPerguntaRef, ModalRemoverPerguntaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase
      id="modal-confirmacao-cancelamento"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ModalCorpo>
        <ContainerModal>
          {IconeDecisao}
          <SubtituloModal>
            Deseja realmente remover esta pergunta?
          </SubtituloModal>
          <SubTitulo texto="Selecione uma opção" />
          <ContainerBotoes>
            <Botao
              type="button"
              texto="Não"
              tema="Secundario"
              onClick={() => {
                acaoSecundaria && acaoSecundaria()
                definirPergunta(null)
                fechar()
              }}
            />
            <Botao
              className="btn-salvar"
              type="button"
              texto="Sim"
              onClick={() => {
                acaoPrimaria(pergunta)
                fechar()
              }}
            />
          </ContainerBotoes>
        </ContainerModal>
      </ModalCorpo>
    </ModalBase>
  )
}

export const ModalRemoverPergunta = forwardRef(ModalExcluirComponente)
