import React from 'react'

export default (
  <svg
    width="55"
    height="63"
    viewBox="0 0 55 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55Z"
      fill="#D8D8D8"
    />
    <path
      d="M31.1766 37.2667C31.1766 36.6815 30.6979 36.2028 30.1127 36.2028H23.7287C23.1435 36.2028 22.6648 36.6815 22.6648 37.2667V43.6507C22.6648 44.2359 23.1435 44.7146 23.7287 44.7146H30.1127C30.6979 44.7146 31.1766 44.2359 31.1766 43.6507V37.2667ZM39.5823 21.307C39.5823 15.2156 33.1982 10.6671 27.5059 10.6671C22.0796 10.6671 18.0364 12.9812 15.1637 17.7426C14.8711 18.2214 14.9775 18.8065 15.4297 19.1525L19.792 22.4774C20.0048 22.6103 20.2175 22.6901 20.4569 22.6901C20.7495 22.6901 21.0687 22.5306 21.2816 22.2645C22.8509 20.2961 23.4893 19.6577 24.1278 19.2056C24.713 18.8065 25.8035 18.4342 27.0005 18.4342C29.155 18.4342 31.0969 19.7642 31.0969 21.2538C31.0969 22.9561 30.2457 23.8339 28.1975 24.7648C25.8567 25.8289 22.6648 28.5952 22.6648 31.8138V33.0108C22.6648 33.596 23.0372 34.5004 23.6224 34.5004H30.0062C30.618 34.5004 31.0703 33.8089 31.0703 33.2237C31.0703 32.4522 32.0544 30.6168 33.6239 29.7125C36.1508 28.3026 39.5823 26.3609 39.5823 21.307Z"
      fill="white"
    />
  </svg>
)
