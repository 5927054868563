import { animated } from 'react-spring'

import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 133px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;

  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  border: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 4px 0;
`

export const AnimatedDiv = styled(animated.div)`
  width: 100%;
  height: auto;
`

export const Conteudo = styled.div`
  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  border: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 4px 0;
`

export const CabecalhoListagem = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`

export const Titulo = styled.div`
  margin-left: 21px;

  h3 {
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    color: ${Cores.SECUNDARIA_2_MAIS_ESCURO};
  }

  p {
    margin-top: 12px;
    font-size: 16px;
    text-align: justify;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }
`

export const ListaTopicos = styled.div`
  border-left: 4px solid ${Cores.SECUNDARIA_2};
`

export const ItemTopico = styled.div`
  cursor: pointer;
  transition: 0.2s all;

  & > div {
    display: flex;
    align-items: center;

    border-top: 1px solid ${Cores.CINZA_3_CLARO};
    border-bottom: 1px solid ${Cores.CINZA_3_CLARO};

    margin: 0px 16px 0px 20px;
    padding: 16px 0px;

    & > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 111.33%;
      letter-spacing: -0.02em;
      color: ${Cores.SECUNDARIA_2_ESCURO};

      margin-left: 12px;
    }
  }

  &:hover {
    background-color: ${Cores.CINZA_3_CLARO};
  }
`

export const ContainerTela = styled.div`
  margin-top: 47px;
`

export const Botoes = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 32px;
`

export const BotaoProrrogacao = styled.button`
  position: relative;
  border: 2px solid ${Cores.TRANSPARENTE};
  border-radius: 3px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: all 0.15s ease-in;
  max-height: 88px;
  width: 250px;
  padding: 16px 18px;
  display: flex;
  flex-direction: row;
  align-items: center;

  letter-spacing: 0.05em;
  background: ${Cores.SECUNDARIA_2_ESCURO};
  color: ${Cores.BRANCO};

  &:hover {
    color: ${Cores.BRANCO};
    background: ${Cores.SECUNDARIA_2_ESCURO};
  }

  &:focus {
    border: 2px solid ${Cores.PRIMARIA_2_ESCURO};
    outline: none;
    box-shadow: none !important;
  }

  &:active {
    background: ${Cores.SECUNDARIA_2_ESCURO_40};
    color: ${Cores.PRIMARIA_2_ESCURO};
    border: 2px solid ${Cores.TRANSPARENTE};
  }

  &:disabled {
    background: ${Cores.SECUNDARIA_2_ESCURO_40};
    color: ${Cores.PRIMARIA_2_36};
    cursor: not-allowed;

    &:active {
      border: 2px solid ${Cores.TRANSPARENTE};
      pointer-events: none;
    }
  }

  svg {
    margin-right: 16px;
    path {
      fill: ${Cores.BRANCO};
    }
  }

  p {
    font-size: 12px;
    font-weight: 500;
  }
`

export const InformacoesProrrogacao = styled.div`
  display: flex;
  flex-direction: column;
`

export const Aviso = styled.h5`
  color: ${Cores.NEGATIVADA};
  font-weight: 400;
  margin-top: 10px;
`
