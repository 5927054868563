import React from 'react'

import { SubTitulo, InputUnform, CepInputUnform } from 'src/componentes'

import PaginaCadastroTurmaContainer from '../../../container'
import {
  ContainerDados,
  ContainerDadosGrande,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  Secao
} from '../../../styles'
import { Navegacoes } from '../../../tipos'

export const Endereco: React.FC = () => {
  const { bloquearCampo } = PaginaCadastroTurmaContainer.useContainer()

  return (
    <Secao id={Navegacoes.Localizacao}>
      <SubTitulo texto="Localização" />
      <ContainerDados>
        <ContainerDadosMedio>
          <InputUnform
            name="local"
            label="Local"
            maxLength={200}
            disabled={bloquearCampo}
          />
        </ContainerDadosMedio>
        <ContainerDadosMedio>
          <CepInputUnform
            name="cep"
            label="CEP"
            obrigatorio
            disabled={bloquearCampo}
          />
        </ContainerDadosMedio>
      </ContainerDados>
      <ContainerDados>
        <ContainerDadosGrande>
          <InputUnform
            name="logradouro"
            label="Logradouro"
            obrigatorio
            maxLength={200}
            disabled={bloquearCampo}
          />
        </ContainerDadosGrande>
        <ContainerDadosPequeno>
          <InputUnform
            name="numero"
            label="Numero"
            obrigatorio
            maxLength={10}
            disabled={bloquearCampo}
          />
        </ContainerDadosPequeno>
      </ContainerDados>
      <ContainerDados>
        <ContainerDadosMedio>
          <InputUnform name="complemento" label="Complemento" maxLength={200} />
        </ContainerDadosMedio>
        <ContainerDadosMedio>
          <InputUnform
            name="bairro"
            label="Bairro"
            obrigatorio
            maxLength={200}
            disabled={bloquearCampo}
          />
        </ContainerDadosMedio>
      </ContainerDados>
      <ContainerDados>
        <ContainerDadosMedio>
          <InputUnform
            name="cidade"
            label="Cidade"
            obrigatorio
            maxLength={200}
            disabled={bloquearCampo}
          />
        </ContainerDadosMedio>
        <ContainerDadosMedio>
          <InputUnform
            name="uf"
            label="UF"
            obrigatorio
            maxLength={10}
            disabled={bloquearCampo}
          />
        </ContainerDadosMedio>
      </ContainerDados>
    </Secao>
  )
}
