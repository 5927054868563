import React from 'react'

export default (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="revelarPassword"
  >
    <path
      d="M21.8259 6.57276C21.6297 6.30433 16.9542 0 10.9827 0C5.01115 0 0.335404 6.30433 0.139387 6.57251C-0.0464623 6.82717 -0.0464623 7.17257 0.139387 7.42723C0.335404 7.69567 5.01115 14 10.9827 14C16.9542 14 21.6297 7.69563 21.8259 7.42745C22.012 7.17283 22.012 6.82717 21.8259 6.57276ZM10.9827 12.5517C6.58401 12.5517 2.77431 8.36742 1.64656 6.99951C2.77285 5.63039 6.57457 1.44827 10.9827 1.44827C15.3811 1.44827 19.1905 5.63185 20.3187 7.00049C19.1925 8.36957 15.3907 12.5517 10.9827 12.5517Z"
      fill="#606060"
    />
    <path
      d="M10.9835 2.65625C8.58781 2.65625 6.63867 4.60539 6.63867 7.0011C6.63867 9.3968 8.58781 11.3459 10.9835 11.3459C13.3792 11.3459 15.3284 9.3968 15.3284 7.0011C15.3284 4.60539 13.3792 2.65625 10.9835 2.65625ZM10.9835 9.89764C9.3863 9.89764 8.08698 8.59828 8.08698 7.0011C8.08698 5.40392 9.38634 4.10456 10.9835 4.10456C12.5807 4.10456 13.8801 5.40392 13.8801 7.0011C13.8801 8.59828 12.5807 9.89764 10.9835 9.89764Z"
      fill="#606060"
    />
  </svg>
)
