import { mensagemPadrao } from 'src/dados-estaticos'
import { TipoPesquisaSatisfacao } from 'src/tipos'
import * as Yup from 'yup'
const caracter = Yup.string().required(mensagemPadrao)

const semEspacosECaracteresEspeciais = Yup.string()
  .matches(
    /^[a-zA-Z0-9]*$/,
    'Sigla não deve conter espaços em branco nem caracteres especiais'
  )
  .required(mensagemPadrao)

export const schema = Yup.object().shape({
  nome: caracter,
  codigo: semEspacosECaracteresEspeciais,
  tipo: caracter,
  modalidade: Yup.string().when('tipo', {
    is: value =>
      value === TipoPesquisaSatisfacao.Curso ||
      value === TipoPesquisaSatisfacao.Professores,
    then: field => field.required(mensagemPadrao)
  }),
  dataInicioPesquisaSatisfacao: Yup.string().when('tipo', {
    is: value => value === TipoPesquisaSatisfacao.Institucional,
    then: field => field.required(mensagemPadrao)
  }),
  dataFimPesquisaSatisfacao: Yup.string().when('tipo', {
    is: value => value === TipoPesquisaSatisfacao.Institucional,
    then: field => field.required(mensagemPadrao)
  })
})
