import React from 'react'

import PropTypes from 'prop-types'

import { Componente, Botao } from './styles'
import { GuiasProps } from './tipos'

export const Guias: React.FC<GuiasProps> = ({
  paginas,
  paginaAtual,
  definePaginaAtual
}: GuiasProps) => {
  return (
    <Componente>
      {paginas.map(item => (
        <Botao
          data-testid={item}
          primeiro={item === paginas[0]}
          ultimo={item === paginas[paginas.length - 1]}
          selecionado={paginaAtual === item}
          key={item}
          onClick={() => definePaginaAtual(item)}
        >
          {item}
        </Botao>
      ))}
    </Componente>
  )
}

Guias.propTypes = {
  paginas: PropTypes.any.isRequired,
  paginaAtual: PropTypes.string.isRequired,
  definePaginaAtual: PropTypes.any.isRequired
}
