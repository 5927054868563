import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { ContainerAcoes, IconeDraggable, IconeEditar, IconeLixeira } from '..'

import { Tabela, DraggableContainer } from './styles'

export interface Draggable {
  draggableId: string | number
}

export interface DragAndDropProps<T extends Draggable> {
  items: T[]
  setItems?: (items: T[]) => void
  editar?: (index: number) => void
  render: (item: T, isDragging: boolean) => JSX.Element
  deveRemover?: (index: number) => boolean
  remover?: (index: number) => void
  disabled?: boolean
}

export function DragAndDrop<T extends Draggable>({
  items,
  setItems,
  render,
  editar,
  remover,
  deveRemover,
  disabled
}: DragAndDropProps<T>): JSX.Element {
  const reordenar = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const excluir = (index: number) => {
    if (remover) {
      remover(index)
      return
    }
    if (!deveRemover || (deveRemover && deveRemover(index))) {
      setItems(items.filter((x, i) => i !== index))
    }
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    setItems(reordenar(items, result.source.index, result.destination.index))
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Tabela
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.draggableId}
                draggableId={item.draggableId}
                index={index}
              >
                {(provided, snapshot) => (
                  <DraggableContainer
                    className="itemDraggableContainer"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                  >
                    {IconeDraggable}
                    {render(item, snapshot.isDragging)}
                    <ContainerAcoes>
                      {editar && (
                        <button
                          type="button"
                          data-tip="Editar"
                          data-for={'tooltip'}
                          onClick={() => editar(index)}
                          disabled={disabled}
                        >
                          {IconeEditar}
                        </button>
                      )}
                      {remover && (
                        <button
                          type="button"
                          data-tip="Excluir"
                          data-for={'tooltip'}
                          onClick={() => excluir(index)}
                          disabled={disabled}
                        >
                          {IconeLixeira}
                        </button>
                      )}
                    </ContainerAcoes>
                  </DraggableContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Tabela>
        )}
      </Droppable>
    </DragDropContext>
  )
}
