import React, { forwardRef } from 'react'

import { CampoLabel } from '../styles'
import { Input } from './styles'
import { TextAreaProps } from './tipos'

export const TextArea: React.ForwardRefExoticComponent<TextAreaProps> = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(({ label, name, id, height, value, minLength, ...rest }, ref?: any) => {
  return (
    <>
      {label && <CampoLabel htmlFor={id}>{label}</CampoLabel>}
      <Input
        {...rest}
        ref={ref}
        id={id}
        name={name}
        value={value}
        height={height}
        minLength={minLength}
      />
    </>
  )
})
