import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import { Botao, CheckboxUnform, FormUnform, ModalBase } from 'src/componentes'
import { SituacaoUpload } from 'src/tipos'

import {
  ContainerConteudo,
  ContainerModal,
  TituloPrincipal,
  ContainerBotoes,
  ContainerCheckbox,
  Opcao,
  OpcaoDados
} from './styles'
import {
  ModalAceiteDocumentoResponsavelProps,
  ModalAceiteDocumentoResponsavelRef
} from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalAceiteDocumentoResponsavelRef,
  ModalAceiteDocumentoResponsavelProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [aceiteResponsavel, definirAceiteResponsavel] = useState(false)
  const [documentoId, definirDocumentoId] = useState('')
  const formRef = useRef<FormHandles>(null)

  const fechar = () => {
    definirAberta(false)
    definirAceiteResponsavel(false)
    formRef.current?.reset()
  }

  const abrir = (documentoId?: string) => {
    definirDocumentoId(documentoId)
    definirAberta(true)
  }

  const acaoSucessoForm = async () => {
    const payload = {
      documentoId: documentoId,
      situacao: SituacaoUpload.Aprovado
    }

    await acaoPrimaria(payload)
    fechar()
    formRef.current?.reset()
  }

  useImperativeHandle<
    ModalAceiteDocumentoResponsavelRef,
    ModalAceiteDocumentoResponsavelRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-aceite-documento-responsavel"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>
          Aceite Documento - Responsável Financeiro
        </TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm}>
          <ContainerConteudo>
            <ContainerCheckbox>
              <Opcao>
                <OpcaoDados>
                  <CheckboxUnform
                    id="chk_aceite_documento_responsavel"
                    name="aceiteDocumentoResponsavel"
                    onChange={() => definirAceiteResponsavel(old => !old)}
                  />
                  <label htmlFor="chk_aceite_documento_responsavel">
                    Confirmo que aceito este documento do Responsável
                    Financeiro.
                  </label>
                </OpcaoDados>
              </Opcao>
            </ContainerCheckbox>
          </ContainerConteudo>
          <ContainerBotoes>
            <Botao
              texto="Voltar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Enviar" disabled={!aceiteResponsavel} />
          </ContainerBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}
export const ModalAceiteDocumentoResponsavel = forwardRef(Modal)
export * from './tipos'
