import * as Medias from './medias'
import { redutor } from './redutor'

function Mobile<TProps>(
  props: TProps
): (strings: TemplateStringsArray, ...variaveis: any[]) => string {
  return function (strings: TemplateStringsArray, ...variaveis: any[]): string {
    const estilos = strings
      .reduce(redutor(variaveis, props), new Array<string>())
      .join('')
    return `${Medias.Mobile} {${estilos}}`
  }
}

function Tablet<TProps>(
  props: TProps
): (strings: TemplateStringsArray, ...variaveis: any[]) => string {
  return function (strings: TemplateStringsArray, ...variaveis: any[]): string {
    const estilos = strings.reduce(redutor(variaveis, props), []).join('')
    return `${Medias.Tablet} {${estilos}}`
  }
}

function Notebook<TProps>(
  props: TProps
): (strings: TemplateStringsArray, ...variaveis: any[]) => string {
  return function (strings: TemplateStringsArray, ...variaveis: any[]): string {
    const estilos = strings.reduce(redutor(variaveis, props), []).join('')
    return `${Medias.Notebook} {${estilos}}`
  }
}

function Desktop<TProps>(
  props: TProps
): (strings: TemplateStringsArray, ...variaveis: any[]) => string {
  return function (strings: TemplateStringsArray, ...variaveis: any[]): string {
    const estilos = strings.reduce(redutor(variaveis, props), []).join('')
    return `${Medias.Desktop} {${estilos}}`
  }
}

export const Responsivo = {
  Mobile,
  Tablet,
  Notebook,
  Desktop
}
