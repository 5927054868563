import React, { useState, useCallback, useEffect } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Checkbox } from '../checkbox'
import { IconeSetaDropDown, IconeFechar } from '../icones'
import { MenuContexto } from '../menu-contexto'
import {
  Lista,
  Item,
  Seletor,
  Icones,
  Limpar,
  Seta,
  Container,
  Label
} from './styles'
import { ListaSuspensaProps } from './tipos'

export const ListaSuspensa: React.FC<ListaSuspensaProps> = ({
  itens,
  renderizarBotao,
  renderizarItem,
  valores,
  valorAlterado,
  label,
  posicao,
  preencherEspacoItem,
  comDesmarcarTodos,
  styleContainer,
  tipo,
  abrirComClick,
  ...rest
}) => {
  const [itensMarcados, definirItensMarcados] = useState<string[]>(valores)

  useEffect(() => {
    definirItensMarcados(valores)
  }, [valores])

  const marcarDesmarcarItem = (valor: string) => {
    definirItensMarcados(oldItensMarcados => {
      let novosValores = []
      if (tipo === 'radio') {
        novosValores = [valor]
        valorAlterado(novosValores)
        return novosValores
      } else {
        if (oldItensMarcados.findIndex(i => i === valor) !== -1) {
          novosValores = oldItensMarcados.filter(i => i !== valor)
          valorAlterado(novosValores)
          return novosValores
        }

        novosValores = [...oldItensMarcados, valor]
        valorAlterado(novosValores)
        return novosValores
      }
    })
  }

  const desmarcarTodos = () => {
    definirItensMarcados([])
    valorAlterado([])
  }

  const renderizarBotaoCallback = useCallback(() => {
    return renderizarBotao(itensMarcados)
  }, [itensMarcados])

  return (
    <Container preencherEspacoItem={preencherEspacoItem} style={styleContainer}>
      {label && <Label>{label}</Label>}
      <MenuContexto
        posicao={posicao}
        abrirComClick={abrirComClick}
        botao={
          <Seletor data-testid={`seletor_${label}`} {...rest}>
            {renderizarBotaoCallback()}
            <Icones>
              {comDesmarcarTodos && (
                <Limpar
                  id={`limpar_${label}`}
                  className={classNames({
                    visivel: itensMarcados.length > 0
                  })}
                  onClick={desmarcarTodos}
                >
                  {IconeFechar}
                </Limpar>
              )}
              <Seta>{IconeSetaDropDown}</Seta>
            </Icones>
          </Seletor>
        }
      >
        <Lista>
          {itens.map((valor, indice) => (
            <Item
              key={`item-suspenso-${indice}-${valor}`}
              className={classNames({
                marcado: itensMarcados.findIndex(i => i === valor) !== -1
              })}
            >
              <button onClick={() => marcarDesmarcarItem(valor)} type="button">
                <Checkbox
                  readOnly
                  htmlFor={`item-suspenso-${indice}-${valor}-checkbox`}
                  tema="Secundario"
                  tamanho="S"
                >
                  <input
                    value={tipo === 'radio' ? `${valor}-checkbox` : ''}
                    name={tipo === 'radio' ? 'item-suspenso' : ''}
                    id={`item-suspenso-${indice}-${valor}-checkbox`}
                    type={tipo}
                    checked={itensMarcados.findIndex(i => i === valor) !== -1}
                    onChange={() => null}
                  />
                </Checkbox>
                {renderizarItem(valor)}
              </button>
            </Item>
          ))}
        </Lista>
      </MenuContexto>
    </Container>
  )
}

ListaSuspensa.defaultProps = {
  valores: [],
  valorAlterado: undefined,
  label: undefined,
  posicao: 'esquerda',
  tipo: 'checkbox',
  comDesmarcarTodos: false
}

ListaSuspensa.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderizarBotao: PropTypes.func.isRequired,
  renderizarItem: PropTypes.func.isRequired,
  valores: PropTypes.arrayOf(PropTypes.string),
  valorAlterado: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  posicao: PropTypes.oneOf(['direita', 'esquerda']),
  tipo: PropTypes.oneOf(['checkbox', 'radio']),
  comDesmarcarTodos: PropTypes.bool,
  preencherEspacoItem: PropTypes.bool,
  styleContainer: PropTypes.object
}
