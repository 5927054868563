import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { NacionalidadePorExtenso } from 'src/tipos'

export const colunaNacionalidade = (): TabelaColuna => ({
  Chave: 'nacionalidade',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <>{NacionalidadePorExtenso[linha.nacionalidade]}</>
  ),
  RenderizarCabecalho: () => <div style={{ width: 110 }}>Nacionalidade</div>
})
