import React from 'react'

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.48892 7.57192C3.72533 7.28967 4.14579 7.2525 4.42807 7.48892L8 10.4804L11.572 7.48892C11.8543 7.2525 12.2748 7.28967 12.5111 7.57192C12.7475 7.85417 12.7103 8.27467 12.4281 8.51108L8.42808 11.8611C8.18042 12.0685 7.81967 12.0685 7.572 11.8611L3.57198 8.51108C3.2897 8.27467 3.25252 7.85417 3.48892 7.57192Z"
      fill="#828282"
    />
    <path
      d="M7.33317 0.666666V10.6667C7.33317 11.0349 7.63164 11.3333 7.99983 11.3333C8.36802 11.3333 8.6665 11.0349 8.6665 10.6667V0.666666C8.6665 0.298476 8.36802 0 7.99983 0C7.63164 0 7.33317 0.298476 7.33317 0.666666Z"
      fill="#828282"
    />
    <path
      d="M15.3333 14H0.666667C0.298477 14 5.96046e-07 14.2985 5.96046e-07 14.6667C5.96046e-07 15.0349 0.298477 15.3333 0.666667 15.3333H15.3333C15.7015 15.3333 16 15.0349 16 14.6667C16 14.2985 15.7015 14 15.3333 14Z"
      fill="#828282"
    />
    <path
      d="M14.6668 11.3333V14.6667C14.6668 15.0349 14.9651 15.3333 15.3333 15.3333C15.7015 15.3333 16 15.0349 16 14.6667L16.0001 11.3333C16.0001 10.9651 15.7016 10.6667 15.3334 10.6667C14.9652 10.6667 14.6668 10.9651 14.6668 11.3333Z"
      fill="#828282"
    />
    <path
      d="M0 11.3333L5.96046e-07 14.6667C5.96046e-07 15.0349 0.298477 15.3333 0.666667 15.3333C1.03486 15.3333 1.33333 15.0349 1.33333 14.6667V11.3333C1.33333 10.9651 1.03486 10.6667 0.666668 10.6667C0.298478 10.6667 0 10.9651 0 11.3333Z"
      fill="#828282"
    />
  </svg>
)
