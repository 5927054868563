import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  useRef
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  InputUnform,
  FormUnform,
  ModalBase,
  ModalRef,
  SelectOpcao,
  SelectUnform,
  Checkbox,
  Tooltip,
  InputMesAnoUnform,
  IconeCartaoVisa,
  IconeCartaoMaster,
  IconeCartaoAmex,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoDiners,
  CartaoCreditoInputUnform,
  IconeInformacao,
  Cores,
  regExpVisa,
  regExpElo,
  regExpMaster,
  regExpAmex,
  regExpHiper,
  regExpJcb,
  regExpDiners
} from 'src/componentes'
import { SELECT_DIAS, SELECT_METODOSPAGAMENTO } from 'src/dados-estaticos'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { FormaPagamentoCobranca } from 'src/tipos'

import { schema } from './schema'
import {
  ContainerGrande,
  ContainerMedio,
  Linha,
  AvisoFinanceiro,
  ContainerModal,
  CorpoModal,
  TituloPrincipal,
  Aviso,
  LinhaBandeiras,
  ContainerBandeiras,
  IconeLabel,
  ContainerBotoes
} from './styles'
import { ModalOpteProrrogacaoProps } from './tipos'
export interface ModalOpteProrrogacao extends Omit<ModalRef, 'abrir'> {
  abrir: (
    valorProrrogacao: number,
    tempoProrrogacao: number,
    quantidadeParcelas: number,
    opcaoProrrogacaoId: string
  ) => void
}
const Componente: ForwardRefRenderFunction<
  ModalOpteProrrogacao,
  ModalOpteProrrogacaoProps
> = ({ backdrop, aberta, acaoSucesso }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const [aberto, definirAberto] = useState(aberta)
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [valorProrrogacao, definirValorProrrogacao] = useState<number>()
  const [tempoProrrogacao, definirTempoProrrogacao] = useState<number>(null)
  const [quantidadeParcelas, definirQuantidadeParcelas] = useState<number>(null)
  const [ehAceiteAluno, definirEhAceiteAlunoProrrogacao] = useState(false)
  const [opcaoProrrogacaoId, definirOpcaoProrrogacaoId] = useState<string>()
  const [metodoPagamento, definirMetedoPagamento] = useState('')
  const [numeroCartao, definirNumeroCartao] = useState('')

  const abrir = (
    valorProrrogacao: number,
    tempoProrrogacao: number,
    quantidadeParcelas: number,
    opcaoProrrogacaoId: string
  ) => {
    definirAberto(true)
    definirPronto(true)
    definirValorProrrogacao(valorProrrogacao)
    definirTempoProrrogacao(tempoProrrogacao)
    definirQuantidadeParcelas(quantidadeParcelas)
    definirOpcaoProrrogacaoId(opcaoProrrogacaoId)
  }

  const fechar = () => {
    definirPronto(false)
    definirAberto(false)
    definirEhAceiteAlunoProrrogacao(false)
  }

  const SELECT_PARCELAS_BOLETO = Array.from(
    { length: quantidadeParcelas },
    (x, i) => {
      const contador = `${i + 1}`
      return { id: contador, texto: contador } as SelectOpcao
    }
  )

  const SELECT_PARCELAS_CARTAO = Array.from({ length: 12 }, (x, i) => {
    const contador = `${i + 1}`
    return { id: contador, texto: `${contador}x` } as SelectOpcao
  })

  const acaoSucessoForm = async (dados: any) => {
    try {
      definirCarregando(true)

      const pagamentoCartao =
        dados.metodoPagamento === SELECT_METODOSPAGAMENTO[0].id

      const dadosFinal = {
        ...dados,
        opcaoProrrogacaoId,
        formaPagamento: pagamentoCartao
          ? FormaPagamentoCobranca.CartaoCredito
          : FormaPagamentoCobranca.Boleto,
        dadosCartao: pagamentoCartao
          ? {
              nomeImpresso: dados.nomeCartao,
              numero: dados.numeroCartao,
              validade: dados.validadeCartao,
              codigo: dados.codigoSeguranca,
              parcelas: dados.quantidadeParcelas
            }
          : null
      }

      await acaoSucesso(dadosFinal)
      fechar()
      formRef.current?.reset()
    } finally {
      definirCarregando(false)
    }
  }

  useImperativeHandle<ModalOpteProrrogacao, ModalOpteProrrogacao>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase aberta={aberto} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <TituloPrincipal>Realização da Prorrogação</TituloPrincipal>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schema}
              ref={formRef}
            >
              <Linha>
                <ContainerGrande>
                  <Aviso>
                    O pagamento da Prorrogação ficará disponível no seu Painel
                    Financeiro do Curso.
                  </Aviso>
                </ContainerGrande>
              </Linha>
              <Linha>
                <ContainerMedio>
                  <InputUnform
                    name="tempoProrrogacao"
                    label="Tempo da Prorrogação"
                    disabled
                    value={tempoProrrogacao}
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    name="valorProrrogacao"
                    label="Valor da Prorrogação"
                    disabled
                    value={formatadores.moeda(valorProrrogacao)}
                  />
                </ContainerMedio>
              </Linha>
              <Linha>
                <ContainerGrande>
                  <SelectUnform
                    id="slc_metodo_pagamento"
                    name="metodoPagamento"
                    label="Selecione a Forma de Pagamento"
                    obrigatorio
                    opcoes={SELECT_METODOSPAGAMENTO.filter(x => x.id !== 'Pix')}
                    valorAlterado={valor => {
                      definirMetedoPagamento(valor.id)
                      definirNumeroCartao('')

                      formRef.current?.setErrors({})
                    }}
                  />
                </ContainerGrande>
              </Linha>

              {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id && (
                <>
                  <AvisoFinanceiro>
                    Nesta forma de pagamento, é comprometido o limite total do
                    valor da taxa a ser paga, porém parcelada na quantidade de
                    parcelas escolhidas
                  </AvisoFinanceiro>
                  <ContainerGrande>
                    <SelectUnform
                      name="quantidadeParcelas"
                      obrigatorio
                      id={'slct_qtd_parcelas'}
                      label="Selecione a Quantidade de Parcelas"
                      iconeFechar
                      opcoes={SELECT_PARCELAS_CARTAO}
                    />
                  </ContainerGrande>
                  <LinhaBandeiras>
                    <ContainerBandeiras
                      selecionado={
                        !!numeroCartao.match(regExpVisa) &&
                        !numeroCartao.match(regExpElo)
                      }
                    >
                      {IconeCartaoVisa}
                    </ContainerBandeiras>
                    <ContainerBandeiras
                      selecionado={!!numeroCartao.match(regExpMaster)}
                    >
                      {IconeCartaoMaster}
                    </ContainerBandeiras>
                    <ContainerBandeiras
                      selecionado={!!numeroCartao.match(regExpAmex)}
                    >
                      {IconeCartaoAmex}
                    </ContainerBandeiras>
                    <ContainerBandeiras
                      selecionado={!!numeroCartao.match(regExpElo)}
                    >
                      {IconeCartaoElo}
                    </ContainerBandeiras>
                    <ContainerBandeiras
                      selecionado={!!numeroCartao.match(regExpHiper)}
                    >
                      {IconeCartaoHiper}
                    </ContainerBandeiras>
                    <ContainerBandeiras
                      selecionado={!!numeroCartao.match(regExpJcb)}
                    >
                      {IconeCartaoJcb}
                    </ContainerBandeiras>
                    <ContainerBandeiras
                      selecionado={!!numeroCartao.match(regExpDiners)}
                    >
                      {IconeCartaoDiners}
                    </ContainerBandeiras>
                  </LinhaBandeiras>
                  <CartaoCreditoInputUnform
                    id="ipt_numero_cartao"
                    name="numeroCartao"
                    label="Número do Cartão"
                    obrigatorio
                    onChange={e => {
                      const valor = e.target.value.replaceAll(' ', '')
                      definirNumeroCartao(valor)
                    }}
                  />
                  <InputUnform
                    id="ipt_nome_cartao"
                    name="nomeCartao"
                    label="Nome Conforme Consta no Cartão"
                    obrigatorio
                  />
                  <Linha>
                    <ContainerMedio>
                      <InputMesAnoUnform
                        id="ipt_validade_cartao"
                        name="validadeCartao"
                        label="Validade do Cartão"
                        placeholder="mm/aaaa"
                        formatoAno="AAAA"
                        obrigatorio
                      />
                    </ContainerMedio>
                    <ContainerMedio>
                      <InputUnform
                        id="ipt_codigo_seguranca"
                        name="codigoSeguranca"
                        label={
                          <>
                            Código de Segurança
                            <IconeLabel
                              data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                              data-for="tooltip-cvv"
                            >
                              {IconeInformacao}
                            </IconeLabel>
                          </>
                        }
                        obrigatorio
                        maxLength={4}
                      />
                      <Tooltip
                        id="tooltip-cvv"
                        place="right"
                        className="tooltip-ipgs"
                        textColor={Cores.PRETO}
                        backgroundColor={Cores.CINZA_1_CLARO}
                      />
                    </ContainerMedio>
                  </Linha>
                </>
              )}
              {metodoPagamento === SELECT_METODOSPAGAMENTO[1].id && (
                <Linha>
                  <ContainerMedio>
                    <SelectUnform
                      name="quantidadeParcelas"
                      obrigatorio
                      id={'slct_qtd_parcelas'}
                      label="Quantidade de Parcelas"
                      opcoes={SELECT_PARCELAS_BOLETO}
                    />
                  </ContainerMedio>
                  <ContainerMedio>
                    <SelectUnform
                      name="diaVencimento"
                      label="Dia Vencimento"
                      obrigatorio
                      id={'slct_dia_vencimento_tcc'}
                      opcoes={SELECT_DIAS}
                    />
                  </ContainerMedio>
                </Linha>
              )}
              <Linha>
                <ContainerGrande>
                  <AvisoFinanceiro>
                    Esta ação é irreversível e não será possível alterar a opção
                    escolhida no futuro.
                  </AvisoFinanceiro>
                </ContainerGrande>
              </Linha>
              <Linha>
                <ContainerGrande>
                  <Checkbox
                    texto="Estou ciente da minha escolha e aceito os termos de realização da Prorrogação"
                    htmlFor="chk_confirmacao_prorrogacao"
                    tamanho="L"
                  >
                    <input
                      type="checkbox"
                      id="chk_confirmacao_prorrogacao"
                      checked={ehAceiteAluno}
                      onChange={() => {
                        definirEhAceiteAlunoProrrogacao(old => !old)
                      }}
                    />
                  </Checkbox>
                </ContainerGrande>
              </Linha>
              <ContainerBotoes>
                <Botao
                  type="button"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                  disabled={carregando}
                />
                <Botao
                  type="submit"
                  id="btn-salvar"
                  texto="Salvar"
                  disabled={!ehAceiteAluno}
                  carregando={carregando}
                />
              </ContainerBotoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalOpteProrrogacao = forwardRef(Componente)
