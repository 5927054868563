import React from 'react'

import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import { IconeSetaAvancar } from '../icones'
import { Atalho, Atalhos, Container, Separador } from './styles'
import { BreadcrumbProps } from './tipos'

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  titulo,
  atalhos,
  tema = 'Padrao',
  className
}) => (
  <Container className={className}>
    <Atalhos>
      {atalhos.map((val, i) => (
        <Atalho tema={tema} key={uuidv4()}>
          {i > 0 ? (
            <Separador>{tema === 'Padrao' ? '/' : IconeSetaAvancar}</Separador>
          ) : (
            <></>
          )}
          <button
            type="button"
            onClick={val.acao}
            className={classNames({ comAcao: val.acao })}
          >
            {val.texto}
          </button>
        </Atalho>
      ))}
    </Atalhos>
    {!!titulo && <h1>{titulo}</h1>}
  </Container>
)

export * from './tipos'
