import { Entidade } from 'src/compartilhados'

import { Cpf } from './cpf'
import { EnderecoComPaisResidencia } from './endereco'
import { Escolaridade, EstadoCivil, Raca, Sexo } from './enums'

export interface Aluno extends Entidade<string> {
  cpf: Cpf
  documentoIdentidade: string
  codigoConselho: string
  nacionalidade: string
  orgaoEmissor: string
  nome: string
  nomeSocial: string
  dataNascimento: Date
  sexo: Sexo
  generoOutros: string
  estadoCivil: EstadoCivil
  profissao: string
  profissaoOutros: string
  escolaridade: Escolaridade
  mesAnoColacaoGrau: string
  telefone: string
  celular: string
  email: string
  corRaca: Raca
  foto: string
  endereco: EnderecoComPaisResidencia
}

export enum AlunoSituacao {
  Ativo = 'Ativo',
  Inativo = 'Inativo',
  Todas = 'Todas'
}

export interface AlunoNotas {
  avaliacoes: AlunoAvaliacaoNotas[]
}

interface AlunoAvaliacaoNotas {
  nomeModulo: string
  disciplinas: AlunoDisciplinaNotas[]
  nota: number
  aprovado: boolean
  tipoSalaAula: string
}

interface AlunoDisciplinaNotas {
  nomeDisciplina: string
  nota: number
  situacao: boolean
}

export interface CursoContratoAluno {
  id: string
  nome: string
  sigla: string
}

export interface TurmaContratoAluno {
  id: string
  nome: string
  codigo: string
}

export interface MatriculasAluno {
  id: string
  curso: CursoContratoAluno
  turma: TurmaContratoAluno
  dataHoraAceiteTermos?: string
  ipAceiteTermos?: string
  dataAceiteContrato?: string
  ipAceiteContrato?: string
  contratoRejeitado?: boolean
}

export interface ContratoAluno {
  idUsuario: string
  matriculas: MatriculasAluno[]
}

export interface ContratosDoAluno {
  idAluno: string
  contratosAluno: ContratoAluno
}
