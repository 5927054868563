import React from 'react'

export default (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="2.66667" rx="1.33333" fill="currentColor" />
    <rect y="8" width="24" height="2.66667" rx="1.33333" fill="currentColor" />
    <rect y="16" width="24" height="2.66667" rx="1.33333" fill="currentColor" />
  </svg>
)
