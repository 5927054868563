import { SituacaoFinanceiraFiltro, StatusFinanceiro } from 'src/tipos'

export interface DadosPesquisa {
  nomeAluno?: string
  modalidade?: null
  turma?: Array<string>
  curso?: Array<string>
  situacaoFinanceira?: Array<SituacaoFinanceiraFiltro>
  inicioMatricula?: Date
  fimMatricula?: Date
  statusFinanceiro?: Array<StatusFinanceiro>
  planoPagamento?: PlanoPagamentoRecorrenteBoleto
  anosFaturamento?: Array<number>
}

export enum PlanoPagamentoRecorrenteBoleto {
  Recorrente = 'Recorrente',
  Boleto = 'Boleto'
}
