import React from 'react'

export default (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6L6.24264 10.2426L10.4853 6"
      stroke="#606060"
      strokeWidth="2"
      strokeLinecap="round"
      className="comStroke semFill"
    />
  </svg>
)
