export enum SituacaoParcela {
  AVencer = 'A vencer',
  Cancelado = 'Cancelado',
  Negativado = 'Negativado',
  Pago = 'Pago',
  Renegociado = 'Renegociado',
  Vencido = 'Vencido',
  AguardandoPagamentoAutomatico = 'Aguardando pagamento automático',
  Reembolso = 'Reembolso'
}

export enum SituacaoParcelaFiltro {
  AVencer = 'AVencer',
  Cancelado = 'Cancelado',
  Negativado = 'Negativado',
  Pago = 'Pago',
  Renegociado = 'Renegociado',
  Vencido = 'Vencido',
  AguardandoPagamentoAutomatico = 'AguardandoPagamentoAutomatico',
  Reembolso = 'Reembolso'
}
