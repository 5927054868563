import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const Conteudo = styled.main`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  margin-left: 30px;
`

export const ContainerImagemAluno = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  svg {
    width: 100px;
    height: 100px;
    margin-right: 80px;
  }
`

export const ImagemAluno = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 80px;
  border-radius: 50%;
  border: 1px solid #ddd;
`

export const ContainerContrato = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  align-items: center;

  button {
    margin-bottom: 27px;
  }
`

export const ContainerSituacao = styled.span`
  form div {
    margin-bottom: 0px;
  }
`

export const ContainerAcoes = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 19px;
    width: 180px;
  }
`

export const ContainerAcoesModais = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      cursor: no-drop;
    }

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: #00a69c;
      }
    }

    &:hover {
      background: #f7f7f7;

      svg {
        fill: #0accb9;

        path {
          fill: #0accb9;
        }
      }
    }
  }
`
