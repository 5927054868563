import React, { useRef, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Carregando,
  focarPrimeiroCampoComErro,
  FormRef,
  FormUnform,
  IconeAtencao,
  PerguntaLinear,
  PerguntaParagrafo,
  PerguntaRespostaUnica,
  scrollParaTopo,
  SelectAsyncUnform,
  Spacer,
  validarPesquisa
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Api } from 'src/servicos'
import {
  PesquisaSatisfacao,
  PesquisaSatisfacaoRespostaPergunta,
  TipoPesquisaSatisfacaoPergunta,
  PesquisaSatisfacaoProfessorSalaAulaOutrosResposta
} from 'src/tipos'

import {
  Container,
  ContainerBotaoPesquisaSatisfacao
} from '../../shared/styles'
import SalaAulaOutrosContainer from '../container'
import { schema } from './schema'
import { ContainerPergunta, Conteudo, SubTitulo, Titulo } from './styles'

export const PesquisaSatisfacaoProfessor: React.FC<{
  pesquisaSatisfacaoId: string
  disciplinaId: string
  moduloId: string
}> = ({ pesquisaSatisfacaoId, disciplinaId, moduloId }) => {
  const formRef = useRef<FormRef>()
  const [
    pesquisaSatisfacao,
    definirPesquisaSatisfacao
  ] = useState<PesquisaSatisfacao>({} as PesquisaSatisfacao)
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const [carregando, definirCarregando] = useState(false)
  const {
    salaAula,
    atualizarRecarregarSala,
    atualizarPassoAoAtualizar
  } = SalaAulaOutrosContainer.useContainer()

  const carregarPesquisaSatisfacao = async () => {
    try {
      definirPronto(false)
      definirPesquisaSatisfacao({} as PesquisaSatisfacao)
      const dados = await Api.RequisitarPesquisaSatisfacao(pesquisaSatisfacaoId)
      definirPesquisaSatisfacao(dados)
    } catch {
      definirErro('Erro ao carregar pesquisa de satisfação.')
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucesso = async (dados: any) => {
    try {
      if (!validarPesquisa(formRef, dados)) return

      definirCarregando(true)

      const respostas = pesquisaSatisfacao.perguntas.map(item => {
        if (item.tipo === TipoPesquisaSatisfacaoPergunta.Paragrafo) {
          return {
            perguntaId: item.id,
            respostaParagrafo: dados.perguntaParagrafo[item.id] ?? null
          } as PesquisaSatisfacaoRespostaPergunta
        } else {
          return {
            perguntaId: item.id,
            alternativaId: dados.pergunta[item.id]
          } as PesquisaSatisfacaoRespostaPergunta
        }
      })

      const payload = {
        pesquisaSatisfacaoId: pesquisaSatisfacao.id,
        salaAulaOutrosId: salaAula.id,
        salaAulaOutrosDisciplinaId: disciplinaId,
        professorId: dados.professor,
        respostas: respostas
      } as PesquisaSatisfacaoProfessorSalaAulaOutrosResposta

      const resultado = await Api.EnviarRespostaPesquisaSatisfacaoSalaAulaOutrosDisciplinaProfessor(
        payload
      )
      atualizarRecarregarSala(true)

      const passoAoRecorregar = resultado?.novoEnvio
        ? `${moduloId}-${disciplinaId}-${pesquisaSatisfacaoId}`
        : null

      atualizarPassoAoAtualizar(passoAoRecorregar)
    } catch {
      toast('Erro ao salvar resposta pesquisa satisfação', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const buscarProfessorId = async (id: string) => {
    try {
      const professor = await Api.ObterProfessor(id)
      return [{ id: professor.id, texto: professor.nome }]
    } catch {
      return null
    }
  }

  const buscarProfessorDisciplina = async () => {
    try {
      const professores = await Api.SalaAulaOutros.CarregarProfessoresPesquisaSatisfacaoDisciplina(
        salaAula.id,
        disciplinaId
      )

      return professores
    } catch {
      toast('Erro ao carregar professores da disciplina')
    }
  }
  useEffect(() => {
    carregarPesquisaSatisfacao()
  }, [pesquisaSatisfacaoId])

  useEffect(() => {
    scrollParaTopo()
  }, [])

  return (
    <>
      {pronto && !erro ? (
        <>
          <Container>
            <Titulo>Pesquisa de Satisfação</Titulo>
            <SubTitulo>{pesquisaSatisfacao.nome}</SubTitulo>
            <FormUnform
              ref={formRef}
              acaoSucesso={acaoSucesso}
              acaoFalha={focarPrimeiroCampoComErro}
              schema={schema}
            >
              <SelectAsyncUnform
                id="slct_professor"
                name="professor"
                buscarPorId={buscarProfessorId}
                buscarPorTexto={buscarProfessorDisciplina}
              />
              <Spacer padding="20px 0px" />
              {pesquisaSatisfacao?.perguntas?.map(pergunta => {
                return (
                  <ContainerPergunta
                    key={pergunta.id}
                    className={`pergunta[${pergunta.id}]`}
                  >
                    {pergunta.tipo ===
                      TipoPesquisaSatisfacaoPergunta.Paragrafo && (
                      <PerguntaParagrafo
                        pergunta={pergunta}
                        name={`perguntaParagrafo[${pergunta.id}]`}
                      />
                    )}
                    {pergunta.tipo ===
                      TipoPesquisaSatisfacaoPergunta.EscalaLinear && (
                      <PerguntaLinear
                        pergunta={pergunta}
                        name={`pergunta[${pergunta.id}]`}
                      />
                    )}
                    {pergunta.tipo ===
                      TipoPesquisaSatisfacaoPergunta.RespostaUnica && (
                      <PerguntaRespostaUnica
                        pergunta={pergunta}
                        name={`pergunta[${pergunta.id}]`}
                      />
                    )}
                  </ContainerPergunta>
                )
              })}
              <ContainerBotaoPesquisaSatisfacao>
                <Botao
                  type="submit"
                  id="btn-salvar"
                  texto="Enviar"
                  carregando={carregando}
                />
              </ContainerBotaoPesquisaSatisfacao>
            </FormUnform>
          </Container>
        </>
      ) : !pronto && !erro ? (
        <Conteudo>
          <Carregando texto="Carregando Pesquisa de Satisfação ..." />
        </Conteudo>
      ) : (
        <Conteudo>
          <ContainerErroLayout>
            <div>
              {IconeAtencao}
              <p>{erro}</p>
              <div>
                <Botao
                  tema="Padrao"
                  texto="Tentar novamente"
                  onClick={carregarPesquisaSatisfacao}
                  tamanho="S"
                />
              </div>
            </div>
          </ContainerErroLayout>
        </Conteudo>
      )}
    </>
  )
}
