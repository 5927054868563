import { TabelaDados } from '..'

export const formatarCpf = (valor: string): string => {
  return !valor
    ? '-'
    : valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
}

// eslint-disable-next-line
export const formatarCpfTabela = (linha: TabelaDados) => {
  if (linha && linha.documento) {
    linha = linha.documento.replace(/[^\d]/g, '')
    return linha.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  return ''
}
