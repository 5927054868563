import React, { FC, useEffect, useMemo, useState } from 'react'
import { hotjar } from 'react-hotjar'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'

import {
  AutenticacaoContainer,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  Visao
} from 'src/componentes'
import { RotasAcademico } from 'src/rotas'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { PainelFinanceiro as PainelFinanceiroTipo, Usuario } from 'src/tipos'

import { CenarioFinanceiro } from './componentes/cenario-financeiro'
import { Container, Conteudo, Titulo } from './styles'

export type PainelFinanceiroProps = RouteComponentProps<{
  usuarioId?: string
}>

const Painel: FC<PainelFinanceiroProps> = ({ match }) => {
  const history = useHistory()
  const { usuario: usuarioSessao, visao } = AutenticacaoContainer.useContainer()
  const [dadosDoUsuario, definirDadosDoUsuario] = useState<Usuario>()
  const [pronto, definirPronto] = useState(false)
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()
  const visaoAluno = useMemo(() => visao === Visao.Aluno, [visao])

  const [
    situacaoFinanceira,
    definirSituacaoFinanceira
  ] = useState<PainelFinanceiroTipo>()

  const requisitarUsuario = async (id: string): Promise<Usuario> => {
    try {
      return await Api.RequisitarUsuario(id)
    } catch (erro) {
      throw new Error('Não foi possível obter os dados do usuário.')
    }
  }

  const obterUsuario = async (): Promise<Usuario> => {
    return match.params.usuarioId
      ? await requisitarUsuario(match.params.usuarioId)
      : ((usuarioSessao as unknown) as Usuario)
  }

  const obterPainelFinanceiro = async (): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const usuario = await obterUsuario()
      const resposta = await Api.ObterDadosFinanceirosDoAluno(usuario.id)
      definirDadosDoUsuario(usuario as Usuario)
      definirSituacaoFinanceira(resposta)
    } catch (erro) {
      const mensagens = 'Ops! Houve algum problema no carregamento dos dados.'
      const acaoVoltar = () =>
        visaoAluno
          ? history.push(RotasAluno.Dashboard)
          : history.push(RotasAcademico.Alunos)
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR, 6)
    }
    obterPainelFinanceiro()
  }, [usuarioSessao])

  return pronto && !erro ? (
    <Container>
      <Titulo>Painel financeiro de {dadosDoUsuario.nomeApresentacao}</Titulo>
      <Titulo> CPF {dadosDoUsuario.cpf}</Titulo>
      <Conteudo>
        <CenarioFinanceiro situacao={situacaoFinanceira} />
      </Conteudo>
    </Container>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando painel financeiro..." />
  ) : (
    <ErroContainerLayout />
  )
}

export const PainelFinanceiro: FC<PainelFinanceiroProps> = (
  props: PainelFinanceiroProps
) => (
  <ErroLayoutContainer.Provider>
    <Painel {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(PainelFinanceiro)
