import styled from 'styled-components'

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 40px 35px;
  max-width: 350px;
`

export const TituloPrincipal = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 36px;
`

export const ContainerDoConteudo = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    font-style: normal;
    font-size: 14px;
    margin-bottom: 9px;
    text-align: center;
  }
`
export const ContainerDosBotoes = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  /* margin-bottom: 20px; */

  & > button:nth-of-type(1) {
    margin-right: 16px;
  }
`
