import React from 'react'

export default (
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4193 0H2.27419C1.16531 0 0.258057 0.9 0.258057 2V14C0.258057 15.1 1.16531 16 2.27419 16H7.31451V17C7.31451 17.55 7.76814 18 8.32257 18H14.371C14.9254 18 15.379 17.55 15.379 17V16H20.4193C21.5282 16 22.4254 15.1 22.4254 14L22.4355 2C22.4355 0.89 21.5282 0 20.4193 0ZM19.4113 14H3.28225C2.72781 14 2.27419 13.55 2.27419 13V3C2.27419 2.45 2.72781 2 3.28225 2H19.4113C19.9657 2 20.4193 2.45 20.4193 3V13C20.4193 13.55 19.9657 14 19.4113 14ZM13.8468 8.87L9.83467 11.15C9.15927 11.53 8.32257 11.04 8.32257 10.28V5.72C8.32257 4.95 9.15927 4.47 9.83467 4.85L13.8468 7.13C14.5222 7.52 14.5222 8.48 13.8468 8.87Z"
      fill="#606060"
    />
  </svg>
)
