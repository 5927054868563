import React from 'react'
export default (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.436,7.586l-3.998-4.02c-0.752-0.756-2.063-0.764-2.83-0.006c-0.196,0.196-0.35,0.436-0.418,0.629  c-0.653,1.362-1.354,2.215-2.254,2.727l-0.217,0.105C10.751,7.506,9.434,8,7,8C6.734,8,6.479,8.052,6.234,8.152  C5.75,8.354,5.355,8.747,5.152,9.236c-0.199,0.484-0.199,1.041,0,1.525c0.104,0.249,0.25,0.471,0.435,0.651l3.235,3.235L5,20  l5.352-3.822l3.227,3.227c0.186,0.189,0.406,0.339,0.656,0.441C14.482,19.949,14.738,20,15.001,20s0.519-0.052,0.765-0.154  c0.498-0.205,0.883-0.592,1.08-1.078c0.103-0.242,0.155-0.507,0.155-0.768c0-2.436,0.494-3.752,0.978-4.721  c0.496-0.992,1.369-1.748,2.754-2.414c0.271-0.104,0.51-0.256,0.711-0.457C22.216,9.626,22.212,8.357,21.436,7.586z M16.188,12.387  c-0.819,1.643-1.188,3.37-1.195,5.604L7,10c2.139,0,3.814-0.335,5.396-1.084l0.235-0.105c1.399-0.699,2.468-1.893,3.388-3.834  l3.924,4.051C18.08,9.921,16.887,10.988,16.188,12.387z"
      fill="#828282"
    />
  </svg>
)
