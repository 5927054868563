export enum TipoTransferenciaAluno {
  TransferenciaTurma = 'TransferenciaTurma',
  TrocaCurso = 'TrocaCurso'
}

export enum TipoTransferenciaAlunoPorExtenso {
  'TransferenciaTurma' = 'Transferência de Turma',
  'TrocaCurso' = 'Troca de Curso'
}

export enum TipoTransferenciaAlunoPorExtensoMinusculo {
  'TransferenciaTurma' = 'transferência de turma',
  'TrocaCurso' = 'troca de curso'
}
