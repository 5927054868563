import { colunaNomeAluno } from './nome-aluno'
import { colunaNota } from './nota'
import { colunaSituacaoFinanceira } from './situacao-financeira'
import { colunaTentativasRestantes } from './tentativas-restantes'

export const COLUNAS_DE_NOTAS_E_PRESENCA_NA_TURMA = [
  colunaNomeAluno(),
  colunaSituacaoFinanceira(),
  colunaTentativasRestantes(),
  colunaNota()
]
