import { ModalidadeCurso } from 'src/tipos'

import { PadraoDeDisposicao } from './padrao-de-disposicao.interface'

export const padraoCursosLivres: PadraoDeDisposicao = {
  disposicao: 4,
  condicao: [
    ModalidadeCurso.Livre,
    ModalidadeCurso.Livre,
    ModalidadeCurso.Livre,
    ModalidadeCurso.Livre
  ]
}

export const padraoPosGraduacaoEad: PadraoDeDisposicao = {
  disposicao: 2,
  condicao: [ModalidadeCurso.PosGraduacaoEad, ModalidadeCurso.PosGraduacaoEad]
}

export const padraoPosGraduacaoEadComLivres: PadraoDeDisposicao = {
  disposicao: 3,
  condicao: [
    ModalidadeCurso.PosGraduacaoEad,
    ModalidadeCurso.Livre,
    ModalidadeCurso.Livre
  ]
}

export const padroes: PadraoDeDisposicao[] = [
  padraoCursosLivres,
  padraoPosGraduacaoEad,
  padraoPosGraduacaoEadComLivres
]
