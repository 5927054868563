import React from 'react'

export default (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.24264 1L2 5.24264L6.24264 9.48528"
      stroke="#606060"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
