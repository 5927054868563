import React from 'react'

export default (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#91C83E" />
    <path
      d="M12.623 5.90941L11.285 4.57138L6.85643 8.94764L4.76589 6.85709L3.42786 8.19512L6.85643 11.6237L12.623 5.90941Z"
      fill="white"
    />
  </svg>
)
