import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  background: ${Cores.PRIMARIA_2};
  padding: 25px 50px;
  box-shadow: 4px 8px 16px rgba(1, 59, 154, 0.24);
  border: 1px solid ${Cores.CINZA_3_CLARO};
  border-radius: 8px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  color: ${Cores.BRANCO};

  svg {
    circle {
      stroke: ${Cores.BRANCO};
    }
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    color: ${Cores.BRANCO};
  }
`
