import styled, { css } from 'styled-components'

import { Cores } from '..'

interface CircleProps {
  size: 'big' | 'small'
  alignContent?: 'bottom'
}

export const Container = styled.div`
  justify-content: center;
`

export const Circle = styled.div<CircleProps>`
  display: flex;
  justify-content: center;
  border-radius: 25px;
  align-items: center;

  ${props =>
    props.size === 'big' &&
    css`
      width: 100px;
      min-width: 100px;
      height: 100px;
      border: 1px solid #dbdbdb;
      border-radius: 50%;
    `}
  ${props =>
    props.size === 'small' &&
    css`
      border: 1px solid #009b9b;
      width: 40px;
      height: 40px;
      z-index: 2;
      cursor: pointer;
      background-color: #ffffff;
    `}
    ${props =>
    props.alignContent === 'bottom' &&
    css`
      display: flex;
      align-items: flex-end;
      box-shadow: inset 0 0 1px grey;
    `}
`

export const CircleContainer = styled.div`
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;

  .rounded-circle {
    border-radius: 50%;
  }
`

export const SmallCircleContainer = styled.div`
  position: relative;
  right: 26%;
  top: 32%;
  max-height: 40px;
  cursor: pointer;
`

export const InputCamera = styled.input`
  position: absolute;
  top: 0%;
  min-width: 30px;
  width: 40px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
  display: none;
`

export const FotoLabel = styled.label`
  float: left;
  margin-right: 1em;
  margin-bottom: 0;
  position: relative;
  top: 10%;
  left: 1%;
`

export const MaxSizeLabel = styled.p`
  color: ${Cores.CINZA_2};
  font-size: 10px;
  text-align: center;
  padding-right: 30px;
`

export const Error = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
`
