import { Responsivo } from 'src/compartilhados'
import styled from 'styled-components'

import { Barra as BarraSuperior } from '../barra-superior/styles'
import { Cores, Medias } from '../styles'

export const Container = styled.div`
  display: flex;
`

export const Hamburguer = styled.div`
  min-width: 72px;
  height: 72px;
  background-color: ${Cores.PRIMARIA_1};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Cores.BRANCO};

  @media ${Medias.TABLET.MAX} {
    cursor: pointer;
    color: ${Cores.PRIMARIA_1};
    background-color: ${Cores.SECUNDARIA_2_ESCURO};
    border-right: 1px solid ${Cores.SECUNDARIA_2_ESCURO};
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  }
`

export const Barra = styled(BarraSuperior)`
  display: flex;
  align-items: center;
  padding: 0 83px;

  @media ${Medias.TABLET.MAX} {
    padding: 0 15px 0 0;

    .logo-ipgs > svg {
      width: 100%;
      max-width: 150px;
    }
  }
`

export const Itens = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;

  & > a {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${Cores.SECUNDARIA_1};

    &:first-child {
      margin-right: 46px;
    }
  }
`

export const Item = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 71px;
  min-width: 71px;
  padding: 16px 22px;
  color: ${Cores.CINZA_1};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @media ${Medias.TABLET.MAX} {
    color: ${Cores.PRIMARIA_1};
    min-width: 75px;
    padding: 16px 10px;
  }
`

export const Usuario = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .mc_caixa_botao > ${Item} {
    padding-right: 33px;

    @media ${Medias.TABLET.MAX} {
      padding-right: 10px;
    }
  }

  .mc_caixa_botao > ${Item} > figure {
    & > img,
    & > svg {
      display: block;
      float: left;
      margin-right: 7px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }

    & > figcaption {
      position: relative;
      float: left;
      line-height: 40px;
      font-size: 12px;
      font-weight: 300;

      &::after {
        transition: transform 0.2s;
        position: absolute;
        top: calc(50% - 4px);
        right: -11px;
        transform: rotate(45deg);
        display: block;
        content: '';
        border: 0.5px solid ${Cores.CINZA_2};
        border-top: none;
        border-left: none;
        width: 5px;
        height: 5px;
      }

      @media ${Medias.TABLET.MAX} {
        visibility: hidden;
        width: 5px;

        &::after {
          border-color: ${Cores.PRIMARIA_1};
          visibility: visible;
          right: unset !important;
        }
      }

      ${props => Responsivo.Mobile(props)`
        line-height: initial;
        top: 20px;
      `}

      ${props => Responsivo.Tablet(props)`
        line-height: initial;
        top: 20px;
      `}
    }
  }

  .mc_caixa_botao {
    &.aberto ${Item}, &:hover ${Item} {
      background-color: ${Cores.PRIMARIA_1};
      color: ${Cores.CINZA_3_MAIS_CLARO};

      & img > svg > path {
        fill: ${Cores.CINZA_3_MAIS_CLARO};
      }

      & img > svg > circle {
        stroke: ${Cores.CINZA_3_MAIS_CLARO};
      }

      & img > svg > rect {
        fill: ${Cores.CINZA_3_MAIS_CLARO};
      }

      & > figure > figcaption {
        &::after {
          border-color: ${Cores.CINZA_3_MAIS_CLARO};
        }
      }
    }

    &.aberto ${Item} {
      & > figure > figcaption {
        &::after {
          top: calc(50% - 1px);
          transform: rotate(225deg);
          border-color: ${Cores.CINZA_3_MAIS_CLARO};
        }
      }
    }
  }
`

export const Menu = styled.div`
  width: 312px;
  border-top: 4px solid ${Cores.PRIMARIA_1};

  .btn-sair {
    justify-content: flex-start;
  }
  .btn-itens {
    justify-content: flex-start;
    font-weight: 500;
    color: ${Cores.SECUNDARIA_2_ESCURO};
  }
`

export const InfosNotificacoes = styled.div`
  padding-bottom: 21px;
  text-align: center;

  & > h3 {
    float: left;
    margin: 19px 0 13px 12px;
    text-transform: uppercase;
    font-size: 14px;
  }

  & > button {
    float: right;
    margin: 17px 15px 0 0;
    border: none;
    background: none;
    font-size: 12px;
    font-weight: 300;
    color: ${Cores.CINZA_2};
  }

  & > ul {
    clear: both;
    margin-bottom: 17px;
    padding: 0;
  }

  & > a {
    font-size: 16px;
    font-weight: 500;
    color: ${Cores.SECUNDARIA_2_ESCURO};
  }
`

export const Notificacoes = styled.div`
  .mc_caixa_botao {
    &.aberto ${Item}, &:hover ${Item} {
      background-color: ${Cores.PRIMARIA_1};
      color: ${Cores.CINZA_3_MAIS_CLARO};

      svg path {
        fill: ${Cores.BRANCO};
      }
    }
  }
`

export const InfosLogin = styled.div`
  padding: 17px 22px 17px 26px;
  display: flex;
  flex-direction: column;

  & > label {
    display: block;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 14px;
  }

  & > a {
    display: block;
    border-top: 1px solid ${Cores.CINZA_3_MAIS_CLARO};
    padding-top: 13px;
    margin-top: 10px;
    color: ${Cores.SECUNDARIA_2_ESCURO};
    text-decoration: none;
  }
`

export const SemNotificacoes = styled.h4`
  float: left;
  font-size: 12px;
  font-weight: 300;
  color: ${Cores.PRETO};
  padding: 16px 15px 15px 12px;
  margin-bottom: 10px;
`
