import { TipoAvaliacao } from 'src/tipos'

import { TipoArquivo, TipoTurma } from '../tipos'

export const TurmaMock = {
  id: '1',
  nome: 'Turma 1 - 2021 EAD',
  tipoTurma: TipoTurma.PRESENCIAL,
  modulos: [
    {
      moduloId: '1',
      nome: 'Módulo 1',
      disciplinas: [
        {
          disciplinaId: '1',
          nome: 'Introdução à psicologia',
          professor: {
            nome: 'Jéssica Stein da Silva',
            id: '06c96a89-2905-4f5c-961f-0d24b20e1f87'
          }
        },
        {
          disciplinaId: '2',
          nome: 'Introdução à psicologia',
          professor: {
            nome: 'Jéssica Stein da Silva',
            id: '06c96a89-2905-4f5c-961f-0d24b20e1f87'
          }
        }
      ],
      avaliacao: {
        id: '1',
        tipo: TipoAvaliacao.AvaliacaoPorModulo,
        tipoArquivo: TipoArquivo.Upload
      }
    },
    {
      moduloId: '2',
      nome: 'Módulo 2',
      disciplinas: [
        {
          disciplinaId: '1',
          nome: 'Aspectos envolvidos no comportamento alimentar',
          professor: { nome: 'João Paulo Souza', id: '2' }
        },
        {
          disciplinaId: '1',
          nome: 'Aspectos envolvidos no comportamento alimentar',
          professor: { nome: 'João Paulo Souza', id: '2' }
        }
      ],
      avaliacao: {
        id: '1',
        tipo: TipoAvaliacao.AvaliacaoPorModulo,
        tipoArquivo: TipoArquivo.Optativa
      }
    }
  ]
}

export const AlunosMock = {
  nomeTurma: 'Turma 1 - 2021 - EAD',
  nomeModulo: 'Módulo 1',
  tutor: 'Jéssica Stein da Silva',
  tipoArquivo: TipoArquivo.Upload,
  alunos: [
    { id: '1', nome: 'Ana Júlia da Rosa', nota: 10, tentativas: 1 },
    { id: '2', nome: 'Pablo Micael Souza', nota: 5, tentativas: 2 }
  ]
}

export const AlunosPresencialMock = {
  nomeTurma: 'Turma 1 - 2021 - Presencial com Avaliação por Disciplina',
  modulo: 'Módulo 1',
  disciplina: 'Fundamentos do comportamento alimentar',
  professor: 'Jéssica Stein da Silva',
  tipoArquivo: TipoArquivo.Upload,
  alunos: [
    {
      id: '1',
      nome: 'João da Silva',
      nota: 10,
      tentativas: 1,
      encontros: [
        { id: '1', data: '12/02', presente: true },
        { id: '2', data: '13/02', presente: false }
      ]
    },
    {
      id: '2',
      nome: 'Paula Pimenta',
      nota: 10,
      tentativas: 1,
      encontros: [
        { id: '11', data: '12/02', presente: true },
        { id: '22', data: '13/02', presente: true }
      ]
    }
  ]
}
