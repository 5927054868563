import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { toast, ToastOptions } from 'react-toastify'

import {
  ResumoDeDocumentosDeSaidaMapper,
  ResumoDeDocumentosAnalisados,
  UploadDeDocumentos,
  UploadDocumentos,
  useRelacaoDeDocumentos,
  selecaoDeDocumentosParaEnvio,
  RelacaoDocumentosLicencaAluno,
  RelacaoDocumentosAlunoBrasileiro,
  ResumoDeDocumentosMultiplosDeEntradaMapper
} from 'src/compartilhados'
import {
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  formatarCpf,
  FuncoesDataHora,
  IconeBack,
  Link,
  Modal,
  ModalRef
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Api } from 'src/servicos'
import {
  DataNascimentoDocumento,
  SituacaoUpload,
  TipoDocumentoAluno
} from 'src/tipos'
import { Usuario } from 'src/tipos/usuario'

import {
  ModalMotivoRejeicao,
  ModalVisualizacaoDocumento,
  ModalVisualizacaoDocumentoRef
} from './componentes'
import { ModalMotivoRejeicaoDados } from './componentes/modal-motivo-rejeicao/modal-motivo-rejeicao.component'
import {
  BotaoDeSalvar,
  ContainerBreadcrumbs,
  ContainerDaPagina,
  ContainerDosBotoes,
  ContainerLink,
  DescricaoContainer,
  Dicas,
  ParagrafoDaNacionalidade,
  TituloComplementar
} from './style'
import { RejeicaoDocumento } from './tipos'

const tituloDaPagina = 'Documentos do aluno'
const erroAoCarregarDados =
  'Ops! Houve algum problema no carregamento dos dados.'
const erroAoEnviarDocumentos = 'Erro ao enviar documentos.'
const erroAoBaixarDocumentos = 'Erro ao baixar documentos.'
const erroAoAtualizarDocumentos = 'Erro ao atualizar documentos.'
const erroAoProcessarDocumentos = 'Erro ao processar documentos.'
const erroAoObterDocumentos = 'Não foi possível obter os documentos do aluno.'
const erroAoObterOsDadosDoUsuario = 'Erro ao obter os dados do usuario.'
const ErroAoEnviarAnalises =
  'Erro ao enviar análise de aprovação de documentos.'
const toastOptions = { type: 'error' } as ToastOptions

export type DocumentosAlunoBrasileiroProps = RouteComponentProps<{
  email: string
}>

const DocumentosAlunoBrasileiro: FC<DocumentosAlunoBrasileiroProps> = ({
  match
}) => {
  const history = useHistory()
  const { documentos, atualizar, reiniciar } = useRelacaoDeDocumentos()
  const [pronto, definirPronto] = useState<boolean>()
  const [carregando, definirCarregando] = useState<boolean>()
  const [dadosDoAluno, definirDadosDoAluno] = useState<Usuario>()
  const [
    rejeicaoDeDocumento,
    definirRejeicaoDeDocumento
  ] = useState<RejeicaoDocumento>()

  const modalDeCancelamentoRef = useRef<ModalRef>(null)
  const modalMotivoRejeicaoRef = useRef<ModalRef>(null)
  const modalVisualizacaoRef = useRef<ModalVisualizacaoDocumentoRef>(null)
  const [mesAnoColacaoGrau, definirMesAnoColacaoGrau] = useState<string>(null)
  const [dataNascimentoDocumentos, definirDataNascimentoDocumentos] = useState<
    DataNascimentoDocumento[]
  >([])

  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const tiposLicenca = useMemo(
    () => RelacaoDocumentosLicencaAluno.map(x => x.tipo),
    [RelacaoDocumentosLicencaAluno]
  )

  const obterAlunoPorEmail = async (email: string): Promise<Usuario> => {
    try {
      return await Api.RequisitarUsuarioPorEmail(email)
    } catch (erro) {
      throw new Error(erroAoObterOsDadosDoUsuario)
    }
  }

  const obterDataNascimentoDocumento = (documentoId: string) =>
    dataNascimentoDocumentos.find(x => x.documentoId === documentoId)
      ?.dataNascimento

  const definirDataNascimentoDocumento = (data: DataNascimentoDocumento) => {
    const filtrado = dataNascimentoDocumentos.filter(
      x => x.documentoId !== data.documentoId
    )

    const dataNascimento =
      data.dataNascimento &&
      data.dataNascimento?.replaceAll('/', '').replaceAll('_', '') !== ''
        ? data.dataNascimento
        : null

    definirDataNascimentoDocumentos([...filtrado, { ...data, dataNascimento }])
  }

  const salvarDataNascimento = (documentos: UploadDocumentos[]) => {
    for (const doc of documentos.filter(x => !!x.dataNascimento)) {
      definirDataNascimentoDocumento({
        documentoId: doc.documentoId,
        dataNascimento: doc.dataNascimento
          ? FuncoesDataHora.novaData(doc.dataNascimento)
          : null
      })
    }
  }

  const obterResumoDeDocumentos = async (idDoUsuario: string) => {
    try {
      const resposta = await Api.ObterResumoDeDocumentos(idDoUsuario)
      let resultado = ResumoDeDocumentosMultiplosDeEntradaMapper(
        documentos,
        resposta
      )

      const duplicadosAprovados = RelacaoDocumentosAlunoBrasileiro.filter(
        x =>
          x.multiplo &&
          resultado
            .filter(y => y.tipo === x.tipo)
            .every(z => z.status === SituacaoUpload.Aprovado)
      )

      if (duplicadosAprovados?.length) {
        const duplicados = duplicadosAprovados.map(x => {
          return RelacaoDocumentosAlunoBrasileiro.find(y => y.tipo === x.tipo)
        })

        resultado = [...resultado, ...duplicados]
      }

      reiniciar(resultado)
      salvarDataNascimento(resultado)
    } catch (erro) {
      throw new Error(erroAoObterDocumentos)
    }
  }

  const uploadDeCriacao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EnviarDocumentosDoAluno(
        dadosDoAluno.id,
        formulario
      )

      if (resposta) {
        history.goBack()
      } else {
        toast(erroAoEnviarDocumentos, toastOptions)
      }
    } catch (erro) {
      throw new Error(erroAoEnviarDocumentos)
    }
  }

  const uploadDeEdicao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EditarDocumentosDoAluno(
        dadosDoAluno.id,
        formulario
      )

      if (resposta) {
        history.goBack()
      } else {
        toast(erroAoAtualizarDocumentos, toastOptions)
      }
    } catch (erro) {
      throw new Error(erroAoAtualizarDocumentos)
    }
  }

  const uploadDeAnalises = async (
    analises: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const resultado = ResumoDeDocumentosAnalisados(analises)

      if (resultado && resultado.documentos.length > 0) {
        const resposta = await Api.AprovacaoDeDocumentosDoAluno(resultado)

        if (resposta) {
          history.goBack()
        } else {
          toast(ErroAoEnviarAnalises, toastOptions)
        }
      }
    } catch (erro) {
      throw new Error(ErroAoEnviarAnalises)
    }
  }

  const uploadDeDocumentos = async (
    edicoes: UploadDocumentos[],
    criacoes: UploadDocumentos[],
    analises: UploadDocumentos[]
  ): Promise<void> => {
    try {
      definirCarregando(true)
      if (edicoes.length > 0) await uploadDeEdicao(edicoes)
      if (criacoes.length > 0) await uploadDeCriacao(criacoes)
      if (analises.length > 0) await uploadDeAnalises(analises)
    } catch (erro) {
      toast(erroAoProcessarDocumentos, toastOptions)
    } finally {
      definirCarregando(false)
    }
  }

  const atualizarMesAnoColacaoGrau = async (): Promise<boolean> => {
    try {
      definirCarregando(true)
      const dataColacaoGrauFinal =
        !mesAnoColacaoGrau || mesAnoColacaoGrau === ''
          ? null
          : FuncoesDataHora.transformarPadraoAmericano(mesAnoColacaoGrau)

      await Api.AtualizarDataColacaoGrau(dadosDoAluno.id, dataColacaoGrauFinal)

      toast('Data da colação de grau salva com sucesso!', { type: 'success' })
      return true
    } catch {
      toast('Erro ao atualizar data da colação de grau', { type: 'error' })
      return false
    } finally {
      definirCarregando(false)
    }
  }
  const atualizarDataNascimentoDocumentos = async (): Promise<boolean> => {
    try {
      if (!dataNascimentoDocumentos || dataNascimentoDocumentos.length <= 0) {
        return true
      }

      definirCarregando(true)
      const data = dataNascimentoDocumentos.map(x => {
        return {
          documentoId: x.documentoId,
          dataNascimento: x.dataNascimento
            ? FuncoesDataHora.transformarPadraoAmericano(x.dataNascimento)
            : null
        } as DataNascimentoDocumento
      })

      await Api.AtualizarDataNascimentoDocumento(dadosDoAluno.id, data)

      toast('Data de nascimento salva com sucesso!', {
        type: 'success'
      })
      return true
    } catch {
      toast('Erro ao atualizar Data de nascimento', { type: 'error' })
      return false
    } finally {
      definirCarregando(false)
    }
  }

  const eventoDeUpload = async (): Promise<void> => {
    if (!(await atualizarMesAnoColacaoGrau())) return

    if (!(await atualizarDataNascimentoDocumentos())) return

    const {
      edicoes,
      criacoes,
      analises,
      contador
    } = selecaoDeDocumentosParaEnvio(documentos)

    if (contador === 0) history.goBack()
    else await uploadDeDocumentos(edicoes, criacoes, analises)
  }

  const obterDownloadDeDocumentos = async (
    documentoId: string
  ): Promise<void> => {
    try {
      await Api.ObterDownloadDeDocumentos(dadosDoAluno.id, documentoId)
    } catch (erro) {
      toast(erroAoBaixarDocumentos, toastOptions)
    }
  }

  const visualizarDocumento = async (documentoId: string) => {
    try {
      const documento = await Api.ObterArquivoDeDocumentos(
        dadosDoAluno.id,
        documentoId
      )
      if (documento) {
        modalVisualizacaoRef?.current?.abrir(documento)
      }
    } catch (error) {
      toast(erroAoObterDocumentos, toastOptions)
    }
  }

  const obterDocumentacao = async (): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const usuario = await obterAlunoPorEmail(match.params.email)

      if (usuario) {
        definirDadosDoAluno(usuario)
        await obterResumoDeDocumentos(usuario.id)

        const colacaoGrau = usuario.mesAnoColacaoGrau
          ? FuncoesDataHora.novaData(usuario.mesAnoColacaoGrau)
          : null

        definirMesAnoColacaoGrau(colacaoGrau)
      } else {
        throw new Error(erroAoCarregarDados)
      }
    } catch (error) {
      const mensagens = erroAoCarregarDados
      const acaoVoltar = () => history.goBack()
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const aprovacao = (id: string): void => {
    const documento = documentos.find(documento => documento.id === id)
    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Aprovado
    } as UploadDocumentos

    atualizar(modificacao)
  }

  const rejeicao = (id: string): void => {
    definirRejeicaoDeDocumento({ id })
    modalMotivoRejeicaoRef?.current?.abrir()
  }

  const obterMotivoDaRejeicao = (dados: ModalMotivoRejeicaoDados): void => {
    const documento = documentos.find(
      documento => documento.id === rejeicaoDeDocumento.id
    )

    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Recusado,
      motivoDaRejeicao: dados.motivoDaRejeicao
    } as UploadDocumentos

    atualizar(modificacao)
    definirRejeicaoDeDocumento(null)
  }

  const documentosAluno = useMemo(() => {
    return documentos.filter(x => !tiposLicenca.some(y => y === x.tipo))
  }, [documentos])

  const documentosLicenca = useMemo(() => {
    return documentos.filter(x => tiposLicenca.some(y => y === x.tipo))
  }, [documentos])

  useEffect(() => {
    obterDocumentacao()
  }, [])

  return pronto && !erro ? (
    <ContainerDaPagina>
      <ContainerLink>
        <Link
          texto="Voltar"
          acaoVoltar={() => history.goBack()}
          icone={IconeBack}
        />
      </ContainerLink>
      <ContainerBreadcrumbs>
        <Breadcrumb
          titulo={tituloDaPagina}
          atalhos={[
            {
              texto: 'Acadêmico',
              acao: () => history.goBack()
            },
            {
              texto: 'Alunos',
              acao: () => history.goBack()
            },
            {
              texto: tituloDaPagina
            }
          ]}
        />
      </ContainerBreadcrumbs>
      <TituloComplementar>{tituloDaPagina}</TituloComplementar>
      <DescricaoContainer>
        <p>Nome: {dadosDoAluno.nomeApresentacao}</p>
        <ParagrafoDaNacionalidade>
          Nacionalidade: {dadosDoAluno.nacionalidade}
        </ParagrafoDaNacionalidade>
        <p>CPF: {formatarCpf(dadosDoAluno.cpf)}</p>
        <p>Documento de Identidade: {dadosDoAluno.documentoIdentidade}</p>
        <p>E-mail: {dadosDoAluno.email}</p>
      </DescricaoContainer>
      <Dicas>
        O envio dos documentos é obrigatório para emissão do certificado do(s)
        curso(s)
      </Dicas>
      {documentosAluno
        .sort((doc1, doc2) => doc1.ordemExibicao - doc2.ordemExibicao)
        .map(documento => (
          <UploadDeDocumentos
            key={documento.id}
            documento={documento}
            eventoDeMudanca={atualizar}
            eventoDeDownload={obterDownloadDeDocumentos}
            eventoDeVisualizacao={visualizarDocumento}
            controleDeAprovacao={{
              ativo: true,
              eventoDeAprovacao: aprovacao,
              eventoDeRejeicao: rejeicao
            }}
            exibirDataColacaoGrau={
              documento.tipo === TipoDocumentoAluno.DiplomaGraduacao
            }
            dataColacaoGrau={
              documento.tipo === TipoDocumentoAluno.DiplomaGraduacao
                ? mesAnoColacaoGrau
                : undefined
            }
            eventoAtualizacaoColacaoGrau={definirMesAnoColacaoGrau}
          />
        ))}
      <TituloComplementar>Licença Maternidade/Paternidade</TituloComplementar>
      <Dicas>
        Documento obrigatório apenas para solicitação de licença
        maternidade/paternidade
      </Dicas>
      {documentosLicenca
        .sort((d1, d2) => d1.ordemExibicao - d2.ordemExibicao)
        .map((documento, indice) => (
          <UploadDeDocumentos
            key={`licenca-${indice}`}
            documento={documento}
            eventoDeMudanca={atualizar}
            eventoDeDownload={obterDownloadDeDocumentos}
            eventoDeVisualizacao={visualizarDocumento}
            controleDeAprovacao={{
              ativo: true,
              eventoDeAprovacao: aprovacao,
              eventoDeRejeicao: rejeicao
            }}
            exibirDataNascimento={
              documento.tipo === TipoDocumentoAluno.CertidaoNascimento
            }
            dataNascimento={obterDataNascimentoDocumento(documento.documentoId)}
            eventoAtualizacaoDataNascimento={
              documento.status === SituacaoUpload.AguardandoAnalise ||
              documento.analisado
                ? definirDataNascimentoDocumento
                : null
            }
          />
        ))}
      <ContainerDosBotoes>
        <Botao
          texto="Cancelar"
          tema="Secundario"
          type="button"
          onClick={() => modalDeCancelamentoRef?.current?.abrir()}
        />
        <BotaoDeSalvar
          texto="Salvar"
          type="button"
          onClick={eventoDeUpload}
          carregando={carregando}
        />
      </ContainerDosBotoes>
      <Modal
        ref={modalDeCancelamentoRef}
        backdrop
        id="modal-de-cancelamento"
        titulo="Deseja cancelar?"
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.goBack()
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamentoRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
      <Modal
        ref={modalDeCancelamentoRef}
        backdrop
        id="modal-de-cancelamento"
        titulo="Deseja cancelar?"
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.goBack()
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamentoRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
      <ModalMotivoRejeicao
        ref={modalMotivoRejeicaoRef}
        backdrop
        id="modal-motivo-rejeicao"
        acaoPrimaria={obterMotivoDaRejeicao}
      />
      <ModalVisualizacaoDocumento
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento-aluno"
      />
    </ContainerDaPagina>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados do aluno..." />
  ) : (
    <ErroContainerLayout />
  )
}

const Container: FC<DocumentosAlunoBrasileiroProps> = (
  props: DocumentosAlunoBrasileiroProps
) => (
  <ErroLayoutContainer.Provider>
    <DocumentosAlunoBrasileiro {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
