import { FormRef } from '../unform'
import { focarPrimeiroCampoComErroLista } from './focarPrimeiroCampoComErro'

const setErrors = (formRef: React.MutableRefObject<FormRef>, ids: string[]) => {
  const validationErrors = {}

  ids.forEach((id: string) => {
    validationErrors[`pergunta[${id}]`] = 'Selecione uma alternativa'
  })

  formRef.current.setErrors(validationErrors)
  focarPrimeiroCampoComErroLista(validationErrors as any)
}

export const validarPesquisa = (
  formRef: React.MutableRefObject<FormRef>,
  dados: any // eslint-disable-line
): boolean => {
  const perguntas = Object.entries(dados.pergunta)
  const vazios = perguntas.filter(x => !x[1])
  if (vazios.length === 0) return true

  const ids = vazios.map(x => x[0])
  setErrors(formRef, ids)
  return false
}
