import { isString } from 'lodash'
import { Material, TipoMaterial } from 'src/tipos'
import { v4 } from 'uuid'

export const materiaisParaFormData = (materiais: Material[]): FormData => {
  const bodyFormData = new FormData()

  const compare = (item1: number, item2: number) => {
    if (item1 < item2) return -1
    if (item1 > item2) return 1
    return 0
  }

  materiais
    .sort((a, b) => compare(a?.ordem ?? 0, b?.ordem ?? 0))
    .forEach((item, index) => (item.ordem = index + 1))

  materiais.forEach(x => {
    const ehAnexo = x.tipo === TipoMaterial.Anexo
    x.id = x.id ?? v4()

    if (ehAnexo) {
      const arquivoAlterado = x.arquivo !== null && !isString(x.arquivo)

      if (arquivoAlterado) {
        x.arquivo = x.arquivo as File
        const [extensao] = x.arquivo.name.split('.').slice(-1)
        const nomeArquivo = `${x.id}.${extensao}`
        bodyFormData.append('anexos', x.arquivo, nomeArquivo)
        bodyFormData.append(
          'anexos_info',
          JSON.stringify({
            id: x.id,
            titulo: x.titulo,
            ordem: x.ordem,
            tipoMaterialComplementar: x.tipoMaterialComplementar
          })
        )
      } else {
        bodyFormData.append(
          'anexos_info',
          JSON.stringify({
            id: x.id,
            titulo: x.titulo,
            ordem: x.ordem,
            tipoMaterialComplementar: x.tipoMaterialComplementar
          })
        )
      }
    } else {
      bodyFormData.append(
        'videos',
        JSON.stringify({
          id: x.id,
          titulo: x.titulo,
          ordem: x.ordem,
          url: x.url,
          tipo: x.tipo,
          tipoMaterialComplementar: x.tipoMaterialComplementar
        })
      )
    }
  })

  return bodyFormData
}
