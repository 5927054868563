import { useState } from 'react'

import { ControleDeErros } from './controle-de-erros.interface'

export const useControleDeErros = <TErro>(
  valorInicial: Array<TErro> = new Array<TErro>()
): ControleDeErros<TErro> => {
  const [erros, alterarErros] = useState<Array<TErro>>(valorInicial)

  const existemErros = (): boolean => erros.length > 0

  const mostrarErros = (): Array<string> => erros.map(erro => erro.toString())

  const adicionarErro = (erro: TErro): void => alterarErros([...erros, erro])

  const limparErros = (): void => alterarErros(new Array<TErro>())

  return {
    existemErros,
    mostrarErros,
    adicionarErro,
    limparErros
  }
}
