import React from 'react'

export default (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#C4C4C4" />
    <circle cx="20" cy="15.7839" r="4.11206" stroke="#E2E2E2" strokeWidth="2" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="10"
      y="20"
      width="20"
      height="10"
    >
      <rect
        x="10.0609"
        y="20.896"
        width="19.8783"
        height="8.43211"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <circle
        cx="20"
        cy="29.2105"
        r="6.67924"
        stroke="#E2E2E2"
        strokeWidth="2"
      />
    </g>
  </svg>
)
