import React from 'react'

export default (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2129 0.778762C14.2129 0.34866 13.8642 0 13.4341 0H0.778824C0.618457 0 0.46196 0.0495374 0.330798 0.141786C-0.0210016 0.389217 -0.105584 0.875026 0.141847 1.22683L4.73035 7.75035L4.74324 12.4963C4.74404 12.7925 4.83213 13.082 4.99644 13.3285C5.4583 14.0213 6.39436 14.2085 7.08719 13.7466L8.72605 12.6541C9.16148 12.3638 9.42205 11.8742 9.41961 11.3509L9.40296 7.77904L14.0684 1.23066C14.1624 1.09869 14.2129 0.940735 14.2129 0.778762ZM7.84314 7.28415L7.86212 11.3581L6.30058 12.3991L6.2866 7.25553L2.27874 1.55752H11.9231L7.84314 7.28415Z"
      fill="white"
      fillOpacity="0.8"
    />
  </svg>
)
