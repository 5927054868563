import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  Carregando,
  SaudacoesDoMural,
  useBarraSuperior
} from 'src/componentes'
import { CenarioFinanceiro } from 'src/paginas/aluno/painel-financeiro/componentes/cenario-financeiro'
import { Api } from 'src/servicos'
import { PainelFinanceiro } from 'src/tipos'

import { Container, Conteudo } from './styles'

export const PaginaInicialResponsavelFinanceiro: React.FC = () => {
  const { usuario } = AutenticacaoContainer.useContainer()
  const barraSuperior = useBarraSuperior()
  const [pronto, definirPronto] = useState(false)

  const [
    situacaoFinanceira,
    definirSituacaoFinanceira
  ] = useState<PainelFinanceiro>()

  const obterFotoUsuario = async () => {
    try {
      const foto = await Api.ObterFotoUsuario(usuario.id)
      if (foto) {
        barraSuperior.atualizarFoto(foto)
      }
    } catch (erro) {
      toast('Erro ao carregar foto do usuário', { type: 'info' })
    }
  }

  const obterPainelFinanceiro = async (): Promise<void> => {
    try {
      definirPronto(false)
      const resposta = await Api.ObterPainelInicialResponsavelFinanceiro(
        usuario.id
      )
      definirSituacaoFinanceira(resposta)
    } catch (erro) {
      toast('Erro ao obter página inicial do responsável financeiro.', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    obterPainelFinanceiro()
  }, [usuario])

  useEffect(() => {
    obterFotoUsuario()
  }, [])

  return pronto ? (
    <Container>
      <SaudacoesDoMural usuario={usuario} />
      <Conteudo>
        <CenarioFinanceiro situacao={situacaoFinanceira} ehResponsavel={true} />
      </Conteudo>
    </Container>
  ) : (
    <Carregando texto="Carregando..." />
  )
}
