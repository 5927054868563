import queryString from 'query-string'
import {
  PesquisaTicketDados,
  PesquisaTicketDTO,
  RequerimentoResponse
} from 'src/dto'
import {
  TipoOrdenacao,
  SetorTicket,
  TipoTicket,
  StatusTicket,
  CategoriaTicket,
  OpcoesPaginacao,
  Documento
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'

const URL_BASE = '/ticket'

export const RequisitarListaTickets = async (
  nomeAluno?: string,
  setores?: SetorTicket[],
  tipos?: TipoTicket[],
  categorias?: CategoriaTicket[],
  status?: StatusTicket[],
  dataCriacaoInicio?: Date,
  dataCriacaoFim?: Date,
  dataEncerramentoInicio?: Date,
  dataEncerramentoFim?: Date,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaTicketDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      nomeAluno,
      setores,
      tipos,
      categorias,
      status,
      dataCriacaoInicio,
      dataCriacaoFim,
      dataEncerramentoInicio,
      dataEncerramentoFim,
      qtdRegistros,
      pagina,
      ordem
    })

    const {
      data: { itens, quantidade }
    } = await gatewayApi.get<PesquisaTicketDados>(`${URL_BASE}?${filtros}`)

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / qtdRegistros)
    }

    return { registros: itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterHistoricoTicket = async (
  idTicket: string
): Promise<PesquisaTicketDTO> => {
  const { data } = await gatewayApi.get<PesquisaTicketDTO>(
    `${URL_BASE}/${idTicket}`
  )

  return data
}

export const FinalizarTicket = async (
  idTicket: string,
  observacao: string
): Promise<void> => {
  await gatewayApi.put(`${URL_BASE}/${idTicket}/finalizar`, { observacao })
}

export const AlterarSetorTicket = async (
  idTicket: string,
  setor: SetorTicket,
  observacao: string
): Promise<void> => {
  await gatewayApi.put(`${URL_BASE}/${idTicket}/alterar-setor`, {
    setor,
    observacao
  })
}

export const ObterInformacaoTicketEmenta = async (
  requerimentoId?: string
): Promise<RequerimentoResponse> => {
  const url = `Requerimento/${requerimentoId}/listar/detalhe-ementa-disciplinas`
  const { data } = await gatewayApi.get<RequerimentoResponse>(url)

  return data
}

export const InserirDocumentoEmenta = async (
  requerimentoId: string,
  copiaEmentaDisciplinasSolicitadasId: string,
  payload: any
): Promise<boolean> => {
  const url = `/Requerimento/${requerimentoId}/criar-upload-ementa-arquivo/${copiaEmentaDisciplinasSolicitadasId}`
  if (payload) {
    await gatewayApi.post(url, payload, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
  return true
}

export const RegistrarDocumentoEmenta = async (
  requerimentoId: string,
  payload: any
): Promise<boolean> => {
  const url = `/Requerimento/${requerimentoId}/registrar-atendimento-ementa-disciplina`

  if (payload) {
    await gatewayApi.post(url, payload)
  }
  return true
}

export const RequisitarDocumentoEmenta = async (
  requerimentoId: string,
  copiaEmentaDisciplinaSolicitacoesId: string
): Promise<Documento> => {
  const resposta = await gatewayApi.get(
    `/Requerimento/${requerimentoId}/ementa-disciplinas/${copiaEmentaDisciplinaSolicitacoesId}/visualizar`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}
