export enum Nacionalidade {
  brasileira,
  outra
}

export enum NacionalidadePorExtenso {
  brasileira = 'brasileira',
  outra = 'outra'
}

export enum NacionalidadeFormatada {
  brasileira = 'Brasileira',
  outra = 'Outra'
}
