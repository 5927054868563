import styled from 'styled-components'

import { Cores } from '../styles/cores'

export const CampoAcao = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  height: 15px;
  width: 100%;
`

export const CampoProximoAnterior = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 24px;
  height: 24px;
  width: 214px;

  span {
    font-family: 'Saira-Extra-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: ${Cores.PRIMARIA_2};
  }

  .anterior,
  .proximo {
    height: 24px;
    border: none;
    cursor: pointer;
    width: 24px;
    justify-content: center;
    align-items: center;
    display: flex;

    svg > path {
      stroke: ${Cores.PRIMARIA_2};
    }
  }
`

export const TituloCalendario = styled.div`
  width: 100%;
  padding: 0 16px;
`

export const CorpoCalendario = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  .selecao-dia {
    font-family: Saira, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Cores.CINZA_1};
  }
`

export const Corpo = styled.div`
  width: 246px;
  border-radius: 8px;
  background: ${Cores.BRANCO};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(7, 30px);
    width: 100%;
    height: 14px;
    align-items: end;
    justify-items: center;
    justify-content: space-around;
    margin-bottom: 14px;
  }

  .dia-semana {
    height: 14px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .dia_do_mes {
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &:hover {
      label {
        span {
          width: 30px;
          height: 30px;
          background: #f2f3f3;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        display: flex;
        justify-content: center;
        color: #2156ce;
        cursor: pointer;
        height: 30px;
      }

      input[type='radio'].sugestao ~ label {
        border: 0px;
      }
    }
  }

  .calenda {
    margin-bottom: 24px;
  }

  .semanas {
    display: grid;
    grid-template-columns: repeat(7, 30px);
    justify-content: space-around;
    align-items: center;
  }

  h3 {
    font-family: 'Saira-Extra-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: ${Cores.PRIMARIA_2};
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked ~ label:hover {
    span {
      background: ${Cores.PRIMARIA_1};
    }
  }

  input[type='radio']:checked ~ label {
    font-family: 'Saira-Semi-Bold', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Cores.PRIMARIA_1};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: ${Cores.BRANCO};
    span {
      font-family: 'Saira-Semi-Bold', sans-serif;
    }
  }

  input[type='radio'].sugestao ~ label {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${Cores.PRIMARIA_1};
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
`

export const Cabecalho = styled.div`
  padding: 24px;
  width: 100%;
  height: 90px;
  border-radius: 8px 8px 0px 0px;
  background: ${Cores.PRIMARIA_2};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-family: Saira, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: ${Cores.BRANCO};
  }

  h3 {
    font-family: Saira, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${Cores.BRANCO};
    mix-blend-mode: normal;
    opacity: 0.7;
  }
`

export const CampoIcone = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ~ #Calendario_input > input {
    background: ${Cores.PRIMARIA_1};
  }

  & > svg {
    fill: ${Cores.CINZA_2};
  }

  & > svg > path {
    fill: inherit;
  }

  &:hover > svg > path {
    fill: ${Cores.PRIMARIA_1};
  }
`

export const Container = styled.div`
  position: relative;

  input[type='tel'] {
    width: 100%;
  }
`

export const Componente = styled.div`
  position: absolute;
  top: 0px;
  box-shadow: 4px 8px 32px ${Cores.SOMBRA_PRIMARIA_2};
  left: 100%;
  display: block;
  &.invisivel {
    display: none;
  }

  &.visivel {
    display: block;
    z-index: 1;
  }
`
