import { ButtonHTMLAttributes } from 'react'

import { FormUnform } from 'src/componentes/unform/form'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Campo = styled.div<{ mr?: boolean }>`
  flex: 1;

  ${({ mr }) =>
    mr &&
    css`
      margin-right: 10px;
    `}
`

export const Dados = styled.div`
  margin-top: 25px;
`

export const BotaoDeAcao = styled.button<
  ButtonHTMLAttributes<HTMLButtonElement>
>`
  cursor: pointer;
  background: transparent;
  border: 0;
  margin-right: 10px;
  align-self: center;
`

export const ContainerDeAcoes = styled.div`
  display: flex;
`

export const IconeDeAcao = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-right: 10px;
`
