import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { Botao } from '../botao'
import { ModalBase } from '../modal-base'
import { Container, Icone, Titulo, Corpo, Acoes } from './styles'
import { ModalProps, ModalRef } from './tipos'

export const ModalComponente: React.ForwardRefRenderFunction<
  ModalRef,
  ModalProps
> = (
  {
    icone,
    titulo,
    backdrop,
    children,
    acaoPrimaria,
    acaoSecundario,
    className,
    ...rest
  },
  ref
) => {
  const [aberta, definirAberta] = useState(false)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      className={className}
      backdrop={backdrop}
      aberta={aberta}
      {...rest}
    >
      <Container>
        {icone && <Icone>{icone}</Icone>}
        <Titulo>{titulo}</Titulo>
        <Corpo>{children}</Corpo>
        <Acoes className="botoes-modal">
          {acaoSecundario && (
            <Botao
              className="btn-acao-modal-secundaria"
              texto={acaoSecundario.titulo}
              tema="Secundario"
              onClick={acaoSecundario.acao}
              type={acaoSecundario.tipo}
              disabled={acaoSecundario.desabilitado}
            />
          )}
          <Botao
            carregando={acaoPrimaria.carregando}
            className="btn-acao-modal-primario"
            texto={acaoPrimaria.titulo}
            tema="Padrao"
            onClick={acaoPrimaria.acao}
            type={acaoPrimaria.tipo}
            disabled={acaoPrimaria.desabilitado}
          />
        </Acoes>
      </Container>
    </ModalBase>
  )
}

export const Modal = forwardRef(ModalComponente)
