import { ModalidadeCurso } from '.'

export enum TipoPesquisaSatisfacao {
  Curso = 'Curso',
  Institucional = 'Institucional',
  Professores = 'Professores'
}

export enum TipoPesquisaSatisfacaoPergunta {
  RespostaUnica = 'RespostaUnica',
  EscalaLinear = 'EscalaLinear',
  Paragrafo = 'Paragrafo'
}

export enum SituacaoPesquisaSatisfacao {
  Ativo,
  Inativo,
  Todos
}

export interface DadosPesquisaSatisfacao {
  codigo: string
  nome: string
  modalidade: ModalidadeCurso | 'Todas'
  situacao: keyof typeof SituacaoPesquisaSatisfacao
  tipo: TipoPesquisaSatisfacao
  dataInicioPesquisaSatisfacao: string
  dataFimPesquisaSatisfacao: string
}

export interface PesquisaSatisfacao {
  id: string
  codigo: string
  nome: string
  modalidade: keyof typeof ModalidadeCurso
  situacao: keyof typeof SituacaoPesquisaSatisfacao
  tipo: keyof typeof TipoPesquisaSatisfacao
  perguntas: PerguntasPesquisaSatisfacao[]
  possuiResposta?: boolean
  possuiCurso?: boolean
  dataFimPesquisaSatisfacao?: Date
}

export interface PerguntasPesquisaSatisfacao {
  id: string
  texto: string
  tipo: TipoPesquisaSatisfacaoPergunta
  ordem?: number
  alternativas: PerguntasPesquisaAlternativa[]
  inicioEscalaLinear?: number
  finalEscalaLinear?: number
  addNaoSeAplica?: boolean
}

export interface PerguntasPesquisaAlternativa {
  id?: string
  texto: string
  ordem?: number
}

export interface PesquisaSatisfacaoRespostaPergunta {
  perguntaId: string
  alternativaId?: string
  respostaParagrafo?: string
}

export interface PesquisaSatisfacaoResposta {
  pesquisaSatisfacaoId: string
  respostas: PesquisaSatisfacaoRespostaPergunta[]
}

export interface PesquisaSatisfacaoSalaAulaLivreResposta
  extends PesquisaSatisfacaoResposta {
  salaAulaLivreId: string
}

export interface PesquisaSatisfacaoSalaAulaOutrosResposta
  extends PesquisaSatisfacaoResposta {
  salaAulaOutrosId: string
  salaAulaOutrosModuloId: string
}

export interface PesquisaSatisfacaoProfessorSalaAulaOutrosResposta
  extends PesquisaSatisfacaoResposta {
  salaAulaOutrosId: string
  salaAulaOutrosDisciplinaId: string
  professorId: string
}
