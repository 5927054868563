import { FormUnform } from 'src/componentes/unform/form'
import styled, { css } from 'styled-components'

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Mr = styled.div`
  margin-right: 10px;
`

export const Campo = styled.div<{ mr?: boolean }>`
  flex: 1;

  ${({ mr }) =>
    mr &&
    css`
      margin-right: 10px;
    `}
`
export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

export const Dados = styled.div`
  margin-top: 25px;
`
