import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  DataInputUnform,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectOpcao,
  SelectUnform,
  Tabela,
  TabelaRef,
  TabelaResposta
} from 'src/componentes'
import { Api } from 'src/servicos'
import {
  RequisitarListaCursosPorTextoFiltro,
  RequisitarListaTurmasPorTexto
} from 'src/servicos/api'
import {
  ModalidadeCurso,
  ModalidadeCursoPorExtenso,
  SituacaoFinanceiraFiltro,
  SituacaoFinanceiraPorExtenso,
  StatusFinanceiro,
  StatusFinanceiroPorExtenso,
  TipoOrdenacao
} from 'src/tipos'

import { colunaAnoFaturamento, COLUNAS_FIXAS } from './componentes'
import { Campo, Container, ContainerExportar, Dados, Form } from './styles'
import { DadosPesquisa, PlanoPagamentoRecorrenteBoleto } from './tipos'

export const PaginaRelatorioFinanceiroGeral: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)
  const [anoFaturamento, definirAnoFaturamento] = useState<number[]>()

  const OPCOES_ANOS = Array.from({ length: 50 }, (x, i) => {
    const contador = `${2020 + i}`
    return { id: contador, texto: contador } as SelectOpcao
  })

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const deParaPlanoPagamento = (opcao?: PlanoPagamentoRecorrenteBoleto) => {
    if (!opcao) return null

    return opcao === PlanoPagamentoRecorrenteBoleto.Recorrente
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioFinanceiroGeral(
      {
        modalidade: dadosPesquisa?.modalidade,
        turmaId: dadosPesquisa?.turma,
        cursoId: dadosPesquisa?.curso,
        situacaoFinanceira: dadosPesquisa?.situacaoFinanceira ?? [
          SituacaoFinanceiraFiltro.Matriculado
        ],
        nome: dadosPesquisa?.nomeAluno,
        inicioMatricula: dadosPesquisa?.inicioMatricula,
        fimMatricula: dadosPesquisa?.fimMatricula,
        statusFinanceiro: dadosPesquisa?.statusFinanceiro,
        pagamentoRecorrente: deParaPlanoPagamento(
          dadosPesquisa?.planoPagamento
        ),
        anosFaturamento: dadosPesquisa?.anosFaturamento ?? [
          new Date().getFullYear()
        ]
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioFinanceiroGeral({
        modalidade: dadosPesquisa?.modalidade,
        turmaId: dadosPesquisa?.turma,
        cursoId: dadosPesquisa?.curso,
        situacaoFinanceira: dadosPesquisa?.situacaoFinanceira ?? [
          SituacaoFinanceiraFiltro.Matriculado
        ],
        nome: dadosPesquisa?.nomeAluno,
        inicioMatricula: dadosPesquisa?.inicioMatricula,
        fimMatricula: dadosPesquisa?.fimMatricula,
        statusFinanceiro: dadosPesquisa?.statusFinanceiro,
        pagamentoRecorrente: deParaPlanoPagamento(
          dadosPesquisa?.planoPagamento
        ),
        anosFaturamento: dadosPesquisa?.anosFaturamento ?? [
          new Date().getFullYear()
        ]
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const COLUNAS = useMemo(() => {
    if (!anoFaturamento || !anoFaturamento?.length) return COLUNAS_FIXAS

    return [
      ...COLUNAS_FIXAS,
      ...anoFaturamento?.map(x => colunaAnoFaturamento(`${x}`))
    ]
  }, [anoFaturamento])

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Situação Financeira Geral"
          atalhos={[
            {
              texto: 'Relatórios Financeiros'
            },
            {
              texto: 'Situação Financeira Geral'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacaoFinanceira: [SituacaoFinanceiraFiltro.Matriculado],
            anosFaturamento: [new Date().getFullYear()]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container pb>
            <Campo mr>
              <SelectUnform
                id="slct_modalidadade"
                name="modalidade"
                label="Modalidade"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Livre],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
                    texto:
                      ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoEadFlex
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoEadFull
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoPresencial
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Graduacao]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Evento],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Evento]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Outra],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Outra]
                  }
                ]}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_ano_faturamento"
                name="anosFaturamento"
                label="Ano Faturamento"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={OPCOES_ANOS}
                valorAlteradoMulti={valores => {
                  if (valores && valores?.length) {
                    definirAnoFaturamento(valores.map(x => +x.id))
                  } else {
                    definirAnoFaturamento([])
                  }
                }}
              />
            </Campo>
          </Container>
          <Container pb>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
                multiplo
              />
            </Campo>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                buscarPorTexto={RequisitarListaCursosPorTextoFiltro}
                buscarPorId={RequisitarListaCursosPorTextoFiltro}
                multiplo
              />
            </Campo>
          </Container>
          <Container pb>
            <Campo mr>
              <SelectUnform
                id="slct_planoPagamento"
                name="planoPagamento"
                label="Plano Pagamento"
                iconeFechar
                margemInferior={false}
                opcoes={[
                  {
                    id:
                      PlanoPagamentoRecorrenteBoleto[
                        PlanoPagamentoRecorrenteBoleto.Recorrente
                      ],
                    texto: 'Recorrente'
                  },
                  {
                    id:
                      PlanoPagamentoRecorrenteBoleto[
                        PlanoPagamentoRecorrenteBoleto.Boleto
                      ],
                    texto: 'Boleto'
                  }
                ]}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_situacaoFinanceira"
                name="situacaoFinanceira"
                label="Situação Matrícula"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro
                          .AguardandoCartaoPagamentoRecorrente
                      ],
                    texto:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro
                          .AguardandoCartaoPagamentoRecorrente
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Cancelado
                      ],
                    texto:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Cancelado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.ContratoRescindido
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.ContratoRescindido
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Finalizado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Finalizado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Inativo
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Inativo
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Matriculado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Matriculado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Reprovado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Reprovado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Reservado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Reservado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Transferido
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Transferido
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Recuperacao
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Recuperacao
                      ]
                  }
                ]}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_status_financeiro"
                name="statusFinanceiro"
                label="Status Financeiro"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id: StatusFinanceiro[StatusFinanceiro.EmDia],
                    texto: StatusFinanceiroPorExtenso[StatusFinanceiro.EmDia]
                  },
                  {
                    id: StatusFinanceiro[StatusFinanceiro.Quitado],
                    texto: StatusFinanceiroPorExtenso[StatusFinanceiro.Quitado]
                  },
                  {
                    id: StatusFinanceiro[StatusFinanceiro.Inadimplente],
                    texto:
                      StatusFinanceiroPorExtenso[StatusFinanceiro.Inadimplente]
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="inicioMatricula"
                label="Início Período Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="fimMatricula"
                label="Fim Período Matrícula"
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={COLUNAS}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}
