import React, { useReducer } from 'react'

import PropTypes from 'prop-types'

import {
  TabelaPaginacaoEstadoContext,
  TabelaPaginacaoAcoesContext
} from './paginacao-context'
import paginacaoReducer from './paginacao-reducer'
import { TabelaPaginacaoProviderProps } from './tipos'

export const TabelaPaginacaoProvider: React.FC<TabelaPaginacaoProviderProps> = ({
  TamanhoPagina,
  Estilo,
  children
}) => {
  const [state, dispatch] = useReducer(paginacaoReducer, {
    Pagina: 1,
    TamanhoPagina,
    TotalRegistros: undefined,
    TotalPaginas: 0,
    Estilo
  })

  return (
    <TabelaPaginacaoEstadoContext.Provider value={state}>
      <TabelaPaginacaoAcoesContext.Provider value={dispatch}>
        {children}
      </TabelaPaginacaoAcoesContext.Provider>
    </TabelaPaginacaoEstadoContext.Provider>
  )
}

TabelaPaginacaoProvider.propTypes = {
  TamanhoPagina: PropTypes.oneOf([10, 25, 50, 100]),
  Estilo: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.oneOf(['Simples', 'Complexo'])
  ]),
  children: PropTypes.element.isRequired
}
