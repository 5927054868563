import { colunaCodigoCurso } from './codigo-curso'
import { colunaDataResposta } from './data-resposta.'
import { colunaModalidade } from './modalidade'
import { colunaNomeCurso } from './nome-curso'
import { colunaNomePesquisa } from './nome-pesquisa'
import { colunaNomeProfessor } from './nome-professor'
import { colunaNomeResposta } from './nome-resposta'
import { colunaNomeTurma } from './nome-turma'
import { colunaOrdemNomeModulo } from './ordem-nome-modulo'
import { colunaPergunta } from './pergunta'
import { colunaPeriodoFim } from './periodo-fim'
import { colunaPeriodoInicio } from './periodo-inicio'
import { colunaResposta } from './resposta'
import { colunaTipoPesquisa } from './tipo-pesquisa'
import { colunaTipoResposta } from './tipo-resposta'

export const COLUNAS_PESQUISA_SATISFACAO = [
  colunaTipoPesquisa(),
  colunaNomePesquisa(),
  colunaPeriodoInicio(),
  colunaPeriodoFim(),
  colunaModalidade(),
  colunaCodigoCurso(),
  colunaNomeCurso(),
  colunaNomeTurma(),
  colunaOrdemNomeModulo(),
  colunaTipoResposta(),
  colunaNomeResposta(),
  colunaDataResposta(),
  colunaPergunta(),
  colunaResposta(),
  colunaNomeProfessor()
]
