import { SituacaoUpload, TipoDocumentoResponsavelFinanceiro } from 'src/tipos'

import { UploadDocumentos } from '../tipos'

export const RelacaoDocumentosResponsavelFinanceiro = new Array<UploadDocumentos>(
  {
    id: 1,
    tipo: TipoDocumentoResponsavelFinanceiro.Identificacao,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 1,
    obrigatorio: true,
    dica: 'Documento obrigatório.'
  } as UploadDocumentos
)
