import { FormaPagamentoCobranca } from './cobranca'

export enum TipoDescontoCupom {
  Porcentagem,
  Valor,
  Todos
}
export enum TiposConta {
  Corrente = 'Corrente',
  Poupanca = 'Poupança'
}

export enum UtilizacaoCupom {
  TaxaMatricula = 'TaxaMatricula',
  PlanoPagamento = 'PlanoPagamento',
  TCC = 'TCC',
  TaxaTrocaCurso = 'TaxaTrocaCurso'
}

export enum UtilizacaoCupomPorExtenso {
  'TaxaMatricula' = 'Taxa de matrícula',
  'PlanoPagamento' = 'Plano de pagamento',
  'TCC' = 'TCC',
  'TaxaTrocaCurso' = 'Taxa de troca de curso'
}

export interface Cupom {
  id?: string
  nome: string
  codigoCupom: string
  modalidade?: string
  tipoDescontoCupom: TipoDescontoCupom
  valorDesconto: number
  porcentagemDesconto?: number
  limitePorCupom?: number
  limitePorCliente?: number
  dataInicio: Date | string
  dataFim: Date | string
  utilizacaoCupom?: string
  cursosId: Array<string>
  planosPagamentoId: Array<string>
  CPF: string
  formaPagamento: FormaPagamentoCobranca
  utilizado: boolean
  validoAVista: boolean
}
