export const DIAS = (): number[] => {
  const meses = []

  for (let i = 1; i <= 25; i++) {
    meses.push(i)
  }

  return meses
}

export const SELECT_DIAS = DIAS().map(item => ({
  id: item.toString(),
  texto: item.toString()
}))

export const DIAS_31 = (): number[] => {
  const meses = []

  for (let i = 1; i <= 31; i++) {
    meses.push(i)
  }

  return meses
}

export const SELECT_DIAS_DIAS_BOLETO_AUTOMATICO = DIAS_31().map(item => ({
  id: item.toString(),
  texto: item.toString()
}))
