import React from 'react'

export default (
  <svg
    width="59"
    height="55"
    viewBox="0 0 59 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.2273 44.805C33.2273 45.3891 32.7731 45.876 32.1889 45.876H25.9581C25.3739 45.876 24.9197 45.3891 24.9197 44.805V38.6392C24.9197 38.0551 25.3739 37.5682 25.9581 37.5682H32.1889C32.7731 37.5682 33.2273 38.0551 33.2273 38.6392V44.805ZM33.1624 32.6679C33.1301 33.0899 32.6432 33.4144 32.0591 33.4144H26.0555C25.4389 33.4144 24.9522 33.0899 24.9522 32.6679L24.4003 17.8374C24.4003 17.6428 24.4978 17.3182 24.7249 17.156C24.9197 16.9937 25.2118 16.799 25.5038 16.799H32.6432C32.9352 16.799 33.2273 16.9937 33.4221 17.156C33.6492 17.3182 33.7467 17.5778 33.7467 17.7725L33.1624 32.6679ZM32.7081 2.35788C31.9941 1.02735 30.5987 0.183594 29.0735 0.183594C27.5483 0.183594 26.1529 1.02735 25.4389 2.35788L0.515784 48.0503C-0.19816 49.3158 -0.165707 50.8735 0.580687 52.1392C1.32708 53.4048 2.69006 54.1836 4.1504 54.1836H53.9966C55.457 54.1836 56.8199 53.4048 57.5663 52.1392C58.3126 50.8735 58.3451 49.3158 57.6313 48.0503L32.7081 2.35788Z"
      fill="#D8D8D8"
    />
  </svg>
)
