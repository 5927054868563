import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  border-radius: 3px;
  padding: 30px;
  box-sizing: border-box;
  margin-right: 21px;
  margin-bottom: 16px;
  margin-top: 10px;
`

export const Conteudo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 4px solid ${Cores.SECUNDARIA_3_CLARO};
  width: 350px;
  padding-left: 16px;
  box-sizing: border-box;
  flex-grow: 1;
`

export const Titulo = styled.div`
  color: ${Cores.SECUNDARIA_3_CLARO};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`

export const Descricao = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  flex-grow: 1;
`

export const VerMais = styled.button`
  color: ${Cores.SECUNDARIA_2_ESCURO};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  margin-top: 22px;

  &:hover {
    text-decoration: underline;
  }
`

export const Alerta = styled.div`
  display: flex;
  align-self: flex-end;
  margin-left: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`

export const DivisorItens = styled.div`
  display: flex;
  justify-content: space-between;
`
