import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  LayoutAuth
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { Api } from 'src/servicos'
import { UsuarioAlteracaoEmail } from 'src/servicos/api/tipos'
import { SituacaoEmailAlterado } from 'src/tipos'

import {
  ModalAvisoAlteracaoEmail,
  ModalAvisoAlteracaoEmailRef
} from './componentes/modal-aviso'
import { schema } from './schema'
import { Form, ResumoUsuario } from './styles'

const PaginaAlterarEmail: React.FC = () => {
  const [carregando, definirCarregando] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [dadosUsuario, definirDadosUsuario] = useState(
    {} as UsuarioAlteracaoEmail
  )
  const [idUsuario, definirIdUsuario] = useState(null)
  const [token, definirToken] = useState(null)
  const [finalTentativas, definirFinalTentativas] = useState(false)
  const modalAvisoRef = useRef<ModalAvisoAlteracaoEmailRef>(null)

  const { erro, definirErro } = ErroLayoutContainer.useContainer()
  const { sair } = AutenticacaoContainer.useContainer()
  const history = useHistory()

  const mensagemModal = (situacao: SituacaoEmailAlterado) => {
    if (
      SituacaoEmailAlterado[SituacaoEmailAlterado.Expirado] ===
      SituacaoEmailAlterado[situacao]
    ) {
      return (
        <>
          <p>Esta solicitação já expirou.</p>
          <p>
            Você tem 24 horas a partir da data da solicitação para confirmar a
            alteração.
          </p>
          <p>
            Se você deseja tentar novamente, realize a alteração na tela de
            Atualização de Cadastro.
          </p>
        </>
      )
    }

    if (
      SituacaoEmailAlterado[SituacaoEmailAlterado.Cancelado] ===
      SituacaoEmailAlterado[situacao]
    ) {
      return (
        <>
          <p>Esta solicitação já foi cancelada.</p>
          <p>
            Se você deseja tentar novamente, realize a alteração na tela de
            Atualização de Cadastro.
          </p>
        </>
      )
    }

    if (
      SituacaoEmailAlterado[SituacaoEmailAlterado.Finalizado] ===
      SituacaoEmailAlterado[situacao]
    ) {
      return (
        <>
          <p>Esta solicitação já foi finalizada.</p>
          <p>Você já pode acessar o portal com seu novo e-mail.</p>
        </>
      )
    }

    return <></>
  }

  const mensagemSucesso = () => {
    return (
      <>
        <p>Alteração efetivada com sucesso!</p>
        <p>Você será redirecionado para a tela de login.</p>
        <p>Utilize seu novo e-mail para acessar o portal.</p>
      </>
    )
  }

  const mensagemSemTentativas = () => {
    return (
      <>
        <p>Senha incorreta!</p>
        <p>Você não possui mais tentativas restantes.</p>
        <p>A solicitação de alteração do e-mail foi cancelada.</p>
        <p>
          Se você deseja tentar novamente, realize a alteração na tela de
          Atualização de Cadastro e, se necessário, redefina a sua senha.
        </p>
      </>
    )
  }

  const trataSituacaoAlteracao = (situacao: SituacaoEmailAlterado) => {
    if (
      SituacaoEmailAlterado[SituacaoEmailAlterado.Aberto] !==
      SituacaoEmailAlterado[situacao]
    ) {
      modalAvisoRef?.current?.abrir(mensagemModal(situacao))
    }
  }

  const carregarSituacaoAlteracao = async () => {
    try {
      definirPronto(false)
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      const id = urlParams.get('id')
      const novoToken = urlParams.get('token')
      definirIdUsuario(id)
      definirToken(novoToken)

      if (!id || !novoToken) {
        throw new Error(
          'Página não encontrada, verifique se você copiou todo o caminho para recuperar sua senha.'
        )
      }

      const dados = await Api.AcessoAlteracaoSenha(id)
      definirDadosUsuario(dados)
      trataSituacaoAlteracao(dados.situacao)
    } catch (error) {
      definirErro({
        mensagens: error.message
      })
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucesso = async ({ senha }: { senha: string }): Promise<void> => {
    try {
      definirCarregando(true)

      const retorno = await Api.AlterarEmail(idUsuario, senha, token)

      if (!retorno || !retorno.sucesso) {
        if (retorno.tentativasRestantes > 0) {
          toast(
            `Senha incorreta! Você tem mais ${retorno.tentativasRestantes} tentativa(s).`,
            { type: 'error' }
          )
        } else {
          definirFinalTentativas(true)
          modalAvisoRef?.current?.abrir(mensagemSemTentativas())
        }
      } else {
        modalAvisoRef?.current?.abrir(mensagemSucesso())
      }
    } finally {
      definirCarregando(false)
    }
  }

  const acaoModal = useMemo(
    () => (finalTentativas ? () => history.push('/') : () => sair(false)),
    [finalTentativas]
  )
  useEffect(() => {
    carregarSituacaoAlteracao()
  }, [])

  return (
    <LayoutAuth>
      {pronto && !erro ? (
        <Form>
          <h1>Confirmar alteração de e-mail</h1>
          <ResumoUsuario>
            <p>Nome: {dadosUsuario.nome}</p>
            <p>Novo E-mail: {dadosUsuario.email}</p>
          </ResumoUsuario>
          <FormUnform schema={schema} acaoSucesso={acaoSucesso}>
            <InputUnform
              type="password"
              id="ipt_senha"
              label="Informe sua Senha"
              name="senha"
              autoComplete="off"
              maxWidthInput="100%"
              obrigatorio
              semValidacaoSenha={true}
            />
            <Botao
              type="submit"
              id="btn-enviar"
              texto="Confirmar alteração"
              carregando={carregando}
            />
          </FormUnform>
        </Form>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando ..." />
      ) : (
        <ErroContainerLayout />
      )}
      <ModalAvisoAlteracaoEmail
        ref={modalAvisoRef}
        acaoPrimaria={acaoModal}
        id="modal-alteracao-email"
        backdrop
      />
    </LayoutAuth>
  )
}

export const AlterarEmail: React.FC = props => {
  return (
    <ErroLayoutContainer.Provider>
      <PaginaAlterarEmail {...props} />
    </ErroLayoutContainer.Provider>
  )
}
