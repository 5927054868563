import React from 'react'

export default (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="#606060"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
      fill="currentColor"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8557 8.1581C16.268 8.52549 16.3045 9.1576 15.9371 9.56997L11.2705 14.8081C11.0883 15.0126 10.8301 15.1334 10.5564 15.1423C10.2826 15.1513 10.0172 15.0475 9.82203 14.8553L6.63156 11.7124C6.23811 11.3248 6.23335 10.6917 6.62093 10.2982C7.0085 9.90478 7.64165 9.90002 8.0351 10.2876L10.4765 12.6926L14.4438 8.23956C14.8112 7.82719 15.4433 7.79072 15.8557 8.1581Z"
      fill="currentColor"
    />
  </svg>
)
