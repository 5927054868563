import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Corpo = styled.div`
  width: 471px;
  height: 495px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:first-child {
    margin-top: 20px;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  form {
    width: 100%;
  }
`

export const CorpoModal = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 68%;

  @media (max-height: 800px) {
    max-height: 78%;
  }
`

export const CorpoModalTCC = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 70%;

  @media (max-height: 800px) {
    max-height: 78%;
  }
`

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 30px 36px 40px 46px;
  height: 100%;

  &:first-child {
    margin-top: 20px;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0px 10px 0px 0px;

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`
export const ContainerDadosGrande = styled.div`
  width: 100%;
`
export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > button:last-child {
    margin-left: 20px;
  }
`
export const ContainerInformacoes = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  h4 {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`
export const ContainerCheckbox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  div {
    margin-right: 50px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    div {
      margin-right: 0px;
    }
    label {
      font-size: 15px;
      padding-top: 3px;
      margin-bottom: 0px;
      display: flex;
    }
  }
`
