import React from 'react'

export default (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46251 12.9936C6.17267 12.7037 6.17267 12.2338 6.46252 11.944L11.9442 6.46232C12.234 6.17247 12.7039 6.17247 12.9938 6.46232C13.2836 6.75216 13.2836 7.22209 12.9938 7.51193L7.51213 12.9936C7.22228 13.2834 6.75236 13.2834 6.46251 12.9936Z"
      fill="#666666"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.60005 6.46232C6.88989 6.17247 7.35982 6.17248 7.64966 6.46232L13.1313 11.944C13.4211 12.2338 13.4211 12.7037 13.1313 12.9936C12.8415 13.2834 12.3715 13.2834 12.0817 12.9936L6.60005 7.51193C6.3102 7.22209 6.3102 6.75216 6.60005 6.46232Z"
      fill="#666666"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 17.5156C13.9269 17.5156 17.5156 13.9269 17.5156 9.5C17.5156 5.07309 13.9269 1.48438 9.5 1.48438C5.07309 1.48438 1.48438 5.07309 1.48438 9.5C1.48438 13.9269 5.07309 17.5156 9.5 17.5156ZM9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
      fill="#666666"
      fillOpacity="0.8"
    />
  </svg>
)
