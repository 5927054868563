import styled from 'styled-components'

export const CorpoModal = styled.div`
  display: flex;
  align-items: flex-end;
  background: white;
  width: 90%;
  height: 90%;
`
export const ContainerDosBotoes = styled.div`
  display: flex;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`
