import React from 'react'

export default (
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.219671 14.0083C-0.0732224 13.7154 -0.0732225 13.2405 0.219671 12.9476L6.0533 7.11396L0.21967 1.28033C-0.0732232 0.987437 -0.0732233 0.512563 0.21967 0.21967C0.512563 -0.0732232 0.987437 -0.0732233 1.28033 0.21967L8.17462 7.11396L1.28033 14.0083C0.987438 14.3011 0.512564 14.3011 0.219671 14.0083Z"
      fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21967 14.0083C5.92678 13.7154 5.92678 13.2405 6.21967 12.9476L12.0533 7.11396L6.21967 1.28033C5.92678 0.987437 5.92678 0.512563 6.21967 0.21967C6.51256 -0.0732232 6.98744 -0.0732233 7.28033 0.21967L14.1746 7.11396L7.28033 14.0083C6.98744 14.3011 6.51256 14.3011 6.21967 14.0083Z"
      fill="#606060"
    />
  </svg>
)
