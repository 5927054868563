import React from 'react'

import { Container } from './styled'
import { ItemNotificacaoProps } from './tipos'

export const ItemNotificacao: React.FC<ItemNotificacaoProps> = ({
  className,
  unread,
  title,
  date,
  description
}) => {
  return (
    <Container className={className} naoLido={unread}>
      <h4>{title}</h4>
      <time>{date}</time>
      <p>{description}</p>
    </Container>
  )
}
