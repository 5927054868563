import { Responsivo } from 'src/compartilhados'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Titulo = styled.h1`
  font-weight: 300;
  font-size: 30px;
  line-height: 35.16px;
`

export const Conteudo = styled.div`
  display: flex;
  margin-top: 26px;

  ${props => Responsivo.Mobile(props)`margin-top: 17px;`}
  ${props => Responsivo.Tablet(props)`margin-top: 25px;`}
`
