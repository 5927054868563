import { DocumentoParaAprovacao, DocumentosParaAprovacao } from 'src/tipos'

import { UploadDocumentos } from '../tipos'

export function ResumoDeDocumentosAnalisados(
  uploads: UploadDocumentos[]
): DocumentosParaAprovacao {
  if (!uploads || uploads.length === 0) {
    return {} as DocumentosParaAprovacao
  }

  const documentos = uploads
    .filter(documento => documento.analisado)
    .map(
      documento =>
        ({
          documentoId: documento.documentoId,
          situacao: documento.status,
          motivoRejeicao: documento.motivoDaRejeicao
        } as DocumentoParaAprovacao)
    )

  return { documentos } as DocumentosParaAprovacao
}
