import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react'

import {
  Botao,
  BotaoStep,
  Checkbox,
  IconeCartaoAmex,
  IconeCartaoMaster,
  IconeCartaoVisa,
  ModalRef,
  PdfViewer,
  FormUnform,
  FormRef,
  InputUnform,
  SelectUnform,
  WizardContainer,
  FuncoesMoeda,
  InputMesAnoUnform,
  IconeInformacao,
  Tooltip,
  Cores,
  scrollParaTopo,
  CartaoCreditoInputUnform,
  focarPrimeiroCampoComErro,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoDiners,
  regExpDiners,
  regExpJcb,
  regExpHiper,
  regExpElo,
  regExpAmex,
  regExpMaster,
  regExpVisa
} from 'src/componentes'
import {
  SELECT_DIAS_DIAS_BOLETO_AUTOMATICO,
  SELECT_METODOSPAGAMENTO
} from 'src/dados-estaticos'
import { Api } from 'src/servicos'
import { BoletoGerado } from 'src/servicos/api/tipos'
import { CupomValido, MetodosPagamento, PagamentoMatricula } from 'src/tipos'

import MatriculaContainer from '../../container'
import {
  schemaPagamentoBoleto,
  schemaPagamentoCartaoCredito,
  schemaPagamentoCartaoRecorrencia,
  schemaPagamentoPlanoIsento
} from '../../schema'
import {
  ContainerConteudo,
  ContainerAcoes,
  ContainerCadastro,
  Linha,
  ContainerMedio,
  ContainerInfos,
  Info,
  TituloIsento,
  ContainerValorTotalCursos
} from '../../styles'
import { ModalPagamentoTaxaTrocaDeCurso } from './modal-pagamento-taxa-troca-curso'
import {
  LinhaBandeiras,
  ContainerBandeiras,
  ContainerBotaoBoleto,
  ContainerBoleto,
  IconeLabel,
  ContainerCupom,
  ContainerInputCupom,
  CupomContainer,
  TituloIsentoRecorrencia
} from './styles'

export const PagamentoTaxaTrocaDeCurso: React.FC = () => {
  const modalRef = useRef<ModalRef>(null)
  const formRef = useRef<FormRef>(null)
  const formCupomRef = useRef<FormRef>(null)

  const {
    curso,
    matricula,
    obterCursoEstado,
    definirPagamento
  } = MatriculaContainer.useContainer()

  const { voltarWizard, passo } = WizardContainer.useContainer()

  const [carregandoBoleto, definirCarregandoBoleto] = useState(false)
  const [carregandoFinalizar, definirCarregandoFinalizar] = useState(false)
  const [carregandoVoltar, definirCarregandoVoltar] = useState(false)

  const [metodoPagamento, definirMetedoPagamento] = useState(
    SELECT_METODOSPAGAMENTO[0].id
  )

  const [boleto, definirBoleto] = useState<BoletoGerado | undefined>(
    matricula.pagamentoMatricula?.boletoGerado || undefined
  )
  const [numeroCartao, definirNumeroCartao] = useState('')
  const [ehPagamentoRecorrente, definirEhPagamentoRecorrente] = useState(false)

  const cursoEstado = useMemo(() => obterCursoEstado(), [curso])
  const [temCupom, definirTemCupom] = useState<boolean>(false)
  const [cupom, definirCupom] = useState<CupomValido>({} as CupomValido)
  const [codigoCupom, definirCodigoCupom] = useState<string>(null)

  const pagamentoComCartao = useMemo(
    () => metodoPagamento && metodoPagamento === SELECT_METODOSPAGAMENTO[0].id,
    [metodoPagamento]
  )

  const boletoGerado = useMemo(() => !!boleto, [boleto])
  const dadosIniciais = useMemo(() => {
    if (matricula.pagamentoMatricula) {
      const {
        metodoPagamento,
        diaVencimento,
        numeroCartao,
        nomeCartao,
        validadeCartao,
        codigoSeguranca,
        parcelas
      } = matricula.pagamentoMatricula

      if (metodoPagamento === SELECT_METODOSPAGAMENTO[0].id) {
        return {
          metodoPagamento,
          diaVencimento,
          numeroCartao,
          nomeCartao,
          validadeCartao,
          codigoSeguranca,
          parcelas
        }
      }

      return {
        metodoPagamento,
        diaVencimento
      }
    }

    return {
      metodoPagamento: SELECT_METODOSPAGAMENTO[0].id
    }
  }, [])

  const valorTaxaTrocaDeCurso = useMemo(() => {
    return ehPagamentoRecorrente || matricula.transferenciaAluno?.taxaIsenta
      ? 0
      : cursoEstado?.taxaTrocaDeCurso ?? 0
  }, [cursoEstado, matricula.transferenciaAluno, ehPagamentoRecorrente])

  const valorTaxaTrocaDeCursoFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(valorTaxaTrocaDeCurso)
  }, [valorTaxaTrocaDeCurso])

  const valorDesconto = useMemo(() => {
    return cupom?.valorDesconto ?? 0
  }, [cupom, cupom.valorDesconto])

  const valorDescontoFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(valorDesconto)
  }, [valorDesconto])

  const valorTotal = useMemo(() => {
    let novoValor = valorTaxaTrocaDeCurso

    if (valorDesconto < novoValor) {
      novoValor -= valorDesconto
    } else {
      novoValor = 0
    }

    return novoValor
  }, [valorTaxaTrocaDeCurso, valorDesconto])

  const valorTotalFormatado = useMemo(
    () => FuncoesMoeda.formatarMoeda(valorTotal),
    [valorTotal]
  )

  const taxaIsenta = useMemo(() => {
    return (ehPagamentoRecorrente && curso?.taxaTrocaDeCurso <= 0) ?? false
  }, [curso, ehPagamentoRecorrente])

  const cupom100SemRecorrencia = useMemo(() => {
    return !ehPagamentoRecorrente && valorDesconto >= valorTotal
  }, [ehPagamentoRecorrente, valorTotal, valorDesconto])

  const exibirCamposCartao = useMemo(() => {
    return (valorTotal > 0 || !taxaIsenta) && !cupom100SemRecorrencia
  }, [valorTotal, taxaIsenta, cupom100SemRecorrencia])

  const tituloPagamento = useMemo(() => {
    if (!ehPagamentoRecorrente) return 'Pagamento da Taxa de Troca de Curso'

    return 'Cartão Pagamento Recorrente'
  }, [valorTotal, ehPagamentoRecorrente])

  const exibirCamposValores = useMemo(
    () => !ehPagamentoRecorrente && valorTaxaTrocaDeCurso > 0,
    [ehPagamentoRecorrente, valorTaxaTrocaDeCurso]
  )
  const parcelasCartaoCredito = useMemo(() => {
    const parcelas = cursoEstado.modalidadesPagamentoTaxaMatricula
      .filter(modalidade => modalidade.formaPagamento === 'CartaoCredito')
      .sort((a, b) => {
        if (a.quantidadeParcelas < b.quantidadeParcelas) {
          return -1
        }
        if (a.quantidadeParcelas > b.quantidadeParcelas) {
          return 1
        }
        return 0
      })
      .map(modalidade => ({
        id: `${modalidade.quantidadeParcelas}`,
        texto: `${
          modalidade.quantidadeParcelas
        }x de ${FuncoesMoeda.formatarMoeda(
          valorTotal / modalidade.quantidadeParcelas
        )}`
      }))

    return parcelas
  }, [cursoEstado.modalidadesPagamentoTaxaMatricula, valorTotal])

  const acaoSucesso = async (dados: PagamentoMatricula) => {
    if (metodoPagamento !== SELECT_METODOSPAGAMENTO[0].id) {
      try {
        definirCarregandoBoleto(true)
        const boletoGerado: BoletoGerado = await Api.GerarBoletoTaxaTrocaCurso({
          idMatricula: matricula.id,
          diaVencimento: dados.diaVencimento,
          cupomId: cupom?.cupomId
        })

        definirBoleto(boletoGerado)
        definirPagamento({
          ...dados,
          boletoGerado,
          valorTotal: valorTotal,
          valor: valorTaxaTrocaDeCurso,
          idCupom: cupom?.cupomId
        })
      } finally {
        definirCarregandoBoleto(false)
      }
    } else {
      try {
        definirCarregandoFinalizar(true)

        const payload = {
          matriculaId: matricula.id,
          cupomId: cupom?.cupomId,
          dadosCartao: {
            nomeImpresso: dados.nomeCartao,
            numero: dados.numeroCartao.replaceAll(' ', ''),
            validade: dados.validadeCartao,
            codigo: dados.codigoSeguranca,
            parcelas: Number(dados.parcelas ?? 1)
          }
        }

        await Api.AvancarPagamentoTaxaTrocaCursoOutrosCursos(payload)

        definirPagamento({
          ...dados,
          valorTotal: valorTotal,
          valor: valorTaxaTrocaDeCurso
        })
        modalRef?.current?.abrir()
      } finally {
        definirCarregandoFinalizar(false)
      }
    }
  }

  const acaoFinalizar = async () => {
    try {
      if (
        metodoPagamento === SELECT_METODOSPAGAMENTO[1].id ||
        (valorTotal < 1 && !ehPagamentoRecorrente)
      ) {
        definirCarregandoFinalizar(true)

        const payload = {
          matriculaId: matricula.id,
          cupomId: cupom?.cupomId
        }

        await Api.AvancarPagamentoTaxaTrocaCursoOutrosCursos(payload)

        modalRef?.current?.abrir()
      } else {
        formRef.current?.submitForm()
      }
    } finally {
      definirCarregandoFinalizar(false)
    }
  }

  const validarCupom = async ({ codigoCupom }: { codigoCupom?: string }) => {
    if (codigoCupom) {
      const novoCupom = await Api.RequisitarValidarCupom(
        matricula.id,
        codigoCupom,
        'TaxaTrocaCurso',
        metodoPagamento as MetodosPagamento
      )

      if (novoCupom) {
        definirCupom({ ...novoCupom, codigoCupom })
        formCupomRef.current?.setFieldError('codigoCupom', 'Cupom válido')
      } else {
        definirCupom({} as CupomValido)
        formCupomRef.current?.setFieldError('codigoCupom', 'Cupom inválido')
      }
    } else {
      definirCupom({} as CupomValido)
      formCupomRef.current?.setFieldError('codigoCupom', '')
    }
  }

  const renderizarBoleto = useCallback(
    () =>
      metodoPagamento === SELECT_METODOSPAGAMENTO[1].id && boleto ? (
        <Linha>
          <ContainerBoleto>
            <h5>
              Boleto gerado com sucesso!
              <br />
              Realize o pagamento do boleto em qualquer banco até a data de
              vencimento
            </h5>
            <p>{boleto.linhaDigitavel}</p>
            <Botao
              tema="Link"
              texto="Copiar Código do Boleto"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(boleto.linhaDigitavel)
              }}
            />
            {!!boleto.linkBoleto && <PdfViewer url={boleto.linkBoleto} />}
          </ContainerBoleto>
        </Linha>
      ) : (
        <></>
      ),
    [metodoPagamento, boleto]
  )

  const schema = useMemo(() => {
    if (taxaIsenta) {
      return schemaPagamentoPlanoIsento
    }

    if (ehPagamentoRecorrente) {
      return schemaPagamentoCartaoRecorrencia
    }

    return metodoPagamento === SELECT_METODOSPAGAMENTO[0].id
      ? schemaPagamentoCartaoCredito
      : schemaPagamentoBoleto
  }, [ehPagamentoRecorrente, metodoPagamento, taxaIsenta])

  const carregarSeEhPagamentoRecorrente = async () => {
    const pagamentoRecorrente = await Api.ObterSeEhPagamentoRecorrente(
      matricula.id
    )
    definirEhPagamentoRecorrente(pagamentoRecorrente.pagamentoRecorrente)
    if (pagamentoRecorrente.pagamentoRecorrente) {
      definirMetedoPagamento(SELECT_METODOSPAGAMENTO[0].id)
    }
  }

  useEffect(() => {
    scrollParaTopo()
  }, [passo])

  useEffect(() => {
    carregarSeEhPagamentoRecorrente()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (metodoPagamento === SELECT_METODOSPAGAMENTO[1].id) {
      formRef.current?.setFieldValue(
        'diaVencimento',
        new Date(new Date().setDate(new Date().getDate() + 2))
          .getDate()
          .toString()
      )
    }
  }, [metodoPagamento])

  useEffect(() => {
    validarCupom({ codigoCupom })
  }, [metodoPagamento])

  return (
    <>
      <ContainerConteudo>
        <ContainerValorTotalCursos>
          <ContainerInfos>
            <h2>Valor Total Taxa Troca de Curso</h2>
            <Info>
              <p>Valor</p>
              <p>{valorTaxaTrocaDeCursoFormatado}</p>
            </Info>
            <Info>
              <p>Cupom de Desconto ( - )</p>
              <p>{valorDescontoFormatado}</p>
            </Info>
            <Info>
              <p>Valor Total a Pagar</p>
              <p>{valorTotalFormatado}</p>
            </Info>
          </ContainerInfos>
          {exibirCamposValores && (
            <ContainerCupom>
              <Checkbox
                id={`chk_cupom_${curso.id}`}
                texto="Tenho um Cupom"
                htmlFor={`chk_tem_cupom_${curso.id}`}
                tamanho="L"
              >
                <input
                  type="checkbox"
                  id={`chk_tem_cupom_${curso.id}`}
                  checked={temCupom}
                  disabled={!pagamentoComCartao && !!boleto}
                  onChange={() => {
                    definirTemCupom(old => !old)
                    definirCupom({} as CupomValido)
                  }}
                />
              </Checkbox>

              {temCupom ? (
                <ContainerInputCupom>
                  <FormUnform
                    dadosIniciais={{
                      codigoCupom:
                        matricula.pagamentoMatricula?.codigoCupom || ''
                    }}
                    ref={formCupomRef}
                    acaoSucesso={validarCupom}
                  >
                    <CupomContainer>
                      <InputUnform
                        id="ipt_cupom"
                        name="codigoCupom"
                        sucesso={!!cupom.cupomId}
                        disabled={!pagamentoComCartao && boletoGerado}
                        onBlur={event => {
                          definirCodigoCupom(event.target.value)
                          if (!pagamentoComCartao && boletoGerado) {
                            return
                          }
                          formCupomRef.current?.submitForm()
                        }}
                        placeholder="Código do cupom"
                      />
                      <Botao
                        id="btn_validar_cupom"
                        type="button"
                        onClick={() => {
                          formCupomRef.current?.submitForm()
                        }}
                        texto="Validar"
                      />
                    </CupomContainer>
                  </FormUnform>
                </ContainerInputCupom>
              ) : (
                <></>
              )}
            </ContainerCupom>
          )}
        </ContainerValorTotalCursos>

        <ContainerCadastro>
          <FormUnform
            ref={formRef}
            schema={schema}
            dadosIniciais={dadosIniciais}
            acaoSucesso={acaoSucesso}
            acaoFalha={focarPrimeiroCampoComErro}
          >
            {ehPagamentoRecorrente && (
              <TituloIsentoRecorrencia>
                ISENTO: Não há taxa a pagar por optar pelo pagamento recorrente.
              </TituloIsentoRecorrencia>
            )}

            {exibirCamposCartao ? (
              <>
                <h4>{tituloPagamento}</h4>
                <Linha>
                  <ContainerMedio>
                    {valorTotal > 0 && (
                      <SelectUnform
                        id="slc_metodo_pagamento"
                        name="metodoPagamento"
                        label="Selecione a Forma de Pagamento"
                        obrigatorio
                        opcoes={SELECT_METODOSPAGAMENTO}
                        valorAlterado={valor => {
                          definirMetedoPagamento(valor.id)
                          definirNumeroCartao('')

                          formRef.current?.setErrors({})
                        }}
                        disabled={boletoGerado}
                      />
                    )}
                    {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id && (
                      <>
                        <LinhaBandeiras>
                          <ContainerBandeiras
                            selecionado={
                              !!numeroCartao.match(regExpVisa) &&
                              !numeroCartao.match(regExpElo)
                            }
                          >
                            {IconeCartaoVisa}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpMaster)}
                          >
                            {IconeCartaoMaster}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpAmex)}
                          >
                            {IconeCartaoAmex}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpElo)}
                          >
                            {IconeCartaoElo}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpHiper)}
                          >
                            {IconeCartaoHiper}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpJcb)}
                          >
                            {IconeCartaoJcb}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpDiners)}
                          >
                            {IconeCartaoDiners}
                          </ContainerBandeiras>
                        </LinhaBandeiras>
                        <CartaoCreditoInputUnform
                          id="ipt_numero_cartao"
                          name="numeroCartao"
                          label="Número do Cartão"
                          obrigatorio
                          onChange={e => {
                            const valor = e.target.value.replaceAll(' ', '')
                            definirNumeroCartao(valor)
                          }}
                        />
                        <InputUnform
                          id="ipt_nome_cartao"
                          name="nomeCartao"
                          label="Nome Conforme Consta no Cartão"
                          obrigatorio
                        />
                        <Linha>
                          <ContainerMedio>
                            <InputMesAnoUnform
                              id="ipt_validade_cartao"
                              name="validadeCartao"
                              label="Validade do Cartão"
                              placeholder="mm/aaaa"
                              formatoAno="AAAA"
                              obrigatorio
                            />
                          </ContainerMedio>
                          <ContainerMedio>
                            <InputUnform
                              id="ipt_codigo_seguranca"
                              name="codigoSeguranca"
                              label={
                                <>
                                  Código de Segurança
                                  <IconeLabel
                                    data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                                    data-for="tooltip-cvv"
                                  >
                                    {IconeInformacao}
                                  </IconeLabel>
                                </>
                              }
                              obrigatorio
                              maxLength={4}
                            />
                            <Tooltip
                              id="tooltip-cvv"
                              place="right"
                              className="tooltip-ipgs"
                              textColor={Cores.PRETO}
                              backgroundColor={Cores.CINZA_1_CLARO}
                            />
                          </ContainerMedio>
                        </Linha>
                      </>
                    )}
                  </ContainerMedio>
                  <ContainerMedio>
                    {!ehPagamentoRecorrente && (
                      <>
                        {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id ? (
                          <SelectUnform
                            id="slc_parcelas"
                            name="parcelas"
                            label="Selecione a Quantidade de Parcelas"
                            obrigatorio
                            iconeFechar
                            opcoes={parcelasCartaoCredito}
                          />
                        ) : (
                          <>
                            <Linha>
                              <ContainerMedio>
                                <SelectUnform
                                  id="slc_dia_vencimento"
                                  name="diaVencimento"
                                  label="Dia de Vencimento"
                                  placeholder="Selecione"
                                  obrigatorio
                                  opcoes={SELECT_DIAS_DIAS_BOLETO_AUTOMATICO}
                                  disabled
                                />
                              </ContainerMedio>
                              {!boleto && (
                                <ContainerBotaoBoleto>
                                  <Botao
                                    texto="Gerar o Boleto"
                                    tema="Secundario"
                                    carregando={carregandoBoleto}
                                  />
                                </ContainerBotaoBoleto>
                              )}
                              {boleto && (
                                <ContainerBotaoBoleto>
                                  <BotaoStep
                                    texto="Concluir"
                                    tamanho={'S'}
                                    textoNegrito="Matrícula"
                                    type="button"
                                    carregando={carregandoFinalizar}
                                    disabled={
                                      metodoPagamento ===
                                        SELECT_METODOSPAGAMENTO[1].id &&
                                      !boleto &&
                                      valorTotal > 0
                                    }
                                    onClick={acaoFinalizar}
                                    textoTooltip={
                                      metodoPagamento ===
                                        SELECT_METODOSPAGAMENTO[1].id &&
                                      !boleto &&
                                      valorTotal > 0
                                        ? 'É necessário realizar a geração do boleto para prosseguir com o pagamento'
                                        : undefined
                                    }
                                  />
                                </ContainerBotaoBoleto>
                              )}
                            </Linha>
                          </>
                        )}
                      </>
                    )}
                  </ContainerMedio>
                </Linha>
              </>
            ) : (
              <TituloIsento>
                ISENTO: Não há valores de matrícula a pagar.
              </TituloIsento>
            )}
            {renderizarBoleto()}
          </FormUnform>
          <ContainerAcoes>
            <BotaoStep
              invertido
              texto="Voltar para"
              textoNegrito={'Termos e Condições'}
              tema="Secundario"
              disabled={carregandoVoltar}
              onClick={() => {
                definirCarregandoVoltar(true)
                voltarWizard()
              }}
            />
            {!boleto && (
              <BotaoStep
                texto="Concluir"
                textoNegrito="Matrícula"
                type="button"
                carregando={carregandoFinalizar}
                disabled={
                  metodoPagamento === SELECT_METODOSPAGAMENTO[1].id &&
                  !boleto &&
                  valorTotal > 0
                }
                onClick={acaoFinalizar}
                textoTooltip={
                  metodoPagamento === SELECT_METODOSPAGAMENTO[1].id &&
                  !boleto &&
                  valorTotal > 0
                    ? 'É necessário realizar a geração do boleto para prosseguir com o pagamento'
                    : undefined
                }
              />
            )}
          </ContainerAcoes>
        </ContainerCadastro>
      </ContainerConteudo>
      <ModalPagamentoTaxaTrocaDeCurso
        ehBoleto={boleto}
        backdrop
        ref={modalRef}
      />
    </>
  )
}
