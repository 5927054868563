import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react'

import {
  Botao,
  FormUnform,
  IconeDownload,
  FormRef,
  IconeArquivo,
  Cores,
  FuncoesDataHora,
  TextArea,
  Spacer,
  scrollParaTopo,
  IconeDeListaDeDocumentos
} from 'src/componentes'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import {
  SalaAulaOutrosEnvioAvaliacaoArquivo,
  StatusCorrecao,
  StatusCorrecaoPorExtenso,
  TipoProfessorModuloTCC
} from 'src/tipos'

import {
  ContainerTitulo,
  Titulo,
  Mensagem,
  Descricao,
  ContainerBotao,
  Tentativas,
  ContainerConteudo,
  Tabela,
  ContainerNotasTCC,
  ListaDicas,
  MensagemPrazoEnvioTCCExpirado
} from '../../../shared/avaliacao/styles'
import { ContainerBotaoLink, ConteudoBotaoLink } from '../../../shared/styles'
import { calcularDistanciaData } from '../../funcoes/calcularDatas'
import { ModalFeedbackProfessor } from '../componentes/modal-feedback'
import { ModalFeedbackRef } from '../componentes/modal-feedback/tipos'
import { schema } from './schema'
import { ArquivoProps } from './tipos'

const Upload: React.FC<ArquivoProps> = ({
  id,
  quantidadeMaximaTentativas,
  quantidadeTentativasRealizadas,
  dataHoraEnvioAvaliacao: data,
  nota,
  nomeAvaliacao,
  descricaoArquivo,
  media,
  nomeArquivo,
  feedBackProfessor,
  downloadArquivo,
  downloadArquivoAvaliacao,
  acaoSucesso,
  notaAproveitamento,
  tcc,
  dataLimiteAvaliacao,
  envios,
  notaTCC
}) => {
  const formRef = useRef<FormRef>()
  const [
    dataHoraEnvioAvaliacao,
    definirDataHoraEnvioAvaliacao
  ] = useState<string>(null)
  const [carregando, definirCarregando] = useState(false)
  const [
    carregandoDownloadArquivo,
    definirCarregandoDownloadArquivo
  ] = useState(false)
  const [
    carregandoDownloadAvaliacao,
    definirCarregandoDownloadAvaliacao
  ] = useState(false)
  const passouDataLimiteAvaliacao =
    calcularDistanciaData(dataLimiteAvaliacao) > 0
  const modalRef = useRef<ModalFeedbackRef>(null)
  const MEDIA_APROVACAO = 7
  const [notasFiltradas, definirNotasFiltradas] = useState<
    SalaAulaOutrosEnvioAvaliacaoArquivo[]
  >()

  useEffect(() => {
    definirDataHoraEnvioAvaliacao(data)
  }, [data])

  const removendoNotaDaBancaNaSegundaTentativa = () => {
    const cloneEnvios = JSON.parse(JSON.stringify(envios))

    if (cloneEnvios.length > 1) {
      cloneEnvios[0].notas = cloneEnvios[0].notas.filter(
        envio => envio?.tipoProfessor === TipoProfessorModuloTCC.Orientador
      )
    }
    definirNotasFiltradas(cloneEnvios)
  }

  useEffect(() => {
    if (envios) {
      removendoNotaDaBancaNaSegundaTentativa()
    }
  }, [envios])

  const tentativasRestantes = useMemo(() => {
    return quantidadeMaximaTentativas - quantidadeTentativasRealizadas
  }, [quantidadeMaximaTentativas, quantidadeTentativasRealizadas])

  const descricao = useMemo(() => {
    if (
      notaAproveitamento &&
      nota !== undefined &&
      nota !== null &&
      nota < media
    ) {
      return 'Parabéns! Você atingiu a média necessária por meio do aproveitamento na transferência de turmas.'
    }

    if (!dataHoraEnvioAvaliacao && nomeArquivo !== null) {
      return 'Faça o download da sua avaliação:'
    }
    if (nota !== null || nota !== undefined) {
      if (nota > media) {
        return `Infelizmente você não atingiu a média necessária${
          tentativasRestantes === 0
            ? 'e já encerrou suas tentativas. Mas fique tranquilo, que ao final do curso você poderá contratar recuperação de até 02 módulos.'
            : '. Mas não se preocupe, você pode tentar novamente. '
        }`
      }

      return `Parabéns! Você atingiu a média necessária${
        notaAproveitamento
          ? ' por meio do aproveitamento na transferência de turmas.'
          : '.'
      }`
    }

    return ''
  }, [tentativasRestantes, dataHoraEnvioAvaliacao])

  const corDescricao = useMemo(() => {
    if (descricao.includes('Infelizmente')) return Cores.PERIGO_ERRO
    if (descricao.includes('Parabéns')) return Cores.SECUNDARIA_3_ESCURO

    return Cores.CINZA_2_MAIS_ESCURO
  }, [descricao])

  const temNota = useMemo(() => nota !== undefined && nota !== null, [nota])

  const ultimoEnvio = useMemo(() => {
    if (tcc && envios?.length >= 1) {
      const ordenados = envios?.sort((a, b) => a.tentativa - b.tentativa)
      return ordenados[ordenados?.length - 1]
    }
  }, [envios])

  const temNotaOrientador = useMemo(
    () =>
      ultimoEnvio?.notas?.find(
        nota =>
          nota.tipoProfessor === TipoProfessorModuloTCC.Orientador &&
          nota.professorNome
      ),
    [ultimoEnvio]
  )

  const aprovado = useMemo(() => nota >= media, [nota, media])
  const aprovadoTCC = useMemo(
    () => temNotaOrientador?.nota >= MEDIA_APROVACAO,
    [temNotaOrientador]
  )

  const enviarAvaliacao = async ({ arquivo }: { arquivo: File | string }) => {
    try {
      definirCarregando(true)

      if (typeof arquivo !== 'string') {
        await acaoSucesso(arquivo)

        scrollParaTopo()
      }
    } finally {
      definirCarregando(false)
    }
  }

  const downloadAvaliacao = async () => {
    try {
      definirCarregandoDownloadAvaliacao(true)
      await downloadArquivo(id)
    } finally {
      definirCarregandoDownloadAvaliacao(false)
    }
  }

  const renderizarBotaoEnviar = useCallback(() => {
    if (
      (!notaAproveitamento &&
        !aprovado &&
        tentativasRestantes > 0 &&
        !passouDataLimiteAvaliacao) ||
      (tcc && !aprovadoTCC && !passouDataLimiteAvaliacao)
    ) {
      return (
        <>
          <ContainerBotao>
            <Botao
              texto={
                quantidadeTentativasRealizadas > 0 && temNota
                  ? 'Enviar Novamente'
                  : 'Enviar Avaliação'
              }
              carregando={carregando}
              onClick={e => {
                e.stopPropagation()
                formRef.current?.submitForm()
              }}
            />
          </ContainerBotao>
          {quantidadeTentativasRealizadas > 0 && (
            <Tentativas>
              Quantidade de tentativas restantes: {tentativasRestantes}
            </Tentativas>
          )}
        </>
      )
    }

    return <></>
  }, [tentativasRestantes, quantidadeTentativasRealizadas, carregando, temNota])

  if (dataHoraEnvioAvaliacao && !feedBackProfessor && !tcc) {
    return (
      <ContainerTitulo>
        <Titulo>{nomeAvaliacao}</Titulo>
        {!!descricaoArquivo && (
          <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
            {descricaoArquivo}
          </Descricao>
        )}
        <Mensagem>
          Avaliação enviada com sucesso em{' '}
          {FuncoesDataHora.dataFormatadaComBarras(
            new Date(dataHoraEnvioAvaliacao)
          )}{' '}
          às{' '}
          {FuncoesDataHora.horaFormatada24H(new Date(dataHoraEnvioAvaliacao))}!
        </Mensagem>
        <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
          Aguarde o feedback do professor.
        </Descricao>

        <ContainerBotaoLink>
          <Botao
            tema="Link"
            onClick={async e => {
              e.stopPropagation()
              await downloadArquivoAvaliacao(id)
            }}
            texto="Realizar download do arquivo enviado"
          />
        </ContainerBotaoLink>

        {tentativasRestantes > 0 && (
          <>
            <ContainerBotao>
              <Botao
                texto="Tentar Novamente"
                carregando={carregando}
                onClick={e => {
                  e.stopPropagation()
                  definirDataHoraEnvioAvaliacao(null)
                }}
              />
            </ContainerBotao>
            <Tentativas>
              Quantidade de tentativas restantes: {tentativasRestantes}
            </Tentativas>
          </>
        )}
      </ContainerTitulo>
    )
  }

  if (temNotaOrientador && !aprovadoTCC && tcc) {
    return (
      <ContainerTitulo>
        <Titulo>{nomeAvaliacao}</Titulo>
        {!!descricaoArquivo && (
          <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
            {descricaoArquivo}
          </Descricao>
        )}
        <ContainerNotasTCC>
          <Tabela>
            <thead>
              <th align="center">Avaliador</th>
              <th align="center">Status</th>
              <th align="center">Nota</th>
              <th align="center">Feedback</th>
            </thead>
            <tbody>
              <tr>
                <td align="center">{temNotaOrientador.tipoProfessor}</td>
                <td align="center">
                  {StatusCorrecaoPorExtenso[temNotaOrientador.statusCorrecao]}
                </td>
                <td align="center">{temNotaOrientador.nota}</td>
                <td align="center">
                  <button
                    type="button"
                    onClick={() =>
                      modalRef?.current?.abrir(temNotaOrientador.feedBack)
                    }
                  >
                    {IconeDeListaDeDocumentos}
                  </button>
                </td>
              </tr>
            </tbody>
          </Tabela>
          <p>
            ATENÇÃO! A nota atribuída pelo orientador não foi suficiente para
            que seu trabalho fosse enviado para avaliação da banca. Acesse o
            feedback do orientador e efetue as alterações necessárias.
          </p>
          <p>
            O novo prazo para reenviar o TCC é até{' '}
            {FuncoesDataHora.dataToLocaleString(dataLimiteAvaliacao)}.
          </p>
        </ContainerNotasTCC>
        <ContainerBotaoLink>
          <Botao
            tema="Link"
            onClick={async e => {
              e.stopPropagation()
              await downloadArquivoAvaliacao(id)
            }}
            texto="Realizar download do arquivo enviado"
          />
        </ContainerBotaoLink>
        {!passouDataLimiteAvaliacao && (
          <>
            <FormUnform
              ref={formRef}
              schema={schema}
              acaoSucesso={enviarAvaliacao}
            >
              <ArquivoInputUnform
                className="ipt_arquivo"
                label="Selecione o Arquivo"
                name="arquivo"
                obrigatorio
              />
            </FormUnform>
            {renderizarBotaoEnviar()}
          </>
        )}

        <ModalFeedbackProfessor ref={modalRef} backdrop id="modal-feedback" />
      </ContainerTitulo>
    )
  }

  if (
    dataHoraEnvioAvaliacao &&
    tcc &&
    !passouDataLimiteAvaliacao &&
    !temNotaOrientador
  ) {
    return (
      <ContainerTitulo>
        <Titulo>{nomeAvaliacao}</Titulo>
        {!!descricaoArquivo && (
          <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
            {descricaoArquivo}
          </Descricao>
        )}
        <Mensagem>
          TCC enviado com sucesso em{' '}
          {FuncoesDataHora.dataFormatadaComBarras(
            new Date(dataHoraEnvioAvaliacao)
          )}{' '}
          às{' '}
          {FuncoesDataHora.horaFormatada24H(new Date(dataHoraEnvioAvaliacao))}.
        </Mensagem>
        <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
          O prazo para correção do trabalho é de 10 dias a contar da data de
          finalização do seu curso. Aguarde o feedback do professor.
        </Descricao>
        <ContainerBotaoLink>
          <Botao
            tema="Link"
            onClick={async e => {
              e.stopPropagation()
              await downloadArquivoAvaliacao(id)
            }}
            texto="Realizar download do arquivo enviado"
          />
        </ContainerBotaoLink>
        <ContainerBotao>
          <Botao
            texto="Tentar Novamente"
            carregando={carregando}
            onClick={e => {
              e.stopPropagation()
              definirDataHoraEnvioAvaliacao(null)
            }}
          />
        </ContainerBotao>
      </ContainerTitulo>
    )
  }

  const resolverMensagem = (temNota: boolean) => {
    if (temNota) {
      return tcc
        ? `Envie o novo arquivo até ${formatadores.dataLocaleString(
            dataLimiteAvaliacao
          )}`
        : 'Envie o novo arquivo com as suas respostas:'
    } else {
      return tcc
        ? `Envie o arquivo até ${formatadores.dataLocaleString(
            dataLimiteAvaliacao
          )}`
        : 'Envie o arquivo com as suas respostas:'
    }
  }
  return (
    <>
      <ContainerTitulo>
        <Titulo>{nomeAvaliacao}</Titulo>
        {!!descricaoArquivo && (
          <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
            {descricaoArquivo}
          </Descricao>
        )}
        {tcc && !notaAproveitamento && !aprovado && !passouDataLimiteAvaliacao && (
          <>
            <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
              Aqui estão algumas dicas antes de realizar o envio de seu
              trabalho!
            </Descricao>
            <ListaDicas>
              <li>Seu trabalho deve ser um artigo de revisão simples;</li>
              <li>
                Não esqueça de utilizar a formatação de acordo com as
                orientações do roteiro para elaboração do TCC;
              </li>
              <li>
                Utilize o Fórum de dúvidas para contato com seu orientador e
                envio das versões preliminares de seu trabalho;
              </li>
              <li>
                Apenas a versão final do TCC deverá ser anexada no campo abaixo,
                para ser avaliada;
              </li>
            </ListaDicas>
          </>
        )}
        {!tcc && temNota && (
          <Mensagem abaixoMedia={nota < media}>
            Sua nota foi:{' '}
            {nota.toLocaleString('pt-BR', {
              minimumSignificantDigits: 2
            })}
            !
          </Mensagem>
        )}
        {!tcc && <Descricao color={corDescricao}>{descricao}</Descricao>}
      </ContainerTitulo>
      {!tcc && feedBackProfessor && (
        <>
          <Spacer padding="10px 0 0 0" />
          <TextArea
            label="Feedback do professor:"
            value={feedBackProfessor}
            disabled
          />
          <Spacer padding="24px 0 0 0" />
        </>
      )}
      {tcc && envios?.length > 0 && (
        <ContainerNotasTCC>
          <Tabela>
            <thead>
              <th align="center">Tentativa</th>
              <th align="center">Avaliador</th>
              <th align="center">Status</th>
              <th align="center">Nota</th>
              <th align="center">Feedback</th>
            </thead>
            <tbody>
              {notasFiltradas?.map((envio, envioIndex) =>
                envio.notas.map((nota, notaIndex) => (
                  <tr key={`envio_${envioIndex}_nota_${notaIndex}`}>
                    <td align="center">{envio.tentativa}</td>
                    <td align="center">{nota.tipoProfessor}</td>
                    <td align="center">
                      {StatusCorrecaoPorExtenso[nota.statusCorrecao]}
                    </td>
                    <td align="center">{nota.nota}</td>
                    <td align="center">
                      <button
                        type="button"
                        onClick={() => modalRef?.current?.abrir(nota.feedBack)}
                        disabled={
                          nota.statusCorrecao ===
                          StatusCorrecao.AguardandoCorrecao
                        }
                      >
                        {IconeDeListaDeDocumentos}
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Tabela>
          <p>Média final: {notaTCC}</p>
        </ContainerNotasTCC>
      )}
      {tcc && passouDataLimiteAvaliacao && !envios?.length && (
        <MensagemPrazoEnvioTCCExpirado>
          Prazo para o envio terminou em{' '}
          {formatadores.dataLocaleString(dataLimiteAvaliacao)}
        </MensagemPrazoEnvioTCCExpirado>
      )}
      <ContainerConteudo>
        {!temNota && nomeArquivo !== null && !passouDataLimiteAvaliacao && (
          <ContainerBotaoLink>
            <Botao
              tema="Link"
              carregando={carregandoDownloadAvaliacao}
              onClick={async () => await downloadAvaliacao()}
              elemento={
                <ConteudoBotaoLink>
                  {IconeArquivo}
                  <p>{nomeArquivo}</p>
                  {IconeDownload}
                </ConteudoBotaoLink>
              }
            />
          </ContainerBotaoLink>
        )}
        {((!notaAproveitamento && !aprovado && !passouDataLimiteAvaliacao) ||
          (tcc && !aprovadoTCC && !passouDataLimiteAvaliacao)) && (
          <>
            <ContainerTitulo>
              {tcc && (
                <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
                  Fique atento para a data limite de postagem!
                </Descricao>
              )}
              <Descricao color={Cores.CINZA_2_MAIS_ESCURO}>
                {resolverMensagem(temNota)}
              </Descricao>
            </ContainerTitulo>
            <FormUnform
              ref={formRef}
              schema={schema}
              acaoSucesso={enviarAvaliacao}
            >
              <ArquivoInputUnform
                className="ipt_arquivo"
                label="Selecione o Arquivo"
                name="arquivo"
                obrigatorio
              />
            </FormUnform>
            <Spacer padding="15px 0 0 0" />
          </>
        )}
        {temNota && (
          <>
            {notaAproveitamento ? (
              <></>
            ) : (
              <ContainerBotaoLink>
                <Botao
                  tema="Link"
                  carregando={carregandoDownloadArquivo}
                  onClick={async e => {
                    try {
                      definirCarregandoDownloadArquivo(true)
                      e.stopPropagation()
                      await downloadArquivoAvaliacao(id)
                    } finally {
                      definirCarregandoDownloadArquivo(false)
                    }
                  }}
                  texto="Realizar download do arquivo enviado"
                />
                {!aprovado && (
                  <Botao
                    tema="Link"
                    carregando={carregandoDownloadAvaliacao}
                    onClick={async e => {
                      e.stopPropagation()
                      await downloadAvaliacao()
                    }}
                    texto="Realizar novamente o download da avaliação"
                  />
                )}
              </ContainerBotaoLink>
            )}
          </>
        )}
      </ContainerConteudo>
      {renderizarBotaoEnviar()}
      <ModalFeedbackProfessor ref={modalRef} backdrop id="modal-feedback" />
    </>
  )
}

export default Upload
