export enum OpcaoTCC {
  Depois = 'Depois',
  Sim = 'Sim',
  Nao = 'Nao',
  NaoAutomatico = 'NaoAutomatico',
  Aproveitamento = 'Aproveitamento',
  Cancelamento = 'Cancelamento',
  Dispensa = 'Dispensa'
}

export enum OpcaoTCCPorExtenso {
  Depois = 'Depois',
  Sim = 'Sim',
  Nao = 'Não',
  NaoAutomatico = 'Não Automático',
  Aproveitamento = 'Aproveitamento',
  Cancelamento = 'Cancelamento',
  Dispensa = 'Dispensa'
}
