import styled from 'styled-components'

export const ContainerInformacoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ColunaInformacoes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
  }
`

export const Divisor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
