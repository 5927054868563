import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react'

import { Botao, ModalBase, ModalBaseProps, ModalRef } from 'src/componentes'

import {
  Apresentacao,
  BotaoDeSalvar,
  BotaoSelecao,
  CampoInput,
  ContainerDosBotoes,
  ContainerInputFile,
  CorpoModal,
  CampoLabel,
  TituloPrincipal
} from '../style'

export interface ModalVisualizacaoContratoRef extends Omit<ModalRef, 'abrir'> {
  abrir: (nome: any) => void
}

export interface ModalVisualizacaoContratoProps
  extends Omit<ModalBaseProps, 'acaoPrimaria'> {
  acaoPrimaria: (dado: any) => Promise<void>
}

const ModalVisualizacaoComponent: React.ForwardRefRenderFunction<
  ModalVisualizacaoContratoRef,
  ModalVisualizacaoContratoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [nomeArquivo, definirNomeArquivo] = useState<string>(null)
  const [nomeInicialArquivo, definirNomeInicialArquivo] = useState<string>(null)
  const [arquivo, definirArquivo] = useState<File>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const abrir = (nome: any) => {
    if (nome === undefined) {
      definirNomeArquivo('')
    } else {
      definirNomeArquivo(nome)
    }

    definirNomeInicialArquivo(nome)
    definirAberta(true)
  }

  const fechar = () => {
    definirNomeArquivo(null)
    definirArquivo(null)
    definirAberta(false)
  }

  useImperativeHandle<
    ModalVisualizacaoContratoRef,
    ModalVisualizacaoContratoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async () => {
    acaoPrimaria(arquivo)
    fechar()
  }

  const manipularAlteracao = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const arquivo = e.target.files?.[0]
    definirNomeArquivo(arquivo?.name)

    if (!arquivo) {
      definirArquivo(null)
      return
    }

    definirArquivo(arquivo)
  }, [])

  return (
    aberta && (
      <ModalBase id="modal-upload-arquivo" backdrop={backdrop} aberta={aberta}>
        <CorpoModal>
          <TituloPrincipal>Anexar Ementa Disciplina</TituloPrincipal>
          <div
            style={{
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <ContainerInputFile>
              <CampoLabel htmlFor={'input-file'}>
                Selecione o Arquivo
              </CampoLabel>
              <input
                ref={inputRef}
                type="file"
                id={'input-file'}
                name={'selecioneArquivo'}
                accept={'.pdf'}
                onChange={manipularAlteracao}
                onReset={() => definirArquivo(null)}
                hidden
              />
              <Apresentacao>
                <CampoInput type="text" value={nomeArquivo} readOnly />
                <BotaoSelecao
                  type="button"
                  onClick={() => {
                    inputRef?.current?.click()
                  }}
                >
                  Procurar
                </BotaoSelecao>
              </Apresentacao>
            </ContainerInputFile>

            <ContainerDosBotoes>
              <Botao
                texto="Cancelar"
                tema="Secundario"
                type="button"
                onClick={fechar}
              />
              <BotaoDeSalvar
                onClick={acaoSucessoForm}
                style={{ padding: '14px 20px' }}
                disabled={
                  arquivo === null || arquivo.name === nomeInicialArquivo
                }
                texto="Anexar"
              />
            </ContainerDosBotoes>
          </div>
        </CorpoModal>
      </ModalBase>
    )
  )
}

export const ModalAdicionarUpload = forwardRef(ModalVisualizacaoComponent)
