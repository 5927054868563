import React, { useEffect, useState, useMemo, useRef } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  IconeDadosPessoais,
  IconePagamentoMatricula,
  IconePlanoPagamento,
  IconeTermosCondicoes,
  Wizard,
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  ModalLogin,
  ModalLoginRef
} from 'src/componentes'
import { RotasApp } from 'src/rotas'
import { Api } from 'src/servicos'
import { DadosMatricula } from 'src/servicos/api/tipos'
import {
  Curso,
  CursosCarrinho,
  EtapasMatriculaCursoLivre,
  EtapasMatriculaOutros,
  ModalidadeCurso,
  ModalidadeCursoPorExtenso,
  SituacaoMatricula,
  Matricula as MatriculaTipo,
  TipoUsuario
} from 'src/tipos'

import MatriculaContainer from './container'
import { DadosPessoaisCursoLivre } from './curso-livre'
import {
  ModalCarrinhoCompraExistente,
  ModalCarrinhoCompraExistenteRef
} from './curso-livre/componentes'
import {
  DadosPessoaisOutrosCursos,
  PlanoPagamentoOutrosCursos,
  TermosCondicoesOutrosCursos
} from './outros-cursos'
import { PagamentoTaxaTrocaDeCurso } from './outros-cursos/pagamento-taxa-troca-curso'
import { Pagamento } from './shared/pagamento-matricula'
import { Container, Icone, Cabecalho } from './styles'
import { ModoMatricula, PaginaMatriculaProps } from './tipos'

const ComponenteMatricula: React.FC<Omit<PaginaMatriculaProps, 'modo'>> = ({
  match
}) => {
  const listaWizardCursoLivre = [
    {
      id: 'etapa-dados-pessoais',
      titulo: 'Dados Pessoais',
      icone: <Icone>{IconeDadosPessoais}</Icone>,
      corpo: <DadosPessoaisCursoLivre />
    },
    {
      id: 'etapa-pagamento-curso',
      titulo: 'Pagamento do Curso',
      icone: <Icone>{IconePagamentoMatricula}</Icone>,
      corpo: <Pagamento />
    }
  ]

  const { usuario } = AutenticacaoContainer.useContainer()
  const {
    modo,
    curso,
    passo,
    carrinhoCurso,
    matricula,
    definirCurso,
    definirCursosCarrinho,
    definirMatricula,
    definirPasso,
    obterCursoEstado,
    definirMatriculaCursosCarrinho
  } = MatriculaContainer.useContainer()
  const [pronto, definirPronto] = useState(false)
  const {
    limparErro,
    definirErro,
    getApiError,
    erro
  } = ErroLayoutContainer.useContainer()
  const history = useHistory()

  const titulo = useMemo(() => {
    const cursoEstado = obterCursoEstado()
    if (!cursoEstado.modalidade) return ''

    if (cursoEstado.modalidade === ModalidadeCurso.PosGraduacaoEadFull) {
      return ModalidadeCursoPorExtenso.PosGraduacaoEad
    }

    return ModalidadeCursoPorExtenso[cursoEstado.modalidade]
  }, [curso, carrinhoCurso])

  const listaWizardCursoOutrosCursos = useMemo(() => {
    const lista = [
      {
        id: 'etapa-dados-pessoais',
        titulo: 'Dados Pessoais',
        icone: <Icone>{IconeDadosPessoais}</Icone>,
        corpo: <DadosPessoaisOutrosCursos />
      },
      {
        id: 'etapa-plano-pagamento',
        titulo: 'Plano de Pagamento',
        icone: <Icone>{IconePlanoPagamento}</Icone>,
        corpo: <PlanoPagamentoOutrosCursos />
      },
      {
        id: 'etapa-termos-condicoes',
        titulo: 'Termos e Condições',
        icone: <Icone>{IconeTermosCondicoes}</Icone>,
        corpo: <TermosCondicoesOutrosCursos />
      }
    ]

    if (matricula?.transferenciaAluno) {
      lista.push({
        id: 'etapa-pagamento-taxa-transferencia',
        titulo: 'Pagamento da Taxa de Troca de Curso',
        icone: <Icone>{IconePagamentoMatricula}</Icone>,
        corpo: <PagamentoTaxaTrocaDeCurso />
      })
    } else {
      lista.push({
        id: 'etapa-pagamento-matricula',
        titulo: 'Pagamento da Matrícula',
        icone: <Icone>{IconePagamentoMatricula}</Icone>,
        corpo: <Pagamento />
      })
    }

    return lista
  }, [matricula])

  const modalLogin = useRef<ModalLoginRef>(null)
  const modalCarrinhoCompraExistente = useRef<ModalCarrinhoCompraExistenteRef>()

  const verificarUsuarioExistenteCpf = async (cpf: string) => {
    if (!cpf) return false

    return await Api.VerificarUsuarioPorCpf(cpf)
  }

  const verificarUsuarioExistenteEmail = async (email: string) => {
    if (!email) return false

    return await Api.VerificarUsuarioPorEmail(email)
  }

  const addMensagemPeriodoMatriculaInvalido = () => {
    const mensagem =
      modo === ModoMatricula.Livre || modo === ModoMatricula.Evento
        ? 'Curso não está mais disponível'
        : 'Fora do período de matrícula.'
    definirErro({
      mensagens: mensagem,
      acaoVoltar: () => history.push(RotasApp.Home)
    })
  }

  const addMensagemPeriodoMatriculaInvalidoCarrinho = (
    carrinho: CursosCarrinho
  ) => {
    const cursosPeriodoInvalido = carrinho.cursos.filter(
      x => !x.periodoMatriculaValido
    )

    for (const item of cursosPeriodoInvalido) {
      toast(
        `Curso ${item.nome} não está mais disponível. Remova ele do carrinho para continuar.`,
        { type: 'error' }
      )
    }
  }

  const carregarCurso = async () => {
    try {
      definirPronto(false)
      limparErro()
      let cursoLocal: Curso
      let cursosCarrinhoLocal: CursosCarrinho
      if (modo === ModoMatricula.Livre || modo === ModoMatricula.Evento) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const siglasCurso = urlParams.getAll('sigla')
        const cpf = urlParams.get('cpf')
        const email = urlParams.get('email')

        if (
          !usuario?.id &&
          ((await verificarUsuarioExistenteCpf(cpf)) ||
            (await verificarUsuarioExistenteEmail(email)))
        ) {
          modalLogin.current?.abrir(cpf ?? email)
        }

        if (siglasCurso.length >= 1) {
          cursosCarrinhoLocal = await Api.RequisitarMatriculaCursosPorSigla(
            siglasCurso
          )
          definirCursosCarrinho(cursosCarrinhoLocal)
        } else {
          cursoLocal = await Api.RequisitarMatriculaCursoPorSigla(
            match.params.sigla
          )
          definirCurso(cursoLocal)
        }
      } else {
        cursoLocal = await Api.RequisitarMatriculaCursoPorTurma(
          match.params.codigoTurma
        )
        definirCurso(cursoLocal)
      }

      const cursoAtual = cursoLocal ?? cursosCarrinhoLocal?.cursos[0]

      if (usuario && usuario.token) {
        let novaMatricula: DadosMatricula = null

        if (modo === ModoMatricula.Livre || modo === ModoMatricula.Evento) {
          novaMatricula = await Api.RequisitarMatriculaPorIdCurso(cursoAtual.id)

          if (cursosCarrinhoLocal?.cursos) {
            const carrinhosExistentes = await Api.VerificarMatriculasCursosCarrinhoCursoLivre(
              cursosCarrinhoLocal?.cursos?.map(c => c.id),
              novaMatricula?.carrinho?.carrinhoCompraCursoLivreId
            )

            if (carrinhosExistentes) {
              try {
                await Api.CadastrarUsuarioAutenticadoCursoLivre({
                  email: usuario.email,
                  celular: usuario.celular,
                  codigoConselho: usuario.codigoConselho,
                  profissao: usuario.profissao,
                  dataNascimento: usuario.dataNascimento,
                  cursosIds: [cursoAtual.id],
                  ehCarrinho: true
                })
              } catch (erro) {
                toast('Houve um erro ao Tentar a recompra. Tente novamente!!')
              }
            }
          }
          const novoPasso =
            (novaMatricula &&
              EtapasMatriculaCursoLivre[novaMatricula.etapaMatricula]) ||
            EtapasMatriculaCursoLivre.DadosPessoais

          if (novoPasso > EtapasMatriculaCursoLivre.DadosPagamento) {
            throw new Error('Você já está matriculado nesse curso.')
          }

          if (usuario.tipoUsuario === TipoUsuario.PJ) {
            throw new Error('Não é permitida a matrícula de Pessoa Jurídica.')
          }

          definirPasso(novoPasso)
        } else {
          novaMatricula = await Api.RequisitarMatriculaPorIdTurma(
            cursoAtual.turmaId
          )

          const novoPasso =
            (novaMatricula &&
              EtapasMatriculaOutros[novaMatricula.etapaMatricula]) ||
            EtapasMatriculaOutros.DadosPessoais

          if (novoPasso > EtapasMatriculaOutros.DadosPagamento) {
            throw new Error('Você já está matriculado nesse curso.')
          }

          if (usuario.tipoUsuario === TipoUsuario.PJ) {
            throw new Error('Não é permitida a matrícula de Pessoa Jurídica.')
          }

          definirPasso(novoPasso)
        }

        if (novaMatricula) {
          const {
            matriculaId,
            situacaoMatricula,
            etapaMatricula,
            pais,
            cep,
            logradouro,
            numero,
            complemento,
            bairro,
            cidade,
            uf,
            planoPagamentoId,
            diaVencimentoParcela,
            codigoCupomPlanoPagamento,
            nomeSocial,
            valorDescontoCupom,
            idCupom,
            valorParcelado,
            carrinho,
            cursoId,
            transferenciaAluno,
            valorParcela,
            quantidadeParcelas,
            formaPagamento,
            responsavelFinanceiro,
            ...dadosPessoais
          } = novaMatricula

          const valorParceladoCupom =
            valorParcelado - valorDescontoCupom < 0
              ? 0
              : valorParcelado - valorDescontoCupom

          definirMatricula({
            id: matriculaId,
            situacaoMatricula,
            etapaMatricula,
            planoPagamento:
              modo === ModoMatricula.Livre || modo === ModoMatricula.Evento
                ? undefined
                : {
                    planoPagamentoId,
                    diaVencimento: `${diaVencimentoParcela}`,
                    idCupom,
                    codigoCupom: codigoCupomPlanoPagamento,
                    valorDesconto: valorDescontoCupom,
                    valorTotal:
                      cursoAtual.modalidadesPagamentoCurso[0].precoBase,
                    valorParcelado:
                      EtapasMatriculaOutros[novaMatricula.etapaMatricula] >
                      EtapasMatriculaOutros.DadosPlanoPagamento
                        ? valorParceladoCupom
                        : null,
                    formPagamentoParcela: formaPagamento,
                    valorParcela: valorParcela,
                    quantidadeParcelas: quantidadeParcelas
                  },
            termosCondicoes:
              modo === ModoMatricula.Livre || modo === ModoMatricula.Evento
                ? undefined
                : {
                    estouCiente:
                      EtapasMatriculaOutros[novaMatricula.etapaMatricula] >
                      EtapasMatriculaOutros.TermosECondicoes,
                    liAceitoTermos:
                      EtapasMatriculaOutros[novaMatricula.etapaMatricula] >
                      EtapasMatriculaOutros.TermosECondicoes
                  },
            dadosPessoais: {
              nome: dadosPessoais.nomeCompleto,
              documentoIdentidade: dadosPessoais.rg,
              nomeSocial: nomeSocial,
              possuiNomeSocial: !!nomeSocial,
              endereco:
                modo === ModoMatricula.Livre || modo === ModoMatricula.Evento
                  ? undefined
                  : {
                      pais,
                      cep,
                      logradouro,
                      numero,
                      complemento,
                      bairro,
                      cidade,
                      uf
                    },
              ...dadosPessoais
            },
            transferenciaAluno: transferenciaAluno,
            cursoId: cursoId,
            responsavelFinanceiro: responsavelFinanceiro ?? {
              possuiResponsavelFinanceiro: false
            }
          })

          if (carrinho) {
            const dadosCarrinho = await Api.ObterCarrinhoCompraCursoLivre(
              carrinho.carrinhoCompraCursoLivreId
            )

            let matriculasCarrinho = []
            if (dadosCarrinho?.matriculas) {
              matriculasCarrinho = dadosCarrinho.matriculas.map(m => {
                const {
                  matriculaId,
                  situacaoMatricula,
                  etapaMatricula,
                  nomeSocial,
                  ...dadosPessoais
                } = m
                return {
                  id: matriculaId,
                  situacaoMatricula,
                  etapaMatricula,
                  planoPagamento: undefined,
                  termosCondicoes: undefined,
                  dadosPessoais: {
                    nome: dadosPessoais.nomeCompleto,
                    documentoIdentidade: dadosPessoais.rg,
                    nomeSocial: nomeSocial,
                    possuiNomeSocial: !!nomeSocial,
                    endereco: undefined,
                    ...dadosPessoais
                  },
                  cursoId: m.cursoId
                }
              })
            }
            definirMatriculaCursosCarrinho({
              ...carrinhoCurso,
              cursos: dadosCarrinho.cursos,
              matriculas: matriculasCarrinho,
              carrinhoCompraId: dadosCarrinho.carrinhoCompraId,
              boletoGerado: dadosCarrinho.boletoGerado
            } as CursosCarrinho)
          }
        } else {
          if (cursosCarrinhoLocal?.cursos?.length > 0) {
            addMensagemPeriodoMatriculaInvalidoCarrinho(cursosCarrinhoLocal)
          } else if (!cursoAtual.periodoMatriculaValido) {
            addMensagemPeriodoMatriculaInvalido()
          }

          if (modo === ModoMatricula.Pos) {
            definirMatricula({
              etapaMatricula: EtapasMatriculaOutros[
                EtapasMatriculaOutros.DadosPessoais
              ] as keyof typeof EtapasMatriculaOutros,
              situacaoMatricula: SituacaoMatricula.Rascunho,
              planoPagamento: {
                valorTotal: cursoAtual.modalidadesPagamentoCurso[0].precoBase,
                formPagamentoParcela: null
              }
            })
          }
        }
      } else {
        if (cursosCarrinhoLocal?.cursos?.length > 0) {
          addMensagemPeriodoMatriculaInvalidoCarrinho(cursosCarrinhoLocal)
        } else if (!cursoAtual.periodoMatriculaValido) {
          addMensagemPeriodoMatriculaInvalido()
        }
      }

      definirPronto(true)
    } catch (error) {
      getApiError(error).then(result => {
        const mensagens = result.join('\n')

        definirErro({
          mensagens: `Ops! Houve algum problema no carregamento dos dados.\n${mensagens}`,
          acaoVoltar: () => history.push(RotasApp.Home)
        })
      })
    }
  }

  const limparCarrinho = async () => {
    definirMatricula({} as MatriculaTipo)
    definirMatriculaCursosCarrinho({} as CursosCarrinho)
  }

  useEffect(() => {
    carregarCurso()
  }, [match.params.sigla, match.params.codigoTurma, usuario?.token])

  return (
    <>
      {' '}
      <ModalLogin
        ref={modalLogin}
        backdrop
        acaoSucesso={() => {
          modalLogin.current?.fechar()
        }}
        acaoCadastrar={() => modalLogin.current?.fechar()}
      />
      <ModalCarrinhoCompraExistente
        ref={modalCarrinhoCompraExistente}
        backdrop
        acaoFinalizar={async () => {
          await limparCarrinho()
          await carregarCurso()
          modalCarrinhoCompraExistente?.current?.fechar()
        }}
      />
      {pronto && !erro ? (
        <Container>
          <Cabecalho>
            <Breadcrumb
              titulo={`Matrícula de ${titulo}`}
              atalhos={[
                {
                  texto: 'Matrícula'
                }
              ]}
            />
          </Cabecalho>
          <Wizard
            passo={passo}
            listaWizard={
              modo === ModoMatricula.Livre || modo === ModoMatricula.Evento
                ? listaWizardCursoLivre
                : listaWizardCursoOutrosCursos
            }
            name="wizard-matricula"
          />
        </Container>
      ) : !pronto && !erro ? (
        <>
          <Carregando texto="Carregando matrícula ..." />
        </>
      ) : (
        <ErroContainerLayout />
      )}
    </>
  )
}

const Matricula: React.FC<PaginaMatriculaProps> = ({ modo, ...props }) => {
  return (
    <MatriculaContainer.Provider initialState={{ passo: 1, modo }}>
      <ErroLayoutContainer.Provider>
        <ComponenteMatricula {...props} />
      </ErroLayoutContainer.Provider>
    </MatriculaContainer.Provider>
  )
}

export default withRouter(Matricula)
