export enum Formacao {
  Medicina = 'Medicina',
  Enfermagem = 'Enfermagem',
  TecnicoEmEnfermagem = 'Técnico em Enfermagem',
  Nutricao = 'Nutrição',
  Odontologia = 'Odontologia',
  Farmacia = 'Farmácia',
  Fisioterapia = 'Fisioterapia',
  Biomedicina = 'Biomedicina',
  Psicologia = 'Psicologia',
  EducacaoFisica = 'Educação Física',
  LicenciaturaEmEducacaoFisica = 'Licenciatura em Educação Física',
  Fonoaudiologia = 'Fonoaudiologia',
  TecnicoEmRadiologia = 'Técnico em Radiologia',
  TecnicoEmAnalisesClinicas = 'Técnico em Análises Clínicas',
  TerapiaOcupacional = 'Terapia Ocupacional',
  EngenhariaCivil = 'Engenharia Civil',
  EngenhariaEletrica = 'Engenharia Elétrica',
  EngenhariaMecanica = 'Engenharia Mecânica',
  EngenhariaDeProducao = 'Engenharia de Produção',
  EngenhariaDeSoftware = 'Engenharia de Software',
  EngenhariaDeComputacao = 'Engenharia de Computação',
  EngenhariaAmbiental = 'Engenharia Ambiental',
  EngenhariaQuimica = 'Engenharia Química',
  EngenhariaDePetroleo = 'Engenharia de Petróleo',
  EngenhariaDeTelecomunicacoes = 'Engenharia de Telecomunicações',
  EngenhariaDeControleEAutomacao = 'Engenharia de Controle e Automação',
  EngenhariaDeMinas = 'Engenharia de Minas',
  EngenhariaDeMateriais = 'Engenharia de Materiais',
  CienciaDaComputacao = 'Ciência da Computação',
  SistemasDeInformacao = 'Sistemas de Informação',
  AnaliseEDesenvolvimentoDeSistemas = 'Análise e Desenvolvimento de Sistemas',
  TecnicoEmInformatica = 'Técnico em Informática',
  TecnicoEmEletrotecnica = 'Técnico em Eletrotécnica',
  TecnicoEmMecatronica = 'Técnico em Mecatrônica',
  TecnicoEmAutomacaoIndustrial = 'Técnico em Automação Industrial',
  Biologia = 'Biologia',
  LicenciaturaEmBiologia = 'Licenciatura em Biologia',
  Agronomia = 'Agronomia',
  MedicinaVeterinaria = 'Medicina Veterinária',
  Zootecnia = 'Zootecnia',
  EngenhariaAgricola = 'Engenharia Agrícola',
  EngenhariaFlorestal = 'Engenharia Florestal',
  CienciasAmbientais = 'Ciências Ambientais',
  Oceanografia = 'Oceanografia',
  TecnicoEmMeioAmbiente = 'Técnico em Meio Ambiente',
  TecnicoEmAgropecuaria = 'Técnico em Agropecuária',
  TecnicoEmAgroecologia = 'Técnico em Agroecologia',
  Direito = 'Direito',
  Historia = 'História',
  LicenciaturaEmHistoria = 'Licenciatura em História',
  Geografia = 'Geografia',
  LicenciaturaEmGeografia = 'Licenciatura em Geografia',
  CienciasSociais = 'Ciências Sociais',
  LicenciaturaEmCienciasSociais = 'Licenciatura em Ciências Sociais',
  Sociologia = 'Sociologia',
  RelacoesInternacionais = 'Relações Internacionais',
  Filosofia = 'Filosofia',
  LicenciaturaEmFilosofia = 'Licenciatura em Filosofia',
  Teologia = 'Teologia',
  Pedagogia = 'Pedagogia',
  Letras = 'Letras',
  LicenciaturaEmLetras = 'Licenciatura em Letras',
  Jornalismo = 'Jornalismo',
  ServicoSocial = 'Serviço Social',
  Psicopedagogia = 'Psicopedagogia',
  CienciaPolitica = 'Ciência Política',
  TecnicoEmTraducaoEInterpretacaoDeLibras = 'Técnico em Tradução e Interpretação de Libras',
  Administracao = 'Administração',
  CienciasContabeis = 'Ciências Contábeis',
  Economia = 'Economia',
  GestaoDePoliticasPublicas = 'Gestão de Políticas Públicas',
  Marketing = 'Marketing',
  ComercioExterior = 'Comércio Exterior',
  GestaoDeRecursosHumanos = 'Gestão de Recursos Humanos',
  Logistica = 'Logística',
  TecnicoEmAdministracao = 'Técnico em Administração',
  TecnicoEmContabilidade = 'Técnico em Contabilidade',
  TecnicoEmSecretariado = 'Técnico em Secretariado',
  DesignGrafico = 'Design Gráfico',
  TecnologoEmDesignGrafico = 'Tecnólogo em Design Gráfico',
  ArtesVisuais = 'Artes Visuais',
  LicenciaturaEmArtesVisuais = 'Licenciatura em Artes Visuais',
  CinemaEAudiovisual = 'Cinema e Audiovisual',
  Teatro = 'Teatro',
  LicenciaturaEmTeatro = 'Licenciatura em Teatro',
  Musica = 'Música',
  LicenciaturaEmMusica = 'Licenciatura em Música',
  DesignDeModa = 'Design de Moda',
  Fotografia = 'Fotografia',
  ArquiteturaEUrbanismo = 'Arquitetura e Urbanismo',
  TecnicoEmDesignDeInteriores = 'Técnico em Design de Interiores',
  TecnicoEmProducaoCultural = 'Técnico em Produção Cultural',
  Turismo = 'Turismo',
  Hotelaria = 'Hotelaria',
  Gastronomia = 'Gastronomia',
  GestaoDeTurismo = 'Gestão de Turismo',
  TecnicoEmGuiaDeTurismo = 'Técnico em Guia de Turismo',
  TecnicoEmEventos = 'Técnico em Eventos',
  TecnicoEmCozinha = 'Técnico em Cozinha',
  FormacaoDeOficiaisDaMarinha = 'Formação de Oficiais da Marinha',
  FormacaoDeOficiaisDoExercito = 'Formação de Oficiais do Exército',
  FormacaoDeOficiaisDaAeronautica = 'Formação de Oficiais da Aeronáutica',
  SegurancaDoTrabalho = 'Segurança do Trabalho',
  TecnicoEmSegurancaDoTrabalho = 'Técnico em Segurança do Trabalho',
  InvestigacaoForenseEPericiaCriminal = 'Investigação Forense e Perícia Criminal',
  TecnicoEmDefesaCivil = 'Técnico em Defesa Civil',
  EducacaoEspecial = 'Educação Especial',
  Libras = 'Libras',
  GestaoEducacional = 'Gestão Educacional',
  TecnicoEmMagisterio = 'Técnico em Magistério',
  Biblioteconomia = 'Biblioteconomia',
  Museologia = 'Museologia',
  CienciaDaInformacao = 'Ciência da Informação',
  Meteorologia = 'Meteorologia',
  Astronomia = 'Astronomia',
  Estudante = 'Estudante',
  Outra = 'Outra'
}

export enum FormacaoPorExtenso {
  Medicina = 'Medicina',
  Enfermagem = 'Enfermagem',
  TecnicoEmEnfermagem = 'TecnicoEmEnfermagem',
  Nutricao = 'Nutricao',
  Odontologia = 'Odontologia',
  Farmacia = 'Farmacia',
  Fisioterapia = 'Fisioterapia',
  Biomedicina = 'Biomedicina',
  Psicologia = 'Psicologia',
  EducacaoFisica = 'EducacaoFisica',
  LicenciaturaEmEducacaoFisica = 'LicenciaturaEmEducacaoFisica',
  Fonoaudiologia = 'Fonoaudiologia',
  TecnicoEmRadiologia = 'TecnicoEmRadiologia',
  TecnicoEmAnalisesClinicas = 'TecnicoEmAnalisesClinicas',
  TerapiaOcupacional = 'TerapiaOcupacional',
  EngenhariaCivil = 'EngenhariaCivil',
  EngenhariaEletrica = 'EngenhariaEletrica',
  EngenhariaMecanica = 'EngenhariaMecanica',
  EngenhariaDeProducao = 'EngenhariaDeProducao',
  EngenhariaDeSoftware = 'EngenhariaDeSoftware',
  EngenhariaDeComputacao = 'EngenhariaDeComputacao',
  EngenhariaAmbiental = 'EngenhariaAmbiental',
  EngenhariaQuimica = 'EngenhariaQuimica',
  EngenhariaDePetroleo = 'EngenhariaDePetroleo',
  EngenhariaDeTelecomunicacoes = 'EngenhariaDeTelecomunicacoes',
  EngenhariaDeControleEAutomacao = 'EngenhariaDeControleEAutomacao',
  EngenhariaDeMinas = 'EngenhariaDeMinas',
  EngenhariaDeMateriais = 'EngenhariaDeMateriais',
  CienciaDaComputacao = 'CienciaDaComputacao',
  SistemasDeInformacao = 'SistemasDeInformacao',
  AnaliseEDesenvolvimentoDeSistemas = 'AnaliseEDesenvolvimentoDeSistemas',
  TecnicoEmInformatica = 'TecnicoEmInformatica',
  TecnicoEmEletrotecnica = 'TecnicoEmEletrotecnica',
  TecnicoEmMecatronica = 'TecnicoEmMecatronica',
  TecnicoEmAutomacaoIndustrial = 'TecnicoEmAutomacaoIndustrial',
  Biologia = 'Biologia',
  LicenciaturaEmBiologia = 'LicenciaturaEmBiologia',
  Agronomia = 'Agronomia',
  MedicinaVeterinaria = 'MedicinaVeterinaria',
  Zootecnia = 'Zootecnia',
  EngenhariaAgricola = 'EngenhariaAgricola',
  EngenhariaFlorestal = 'EngenhariaFlorestal',
  CienciasAmbientais = 'CienciasAmbientais',
  Oceanografia = 'Oceanografia',
  TecnicoEmMeioAmbiente = 'TecnicoEmMeioAmbiente',
  TecnicoEmAgropecuaria = 'TecnicoEmAgropecuaria',
  TecnicoEmAgroecologia = 'TecnicoEmAgroecologia',
  Direito = 'Direito',
  Historia = 'Historia',
  LicenciaturaEmHistoria = 'LicenciaturaEmHistoria',
  Geografia = 'Geografia',
  LicenciaturaEmGeografia = 'LicenciaturaEmGeografia',
  CienciasSociais = 'CienciasSociais',
  LicenciaturaEmCienciasSociais = 'LicenciaturaEmCienciasSociais',
  Sociologia = 'Sociologia',
  RelacoesInternacionais = 'RelacoesInternacionais',
  Filosofia = 'Filosofia',
  LicenciaturaEmFilosofia = 'LicenciaturaEmFilosofia',
  Teologia = 'Teologia',
  Pedagogia = 'Pedagogia',
  Letras = 'Letras',
  LicenciaturaEmLetras = 'LicenciaturaEmLetras',
  Jornalismo = 'Jornalismo',
  ServicoSocial = 'ServicoSocial',
  Psicopedagogia = 'Psicopedagogia',
  CienciaPolitica = 'CienciaPolitica',
  TecnicoEmTraducaoEInterpretacaoDeLibras = 'TecnicoEmTraducaoEInterpretacaoDeLibras',
  Administracao = 'Administracao',
  CienciasContabeis = 'CienciasContabeis',
  Economia = 'Economia',
  GestaoDePoliticasPublicas = 'GestaoDePoliticasPublicas',
  Marketing = 'Marketing',
  ComercioExterior = 'ComercioExterior',
  GestaoDeRecursosHumanos = 'GestaoDeRecursosHumanos',
  Logistica = 'Logistica',
  TecnicoEmAdministracao = 'TecnicoEmAdministracao',
  TecnicoEmContabilidade = 'TecnicoEmContabilidade',
  TecnicoEmSecretariado = 'TecnicoEmSecretariado',
  DesignGrafico = 'DesignGrafico',
  TecnologoEmDesignGrafico = 'TecnologoEmDesignGrafico',
  ArtesVisuais = 'ArtesVisuais',
  LicenciaturaEmArtesVisuais = 'LicenciaturaEmArtesVisuais',
  CinemaEAudiovisual = 'CinemaEAudiovisual',
  Teatro = 'Teatro',
  LicenciaturaEmTeatro = 'LicenciaturaEmTeatro',
  Musica = 'Musica',
  LicenciaturaEmMusica = 'LicenciaturaEmMusica',
  DesignDeModa = 'DesignDeModa',
  Fotografia = 'Fotografia',
  ArquiteturaEUrbanismo = 'ArquiteturaEUrbanismo',
  TecnicoEmDesignDeInteriores = 'TecnicoEmDesignDeInteriores',
  TecnicoEmProducaoCultural = 'TecnicoEmProducaoCultural',
  Turismo = 'Turismo',
  Hotelaria = 'Hotelaria',
  Gastronomia = 'Gastronomia',
  GestaoDeTurismo = 'GestaoDeTurismo',
  TecnicoEmGuiaDeTurismo = 'TecnicoEmGuiaDeTurismo',
  TecnicoEmEventos = 'TecnicoEmEventos',
  TecnicoEmCozinha = 'TecnicoEmCozinha',
  FormacaoDeOficiaisDaMarinha = 'FormacaoDeOficiaisDaMarinha',
  FormacaoDeOficiaisDoExercito = 'FormacaoDeOficiaisDoExercito',
  FormacaoDeOficiaisDaAeronautica = 'FormacaoDeOficiaisDaAeronautica',
  SegurancaDoTrabalho = 'SegurancaDoTrabalho',
  TecnicoEmSegurancaDoTrabalho = 'TecnicoEmSegurancaDoTrabalho',
  InvestigacaoForenseEPericiaCriminal = 'InvestigacaoForenseEPericiaCriminal',
  TecnicoEmDefesaCivil = 'TecnicoEmDefesaCivil',
  EducacaoEspecial = 'EducacaoEspecial',
  Libras = 'Libras',
  GestaoEducacional = 'GestaoEducacional',
  TecnicoEmMagisterio = 'TecnicoEmMagisterio',
  Biblioteconomia = 'Biblioteconomia',
  Museologia = 'Museologia',
  CienciaDaInformacao = 'CienciaDaInformacao',
  Meteorologia = 'Meteorologia',
  Astronomia = 'Astronomia',
  Estudante = 'Estudante',
  Outra = 'Outra'
}
