import * as Yup from 'yup'

export const schema = Yup.object().shape({
  professorOrientador: Yup.string().when('professorBanca', {
    is: professorBanca => professorBanca.length < 1,
    then: Yup.string().required(
      'Professor Orientador e/ou Banca devem ser selecionados.'
    ),
    otherwise: Yup.string()
  })
})
