import { Cores } from 'src/componentes/styles/cores'
import styled, { css } from 'styled-components'

export const Componente = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  &.semMargemAbaixo {
    margin-bottom: 0px;
  }

  ${({ theme: { ComErro } }) =>
    ComErro &&
    css`
      span {
        opacity: 1;
        bottom: -20px;
      }
    `}
`

export const Erro = styled.span<{ sucesso?: boolean }>`
  color: ${props => (props.sucesso ? Cores.SUCESSO : Cores.PERIGO_ERRO)};
  font-size: 10px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  white-space: pre-line;
  opacity: 0;
  transition: opacity 0.1s ease-in, bottom 0.2s ease-in;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`

export const CampoLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: left;
  line-height: 125%;
  margin-bottom: 6px;
  color: ${Cores.CINZA_2_ESCURO};

  :first-letter {
    text-transform: uppercase;
  }
`

export const Apresentacao = styled.div`
  display: flex;
`

export const CampoInput = styled.input<{ comErro: boolean }>`
  outline: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: ${Cores.CINZA_2_ESCURO};
  transition: 0.15s;
  position: relative;
  height: 40px;
  padding: 0px 17px;
  flex: 1;

  ${({ comErro }) =>
    comErro &&
    css`
      border: 1px solid ${Cores.PERIGO_ERRO};
    `}

  &::placeholder {
    color: ${Cores.CINZA_3_ESCURO};
    font-size: 12px;
  }

  &:not(:placeholder-shown) {
    color: ${Cores.CINZA_2};
  }

  ${({ theme: { Desabilitado } }) =>
    !Desabilitado &&
    css`
      &:hover {
        border: 1px solid ${Cores.PRIMARIA_1};
      }

      &:focus {
        caret-color: ${Cores.PRIMARIA_2};
        border: 1px solid ${Cores.PRIMARIA_2};
        color: ${Cores.CINZA_2_ESCURO};
      }
    `}
`

export const BotaoSelecao = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  width: 70px;
  border: 1px solid ${Cores.CINZA_1_CLARO};
  border-left-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${Cores.CINZA_2};
  outline: none;
  cursor: pointer;
`
