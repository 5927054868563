import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import { ModalBase, Botao, FileInput, FormUnform } from 'src/componentes'

import { schemaInserirRelatorio } from './schema'
import { ContainerAcoes, ContainerModal, CorpoModal } from './style'
import {
  ModalInserirRelatorioProps,
  ModalInserirRelatorioRef,
  NovoRelatorio
} from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalInserirRelatorioRef,
  ModalInserirRelatorioProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = () => {
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: NovoRelatorio) => {
    if (!dados || !dados.arquivo) {
      toast.error('Insira um relatório')
      return
    }

    try {
      definirCarregando(true)
      const dadosNovoRelatorio = {
        ...dados
      }
      await acaoPrimaria(dadosNovoRelatorio)
    } catch (error) {
      toast.error('Erro ao enviar relatório')
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  return (
    <ModalBase id="modal-inserir-relatorio" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Inserir Relatório</h5>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaInserirRelatorio}
              ref={formRef}
            >
              <FileInput name="arquivo" />
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={fechar}
                />
                <Botao texto="Adicionar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalInserirRelatorio = forwardRef(Modal)
