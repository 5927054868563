import React, { useRef, useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import { Botao, Carregando, IconeAtencao } from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Api } from 'src/servicos'
import { DadosCertificado } from 'src/servicos/api/sala-aula/tipos'

import { Container, ContainerBotao } from '../../shared/styles'
import SalaAulaContainer from '../container'
import { CertificadoTemplate } from './certificado-template'
import { Mensagem } from './styles'

export const Certificado: React.FC = () => {
  const certificadoRef = useRef()
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const [certificado, definirCertificado] = useState<DadosCertificado>(
    {} as DadosCertificado
  )
  const acaoPrint = useReactToPrint({
    content: () => certificadoRef.current
  })
  const { salaAula, idMatricula } = SalaAulaContainer.useContainer()

  const carregarDados = async () => {
    try {
      definirPronto(false)
      definirErro(undefined)

      const dadosCertificado = await Api.SalaAulaCursoLivre.RequisitarDadosCertificado(
        idMatricula
      )

      definirCertificado(dadosCertificado)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar o certificado')
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    carregarDados()
  }, [idMatricula])

  return (
    <Container>
      {pronto && !erro ? (
        <>
          <Mensagem>
            Parabéns! Você concluiu o <strong>{salaAula.nomeCurso}</strong>.
            Realize o download do seu certificado.
          </Mensagem>
          <CertificadoTemplate certificado={certificado} ref={certificadoRef} />
          <ContainerBotao>
            <Botao
              texto="Realizar Download do Certificado"
              onClick={acaoPrint}
            />
          </ContainerBotao>
        </>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando certificado ..." />
      ) : (
        <ContainerErroLayout>
          <div>
            {IconeAtencao}
            <p>{erro}</p>
            <div>
              <Botao
                tema="Padrao"
                texto="Tentar novamente"
                onClick={carregarDados}
                tamanho="S"
              />
            </div>
          </div>
        </ContainerErroLayout>
      )}
    </Container>
  )
}
