import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  InputUnform,
  InputMesAnoUnform,
  CartaoCreditoInputUnform,
  IconeInformacao,
  Tooltip,
  Cores,
  IconeCartaoVisa,
  IconeCartaoMaster,
  IconeCartaoAmex,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoDiners,
  regExpVisa,
  regExpMaster,
  regExpAmex,
  regExpElo,
  regExpHiper,
  regExpJcb,
  regExpDiners
} from 'src/componentes'
import { DadosCartao } from 'src/servicos/api/tipos'

import { schemaPagamentoCartaoCredito } from './schema'
import {
  ContainerAcoes,
  ContainerBandeiras,
  ContainerDados,
  ContainerDadosMedio,
  ContainerModal,
  IconeLabel,
  LinhaBandeiras,
  CorpoModal
} from './styles'
import { ModalPagamentoProps, ModalPagamentoRef } from './tipos'

const ModalPagamentoRecorrente: React.ForwardRefRenderFunction<
  ModalPagamentoRef,
  ModalPagamentoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [numeroCartao, definirNumeroCartao] = useState('')
  const [carregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = () => {
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalPagamentoRef, ModalPagamentoRef>(ref, () => ({
    fechar,
    abrir
  }))

  const dadosIniciais = useMemo(() => {
    return {
      nomeImpresso: null,
      numero: null,
      validade: null,
      codigo: null,
      parcelas: null
    }
  }, [])

  const acaoSucessoForm = async (dados: DadosCartao) => {
    const payload = {
      ...dados,
      parcelas: 1
    }
    acaoPrimaria(payload)
    fechar()
  }

  return (
    <ModalBase id="modal-pagamento" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Cartão pagamento recorrente</h5>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaPagamentoCartaoCredito}
              ref={formRef}
              dadosIniciais={dadosIniciais}
            >
              <LinhaBandeiras>
                <ContainerBandeiras
                  selecionado={
                    !!numeroCartao.match(regExpVisa) &&
                    !numeroCartao.match(regExpElo)
                  }
                >
                  {IconeCartaoVisa}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpMaster)}
                >
                  {IconeCartaoMaster}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpAmex)}
                >
                  {IconeCartaoAmex}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpElo)}
                >
                  {IconeCartaoElo}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpHiper)}
                >
                  {IconeCartaoHiper}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpJcb)}
                >
                  {IconeCartaoJcb}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpDiners)}
                >
                  {IconeCartaoDiners}
                </ContainerBandeiras>
              </LinhaBandeiras>
              <CartaoCreditoInputUnform
                id="ipt_numero"
                name="numero"
                label="Número do Cartão"
                obrigatorio
                onChange={e => {
                  const valor = e.target.value.replaceAll(' ', '')
                  definirNumeroCartao(valor)
                }}
              />
              <InputUnform
                id="ipt_nome_impresso"
                name="nomeImpresso"
                label="Nome Conforme Consta no Cartão"
                obrigatorio
              />
              <ContainerDados>
                <ContainerDadosMedio>
                  <InputMesAnoUnform
                    id="ipt_validade"
                    name="validade"
                    label="Validade do Cartão"
                    placeholder="mm/aaaa"
                    formatoAno="AAAA"
                    obrigatorio
                  />
                  <Tooltip
                    id="tooltip-cvv"
                    place="right"
                    className="tooltip-ipgs"
                    textColor={Cores.PRETO}
                    backgroundColor={Cores.CINZA_1_CLARO}
                  />
                </ContainerDadosMedio>
                <ContainerDadosMedio>
                  <InputUnform
                    id="ipt_codigo"
                    name="codigo"
                    label={
                      <>
                        Código de Segurança
                        <IconeLabel
                          data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                          data-for="tooltip-cvv"
                        >
                          {IconeInformacao}
                        </IconeLabel>
                      </>
                    }
                    obrigatorio
                    maxLength={4}
                  />
                </ContainerDadosMedio>
              </ContainerDados>
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Enviar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalPagamentoCartaoRecorrente = forwardRef(
  ModalPagamentoRecorrente
)
