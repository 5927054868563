import { useState } from 'react'

import { createContainer } from 'unstated-next'

import { Notificacao } from './tipos'

export interface TipoDoProviderDeNotificacoes {
  notificacoes: Notificacao[]
  adicionar: (notificacao: Notificacao) => void
  remover: (titulo: string) => void
  atualizar: (notificacoes: Notificacao[]) => void
}

const ordenar = (anterior: Notificacao, proxima: Notificacao) => {
  if (anterior.lida === proxima.lida) return 0
  else if (anterior.lida && !proxima.lida) return 1
  else return -1
}

export const useNotificacoes = (): TipoDoProviderDeNotificacoes => {
  const [notificacoes, definirNotificacoes] = useState<Notificacao[]>([])

  const adicionar = (notificacao: Notificacao) =>
    definirNotificacoes([...notificacoes, notificacao].sort(ordenar))

  const remover = (titulo: string) =>
    definirNotificacoes(
      notificacoes.filter(notificacao => notificacao.titulo === titulo)
    )

  const atualizar = (notificacoes: Notificacao[]) =>
    definirNotificacoes(notificacoes)

  return {
    notificacoes,
    adicionar,
    remover,
    atualizar
  }
}

export default createContainer(useNotificacoes)
