import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform,
  SelectOpcao,
  DataInputUnform,
  FuncoesDataHora
} from 'src/componentes'
import { formatarCpf } from 'src/componentes/funcoes/cpf'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import {
  RequisitarListaTurmasPorTexto,
  RequisitarListaCursosPorTextoFiltro
} from 'src/servicos/api'
import {
  AlunoSituacao,
  FormaPagamentoCobranca,
  ModalidadeCurso,
  SituacaoFinanceiraPorExtenso,
  TipoOrdenacao,
  SituacaoFinanceiraFiltro,
  SituacaoParcelaFiltro,
  SituacaoParcela,
  ModalidadeCursoPorExtenso
} from 'src/tipos'

import { Form, Container, Campo, Dados, ContainerExportar } from './styles'
import { DadosPesquisa } from './tipos'

export const PaginaSituacaoFinanceiraAlunos: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [
    planoPagamentoSelecionado,
    definirPlanoPagamentoSelecionado
  ] = useState<string>(null)
  const [carregando, definirCarregando] = useState(false)

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioFinanceiro(
      {
        inicioPagamento:
          dadosPesquisa?.inicioPagamento ?? FuncoesDataHora.dataMesRelatorio(),
        fimPagamento:
          dadosPesquisa?.fimPagamento ?? FuncoesDataHora.dataMesRelatorio(true),
        inicioVencimento: dadosPesquisa?.inicioVencimento,
        fimVencimento: dadosPesquisa?.fimVencimento,
        inicioDataMatricula: dadosPesquisa?.inicioDataMatricula,
        fimDataMatricula: dadosPesquisa?.fimDataMatricula,
        nomeAluno: dadosPesquisa?.nomeAluno,
        situacoesCobranca: dadosPesquisa?.situacoesCobranca,
        situacoesFinanceiras: dadosPesquisa?.situacoesFinanceiras,
        formaPagamento: dadosPesquisa?.formaPagamento,
        modalidade: dadosPesquisa?.modalidade,
        planoPagamentoId: planoPagamentoSelecionado,
        cursoId: dadosPesquisa?.curso,
        turmaId: dadosPesquisa?.turma
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioFinanceiro({
        inicioPagamento:
          dadosPesquisa?.inicioPagamento ?? FuncoesDataHora.dataMesRelatorio(),
        fimPagamento:
          dadosPesquisa?.fimPagamento ?? FuncoesDataHora.dataMesRelatorio(true),
        inicioVencimento: dadosPesquisa?.inicioVencimento,
        fimVencimento: dadosPesquisa?.fimVencimento,
        inicioDataMatricula: dadosPesquisa?.inicioDataMatricula,
        fimDataMatricula: dadosPesquisa?.fimDataMatricula,
        nomeAluno: dadosPesquisa?.nomeAluno,
        situacoesCobranca: dadosPesquisa?.situacoesCobranca,
        situacoesFinanceiras: dadosPesquisa?.situacoesFinanceiras,
        formaPagamento: dadosPesquisa?.formaPagamento,
        modalidade: dadosPesquisa?.modalidade,
        planoPagamentoId: planoPagamentoSelecionado,
        cursoId: dadosPesquisa?.curso,
        turmaId: dadosPesquisa?.turma
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const planoPagamentoAlterado = (opcao: SelectOpcao) => {
    definirPlanoPagamentoSelecionado(opcao?.id)
  }
  const deveLimparSituacaoParcela = () => {
    return false
  }

  const RequisitarPlanoPlagamento = async (texto: string) => {
    return await Api.RequisitarListaPlanoPagamentoFiltro(texto)
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Situação Financeira"
          atalhos={[
            {
              texto: 'Relatórios Financeiros'
            },
            {
              texto: 'Situação Financeira'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: AlunoSituacao[AlunoSituacao.Todas],
            inicioPagamento: FuncoesDataHora.dataMesRelatorio(),
            fimPagamento: FuncoesDataHora.dataMesRelatorio(true)
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo mr>
              <DataInputUnform
                name="inicioPagamento"
                label="Início Período Pagamento"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="fimPagamento"
                label="Fim Período Pagamento"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="inicioVencimento"
                label="Início Período Vencimento"
              />
            </Campo>
            <Campo>
              <DataInputUnform
                name="fimVencimento"
                label="Fim Período Vencimento"
              />
            </Campo>
          </Container>
          <Container pb>
            <Campo mr>
              <SelectUnform
                id="slct_formaPagamento"
                name="formaPagamento"
                label="Forma de Pagamento"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto],
                    texto: FormaPagamentoCobranca.Boleto
                  },
                  {
                    id:
                      FormaPagamentoCobranca[
                        FormaPagamentoCobranca.CartaoCredito
                      ],
                    texto: FormaPagamentoCobranca.CartaoCredito
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Cheque],
                    texto: FormaPagamentoCobranca.Cheque
                  },
                  {
                    id:
                      FormaPagamentoCobranca[
                        FormaPagamentoCobranca.CupomDesconto
                      ],
                    texto: FormaPagamentoCobranca.CupomDesconto
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Deposito],
                    texto: FormaPagamentoCobranca.Deposito
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Dinheiro],
                    texto: FormaPagamentoCobranca.Dinheiro
                  },
                  {
                    id:
                      FormaPagamentoCobranca[FormaPagamentoCobranca.PagSeguro],
                    texto: FormaPagamentoCobranca.PagSeguro
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.PayPal],
                    texto: FormaPagamentoCobranca.PayPal
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Pix],
                    texto: FormaPagamentoCobranca.Pix
                  },
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.TED],
                    texto: FormaPagamentoCobranca.TED
                  }
                ]}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_modalidadade"
                name="modalidade"
                label="Modalidade"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Livre],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
                    texto:
                      ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoEadFlex
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoEadFull
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
                    texto:
                      ModalidadeCursoPorExtenso[
                        ModalidadeCurso.PosGraduacaoPresencial
                      ]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Graduacao]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Evento],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Evento]
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Outra],
                    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Outra]
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container pb>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
                multiplo
              />
            </Campo>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                buscarPorTexto={RequisitarListaCursosPorTextoFiltro}
                buscarPorId={RequisitarListaCursosPorTextoFiltro}
                multiplo
              />
            </Campo>
            <Campo>
              <SelectAsyncUnform
                id="slct_planoPagamento"
                name="planoPagamento"
                label="Plano Pagamento"
                buscarPorId={RequisitarPlanoPlagamento}
                buscarPorTexto={RequisitarPlanoPlagamento}
                valorAlterado={planoPagamentoAlterado}
              />
            </Campo>
          </Container>
          <Container pb>
            <Campo mr>
              <SelectUnform
                id="slct_situacao_parcela"
                name="situacoesCobranca"
                label="Situação Parcela"
                iconeFechar
                margemInferior={false}
                deveLimpar={deveLimparSituacaoParcela}
                multiplo
                opcoes={[
                  {
                    id: 'Todas',
                    texto: 'Todas'
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.AVencer],
                    texto: SituacaoParcelaFiltro.AVencer
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Cancelado],
                    texto: SituacaoParcelaFiltro.Cancelado
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Negativado],
                    texto: SituacaoParcelaFiltro.Negativado
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Pago],
                    texto: SituacaoParcelaFiltro.Pago
                  },
                  {
                    id:
                      SituacaoParcelaFiltro[SituacaoParcelaFiltro.Renegociado],
                    texto: SituacaoParcelaFiltro.Renegociado
                  },
                  {
                    id: SituacaoParcelaFiltro[SituacaoParcelaFiltro.Vencido],
                    texto: SituacaoParcelaFiltro.Vencido
                  },
                  {
                    id:
                      SituacaoParcelaFiltro[
                        SituacaoParcelaFiltro.AguardandoPagamentoAutomatico
                      ],
                    texto: SituacaoParcelaFiltro.AguardandoPagamentoAutomatico
                  }
                ]}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_situacaoFinanceira"
                name="situacoesFinanceiras"
                label="Situação Matrícula"
                iconeFechar
                margemInferior={false}
                multiplo
                opcoes={[
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro
                          .AguardandoCartaoPagamentoRecorrente
                      ],
                    texto:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro
                          .AguardandoCartaoPagamentoRecorrente
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Cancelado
                      ],
                    texto:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Cancelado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.ContratoRescindido
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.ContratoRescindido
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Finalizado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Finalizado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Inativo
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Inativo
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Matriculado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Matriculado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Reprovado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Reprovado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Reservado
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Reservado
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Transferido
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Transferido
                      ]
                  },
                  {
                    id:
                      SituacaoFinanceiraFiltro[
                        SituacaoFinanceiraFiltro.Recuperacao
                      ],
                    texto:
                      SituacaoFinanceiraPorExtenso[
                        SituacaoFinanceiraFiltro.Recuperacao
                      ]
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="inicioDataMatricula"
                label="Início Período Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="fimDataMatricula"
                label="Fim Período Matrícula"
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
          carregando={carregando}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nomeAluno',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.nomeAluno}</span>
              ),
              RenderizarCabecalho: () => <div style={{ width: 200 }}>Nome</div>
            },
            {
              Chave: 'cpfAluno',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatarCpf(linha.cpfAluno)}</span>
              ),
              RenderizarCabecalho: () => <div style={{ width: 100 }}>CPF</div>
            },
            {
              Chave: 'email',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 200 }}>E-mail</div>
              )
            },
            {
              Chave: 'endereco',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 300 }}>Endereço</div>
              )
            },
            {
              Chave: 'codigoTurmaCurso',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Código</div>
              )
            },
            {
              Chave: 'nomeTurmaCurso',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 250 }}>Turma / Curso</div>
              )
            },
            {
              Chave: 'modalidade',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{ModalidadeCurso[linha.modalidade]}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Modalidade</div>
              )
            },
            {
              Chave: 'numeroMesParcela',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>N°/ Mês Parcela</div>
              )
            },
            {
              Chave: 'totalParcelas',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 120 }}>Total Parcelas</div>
              )
            },
            {
              Chave: 'tipoParcela',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Tipo da Parcela</div>
              )
            },
            {
              Chave: 'valorParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorParcela)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor da Parcela</div>
              )
            },
            {
              Chave: 'dataVencimentoParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataVencimentoParcela)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Vencimento</div>
              )
            },
            {
              Chave: 'situacaoParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{SituacaoParcela[linha.situacaoParcela]}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Situação da Parcela</div>
              )
            },
            {
              Chave: 'valorPagoParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorPagoParcela)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor Pago</div>
              )
            },
            {
              Chave: 'valorEmAbertoParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.moeda(linha.valorEmAbertoParcela)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 80 }}>Valor em aberto</div>
              )
            },
            {
              Chave: 'dataPagamentoParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataPagamentoParcela)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Pagamento</div>
              )
            },
            {
              Chave: 'formaPagamento',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.formaPagamento}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 125 }}>Forma de Pagamento</div>
              )
            },
            {
              Chave: 'situacaoMatricula',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {SituacaoFinanceiraPorExtenso[linha.situacaoMatricula]}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Situação Matrícula</div>
              )
            },
            {
              Chave: 'dataMatricula',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataMatricula)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Matrícula</div>
              )
            },
            {
              Chave: 'observacaoParcela',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.observacaoParcela}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 250 }}>Observação</div>
              )
            },
            {
              Chave: 'nossoNumeroTID',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.nossoNumeroTID}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 160 }}>Nosso N° TID</div>
              )
            },
            {
              Chave: 'qtdTentativas',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.qtdTentativas}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 75 }}>Qtd. Tentativas</div>
              )
            },
            {
              Chave: 'codigoCielo',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.codigoCielo}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 50 }}>Cód. Cielo</div>
              )
            },
            {
              Chave: 'mensagemRetorno',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.mensagemRetorno}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Mensagem Retorno</div>
              )
            },
            {
              Chave: 'CarrinhoId',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.carrinhoId}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Id do Carrinho</div>
              )
            },
            {
              Chave: 'StatusCarrinho',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.statusCarrinho}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Status do Carrinho</div>
              )
            },
            {
              Chave: 'QtdCursosCarrinho',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.qtdCursosCarrinho}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Qtd. Cursos Carrinho</div>
              )
            },
            {
              Chave: 'DataUltimoAcesso',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataUltimoAcesso)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Data Último Acesso</div>
              )
            },
            {
              Chave: 'ResponsavelFinanceiro',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.responsavelFinanceiro}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Responsavel Financeiro</div>
              )
            },
            {
              Chave: 'tipoMatricula',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Tipo matricula</div>
              )
            },
            {
              Chave: 'pagamentoRecorrente',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Pagamento recorrente</div>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaSituacaoFinanceiraAlunos />
  </ErroLayoutContainer.Provider>
)
