import React, { useEffect, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  Botao,
  Breadcrumb,
  Carregando,
  ContainerAcoes,
  FormUnform,
  IconeAlterar,
  IconeBack,
  IconeInformacaoAzul,
  Link,
  SelectOpcao,
  SelectUnform,
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  Tooltip,
  IconeEnviarEmail,
  ModalRef,
  Modal
} from 'src/componentes'
import { Conteudo } from 'src/paginas/aluno/editar-dados/styles'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import { TipoProfessorModuloTCC } from 'src/tipos'

import { ModalAlterarTituloTCC } from './componentes'
import { ModalAlterarTituloTCCRef } from './componentes/modal-alterar-titulo-tcc/tipos'
import { schema } from './schema'
import {
  Container,
  ContainerAcoesBotao,
  CabecalhoComEstilos,
  ContainerLink,
  IconeTabela,
  ContainerIcone,
  ContainerProfessor
} from './styles'
import {
  AcoesFinalizacaoProps,
  DadosAlunoFinalizacao,
  AtualizarProfessorTCC,
  AlunosFinalizacao
} from './tipos'

const PaginaAcoesFinalizacao: React.FC<AcoesFinalizacaoProps> = ({ match }) => {
  const turmaId = match.params.id
  const history = useHistory()
  const tabelaRef = useRef<TabelaRef>()
  const formRef = useRef<FormHandles>(null)
  const modalAlterarTituloTCCRef = useRef<ModalAlterarTituloTCCRef>(null)
  const modalConfirmacaoRef = useRef<ModalRef>(null)
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [carregandoBotao, definirCarregandoBotao] = useState(false)
  const [
    alunosFinalizacao,
    definirAlunosFinalizacao
  ] = useState<DadosAlunoFinalizacao>()
  const [
    opcoesProfessorOrientador,
    definirOpcoesProfessorOrientador
  ] = useState<SelectOpcao[]>()
  const [opcoesProfessorBanca, definirOpcoesProfessorBanca] = useState<
    SelectOpcao[]
  >()
  const [professorOrientador, definirProfessorOrientador] = useState<string>()
  const [professorBanca, definirProfessorBanca] = useState<string>()
  const [
    tipoProfessor,
    definirTipoProfessor
  ] = useState<TipoProfessorModuloTCC>()
  const [idSaladeAula, definirIdSaladeAula] = useState('')
  const [alunosSelecionados, definirAlunosSelecionados] = useState<string[]>([])
  const [desmarcarItens, definirDesmarcarItens] = useState<boolean>(false)
  const alunosVazio = alunosSelecionados.length < 1

  const obterAlunosFinalizacao = async (): Promise<void> => {
    try {
      definirPronto(false)
      const resposta = await Api.ObterDadosAlunoFinalizacao(turmaId)
      definirAlunosFinalizacao(resposta)
      definirProfessorOrientador(null)
      definirProfessorBanca(null)
    } finally {
      definirPronto(true)
    }
  }

  const obterDados = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: alunosFinalizacao?.alunos
    } as TabelaResposta
  }

  const enviarEmailProfessor = async (
    id: string,
    tipoProfessor: TipoProfessorModuloTCC
  ) => {
    try {
      definirCarregandoBotao(true)
      const payload = {
        salaAulaEadId: id,
        tipoProfessor: tipoProfessor
      }
      await Api.EnviarEmailProfessoresTcc(payload)
    } catch (error) {
      toast('erro', { type: 'error' })
    } finally {
      modalConfirmacaoRef?.current?.fechar()
      definirCarregandoBotao(false)
      toast('E-mail enviado com sucesso!', { type: 'success' })
    }
  }

  const acaoSucessoForm = async (dados: AtualizarProfessorTCC) => {
    try {
      definirCarregando(true)

      if (dados.professorOrientador) {
        const payload = {
          salaAulaIds: alunosSelecionados,
          professorId: dados.professorOrientador,
          tipoProfessor: TipoProfessorModuloTCC.Orientador,
          turmaId: turmaId
        }
        await Api.AtualizarProfessoresTCC(payload)
      }

      if (dados.professorBanca) {
        const payload = {
          salaAulaIds: alunosSelecionados,
          professorId: dados.professorBanca,
          tipoProfessor: TipoProfessorModuloTCC.Banca,
          turmaId: turmaId
        }
        await Api.AtualizarProfessoresTCC(payload)
      }

      await obterAlunosFinalizacao()
      toast('Dados alterados com sucesso!', { type: 'success' })
    } finally {
      definirCarregando(false)
    }
  }

  const alterarTituloTCC = async (idSalaAula: string, tituloTCC?: string) => {
    try {
      await Api.SalaAulaOutros.AlterarTituloTCC(idSalaAula, tituloTCC)
      await obterAlunosFinalizacao()
      toast('Título do TCC alterado com sucesso!', { type: 'success' })
    } catch (erro) {
      toast('Não foi possível alterar o(s) aluno(s).', { type: 'error' })
    }
  }

  const requisitarListaProfessoresDisponiveisTCC = async (
    tipo: TipoProfessorModuloTCC
  ) => {
    try {
      const professores = await Api.RequisitarListaProfessoresTCC(turmaId, tipo)

      if (tipo === TipoProfessorModuloTCC.Orientador) {
        definirOpcoesProfessorOrientador(professores)
      }

      if (tipo === TipoProfessorModuloTCC.Banca) {
        definirOpcoesProfessorBanca(professores)
      }
    } catch {
      toast('Erro ao carregar professores.', { type: 'error' })
    }
  }

  useEffect(() => {
    obterAlunosFinalizacao()
  }, [match])

  useEffect(() => {
    if (turmaId) {
      requisitarListaProfessoresDisponiveisTCC(
        TipoProfessorModuloTCC.Orientador
      )
      requisitarListaProfessoresDisponiveisTCC(TipoProfessorModuloTCC.Banca)
    }
  }, [turmaId])

  useEffect(() => {
    if (!professorOrientador && !professorBanca) definirDesmarcarItens(true)
    else definirDesmarcarItens(false)
  }, [professorOrientador, professorBanca])

  if (!pronto) {
    return <Carregando texto="Carregando alunos da finalização..." />
  }

  return (
    <>
      <Container>
        <CabecalhoComEstilos>
          <ContainerLink>
            <Link
              texto="Voltar"
              href={RotasAcademico.Turmas}
              icone={IconeBack}
            />
          </ContainerLink>
          <Breadcrumb
            titulo="Ações de Finalização"
            atalhos={[
              {
                texto: 'Acadêmico',
                acao: () => history.goBack()
              },
              {
                texto: 'Turmas',
                acao: () => history.goBack()
              },
              {
                texto: 'Ações de Finalização'
              }
            ]}
          />
        </CabecalhoComEstilos>
        <FormUnform acaoSucesso={acaoSucessoForm} schema={schema} ref={formRef}>
          <Conteudo>
            <SelectUnform
              id="slct_professor_orientador"
              name="professorOrientador"
              label="Professor Orientador"
              opcoes={opcoesProfessorOrientador}
              valorAlterado={valor => {
                definirProfessorOrientador(valor?.id)
              }}
              disabled={professorBanca?.length > 0}
              obrigatorio
              iconeFechar
            />
            <SelectUnform
              id="slct_professor_banca"
              name="professorBanca"
              label="Professor Banca"
              opcoes={opcoesProfessorBanca}
              valorAlterado={valor => {
                definirProfessorBanca(valor?.id)
              }}
              disabled={professorOrientador?.length > 0}
              obrigatorio
              iconeFechar
            />
            <Tabela
              Id="tabela-alunos-finalizacao"
              key="tabela-alunos-finalizacao"
              ref={tabelaRef}
              ComMarcacao
              ComMarcacaoCondicao={dados =>
                (professorOrientador && !dados.possuiNotaOrientador) ||
                (professorBanca &&
                  !dados.possuiNotaBanca &&
                  dados.possuiNotaOrientador)
              }
              OnChangeMarcacao={dados => {
                definirAlunosSelecionados(dados.map(d => d.idSalaAula))
              }}
              desmarcarTodos={desmarcarItens}
              Colunas={[
                {
                  Chave: 'nomeAluno',
                  Texto: 'Nome Aluno'
                },
                {
                  Chave: 'dataInscricaoTCC',
                  Texto: 'Data Inscrição TCC',
                  Renderizar: (linha: TabelaDados) => (
                    <>{formatadores.dataLocaleString(linha.dataInscricaoTCC)}</>
                  )
                },
                {
                  Chave: 'professorOrientador',
                  Texto: 'Professor Orientador',
                  Renderizar: (linha: TabelaDados) => {
                    const aluno = (linha as unknown) as AlunosFinalizacao
                    return (
                      <ContainerProfessor>
                        <span>{linha.professorOrientador}</span>
                        {aluno.notificacaoOrientadorTCCEnviada && (
                          <ContainerIcone
                            data-tip="E-mail de orientação para o Professor Orientador já foi enviado."
                            data-for={linha.id}
                          >
                            {IconeInformacaoAzul}
                            <Tooltip id={linha.id} place="right" html />
                          </ContainerIcone>
                        )}
                      </ContainerProfessor>
                    )
                  }
                },
                {
                  Chave: 'professorBanca',
                  Texto: 'Professor Banca',
                  Renderizar: (linha: TabelaDados) => {
                    const aluno = (linha as unknown) as AlunosFinalizacao
                    return (
                      <ContainerProfessor>
                        <span>{linha.professorBanca}</span>
                        {aluno.notificacaoBancaTCCEnviada && (
                          <ContainerIcone
                            data-tip="E-mail de orientação para o Professor Banca já foi enviado."
                            data-for={linha.id}
                          >
                            {IconeInformacaoAzul}
                            <Tooltip id={linha.id} place="right" html />
                          </ContainerIcone>
                        )}
                      </ContainerProfessor>
                    )
                  }
                },
                {
                  Chave: 'acoes',
                  Alinhamento: 'center',
                  Renderizar: (linha: TabelaDados) => (
                    <ContainerAcoes>
                      <button
                        type="button"
                        data-tip="Alterar Título TCC"
                        data-for={`${linha.id}-tooltip-alterar-titulo`}
                        onClick={() => {
                          modalAlterarTituloTCCRef.current?.abrir(
                            linha.idSalaAula,
                            linha.tituloTCC
                          )
                        }}
                      >
                        <IconeTabela>{IconeAlterar}</IconeTabela>
                      </button>
                      <Tooltip
                        id={`${linha.id}-tooltip-alterar-titulo`}
                        offset={{ top: 10 }}
                      />

                      <button
                        type="button"
                        data-tip="Enviar e-mail notificação orientador"
                        data-for={`${linha.id}-tooltip-orientador`}
                        onClick={() => {
                          definirIdSaladeAula(linha.idSalaAula)
                          definirTipoProfessor(
                            TipoProfessorModuloTCC.Orientador
                          )
                          modalConfirmacaoRef?.current?.abrir()
                        }}
                      >
                        <IconeTabela>{IconeEnviarEmail}</IconeTabela>
                      </button>
                      <Tooltip
                        id={`${linha.id}-tooltip-orientador`}
                        offset={{ top: 10 }}
                      />

                      <button
                        type="button"
                        data-tip="Enviar e-mail notificação banca"
                        data-for={`${linha.id}-tooltip-banca`}
                        onClick={() => {
                          definirIdSaladeAula(linha.idSalaAula)
                          definirTipoProfessor(TipoProfessorModuloTCC.Banca)
                          modalConfirmacaoRef?.current?.abrir()
                        }}
                        disabled={!linha.possuiNotaOrientador}
                      >
                        <IconeTabela>{IconeEnviarEmail}</IconeTabela>
                      </button>
                      <Tooltip
                        id={`${linha.id}-tooltip-banca`}
                        offset={{ top: 10 }}
                      />
                    </ContainerAcoes>
                  )
                }
              ]}
              ObterDados={obterDados}
              TextoSemResultados={'Nenhum resultado encontrado.'}
            />
          </Conteudo>
          <ContainerAcoesBotao>
            <Botao
              type="button"
              texto="Voltar"
              tema="Secundario"
              onClick={() => history.goBack()}
            />
            <Botao
              type="submit"
              texto="Salvar"
              disabled={alunosVazio}
              carregando={carregando}
            />
          </ContainerAcoesBotao>
        </FormUnform>
        <ModalAlterarTituloTCC
          ref={modalAlterarTituloTCCRef}
          backdrop
          id="modal-alterar-titulo-tcc"
          acaoPrimaria={alterarTituloTCC}
        />
        <Modal
          ref={modalConfirmacaoRef}
          backdrop
          id="modal-aviso-data-recuperacao"
          titulo="Atenção"
          acaoPrimaria={{
            titulo: 'OK',
            tipo: 'button',
            carregando: carregandoBotao,
            acao: () => enviarEmailProfessor(idSaladeAula, tipoProfessor)
          }}
          acaoSecundario={{
            titulo: 'Cancelar',
            tipo: 'button',
            carregando: carregandoBotao,
            acao: () => modalConfirmacaoRef?.current?.fechar()
          }}
        >
          <p style={{ textAlign: 'justify', fontSize: 16 }}>
            Tem certeza que deseja enviar o e-mail para{' '}
            {tipoProfessor === TipoProfessorModuloTCC.Orientador
              ? 'o orientador'
              : 'o banca'}
            ?
          </p>
        </Modal>
      </Container>
    </>
  )
}

export default withRouter(PaginaAcoesFinalizacao)
