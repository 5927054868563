import { ButtonHTMLAttributes } from 'react'

import { Medias } from 'src/componentes/styles/breakpoints'
import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

import { TooltipParcelaEmAtraso } from './componentes/tooltip-parcela-em-atraso/tooltip-parcela-em-atraso'
import { TooltipParcelaObservacao } from './componentes/tooltip-parcela-observacao/tooltip-parcela-observacao'

export const ParagrafoComEstilos = styled.p`
  margin-right: 80px;
  color: red;
  opacity: 0.7;

  @media ${Medias.MOBILE} {
    margin-right: 0;
  }
`

export const Titulo = styled.h1`
  font-weight: 300;
  font-size: 30px;
  line-height: 35.16px;

  @media ${Medias.MOBILE} {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
`

export const ContainerParcelas = styled.div`
  @media ${Medias.MOBILE} {
    display: flex;
  }
  margin-top: 20px;
  display: none;
  flex-direction: column;
  & h2 {
    margin-bottom: 30px;
  }
  table {
    font-family: 'Saira', sans-serif;
    font-size: 12px;
    color: #191414;
    border-collapse: collapse;
    border: 1px solid ${Cores.CINZA_3_CLARO};
    width: 100%;
    thead {
      th {
        background: ${Cores.CINZA_HEAD_TABLE};
        color: ${Cores.PRETO};
        font-family: 'Saira-Semi-Bold', sans-serif;
        font-weight: 600;
        font-size: 14px;
        padding: 12px;
        border-bottom: none;
        text-transform: uppercase;
      }
      th:first-child {
        border-top-left-radius: 8px;
      }
      th:last-child {
        border-top-right-radius: 8px;
      }
    }
  }
  tbody {
    line-height: 125%;
    tr:first-child {
      border-top: 2px solid ${Cores.CINZA_3_CLARO};
    }
    tr {
      height: 48px;
      border: 1px solid ${Cores.FUNDO};
      border-bottom: none;
      td {
        b {
          font-weight: 500;
          margin-right: 5px;
        }

        p {
          font-size: 14px;
          display: flex;
          margin-bottom: 10px;
        }
      }
      &:last-child {
        border-bottom: 1px solid ${Cores.FUNDO};
      }
      &.par {
        background: ${Cores.FUNDO};
      }
      &.marcado {
        background: #afc2ed;
        border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
      }
      &.comMarcacao {
        cursor: pointer;
      }
      td,
      th {
        padding: 0 12px;
      }
      &:hover {
        border: 1px solid ${Cores.SECUNDARIA_1};
      }
    }
  }
`
export const ContainerItemAcoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    span {
      svg path {
        fill: #009b9b;
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: ${Cores.CINZA_2};
    }

    &:hover {
      background: ${Cores.CINZA_3_MAIS_CLARO};

      svg {
        fill: ${Cores.SECUNDARIA_1};

        path {
          fill: ${Cores.SECUNDARIA_1};
        }
      }
    }
  }
`
export const ContainerTabela = styled.div`
  @media ${Medias.MOBILE} {
    display: none;
  }
`
export const ImprimirReciboContainer = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const ParagrafoDoBotaoDeAcao = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
`

export const ContainerTitulo = styled.div`
  div {
    h1 {
      @media ${Medias.MOBILE} {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
`

export const TabelaComEstilos = styled.table`
  @media ${Medias.MOBILE} {
    & > tbody > tr > td {
      padding: 12px 12px 0 12px;
    }
  }
`

export const ParagrafoDeAviso = styled.p`
  & > a {
    color: ${Cores.CINZA_2};
    text-decoration: underline;
  }
`

export const TooltipParcelaEmAtrasoComEstilos = styled(TooltipParcelaEmAtraso)`
  margin-left: 8px;
`

export const TooltipParcelaObservacaoComEstilos = styled(
  TooltipParcelaObservacao
)`
  margin-left: 8px;
`

export const ContainerDoValorDaTabela = styled.div`
  display: flex;
`

export const BotaoDeAtualizacaoDoPlanoDePagamento = styled.button<
  ButtonHTMLAttributes<HTMLButtonElement>
>`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;

  & svg {
    margin-right: 8px;
  }

  & > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: ${Cores.SECUNDARIA_2_ESCURO};
  }
`

export const ParagrafoDoSaldoDevedor = styled.p`
  margin-bottom: 0 !important;
`

export const ContainerBotoesHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`
export const ContainerBotaoCartaoRecorrencia = styled.div`
  padding-top: 10px;

  > button {
    > span {
      padding-left: 10px;
    }
  }
`
export const TextoRecorrenciaFinalizada = styled.p`
  color: ${Cores.SECUNDARIA_2};
  padding-top: 10px;
  width: 50%;
  text-align: inherit;
  font-weight: 500;
`
