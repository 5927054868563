import { useState } from 'react'

import {
  DadosMatriculasReingresso,
  DadosReingresso
} from 'src/servicos/api/tipos'
import {
  Curso,
  PlanoPagamentoMatricula,
  ResponsavelFinanceiro
} from 'src/tipos'
import {
  PagamentoReingresso,
  Reingresso,
  TermosCondicoesReingresso
} from 'src/tipos/reingresso'
import { createContainer } from 'unstated-next'

import {
  ReingressoContainer,
  ReingressoContainerProps,
  ReingressoContainerEstado
} from './tipos'

const useReingresso = (initialState?: ReingressoContainerProps) => {
  const [estado, definirEstado] = useState<ReingressoContainerEstado>({
    dados: {} as DadosReingresso,
    ...initialState
  })

  const definirPasso = (passo: number) => {
    definirEstado(old => ({ ...old, passo }))
  }

  const definirPagamento = (pagamentoMatricula: PagamentoReingresso) => {
    definirEstado(old => ({
      ...old,
      matricula: { ...old.matricula, pagamentoMatricula }
    }))
  }

  const definirPlanoPagamento = (planoPagamento: PlanoPagamentoMatricula) => {
    definirEstado(old => ({
      ...old,
      dados: {
        ...old.dados,
        planoPagamentoId: planoPagamento?.planoPagamentoId,
        formaPagamento: planoPagamento?.formPagamentoParcela,
        matriculaDestino: {
          ...old.dados.matriculaDestino,
          codigoCupom: planoPagamento?.codigoCupom,
          idCupom: planoPagamento?.idCupom
        }
      }
    }))
  }

  const definirTermosCondicoes = (
    termosCondicoes: Omit<TermosCondicoesReingresso, 'urlTermosCondicoes'>
  ) => {
    definirEstado(old => ({
      ...old,
      termosCondicoes: {
        ...old.termosCondicoes,
        ...termosCondicoes
      }
    }))
  }

  const definirReingresso = (reingresso: Reingresso) => {
    definirEstado(old => ({
      ...old,
      reingresso
    }))
  }

  const definirCurso = (curso: Curso) => {
    definirEstado(old => ({
      ...old,
      curso
    }))
  }

  const definirResponsavelFinanceiro = (responsavel: ResponsavelFinanceiro) => {
    definirEstado(old => ({
      ...old,
      dados: {
        ...old.dados,
        matriculaDestino: {
          ...old.dados?.matriculaDestino,
          responsavelFinanceiro: responsavel
        }
      }
    }))
  }

  const definirMatriculaDestino = (
    matriculaDestino: DadosMatriculasReingresso
  ) => {
    definirEstado(old => ({
      ...old,
      dados: {
        ...old.dados,
        matriculaDestino: {
          ...old.dados?.matriculaDestino,
          matriculaDestino
        }
      }
    }))
  }

  const definirDados = (dados: DadosReingresso) => {
    definirEstado(old => ({
      ...old,
      dados
    }))
  }

  return {
    ...estado,
    definirPasso,
    definirCurso,
    definirDados,
    definirReingresso,
    definirPagamento,
    definirTermosCondicoes,
    definirPlanoPagamento,
    definirResponsavelFinanceiro,
    definirMatriculaDestino
  }
}
export default createContainer<ReingressoContainer, ReingressoContainerProps>(
  useReingresso
)
