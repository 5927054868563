import { RouteComponentProps } from 'react-router-dom'

import {
  Curso,
  CursosCarrinho,
  DadosPessoaisMatricula,
  Matricula,
  PagamentoMatricula,
  PlanoPagamentoMatricula,
  ResponsavelFinanceiro,
  TermosCondicoesMatricula
} from 'src/tipos'

export enum ModoMatricula {
  'Livre',
  'Pos',
  'Evento'
}

export interface MatriculaContainerEstado {
  passo: number
  curso?: Curso
  carrinhoCurso?: CursosCarrinho
  modo: ModoMatricula
  matricula?: Matricula
}

export interface MatriculaContainerProps {
  passo: number
  modo: ModoMatricula
}

export interface MatriculaContainer {
  passo: number
  curso?: Curso
  carrinhoCurso?: CursosCarrinho
  modo: ModoMatricula
  matricula?: Matricula
  definirPasso: (passo: number) => void
  definirCurso: (curso: Curso) => void
  definirCursosCarrinho: (curso: CursosCarrinho) => void
  definirMatricula: (matricula: Matricula) => void
  definirDadosPessoais: (
    id: string,
    dadosPessoais: DadosPessoaisMatricula
  ) => void
  definirPagamento: (pagamento: PagamentoMatricula) => void
  definirPlanoPagamento: (planoPagamento: PlanoPagamentoMatricula) => void
  definirTermosCondicoes: (
    termosCondicoes: Omit<TermosCondicoesMatricula, 'urlTermosCondicoes'>
  ) => void
  possuiCarrinho: () => boolean
  obterCursoEstado: () => Curso
  definirMatriculaCursosCarrinho: (carrinhoCompra: CursosCarrinho) => void
  obterMatriculas: () => Matricula[]
  possuiMatriculaCarrinho: () => boolean
  definirBoletoGeradoCarrinho: (boletoGerado: boolean) => void
  definirPagamentoMatriculasCarrinho: (pagamento: PagamentoMatricula) => void
  definirResponsavelFinanceiro: (responsavel: ResponsavelFinanceiro) => void
}

export type PaginaMatriculaProps = RouteComponentProps<{
  sigla?: string
  codigoTurma?: string
}> & {
  modo: ModoMatricula
}

export interface DadosPessoaisForm extends DadosPessoaisMatricula {
  novaSenha: string
  repetirSenha: string
}
