import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  Botao,
  ModalBase,
  ModalBaseProps,
  ModalRef,
  PdfViewer
} from 'src/componentes'

import { ContainerDosBotoes, CorpoModal } from './styles'
export interface ModalVisualizacaoContratoRef extends Omit<ModalRef, 'abrir'> {
  abrir: (url: string) => void
}

const ModalVisualizacaoComponent: React.ForwardRefRenderFunction<
  ModalVisualizacaoContratoRef,
  ModalBaseProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [contrato, definirContrato] = useState<string>(null)

  const abrir = (url: string) => {
    definirContrato(url)
    definirAberta(true)
  }

  const fechar = () => {
    definirAberta(false)
    definirContrato(null)
  }

  useImperativeHandle<
    ModalVisualizacaoContratoRef,
    ModalVisualizacaoContratoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-visualizacao-contrato"
      backdrop={backdrop}
      aberta={aberta}
    >
      <CorpoModal>
        <ContainerDosBotoes>
          <Botao
            texto="Fechar"
            tema="Secundario"
            type="button"
            onClick={() => fechar()}
          />
        </ContainerDosBotoes>
        {contrato && (
          <PdfViewer
            url={contrato}
            descricaoMobile="Contrato"
            nomeArquivo="contrato"
            altura="100%"
          ></PdfViewer>
        )}
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalVisualizacaoContrato = forwardRef(ModalVisualizacaoComponent)
