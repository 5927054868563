import React, { createContext, useContext, useReducer, useEffect } from 'react'

import * as Storage from '../../../servicos/local-storage'
import { TemaEstado, TemaAcoes, TemaDispatch } from './tipos'

const TemaStateContext = createContext<TemaEstado | undefined>(undefined)

const TemaDispatchContext = createContext<TemaDispatch | undefined>(undefined)

const temaReducer = (estado: TemaEstado, acao: TemaAcoes) => {
  switch (acao.tipo) {
    case 'iniciar': {
      const { menuExpandido } = acao
      return { menuExpandido }
    }
    case 'expandir-retrair-menu': {
      const novoEstado = !estado.menuExpandido
      Storage.definir('@ipgs/estadoMenu', { estado: novoEstado })
      return { ...estado, menuExpandido: novoEstado }
    }
    case 'retrair-menu': {
      Storage.definir('@ipgs/estadoMenu', { estado: false })
      return { ...estado, menuExpandido: false }
    }
    default: {
      throw new Error(`Ação inválida: ${acao}`)
    }
  }
}

export const useTemaState = (): TemaEstado => {
  const context = useContext(TemaStateContext)
  if (context === undefined) {
    throw new Error('useTemaState deve ser usado dentro de um TemaProvider')
  }
  return context
}

export const useTemaDispatch = (): TemaDispatch => {
  const context = useContext(TemaDispatchContext)
  if (context === undefined) {
    throw new Error('useTemaDispatch deve ser usado dentro de um TemaProvider')
  }
  return context
}

export const TemaProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(temaReducer, {
    menuExpandido: undefined
  })

  useEffect(() => {
    const historicoMenuExpandido = Storage.obter('@ipgs/estadoMenu')
    dispatch({
      tipo: 'iniciar',
      menuExpandido: historicoMenuExpandido?.novoEstado ?? false
    })
    // eslint-disable-next-line
  }, [])

  return (
    <TemaStateContext.Provider value={state}>
      <TemaDispatchContext.Provider value={dispatch}>
        {children}
      </TemaDispatchContext.Provider>
    </TemaStateContext.Provider>
  )
}

export const useTema = (): [TemaEstado, TemaDispatch] => [
  useTemaState(),
  useTemaDispatch()
]
