import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3 1.79999H4.70005C3.10005 1.79999 1.80005 3.09999 1.80005 4.69999V15.6C1.80005 16.5 2.50005 17.3 3.50005 17.3H5.90005V20.6C5.90005 21.5 6.60005 22.3 7.60005 22.3H20.7001C21.6 22.3 22.4 21.6 22.4 20.6V4.69999C22.3 3.09999 20.9 1.79999 19.3 1.79999ZM7.30005 3.49999H19.3C19.9 3.49999 20.5 3.99999 20.5 4.69999V20.5H7.60005V4.69999C7.60005 4.29999 7.50005 3.89999 7.30005 3.49999ZM3.50005 4.69999C3.50005 3.99999 4.00005 3.49999 4.70005 3.49999C5.40005 3.49999 5.80005 3.99999 5.80005 4.69999V15.5H3.50005V4.69999Z"
      fill="#666666"
      fillOpacity="0.8"
    />
    <path
      d="M3.50005 17.375H5.82505V20.6C5.82505 21.5385 6.55577 22.375 7.60005 22.375H20.7001C21.6386 22.375 22.475 21.6443 22.475 20.6V4.69999H22.4752L22.4749 4.69531C22.3724 3.05534 20.9392 1.72499 19.3 1.72499H4.70005C3.05863 1.72499 1.72505 3.05857 1.72505 4.69999V15.6C1.72505 16.5385 2.45577 17.375 3.50005 17.375ZM7.67505 4.69999C7.67505 4.32353 7.58917 3.94835 7.419 3.57499H19.3C19.8627 3.57499 20.425 4.04539 20.425 4.69999V20.425H7.67505V4.69999ZM3.57505 4.69999C3.57505 4.04141 4.04147 3.57499 4.70005 3.57499C5.03098 3.57499 5.28459 3.69249 5.45631 3.88711C5.62906 4.08289 5.72505 4.36379 5.72505 4.69999V15.425H3.57505V4.69999Z"
      stroke="#666666"
      strokeOpacity="0.8"
      strokeWidth="0.15"
    />
    <path
      d="M13.3422 15.9205C12.478 15.8636 11.7758 15.7973 11.2357 15.7216V14.017C12.2177 14.0928 13.0132 14.1307 13.6221 14.1307C14.0934 14.1307 14.4273 14.1165 14.6237 14.0881C14.83 14.0502 14.9576 13.9934 15.0067 13.9176C15.0657 13.8419 15.0951 13.714 15.0951 13.5341C15.0951 13.3826 15.0804 13.2784 15.0509 13.2216C15.0215 13.1553 14.9625 13.108 14.8742 13.0795C14.7858 13.0511 14.6286 13.0227 14.4028 12.9943L12.9297 12.8097C12.3798 12.7434 11.9673 12.6061 11.6923 12.3977C11.4174 12.1894 11.2308 11.929 11.1326 11.6165C11.0442 11.304 11 10.9015 11 10.4091C11 9.61364 11.1964 9.05019 11.5892 8.71875C11.9919 8.38731 12.5762 8.19318 13.3422 8.13636V7H15.0804V8.12216C15.8169 8.15057 16.4356 8.21686 16.9364 8.32102V10.0256C16.0919 9.94981 15.3554 9.91193 14.7269 9.91193C14.2849 9.91193 13.9658 9.9214 13.7694 9.94034C13.5828 9.95928 13.4502 10.0066 13.3716 10.0824C13.2931 10.1487 13.2538 10.267 13.2538 10.4375C13.2538 10.5985 13.2636 10.7121 13.2832 10.7784C13.3127 10.8352 13.3716 10.8778 13.46 10.9063C13.5484 10.9347 13.7055 10.9631 13.9314 10.9915L15.5812 11.1761C16.0133 11.233 16.3521 11.3277 16.5976 11.4602C16.853 11.5928 17.0445 11.8153 17.1721 12.1278C17.2998 12.4403 17.3636 12.8949 17.3636 13.4915C17.3636 14.3722 17.1721 14.9877 16.7891 15.3381C16.4061 15.679 15.8366 15.8731 15.0804 15.9205V17H13.3422V15.9205Z"
      fill="#666666"
      fillOpacity="0.8"
    />
  </svg>
)
