import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

import {
  Botao,
  InputUnform,
  FormUnform,
  ModalBase,
  ModalRef,
  CheckboxUnform
} from 'src/componentes'
import { mensagemPadrao } from 'src/dados-estaticos'
import { ContainerBotoes, SubtituloModal } from 'src/paginas/ipgs/turmas/styles'
import { Api } from 'src/servicos'
import * as Yup from 'yup'

import SalaAulaOutrosContainer from '../../container'
import { ContainerCheckbox, ModalCorpo } from './styles'
import { ModalIncluirTopicoProps } from './tipos'

const Componente: ForwardRefRenderFunction<
  ModalRef,
  ModalIncluirTopicoProps
> = ({ backdrop, aberta, acaoSucesso }, ref) => {
  const { salaAula } = SalaAulaOutrosContainer.useContainer()

  const [aberto, definirAberto] = useState(aberta)
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [possuiComentariosBloqueados, definirComentariosBloqueados] = useState(
    false
  )

  const abrir = () => {
    definirAberto(true)
    definirPronto(true)
  }

  const fechar = () => {
    definirPronto(false)
    definirAberto(false)
  }

  const acaoSucessoForm = async (payload: {
    titulo: string
    comentariosBloqueados: boolean
  }) => {
    try {
      definirCarregando(true)
      await Api.CriarTopico({ ...payload, turmaId: salaAula.turmaId })

      acaoSucesso()
      definirComentariosBloqueados(false)
      fechar()
    } finally {
      definirCarregando(false)
    }
  }

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase aberta={aberto} backdrop={backdrop}>
      <ModalCorpo>
        <SubtituloModal>Incluir Tópico</SubtituloModal>
        {pronto && (
          <FormUnform
            acaoSucesso={acaoSucessoForm}
            schema={Yup.object().shape({
              titulo: Yup.string().required(mensagemPadrao)
            })}
          >
            <InputUnform
              name="titulo"
              label="Informe o título para o seu tópico"
              obrigatorio
            />
            <ContainerCheckbox>
              <CheckboxUnform
                id="chk_comentarios_bloqueados"
                name="comentariosBloqueados"
                onChange={() => definirComentariosBloqueados(old => !old)}
                checked={possuiComentariosBloqueados}
              />
              <p>Não permitir comentários.</p>
            </ContainerCheckbox>
            <ContainerBotoes>
              <Botao
                type="button"
                texto="Cancelar"
                tema="Secundario"
                onClick={() => {
                  fechar()
                }}
                disabled={carregando}
              />
              <Botao
                type="submit"
                id="btn-salvar"
                texto="Salvar"
                carregando={carregando}
              />
            </ContainerBotoes>
          </FormUnform>
        )}
      </ModalCorpo>
    </ModalBase>
  )
}

export const ModalIncluirTopico = forwardRef(Componente)
