import React, { FC, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  ResumoDeDocumentosDeEntradaMapper,
  UploadDeDocumentos,
  useRelacaoDeDocumentos,
  RelacaoDocumentosResponsavelFinanceiro,
  UploadDocumentos
} from 'src/compartilhados'
import {
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  formatarCpf,
  IconeBack,
  Link,
  ModalVisualizacaoPdf,
  ModalVisualizacaoPdfRef
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Api } from 'src/servicos'
import {
  AceiteDocumentoResponsavelFinanceiro,
  ResumoDocumentos,
  SituacaoUpload,
  TipoDocumentoResponsavelFinanceiro,
  TipoUsuario
} from 'src/tipos'
import { Usuario } from 'src/tipos/usuario'

import {
  ModalAceiteDocumentoResponsavel,
  ModalAceiteDocumentoResponsavelRef,
  ModalRecusaDocumentoResponsavel,
  ModalRecusaDocumentoResponsavelRef
} from './componentes'
import {
  BotaoDeSalvar,
  ContainerBreadcrumbs,
  ContainerDaPagina,
  ContainerDosBotoes,
  ContainerLink,
  DescricaoContainer,
  Dicas,
  ParagrafoDaNacionalidade,
  TituloComplementar
} from './styles'

const tituloDaPagina = 'Documentos do Responsável Financeiro'
const erroAoCarregarDados =
  'Ops! Houve algum problema no carregamento dos dados.'
const erroAoObterDocumentos = 'Não foi possível obter os documentos do aluno.'
const erroAoObterOsDadosDoUsuario = 'Erro ao obter os dados do usuario.'

export type DocumentosResponsavelFinanceiroProps = RouteComponentProps<{
  email: string
}>

const DocumentosResponsavelFinanceiro: FC<DocumentosResponsavelFinanceiroProps> = ({
  match
}) => {
  const history = useHistory()
  const { documentos, atualizar, reiniciar } = useRelacaoDeDocumentos(
    RelacaoDocumentosResponsavelFinanceiro
  )
  const [pronto, definirPronto] = useState<boolean>()
  const [dadosUsuario, definirUsuario] = useState<Usuario>()

  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const modalAceiteDocumentoResponsavelRef = useRef<ModalAceiteDocumentoResponsavelRef>(
    null
  )
  const modalRecusaDocumentoResponsavelRef = useRef<ModalRecusaDocumentoResponsavelRef>(
    null
  )
  const modalVisualizacaoRef = useRef<ModalVisualizacaoPdfRef>()

  const obterUsuarioPorEmail = async (email: string): Promise<Usuario> => {
    try {
      return await Api.RequisitarUsuarioPorEmail(email)
    } catch (erro) {
      throw new Error(erroAoObterOsDadosDoUsuario)
    }
  }

  const obterResumoDeDocumentos = async (idDoUsuario: string) => {
    try {
      const resposta = await Api.ObterDocumentoResponsavelFinanceiro(
        idDoUsuario
      )
      const itensResumo = (resposta
        ? [
            ({
              documentoId: resposta.documentoId,
              nomeArquivo: resposta.nomeArquivo,
              tipo: TipoDocumentoResponsavelFinanceiro.Identificacao,
              dataPostagem: resposta.dataPostagem,
              motivoRejeicao: resposta.motivoRejeicao,
              situacaoUpload: resposta.situacaoUpload,
              dataConferencia: resposta.dataPostagem
            } as unknown) as ResumoDocumentos
          ]
        : []) as ResumoDocumentos[]

      const resultado = ResumoDeDocumentosDeEntradaMapper(
        documentos,
        itensResumo
      )
      reiniciar(resultado)
    } catch (erro) {
      throw new Error(erroAoObterDocumentos)
    }
  }

  const obterDocumentacao = async (): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const usuario = await obterUsuarioPorEmail(match.params.email)

      if (usuario) {
        definirUsuario(usuario)
        await obterResumoDeDocumentos(usuario.id)
      } else {
        throw new Error(erroAoCarregarDados)
      }
    } catch (error) {
      const mensagens = erroAoCarregarDados
      const acaoVoltar = () => history.goBack()
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const downloadDocumentoResponsavel = async (documentoId: string) => {
    try {
      await Api.ObterDownloadDocumentoResponsavel(documentoId)
    } catch (error) {
      toast('Erro ao baixar documento do responsável.', { type: 'error' })
    }
  }

  const visualizarDocumentoResponsavel = async (documentoId: string) => {
    try {
      const documento = await Api.ObterArquivoDocumentoResponsavel(documentoId)
      if (documento) {
        modalVisualizacaoRef?.current?.abrir(documento)
      }
    } catch (error) {
      toast('Erro ao visualizar documento do responsável.', { type: 'error' })
    }
  }

  const aceiteDocumentoResponsavel = async (
    payload: AceiteDocumentoResponsavelFinanceiro
  ) => {
    try {
      await Api.AceiteDocumentoResponsavel(payload)
      toast('Conferência feita com sucesso.', { type: 'success' })
    } catch (erro) {
      toast('Erro ao conferir docuemnto.', { type: 'error' })
    }
  }

  const aprovacao = (id: string): void => {
    const documento = documentos.find(documento => documento.id === id)
    modalAceiteDocumentoResponsavelRef?.current?.abrir(
      documento?.documentoId ?? ''
    )

    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Aprovado
    } as UploadDocumentos

    atualizar(modificacao)
  }

  const rejeicao = (id: string): void => {
    const documento = documentos.find(documento => documento.id === id)
    modalRecusaDocumentoResponsavelRef?.current?.abrir(
      documento?.documentoId ?? ''
    )

    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Recusado
    } as UploadDocumentos

    atualizar(modificacao)
  }

  useEffect(() => {
    obterDocumentacao()
  }, [match])

  return pronto && !erro ? (
    <ContainerDaPagina>
      <ContainerLink>
        <Link
          texto="Voltar"
          acaoVoltar={() => history.goBack()}
          icone={IconeBack}
        />
      </ContainerLink>
      <ContainerBreadcrumbs>
        <Breadcrumb
          titulo={tituloDaPagina}
          atalhos={[
            {
              texto: 'Acadêmico',
              acao: () => history.goBack()
            },
            {
              texto: 'Alunos',
              acao: () => history.goBack()
            },
            {
              texto: tituloDaPagina
            }
          ]}
        />
      </ContainerBreadcrumbs>
      <TituloComplementar>{tituloDaPagina}</TituloComplementar>
      <DescricaoContainer>
        <p>Nome: {dadosUsuario.nomeApresentacao}</p>
        {dadosUsuario?.nacionalidade && (
          <ParagrafoDaNacionalidade>
            Nacionalidade: {dadosUsuario.nacionalidade}
          </ParagrafoDaNacionalidade>
        )}
        {dadosUsuario.tipoUsuario === TipoUsuario.PF ? (
          <>
            <p>CPF: {formatarCpf(dadosUsuario.cpf)}</p>
            <p>Documento de Identidade: {dadosUsuario.documentoIdentidade}</p>
          </>
        ) : (
          <p>CNPJ: {dadosUsuario.cnpj}</p> // fazer formatador cpnj
        )}

        <p>E-mail: {dadosUsuario.email}</p>
      </DescricaoContainer>
      <Dicas>
        O envio dos documentos é obrigatório para emissão do certificado do(s)
        curso(s)
      </Dicas>
      {documentos
        .sort((doc1, doc2) => doc1.ordemExibicao - doc2.ordemExibicao)
        .map(documento => (
          <UploadDeDocumentos
            key={documento.id}
            documento={documento}
            eventoDeMudanca={atualizar}
            eventoDeDownload={downloadDocumentoResponsavel}
            eventoDeVisualizacao={visualizarDocumentoResponsavel}
            controleDeAprovacao={{
              ativo: true,
              eventoDeAprovacao: aprovacao,
              eventoDeRejeicao: rejeicao
            }}
          />
        ))}
      <ContainerDosBotoes>
        <Botao
          texto="Voltar"
          tema="Secundario"
          type="button"
          onClick={() => history.goBack()}
        />
        <BotaoDeSalvar
          texto="Salvar"
          type="button"
          onClick={() => {
            history.goBack()
          }}
        />
      </ContainerDosBotoes>
      <ModalAceiteDocumentoResponsavel
        ref={modalAceiteDocumentoResponsavelRef}
        backdrop
        id="modal-aceite"
        acaoPrimaria={aceiteDocumentoResponsavel}
      />
      <ModalRecusaDocumentoResponsavel
        ref={modalRecusaDocumentoResponsavelRef}
        backdrop
        id="modal-rejeicao"
        acaoPrimaria={aceiteDocumentoResponsavel}
      />
      <ModalVisualizacaoPdf
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento"
      />
    </ContainerDaPagina>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados do aluno..." />
  ) : (
    <ErroContainerLayout />
  )
}

const Container: FC<DocumentosResponsavelFinanceiroProps> = (
  props: DocumentosResponsavelFinanceiroProps
) => (
  <ErroLayoutContainer.Provider>
    <DocumentosResponsavelFinanceiro {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
