import {
  ModalidadeCurso,
  ModalidadeCursoPorExtenso,
  SituacaoFinanceiraFiltro
} from 'src/tipos'

export const SELECT_MODALIDADES = [
  {
    id: ModalidadeCurso[ModalidadeCurso.Livre],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFlex]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFull]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoPresencial]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Graduacao]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Evento],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Evento]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Outra],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Outra]
  }
]

export const OPCOES_SITUACAO_FINANCEIRA = [
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reservado],
    texto: 'Reservado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Inativo],
    texto: 'Inativo'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Matriculado],
    texto: 'Matriculado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.ContratoRescindido],
    texto: 'Contrato Rescindido'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Cancelado],
    texto: 'Cancelado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Transferido],
    texto: 'Transferido'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Finalizado],
    texto: 'Finalizado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reprovado],
    texto: 'Reprovado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Recuperacao],
    texto: 'Em Recuperação'
  },
  {
    id:
      SituacaoFinanceiraFiltro[
        SituacaoFinanceiraFiltro.AguardandoCartaoPagamentoRecorrente
      ],
    texto: 'Aguardando Pag. Recorrente'
  }
]
