import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { ModalBase, Botao, Checkbox } from 'src/componentes'

import { Botoes, Corpo, Texto, Titulo } from './styles'
import {
  ModalAproveitamentoDisciplinaProps,
  ModalAproveitamentoDisciplinaRef
} from './tipos'

const ModalConfirmacaoAproveitamento: React.ForwardRefRenderFunction<
  ModalAproveitamentoDisciplinaRef,
  ModalAproveitamentoDisciplinaProps
> = ({ backdrop, acaoSucesso }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [ehAproveitamento, definirEhAproveitamento] = useState(false)
  const [carregando, definirCarregando] = useState(false)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<
    ModalAproveitamentoDisciplinaRef,
    ModalAproveitamentoDisciplinaRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoAproveitamento = async (aproveitamento: boolean) => {
    try {
      definirCarregando(true)
      await acaoSucesso(aproveitamento)
      fechar()
    } finally {
      definirCarregando(false)
    }
  }

  const acaoCancelar = () => {
    definirEhAproveitamento(false)
    fechar()
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <Titulo>Confirmação de Aproveitamento</Titulo>
        <Texto>Você tem certeza que deseja fazer o aproveitamento?</Texto>
        <Texto>
          Ao confirmar esta ação, não será possível reverter o aproveitamento.
        </Texto>
        <Checkbox
          texto="Sim, tenho certeza e quero prosseguir com esta escolha."
          htmlFor="chk_confirmacao_aproveitamento_disciplina"
          tamanho="L"
        >
          <input
            type="checkbox"
            id="chk_confirmacao_aproveitamento_disciplina"
            checked={ehAproveitamento}
            onChange={() => {
              definirEhAproveitamento(old => !old)
            }}
          />
        </Checkbox>
        <Botoes>
          <Botao
            texto="Cancelar"
            type="button"
            tema="Secundario"
            onClick={() => acaoCancelar()}
          />
          <Botao
            texto="Salvar"
            type="button"
            onClick={() => acaoSucessoAproveitamento(true)}
            disabled={!ehAproveitamento}
            carregando={carregando}
          />
        </Botoes>
      </Corpo>
    </ModalBase>
  )
}

export const ModalConfirmacaoAproveitamentoDisciplina = forwardRef(
  ModalConfirmacaoAproveitamento
)
