import React from 'react'

export default (
  <svg
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2471 31.0644H4.05218C2.3666 31.0644 1 29.7072 1 28.0331V4.29945C1 2.62517 2.36661 1.26782 4.05233 1.26782H44.2471C45.9328 1.26782 47.2994 2.62517 47.2994 4.29945V28.0328C47.2994 29.7072 45.9328 31.0644 44.2471 31.0644Z"
      fill="#F9F9F9"
    />
    <path
      d="M30.9033 26.069C36.354 26.069 40.7727 21.6803 40.7727 16.2665C40.7727 10.8527 36.354 6.46393 30.9033 6.46393C25.4525 6.46393 21.0338 10.8527 21.0338 16.2665C21.0338 21.6803 25.4525 26.069 30.9033 26.069Z"
      fill="#FFC601"
    />
    <path
      d="M17.613 26.069C23.0637 26.069 27.4825 21.6803 27.4825 16.2665C27.4825 10.8527 23.0637 6.46393 17.613 6.46393C12.1622 6.46393 7.74353 10.8527 7.74353 16.2665C7.74353 21.6803 12.1622 26.069 17.613 26.069Z"
      fill="#F43735"
    />
    <path
      d="M44.2471 31.0644H4.05218C2.3666 31.0644 1 29.7072 1 28.0331V4.29945C1 2.62517 2.36661 1.26782 4.05233 1.26782H44.2471C45.9328 1.26782 47.2994 2.62517 47.2994 4.29945V28.0328C47.2994 29.7072 45.9328 31.0644 44.2471 31.0644Z"
      stroke="#DDDDDD"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5867 14.8679C11.4046 15.9182 11.2226 16.9685 11.0384 18.0315C10.7415 18.0315 10.4499 18.0315 10.1317 18.0315C10.3826 16.5637 10.632 15.1038 10.884 13.63C11.3376 13.63 11.7836 13.63 12.2875 13.63C12.2875 14.485 12.2875 15.3419 12.2875 16.199C12.3134 16.2009 12.3395 16.203 12.3654 16.2051C12.5317 15.7233 12.6981 15.2414 12.8645 14.7597C12.979 14.4277 13.0886 14.0941 13.212 13.7653C13.2345 13.7049 13.3098 13.6227 13.3625 13.6215C13.811 13.61 14.2596 13.6148 14.752 13.6148C14.503 15.0961 14.2577 16.5555 14.0099 18.0295C13.6918 18.0295 13.379 18.0295 13.0455 18.0295C13.2117 16.9954 13.3765 15.9674 13.5414 14.9394C13.5276 14.9358 13.5137 14.9322 13.5 14.9286C13.4657 15.014 13.4294 15.0985 13.3978 15.1849C13.0737 16.0712 12.7452 16.956 12.4322 17.8464C12.3748 18.0096 12.298 18.0649 12.1334 18.048C12.0034 18.0349 11.8709 18.0455 11.7091 18.0455C11.6801 16.9785 11.6514 15.9277 11.6227 14.877C11.6106 14.874 11.5985 14.8709 11.5867 14.8679Z"
      fill="white"
    />
    <path
      d="M37.0235 14.8295C37.0927 14.4178 37.1582 14.0292 37.2266 13.6229C37.5326 13.6229 37.8381 13.6229 38.1678 13.6229C38.0971 14.058 38.0266 14.4943 37.9549 14.9304C37.7948 15.9053 37.6297 16.8792 37.4776 17.855C37.455 18.0009 37.3982 18.0353 37.2649 18.0305C37.0304 18.0224 36.7952 18.0284 36.5452 18.0284C36.5452 17.9269 36.5452 17.8437 36.5452 17.7582C36.3863 17.8455 36.2475 17.9435 36.0932 18.0024C35.4772 18.237 34.8669 17.9538 34.6462 17.3035C34.3448 16.4147 34.5184 15.5884 35.113 14.8737C35.6529 14.2244 36.3128 14.2172 37.0235 14.8295ZM36.785 16.0085C36.7655 15.8794 36.7595 15.7463 36.7242 15.6217C36.6223 15.2601 36.2481 15.1233 35.9449 15.3471C35.8262 15.4347 35.7233 15.5637 35.6555 15.6953C35.4977 16.0016 35.4638 16.3348 35.5125 16.6754C35.5442 16.8968 35.6248 17.0883 35.8616 17.1601C36.0924 17.2301 36.3344 17.1354 36.498 16.9158C36.6982 16.6475 36.7732 16.3396 36.785 16.0085Z"
      fill="white"
    />
    <path
      d="M16.4292 15.7642C16.5452 15.4151 16.4376 15.2264 16.0857 15.2265C15.6858 15.2267 15.286 15.275 14.874 15.3034C14.891 15.2016 14.9131 15.0673 14.9359 14.933C15.0047 14.5281 15.0014 14.5034 15.4086 14.448C15.7764 14.3979 16.1581 14.3635 16.5241 14.4055C17.1192 14.4735 17.4661 14.9587 17.3904 15.5494C17.288 16.3472 17.1733 17.1436 17.0567 17.9396C17.0486 17.9954 16.9849 18.0843 16.9428 18.087C16.7098 18.1015 16.4756 18.094 16.2304 18.094C16.2304 17.9833 16.2304 17.8987 16.2304 17.8395C15.9995 17.9423 15.7788 18.0807 15.5384 18.1392C14.9352 18.2858 14.47 17.9397 14.4034 17.3298C14.3058 16.4342 14.8572 15.8309 15.8401 15.7661C16.0369 15.753 16.2354 15.7642 16.4292 15.7642ZM16.327 16.5222C15.9883 16.4637 15.702 16.5061 15.5157 16.6331C15.2923 16.7853 15.2223 17.1157 15.373 17.307C15.5176 17.4908 15.8389 17.4843 16.039 17.2846C16.2465 17.0775 16.3243 16.8187 16.327 16.5222Z"
      fill="white"
    />
    <path
      d="M31.8151 15.7614C31.9009 15.3719 31.812 15.2237 31.444 15.221C31.0575 15.218 30.6703 15.2694 30.2577 15.2986C30.2891 15.1 30.3217 14.8594 30.3698 14.6217C30.3781 14.5807 30.4473 14.5371 30.4971 14.521C30.9583 14.3725 31.4275 14.2994 31.9116 14.3931C32.4978 14.5068 32.8449 14.9711 32.766 15.5593C32.6542 16.3929 32.5312 17.225 32.4104 18.0749C32.1469 18.0749 31.8859 18.0749 31.6138 18.0749C31.6138 17.9826 31.6138 17.8909 31.6138 17.775C31.5321 17.8379 31.4655 17.892 31.396 17.942C31.0328 18.2036 30.5207 18.2469 30.1879 18.0334C29.7821 17.7727 29.7355 17.3654 29.7872 16.9443C29.8739 16.2395 30.4038 15.8082 31.2104 15.763C31.4083 15.752 31.6072 15.7614 31.8151 15.7614ZM31.7115 16.5098C31.414 16.471 31.1466 16.4727 30.9039 16.6256C30.7253 16.7381 30.6408 16.9148 30.6904 17.1126C30.7182 17.2234 30.8094 17.359 30.9081 17.403C31.0937 17.4856 31.2821 17.4218 31.4271 17.2707C31.6249 17.0644 31.6922 16.8094 31.7115 16.5098Z"
      fill="white"
    />
    <path
      d="M22.6411 16.6047C22.5877 16.9607 22.7368 17.1987 23.0753 17.2944C23.444 17.3984 23.8057 17.3348 24.1631 17.2299C24.2543 17.2031 24.3444 17.1731 24.4723 17.1328C24.4185 17.4276 24.3736 17.6991 24.3147 17.9674C24.3057 18.0077 24.2343 18.0504 24.1843 18.0633C23.707 18.187 23.2244 18.2233 22.7419 18.0999C22.1604 17.951 21.8178 17.5682 21.7179 16.9877C21.5849 16.214 21.7355 15.4989 22.2507 14.8872C22.5786 14.4977 23.0064 14.3057 23.5235 14.3677C24.3386 14.4658 24.7812 15.0898 24.6685 15.9652C24.5884 16.5878 24.5884 16.5876 23.9571 16.5881C23.5367 16.5882 23.1163 16.589 22.6963 16.5902C22.6823 16.5902 22.6683 16.5972 22.6411 16.6047ZM22.7169 15.84C23.0382 15.84 23.3449 15.8431 23.6512 15.836C23.6946 15.8351 23.763 15.7912 23.7753 15.753C23.8599 15.4917 23.6404 15.1921 23.3635 15.1785C23.0146 15.1613 22.7663 15.4035 22.7169 15.84Z"
      fill="white"
    />
    <path
      d="M30.0277 13.759C29.9788 14.0785 29.9313 14.3882 29.8822 14.7106C29.6819 14.6432 29.4944 14.5632 29.2989 14.5173C28.8157 14.4039 28.3997 14.5608 28.1257 14.9706C27.7909 15.4711 27.7 16.029 27.8354 16.6094C27.9526 17.1126 28.4029 17.3732 28.9181 17.2609C29.1093 17.2194 29.2946 17.1488 29.5002 17.086C29.451 17.3958 29.4068 17.6981 29.3504 17.9985C29.3423 18.0413 29.2782 18.1009 29.2346 18.1058C28.9072 18.1433 28.5729 18.2212 28.2513 18.1877C27.5187 18.1119 27.0651 17.6845 26.8955 16.969C26.7192 16.2259 26.7882 15.4962 27.1051 14.8026C27.6378 13.6356 28.7828 13.2265 30.0277 13.759Z"
      fill="white"
    />
    <path
      d="M19.8823 14.4359C19.842 14.718 19.8069 14.964 19.7683 15.235C19.5701 15.2262 19.3814 15.2163 19.1927 15.2096C18.8432 15.1974 18.6608 15.2663 18.632 15.4422C18.5848 15.7307 18.8285 15.7953 19.0166 15.8738C19.5297 16.0882 19.8532 16.4455 19.6819 17.271C19.5995 17.668 19.3353 17.9144 18.9501 18.0132C18.4042 18.153 17.8572 18.098 17.2875 17.9881C17.3283 17.7139 17.3663 17.4575 17.4026 17.2135C17.731 17.2438 18.042 17.2781 18.3537 17.2974C18.4457 17.303 18.5471 17.2816 18.632 17.2448C18.8366 17.1564 18.8751 16.9224 18.7011 16.7828C18.5768 16.6832 18.4275 16.6132 18.2854 16.5368C17.7672 16.2576 17.5698 15.8742 17.6668 15.3272C17.7619 14.7904 18.0911 14.4838 18.6611 14.3915C19.0654 14.3262 19.4631 14.3599 19.8823 14.4359Z"
      fill="white"
    />
    <path
      d="M20.4322 13.9193C20.7615 13.9193 21.0539 13.9193 21.3637 13.9193C21.3384 14.0954 21.3152 14.2584 21.2896 14.4367C21.4421 14.444 21.5788 14.4504 21.7409 14.4583C21.7037 14.7447 21.6684 15.017 21.6304 15.3092C21.47 15.3092 21.3189 15.3092 21.1403 15.3092C21.0814 15.653 21.0246 15.9808 20.9691 16.309C20.9398 16.4819 20.9137 16.6553 20.8849 16.8284C20.832 17.1455 20.9374 17.2627 21.2642 17.2464C21.3149 17.244 21.3653 17.2382 21.4444 17.2315C21.4112 17.4719 21.3835 17.7005 21.3445 17.9272C21.3375 17.9669 21.2862 18.0254 21.2498 18.0296C20.9747 18.0621 20.6941 18.1248 20.4236 18.0972C20.0952 18.0639 19.8934 17.7981 19.9077 17.4355C19.9238 17.0281 19.9803 16.6209 20.0435 16.2172C20.1634 15.4526 20.3006 14.691 20.4322 13.9193Z"
      fill="white"
    />
    <path
      d="M33.6282 18.0712C33.2912 18.0712 32.9764 18.0712 32.6511 18.0712C32.85 16.8616 33.047 15.6649 33.2458 14.4562C33.5419 14.4562 33.8251 14.4562 34.128 14.4562C34.1086 14.599 34.0903 14.7343 34.066 14.9153C34.3205 14.6352 34.5347 14.3408 34.9486 14.4068C34.8347 14.7628 34.7227 15.1136 34.6092 15.4679C34.2939 15.4782 34.1584 15.5498 34.0672 15.8881C33.9363 16.374 33.8504 16.8719 33.7506 17.3659C33.7031 17.6007 33.6683 17.8381 33.6282 18.0712Z"
      fill="white"
    />
    <path
      d="M25.6186 18.0753C25.2825 18.0753 24.9741 18.0753 24.6431 18.0753C24.7182 17.6067 24.7903 17.148 24.8656 16.6899C24.9795 15.9967 25.0989 15.3048 25.2072 14.6109C25.2268 14.4851 25.2658 14.4333 25.3997 14.4388C25.6338 14.4485 25.8684 14.4415 26.1198 14.4415C26.098 14.6015 26.0794 14.7376 26.0547 14.9171C26.3102 14.634 26.5258 14.3396 26.94 14.4064C26.8252 14.7641 26.7124 15.1157 26.5991 15.4692C26.2923 15.4741 26.1509 15.5461 26.0627 15.8715C25.9293 16.3643 25.8416 16.8694 25.74 17.3703C25.6936 17.5988 25.6605 17.8298 25.6186 18.0753Z"
      fill="white"
    />
    <path
      d="M8.74121 19.1991H8.90543C8.86922 19.0969 8.82579 18.9986 8.79305 18.8949L8.74121 19.1991Z"
      fill="white"
    />
    <path
      d="M26.5038 12.0307C25.9372 10.8227 25.1218 9.75208 24.1268 8.88731C22.3854 7.33623 20.1189 6.41656 17.6175 6.41656C12.1862 6.41656 7.80524 10.7816 7.80524 16.1623C7.80524 21.543 12.1862 25.908 17.6175 25.908C20.1189 25.908 22.3854 24.9884 24.1268 23.4373C25.0665 22.6274 25.8405 21.6254 26.3933 20.4998"
      stroke="#303030"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7496 12.0305C22.3162 10.8226 23.1316 9.75195 24.1266 8.88719C25.868 7.33611 28.1345 6.41644 30.6359 6.41644C36.0534 6.41644 40.4482 10.7814 40.4482 16.1622C40.4482 21.5429 36.0534 25.9079 30.6359 25.9079C28.1345 25.9079 25.868 24.9882 24.1266 23.4372C23.1869 22.6273 22.4129 21.6253 21.8463 20.4997"
      stroke="#303030"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
