import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaTempoProrrogacao = (): TabelaColuna => ({
  Chave: 'tempoProrrogacao',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ maxWidth: 200, minWidth: 100 }}>Tempo Prorrogação</div>
  )
})
