import { Cabecalho } from 'src/componentes/cabecalho'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContainerLink = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const CabecalhoComEstilos = styled(Cabecalho)`
  flex-direction: column;
`

export const ContainerAcoesBotao = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 20px;
`

export const IconeTabela = styled.span`
  svg path {
    fill: #666666;
  }
`

export const ContainerIcone = styled.span`
  display: flex;
  align-items: center;
`

export const ContainerProfessor = styled.span`
  display: flex;
  gap: 8px;
`
