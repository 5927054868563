import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { NegativadoFinanceiroPorExtenso } from 'src/tipos'

export const colunaNegativadoFinanceiro = (): TabelaColuna => ({
  Chave: 'negativadoFinanceiro',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <>{NegativadoFinanceiroPorExtenso[linha.negativadoFinanceiro]}</>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 100 }}>Negativado Financeiro</div>
  )
})
