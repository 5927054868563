import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaDataFimOriginal = (): TabelaColuna => ({
  Chave: 'dataFimOriginal',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataFimOriginal)}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 90 }}>Data Fim Original</div>
})
