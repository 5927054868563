export enum Escolaridade {
  NaoEspecializado = 'NaoEspecializado',
  Especializacao = 'Especializacao',
  Mestrado = 'Mestrado',
  Doutorado = 'Doutorado',
  PosDoutorado = 'PosDoutorado',
  EnsinoSuperiorCompleto = 'EnsinoSuperiorCompleto',
  EnsinoSuperiorEmAndamento = 'EnsinoSuperiorEmAndamento',
  EnsinoMedio = 'EnsinoMedio',
  PosGraduado = 'PosGraduado'
}

export enum EscolaridadePorExtenso {
  'NaoEspecializado' = 'Não Especializado',
  'Especializacao' = 'Especialização',
  'Mestrado' = 'Mestrado',
  'Doutorado' = 'Doutorado',
  'PosDoutorado' = 'Pós Doutorado',
  'EnsinoSuperiorCompleto' = 'Ensino Superior Completo',
  'EnsinoSuperiorEmAndamento' = 'Ensino Superior em Andamento',
  'EnsinoMedio' = 'Ensino Médio',
  'PosGraduado' = 'Pós Graduação'
}
