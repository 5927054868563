import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { ModalProrrogacaoProps, ModalIncluirProrrogacaoRef } from '..'

import {
  Botao,
  FormUnform,
  MoedaInputUnform,
  NumeroInputUnform,
  Spacer
} from '../../../../../../componentes'
import { ModalBase } from '../../../../../../componentes/modal-base'
import { PlanoPagamentoProrrogacao } from '../../../../../../tipos'
import { Corpo, ContainerBotoes } from '../../styles'

const ModalIncluir: React.ForwardRefRenderFunction<
  ModalIncluirProrrogacaoRef,
  ModalProrrogacaoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [pronto, definirPronto] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const [, definirDados] = useState<PlanoPagamentoProrrogacao>(null)
  const [
    planoPagamento,
    definirPlanoPagamento
  ] = useState<PlanoPagamentoProrrogacao>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = planoPagamento => {
    definirPlanoPagamento(planoPagamento)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalIncluirProrrogacaoRef, ModalIncluirProrrogacaoRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucesso = async (dados: PlanoPagamentoProrrogacao) => {
    const novaProrrogacao = {
      id: planoPagamento.id,
      quantidadeMeses: dados.quantidadeMeses,
      valor: dados.valor,
      quantidadeParcelas: dados.quantidadeParcelas
    }

    definirDados(dados)
    acaoPrimaria(novaProrrogacao)
    fechar()
  }

  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo>
          <h5 style={{ margin: 'auto' }}> Editar Prorrogação </h5>
          <Spacer padding="10px 0px" />
          {pronto && (
            <FormUnform
              dadosIniciais={planoPagamento}
              acaoSucesso={acaoSucesso}
            >
              <NumeroInputUnform
                name="quantidadeMeses"
                max={9999}
                maxLength={4}
                label="Tempo de Prorrogação"
              />
              <NumeroInputUnform
                name="quantidadeParcelas"
                max={9999}
                maxLength={4}
                label="Quantidade de parcelas"
              />
              <MoedaInputUnform name="valor" label="Valor da Prorrogação" />
              <Spacer padding="10px 0px" />
              <ContainerBotoes>
                <Botao
                  type="button"
                  id="btn-cancelar"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                />
                <Botao id="btn-salvar" texto="Salvar" type="submit" />
              </ContainerBotoes>
            </FormUnform>
          )}
        </Corpo>
      </ModalBase>
    </>
  )
}
export const ModalIncluirProrrogacao = forwardRef(ModalIncluir)
