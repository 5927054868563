import React from 'react'

export default (
  <svg width="32" height="32" viewBox="0 0 24 24">
    <g
      fill="none"
      stroke="#00A69C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="m18 2l3 3l-3 3M6 22l-3-3l3-3" />
      <path d="M21 5H10a7 7 0 0 0-7 7m0 7h11a7 7 0 0 0 7-7" />
    </g>
  </svg>
)
