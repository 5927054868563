import React, { useMemo, useEffect, useState, useRef } from 'react'

import {
  Checkbox,
  FormUnform,
  FormRef,
  InputUnform,
  FuncoesMoeda,
  Informacao,
  Botao
} from 'src/componentes'
import { Api } from 'src/servicos'
import { CupomValidoCurso, validaEhModalidadeLivreOuEvento } from 'src/tipos'

import {
  ContainerConteudo,
  ContainerInfosPagamento,
  ContainerInfos,
  ContainerCupom,
  TituloInfo,
  Info,
  ContainerInputCupom,
  CupomContainer
} from './styles'
import { InfoValoresCursoProps } from './tipos'

export const InfoValoresCurso: React.FC<InfoValoresCursoProps> = ({
  curso,
  matricula,
  pagamentoComCartao,
  boletoGerado,
  formaPagamento,
  recorrencia,
  acaoAlterarCupom,
  acaoAtualizarValorCurso,
  acaoAtualizarValorDescontoCurso,
  cupomId,
  cupomCodigo
}) => {
  const formCupomRef = useRef<FormRef>(null)
  const [idCupom, definirIdCupom] = useState<string>(cupomId ?? null)
  const [codigoCupom, definirCodigoCupom] = useState<string>(
    cupomCodigo ?? null
  )
  const [temCupom, definirTemCupom] = useState<boolean>(!!cupomId)
  const [cupom, definirCupom] = useState<CupomValidoCurso>(
    {} as CupomValidoCurso
  )

  const ehCursoLivreOuEvento = useMemo(
    () => validaEhModalidadeLivreOuEvento(curso.modalidade),
    [curso]
  )
  const valorFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(curso.valorTaxaMatricula)
  }, [curso.valorTaxaMatricula])

  const valorDesconto = useMemo(() => cupom?.valorDesconto ?? 0, [
    cupom,
    cupom.valorDesconto
  ])

  const valorDescontoFormatado = useMemo(
    () => FuncoesMoeda.formatarMoeda(valorDesconto),
    [valorDesconto]
  )

  const valorTotal = useMemo(() => {
    let novoValor = curso.valorTaxaMatricula
    if (recorrencia) {
      novoValor = 0
    } else if (valorDesconto < novoValor) {
      novoValor -= valorDesconto
    } else {
      novoValor = 0
    }

    return novoValor
  }, [curso.valorTaxaMatricula, valorDesconto, recorrencia])

  const valorTotalFormatado = useMemo(
    () => FuncoesMoeda.formatarMoeda(valorTotal),
    [valorTotal]
  )

  const validarCupom = async ({ codigoCupom }: { codigoCupom?: string }) => {
    if (codigoCupom) {
      const novoCupom = await Api.RequisitarValidarCupom(
        matricula.id,
        codigoCupom,
        'TaxaMatricula',
        formaPagamento
      )

      if (novoCupom) {
        definirCupom({ ...novoCupom, codigoCupom, cursoId: matricula.cursoId })
        definirIdCupom(novoCupom.cupomId)
        formCupomRef.current?.setFieldError('codigoCupom', 'Cupom válido')
      } else {
        definirCupom({} as CupomValidoCurso)
        formCupomRef.current?.setFieldError('codigoCupom', 'Cupom inválido')
      }
    } else {
      definirCupom({} as CupomValidoCurso)
      formCupomRef.current?.setFieldError('codigoCupom', '')
    }
  }

  useEffect(() => {
    if (acaoAlterarCupom) {
      acaoAlterarCupom(temCupom, {
        ...cupom,
        cupomId: cupom?.cupomId ?? idCupom,
        cursoId: cupom?.cursoId ?? matricula.cursoId
      })
    }

    if (!temCupom) {
      definirIdCupom(null)
    }
  }, [temCupom, cupom])

  useEffect(() => {
    if (acaoAtualizarValorCurso) {
      acaoAtualizarValorCurso(curso.id, valorTotal)
    }
  }, [valorTotal])

  useEffect(() => {
    if (acaoAtualizarValorDescontoCurso) {
      acaoAtualizarValorDescontoCurso(curso.id, valorDesconto)
    }
  }, [valorDesconto])

  useEffect(() => {
    validarCupom({ codigoCupom })
  }, [formaPagamento])

  return (
    <>
      <ContainerConteudo>
        <h2>
          {ehCursoLivreOuEvento
            ? `${curso.nome} (${curso.cargaHoraria}h)`
            : 'Matrícula'}
        </h2>
        <ContainerInfosPagamento>
          <ContainerInfos>
            <TituloInfo>
              {ehCursoLivreOuEvento
                ? 'Valores do Curso'
                : 'Valores da Taxa de Matrícula'}
            </TituloInfo>
            <Info>
              <p>Valor</p>
              <p>{valorFormatado}</p>
            </Info>
            <Info>
              <p>Cupom de Desconto ( - )</p>
              <p>{valorDescontoFormatado}</p>
            </Info>
            <Info negrito>
              <p>
                Valor a Pagar
                {!ehCursoLivreOuEvento && <sup> 1</sup>}
              </p>
              <p>{valorTotalFormatado}</p>
            </Info>
            {!ehCursoLivreOuEvento && (
              <Info>
                <Informacao
                  invertido
                  id="informacao-valor-nao-reembolsavel"
                  textoTooltip="De acordo com a política interna do iPGS, quando o aluno desiste de participar do curso, o valor da taxa de matrícula não é reembolsável."
                >
                  <sup>1</sup> Valor não reembolsável em caso de desistência
                </Informacao>
              </Info>
            )}
          </ContainerInfos>
          {curso.valorTaxaMatricula && !recorrencia && (
            <ContainerCupom>
              <Checkbox
                id={`chk_cupom_${curso.id}`}
                texto="Tenho um Cupom"
                htmlFor={`chk_tem_cupom_${curso.id}`}
                tamanho="L"
              >
                <input
                  type="checkbox"
                  id={`chk_tem_cupom_${curso.id}`}
                  checked={temCupom}
                  disabled={!pagamentoComCartao && boletoGerado}
                  onChange={() => {
                    definirTemCupom(old => !old)
                    definirCupom({} as CupomValidoCurso)
                  }}
                />
              </Checkbox>

              {temCupom ? (
                <ContainerInputCupom>
                  <FormUnform
                    dadosIniciais={{
                      codigoCupom:
                        matricula.pagamentoMatricula?.codigoCupom ||
                        cupomCodigo ||
                        ''
                    }}
                    ref={formCupomRef}
                    acaoSucesso={validarCupom}
                  >
                    <CupomContainer>
                      <InputUnform
                        id="ipt_cupom"
                        name="codigoCupom"
                        sucesso={!!cupom.cupomId}
                        disabled={!pagamentoComCartao && boletoGerado}
                        onBlur={event => {
                          definirCodigoCupom(event.target.value)
                          if (!pagamentoComCartao && boletoGerado) {
                            return
                          }
                          formCupomRef.current?.submitForm()
                        }}
                        placeholder="Código do cupom"
                      />
                      <Botao
                        id="btn_validar_cupom"
                        type="button"
                        onClick={() => {
                          formCupomRef.current?.submitForm()
                        }}
                        texto="Validar"
                      />
                    </CupomContainer>
                  </FormUnform>
                </ContainerInputCupom>
              ) : (
                <></>
              )}
            </ContainerCupom>
          )}
        </ContainerInfosPagamento>
      </ContainerConteudo>
    </>
  )
}
