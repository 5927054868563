import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useMemo
} from 'react'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  ModalBaseProps,
  ModalRef,
  FormUnform,
  SelectUnform,
  SelectOpcao
} from 'src/componentes'

import { schema } from './schema'
import {
  ContainerBotoes,
  ContainerModal,
  Titulo,
  ContainerDoConteudo
} from './styles'

export type ModalReciboAnualProps = Omit<ModalBaseProps, 'acaoPrimaria'> & {
  acaoPrimaria: (ano: number) => Promise<void> | void
}

export interface ModalReciboAnualRef extends Omit<ModalRef, 'abrir'> {
  abrir: (opcoesAno: number[]) => void
}

const ModalReciboAnualComponent: React.ForwardRefRenderFunction<
  ModalReciboAnualRef,
  ModalReciboAnualProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [carregando, definirCarregando] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [opcoesAno, definirOpcoesAno] = useState<number[]>([])

  const fechar = () => {
    definirOpcoesAno([])
    definirCarregando(false)
    definirAberta(false)
  }

  const abrir = (opcoes: number[]) => {
    definirOpcoesAno(opcoes)
    definirAberta(true)
  }

  useImperativeHandle<ModalReciboAnualRef, ModalReciboAnualRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = (dados: any) => {
    try {
      definirCarregando(true)
      acaoPrimaria(dados.ano)
    } finally {
      fechar()
    }
  }

  const opcoesSelect: SelectOpcao[] = useMemo(() => {
    return (
      opcoesAno?.map(x => {
        return { id: `${x}`, texto: `${x}` }
      }) ?? []
    )
  }, [opcoesAno])

  return (
    <ModalBase
      id="modal-atualizar-data-cobranca"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <Titulo>Recibo por ano contábil</Titulo>
        <FormUnform ref={formRef} acaoSucesso={acaoSucesso} schema={schema}>
          <ContainerDoConteudo>
            <SelectUnform
              id="slct-ano-recibo"
              label="Ano contábil"
              name="ano"
              opcoes={opcoesSelect}
            />
          </ContainerDoConteudo>
          <ContainerBotoes>
            <Botao
              texto="Cancelar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Gerar" carregando={carregando} />
          </ContainerBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}
export const ModalReciboAnual = forwardRef(ModalReciboAnualComponent)
