import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaDataUltimoAcesso = (): TabelaColuna => ({
  Chave: 'dataUltimoAcesso',
  Texto: 'Data Último Acesso',
  StyleColuna: { width: 100, paddingInline: 10 },
  Renderizar: (linha: TabelaDados) => (
    <>{formatadores.dataLocaleString(linha.dataUltimoAcesso)}</>
  )
})
