import React from 'react'
import { Link } from 'react-router-dom'

import { StatusCarrinhoCompra } from 'src/tipos'

import { formatadores } from '../pagina-financeiro/formatadores'
import {
  Container,
  ContainerLink,
  TextoSituacaoFinanceira,
  TituloCursosCarrinho
} from './styles'

export interface ItemCarrinhoCompraProps {
  id: string
  className?: string
  cursosNomes: string[]
  link: string
  valorTotal?: number
  saldoDevedor: number
  status: string
}

export const ItemCarrinhoCompra: React.FC<ItemCarrinhoCompraProps> = ({
  className,
  cursosNomes,
  link,
  valorTotal,
  saldoDevedor,
  status
}) => {
  return (
    <Container className={className}>
      <div>
        {cursosNomes?.map(c => (
          <TituloCursosCarrinho className="tituloCursoCarrinho" key={c}>
            {c}
          </TituloCursosCarrinho>
        ))}
        <p>
          <b>Valor total:</b> {formatadores.moeda(valorTotal)}
        </p>
        <p>
          <b> Saldo devedor: </b>
          {formatadores.moeda(saldoDevedor)}
        </p>
        <TextoSituacaoFinanceira>
          {StatusCarrinhoCompra[status]}
        </TextoSituacaoFinanceira>
        <ContainerLink>
          <Link to={link}>Ver Parcelas</Link>
        </ContainerLink>
      </div>
    </Container>
  )
}
