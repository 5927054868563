import {
  SituacaoFinanceiraFiltro,
  SituacaoFinanceiraPorExtenso,
  TipoMatricula
} from 'src/tipos'

export const OPCOES_SITUACAO_FINANCEIRA = [
  {
    id:
      SituacaoFinanceiraFiltro[
        SituacaoFinanceiraFiltro.AguardandoCartaoPagamentoRecorrente
      ],
    texto:
      SituacaoFinanceiraPorExtenso[
        SituacaoFinanceiraFiltro.AguardandoCartaoPagamentoRecorrente
      ]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Cancelado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Cancelado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.ContratoRescindido],
    texto:
      SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.ContratoRescindido]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Finalizado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Finalizado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Inativo],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Inativo]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Matriculado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Matriculado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reprovado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Reprovado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reservado],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Reservado]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Transferido],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Transferido]
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Recuperacao],
    texto: SituacaoFinanceiraPorExtenso[SituacaoFinanceiraFiltro.Recuperacao]
  }
]

export const OPCOES_STATUS_MATRICULA = [
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Finalizado],
    texto: 'Aprovado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Matriculado],
    texto: 'Matriculado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reprovado],
    texto: 'Reprovado'
  }
]

export const OPCOES_TIPO_MATRICULA = [
  {
    id: TipoMatricula[TipoMatricula.Cancelado7Dias],
    texto: 'Cancelado 7 Dias'
  },
  {
    id: TipoMatricula[TipoMatricula.ColaboradorIPGS],
    texto: 'Colaborador IPGS'
  },
  {
    id: TipoMatricula[TipoMatricula.ErroMatricula],
    texto: 'Erro Matrícula'
  },
  {
    id: TipoMatricula[TipoMatricula.Normal],
    texto: 'Normal'
  },
  {
    id: TipoMatricula[TipoMatricula.Ouvinte],
    texto: 'Ouvinte'
  },
  {
    id: TipoMatricula[TipoMatricula.Reingresso],
    texto: 'Reingresso'
  },
  {
    id: TipoMatricula[TipoMatricula.Transferencia],
    texto: 'Transferência'
  },
  {
    id: TipoMatricula[TipoMatricula.TrocaDeCurso],
    texto: 'Troca de Curso'
  }
]
