import { colunaAprovadoCurso } from './aprovado-curso'
import { colunaCelular } from './celular'
import { colunaCidade } from './cidade'
import { colunaCpf } from './cpf'
import { colunaCurso } from './curso'
import { colunaDataAprovacao } from './data-aprovacao'
import { colunaDataConclusao } from './data-conclusao'
import { colunaDataInicio } from './data-inicio'
import { colunaDataMatricula } from './data-matricula'
import { colunaDataPrimeiraVisualizacao } from './data-primeira-visualizacao'
import { colunaDataReprovacao } from './data-reprovacao'
import { colunaDataUltimoAcessoPortal } from './data-ultimo-acesso-portal'
import { colunaDataUltimoAcessoSala } from './data-ultimo-acesso-sala'
import { colunaDisciplinaOrdemNome } from './disciplina-ordem-nome'
import { colunaDocumentoOrgaoEmissor } from './documento-orgao-emissor'
import { colunaEmail } from './email'
import { colunaModalidade } from './modalidade'
import { colunaModoAvaliacao } from './modo-avaliacao'
import { colunaModuloOrdemNome } from './modulo-ordem-nome'
import { colunaNomeAluno } from './nome-aluno'
import { colunaNomeAvaliacao } from './nome-avaliacao'
import { colunaNota } from './nota'
import { colunaNotaAproveitamento } from './nota-por-aproveitamento'
import { colunaProfessoresTCC } from './professores-tcc'
import { colunaProgressoCurso } from './progresso-curso'
import { colunaProrrogacaoMatricula } from './prorrogacao-matricula'
import { colunaRecuperacao } from './recuperacao'
import { colunaSituacaoFinanceira } from './situacao-financeira'
import { colunaTentativasRestantes } from './tentativas-restantes'
import { colunaTipoAvaliacao } from './tipo-avaliacao'
import { colunaTipoMatricula } from './tipo-matricula'
import { colunaTotalTentativas } from './total-tentativas'
import { colunaTurma } from './turma'
import { colunaUF } from './uf'

export const COLUNAS_HISTORICO_ESCOLAR = [
  colunaNomeAluno(),
  colunaEmail(),
  colunaCpf(),
  colunaDocumentoOrgaoEmissor(),
  colunaCelular(),
  colunaCidade(),
  colunaUF(),
  colunaCurso(),
  colunaTurma(),
  colunaModalidade(),
  colunaSituacaoFinanceira(),
  colunaTipoMatricula(),
  colunaDataMatricula(),
  colunaDataInicio(),
  colunaDataConclusao(),
  colunaProrrogacaoMatricula(),
  colunaDataPrimeiraVisualizacao(),
  colunaDataUltimoAcessoSala(),
  colunaDataUltimoAcessoPortal(),
  colunaProgressoCurso(),
  colunaAprovadoCurso(),
  colunaDataAprovacao(),
  colunaDataReprovacao(),
  colunaModoAvaliacao(),
  colunaModuloOrdemNome(),
  colunaDisciplinaOrdemNome(),
  colunaNota(),
  colunaNotaAproveitamento(),
  colunaNomeAvaliacao(),
  colunaTipoAvaliacao(),
  colunaTentativasRestantes(),
  colunaTotalTentativas(),
  colunaRecuperacao(),
  colunaProfessoresTCC()
]
