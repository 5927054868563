import React from 'react'

import { Container } from './styles'

export interface NavegacaoItem {
  nome: string
  link: string
}

export interface NavegacaoProps {
  itens: NavegacaoItem[]
  className?: string
}

export const Navegacao: React.FC<NavegacaoProps> = ({ itens, className }) => {
  return (
    <Container className={className}>
      <h4>Navegação</h4>
      <ul>
        {itens.map((item, i) => (
          <li key={i}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                document
                  .querySelectorAll(`#${item.link}`)
                  ?.item(0)
                  ?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                  })
              }}
            >
              {item.nome}
            </a>
          </li>
        ))}
      </ul>
    </Container>
  )
}
