import React, { useEffect, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast, ToastOptions } from 'react-toastify'

import {
  AvisoContainer,
  ResumoDeDocumentosDeEntradaMapper,
  ResumoDeDocumentosDeSaidaMapper,
  selecaoDeDocumentosParaEnvio,
  TipoDeAviso,
  UploadDeDocumentos,
  UploadDocumentos,
  RelacaoDocumentosResponsavelFinanceiro,
  useRelacaoDeDocumentos
} from 'src/compartilhados'
import {
  AutenticacaoContainer,
  Botao,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  Modal,
  ModalRef,
  ModalVisualizacaoPdf,
  ModalVisualizacaoPdfRef
} from 'src/componentes'
import { RotasResponsavelFinanceiro } from 'src/rotas/responsavel-financeiro'
import { Api } from 'src/servicos'
import { ResumoDocumentos, TipoDocumentoResponsavelFinanceiro } from 'src/tipos'
import { v4 } from 'uuid'

import {
  BotaoDeSalvar,
  ContainerDaPagina,
  ContainerDosBotoes,
  TituloComplementar,
  TituloPrincipal
} from './styles'

const documentosSalvosComSucesso = 'Documentos salvos com sucesso.'
const documentosPendentes = 'Você ainda possui documentos pendentes.'
const documentosSalvosComPendencia = `Documentos salvos com sucesso. ${documentosPendentes}`
const erroAoEnviarDocumentos = 'Erro ao enviar documentos.'
const erroAoBaixarDocumentos = 'Erro ao baixar documentos.'
const erroAoProcessarDocumentos = 'Erro ao processar documentos.'
const ErroAoObterDocumentos = 'Não foi possível obter os documentos.'
const toastOptions = { type: 'error' } as ToastOptions

const DocumentosResponsavelFinanceiro: React.FC = () => {
  const history = useHistory()
  const { documentos, atualizar, reiniciar } = useRelacaoDeDocumentos(
    RelacaoDocumentosResponsavelFinanceiro
  )

  const [pronto, definirPronto] = useState<boolean>()
  const [carregando, definirCarregando] = useState<boolean>()
  const modalDeCancelamento = useRef<ModalRef>(null)

  const modalVisualizacaoRef = useRef<ModalVisualizacaoPdfRef>(null)
  const aviso = AvisoContainer.useContainer()
  const { usuario } = AutenticacaoContainer.useContainer()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const obterResumoDocumentos = async (idDoUsuario: string) => {
    try {
      definirPronto(false)
      limparErro()
      const resposta = await Api.ObterDocumentoResponsavelFinanceiro(
        idDoUsuario
      )

      const itensResumo = (resposta
        ? [
            ({
              documentoId: resposta.documentoId,
              nomeArquivo: resposta.nomeArquivo,
              tipo: TipoDocumentoResponsavelFinanceiro.Identificacao,
              dataPostagem: resposta.dataPostagem,
              motivoRejeicao: resposta.motivoRejeicao,
              situacaoUpload: resposta.situacaoUpload,
              dataConferencia: resposta.dataPostagem
            } as unknown) as ResumoDocumentos
          ]
        : []) as ResumoDocumentos[]

      const resultado = ResumoDeDocumentosDeEntradaMapper(
        documentos,
        itensResumo
      )
      reiniciar(resultado)
    } catch (erro) {
      const mensagens = ErroAoObterDocumentos
      const acaoVoltar = () => history.goBack()
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const obterDownloadDeDocumentos = async (
    documentoId: string
  ): Promise<void> => {
    try {
      await Api.ObterDownloadDocumentoResponsavel(documentoId)
    } catch (erro) {
      toast(erroAoBaixarDocumentos, toastOptions)
    }
  }

  const visualizarDocumento = async (documentoId: string) => {
    try {
      const documento = await Api.ObterArquivoDocumentoResponsavel(documentoId)
      if (documento) {
        modalVisualizacaoRef?.current?.abrir(documento)
      }
    } catch (error) {
      toast(ErroAoObterDocumentos, toastOptions)
    }
  }

  const verificacaoDePreenchimento = () => {
    const documentosPreenchidos = documentos.every(documento => documento.nome)

    documentosPreenchidos
      ? aviso.criar(documentosSalvosComSucesso, TipoDeAviso.Sucesso)
      : aviso.criar(documentosSalvosComPendencia, TipoDeAviso.Advertencia)

    history.push(RotasResponsavelFinanceiro.Dashboard)
  }

  const upload = async (documentos: UploadDocumentos[]): Promise<void> => {
    try {
      let resposta
      for (let i = 0; i < documentos.length; i++) {
        const documento = ResumoDeDocumentosDeSaidaMapper(documentos)
        resposta = await Api.EnviarDocumentoResponsavel(documento)
      }

      if (resposta) {
        verificacaoDePreenchimento()
      } else {
        toast(erroAoEnviarDocumentos, toastOptions)
      }
    } catch (erro) {
      toast(erroAoEnviarDocumentos, toastOptions)
    }
  }

  const uploadDeDocumentos = async (
    edicoes: UploadDocumentos[],
    criacoes: UploadDocumentos[]
  ): Promise<void> => {
    try {
      definirCarregando(true)
      if (edicoes.length > 0) await upload(edicoes)
      if (criacoes.length > 0) await upload(criacoes)
    } catch (erro) {
      toast(erroAoProcessarDocumentos, toastOptions)
    } finally {
      definirCarregando(false)
    }
  }

  const eventoDeUpload = async (): Promise<void> => {
    const { edicoes, criacoes, contador } = selecaoDeDocumentosParaEnvio(
      documentos
    )

    if (contador === 0) history.push(RotasResponsavelFinanceiro.Dashboard)
    else await uploadDeDocumentos(edicoes, criacoes)
  }

  useEffect(() => {
    obterResumoDocumentos(usuario.id)
  }, [])

  return pronto && !erro ? (
    <ContainerDaPagina>
      <TituloPrincipal>Envio de Documentos</TituloPrincipal>
      <TituloComplementar>Meus Documentos</TituloComplementar>
      {documentos
        .sort((doc1, doc2) => doc1.ordemExibicao - doc2.ordemExibicao)
        .map(documento => (
          <UploadDeDocumentos
            key={documento.id ?? v4()}
            documento={documento}
            eventoDeMudanca={atualizar}
            eventoDeDownload={obterDownloadDeDocumentos}
            eventoDeVisualizacao={visualizarDocumento}
            tiposAceitos={['.pdf', '.jpg', '.jpeg', '.png']}
          />
        ))}
      <ContainerDosBotoes>
        <Botao
          texto="Cancelar"
          tema="Secundario"
          type="button"
          onClick={() => modalDeCancelamento?.current?.abrir()}
        />
        <BotaoDeSalvar
          texto="Salvar"
          type="button"
          onClick={eventoDeUpload}
          carregando={carregando}
        />
      </ContainerDosBotoes>
      <Modal
        ref={modalDeCancelamento}
        backdrop
        id="modal-de-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.push(RotasResponsavelFinanceiro.Dashboard)
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamento?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
      <ModalVisualizacaoPdf
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento"
      />
    </ContainerDaPagina>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando documentos..." />
  ) : (
    <ErroContainerLayout />
  )
}

const Container: React.FC = () => (
  <ErroLayoutContainer.Provider>
    <DocumentosResponsavelFinanceiro />
  </ErroLayoutContainer.Provider>
)
export default withRouter(Container)
