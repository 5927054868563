import React, {
  ChangeEvent,
  useState,
  useCallback,
  useMemo,
  forwardRef
} from 'react'

import { Obrigatorio } from '../styles'
import {
  Apresentacao,
  BotaoSelecao,
  CampoInput,
  CampoLabel,
  Container
} from './styles'
import { InputArquivoProps } from './tipos'

export const InputArquivo: React.ForwardRefExoticComponent<InputArquivoProps> = forwardRef<
  HTMLInputElement,
  InputArquivoProps
>(
  (
    {
      label,
      id,
      name,
      comErro,
      valorPadrao,
      tiposAceitos,
      acaoAoAlterar,
      obrigatorio,
      ...rest
    },
    ref?: any
  ) => {
    const [arquivo, definirArquivo] = useState<File>(null)

    const obterTipo = useMemo(
      () => (tiposAceitos ? tiposAceitos.join(',') : undefined),
      [tiposAceitos]
    )

    const manipularAlteracao = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        acaoAoAlterar && acaoAoAlterar(e)
        const arquivo = e.target.files?.[0]

        if (!arquivo) {
          definirArquivo(null)
          return
        }

        definirArquivo(arquivo)
      },
      []
    )
    return (
      <Container>
        {label && (
          <CampoLabel htmlFor={id}>
            {obrigatorio ? <Obrigatorio>*</Obrigatorio> : null}
            {label}
          </CampoLabel>
        )}
        <input
          ref={ref}
          type="file"
          {...rest}
          id={id}
          name={name}
          accept={obterTipo}
          onChange={manipularAlteracao}
          onReset={() => definirArquivo(null)}
          hidden
          required={obrigatorio}
        />
        <Apresentacao>
          <CampoInput
            type="text"
            value={arquivo?.name ?? valorPadrao}
            comErro={comErro}
            readOnly
          />
          <BotaoSelecao type="button" onClick={() => ref?.current?.click()}>
            Procurar
          </BotaoSelecao>
        </Apresentacao>
      </Container>
    )
  }
)

export * from './tipos'
