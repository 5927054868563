import { TipoMatricula } from 'src/tipos'

import { SituacaoMatricula } from './tipos'

export const OPCOES_SITUACAO = [
  {
    id: SituacaoMatricula[SituacaoMatricula.Cancelado],
    texto: 'Cancelado'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.ContratoRescindido],
    texto: 'Contrato Rescindido'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.Finalizado],
    texto: 'Finalizado'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.Inativo],
    texto: 'Inativo'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.Matriculado],
    texto: 'Matriculado'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.Reprovado],
    texto: 'Reprovado'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.Reservado],
    texto: 'Reservado'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.Transferido],
    texto: 'Transferido'
  },
  {
    id:
      SituacaoMatricula[SituacaoMatricula.AguardandoCartaoPagamentoRecorrente],
    texto: 'Aguardando pag. recorrente'
  },
  {
    id: SituacaoMatricula[SituacaoMatricula.Recuperacao],
    texto: 'Recuperação'
  }
]

export const OPCOES_TIPO = [
  {
    id: TipoMatricula[TipoMatricula.Normal],
    texto: 'Normal'
  },
  {
    id: TipoMatricula[TipoMatricula.Ouvinte],
    texto: 'Ouvinte'
  },
  {
    id: TipoMatricula[TipoMatricula.Reingresso],
    texto: 'Reingresso'
  },
  {
    id: TipoMatricula[TipoMatricula.Transferencia],
    texto: 'Transferência'
  },
  {
    id: TipoMatricula[TipoMatricula.TrocaDeCurso],
    texto: 'Troca de Curso'
  },
  {
    id: TipoMatricula[TipoMatricula.ColaboradorIPGS],
    texto: 'Colaborador iPGS'
  },
  {
    id: TipoMatricula[TipoMatricula.Cancelado7Dias],
    texto: 'Cancelado 7 Dias'
  },
  {
    id: TipoMatricula[TipoMatricula.ErroMatricula],
    texto: 'Erro de Matrícula'
  }
]
