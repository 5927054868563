import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

import {
  Botao,
  CalendarioUnform,
  FormRow,
  FormUnform,
  ModalBase,
  NumeroInputUnform
} from 'src/componentes'
import { ContainerBotoes, SubtituloModal } from 'src/paginas/ipgs/turmas/styles'
import { v4 } from 'uuid'

import { EncontroPresencialTurma } from '../../../tipos'
import { schema } from './schema'
import { ModalCorpo } from './styles'
import { ModalIncluirEncontroProps, ModalIncluirEncontroRef } from './tipos'

const Componente: ForwardRefRenderFunction<
  ModalIncluirEncontroRef,
  ModalIncluirEncontroProps
> = ({ backdrop, acaoPrimaria, aberta }, ref) => {
  const [aberto, definirAberto] = useState(aberta)
  const [pronto, definirPronto] = useState(false)
  const [idDisciplina, definirIdDisciplina] = useState(null)

  const abrir = (idDisciplina: string) => {
    definirAberto(true)
    definirPronto(true)
    definirIdDisciplina(idDisciplina)
  }

  const fechar = () => {
    definirIdDisciplina(null)
    definirPronto(false)
    definirAberto(false)
  }

  const acaoSucesso = (dados: EncontroPresencialTurma) => {
    const novoEncontro = { ...dados, idDisciplina, id: v4() }

    acaoPrimaria(novoEncontro)
    fechar()
  }

  useImperativeHandle<ModalIncluirEncontroRef, ModalIncluirEncontroRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase aberta={aberto} backdrop={backdrop}>
      <ModalCorpo>
        <SubtituloModal>Incluir Encontro Presencial</SubtituloModal>
        {pronto && (
          <FormUnform acaoSucesso={acaoSucesso} schema={schema}>
            <FormRow>
              <CalendarioUnform
                maxWidthInput="180px"
                id="data_encontro"
                name="data"
                label="Data do Encontro"
                obrigatorio
              />
              <NumeroInputUnform
                maxWidthInput="180px"
                name="cargaHoraria"
                label="Carga Horária (em horas)"
                maxLength={9}
                isDecimal={true}
              />
            </FormRow>
            <ContainerBotoes>
              <Botao
                type="button"
                texto="Cancelar"
                tema="Secundario"
                onClick={() => {
                  fechar()
                }}
              />
              <Botao type="submit" id="btn-salvar" texto="Salvar" />
            </ContainerBotoes>
          </FormUnform>
        )}
      </ModalCorpo>
    </ModalBase>
  )
}

export const ModalIncluirEncontroPresencial = forwardRef(Componente)
