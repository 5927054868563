import styled from 'styled-components'

import { Cores, Medias } from '../styles'

export const Layout = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const Conteudo = styled.main`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  word-break: break-word;
  margin-left: 72px;
  padding: 24px;
  justify-content: center;

  @media ${Medias.MOBILE} {
    margin: 0;
    padding: 20px 10px;

    h1 {
      font-size: 2em !important;
    }
  }
`

export const Centralizado = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1000px;

  @media ${Medias.TABLET.MAX} {
    max-width: 100%;
  }
`

export const BarraLateral = styled.div`
  width: 72px;
  height: 100%;
  background-color: ${Cores.PRIMARIA_1};
  position: fixed;

  @media ${Medias.MOBILE} {
    display: none;
  }
`

export const BarraSuperior = styled.header`
  border-bottom: 1px solid ${Cores.CINZA_1_CLARO};
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 72px;
  margin-left: 72px;
  padding: 0px 155px 0px 83px;
  background-color: ${Cores.BRANCO};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${Medias.MOBILE} {
    margin-left: 0;
    padding: 0 20px;

    button {
      margin-top: 8px;
    }
  }
`
