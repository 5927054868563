import React, { useRef, useEffect } from 'react'

import { EstruturaCalendario } from '../../calendario/calendario'
import { useCalendario } from '../../calendario/calendario-hooks'
import { CalendarioProvider } from '../../calendario/calendario-provider'
import { CampoIcone, Container } from '../../calendario/styles'
import { dataValida, transformarPadraoAmericano } from '../../funcoes/data-hora'
import * as Icones from '../../icones'
import { DataInputUnform } from '../input/data'
import { DataInputUnformRef } from '../input/tipos'
import { CalendarioUnformProps } from './tipos'

export const ComponenteCalendarioUnform: React.FC<CalendarioUnformProps> = ({
  id,
  label,
  dataSugestao,
  readOnly,
  disabled,
  maxWidthInput,
  defaultValue,
  ...rest
}) => {
  const inputRef = useRef<DataInputUnformRef>(null)
  const [{ data, aberto }, calendarioDispatch] = useCalendario()

  const validaData = (dataAValidar: string) => {
    if (dataValida(dataAValidar)) {
      const novaData = transformarPadraoAmericano(dataAValidar)

      calendarioDispatch({
        tipo: 'mudar-data',
        dataEscolhida: novaData
      })
    }
  }

  function alterarDadosInput(evento) {
    let valor: string = evento.target.value.replace(/[_\\/]/gi, '')

    if (valor.length === 8) {
      valor =
        valor.slice(0, 2) + '/' + valor.slice(2, 4) + '/' + valor.slice(4, 8)
      validaData(valor)
    }
  }

  function load() {
    if (!readOnly && !disabled) {
      const input = document.getElementById(id)
      const elemento = document.getElementsByClassName(`icone-${id}`).item(0)
      elemento.addEventListener('click', () => {
        if (input && input.focus) input.focus()
      })
    }
  }

  useEffect(() => {
    load()

    if (dataSugestao) {
      calendarioDispatch({
        tipo: 'data-sugestao',
        dataSugestao
      })
    }
  }, []) //eslint-disable-line

  const exibir = () => {
    if (aberto || readOnly || disabled) return

    if (inputRef.current.obterValor() !== data) {
      calendarioDispatch({
        tipo: 'mudar-data',
        dataEscolhida: inputRef.current.obterValor()
      })
    }
    calendarioDispatch({ tipo: 'abrir-calendario', abrir: true })
  }

  return (
    <Container>
      <DataInputUnform
        {...rest}
        defaultValue={defaultValue}
        maxWidthInput={maxWidthInput}
        id={id}
        autoComplete="off"
        type="text"
        label={label}
        icone={
          <CampoIcone className={`icone-${id}`}>
            {Icones.IconeCalendarioGenerico}
          </CampoIcone>
        }
        ladoIcone="direita"
        placeholder="DD/MM/AAAA"
        onClick={() => exibir()}
        onClickIcone={() => exibir()}
        valor={data}
        ref={inputRef}
        disabled={disabled}
        readOnly={readOnly}
        onChange={alterarDadosInput}
      >
        <EstruturaCalendario id={id} altura={label !== undefined} />
      </DataInputUnform>
    </Container>
  )
}

export const CalendarioUnform: React.FC<CalendarioUnformProps> = props => (
  <CalendarioProvider>
    <ComponenteCalendarioUnform {...props} />
  </CalendarioProvider>
)
