import { uid } from 'react-uid'

import { Evento } from 'src/compartilhados/dominio'

export const agendaMock = new Array<Evento>(
  new Evento({
    id: uid(1),
    data: '20/05/2021',
    mensagem: 'Encontro presencial da turma XYZ às 20h'
  }),

  new Evento({
    id: uid(2),
    data: '21/05/2021',
    mensagem: 'Vídeo conferência de apresentação do trabalho'
  }),

  new Evento({
    id: uid(3),
    data: '22/05/2021',
    mensagem: 'Evento ConfSaúde no auditório da AMRIGS'
  }),

  new Evento({
    id: uid(4),
    data: '23/05/2021',
    mensagem:
      'Reapresentação dos Summits 2020 do iPGS estão disponíveis para o público'
  }),

  new Evento({
    id: uid(5),
    data: '24/05/2021',
    mensagem: 'O Janeiro Branco e a importância do debate sobre Saúde Mental'
  }),

  new Evento({
    id: uid(6),
    data: '25/05/2021',
    mensagem: 'Doação de materiais para a Escola Lauro Rodrigues'
  }),

  new Evento({
    id: uid(7),
    data: '26/05/2021',
    mensagem:
      'Próximo Summit organizado pela Faculdade iPGS será sobre Fitoterapia'
  }),

  new Evento({
    id: uid(8),
    data: '27/05/2021',
    mensagem: 'iPGS conclui doação para a ONG Médicos Sem Fronteiras'
  })
)
