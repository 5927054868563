import React, { forwardRef, useMemo } from 'react'

import assinatura from 'src/assets/imagens/recibo-assinatura.png'
import esferas from 'src/assets/imagens/recibo-esferas.png'
import logo from 'src/assets/imagens/recibo-ipgs-logo.png'
import { AutenticacaoContainer, formatarCpf } from 'src/componentes'
import { mesesDoAno } from 'src/componentes/funcoes/data-hora'
import {
  validaEhModalidadeLivreOuEvento,
  ModalidadeCurso,
  Nacionalidade,
  Recibo
} from 'src/tipos'

import { formatadores } from '../../formatadores'
import {
  Assinatura,
  Container,
  ContainerDeDescricao,
  Esferas,
  Linha,
  Logo,
  ParagrafoDaColuna,
  ParagrafoDaDataAtual,
  ParagrafoDaMoeda,
  ParagrafoPrincipal,
  PrimeiraColuna,
  SegundaColuna,
  TerceiraColuna,
  Titulo
} from './styles'

const dataAtual = new Date()

export enum ModelosDeRecibo {
  Matricula,
  Boleto,
  TrocaCurso
}

export const ReciboParaImpressao = forwardRef<
  HTMLDivElement,
  {
    recibo: Recibo
    nomeDoCurso: string
    modalidadeCurso: ModalidadeCurso
    isTaxa?: boolean
    modelo: ModelosDeRecibo
  }
>(
  (
    {
      recibo,
      nomeDoCurso,
      modalidadeCurso,
      isTaxa,
      modelo = ModelosDeRecibo.Matricula
    },
    ref
  ) => {
    const { usuario } = AutenticacaoContainer.useContainer()
    const ehCursoLivreOuEvento = useMemo(
      () => validaEhModalidadeLivreOuEvento(modalidadeCurso),
      [modalidadeCurso]
    )

    const mensagemTipoTaxa = useMemo(() => {
      if (modelo === ModelosDeRecibo.Matricula) {
        return 'taxa de matrícula'
      }

      if (modelo === ModelosDeRecibo.TrocaCurso) {
        return 'taxa de troca de curso'
      }

      return 'taxa'
    }, [modelo])

    return (
      <div ref={ref}>
        <Container>
          <Esferas src={esferas} />
          <Logo src={logo} />
          <ContainerDeDescricao>
            <Titulo>Recibo</Titulo>
            {recibo && (
              <>
                <ParagrafoPrincipal>
                  A empresa{' '}
                  <b>
                    IPGS – Consultoria em Pesquisa Ensino e Gestão em Saúde Ltda
                  </b>
                  , inscrita no CNPJ: 08.976.595/0001-27 com sua sede na Rua Av.
                  Cristóvão Colombo, 203 D – Floresta – Porto Alegre/RS, declara
                  para os devidos fins que{' '}
                  {usuario.nacionalidade === Nacionalidade[0] ? (
                    <b>
                      {recibo.nome}, CPF nº {formatarCpf(recibo.documento)}
                    </b>
                  ) : (
                    <b>
                      {recibo.nome}, documento de identidade nº{' '}
                      {recibo.documento}
                    </b>
                  )}
                  ,{' '}
                  {ehCursoLivreOuEvento ? (
                    <>
                      realizou o pagamento do(s) curso(s) {nomeDoCurso},{' '}
                      {recibo.quitadoComCupom ? (
                        <>curso isento por cupom de desconto</>
                      ) : (
                        <>
                          no valor de {formatadores.moeda(recibo.valorParcela)}
                        </>
                      )}
                    </>
                  ) : modalidadeCurso === ModalidadeCurso.Outra && !isTaxa ? (
                    <>
                      realizou o pagamento da parcela do curso de {nomeDoCurso},
                      do seguinte mês:
                    </>
                  ) : (
                    modalidadeCurso === ModalidadeCurso.Outra &&
                    isTaxa && (
                      <>
                        realizou o pagamento da {mensagemTipoTaxa} do curso{' '}
                        {nomeDoCurso},{' '}
                        {recibo.quitadoComCupom ? (
                          <>{mensagemTipoTaxa} isenta por cupom de desconto</>
                        ) : (
                          <>
                            no valor de{' '}
                            {formatadores.moeda(recibo.valorParcela)}
                          </>
                        )}
                      </>
                    )
                  )}
                </ParagrafoPrincipal>
                {modalidadeCurso === ModalidadeCurso.Outra && !isTaxa && (
                  <ParagrafoDaMoeda>
                    {recibo.mesParcela.toString().padStart(2, '0')}
                    {'/'}
                    {recibo.anoParcela}
                    {'.'.repeat(100)}
                    {formatadores.moeda(recibo.valorParcela)}
                  </ParagrafoDaMoeda>
                )}
                <ParagrafoDaDataAtual modelo={modelo}>
                  Porto Alegre, {dataAtual.getDate()} de{' '}
                  {mesesDoAno[dataAtual.getMonth()]} do ano de{' '}
                  {dataAtual.getFullYear()}
                </ParagrafoDaDataAtual>
                <Assinatura src={assinatura} modelo={modelo} />
                <Linha>
                  <PrimeiraColuna>
                    <ParagrafoDaColuna>
                      Av. Cristovão Colombo, 203d
                      <br />
                      Floresta
                    </ParagrafoDaColuna>
                  </PrimeiraColuna>
                  <SegundaColuna>
                    <ParagrafoDaColuna>
                      Porto Alegre | RS
                      <br />
                      90560-003
                    </ParagrafoDaColuna>
                  </SegundaColuna>
                  <TerceiraColuna>
                    <ParagrafoDaColuna>
                      51 3062.9322
                      <br />
                      ipgs@ipgs.com.br
                    </ParagrafoDaColuna>
                  </TerceiraColuna>
                </Linha>
              </>
            )}
          </ContainerDeDescricao>
        </Container>
      </div>
    )
  }
)
