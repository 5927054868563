export enum Sexo {
  NaoInformado = 'NaoInformado',
  Masculino = 'Masculino',
  Feminino = 'Feminino',
  Outros = 'Outros'
}

export enum SexoPorExtenso {
  NaoInformado = 'Não informado',
  Masculino = 'Masculino',
  Feminino = 'Feminino',
  Outros = 'Outros'
}
