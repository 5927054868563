import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'

import { FormHandles } from '@unform/core'
import { ModalBase, Botao, FormUnform, TextAreaUnform } from 'src/componentes'

import { schema } from './schema'
import { Botoes, ContainerInput, Corpo, Texto, Titulo } from './styles'
import {
  DadosAlteracaoDoc,
  ModalAlteracaoDocAprovadoProps,
  ModalAlteracaoDocAprovadoRef
} from './tipos'

const ModalAlteracaoDocAprovadoComponent: React.ForwardRefRenderFunction<
  ModalAlteracaoDocAprovadoRef,
  ModalAlteracaoDocAprovadoProps
> = ({ backdrop, acaoSucesso, acaoSecundaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [carregando, definirCarregando] = useState(false)

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<
    ModalAlteracaoDocAprovadoRef,
    ModalAlteracaoDocAprovadoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSalvar = async (dados: DadosAlteracaoDoc) => {
    try {
      definirCarregando(true)
      await acaoSucesso(dados.motivo)
      fechar()
    } finally {
      definirCarregando(false)
    }
  }

  const acaoCancelar = () => {
    acaoSecundaria()
    fechar()
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <Titulo>Alteração Documento</Titulo>
        <Texto>
          Informe o motivo de alteração do documento aprovado anteriormente.
        </Texto>
        <FormUnform
          ref={formRef}
          acaoSucesso={acaoSalvar}
          schema={schema}
          dadosIniciais={{}}
        >
          <ContainerInput>
            <TextAreaUnform
              name="motivo"
              id="motivo"
              rows={3}
              maxLength={2000}
            />
          </ContainerInput>
          <Botoes>
            <Botao
              texto="Cancelar"
              type="button"
              tema="Secundario"
              onClick={() => acaoCancelar()}
            />
            <Botao texto="Salvar" type="submit" carregando={carregando} />
          </Botoes>
        </FormUnform>
      </Corpo>
    </ModalBase>
  )
}

export const ModalAlteracaoDocAprovado = forwardRef(
  ModalAlteracaoDocAprovadoComponent
)
