import React, { forwardRef, useMemo } from 'react'

import QRCode from 'qrcode.react'
import imagemAssinatura from 'src/assets/imagens/imagem-assinatura.png'
import logoCertificado from 'src/assets/imagens/logo-certificado.png'
import parteLateralCertificado from 'src/assets/imagens/parte-lateral-certificado.png'
import { FuncoesDataHora } from 'src/componentes'
import { RotasApp } from 'src/rotas'
import { DadosCertificado } from 'src/servicos/api/sala-aula/tipos'

import SalaAulaContainer from '../container'
import {
  ContainerCertificado,
  ContainerCertificadoVerso,
  LogoLateralCertificado,
  ConteudoCertificado,
  RodapeCertificado,
  Assinatura,
  AssinaturaNome,
  AssinaturaCargo,
  ContainerQRCode,
  ConteudoCertificadoVerso,
  ConteudoCentralizadoCertificado,
  ConteudoCentralizadoCertificadoVerso,
  RodapeCertificadoVerso,
  ContainerLogoCertificadoVerso,
  TituloCertificado
} from './styles'

export const CertificadoTemplate = forwardRef<
  HTMLDivElement,
  { certificado: DadosCertificado }
>(({ certificado }, ref) => {
  const { idMatricula } = SalaAulaContainer.useContainer()

  const urlQRCode = useMemo(
    () =>
      `${process.env.REACT_APP_URL}${RotasApp.ValidacaoCertificado}/${idMatricula}`,
    [idMatricula]
  )

  return (
    <div ref={ref}>
      <ContainerCertificado>
        <LogoLateralCertificado src={parteLateralCertificado} />
        <ConteudoCertificado>
          <ConteudoCentralizadoCertificado>
            <img src={logoCertificado} />
            <TituloCertificado>Certificado</TituloCertificado>
            <p>A Faculdade iPGS, no uso de suas atribuições certifica que</p>
            <p className="nome-usuario">
              <strong>{certificado.nome}</strong>
            </p>
            <p>
              realizou o Curso Livre {certificado.nomeCurso}, com carga horária
              de {certificado.cargaHoraria} horas.
            </p>
            <p>
              Período: {FuncoesDataHora.novaData(certificado.dataInscricao)} à{' '}
              {FuncoesDataHora.novaData(certificado.dataAprovacao)}
            </p>
            <p className="localidade-data">
              Porto Alegre,{' '}
              {FuncoesDataHora.dataFormatadaMesEscrito(new Date())}
            </p>
          </ConteudoCentralizadoCertificado>
          <RodapeCertificado>
            <Assinatura>
              <img src={imagemAssinatura} />
              <p className="linha-absolut">
                __________________________________
              </p>
              <AssinaturaNome>Dra. Aline P. Schneider</AssinaturaNome>
              <AssinaturaCargo>
                Diretora Geral da Faculdade iPGS
              </AssinaturaCargo>
            </Assinatura>
            <ContainerQRCode>
              <QRCode value={urlQRCode} includeMargin />
            </ContainerQRCode>
          </RodapeCertificado>
        </ConteudoCertificado>
      </ContainerCertificado>
      <ContainerCertificadoVerso>
        <LogoLateralCertificado src={parteLateralCertificado} />
        <ConteudoCertificadoVerso>
          <ConteudoCentralizadoCertificadoVerso>
            <h1>ASSUNTOS ABORDADOS NESTE CURSO:</h1>
            <h2>{certificado.nomeCurso}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: certificado.versoCertificado }}
            />
          </ConteudoCentralizadoCertificadoVerso>
          <RodapeCertificadoVerso>
            <QRCode value={urlQRCode} includeMargin />
            <ContainerLogoCertificadoVerso>
              <img src={logoCertificado} />
            </ContainerLogoCertificadoVerso>
          </RodapeCertificadoVerso>
        </ConteudoCertificadoVerso>
      </ContainerCertificadoVerso>
    </div>
  )
})
