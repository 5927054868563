import React, { useMemo } from 'react'

import classNames from 'classnames'

import { IconeCerto, IconeErrado } from '../icones'
import { ItemValidacao } from './styles'

export const Validacao: React.FC<{ valor: string; regex: RegExp }> = ({
  valor,
  regex,
  children
}) => {
  const EhValido = () => useMemo(() => regex.test(valor), [valor, regex])

  return (
    <ItemValidacao
      className={classNames({
        certo: EhValido()
      })}
    >
      {EhValido() ? <i>{IconeCerto}</i> : <i>{IconeErrado}</i>}
      <p>{children}</p>
    </ItemValidacao>
  )
}
