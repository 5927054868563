import { saveAs } from 'file-saver'
import queryString from 'query-string'
import {
  RetornoAPIPesquisaRelatorio,
  RetornoAPIPesquisaRelatorioFinalizacao,
  RetornoAPIPesquisaRelatorioFinanceiroGeral,
  RetornoAPIPesquisaRelatorioMatriculadosTurma,
  RetornoAPIPesquisaRelatorioProrrogacao,
  RetornoAPIPesquisaRelatorioRecorrencia
} from 'src/dto'
import {
  RelatorioFinanceiroFiltros,
  RelatorioFinanceiroResponse,
  OpcoesPaginacao,
  TipoOrdenacao,
  RelatorioMatriculadosTurmaFiltros,
  RelatorioMatriculadosTurmaResponse,
  RelatorioMatriculadosCursosLivresFiltros,
  RelatorioComercialFiltros,
  RelatorioMatriculadosCursosLivresResponse,
  RelatorioCuponsDescontoFiltros,
  RelatorioCuponsDescontoResponse,
  RelatorioDocumentosAlunoFiltros,
  RelatorioDocumentosAlunoResponse,
  RelatorioProrrogacaoFiltros,
  RelatorioProrrogacaoResponse,
  RelatorioHistoricoEscolarFiltros,
  RelatorioHistoricoEscolarResponse,
  RelatorioFinalizacaoFiltros,
  RelatorioFinalizacaoResponse,
  RelatorioEnvioNotasTCCFiltros,
  RelatorioEnvioNotasTCCResponse,
  RelatorioRecorrenciaFiltros,
  RelatorioRecorrenciaResponse,
  RelatorioFinanceiroGeralFiltros,
  RelatorioNotasFiltros,
  RelatorioNotasResponse,
  RelatorioPesquisaSatisfacaoFiltros,
  RelatorioPesquisaSatisfacaoResponse
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'

const urlBase = '/relatorio'

const removerCamposVazios = (objeto: any) =>
  Object.entries(objeto).reduce(
    (a, [k, v]) =>
      v == null || v === '' || v === undefined ? a : ((a[k] = v), a),
    {}
  )

export const ObterRelatorioFinanceiro = async (
  relatorioFinanceiroFiltros: RelatorioFinanceiroFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioFinanceiroResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioFinanceiroFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const { data } = await gatewayApi.get<RetornoAPIPesquisaRelatorio>(
      `${urlBase}/financeiro?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioFinanceiro = async (
  relatorioFinanceiroFiltros: RelatorioFinanceiroFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioFinanceiroFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/financeiro/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Financeiro.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioMatriculadosTurma = async (
  relatorioMatriculadosTurmaFiltros: RelatorioMatriculadosTurmaFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioMatriculadosTurmaResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioMatriculadosTurmaFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const {
      data
    } = await gatewayApi.get<RetornoAPIPesquisaRelatorioMatriculadosTurma>(
      `${urlBase}/turma?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioMatriculadosTurma = async (
  relatorioMatriculadosTurmaFiltros: RelatorioMatriculadosTurmaFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioMatriculadosTurmaFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/turma/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Matriculados-Turma.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioMatriculadosCursosLivres = async (
  relatorioMatriculadosCursosLivresFiltros: RelatorioMatriculadosCursosLivresFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioMatriculadosCursosLivresResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioMatriculadosCursosLivresFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const { data } = await gatewayApi.get<RetornoAPIPesquisaRelatorio>(
      `${urlBase}/matriculados-curso-livre?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioMatriculadosCursosLivres = async (
  relatorioMatriculadosCursosLivresFiltros: RelatorioMatriculadosCursosLivresFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioMatriculadosCursosLivresFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/matriculados-curso-livre/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Matriculados-Livre.xlsx')
  } catch {
    return null
  }
}
export const ObterDowloandRelatorioComercial = async (
  RelatorioComercialFiltros: RelatorioComercialFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(RelatorioComercialFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/comercial/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Comercial.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioCuponsDesconto = async (
  relatorioCuponsDescontoFiltros: RelatorioCuponsDescontoFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioCuponsDescontoResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioCuponsDescontoFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const { data } = await gatewayApi.get<RetornoAPIPesquisaRelatorio>(
      `${urlBase}/cupons-desconto?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioCuponsDesconto = async (
  relatorioCuponsDescontoFiltros: RelatorioCuponsDescontoFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioCuponsDescontoFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/cupons-desconto/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Cupons-Desconto.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioDocumentosAluno = async (
  relatorioDocumentosAlunoFiltros: RelatorioDocumentosAlunoFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioDocumentosAlunoResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioDocumentosAlunoFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const { data } = await gatewayApi.get<RetornoAPIPesquisaRelatorio>(
      `${urlBase}/documentos-aluno?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioDocumentosAluno = async (
  relatorioDocumentosAlunoFiltros: RelatorioDocumentosAlunoFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioDocumentosAlunoFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/documentos-aluno/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Documentos-Aluno.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioProrrogacao = async (
  relatorioProrrogacaoFiltros: RelatorioProrrogacaoFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioProrrogacaoResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioProrrogacaoFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const {
      data
    } = await gatewayApi.get<RetornoAPIPesquisaRelatorioProrrogacao>(
      `${urlBase}/prorrogacao?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioProrrogacao = async (
  relatorioProrrogacaoFiltros: RelatorioProrrogacaoFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioProrrogacaoFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/prorrogacao/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Prorrogacao.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioHistoricoEscolar = async (
  relatorioHistoricoEscolarFiltros: RelatorioHistoricoEscolarFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioHistoricoEscolarResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioHistoricoEscolarFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const {
      data
    } = await gatewayApi.get<RetornoAPIPesquisaRelatorioProrrogacao>(
      `${urlBase}/historico-escolar-aluno?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioHistoricoEscolar = async (
  relatorioHistoricoEscolarFiltros: RelatorioHistoricoEscolarFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioHistoricoEscolarFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/historico-escolar-aluno/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Historico-Escolar.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioFinalizacao = async (
  relatorioFinalizacaoFiltros: RelatorioFinalizacaoFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioFinalizacaoResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioFinalizacaoFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const {
      data
    } = await gatewayApi.get<RetornoAPIPesquisaRelatorioFinalizacao>(
      `${urlBase}/finalizacao?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioFinalizacao = async (
  relatorioFinalizacaoFiltros: RelatorioFinalizacaoFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioFinalizacaoFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/finalizacao/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Finalizacao.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioEnvioNotasTCC = async (
  relatorioFinalizacaoFiltros: RelatorioEnvioNotasTCCFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioEnvioNotasTCCResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioFinalizacaoFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const {
      data
    } = await gatewayApi.get<RetornoAPIPesquisaRelatorioFinalizacao>(
      `${urlBase}/envios-nota-tcc?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatoriosEnvioNotasTCC = async (
  relatorioFinalizacaoFiltros: RelatorioEnvioNotasTCCFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioFinalizacaoFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/envios-nota-tcc/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Envio-Notas-TCC.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioRecorrencia = async (
  relatorioRecorrenciaFiltros: RelatorioRecorrenciaFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioRecorrenciaResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioRecorrenciaFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const {
      data
    } = await gatewayApi.get<RetornoAPIPesquisaRelatorioRecorrencia>(
      `${urlBase}/recorrencia?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioRecorrencia = async (
  relatorioRecorrenciaFiltros: RelatorioRecorrenciaFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioRecorrenciaFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/recorrencia/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-recorrencia.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioFinanceiroGeral = async (
  relatorioFiltros: RelatorioFinanceiroGeralFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioRecorrenciaResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const {
      data
    } = await gatewayApi.get<RetornoAPIPesquisaRelatorioFinanceiroGeral>(
      `${urlBase}/financeiro-geral?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioFinanceiroGeral = async (
  relatorioRecorrenciaFiltros: RelatorioFinanceiroGeralFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioRecorrenciaFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/financeiro-geral/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-financeiro-geral.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioNotas = async (
  relatorioFinalizacaoFiltros: RelatorioNotasFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioNotasResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioFinalizacaoFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const { data } = await gatewayApi.get(`${urlBase}/notas?${filtros}`)

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioNotas = async (
  relatorioFinalizacaoFiltros: RelatorioNotasFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(relatorioFinalizacaoFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/notas/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-Notas.xlsx')
  } catch {
    return null
  }
}

export const ObterRelatorioPesquisaSatisfacao = async (
  relatorioFiltros: RelatorioPesquisaSatisfacaoFiltros,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: RelatorioPesquisaSatisfacaoResponse[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const objetoFiltros = {
      ...relatorioFiltros,
      qtdRegistros,
      pagina,
      ordem
    }

    const objetoFiltrosLimpo = removerCamposVazios(objetoFiltros)
    const filtros = queryString.stringify(objetoFiltrosLimpo)

    const { data } = await gatewayApi.get(
      `${urlBase}/pesquisa-satisfacao-portal?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: data.data.quantidade,
      totalPaginas: Math.ceil(data.data.quantidade / qtdRegistros)
    }

    return { registros: data.data.itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const ObterDownloadRelatorioPesquisaSatisfacao = async (
  RelatorioPesquisaSatisfacaoFiltros: RelatorioPesquisaSatisfacaoFiltros
): Promise<boolean | void> => {
  try {
    const filtros = queryString.stringify({
      ...removerCamposVazios(RelatorioPesquisaSatisfacaoFiltros)
    })

    const resposta = await gatewayApi.get(
      `${urlBase}/pesquisa-satisfacao-portal/download?${filtros}`,
      { responseType: 'blob' }
    )

    if (resposta.status === 204) return false
    if (resposta.status !== 200) return null
    saveAs(resposta.data, 'Relatorio-pesquisa-satisfacao.xlsx')
  } catch {
    return null
  }
}
