import { colunaCpf } from './cpf'
import { colunaDataColacaoGrau } from './data-colacao-grau'
import { colunaDataConclusao } from './data-conclusao'
import { colunaDataInicio } from './data-inicio'
import { colunaDataMatricula } from './data-matricula'
import { colunaDataOpcaoTCC } from './data-opcao-tcc'
import { colunaDisciplinasAproveitadas } from './disciplinas-aproveitadas'
import { colunaDocumentosAnalise } from './documentos-analise'
import { colunaDocumentosAprovados } from './documentos-aprovados'
import { colunaDocumentosPendentes } from './documentos-pendentes'
import { colunaDocumentosRecusados } from './documentos-recusados'
import { colunaEmailAluno } from './email-aluno'
import { colunaFimPeriodoInscricaoTCC } from './fim-periodo-inscricao-tcc'
import { colunaInicioOrientacaoTCC } from './inicio-orientacao-tcc'
import { colunaInicioPeriodoInscricaoTCC } from './inicio-periodo-inscricao-tcc'
import { colunaModalidade } from './modalidade'
import { colunaNacionalidade } from './nacionalidade'
import { colunaNegativadoFinanceiro } from './negativado-financeiro'
import { colunaNomeAluno } from './nome-aluno'
import { colunaNotas } from './notas'
import { colunaOpcaoTCC } from './opcao-tcc'
import { colunaProrrogacaoMatricula } from './prorrogacao-matricula'
import { colunaSituacaoFinanceira } from './situacao-financeira'
import { colunaTipoAluno } from './tipo-aluno'
import { colunaTipoMatricula } from './tipo-matricula'
import { colunaTurma } from './turma'

export const COLUNAS_FINALIZACAO = [
  colunaNomeAluno(),
  colunaEmailAluno(),
  colunaCpf(),
  colunaTurma(),
  colunaModalidade(),
  colunaSituacaoFinanceira(),
  colunaTipoMatricula(),
  colunaDataColacaoGrau(),
  colunaTipoAluno(),
  colunaDataMatricula(),
  colunaDataInicio(),
  colunaDataConclusao(),
  colunaNacionalidade(),
  colunaDocumentosAnalise(),
  colunaDocumentosAprovados(),
  colunaDocumentosRecusados(),
  colunaDocumentosPendentes(),
  colunaNotas(),
  colunaDisciplinasAproveitadas(),
  colunaProrrogacaoMatricula(),
  colunaInicioPeriodoInscricaoTCC(),
  colunaFimPeriodoInscricaoTCC(),
  colunaOpcaoTCC(),
  colunaDataOpcaoTCC(),
  colunaInicioOrientacaoTCC(),
  colunaNegativadoFinanceiro()
]
