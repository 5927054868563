import React, { useImperativeHandle, useRef, useState, forwardRef } from 'react'
import Highlighter from 'react-highlight-words'
import Select from 'react-select/async'

import debounce from 'debounce-promise'
import deburr from 'lodash/deburr'
import PropTypes from 'prop-types'

import { IconeCarregando, IconeFechar } from '../icones'
import { Componente, Label, Grupo } from './styles'
import { AsyncSelectProps, OpcaoSelect, AsyncSelectRef } from './tipos'

export const SelectAsync: React.ForwardRefExoticComponent<AsyncSelectProps> = forwardRef<
  AsyncSelectRef,
  AsyncSelectProps
>(
  (
    {
      id,
      defineValor,
      label,
      placeholder,
      buscarPorTexto,
      multiplo,
      disabled,
      tamanho,
      maxWidthInput,
      posicao,
      maxWidthMenu,
      ...rest
    },
    ref: any
  ) => {
    const cancelado = React.useRef(false)
    const selectRef = useRef(null)
    const [carregando, definirCarregando] = useState<boolean>(false)
    const [temValor, definirTemValor] = useState<boolean>(false)

    const limparCampoSelect = () => selectRef.current.select.select.clearValue()

    useImperativeHandle(ref, () => ({ limparCampo: () => limparCampoSelect() }))

    const carregarOpcoes = (texto: string) => {
      definirCarregando(true)

      return new Promise(resolve => {
        buscarPorTexto(texto)
          .then(resolve)
          .catch(() => resolve([]))
          .finally(() => {
            if (!cancelado.current) definirCarregando(false)
          })
      })
    }

    const carregarOpcoesDebounce = debounce(carregarOpcoes, 500, {
      leading: true
    })

    const renderizarIconeFechar = () => (
      <span className="icone_fechar" onClick={limparCampoSelect}>
        {IconeFechar}
      </span>
    )

    return (
      <Componente
        disabled={disabled}
        tamanho={tamanho}
        posicao={posicao}
        maxWidthMenu={maxWidthMenu}
        {...rest}
      >
        {label && <Label htmlFor={id}>{label}</Label>}
        <Grupo data-testid={id} maxWidthInput={maxWidthInput}>
          <Select
            cacheOptions
            defaultOptions
            id={id}
            ref={selectRef}
            classNamePrefix="react-select"
            isClearable={!disabled}
            noOptionsMessage={() => 'Nenhuma opção para pesquisa'}
            loadingMessage={() => 'Carregando ...'}
            backspaceRemovesValue={true}
            menuPlacement="auto"
            loadOptions={carregarOpcoesDebounce}
            onChange={valor => {
              definirTemValor(!!valor)
              if (defineValor) defineValor(valor)
            }}
            isMulti={multiplo}
            placeholder={placeholder || 'Selecione ...'}
            getOptionLabel={(opcao: OpcaoSelect) => opcao.texto}
            formatOptionLabel={({ texto }, { inputValue }) => (
              <Highlighter
                searchWords={[inputValue]}
                textToHighlight={texto}
                sanitize={deburr}
              />
            )}
            getOptionValue={(opcao: OpcaoSelect) => opcao.id}
            isSearchable={true}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9 })
            }}
          />
          {carregando && (
            <span className="icone_carregando">{IconeCarregando}</span>
          )}
          {temValor && !carregando && renderizarIconeFechar()}
        </Grupo>
      </Componente>
    )
  }
)

SelectAsync.defaultProps = {
  label: undefined,
  defineValor: null,
  placeholder: 'Selecione ...',
  multiplo: false,
  'data-testid': undefined,
  className: undefined,
  maxWidthInput: null,
  tamanho: null,
  disabled: false
}

SelectAsync.propTypes = {
  id: PropTypes.string.isRequired,
  defineValor: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
  buscarPorTexto: PropTypes.func.isRequired,
  multiplo: PropTypes.bool,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  maxWidthInput: PropTypes.string,
  tamanho: PropTypes.oneOf(['S', 'M', 'L']),
  disabled: PropTypes.bool,
  posicao: PropTypes.oneOf(['direita', 'esquerda']),
  maxWidthMenu: PropTypes.string
}
