import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  position: sticky;
  top: 40px;
  right: 0;
  z-index: 0;
  border-radius: 4px;
  padding: 20px 0 24px 22px;
  width: 230px;
  background-color: ${Cores.CINZA_3_MAIS_CLARO};
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);

  h4 {
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }

  a {
    margin-bottom: 5px;
    border: none;
    line-height: 1.5;
    background: none;
    font-weight: 300;
    color: ${Cores.CINZA_2};
    cursor: pointer;
  }
`
