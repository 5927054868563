import React, { useState, useMemo, useEffect } from 'react'

import {
  CalendarioUnform,
  InputUnform,
  NumeroInputUnform,
  Spacer,
  SubTitulo,
  TextAreaUnform,
  RadioInputUnform,
  CheckboxUnform
} from 'src/componentes'
import { ListaPerguntas } from 'src/componentes/perguntas'
import { TipoDeAvaliacao } from 'src/dto'

import PaginaCadastroTurmaContainer from '../../../container'
import { AvaliacaoArquivoInput } from '../avaliacao-arquivo-input'
import { Linha } from './styles'
import { AvaliacaoProps } from './tipos'

export const Avaliacao: React.FC<AvaliacaoProps> = ({
  indice,
  idReferencia,
  tipo,
  contemAvaliacao,
  possuiAvaliacao,
  ehModuloTCC
}) => {
  const {
    modalIncluirPerguntaRef,
    perguntas,
    bloquearCampo,
    removerPergunta
  } = PaginaCadastroTurmaContainer.useContainer()
  const [temAvaliacao, definirTemAvaliacao] = useState(possuiAvaliacao ?? false)
  const [adicionarAvaliacao, definirAdicionarAvaliacao] = useState(
    contemAvaliacao ?? false
  )
  const [tipoAvaliacao, definirTipoAvaliacao] = useState<TipoDeAvaliacao>(
    tipo ?? null
  )

  const perguntasAtuais = useMemo(() => {
    return perguntas.filter(x => x.idReferencia === idReferencia)
  }, [perguntas, idReferencia])

  useEffect(() => {
    if (ehModuloTCC) {
      definirTipoAvaliacao(TipoDeAvaliacao.AvaliacaoPorAquivo)
      definirTemAvaliacao(true)
    }
  }, [])

  return (
    <>
      <Linha>
        <SubTitulo texto="Avaliação" />
        <InputUnform name="id" esconder />
      </Linha>
      <Linha>
        <CheckboxUnform
          id={`ipt_tem_avalicao_${indice}`}
          name="temAvaliacao"
          texto="Tem avaliação?"
          onChange={() => definirTemAvaliacao(old => !old)}
          checked={temAvaliacao}
          disabled={ehModuloTCC}
        />
        {temAvaliacao && !ehModuloTCC && (
          <CheckboxUnform
            id={`ipt_adicionar_avalicao_${indice}`}
            name="adicionarAvaliacao"
            texto="Adicionar avaliação"
            onChange={() => definirAdicionarAvaliacao(old => !old)}
            checked={adicionarAvaliacao}
          />
        )}
      </Linha>
      {temAvaliacao && (ehModuloTCC || adicionarAvaliacao) && (
        <>
          <Spacer padding="0px 0px 20px" />
          <Linha>
            <InputUnform
              className="md"
              type="text"
              name="nome"
              label="Nome da Avaliação"
              obrigatorio
              disabled={bloquearCampo}
            />
            <NumeroInputUnform
              className="md"
              name="quantidadeTentativas"
              label="Quantidade de Tentativas"
              obrigatorio
              disabled={bloquearCampo}
            />
          </Linha>
          <TextAreaUnform
            className="lg"
            name="descricao"
            label="Descrição da Avaliação"
            maxLength={4000}
          />
          <Linha>
            <CalendarioUnform
              label="Data de Liberação da Avaliação"
              className="sm"
              name="dataLiberacaoAvaliacao"
              obrigatorio
              id={`inp_data_liberacao_avaliacao_${indice}`}
              disabled={bloquearCampo}
            />
            <Spacer className="sm" padding="0px 40px" />
            <CalendarioUnform
              label="Data Final para Realização da Avaliação"
              className="sm"
              name="dataRealizacaoAvaliacao"
              id={`inp_data_realizacao_avaliacao_${indice}`}
              disabled={bloquearCampo}
            />
          </Linha>
          {!ehModuloTCC ? (
            <Linha className="sm">
              <RadioInputUnform
                opcoesHorizontal
                id={`${indice}-radiobutton`}
                name="tipo"
                disabled={bloquearCampo}
                valorAlterado={valor =>
                  definirTipoAvaliacao(valor as TipoDeAvaliacao)
                }
                opcoes={[
                  {
                    id: `${idReferencia}-${TipoDeAvaliacao.AvaliacaoPorAquivo}`,
                    texto: 'Desejo realizar upload da avaliação',
                    valor: TipoDeAvaliacao.AvaliacaoPorAquivo
                  },
                  {
                    id: `${idReferencia}-${TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha}`,
                    texto: 'Desejo cadastrar as perguntas manualmente',
                    valor: TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha
                  }
                ]}
              />
            </Linha>
          ) : null}

          {tipoAvaliacao === TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha && (
            <ListaPerguntas
              items={perguntasAtuais}
              adicionar={item =>
                modalIncluirPerguntaRef.current?.abrir(item, idReferencia)
              }
              remover={item => removerPergunta(item.id)}
              disabled={bloquearCampo}
            />
          )}
          {tipoAvaliacao === TipoDeAvaliacao.AvaliacaoPorAquivo && (
            <AvaliacaoArquivoInput name="arquivo" disabled={bloquearCampo} />
          )}
        </>
      )}
    </>
  )
}
