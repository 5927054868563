import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'
import { UsuarioPerfil } from 'src/tipos'

import { AutenticacaoContainer, Visao } from '../autenticacao'
import { SubItem } from './styles'
import { MenuSubItem as MenuSubItemProps } from './tipos'

export const MenuSubItem: React.FC<{
  item: MenuSubItemProps
}> = ({ item }) => {
  const {
    perfil: { perfis },
    visao
  } = AutenticacaoContainer.useContainer()
  const history = useHistory()

  const podeVisualizar = useCallback(
    (
      visaoPermitida?: Visao,
      perfisComAcesso?: Array<keyof typeof UsuarioPerfil>
    ) => {
      return (
        (visaoPermitida === undefined || visaoPermitida === visao) &&
        (perfisComAcesso === undefined ||
          perfisComAcesso.some(p => perfis.includes(p)))
      )
    },
    [visao, perfis]
  )

  const itemAtivo = (path: string) =>
    item.Caminho && path.startsWith(item.Caminho)

  const [ativo, definirAtivo] = useState(itemAtivo(history.location.pathname))

  useEffect(() => {
    return history.listen(location => {
      definirAtivo(itemAtivo(location.pathname))
    })
  }, [history])

  return (
    podeVisualizar(item.Visao, item.PerfisComAcesso) && (
      <SubItem>
        <button
          className={classNames({
            clicavel: !!item.Caminho,
            ativo
          })}
          type="button"
          onClick={() => item.Caminho && history.push(item.Caminho)}
        >
          {item.Titulo}
        </button>
      </SubItem>
    )
  )
}
