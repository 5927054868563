import style from 'styled-components'

import { Cores } from '../styles'

export const Container = style.li<{ naoLido: boolean }>`
    list-style: none;
    padding: 16px 15px 15px 12px;

    h4 {
        float: left;
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 300;
        color: ${Cores.PRETO};
    }

    time {
        float: right;
        font-size: 11px;
        font-weight: 300;
        color: ${Cores.CINZA_2_CLARO};
    }

    p {
        clear: both;
        margin: 0;
        text-align: left;
        font-size: 11px;
        font-weight: 300;
        color: ${Cores.CINZA_2_CLARO};
    }

    ${({ naoLido }) =>
      naoLido
        ? `
        border-left: 2px solid ${Cores.SECUNDARIA_2};
        background: none;

        h4, p {
            font-weight: 500;
        }
    `
        : `
        background-color: ${Cores.CINZA_3_MAIS_CLARO};
    `}
`
