import styled from 'styled-components'

export const Titulo = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 36px;
`

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 40px 46px;

  > form {
    width: 100%;
    display: flex;
    flex-direction: column;

    & label {
      font-size: 14px;
    }
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;

  & > button:nth-of-type(1) {
    margin-right: 16px;
  }
`
export const ContainerDoConteudo = styled.div`
  display: flex;
  flex-direction: column;
`
