import {
  mensagemPadrao,
  SELECT_NACIONALIDADES,
  SELECT_PAISES
} from 'src/dados-estaticos'
import * as Yup from 'yup'

export const schemaPF = Yup.object().shape({
  cpf: Yup.string()
    .cpf()
    .nullable()
    .when('nacionalidade', {
      is: SELECT_NACIONALIDADES[0].id,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  rg: Yup.string().required(mensagemPadrao),
  nome: Yup.string().required(mensagemPadrao),
  dataNascimento: Yup.string().data().required(mensagemPadrao),
  escolaridade: Yup.string().required(mensagemPadrao),
  celular: Yup.string().telefone().required(mensagemPadrao),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required(mensagemPadrao),
  raca: Yup.string().required(mensagemPadrao),
  nomeSocial: Yup.string()
    .nullable()
    .when('possuiNomeSocial', {
      is: true,
      then: field => field.required(mensagemPadrao)
    }),
  cep: Yup.string()
    .nullable()
    .when('paisResidencia', {
      is: SELECT_PAISES[0].id,
      then: field =>
        field
          .min(8, 'Este campo deve possuir 8 caracteres')
          .required(mensagemPadrao)
    }),
  logradouro: Yup.string().required(mensagemPadrao),
  numero: Yup.string().required(mensagemPadrao),
  bairro: Yup.string().required(mensagemPadrao),
  cidade: Yup.string().required(mensagemPadrao),
  uf: Yup.string().required(mensagemPadrao)
})

export const schemaPJ = Yup.object().shape({
  cnpj: Yup.string().cpfCnpj().required(mensagemPadrao),
  inscricaoEstadual: Yup.string().required(mensagemPadrao),
  inscricaoMunicipal: Yup.string().nullable(),
  nome: Yup.string().required(mensagemPadrao),
  celular: Yup.string().telefone().required(mensagemPadrao),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required(mensagemPadrao),
  cep: Yup.string()
    .nullable()
    .when('paisResidencia', {
      is: SELECT_PAISES[0].id,
      then: field =>
        field
          .min(8, 'Este campo deve possuir 8 caracteres')
          .required(mensagemPadrao)
    }),
  logradouro: Yup.string().required(mensagemPadrao),
  numero: Yup.string().required(mensagemPadrao),
  bairro: Yup.string().required(mensagemPadrao),
  cidade: Yup.string().required(mensagemPadrao),
  uf: Yup.string().required(mensagemPadrao)
})
