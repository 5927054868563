import { useContext } from 'react'

import { CalendarioContext, CalendarioAcoesContext } from './calendario-context'
import { CalendarioEstado, CalendarioDispatch } from './tipos'

export const useCalendarioState = (): CalendarioEstado => {
  const context = useContext(CalendarioContext)
  if (context === undefined) {
    throw new Error(
      'useCalendarioState deve ser usado dentro de um CalendarioContextProvider'
    )
  }
  return context
}

export const useCalendarioDispatch = (): CalendarioDispatch => {
  const context = useContext(CalendarioAcoesContext)
  if (context === undefined) {
    throw new Error(
      'useCalendarioDispatch deve ser usado dentro de um CalendarioContextProvider'
    )
  }
  return context
}

export const useCalendario = (): [CalendarioEstado, CalendarioDispatch] => [
  useCalendarioState(),
  useCalendarioDispatch()
]
