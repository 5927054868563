import { Entidade } from './entidade'

export class Evento extends Entidade<string> {
  public titulo?: string
  public data: string
  public mensagem: string

  constructor(valorInicial: Partial<Evento> = {}) {
    super()
    Object.assign(this, valorInicial)
  }
}
