import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useEffect
} from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  SelectUnform,
  SelectOpcao
} from 'src/componentes'
import { Api } from 'src/servicos'

import { schemaNovaMatricula } from './schema'
import { ContainerAcoes, ContainerModal, CorpoModal } from './style'
import {
  ModalNovaMatriculaRef,
  ModalNovaMatriculaProps,
  NovaMatriculaAluno
} from './tipos'
const Modal: React.ForwardRefRenderFunction<
  ModalNovaMatriculaRef,
  ModalNovaMatriculaProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [alunoId, definirAlunoId] = useState<string>(null)
  const [opcoesTurmas, definirOpcoesTurmas] = useState<SelectOpcao[]>()

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = (alunoId: string) => {
    definirAlunoId(alunoId)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalNovaMatriculaRef, ModalNovaMatriculaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoForm = async (dados: NovaMatriculaAluno) => {
    try {
      definirCarregando(true)
      const dadosNovaMatricula = {
        ...dados,
        alunoId
      }

      await acaoPrimaria(dadosNovaMatricula)
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  const requisitarTurmasDisponiveisAluno = async () => {
    try {
      const dados = await Api.ObterTurmasNovaMatricula(alunoId)
      definirOpcoesTurmas(dados)
    } catch {
      toast('Erro ao carregar turmas!', { type: 'error' })
    }
  }

  useEffect(() => {
    if (alunoId) {
      requisitarTurmasDisponiveisAluno()
    }
  }, [alunoId])
  return (
    <ModalBase id="modal-nova-matricula" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Nova Matrícula</h5>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaNovaMatricula}
              ref={formRef}
            >
              <SelectUnform
                id="slct_turma_destino"
                name="turmaId"
                label="Turma de destino"
                opcoes={opcoesTurmas}
                obrigatorio
              />
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Adicionar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}
export const ModalNovaMatricula = forwardRef(Modal)
