import { Medias } from 'src/compartilhados/modulos/responsivo'
import styled from 'styled-components'

export const Corpo = styled.div`
  width: 471px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 60px 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Titulo = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
`

export const Texto = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 20px;
`

export const TextoMarginTop = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 20px;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`
export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${Medias.Mobile} {
    & {
      width: 50%;
    }
  }

  button {
    margin-right: 5px;
  }
`
