import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'
import { Obrigatorio } from 'src/componentes/input/styles'

import { Editor } from '../../editor'
import { EditorRef } from '../../editor/tipos'
import { Componente, Label, Erro } from './styles'
import { EditorProps } from './tipos'

export const EditorUnform: React.FC<EditorProps> = ({
  id,
  label,
  name,
  obrigatorio,
  placeholder,
  ...rest
}) => {
  const editorRef = useRef<EditorRef>(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: editorRef.current,
      getValue: () => {
        return editorRef.current.valorHTML()
      },
      setValue: (_: any, value: any) => {
        editorRef.current.definirValor(value)
      },
      clearValue: () => {
        editorRef.current.limpar()
      }
    })
  }, [fieldName, registerField])

  return (
    <Componente erro={error} id={`${id}-container`}>
      {label && (
        <Label htmlFor={id}>
          {obrigatorio ? <Obrigatorio>*</Obrigatorio> : <></>}
          {label}
        </Label>
      )}
      <Editor
        defaultValue={defaultValue}
        ref={editorRef}
        id={id}
        erro={!!error}
        onChange={clearError}
        placeholder={placeholder}
        {...rest}
      />
      <Erro id={`erro-${id}`} className="erro">
        {error}
      </Erro>
    </Componente>
  )
}
