import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import { NotificarUsuario } from 'src/servicos/api'

import {
  Breadcrumb,
  Cabecalho,
  IconeBack,
  Link,
  Navegacao,
  Row
} from '../../../../componentes'
import {
  CorRaca,
  Escolaridade,
  EstadoCivil,
  IntegracaoOMIE,
  ModoPaginaCadastro,
  Professor,
  Sexo,
  UsuarioSituacao
} from '../../../../tipos'
import { Form } from './form'
import {
  Container,
  ContainerLink,
  ContainerNavegacao,
  Conteudo,
  ContainerMain
} from './styles'
import { CadastroProfessorProps } from './tipos'

export enum Navegacoes {
  DadosPessoais = 'dados-pessoais',
  Contato = 'contato',
  Residencia = 'residencia',
  ResumoAcademico = 'resumo-academico',
  DadosFinanceiros = 'dados-financeiros'
}

const CadastroProfessores: React.FC<CadastroProfessorProps> = ({
  match,
  modo
}) => {
  const [isInclusao] = useState(modo === ModoPaginaCadastro.Inclusao)
  const [titulo] = useState(
    `${isInclusao ? 'Inclusão' : 'Edição'} de Professor`
  )

  const [pronto, definirPronto] = useState(false)
  const [carregando, setCarregando] = useState(false)
  const history = useHistory()
  const [professor, setProfessor] = useState<Professor | undefined>()

  const professorDefault = {
    situacao: UsuarioSituacao.Ativo,
    lattes: 'http://lattes.cnpq.br'
  } as Professor

  const dadosIniciais = useMemo(() => {
    const prof = professor
      ? Object.assign({}, professorDefault, professor)
      : { ...professorDefault }

    return {
      ...prof,
      rg: prof.rg,
      situacao: prof.situacao && UsuarioSituacao[prof.situacao],
      sexo: prof.sexo && Sexo[prof.sexo].toString(),
      estadoCivil: prof.estadoCivil && EstadoCivil[prof.estadoCivil].toString(),
      escolaridade:
        prof.escolaridade && Escolaridade[prof.escolaridade].toString(),
      corRaca: prof.corRaca && CorRaca[prof.corRaca].toString()
    }
  }, [professor])

  const carregarDados = async () => {
    try {
      if (modo === ModoPaginaCadastro.Edicao) {
        const professorApi = await Api.ObterProfessor(match.params.id)

        setProfessor(professorApi)
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados do professor', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const resolveMensagemIntegracao = (
    integracao?: IntegracaoOMIE,
    dados?: string[]
  ) => {
    if (
      !integracao ||
      IntegracaoOMIE[integracao] === IntegracaoOMIE[IntegracaoOMIE.Erro]
    ) {
      toast('Erro ao realizar cadastro professor na plataforma OMIE', {
        type: 'error'
      })
    }

    if (
      IntegracaoOMIE[integracao] === IntegracaoOMIE[IntegracaoOMIE.Existente]
    ) {
      toast(
        'O Professor já possui cadastro na plataforma OMIE. Não foi possível atualizar os dados, verifique manualmente'
      )
    }

    if (IntegracaoOMIE[integracao] === IntegracaoOMIE[IntegracaoOMIE.Criado]) {
      toast('Professor cadastrado com sucesso na plataforma OMIE')
    }

    if (
      IntegracaoOMIE[integracao] ===
      IntegracaoOMIE[IntegracaoOMIE.TipoContaInvalido]
    ) {
      toast(
        'Para criar o cadastro na plataforma OMIE é preciso informar uma conta do tipo Corrente'
      )
    }

    if (
      IntegracaoOMIE[integracao] === IntegracaoOMIE[IntegracaoOMIE.Invalido]
    ) {
      const campos = dados?.join(', ')
      toast(
        `Dados insuficientes para criar o cadastro na plataforma OMIE: ${campos}`
      )
    }
  }

  const acaoSucesso = async (dados: any) => {
    setCarregando(true)

    try {
      if (isInclusao) {
        const usuarioExiste = await Api.RequisitarUsuarioPorCpf(dados.cpf)
        const professor = await Api.InserirProfessor(dados)
        toast('Professor cadastrado com sucesso', {
          type: 'success'
        })

        resolveMensagemIntegracao(
          professor.data.integracao,
          professor.data.dadosFaltantesOmie
        )
        history.push(RotasAcademico.Professores)
        if (usuarioExiste === null) {
          await NotificarUsuario(professor.data.usuarioId)
        }
      } else {
        const resposta = await Api.EditarProfessor(match.params.id, dados)
        resolveMensagemIntegracao(resposta.integracao, resposta.dados)
        toast('Professor editado com sucesso', {
          type: 'success'
        })
        history.push(RotasAcademico.Professores)
      }
    } catch (erro) {
      toast('Ops! Aconteceu um erro. Tente novamente.', { type: 'error' })
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {
    carregarDados()
  }, [])

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.Professores}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Acadêmico'
                  },
                  {
                    texto: 'Professores',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <Row>
                <Form
                  acaoSucesso={acaoSucesso}
                  isInclusao={isInclusao}
                  professor={dadosIniciais}
                  loading={carregando}
                  className="lg"
                />
              </Row>
            </Conteudo>
          </ContainerMain>
          <ContainerNavegacao>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.DadosPessoais,
                  nome: 'Dados Pessoais'
                },
                { link: Navegacoes.Contato, nome: 'Contato' },
                {
                  link: Navegacoes.DadosFinanceiros,
                  nome: 'Dados Financeiros'
                },
                { link: Navegacoes.Residencia, nome: 'Residência' },
                {
                  link: Navegacoes.ResumoAcademico,
                  nome: 'Resumo Acadêmico'
                }
              ]}
            />
          </ContainerNavegacao>
        </Container>
      ) : null}
    </>
  )
}

export default withRouter(CadastroProfessores)
