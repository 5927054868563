import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaDataVencimento = (): TabelaColuna => ({
  Chave: 'dataVencimento',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataVencimento)}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 90 }}>Data Vencimento</div>
})
