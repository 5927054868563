import { Cores } from 'src/componentes/styles'
import styled, { css } from 'styled-components'

export const Mensagem = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: ${Cores.CINZA_2_MAIS_ESCURO};

  strong {
    font-weight: 400;
  }

  margin-bottom: 15px;
`

const EstiloContainerCertificado = css`
  background-color: ${Cores.BRANCO};
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  flex: 1;
  height: 595px;

  @media print {
    height: 100%;
    padding-right: 80px;

    @page {
      size: landscape;
    }
  }
`

export const ContainerCertificado = styled.div`
  ${EstiloContainerCertificado}

  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoLateralCertificado = styled.img`
  height: 100%;
  width: 60px;

  @media (min-width: 827px) and (max-width: 920px) {
    width: 50px;
  }

  @media (min-width: 769px) and (max-width: 826px) {
    height: 60%;
  }

  @media (min-width: 537px) and (max-width: 768px) {
    height: 80%;
  }

  @media (min-width: 345px) and (max-width: 536px) {
    height: 60%;
  }

  @media (max-width: 344px) {
    display: none;
  }

  @media print {
    height: 100%;
    width: 80px;
    display: block;
  }
`

export const TituloCertificado = styled.h1`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 30px;
  color: ${Cores.PRIMARIA_1};
  text-transform: uppercase;

  @media (min-width: 769px) and (max-width: 920px) {
    font-size: 30px;
  }

  @media (min-width: 345px) and (max-width: 536px) {
    font-size: 30px;
  }

  @media (max-width: 344px) {
    font-size: 20px;
  }

  @media print {
    font-size: 50px;
    margin-bottom: 40px;
  }
`

export const ConteudoCentralizadoCertificado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 30%;
    margin-bottom: 50px;
  }

  & > p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
    letter-spacing: 0.2px;

    &.localidade-data {
      margin-bottom: 0px;
      font-size: 16px;
    }

    &.nome-usuario {
      font-size: 22px;
    }
  }

  @media (min-width: 769px) and (max-width: 920px) {
    & > p {
      font-size: 14px;
    }
  }

  @media (min-width: 537px) and (max-width: 768px) {
    & > p {
      font-size: 16px;
    }
  }

  @media (min-width: 345px) and (max-width: 536px) {
    & > p {
      font-size: 14px;
    }
  }

  @media (max-width: 344px) {
    & > p {
      font-size: 14px;
    }
  }

  @media print {
    & > img {
      width: 25%;
      margin-bottom: 60px;
    }
    & > p {
      font-size: 20px;
      margin-bottom: 30px;

      &.nome-usuario {
        font-size: 28px;
      }
    }
  }
`

export const ConteudoCertificado = styled.div`
  flex: 1;
`

export const RodapeCertificado = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;

  @media print {
    margin-top: 20px;
  }
`

export const Assinatura = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > img {
    width: 60%;
  }

  & > .linha-absolut {
    position: absolute;
  }
`

export const AssinaturaNome = styled.span`
  font-size: 14px;
`

export const AssinaturaCargo = styled.p`
  font-size: 12px;
`

export const ContainerQRCode = styled.div`
  @media (min-width: 827px) and (max-width: 920px) {
    padding-right: 25px;
  }

  @media (min-width: 769px) and (max-width: 826px) {
    padding-right: 5px;

    & > canvas {
      width: 90px;
      height: 90px;
    }
  }

  @media (max-width: 536px) {
    padding-right: 5px;

    & > canvas {
      width: 90px;
      height: 90px;
    }
  }

  @media print {
    & > canvas {
      width: 128px;
      height: 128px;
    }
  }
`

export const ContainerCertificadoVerso = styled.div`
  ${EstiloContainerCertificado}
  padding-right: 10px;

  display: none;

  @media print {
    display: flex;
    align-items: flex-start;
  }
`

export const ContainerLateral = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & > canvas {
    margin: 50px;
  }
`

export const ConteudoCertificadoVerso = styled.div`
  flex: 1;
  height: 80%;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ConteudoCentralizadoCertificadoVerso = styled.div`
  & > h1 {
    background-color: ${Cores.SECUNDARIA_2_ESCURO};
    color: ${Cores.BRANCO};
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }

  & > h2 {
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  & > div > p {
    font-size: 17px;
    text-align: left;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
`
export const RodapeCertificadoVerso = styled.div`
  display: flex;
  flex-direction: row;
  gap: 200px;
`

export const ContainerLogoCertificadoVerso = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > img {
    width: 240px;
    height: 50px;
  }
`
