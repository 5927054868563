import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormHandles, Scope } from '@unform/core'
import { SELECT_PAISES, SELECT_SEXO } from 'src/dados-estaticos'
import { Api } from 'src/servicos'

import { Navegacoes } from '.'
import {
  SubTitulo,
  ImageInput,
  Botao,
  Spacer,
  FormRow,
  focarPrimeiroCampoComErro,
  Modal,
  ModalRef,
  IconeCarregando
} from '../../../../componentes'
import { CampoLabel } from '../../../../componentes/input/styles'
import {
  CheckboxUnform,
  FormUnform,
  InputUnform,
  TelefoneInputUnform,
  SelectUnform,
  SeletorUnform,
  TextAreaUnform,
  DataInputUnform,
  CpfInputUnform,
  MoedaInputUnform,
  SelectAsyncUnform
} from '../../../../componentes/unform'
import {
  CorRaca,
  Escolaridade,
  EstadoCivil,
  Nacionalidade,
  Professor,
  Raca,
  Sexo,
  SexoPorExtenso,
  TipoConta,
  Usuario,
  UsuarioSituacao
} from '../../../../tipos'
import { schemaEdicao } from './schema'
import {
  Opcao,
  OpcaoDados,
  Secao,
  ContainerAcoes,
  ContainerDadosMedio,
  Carregamento
} from './styles'

interface FormProps {
  acaoSucesso: (dados) => void
  isInclusao: boolean
  loading: boolean
  className?: string
  professor: Professor
}

export const Form: React.FC<FormProps> = ({
  acaoSucesso,
  isInclusao,
  loading,
  className,
  professor
}) => {
  const formRef = useRef<FormHandles>(null)
  const modalCancelamentoRef = useRef<ModalRef>(null)
  const history = useHistory()

  const [paisResidencia, setPaisResidencia] = useState<string>(
    professor?.paisResidencia
  )
  const [foto, setFoto] = useState(null)
  const [possuiNomeSocial, setPossuiNomeSocial] = useState(
    !!professor?.nomeSocial
  )
  const [generoAluno, definirGeneroAluno] = useState<string>('')
  const [contaTerceiro, definirContaTerceiro] = useState(
    isInclusao ? false : !professor?.dadosFinanceirosProfessor?.titularConta
  )
  const [
    camposDadosPessoasDesabilitados,
    setCamposDadosPessoasDesabilitados
  ] = useState(false)
  const [usuarioPorCpf, setUsuarioPorCpf] = useState<Usuario>(null)
  const [carregando, setCarregando] = useState(false)

  const dadosiniciais = {
    foto: foto,
    lattes: 'http://lattes.cnpq.br',
    situacao: 0
  }

  const atualizarValorFormulario = (campo: string, valor: any) => {
    if (!valor) return
    formRef?.current.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const renderizarCarregamento = () => (
    <Carregamento> {IconeCarregando} </Carregamento>
  )

  const buscarCep = async (cep: string) => {
    const endereco = await Api.BuscarCep(cep)

    if (endereco) {
      formRef.current?.setFieldValue('bairro', endereco.bairro)
      formRef.current?.setFieldValue('logradouro', endereco.logradouro)
      formRef.current?.setFieldValue('cidade', endereco.cidade)
      formRef.current?.setFieldValue('uf', endereco.uf)
      formRef.current?.setFieldValue('numero', endereco.numero)
      document.getElementById('ipt_numero')?.focus()
    } else {
      formRef.current?.setFieldValue('bairro', '')
      formRef.current?.setFieldValue('logradouro', '')
      formRef.current?.setFieldValue('cidade', '')
      formRef.current?.setFieldValue('uf', '')
      formRef.current?.setFieldValue('complemento', '')
      formRef.current?.setFieldValue('numero', '')
    }
  }

  const verificarExistenciaPessoa = async () => {
    const cpf = formRef?.current?.getFieldValue('cpf')

    if (usuarioPorCpf?.cpf === cpf) return

    if (cpf.length !== 11) {
      setCamposDadosPessoasDesabilitados(false)
      setUsuarioPorCpf(null)
      return
    }

    try {
      setCarregando(true)
      const usuario = await Api.RequisitarUsuarioPorCpf(cpf)
      setFoto(usuario.foto)
      const pais =
        usuario.nacionalidade === Nacionalidade[Nacionalidade.brasileira]
          ? 'Brasil'
          : 'Outro'
      setPossuiNomeSocial(!!usuario?.nomeSocial)
      setUsuarioPorCpf({
        ...usuario,
        endereco: {
          ...usuario.endereco,
          pais: usuario?.endereco?.paisResidencia
        }
      })
      setCamposDadosPessoasDesabilitados(true)
      atualizarValorFormulario('rg', usuario?.documentoIdentidade)
      atualizarValorFormulario('nome', usuario.nome)
      atualizarValorFormulario('email', usuario.email)
      atualizarValorFormulario('sexo', Sexo[usuario.sexo].toString())
      atualizarValorFormulario('generoOutros', usuario?.generoOutros)
      atualizarValorFormulario('dataNascimento', usuario?.dataNascimento)
      atualizarValorFormulario('nomeSocial', usuario.nomeSocial)
      atualizarValorFormulario('raca', Raca[usuario?.corRaca])
      atualizarValorFormulario(
        'escolaridade',
        Escolaridade[usuario?.escolaridade]
      )
      atualizarValorFormulario(
        'estadoCivil',
        EstadoCivil[usuario?.estadoCivil].toString()
      )
      atualizarValorFormulario('profissao', usuario?.profissao)
      atualizarValorFormulario('celular', usuario?.celular)
      atualizarValorFormulario('telefone', usuario?.telefone)

      atualizarValorFormulario('paisResidencia', pais)
      atualizarValorFormulario('cep', usuario.endereco?.cep)
      atualizarValorFormulario('logradouro', usuario.endereco?.logradouro)
      atualizarValorFormulario('bairro', usuario.endereco?.bairro)
      atualizarValorFormulario('complemento', usuario.endereco?.complemento)
      atualizarValorFormulario('numero', usuario.endereco?.numero)
      atualizarValorFormulario('uf', usuario.endereco?.uf)
      atualizarValorFormulario('cidade', usuario.endereco?.cidade)
    } catch {
      setCamposDadosPessoasDesabilitados(false)
      setUsuarioPorCpf(null)
    } finally {
      setCarregando(false)
    }
  }

  const carregarOpcoesBancos = async (filtro: string) => {
    try {
      const bancos = await Api.ObterBancosAtivos(filtro)
      return (
        bancos?.map(b => {
          return { id: b.id, texto: `${b.codigo ?? ' '} - ${b.nome}` }
        }) ?? []
      )
    } catch (erro) {
      toast('Erro ao carregar opções de bancos.', { type: 'error' })
    }
  }

  const carregarBancoPorId = async (id: string) => {
    try {
      const banco = await Api.ObterBancoPorId(id)
      return [{ id: banco.id, texto: `${banco.codigo ?? ' '} - ${banco.nome}` }]
    } catch (erro) {
      toast('Erro ao carregar opções de bancos.', { type: 'error' })
    }
  }
  return (
    <FormUnform
      ref={formRef}
      schema={schemaEdicao}
      acaoSucesso={acaoSucesso}
      acaoFalha={focarPrimeiroCampoComErro}
      className={className}
      dadosIniciais={professor.sexo === undefined ? dadosiniciais : professor}
    >
      <Secao id={Navegacoes.DadosPessoais}>
        <SubTitulo texto="Dados Pessoais" />
        <ImageInput id="foto" name="foto" label />
        <Spacer padding="10px" />
        <FormRow>
          <CpfInputUnform
            id="cpf"
            name="cpf"
            label="CPF"
            onBlur={verificarExistenciaPessoa}
            disabled={
              !isInclusao ||
              (camposDadosPessoasDesabilitados && !!usuarioPorCpf?.cpf) ||
              carregando
            }
            obrigatorio
          />
          {carregando && renderizarCarregamento()}
          <InputUnform
            id={isInclusao ? 'documentoIdentidade' : 'rg'}
            name={isInclusao ? 'documentoIdentidade' : 'rg'}
            label="RG"
            disabled={
              (camposDadosPessoasDesabilitados &&
                !!usuarioPorCpf?.documentoIdentidade) ||
              carregando
            }
            maxLength={10}
          />
          <SeletorUnform<number>
            id="situacao"
            name="situacao"
            tipo="radio"
            label="Situação"
            seletores={[
              {
                id: UsuarioSituacao.Ativo,
                texto: 'Ativo'
              },
              {
                id: UsuarioSituacao.Inativo,
                texto: 'Inativo'
              }
            ]}
            disabled={carregando}
          />
        </FormRow>
        <InputUnform
          id="nome"
          name="nome"
          label="Nome"
          maxLength={200}
          disabled={
            (camposDadosPessoasDesabilitados && !!usuarioPorCpf?.nome) ||
            carregando
          }
          obrigatorio
        />
        <FormRow>
          <SelectUnform
            className="md"
            id="sexo"
            name="sexo"
            label="Gênero"
            placeholder="Selecione"
            opcoes={SELECT_SEXO}
            valorAlterado={valor => definirGeneroAluno(valor?.texto)}
            disabled={!isInclusao}
            obrigatorio
          />
          <InputUnform
            type="text"
            id="generoOutros"
            name="generoOutros"
            label="Qual?"
            disabled={generoAluno !== SexoPorExtenso.Outros}
            maxLength={200}
          />
          <DataInputUnform
            className="sm"
            id="dataNascimento"
            name="dataNascimento"
            label="Data de Nascimento"
            disabled={
              (camposDadosPessoasDesabilitados &&
                !!usuarioPorCpf?.dataNascimento) ||
              carregando
            }
            obrigatorio
          />
          <Spacer className="sm" />
        </FormRow>
        <Opcao>
          <CampoLabel>Possui nome social?</CampoLabel>
          <OpcaoDados>
            <CheckboxUnform
              id="chk_possui_nome_social"
              name="possuiNomeSocial"
              onChange={() => setPossuiNomeSocial(old => !old)}
              checked={possuiNomeSocial}
              disabled={
                (camposDadosPessoasDesabilitados &&
                  !!usuarioPorCpf?.dataNascimento) ||
                carregando
              }
            />
            <p>
              <b>Sim</b>
              <i>
                ( Nome social é a adoção de nome diferente do oficialmente
                registrado, de modo a identificar adequadamente sua identidade
                de gênero - Decreto nº 8727/2016)
              </i>
            </p>
          </OpcaoDados>
        </Opcao>
        {possuiNomeSocial ? (
          <InputUnform
            type="text"
            name="nomeSocial"
            label="Nome Social"
            maxLength={200}
            disabled={
              (camposDadosPessoasDesabilitados &&
                !!usuarioPorCpf?.nomeSocial) ||
              carregando
            }
            obrigatorio
          />
        ) : null}
        <FormRow>
          <SelectUnform
            className="lg"
            id="corRaca"
            name="corRaca"
            label="Raça"
            placeholder="Selecione"
            opcoes={[
              {
                id: `${CorRaca.NaoInformado}`,
                texto: 'Não informado'
              },
              {
                id: `${CorRaca.Amarela}`,
                texto: 'Amarela'
              },
              {
                id: `${CorRaca.Branca}`,
                texto: 'Branca'
              },
              {
                id: `${CorRaca.Indigena}`,
                texto: 'Indígena'
              },
              {
                id: `${CorRaca.Parda}`,
                texto: 'Parda'
              },
              {
                id: `${CorRaca.Preta}`,
                texto: 'Preta'
              }
            ]}
            disabled={!isInclusao || carregando}
            obrigatorio
          />
          <SelectUnform
            className="lg"
            id="estadoCivil"
            name="estadoCivil"
            label="Estado Civil"
            placeholder="Selecione"
            opcoes={[
              {
                id: `${EstadoCivil.NaoInformado}`,
                texto: 'Não informado'
              },
              {
                id: `${EstadoCivil.Casado}`,
                texto: 'Casado(a)'
              },
              {
                id: `${EstadoCivil.Divorciado}`,
                texto: 'Divorciado(a)'
              },
              {
                id: `${EstadoCivil.Separado}`,
                texto: 'Separado(a)'
              },
              {
                id: `${EstadoCivil.Solteiro}`,
                texto: 'Solteiro(a)'
              },
              {
                id: `${EstadoCivil.Viuvo}`,
                texto: 'Viúvo(a)'
              }
            ]}
            disabled={carregando}
          />
        </FormRow>
        <FormRow>
          <SelectUnform
            className="lg"
            id="escolaridade"
            name="escolaridade"
            label="Escolaridade"
            placeholder="Selecione"
            opcoes={[
              {
                id: `${Escolaridade.Doutorado}`,
                texto: 'Doutorado'
              },
              {
                id: `${Escolaridade.Especializacao}`,
                texto: 'Especialização'
              },
              {
                id: `${Escolaridade.Mestrado}`,
                texto: 'Mestrado'
              },
              {
                id: `${Escolaridade.PosDoutorado}`,
                texto: 'Pós Doutorado'
              }
            ]}
            disabled={carregando}
            obrigatorio
          />
          <InputUnform
            className="lg"
            type="text"
            name="profissao"
            label="Profissão"
            maxLength={200}
            disabled={
              (camposDadosPessoasDesabilitados && !!usuarioPorCpf?.profissao) ||
              carregando
            }
          />
        </FormRow>
      </Secao>
      <Secao id={Navegacoes.Contato}>
        <SubTitulo texto="Contato" />
        <FormRow>
          <InputUnform
            className="md"
            id="email"
            type="email"
            name="email"
            label="E-mail"
            maxLength={200}
            obrigatorio
            disabled={
              !isInclusao ||
              (camposDadosPessoasDesabilitados && !!usuarioPorCpf?.email) ||
              carregando
            }
          />
          <TelefoneInputUnform
            className="sm"
            id="celular"
            name="celular"
            label="Celular"
            maxLength={11}
            obrigatorio
            disabled={
              (camposDadosPessoasDesabilitados && !!usuarioPorCpf?.celular) ||
              carregando
            }
          />
          <TelefoneInputUnform
            className="sm"
            name="telefone"
            label="Telefone"
            maxLength={11}
            disabled={
              (camposDadosPessoasDesabilitados && !!usuarioPorCpf?.telefone) ||
              carregando
            }
          />
        </FormRow>
      </Secao>
      <Secao id={Navegacoes.DadosFinanceiros}>
        <SubTitulo texto="Dados Financeiros" />
        <Scope path="dadosFinanceirosProfessor">
          <FormRow>
            <SelectAsyncUnform
              id="ipt_banco_id"
              name="bancoId"
              label="Banco"
              className="md ipt_banco_slc"
              buscarPorTexto={carregarOpcoesBancos}
              buscarPorId={carregarBancoPorId}
            />
            <SelectUnform
              className="md"
              id="tipoConta"
              name="tipoConta"
              label="Tipo Conta"
              placeholder="Selecione"
              opcoes={[
                {
                  id: `${TipoConta.ContaCorrente}`,
                  texto: 'Conta Corrente'
                },
                {
                  id: `${TipoConta.ContaPoupanca}`,
                  texto: 'Conta Poupança'
                },
                {
                  id: `${TipoConta.ContaSalario}`,
                  texto: 'Conta Salário'
                }
              ]}
            />
          </FormRow>
          <FormRow>
            <InputUnform
              className="sm"
              id="agencia"
              type="agencia"
              name="agencia"
              label="Agência"
            />
            <InputUnform
              className="sm"
              id="conta"
              type="conta"
              name="conta"
              label="Conta"
            />
            <InputUnform
              className="md"
              id="chavePix"
              type="chavePix"
              name="chavePix"
              label="Chave PIX"
            />
          </FormRow>
          <FormRow>
            <InputUnform
              className="md"
              id="observacoes"
              type="observacoes"
              name="observacoes"
              label="Observações"
              maxLength={100}
            />
            <MoedaInputUnform
              className="md"
              id="valorHora"
              type="valorHora"
              name="valorHora"
              label="Valor Hora"
            />
          </FormRow>
          <FormRow>
            <Opcao>
              <CampoLabel>Conta de Terceiro</CampoLabel>
              <OpcaoDados>
                <CheckboxUnform
                  id="chk_titular_conta"
                  name="titularConta"
                  onChange={() => definirContaTerceiro(old => !old)}
                  checked={contaTerceiro}
                />
              </OpcaoDados>
            </Opcao>
            {contaTerceiro && (
              <ContainerDadosMedio>
                <InputUnform
                  id="nomeTitular"
                  name="nomeTitular"
                  label="Nome do Titular"
                  maxLength={200}
                />
              </ContainerDadosMedio>
            )}
            {contaTerceiro && (
              <ContainerDadosMedio>
                <CpfInputUnform
                  id="cpfTitular"
                  name="cpfTitular"
                  label="CPF do Titular"
                />
              </ContainerDadosMedio>
            )}
          </FormRow>
        </Scope>
      </Secao>
      <Secao id={Navegacoes.Residencia}>
        <SubTitulo texto="Residência" />
        <SelectUnform
          name="paisResidencia"
          id="paisResidencia"
          label="País de Residência"
          valorAlterado={opcao => setPaisResidencia(opcao ? opcao.id : '')}
          opcoes={SELECT_PAISES}
          obrigatorio
          disabled={carregando}
        />
        {paisResidencia === 'brasil' ? (
          <ContainerDadosMedio>
            <InputUnform
              className="sm"
              type="text"
              name="cep"
              label="CEP"
              obrigatorio={paisResidencia === 'brasil'}
              mascara="99999999"
              onBlur={event => buscarCep(event.target.value)}
              disabled={carregando}
            />
            <Spacer className="lg" />
          </ContainerDadosMedio>
        ) : null}
        <InputUnform
          type="text"
          name="logradouro"
          label="Logradouro"
          maxLength={200}
          obrigatorio
          disabled={carregando}
        />
        <FormRow>
          <InputUnform
            className="sm"
            type="text"
            name="numero"
            id="ipt_numero"
            label="Número"
            maxLength={200}
            obrigatorio
            disabled={carregando}
          />
          <InputUnform
            className="sm"
            type="text"
            name="complemento"
            label="Complemento"
            maxLength={200}
            disabled={carregando}
          />
        </FormRow>
        <FormRow>
          <InputUnform
            type="text"
            name="bairro"
            label="Bairro"
            maxLength={200}
            obrigatorio
            className="sm"
            disabled={carregando}
          />
          <InputUnform
            type="text"
            name="cidade"
            label="Cidade"
            maxLength={200}
            obrigatorio
            className="sm"
            disabled={carregando}
          />
        </FormRow>
        <FormRow>
          <InputUnform
            type="text"
            name="uf"
            label="UF"
            maxLength={10}
            obrigatorio
            className="sm"
            disabled={carregando}
          />
          <Spacer className="sm" />
        </FormRow>
      </Secao>
      <Secao id={Navegacoes.ResumoAcademico}>
        <SubTitulo texto="Resumo Acadêmico" />
        <InputUnform
          type="text"
          name="lattes"
          label="Link do Lattes"
          defaultValue="http://lattes.cnpq.br"
          maxLength={200}
          disabled={carregando}
        />
        <TextAreaUnform
          name="curriculo"
          label="Resumo do Currículo"
          maxLength={200}
          disabled={carregando}
        />
        <TextAreaUnform
          name="aptidoes"
          label="Aptidões"
          maxLength={200}
          obrigatorio
          disabled={carregando}
        />
        <TextAreaUnform
          name="informacoesAdicionais"
          label="Informações Adicionais"
          placeholder="Aqui você pode informar detalhes de contato e disponibilidade para viagens..."
          maxLength={200}
        />
      </Secao>
      <ContainerAcoes>
        <Botao
          type="button"
          texto="Cancelar"
          tema="Secundario"
          disabled={loading}
          onClick={() => modalCancelamentoRef?.current?.abrir()}
        />
        <Botao type="submit" texto="Salvar" carregando={loading} />
      </ContainerAcoes>
      <Modal
        ref={modalCancelamentoRef}
        backdrop
        id="modal-confirmacao-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.goBack()
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalCancelamentoRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
    </FormUnform>
  )
}
