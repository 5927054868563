import styled, { css } from 'styled-components'

import { Cores } from '../../styles'

export const Componente = styled.div<{ esconder?: boolean }>`
  position: relative;
  display: ${props => (props.esconder ? 'none' : 'flex')};
  flex-direction: column;
  margin-bottom: 25px;

  &.semMargemAbaixo {
    margin-bottom: 0px;
  }

  ${({ theme: { ComErro } }) =>
    ComErro &&
    css`
      span {
        opacity: 1;
        bottom: -20px;
      }
    `}
`

export const Erro = styled.span<{ sucesso?: boolean }>`
  color: ${props => (props.sucesso ? Cores.SUCESSO : Cores.PERIGO_ERRO)};
  font-size: 10px;
  margin-top: 5px;
  white-space: pre-line;
  top: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s ease-in, bottom 0.2s ease-in;
`
