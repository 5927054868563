import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  CheckboxUnform,
  FormUnform,
  ModalBase,
  ModalRef,
  TextAreaUnform
} from 'src/componentes'

import {
  ContainerConteudo,
  ContainerModal,
  TituloPrincipal,
  ContainerBotoes,
  ContainerCheckbox,
  Opcao,
  OpcaoDados
} from './styles'
import { DadosRecusa, ModalRecusaResponsavelProps } from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalRef,
  ModalRecusaResponsavelProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [aceiteResponsavel, definirAceiteResponsavel] = useState(false)
  const formRef = useRef<FormHandles>(null)

  const fechar = () => {
    definirAberta(false)
    definirAceiteResponsavel(false)
    formRef.current?.reset()
  }

  const abrir = () => {
    definirAberta(true)
  }

  const acaoSucessoForm = async (dados: DadosRecusa) => {
    await acaoPrimaria(dados?.motivoSituacao)
    fechar()
    formRef.current?.reset()
  }

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-recusa-responsavel"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Recusa - Responsável Financeiro</TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm}>
          <ContainerConteudo>
            <TextAreaUnform name="motivoSituacao" label="Motivo" />
            <ContainerCheckbox>
              <Opcao>
                <OpcaoDados>
                  <CheckboxUnform
                    id="chk_recusa_responsavel"
                    name="recusaResponsavel"
                    onChange={() => definirAceiteResponsavel(old => !old)}
                  />
                  <label htmlFor="chk_recusa_responsavel">
                    Fui atribuído como Responsável Financeiro de maneira
                    indevida ou fora dos parâmetros combinados. Confirmo que
                    quero recusar esta função.
                  </label>
                </OpcaoDados>
              </Opcao>
            </ContainerCheckbox>
          </ContainerConteudo>
          <ContainerBotoes>
            <Botao
              texto="Voltar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Enviar" disabled={!aceiteResponsavel} />
          </ContainerBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}
export const ModalRecusaResponsavel = forwardRef(Modal)
