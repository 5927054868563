import * as Yup from 'yup'

import { mensagemPadrao } from '../../../../../../dados-estaticos'

export const createSchema = (): Yup.ObjectSchema =>
  Yup.object().shape({
    nome: Yup.string().required(mensagemPadrao),
    informacoesAdicionais: Yup.string()
      .nullable()
      .when('tipoModulo', {
        is: tipoModulo => tipoModulo === 'Flex',
        then: field => field.required(mensagemPadrao)
      })
  })
