import {
  TabelaPaginacaoEstado,
  TabelaPaginacaoAcoes,
  TabelaTamanhoPagina
} from './tipos'

export default (
  estado: TabelaPaginacaoEstado,
  acao: TabelaPaginacaoAcoes
): TabelaPaginacaoEstado => {
  switch (acao.tipo) {
    case 'proxima-pagina': {
      return { ...estado, Pagina: ++estado.Pagina }
    }
    case 'pagina-anterior': {
      return { ...estado, Pagina: --estado.Pagina }
    }
    case 'ir-para-pagina': {
      return { ...estado, Pagina: acao.pagina }
    }
    case 'alterar-tamanho-pagina': {
      return {
        ...estado,
        TamanhoPagina: acao.tamanhoPagina as TabelaTamanhoPagina,
        Pagina: 1
      }
    }
    case 'alterar-totais': {
      return {
        ...estado,
        TotalRegistros: acao.totalRegistros,
        TotalPaginas: Math.ceil(
          (acao.totalRegistros || 0) / estado.TamanhoPagina
        )
      }
    }
    default: {
      throw new Error(`Ação inválida: ${acao}`)
    }
  }
}
