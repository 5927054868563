import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { TipoDeAvaliacaoPorExtenso } from 'src/dto'
import { Api } from 'src/servicos'
import {
  Disciplina,
  DisciplinasTCC,
  Modulo,
  Notas
} from 'src/servicos/api/sala-aula/tipos'
import {
  NotaAlteracaoTCC,
  TipoAvaliacaoPorExtenso,
  TipoProfessorModuloTCC
} from 'src/tipos'

import { ColunaNotas } from './styles'

interface AbaNotasProps {
  matriculaId: string
  tipoAvaliacao: TipoAvaliacaoPorExtenso
}

export const AbaNotas: React.FC<AbaNotasProps> = ({
  matriculaId,
  tipoAvaliacao
}) => {
  const [notas, definirNotas] = useState({} as Notas)
  const [pronto, definirPronto] = useState(false)
  const ehAvaliacaoPorDisciplina =
    tipoAvaliacao === TipoAvaliacaoPorExtenso.AvaliacaoPorDisciplina

  const carregarNotas = async () => {
    try {
      definirPronto(false)
      const resposta = await Api.SalaAulaOutros.RequisitarNotas(matriculaId)

      definirNotas(resposta)
    } catch {
      toast('Erro ao carregar notas.', { type: 'error' })
    } finally {
      definirPronto(true)
    }
  }

  const resolverMensagemNotaDisciplina = (disciplina: Disciplina) => {
    if (disciplina.aproveitamento || disciplina.reingresso) {
      return `${disciplina.nota} - Aprovado por aproveitamento.`
    }

    return disciplina.nota
      ? `${disciplina.nota} - ${
          disciplina.tipoAvaliacao
            ? TipoDeAvaliacaoPorExtenso[disciplina.tipoAvaliacao]
            : ''
        }`
      : '-'
  }

  const resolverMensagemNotaModulo = (modulo: Modulo) => {
    if (modulo.reingresso) {
      return `${modulo.nota} - Aprovado por aproveitamento.`
    }
    return modulo.nota
      ? `${modulo.nota} - ${
          modulo.tipoAvaliacao
            ? TipoDeAvaliacaoPorExtenso[modulo.tipoAvaliacao]
            : ''
        }`
      : '-'
  }

  const resolverNota = (disciplina: DisciplinasTCC) => {
    if (disciplina.dispensada) return NotaAlteracaoTCC.DISP
    if (disciplina.aproveitada) return NotaAlteracaoTCC.APRO
    return disciplina.nota ?? '-'
  }
  useEffect(() => {
    carregarNotas()
  }, [])

  return (
    <ColunaNotas>
      {pronto && (
        <>
          {notas?.modulos?.map((modulo, index) => (
            <>
              <li key={index}>
                <ul>
                  <p
                    className={
                      ehAvaliacaoPorDisciplina ? 'titulo-modulo' : undefined
                    }
                  >
                    Módulo: {modulo.nomeModulo}
                  </p>
                  {!ehAvaliacaoPorDisciplina && (
                    <>
                      <p>- Nota: {resolverMensagemNotaModulo(modulo)}</p>
                      {modulo?.reingresso && (
                        <p>- Turma de Origem: {notas?.turmaOrigem}</p>
                      )}
                    </>
                  )}
                </ul>
              </li>
              {ehAvaliacaoPorDisciplina &&
                modulo.disciplinas.map((disciplina, index) => (
                  <li key={index}>
                    <ul>
                      <p>- {disciplina.nomeDisciplina}</p>
                      <p>
                        - Nota: {resolverMensagemNotaDisciplina(disciplina)}
                      </p>
                      {disciplina?.reingresso && (
                        <p>- Turma de Origem: {notas?.turmaOrigem}</p>
                      )}
                    </ul>
                  </li>
                ))}
            </>
          ))}
          {notas.nomeModuloTCC && notas.disciplinasTCC && (
            <>
              <p className={'titulo-modulo'}>Módulo: {notas.nomeModuloTCC}</p>
              {notas.disciplinasTCC.map((disciplina, index) => {
                const notasOrientador = disciplina?.feedbacks[0]?.envios?.map(
                  envio =>
                    envio?.notas?.find(
                      n => n.tipoProfessor === TipoProfessorModuloTCC.Orientador
                    )
                )
                const notaOrientador =
                  notasOrientador && notasOrientador.length
                    ? notasOrientador[notasOrientador.length - 1].nota
                    : null
                const notasBanca = disciplina?.feedbacks[0]?.envios?.map(
                  envio =>
                    envio?.notas?.find(
                      n => n.tipoProfessor === TipoProfessorModuloTCC.Banca
                    )
                )
                const notaBanca =
                  notasBanca && notasBanca.length
                    ? notasBanca[notasBanca.length - 1]?.nota
                    : null

                return (
                  <li key={index}>
                    <ul>
                      <p>- {disciplina.nomeDisciplina}</p>
                      <p>- Média: {resolverNota(disciplina)}</p>
                      {disciplina.aproveitada ? (
                        <>
                          <p>- Nota Aproveitada: {disciplina.nota ?? '-'}</p>
                          <p>
                            - Instituição de Origem:{' '}
                            {disciplina.instituicaoAproveitamento ?? '-'}
                          </p>
                          <p>
                            - Turma de Origem:{' '}
                            {disciplina.turmaAproveitamento ?? '-'}
                          </p>
                        </>
                      ) : (
                        <>
                          {' '}
                          <p>- Nota Orientador: {notaOrientador ?? '-'}</p>
                          <p>- Nota Banca: {notaBanca ?? '-'}</p>
                        </>
                      )}
                    </ul>
                  </li>
                )
              })}
            </>
          )}
        </>
      )}
    </ColunaNotas>
  )
}
