import styled from 'styled-components'

import { Cores } from '../../styles/cores'

export const Componente = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  ${({ theme: { ComErro } }) =>
    ComErro &&
    `
      span {
        opacity: 1;
        bottom: -20px;
      }
    `}
`

export const Erro = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  white-space: pre-line;
  opacity: 0;
  transition: opacity 0.2s ease-in, bottom 0.3s ease-in;
`
