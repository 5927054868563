import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { IconeDownload, IconeRevelarSenha } from 'src/componentes'
import { Api } from 'src/servicos'

import { ResumoContratos } from '../../tipos'
import {
  ModalVisualizacaoContrato,
  ModalVisualizacaoContratoRef
} from '../modal-visualizacao'
import { BotaoAcao, ColunaContratos, ContainerBotoes } from './styles'

interface AbaContratosProps {
  matriculaId: string
}

export const AbaContratos: React.FC<AbaContratosProps> = ({ matriculaId }) => {
  const [contratos, definirContratos] = useState<ResumoContratos>(null)
  const modalVisualizacaoRef = useRef<ModalVisualizacaoContratoRef>(null)

  const obterDownloadContratoAdendo = async (
    contratoMatriculaId: string
  ): Promise<void> => {
    try {
      await Api.ObterDownloadContratosAdendo(matriculaId, contratoMatriculaId)
    } catch (erro) {
      toast('Erro ao baixar contrato.', { type: 'error' })
    }
  }

  const obterDownloadContratoCurso = async (): Promise<void> => {
    try {
      await Api.ObterDownloadContratoMatricula(matriculaId)
    } catch (erro) {
      toast('Erro ao baixar contrato.', { type: 'error' })
    }
  }

  const carregarContratos = async () => {
    try {
      const contratosAdendo = await Api.ObterResumoContratosAdendo(matriculaId)
      definirContratos(contratosAdendo)
    } catch (error) {
      toast('Houve um problema ao obter os contratos.', {
        type: 'error'
      })
    }
  }

  const visualizarContratoAdendo = async (contratoMatriculaId: string) => {
    try {
      const contrato = await Api.ObterContratosAdendo(
        matriculaId,
        contratoMatriculaId
      )
      if (contrato) {
        modalVisualizacaoRef?.current?.abrir(contrato)
      }
    } catch (error) {
      toast('Houve um problema ao obter o contrato.', {
        type: 'error'
      })
    }
  }

  const visualizarContratoMatricula = async () => {
    try {
      const contrato = await Api.ObterContratoMatricula(matriculaId)
      if (contrato) {
        modalVisualizacaoRef?.current?.abrir(contrato)
      }
    } catch (error) {
      toast('Houve um problema ao obter o contrato.', {
        type: 'error'
      })
    }
  }

  useEffect(() => {
    carregarContratos()
  }, [])

  return (
    <ColunaContratos>
      <p className="titulo">Contrato</p>
      <ContainerBotoes>
        <p>Contrato da Matrícula</p>
        <BotaoAcao onClick={() => visualizarContratoMatricula()}>
          {IconeRevelarSenha}
        </BotaoAcao>
        <BotaoAcao onClick={() => obterDownloadContratoCurso()}>
          {IconeDownload}
        </BotaoAcao>
      </ContainerBotoes>
      <p className="titulo">Adendos</p>
      <li>
        {contratos &&
          contratos?.adendos?.map((contrato, index) => {
            return (
              <ul key={index}>
                <ContainerBotoes>
                  <p>{contrato.nome}</p>
                  <BotaoAcao
                    onClick={() => visualizarContratoAdendo(contrato.id)}
                  >
                    {IconeRevelarSenha}
                  </BotaoAcao>
                  <BotaoAcao
                    onClick={() => obterDownloadContratoAdendo(contrato.id)}
                  >
                    {IconeDownload}
                  </BotaoAcao>
                </ContainerBotoes>
              </ul>
            )
          })}
      </li>
      <ModalVisualizacaoContrato
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-contrato"
      />
    </ColunaContratos>
  )
}
