import { SelectOpcao } from 'src/componentes'
import {
  CursoSituacao,
  Disciplina,
  GradeCurricular,
  GradeCurricularSituacao,
  ModalidadeCurso,
  ModalidadeLivre,
  PlanoPagamentoParcelas,
  TCCSituacao,
  TipoAvaliacao,
  PlanoPagamentoProrrogacao,
  PlanoPagamentoReingresso
} from 'src/tipos'

import { Pergunta } from '.'

export enum TipoDeAvaliacao {
  AvaliacaoPorAquivo = 'AvaliacaoPorAquivo',
  AvaliacaoPorMultiplaEscolha = 'AvaliacaoPorMultiplaEscolha'
}

export enum TipoDeAvaliacaoPorExtenso {
  AvaliacaoPorAquivo = 'Avaliação por Arquivo',
  AvaliacaoPorMultiplaEscolha = 'Avaliação múltipla escolha'
}

export interface PesquisaCursosResposta {
  itens: PesquisaCursoDTO[]
  temProximaPagina: boolean
  quantidade: number
}

export interface PesquisaCursoDTO {
  id: string
  sigla: string
  nome: string
  descricao: string
  modalidade: string
  cargaHoraria: number
  situacao: string
  temGrupoDiscussao: boolean
}

export interface CursoPayload {
  id: string
  nome: string
  sigla: string
  modalidade: keyof typeof ModalidadeCurso
  cargaHoraria: number
  duracao: number
  tempoDisponibilidade: number
  situacao: keyof typeof CursoSituacao
  valorTaxaMatricula: number
  quantidadeMaximaParcelas: number
  informacoes: string
  tcc: keyof typeof TCCSituacao
  planoPagamento: PlanoPagamentoParcelas[]
  tipoAvaliacao: keyof typeof TipoDeAvaliacao
  gradesCurriculares: GradeCurricular[]
  contrato: File | string
  precoBaseTCC?: number
  quantidadeMaximaParcelasTCC?: number
  opcoesProrrogacao: PlanoPagamentoProrrogacao[]
  tempoMaximoProrrogacao?: number
  possuiAvaliacao?: boolean
  avaliacao: Avaliacao
  valorRecuperacao?: number
  tempoRecuperacao?: number
  quantidadeMaximaParcelasRecuperacao?: number
  planosPagamentoReingresso: PlanoPagamentoReingresso[]
}

export interface CursoDisicplinaDto {
  id: string
}

export interface GradeCurricularDto {
  modulos: CursoModuloDto[]
  nome: string
  tipoAvaliacao: keyof typeof TipoAvaliacao
  modalidade: keyof typeof ModalidadeCurso
  situacao?: keyof typeof GradeCurricularSituacao
  moduloTCC: CursoModuloTCCDto
}

export interface CursoModuloDto {
  id: string
  nome: string
  ordem: number
  tipoModulo?: string
  informacoesAdicionais?: string
  disciplinas: Disciplina[]
}

export interface CursoModuloTCCDto {
  id: string
  nome: string
  disciplinas: Disciplina[]
}

export interface CursoLivre {
  id: string
  nome: string
  sigla: string
  modalidade: keyof typeof ModalidadeCurso
  cargaHoraria: number
  duracao: number
  tempoDisponibilidade: number
  situacao: keyof typeof CursoSituacao
  tcc: keyof typeof TCCSituacao
  valorTaxaMatricula: number
  quantidadeMaximaParcelas: number
  informacoes: string
  informacoesIniciais: string
  versoCertificado: string
  avaliacao: Avaliacao
  disciplinas: Disciplina[]
  grupoDiscussao: GrupoDiscussao
  modalidadeLivre?: ModalidadeLivre
  idAreaCurso: string
  pesquisaSatisfacaoId: string
  dataInicio?: string
  possuiAvaliacao?: boolean
  sincronizarCrm?: boolean
}

export interface Avaliacao {
  id: string
  nome: string
  quantidadeTentativas: number
  descricao: string
  perguntas: Pergunta[]
}

export interface GrupoDiscussao {
  temGrupoDiscussao: boolean
  grupoDiscussaoDescricao: string
}

export interface PesquisaCursosFiltroResposta {
  id: string
  sigla: string
  nome: string
  descricao: string
}

export interface CursosRelatorio {
  modalidade: string
}
export interface SelectOpcaoCursos extends SelectOpcao {
  dadosAdicionais?: CursosRelatorio
}

export interface AreaCurso {
  id: string
  nome: string
  modalidadeCurso: string
}

export interface RequerimentoResponse {
  itens: any[]
}
