import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AutenticacaoContainer, ErroLayoutContainer } from 'src/componentes'
import { Api } from 'src/servicos'
import { ContratosDoAluno } from 'src/tipos'

import { TituloPrincipal } from './styles'
import { UploadContratoAluno } from './upload-contrato-aluno'

const ContratosAluno: React.FC = () => {
  const { usuario } = AutenticacaoContainer.useContainer()
  const [contratos, definirContratos] = useState<ContratosDoAluno>(
    {} as ContratosDoAluno
  )
  const [
    contratosMatricula,
    definirContratosMatricula
  ] = useState<ContratosDoAluno>({} as ContratosDoAluno)

  const ObterContratosAluno = async () => {
    try {
      const resposta = await Api.ObterContratosAluno(usuario.id)
      definirContratos(resposta)
    } catch (erro) {
      toast('Houve um erro ao carregar seus contratos. Tente novamente!')
    }
  }

  const ObterContratosAlunoMatricula = async () => {
    try {
      const resposta = await Api.ObterContratosAlunoMatricula(usuario.id)
      definirContratosMatricula(resposta)
    } catch (erro) {
      toast('Houve um erro ao carregar seus contratos. Tente novamente!')
    }
  }

  const ObterDados = async () => {
    await ObterContratosAluno()
    await ObterContratosAlunoMatricula()
  }

  useEffect(() => {
    ObterDados()
  }, [])

  return (
    <>
      <TituloPrincipal>Meus Contratos</TituloPrincipal>
      <UploadContratoAluno
        contratos={contratos?.contratosAluno?.matriculas}
        contratosMatricula={contratosMatricula?.contratosAluno?.matriculas}
        acaoEnvioAceito={ObterDados}
      />
    </>
  )
}

const Container: React.FC = () => (
  <ErroLayoutContainer.Provider>
    <ContratosAluno />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
