import React, { FC } from 'react'

import { useControladorDeConteudos } from 'src/compartilhados'
import { IconeBook, LinkRodape } from 'src/componentes'
import { TurmaProfessor } from 'src/tipos'

import { ControleDeTurma } from '../controle-de-turma'
import { TituloDecorado } from '../titulo-decorado'
import { Container, TextoAviso, TurmasProfessor } from './styles'

const configuracao = {
  disposicaoInicial: 4,
  passos: 4
}

export interface MinhasTurmasProps {
  turmas: TurmaProfessor[]
}

export const MinhasTurmas: FC<MinhasTurmasProps> = ({
  turmas
}: MinhasTurmasProps) => {
  const controlador = useControladorDeConteudos(turmas, configuracao)

  const botaoVerMais = () => {
    if (!controlador.concluido()) {
      const execucao = () => controlador.proximo()
      return <LinkRodape acao={execucao} texto="Ver mais" />
    }

    return null
  }

  return (
    <Container>
      {turmas && turmas.length > 0 ? (
        <>
          <TituloDecorado icone={IconeBook} texto="Minhas Turmas" />
          <TurmasProfessor>
            {turmas.map(turma => (
              <ControleDeTurma key={turma.id} turma={turma} />
            ))}
          </TurmasProfessor>
          {botaoVerMais()}
        </>
      ) : (
        <TextoAviso>Você ainda não tem turmas vinculadas!</TextoAviso>
      )}
    </Container>
  )
}
