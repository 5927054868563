import React, { useState, useEffect } from 'react'

import { IconeSetaDropDown } from '../../icones'
import StepperContainer from '../container'
import { StepperItem } from '../tipos'
import { Container, ContainerItem, SubItens } from './styles'
import { SubItem } from './subitem'

interface ItemProps {
  item: StepperItem
}

export const Item: React.FC<ItemProps> = ({ item }) => {
  const {
    passo,
    itensMarcados,
    definirPassoStepper,
    abrirModalNotificacaoMotivoBloqueio
  } = StepperContainer.useContainer()
  const [visibilidadeItens, definirVisibilidadeItens] = useState<string[]>([])

  const estaAberto = (idItem: string) => {
    const aberto = visibilidadeItens.some(a => a === idItem) ?? false

    return aberto
  }

  const temaSubItemComSubItens = (subItens: StepperItem[]) => {
    if (subItens) {
      const temSubItens = subItens.some(
        subItem => subItem.subItens !== null && subItem.subItens !== undefined
      )

      return temSubItens
    }
    return true
  }

  const removerItensFilhos = (
    item: StepperItem,
    itensSelecionados: string[]
  ) => {
    let novosItensSelecionados = itensSelecionados

    if (item.subItens) {
      item.subItens.forEach(subItem => {
        const indice = novosItensSelecionados.findIndex(
          val => val === subItem.id
        )
        if (indice > -1) {
          novosItensSelecionados.splice(indice, 1)
        }

        novosItensSelecionados = removerItensFilhos(
          subItem,
          novosItensSelecionados
        )
      })
    }

    return novosItensSelecionados
  }

  const manipularVisibilidadeItens = (item: StepperItem) => {
    let novo = [...visibilidadeItens]

    const indice = novo.findIndex(val => val === item.id)
    if (indice > -1) {
      novo.splice(indice, 1)
      novo = removerItensFilhos(item, novo)
    } else {
      novo.push(item.id)
    }

    definirVisibilidadeItens(novo)
  }

  const renderizarSubItens = (
    subItens: StepperItem[],
    idItem: string,
    tema: 'padrao' | 'secundario'
  ) => {
    if (subItens) {
      const aberto = estaAberto(idItem)
      return (
        <SubItens aberto={aberto} tema={tema}>
          {subItens.map(subItem => (
            <>
              <SubItem
                key={`${subItem.id}${itensMarcados.some(
                  i => i === subItem.id
                )}${subItem.bloqueado}`}
                subItem={subItem}
                aberto={estaAberto(subItem.id)}
                manipularVisibilidadeItens={manipularVisibilidadeItens}
              />
              {renderizarSubItens(subItem.subItens, subItem.id, 'secundario')}
            </>
          ))}
        </SubItens>
      )
    }
  }

  const abrirItensSubItens = (
    itemSelecionado: StepperItem,
    itensSelecionados: string[]
  ) => {
    if (itemSelecionado.subItens) {
      let novosItensSelecionados = []

      itemSelecionado.subItens.forEach(subItem => {
        if (subItem.subItens) {
          novosItensSelecionados = abrirItensSubItens(
            subItem,
            novosItensSelecionados
          )
        }

        if (subItem.passo && subItem.passo === passo) {
          const inserido = novosItensSelecionados.some(
            val => val === subItem.id
          )

          if (!inserido) {
            novosItensSelecionados.push(itemSelecionado.id)
          }
        }

        if (novosItensSelecionados.length > 0) {
          novosItensSelecionados.push(itemSelecionado.id)
        }
      })

      return novosItensSelecionados
    }

    return itensSelecionados
  }

  useEffect(() => {
    const novosItens = [
      ...visibilidadeItens,
      ...abrirItensSubItens(item, visibilidadeItens)
    ]

    const novaVisibilidadeItens = novosItens.filter(
      (este, i) => novosItens.indexOf(este) === i
    )

    definirVisibilidadeItens(novaVisibilidadeItens)
  }, [passo])

  return (
    <Container>
      <ContainerItem
        ativo={passo === item.passo}
        ehFlex={item?.ehModuloFlex}
        aberto={item.subItens ? estaAberto(item.id) : false}
        tipo={item.tipo}
        bloqueado={item.bloqueado}
        data-for={item.bloqueado ? 'tooltip-stepper' : undefined}
        data-tip={
          item.bloqueado && item.mensagemBloqueado
            ? item.mensagemBloqueado
            : undefined
        }
        onClick={e => {
          e.stopPropagation()

          if (item.bloqueado) {
            if (item.notificacaoModalBloqueado) {
              abrirModalNotificacaoMotivoBloqueio(
                item.notificacaoModalBloqueado
              )
            }
            return
          }

          if (item.passo) {
            definirPassoStepper(item.passo)
          }
          if (item.subItens) {
            manipularVisibilidadeItens(item)
          }
        }}
        ehFilho={!temaSubItemComSubItens(item.subItens)}
        temSubItens={!!item.subItens}
      >
        {item.icone && <div className="stepper-item-icone">{item.icone}</div>}
        <div className="stepper-item-titulo">
          <p>{item.titulo}</p>
          {item?.ehModuloFlex && <span>FLEX</span>}
        </div>
        {!!item.subItens && (
          <div className="stepper-item-icone">{IconeSetaDropDown}</div>
        )}
      </ContainerItem>
      {renderizarSubItens(
        item.subItens,
        item.id,
        temaSubItemComSubItens(item.subItens) ? 'padrao' : 'secundario'
      )}
    </Container>
  )
}
