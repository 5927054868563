import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Scope } from '@unform/core'
import { groupBy } from 'lodash'
import {
  AutenticacaoContainer,
  Botao,
  CalendarioUnform,
  Carregando,
  CheckboxUnform,
  Cores,
  FormRef,
  FormUnform,
  IconeInformacao,
  InputUnform,
  Modal,
  ModalRef,
  SelectAsyncUnform,
  SelectOpcao,
  SelectUnform,
  Spacer,
  SubTitulo,
  TextAreaUnform,
  Tooltip
} from 'src/componentes'
import { TipoDeAvaliacao } from 'src/dto'
import { ContainerPequeno } from 'src/paginas/matricula/styles'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  RequisitarListaTurmasPorTexto,
  RequisitarTurmaPorId
} from 'src/servicos/api'
import {
  TurmaAvaliacaoDisciplinaResposta,
  TurmaAvaliacaoModuloResposta
} from 'src/servicos/api/tipos'
import {
  Material,
  ModalidadeCursoPorExtenso,
  Professor,
  TipoAvaliacao,
  TipoPesquisaSatisfacao,
  TurmaSituacao,
  UsuarioSituacao
} from 'src/tipos'
import { v4 } from 'uuid'

import { AccordionDisciplina, Avaliacao, ListaMateriais } from '../'
import PaginaCadastroTurmaContainer from '../../../container'
import { schema } from '../../../schema'
import {
  ContainerAcoes,
  ContainerDados,
  ContainerDadosCheckboxReingresso,
  ContainerDadosGrande,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  ContainerDadosReingresso,
  ContainerDadosSelectReingresso,
  ContainerTextArea,
  LabelPesquisaSatisfacaoProfessores,
  Secao
} from '../../../styles'
import {
  FormularioTurma,
  MaterialTurma,
  Navegacoes,
  TipoMaterialComplementar
} from '../../../tipos'
import { Endereco } from '../../presencial'
import { AccordionMaterialComplementar } from '../accordion-material-complementar'
import {
  turmaEadAvaliacaoDisciplinaMapper,
  turmaEadAvaliacaoModuloMapper,
  turmaPresencialAvaliacaoDisciplinaMapper,
  turmaPresencialAvaliacaoModuloMapper
} from './core'

interface DisciplinaPossuiProfessor {
  idDisciplina: string
  possuiProfessor: boolean
}

export const Formulario: React.FC = () => {
  const {
    cursoId,
    turma,
    perguntas,
    materiais,
    encontros,
    ehModoCadastro,
    ehModalidadeEad,
    ehModalidadePosGraduacaoEad,
    bloquearCampo,
    materiaisAtualizados,
    definirTurma,
    definirMateriais,
    definirCursoId,
    definirEncontros,
    definirPerguntas,
    definirDisciplinaComplementar,
    disciplinasComplementares
  } = PaginaCadastroTurmaContainer.useContainer()

  const primeiroRender = useRef(!ehModoCadastro)
  const modalRef = useRef<ModalRef>(null)
  const formRef = useRef<FormRef>(null)
  const history = useHistory()

  const [carregando, definirCarregando] = useState(false)
  const [carregandoGrade, definirCarregandoGrade] = useState(false)
  const [gradeSelecionada, definirGradeSelecionada] = useState<SelectOpcao[]>(
    null
  )
  const { visao } = AutenticacaoContainer.useContainer()
  const [professores, definirProfessores] = useState<Professor[]>([])
  const visaoProfessor = 2

  const [erroModuloTCC, definirErroModuloTCC] = useState(false)
  const [disciplinasProfessores, definirDisciplinasProfessores] = useState<
    DisciplinaPossuiProfessor[]
  >([])
  const [possuiReingresso, definirPossuiReingresso] = useState(false)

  const todasDisciplinaPossuemProfessor = useMemo(() => {
    return disciplinasProfessores.every(x => x.possuiProfessor)
  }, [disciplinasProfessores])

  const dadosIniciais = useMemo(() => {
    if (!ehModoCadastro && !!turma) {
      definirPossuiReingresso(!!turma?.turmaReingressoId)
      const disciplinasComp = {}
      turma.disciplinasComplementares?.forEach(x => {
        disciplinasComp[`nomePersonalizado${x.tipo}`] = x.nome
      })

      const resultado = {
        nome: turma.nome,
        codigo: turma.codigo,
        situacaoTurma: turma.situacaoTurma,
        dataInicio: turma.dataInicio,
        dataFim: turma.dataFim,
        dataInicioInscricao: turma.dataInicioInscricao,
        dataFimInscricao: turma.dataFimInscricao,
        cursoId,
        gradeCurricularId: turma.gradeCurricularId,
        grupoDiscussao: turma.grupoDiscussao,
        professorId: turma.tutorId,
        sincronizarCrm: turma.sincronizarCrm,
        turmaSoCertificacao: turma.turmaSoCertificacao,
        turmaSplit: turma.turmaSplit,
        modulo: turma.modulos.map(m => ({
          id: m.moduloId,
          dataLiberacao: m.dataLiberacao,
          disciplina: m.disciplinas.map(d => ({
            id: d.disciplinaId,
            professoresIds:
              d.professores?.length > 0
                ? d.professores.map(item => item.professorId)
                : null,
            avaliacao: d.avaliacao
              ? {
                  temAvaliacao: true,
                  adicionarAvaliacao: true,
                  id: d.avaliacao.id,
                  nome: d.avaliacao.nome,
                  quantidadeTentativas: d.avaliacao.quantidadeTentativas,
                  descricao: d.avaliacao.descricao,
                  dataLiberacaoAvaliacao: d.avaliacao.dataLiberacao,
                  dataRealizacaoAvaliacao: d.avaliacao.dataLimite,
                  tipo: d.avaliacao.tipoAvaliacaoTurma,
                  arquivo:
                    d.avaliacao.tipoAvaliacaoTurma ===
                    TipoDeAvaliacao.AvaliacaoPorAquivo
                      ? d.avaliacao.chaveArquivo
                      : null
                }
              : {
                  temAvaliacao: d.possuiAvaliacao
                }
          })),
          avaliacao: m.avaliacao
            ? {
                temAvaliacao: true,
                adicionarAvaliacao: true,
                id: m.avaliacao.id,
                nome: m.avaliacao.nome,
                quantidadeTentativas: m.avaliacao.quantidadeTentativas,
                descricao: m.avaliacao.descricao,
                dataLiberacaoAvaliacao: m.avaliacao.dataLiberacao,
                dataRealizacaoAvaliacao: m.avaliacao.dataLimite,
                tipo: m.avaliacao.tipoAvaliacaoTurma,
                arquivo:
                  m.avaliacao.tipoAvaliacaoTurma ===
                  TipoDeAvaliacao.AvaliacaoPorAquivo
                    ? m.avaliacao.chaveArquivo
                    : undefined
              }
            : {
                temAvaliacao: m.possuiAvaliacao
              }
        })),
        localizacao: turma.endereco,
        moduloTCC: turma.moduloTCC
          ? {
              id: turma.moduloTCC.moduloId,
              dataLiberacao: turma.moduloTCC.dataLiberacao,
              disciplina: turma.moduloTCC.disciplinas?.map(d => ({
                id: d.disciplinaId,
                professoresOrientadoresIds:
                  d.professoresOrientador?.length > 0
                    ? d.professoresOrientador.map(item => item.professorId)
                    : null,
                professoresBancaIds:
                  d.professoresBanca?.length > 0
                    ? d.professoresBanca.map(item => item.professorId)
                    : null,
                avaliacao: d.avaliacao
                  ? {
                      temAvaliacao: true,
                      id: d.avaliacao.id,
                      nome: d.avaliacao.nome,
                      quantidadeTentativas: d.avaliacao.quantidadeTentativas,
                      descricao: d.avaliacao.descricao,
                      dataLiberacaoAvaliacao: d.avaliacao.dataLiberacao,
                      dataRealizacaoAvaliacao: d.avaliacao.dataLimite,
                      tipo: d.avaliacao.tipoAvaliacaoTurma,
                      arquivo:
                        d.avaliacao.tipoAvaliacaoTurma ===
                        TipoDeAvaliacao.AvaliacaoPorAquivo
                          ? d.avaliacao.chaveArquivo
                          : null
                    }
                  : undefined
              }))
            }
          : null,
        pesquisaSatisfacaoId: turma.pesquisaSatisfacaoId,
        pesquisaSatisfacaoProfessoresId: turma.pesquisaSatisfacaoProfessoresId,
        materiaisComplementares: {
          ...turma.materiaisComplementares,
          ...disciplinasComp
        },
        turmaReingressoId: turma.turmaReingressoId,
        possuiReingresso: !!turma.turmaReingressoId
      }

      return resultado
    }
  }, [turma, ehModoCadastro])

  const obterProfessores = async () => {
    try {
      const resposta = await Api.ObterTodosProfessores()

      definirProfessores(resposta)
    } catch (error) {
      return null
    }
  }

  const atualizarValorFormulario = (campo: string, valor: any) => {
    if (!valor) return
    formRef.current?.setFieldError(campo, null)
    formRef.current?.setFieldValue(campo, valor)
  }

  const limpar = () => {
    definirMateriais([])
    definirEncontros([])
    definirPerguntas([])

    definirTurma({
      ...turma,
      gradeCurricularId: '',
      nomeGrade: '',
      tipoAvaliacaoCurso: null,
      modalidadeCurso: null,
      modulos: [],
      moduloTCC: null
    })
  }

  const carregarCursoPorTexto = async (
    texto?: string
  ): Promise<SelectOpcao[]> => {
    try {
      const dado = await Api.RequisitarListaCursoOutros(texto)

      return dado.map(d => ({ id: d.id, texto: d.nome, dadosAdicionais: d }))
    } catch {
      return []
    }
  }

  const carregarPesquisaSatisfacaoTexto = async (
    tipo: TipoPesquisaSatisfacao,
    texto?: string
  ): Promise<SelectOpcao[]> => {
    try {
      const dados = await Api.RequisitarListaPesquisaSatisfacaoCodigo(
        turma.modalidadeCurso,
        tipo,
        texto
      )

      return dados?.map(d => ({
        id: d.id,
        texto: d.codigo,
        dadosAdicionais: d
      }))
    } catch {
      return []
    }
  }

  const carregarPesquisaSatisfacaoCursoTexto = async (
    texto?: string
  ): Promise<SelectOpcao[]> =>
    carregarPesquisaSatisfacaoTexto(TipoPesquisaSatisfacao.Curso, texto)

  const carregarPesquisaSatisfacaoProfessoresTexto = async (
    texto?: string
  ): Promise<SelectOpcao[]> =>
    carregarPesquisaSatisfacaoTexto(TipoPesquisaSatisfacao.Professores, texto)

  const carregarPesquisaSatisfacaoPorId = async (
    id: string
  ): Promise<SelectOpcao[]> => {
    try {
      const dados = await Api.RequisitarPesquisaSatisfacao(id)

      return [{ id: dados.id, texto: dados.codigo }]
    } catch {
      return []
    }
  }

  const carregarCursoPorId = async (id: string): Promise<SelectOpcao[]> => {
    try {
      const dado = await Api.RequisitarListaCursoOutrosPorId(id)

      return [{ id: dado.id, texto: dado.nome }]
    } catch {
      return []
    }
  }

  const carregarTurmaPorId = async (id: string): Promise<SelectOpcao[]> => {
    try {
      const dados = await RequisitarTurmaPorId(id)
      return [{ id: dados.id, texto: dados.nome }]
    } catch {
      return []
    }
  }

  const carregarGradePorId = async (idGrade: string) => {
    try {
      definirCarregandoGrade(true)
      const novaGrade = await Api.RequisitarGradePorId(idGrade)

      const camposParaAtualizar: { campo: string; valor: string }[] = []

      const novosModulos = novaGrade.modulos
        .sort((a, b) => a.ordem - b.ordem)
        .map((m, indiceM) => {
          const moduloId = ehModoCadastro ? v4() : m.id

          camposParaAtualizar.push({
            campo: `modulo[${indiceM}].id`,
            valor: moduloId
          })

          const novasDisciplinas = m.disciplinas.map((d, indiceD) => {
            camposParaAtualizar.push({
              campo: `modulo[${indiceM}].disciplina[${indiceD}].id`,
              valor: d.id
            })

            return {
              disciplinaId: d.id,
              nome: d.nome,
              cargaHoraria: d.cargaHoraria,
              materiais: [],
              tutorDisciplina: false
            }
          })

          return {
            moduloId,
            nome: m.nome,
            ordem: m.ordem,
            tipoModulo: m.tipoModulo,
            informacoesAdicionais: m.informacoesAdicionais,
            disciplinas: novasDisciplinas
          }
        })

      let novoModuloTCC = null
      if (novaGrade.moduloTCC) {
        const moduloTCCId = ehModoCadastro ? v4() : novaGrade.moduloTCC?.id
        camposParaAtualizar.push({
          campo: 'moduloTCC.id',
          valor: moduloTCCId
        })

        novoModuloTCC = {
          moduloId: moduloTCCId,
          nome: novaGrade.moduloTCC?.nome,
          disciplinas: novaGrade.moduloTCC?.disciplinas.map((d, index) => {
            camposParaAtualizar.push({
              campo: `moduloTCC.disciplina[${index}].id`,
              valor: d.id
            })

            return {
              disciplinaId: d.id,
              nome: d.nome,
              cargaHoraria: d.cargaHoraria,
              materiais: [],
              tutorDisciplina: false
            }
          })
        }
      }

      const novaTurma = {
        ...turma,
        gradeCurricularId: idGrade,
        nomeGrade: novaGrade.nome,
        tipoAvaliacaoCurso: novaGrade.tipoAvaliacao,
        modalidadeCurso: novaGrade.modalidade,
        modulos: novosModulos,
        moduloTCC: novoModuloTCC
      }
      definirTurma(novaTurma)

      setTimeout(
        () =>
          camposParaAtualizar.forEach(c =>
            atualizarValorFormulario(c.campo, c.valor)
          ),
        1000
      )
    } catch {
      toast('Houve um problema ao obter os dados da grade', {
        type: 'error'
      })
    } finally {
      definirCarregandoGrade(false)
    }
  }

  const carregarGradePorIdCurso = async (
    idCurso: string
  ): Promise<SelectOpcao[]> => {
    try {
      const dado = await Api.RequisitarGradePorIdCurso(idCurso)

      return dado.map(r => ({
        id: r.id,
        texto: r.nome,
        dadosAdicionais: r
      }))
    } catch {
      return []
    }
  }

  const carregarGradeSelect = async (
    idGrade: string
  ): Promise<SelectOpcao[]> => {
    try {
      const dado = await Api.RequisitarGradePorId(idGrade)
      let curso = await carregarCursoPorId(cursoId)
      curso = curso.map(d => ({ id: d.id, texto: d.texto, dadosAdicionais: d }))

      return [
        {
          id: idGrade,
          texto: `${curso[0].texto} - ${dado.nome}`
        }
      ]
    } catch {
      return []
    }
  }

  const carregarGrade = async (idCurso: string) => {
    const dado = await carregarGradePorIdCurso(idCurso)
    const idGrade = dado[0].id

    if (!primeiroRender.current) {
      await carregarGradePorId(idGrade)
    } else {
      primeiroRender.current = true
    }

    definirGradeSelecionada(dado)
    atualizarValorFormulario('gradeCurricularId', idGrade)
  }

  const carregarTutores = async () => {
    try {
      const professores = await Api.ObterListaProfessores({
        situacao: UsuarioSituacao.Ativo
      })

      return professores.registros.map(r => ({ id: r.id, texto: r.nome }))
    } catch {
      return []
    }
  }

  const carregarTutorPorId = async (idProfessor: string) => {
    try {
      const professor = await Api.ObterProfessor(idProfessor)

      return [{ id: professor.id, texto: professor.nome }]
    } catch {
      return []
    }
  }

  const acaoSucesso = async (dados: FormularioTurma) => {
    try {
      definirCarregando(true)

      const avaliacaoPorModulo =
        turma.tipoAvaliacaoCurso ===
        TipoAvaliacao[TipoAvaliacao.AvaliacaoPorModulo]

      let idTurma = turma.id
      const arquivosAvaliacoes: {
        idAvaliacao: string
        arquivo: File | string
      }[] = []

      if (avaliacaoPorModulo) {
        for (const moduloAtual of dados.modulo) {
          if (
            moduloAtual.avaliacao?.adicionarAvaliacao &&
            moduloAtual.avaliacao?.tipo ===
              TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha &&
            perguntas.filter(p => p.idReferencia === moduloAtual.id).length < 1
          ) {
            toast(
              `Inclua pelo menos uma pergunta na avaliação "${moduloAtual.avaliacao?.nome}"`,
              {
                type: 'error'
              }
            )
            definirCarregando(false)
            return
          }
        }

        let resposta: TurmaAvaliacaoModuloResposta = null

        if (ehModalidadeEad) {
          const payload = turmaEadAvaliacaoModuloMapper(
            turma,
            perguntas,
            dados,
            todasDisciplinaPossuemProfessor,
            disciplinasComplementares
          )

          if (ehModoCadastro) {
            resposta = await Api.CriarTurmaEadAvaliacaoModulo(payload)

            idTurma = resposta.turmaId
          } else {
            resposta = await Api.EditarTurmaEadAvaliacaoModulo(idTurma, {
              ...payload,
              situacaoTurma: dados.situacaoTurma
            })
          }
        } else {
          const payload = turmaPresencialAvaliacaoModuloMapper(
            turma,
            perguntas,
            encontros,
            dados,
            todasDisciplinaPossuemProfessor,
            disciplinasComplementares
          )

          if (ehModoCadastro) {
            resposta = await Api.CriarTurmaPresencialAvaliacaoModulo(payload)

            idTurma = resposta.turmaId
          } else {
            resposta = await Api.EditarTurmaPresencialAvaliacaoModulo(idTurma, {
              ...payload,
              situacaoTurma: dados.situacaoTurma
            })
          }
        }

        if (resposta) {
          const respostaModulos = groupBy(
            resposta.modulos,
            m => m.moduloTurmaId
          )

          Object.keys(respostaModulos).forEach(m => {
            respostaModulos[m].forEach(r => {
              const indiceModulo = dados.modulo.findIndex(
                x => x.id === r.moduloTurmaId
              )

              if (
                indiceModulo > -1 &&
                dados.modulo[indiceModulo].avaliacao?.adicionarAvaliacao &&
                dados.modulo[indiceModulo].avaliacao?.tipo ===
                  TipoDeAvaliacao.AvaliacaoPorAquivo
              ) {
                arquivosAvaliacoes.push({
                  idAvaliacao: r.avaliacaoId,
                  arquivo: dados.modulo[indiceModulo].avaliacao.arquivo
                })
              }
            })
          })

          if (resposta.modulosTCC) {
            for (const r of resposta.modulosTCC) {
              const disciplinaEdicao = dados.moduloTCC.disciplina.find(
                d => d.id === r.disciplinaId
              )

              if (
                disciplinaEdicao &&
                disciplinaEdicao.avaliacao?.adicionarAvaliacao &&
                disciplinaEdicao.avaliacao?.arquivo
              ) {
                arquivosAvaliacoes.push({
                  idAvaliacao: r.avaliacaoId,
                  arquivo: disciplinaEdicao.avaliacao.arquivo
                })
              }
            }
          }
        }
      } else {
        for (const moduloAtual of dados.modulo) {
          for (const disciplinaAtual of moduloAtual.disciplina) {
            if (
              disciplinaAtual.avaliacao?.adicionarAvaliacao &&
              disciplinaAtual.avaliacao?.tipo ===
                TipoDeAvaliacao.AvaliacaoPorMultiplaEscolha &&
              perguntas.filter(p => p.idReferencia === disciplinaAtual.id)
                .length < 1
            ) {
              toast(
                `Inclua pelo menos uma pergunta na avaliação "${disciplinaAtual.avaliacao?.nome}"`,
                {
                  type: 'error'
                }
              )
              definirCarregando(false)
              return
            }
          }
        }

        let resposta: TurmaAvaliacaoDisciplinaResposta = null

        if (ehModalidadeEad) {
          const payload = turmaEadAvaliacaoDisciplinaMapper(
            turma,
            perguntas,
            dados,
            todasDisciplinaPossuemProfessor,
            disciplinasComplementares
          )

          if (ehModoCadastro) {
            resposta = await Api.CriarTurmaEadAvaliacaoDisciplina(payload)
            idTurma = resposta.turmaId
          } else {
            resposta = await Api.EditarTurmaEadAvaliacaoDisciplina(idTurma, {
              ...payload,
              situacaoTurma: dados.situacaoTurma
            })
          }
        } else {
          const payload = turmaPresencialAvaliacaoDisciplinaMapper(
            turma,
            perguntas,
            encontros,
            dados,
            todasDisciplinaPossuemProfessor,
            disciplinasComplementares
          )

          if (ehModoCadastro) {
            resposta = await Api.CriarTurmaPresencialAvaliacaoDisciplina(
              payload
            )
            idTurma = resposta.turmaId
          } else {
            resposta = await Api.EditarTurmaPresencialAvaliacaoDisciplina(
              idTurma,
              {
                ...payload,
                situacaoTurma: dados.situacaoTurma
              }
            )
          }
        }

        if (resposta) {
          const respostaDisciplinas = groupBy(
            resposta.modulos,
            m => m.disciplinaId
          )

          Object.keys(respostaDisciplinas).forEach(m => {
            respostaDisciplinas[m].forEach(r => {
              const indiceModulo = dados.modulo.findIndex(
                x => x.id === r.moduloTurmaId
              )

              if (indiceModulo > -1) {
                const disciplina = dados.modulo[indiceModulo].disciplina.find(
                  d => d.id === r.disciplinaId
                )

                if (
                  disciplina &&
                  disciplina.avaliacao?.adicionarAvaliacao &&
                  disciplina.avaliacao?.tipo ===
                    TipoDeAvaliacao.AvaliacaoPorAquivo
                ) {
                  arquivosAvaliacoes.push({
                    idAvaliacao: r.avaliacaoId,
                    arquivo: disciplina.avaliacao.arquivo
                  })
                }
              }
            })
          })

          if (resposta.modulosTCC) {
            for (const r of resposta.modulosTCC) {
              const disciplinaEdicao = dados.moduloTCC.disciplina.find(
                d => d.id === r.disciplinaId
              )

              if (
                disciplinaEdicao &&
                disciplinaEdicao.avaliacao?.adicionarAvaliacao &&
                disciplinaEdicao.avaliacao?.arquivo
              ) {
                arquivosAvaliacoes.push({
                  idAvaliacao: r.avaliacaoId,
                  arquivo: disciplinaEdicao.avaliacao.arquivo
                })
              }
            }
          }
        }
      }

      if (arquivosAvaliacoes.length > 0) {
        for (const arquivoAvaliacao of arquivosAvaliacoes) {
          if (typeof arquivoAvaliacao.arquivo === 'object') {
            const bodyFormData = new FormData()
            bodyFormData.append('arquivo', arquivoAvaliacao.arquivo)

            await Api.CriarAvaliacaoArquivoTurma(
              idTurma,
              arquivoAvaliacao.idAvaliacao,
              bodyFormData
            )
          }
        }
      }

      const materiaisDisciplina: {
        idModulo: string
        idDisciplina: string
        materiais: MaterialTurma[]
      }[] = []

      turma.modulos.forEach(m => {
        m.disciplinas.forEach(d => {
          const materiaisSelecionados = materiais.filter(
            mat => mat.idDisciplina === d.disciplinaId
          )

          if (ehModoCadastro) {
            if (materiaisSelecionados.length > 0) {
              materiaisDisciplina.push({
                idModulo: m.moduloId,
                idDisciplina: d.disciplinaId,
                materiais: materiaisSelecionados
              })
            }
          } else {
            materiaisDisciplina.push({
              idModulo: m.moduloId,
              idDisciplina: d.disciplinaId,
              materiais:
                materiaisSelecionados.length > 0 ? materiaisSelecionados : []
            })
          }
        })
      })

      const materiaisDisciplinaTCC: {
        idModulo: string
        idDisciplina: string
        materiais: MaterialTurma[]
      }[] = []

      turma.moduloTCC?.disciplinas?.forEach(d => {
        const materiaisSelecionados = materiais.filter(
          mat => mat.idDisciplina === d.disciplinaId
        )

        if (ehModoCadastro) {
          if (materiaisSelecionados.length > 0) {
            materiaisDisciplinaTCC.push({
              idModulo: turma.moduloTCC.moduloId,
              idDisciplina: d.disciplinaId,
              materiais: materiaisSelecionados
            })
          }
        } else {
          materiaisDisciplinaTCC.push({
            idModulo: turma.moduloTCC.moduloId,
            idDisciplina: d.disciplinaId,
            materiais:
              materiaisSelecionados.length > 0 ? materiaisSelecionados : []
          })
        }
      })

      if (materiaisDisciplina.length > 0) {
        if (ehModoCadastro) {
          for (const materialDisciplina of materiaisDisciplina) {
            await Api.CriarMaterialDisciplinaTurma(
              idTurma,
              materialDisciplina.idModulo,
              materialDisciplina.idDisciplina,
              materialDisciplina.materiais as Material[]
            )
          }
        } else {
          for (const materialDisciplina of materiaisDisciplina) {
            if (
              materiaisAtualizados.some(
                x => x.idDisciplina === materialDisciplina.idDisciplina
              )
            ) {
              await Api.EditarMaterialDisciplinaTurma(
                idTurma,
                materialDisciplina.idModulo,
                materialDisciplina.idDisciplina,
                materialDisciplina.materiais as Material[]
              )
            }
          }
        }
      }

      if (materiaisDisciplinaTCC.length > 0) {
        if (ehModoCadastro) {
          for (const materialDisciplina of materiaisDisciplinaTCC) {
            await Api.CriarMaterialDisciplinaTCCTurma(
              idTurma,
              materialDisciplina.idModulo,
              materialDisciplina.idDisciplina,
              materialDisciplina.materiais as Material[]
            )
          }
        } else {
          for (const materialDisciplina of materiaisDisciplinaTCC) {
            if (
              materiaisAtualizados.some(
                x => x.idDisciplina === materialDisciplina.idDisciplina
              )
            ) {
              await Api.EditarMaterialDisciplinaTCCTurma(
                idTurma,
                materialDisciplina.idModulo,
                materialDisciplina.idDisciplina,
                materialDisciplina.materiais as Material[]
              )
            }
          }
        }
      }

      const materiaisTurma = materiais.filter(
        m => !m.idDisciplina && !m.tipoMaterialComplementar
      ) as Material[]

      if (ehModoCadastro) {
        if (materiaisTurma.length > 0) {
          await Api.CriarMaterialTurma(idTurma, materiaisTurma)
        }
      } else {
        if (
          materiaisAtualizados.some(
            x => !x.idDisciplina && !x.tipoMaterialComplementar
          )
        ) {
          await Api.EditarMaterialTurma(idTurma, materiaisTurma)
        }
      }

      const materiaisComplementares = materiais.filter(
        m => m.tipoMaterialComplementar
      ) as Material[]

      if (materiaisComplementares?.length > 0) {
        if (ehModoCadastro) {
          await Api.CriarMaterialComplementar(idTurma, materiaisComplementares)
        } else {
          if (materiaisAtualizados.some(x => x.tipoMaterialComplementar)) {
            await Api.EditarMaterialComplementar(
              idTurma,
              materiaisComplementares
            )
          }
        }
      }

      toast(`Turma ${ehModoCadastro ? 'cadastrada' : 'editada'} com sucesso`, {
        type: 'success'
      })

      history.push(RotasAcademico.Turmas)
    } finally {
      definirCarregando(false)
    }
  }

  const focarPrimeiroCampoComErroEdicaoTurma = (idContexto?: string): void => {
    setTimeout(() => {
      const campo = document
        .querySelectorAll(
          `${
            idContexto && typeof idContexto === 'string'
              ? `#${idContexto} `
              : ''
          }.error`
        )
        .item(0) as HTMLElement

      const accordion = campo?.closest('#accordion-conteudo') as HTMLElement

      if (accordion) {
        definirErroModuloTCC(true)

        setTimeout(() => {
          accordion.scrollIntoView({ block: 'start', behavior: 'smooth' })
          accordion.focus()
        }, 500)
      }

      if (campo) {
        campo.scrollIntoView({ block: 'start', behavior: 'smooth' })
        campo.focus()
      }
    }, 0)
  }

  const deveExpandirAccordionTCC = useCallback(() => {
    const deveExpandir = erroModuloTCC
    definirErroModuloTCC(false)
    return deveExpandir
  }, [erroModuloTCC])

  const disciplinaPossuiProfessores = (
    idDisciplina: string,
    possuiProfessor: boolean
  ) =>
    definirDisciplinasProfessores(old => {
      const filtrado = old.filter(x => x.idDisciplina !== idDisciplina)
      return [
        ...filtrado,
        {
          idDisciplina,
          possuiProfessor
        }
      ]
    })

  useEffect(() => {
    obterProfessores()
  }, [])

  return (
    <FormUnform
      ref={formRef}
      schema={schema(turma.modalidadeCurso, turma.tipoAvaliacaoCurso)}
      dadosIniciais={dadosIniciais}
      acaoSucesso={acaoSucesso}
      acaoFalha={focarPrimeiroCampoComErroEdicaoTurma}
    >
      <Secao id={Navegacoes.DadosTurma}>
        <SubTitulo texto="Dados da Turma" />
        <InputUnform
          name="nome"
          label="Nome da Turma"
          obrigatorio
          maxLength={200}
          disabled={visao === visaoProfessor}
        />
        <ContainerDados>
          <ContainerDadosMedio>
            <InputUnform
              name="codigo"
              label="Código da Turma"
              obrigatorio
              maxLength={100}
              disabled={
                (!ehModoCadastro &&
                  turma.situacaoTurma !== TurmaSituacao.Aberta &&
                  turma.situacaoTurma !== TurmaSituacao.AguardandoInicio) ||
                bloquearCampo ||
                visao === visaoProfessor
              }
            />
          </ContainerDadosMedio>
          {!ehModoCadastro && (
            <ContainerDadosMedio>
              <SelectUnform
                className="sm"
                id="situacao"
                name="situacaoTurma"
                label="Situação"
                placeholder="Selecione"
                iconeFechar={visao !== visaoProfessor}
                obrigatorio
                opcoes={[
                  {
                    id: TurmaSituacao[TurmaSituacao.Aberta],
                    texto: 'Aberta'
                  },
                  {
                    id: TurmaSituacao[TurmaSituacao.AguardandoInicio],
                    texto: 'Aguardando Início'
                  },
                  {
                    id: TurmaSituacao[TurmaSituacao.EmAndamento],
                    texto: 'Em Andamento'
                  },
                  {
                    id: TurmaSituacao[TurmaSituacao.Cancelada],
                    texto: 'Cancelada'
                  },
                  {
                    id: TurmaSituacao[TurmaSituacao.Finalizada],
                    texto: 'Finalizada'
                  }
                ]}
                disabled={bloquearCampo || visao === visaoProfessor}
              />
            </ContainerDadosMedio>
          )}
        </ContainerDados>
        <ContainerDados>
          <ContainerDadosPequeno>
            <CalendarioUnform
              className="sm"
              id="ipt_data_inicio"
              name="dataInicio"
              label="Data de Início da Turma"
              obrigatorio
              disabled={bloquearCampo || visao === visaoProfessor}
            />
          </ContainerDadosPequeno>
          <ContainerDadosPequeno>
            <CalendarioUnform
              className="sm"
              id="ipt_data_fim"
              name="dataFim"
              label="Data de Fim da Turma"
              obrigatorio={ehModalidadePosGraduacaoEad}
              disabled={bloquearCampo || visao === visaoProfessor}
            />
          </ContainerDadosPequeno>
          <ContainerDadosPequeno>
            <CalendarioUnform
              className="sm"
              id="ipt_data_inicio_inscricao"
              name="dataInicioInscricao"
              label="Data de Início da Inscrição"
              disabled={bloquearCampo || visao === visaoProfessor}
              obrigatorio
            />
          </ContainerDadosPequeno>
          <ContainerDadosPequeno>
            <CalendarioUnform
              className="sm"
              id="ipt_data_fim_inscricao"
              name="dataFimInscricao"
              label="Data de Fim da Inscrição"
              disabled={bloquearCampo || visao === visaoProfessor}
              obrigatorio
            />
          </ContainerDadosPequeno>
        </ContainerDados>
        <ContainerDadosReingresso>
          <ContainerDadosCheckboxReingresso>
            <CheckboxUnform
              id={'ipt_possui_reingresso_turma'}
              name="possuiReingresso"
              texto="Possui reingresso?"
              onChange={() => definirPossuiReingresso(old => !old)}
              checked={possuiReingresso}
            />
          </ContainerDadosCheckboxReingresso>
          {possuiReingresso && (
            <ContainerDadosSelectReingresso>
              <SelectAsyncUnform
                id="slct_turma_reingresso"
                name="turmaReingressoId"
                label="Turma de Reingresso"
                placeholder="Selecione"
                obrigatorio
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={carregarTurmaPorId}
              />
            </ContainerDadosSelectReingresso>
          )}
        </ContainerDadosReingresso>
        <Spacer padding="5px 0px" />
        <ContainerDados>
          <CheckboxUnform
            id="chk-sincronizar-rubeus"
            name="sincronizarCrm"
            tamanho="L"
            texto={'Sincronizar RD'}
          />
        </ContainerDados>
        <Spacer padding="5px 0px" />
        <ContainerDados>
          <CheckboxUnform
            id={'ipt_turma_certificacao'}
            name="turmaSoCertificacao"
            tamanho="L"
            texto="Turma certificadora"
          />
        </ContainerDados>
        <Spacer padding="5px 0px" />
        <ContainerDados>
          <CheckboxUnform
            id={'ipt_turma_split'}
            name="turmaSplit"
            tamanho="L"
            texto="Turma Split"
          />
        </ContainerDados>
      </Secao>
      <Secao id={Navegacoes.Curso}>
        <SubTitulo texto="Curso" />
        <SelectAsyncUnform
          className="sm"
          id="slct_curso"
          name="cursoId"
          label="Curso"
          placeholder="Selecione"
          obrigatorio
          buscarPorTexto={carregarCursoPorTexto}
          buscarPorId={carregarCursoPorId}
          valorAlterado={selected => {
            if (!primeiroRender.current) limpar()

            if (selected !== null) {
              definirCursoId(selected.id)
              carregarGrade(selected.id)
              return
            }

            definirCursoId(null)
          }}
          disabled={!ehModoCadastro || bloquearCampo}
        />
        {!carregandoGrade ? (
          <>
            <ContainerDadosGrande style={{ marginTop: '25px' }}>
              <SelectAsyncUnform
                className="sm"
                id="slct_grade"
                name="gradeCurricularId"
                label="Grade Curricular"
                placeholder="Selecione"
                obrigatorio
                disabled={true}
                buscarPorTexto={async () =>
                  gradeSelecionada ?? ([] as SelectOpcao[])
                }
                buscarPorId={() => {
                  if (!ehModoCadastro) {
                    return carregarGradeSelect(turma.gradeCurricularId)
                  } else {
                    return carregarGradePorIdCurso(cursoId)
                  }
                }}
                key={cursoId}
              />
            </ContainerDadosGrande>
            {turma.modalidadeCurso && ehModalidadeEad && (
              <ContainerDadosMedio style={{ marginTop: '25px' }}>
                <SelectAsyncUnform
                  id="slct_tutor"
                  name="professorId"
                  label="Tutor"
                  buscarPorTexto={carregarTutores}
                  buscarPorId={carregarTutorPorId}
                  disabled={bloquearCampo || visao === visaoProfessor}
                />
              </ContainerDadosMedio>
            )}
            <>
              {turma.moduloTCC && (
                <Scope key={`${turma.moduloTCC.moduloId}`} path={'moduloTCC'}>
                  <Spacer padding="30px 0px 0px" />
                  <SubTitulo texto={turma.moduloTCC.nome} />

                  <InputUnform name="id" esconder />

                  <ContainerDados>
                    <ContainerPequeno>
                      <CalendarioUnform
                        label={
                          ehModalidadeEad
                            ? 'Data de Liberação do Material'
                            : 'Data de Liberação do Módulo'
                        }
                        className="sm"
                        name="dataLiberacao"
                        id={`inp_data_liberacao_${turma.moduloTCC.moduloId}`}
                        disabled={bloquearCampo}
                      />
                    </ContainerPequeno>
                  </ContainerDados>
                  {turma.moduloTCC.disciplinas.map((disciplina, index) => {
                    return (
                      <Scope
                        key={`${turma.moduloTCC.moduloId}-${index}`}
                        path={`disciplina[${index}]`}
                      >
                        {professores.length > 0 && (
                          <AccordionDisciplina
                            indice={`${turma.moduloTCC.moduloId}-${index}`}
                            disciplina={disciplina}
                            ehModuloTCC
                            professores={professores}
                            modalidade={
                              ModalidadeCursoPorExtenso[turma.modalidadeCurso]
                            }
                            deveExpandir={deveExpandirAccordionTCC}
                          />
                        )}
                        <Spacer padding="10px 0px 0px" />
                      </Scope>
                    )
                  })}
                </Scope>
              )}

              {turma.modulos &&
                turma.modulos.map((modulo, indiceModulo) => (
                  <Scope
                    key={`${indiceModulo}`}
                    path={`modulo[${indiceModulo}]`}
                  >
                    <Spacer padding="30px 0px 0px" />
                    <SubTitulo texto={modulo.nome} />

                    <InputUnform name="id" esconder />

                    <ContainerDados>
                      <ContainerPequeno>
                        <CalendarioUnform
                          label={
                            ehModalidadeEad
                              ? 'Data de Liberação do Material'
                              : 'Data de Liberação do Módulo'
                          }
                          className="sm"
                          name="dataLiberacao"
                          id={`inp_data_liberacao_${indiceModulo}`}
                          disabled={bloquearCampo}
                        />
                      </ContainerPequeno>
                    </ContainerDados>
                    {modulo.disciplinas.map((disciplina, index) => {
                      return (
                        <Scope
                          key={`${indiceModulo}-${index}`}
                          path={`disciplina[${index}]`}
                        >
                          {professores.length > 0 && (
                            <AccordionDisciplina
                              indice={`${indiceModulo}-${index}`}
                              disciplina={disciplina}
                              modalidade={
                                ModalidadeCursoPorExtenso[turma.modalidadeCurso]
                              }
                              professores={professores}
                              professoresAlterados={disciplinaPossuiProfessores}
                            />
                          )}

                          <Spacer padding="10px 0px 0px" />
                        </Scope>
                      )
                    })}

                    {turma.tipoAvaliacaoCurso ===
                      TipoAvaliacao[TipoAvaliacao.AvaliacaoPorModulo] && (
                      <Scope path="avaliacao">
                        <Spacer padding="35px 0px 0px" />
                        <Avaliacao
                          indice={`${indiceModulo}`}
                          idReferencia={modulo.moduloId}
                          tipo={modulo.avaliacao?.tipoAvaliacaoTurma ?? null}
                          contemAvaliacao={!!modulo.avaliacao}
                          possuiAvaliacao={modulo.possuiAvaliacao}
                        />
                        <Spacer padding="15px 0px 0px" />
                      </Scope>
                    )}
                  </Scope>
                ))}

              {turma.modalidadeCurso && !ehModalidadeEad && (
                <Scope path="localizacao">
                  <Spacer padding="30px 0px 0px" />
                  <Endereco />
                </Scope>
              )}

              <Secao id={Navegacoes.MaterialComplementar}>
                <SubTitulo texto="Aprenda Mais" />
                <Scope path="materiaisComplementares">
                  <AccordionMaterialComplementar
                    tipoMaterialComplementar={TipoMaterialComplementar.Artigos}
                  />
                  <Spacer padding="10px 0px 0px" />
                </Scope>

                <Scope path="materiaisComplementares">
                  <AccordionMaterialComplementar
                    tipoMaterialComplementar={
                      TipoMaterialComplementar.Encontros
                    }
                    definirNome={definirDisciplinaComplementar}
                  />
                  <Spacer padding="10px 0px 0px" />
                </Scope>

                <Scope path="materiaisComplementares">
                  <AccordionMaterialComplementar
                    tipoMaterialComplementar={
                      TipoMaterialComplementar.GestaoDeCarreira
                    }
                  />
                  <Spacer padding="10px 0px 0px" />
                </Scope>
              </Secao>

              {turma.modalidadeCurso && (
                <>
                  <Spacer padding="30px 0px 0px" />
                  <Secao id={Navegacoes.PesquisaSatisfacao}>
                    <SubTitulo texto="Pesquisa de Satisfação" />
                    <SelectAsyncUnform
                      className="lg"
                      id="slct_pesquisa_satisfacao"
                      name="pesquisaSatisfacaoId"
                      label="Pesquisa de Satisfação sobre o Curso"
                      placeholder="Selecione"
                      buscarPorTexto={carregarPesquisaSatisfacaoCursoTexto}
                      buscarPorId={carregarPesquisaSatisfacaoPorId}
                      disabled={bloquearCampo || visao === visaoProfessor}
                    />

                    {ehModalidadeEad && (
                      <>
                        <Spacer padding="30px 0px 0px" />
                        <SelectAsyncUnform
                          className="lg"
                          id="slct_pesquisa_satisfacao_professores"
                          name="pesquisaSatisfacaoProfessoresId"
                          label={
                            <LabelPesquisaSatisfacaoProfessores>
                              Pesquisa de Satisfação sobre os Professores
                              <div
                                className="informacao-icone"
                                data-tip={
                                  'A pesquisa de satisfação sobre os professores só pode ser adicionada se todas as disciplinas estiverem com professor responsável cadastrado.'
                                }
                                data-for={
                                  'pesquisa-satisfacao-professores-tooltip'
                                }
                              >
                                {IconeInformacao}
                              </div>
                              <Tooltip
                                id={'pesquisa-satisfacao-professores-tooltip'}
                                place="right"
                                className="informacao-tooltip"
                                textColor={Cores.PRETO}
                                backgroundColor={Cores.CINZA_1_CLARO}
                              />
                            </LabelPesquisaSatisfacaoProfessores>
                          }
                          placeholder="Selecione"
                          buscarPorTexto={
                            carregarPesquisaSatisfacaoProfessoresTexto
                          }
                          buscarPorId={carregarPesquisaSatisfacaoPorId}
                          disabled={
                            !todasDisciplinaPossuemProfessor ||
                            bloquearCampo ||
                            visao === visaoProfessor
                          }
                        />
                      </>
                    )}
                  </Secao>
                  <Spacer padding="30px 0px 0px" />
                  <Secao id={Navegacoes.GrupoDiscussao}>
                    <SubTitulo texto="Fórum de dúvidas" />
                    <ContainerTextArea>
                      <TextAreaUnform
                        className="lg"
                        name="grupoDiscussao"
                        label="Mensagem"
                        tooltipLabel="Esta mensagem será a descrição exibida ao aluno no Fórum de dúvidas do curso."
                        maxLength={1000}
                        height="100px"
                      />
                    </ContainerTextArea>
                  </Secao>
                  <Spacer padding="25px 0px 0px" />
                  <Secao id={Navegacoes.MateriaisCurso}>
                    <ListaMateriais
                      modalidadeTurma={
                        ModalidadeCursoPorExtenso[turma.modalidadeCurso]
                      }
                      titulo="Materiais do Curso"
                    />
                  </Secao>
                </>
              )}
            </>
          </>
        ) : (
          <Carregando />
        )}
      </Secao>
      {!bloquearCampo && (
        <>
          <Spacer padding="10px 0px" />
          <ContainerAcoes>
            <Botao
              texto="Cancelar"
              tema="Secundario"
              type="button"
              disabled={carregando}
              onClick={() => modalRef.current?.abrir()}
            />
            <Botao type="submit" texto="Salvar" carregando={carregando} />
          </ContainerAcoes>
        </>
      )}
      <Modal
        ref={modalRef}
        backdrop
        id="modal-confirmacao-cancelamento"
        titulo="Deseja cancelar?"
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.goBack()
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalRef.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
    </FormUnform>
  )
}
