import { mensagemPadrao } from 'src/dados-estaticos'
import { ModalidadeCurso } from 'src/tipos'
import * as Yup from 'yup'

const caracter = Yup.string().required(mensagemPadrao)

export const schema = Yup.object().shape({
  codigo: caracter,
  nome: caracter,
  cargaHoraria: Yup.number().required(mensagemPadrao).typeError(mensagemPadrao),
  modalidadeCurso: Yup.string().required(mensagemPadrao),
  tipo: Yup.string().when('modalidadeCurso', {
    is: value => value === ModalidadeCurso.Outra,
    then: field => field.required(mensagemPadrao)
  })
})
