import { colunaAcertou } from './acertou'
import { colunaAlternativa } from './alternativa'
import { colunaCorreta } from './correta'
import { colunaDataFim } from './data-fim'
import { colunaDataInicio } from './data-inicio'
import { colunaDataTentativa } from './data-tentativa'
import { colunaDisciplinaNome } from './disciplina-nome'
import { colunaMarcou } from './marcou'
import { colunaModuloNome } from './modulo-nome'
import { colunaNomeAluno } from './nome-aluno'
import { colunaPergunta } from './pergunta'
import { colunaProgressoCurso } from './progresso-curso'
import { colunaSituacaoMatricula } from './situacao-matricula'
import { colunaTentativaNumero } from './tentativa-numero'
import { colunaTentativasRealizadas } from './tentativas-realizadas'
import { colunaTotalTentativas } from './total-tentativas'
import { colunaTurma } from './turma'
export const COLUNAS_ENVIOS_NOTAS_TCC = [
  colunaNomeAluno(),
  colunaTurma(),
  colunaSituacaoMatricula(),
  colunaDataInicio(),
  colunaDataFim(),
  colunaProgressoCurso(),
  colunaModuloNome(),
  colunaDisciplinaNome(),
  colunaTotalTentativas(),
  colunaTentativasRealizadas(),
  colunaTentativaNumero(),
  colunaDataTentativa(),
  colunaPergunta(),
  colunaAlternativa(),
  colunaCorreta(),
  colunaMarcou(),
  colunaAcertou()
]
