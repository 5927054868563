import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 10H12H20V11H4V10Z" fill="#DDDDDD" />
    <path d="M4 12H20V14H4V12Z" fill="#DDDDDD" />
    <path d="M4 15H20V16H12H4V15Z" fill="#DDDDDD" />
  </svg>
)
