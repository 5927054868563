import React, { useMemo, useState } from 'react'
import { useSpring } from 'react-spring'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  FuncoesDataHora,
  IconeCalendarioPequeno,
  IconeDesafixar,
  IconeFixar,
  IconeLixeira,
  IconeRelogioPequeno,
  IconeTopico,
  SeletorUnform,
  Tooltip,
  Visao
} from 'src/componentes'
import { Api } from 'src/servicos'
import { TipoGrupoDiscussao } from 'src/tipos'
import { GrupoDiscussao } from 'src/tipos/grupo-discussao'

import {
  Container,
  CabecalhoListagem,
  ListaTopicos,
  ItemTopico,
  AnimatedDiv,
  ContainerInformacoes,
  ContainerTitulo,
  ContainerDescricao,
  ContainerTopico,
  ContainerBotoesDeletarTopico,
  ContainerBotoesFixarTopico
} from './styles'
import { Topico } from './topico'

interface GrupoDiscussaoComponenteProps {
  grupoDiscussao: GrupoDiscussao
  recarregar?: () => void
}

export const GrupoDiscussaoComponente: React.FC<GrupoDiscussaoComponenteProps> = ({
  grupoDiscussao,
  recarregar
}) => {
  const [topicoSelecionado, definirTopicoSelecionado] = useState<string>(null)
  const [confirmacaoDeletarTopico, definirConfirmacaoDeletarTopico] = useState(
    false
  )
  const [topicoExclusaoId, definirTopicoExclusaoId] = useState('')
  const [confirmacaoFixarTopico, definirConfirmacaoFixarTopico] = useState(
    false
  )
  const [ordemFixar, definirOrdemFixar] = useState('')
  const [topicoFixarId, definirTopicoFixarId] = useState('')
  const [carregando, definirCarregando] = useState(false)
  const { usuario, visao } = AutenticacaoContainer.useContainer()
  const visaoIPGS = useMemo(() => {
    return visao === Visao.IPGS
  }, [visao])
  const visaoParceiro = useMemo(() => visao === Visao.Parceiro, [visao])
  const podeFixarTopico = useMemo(
    () =>
      grupoDiscussao.tipoGrupoDiscussao === TipoGrupoDiscussao.SalaAula &&
      (visaoIPGS || visaoParceiro),
    [visaoIPGS, visaoParceiro, grupoDiscussao]
  )
  const podeDeletarTopico =
    grupoDiscussao.tipoGrupoDiscussao === TipoGrupoDiscussao.SalaAula &&
    visaoIPGS

  const { transform, opacity } = useSpring({
    opacity: topicoSelecionado ? 1 : 0,
    transform: `translate(${topicoSelecionado ? -180 : 0}px, 0px)`,
    config: { mass: 1, tension: 900, friction: 100 }
  })

  const excluirTopico = async (topicoId: string) => {
    try {
      definirCarregando(true)

      await Api.DeletarTopico(grupoDiscussao.grupoDiscussaoId, topicoId)

      if (recarregar) recarregar()

      definirConfirmacaoDeletarTopico(false)
    } finally {
      definirCarregando(false)
    }
  }

  const fixarTopico = async (topicoId: string, fixar: boolean) => {
    if (fixar && (!ordemFixar || ordemFixar === '')) {
      toast('A ordem de exibição precisa ser definida.', { type: 'error' })
      return
    }

    try {
      definirCarregando(true)
      await Api.FixarTopico(
        grupoDiscussao.grupoDiscussaoId,
        topicoId,
        fixar,
        ordemFixar
      )

      if (recarregar) recarregar()

      definirConfirmacaoFixarTopico(false)
      definirTopicoFixarId(null)

      definirCarregando(false)
    } finally {
      definirCarregando(false)
    }
  }

  const toggleDeletar = topicoId => {
    definirConfirmacaoDeletarTopico(true)
    definirTopicoExclusaoId(topicoId)

    definirConfirmacaoFixarTopico(false)
    definirTopicoFixarId(null)
  }

  const toggleFixar = topicoId => {
    definirConfirmacaoFixarTopico(true)
    definirTopicoFixarId(topicoId)
    definirOrdemFixar('')

    definirConfirmacaoDeletarTopico(false)
    definirTopicoExclusaoId(null)
  }
  return (
    <>
      <Container>
        {!topicoSelecionado ? (
          <AnimatedDiv
            id="grupo-discussao-listagem"
            style={{
              opacity: opacity.interpolate(o => 1 - Number(o)),
              transform
            }}
          >
            <CabecalhoListagem>
              {grupoDiscussao?.topicos?.length > 0 && (
                <ListaTopicos>
                  {grupoDiscussao.topicos.map((topico, index) => (
                    <ContainerTopico key={index}>
                      {(grupoDiscussao.tipoGrupoDiscussao ===
                        TipoGrupoDiscussao.SalaAula ||
                        usuario.id === topico.usuarioCriadorId ||
                        visaoIPGS) && (
                        <ItemTopico
                          key={topico.topicoId}
                          onClick={() => {
                            definirTopicoSelecionado(topico.topicoId)
                          }}
                        >
                          <ContainerInformacoes>
                            <ContainerTitulo>
                              {IconeTopico}
                              <h4>{topico.titulo}</h4>
                            </ContainerTitulo>
                            <ContainerDescricao>
                              <span>Criado em </span>
                              {IconeCalendarioPequeno}
                              <span>
                                {FuncoesDataHora.novaData(topico?.dataInclusao)}
                              </span>
                              {IconeRelogioPequeno}
                              <span>
                                {FuncoesDataHora.horaFormatada24H(
                                  new Date(topico?.dataInclusao)
                                )}
                              </span>
                            </ContainerDescricao>
                          </ContainerInformacoes>
                        </ItemTopico>
                      )}
                      {podeDeletarTopico && (
                        <button
                          type="button"
                          data-tip="Excluir Tópico"
                          data-for={`${topico.topicoId}-tooltip-excluir-topico`}
                          onClick={() => {
                            toggleDeletar(topico.topicoId)
                          }}
                          disabled={carregando}
                        >
                          {IconeLixeira}
                        </button>
                      )}
                      {confirmacaoDeletarTopico &&
                        topicoExclusaoId === topico.topicoId && (
                          <span>
                            <ContainerBotoesDeletarTopico>
                              <button
                                type="button"
                                data-tip="Excluir Comentário"
                                data-for={`${topico.topicoId}-tooltip-excluir-topico`}
                                onClick={() => {
                                  definirConfirmacaoDeletarTopico(false)
                                }}
                                disabled={!visaoIPGS}
                              >
                                Cancelar
                              </button>
                              <button
                                type="button"
                                data-tip="Excluir Comentário"
                                data-for={`${topico.topicoId}-tooltip-excluir-topico`}
                                onClick={() => excluirTopico(topico.topicoId)}
                                disabled={!visaoIPGS || carregando}
                              >
                                Confirmar
                              </button>
                            </ContainerBotoesDeletarTopico>
                          </span>
                          // eslint-disable-next-line
                        )}
                      {podeFixarTopico && (
                        <>
                          <button
                            type="button"
                            data-tip={
                              !topico.fixado ? 'Fixar tópico' : 'Desafixar'
                            }
                            data-for={`${topico.topicoId}-tooltip-fixar-topico`}
                            onClick={() => {
                              toggleFixar(topico.topicoId)
                            }}
                            disabled={carregando}
                          >
                            {!topico.fixado ? IconeFixar : IconeDesafixar}
                          </button>
                          <Tooltip
                            id={`${topico.topicoId}-tooltip-fixar-topico`}
                            place="left"
                            className="tooltipFixar"
                          />
                          {confirmacaoFixarTopico &&
                            topicoFixarId === topico.topicoId && (
                              <ContainerBotoesFixarTopico>
                                {!topico.fixado && (
                                  <SeletorUnform
                                    id={`${topico.topicoId}-slc-ordem-fixar`}
                                    name={`${topico.topicoId}-slc-ordem-fixar`}
                                    tipo="radio"
                                    label="Ordem"
                                    seletores={[
                                      {
                                        id: '1',
                                        texto: '1'
                                      },
                                      {
                                        id: '2',
                                        texto: '2'
                                      },
                                      {
                                        id: '3',
                                        texto: '3'
                                      }
                                    ]}
                                    valorAlterado={opcao => {
                                      definirOrdemFixar(opcao?.toString())
                                    }}
                                  />
                                )}
                                <button
                                  type="button"
                                  onClick={() => {
                                    definirConfirmacaoFixarTopico(false)
                                  }}
                                >
                                  Cancelar
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    fixarTopico(topico.topicoId, !topico.fixado)
                                  }
                                >
                                  Confirmar
                                </button>
                              </ContainerBotoesFixarTopico>
                              // eslint-disable-next-line
                            )}
                        </>
                      )}
                    </ContainerTopico>
                  ))}
                </ListaTopicos>
              )}
            </CabecalhoListagem>
          </AnimatedDiv>
        ) : (
          <AnimatedDiv
            id="grupo-discussao-topico"
            style={{
              opacity,
              transform: transform.interpolate(
                t => `${t} translate(180px, 0px)`
              )
            }}
          >
            <Topico
              idTopico={topicoSelecionado}
              idGrupoDiscussao={grupoDiscussao?.grupoDiscussaoId}
              tipoGrupoDiscussao={grupoDiscussao?.tipoGrupoDiscussao}
              onClick={() => definirTopicoSelecionado(null)}
            />
          </AnimatedDiv>
        )}
      </Container>
    </>
  )
}
