import { Medias } from 'src/compartilhados/modulos/responsivo'
import styled from 'styled-components'

import { BarraDeProgressoDoCurso } from '../barra-de-progresso-do-curso'
import { Cores } from '../styles'

interface ContainerProps {
  main?: boolean
}

const espacamentoQuandoNaoExisteBarra = ({ main }: ContainerProps) =>
  main ? '73px' : '30px'

export const Container = styled.div<ContainerProps>`
  padding: 30px;
  grid-column: span 2;
  width: 100%;
  background-color: #f7f7f7;
  position: relative;
  border-radius: 3px;
  padding-top: ${espacamentoQuandoNaoExisteBarra};

  ${Medias.Mobile} {
    padding-top: 30px;
  }

  ${Medias.Tablet} {
    padding-top: 30px;
  }

  & > .conteudo {
    display: flex;
    flex-direction: column;
    border-left: 4px solid;
    padding-left: 17px;
    grid-row: span 2;
    height: 90%;
    justify-content: space-between;

    border-color: ${Cores.SECUNDARIA_2};

    h4 {
      color: ${Cores.SECUNDARIA_2};

      ${Medias.Tablet} {
        margin-bottom: 8px;
      }

      line-height: 18px;
      font-size: 14px;
      font-weight: 500;

      ${Medias.Mobile} {
        font-size: 12px;
        margin-bottom: 8px;
      }

      ${Medias.Tablet} {
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    h5 {
      flex-grow: 1;
      line-height: ${props => (props.main ? '27px' : '22px')};
      font-size: ${props => (props.main ? '18px' : '14px')};
      font-weight: 300;

      ${Medias.Mobile} {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 27px;
      }

      ${Medias.Tablet} {
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
      }
    }

    h5.sigla {
      text-transform: uppercase;
    }

    .progressbar {
      position: absolute;
      top: ${props => (props.main ? '30px' : 'auto')};
      bottom: ${props => (props.main ? 'auto' : '30px')};
      right: ${props => (props.main ? 'auto' : '30px')};
      left: ${props => (props.main ? '30px' : 'auto')};
    }

    a {
      ${Medias.Mobile} {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }

      font-size: ${props => (props.main ? '16px' : '12px')};
      font-weight: ${props => (props.main ? '500' : '400')};
      color: ${Cores.SECUNDARIA_2_ESCURO};

      ${Medias.Tablet} {
        font-style: italic;
      }

      &.disabled {
        opacity: 0.4;
        cursor: default;
        pointer-events: none;

        ${Medias.Mobile} {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        ${Medias.Tablet} {
          font-style: italic;
        }
      }
    }
  }

  &.freecourse > div {
    border-color: ${Cores.SECUNDARIA_3_CLARO};

    h4 {
      color: ${Cores.SECUNDARIA_3_CLARO};
    }

    h5 {
      ${Medias.Tablet} {
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
`

export const BarraDeProgressoComEstilos = styled(BarraDeProgressoDoCurso)`
  ${Medias.Mobile} {
    height: 10px;
  }
`

export const ContainerDaBarra = styled.div`
  margin-bottom: 30px;

  ${Medias.Mobile} {
    margin-bottom: 15px;
  }

  ${Medias.Tablet} {
    display: flex;
    justify-content: center;

    ${BarraDeProgressoComEstilos} {
      width: 75%;
    }
  }
`

export const ContainerDoLink = styled.div`
  display: flex;
  justify-content: space-between;

  ${Medias.Tablet} {
    width: 60%;
  }
`

export const ContainerDaBarraCompacta = styled.div`
  width: 95px;
`

export const ContainerSiglas = styled.div`
  width: 100%;
`

export const TextoSituacao = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${Cores.SECUNDARIA_2};
`
