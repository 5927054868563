import React from 'react'

export default (
  <svg width="32" height="32" viewBox="0 0 24 24">
    <path
      fill="#888888"
      d="M13 19c0-.34.04-.67.09-1H4V8l8 5l8-5v5.09c.72.12 1.39.37 2 .72V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9.09c-.05-.33-.09-.66-.09-1m7-13l-8 5l-8-5h16m0 16v-2h-4v-2h4v-2l3 3l-3 3Z"
    />
  </svg>
)
