import { colunaCurso } from './curso'
import { colunaDataFimOriginal } from './data-fim-original'
import { colunaDataFimProrrogada } from './data-fim-prorrogada'
import { colunaDataInicio } from './data-inicio'
import { colunaDataOpcaoTCC } from './data-opcao-tcc'
import { colunaInseridoPor } from './inserido-por'
import { colunaModalidade } from './modalidade'
import { colunaNomeAluno } from './nome-aluno'
import { colunaQuantidadeProrrogacoes } from './quantidade-prorrogacoes'
import { colunaSituacaoFinanceira } from './situacao-financeira'
import { colunaTempoProrrogacao } from './tempo-prorrogacao'
import { colunaTipoMatricula } from './tipo-matricula'
import { colunaTurma } from './turma'

export const COLUNAS_PRORROGACAO = [
  colunaNomeAluno(),
  colunaModalidade(),
  colunaCurso(),
  colunaTurma(),
  colunaTipoMatricula(),
  colunaSituacaoFinanceira(),
  colunaDataInicio(),
  colunaDataFimOriginal(),
  colunaDataFimProrrogada(),
  colunaTempoProrrogacao(),
  colunaQuantidadeProrrogacoes(),
  colunaDataOpcaoTCC(),
  colunaInseridoPor()
]
