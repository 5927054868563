import styled, { css } from 'styled-components'

export const Linha = styled.div<{ centralizado?: boolean }>`
  display: flex;

  & > div:not(:first-child) {
    margin-left: 10px;
  }

  .sm {
    flex: 1;
  }

  .md {
    flex: 2;
  }

  .lg {
    flex: 3;
  }

  ${props =>
    props.centralizado &&
    css`
      align-items: center;
      justify-content: center;
    `}
`
