import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  LayoutAuth
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { formatarCpf } from 'src/componentes/funcoes'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { RotasApp } from 'src/rotas'
import { Api } from 'src/servicos'
import { UsuarioAuth } from 'src/servicos/api/tipos'

import { schema } from './schema'
import { Form, ResumoUsuario } from './styles'

const PaginaRecuperarSenha: React.FC = () => {
  const [carregando, definirCarregando] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const [dadosUsuario, definirDadosUsuario] = useState({} as UsuarioAuth)
  const [idUsuario, definirIdUsuario] = useState(null)
  const [token, definirToken] = useState(null)

  const { erro, definirErro } = ErroLayoutContainer.useContainer()
  const history = useHistory()

  const carregarUsuario = async () => {
    try {
      definirPronto(false)
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      const id = urlParams.get('id')
      const novoToken = urlParams.get('token')
      definirIdUsuario(id)
      definirToken(novoToken)

      if (!id || !novoToken) {
        throw new Error(
          'Página não encontrada, verifique se você copiou todo o caminho para recuperar sua senha.'
        )
      }

      const usuario = await Api.RequisitarUsuarioAuth(id)

      definirDadosUsuario(usuario)
    } catch (error) {
      definirErro({
        mensagens: error.message
      })
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucesso = async ({
    senha,
    repetirSenha
  }: {
    senha: string
    repetirSenha: string
  }): Promise<void> => {
    try {
      definirCarregando(true)

      if (senha === repetirSenha) {
        const sucesso = await Api.RedefinirSenha(idUsuario, senha, token)

        if (sucesso) {
          history.push(RotasApp.Home)
          toast('Senha redefinida com sucesso!', { type: 'success' })
        }
      }
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    carregarUsuario()
  }, [])

  return (
    <LayoutAuth>
      {pronto && !erro ? (
        <Form>
          <h1>Redefina sua Senha</h1>
          <ResumoUsuario>
            <p>Nome: {dadosUsuario.nome}</p>
            {!!dadosUsuario.cpf && <p>CPF: {formatarCpf(dadosUsuario.cpf)}</p>}
            <p>E-mail: {dadosUsuario.email}</p>
          </ResumoUsuario>
          <FormUnform schema={schema} acaoSucesso={acaoSucesso}>
            <InputUnform
              type="password"
              id="ipt_senha"
              label="Informe sua Nova Senha"
              name="senha"
              autoComplete="off"
              maxWidthInput="100%"
              obrigatorio
            />
            <InputUnform
              type="password"
              id="ipt_repetir_senha"
              label="Confirme sua Nova Senha"
              name="repetirSenha"
              autoComplete="off"
              maxWidthInput="100%"
              obrigatorio
              semValidacaoSenha
            />
            <Botao
              type="submit"
              id="btn-enviar"
              texto="Redefinir Senha"
              carregando={carregando}
            />
          </FormUnform>
        </Form>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando ..." />
      ) : (
        <ErroContainerLayout />
      )}
    </LayoutAuth>
  )
}

export const RecuperarSenha: React.FC = props => {
  return (
    <ErroLayoutContainer.Provider>
      <PaginaRecuperarSenha {...props} />
    </ErroLayoutContainer.Provider>
  )
}
