import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaDataSolicitacao = (): TabelaColuna => ({
  Chave: 'dataSolicitacao',
  Alinhamento: 'center',
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataSolicitacao)}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 110 }}>Data Solicitação</div>
})
