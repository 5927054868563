import { AxiosRequestConfig } from 'axios'
import { saveAs } from 'file-saver'
import { UploadDocumentos } from 'src/compartilhados'

import {
  DataNascimentoDocumento,
  Documento,
  DocumentosParaAprovacao,
  RespostaPaginacao,
  ResultadoDaAprovacaoDeDocumentos,
  ResumoDocumentos
} from '../../tipos'
import { gatewayApi } from '../instancias-api'

const urlBase = '/documentosAluno'
const headers = { headers: { 'Content-Type': 'multipart/form-data' } }

export const ObterResumoDeDocumentos = async (
  usuarioId: string
): Promise<ResumoDocumentos[]> => {
  const { data } = await gatewayApi.get<RespostaPaginacao<ResumoDocumentos>>(
    `${urlBase}/resumo-documentos/${usuarioId}`
  )

  if (!data) {
    return [] as ResumoDocumentos[]
  }

  return data.itens
}

export const ObterDownloadDeDocumentos = async (
  usuarioId: string,
  documentoId: string
): Promise<void> => {
  const rota = `${urlBase}/resumo-documentos/${usuarioId}/documento/${documentoId}`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null

  if (resposta.data) {
    saveAs(resposta.data)
  }
}

export const ObterArquivoDeDocumentos = async (
  usuarioId: string,
  documentoId: string
): Promise<string> => {
  const rota = `${urlBase}/resumo-documentos/${usuarioId}/documento/${documentoId}`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null
  return URL.createObjectURL(resposta.data)
}

export const RequisitarDocumentoAluno = async (
  usuarioId: string,
  documentoId: string
): Promise<Documento> => {
  const resposta = await gatewayApi.get(
    `${urlBase}/resumo-documentos/${usuarioId}/documento/${documentoId}/visualizar`
  )

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const EnviarDocumentosDoAluno = async (
  usuarioId: string,
  form: FormData
): Promise<boolean> => {
  const rota = `${urlBase}/${usuarioId}`
  const resposta = await gatewayApi.post(rota, form, headers)

  return Boolean(resposta.status < 300)
}

export const EditarDocumentosDoAluno = async (
  usuarioId: string,
  form: FormData
): Promise<boolean> => {
  const rota = `${urlBase}/${usuarioId}`
  const resposta = await gatewayApi.put(rota, form, headers)

  return Boolean(resposta.status < 300)
}

export const AprovacaoDeDocumentosDoAluno = async (
  documentos: DocumentosParaAprovacao
): Promise<boolean> => {
  const { data } = await gatewayApi.put<ResultadoDaAprovacaoDeDocumentos>(
    `${urlBase}/atualizar-documentos`,
    documentos
  )

  return Boolean(data.sucesso)
}

export const AlteracaoAprovado = async (
  usuarioId: string,
  documentos: UploadDocumentos[]
): Promise<boolean> => {
  const rota = `${urlBase}/${usuarioId}/alteracao-aprovado`
  const data = {
    alteracoes: documentos.map(x => {
      return {
        tipo: x.tipo,
        motivo: x.motivoAlteracaoAprovado
      }
    })
  }
  const resposta = await gatewayApi.put(rota, data)

  return resposta.status < 300
}

export const AtualizarDataNascimentoDocumento = async (
  usuarioId: string,
  documentos: DataNascimentoDocumento[]
): Promise<boolean> => {
  const rota = `${urlBase}/${usuarioId}/data-nascimento`
  const data = {
    documentos
  }
  const resposta = await gatewayApi.put(rota, data)
  return resposta.status < 300
}
