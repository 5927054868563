import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  ModalRequisitarSenha,
  ModalRequisitarSenhaRef,
  LayoutAuth,
  ModalRef
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { IconeSetaDireita } from 'src/componentes/icones'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'

import { ModalAvisoUsuarioLogado } from './modal-aviso-usuario-logado'
import { schema } from './schema'
import { Form, IconeBotao, Link } from './styles'
import { Dados } from './tipos'

export const Entrar: React.FC = () => {
  const {
    autenticar,
    perfil,
    conferirUsuarioLogado
  } = AutenticacaoContainer.useContainer()
  const [carregando, definirCarregando] = useState(false)
  const modalRef = useRef<ModalRequisitarSenhaRef>(null)
  const modalRefAviso = useRef<ModalRef>(null)
  const history = useHistory()

  useEffect(() => {
    if (perfil) {
      history.push('/')
    }
  }, [])

  const acaoSucesso = async ({ usuario, senha }: Dados): Promise<void> => {
    definirCarregando(true)
    try {
      if (conferirUsuarioLogado() !== null) modalRefAviso.current?.abrir()
      else await autenticar(usuario, senha)
    } finally {
      definirCarregando(false)
    }
  }

  return (
    <>
      <LayoutAuth>
        <Form>
          <h1>Realize seu Login</h1>
          <FormUnform schema={schema} acaoSucesso={acaoSucesso}>
            <InputUnform
              id="ipt_usuario"
              label="Informe seu CPF, CNPJ ou E-mail"
              name="usuario"
              maxWidthInput="100%"
              obrigatorio
            />
            <InputUnform
              type="password"
              id="ipt_senha"
              label="Informe sua Senha"
              name="senha"
              autoComplete="off"
              maxWidthInput="100%"
              obrigatorio
              semValidacaoSenha
            />
            <Botao
              type="submit"
              id="btn-logar"
              texto="Logar"
              elemento={<IconeBotao>{IconeSetaDireita}</IconeBotao>}
              carregando={carregando}
            />
            <Link>
              <Botao
                type="button"
                id="btn-esqueceu-senha"
                texto="Esqueceu a senha?"
                tema="Link"
                onClick={() => modalRef.current?.abrir()}
              />
            </Link>
          </FormUnform>
        </Form>
      </LayoutAuth>
      <ModalRequisitarSenha
        backdrop
        ref={modalRef}
        acaoSucesso={() => {
          toast('Sucesso! Confira seu e-mail.', { type: 'success' })
        }}
      />
      <ModalAvisoUsuarioLogado backdrop ref={modalRefAviso} />
    </>
  )
}
