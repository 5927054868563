import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { OpcaoTCCPorExtenso } from 'src/tipos'

export const colunaOpcaoTCC = (): TabelaColuna => ({
  Chave: 'opcaoTCC',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <>{OpcaoTCCPorExtenso[linha.opcaoTCC]}</>,
  RenderizarCabecalho: () => <div style={{ width: 100 }}>Opção TCC</div>
})
