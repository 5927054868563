import styled from 'styled-components'

import { Cores } from '../styles'

export const Corpo = styled.div`
  width: 500px;
  height: 410px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-top: 20px;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  form {
    width: 100%;
  }
`
export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 23px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`
export const ContainerModalPreRequisitos = styled.div`
  margin-top: 10px;
  width: 70%;
`
export const ContainerSelect = styled.div`
  margin-bottom: 60px;
  width: 70%;
  max-height: 40px;
`
export const CorpoModal = styled(Corpo)`
  padding: 41px 0px;
`
export const LabelSelect = styled.div`
  align-self: flex-start;
  margin-bottom: 8px;
  margin-left: 3px;
`
export const Obrigatorio = styled.span`
  color: ${Cores.PERIGO_ERRO};
  margin-right: 5px;
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
`
