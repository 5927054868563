import { Cores } from 'src/componentes/styles/cores'
import styled, { css } from 'styled-components'

export interface ComponenteProps {
  erro: string
}

export const Componente = styled.div<ComponenteProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  ${({ erro }) =>
    erro &&
    css`
      span {
        opacity: 1;
        bottom: -20px;
      }
    `}
`

export const Label = styled.label<{ readOnly?: boolean }>`
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  text-align: left;
  margin-bottom: 6px;
  line-height: 125%;

  :first-letter {
    text-transform: uppercase;
  }
`

export const Erro = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in, bottom 0.3s ease-in;
`
