import { SELECT_NACIONALIDADES } from 'src/dados-estaticos'
import {
  Aluno,
  Escolaridade,
  EstadoCivil,
  PaisResidencia,
  Raca,
  Sexo
} from 'src/tipos'

import { TipoDadosDoFormulario } from './tipos'

export const normalizacaoDeDadosParaFormulario = (
  aluno: Aluno,
  callback: (form: TipoDadosDoFormulario) => TipoDadosDoFormulario
): TipoDadosDoFormulario => {
  const formulario = {
    foto: aluno.foto,
    nacionalidade: aluno.nacionalidade || SELECT_NACIONALIDADES[0].id,
    cpf: aluno.cpf && aluno.cpf.documento ? aluno.cpf.documento : null,
    rg: aluno.documentoIdentidade,
    orgao: aluno.orgaoEmissor,
    nome: aluno.nome,
    nomeSocial: aluno.nomeSocial,
    codigoConselho: aluno.codigoConselho || '',
    dataNascimento: aluno.dataNascimento,
    raca: aluno.corRaca || Raca.NaoInformado,
    estadoCivil: aluno.estadoCivil || EstadoCivil[EstadoCivil.NaoInformado],
    profissao: aluno.profissao,
    profissaoOutros: aluno.profissaoOutros,
    escolaridade: aluno.escolaridade || Escolaridade.EnsinoMedio,
    dataColacao: aluno.mesAnoColacaoGrau,
    email: aluno.email,
    celular: aluno.celular,
    telefone: aluno.telefone,
    paisResidencia:
      !aluno.endereco || !aluno.endereco.paisResidencia
        ? PaisResidencia.Brasil
        : aluno.endereco.paisResidencia,
    cep: aluno.endereco?.cep,
    logradouro: aluno.endereco?.logradouro,
    numero: aluno.endereco?.numero,
    complemento: aluno.endereco?.complemento,
    bairro: aluno.endereco?.bairro,
    cidade: aluno.endereco?.cidade,
    uf: aluno.endereco?.uf,
    sexo: aluno.sexo || Sexo[Sexo.NaoInformado],
    generoOutros: aluno.generoOutros
  }

  return callback(formulario)
}
