import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  useRef,
  useMemo
} from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  Botao,
  InputUnform,
  FormUnform,
  ModalBase,
  ModalRef,
  SelectOpcao,
  SelectUnform,
  Checkbox,
  FormRef,
  focarPrimeiroCampoComErro,
  Tooltip,
  InputMesAnoUnform,
  IconeCartaoVisa,
  IconeCartaoMaster,
  IconeCartaoAmex,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoDiners,
  CartaoCreditoInputUnform,
  IconeInformacao,
  Cores,
  regExpVisa,
  regExpElo,
  regExpMaster,
  regExpAmex,
  regExpHiper,
  regExpJcb,
  regExpDiners
} from 'src/componentes'
import { SELECT_DIAS, SELECT_METODOSPAGAMENTO } from 'src/dados-estaticos'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import {
  TipoProfessorModuloTCC,
  CupomValido,
  OpcaoTCC,
  FormaPagamentoCobranca
} from 'src/tipos'

import { schema } from './schema'
import {
  ContainerGrande,
  ContainerMedio,
  Linha,
  CorpoModal,
  ContainerModal,
  ContainerCupom,
  ContainerInputCupom,
  AvisoFinanceiro,
  Aviso,
  ContainerBandeiras,
  LinhaBandeiras,
  IconeLabel,
  TituloPrincipal,
  ContainerBotoes
} from './styles'
import { ModalOpteTCCProps, SelectOpcaoProfessor } from './tipos'
export interface ModalOpteTCC extends Omit<ModalRef, 'abrir'> {
  abrir: (
    valorTCC: number,
    quantidadeParcela: number,
    salaAulaId: string,
    idMatricula: string
  ) => void
}
const Componente: ForwardRefRenderFunction<ModalOpteTCC, ModalOpteTCCProps> = (
  { backdrop, aberta, acaoSucesso },
  ref
) => {
  const formRef = useRef<FormHandles>(null)
  const formCupomRef = useRef<FormRef>(null)
  const [aberto, definirAberto] = useState(aberta)
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [valorTCC, definirValorTCC] = useState<number>()
  const [quantidadeParcela, definirQuantidadeParcela] = useState<number>(1)
  const [salaAulaId, definirSalaAulaId] = useState<string>(null)
  const [ehAceiteAluno, definirEhAceiteAlunoTCC] = useState(false)
  const [temCupom, definirTemCupom] = useState<boolean>(false)
  const [cupom, definirCupom] = useState<CupomValido>({} as CupomValido)
  const [idMatricula, definirIdMatricula] = useState<string>(null)
  const [metodoPagamento, definirMetedoPagamento] = useState('')
  const [numeroCartao, definirNumeroCartao] = useState('')

  const SELECT_PARCELAS_BOLETO = Array.from(
    { length: quantidadeParcela },
    (x, i) => {
      const contador = `${i + 1}`
      return { id: contador, texto: contador } as SelectOpcao
    }
  )

  const SELECT_PARCELAS_CARTAO = Array.from({ length: 12 }, (x, i) => {
    const contador = `${i + 1}`
    return { id: contador, texto: `${contador}x` } as SelectOpcao
  })

  const dadosIniciais = useMemo(() => {
    return {
      valorTCC: valorTCC,
      valorFinal: valorTCC
    }
  }, [valorTCC])

  const abrir = (
    valorTCC: number,
    quantidadeParcela: number,
    salaAulaId: string,
    idMatricula: string
  ) => {
    definirAberto(true)
    definirPronto(true)
    definirValorTCC(valorTCC)
    definirQuantidadeParcela(quantidadeParcela)
    definirSalaAulaId(salaAulaId)
    definirIdMatricula(idMatricula)
  }

  const fechar = () => {
    definirPronto(false)
    definirAberto(false)
  }

  const obterProfessoresTurma = async (
    tipo: TipoProfessorModuloTCC
  ): Promise<SelectOpcao[]> => {
    try {
      const professores = await Api.ObterProfessoresSalaAula(salaAulaId, tipo)
      let resultado: SelectOpcaoProfessor[] = []
      if (professores) {
        resultado = professores.map(x => ({
          id: x.id,
          texto: x.nome,
          professor: x
        }))
      }

      return resultado
    } catch {
      toast('Erro ao carregar professores', { type: 'error' })
    }
  }

  const acaoSucessoForm = async (dados: any) => {
    const requisicaoOrientadorTCC = await obterProfessoresTurma(
      TipoProfessorModuloTCC.Orientador
    )
    const requisicaoCoorientadorTCC = await obterProfessoresTurma(
      TipoProfessorModuloTCC.Banca
    )
    const orientadorTCC = requisicaoOrientadorTCC.shift()
    const coorientadorTCC = requisicaoCoorientadorTCC.shift()

    const pagamentoCartao =
      dados.metodoPagamento &&
      FormaPagamentoCobranca[dados.metodoPagamento] ===
        FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito]

    const request = {
      orientadorTCC: orientadorTCC.id,
      coorientadorTCC: coorientadorTCC.id,
      opcaoTCC: OpcaoTCC[OpcaoTCC.Sim],
      cupomId: cupom?.cupomId,
      formaPagamento: pagamentoCartao
        ? FormaPagamentoCobranca.CartaoCredito
        : FormaPagamentoCobranca.Boleto,
      dadosCartao: pagamentoCartao
        ? {
            nomeImpresso: dados.nomeCartao,
            numero: dados.numeroCartao,
            validade: dados.validadeCartao,
            codigo: dados.codigoSeguranca,
            parcelas: dados.quantidadeParcelasTCC
          }
        : null,
      ...dados
    }

    try {
      definirCarregando(true)
      await acaoSucesso(request)
      fechar()
      formRef.current?.reset()
    } finally {
      definirCarregando(false)
    }
  }

  const validarCupom = async ({ codigoCupom }: { codigoCupom?: string }) => {
    if (codigoCupom) {
      const novoCupom = await Api.RequisitarValidarCupomTCC(
        idMatricula,
        codigoCupom
      )

      if (novoCupom) {
        definirCupom({ ...novoCupom, codigoCupom })
      } else {
        definirCupom({} as CupomValido)
      }

      formCupomRef.current?.setFieldError(
        'codigoCupom',
        novoCupom ? 'Cupom válido' : 'Cupom inválido'
      )
    } else {
      definirCupom({} as CupomValido)
      formCupomRef.current?.setFieldError('codigoCupom', '')
    }
  }

  const valorDesconto = useMemo(() => {
    return cupom?.valorDesconto ?? 0
  }, [cupom])

  const valorFinal = useMemo(() => {
    const valor = valorTCC ? valorTCC - valorDesconto : 0
    return valor <= 0 ? 0 : valor
  }, [valorDesconto, valorTCC])

  useImperativeHandle<ModalOpteTCC, ModalOpteTCC>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase aberta={aberto} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <TituloPrincipal>Realização do TCC</TituloPrincipal>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schema}
              ref={formRef}
              dadosIniciais={dadosIniciais}
            >
              <Linha>
                <ContainerGrande>
                  <Aviso>
                    O pagamento do TCC ficará disponível no seu Painel
                    Financeiro do Curso.
                  </Aviso>
                </ContainerGrande>
              </Linha>
              <Linha>
                <ContainerMedio>
                  <InputUnform
                    name="valorTCC"
                    label="Valor do TCC"
                    disabled
                    value={formatadores.moeda(valorTCC)}
                  />
                </ContainerMedio>
                <ContainerMedio>
                  <InputUnform
                    name="valorTCC"
                    label="Valor A Pagar"
                    disabled
                    value={formatadores.moeda(valorFinal)}
                  />
                  <InputUnform name="valorFinal" value={valorFinal} esconder />
                </ContainerMedio>
              </Linha>
              {valorTCC > 0 && (
                <Linha>
                  <ContainerGrande>
                    <ContainerCupom>
                      <Checkbox
                        texto="Tenho um Cupom"
                        htmlFor="chk_tem_cupom"
                        tamanho="L"
                      >
                        <input
                          type="checkbox"
                          id="chk_tem_cupom"
                          checked={temCupom}
                          onChange={() => {
                            definirTemCupom(old => !old)
                            definirCupom({} as CupomValido)
                          }}
                        />
                      </Checkbox>

                      {temCupom ? (
                        <ContainerInputCupom>
                          <FormUnform
                            dadosIniciais={{
                              codigoCupom: cupom?.codigoCupom || ''
                            }}
                            ref={formCupomRef}
                            acaoSucesso={validarCupom}
                            acaoFalha={focarPrimeiroCampoComErro}
                          >
                            <InputUnform
                              id="ipt_cupom"
                              name="codigoCupom"
                              sucesso={!!cupom.cupomId}
                              onBlur={() => {
                                formCupomRef.current?.submitForm()
                              }}
                            />
                          </FormUnform>
                        </ContainerInputCupom>
                      ) : (
                        <></>
                      )}
                    </ContainerCupom>
                  </ContainerGrande>
                </Linha>
              )}
              {valorFinal > 0 ? (
                <>
                  <Linha>
                    <ContainerGrande>
                      <SelectUnform
                        id="slc_metodo_pagamento"
                        name="metodoPagamento"
                        label="Selecione a Forma de Pagamento"
                        obrigatorio
                        opcoes={SELECT_METODOSPAGAMENTO.filter(
                          x => x.id !== 'Pix'
                        )}
                        valorAlterado={valor => {
                          definirMetedoPagamento(valor.id)
                          definirNumeroCartao('')

                          formRef.current?.setErrors({})
                        }}
                      />
                    </ContainerGrande>
                  </Linha>
                  {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id && (
                    <>
                      <AvisoFinanceiro>
                        Nesta forma de pagamento, é comprometido o limite total
                        do valor da taxa a ser paga, porém parcelada na
                        quantidade de parcelas escolhidas
                      </AvisoFinanceiro>
                      <ContainerGrande>
                        <SelectUnform
                          name="quantidadeParcelasTCC"
                          obrigatorio
                          id={'slct_qtd_parcelas_tcc'}
                          label="Selecione a Quantidade de Parcelas"
                          iconeFechar
                          opcoes={SELECT_PARCELAS_CARTAO}
                        />
                      </ContainerGrande>
                      <LinhaBandeiras>
                        <ContainerBandeiras
                          selecionado={
                            !!numeroCartao.match(regExpVisa) &&
                            !numeroCartao.match(regExpElo)
                          }
                        >
                          {IconeCartaoVisa}
                        </ContainerBandeiras>
                        <ContainerBandeiras
                          selecionado={!!numeroCartao.match(regExpMaster)}
                        >
                          {IconeCartaoMaster}
                        </ContainerBandeiras>
                        <ContainerBandeiras
                          selecionado={!!numeroCartao.match(regExpAmex)}
                        >
                          {IconeCartaoAmex}
                        </ContainerBandeiras>
                        <ContainerBandeiras
                          selecionado={!!numeroCartao.match(regExpElo)}
                        >
                          {IconeCartaoElo}
                        </ContainerBandeiras>
                        <ContainerBandeiras
                          selecionado={!!numeroCartao.match(regExpHiper)}
                        >
                          {IconeCartaoHiper}
                        </ContainerBandeiras>
                        <ContainerBandeiras
                          selecionado={!!numeroCartao.match(regExpJcb)}
                        >
                          {IconeCartaoJcb}
                        </ContainerBandeiras>
                        <ContainerBandeiras
                          selecionado={!!numeroCartao.match(regExpDiners)}
                        >
                          {IconeCartaoDiners}
                        </ContainerBandeiras>
                      </LinhaBandeiras>
                      <CartaoCreditoInputUnform
                        id="ipt_numero_cartao"
                        name="numeroCartao"
                        label="Número do Cartão"
                        obrigatorio
                        onChange={e => {
                          const valor = e.target.value.replaceAll(' ', '')
                          definirNumeroCartao(valor)
                        }}
                      />
                      <InputUnform
                        id="ipt_nome_cartao"
                        name="nomeCartao"
                        label="Nome Conforme Consta no Cartão"
                        obrigatorio
                      />
                      <Linha>
                        <ContainerMedio>
                          <InputMesAnoUnform
                            id="ipt_validade_cartao"
                            name="validadeCartao"
                            label="Validade do Cartão"
                            placeholder="mm/aaaa"
                            formatoAno="AAAA"
                            obrigatorio
                          />
                        </ContainerMedio>
                        <ContainerMedio>
                          <InputUnform
                            id="ipt_codigo_seguranca"
                            name="codigoSeguranca"
                            label={
                              <>
                                Código de Segurança
                                <IconeLabel
                                  data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                                  data-for="tooltip-cvv"
                                >
                                  {IconeInformacao}
                                </IconeLabel>
                              </>
                            }
                            obrigatorio
                            maxLength={4}
                          />
                          <Tooltip
                            id="tooltip-cvv"
                            place="right"
                            className="tooltip-ipgs"
                            textColor={Cores.PRETO}
                            backgroundColor={Cores.CINZA_1_CLARO}
                          />
                        </ContainerMedio>
                      </Linha>
                    </>
                  )}
                  {metodoPagamento === SELECT_METODOSPAGAMENTO[1].id && (
                    <Linha>
                      <ContainerMedio>
                        <SelectUnform
                          name="quantidadeParcelasTCC"
                          obrigatorio
                          id={'slct_qtd_parcelas_tcc'}
                          label="Quantidade de Parcelas"
                          opcoes={SELECT_PARCELAS_BOLETO}
                        />
                      </ContainerMedio>
                      <ContainerMedio>
                        <SelectUnform
                          name="diaVencimento"
                          label="Dia Vencimento"
                          obrigatorio
                          id={'slct_dia_vencimento_tcc'}
                          opcoes={SELECT_DIAS}
                        />
                      </ContainerMedio>
                    </Linha>
                  )}
                </>
              ) : (
                <AvisoFinanceiro>
                  ISENTO: Não há parcelas a pagar
                </AvisoFinanceiro>
              )}
              <Linha>
                <ContainerGrande>
                  <AvisoFinanceiro>
                    Esta ação é irreversível e não será possível alterar a opção
                    escolhida no futuro.
                  </AvisoFinanceiro>
                </ContainerGrande>
              </Linha>
              <Linha>
                <ContainerGrande>
                  <Checkbox
                    texto="Estou ciente da minha escolha e aceito os termos de realização do TCC"
                    htmlFor="chk_confirmacao_tcc"
                    tamanho="L"
                  >
                    <input
                      type="checkbox"
                      id="chk_confirmacao_tcc"
                      checked={ehAceiteAluno}
                      onChange={() => {
                        definirEhAceiteAlunoTCC(old => !old)
                      }}
                    />
                  </Checkbox>
                </ContainerGrande>
              </Linha>
              <ContainerBotoes>
                <Botao
                  type="button"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                  disabled={carregando}
                />
                <Botao
                  type="submit"
                  id="btn-salvar"
                  texto="Salvar"
                  disabled={!ehAceiteAluno}
                  carregando={carregando}
                />
              </ContainerBotoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalOpteTCC = forwardRef(Componente)
