import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  BotaoStep,
  PdfViewer,
  FormUnform,
  FormRef,
  CheckboxUnform,
  WizardContainer,
  scrollParaTopo,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { TermosCondicoesMatricula } from 'src/tipos'

import MatriculaContainer from '../../container'
import {
  ContainerAcoes,
  ContainerConteudo,
  Opcao,
  OpcaoDados
} from '../../styles'
import { ContainerPDFViewer, ContainerCheckbox } from './styles'

export const TermosCondicoes: React.FC = () => {
  const formRef = useRef<FormRef>(null)
  const {
    matricula,
    definirPasso,
    definirTermosCondicoes
  } = MatriculaContainer.useContainer()
  const { mudarEtapa, voltarWizard, passo } = WizardContainer.useContainer()

  const [carregandoAvancar, definirCarregandoAvancar] = useState(false)
  const [carregandoVoltar, definirCarregandoVoltar] = useState(false)
  const [liAceitoTermos, definirLiAceitoTermoseitoTermos] = useState(
    matricula.termosCondicoes?.liAceitoTermos ?? false
  )
  const [estouCiente, definirEstouCiente] = useState(
    matricula.termosCondicoes?.estouCiente ?? false
  )
  const [contrato, definirContrato] = useState<string>('')
  const [carregando, definirCarregando] = useState(true)
  const { limparErro, definirErro } = ErroLayoutContainer.useContainer()
  const history = useHistory()
  const dadosIniciais = useMemo(() => {
    if (matricula.termosCondicoes) {
      const { liAceitoTermos, estouCiente } = matricula.termosCondicoes

      return { liAceitoTermos, estouCiente }
    }

    return {}
  }, [matricula.termosCondicoes])

  const carregarTermosCondicoes = async () => {
    try {
      limparErro()
      definirCarregando(true)
      const novoContrato = await Api.RequisitarContatoTermoCondicoes(
        matricula.id
      )
      definirContrato(novoContrato)
    } catch {
      const mensagens = 'Erro ao carregar contrato.'

      definirErro({
        mensagens,
        acaoVoltar: () => history.push(RotasAluno.Dashboard),
        acaoConfirmar: carregarTermosCondicoes,
        textoConfirmar: 'Recarregar'
      })
    } finally {
      setTimeout(() => definirCarregando(false), 1000)
    }
  }

  useEffect(() => {
    scrollParaTopo()
  }, [passo])

  useEffect(() => {
    carregarTermosCondicoes()
  }, []) //eslint-disable-line

  const acaoSucesso = async (
    dados: Omit<TermosCondicoesMatricula, 'urlTermosCondicoes'>
  ) => {
    try {
      definirCarregandoAvancar(true)

      await Api.AvancarTermosCondicoesOutrosCursos(matricula.id, dados)

      definirTermosCondicoes(dados)

      mudarEtapa(4)
      definirPasso(4)
    } finally {
      definirCarregandoAvancar(false)
    }
  }
  return (
    <ContainerConteudo>
      <h2>Termos e Condições</h2>
      {carregando ? (
        <Carregando texto="Carregando termos e condições de uso..." />
      ) : (
        <>
          {contrato ? (
            <FormUnform
              ref={formRef}
              dadosIniciais={dadosIniciais}
              acaoSucesso={acaoSucesso}
            >
              <ContainerPDFViewer>
                <PdfViewer
                  url={contrato}
                  descricaoMobile="Clique no botão abaixo para baixar o contrato"
                  nomeArquivo="contrato"
                />
              </ContainerPDFViewer>
              <ContainerCheckbox>
                <Opcao>
                  <OpcaoDados>
                    <CheckboxUnform
                      id="chk_aceito_termos"
                      name="liAceitoTermos"
                      onChange={() =>
                        definirLiAceitoTermoseitoTermos(old => !old)
                      }
                    />
                    <label htmlFor="chk_aceito_termos">
                      Li e aceito os termos e condições do Contrato de Prestação
                      de Serviços Educacionais
                    </label>
                  </OpcaoDados>
                </Opcao>
              </ContainerCheckbox>
              <ContainerCheckbox>
                <Opcao>
                  <OpcaoDados>
                    <CheckboxUnform
                      id="chk_ciente_cancelar"
                      name="estouCiente"
                      onChange={() => definirEstouCiente(old => !old)}
                    />
                    <label htmlFor="chk_ciente_cancelar">
                      Estou ciente que caso eu finalize a matrícula e opte por
                      desistência, devo comunicar o setor comercial para
                      cancelar a inscrição no curso
                    </label>
                  </OpcaoDados>
                </Opcao>
              </ContainerCheckbox>
              <ContainerAcoes>
                <BotaoStep
                  invertido
                  texto="Voltar para"
                  textoNegrito="Plano de Pagamento do Curso"
                  tema="Secundario"
                  disabled={carregandoVoltar}
                  type="button"
                  onClick={() => {
                    definirCarregandoVoltar(true)
                    voltarWizard()
                  }}
                />
                <BotaoStep
                  texto="Ir para"
                  textoNegrito="Pagamento da Matrícula"
                  type="submit"
                  carregando={carregandoAvancar}
                  disabled={!liAceitoTermos || !estouCiente}
                  textoTooltip={
                    !liAceitoTermos || !estouCiente
                      ? 'Para avançar, você deve primeiro aceitar os termos e condições acima'
                      : undefined
                  }
                />
              </ContainerAcoes>
            </FormUnform>
          ) : (
            <ErroContainerLayout />
          )}
        </>
      )}
    </ContainerConteudo>
  )
}
