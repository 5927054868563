import styled from 'styled-components'

import { FormUnform } from '../unform/form'

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const Dados = styled.div`
  margin-top: 25px;
`

export const Secao = styled.section`
  margin-top: 30px;
`

export const ContainerMain = styled.div`
  width: 70%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const Conteudo = styled.div`
  margin-top: 18px;
  margin-left: 30px;
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDadosMedio = styled.div`
  width: 50%;
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosPequeno = styled.div`
  width: 23%;
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 19px;
  }
`

export const ContainerLateral = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 63px;
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerDados100 = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDados75 = styled.div`
  width: 75%;
  float: left;
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDados25 = styled.div`
  width: 25%;
  float: left;
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDados50 = styled.div`
  width: 50%;
  &:not(:last-child) {
    margin-right: 19px;
  }
`
