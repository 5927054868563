import styled from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.li`
  time {
    display: block;
    margin-bottom: 5px;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    margin-bottom: 5px;
  }

  a {
    font-weight: 300;
    color: ${Cores.PRETO};
    text-decoration: none;
  }
`
