import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import {
  dataFormatadaComBarras,
  transformarPadraoAmericano,
  dataValidaAmericana,
  dataValida
} from '../../funcoes/data-hora'
import { Input } from '../../input'
import { Componente, Erro } from './styles'
import { DataInputUnformProps, DataInputUnformRef } from './tipos'

export const DataInputUnform: React.ForwardRefExoticComponent<
  Omit<DataInputUnformProps, 'mascara'>
> = forwardRef<DataInputUnformRef, Omit<DataInputUnformProps, 'mascara'>>(
  (
    { name, valor: valorExterno, semMargemAbaixo, children, onChange, ...rest },
    ref: any
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const {
      fieldName,
      defaultValue,
      registerField,
      error,
      clearError
    } = useField(name || '')
    const [valor, definirValor] = useState('')

    useEffect(() => {
      if (defaultValue) {
        definirValor(dataFormatadaComBarras(new Date(defaultValue)))
      }
    }, [])

    useEffect(() => {
      if (valorExterno && dataValidaAmericana(valorExterno)) {
        definirValor(dataFormatadaComBarras(new Date(valorExterno)))
      }
    }, [valorExterno])

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue({ value }) {
          return value ? transformarPadraoAmericano(value) : ''
        },
        setValue(ref: HTMLInputElement, value: string) {
          if (!value) return
          const novaData = dataFormatadaComBarras(new Date(value))

          ref.value = novaData
          definirValor(novaData)
        },
        clearValue(ref: any) {
          ref.value = ''
          definirValor('')
        }
      })
    }, [fieldName, registerField])

    useImperativeHandle(ref, () => ({
      obterValor: () =>
        dataValida(valor) ? transformarPadraoAmericano(valor) : ''
    }))

    return (
      <Componente
        theme={{
          ComErro: !!error
        }}
        className={classNames({
          semMargemAbaixo
        })}
      >
        <Input
          {...rest}
          name={name}
          ref={inputRef}
          erro={!!error}
          className={classNames({
            error
          })}
          mascara="99/99/9999"
          type="tel"
          value={valor}
          onChange={e => {
            definirValor(e.target.value)

            if (onChange) onChange(e)
          }}
          onKeyPress={clearError}
        >
          {children}
        </Input>

        <Erro id={`erro-${rest.id}`}>{error}</Erro>
      </Componente>
    )
  }
)
