import React from 'react'

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.60078 11.8002C12.2528 11.8002 14.4008 9.6522 14.4008 7.0002C14.4008 4.3482 12.2528 2.2002 9.60078 2.2002C6.94878 2.2002 4.80078 4.3482 4.80078 7.0002C4.80078 9.6522 6.94878 11.8002 9.60078 11.8002ZM9.60078 4.6002C10.9208 4.6002 12.0008 5.6802 12.0008 7.0002C12.0008 8.3202 10.9208 9.4002 9.60078 9.4002C8.28078 9.4002 7.20078 8.3202 7.20078 7.0002C7.20078 5.6802 8.28078 4.6002 9.60078 4.6002Z"
      fill="white"
    />
    <path
      d="M2.4 19.0002C2.664 18.1362 6.372 16.6002 9.6 16.6002C9.6 15.7602 9.756 14.9562 10.02 14.2122C6.744 14.0922 0 15.7242 0 19.0002V21.4002H11.448C10.824 20.7042 10.332 19.9002 10.02 19.0002H2.4Z"
      fill="white"
    />
    <path
      d="M20.916 19.024C21.348 18.316 21.6 17.488 21.6 16.6C21.6 13.948 19.452 11.8 16.8 11.8C14.148 11.8 12 13.948 12 16.6C12 19.252 14.148 21.4 16.8 21.4C17.688 21.4 18.516 21.136 19.224 20.716C20.34 21.832 21.168 22.66 22.308 23.8L24 22.108C22.2 20.308 23.052 21.148 20.916 19.024ZM16.8 19C15.48 19 14.4 17.92 14.4 16.6C14.4 15.28 15.48 14.2 16.8 14.2C18.12 14.2 19.2 15.28 19.2 16.6C19.2 17.92 18.12 19 16.8 19Z"
      fill="white"
    />
  </svg>
)
