import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  TextAreaUnform,
  SelectUnform
} from 'src/componentes'
import { SetorTicket } from 'src/tipos'

import { ContainerAcoes, ContainerModal, CorpoModal } from './styles.ts'
import {
  ModalAlterarSetorRef,
  ModalAlterarSetorProps,
  AlterarSetor
} from './tipos'
const Modal: React.ForwardRefRenderFunction<
  ModalAlterarSetorRef,
  ModalAlterarSetorProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [idTicket, definirIdTicket] = useState<string>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = (idTicket: string) => {
    definirIdTicket(idTicket)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalAlterarSetorRef, ModalAlterarSetorRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: AlterarSetor) => {
    try {
      definirCarregando(true)
      const dadosAlterarSetor = {
        ...dados,
        idTicket
      }

      await acaoPrimaria(dadosAlterarSetor)
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  return (
    <ModalBase id="modal-alterar-setor" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Alterar Setor</h5>
          {pronto && (
            <FormUnform acaoSucesso={acaoSucessoForm} ref={formRef}>
              <SelectUnform
                id="slct_setor"
                name="setor"
                label="Setor"
                opcoes={[
                  {
                    id: SetorTicket[SetorTicket.Gerencial],
                    texto: 'Gerencial'
                  },
                  {
                    id: SetorTicket[SetorTicket.Financeiro],
                    texto: 'Financeiro'
                  },
                  {
                    id: SetorTicket[SetorTicket.Academico],
                    texto: 'Acadêmico'
                  },
                  {
                    id: SetorTicket[SetorTicket.Comercial],
                    texto: 'Comercial'
                  }
                ]}
              />
              <TextAreaUnform
                id="ipt_observacao"
                name="observacao"
                label="Observação"
              />
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Alterar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}
export const ModalAlterarSetor = forwardRef(Modal)
