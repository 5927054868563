import { Escolaridade, EscolaridadePorExtenso } from 'src/tipos'

export const SELECT_ESCOLARIDADE = [
  {
    id: Escolaridade[Escolaridade.Mestrado].toString(),
    texto: EscolaridadePorExtenso[Escolaridade.Mestrado].toString()
  },
  {
    id: Escolaridade[Escolaridade.Doutorado].toString(),
    texto: EscolaridadePorExtenso[Escolaridade.Doutorado].toString()
  },
  {
    id: Escolaridade[Escolaridade.PosDoutorado].toString(),
    texto: EscolaridadePorExtenso[Escolaridade.PosDoutorado].toString()
  },
  {
    id: Escolaridade[Escolaridade.EnsinoSuperiorCompleto].toString(),
    texto: EscolaridadePorExtenso[
      Escolaridade.EnsinoSuperiorCompleto
    ].toString()
  },
  {
    id: Escolaridade[Escolaridade.EnsinoSuperiorEmAndamento].toString(),
    texto: EscolaridadePorExtenso[
      Escolaridade.EnsinoSuperiorEmAndamento
    ].toString()
  },
  {
    id: Escolaridade[Escolaridade.EnsinoMedio].toString(),
    texto: EscolaridadePorExtenso[Escolaridade.EnsinoMedio].toString()
  },
  {
    id: Escolaridade[Escolaridade.PosGraduado].toString(),
    texto: EscolaridadePorExtenso[Escolaridade.PosGraduado].toString()
  }
]
