import styled from 'styled-components'

export const Secao = styled.section`
  &:not(:first-of-type) {
    margin-top: 30px;
  }
`

export const Conteudo = styled.main`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  word-break: break-word;
  padding-left: 36px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .ipt_banco_slc {
    margin-bottom: 25px;
  }
`
export const ContainerMain = styled.div`
  width: 100%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const ContainerAcoes = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 20px;
  }
`

export const ContainerNavegacao = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 58px;
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`
export const ContainerDadosMedio = styled.div`
  width: 50%;
`
export const Carregamento = styled.div`
  position: absolute;
  padding: 25px 0;
  z-index: 1;
  white-space: nowrap;
  width: 170px;
  top: 378px;
  right: 61%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  svg {
    circle {
      stroke: #dbdbdb;
    }
  }
`
