import styled, { css } from 'styled-components'

import { Cores } from '../../styles/cores'
import { StepperItemTipo } from '../tipos'

interface ItemProps {
  ativo?: boolean
  tipo?: StepperItemTipo
  ehFilho?: boolean
  temSubItens?: boolean
  aberto?: boolean
  bloqueado?: boolean
  ehFlex?: boolean
}

interface SubItensProp {
  tema: 'padrao' | 'secundario'
  aberto: boolean
}

export const Container = styled.div`
  margin-top: 4px;
`

const ItemTipoVariacoes = (tipo: StepperItemTipo) => {
  switch (tipo) {
    case 'padrao':
      return {
        textColor: Cores.SECUNDARIA_1_CLARA_ESCURA,
        justifyContent: 'center',
        pFlex: 'unset',
        marginLeftIcone: '0px',
        marginRightIcone: '6px'
      }
    case 'check':
      return {
        textColor: Cores.CINZA_2_ESCURO,
        justifyContent: 'flex-start',
        pFlex: '1',
        marginLeftIcone: '20px',
        marginRightIcone: '19px'
      }
    case 'download':
      return {
        textColor: Cores.CINZA_2_ESCURO,
        justifyContent: 'flex-start',
        pFlex: '1',
        marginLeftIcone: '20px',
        marginRightIcone: '19px'
      }
    case 'url':
      return {
        textColor: Cores.CINZA_2_ESCURO,
        justifyContent: 'flex-start',
        pFlex: '1',
        marginLeftIcone: '20px',
        marginRightIcone: '19px'
      }
    case 'trocaModuloFlex':
      return {
        textColor: Cores.SECUNDARIA_1_CLARA_ESCURA,
        justifyContent: 'center',
        pFlex: 'unset',
        marginLeftIcone: '0px',
        marginRightIcone: '6px'
      }
    default:
      return {
        textColor: Cores.BRANCO,
        justifyContent: 'center',
        pFlex: 'unset',
        marginLeftIcone: '0px',
        marginRightIcone: '6px'
      }
  }
}

export const SubItens = styled.div<SubItensProp>`
  display: hidden;
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;

  ${props =>
    props.aberto &&
    css`
      border: 1px solid
        ${props.tema === 'padrao'
          ? Cores.AZUL_CINZA
          : Cores.SECUNDARIA_2_MAIS_ESCURO};
      border-top: 0px;
      max-height: 99999px;
      transition: max-height 0.3s ease-in;
    `}
`

export const ContainerItem = styled.div.attrs<ItemProps>(props => ({
  ...props,
  tipo: props.tipo ?? 'padrao',
  temSubItens: props.temSubItens ?? true,
  ehFilho: props.ehFilho ?? false,
  ehFlex: props.ehFlex ?? false
}))<ItemProps>`
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px ${props => props.tipo === 'check' && ' 4px 10px 10px'};
  width: 100%;

  position: relative;
  display: flex;
  justify-content: ${props => ItemTipoVariacoes(props.tipo).justifyContent};
  align-items: center;
  transition: all 0.2s;
  color: ${props => ItemTipoVariacoes(props.tipo).textColor};

  .seleciona-checkbox {
    margin-right: 0px;
  }

  & > div.stepper-item-titulo {
    display: flex;
    align-items: center;

    text-align: left;
    flex: ${props => ItemTipoVariacoes(props.tipo).pFlex};

    ${props =>
      props.temSubItens &&
      css`
        justify-content: center;
        flex: 1;
      `}

    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 125%;
    }

    & > span {
      position: absolute;
      font-size: 14px;
      padding: 0px 4px;
      left: -1px;
      top: -1px;
      letter-spacing: 1px;
    }
  }

  & > .stepper-item-icone {
    transition: transform 0.2s;

    ${props =>
      props.aberto &&
      css`
        transform: rotate(180deg);
      `}

    margin-left: ${props => ItemTipoVariacoes(props.tipo).marginLeftIcone};
    margin-right: ${props => ItemTipoVariacoes(props.tipo).marginRightIcone};

    & > svg > path {
      &:not(.semFill) {
        fill: currentColor;
      }

      &[class*='comStroke'] {
        stroke: currentColor;
      }
    }
  }

  ${props =>
    !props.bloqueado &&
    css`
      &:hover {
        background: ${Cores.SECUNDARIA_1_CLARA_ESCURA};
        color: ${Cores.BRANCO};
      }
    `}

  ${props =>
    !props.temSubItens &&
    css`
      border: 0px;
      background: ${Cores.BRANCO};
    `}

  ${props =>
    !props.ehFilho &&
    css`
      border: 1px solid ${Cores.SECUNDARIA_1_CLARA_ESCURA};
      border-radius: 4px;
    `}

  ${props =>
    !props.ehFilho &&
    props.temSubItens &&
    css`
      background-color: ${Cores.AZUL_CINZA};
      color: ${Cores.BRANCO};
      border-radius: 0;
      border: 1px solid ${Cores.AZUL_CINZA};
      padding: 20px 5px;

      ${!props.bloqueado &&
      css`
        &:hover {
          background: ${Cores.AZUL_CINZA};
        }
      `}
    `}

    ${props =>
    !props.ehFilho &&
    props.temSubItens &&
    props.ehFlex &&
    css`
      background-color: ${Cores.PRIMARIA_2_ESCURO};
      border: 1px solid ${Cores.PRIMARIA_2_ESCURO};

      ${!props.bloqueado &&
      css`
        &:hover {
          background: ${Cores.PRIMARIA_2_ESCURO};
        }
      `}
    `}

    ${props =>
    props.ehFilho &&
    props.temSubItens &&
    css`
      background-color: ${Cores.SECUNDARIA_1_CLARA_ESCURA};
      color: ${Cores.BRANCO};
      border-radius: 0;
      border: 1px solid ${Cores.SECUNDARIA_2_MAIS_ESCURO};
      padding: 20px 5px;

      ${!props.bloqueado &&
      css`
        &:hover {
          background: ${Cores.SECUNDARIA_1_CLARA_ESCURA};
        }
      `}
    `}

    ${props =>
    props.ativo &&
    css`
      background: ${Cores.SECUNDARIA_2_MAIS_ESCURO};
      border: 1px solid ${Cores.SECUNDARIA_2_MAIS_ESCURO};
      color: ${Cores.BRANCO};

      & > div.stepper-item-titulo > p {
        font-size: 13px;
      }
    `}

    ${props =>
    props.ehFilho &&
    !props.temSubItens &&
    css`
      border: 0px;
      border-top: 1px solid ${Cores.CINZA_1_CLARO};

      &:first-child {
        border-top: 0px;
      }

      &:last-child {
        border-bottom: 0px;
      }
    `}

    ${props =>
    props.bloqueado &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`

export const BotaoDownload = styled.div`
  border: 0;
  background-color: transparent;
  cursor: pointer;
`
