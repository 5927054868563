import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'

const MENSAGEM_FEEDBACK =
  'Para salvar, é preciso informar uma nota e um feedback com no mínimo 50 caracteres.'

export const schemaComUpload = Yup.object().shape({
  nota: Yup.number()
    .nullable()
    .test(
      'nota',
      'A nota deve estar entre 0 e 10',
      (valor: number) => valor >= 0 && valor <= 10
    )
    .required(mensagemPadrao)
})

export const schemaPresencial = Yup.object().shape({
  nota: Yup.number()
    .nullable()
    .test(
      'nota',
      'A nota deve estar entre 0 e 10',
      (valor: number) => valor >= 0 && valor <= 10
    )
    .required(mensagemPadrao)
})

export const schemaNotasTCC = (
  ehProfessorOrientador: boolean,
  visaoIPGS: boolean
): Yup.ObjectSchema => {
  if (visaoIPGS) {
    return Yup.object().shape({
      notaOrientador: Yup.number()
        .nullable()
        .min(0, 'Nota mínima é 0.')
        .max(10, 'Nota máxima é 10.'),
      feedbackOrientador: Yup.string()
        .nullable()
        .transform((input, valor) => (input === '' ? null : valor))
        .min(50, MENSAGEM_FEEDBACK),
      notaBanca: Yup.number()
        .nullable()
        .min(0, 'Nota mínima é 0.')
        .max(10, 'Nota máxima é 10.'),
      feedbackBanca: Yup.string()
        .nullable()
        .transform((input, valor) => (input === '' ? null : valor))
        .min(50, MENSAGEM_FEEDBACK)
    })
  }

  if (ehProfessorOrientador) {
    return Yup.object().shape({
      notaOrientador: Yup.number()
        .typeError(mensagemPadrao)
        .min(0, 'Nota mínima é 0.')
        .max(10, 'Nota máxima é 10.'),
      feedbackOrientador: Yup.string()
        .required(mensagemPadrao)
        .min(50, MENSAGEM_FEEDBACK)
    })
  } else {
    return Yup.object().shape({
      notaBanca: Yup.number()
        .typeError(mensagemPadrao)
        .min(0, 'Nota mínima é 0.')
        .max(10, 'Nota máxima é 10.'),
      feedbackBanca: Yup.string()
        .required(mensagemPadrao)
        .min(50, MENSAGEM_FEEDBACK)
    })
  }
}
