import React from 'react'

export default (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10131 10.2583C4.87248 10.0295 4.87248 9.65847 5.1013 9.42965L9.42891 5.10204C9.65774 4.87321 10.0287 4.87321 10.2576 5.10204C10.4864 5.33086 10.4864 5.70185 10.2576 5.93068L5.92995 10.2583C5.70112 10.4871 5.33013 10.4871 5.10131 10.2583Z"
      fill="#E6414B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.20924 5.10204C5.43807 4.87322 5.80906 4.87322 6.03788 5.10204L10.3655 9.42965C10.5943 9.65847 10.5943 10.0295 10.3655 10.2583C10.1367 10.4871 9.76567 10.4871 9.53685 10.2583L5.20924 5.93068C4.98042 5.70186 4.98042 5.33086 5.20924 5.10204Z"
      fill="#E6414B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 13.8281C10.9949 13.8281 13.8281 10.9949 13.8281 7.5C13.8281 4.00507 10.9949 1.17188 7.5 1.17188C4.00507 1.17188 1.17188 4.00507 1.17188 7.5C1.17188 10.9949 4.00507 13.8281 7.5 13.8281ZM7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
      fill="#E6414B"
    />
  </svg>
)
