import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Cores,
  CpfInputUnform,
  ErroLayoutContainer,
  Filtro,
  IconeDeFinancasDoAluno,
  IconeFileAluno,
  IconeEditar,
  IconeDeListaDeDocumentos,
  IconeAdd,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform,
  Tooltip,
  SelectOpcao,
  AutenticacaoContainer,
  DataInputUnform
} from 'src/componentes'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { PesquisaAlunoDTO } from 'src/dto/aluno'
import { IconeTabela } from 'src/paginas/ipgs/turmas/acoes-finalizacao/styles'
import { RotasAlunos } from 'src/rotas'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import {
  RequisitarListaTurmasPorTexto,
  RequisitarListaCursosPorTextoFiltro
} from 'src/servicos/api'
import { AlunoSituacao, Nacionalidade, TipoOrdenacao } from 'src/tipos'

import {
  ModalNovaMatricula,
  ModalNovaMatriculaRef,
  NovaMatriculaAluno
} from './componentes/moda-nova-matricula'
import {
  ModalInserirNota,
  ModalInserirNotaRef,
  NovaNota
} from './componentes/modal-inserir-nota'
import { SituacaoMatricula } from './edicao/tipos'
import {
  Form,
  Container,
  Campo,
  Dados,
  ContainerDeAcoes,
  IconeDeAcao,
  BotaoDeAcao
} from './styles'

interface DadosPesquisa {
  cpf: string
  nome: string
  email: string
  situacao: keyof typeof AlunoSituacao
  turma: string
  curso: string
  dataInicioMatricula: string
  dataFimMatricula: string
  situacaoMatricula: keyof typeof SituacaoMatricula
}

export const PaginaAlunos: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const modalNovaMatricula = useRef<ModalNovaMatriculaRef>(null)
  const modalInserirNota = useRef<ModalInserirNotaRef>(null)
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState(null)
  const [turmaSelecionada, definirTurmaSelecionada] = useState<string>(null)
  const [cursoSelecionado, definirCursoSelecionado] = useState<string>(null)
  const { validarPerfilUnico, perfil } = AutenticacaoContainer.useContainer()

  const history = useHistory()

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.RequisitarListaAlunos(
      dadosPesquisa?.nome,
      dadosPesquisa?.cpf,
      dadosPesquisa?.email,
      dadosPesquisa?.situacao,
      dadosPesquisa?.turma,
      dadosPesquisa?.curso,
      dadosPesquisa?.situacaoMatricula,
      dadosPesquisa?.dataInicioMatricula,
      dadosPesquisa?.dataFimMatricula,
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const formatarCPF = (linha: TabelaDados) => {
    if (linha && linha.documento) {
      linha = linha.documento.replace(/[^\d]/g, '')
      return linha.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }

    return ''
  }

  const turmaAlterada = (opcao: SelectOpcao) => {
    definirTurmaSelecionada(opcao?.id)
  }

  const cursoAlterado = (opcao: SelectOpcao) => {
    definirCursoSelecionado(opcao?.id)
  }

  const deveLimparSituacaoMatricula = useCallback(
    () => !turmaSelecionada && !cursoSelecionado,
    [turmaSelecionada, cursoSelecionado]
  )

  const enviarNovaMatricula = async (dados: NovaMatriculaAluno) => {
    try {
      await Api.PostNovaMatricula(dados.alunoId, dados.turmaId)
      toast('Nova matrícula criada com sucesso', { type: 'success' })
    } catch {
      toast('Erro ao criar nova matrícula', { type: 'error' })
    }
  }

  const enviarNovaNota = async (dados: NovaNota) => {
    try {
      await Api.PostNovaNota(dados.idModulo, dados.nota, dados.matriculaId)
      toast('Nota Inserida com sucesso', { type: 'success' })
    } catch {
      toast('Erro ao inserir nota', { type: 'error' })
    }
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const perfilParceiro = useMemo(() => {
    return validarPerfilUnico('Parceiro')
  }, [perfil, validarPerfilUnico])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Alunos"
          atalhos={[
            {
              texto: 'Acadêmico'
            },
            {
              texto: 'Alunos'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: AlunoSituacao[AlunoSituacao.Todas]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo mr>
              <CpfInputUnform type="text" name="cpf" label="CPF" />
            </Campo>
            <Campo mr>
              <InputUnform
                type="text"
                name="nome"
                label="Nome"
                maxLength={200}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <InputUnform
                type="email"
                name="email"
                label="E-mail"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_situacao"
                name="situacao"
                label="Situação"
                iconeFechar={false}
                opcoes={[
                  {
                    id: AlunoSituacao[AlunoSituacao.Todas],
                    texto: 'Todas'
                  },
                  {
                    id: AlunoSituacao[AlunoSituacao.Ativo],
                    texto: 'Ativo'
                  },
                  {
                    id: AlunoSituacao[AlunoSituacao.Inativo],
                    texto: 'Inativo'
                  }
                ]}
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataInicioMatricula"
                label="Início Período Matrícula"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataFimMatricula"
                label="Fim Período Matrícula"
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_turma"
                name="turma"
                label="Turma"
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
                valorAlterado={turmaAlterada}
              />
            </Campo>
            <Campo mr>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                buscarPorTexto={RequisitarListaCursosPorTextoFiltro}
                buscarPorId={RequisitarListaCursosPorTextoFiltro}
                valorAlterado={cursoAlterado}
                disabled={perfilParceiro}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_situacao_matricula"
                name="situacaoMatricula"
                label="Situação Matricula"
                iconeFechar={true}
                margemInferior={false}
                disabled={!turmaSelecionada && !cursoSelecionado}
                deveLimpar={deveLimparSituacaoMatricula}
                opcoes={[
                  {
                    id: 'Todas',
                    texto: 'Todas'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.Cancelado],
                    texto: 'Cancelado'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.ContratoRescindido],
                    texto: 'Contrato Rescindido'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.Finalizado],
                    texto: 'Finalizado'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.Inativo],
                    texto: 'Inativo'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.Matriculado],
                    texto: 'Matriculado'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.Reprovado],
                    texto: 'Reprovado'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.Reservado],
                    texto: 'Reservado'
                  },
                  {
                    id: SituacaoMatricula[SituacaoMatricula.Transferido],
                    texto: 'Transferido'
                  }
                ]}
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'cpf',
              Texto: 'CPF',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => formatarCPF(linha.cpf)
            },
            {
              Chave: 'nome',
              Texto: 'Nome',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.nomeApresentacao}</span>
              )
            },
            {
              Chave: 'email',
              Texto: 'E-mail',
              ComOrdenacao: true
            },
            {
              Chave: 'situacao',
              Texto: 'Situação',
              ComOrdenacao: true
            },
            {
              Chave: 'acoes',
              Texto: '',
              Renderizar: (linha: TabelaDados) => {
                const listaDeDocumentosTooltipId = `LISTA_DE_DOCUMENTOS_ID/${linha.idUsuario}`
                const dadosFinanceirosTooltipId = `DADOS_FINANCEIROS_ID/${linha.idUsuario}`
                const situacaoDoAlunoTooltipId = `SITUACAO_DO_ALUNO_ID/${linha.idUsuario}`
                const novaMatriculaTooltipId = `NOVA_MATRICULA_ID/${linha.idUsuario}`

                return (
                  !perfilParceiro && (
                    <ContainerDeAcoes>
                      <BotaoDeAcao
                        onClick={() => {
                          const rota = `${RotasAluno.PainelFinanceiro}/${linha.idUsuario}`
                          history.push(rota)
                        }}
                        onContextMenu={() => {
                          const rota = window.open(
                            `${RotasAluno.PainelFinanceiro}/${linha.idUsuario}`
                          )
                          rota.focus()
                        }}
                      >
                        <div
                          data-tip="Ver dados financeiros"
                          data-for={dadosFinanceirosTooltipId}
                        >
                          {IconeDeFinancasDoAluno}
                        </div>
                        <Tooltip
                          id={dadosFinanceirosTooltipId}
                          place={'right'}
                          textColor={Cores.PRETO}
                          backgroundColor={Cores.CINZA_1_CLARO}
                        />
                      </BotaoDeAcao>
                      <IconeDeAcao
                        onClick={() => {
                          const aluno = (linha as unknown) as PesquisaAlunoDTO

                          const caminho =
                            aluno.nacionalidade === Nacionalidade[0]
                              ? RotasAlunos.DocumentosAlunoBrasileiro
                              : RotasAlunos.DocumentosAlunoEstrangeiro

                          const rota = `${caminho}/${aluno.email}`

                          history.push(rota)
                        }}
                        onContextMenu={() => {
                          const aluno = (linha as unknown) as PesquisaAlunoDTO

                          const caminho =
                            aluno.nacionalidade === Nacionalidade[0]
                              ? RotasAlunos.DocumentosAlunoBrasileiro
                              : RotasAlunos.DocumentosAlunoEstrangeiro

                          const rota = window.open(`${caminho}/${aluno.email}`)
                          rota.focus()
                        }}
                        data-tip={'Ver documentos do aluno'}
                        data-for={listaDeDocumentosTooltipId}
                      >
                        {IconeDeListaDeDocumentos}
                        <Tooltip
                          id={listaDeDocumentosTooltipId}
                          textColor={Cores.PRETO}
                          backgroundColor={Cores.CINZA_1_CLARO}
                          place={'right'}
                        />
                      </IconeDeAcao>
                      <BotaoDeAcao
                        onClick={() => {
                          const rota = `${RotasAlunos.Visualizar}/${linha.id}`
                          history.push(rota)
                        }}
                        onContextMenu={() => {
                          const rota = window.open(
                            `${RotasAlunos.Visualizar}/${linha.id}`
                          )
                          rota.focus()
                        }}
                      >
                        <div
                          data-tip="Ver situação do aluno"
                          data-for={situacaoDoAlunoTooltipId}
                        >
                          {IconeFileAluno}
                        </div>
                        <Tooltip
                          id={situacaoDoAlunoTooltipId}
                          place={'right'}
                          textColor={Cores.PRETO}
                          backgroundColor={Cores.CINZA_1_CLARO}
                        />
                      </BotaoDeAcao>
                      <BotaoDeAcao
                        onClick={() => {
                          modalNovaMatricula?.current?.abrir(linha.id)
                        }}
                      >
                        <div
                          data-tip="Adicionar Matrícula"
                          data-for={novaMatriculaTooltipId}
                        >
                          {IconeAdd}
                        </div>
                        <Tooltip
                          id={novaMatriculaTooltipId}
                          place={'right'}
                          textColor={Cores.PRETO}
                          backgroundColor={Cores.CINZA_1_CLARO}
                        />
                      </BotaoDeAcao>
                      <BotaoDeAcao
                        onClick={() => {
                          modalInserirNota?.current?.abrir(linha.id)
                        }}
                      >
                        <div
                          data-tip="Adicionar Nota"
                          data-for={novaMatriculaTooltipId}
                        >
                          <IconeTabela>{IconeEditar}</IconeTabela>
                        </div>
                        <Tooltip
                          id={novaMatriculaTooltipId}
                          place={'right'}
                          textColor={Cores.PRETO}
                          backgroundColor={Cores.CINZA_1_CLARO}
                        />
                      </BotaoDeAcao>
                    </ContainerDeAcoes>
                  )
                )
              }
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
      <ModalNovaMatricula
        id="modal-novaMatricula"
        ref={modalNovaMatricula}
        backdrop
        acaoPrimaria={enviarNovaMatricula}
      />
      <ModalInserirNota
        id="modal-inserirNota"
        ref={modalInserirNota}
        backdrop
        acaoPrimaria={enviarNovaNota}
      />
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaAlunos />
  </ErroLayoutContainer.Provider>
)
export { default as VisualizarAluno } from './edicao'
