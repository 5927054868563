import React, { useState, useEffect } from 'react'
import { uid } from 'react-uid'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import {
  dataFormatadaAmericana,
  dataFormatadaCalendario,
  dataValidaAmericana,
  novaDataAmericana,
  transformarDataAmericanaParaDate
} from '../funcoes/data-hora'
import * as Icones from '../icones'
import { CaixaAltaPrimeiraLetra } from '../utils/capitalize'
import { diaSemanaPrimeiraLetra, mesesDoAno } from '../utils/constants'
import { useCalendario } from './calendario-hooks'
import {
  Componente,
  Cabecalho,
  Corpo,
  CampoProximoAnterior,
  CorpoCalendario,
  TituloCalendario
} from './styles'
import { PropsCalendario } from './tipos'

export const EstruturaCalendario: React.FC<PropsCalendario> = ({
  altura,
  id
}) => {
  const quantidadeSemanasMes = new Array<string>(6).fill('')
  const quantidadeDiasSemana = new Array<string>(7).fill('')

  const [{ data, dataSugestao, aberto }, calendarioDispatch] = useCalendario()
  const [diaSelecionado, definirDiaSelecionado] = useState<undefined | Date>(
    undefined
  )

  const validarDiaSelecionado = () => {
    return !!diaSelecionado && !Number.isNaN(diaSelecionado?.getDate())
  }

  const [mesSelecionado, definirMesSelecionado] = useState<number>(
    new Date().getMonth()
  )

  const [anoSelecionado, definirAnoSelecionado] = useState<number>(
    new Date().getFullYear()
  )
  const DATA_CABECALHO = validarDiaSelecionado()
    ? dataFormatadaCalendario(diaSelecionado)
    : dataFormatadaCalendario(new Date(dataSugestao))

  const configuraData = (dia: Date) => {
    definirMesSelecionado(dia.getMonth())
    definirAnoSelecionado(dia.getFullYear())
  }

  useEffect(() => {
    if (data) {
      definirDiaSelecionado(transformarDataAmericanaParaDate(data))
    }
  }, [data])

  useEffect(() => {
    if (validarDiaSelecionado()) {
      configuraData(diaSelecionado)
    }
  }, [diaSelecionado])

  const validaData = (dataAValidar: string) => {
    if (dataValidaAmericana(dataAValidar)) {
      calendarioDispatch({ tipo: 'mudar-data', dataEscolhida: dataAValidar })
    }
  }

  function fecharCalendario(evento) {
    const classe = document.getElementById(`${id}-calendario_id`)
    const ipt = document.getElementById(id)

    if (!classe.contains(evento.target.parentNode) && evento.target !== ipt) {
      validaData(data)
      calendarioDispatch({ tipo: 'abrir-calendario', abrir: false })
    }
  }

  useEffect(() => {
    if (aberto) {
      document.body.addEventListener('click', fecharCalendario)
      return () => document.body.removeEventListener('click', fecharCalendario)
    }
  }, [fecharCalendario])

  const manipularAlteracao = (identificacao: string) => {
    calendarioDispatch({
      tipo: 'mudar-data',
      dataEscolhida: identificacao
    })
    calendarioDispatch({ tipo: 'abrir-calendario', abrir: false })
  }

  useEffect(() => {
    if (aberto && data && dataValidaAmericana(data)) {
      calendarioDispatch({
        tipo: 'mudar-data',
        dataEscolhida: data
      })
    }
  }, [aberto])

  const diaPreSelecionado = (e: string) => {
    if (diaSelecionado && e === dataFormatadaAmericana(diaSelecionado)) {
      return true
    }
    return false
  }

  const diaPreSugestao = (e: string) => {
    if (e === novaDataAmericana(dataSugestao)) {
      return true
    }
    return false
  }

  const renderizaCorpoCalendario = (mes: number, ano: number) => {
    let dataContador = 1
    const primeiroDia = new Date(ano, mes).getDay()
    return (
      <div className="calenda">
        {quantidadeSemanasMes.map((_, index) => {
          return (
            <div className="semanas" key={uid(index)}>
              {quantidadeDiasSemana.map((_, jndex) => {
                if (index === 0 && jndex < primeiroDia) {
                  return (
                    <div className="dia_do_mes" key={uid(jndex)}>
                      <label className="selecao-dia"></label>
                    </div>
                  )
                } else if (
                  dataContador >
                  32 - new Date(ano, mes, 32).getDate()
                ) {
                  return <></>
                } else {
                  const idRadioBox = `${id}-${dataContador}/${mes + 1}/${ano}`
                  const valor = novaDataAmericana(
                    `${ano}-${mes + 1}-${dataContador}`
                  )

                  dataContador++

                  return (
                    <div className="dia_do_mes" key={uid(jndex)}>
                      <input
                        name="selecao-dia"
                        type="radio"
                        value={valor}
                        id={idRadioBox}
                        className={classNames({
                          sugestao: diaPreSugestao(valor)
                        })}
                        checked={diaPreSelecionado(valor)}
                        onChange={e => manipularAlteracao(e.target.value)}
                      />
                      <label className="selecao-dia" htmlFor={idRadioBox}>
                        <span>{dataContador - 1}</span>
                      </label>
                    </div>
                  )
                }
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const proximo = () => {
    const mes = (mesSelecionado + 1) % 12
    const ano = mesSelecionado === 11 ? anoSelecionado + 1 : anoSelecionado
    const dia = `${ano}-${mes + 1}-${
      validarDiaSelecionado()
        ? diaSelecionado.getDate()
        : new Date(dataSugestao).getDate()
    }`

    if (validarDiaSelecionado()) {
      calendarioDispatch({
        tipo: 'mudar-data',
        dataEscolhida: dia
      })
    } else {
      configuraData(transformarDataAmericanaParaDate(dia))
    }
    renderizaCorpoCalendario(mesSelecionado, anoSelecionado)
  }

  const anterior = () => {
    const mes = mesSelecionado === 0 ? 11 : mesSelecionado - 1
    const ano = mesSelecionado === 0 ? anoSelecionado - 1 : anoSelecionado
    const dia = `${ano}-${mes + 1}-${
      validarDiaSelecionado()
        ? diaSelecionado.getDate()
        : new Date(dataSugestao).getDate()
    }`

    if (validarDiaSelecionado()) {
      calendarioDispatch({ tipo: 'mudar-data', dataEscolhida: dia })
    } else {
      configuraData(transformarDataAmericanaParaDate(dia))
    }
    renderizaCorpoCalendario(mesSelecionado, anoSelecionado)
  }

  return (
    <div>
      <Componente
        data-testid="calendario"
        className={classNames({
          visivel: aberto,
          invisivel: !aberto,
          altura: altura
        })}
        id={`${id}-calendario_id`}
      >
        <Cabecalho>
          <h3>
            {validarDiaSelecionado()
              ? diaSelecionado?.getFullYear().toString()
              : new Date(dataSugestao).getFullYear().toString()}
          </h3>
          <h2>{CaixaAltaPrimeiraLetra(DATA_CABECALHO)}</h2>
        </Cabecalho>
        <Corpo>
          <TituloCalendario>
            <CampoProximoAnterior>
              <span
                id={`${id}-anterior`}
                onClick={anterior}
                className="anterior"
              >
                {Icones.IconeSetaRetornar}
              </span>
              <span>{CaixaAltaPrimeiraLetra(mesesDoAno[mesSelecionado])}</span>
              <span id={`${id}-proximo`} onClick={proximo} className="proximo">
                {Icones.IconeSetaAvancar}
              </span>
            </CampoProximoAnterior>
          </TituloCalendario>
          <CorpoCalendario>
            <div className="wrapper">
              {diaSemanaPrimeiraLetra.map((valor, _) => {
                return (
                  <span key={uid(_)} className="dia-semana">
                    {CaixaAltaPrimeiraLetra(valor)}
                  </span>
                )
              })}
            </div>
            {renderizaCorpoCalendario(mesSelecionado, anoSelecionado)}
          </CorpoCalendario>
        </Corpo>
      </Componente>
    </div>
  )
}

EstruturaCalendario.propTypes = {
  id: PropTypes.string,
  altura: PropTypes.bool.isRequired
}
