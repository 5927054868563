import styled, { css } from 'styled-components'

import { Cores } from '../styles'
import { BreadcrumbTemas } from './tipos'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Atalhos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Separador = styled.span`
  padding: 0 2px;

  display: flex;
  align-items: center;
`

export const Atalho = styled.span<{ tema: BreadcrumbTemas }>`
  display: flex;
  align-items: center;

  button {
    pointer-events: none;
    outline: none;
    border: none;
    background: none;
    text-align: center;
    color: ${props => (props.tema === 'Padrao' ? Cores.CINZA_2 : '#2BB8D3')};
    ${props =>
      props.tema === 'Secundario' &&
      css`
        font-weight: 500;
      `}

    &:hover {
      color: ${Cores.PRIMARIA_1};
    }

    &.comAcao {
      cursor: pointer;
      pointer-events: unset;
    }
  }

  ${props =>
    props.tema === 'Secundario' &&
    css`
      ${Separador} {
        padding: 0 10px;

        svg > path {
          stroke: #aaaaaa;
        }
      }
    `}
`
