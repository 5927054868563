import { SituacaoUpload, TipoDocumentoAluno } from 'src/tipos'

import { UploadDocumentos } from '../tipos'

export const RelacaoDocumentosAlunoEstrangeiro = new Array<UploadDocumentos>(
  {
    id: 1,
    tipo: TipoDocumentoAluno.RegistroCivil,
    status: SituacaoUpload.PendenteEnvio,
    obrigatorio: true,
    dica: 'Exemplos: certidão de nascimento, certidão de casamento.',
    editarAprovado: true
  } as UploadDocumentos,
  {
    id: 2,
    tipo: TipoDocumentoAluno.Rne,
    status: SituacaoUpload.PendenteEnvio,
    obrigatorio: true,
    dica: 'Não será aceito CNH ou documento profissional.',
    editarAprovado: true
  } as UploadDocumentos,
  {
    id: 3,
    tipo: TipoDocumentoAluno.HistoricoEscolar,
    status: SituacaoUpload.PendenteEnvio
  } as UploadDocumentos,

  {
    id: 4,
    tipo: TipoDocumentoAluno.CertidaoNascimento,
    status: SituacaoUpload.PendenteEnvio,
    multiplo: true
  } as UploadDocumentos
)
