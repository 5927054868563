import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaAprovadoCurso = (): TabelaColuna => ({
  Chave: 'aprovadoCurso',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <span>{linha.aprovadoCurso}</span>,
  RenderizarCabecalho: () => <div style={{ width: 100 }}>Aprovado Curso</div>
})
