import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import { formatarMoeda } from 'src/componentes/funcoes/moeda'

import {
  ModalBase,
  Botao,
  FormUnform,
  InputUnform,
  InputMesAnoUnform,
  CartaoCreditoInputUnform,
  IconeInformacao,
  Tooltip,
  Cores,
  IconeCartaoVisa,
  IconeCartaoMaster,
  IconeCartaoAmex,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoDiners,
  regExpVisa,
  regExpMaster,
  regExpAmex,
  regExpElo,
  regExpHiper,
  regExpJcb,
  regExpDiners,
  SelectUnform,
  SelectOpcao
} from '../../../../../src/componentes'
import { Dados, ModalPagamentoProps, ModalPagamentoRef } from '../tipos'
import { schemaPagamentoCartaoCredito } from './schema'
import {
  ContainerAcoes,
  ContainerBandeiras,
  ContainerDados,
  ContainerDadosMedio,
  ContainerModal,
  IconeLabel,
  LinhaBandeiras,
  CorpoModal,
  ContainerDadosMedioSelect,
  AvisoModalQuantidaeParcelas
} from './styles'

const ModalPagamento: React.ForwardRefRenderFunction<
  ModalPagamentoRef,
  ModalPagamentoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [numeroCartao, definirNumeroCartao] = useState('')
  const [carregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [permitidoParcelamento, definirPermitidoParcelamento] = useState(false)
  const [valor, definirValor] = useState(0)
  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = (valorParcela?: number, permitirParcelamento?: boolean) => {
    definirValor(valorParcela)
    definirPermitidoParcelamento(permitirParcelamento)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalPagamentoRef, ModalPagamentoRef>(ref, () => ({
    fechar,
    abrir
  }))

  const dadosIniciais = useMemo(() => {
    return {
      numeroCartao: null,
      nomeCartao: null,
      validadeCartao: null,
      codigoSeguranca: null
    }
  }, [])

  const acaoSucessoForm = async (dados: Dados) => {
    acaoPrimaria(dados)
    fechar()
  }

  const dias = useMemo(() => {
    if (!valor) return []

    return Array.from({ length: 12 }, (x, i) => {
      const contador = `${i + 1}`
      const valorParcelado = valor / (i + 1)
      const valorParceladoFormatado = formatarMoeda(valorParcelado)
      return {
        id: contador,
        texto: `${contador}x de ${valorParceladoFormatado}`
      } as SelectOpcao
    })
  }, [valor])

  return (
    <ModalBase id="modal-pagamento" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Realizar Pagamento</h5>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              schema={schemaPagamentoCartaoCredito}
              ref={formRef}
              dadosIniciais={dadosIniciais}
            >
              <LinhaBandeiras>
                <ContainerBandeiras
                  selecionado={
                    !!numeroCartao.match(regExpVisa) &&
                    !numeroCartao.match(regExpElo)
                  }
                >
                  {IconeCartaoVisa}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpMaster)}
                >
                  {IconeCartaoMaster}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpAmex)}
                >
                  {IconeCartaoAmex}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpElo)}
                >
                  {IconeCartaoElo}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpHiper)}
                >
                  {IconeCartaoHiper}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpJcb)}
                >
                  {IconeCartaoJcb}
                </ContainerBandeiras>
                <ContainerBandeiras
                  selecionado={!!numeroCartao.match(regExpDiners)}
                >
                  {IconeCartaoDiners}
                </ContainerBandeiras>
              </LinhaBandeiras>
              <CartaoCreditoInputUnform
                id="ipt_numero_cartao"
                name="numeroCartao"
                label="Número do Cartão"
                obrigatorio
                onChange={e => {
                  const valor = e.target.value.replaceAll(' ', '')
                  definirNumeroCartao(valor)
                }}
              />
              <InputUnform
                id="ipt_nome_cartao"
                name="nomeCartao"
                label="Nome Conforme Consta no Cartão"
                obrigatorio
              />
              <ContainerDados>
                <ContainerDadosMedio>
                  <InputMesAnoUnform
                    id="ipt_validade_cartao"
                    name="validadeCartao"
                    label="Validade do Cartão"
                    placeholder="mm/aaaa"
                    formatoAno="AAAA"
                    obrigatorio
                  />
                  <Tooltip
                    id="tooltip-cvv"
                    place="right"
                    className="tooltip-ipgs"
                    textColor={Cores.PRETO}
                    backgroundColor={Cores.CINZA_1_CLARO}
                  />
                </ContainerDadosMedio>

                <ContainerDadosMedio>
                  <InputUnform
                    id="ipt_codigo_seguranca"
                    name="codigoSeguranca"
                    label={
                      <>
                        Código de Segurança
                        <IconeLabel
                          data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                          data-for="tooltip-cvv"
                        >
                          {IconeInformacao}
                        </IconeLabel>
                      </>
                    }
                    obrigatorio
                    maxLength={4}
                  />
                </ContainerDadosMedio>
              </ContainerDados>
              <InputUnform
                name="permitidoParcelamento"
                value={`${permitidoParcelamento}`}
                esconder
              />
              {permitidoParcelamento && (
                <>
                  <ContainerDados>
                    <ContainerDadosMedioSelect>
                      <SelectUnform
                        id="slct_quantidadeParcelas"
                        name="quantidadeParcelas"
                        opcoes={dias}
                        label={'Selecione a Quantidade de Parcelas'}
                        obrigatorio
                        deveLimpar={() => !aberta}
                      />
                    </ContainerDadosMedioSelect>
                  </ContainerDados>
                  <AvisoModalQuantidaeParcelas>
                    Nesta forma de pagamento, é comprometido o limite total do
                    valor da taxa a ser paga, porém parcelada na quantidade de
                    parcelas escolhidas
                  </AvisoModalQuantidaeParcelas>
                </>
              )}

              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Pagar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalPagamentoCartao = forwardRef(ModalPagamento)
