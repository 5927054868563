import queryString from 'query-string'
import {
  FormaPagamentoCobranca,
  MetodosPagamento,
  ResponsavelFinanceiro
} from 'src/tipos'
import { SituacaoReingresso } from 'src/tipos/reingresso'

import { gatewayApi } from '../instancias-api'
import {
  BoletoGerado,
  DadosCartao,
  DadosMatriculasReingresso,
  DadosReingresso,
  DadosReprovacaoReingresso,
  DadosTaxaMatriculaReingresso,
  PlanoPagamentoReingresso,
  ValidarCupomResposta
} from './tipos'

export const RequisitarReingresso = async (
  id: string
): Promise<DadosReingresso> => {
  const { data: reingresso } = await gatewayApi.get<DadosReingresso>(
    `/reingresso/${id}`
  )

  return reingresso
}

export const AvancarRegulamentoReingresso = async (
  id: string,
  opcao: string,
  motivoOpcao: string,
  explicacaoMotivo: string
): Promise<{
  situacao?: SituacaoReingresso
  protocolo?: string
}> => {
  const { data: dados } = await gatewayApi.post<{
    situacao?: SituacaoReingresso
    protocolo?: string
  }>(`/reingresso/${id}/regulamento/avancar`, {
    opcao,
    motivoOpcao,
    explicacaoMotivo
  })

  return dados
}

export const RequisitarInformacoesReprovacaoReingresso = async (
  id: string
): Promise<DadosReprovacaoReingresso> => {
  const {
    data: infoReprovacao
  } = await gatewayApi.get<DadosReprovacaoReingresso>(
    `/reingresso/${id}/informacoes-reprovacao`
  )

  return infoReprovacao
}

export const AvancarFinanceiroReingresso = async (
  id: string
): Promise<{
  matriculaDestino?: DadosMatriculasReingresso
  protocolo?: string
}> => {
  const { data: dados } = await gatewayApi.post<{
    matriculaDestino?: DadosMatriculasReingresso
    protocolo?: string
  }>(`/reingresso/${id}/financeiro/avancar`)

  return dados
}

export const RequisitarPlanosPagamentoReingresso = async (
  id: string,
  formaPagamento?: string,
  idCupom?: string,
  idPlanoPagamento?: string
): Promise<PlanoPagamentoReingresso[]> => {
  let url = `/reingresso/${id}/planos-pagamento?formaPagamento=`

  if (formaPagamento) {
    url += formaPagamento
  }

  if (idPlanoPagamento) {
    url += `&planoPagamentoId=${idPlanoPagamento}`
  }

  if (idCupom) {
    url += `&cupomId=${idCupom}`
  }

  const { data: planoPagamento } = await gatewayApi.get<
    PlanoPagamentoReingresso[]
  >(url)

  return planoPagamento
}

export const RequisitarContatoTermoCondicoesReingresso = async (
  id: string
): Promise<string> => {
  const resposta = await gatewayApi.get(`/reingresso/${id}/contrato`, {
    headers: {
      Accept: 'application/pdf'
    },
    responseType: 'blob'
  })

  if (resposta?.status !== 200) return null

  return URL.createObjectURL(resposta.data)
}

export const AvancarTermosCondicoesReingresso = async (
  id: string,
  liAceitoTermos: boolean,
  estouCiente: boolean
): Promise<void> => {
  await gatewayApi.post(`/reingresso/${id}/termo-condicao/avancar`, {
    liAceitoTermos: liAceitoTermos,
    estouCiente: estouCiente
  })
}

export const RequisitarInformacoesTaxaMatriculaReingresso = async (
  id: string
): Promise<DadosTaxaMatriculaReingresso> => {
  const { data: infoTaxa } = await gatewayApi.get<DadosTaxaMatriculaReingresso>(
    `/reingresso/${id}/taxa-matricula`
  )

  return infoTaxa
}

export const GerarBoletoMatriculaReingresso = async (
  id: string
): Promise<BoletoGerado> => {
  const { data: boleto } = await gatewayApi.get<BoletoGerado>(
    `/reingresso/${id}/boleto/cobrar`
  )

  return boleto
}

export const AvancarPagamentoMatriculaReingresso = async (
  id: string,
  dadosCartao?: DadosCartao
): Promise<void> => {
  await gatewayApi.post(`/reingresso/${id}/pagamento/avancar`, {
    dadosCartao
  })
}

export const RequisitarValidarCupomReingresso = async (
  idReingresso: string,
  codicoCupom: string,
  formaPagamento?: MetodosPagamento | FormaPagamentoCobranca
): Promise<ValidarCupomResposta> => {
  try {
    const queryParams = queryString.stringify({
      Codigo: codicoCupom,
      formaPagamento: formaPagamento
    })
    const { data } = await gatewayApi.get<ValidarCupomResposta>(
      `/reingresso/${idReingresso}/cupom/validar?${queryParams}`
    )

    return data
  } catch {
    return null
  }
}

export const AvancarPlanoPagamentoReingresso = async (
  reingressoId: string,
  responsavelFinanceiro: ResponsavelFinanceiro,
  planoPagamentoId?: string,
  pagamentoRecorrente?: boolean,
  cupomId?: string,
  formaPagamento?: FormaPagamentoCobranca
): Promise<ResponsavelFinanceiro> => {
  const { data: dados } = await gatewayApi.post<ResponsavelFinanceiro>(
    `/reingresso/${reingressoId}/plano-pagamento/avancar`,
    {
      cupomId,
      planoPagamentoId,
      pagamentoRecorrente,
      formaPagamento,
      responsavelFinanceiro
    }
  )

  return dados
}
