import React, { useMemo, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Carregando,
  Cores,
  FuncoesDataHora,
  IconeAtencao,
  IconeCheckPequeno,
  IconeErroPequeno,
  IconeBrancoInformacao,
  Spacer,
  Tooltip
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Api } from 'src/servicos'
import {
  Disciplina,
  DisciplinasTCC,
  Modulo,
  Notas
} from 'src/servicos/api/sala-aula/tipos'
import {
  ModalidadeCurso,
  NotaAlteracaoTCC,
  TipoAvaliacaoPorExtenso
} from 'src/tipos'

import {
  Tabela,
  CelulaModulo,
  CelulaDisciplina,
  CelulaData,
  Linha,
  Nota,
  CelulaNota,
  CelulaEncontros,
  Encontro,
  CelulaEncontro,
  Data
} from './styles'
import { MinhasNotasProps } from './tipos'

export const MinhasNotas: React.FC<MinhasNotasProps> = ({
  idMatricula,
  tipoAvaliacao,
  modalidadeCurso
}) => {
  const [notas, definirNotas] = useState({} as Notas)
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)

  const ehAvaliacaoPorDisciplina = useMemo(
    () =>
      TipoAvaliacaoPorExtenso[tipoAvaliacao] ===
      TipoAvaliacaoPorExtenso.AvaliacaoPorDisciplina,
    [tipoAvaliacao]
  )

  const carregarNotas = async () => {
    try {
      definirPronto(false)
      const resposta = await Api.SalaAulaOutros.RequisitarNotas(idMatricula)

      definirNotas(resposta)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar as notas')
    } finally {
      definirPronto(true)
    }
  }

  const atualizarVisualizacaoNotaTCC = async () => {
    try {
      await Api.SalaAulaOutros.AtualizarVisualizacaoNotaTCC(idMatricula)
    } catch {
      toast('Erro ao atualização visualização nota', { type: 'error' })
    }
  }

  const formatadorValor = (nota: number) => nota ?? '-'

  const textoPresenca = (presente?: boolean) => {
    if (presente === null || presente === undefined) {
      return null
    }

    return presente ? 'Presença' : 'Falta'
  }

  const renderizarIconePresenca = (presente?: boolean) => {
    if (presente === null || presente === undefined) {
      return <></>
    }

    if (presente) {
      return IconeCheckPequeno
    }

    return IconeErroPequeno
  }
  const renderizarTooltipNOta = (nota: any) => {
    // eslint-disable-next-line eqeqeq
    if (nota < 7 || nota === '-') {
      return IconeBrancoInformacao
    }
  }

  const resolverNota = (disciplina: DisciplinasTCC) => {
    if (disciplina.dispensada) return NotaAlteracaoTCC.DISP
    if (disciplina.aproveitada) return NotaAlteracaoTCC.APRO
    return disciplina.nota ?? '-'
  }

  useEffect(() => {
    carregarNotas()
  }, [])

  useEffect(() => {
    atualizarVisualizacaoNotaTCC()
  }, [])

  if (erro) {
    return (
      <ContainerErroLayout>
        <div>
          {IconeAtencao}
          <p>{erro}</p>
          <div>
            <Botao
              tema="Padrao"
              texto="Tentar novamente"
              onClick={carregarNotas}
              tamanho="S"
            />
          </div>
        </div>
      </ContainerErroLayout>
    )
  }

  if (!pronto) {
    return <Carregando texto="Carregando notas ..." />
  }

  const retornarCorAvaliacaoDisciplina = (valor: Disciplina) => {
    if (valor.aproveitamento || !valor.possuiAvaliacao) {
      return 'branco'
    } else if (valor.aprovado && valor.possuiAvaliacao) {
      return 'verde'
    } else if (valor.possuiAvaliacao && !valor.possuiEnvio) {
      return 'amarelo'
    } else if (valor.possuiAvaliacao && valor.nota < 7) {
      return 'vermelho'
    }
  }

  const retornarCorAvaliacaoModulo = (valor: Modulo, valor2: Disciplina) => {
    if (valor2.aproveitamento) {
      return 'branco'
    } else if (valor.aprovado && valor.possuiAvaliacao) {
      return 'verde'
    } else if (valor.possuiAvaliacao && !valor.possuiEnvio) {
      return 'amarelo'
    } else if (valor.possuiAvaliacao && valor.nota < 7) {
      return 'vermelho'
    } else {
      return 'branco'
    }
  }

  const retornarCorAvaliacaoTCC = (valor: DisciplinasTCC) => {
    if (valor.aprovado || valor.aproveitada) {
      return 'verde'
    } else if (valor.id && !valor.feedbacks) {
      return 'amarelo'
    } else if (valor.nota && valor.nota < 7) {
      return 'vermelho'
    }
  }

  const retornarNota = (valor: Disciplina) => {
    if (valor.aproveitamento) {
      return 'N/A'
    } else if (valor.possuiAvaliacao) {
      return formatadorValor(valor.nota)
    } else {
      return 'N/A'
    }
  }

  if (modalidadeCurso === ModalidadeCurso.PosGraduacaoPresencial) {
    return (
      <>
        <Spacer padding="47px 0 0 0" />
        {notas?.modulos.map((modulo, indice) => (
          <Tabela key={`${indice}`}>
            <thead>
              <tr>
                <CelulaModulo colSpan={ehAvaliacaoPorDisciplina ? 4 : 2}>
                  {modulo.nomeModulo}
                </CelulaModulo>
                {!ehAvaliacaoPorDisciplina && (
                  <CelulaModulo>
                    Data Limite:{' '}
                    {FuncoesDataHora.dataStringFormatadaComBarras(
                      modulo.dataLimiteAvaliacao
                    )}
                  </CelulaModulo>
                )}
                {!ehAvaliacaoPorDisciplina && (
                  <CelulaModulo>
                    Nota:{' '}
                    {modulo.possuiAvaliacao
                      ? formatadorValor(modulo.nota)
                      : 'N/A'}
                  </CelulaModulo>
                )}
              </tr>
              <tr>
                <CelulaDisciplina
                  colSpan={!ehAvaliacaoPorDisciplina ? 2 : undefined}
                >
                  DISCIPLINA
                </CelulaDisciplina>
                {ehAvaliacaoPorDisciplina && (
                  <CelulaData>DATA LIMITE</CelulaData>
                )}
                {ehAvaliacaoPorDisciplina && <CelulaNota>NOTA</CelulaNota>}
                <CelulaEncontro>ENCONTROS PRESENCIAIS</CelulaEncontro>
              </tr>
            </thead>
            <tbody>
              {modulo.disciplinas.map((disciplina, ind) => (
                <Linha key={`${ind}`}>
                  <td colSpan={!ehAvaliacaoPorDisciplina ? 2 : undefined}>
                    {disciplina.nomeDisciplina}
                  </td>
                  {ehAvaliacaoPorDisciplina && (
                    <Data>
                      {FuncoesDataHora.dataStringFormatadaComBarras(
                        disciplina.dataLimiteAvaliacao
                      )}
                    </Data>
                  )}
                  {ehAvaliacaoPorDisciplina && (
                    <Nota aprovado={disciplina.aprovado}>
                      {formatadorValor(disciplina.nota)}
                    </Nota>
                  )}
                  <CelulaEncontros>
                    {disciplina.encontros.map((encontro, index) => (
                      <Encontro key={index}>
                        {FuncoesDataHora.dataFormatadaDiaMes(
                          encontro.dataEncontro
                        )}
                        <div
                          data-for="tooltip-encontro"
                          data-tip={textoPresenca(encontro.presente)}
                        >
                          {renderizarIconePresenca(encontro.presente)}
                        </div>
                      </Encontro>
                    ))}
                  </CelulaEncontros>
                </Linha>
              ))}
            </tbody>
          </Tabela>
        ))}
        <Tooltip
          id="tooltip-encontro"
          textColor={Cores.PRETO}
          backgroundColor={Cores.CINZA_1_CLARO}
        />
      </>
    )
  }

  return (
    <>
      <Spacer padding="47px 0 0 0" />
      {notas.modulos.map((modulo, indice) => (
        <Tabela key={`${indice}`}>
          <thead>
            <tr>
              <CelulaModulo colSpan={ehAvaliacaoPorDisciplina ? 3 : undefined}>
                <p>{modulo.nomeModulo}</p>
                {!ehAvaliacaoPorDisciplina && modulo.reingresso && (
                  <p>Aproveitamento</p>
                )}
              </CelulaModulo>
              {!ehAvaliacaoPorDisciplina && (
                <CelulaModulo>
                  Data Limite:{' '}
                  {FuncoesDataHora.dataStringFormatadaComBarras(
                    modulo.dataLimiteAvaliacao
                  )}
                </CelulaModulo>
              )}
              {!ehAvaliacaoPorDisciplina && (
                <CelulaModulo>
                  <p
                    data-for="tooltip-nota"
                    data-tip={
                      'Infelizmente você não atingiu a média necessária.Mas fique tranquilo, que ao final do curso você poderá contratar recuperação de até 02 módulos'
                    }
                    data-tooltip-offset={0}
                  >
                    {renderizarTooltipNOta(formatadorValor(modulo.nota))}
                  </p>
                  <Tooltip
                    id="tooltip-nota"
                    textColor={Cores.PRETO}
                    backgroundColor={Cores.CINZA_1_CLARO}
                  />
                  Nota:{' '}
                  {modulo.possuiAvaliacao
                    ? formatadorValor(modulo.nota)
                    : 'N/A'}
                </CelulaModulo>
              )}
            </tr>
            <tr>
              <CelulaDisciplina
                colSpan={!ehAvaliacaoPorDisciplina ? 3 : undefined}
              >
                Disciplina
              </CelulaDisciplina>
              {ehAvaliacaoPorDisciplina && <CelulaData>Data Limite</CelulaData>}
              {ehAvaliacaoPorDisciplina && <CelulaNota>Nota</CelulaNota>}
            </tr>
          </thead>
          <tbody>
            {modulo.disciplinas.map((disciplina, ind) =>
              ehAvaliacaoPorDisciplina ? (
                <Linha
                  key={`${ind}`}
                  cor={retornarCorAvaliacaoDisciplina(disciplina)}
                >
                  <td colSpan={undefined}>
                    <p>{disciplina.nomeDisciplina}</p>
                    {disciplina.reingresso && <p>Aproveitamento</p>}
                  </td>
                  <Data>
                    {FuncoesDataHora.dataStringFormatadaComBarras(
                      disciplina.dataLimiteAvaliacao
                    )}
                  </Data>
                  <Nota aprovado={disciplina.aprovado}>
                    {retornarNota(disciplina)}
                  </Nota>
                </Linha>
              ) : (
                <Linha
                  key={`${ind}`}
                  cor={retornarCorAvaliacaoModulo(modulo, disciplina)}
                >
                  <td colSpan={3}>{disciplina.nomeDisciplina}</td>
                </Linha>
              )
            )}
          </tbody>
        </Tabela>
      ))}

      {notas?.disciplinasTCC &&
        notas?.disciplinasTCC.map((disciplina, indice) => (
          <Tabela key={`${indice}`}>
            <thead>
              <tr>
                <CelulaModulo
                  colSpan={ehAvaliacaoPorDisciplina ? 2 : undefined}
                >
                  {notas?.nomeModuloTCC}
                </CelulaModulo>
                {!ehAvaliacaoPorDisciplina && (
                  <CelulaModulo>Nota: {resolverNota(disciplina)}</CelulaModulo>
                )}
              </tr>
              <tr>
                <CelulaDisciplina
                  colSpan={!ehAvaliacaoPorDisciplina ? 2 : undefined}
                >
                  Disciplina
                </CelulaDisciplina>
                {ehAvaliacaoPorDisciplina && <CelulaNota>Nota</CelulaNota>}
              </tr>
            </thead>
            <tbody>
              <Linha
                key={`${indice}`}
                cor={retornarCorAvaliacaoTCC(disciplina)}
              >
                <td colSpan={!ehAvaliacaoPorDisciplina ? 2 : undefined}>
                  {disciplina?.nomeDisciplina}
                </td>
                {ehAvaliacaoPorDisciplina && (
                  <Nota aprovado={disciplina?.aprovado}>
                    {resolverNota(disciplina)}
                  </Nota>
                )}
              </Linha>
            </tbody>
          </Tabela>
        ))}
    </>
  )
}
