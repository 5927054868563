import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState
} from 'react'

import { useField } from '@unform/core'

import { TamanhoInput } from '../input/tipos'
import { arquivosAceitos } from './arquivos-aceitos'
import { Input, Error, CampoArquivo, Obrigatorio } from './styles'

interface InputFileProps {
  name: string
  onChange?: (param: string) => void
  label?: string
  value?: string
  readOnly?: boolean
  tamanho?: TamanhoInput
  maxWidthInput?: string
}

type InputProps = JSX.IntrinsicElements['input'] & InputFileProps

export const FileInput: React.FC<InputProps> = ({
  name,
  label,
  value,
  required,
  disabled
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, registerField, error } = useField(name)
  const [file, setFile] = useState(value)

  const lerArquivo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setFile(file.name)
    }
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
        setFile(null)
      },
      setValue(_: HTMLInputElement, value: string) {
        setFile(value)
      }
    })
  }, [fieldName, registerField])

  return (
    <>
      {label && required ? <Obrigatorio> * </Obrigatorio> : null} {label}
      <Input
        accept={arquivosAceitos}
        type="file"
        ref={inputRef}
        name="arquivo"
        onChange={lerArquivo}
        disabled={disabled}
      />
      <CampoArquivo onClick={() => inputRef?.current?.click()}>
        <div id="nome-arquivo">{file}</div>
        <div> Procurar </div>
      </CampoArquivo>
      {error && <Error>{error}</Error>}
    </>
  )
}
