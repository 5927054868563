import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  Botao,
  ModalBase,
  ModalBaseProps,
  ModalRef,
  PdfViewer
} from 'src/componentes'

import { ContainerDosBotoes, CorpoModal } from './styles'

export interface ModalVisualizacaoPdfRef extends Omit<ModalRef, 'abrir'> {
  abrir: (url: string) => void
}

const ModalVisualizacaoPdfComponent: React.ForwardRefRenderFunction<
  ModalVisualizacaoPdfRef,
  ModalBaseProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [pdf, definirPdf] = useState<string>(null)

  const abrir = (pdf: string) => {
    definirPdf(pdf)
    definirAberta(true)
  }

  const fechar = () => {
    definirAberta(false)
    definirPdf(null)
  }

  useImperativeHandle<ModalVisualizacaoPdfRef, ModalVisualizacaoPdfRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase id="modal-visualizacao-pdf" backdrop={backdrop} aberta={aberta}>
      <CorpoModal>
        <ContainerDosBotoes>
          <Botao
            texto="Fechar"
            tema="Secundario"
            type="button"
            onClick={() => fechar()}
          />
        </ContainerDosBotoes>
        {pdf && (
          <PdfViewer
            url={pdf}
            descricaoMobile="Documento"
            nomeArquivo="documento"
            altura="100%"
          ></PdfViewer>
        )}
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalVisualizacaoPdf = forwardRef(ModalVisualizacaoPdfComponent)
