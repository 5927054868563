import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { Api } from 'src/servicos'

import { Cores } from '..'

import { Botao } from '../botao'
import { Informacao } from '../informacao'
import { ModalBase } from '../modal-base'
import { FormUnform } from '../unform/form'
import { InputUnform } from '../unform/input'
import { schema } from './schema'
import { ContainerBotoes, Corpo, Conteudo } from './styles'
import {
  ModalRequisitarSenhaProps,
  ModalRequisitarSenhaRef,
  ConteudoModalRequisitarSenhaProps
} from './tipos'

export const ConteudoModalRequisitarSenha: React.FC<ConteudoModalRequisitarSenhaProps> = ({
  acaoSucesso,
  fechar,
  className
}) => {
  const [carregando, definirCarregando] = useState(false)

  const acaoSucessoForm = async ({ usuario }: { usuario: string }) => {
    try {
      definirCarregando(true)

      const token = await Api.RecuperarSenha(usuario)

      if (token) {
        if (acaoSucesso) acaoSucesso()
        if (fechar) fechar()
      }
    } finally {
      definirCarregando(false)
    }
  }

  return (
    <Conteudo className={className}>
      <h5>Esqueci Minha Senha</h5>
      <FormUnform schema={schema} acaoSucesso={acaoSucessoForm}>
        <InputUnform
          id="ipt_usuario_requisitar_senha"
          label="Informe seu CPF, CNPJ ou E-mail"
          name="usuario"
          maxWidthInput="100%"
          obrigatorio
          maxLength={256}
        />

        <Informacao>
          <b>
            <p style={{ color: Cores.VERMELHO_CLARO }}>
              Você receberá um e-mail em até 10 minutos com um link para a
              recuperação da sua senha. Verifique no SPAM. Solicite apenas uma
              vez e realize a alteração de senha no link enviado por e-mail.
              Caso receba mais de um link, clique no primeiro link recebido.
            </p>
          </b>
        </Informacao>

        <ContainerBotoes>
          <Botao
            type="button"
            id="btn-cancelar"
            texto="Cancelar"
            tema="Secundario"
            onClick={() => {
              fechar()
            }}
          />
          <Botao
            type="submit"
            id="btn-enviar"
            texto="Recuperar Senha"
            carregando={carregando}
          />
        </ContainerBotoes>
      </FormUnform>
    </Conteudo>
  )
}

const ModalRequisitarSenhaComponente: React.ForwardRefRenderFunction<
  ModalRequisitarSenhaRef,
  ModalRequisitarSenhaProps
> = ({ backdrop, acaoSucesso }, ref) => {
  const [aberta, definirAberta] = useState(false)

  const fechar = () => {
    definirAberta(false)
    const input = document.getElementById(
      'ipt_usuario_requisitar_senha'
    ) as HTMLInputElement

    input.value = ''
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<ModalRequisitarSenhaRef, ModalRequisitarSenhaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <ConteudoModalRequisitarSenha
          acaoSucesso={acaoSucesso}
          fechar={fechar}
        />
      </Corpo>
    </ModalBase>
  )
}

export const ModalRequisitarSenha = forwardRef(ModalRequisitarSenhaComponente)
