import { createContext } from 'react'

import { dataFormatadaAmericana } from '../funcoes/data-hora'
import { CalendarioEstado, CalendarioDispatch } from './tipos'

const CalendarioContext = createContext<CalendarioEstado>({
  data: '',
  dataSugestao: dataFormatadaAmericana(),
  aberto: false
})

const CalendarioAcoesContext = createContext<CalendarioDispatch>(
  {} as CalendarioDispatch
)

export { CalendarioContext, CalendarioAcoesContext }
