import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { ModalBase, Botao, ModalBaseProps } from 'src/componentes'

import { Corpo, Texto, Titulo } from './styles'
import { ModalReingressoInadimplenteRef } from './tipos'

const ModalInadimplente: React.ForwardRefRenderFunction<
  ModalReingressoInadimplenteRef,
  ModalBaseProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)

  const [protocolo, definirProtocolo] = useState('')

  const fechar = () => {
    definirProtocolo(null)
    definirAberta(false)
  }

  const abrir = (protocolo: string) => {
    definirProtocolo(protocolo)
    definirAberta(true)
  }

  useImperativeHandle<
    ModalReingressoInadimplenteRef,
    ModalReingressoInadimplenteRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = () => {
    fechar()
    acaoPrimaria(null)
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        <Titulo>Aviso de inadimplência!</Titulo>
        <>
          <Texto>A sua matrícula atual possui pendências financeiras. </Texto>
          <Texto>
            É necessário aguardar o contato do setor financeiro para negociação
            das pendências e reingresso.
          </Texto>
          <Texto>
            O número do seu protocolo de atendimento é {protocolo ?? ''}
          </Texto>
          <Texto>Bons estudos e conte conosco.</Texto>
        </>

        <Botao texto="OK" type="button" onClick={() => acaoSucesso()} />
      </Corpo>
    </ModalBase>
  )
}

export const ModalReingressoInadimplente = forwardRef(ModalInadimplente)
