import { CalendarioEstado, CalendarioAcoes } from './tipos'

export default (
  estado: CalendarioEstado,
  acao: CalendarioAcoes
): CalendarioEstado => {
  switch (acao.tipo) {
    case 'mudar-data': {
      return {
        ...estado,
        data: acao.dataEscolhida,
        dataSugestao: acao.dataSugestao || estado.dataSugestao
      }
    }
    case 'data-sugestao': {
      return { ...estado, dataSugestao: acao.dataSugestao }
    }
    case 'abrir-calendario': {
      return { ...estado, aberto: acao.abrir }
    }
    default: {
      throw new Error(`Ação inválida: ${acao}`)
    }
  }
}
