import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'
import { Cores, IconeInformacao, Tooltip, TextArea } from 'src/componentes'
import { Obrigatorio } from 'src/componentes/input/styles'

import { Componente, Erro } from './styles'
import { TextAreaProps } from './tipos'

export const TextAreaUnform: React.FC<TextAreaProps> = ({
  label,
  obrigatorio,
  name,
  tooltipLabel,
  id,
  height,
  minLength,
  tooltipModal,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name || '')

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className="textarea-container"
    >
      <TextArea
        {...rest}
        id={id}
        name={name}
        label={
          label ? (
            <>
              {obrigatorio ? <Obrigatorio>*</Obrigatorio> : <></>}
              {label}
              {tooltipLabel ? (
                <div
                  className="informacao-icone"
                  data-tip={tooltipLabel}
                  data-for={`${id}-tooltip-textarea`}
                >
                  {IconeInformacao}
                </div>
              ) : (
                <></>
              )}
            </>
          ) : undefined
        }
        defaultValue={defaultValue}
        ref={textareaRef}
        className={classNames({
          error
        })}
        onKeyPress={clearError}
        height={height}
        minLength={minLength}
      />
      <Erro id={`erro-${id}`}>{error}</Erro>
      {tooltipLabel && (
        <Tooltip
          id={`${id}-tooltip-textarea`}
          place="right"
          className="informacao-tooltip"
          textColor={Cores.PRETO}
          backgroundColor={Cores.CINZA_1_CLARO}
          modal={tooltipModal}
        />
      )}
    </Componente>
  )
}

export * from './tipos'
