import React, {
  Suspense,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'

import {
  BotaoStep,
  Breadcrumb,
  BreadcrumbAtalho,
  Carregando,
  IconeAtencao,
  Botao,
  Spacer,
  useResponsivoState,
  scrollParaTopo
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { StepperItem } from 'src/componentes/stepper/tipos'
import { SalaAulaTipoMaterial } from 'src/tipos'

import { Container, ContainerBotoesStep, ContainerBreadcrumb } from '../styles'
import { MaterialProps } from './tipos'

const Player = React.lazy(() => import('src/componentes/player'))

export const Material: React.FC<MaterialProps> = ({
  id,
  tipo,
  salaAulaId,
  url,
  ultimoMaterial,
  etapas,
  carregarMaterialPorId,
  acaoAvancar,
  acaoVoltar,
  pdf,
  children,
  isAulaEad
}) => {
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const [material, definirMaterial] = useState<string>('')

  const { mobile } = useResponsivoState()

  const carregarMaterial = async () => {
    try {
      definirPronto(false)
      definirErro(undefined)
      definirMaterial('')

      const novoMaterial = await carregarMaterialPorId()

      definirMaterial(novoMaterial)
    } catch {
      definirErro('Ops! Houve algum problema ao carregar o material')
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    if (tipo === SalaAulaTipoMaterial.Anexo) {
      carregarMaterial()
    } else {
      definirPronto(true)
      definirErro(undefined)
    }
  }, [id, tipo])

  const idsItens = (etapasSelecionadas: StepperItem[]): string[] => {
    let novosIds = []

    etapasSelecionadas.forEach(etapa => {
      if (etapa.id === id) {
        novosIds.push(etapa.titulo)
      }

      if (etapa.subItens) {
        const novosIdsSub = idsItens(etapa.subItens)

        if (novosIdsSub.length > 0) {
          novosIds = novosIdsSub
          novosIds.push(etapa.titulo)
        }
      }
    })

    return novosIds
  }

  const atalhosBreadcrumb = useMemo(
    (): BreadcrumbAtalho[] =>
      idsItens(etapas)
        .reverse()
        .map(item => ({ texto: item })),
    [etapas, id]
  )

  const obterMaterial = useCallback(() => {
    if (tipo === SalaAulaTipoMaterial.Video) {
      return (
        <Player
          key={url}
          url={url}
          materialId={id}
          salaAulaId={salaAulaId}
          isAulaEad={isAulaEad}
        />
      )
    }
  }, [tipo, url, mobile, material])

  useEffect(() => {
    if (
      tipo === SalaAulaTipoMaterial.Video ||
      (tipo === SalaAulaTipoMaterial.Anexo && pdf)
    ) {
      scrollParaTopo()
    }
  }, [id, tipo, pdf])

  return (
    <Container>
      {pronto && !erro ? (
        <>
          <Suspense fallback={<div>Carregando...</div>}>
            {obterMaterial()}
          </Suspense>
          <ContainerBreadcrumb>
            <Breadcrumb tema="Secundario" atalhos={atalhosBreadcrumb} />
          </ContainerBreadcrumb>

          <ContainerBotoesStep>
            <BotaoStep
              texto="Voltar para"
              textoNegrito="Conteúdo anterior"
              invertido
              tema="Secundario"
              tamanho="S"
              onClick={acaoVoltar}
            />
            {!ultimoMaterial && (
              <BotaoStep
                texto="Ir para"
                textoNegrito="Próximo conteúdo"
                tamanho="S"
                onClick={acaoAvancar}
              />
            )}
          </ContainerBotoesStep>

          {!!children && <Spacer padding="12px 0" />}
          {children}
        </>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando material ..." />
      ) : (
        <ContainerErroLayout>
          <div>
            {IconeAtencao}
            <p>{erro}</p>
            <div>
              <Botao
                tema="Padrao"
                texto="Tentar novamente"
                onClick={carregarMaterial}
                tamanho="S"
              />
            </div>
          </div>
        </ContainerErroLayout>
      )}
    </Container>
  )
}
