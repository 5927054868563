import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Cores,
  ErroLayoutContainer,
  Filtro,
  IconeDeListaDeDocumentos,
  InputUnform,
  SelectUnform,
  Tooltip,
  IconeRevelarSenha,
  IconeEditar,
  IconeCheckPequeno,
  AutenticacaoContainer,
  DataInputUnform
} from 'src/componentes'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { PesquisaTicketDTO } from 'src/dto'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import {
  RotasAcademico,
  RotasAlunos,
  RotasRequerimentos,
  RotasResponsavelFinanceiro
} from 'src/rotas'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import {
  CategoriaTicket,
  CategoriaTicketPorExtenso,
  Nacionalidade,
  SetorTicketPorExtenso,
  StatusTicket,
  TipoOrdenacao,
  TipoTicket,
  TipoTicketPorExtenso
} from 'src/tipos'

import {
  ModalFinalizarTicket,
  ModalFinalizarTicketRef,
  FinalizarTicket,
  ModalAlterarSetor,
  ModalAlterarSetorRef,
  AlterarSetor
} from './componentes'
import {
  Form,
  Container,
  Campo,
  Dados,
  ContainerAcoes,
  IconeAcao
} from './styles'
import { DadosPesquisa } from './tipos'

export const PaginaFilasTrabalho: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>({
    status: [
      StatusTicket[StatusTicket.Aberto],
      StatusTicket[StatusTicket.Pendente]
    ]
  })
  const modalFinalizarTicket = useRef<ModalFinalizarTicketRef>(null)
  const modalAlterarSetor = useRef<ModalAlterarSetorRef>(null)
  const [paginas, definirPaginas] = useState(null)
  const [ticketFinalizado, definirTicketFinalizado] = useState(false)
  const [setorAlterado, definirSetorAlterado] = useState(false)
  const history = useHistory()
  const { usuario } = AutenticacaoContainer.useContainer()

  const SETORES_FILA = usuario?.perfis.filter(setor => {
    return (
      SetorTicketPorExtenso[setor as keyof typeof SetorTicketPorExtenso] ===
        SetorTicketPorExtenso.Academico ||
      SetorTicketPorExtenso[setor as keyof typeof SetorTicketPorExtenso] ===
        SetorTicketPorExtenso.Comercial ||
      SetorTicketPorExtenso[setor as keyof typeof SetorTicketPorExtenso] ===
        SetorTicketPorExtenso.Financeiro ||
      SetorTicketPorExtenso[setor as keyof typeof SetorTicketPorExtenso] ===
        SetorTicketPorExtenso.Gerencial
    )
  })

  const SELECT_SETOR = SETORES_FILA.map(setor => ({
    id: setor,
    texto: SetorTicketPorExtenso[setor as keyof typeof SetorTicketPorExtenso]
  }))

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.RequisitarListaTickets(
      dadosPesquisa?.nomeAluno,
      dadosPesquisa?.setores,
      dadosPesquisa?.tipos,
      dadosPesquisa?.categorias,
      dadosPesquisa?.status,
      dadosPesquisa?.dataCriacaoInicio,
      dadosPesquisa?.dataCriacaoFim,
      dadosPesquisa?.dataEncerramentoInicio,
      dadosPesquisa?.dataEncerramentoFim,
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const finalizarTicket = async (dados: FinalizarTicket) => {
    try {
      await Api.FinalizarTicket(dados.idTicket, dados.observacao)
      definirTicketFinalizado(true)
      toast('Ticket finalizado com sucesso!', { type: 'success' })
    } catch {
      toast('Erro ao finalizar ticket.', { type: 'error' })
    } finally {
      definirTicketFinalizado(false)
    }
  }

  const alterarSetor = async (dados: AlterarSetor) => {
    try {
      await Api.AlterarSetorTicket(
        dados.idTicket,
        dados.setor,
        dados.observacao
      )
      definirSetorAlterado(true)
      toast('Setor alterado com sucesso!', { type: 'success' })
    } catch {
      toast('Erro ao alterar setor.', { type: 'error' })
    } finally {
      definirSetorAlterado(false)
    }
  }

  const resolverLinkRedicionarTicket = (ticket: PesquisaTicketDTO) => {
    if (ticket.categoria === CategoriaTicket.Documentacao) {
      const caminho =
        ticket.usuario.nacionalidade === Nacionalidade[0]
          ? RotasAlunos.DocumentosAlunoBrasileiro
          : RotasAlunos.DocumentosAlunoEstrangeiro

      return `${caminho}/${ticket.usuario.email}`
    }

    if (
      ticket.categoria === CategoriaTicket.DocumentacaoResponsavelFinanceiro
    ) {
      return `${RotasResponsavelFinanceiro.Documentos}/${ticket.usuario.email}`
    }

    if (
      ticket.categoria === CategoriaTicket.PrazoNotaTCC ||
      ticket.categoria === CategoriaTicket.ServicoFinalizacao
    ) {
      return `${RotasAlunos.Visualizar}/${ticket.usuario.alunoId}`
    }

    if (ticket.categoria === CategoriaTicket.Reingresso) {
      return `${RotasAluno.PainelFinanceiro}/${ticket.usuario.id}`
    }

    if (ticket.categoria === CategoriaTicket.CopiaEmentasDisciplina) {
      return `${RotasRequerimentos.CadastroEmentasDisciplinas}/${ticket?.requerimentoId}`
    }

    return '/'
  }

  const resolverTooltipTicket = (categoria: CategoriaTicket) => {
    if (categoria === CategoriaTicket.Documentacao) {
      return 'Ver documentos do aluno'
    }

    if (categoria === CategoriaTicket.DocumentacaoResponsavelFinanceiro) {
      return 'Ver documentos do responsável'
    }

    if (categoria === CategoriaTicket.PrazoNotaTCC) {
      return 'Ver situação aluno'
    }

    if (categoria === CategoriaTicket.CopiaEmentasDisciplina) {
      return 'Acessar Requerimento'
    }
    return 'Ver'
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  useEffect(() => {
    if (!setorAlterado) return
    tabelaRef.current.CarregarDados()
  }, [setorAlterado])

  useEffect(() => {
    if (!ticketFinalizado) return
    tabelaRef.current.CarregarDados()
  }, [ticketFinalizado])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Filas de Trabalho"
          atalhos={[
            {
              texto: 'Acadêmico'
            },
            {
              texto: 'Filas de Trabalho'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            status: [
              StatusTicket[StatusTicket.Aberto],
              StatusTicket[StatusTicket.Pendente]
            ]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeAluno"
                label="Nome do Usuário"
                maxLength={200}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_setores"
                name="setores"
                label="Setor"
                iconeFechar
                multiplo
                opcoes={SELECT_SETOR}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <SelectUnform
                id="slct_tipos"
                name="tipos"
                label="Tipo"
                iconeFechar
                multiplo
                opcoes={[
                  {
                    id: TipoTicket[TipoTicket.Informacao],
                    texto: 'Informação'
                  },
                  {
                    id: TipoTicket[TipoTicket.Solicitacao],
                    texto: 'Solicitação'
                  },
                  {
                    id: TipoTicket[TipoTicket.Manifestacao],
                    texto: 'Manifestação'
                  }
                ]}
              />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_categorias"
                name="categorias"
                label="Categoria"
                iconeFechar
                multiplo
                opcoes={[
                  {
                    id:
                      CategoriaTicket[CategoriaTicket.AtividadesComplementares],
                    texto: 'Atividades Complementares'
                  },
                  {
                    id: CategoriaTicket[CategoriaTicket.Documentacao],
                    texto: 'Documentação'
                  },
                  {
                    id:
                      CategoriaTicket[
                        CategoriaTicket.DocumentacaoResponsavelFinanceiro
                      ],
                    texto: 'Documentação Responsável'
                  },
                  {
                    id: CategoriaTicket[CategoriaTicket.CopiaEmentasDisciplina],
                    texto: 'Ementas de Disciplina'
                  },
                  {
                    id: CategoriaTicket[CategoriaTicket.PrazoNotaTCC],
                    texto: 'Prazo envio nota TCC'
                  }
                ]}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_status"
                name="status"
                label="Status"
                iconeFechar
                multiplo
                opcoes={[
                  {
                    id: StatusTicket[StatusTicket.Aberto],
                    texto: 'Aberto'
                  },
                  {
                    id: StatusTicket[StatusTicket.Pendente],
                    texto: 'Pendente'
                  },
                  {
                    id: StatusTicket[StatusTicket.Finalizado],
                    texto: 'Finalizado'
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="dataCriacaoInicio"
                label="Data Criação Início"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform name="dataCriacaoFim" label="Data Criação Fim" />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataEncerramentoInicio"
                label="Data Encerramento Início"
              />
            </Campo>
            <Campo>
              <DataInputUnform
                name="dataEncerramentoFim"
                label="Data Encerramento Fim"
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'numeroProtocolo',
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Protocolo</div>
              )
            },
            {
              Chave: 'nomeAluno',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>{linha.usuario.nomeApresentacao}</>
              ),
              RenderizarCabecalho: () => <div style={{ width: 150 }}>Nome</div>
            },
            {
              Chave: 'tipo',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>{TipoTicketPorExtenso[linha.tipo]}</>
              ),
              RenderizarCabecalho: () => <div style={{ width: 80 }}>Tipo</div>
            },
            {
              Chave: 'categoria',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>{CategoriaTicketPorExtenso[linha.categoria]}</>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Categoria</div>
              )
            },
            {
              Chave: 'setor',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>{SetorTicketPorExtenso[linha.setor]}</>
              ),
              RenderizarCabecalho: () => <div style={{ width: 80 }}>Setor</div>
            },
            {
              Chave: 'status',
              ComOrdenacao: true,
              RenderizarCabecalho: () => <div style={{ width: 80 }}>Status</div>
            },
            {
              Chave: 'dataCriacao',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataHoraLocaleString(linha.dataCriacao)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Data Criação</div>
              )
            },
            {
              Chave: 'dataAtualizacao',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataHoraLocaleString(linha.dataAtualizacao)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 125 }}>Data Atualização</div>
              )
            },
            {
              Chave: 'acoes',
              RenderizarCabecalho: () => <div>Ações</div>,
              Renderizar: (linha: TabelaDados) => {
                const LISTA_DOCUMENTOS_ID = `LISTA_DOCUMENTOS_ID/${linha.idUsuario}`
                const TICKET_LOG_ID = `TICKET_LOG_ID/${linha.idUsuario}`
                const ALTERAR_SETOR_ID = `ALTERAR_SETOR_ID/${linha.idUsuario}`
                const FINALIZAR_TICKET_ID = `FINALIZAR_TICKET_ID/${linha.idUsuario}`

                return (
                  <ContainerAcoes>
                    <IconeAcao
                      onClick={() => {
                        const rota = resolverLinkRedicionarTicket(
                          (linha as unknown) as PesquisaTicketDTO
                        )
                        history.push(rota)
                      }}
                      onContextMenu={() => {
                        const caminho = resolverLinkRedicionarTicket(
                          (linha as unknown) as PesquisaTicketDTO
                        )
                        const rota = window.open(caminho)
                        rota.focus()
                      }}
                      data-tip={(() => {
                        const dataTip = resolverTooltipTicket(
                          ((linha as unknown) as PesquisaTicketDTO).categoria
                        )
                        return dataTip
                      })()}
                      data-for={LISTA_DOCUMENTOS_ID}
                    >
                      {IconeDeListaDeDocumentos}
                      <Tooltip
                        id={LISTA_DOCUMENTOS_ID}
                        textColor={Cores.PRETO}
                        backgroundColor={Cores.CINZA_1_CLARO}
                        place="right"
                      />
                    </IconeAcao>
                    <IconeAcao
                      onClick={() => {
                        const caminho = RotasAcademico.HistoricoTicket
                        const rota = `${caminho}/${linha.id}`
                        history.push(rota)
                      }}
                      onContextMenu={() => {
                        const caminho = RotasAcademico.HistoricoTicket
                        const rota = window.open(`${caminho}/${linha.id}`)
                        rota.focus()
                      }}
                      data-tip="Ver histórico do ticket"
                      data-for={TICKET_LOG_ID}
                    >
                      {IconeRevelarSenha}
                      <Tooltip
                        id={TICKET_LOG_ID}
                        textColor={Cores.PRETO}
                        backgroundColor={Cores.CINZA_1_CLARO}
                        place="right"
                      />
                    </IconeAcao>
                    {linha.status !== StatusTicket.Finalizado && (
                      <>
                        <IconeAcao
                          onClick={() => {
                            modalAlterarSetor?.current?.abrir(linha.id)
                          }}
                          data-tip="Alterar setor"
                          data-for={ALTERAR_SETOR_ID}
                        >
                          {IconeEditar}
                          <Tooltip
                            id={ALTERAR_SETOR_ID}
                            textColor={Cores.PRETO}
                            backgroundColor={Cores.CINZA_1_CLARO}
                            place="right"
                          />
                        </IconeAcao>
                        <IconeAcao
                          onClick={() => {
                            modalFinalizarTicket?.current?.abrir(linha.id)
                          }}
                          data-tip="Finalizar ticket"
                          data-for={FINALIZAR_TICKET_ID}
                        >
                          {IconeCheckPequeno}
                          <Tooltip
                            id={FINALIZAR_TICKET_ID}
                            textColor={Cores.PRETO}
                            backgroundColor={Cores.CINZA_1_CLARO}
                            place="right"
                          />
                        </IconeAcao>
                      </>
                    )}
                  </ContainerAcoes>
                )
              }
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
      <ModalAlterarSetor
        id="modal-alterar-setor"
        ref={modalAlterarSetor}
        backdrop
        acaoPrimaria={alterarSetor}
      />
      <ModalFinalizarTicket
        id="modal-finalizar-ticket"
        ref={modalFinalizarTicket}
        backdrop
        acaoPrimaria={finalizarTicket}
      />
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaFilasTrabalho />
  </ErroLayoutContainer.Provider>
)

export { default as PaginaHistoricoTicket } from './historico'
