import React from 'react'

import { IconeCarregando } from '../icones'
import { Container } from './styles'
import { CarregandoProps } from './tipos'

export const Carregando: React.FC<CarregandoProps> = ({ texto }) => {
  return (
    <Container>
      {IconeCarregando}
      <p>{texto ?? 'Carregando ...'}</p>
    </Container>
  )
}
