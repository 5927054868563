import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { ModalBase, Botao, ModalRef, ModalBaseProps } from 'src/componentes'

import {
  ContainerBotoes,
  ContainerModal,
  ContainerTexto,
  Texto,
  Titulo
} from './styles'

export interface ModalAvisoPendenciaFinanceiraRef
  extends Omit<ModalRef, 'abrir'> {
  abrir: (nomeUsuario: string, cursos: string[]) => void
}

const ModalAvisoPendenciaFinanceiraComponent: React.ForwardRefRenderFunction<
  ModalAvisoPendenciaFinanceiraRef,
  ModalBaseProps
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [nomeUsuario, definirNomeUsuario] = useState('')
  const [cursos, definirCursos] = useState<string[]>([])

  const fechar = () => {
    definirNomeUsuario('')
    definirCursos([])
    definirAberta(false)
  }

  const abrir = (nomeUsuario: string, cursos: string[]) => {
    definirNomeUsuario(nomeUsuario)
    definirCursos(cursos)
    definirAberta(true)
  }

  useImperativeHandle<
    ModalAvisoPendenciaFinanceiraRef,
    ModalAvisoPendenciaFinanceiraRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-atualizar-data-cobranca"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <Titulo>Pendência Financeira</Titulo>
        <ContainerTexto>
          <Texto>Atenção {nomeUsuario}!</Texto>
          <Texto>
            Identificamos que você encontra-se com pendências financeiras junto
            à instituição no curso de Pós-Graduação em{' '}
            {cursos?.join(', ') ?? ''} e, por este motivo, não será possível
            prosseguir com sua nova matrícula sem a quitação desta dívida.
          </Texto>
          <Texto>
            Caso tenha interesse em realizar a quitação e matricular-se, entre
            em contato com nosso time financeiro pelos nossos canais de
            atendimento oficiais:
          </Texto>
          <Texto>E-mail: financas@ipgs.com.br</Texto>
          <Texto>WhatsApp: (51) 3062-9322</Texto>
          <Texto>Abraços</Texto>
          <Texto>Faculdade iPGS</Texto>
        </ContainerTexto>
        <ContainerBotoes>
          <Botao
            texto="Fechar"
            tema="Secundario"
            type="button"
            onClick={() => fechar()}
          />
        </ContainerBotoes>
      </ContainerModal>
    </ModalBase>
  )
}
export const ModalAvisoPendenciaFinanceira = forwardRef(
  ModalAvisoPendenciaFinanceiraComponent
)
