import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {
  AutenticacaoContainer,
  Botao,
  Cores,
  ErroLayoutContainer,
  IconeAdicionar
} from 'src/componentes'
import { Api } from 'src/servicos'

import { ModalOrdenarModulosFlex } from './componentes/modal-ordenar-modulos'
import { ModalOrdenarModulosRef } from './componentes/modal-ordenar-modulos/tipos'
import { ModalVerMaisInformacoes } from './componentes/modal-ver-mais'
import { ModalVerMaisRef } from './componentes/modal-ver-mais/tipos'
import {
  BotaoVerMais,
  ContainerBotao,
  ContainerConteudo,
  ContainerConteudoDois,
  ContainerModulosFixos,
  ContainerModulosFlex,
  InformacoesSuperior,
  ModulosFixos,
  ModulosFlex,
  NomeModulo
} from './style'
import { InformacoesModulosFlex, Modulo } from './tipos'

export const PaginaSelecaoModulosFlex: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const modalVerMaisRef = useRef<ModalVerMaisRef>(null)
  const modalOrdenarModulosRef = useRef<ModalOrdenarModulosRef>(null)
  const { usuario } = AutenticacaoContainer.useContainer()
  const [modulosFlex, definirModulosFlex] = useState<Modulo[]>([])
  const [modulosFixo, definirModulosFixo] = useState<Modulo[]>([])
  const [carregando, definirCarregando] = useState(false)
  const [modulosEscolhidos, definirModulosEscolhidos] = useState([])
  const [dados, definirDados] = useState<InformacoesModulosFlex>()
  const history = useHistory()

  const obterDados = async () => {
    try {
      const resposta = await Api.ObterModulosFlexiveis(id)

      if (!resposta?.precisaEscolherModulosFlex) {
        history.push('/aluno')
      }

      definirDados(resposta)

      const fixo = []
      const flex = []

      resposta?.modulos?.forEach(modulo => {
        return modulo?.tipoModulo === 'Flex'
          ? flex.push(modulo)
          : fixo.push(modulo)
      })

      definirModulosFlex(flex)
      definirModulosFixo(fixo)
    } catch (error) {}
  }

  const handleCheck = event => {
    let updatedList = [...modulosEscolhidos]
    if (event.target.checked) {
      updatedList = [
        ...modulosEscolhidos,
        { id: event.target.id, nome: event.target.name }
      ]
    } else {
      updatedList = modulosEscolhidos.filter(
        modulo => modulo.id !== event.target.id
      )
    }

    definirModulosEscolhidos(updatedList)
  }

  const estaSelecionado = (id: string) => {
    const retorno = modulosEscolhidos.some(e => e.id === id)
    return retorno
  }

  const deveBloquearModuloFlex = (id: string) => {
    const retorno =
      modulosEscolhidos.length >= dados.quantidadeModulosFlexObrigatorios &&
      !estaSelecionado(id)
    return retorno
  }

  const acaosucesso = async (item: any) => {
    try {
      definirCarregando(true)
      const payload = item.map(modulo => {
        return {
          id: modulo?.id,
          ordem: modulo?.ordem
        }
      })

      await Api.EnviarModulosFlexiveis(payload, id)

      history.push(`/sala-aula/outros-cursos/${id}`)
    } catch (error) {
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    obterDados()
  }, [])

  return (
    <>
      <ContainerConteudo>
        <h2>Bem vinda(o), {usuario.nomeApresentacao}!</h2>
        <p>
          Os módulos fixos já se encontram disponíveis no ambiente
          {' "Sala de Aula"'}.{' '}
          <span>
            Sendo eles obrigatórios e desta forma não podem ser trocados.
          </span>{' '}
          Na próxima etapa você irá escolher os módulos flexíveis do curso,
          contemplando desta forma a carga horária total do curso.
        </p>
        <h3>Módulos Fixos:</h3>
        <ContainerModulosFixos>
          {modulosFixo.map(modulo => (
            <ModulosFixos key={modulo.moduloId}>{modulo?.nome}</ModulosFixos>
          ))}
        </ContainerModulosFixos>
        <h4>
          Antes de escolher seus Módulos Flexíveis, ressaltamos algumas
          condições:
        </h4>
        <p>
          • Após a escolha dos Módulos Flex, é permitido realizar a troca
          somente uma vez.
        </p>
        <p>
          • Caso efetive a troca de módulo, não poderá escolher novamente o
          módulo a qual solicitou a troca;
        </p>
      </ContainerConteudo>
      <ContainerConteudoDois>
        <h2>Módulos Flexíveis</h2>
        <h3>
          Escolha {dados?.quantidadeModulosFlexObrigatorios} módulos dos
          disponíveis abaixo:
        </h3>
        <ContainerModulosFlex>
          {modulosFlex.map(modulo => (
            <div key={modulo.moduloId} style={{ position: 'relative' }}>
              <ModulosFlex
                selecionado={estaSelecionado(modulo.moduloId)}
                bloqueado={deveBloquearModuloFlex(modulo.moduloId)}
                htmlFor={modulo.moduloId}
                data-for={'tooltip-stepper'}
                data-tip={
                  !deveBloquearModuloFlex(modulo.moduloId)
                    ? ''
                    : 'Número máximo de módulos já selecionados.'
                }
              >
                <InformacoesSuperior>
                  <input
                    onChange={handleCheck}
                    type="checkbox"
                    name={modulo.nome}
                    disabled={deveBloquearModuloFlex(modulo.moduloId)}
                    id={modulo.moduloId}
                  />
                </InformacoesSuperior>
                <NomeModulo>
                  <p>{modulo.nome}</p>
                </NomeModulo>
                <ReactTooltip
                  effect="solid"
                  backgroundColor={Cores.CINZA_1_CLARO}
                  textColor={Cores.PRETO}
                  id={'tooltip-stepper'}
                  place="top"
                />
              </ModulosFlex>
              <BotaoVerMais
                onClick={() => modalVerMaisRef?.current?.abrir(modulo)}
                data-for={'tooltip-stepper'}
                data-tip={''}
              >
                {IconeAdicionar}Ver mais
              </BotaoVerMais>
            </div>
          ))}
        </ContainerModulosFlex>
        <ContainerBotao>
          <Botao
            style={{ color: 'white' }}
            disabled={
              modulosEscolhidos.length <
              dados?.quantidadeModulosFlexObrigatorios
            }
            id="btn_confirmar"
            texto="Confirmar"
            carregando={carregando}
            onClick={() =>
              modalOrdenarModulosRef.current.abrir(modulosEscolhidos)
            }
          />
        </ContainerBotao>
        <ModalVerMaisInformacoes
          id="modal-ver-mais"
          backdrop
          ref={modalVerMaisRef}
        />
        <ModalOrdenarModulosFlex
          id="modal-ordenar-modulos"
          backdrop
          ref={modalOrdenarModulosRef}
          acaoPrimaria={acaosucesso}
        />
      </ContainerConteudoDois>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaSelecaoModulosFlex />
  </ErroLayoutContainer.Provider>
)
