import styled from 'styled-components'

export const Form = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 39px 39px 39px;
  width: 400px;

  h1 {
    margin-bottom: 60px;
  }

  form {
    width: 100%;
  }

  button[type='submit'] {
    width: 100%;
    margin-top: 40px;
  }

  #btn-esqueceu-senha {
    margin-top: 32px;
  }

  @media (max-width: 425px) {
    input {
      font-size: 16px;
    }
  }
`

export const IconeBotao = styled.div`
  position: absolute;
  right: 60px;
`

export const Link = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
