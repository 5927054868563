export const Breakpoints = {
  MOBILE: 425,
  TABLET: 768,
  DESKTOP: 1200
}

export const Medias = {
  MOBILE: `(max-width: ${Breakpoints.MOBILE}px)`,
  TABLET: {
    MIN: `(min-width: ${Breakpoints.MOBILE + 1}px)`,
    MAX: `(max-width: ${Breakpoints.TABLET}px)`
  },
  NOTEBOOK: {
    MIN: `(min-width: ${Breakpoints.TABLET + 1}px)`,
    MAX: `(max-width: ${Breakpoints.DESKTOP}px)`
  },
  DESKTOP: `(min-width: ${Breakpoints.DESKTOP + 1}px)`
}
