import { TipoMaterialComplementar } from 'src/componentes'

import { Disciplina, Professor } from './'

export enum TipoMaterial {
  Anexo = 'Anexo',
  Video = 'Video',
  HiperLink = 'HiperLink'
}

export interface Material {
  titulo: string
  id: string
  url: string
  tipo: TipoMaterial
  professor?: Professor
  disciplina: Disciplina
  arquivo: File | string
  ordem: number
  tipoMaterialComplementar?: TipoMaterialComplementar
}
