import React from 'react'

import { Breadcrumb, FuncoesDataHora, Stepper } from 'src/componentes'
import { StepperItem } from 'src/componentes/stepper/tipos'
import { Api } from 'src/servicos'
import { SalaAulaMaterial } from 'src/tipos'

import { calcularDistanciaData } from '../../shared/funcoes/calcularDatas'
import SalaAulaContainer from '../container'

export const ComponenteStepper: React.FC = () => {
  const {
    salaAula,
    etapas,
    itensMarcados,
    definirItensMarcados,
    definirProgresso,
    definirPesquisaSatisfacaoLiberada
  } = SalaAulaContainer.useContainer()

  const naoIniciouSalaAula = salaAula?.dataInicio
    ? calcularDistanciaData(salaAula?.dataInicio) <= 0
    : false

  const mensagemSubTitulo = naoIniciouSalaAula
    ? `Curso inicia em ${FuncoesDataHora.dataToLocaleString(
        salaAula?.dataInicio
      )}.`
    : `Seu curso iniciou em ${FuncoesDataHora.dataToLocaleString(
        salaAula?.dataInicio
      )}.
  Você tem até ${FuncoesDataHora.dataToLocaleString(
    salaAula?.dataLimiteAcessoTempoDisponibilidade
  )} para finalizá-lo.`

  const marcarConcluido = async (
    id: string,
    concluido: boolean,
    itens: string[]
  ) => {
    const resposta = await Api.SalaAulaCursoLivre.MarcarMaterial(
      id,
      salaAula.id,
      concluido
    )

    if (resposta) {
      definirProgresso(resposta.progresso)
      definirPesquisaSatisfacaoLiberada(resposta.pesquisaSatisfacaoLiberada)
      if (concluido) {
        definirItensMarcados([...itens, id])
      } else {
        const indice = itens.findIndex(i => i === id)
        if (indice !== -1) {
          const novosItens = itens.filter((_, i) => i !== indice)
          definirItensMarcados(novosItens)
        }
      }
    }
  }

  const downloadMaterial = async (id: string) => {
    let material: SalaAulaMaterial = null
    salaAula.disciplinas?.forEach(disc => {
      const novoMaterial = disc.materiais?.find(mat => mat.id === id)

      if (novoMaterial) material = novoMaterial
    })

    if (material) {
      await Api.SalaAulaCursoLivre.DownloadMaterial(
        id,
        salaAula.id,
        `${material.nomeMaterial}${material.extensao}`
      )
    }
  }

  const getMaterialBlob = async (subItem: StepperItem) =>
    await Api.SalaAulaCursoLivre.RequisitarMaterialPorId(
      subItem.id,
      salaAula.id
    )

  return (
    <Stepper
      idItemSelecionado={
        salaAula.aprovado && !salaAula.pesquisaSatisfacaoId
          ? 'emitir-certificado'
          : salaAula.primeiroMaterialNaoLido
      }
      progresso={salaAula.progresso}
      itens={etapas}
      itensMarcados={itensMarcados}
      onClickConcluir={marcarConcluido}
      onClickDownload={downloadMaterial}
      onClickGetBlob={getMaterialBlob}
    >
      <Breadcrumb
        titulo={`${salaAula.nomeCurso} (${salaAula.cargaHoraria}h)`}
        atalhos={[
          {
            texto: 'Sala de Aula'
          }
        ]}
      />
      <p style={{ marginTop: '20px' }}>{mensagemSubTitulo}</p>
    </Stepper>
  )
}
