import React from 'react'

export default (
  <svg
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8565 24.4975H5.2104C3.9686 24.4975 2.95828 23.4873 2.95828 22.2454V10.3964C2.95828 9.96375 3.30897 9.61301 3.74163 9.61301C4.17428 9.61301 4.52497 9.96375 4.52497 10.3964V22.2454C4.52497 22.6234 4.83244 22.9309 5.2104 22.9309H21.8565C22.2345 22.9309 22.5419 22.6234 22.5419 22.2454V10.3964C22.5419 9.96375 22.8926 9.61301 23.3253 9.61301C23.7579 9.61301 24.1086 9.96375 24.1086 10.3964V22.2454C24.1086 23.4872 23.0983 24.4975 21.8565 24.4975Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8565 24.6975H5.2104C3.85815 24.6975 2.75828 23.5977 2.75828 22.2454V10.3964C2.75828 9.8533 3.19851 9.41301 3.74163 9.41301C4.28474 9.41301 4.72497 9.8533 4.72497 10.3964V22.2454C4.72497 22.5129 4.94289 22.7309 5.2104 22.7309H21.8565C22.124 22.7309 22.3419 22.5129 22.3419 22.2454V10.3964C22.3419 9.8533 22.7822 9.41301 23.3253 9.41301C23.8684 9.41301 24.3086 9.8533 24.3086 10.3964V22.2454C24.3086 23.5977 23.2088 24.6975 21.8565 24.6975ZM5.2104 24.4975H21.8565C23.0983 24.4975 24.1086 23.4872 24.1086 22.2454V10.3964C24.1086 9.96375 23.7579 9.61301 23.3253 9.61301C22.8926 9.61301 22.5419 9.96375 22.5419 10.3964V22.2454C22.5419 22.6234 22.2345 22.9309 21.8565 22.9309H5.2104C4.83244 22.9309 4.52497 22.6234 4.52497 22.2454V10.3964C4.52497 9.96375 4.17428 9.61301 3.74163 9.61301C3.30897 9.61301 2.95828 9.96375 2.95828 10.3964V22.2454C2.95828 23.4873 3.9686 24.4975 5.2104 24.4975Z"
      fill="black"
    />
    <path
      d="M25.2836 13.1378C25.0831 13.1378 24.8827 13.0613 24.7298 12.9083L14.9874 3.1661C14.1857 2.36435 12.8812 2.36435 12.0795 3.1661L2.33718 12.9084C2.03129 13.2143 1.53528 13.2143 1.22939 12.9084C0.923441 12.6025 0.923441 12.1065 1.22939 11.8006L10.9717 2.05825C12.3842 0.645686 14.6827 0.645686 16.0952 2.05825L25.8375 11.8005C26.1434 12.1065 26.1434 12.6024 25.8375 12.9083C25.6846 13.0613 25.4841 13.1378 25.2836 13.1378Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0952 2.05825C14.6827 0.645686 12.3842 0.645686 10.9717 2.05825L1.22939 11.8006C0.923441 12.1065 0.923441 12.6025 1.22939 12.9084C1.53528 13.2143 2.03129 13.2143 2.33718 12.9084L12.0795 3.1661C12.8812 2.36435 14.1857 2.36435 14.9874 3.1661L24.7298 12.9083C24.8827 13.0613 25.0831 13.1378 25.2836 13.1378C25.4841 13.1378 25.6846 13.0613 25.8375 12.9083C26.1434 12.6024 26.1434 12.1065 25.8375 11.8005L16.0952 2.05825ZM14.846 3.30752C14.1224 2.58388 12.9445 2.58387 12.2209 3.30752L2.47862 13.0498C2.09461 13.4339 1.47196 13.4339 1.08795 13.0498C0.703914 12.6658 0.703918 12.0432 1.08796 11.6592C1.08797 11.6592 1.08796 11.6592 1.08796 11.6592L10.8302 1.91683C12.3209 0.426161 14.746 0.426158 16.2367 1.91684L25.9789 11.6591C26.363 12.0432 26.363 12.6658 25.9789 13.0498C25.787 13.2418 25.535 13.3378 25.2836 13.3378C25.0323 13.3378 24.7803 13.2418 24.5883 13.0498C24.5883 13.0497 24.5883 13.0498 24.5883 13.0498L14.846 3.30752Z"
      fill="black"
    />
    <path
      d="M16.6669 24.4975H10.4001C9.96743 24.4975 9.61674 24.1467 9.61674 23.7141V16.7619C9.61674 15.4121 10.7149 14.314 12.0647 14.314H15.0022C16.352 14.314 17.4502 15.4121 17.4502 16.7619V23.7141C17.4502 24.1467 17.0995 24.4975 16.6669 24.4975ZM11.1834 22.9308H15.8835V16.7619C15.8835 16.276 15.4882 15.8807 15.0022 15.8807H12.0647C11.5788 15.8807 11.1834 16.276 11.1834 16.7619V22.9308Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6669 24.6975H10.4001C9.85697 24.6975 9.41674 24.2572 9.41674 23.7141V16.7619C9.41674 15.3017 10.6044 14.114 12.0647 14.114H15.0022C16.4625 14.114 17.6502 15.3017 17.6502 16.7619V23.7141C17.6502 24.2572 17.21 24.6975 16.6669 24.6975ZM15.8835 16.7619C15.8835 16.276 15.4882 15.8807 15.0022 15.8807H12.0647C11.5788 15.8807 11.1834 16.276 11.1834 16.7619V22.9308H15.8835V16.7619ZM15.6835 22.7308V16.7619C15.6835 16.3865 15.3777 16.0807 15.0022 16.0807H12.0647C11.6892 16.0807 11.3834 16.3865 11.3834 16.7619V22.7308H15.6835ZM10.4001 24.4975H16.6669C17.0995 24.4975 17.4502 24.1467 17.4502 23.7141V16.7619C17.4502 15.4121 16.352 14.314 15.0022 14.314H12.0647C10.7149 14.314 9.61674 15.4121 9.61674 16.7619V23.7141C9.61674 24.1467 9.96743 24.4975 10.4001 24.4975Z"
      fill="black"
    />
  </svg>
)
