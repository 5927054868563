import styled from 'styled-components'

import { Breakpoints } from '../../styles/breakpoints'
import { Cores } from '../../styles/cores'
import { BotaoProps } from './tipos'

export const Componente = styled.div`
  display: flex;
  position: absolute;
  height: 57px;
  margin-left: 8px;

  @media (max-width: ${Breakpoints.MOBILE}px) {
    height: 51px;
  }
`

export const Botao = styled.button<BotaoProps>`
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  outline: none;
  transition: 0.17s;
  padding: 20px 24px 20px 24px;
  align-items: center;
  border: 1px solid ${Cores.CINZA_3};
  cursor: pointer;

  border-right: ${props =>
    props.ultimo ? `1px solid ${Cores.CINZA_3}` : 'none'};

  border-bottom: ${props =>
    props.selecionado
      ? `2px solid ${Cores.PRIMARIA_2}`
      : `1px solid ${Cores.CINZA_3}`};

  background-color: ${props =>
    props.selecionado ? Cores.CINZA_3_CLARO : Cores.BRANCO};

  color: ${props =>
    props.selecionado ? Cores.PRIMARIA_2 : Cores.CINZA_2_CLARO};

  border-radius: ${props =>
    props.primeiro
      ? '4px 0px 0px 0px'
      : props.ultimo
      ? ' 0px 4px 0px 0px'
      : '0px'};

  &:hover {
    color: ${props => !props.selecionado && Cores.PRIMARIA_1};
    background-color: ${props =>
      !props.selecionado && Cores.CINZA_3_MAIS_CLARO};
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    padding: 18px 10px 18px 10px;
    font-size: 12px;
  }
`
