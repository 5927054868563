import React, { useEffect, useState } from 'react'

import { criar } from 'src/servicos/instancias-api'

import AutenticacaoContainer from '../autenticacao/container'

export const ControladorInstanciasApis: React.FC = ({ children }) => {
  const { usuario, sair } = AutenticacaoContainer.useContainer()
  const [pronto, definirPronto] = useState(false)

  useEffect(() => {
    criar(usuario?.token ?? '', sair)
    definirPronto(true)
  }, [usuario])

  return <>{pronto && children}</>
}
