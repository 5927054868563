import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;

  button {
    width: 100px;
  }

  & > .btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`
export const SubtituloModal = styled.h5`
  margin-bottom: 60px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-top: 10px;
`

export const Titulo = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
`
export const ModalCorpo = styled.div`
  width: 574px;
  min-height: 470px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  overflow-y: auto;
  max-height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  form {
    width: 100%;
  }
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
