import React from 'react'
import ReactPlayer from 'react-player'
import { useHistory, useParams } from 'react-router-dom'

import { v4 } from 'uuid'

import { businessUnitys } from './mock'
import { Container, Card, IconContainer, TopicList, Title } from './styles'

export const BusinessUnityComponent: React.FC = () => {
  const history = useHistory()

  return (
    <>
      {businessUnitys.map((unity, index) => (
        <Card
          key={index}
          onClick={() => {
            history.push(`/sistema/FAQ/${unity.id}`)
          }}
        >
          {unity.title}
        </Card>
      ))}
    </>
  )
}

export const UnityComponent: React.FC<{ unityId: string }> = ({ unityId }) => {
  const history = useHistory()

  return (
    <>
      {businessUnitys
        .find(u => u.id === unityId)
        .topics.map((topic, index) => (
          <Card
            key={index}
            onClick={() => {
              topic.questionsAndAwnsers &&
                topic.questionsAndAwnsers.length > 0 &&
                history.push(`/sistema/FAQ/${unityId}/${topic.id}`)
            }}
          >
            <IconContainer>{topic?.icon}</IconContainer>
            {topic.title}
          </Card>
        ))}
    </>
  )
}

export const TopicComponent: React.FC<{ unityId: string; topicId: string }> = ({
  unityId,
  topicId
}) => {
  const history = useHistory()

  const topic = businessUnitys
    .find(u => u.id === unityId)
    .topics.find(t => t.id === topicId)

  return (
    <>
      <Title>{topic.title}</Title>
      <div
        dangerouslySetInnerHTML={{
          __html: topic.description
        }}
      />
      <TopicList>
        {businessUnitys
          .find(u => u.id === unityId)
          .topics.find(t => t.id === topicId)
          .questionsAndAwnsers.map((qna, index) => (
            <li key={v4()} style={{ listStyleType: 'disc' }}>
              <div
                key={index}
                onClick={() => {
                  history.push(`/sistema/FAQ/${unityId}/${topicId}/${qna.id}`)
                }}
              >
                {qna.title}
              </div>
            </li>
          ))}
      </TopicList>
    </>
  )
}

export const QnaComponent: React.FC<{
  unityId: string
  topicId: string
  qnaId: string
}> = ({ unityId, topicId, qnaId }) => {
  const qna = businessUnitys
    .find(u => u.id === unityId)
    .topics.find(t => t.id === topicId)
    .questionsAndAwnsers.find(q => q.id === qnaId)

  return (
    <>
      <Title>{qna.title}</Title>
      {qna.response && (
        <div
          dangerouslySetInnerHTML={{
            __html: qna.response
          }}
        />
      )}

      {qna.imageUrl &&
        qna.imageUrl.length > 0 &&
        qna.imageUrl.map(e => (
          <div
            key={e}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img src={e} width="100%" height="100%" />
          </div>
        ))}

      {qna.videoUrl && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <ReactPlayer
            className="player"
            url={qna.videoUrl}
            config={{ vimeo: { playerOptions: { controls: true } } }}
          />
        </div>
      )}
    </>
  )
}

export const PaginaFAQ: React.FC = () => {
  const { unityId, topicId, qnaId } = useParams<{
    unityId?: string
    topicId?: string
    qnaId?: string
  }>()

  if (qnaId) {
    return (
      <Container>
        <QnaComponent unityId={unityId} topicId={topicId} qnaId={qnaId} />
      </Container>
    )
  }

  if (topicId) {
    return (
      <Container>
        <TopicComponent unityId={unityId} topicId={topicId} />
      </Container>
    )
  }

  if (unityId) {
    return (
      <Container>
        <UnityComponent unityId={unityId} />
      </Container>
    )
  }

  return (
    <Container>
      <BusinessUnityComponent />
    </Container>
  )
}
