import React from 'react'

export default (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9.5" fill="#666666" fillOpacity="0.8" />
    <path
      d="M14.9899 7.01742L13.4009 5.42851L8.14201 10.6253L5.65949 8.14279L4.07058 9.7317L8.14201 13.8031L14.9899 7.01742Z"
      fill="white"
    />
  </svg>
)
