import React from 'react'

export default (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8C16 3.58878 12.4112 0 7.99995 0C3.5888 0 0 3.58878 0 8C0 12.4112 3.5888 16 7.99995 16C12.4112 16 16 12.4112 16 8ZM7.99995 13.8037C4.79974 13.8037 2.19613 11.2001 2.19613 7.99991C2.19613 6.79585 2.56493 5.67637 3.19516 4.7482L11.2518 12.8047C10.3235 13.4349 9.20402 13.8037 7.99995 13.8037ZM4.74823 3.19523C5.67649 2.565 6.79589 2.19621 7.99995 2.19621C11.2002 2.19621 13.8037 4.7998 13.8037 8C13.8037 9.20406 13.4349 10.3234 12.8048 11.2517L4.74823 3.19523Z"
      fill="#EC424B"
    />
  </svg>
)
