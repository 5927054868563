import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  Cores,
  Carregando,
  ErroContainerLayout,
  IconeDownload,
  Tooltip,
  SubTitulo
} from 'src/componentes'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'

import { ModalDeclaracoes, ModalDeclaracoesRef } from './componentes'
import {
  BotaoDeAcao,
  Container,
  ContainerMain,
  TituloPrincipal,
  Dados
} from './styles'

const tituloDaPagina = 'Histórico de Cursos'

export const HistoricoCursos: React.FC = () => {
  const { usuario } = AutenticacaoContainer.useContainer()
  const modalDeclaracoesRef = useRef<ModalDeclaracoesRef>(null)
  const [pronto, definirPronto] = useState(false)
  const tabelaCursosLivres = useRef<TabelaRef>()
  const tabelaCursosOutros = useRef<TabelaRef>()

  const obterDadosCursoLivre = async (): Promise<TabelaResposta | null> => {
    try {
      const { data } = await Api.DadosHistoricoCursoLivre(usuario.id)
      return {
        Dados: data
      } as TabelaResposta
    } catch (error) {
      toast('Erro ao carregar dados cursos livres!')
    }
  }

  const obterDadosCursosOutros = async (): Promise<TabelaResposta | null> => {
    try {
      const { data } = await Api.DadosHistoricoCursoOutros(usuario.id)

      return {
        Dados: data
      } as TabelaResposta
    } catch (error) {
      toast('Erro ao carregar dados cursos pós graduação!')
    }
  }

  const acaoPrimariaDeclaracoes = async () => {}

  useEffect(() => {
    definirPronto(true)
  }, [])

  return pronto ? (
    <Container>
      <ContainerMain>
        <TituloPrincipal>{tituloDaPagina}</TituloPrincipal>
        <Dados>
          <SubTitulo texto="Curso Livre/Evento" />
          <Tabela
            ref={tabelaCursosLivres}
            ObterDados={obterDadosCursoLivre}
            Colunas={[
              {
                Chave: 'nomeCurso',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>{linha.nomeCurso}</span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 250 }}>Curso</div>
                )
              },
              {
                Chave: 'cargaHoraria',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>{linha.cargaHoraria}</span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 60 }}>Carga Horária</div>
                )
              },
              {
                Chave: 'dataInicio',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>{formatadores.dataLocaleString(linha.dataInicio)}</span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 90 }}>Data Início</div>
                )
              },
              {
                Chave: 'dataConclusao',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>
                    {formatadores.dataLocaleString(linha.dataConclusao)}
                  </span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 90 }}>Data Conclusão</div>
                )
              },
              {
                Chave: 'nota',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => <span>{linha.nota}</span>,
                RenderizarCabecalho: () => <div style={{ width: 60 }}>Nota</div>
              }
            ]}
          />
        </Dados>
        <Dados>
          <SubTitulo texto="Pós Graduação" />
          <Tabela
            ref={tabelaCursosOutros}
            ObterDados={obterDadosCursosOutros}
            Colunas={[
              {
                Chave: 'nomeCurso',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>{linha.nomeCurso}</span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 250 }}>Curso</div>
                )
              },
              {
                Chave: 'cargaHoraria',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>{linha.cargaHoraria}</span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 60 }}>Carga Horária</div>
                )
              },
              {
                Chave: 'dataInicio',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>{formatadores.dataLocaleString(linha.dataInicio)}</span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 90 }}>Data Início</div>
                )
              },
              {
                Chave: 'dataConclusao',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>
                    {formatadores.dataLocaleString(linha.dataConclusao)}
                  </span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 90 }}>Data Conclusão</div>
                )
              },
              {
                Chave: 'ipAceiteTermos',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>{linha.ipAceiteTermos}</span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 90 }}>IP Aceite Termos</div>
                )
              },
              {
                Chave: 'dataHoraAceiteTermos',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => (
                  <span>
                    {formatadores.dataHoraLocaleString(
                      linha.dataHoraAceiteTermos
                    )}
                  </span>
                ),
                RenderizarCabecalho: () => (
                  <div style={{ width: 100 }}>Data/Hora Aceite Termos</div>
                )
              },
              {
                Chave: 'acoes',
                Texto: '',
                Alinhamento: 'center',
                Renderizar: (linha: TabelaDados) => {
                  const salaId = linha.salaId
                  const documentoDownloadDeclaracoes = `DECLARACOES/${salaId}`

                  return (
                    <BotaoDeAcao
                      onClick={() => {
                        modalDeclaracoesRef?.current.abrir(salaId)
                      }}
                    >
                      <div
                        data-tip="Baixar Declarações"
                        data-for={documentoDownloadDeclaracoes}
                      >
                        {IconeDownload}
                      </div>
                      <Tooltip
                        id={documentoDownloadDeclaracoes}
                        place={'right'}
                        textColor={Cores.PRETO}
                        backgroundColor={Cores.CINZA_1_CLARO}
                      />
                    </BotaoDeAcao>
                  )
                }
              }
            ]}
          />
        </Dados>
        <ModalDeclaracoes
          ref={modalDeclaracoesRef}
          backdrop
          id="modal-declaracoes"
          acaoPrimaria={acaoPrimariaDeclaracoes}
        />
      </ContainerMain>
    </Container>
  ) : !pronto ? (
    <Carregando texto="Carregando dados..." />
  ) : (
    <ErroContainerLayout />
  )
}

export default withRouter(HistoricoCursos)
