import React from 'react'

export default (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.75C8 9.89062 7.89063 10 7.75 10H4.25C4.10938 10 4 9.89062 4 9.75V8.5C4 8.35937 4.10938 8.25 4.25 8.25H5V5.75H4.25C4.10938 5.75 4 5.64062 4 5.5V4.25C4 4.10937 4.10938 4 4.25 4H6.75C6.89063 4 7 4.10937 7 4.25V8.25H7.75C7.89063 8.25 8 8.35937 8 8.5V9.75ZM7 2.75C7 2.89062 6.89063 3 6.75 3H5.25C5.10938 3 5 2.89062 5 2.75V1.5C5 1.35937 5.10938 1.25 5.25 1.25H6.75C6.89063 1.25 7 1.35937 7 1.5V2.75ZM12 6C12 2.6875 9.3125 -5.96046e-07 6 -5.96046e-07C2.6875 -5.96046e-07 0 2.6875 0 6C0 9.3125 2.6875 12 6 12C9.3125 12 12 9.3125 12 6Z"
      fill="#FF619B"
    />
  </svg>
)
