import styled from 'styled-components'

import { ModelosDeRecibo } from './recibo-para-impressao.component'

const proporcao = 1.7

const proporcional = (tamanho: string) => `calc(${tamanho} / ${proporcao})`

interface Modelo {
  modelo: ModelosDeRecibo
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 90px 120px;
`

export const Esferas = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: ${proporcional('307px')};
  z-index: 0;
`

export const Logo = styled.img`
  width: ${proporcional('270px')};
`

export const ContainerDeDescricao = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 1;
`

export const Titulo = styled.h1`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: ${proporcional('230px')};
  margin-bottom: ${proporcional('30px')};
`

export const Paragrafo = styled.p`
  font-size: 16px;
  font-weight: normal;
`

export const ParagrafoPrincipal = styled(Paragrafo)`
  margin-bottom: ${proporcional('70px')};
`

export const ParagrafoDaMoeda = styled(Paragrafo)`
  margin-bottom: ${proporcional('70px')};
`

export const ParagrafoDaDataAtual = styled(Paragrafo)<Modelo>`
  text-align: right;
  margin-bottom: ${({ modelo }) =>
    modelo === ModelosDeRecibo.Matricula ||
    modelo === ModelosDeRecibo.TrocaCurso
      ? proporcional('220px')
      : proporcional('120px')};
`

export const ParagrafoDaDataPorExtenso = styled(Paragrafo)<Modelo>`
  text-align: right;
  margin-bottom: ${({ modelo }) =>
    modelo === ModelosDeRecibo.Matricula ||
    modelo === ModelosDeRecibo.TrocaCurso
      ? proporcional('220px')
      : proporcional('120px')};
`

export const ParagrafoDaColuna = styled(Paragrafo)`
  color: gray;
`

export const Assinatura = styled.img<Modelo>`
  width: ${proporcional('455px')};
  margin-bottom: ${({ modelo }) =>
    modelo === ModelosDeRecibo.Matricula ||
    modelo === ModelosDeRecibo.TrocaCurso
      ? proporcional('150px')
      : proporcional('100px')};
`

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${proporcional('65px')};
`

export const PrimeiraColuna = styled(Coluna)`
  width: 50%;
`

export const SegundaColuna = styled(Coluna)`
  width: 30%;
`

export const TerceiraColuna = styled(Coluna)`
  width: 20%;
`

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`
