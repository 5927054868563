import style from 'styled-components'

import { Cores } from '../styles'
import { BarraProgressoProps } from './tipos'

export const Container = style.div<BarraProgressoProps>`
    position: relative;
    width: ${props => props.width};
    height: 13px;
    text-align: right;
    font-size: 12px;
    color: ${Cores.CINZA_1_ESCURO};

    div {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 30px;
      border-radius: 6px;
      height: 13px;
      background-color: ${Cores.CINZA_1_ESCURO};

      &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 30px;
          border-radius: 6px;
          width: ${props => props.percent}%;
          height: 13px;
          background-color: ${Cores.SECUNDARIA_3_ESCURO};
      }
    }
`
