export enum StatusRecorrencia {
  Ativo = 'Ativo',
  Finalizado = 'Finalizado',
  DesativadaLojista = 'DesativadaLojista',
  DesativadaNumeroTentativas = 'DesativadaNumeroTentativas',
  DesativadaCartaoVencido = 'DesativadaCartaoVencido'
}

export enum StatusRecorrenciaPorExtenso {
  'Ativo' = 'Ativo',
  'Finalizado' = 'Finalizado',
  'DesativadaLojista' = 'Desativada Lojista',
  'DesativadaNumeroTentativas' = 'Desativada Número Tentativas',
  'DesativadaCartaoVencido' = 'Desativada Cartão Vencido'
}
