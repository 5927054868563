import React from 'react'

import { StepperContainer } from 'src/componentes'

import { SobreCurso as SobreCursoComponente } from '../../shared'
import SalaAulaOutrosContainer from '../container'

export const SobreCurso: React.FC = () => {
  const { avancarStepper } = StepperContainer.useContainer()
  const { salaAula } = SalaAulaOutrosContainer.useContainer()

  return (
    <SobreCursoComponente
      informacoesGeraisCurso={salaAula.informacoesGeraisCurso}
      progresso={salaAula.progresso}
      dataInicio={salaAula?.dataInicio}
      acaoAvancar={avancarStepper}
    />
  )
}
