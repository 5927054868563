import React from 'react'

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    className="seta"
  >
    <path
      d="M1.24268 10L5.48532 5.75736L1.24268 1.51472"
      stroke="#013B9A"
      strokeWidth="2"
    />
  </svg>
)
