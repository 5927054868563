import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 70px;
`

export const TurmasProfessor = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const TextoAviso = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: black;
  box-sizing: border-box;
  padding-top: 24px;
`
