import React from 'react'
export default (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 21.436,7.586 17.438,3.566 C 16.686,2.81 15.375,2.802 14.608,3.56 14.412,3.756 14.258,3.996 14.19,4.189 13.537,5.551 12.836,6.404 11.936,6.916 L 11.719,7.021 C 10.751,7.506 9.434,8 7,8 6.734,8 6.479,8.052 6.234,8.152 5.75,8.354 5.355,8.747 5.152,9.236 c -0.199,0.484 -0.199,1.041 0,1.525 0.104,0.249 0.25,0.471 0.435,0.651 L 8.822,14.647 5,20 l 5.352,-3.822 3.227,3.227 c 0.186,0.189 0.406,0.339 0.656,0.441 0.247,0.103 0.503,0.154 0.766,0.154 0.263,0 0.519,-0.052 0.765,-0.154 0.498,-0.205 0.883,-0.592 1.08,-1.078 0.103,-0.242 0.155,-0.507 0.155,-0.768 0,-2.436 0.494,-3.752 0.978,-4.721 0.496,-0.992 1.369,-1.748 2.754,-2.414 0.271,-0.104 0.51,-0.256 0.711,-0.457 0.772,-0.782 0.768,-2.051 -0.008,-2.822 z"
      fill="#828282"
    />
  </svg>
)
