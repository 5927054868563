export const focarPrimeiroCampoComErro = (idContexto?: string): void => {
  setTimeout(() => {
    const campo = document
      .querySelectorAll(
        `${
          idContexto && typeof idContexto === 'string' ? `#${idContexto} ` : ''
        }.error`
      )
      .item(0) as HTMLElement

    if (campo) {
      campo.scrollIntoView({ block: 'start', behavior: 'smooth' })
      campo.focus()
    }
  }, 0)
}

export const focarPrimeiroCampoOuEditorComErro = (
  pesquisa: string
): ((id?: string) => void) => {
  return (id?: string): void => {
    setTimeout(() => {
      const scrollTo = (alvo: HTMLElement) => {
        alvo.scrollIntoView({ block: 'start', behavior: 'smooth' })
        alvo.focus()
      }

      const selecao = `${id && typeof id === 'string' ? `#${id} ` : ''}.error`
      const campo = document.querySelectorAll(selecao).item(0) as HTMLElement

      if (campo) {
        scrollTo(campo)
      } else {
        const editor = document.querySelector(pesquisa)

        if (editor) {
          scrollTo(editor.parentElement)
        }
      }
    }, 0)
  }
}

export const focarPrimeiroCampoComErroLista = (idContexto?: string): void => {
  setTimeout(() => {
    const primeiroItemComErro = document.getElementsByClassName(
      Object.keys(idContexto)[0]
    )[0]

    if (primeiroItemComErro) {
      primeiroItemComErro.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, 0)
}
