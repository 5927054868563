import React from 'react'

export default (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8 6.09995L12.5 0.799951C12.4 0.699951 12.4 0.699951 12.3 0.699951H0.399976C0.199976 0.699951 0.0999756 0.799951 0.0999756 0.999951V23C0.0999756 23.2 0.199976 23.2999 0.399976 23.2999H17.6C17.8 23.2999 17.9 23.2 17.9 23V6.29995C17.9 6.29995 17.9 6.19995 17.8 6.09995ZM12.8 5.79995V2.99995L15.6 5.79995H12.8ZM11.8 7.19995H16.6V21.9H1.39998V2.09995H11.4V6.89995C11.4 6.99995 11.6 7.19995 11.8 7.19995Z"
      fill="currentColor"
    />
  </svg>
)
