import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform,
  DataInputUnform,
  CpfInputUnform
} from 'src/componentes'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import { RequisitarListaCursosPorTextoFiltro } from 'src/servicos/api'
import {
  AlunoSituacao,
  FormaPagamentoCobranca,
  ModalidadeCurso,
  TipoDescontoCupom,
  TipoOrdenacao,
  UtilizacaoCupom,
  UtilizacaoCupomPorExtenso
} from 'src/tipos'

import { Form, Container, Campo, Dados, ContainerExportar } from './styles'
import { DadosPesquisa } from './tipos'

export const PaginaCuponsDesconto: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()

  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterRelatorioCuponsDesconto(
      {
        nomeCupom: dadosPesquisa?.nomeCupom,
        codigoCupom: dadosPesquisa?.codigoCupom,
        modalidadeCurso: dadosPesquisa?.modalidadeCurso,
        tipoCupom: dadosPesquisa?.tipoCupom,
        dataInicioCupom: dadosPesquisa?.dataInicioCupom,
        dataFimCupom: dadosPesquisa?.dataFimCupom,
        utilizacaoCupom: dadosPesquisa?.utilizacaoCupom,
        formaPagamento: dadosPesquisa?.formaPagamento,
        cpf: dadosPesquisa?.cpf,
        cursosId: dadosPesquisa?.curso
      },
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina:
          dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
            ? 1
            : dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const downloadDados = async () => {
    try {
      const download = await Api.ObterDownloadRelatorioCuponsDesconto({
        nomeCupom: dadosPesquisa?.nomeCupom,
        codigoCupom: dadosPesquisa?.codigoCupom,
        modalidadeCurso: dadosPesquisa?.modalidadeCurso,
        tipoCupom: dadosPesquisa?.tipoCupom,
        dataInicioCupom: dadosPesquisa?.dataInicioCupom,
        dataFimCupom: dadosPesquisa?.dataFimCupom,
        utilizacaoCupom: dadosPesquisa?.utilizacaoCupom,
        formaPagamento: dadosPesquisa?.formaPagamento,
        cpf: dadosPesquisa?.cpf,
        cursosId: dadosPesquisa?.curso
      })

      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    }
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Cupons de Desconto"
          atalhos={[
            {
              texto: 'Relatórios Gerais'
            },
            {
              texto: 'Cupons de Desconto'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: AlunoSituacao[AlunoSituacao.Todas]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nomeCupom"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo mr>
              <InputUnform
                type="text"
                name="codigoCupom"
                label="Código"
                maxLength={20}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_modalidade"
                name="modalidadeCurso"
                label="Modalidade do Curso"
                placeholder="Selecione"
                multiplo
                opcoes={[
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
                    texto: 'Graduação/Tecnólogo'
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Livre],
                    texto: 'Livre'
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
                    texto: 'Pós Graduação EAD'
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
                    texto: 'Pós Graduação EAD Flex'
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
                    texto: 'Pós Graduação EAD Full'
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
                    texto: 'Pós Graduação Presencial'
                  },
                  {
                    id: ModalidadeCurso[ModalidadeCurso.Evento],
                    texto: 'Evento'
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <SelectUnform
                id="slct_tipo_cupom"
                name="tipoCupom"
                label="Tipo Cupom"
                iconeFechar
                multiplo
                opcoes={[
                  {
                    id: TipoDescontoCupom[TipoDescontoCupom.Valor],
                    texto: 'Valor'
                  },
                  {
                    id: TipoDescontoCupom[TipoDescontoCupom.Porcentagem],
                    texto: 'Porcentagem'
                  }
                ]}
              />
            </Campo>
            <Campo mr>
              <DataInputUnform name="dataInicioCupom" label="Data de Início" />
            </Campo>
            <Campo mr>
              <DataInputUnform name="dataFimCupom" label="Data de Fim" />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_utilizacao_cupom"
                name="utilizacaoCupom"
                label="Utilização Cupom"
                iconeFechar
                multiplo
                opcoes={[
                  {
                    id: UtilizacaoCupom[UtilizacaoCupom.TaxaMatricula],
                    texto:
                      UtilizacaoCupomPorExtenso[UtilizacaoCupom.TaxaMatricula]
                  },
                  {
                    id: UtilizacaoCupom[UtilizacaoCupom.PlanoPagamento],
                    texto:
                      UtilizacaoCupomPorExtenso[UtilizacaoCupom.PlanoPagamento]
                  },
                  {
                    id: UtilizacaoCupom[UtilizacaoCupom.TCC],
                    texto: UtilizacaoCupomPorExtenso[UtilizacaoCupom.TCC]
                  }
                ]}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr style={{ marginTop: '25px' }}>
              <SelectUnform
                id="slct_formaPagamento"
                name="formaPagamento"
                label="Forma de Pagamento"
                placeholder="Selecione"
                multiplo
                opcoes={[
                  {
                    id: FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto],
                    texto: FormaPagamentoCobranca.Boleto
                  },
                  {
                    id:
                      FormaPagamentoCobranca[
                        FormaPagamentoCobranca.CartaoCredito
                      ],
                    texto: FormaPagamentoCobranca.CartaoCredito
                  }
                ]}
                iconeFechar
              />
            </Campo>
            <Campo mr style={{ marginTop: '25px' }}>
              <CpfInputUnform id="ipt_cpf" name="cpf" label="CPF" />
            </Campo>
            <Campo>
              <SelectAsyncUnform
                id="slct_curso"
                name="curso"
                label="Curso"
                multiplo
                buscarPorTexto={RequisitarListaCursosPorTextoFiltro}
                buscarPorId={RequisitarListaCursosPorTextoFiltro}
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <ContainerExportar>
        <Botao
          type="button"
          id="btn-download-relatorio"
          texto="Exportar"
          onClick={downloadDados}
        />
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nomeCupom',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.nomeCupom}</span>
              ),
              RenderizarCabecalho: () => <div style={{ width: 200 }}>Nome</div>
            },
            {
              Chave: 'codigoCupom',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Código</div>
              )
            },
            {
              Chave: 'modalidadeCurso',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 160 }}>Modalidade Curso</div>
              )
            },
            {
              Chave: 'desconto',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Desconto</div>
              )
            },
            {
              Chave: 'dataInicioCupom',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataInicioCupom)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Início Cupom</div>
              )
            },
            {
              Chave: 'dataFimCupom',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.dataLocaleString(linha.dataFimCupom)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Fim Cupom</div>
              )
            },
            {
              Chave: 'utilizacaoCupom',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 120 }}>Utilização Cupom</div>
              )
            },
            {
              Chave: 'formaPagamento',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Forma Pagamento</div>
              )
            },
            {
              Chave: 'validoAVista',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Válido À Vista</div>
              ),
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.validoAVista === false ? 'Não' : 'Sim'}</span>
              )
            },
            {
              Chave: 'limitePorCupom',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.limitePorCupom}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Limite por Cupom</div>
              )
            },
            {
              Chave: 'limitePorCliente',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Limite por Cliente</div>
              )
            },
            {
              Chave: 'quantidadeUso',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => <>{linha.quantidadeUso}</>,
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Quantidade Uso</div>
              )
            },
            {
              Chave: 'quantidadeUsoRestante',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.quantidadeUsoRestante}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Quantidade Uso Restante</div>
              )
            },
            {
              Chave: 'curso',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => <span>{linha.curso}</span>,
              RenderizarCabecalho: () => (
                <div style={{ width: 250 }}>Curso(s)</div>
              )
            },
            {
              Chave: 'planoPagamento',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.planoPagamento}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Plano Pagamento</div>
              )
            },
            {
              Chave: 'dataCriacao',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{formatadores.dataLocaleString(linha.dataCriacao)}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Criação</div>
              )
            },
            {
              Chave: 'criadoPor',
              ComOrdenacao: true,
              RenderizarCabecalho: () => (
                <div style={{ width: 120 }}>Criado Por</div>
              )
            },
            {
              Chave: 'dataUltimaAlteracao',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataLocaleString(linha.dataUltimaAlteracao)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Data Última Alteração</div>
              )
            },
            {
              Chave: 'modificadoPor',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <span>{linha.modificadoPor}</span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 90 }}>Modificado Por</div>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaCuponsDesconto />
  </ErroLayoutContainer.Provider>
)
