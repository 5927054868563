import { colunaCelular } from './celular'
import { colunaDataEnvioAvaliacao } from './data-envio-avaliacao'
import { colunaDataInscricaoTcc } from './data-inscricao-tcc'
import { colunaDataPostagemNota } from './data-postagem-nota-professor'
import { colunaEmail } from './email'
import { colunaFeedBackProfessor } from './feedback-professor'
import { colunaInicioOrientacaoTCC } from './inicio-orientacao-tcc'
import { colunaMediaFinal } from './media-final'
import { colunaNomeAluno } from './nome-aluno'
import { colunaNotaAproveitada } from './nota-aproveitada'
import { colunaNotaProfessor } from './nota-professor'
import { colunaProfessor } from './professor'
import { colunaSituacaoFinanceira } from './situacao-financeira'
import { colunaTipoProfessor } from './tipo-professor'
import { colunaTurma } from './turma'
export const COLUNAS_ENVIOS_NOTAS_TCC = [
  colunaNomeAluno(),
  colunaEmail(),
  colunaCelular(),
  colunaSituacaoFinanceira(),
  colunaTurma(),
  colunaDataInscricaoTcc(),
  colunaInicioOrientacaoTCC(),
  colunaMediaFinal(),
  colunaNotaAproveitada(),
  colunaDataEnvioAvaliacao(),
  colunaDataPostagemNota(),
  colunaProfessor(),
  colunaTipoProfessor(),
  colunaNotaProfessor(),
  colunaFeedBackProfessor()
]
