import styled from 'styled-components'

export const Linha = styled.div`
  display: flex;

  & > div:not(:first-child) {
    margin-left: 10px;
  }

  .sm {
    flex: 1;
  }

  .md {
    flex: 2;
  }

  .lg {
    flex: 3;
  }
`
