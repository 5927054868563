import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  FormUnform,
  InputUnform,
  ModalBase,
  ModalBaseProps,
  ModalRef
} from 'src/componentes'
import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'

import {
  ContainerDoConteudo,
  ContainerDosBotoes,
  ContainerModal,
  TituloPrincipal
} from './style'

export interface ModalMotivoRejeicaoRef extends Omit<ModalRef, 'abrir'> {
  abrir: (index?: number) => void
}

export interface ModalMotivoRejeicaoProps extends ModalBaseProps {
  nome?: string
}

export interface ModalMotivoRejeicaoDados {
  motivoDaRejeicao?: string
}

export const EsquemaDeValidacao = Yup.object().shape({
  motivoDaRejeicao: Yup.string().required(mensagemPadrao)
})

const Modal: React.ForwardRefRenderFunction<
  ModalMotivoRejeicaoRef,
  ModalMotivoRejeicaoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const fechar = () => definirAberta(false)
  const abrir = () => definirAberta(true)

  useImperativeHandle<ModalMotivoRejeicaoRef, ModalMotivoRejeicaoRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucessoForm = async (dados: ModalMotivoRejeicaoDados) => {
    acaoPrimaria(dados)
    fechar()
    formRef.current?.reset()
  }

  const dadosIniciais = useMemo(() => {
    return {
      motivoDaRejeicao: null
    }
  }, [])

  return (
    <ModalBase id="modal-motivo-rejeicao" aberta={aberta} backdrop={backdrop}>
      <ContainerModal>
        <TituloPrincipal>Recusar Documento</TituloPrincipal>
        <FormUnform
          ref={formRef}
          acaoSucesso={acaoSucessoForm}
          schema={EsquemaDeValidacao}
          dadosIniciais={dadosIniciais}
        >
          <ContainerDoConteudo>
            <span>
              <b>*</b> Informe o motivo de rejeição do documento
            </span>
            <InputUnform
              id="ipt_motivo_da_rejeicao"
              name="motivoDaRejeicao"
              obrigatorio
            />
          </ContainerDoConteudo>
          <ContainerDosBotoes>
            <Botao
              texto="Cancelar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Salvar" />
          </ContainerDosBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalMotivoRejeicao = forwardRef(Modal)
