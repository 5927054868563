import React from 'react'

import { StepperContainer } from 'src/componentes'
import { Api } from 'src/servicos'

import { Material as MaterialComponente } from '../../shared'
import SalaAulaOutrosContainer from '../container'
import { GrupoDiscussaoComponente } from '../grupo-discussao'
import { MaterialProps } from './tipos'

export const Material: React.FC<MaterialProps> = ({
  id,
  tipo,
  url,
  ultimoMaterial,
  materialTCC,
  materialComplementar,
  pdf
}) => {
  const { avancarStepper, voltarStepper } = StepperContainer.useContainer()
  const {
    salaAula,
    etapas,
    itensMarcados,
    definirItensMarcados
  } = SalaAulaOutrosContainer.useContainer()

  const carregarMaterialPorId = async () => {
    if (materialTCC) {
      return await Api.SalaAulaOutros.RequisitarMaterialTCCPorId(
        id,
        salaAula.id
      )
    } else if (materialComplementar) {
      return await Api.SalaAulaOutros.RequisitarMaterialComplementarPorId(
        id,
        salaAula.id
      )
    } else {
      return await Api.SalaAulaOutros.RequisitarMaterialPorId(id, salaAula.id)
    }
  }

  const acaoAvancar = async () => {
    if (itensMarcados.some(i => i === id)) {
      avancarStepper()
      return
    }

    let resposta: { progresso: number } = null
    if (materialTCC) {
      resposta = await Api.SalaAulaOutros.MarcarMaterialTCC(
        id,
        salaAula.id,
        true
      )
    } else if (materialComplementar) {
      resposta = await Api.SalaAulaOutros.MarcarMaterialComplementar(
        id,
        salaAula.id,
        true
      )
    } else {
      resposta = await Api.SalaAulaOutros.MarcarMaterial(id, salaAula.id, true)
    }

    if (resposta) {
      if (!itensMarcados.some(i => i === id)) {
        definirItensMarcados([...itensMarcados, id])
      }

      avancarStepper()
    }
  }

  return (
    <MaterialComponente
      id={id}
      tipo={tipo}
      salaAulaId={salaAula.id}
      url={url}
      ultimoMaterial={ultimoMaterial}
      etapas={etapas}
      acaoAvancar={acaoAvancar}
      acaoVoltar={voltarStepper}
      carregarMaterialPorId={carregarMaterialPorId}
      pdf={pdf}
      isAulaEad
    >
      <GrupoDiscussaoComponente />
    </MaterialComponente>
  )
}
