import styled from 'styled-components'

import { Cores } from '../styles'

export const Error = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
`

export const Input = styled.input`
  cursor: pointer;
  opacity: 0;
  display: none;
`

export const CampoArquivo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1px solid ${Cores.CINZA_3_ESCURO};
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;

  &:hover {
    border-color: ${Cores.CINZA_2};
    cursor: pointer;
  }

  #nome-arquivo {
    flex: 1;
    max-width: 100%;
    border: none;
    padding: 17px;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-right: 17px;
  }

  div:last-child {
    background-color: ${Cores.CINZA_1_CLARO};
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: #666666;
  }
`

export const Obrigatorio = styled.span`
  color: red;
`
