import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Cores,
  ErroLayoutContainer,
  FormUnform,
  IconeBack,
  IconeLixeira,
  Link,
  Modal,
  ModalRef,
  SelectOpcao,
  SelectUnform,
  Spacer,
  TextAreaUnform,
  Tooltip
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { ModalidadeCurso } from 'src/tipos'

import { schema } from './schema'
import {
  ContainerDaPagina,
  SubMenu,
  Campo,
  Container,
  TextoSelect,
  ContainerLink,
  ContainerSelect,
  ContainerBotoes,
  ContainerDisciplinasSelecionadas,
  DisciplinaSelecionada,
  Obrigatorio,
  BotaoRemoverDisciplina
} from './style'

export const PaginaEmentaDisciplina: React.FC = () => {
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const modalDeCancelamentoRef = useRef<ModalRef>(null)
  const [carregando, definirCarregando] = useState<boolean>(false)
  const [cursos, definirCursos] = useState<SelectOpcao[]>()
  const [disciplinas, definirDisciplinas] = useState<SelectOpcao[]>([])
  const [modalidade, definirModalidade] = useState<ModalidadeCurso>()
  const [limparDisciplina, definirLimparDisciplina] = useState<boolean>(true)
  const [disciplinasEscolhidas, definirDisciplinasEscolhidas] = useState<
    SelectOpcao[]
  >([])

  const obterDadosCurso = async () => {
    try {
      const cursos = await Api.ObterCursosAluno()

      const cursosFormatado = cursos?.data?.cursos?.map(e => {
        return {
          id: e?.id,
          texto: e?.nome
        }
      })

      definirCursos(cursosFormatado)
    } catch (error) {}
  }

  const obterDadosDisciplina = async (opcao: SelectOpcao) => {
    try {
      definirLimparDisciplina(false)
      const disciplinas = await Api.ObterDisciplinasAluno(opcao?.id)

      definirModalidade(disciplinas?.data?.modalidade)

      const disciplinasFormatada = disciplinas?.data?.disciplinas?.map(e => {
        return {
          id: e?.id,
          texto: e?.nome
        }
      })

      definirDisciplinas([
        {
          id: 'Todas',
          texto: 'Todas'
        },
        ...disciplinasFormatada
      ])
      definirDisciplinasEscolhidas([])
    } catch (error) {
    } finally {
      definirLimparDisciplina(true)
    }
  }

  const separacaoDisciplinas = async (opcao: SelectOpcao) => {
    if (opcao?.id === 'Todas') {
      const resultUm = disciplinas.filter(o => o?.id !== opcao?.id)
      const resultDois = disciplinasEscolhidas.filter(o => o?.id !== opcao?.id)

      definirDisciplinasEscolhidas([...resultUm, ...resultDois])

      definirDisciplinas([opcao])
    } else {
      const verificarSeTodasEstaSelecionada = disciplinasEscolhidas.some(
        e => e?.id === 'Todas'
      )

      let result
      if (verificarSeTodasEstaSelecionada) {
        const retirandoOpcaoTodas = disciplinas.filter(o => o?.id !== 'Todas')
        result = retirandoOpcaoTodas.filter(o => o?.id !== opcao?.id)
      } else {
        result = disciplinas.filter(o => o?.id !== opcao?.id)
      }

      definirDisciplinasEscolhidas([...disciplinasEscolhidas, opcao])
      definirDisciplinas(result)
    }
  }

  const exclusaoDisciplinas = (a: any) => {
    const result = disciplinasEscolhidas.filter(o => o?.id !== a?.id)

    definirDisciplinasEscolhidas(result)
    definirDisciplinas([...disciplinas, a])
  }

  const acaoSucesso = async (teste: any) => {
    definirCarregando(true)
    const disciplinasSomenteId = []
    disciplinasEscolhidas?.forEach(e => {
      disciplinasSomenteId.push(e?.id)
    })

    try {
      await Api.CriarRequerimentos(
        modalidade,
        teste?.curso,
        teste?.observacao,
        disciplinasSomenteId
      )

      toast('Requerimento criado com sucesso!', {
        type: 'success'
      })
      history.push(RotasAluno.Requerimentos)
    } catch (error) {
    } finally {
      definirCarregando(false)
    }
  }

  const verificar = async () => {
    const resposta = await Api.ObterCursosAluno()

    const cursosFormatado = resposta?.data?.cursos?.map(e => {
      return {
        id: e?.id,
        texto: e?.nome
      }
    })

    if (cursosFormatado.length === 1) {
      formRef.current.setFieldValue('curso', cursosFormatado[0]?.id)
    }
  }

  useEffect(() => {
    obterDadosCurso()
    verificar()
  }, [])
  return (
    <ContainerDaPagina>
      <ContainerLink>
        <Link
          texto="Voltar"
          icone={IconeBack}
          acaoVoltar={() => history.goBack()}
        />
      </ContainerLink>
      <Cabecalho>
        <Breadcrumb
          titulo="Solicitação de Cópia de Ementa de Disciplina"
          atalhos={[
            {
              texto: 'Requerimentos'
            },
            {
              texto: 'Ementa de Disciplina'
            }
          ]}
        />
      </Cabecalho>
      <FormUnform schema={schema} ref={formRef} acaoSucesso={acaoSucesso}>
        <Container>
          <SubMenu>Selecione o Curso que deseja solicitar a Ementa</SubMenu>
          <ContainerSelect>
            <TextoSelect>
              <Obrigatorio>*</Obrigatorio>Curso
            </TextoSelect>
            <Campo>
              <SelectUnform
                id="slct_curso"
                name="curso"
                opcoes={cursos}
                valorAlterado={obterDadosDisciplina}
              />
            </Campo>
          </ContainerSelect>
          <SubMenu>Selecione as Disciplinas</SubMenu>
          <ContainerSelect>
            <TextoSelect>
              <Obrigatorio>*</Obrigatorio>Disciplinas
            </TextoSelect>
            <Campo>
              {limparDisciplina && (
                <SelectUnform
                  id="slct_disciplina"
                  name="disciplina"
                  valorAlterado={separacaoDisciplinas}
                  disabled={disciplinas?.length === 0}
                  opcoes={disciplinas}
                />
              )}
            </Campo>
          </ContainerSelect>
          <ContainerDisciplinasSelecionadas>
            {disciplinasEscolhidas?.map(e => (
              <DisciplinaSelecionada
                style={{ display: 'flex', alignItems: 'center' }}
                key={e?.id}
              >
                <p>{e?.texto}</p>
                <BotaoRemoverDisciplina
                  data-tip={'Remover'}
                  data-for={'icone-remover-disciplina'}
                  onClick={() => exclusaoDisciplinas(e)}
                >
                  {IconeLixeira}
                  <Tooltip
                    id={'icone-remover-disciplina'}
                    textColor={Cores.PRETO}
                    backgroundColor={Cores.CINZA_3_CLARO}
                    place="right"
                  />
                </BotaoRemoverDisciplina>
              </DisciplinaSelecionada>
            ))}
          </ContainerDisciplinasSelecionadas>
          <Spacer padding="20px 0px" />
          <SubMenu>Observação:</SubMenu>
          <TextAreaUnform
            style={{ width: '600px', height: '100px' }}
            id="txt_observacao"
            name="observacao"
          />
        </Container>
        <ContainerBotoes>
          <Botao
            type="button"
            id="btn-cancelar"
            texto="Cancelar"
            tema="Secundario"
            onClick={() => modalDeCancelamentoRef?.current?.abrir()}
          />
          <Botao
            type="submit"
            id="btn-salvar"
            texto="Salvar"
            carregando={carregando}
          />
        </ContainerBotoes>
      </FormUnform>
      <Modal
        ref={modalDeCancelamentoRef}
        backdrop
        id="modal-de-cancelamento"
        titulo="Deseja cancelar?"
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.goBack()
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamentoRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
    </ContainerDaPagina>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaEmentaDisciplina />
  </ErroLayoutContainer.Provider>
)
