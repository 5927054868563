import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'
import classNames from 'classnames'

import { InputMesAno } from '../../input'
import { Componente, Erro } from './styles'
import { InputUnformProps } from './tipos'

export const InputMesAnoUnform: React.FC<
  Omit<InputUnformProps, 'mascara'> & { formatoAno?: 'AAAA' | 'AA' }
> = ({
  name,
  semMargemAbaixo,
  maxWidthInput,
  readOnly,
  tamanho,
  erro,
  textoTooltip,
  label,
  onClickIcone,
  descricao,
  formatoAno,
  obrigatorio,
  placeholder,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name || '')

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue({ value }) {
        return value
      },
      setValue(ref: any, value: string) {
        ref.value = value
      },
      clearValue(ref: any) {
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  return (
    <Componente
      theme={{
        ComErro: !!error
      }}
      className={classNames({
        semMargemAbaixo
      })}
    >
      <InputMesAno
        id={rest.id}
        maxWidthInput={maxWidthInput}
        readOnly={readOnly}
        tamanho={tamanho}
        erro={erro || !!error}
        textoTooltip={textoTooltip}
        disabled={rest.disabled}
        label={label}
        onClickIcone={onClickIcone}
        descricao={descricao}
        defaultValue={defaultValue}
        name={name}
        ref={inputRef}
        formatoAno={formatoAno}
        onKeyPress={clearError}
        obrigatorio={obrigatorio}
        placeholder={placeholder}
      />
      <Erro id={`erro-${rest.id}`}>{error}</Erro>
    </Componente>
  )
}
