import { Botao } from 'src/componentes/botao'
import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

export const ContainerDaPagina = styled.div`
  padding-top: 14px;
`

export const TituloPrincipal = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 18px;
`
export const TituloComplementar = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 30px;
`

export const ContainerDosBotoes = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`

export const BotaoDeSalvar = styled(Botao)`
  padding: 14px 60px;
  margin-left: 20px;
`

export const MensagemDeErro = styled.span`
  display: flex;
  color: ${Cores.PERIGO_ERRO};
  font-size: 12px;
  margin-top: 30px;
`
