import React from 'react'

export default (
  <svg
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2435 30.8H4.05631C2.36846 30.8 1 29.4407 1 27.7641V4.03604C1 2.35917 2.36846 1 4.05647 1H44.2435C45.9317 1 47.3 2.35917 47.3 4.03604V27.7639C47.3 29.4407 45.9317 30.8 44.2435 30.8Z"
      fill="#F9F9F9"
    />
    <path
      d="M26.0694 25.7432C31.7549 25.7707 36.9447 21.1475 36.9447 15.5231C36.9447 9.3731 31.7549 5.12214 26.0694 5.1239H21.1766C15.423 5.12214 10.6872 9.37411 10.6872 15.5231C10.6872 21.1487 15.423 25.7707 21.1766 25.7432H26.0694Z"
      fill="#006095"
    />
    <path
      d="M23.3589 20.9996V9.83173C25.6236 10.6929 27.2312 12.8662 27.235 15.4153C27.2312 17.9649 25.6236 20.137 23.3589 20.9996ZM15.1656 15.4153C15.1707 12.8677 16.7758 10.6956 19.0392 9.83198V20.9974C16.7758 20.1345 15.1707 17.9637 15.1656 15.4153ZM21.1994 5.9762C15.942 5.97796 11.6818 10.2022 11.6805 15.4153C11.6818 20.6279 15.942 24.8514 21.1994 24.8529C26.4586 24.8514 30.7196 20.6279 30.7204 15.4153C30.7196 10.2022 26.4586 5.97796 21.1994 5.9762Z"
      fill="white"
    />
    <path
      d="M44.2435 30.8H4.05631C2.36846 30.8 1 29.4407 1 27.7641V4.03604C1 2.35917 2.36846 1 4.05647 1H44.2435C45.9317 1 47.3 2.35917 47.3 4.03604V27.7639C47.3 29.4407 45.9317 30.8 44.2435 30.8Z"
      stroke="#DDDDDD"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
