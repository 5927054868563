import React from 'react'

export default (
  <svg
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2559 31.0644H4.04329C2.36256 31.0644 1 29.7111 1 28.0417V4.29048C1 2.621 2.36256 1.26782 4.04329 1.26782H44.2559C45.9366 1.26782 47.2992 2.621 47.2992 4.29048V28.0417C47.2992 29.7111 45.9366 31.0644 44.2559 31.0644Z"
      fill="#F9F9F9"
    />
    <path
      d="M44.2559 31.0644H4.04329C2.36256 31.0644 1 29.7111 1 28.0417V4.29048C1 2.621 2.36256 1.26782 4.04329 1.26782H44.2559C45.9366 1.26782 47.2992 2.621 47.2992 4.29048V28.0417C47.2992 29.7111 45.9366 31.0644 44.2559 31.0644Z"
      stroke="#DDDDDD"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9315 11.5981L20.1922 17.811L17.3234 11.5981H13.6349V20.1888L9.83874 11.5981H6.58168L2.61279 20.7246L5.02858 20.734L5.78371 18.7749H10.4857L11.3487 20.734H15.8783V13.5905L19.0706 20.7246C19.0706 20.7246 21.0118 20.7459 21.1627 20.7246C21.3137 20.703 24.3119 13.6547 24.3119 13.6547V20.7246H26.4905V11.5981H22.9315V11.5981ZM6.66618 16.8415L8.1742 13.2738L9.68221 16.8415H6.66618Z"
      fill="#128CF9"
    />
    <path
      d="M45.6865 11.598H43.049L40.2431 14.6178L37.5309 11.598H28.5336V20.7337H37.4185L40.2057 17.7761L42.9366 20.7337H45.6114L41.6272 16.1658L45.6865 11.598ZM36.1842 18.928H30.7408V17.0701C30.7408 17.0701 35.5105 17.0701 35.6229 17.0701C35.7351 17.0701 35.6229 15.1751 35.6229 15.1751H30.7408V13.4661H36.2962L38.8214 16.1971L36.1842 18.928Z"
      fill="#128CF9"
    />
  </svg>
)
