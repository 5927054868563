import React, { useEffect, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Carregando,
  FormUnform,
  IconeBack,
  IconeCadastroVerde,
  IconeDoAvatarDoUsuario,
  IconeProrrogacaoManual,
  IconeRecuperacao,
  IconeResponder,
  Link,
  Modal,
  ModalRef,
  SelectUnform,
  SeletorUnform,
  SubTitulo,
  Tabela,
  TabelaColuna,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  Tooltip
} from 'src/componentes'
import {
  TabelaDinamica,
  TabelaDinamicaRef
} from 'src/componentes/tabela-dinamica'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  Nacionalidade,
  OpcaoAlteracaoTCC,
  SolicitacaoRecuperacaoManual,
  TipoProrrogacao,
  TipoTransferenciaAluno,
  UsuarioSituacao
} from 'src/tipos'

import {
  AbaContratos,
  AbaInformacoesAcesso,
  AbaMatricula,
  AbaNotas,
  colunaCargaHoraria,
  colunaCurso,
  colunaDataConclusao,
  colunaDataInicio,
  colunaDataUltimoAcesso,
  colunaNota,
  colunaTurma,
  AccordionInformacoesAluno,
  ModalTransferenciaAluno,
  ModalInserirNovoDocumento,
  ModalProrrogacaoManual,
  ModalAlteracaoTCC,
  colunaDataEmissaoCertificado,
  ModalRecuperacaoManual
} from './componentes'
import {
  AlteracaoTCC,
  ModalAlteracaoTCCRef
} from './componentes/modal-alteracao-tcc/tipos'
import { NovoContrato } from './componentes/modal-inserir-novo-documento/tipos'
import {
  ModalProrrogacaoManualRef,
  ProrrogacaoManual
} from './componentes/modal-prorrogacao-manual/tipos'
import { ModalRecuperacaoManualRef } from './componentes/modal-recuperacao-manual/tipos'
import {
  ModalTransferenciaAlunoRef,
  TransferenciaAluno
} from './componentes/modal-transferencia-aluno/tipos'
import { OPCOES_SITUACAO, OPCOES_TIPO } from './opcoes'
import {
  Container,
  ContainerAcoes,
  ContainerAcoesModais,
  ContainerContrato,
  ContainerImagemAluno,
  ContainerLink,
  ContainerSituacao,
  Conteudo,
  ImagemAluno
} from './styles'
import {
  Aluno,
  MatriculaAluno,
  PaginaVisualizarAlunoProps,
  SituacaoMatricula,
  SituacaoFinanceiraExtenso,
  TipoMatriculaPorExtenso
} from './tipos'

export const VisualizarAluno: React.FC<PaginaVisualizarAlunoProps> = ({
  match
}) => {
  const history = useHistory()
  const [aluno, definirAluno] = useState<Aluno>(null)
  const [matriculas, definirMatriculas] = useState<MatriculaAluno[]>([])
  const [pronto, definirPronto] = useState(false)
  const [situacao, definirSituacao] = useState(null)
  const [estrangeiro, definirEstrangeiro] = useState(false)
  const tabelaRef = useRef<TabelaRef>()
  const tabelaDinamicaRef = useRef<TabelaDinamicaRef>()
  const modalRef = useRef<ModalRef>(null)
  const modalInserirNovoDocumentolRef = useRef<ModalRef>(null)
  const modalTransferenciaAlunoRef = useRef<ModalTransferenciaAlunoRef>(null)
  const modalProrrogacaoManualRef = useRef<ModalProrrogacaoManualRef>(null)
  const modalAlteracaoTCCRef = useRef<ModalAlteracaoTCCRef>(null)
  const modalRecuperacaoManualRef = useRef<ModalRecuperacaoManualRef>(null)

  const carregarAluno = async () => {
    try {
      const aluno = await Api.ObterAlunoPorId(match.params.id)
      definirAluno(aluno)
      definirSituacao(aluno.situacao)

      definirMatriculas(aluno.matriculaCurso.concat(aluno.matriculaTurma))

      const ehEstrangeiro = aluno.nacionalidade === Nacionalidade[1]
      definirEstrangeiro(ehEstrangeiro)
    } catch (error) {
      toast('Houve um problema ao obter os dados do aluno', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const adicionarNovoContrato = async (novoContrato: NovoContrato) => {
    try {
      await Api.AdicionarNovoContrato(novoContrato)
      toast('Novo contrato adicionado com sucesso.', { type: 'success' })
    } catch (erro) {
      toast('Não foi possível adicionar um novo contrato.', { type: 'error' })
    }
  }

  const obterDadosCurso = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: aluno.matriculaCurso
    } as TabelaResposta
  }

  const obterDadosTurma = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: aluno.matriculaTurma
    } as TabelaResposta
  }

  const atualizarMatriculas = (id, situacao) => {
    matriculas?.forEach(item => {
      if (item.matriculaId === id) {
        item.situacaoFinanceira = situacao.id
      }
    })
  }

  const atualizarTipoMatricula = (id, tipo) => {
    matriculas?.forEach(item => {
      if (item.matriculaId === id) {
        item.tipoMatricula = tipo?.id
      }
    })
  }

  const atualizarSituacaoAluno = async () => {
    try {
      const matriculasAluno = matriculas?.map(i => {
        return {
          matriculaId: i.matriculaId,
          situacao: i.situacaoFinanceira,
          ip: i.ipAceiteTermos,
          dataHoraAceiteTermos: i.dataHoraAceiteTermos,
          tipoMatricula: i.tipoMatricula
        }
      })

      const alunoEditado = {
        situacao: UsuarioSituacao[situacao],
        matriculas: matriculasAluno
      }

      await Api.EditarSituacaoAluno(match.params.id, alunoEditado)
      toast('Aluno editado com sucesso', { type: 'success' })
      history.push(RotasAcademico.Alunos)
    } catch (error) {
      toast('Houve um problema ao salvar os dados do aluno,', {
        type: 'error'
      })
    }
  }

  const realizarTransferenciaAluno = async (
    dados: TransferenciaAluno
  ): Promise<void> => {
    try {
      const moduloAproveitado = await Api.TransferirAluno(
        dados.matriculaId,
        dados.turmaDestinoId,
        dados.tipoTransferencia,
        dados.termo,
        dados.addPlanoPagamento,
        dados.planosPagamento,
        dados.taxaIsenta
      )

      let mensagem = 'Dados enviados com sucesso'

      if (
        dados.tipoTransferencia === TipoTransferenciaAluno.TransferenciaTurma
      ) {
        mensagem = 'Aluno transferido com sucesso'
      }
      if (dados.tipoTransferencia === TipoTransferenciaAluno.TrocaCurso) {
        mensagem = 'Troca de curso solicitada com sucesso'
      }

      toast(mensagem, { type: 'success' })

      if (
        !moduloAproveitado &&
        dados.tipoTransferencia === TipoTransferenciaAluno.TransferenciaTurma
      ) {
        toast('Notas de modulos não foram aproveitadas.')
      }

      await carregarAluno()
      await tabelaRef?.current?.CarregarDados(true)
      await tabelaDinamicaRef?.current?.CarregarDados(true)
    } catch (error) {
      toast('Houve um problema ao transferir aluno,', {
        type: 'error'
      })
    }
  }

  const realizarProrrogacaoManual = async (
    dados: ProrrogacaoManual
  ): Promise<void> => {
    try {
      await Api.SalaAulaOutros.RealizarProrrogacaoManual(
        dados.matriculaId,
        dados
      )

      toast('Prorrogação manual feita com sucesso', { type: 'success' })
      await carregarAluno()
      await tabelaRef?.current?.CarregarDados(true)
      await tabelaDinamicaRef?.current?.CarregarDados(true)
    } catch (error) {
      toast('Houve um problema ao realizar a prorrogação manual.', {
        type: 'error'
      })
    }
  }

  const realizarAlteracaoTCC = async (dados: AlteracaoTCC) => {
    try {
      if (
        dados.alteracao === OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Aproveitamento]
      ) {
        await Api.AproveitamentoTCC(
          dados.matriculaId,
          dados.instituicao,
          dados.turma,
          dados.nota
        )
      }

      if (dados.alteracao === OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Inclusao]) {
        await Api.InclusaoTCC(
          dados.matriculaId,
          dados.valor,
          dados.quantidadeParcelas,
          dados.diaVencimento,
          dados.professorOrientadorId
        )
      }

      if (
        dados.alteracao === OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Cancelamento]
      ) {
        await Api.CancelamentoTCC(dados.matriculaId)
      }

      if (dados.alteracao === OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Dispensa]) {
        await Api.DispensaTCC(dados.matriculaId)
      }

      toast('TCC alterado com sucesso.', { type: 'success' })
    } catch {
      toast('Erro ao alterar TCC', { type: 'error' })
    } finally {
      await carregarAluno()
      await tabelaRef?.current?.CarregarDados(true)
      await tabelaDinamicaRef?.current?.CarregarDados(true)
    }
  }

  const realizarRecuperacaoManual = async (dados: any) => {
    try {
      await Api.EnviarInformacoesRecuperacaoManual(dados.matriculaId, dados)

      toast('Recuperação realizada com sucesso.', { type: 'success' })
    } catch {
      toast('Erro ao realizar recuperação', { type: 'error' })
    } finally {
      await carregarAluno()
      await tabelaRef?.current?.CarregarDados(true)
      await tabelaDinamicaRef?.current?.CarregarDados(true)
    }
  }

  const colunaSituacao = (): TabelaColuna => ({
    Chave: 'situacaoFinanceira',
    Texto: 'Situação',
    StyleColuna: { width: 140, paddingInline: 10 },
    Renderizar: (linha: TabelaDados) => (
      <ContainerSituacao>
        <FormUnform
          dadosIniciais={{
            situacao: SituacaoFinanceiraExtenso[linha.situacaoFinanceira]
          }}
          acaoSucesso={null}
        >
          <SelectUnform
            id="situacao"
            name="situacao"
            placeholder="Selecione"
            valorAlterado={valor =>
              atualizarMatriculas(linha.matriculaId, valor)
            }
            opcoes={OPCOES_SITUACAO}
          />
        </FormUnform>
      </ContainerSituacao>
    )
  })

  const colunaTipo = (): TabelaColuna => ({
    Chave: 'tipoMatricula',
    Texto: 'Tipo',
    StyleColuna: { width: 140, paddingInline: 10 },
    Renderizar: (linha: TabelaDados) => (
      <ContainerSituacao>
        <FormUnform
          dadosIniciais={{
            tipo: TipoMatriculaPorExtenso[linha.tipoMatricula]
          }}
          acaoSucesso={null}
        >
          <SelectUnform
            id="tipo"
            name="tipo"
            placeholder="Selecione"
            valorAlterado={valor =>
              atualizarTipoMatricula(linha.matriculaId, valor)
            }
            opcoes={OPCOES_TIPO}
          />
        </FormUnform>
      </ContainerSituacao>
    )
  })

  const obterProrrogacoesDisponiveis = (
    matricula: MatriculaAluno
  ): TipoProrrogacao[] => {
    const tipos = [TipoProrrogacao.Padrao]
    if (matricula.podeSolicitarProrrogacaoLicenca) {
      tipos.push(TipoProrrogacao.Licenca)
    }

    return tipos
  }

  useEffect(() => {
    carregarAluno()
  }, [])

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerLink>
            <Link
              texto="Voltar"
              icone={IconeBack}
              acaoVoltar={() => history.goBack()}
            />
          </ContainerLink>
          <Cabecalho>
            <Breadcrumb
              titulo="Situação do Aluno"
              atalhos={[
                {
                  texto: 'Acadêmico'
                },
                {
                  texto: 'Alunos',
                  acao: () => history.goBack()
                },
                {
                  texto: 'Situação do Aluno'
                }
              ]}
            />
          </Cabecalho>
          <Conteudo>
            <SubTitulo texto="Dados do Aluno" />
            <FormUnform
              dadosIniciais={{
                situacao: aluno ? UsuarioSituacao[aluno.situacao] : ''
              }}
              acaoSucesso={null}
            >
              <ContainerImagemAluno>
                {aluno?.foto ? (
                  <ImagemAluno src={aluno.foto} alt="Imagem do aluno" />
                ) : (
                  IconeDoAvatarDoUsuario
                )}
                <SeletorUnform
                  id="situacao"
                  name="situacao"
                  tipo="radio"
                  label="Situação"
                  valorAlterado={valor => definirSituacao(valor)}
                  seletores={[
                    {
                      id: UsuarioSituacao.Ativo,
                      texto: 'Ativo'
                    },
                    {
                      id: UsuarioSituacao.Inativo,
                      texto: 'Inativo'
                    }
                  ]}
                />
              </ContainerImagemAluno>
            </FormUnform>
            <AccordionInformacoesAluno
              aluno={aluno}
              ehEstrangeiro={estrangeiro}
            />
            <ContainerContrato>
              <SubTitulo texto="Pós-Graduação" />
              <Botao
                texto="Adicionar contrato"
                tema="Secundario"
                type="button"
                onClick={() => modalInserirNovoDocumentolRef?.current?.abrir()}
              />
            </ContainerContrato>
            <TabelaDinamica
              Abas={[
                {
                  titulo: 'Matrícula',
                  conteudoTabela: [
                    {
                      Renderizar: (linha: TabelaDados) => (
                        <AbaMatricula informacoes={linha as MatriculaAluno} />
                      )
                    }
                  ]
                },
                {
                  titulo: 'Informações de Acesso',
                  conteudoTabela: [
                    {
                      Renderizar: (linha: TabelaDados) => (
                        <AbaInformacoesAcesso
                          informacoes={linha as MatriculaAluno}
                        />
                      )
                    }
                  ]
                },
                {
                  titulo: 'Notas',
                  conteudoTabela: [
                    {
                      Renderizar: (linha: TabelaDados) => (
                        <AbaNotas
                          matriculaId={linha.matriculaId}
                          tipoAvaliacao={linha.tipoAvaliacao}
                        />
                      )
                    }
                  ]
                },
                {
                  titulo: 'Contratos',
                  conteudoTabela: [
                    {
                      Renderizar: (linha: TabelaDados) => (
                        <AbaContratos matriculaId={linha.matriculaId} />
                      )
                    }
                  ]
                }
              ]}
              ref={tabelaDinamicaRef}
              Colunas={[
                colunaTurma(),
                colunaDataInicio(),
                colunaSituacao(),
                colunaTipo(),
                {
                  Chave: 'acoes',
                  Alinhamento: 'center',
                  StyleColuna: { width: 100, paddingInline: 10 },
                  Renderizar: (linha: TabelaDados) => (
                    <ContainerAcoesModais>
                      <button
                        type="button"
                        data-tip="Transferência de Aluno"
                        data-for={`${linha.id}-tooltip-transferencia`}
                        onClick={() => {
                          const dados = {
                            matriculaId: linha.matriculaId,
                            nomeTurma: linha.nomeTurma,
                            codigoTurma: linha.codigoTurma
                          }
                          modalTransferenciaAlunoRef?.current?.abrir(dados)
                        }}
                        disabled={
                          SituacaoFinanceiraExtenso[
                            linha.situacaoFinanceira
                          ] === SituacaoMatricula[SituacaoMatricula.Transferido]
                        }
                      >
                        {IconeResponder}
                      </button>
                      <Tooltip
                        id={`${linha.id}-tooltip-transferencia`}
                        offset={{ top: 10 }}
                      />

                      <button
                        type="button"
                        data-tip="Alterações no TCC"
                        data-for={`${linha.id}-tooltip-alteracoes-tcc`}
                        onClick={() =>
                          modalAlteracaoTCCRef?.current?.abrir(
                            linha.matriculaId,
                            linha.salaAulaId
                          )
                        }
                        disabled={
                          SituacaoFinanceiraExtenso[
                            linha.situacaoFinanceira
                          ] !== SituacaoMatricula[SituacaoMatricula.Matriculado]
                        }
                      >
                        {IconeCadastroVerde}
                      </button>
                      <Tooltip
                        id={`${linha.id}-tooltip-alteracoes-tcc`}
                        offset={{ top: 10 }}
                      />

                      <button
                        type="button"
                        data-tip="Prorrogação Manual"
                        data-for={`${linha.id}-tooltip-prorrogacao`}
                        onClick={() => {
                          modalProrrogacaoManualRef?.current?.abrir(
                            linha.matriculaId,
                            linha.dataFimProrrogacao ?? linha.dataFimTurma,
                            obterProrrogacoesDisponiveis(
                              linha as MatriculaAluno
                            ),
                            linha.dataNascimentoProrrogacaoLicenca,
                            linha.tempoProrrogacaoLicenca
                          )
                        }}
                        disabled={
                          SituacaoFinanceiraExtenso[
                            linha.situacaoFinanceira
                          ] !== SituacaoMatricula[SituacaoMatricula.Matriculado]
                        }
                      >
                        {IconeProrrogacaoManual}
                      </button>
                      <Tooltip
                        id={`${linha.id}-tooltip-prorrogacao`}
                        offset={{ top: 10 }}
                      />

                      <button
                        type="button"
                        data-tip="Recuperação manual"
                        data-for={`${linha.id}-tooltip-recuperacao`}
                        onClick={() =>
                          modalRecuperacaoManualRef?.current?.abrir(
                            linha.matriculaId
                          )
                        }
                        disabled={
                          linha.situacaoRecuperacao !==
                          SolicitacaoRecuperacaoManual.Disponivel
                        }
                      >
                        {IconeRecuperacao}
                      </button>
                      <Tooltip
                        id={`${linha.id}-tooltip-recuperacao`}
                        offset={{ top: 10 }}
                      />
                    </ContainerAcoesModais>
                  )
                }
              ]}
              ObterDados={obterDadosTurma}
            />
            <ContainerContrato>
              <SubTitulo texto="Cursos Livres / Eventos" />
            </ContainerContrato>
            <Tabela
              ref={tabelaRef}
              Colunas={[
                colunaCurso(),
                colunaDataInicio(),
                colunaDataConclusao(),
                colunaDataUltimoAcesso(),
                colunaCargaHoraria(),
                colunaNota(),
                colunaDataEmissaoCertificado(),
                colunaSituacao(),
                colunaTipo()
              ]}
              ObterDados={obterDadosCurso}
            />
            <ContainerAcoes>
              <Botao
                texto="Cancelar"
                tema="Secundario"
                type="button"
                onClick={() => modalRef?.current?.abrir()}
              />
              <Botao
                texto="Salvar"
                type="button"
                onClick={() => atualizarSituacaoAluno()}
              />
            </ContainerAcoes>
          </Conteudo>
          <ModalInserirNovoDocumento
            backdrop
            matriculas={matriculas}
            id="modal-inserir-novo-documento"
            acaoPrimaria={adicionarNovoContrato}
            ref={modalInserirNovoDocumentolRef}
          />
          <Modal
            ref={modalRef}
            backdrop
            id="modal-confirmacao-cancelamento"
            titulo="Deseja cancelar?"
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalRef?.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
          <ModalTransferenciaAluno
            backdrop
            id="modal-transferencia-aluno"
            acaoPrimaria={realizarTransferenciaAluno}
            ref={modalTransferenciaAlunoRef}
          />
          <ModalProrrogacaoManual
            backdrop
            id="modal-prorrogacao-manual"
            acaoPrimaria={realizarProrrogacaoManual}
            ref={modalProrrogacaoManualRef}
          />
          <ModalAlteracaoTCC
            backdrop
            id="modal-alteracao-tcc"
            acaoPrimaria={realizarAlteracaoTCC}
            ref={modalAlteracaoTCCRef}
          />
          <ModalRecuperacaoManual
            backdrop
            id="modal-recuperacao-manual"
            acaoPrimaria={realizarRecuperacaoManual}
            ref={modalRecuperacaoManualRef}
          />
        </Container>
      ) : (
        <Carregando />
      )}
    </>
  )
}

export default withRouter(VisualizarAluno)
