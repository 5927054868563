import React from 'react'
import { useSpring } from 'react-spring'

import classNames from 'classnames'

import {
  IconeCarregando,
  IconeFeedBackBotaoErro,
  IconeFeedBackBotaoSucesso
} from '../icones'
import { Tooltip } from '../tooltip'
import { ComponenteBotao, CampoImagem, CampoFeedback } from './styles'
import { BotaoProps } from './tipos'

export const Botao: React.FC<BotaoProps> = ({
  elemento,
  texto,
  elementoInvertido,
  disabled,
  ...outras
}) => {
  const { className: classes, ...rest } = outras
  const { feedback, carregando, tooltip } = rest
  const springProps = useSpring({
    opacity: feedback ? 1 : 0,
    transform: feedback ? 'scale(1)' : 'scale(0)',
    config: { duration: 400, mass: 5, tension: 350, friction: 40 }
  })
  return (
    <>
      <ComponenteBotao
        className={`${classes ?? ''} ${classNames({
          sucesso: !carregando && feedback === 'Sucesso',
          erro: !carregando && feedback === 'Erro'
        })}`}
        elemento={elemento}
        disabled={disabled}
        texto={texto}
        data-tip={tooltip ? tooltip.texto : undefined}
        data-for={tooltip ? tooltip.id : undefined}
        {...rest}
      >
        <i className="loader">{IconeCarregando}</i>
        <CampoFeedback style={springProps}>
          <i className="icone_feedback">
            {feedback === 'Sucesso'
              ? IconeFeedBackBotaoSucesso
              : IconeFeedBackBotaoErro}
          </i>
        </CampoFeedback>
        <CampoImagem
          PossuiElemento={!!elemento}
          PossuiTexto={!!texto || elementoInvertido}
        >
          {!!texto && texto}
          {!!elemento && elemento}
        </CampoImagem>
      </ComponenteBotao>
      {tooltip && <Tooltip id={tooltip.id} place={tooltip.lado} />}
    </>
  )
}

export { ConteudoBotao } from './styles'
