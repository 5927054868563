import { SituacaoFinanceira } from 'src/tipos'

export const opcoesFiltros = [
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Reservado],
    texto: 'Reservado'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Inativo],
    texto: 'Inativo'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Matriculado],
    texto: 'Matriculado'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.ContratoRescindido],
    texto: 'Contrato Rescindido'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Cancelado],
    texto: 'Cancelado'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Transferido],
    texto: 'Transferido'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Finalizado],
    texto: 'Finalizado'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Reprovado],
    texto: 'Reprovado'
  },
  {
    id: SituacaoFinanceira[SituacaoFinanceira.Recuperacao],
    texto: 'Recuperação'
  }
]
