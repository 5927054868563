import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

import { ContainerBotao as ContainerBotaoStyle } from '../../shared/styles'

export const ContainerTitulo = styled.div`
  display: flex;
  flex-direction: column;
`

export const Titulo = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${Cores.CINZA_2_MAIS_ESCURO};

  margin-bottom: 26px;
`

export const Mensagem = styled.h3<{ abaixoMedia?: boolean }>`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.02em;
  color: ${({ abaixoMedia }) =>
    abaixoMedia ? Cores.PERIGO_ERRO : Cores.CINZA_2_ESCURO};

  margin-bottom: 12px;
`

export const Descricao = styled.p<{ color: string }>`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: ${props => props.color};
  margin-bottom: 24px;
  white-space: break-spaces;
`

export const ContainerQuestao = styled.div`
  margin-bottom: 10px;

  & > div {
    margin-bottom: 25px;
  }

  & > span {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: ${Cores.CINZA_2_MAIS_ESCURO};
  }
`

export const ContainarStatus = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.02em;
    color: ${Cores.CINZA_2_MAIS_ESCURO};

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:not(:first-child) {
      margin-left: 6px;
    }
  }
`

export const ContainerBotao = styled(ContainerBotaoStyle)`
  margin-top: 27px;
`

export const Tentativas = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: ${Cores.CINZA_2_MAIS_ESCURO};
  text-align: center;
  margin-top: 11px;
`

export const ContainerConteudo = styled.div`
  .ipt_arquivo {
    max-width: 375px;
    margin-bottom: 0px;
  }
`

export const Tabela = styled.table`
  margin: 20px 0 27px 0;
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  color: #191414;
  border-collapse: collapse;
  border: 1px solid ${Cores.CINZA_3_CLARO};
  width: 100%;

  th {
    background: ${Cores.CINZA_HEAD_TABLE};
    color: ${Cores.PRETO};
    font-family: 'Saira-Semi-Bold', sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    border-bottom: none;
    text-transform: uppercase;
  }

  th:first-child {
    border-top-left-radius: 8px;
  }

  th:last-child {
    border-top-right-radius: 8px;
  }

  tbody {
    line-height: 125%;

    tr:first-child {
      border-top: 2px solid ${Cores.CINZA_3_CLARO};
    }

    tr {
      height: 48px;
      border: 1px solid ${Cores.FUNDO};
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid ${Cores.FUNDO};
      }

      td,
      th {
        padding: 0 12px;
      }
    }
  }

  button {
    cursor: pointer;
    background: transparent;
    border: 0;
    margin-right: 10px;
    align-self: center;
  }

  button:disabled {
    opacity: 0.5;
  }

  .tooltip {
    max-width: 300px;
  }
`

export const ContainerNotasTCC = styled.div`
  display: flex;
  flex-direction: column;

  p {
    align-self: flex-start;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
  }
`

export const ListaDicas = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 24px;
  color: ${Cores.CINZA_2_MAIS_ESCURO};

  li {
    list-style: disc;
  }
`
export const MensagemPrazoEnvioTCCExpirado = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${Cores.PERIGO_ERRO};

  margin-bottom: 26px;
`
