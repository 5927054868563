import React, { useMemo } from 'react'

import classNames from 'classnames'

import { IconeSeta, IconeSetaDupla } from '../icones'
import { usePaginacao } from './paginacao-hooks'
import {
  Paginacao as PaginacaoComponent,
  TotalRegistorPorPagina,
  Acoes,
  Botoes,
  BotaoPagina
} from './styles'

export const Paginacao: React.FC = () => {
  const [
    { Pagina, TamanhoPagina, TotalPaginas, TotalRegistros, Estilo },
    dispatch
  ] = usePaginacao()

  const renderizarPaginas = useMemo(() => {
    if (TotalPaginas <= 0 || !TotalPaginas) return undefined

    const limite = 3
    let paginas = [...Array(TotalPaginas)].map((_, index) => index + 1)

    if (TotalPaginas - Pagina < ~~(limite / 2)) {
      paginas = paginas.slice(-~~(limite / 2) * 2)
    } else {
      if (paginas.length > limite) {
        const indexLeft = Math.max(Pagina - Math.ceil(limite / 2), 0)
        paginas = paginas.slice(indexLeft, indexLeft + limite)
      }
      if (paginas.length > limite) {
        const indexRight = Math.min(
          Pagina + Math.ceil(limite / 2),
          TotalPaginas
        )
        paginas = paginas.slice(indexRight, indexRight + limite)
      }
    }

    if (paginas.length <= 1) return undefined

    return (
      <Botoes>
        <BotaoPagina
          className="invertido"
          type="button"
          onClick={() => {
            dispatch({ tipo: 'ir-para-pagina', pagina: 1 })
          }}
          disabled={Pagina === 1}
        >
          {IconeSetaDupla}
        </BotaoPagina>
        <BotaoPagina
          className="invertido"
          type="button"
          onClick={() => {
            dispatch({ tipo: 'pagina-anterior' })
          }}
          disabled={Pagina === 1}
        >
          {IconeSeta}
        </BotaoPagina>
        <span className="numeros-pagina">
          {paginas.map(p => (
            <BotaoPagina
              key={`pagina-${p}`}
              type="button"
              className={classNames({
                ativo: Pagina === p
              })}
              onClick={() => {
                if (Pagina !== p) {
                  dispatch({ tipo: 'ir-para-pagina', pagina: p })
                }
              }}
            >
              {p}
            </BotaoPagina>
          ))}
        </span>
        <BotaoPagina
          type="button"
          onClick={() => {
            dispatch({ tipo: 'proxima-pagina' })
          }}
          disabled={Pagina === TotalPaginas}
        >
          {IconeSeta}
        </BotaoPagina>
        {TotalPaginas > 2 && (
          <BotaoPagina
            type="button"
            onClick={() => {
              dispatch({ tipo: 'ir-para-pagina', pagina: TotalPaginas })
            }}
            disabled={Pagina === TotalPaginas}
          >
            {IconeSetaDupla}
          </BotaoPagina>
        )}
      </Botoes>
    )
  }, [Pagina, TotalPaginas])

  const renderizarBotoes = useMemo(() => {
    switch (Estilo) {
      case 'Simples':
        return (
          <Botoes>
            <BotaoPagina
              id="acao_retornar_pagina"
              className="invertido"
              type="button"
              onClick={() => {
                dispatch({ tipo: 'pagina-anterior' })
              }}
              disabled={Pagina === 1}
            >
              {IconeSeta}
            </BotaoPagina>
            <BotaoPagina
              id="acao_avancar_pagina"
              type="button"
              onClick={() => {
                dispatch({ tipo: 'proxima-pagina' })
              }}
              disabled={Pagina === TotalPaginas}
            >
              {IconeSeta}
            </BotaoPagina>
          </Botoes>
        )
      case 'Complexo':
        return renderizarPaginas
      default:
        return <></>
    }
  }, [Estilo, Pagina, TotalRegistros])

  const ultimoItemExibidoNaPaginaAtual = () => {
    const item = Pagina * TamanhoPagina
    return item > TotalRegistros ? TotalRegistros : item
  }

  const primeiroItemExibidoNaPaginaAtual = () => {
    return Pagina * TamanhoPagina - TamanhoPagina + 1
  }

  return (
    <>
      {TotalRegistros && (
        <PaginacaoComponent id="rodape_paginacao">
          <TotalRegistorPorPagina>
            <label id="linhas_paginacao">Linhas por página</label>
            <select
              id="selecionar_linhas_paginacao"
              value={TamanhoPagina}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                dispatch({
                  tipo: 'alterar-tamanho-pagina',
                  tamanhoPagina: Number(e.target.value)
                })
              }
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </TotalRegistorPorPagina>
          {TotalPaginas > 0 && (
            <Acoes>
              <p id="contador_paginacao">
                {primeiroItemExibidoNaPaginaAtual()} -{' '}
                {ultimoItemExibidoNaPaginaAtual()} de {TotalRegistros} itens
              </p>
              {renderizarBotoes}
            </Acoes>
          )}
        </PaginacaoComponent>
      )}
    </>
  )
}
