import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaCurso = (): TabelaColuna => ({
  Chave: 'nomeCurso',
  Texto: 'Curso',
  StyleColuna: { width: 110, paddingInline: 10 },
  Renderizar: (linha: TabelaDados) => <>{linha.nomeCurso}</>
})
