import React, { useMemo, useRef, useState } from 'react'

import {
  AutenticacaoContainer,
  Botao,
  CheckboxUnform,
  FormRef,
  FormUnform,
  FuncoesDataHora,
  IconeCadeado,
  IconeCalendarioPequeno,
  IconeComentario,
  IconeDoAvatarDoUsuario,
  IconeLixeira,
  IconeRelogioPequeno,
  IconeResponder,
  IconeRespostaComentario,
  Tooltip,
  Visao
} from 'src/componentes'
import { CHAVE_AVATAR_ALUNO } from 'src/componentes/barra-superior-layout/providers/barra-superior.provider'
import { EditorUnform } from 'src/componentes/unform/editor'
import { sanitizeUtil } from 'src/componentes/utils'
import { Api } from 'src/servicos'
import * as Storage from 'src/servicos/local-storage'
import { Comentario as ComentarioType } from 'src/tipos'

import { ContainerBotao, ImagemUsuario } from '../styles'
import { schema } from './schema'
import {
  Container,
  Conteudo,
  ContainarStatus,
  TextoComentario,
  ContainerChildren,
  ContainerResponder,
  TextoResponder,
  Linha,
  ContainerPrivado,
  TituloComentario
} from './styles'
import { ComentarioProps } from './tipos'

const avatar = Storage.obter(CHAVE_AVATAR_ALUNO) as { foto: string }

export const Comentario: React.FC<ComentarioProps> = ({
  comentarios,
  grupo,
  topico,
  carregarTopico
}) => {
  const [respondendo, definirRespondendo] = useState<string>(null)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormRef>()
  const { usuario, visao } = AutenticacaoContainer.useContainer()

  const visaoResposta = useMemo(
    () =>
      visao === Visao.IPGS ||
      visao === Visao.Professor ||
      visao === Visao.Parceiro,
    [visao]
  )

  const aoClicarResponder = (comentario: string) => {
    formRef?.current?.clearField('comentario')
    definirRespondendo(comentario)
  }

  const adicionarResposta = async (dados: {
    comentario: string
    privado: boolean
  }) => {
    try {
      definirCarregando(true)

      await Api.CriarRespostaComentario(
        grupo,
        topico,
        respondendo,
        sanitizeUtil(dados.comentario),
        dados.privado
      )

      formRef.current?.clearField('comentario')
      formRef.current?.clearField('privado')
      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  const renderizarResponder = (comentario: ComentarioType) => {
    return respondendo === comentario?.comentarioId ? (
      <ContainerResponder>
        <FormUnform
          ref={formRef}
          acaoSucesso={adicionarResposta}
          schema={schema}
        >
          <Conteudo>
            <Linha>
              <ImagemUsuario>
                {avatar && avatar.foto ? (
                  <img src={avatar.foto} />
                ) : (
                  IconeDoAvatarDoUsuario
                )}
              </ImagemUsuario>
              <EditorUnform
                id="comentario"
                name="comentario"
                label="Adicionar um comentário"
              />
            </Linha>
          </Conteudo>
          <ContainerBotao>
            <ContainerPrivado>
              <CheckboxUnform id="chk_privado_resposta" name="privado" />
              <label
                id={'lbl_privado'}
                data-tip="Somente o criador do comentário e você poderão ver esta resposta"
                data-for={`${comentario?.comentarioId}-tooltip-comentario-privado`}
              >
                Privado
              </label>
              <Tooltip
                id={`${comentario?.comentarioId}-tooltip-comentario-privado`}
                place="right"
                offset={{ top: 5 }}
              />
            </ContainerPrivado>
            <Botao
              texto="Enviar Comentário"
              carregando={carregando}
              type="button"
              onClick={() => formRef?.current?.submitForm()}
            />
          </ContainerBotao>
        </FormUnform>
      </ContainerResponder>
    ) : (
      <ContainerResponder
        onClick={() => aoClicarResponder(comentario.comentarioId)}
      >
        {IconeResponder}
        <TextoResponder> Responder </TextoResponder>
      </ContainerResponder>
    )
  }

  const deletarComentario = async (comentarioId: string) => {
    try {
      definirCarregando(true)

      await Api.DeletarComentario(grupo, topico, comentarioId)

      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  const renderizarComentario = (
    tipo: 'comentario' | 'resposta',
    comentario: ComentarioType,
    existeComentarioProfessor?: boolean,
    children?: JSX.Element
  ) => {
    return (
      <div
        key={comentario.comentarioId}
        className={
          (comentario.respostasComentario?.length < 1 ||
            !existeComentarioProfessor) &&
          'comentario-nao-respondido'
        }
      >
        {tipo === 'comentario' ? IconeComentario : IconeRespostaComentario}
        <Conteudo>
          <TituloComentario>
            {comentario?.nomeUsuario}{' '}
            {comentario?.privado ? (
              <>
                <div
                  data-tip="Somente o criador do tópico/comentário e você poderão ver esse comentário"
                  data-for={`${comentario?.comentarioId}-tooltip-cadeado-comentario`}
                >
                  {IconeCadeado}
                </div>
                <Tooltip
                  id={`${comentario?.comentarioId}-tooltip-cadeado-comentario`}
                  place="right"
                  offset={{ top: 5 }}
                />
              </>
            ) : (
              <></>
            )}
          </TituloComentario>
          <ContainarStatus>
            <span>{tipo === 'comentario' ? 'Postado' : 'Respondido'} em</span>
            {IconeCalendarioPequeno}
            <span>{FuncoesDataHora.novaData(comentario?.dataInclusao)}</span>
            {IconeRelogioPequeno}
            <span>
              {FuncoesDataHora.horaFormatada24H(
                new Date(comentario?.dataInclusao)
              )}
            </span>
            <span>
              <button
                type="button"
                data-tip="Excluir"
                data-for={'tooltip'}
                onClick={() => deletarComentario(comentario.comentarioId)}
              >
                {IconeLixeira}
              </button>
              <Tooltip
                id={`${comentario.comentarioId}-tooltip-excluir-comentario`}
                place="right"
                offset={{ top: 10 }}
              />
            </span>
          </ContainarStatus>
          <TextoComentario>
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeUtil(
                  comentario?.texto?.replaceAll('<p></p>', '<br />')
                )
              }}
            />
          </TextoComentario>
          {children}
          {tipo === 'comentario' &&
            (comentario.usuarioId === usuario.id || visaoResposta) &&
            renderizarResponder(comentario)}
        </Conteudo>
      </div>
    )
  }

  return (
    <>
      <Container>
        {comentarios.map(comentario => {
          const existeComentarioProfessor =
            comentario?.respostasComentario.filter(
              r => r.usuarioId !== comentario.usuarioId
            )?.length >= 1

          return renderizarComentario(
            'comentario',
            comentario,
            existeComentarioProfessor,
            <ContainerChildren>
              {comentario.respostasComentario.map(resposta => {
                return renderizarComentario(
                  'resposta',
                  resposta,
                  existeComentarioProfessor
                )
              })}
            </ContainerChildren>
          )
        })}
      </Container>
    </>
  )
}
