import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaDataTentativa = (): TabelaColuna => ({
  Chave: 'dataTentativa',
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataTentativa)}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 80 }}>Data Tentativa</div>
})
