import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 24px;
  font-weight: 300;
  font-size: 20px;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 26px;
  border: 1px solid #d1d3d4;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 30px;
    height: 30px;
  }

  & > svg path {
    fill: #666666;
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 25px;
`

export const TopicList = styled.ul`
  list-style-type: circle;
  display: grid;
  row-gap: 5px;
  cursor: pointer;
  & div {
    color: blue;
    text-decoration: underline;
  }
`
