import React, { useRef, useEffect } from 'react'

import {
  IconeAdicionar,
  TabelaRef,
  Spacer,
  DragAndDrop,
  SubTitulo,
  Tooltip
} from 'src/componentes'
import { PerguntasPesquisaSatisfacao } from 'src/tipos'

import { ModalRemoverPergunta } from '../remover-pergunta'
import {
  Botao,
  ContainerTooltip,
  Linha,
  LinhaTabela,
  SubTituloAviso
} from '../styles'
import {
  ModalRemoverPerguntaRef,
  ListaPerguntaProps,
  PerguntaDraggable
} from '../tipos'

export const ListaPerguntas: React.FC<ListaPerguntaProps> = ({
  adicionar,
  remover,
  reordenar,
  items,
  disabled
}) => {
  const tabelaRef = useRef<TabelaRef>(null)
  const modalRemoverRef = useRef<ModalRemoverPerguntaRef>(null)
  const tamanhoMaximoPergunta = 200

  const abrirModalRemoverPergunta = (pergunta: PerguntasPesquisaSatisfacao) =>
    modalRemoverRef?.current?.abrir(pergunta)

  const renderPesqunta = (pergunta: PerguntasPesquisaSatisfacao) => {
    return (
      <LinhaTabela>
        {pergunta.texto.length > tamanhoMaximoPergunta ? (
          <div data-tip={pergunta.texto} data-for={`exibir-${pergunta.id}`}>
            {pergunta.texto.substring(0, tamanhoMaximoPergunta)}...
          </div>
        ) : (
          <>{pergunta.texto}</>
        )}
        {pergunta.texto.length > tamanhoMaximoPergunta && (
          <ContainerTooltip>
            <Tooltip id={`exibir-${pergunta.id}`} offset={{ top: 10 }} />
          </ContainerTooltip>
        )}
      </LinhaTabela>
    )
  }

  useEffect(() => {
    tabelaRef?.current?.CarregarDados(true)
  }, [items])

  return (
    <>
      <Linha justifyContent="flex-end">
        {!disabled && (
          <Botao
            type="button"
            onClick={() => adicionar(null)}
            disabled={disabled}
          >
            {IconeAdicionar} Incluir Pergunta
          </Botao>
        )}
      </Linha>
      <>
        <SubTitulo texto="Perguntas" />
        {disabled && (
          <SubTituloAviso texto="A pesquisa já foi respondida e não pode ser editada!" />
        )}
        <Spacer padding="0px 0px 20px" />
        <DragAndDrop<PerguntaDraggable>
          render={item => renderPesqunta(item)}
          items={(items as PerguntaDraggable[]).map((x, i) => {
            x.draggableId = x.id

            if (x.ordem === null || x.ordem === undefined) {
              x.ordem = i
            }

            return x
          })}
          setItems={disabled ? () => {} : reordenar}
          editar={index => {
            adicionar(items[index])
          }}
          remover={index => {
            abrirModalRemoverPergunta(items[index])
          }}
          disabled={disabled}
        />
        <ModalRemoverPergunta
          ref={modalRemoverRef}
          backdrop
          acaoPrimaria={remover}
        />
      </>
    </>
  )
}
