import React from 'react'

export default (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.69998 9.39995C3.69998 9.79995 3.99998 10.1 4.39998 10.1H13.6C14 10.1 14.3 9.79995 14.3 9.39995C14.3 8.99995 14 8.69995 13.6 8.69995H4.39998C3.99998 8.69995 3.69998 9.09995 3.69998 9.39995Z"
      fill="currentColor"
    />
    <path
      d="M4.39998 7.19995H8.09998C8.49998 7.19995 8.79998 6.89995 8.79998 6.49995C8.79998 6.09995 8.49998 5.79995 8.09998 5.79995H4.39998C3.99998 5.79995 3.69998 6.09995 3.69998 6.49995C3.69998 6.89995 3.99998 7.19995 4.39998 7.19995Z"
      fill="currentColor"
    />
    <path
      d="M13.6 11.7H4.39998C3.99998 11.7 3.69998 12 3.69998 12.4C3.69998 12.8 3.99998 13.1 4.39998 13.1H13.6C14 13.1 14.3 12.8 14.3 12.4C14.3 12 14 11.7 13.6 11.7Z"
      fill="currentColor"
    />
    <path
      d="M13.6 14.6H4.39998C3.99998 14.6 3.69998 14.9 3.69998 15.3C3.69998 15.7 3.99998 16 4.39998 16H13.6C14 16 14.3 15.7 14.3 15.3C14.3 14.9 14 14.6 13.6 14.6Z"
      fill="currentColor"
    />
    <path
      d="M13.6 17.5H4.39998C3.99998 17.5 3.69998 17.8 3.69998 18.2C3.69998 18.6 3.99998 18.9 4.39998 18.9H13.6C14 18.9 14.3 18.6 14.3 18.2C14.3 17.9 14 17.5 13.6 17.5Z"
      fill="currentColor"
    />
    <path
      d="M17.8 6.09995L12.5 0.799951C12.4 0.699951 12.4 0.699951 12.3 0.699951H0.399976C0.199976 0.699951 0.0999756 0.799951 0.0999756 0.999951V23C0.0999756 23.2 0.199976 23.2999 0.399976 23.2999H17.6C17.8 23.2999 17.9 23.2 17.9 23V6.29995C17.9 6.29995 17.9 6.19995 17.8 6.09995ZM12.8 5.79995V2.99995L15.6 5.79995H12.8ZM11.8 7.19995H16.6V21.9H1.39998V2.09995H11.4V6.89995C11.4 6.99995 11.6 7.19995 11.8 7.19995Z"
      fill="currentColor"
    />
  </svg>
)
