import { Cores, Medias } from 'src/componentes/styles'
import styled from 'styled-components'

export const CorpoModal = styled.div`
  width: 650px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 80%;

  @media ${Medias.MOBILE} {
    width: 90%;
  }

  @media ${Medias.MOBILE} {
    height: 600px;
  }

  &:first-child {
    margin-top: 20px;

    @media ${Medias.MOBILE} {
      margin-top: 0px;
    }
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;

    @media ${Medias.MOBILE} {
      font-size: 20px;
    }
  }

  form {
    width: 100%;
  }
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;

  @media ${Medias.MOBILE} {
    min-width: 90%;
  }

  h5 {
    margin-top: 20px;
  }

  > form > div {
    margin-bottom: 12px;
  }

  form {
    max-height: 60%;
    overflow-y: auto;
    padding: 0px 10px;

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`

export const ContainerInput = styled.div`
  padding-top: 10px;
  margin-bottom: 0px;
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
  }

  & > button:last-child {
    margin-left: 20px;
    @media ${Medias.MOBILE} {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  button {
    @media ${Medias.MOBILE} {
      width: 100%;
    }
  }
`
export const ContainerBotaoIcone = styled.div`
  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: ${Cores.CINZA_2};
    }

    &:hover {
      background: ${Cores.CINZA_3_MAIS_CLARO};

      svg {
        fill: ${Cores.SECUNDARIA_1};

        path {
          fill: ${Cores.SECUNDARIA_1};
        }
      }
    }
  }
`
export const Tabela = styled.table`
  margin: 20px 0 27px 0;
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  color: #191414;
  border-collapse: collapse;
  border: 1px solid ${Cores.CINZA_3_CLARO};
  width: 100%;

  th {
    background: ${Cores.CINZA_HEAD_TABLE};
    color: ${Cores.PRETO};
    font-family: 'Saira-Semi-Bold', sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    border-bottom: none;
    text-transform: uppercase;
  }

  th:first-child {
    border-top-left-radius: 8px;
  }

  th:last-child {
    border-top-right-radius: 8px;
  }

  tbody {
    line-height: 125%;

    tr:first-child {
      border-top: 2px solid ${Cores.CINZA_3_CLARO};
    }

    tr {
      height: 48px;
      border: 1px solid ${Cores.FUNDO};
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid ${Cores.FUNDO};
      }

      &.par {
        background: ${Cores.FUNDO};
      }

      &.marcado {
        background: #afc2ed;
        border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
      }

      &.comMarcacao {
        cursor: pointer;
      }

      td,
      th {
        padding: 0 12px;
      }

      &:hover {
        border: 1px solid ${Cores.SECUNDARIA_1};
      }
    }
  }
`
export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDadosMedio = styled.div`
  width: 50%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosPequeno = styled.div`
  min-width: 25%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`
