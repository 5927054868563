import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaQuantidadeCobrancas = (): TabelaColuna => ({
  Chave: 'QtdCobrancas',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 250 }}>Quantidade Cobranças</div>
  )
})
