import React from 'react'

export default (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3238 3H14.9336V2.25C14.9336 1.00936 13.8594 0 12.539 0H9.34607C8.02566 0 6.9514 1.00936 6.9514 2.25V3H2.56118C1.46083 3 0.565628 3.84113 0.565628 4.875V7.5C0.565628 7.91419 0.923032 8.25 1.36385 8.25H1.80008L2.48969 21.857C2.55061 23.0587 3.60126 24 4.88161 24H17.0034C18.2838 24 19.3345 23.0587 19.3953 21.857L20.0849 8.25H20.5212C20.962 8.25 21.3194 7.91419 21.3194 7.5V4.875C21.3194 3.84113 20.4242 3 19.3238 3ZM8.54784 2.25C8.54784 1.83647 8.90595 1.5 9.34607 1.5H12.539C12.9791 1.5 13.3372 1.83647 13.3372 2.25V3H8.54784V2.25ZM2.16207 4.875C2.16207 4.66823 2.34112 4.5 2.56118 4.5H19.3238C19.5439 4.5 19.7229 4.66823 19.7229 4.875V6.75C19.4769 6.75 3.18145 6.75 2.16207 6.75V4.875ZM17.8007 21.7857C17.7804 22.1862 17.4302 22.5 17.0034 22.5H4.88161C4.45481 22.5 4.10459 22.1862 4.08434 21.7857L3.39832 8.25H18.4867L17.8007 21.7857Z"
      fill="#828282"
    />
  </svg>
)
