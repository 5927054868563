import { useEffect, useState } from 'react'

import { createContainer } from 'unstated-next'

import { configuracaoDeAviso } from './configuracao-de-aviso.config'
import { TipoDeAviso } from './tipo-de-aviso.enum'

export interface TipoDoAvisoContainer {
  criar: (mensagem: string, tipo: TipoDeAviso) => void
  configuracao: configuracaoDeAviso
  ativo: boolean
}

const configuracaoInicial = {
  tipo: TipoDeAviso.Sucesso,
  mensagem: 'Mensagem de exemplo.'
}

const atraso = 3500

const useAviso = (): TipoDoAvisoContainer => {
  const [ativo, definirAtivo] = useState<boolean>(false)
  const [configuracao, definirConfiguracao] = useState<configuracaoDeAviso>(
    configuracaoInicial
  )

  const criar = (mensagem: string, tipo: TipoDeAviso): void => {
    definirConfiguracao({ mensagem, tipo })
    definirAtivo(true)
  }

  useEffect(() => {
    const tempo = setTimeout(() => {
      definirAtivo(false)
    }, atraso)

    return () => {
      clearTimeout(tempo)
    }
  }, [ativo])

  return {
    criar,
    configuracao,
    ativo
  }
}

export default createContainer<TipoDoAvisoContainer>(useAviso)
