import styled, { css } from 'styled-components'

import { Cores, Medias } from '../styles'
import { BotaoStepProps } from './tipos'

type ContainerProps = Omit<
  BotaoStepProps,
  'texto' | 'textoNegrito' | 'invertido'
>

export const IconeLoader = styled.i`
  color: ${Cores.BRANCO};
`

export const Container = styled.button<ContainerProps>`
  display: flex;
  width: ${({ tamanho }) => (tamanho === 'M' ? 300 : 222)}px;
  border: 1px solid ${Cores.TRANSPARENTE};
  border-radius: 3px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: all 0.15s ease-in;
  padding: 15px 25px;

  ${props => {
    switch (props.tema) {
      case 'Secundario':
        return css`
          border: 1px solid ${Cores.SECUNDARIA_2_ESCURO};
          background: ${Cores.TRANSPARENTE};
          color: ${Cores.SECUNDARIA_2_ESCURO};

          &:hover {
            color: ${Cores.BRANCO};
            background: ${props.carregando
              ? Cores.BRANCO
              : Cores.SECUNDARIA_2_ESCURO};
            border: 1px solid ${Cores.SECUNDARIA_2_ESCURO};
            outline: 0;

            svg {
              fill: ${Cores.BRANCO};

              path {
                fill: ${Cores.BRANCO};
                stroke: ${Cores.BRANCO};
              }
            }
          }

          &:focus {
            color: ${Cores.SECUNDARIA_2_ESCURO};
            border: 1px solid ${Cores.SECUNDARIA_2_ESCURO};
            box-shadow: inset 0 0 0 2px ${Cores.CINZA_2};
            outline: 0;

            &:hover {
              color: ${Cores.BRANCO};
              border: 1px solid ${Cores.SECUNDARIA_2_ESCURO};
            }
          }

          &:active {
            color: ${Cores.BRANCO};
            background: ${Cores.CINZA_2_ESCURO};
            border: 1px solid ${Cores.TRANSPARENTE};
            box-shadow: inset 0 0 0 2px ${Cores.TRANSPARENTE};
            outline: 0;
          }

          &:disabled {
            color: ${Cores.CINZA_2_36};
            border: 1px solid ${Cores.CINZA_2_36};
            cursor: not-allowed;

            svg {
              fill: ${Cores.CINZA_2_36};
            }

            &:active {
              border: 1px solid ${Cores.CINZA_2_36};
              pointer-events: none;
            }
          }

          &[disabled]:hover {
            border: 1px solid ${Cores.TRANSPARENTE};
            color: ${Cores.CINZA_2_36};
          }

          svg {
            fill: ${Cores.SECUNDARIA_2_ESCURO};

            path {
              fill: ${Cores.SECUNDARIA_2_ESCURO};
              stroke: ${Cores.SECUNDARIA_2_ESCURO};
            }
          }
        `
      default:
        return css`
          letter-spacing: 0.05em;
          background: ${props.carregando
            ? Cores.SECUNDARIA_2_ESCURO_40
            : Cores.SECUNDARIA_2_ESCURO};
          color: ${Cores.BRANCO};

          &:hover {
            color: ${Cores.BRANCO};
            background: ${Cores.SECUNDARIA_2_ESCURO};

            svg {
              fill: ${Cores.BRANCO};
            }
          }

          &:focus {
            border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
            outline: none;
            box-shadow: none !important;
          }

          &:active {
            background: ${Cores.SECUNDARIA_2_ESCURO_40};
            color: ${Cores.PRIMARIA_2_ESCURO};
            border: 1px solid ${Cores.TRANSPARENTE};

            svg {
              fill: ${Cores.BRANCO};

              path {
                fill: ${Cores.BRANCO};
                stroke: ${Cores.BRANCO};
              }
            }

            &:hover {
              color: ${Cores.BRANCO};
            }
          }

          &:disabled {
            background: ${Cores.SECUNDARIA_2_ESCURO_40};
            color: ${Cores.BRANCO};
            cursor: not-allowed;

            svg {
              fill: ${Cores.BRANCO};
            }

            &:active {
              border: 1px solid ${Cores.TRANSPARENTE};
              pointer-events: none;
            }
          }

          svg {
            fill: ${Cores.BRANCO};

            path {
              fill: ${Cores.BRANCO};
              stroke: ${Cores.BRANCO};
            }
          }
        `
    }
  }}

  ${({ carregando: Carregando }) => css`
    pointer-events: ${Carregando ? 'none' : 'unset'};

    ${IconeLoader} {
      display: ${Carregando ? 'block' : 'none'};
      pointer-events: ${Carregando ? 'none' : 'unset'};
      margin-right: 10px;
      align-self: center;
    }
  `}

  @media ${Medias.MOBILE} {
    width: 220px;
  }
`

export const ContainerIcone = styled.div<{ invertido?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.invertido ? 'flex-start' : 'flex-end')};
  justify-content: center;
  order: ${props => (props.invertido ? '0' : '1')};

  ${props =>
    props.invertido
      ? css`
          svg {
            transform: rotate(180deg);
          }
        `
      : ''}
`

export const ContainerTexto = styled.div<{ invertido?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.invertido ? 'flex-end' : 'flex-start')};
  flex: 1;
  order: ${props => (props.invertido ? '1' : '0')};

  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;

  strong {
    font-weight: 500;
  }
`
