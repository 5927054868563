import { SituacaoUpload, TipoDocumentoAluno } from 'src/tipos'

import { UploadDocumentos } from '../tipos'

export const RelacaoDocumentosAlunoBrasileiro = new Array<UploadDocumentos>(
  {
    id: 1,
    tipo: TipoDocumentoAluno.Rg,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 1,
    obrigatorio: true,
    editarAprovado: true
  } as UploadDocumentos,
  {
    id: 2,
    tipo: TipoDocumentoAluno.DiplomaGraduacao,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 3,
    obrigatorio: true,
    editarAprovado: true
  } as UploadDocumentos,
  {
    id: 3,
    tipo: TipoDocumentoAluno.RgVerso,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 2,
    obrigatorio: true,
    editarAprovado: true
  } as UploadDocumentos,
  {
    id: 4,
    tipo: TipoDocumentoAluno.DiplomaGraduacaoVerso,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 4,
    obrigatorio: true,
    editarAprovado: true
  } as UploadDocumentos,
  {
    id: 5,
    tipo: TipoDocumentoAluno.CertidaoNascimento,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 6,
    multiplo: true
  } as UploadDocumentos,
  {
    id: 6,
    tipo: TipoDocumentoAluno.RegistroCivil,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 5,
    dica: 'Documento Opcional',
    editarAprovado: true
  } as UploadDocumentos
)
