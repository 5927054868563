import React from 'react'

import { TabelaColuna } from 'src/componentes'

export const colunaMotivoTentativas = (): TabelaColuna => ({
  Chave: 'motivoTentativas',
  ComOrdenacao: true,
  RenderizarCabecalho: () => (
    <div style={{ width: 130 }}>Motivo das tentativas</div>
  )
})
