import React from 'react'

export default (
  <svg
    style={{
      margin: 'auto',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto'
    }}
    width="24px"
    height="24px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="currentColor"
      strokeWidth="12"
      r="36"
      strokeDasharray="169.64600329384882 58.548667764616276"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
)
