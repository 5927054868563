export enum OpcaoAlteracaoTCC {
  Aproveitamento = 'Aproveitamento',
  Cancelamento = 'Cancelamento',
  Dispensa = 'Dispensa',
  Inclusao = 'Inclusao'
}

export enum OpcaoAlteracaoTCCPorExtenso {
  Aproveitamento = 'Aproveitamento',
  Cancelamento = 'Cancelamento',
  Dispensa = 'Dispensa',
  Inclusao = 'Inclusão'
}
