import { FormUnform } from 'src/componentes/unform/form'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ pb?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;

  ${({ pb }) =>
    pb &&
    css`
      padding-bottom: 20px;
    `}
`

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Campo = styled.div<{ mr?: boolean }>`
  flex: 1;

  ${({ mr }) =>
    mr &&
    css`
      margin-right: 10px;
    `}
`
export const ContainerExportar = styled.div`
  > button {
    margin: 10px;
  }
`
export const Dados = styled.div`
  margin-top: 25px;
  display: flex;
  overflow-x: auto;
  scroll-behavior: auto;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  #rodape_paginacao {
    float: left;
  }
`
