import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const Secao = styled.section`
  margin-top: 30px;
`

export const ContainerMain = styled.div`
  width: 70%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const Conteudo = styled.div`
  margin-top: 18px;
  margin-left: 30px;
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDadosMedio = styled.div`
  width: 50%;
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosPequeno = styled.div`
  width: 23%;
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 19px;
  }
`

export const ContainerLateral = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 58px;
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerPreRequisitos = styled.div`
  display: flex;
  flex-direction: column;

  & h2 {
    margin-bottom: 30px;
  }
  table {
    margin: 20px 0 60px 0;
    font-family: 'Saira', sans-serif;
    font-size: 12px;
    color: #191414;
    border-collapse: collapse;
    border: 1px solid ${Cores.CINZA_3_CLARO};
    width: 100%;
    th {
      background: ${Cores.CINZA_HEAD_TABLE};
      color: ${Cores.CINZA_2};
      font-family: 'Saira-Semi-Bold', sans-serif;
      font-weight: 600;
      font-size: 14px;
      padding: 24px 12px 10px 12px;
      border-bottom: none;
    }
    th:first-child {
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;
    }
    tbody {
      line-height: 125%;

      tr:first-child {
        border-top: 2px solid ${Cores.CINZA_3_CLARO};
      }

      tr {
        height: 48px;
        border: 1px solid ${Cores.FUNDO};
        border-bottom: none;

        &:last-child {
          border-bottom: 1px solid ${Cores.FUNDO};
        }

        &.par {
          background: ${Cores.FUNDO};
        }

        &.marcado {
          background: #afc2ed;
          border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
        }

        &.comMarcacao {
          cursor: pointer;
        }

        td,
        th {
          padding: 0 12px;
        }

        &:hover {
          border: 1px solid ${Cores.SECUNDARIA_1};
        }
      }
    }
  }
`
export const SemDadosPreReq = styled.p`
  padding: 54px;
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  color: ${Cores.CINZA_2_CLARO};
  text-align: center;
`
export const ContainerPreRequisitosBotao = styled.div`
  width: 180px;
  justify-self: flex-end;
`
export const ContainerPreRequisitosBotaoIcone = styled.div`
  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: ${Cores.CINZA_2};
    }

    &:hover {
      background: ${Cores.CINZA_3_MAIS_CLARO};

      svg {
        fill: ${Cores.SECUNDARIA_1};

        path {
          fill: ${Cores.SECUNDARIA_1};
        }
      }
    }
  }
`

export const Corpo = styled.div`
  width: 574px;
  height: 470px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 98px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  form {
    width: 100%;
  }
`

export const CorpoModal = styled(Corpo)`
  padding: 41px 0px;
  display: flex;
  justify-content: center;
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 23px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`

export const Linha = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .subtitulo {
    margin-bottom: 20px;
  }

  span:first-child {
    margin-right: 10px;
    margin-bottom: 12px;
  }

  .span:last-child {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 10px;
    color: ${Cores.CINZA_2};
  }

  .lg {
    flex: 1;
  }

  .button:last-child {
    margin-right: 10px;
  }
`

export const Botao = styled.button`
  color: ${Cores.SECUNDARIA_2_ESCURO};
  border: 2px solid ${Cores.SECUNDARIA_2_ESCURO};
  background-color: white;
  min-width: 160px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 15px 0 15px;
  cursor: pointer;
  transition: all 0.15s ease-in;

  &:hover {
    color: ${Cores.BRANCO};
    background: ${Cores.SECUNDARIA_2_ESCURO};
    border: 2px solid ${Cores.SECUNDARIA_2_ESCURO};
    outline: 0;
  }

  &:hover svg path {
    fill: ${Cores.BRANCO};
  }
`
export const ContainerLinha = styled.div`
  margin-bottom: 20px;
`

export const ContainerListaMaterial = styled.div`
  margin-bottom: 60px;
`

export const ContainerModalPreRequisitos = styled.div`
  margin-top: 10px;
  width: 70%;
`

export const ContainerSelect = styled.div`
  margin-bottom: 60px;
  width: 100%;
  max-height: 40px;
`

export const LabelSelect = styled.div`
  align-self: flex-start;
  margin-left: 85px;
  margin-top: 20px;
`

export const Obrigatorio = styled.span`
  color: ${Cores.PERIGO_ERRO};
  margin-right: 5px;
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ContainerIncluir = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`
