import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4285 11.9997C18.034 11.9997 17.7142 12.3195 17.7142 12.714V19.8569C17.7142 20.2514 17.3944 20.5712 16.9999 20.5712H4.14269C3.74818 20.5712 3.42838 20.2514 3.42838 19.8569V5.57109C3.42838 5.17659 3.74818 4.85679 4.14269 4.85679H12.7142C13.1087 4.85679 13.4285 4.53699 13.4285 4.14249C13.4285 3.74798 13.1087 3.42822 12.7142 3.42822H4.14269C2.95921 3.42822 1.99982 4.38762 1.99982 5.57109V19.8569C1.99982 21.0404 2.95921 21.9998 4.14269 21.9998H16.9999C18.1834 21.9998 19.1428 21.0404 19.1428 19.8569V12.714C19.1428 12.3195 18.823 11.9997 18.4285 11.9997Z"
      fill="inherit"
    />
    <path
      d="M21.1995 2.80099C20.6867 2.28804 19.991 1.99993 19.2657 2.00001C18.5399 1.99792 17.8435 2.28654 17.3321 2.80145L7.92343 12.2093C7.84538 12.288 7.78649 12.3836 7.75129 12.4886L6.32272 16.7744C6.19804 17.1486 6.4004 17.5531 6.77469 17.6777C6.84731 17.7019 6.92335 17.7143 6.99986 17.7144C7.07654 17.7142 7.15275 17.7019 7.22558 17.678L11.5113 16.2494C11.6166 16.2142 11.7122 16.1551 11.7906 16.0765L21.1992 6.66791C22.2671 5.60016 22.2672 3.86887 21.1995 2.80099ZM20.1892 5.65859L10.9034 14.9444L8.12914 15.8708L9.05271 13.1L18.3421 3.81429C18.8527 3.30461 19.68 3.30545 20.1897 3.81614C20.4331 4.0601 20.5703 4.39036 20.5714 4.73502C20.5722 5.08152 20.4347 5.414 20.1892 5.65859Z"
      fill="inherit"
    />
  </svg>
)
