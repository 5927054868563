import * as Yup from 'yup'

import { FuncoesDataHora } from '../funcoes'
import {
  regExpAmex,
  regExpDiners,
  regExpElo,
  regExpHiper,
  regExpJcb,
  regExpMaster,
  regExpVisa
} from '../regexp'

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'cep',
  function (mensagem?: string) {
    return this.test(
      'len',
      mensagem ?? 'CEP inválido',
      valor => !valor || valor.replace(/\D/g, '').length === 8
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'telefone',
  function (mensagem?: string) {
    return this.matches(
      /(^[0-9]{2}[1-8][0-9]{3}[0-9]{4}$|^[0-9]{2}9[0-9]{4}[0-9]{4})$/,
      mensagem ?? 'Telefone inválido'
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'cpf',
  function (mensagem?: string) {
    return this.test(
      'len',
      mensagem ?? 'CPF inválido',
      valor => !valor || valor.replace(/\D/g, '').length === 11
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'cpfCnpj',
  function (mensagem?: string) {
    return this.test(
      'len',
      mensagem ?? 'CPF / CNPJ inválido',
      valor =>
        !valor ||
        valor.replace(/\D/g, '').length === 11 ||
        valor.replace(/\D/g, '').length === 14
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'numero',
  function (mensagem?: string) {
    return this.test(
      'valor',
      mensagem ?? 'Informe um número válido',
      valor => !valor || /[0-9]/.test(valor)
    )
  }
)

const calculateImageSize = (base64String: string) => {
  let padding: number

  if (base64String.endsWith('==')) {
    padding = 2
  } else if (base64String.endsWith('=')) {
    padding = 1
  } else {
    padding = 0
  }

  return (base64String.length / 4) * 3 - padding
}
const maxAllowedByteSize = 5242880

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'imageSize',
  function (mensagem?: string) {
    return this.test(
      'len',
      mensagem ?? 'A Imagem excede o tamanho máximo permitido(5mb)',
      valor => valor && calculateImageSize(valor) <= maxAllowedByteSize
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'cartaoCredito',
  function (mensagem?: string) {
    const novaMensagem = mensagem ?? 'Cartão de Crédito inválido'

    return this.test('valid', novaMensagem, valor => {
      const novoValor = valor.replaceAll(' ', '')
      const validation =
        !!novoValor.match(regExpVisa) ||
        !!novoValor.match(regExpMaster) ||
        !!novoValor.match(regExpAmex) ||
        !!novoValor.match(regExpElo) ||
        !!novoValor.match(regExpHiper) ||
        !!novoValor.match(regExpJcb) ||
        !!novoValor.match(regExpDiners)

      return validation
    })
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'validadeCartaoCredito',
  function (mensagem?: string) {
    return this.matches(
      /^(0[1-9]|1[0-2])\/([0-9]{4})$/,
      mensagem ?? 'Validade do cartão inválida'
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'mesAno',
  function (formato = 'AAAA', mensagem?: string) {
    return this.test('mesAno', mensagem ?? 'Data inválida', value => {
      if (!value) return true

      return !!value.match(
        formato === 'AAAA'
          ? /^(0[0-9]|1[0-2])\/([0-9]{4})$/
          : /^(0[0-9]|1[0-2])\/([0-9]{2})$/
      )
    })
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'data',
  function (mensagem?: string) {
    return this.test(
      'valor',
      mensagem ?? 'Formato de data inválida.',
      valor => {
        return (
          !valor ||
          (valor.length === 10 &&
            !valor.match('_') &&
            FuncoesDataHora.dataValidaAmericana(valor))
        )
      }
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'dataBrasileira',
  function (mensagem?: string) {
    return this.test(
      'valor',
      mensagem ?? 'Formato de data inválida.',
      valor => valor.length === 10 && FuncoesDataHora.dataValida(valor)
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'senha',
  function (mensagem?: string) {
    return this.test('valor', mensagem ?? 'Informe uma senha válida', valor =>
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(valor)
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'dataMaiorIgualHoje',
  function (mensagem?: string) {
    return this.test(
      'valor',
      mensagem ?? 'Data deve ser maior ou igual a data atual',
      valor => {
        const valorFormat = valor.replaceAll('-', '/')
        const maiorQueDataAtual =
          new Date().setHours(0, 0, 0, 0) <=
          new Date(valorFormat).setHours(0, 0, 0, 0)

        return maiorQueDataAtual
      }
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'dataMaiorQue',
  function (data = new Date(), mensagem?: string) {
    return this.test(
      'valor',
      mensagem ??
        `Data deve ser maior que ${FuncoesDataHora.dataToLocaleString(data)}`,
      valor => {
        const valorFormat = valor.replaceAll('-', '/')
        const maiorQueDataAtual =
          data <= new Date(valorFormat).setHours(0, 0, 0, 0)

        return maiorQueDataAtual
      }
    )
  }
)

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'dataBrasileiraNullable',
  function (mensagem?: string) {
    return this.test('valor', mensagem ?? 'Formato de data inválida.', valor =>
      FuncoesDataHora.dataValidaNullable(valor)
    )
  }
)
