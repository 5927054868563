import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

import { Api } from 'src/servicos'

import { Carregando } from '../carregando'
import { Container } from './styles'
import { PlayerProps } from './tipos'

const Player: React.FC<PlayerProps> = ({
  url,
  materialId,
  salaAulaId,
  isAulaEad
}) => {
  const [carregando, definirCarregando] = useState<boolean>(true)
  const [urlLocal, setUrlLocal] = useState('')
  const videoRef = React.createRef<ReactPlayer>()

  const getOriginalUrl = () => {
    const sliceCounter =
      url.indexOf('#t=') > 0 ? url.indexOf('#t=') : url.length

    return url.slice(0, sliceCounter)
  }

  const getVideoInfoByMaterialId = () => {
    return JSON.parse(localStorage.getItem('@ipgs/materiaisVideo')).filter(
      video => video.materialId === materialId
    )[0]
  }

  const updateMaterialVideoInfo = (time: number, materialId: string) => {
    let otherVideos =
      JSON.parse(localStorage.getItem('@ipgs/materiaisVideo')) || []

    if (otherVideos.length > 0) {
      otherVideos = otherVideos.filter(video => video.materialId !== materialId)
    }

    localStorage.setItem(
      '@ipgs/materiaisVideo',
      JSON.stringify([...otherVideos, { time, materialId }])
    )
  }

  const getVideoTime = () => {
    const videoTimeLocalStorage = getVideoInfoByMaterialId()?.time || 0

    const urlTime =
      urlLocal.indexOf('#t=') > 0
        ? urlLocal.slice(urlLocal.indexOf('#t=') + 3, urlLocal.length)
        : 0

    return Number(videoTimeLocalStorage) > Number(urlTime)
      ? Number(videoTimeLocalStorage)
      : Number(urlTime)
  }

  const enviarTempoAtual = async (time: number) => {
    if (isAulaEad) {
      await Api.SalaAulaOutros.AtualizarTempoVideoEad(
        materialId,
        salaAulaId,
        getOriginalUrl() + '#t=' + time,
        isAulaEad
      )
    } else {
      await Api.SalaAulaCursoLivre.AtualizarTempoVideo(
        materialId,
        salaAulaId,
        getOriginalUrl() + '#t=' + time,
        isAulaEad
      )
    }
  }

  const saveAndCleanVideoTime = () => {
    enviarTempoAtual(Number(getVideoInfoByMaterialId().time))
  }

  useEffect(() => {
    window.addEventListener('beforeunload', saveAndCleanVideoTime)
    window.addEventListener('unload', saveAndCleanVideoTime)

    return () => {
      saveAndCleanVideoTime()
    }
  }, [])

  const getMaterialVideoUrlBack = async () => {
    let urlFromBack = ''
    if (isAulaEad) {
      urlFromBack = await Api.SalaAulaOutros.getMaterialVideoUrlEad(
        materialId,
        salaAulaId
      ).then(result => result)
    } else {
      urlFromBack = await Api.SalaAulaCursoLivre.getMaterialVideoUrl(
        materialId,
        salaAulaId
      ).then(result => result)
    }

    setUrlLocal(urlFromBack || url)
  }

  useEffect(() => {
    definirCarregando(true)

    getMaterialVideoUrlBack()

    if (typeof urlLocal === 'string' && urlLocal) {
      const urlTime =
        urlLocal.indexOf('#t=') > 0
          ? urlLocal.slice(urlLocal.indexOf('#t=') + 3, urlLocal.length)
          : 0

      updateMaterialVideoInfo(Number(urlTime), materialId)
      definirCarregando(false)
    }
  }, [materialId, url, urlLocal])

  return (
    <Container>
      {carregando ? (
        <Carregando texto="Carregando..." />
      ) : (
        <ReactPlayer
          ref={videoRef}
          className="player"
          url={url}
          width="100%"
          height="100%"
          config={{ vimeo: { playerOptions: { controls: true } } }}
          onReady={() => {
            definirCarregando(false)
          }}
          onError={() => {
            definirCarregando(false)
          }}
          onProgress={segundos =>
            updateMaterialVideoInfo(
              segundos.playedSeconds || getVideoTime(),
              materialId
            )
          }
          onPause={() =>
            enviarTempoAtual(Number(getVideoInfoByMaterialId()?.time || 0))
          }
          onDuration={() => videoRef?.current.seekTo(getVideoTime(), 'seconds')}
        />
      )}
    </Container>
  )
}

export default Player
