import React from 'react'

export default (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4ZM4 2C1.79086 2 0 3.79086 0 6V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V6C20 3.79086 18.2091 2 16 2H4Z"
      fill="#4F4F4F"
    />
    <path
      d="M5.75 5C5.33579 5 5 4.66421 5 4.25V0.75C5 0.335786 5.33579 1.81058e-08 5.75 0C6.16421 -1.81058e-08 6.5 0.335786 6.5 0.75V4.25C6.5 4.66421 6.16421 5 5.75 5Z"
      fill="#4F4F4F"
    />
    <path
      d="M13.75 5C13.3358 5 13 4.66421 13 4.25V0.75C13 0.335786 13.3358 1.81058e-08 13.75 0C14.1642 -1.81058e-08 14.5 0.335786 14.5 0.75V4.25C14.5 4.66421 14.1642 5 13.75 5Z"
      fill="#4F4F4F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8H0V6.5H20V8Z"
      fill="#4F4F4F"
    />
  </svg>
)
