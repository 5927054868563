import React, { useRef, useState, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import {
  FuncoesDataHora,
  IconeDeFinancasDoAluno,
  StepperContainer,
  Tooltip
} from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import { InfoProrrogacao } from 'src/servicos/api/sala-aula/tipos'
import { SalaAulaOutrosAceiteProrrogacao } from 'src/tipos'

import SalaAulaOutrosContainer from '../container'
import { ModalOpteProrrogacao } from './modal-opte-prorrogacao'
import {
  Container,
  CabecalhoListagem,
  Titulo,
  ContainerTela,
  Botoes,
  BotaoProrrogacao,
  InformacoesProrrogacao,
  Aviso
} from './styles'

export const OpteProrrogacaoComponente: React.FC<{
  salaAulaId: string
  podePedirProrrogacaoDataLiberacao: boolean
  podePedirProrrogacaoDataLimite: boolean
  podePedirProrrogacaoPendencias: boolean
  possuiProrrogacao: boolean
  dataInicioSolicitacao: string
  dataFimSolicitacao: string
  dataFimSala: string
  tempoMaximoProrrogacao: number
}> = ({
  salaAulaId,
  podePedirProrrogacaoDataLiberacao,
  podePedirProrrogacaoDataLimite,
  podePedirProrrogacaoPendencias,
  possuiProrrogacao,
  dataInicioSolicitacao,
  dataFimSolicitacao,
  dataFimSala,
  tempoMaximoProrrogacao
}) => {
  const modalRef = useRef<ModalOpteProrrogacao>(null)
  const [infoProrrogacao, definirInfoProrrogacao] = useState<InfoProrrogacao>(
    null
  )
  const { voltarPrimeiroItemStepper } = StepperContainer.useContainer()
  const { atualizarRecarregarSala } = SalaAulaOutrosContainer.useContainer()
  const [semOpcoes, definirSemOpcoes] = useState<boolean>(false)

  const carregarInformacaoProrrogacao = async () => {
    definirSemOpcoes(false)
    try {
      const informacoes = await Api.SalaAulaOutros.RequisitarInformacoesProrrogacao(
        salaAulaId
      )

      if (!informacoes?.tempoMaximoProrrogacao) {
        definirSemOpcoes(true)
      }

      definirInfoProrrogacao(informacoes)
    } catch (erro) {
      toast('Erro ao carregar informações dos valores da Prorrogação.', {
        type: 'error'
      })
    }
  }

  const enviarAceiteProrrogacao = async (
    dados: SalaAulaOutrosAceiteProrrogacao
  ): Promise<void> => {
    try {
      const resultado = await Api.SalaAulaOutros.EnviarAceiteProrrogacao(
        salaAulaId,
        dados.opcaoProrrogacaoId,
        dados.quantidadeParcelas,
        dados.formaPagamento,
        dados.diaVencimento,
        dados.dadosCartao
      )

      if (resultado) {
        atualizarRecarregarSala(true)
        voltarPrimeiroItemStepper()
        toast('Prorrogação efetivada com sucesso!', {
          type: 'success'
        })
      } else {
        toast(
          'Erro ao enviar informações Prorrogação, tente novamente mais tarde.',
          {
            type: 'error'
          }
        )
      }
    } catch {
      toast(
        'Erro ao enviar informações Prorrogação, tente novamente mais tarde.',
        {
          type: 'error'
        }
      )
    }
  }

  const podePedirProrrogacaoData = useMemo(
    () => podePedirProrrogacaoDataLimite && podePedirProrrogacaoDataLiberacao,
    [podePedirProrrogacaoDataLimite, podePedirProrrogacaoDataLiberacao]
  )

  const dataAtual = new Date()
  const dataInicioProrrogacao = new Date(dataInicioSolicitacao)
  const mensagemPodePedirProrrogacao = useMemo(() => {
    if (podePedirProrrogacaoData) {
      if (dataInicioProrrogacao >= dataAtual) {
        return (
          <p>
            A solicitação poderá ser feita entre{' '}
            {FuncoesDataHora.dataToLocaleString(dataInicioSolicitacao)} e{' '}
            {FuncoesDataHora.dataToLocaleString(dataFimSolicitacao)}.
          </p>
        )
      } else {
        return (
          <p>
            A solicitação poderá ser feita até{' '}
            {FuncoesDataHora.dataToLocaleString(dataFimSolicitacao)}.
          </p>
        )
      }
    } else {
      if (!podePedirProrrogacaoDataLimite) {
        return (
          <p>
            Esta modalidade poderia ser solicitada até o último dia de
            finalização de seu Curso. Após este prazo, a prorrogação não é
            autorizada. Portanto, neste momento não será possível realizar a
            contratação.
          </p>
        )
      } else if (possuiProrrogacao && !podePedirProrrogacaoDataLiberacao) {
        return (
          <p>
            As solicitações de prorrogação não são acumulativas e você já possui
            uma contratação em andamento! A data de finalização de seu curso é{' '}
            {FuncoesDataHora.dataToLocaleString(dataFimSala)}.
          </p>
        )
      } else {
        return (
          <p>
            A solicitação poderá ser feita entre{' '}
            {FuncoesDataHora.dataToLocaleString(dataInicioSolicitacao)} e{' '}
            {FuncoesDataHora.dataToLocaleString(dataFimSolicitacao)}.
          </p>
        )
      }
    }
  }, [
    podePedirProrrogacaoData,
    podePedirProrrogacaoDataLimite,
    podePedirProrrogacaoDataLiberacao,
    possuiProrrogacao,
    dataInicioSolicitacao,
    dataFimSolicitacao,
    dataFimSala
  ])

  const podePedirProrrogacao = useMemo(
    () =>
      podePedirProrrogacaoData &&
      podePedirProrrogacaoPendencias &&
      tempoMaximoProrrogacao > 0,
    [
      podePedirProrrogacaoData,
      podePedirProrrogacaoPendencias,
      tempoMaximoProrrogacao
    ]
  )

  const mensagemToolTip = useMemo(() => {
    const mensagemPendencias = podePedirProrrogacaoPendencias
      ? ''
      : 'Não é possível realizar esta prorrogação, entre em contato com a equipe iPGS. <br>'

    const mensagemData = podePedirProrrogacaoData
      ? ''
      : `A solicitação poderá ser feita entre
    ${FuncoesDataHora.dataToLocaleString(dataInicioSolicitacao)} e
    ${FuncoesDataHora.dataToLocaleString(dataFimSolicitacao)}.`

    return `${
      mensagemData ? `${mensagemData} <br>` : ''
    }  ${mensagemPendencias}`
  }, [podePedirProrrogacaoPendencias])

  useEffect(() => {
    if (tempoMaximoProrrogacao) {
      carregarInformacaoProrrogacao()
    }
  }, [tempoMaximoProrrogacao])

  return (
    <>
      <Container>
        <CabecalhoListagem>
          <>
            <Titulo>
              <h3>Prorrogação</h3>
              <p>
                Surgiu algum contratempo e não vai conseguir finalizar seu curso
                no tempo previsto? Aqui você tem a opção de adquirir o prazo que
                precisa para a finalização.
              </p>
              <p>
                Atenção! Após a confirmação da solicitação, não haverá como
                cancelar o pedido de prorrogação e o pagamento será realizado de
                forma integral. Portanto, só realize o pedido quando tiver
                certeza da necessidade em prorrogar a finalização de seu curso.
              </p>
              <p>
                Esclarecemos ainda que ao contratar a prorrogação
                automaticamente o prazo de encerramento do seu curso será
                estendido conforme opção escolhida na contratação, não sendo
                possível antecipar a finalização após a adesão em hipótese
                alguma.
              </p>
              <p>{mensagemPodePedirProrrogacao}</p>
              {!podePedirProrrogacaoPendencias && (
                <Aviso>
                  Contratação não disponível para alunos com pendências
                  financeiras. Para realização desta modalidade, é necessário
                  estar com as parcelas em dia. Converse com nosso time
                  Financeiro através do telefone (51) 98189-2884.
                </Aviso>
              )}
              {semOpcoes && (
                <Aviso>Não existem opções de prorrogação disponíveis.</Aviso>
              )}
              {!tempoMaximoProrrogacao && (
                <p>
                  Você já realizou a contratação de todo o período disponível
                  para a prorrogação de seu curso e não é possível realizar
                  outra extensão de prazo. A data de finalização de seu curso é{' '}
                  {FuncoesDataHora.dataToLocaleString(dataFimSala)}.
                </p>
              )}
            </Titulo>
            <Botoes>
              {infoProrrogacao?.opcoes?.map((prorrogacao, indice) => (
                <BotaoProrrogacao
                  key={indice}
                  disabled={!podePedirProrrogacao}
                  onClick={() =>
                    modalRef.current?.abrir(
                      prorrogacao.valor,
                      prorrogacao.quantidadeMeses,
                      prorrogacao.quantidadeParcelas,
                      prorrogacao.id
                    )
                  }
                >
                  {IconeDeFinancasDoAluno}
                  <InformacoesProrrogacao
                    data-tip={mensagemToolTip}
                    data-for={`prorrogacao-${prorrogacao.id}`}
                  >
                    <p>+ {prorrogacao.quantidadeMeses} MESES DE CURSO</p>
                    <p>{formatadores.moeda(prorrogacao.valor)}</p>
                  </InformacoesProrrogacao>
                  {!podePedirProrrogacaoPendencias && (
                    <Tooltip
                      id={`prorrogacao-${prorrogacao.id}`}
                      place="top"
                      html={true}
                    />
                  )}
                </BotaoProrrogacao>
              ))}
            </Botoes>
          </>
        </CabecalhoListagem>
      </Container>
      <ModalOpteProrrogacao
        backdrop
        ref={modalRef}
        acaoSucesso={enviarAceiteProrrogacao}
      />
    </>
  )
}

export const OpteProrrogacao: React.FC<{
  salaAulaId: string
  podePedirProrrogacaoDataLiberacao: boolean
  podePedirProrrogacaoDataLimite: boolean
  podePedirProrrogacaoPendencias: boolean
  possuiProrrogacao: boolean
  dataInicioSolicitacao: string
  dataFimSolicitacao: string
  dataFimSala: string
  tempoMaximoProrrogacao: number
}> = ({
  salaAulaId,
  podePedirProrrogacaoDataLiberacao,
  podePedirProrrogacaoDataLimite,
  podePedirProrrogacaoPendencias,
  possuiProrrogacao,
  dataInicioSolicitacao,
  dataFimSolicitacao,
  dataFimSala,
  tempoMaximoProrrogacao
}) => {
  return (
    <ContainerTela>
      <OpteProrrogacaoComponente
        salaAulaId={salaAulaId}
        podePedirProrrogacaoDataLiberacao={podePedirProrrogacaoDataLiberacao}
        podePedirProrrogacaoDataLimite={podePedirProrrogacaoDataLimite}
        podePedirProrrogacaoPendencias={podePedirProrrogacaoPendencias}
        possuiProrrogacao={possuiProrrogacao}
        dataInicioSolicitacao={dataInicioSolicitacao}
        dataFimSolicitacao={dataFimSolicitacao}
        dataFimSala={dataFimSala}
        tempoMaximoProrrogacao={tempoMaximoProrrogacao}
      />
    </ContainerTela>
  )
}
