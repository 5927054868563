import { ResumoDocumentos } from 'src/tipos'

import { UploadDocumentos } from '../tipos'

export function ResumoDeDocumentosDeEntradaMapper(
  atuais: UploadDocumentos[],
  novos: ResumoDocumentos[]
): UploadDocumentos[] {
  if (novos.length === 0) {
    return atuais
  }

  return atuais.map(atual => {
    const novo = novos.find(novo => novo.tipo === atual.tipo)

    if (novo) {
      const modificado = {
        documentoId: novo.documentoId,
        nome: novo.nomeArquivo,
        tipo: novo.tipo,
        status: novo.situacaoUpload,
        motivoDaRejeicao: novo.motivoRejeicao,
        dataPostagem: novo.dataPostagem,
        dataConferencia: novo.dataConferencia,
        dataNascimento: novo.dataNascimento
      } as UploadDocumentos

      return { ...atual, ...modificado }
    }

    return atual
  })
}

export function ResumoDeDocumentosMultiplosDeEntradaMapper(
  atuais: UploadDocumentos[],
  novos: ResumoDocumentos[]
): UploadDocumentos[] {
  if (novos.length === 0) {
    return atuais
  }

  const tiposAtuais = atuais.map(a => a.tipo)

  const novosMapeados =
    novos
      .filter(x => tiposAtuais.some(t => t === x.tipo))
      .map(novo => {
        const atual = atuais.find(x => x.tipo === novo.tipo)
        const modificado = {
          id: novo.documentoId,
          documentoId: novo.documentoId,
          nome: novo.nomeArquivo,
          tipo: novo.tipo,
          status: novo.situacaoUpload,
          motivoDaRejeicao: novo.motivoRejeicao,
          dataPostagem: novo.dataPostagem,
          dataConferencia: novo.dataConferencia,
          dataNascimento: novo.dataNascimento
        } as UploadDocumentos

        return { ...atual, ...modificado }
      }) ?? []

  const tiposNovos = novosMapeados.map(n => n.tipo)

  const atuaisFiltrados = atuais.filter(
    x => !tiposNovos.some(y => y === x.tipo)
  )

  return [...atuaisFiltrados, ...novosMapeados]
}
