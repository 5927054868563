import { Cores } from 'src/componentes/styles/cores'
import styled, { css } from 'styled-components'

export const ContainerDaPagina = styled.div``

export const TituloPrincipal = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
`

export const SubMenu = styled.h1`
  margin: 10px 0px 5px 0px;
  font-style: normal;
  font-size: 20px;
  line-height: 35px;
`

export const Obrigatorio = styled.span`
  color: ${Cores.PERIGO_ERRO};
  margin-right: 5px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TextoSelect = styled.p`
  font-size: 18px;
  margin-right: 10px;
  padding-bottom: 25px;
`

export const Campo = styled.div`
  width: 400px;
  min-height: 65px;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 23px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`

export const ContainerDisciplinasSelecionadas = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0px 0px 10px;
`

export const DisciplinaSelecionada = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  width: 590px;
  border-bottom: 1px solid ${Cores.CINZA_3_CLARO};
`

export const BotaoRemoverDisciplina = styled.div`
  background-color: white;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  padding: 2px;
  border-radius: 2px;

  :hover {
    background-color: ${Cores.CINZA_3_CLARO};
  }
`
