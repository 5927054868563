import React, { useState, useMemo } from 'react'

import {
  IconeInformacaoMedio,
  IconeAvaliacao,
  IconeCertificates,
  IconeVideo,
  StepperContainer,
  FuncoesDataHora
} from 'src/componentes'
import { StepperItem } from 'src/componentes/stepper/tipos'
import { SalaAulaCursoLivre, SalaAulaTipoMaterial } from 'src/tipos'
import { createContainer } from 'unstated-next'

import { itemStepperMaterial, mensagemEtapaBloqueada } from '../shared'
import { calcularDistanciaData } from '../shared/funcoes/calcularDatas'
import { Avaliacao } from './avaliacao'
import { Certificado } from './certificado'
import { ComeceAqui } from './comece-aqui'
import { GrupoDiscussao } from './grupo-discussao'
import { Material } from './material'
import { PesquisaSatisfacaoSalaAulaLivre } from './pesquisa-satisfacao'
import { SobreCurso } from './sobre-curso'
import {
  SalaAulaCursoLivreContainer,
  SalaAulaCursoLivreContainerEstado
} from './tipos'

const useSalaAula = (initialState: { idMatricula: string }) => {
  const { avancarStepper } = StepperContainer.useContainer()
  const [estado, definirEstado] = useState<SalaAulaCursoLivreContainerEstado>({
    idMatricula: '',
    ...initialState,
    salaAula: {} as SalaAulaCursoLivre,
    itensMarcados: [],
    atualizarSala: false
  })

  const mensagemCertificadoBloqueado = useMemo(() => {
    const { salaAula } = estado

    const dataLiberacao = new Date(salaAula.dataLiberacao)

    if (!salaAula.dataLiberacao) return ''

    if (dataLiberacao > new Date()) {
      return `Será liberada em ${FuncoesDataHora.dataFormatadaComBarras(
        dataLiberacao
      )} ${FuncoesDataHora.horaFormatada24H(dataLiberacao)}, após aprovação`
    }

    if (!salaAula.avaliacao && !salaAula.pesquisaSatisfacaoRespondida) {
      return 'Certificado será liberado somente após a resposta da pesquisa de satisfação'
    }

    if (!salaAula.aprovado) {
      return 'Certificado será liberado somente após ser aprovado na avaliação'
    }

    if (
      salaAula.pesquisaSatisfacaoId &&
      !salaAula.pesquisaSatisfacaoRespondida
    ) {
      return 'Certificado será liberado somente após a resposta da pesquisa de satisfação'
    }

    if (calcularDistanciaData(salaAula.dataInicio) <= 0) {
      return `Curso inicia em ${FuncoesDataHora.novaData(
        salaAula?.dataInicio
      )}.`
    }
  }, [
    estado.salaAula?.dataLiberacao,
    estado.salaAula?.avaliacao,
    estado.salaAula?.aprovado,
    estado.salaAula?.pesquisaSatisfacaoId
  ])

  const etapas: StepperItem[] = useMemo(() => {
    const { salaAula } = estado
    if (!salaAula.id) return []

    const naoIniciouSalaAula = salaAula?.dataInicio
      ? calcularDistanciaData(salaAula?.dataInicio) <= 0
      : false

    let novoPasso = 1

    const novasEtapas: StepperItem[] = [
      {
        id: 'sobre-curso',
        titulo: 'Apresentação do curso',
        icone: IconeInformacaoMedio,
        passo: novoPasso,
        componente: <SobreCurso />
      }
    ]

    if (salaAula?.informacoesIniciaisCurso) {
      novasEtapas.push({
        id: 'comece-aqui',
        titulo: 'Comece aqui',
        icone: IconeVideo,
        passo: (novoPasso += 1),
        componente: (
          <ComeceAqui
            informacoesIniciaisCurso={salaAula.informacoesIniciaisCurso}
          />
        )
      })
    }

    novoPasso += 1

    const bloquearSalaAulaTempoLimite =
      calcularDistanciaData(salaAula.dataLimiteAcessoTempoDisponibilidade) > 0

    const mensagemSalaAulaBloqueadaTempoLimite =
      'Tempo de disponibilidade de materiais já encerrou.'

    const dataLiberacao = new Date(salaAula.dataLiberacao)

    let mensagemBloqueioPesquisaSatisfacao

    if (salaAula.dataLiberacao && dataLiberacao >= new Date()) {
      mensagemBloqueioPesquisaSatisfacao = `Pesquisa será liberada em  ${FuncoesDataHora.novaData(
        salaAula?.dataLiberacao
      )}`
    } else {
      mensagemBloqueioPesquisaSatisfacao =
        'Para responder a pesquisa é necessário assistir todas as aulas do curso.'
    }
    const MENSAGEM_SALA_AULA_NAO_INICIOU = `Curso inicia em ${FuncoesDataHora.novaData(
      salaAula?.dataInicio
    )}.`

    const MENSAGEM_AVALIACAO_BLOQUEADA_POR_NAO_TER_AVALIACAO_AINDA =
      'A avaliação ainda não foi liberada. Aguarde a liberação para concluir o curso.'

    const MENSAGEM_AVALIACAO_BLOQUEADA_RESPOSTA_PESQUISA_SATISFACAO =
      'É preciso realizar a pesquisa de satisfação para liberar a avaliação'

    salaAula?.disciplinas?.forEach(disciplina => {
      const subItensDisciplinas: StepperItem[] = []

      if (!bloquearSalaAulaTempoLimite) {
        disciplina.materiais.forEach((material, indice) => {
          subItensDisciplinas.push(
            itemStepperMaterial(
              material,
              <Material
                id={material.id}
                tipo={material.tipoMaterial}
                url={
                  material.tipoMaterial === SalaAulaTipoMaterial.Video
                    ? material.linkMaterial
                    : undefined
                }
                ultimoMaterial={indice + 1 === disciplina.materiais.length}
                pdf={material.extensao === '.pdf'}
              />,
              novoPasso,
              naoIniciouSalaAula,
              MENSAGEM_SALA_AULA_NAO_INICIOU
            )
          )

          if (
            (material.tipoMaterial === SalaAulaTipoMaterial.Anexo &&
              material.extensao === '.pdf') ||
            material.tipoMaterial === SalaAulaTipoMaterial.Video
          ) {
            novoPasso += 1
          }
        })
      }

      novasEtapas.push({
        id: disciplina.id,
        titulo: disciplina.nomeDisciplina,
        subItens: subItensDisciplinas,
        bloqueado: bloquearSalaAulaTempoLimite,
        mensagemBloqueado: mensagemSalaAulaBloqueadaTempoLimite
      })
    })

    salaAula.temGrupoDiscussao &&
      novasEtapas.push({
        id: 'grupo-discussao-livre',
        titulo: 'Fórum de dúvidas',
        passo: novoPasso,
        componente: <GrupoDiscussao />
      })

    const avaliacaoBloqueada =
      salaAula.dataLiberacao && dataLiberacao >= new Date()

    let mensagemAvaliacaoIndisponivel

    const avaliacaoNaoLiberada = !salaAula.avaliacao
    const bloqueioPesquisaSatisfacao =
      salaAula.pesquisaSatisfacaoId && !salaAula.pesquisaSatisfacaoRespondida

    if (avaliacaoBloqueada) {
      mensagemAvaliacaoIndisponivel = mensagemEtapaBloqueada(dataLiberacao)
    }

    if (bloquearSalaAulaTempoLimite) {
      mensagemAvaliacaoIndisponivel = mensagemSalaAulaBloqueadaTempoLimite
    }

    if (naoIniciouSalaAula) {
      mensagemAvaliacaoIndisponivel = MENSAGEM_SALA_AULA_NAO_INICIOU
    }

    if (bloqueioPesquisaSatisfacao) {
      mensagemAvaliacaoIndisponivel = MENSAGEM_AVALIACAO_BLOQUEADA_RESPOSTA_PESQUISA_SATISFACAO
    }

    if (avaliacaoNaoLiberada) {
      mensagemAvaliacaoIndisponivel = MENSAGEM_AVALIACAO_BLOQUEADA_POR_NAO_TER_AVALIACAO_AINDA
    }

    if (salaAula.pesquisaSatisfacaoId) {
      novasEtapas.push({
        id: salaAula.pesquisaSatisfacaoId,
        titulo: 'Pesquisa de Satisfação',
        passo: novoPasso,
        componente: <PesquisaSatisfacaoSalaAulaLivre />,
        bloqueado: naoIniciouSalaAula || !salaAula.pesquisaSatisfacaoLiberada,
        mensagemBloqueado: salaAula.pesquisaSatisfacaoRespondida
          ? 'A pesquisa já foi respondida'
          : mensagemBloqueioPesquisaSatisfacao
      })
      novoPasso += 1
    }

    if (salaAula.possuiAvaliacao) {
      novoPasso += 1

      novasEtapas.push({
        id: salaAula.avaliacao?.id,
        titulo: 'Avaliação',
        passo: novoPasso,
        icone: IconeAvaliacao,
        componente: <Avaliacao />,
        bloqueado:
          avaliacaoBloqueada ||
          bloquearSalaAulaTempoLimite ||
          naoIniciouSalaAula ||
          avaliacaoNaoLiberada ||
          bloqueioPesquisaSatisfacao,
        mensagemBloqueado: mensagemAvaliacaoIndisponivel
      })

      novoPasso += 1
    }

    const bloquearCertificado =
      !salaAula.dataLiberacao ||
      dataLiberacao > new Date() ||
      !salaAula.aprovado ||
      bloqueioPesquisaSatisfacao

    novasEtapas.push({
      id: 'emitir-certificado',
      titulo: 'Emitir certificado',
      icone: IconeCertificates,
      passo: novoPasso,
      componente: <Certificado />,
      bloqueado: bloquearCertificado || naoIniciouSalaAula,
      mensagemBloqueado: bloquearCertificado
        ? mensagemCertificadoBloqueado
        : undefined
    })

    return novasEtapas
  }, [
    estado.salaAula?.disciplinas,
    estado.salaAula?.avaliacao,
    estado.salaAula?.dataLiberacao,
    estado.salaAula?.aprovado,
    estado.salaAula?.pesquisaSatisfacaoId,
    estado.salaAula?.pesquisaSatisfacaoLiberada,
    mensagemCertificadoBloqueado
  ])

  const definirPasso = (passo: number) => {
    definirEstado(old => ({ ...old, passo }))
  }

  const definirProgresso = (progresso: number) => {
    definirEstado(old => ({ ...old, salaAula: { ...old.salaAula, progresso } }))
  }

  const definirSalaAula = (salaAula: SalaAulaCursoLivre) => {
    definirEstado(old => ({
      ...old,
      salaAula
    }))
  }

  const definirItensMarcados = (itensMarcados: string[]) => {
    definirEstado(old => ({ ...old, itensMarcados }))
  }

  const emitirCertificado = async () => {
    avancarStepper()
  }

  const definirAtualizarSala = (atualizarSalaNovoEstado: boolean) => {
    definirEstado(old => ({ ...old, atualizarSala: atualizarSalaNovoEstado }))
  }

  const definirPesquisaSatisfacaoId = (pesquisaSatisfacaoId?: string) => {
    definirEstado(old => ({
      ...old,
      salaAula: { ...old.salaAula, pesquisaSatisfacaoId }
    }))
  }

  const definirPesquisaSatisfacaoLiberada = (
    pesquisaSatisfacaoLiberada: boolean
  ) => {
    definirEstado(old => ({
      ...old,
      salaAula: { ...old.salaAula, pesquisaSatisfacaoLiberada }
    }))
  }

  return {
    ...estado,
    definirPasso,
    definirProgresso,
    definirSalaAula,
    emitirCertificado,
    definirItensMarcados,
    definirAtualizarSala,
    definirPesquisaSatisfacaoId,
    definirPesquisaSatisfacaoLiberada,
    etapas
  }
}

export default createContainer<
  SalaAulaCursoLivreContainer,
  { idMatricula: string }
>(useSalaAula)
