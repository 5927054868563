import queryString from 'query-string'
import {
  PesquisaPesquisaSatisfacaoDTO,
  PesquisaPesquisaSatisfacaoResposta,
  PesquisaSatisfacaoListaResposta
} from 'src/dto/pesquisa-satisfacao'
import {
  ModalidadeCurso,
  OpcoesPaginacao,
  PesquisaSatisfacao,
  PesquisaSatisfacaoProfessorSalaAulaOutrosResposta,
  PesquisaSatisfacaoResposta,
  PesquisaSatisfacaoSalaAulaLivreResposta,
  PesquisaSatisfacaoSalaAulaOutrosResposta,
  RespostaPadraoAPI,
  RespostaPadraoDadosAPI,
  SituacaoPesquisaSatisfacao,
  TipoOrdenacao,
  TipoPesquisaSatisfacao
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'

const urlBase = '/pesquisaSatisfacao'

export const RequisitarPesquisaSatisfacao = async (
  id: string
): Promise<PesquisaSatisfacao> => {
  const {
    data: { data }
  } = await gatewayApi.get<RespostaPadraoDadosAPI<PesquisaSatisfacao>>(
    `${urlBase}/${id}`
  )

  return { ...data }
}

export const RequisitarListaPesquisasSatisfacao = async (
  codigo?: string,
  nome?: string,
  modalidade?: keyof typeof ModalidadeCurso | 'Todas',
  situacao?: keyof typeof SituacaoPesquisaSatisfacao,
  tipo?: TipoPesquisaSatisfacao,
  dataInicioPesquisaSatisfacao?: string,
  dataFimPesquisaSatisfacao?: string,
  pagina?: number,
  quantidadeRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaPesquisaSatisfacaoDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      codigo,
      nome,
      modalidade: modalidade === 'Todas' ? null : modalidade,
      situacao: situacao === 'Todos' ? null : situacao,
      tipo,
      dataInicioPesquisaSatisfacao,
      dataFimPesquisaSatisfacao,
      quantidadeRegistros,
      pagina,
      ordem
    })

    const {
      data: {
        data: { itens, quantidade }
      }
    } = await gatewayApi.get<PesquisaPesquisaSatisfacaoResposta>(
      `${urlBase}?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }

    return new Promise(resolve =>
      resolve({ registros: itens, opcoesPaginacao })
    )
  } catch {
    return null
  }
}

export const InserirPesquisaSatisfacao = async (
  pesquisaSatisfacao: PesquisaSatisfacao
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.post<RespostaPadraoAPI>(`${urlBase}`, pesquisaSatisfacao)

  return success
}

export const AtualizarPesquisaSatisfacao = async (
  id: string,
  pesquisaSatisfacao: PesquisaSatisfacao
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.put<RespostaPadraoAPI>(
    `${urlBase}/${id}`,
    pesquisaSatisfacao
  )

  return success
}

export const RequisitarListaPesquisaSatisfacaoCodigo = async (
  modalidade: keyof typeof ModalidadeCurso,
  tipo: keyof typeof TipoPesquisaSatisfacao,
  texto?: string
): Promise<PesquisaPesquisaSatisfacaoDTO[]> => {
  const filtro = queryString.stringify({
    modalidade,
    tipo,
    codigo: texto
  })
  const { data } = await gatewayApi.get<PesquisaSatisfacaoListaResposta>(
    `${urlBase}/lista?${filtro}`
  )

  return data.data
}

export const EnviarRespostaPesquisaSatisfacaoSalaAulaLivre = async (
  dados: PesquisaSatisfacaoSalaAulaLivreResposta
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.post(
    `${urlBase}/${dados.pesquisaSatisfacaoId}/resposta/curso-livre/${dados.salaAulaLivreId}`,
    dados
  )

  return success
}

export const EnviarRespostaPesquisaSatisfacaoInstitucional = async (
  dados: PesquisaSatisfacaoResposta
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.post(
    `${urlBase}/${dados.pesquisaSatisfacaoId}/resposta/institucional`,
    dados
  )

  return success
}

export const EnviarRespostaPesquisaSatisfacaoSalaAulaOutros = async (
  dados: PesquisaSatisfacaoSalaAulaOutrosResposta
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.post(
    `${urlBase}/${dados.pesquisaSatisfacaoId}/resposta/curso-ead/${dados.salaAulaOutrosId}/${dados.salaAulaOutrosModuloId}`,
    dados
  )

  return success
}

export const EnviarRespostaPesquisaSatisfacaoSalaAulaOutrosDisciplinaProfessor = async (
  dados: PesquisaSatisfacaoProfessorSalaAulaOutrosResposta
): Promise<{ novoEnvio: boolean }> => {
  const { data } = await gatewayApi.post<{ novoEnvio: boolean }>(
    `${urlBase}/${dados.pesquisaSatisfacaoId}/resposta/curso-ead/professores/${dados.salaAulaOutrosId}/${dados.salaAulaOutrosDisciplinaId}`,
    dados
  )

  return data
}
