import React from 'react'

export default (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 13.3H22.6V12.9C22.6 10.8 20.9 9 18.7 9C16.6 9 14.8 10.7 14.8 12.9V13.3H14.4C14.2 13.3 14 13.5 14 13.7V20.6C14 20.8 14.2 21 14.4 21H23C23.2 21 23.4 20.8 23.4 20.6V13.7C23.4 13.5 23.2 13.3 23 13.3ZM15.7 12.8C15.7 11.1 17 9.8 18.7 9.8C20.4 9.8 21.7 11.1 21.7 12.8V13.2H15.7V12.8ZM22.5 20.1H14.8V14.1H22.5V20.1Z"
      fill="#606060"
    />
    <path
      d="M19.9 16.3C19.7 15.8 19.2 15.4 18.7 15.4C18 15.4 17.4 16 17.4 16.7C17.4 17.2 17.7 17.7 18.3 17.9V18.8C18.3 19 18.5 19.2 18.7 19.2C18.9 19.2 19.1 19 19.1 18.8V17.9C19.8 17.7 20.1 16.9 19.9 16.3ZM18.7 17.1C18.5 17.1 18.3 16.9 18.3 16.7C18.3 16.5 18.5 16.3 18.7 16.3C18.9 16.3 19.1 16.5 19.1 16.7C19.1 16.9 18.9 17.1 18.7 17.1Z"
      fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6 6.1V2.6C19.6 2.5 19.6 2.4 19.6 2.3C19.4 1 18.3 0 17 0H3.00002C1.70002 0 0.600024 1 0.400024 2.2C0.400024 2.3 0.400024 2.4 0.400024 2.5V13.3C0.400024 14.8 1.60002 16 3.00002 16H11C11.5 16 11.9 15.6 11.9 15.1C11.9 14.6 11.5 14.2 11 14.2H3.00002C2.50002 14.2 2.10002 13.8 2.10002 13.3V4.2L9.50002 9.4C9.80002 9.6 10.2 9.6 10.5 9.4L17.9 4.2V6.1C17.9 6.6 18.3 7 18.8 7C19.3 7 19.6 6.6 19.6 6.1ZM10 7.6L2.30002 2.1C2.50002 1.9 2.70002 1.8 3.00002 1.8H17C17.3 1.8 17.5 1.9 17.7 2.2L10 7.6Z"
      fill="#606060"
    />
  </svg>
)
