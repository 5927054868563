import { ModalidadeLivre, PlanoPagamento } from './curso'
import {
  MetodosPagamento,
  NacionalidadePorExtenso,
  OpcaoTCC,
  TipoProfessorModuloTCC,
  StatusRecorrencia,
  StatusFinanceiro
} from './enums'
import { TipoPesquisaSatisfacao } from './enums/pesquisa-satisfacao'
import { SituacaoParcela, SituacaoParcelaFiltro } from './enums/situacaoParcela'
import {
  ModalidadeCurso,
  FormaPagamentoCobranca,
  TipoCobranca,
  SituacaoFinanceiraFiltro,
  SituacaoFinanceira,
  TipoDescontoCupom,
  UtilizacaoCupom,
  SituacaoUpload,
  TipoMatriculaPorExtenso,
  TipoRecorrencia
} from './index'

export interface RelatorioFinanceiroFiltros {
  inicioPagamento?: string
  fimPagamento?: string
  inicioVencimento?: Date
  fimVencimento?: Date
  turmaId?: string[]
  cursoId?: string[]
  modalidade?: Array<ModalidadeCurso>
  planoPagamentoId?: string
  situacoesCobranca?: Array<SituacaoParcelaFiltro>
  formaPagamento?: Array<FormaPagamentoCobranca>
  situacoesFinanceiras?: Array<SituacaoFinanceiraFiltro>
  nomeAluno?: string
  inicioDataMatricula?: Date
  fimDataMatricula?: Date
}

export interface RelatorioFinanceiroResponse {
  nomeAluno?: string
  cpfAluno?: string
  celular?: string
  email?: string
  codigoTurmaCurso?: string
  nomeoTurmaCurso?: string
  numeroMesParcela?: string
  tipoCobranca?: TipoCobranca
  valorParcela?: number
  dataVencimentoParcela?: Date
  situacaoParcela?: SituacaoParcela
  valorPagoParcela?: number
  dataPagamentoParcela?: Date
  dataMatricula?: Date
  formaPagamento?: FormaPagamentoCobranca
  modalidade?: ModalidadeCurso
  situacaoMatricula?: SituacaoFinanceira
  observacaoParcela?: string
  nossoNumeroTID?: string
  qtdTentativas?: string
  codigoCielo?: string
  mensagemRetorno?: string
  carrinhoId?: string
  statusCarrinho?: string
  qtdCursosCarrinho: string
  dataUltimoAcesso?: Date
  responsavelFinanceiro: string
  totalParcelas?: string
}

export interface RelatorioMatriculadosTurmaFiltros {
  cursoId?: string
  turmaId?: string[]
  situacoesFinanceiras?: SituacaoFinanceiraFiltro[]
  nomeAluno?: string
  inicioDataMatricula?: Date
  fimDataMatricula?: Date
  dataRescisao?: Date
}

export interface RelatorioMatriculadosTurmaResponse {
  nomeAluno?: string
  email?: string
  cpf?: string
  celular?: string
  endereco?: string
  modalidade?: ModalidadeCurso
  curso?: string
  turma?: string
  situacaoMatricula?: SituacaoFinanceira
  dataMatricula?: Date
  dataInicio?: Date
  formaPagamento?: FormaPagamentoCobranca
  valorParcela?: number
  quantidadeParceelas?: number
  cupom?: string
  desconto?: string
  valorParcelaComDesconto?: number
  valorTotal?: number
  valorFinal?: number
}

export interface RelatorioComercialFiltros {
  nomeAluno?: string
  email?: string
  curso?: string
  DataInicio?: Date
  DataFim?: Date
  UF?: string
  profissao?: string
  formaPagamento?: string
  situacoesFinanceiras?: Array<StatusFinanceiro>
}
export interface RelatorioMatriculadosCursosLivresFiltros {
  cursosId?: string[]
  inicioQuitacao?: Date
  fimQuitacao?: Date
  inicioMatricula?: Date
  fimMatricula?: Date
  situacoesFinanceiras?: SituacaoFinanceiraFiltro[]
  nomeAluno?: string
}

export interface RelatorioMatriculadosCursosLivresResponse {
  nomeAluno?: string
  emailAluno?: string
  cpf?: string
  // eslint-disable-next-line
  docIdentidade_OrgaoEmissor?: string
  celular?: string
  cidade?: string
  uf?: string
  curso?: string
  modalidadeLivre?: ModalidadeLivre
  area?: SituacaoParcela
  situacao?: SituacaoFinanceira
  dataMatricula?: Date
  dataInicio?: Date
  dataInicioCurso?: Date
  dataLimiteAcesso?: Date
  dataPagamento?: Date
  valorRecebido?: number
  cupom?: string
  descontoCupom?: string
  progressoCurso?: string
  avaliacaoNome?: string
  nota?: string
  dataPrimeiraGeracaoCertificado?: Date
}

export interface RelatorioCuponsDescontoFiltros {
  nomeCupom?: string
  codigoCupom?: string
  modalidadeCurso?: ModalidadeCurso[]
  tipoCupom?: TipoDescontoCupom[]
  dataInicioCupom?: Date
  dataFimCupom?: Date
  utilizacaoCupom?: UtilizacaoCupom[]
  formaPagamento?: FormaPagamentoCobranca[]
  cpf?: string
  cursosId?: string[]
}

export interface RelatorioCuponsDescontoResponse {
  nomeCupom?: string
  codigoCupom?: string
  modalidadeCurso?: ModalidadeCurso
  desconto?: string
  dataInicioCupom?: Date
  dataFimCupom?: string
  utilizacaoCupom?: UtilizacaoCupom
  formaPagamento?: FormaPagamentoCobranca
  validoAVista?: boolean
  limitePorCupom?: number
  limitePorCliente?: number
  quantidadeUso?: number
  quantidadeUsoRestante?: number
  curso?: string
  planoPagamentoCupom?: PlanoPagamento
  dataCriacao?: Date
  criadoPor?: string
  dataUltimaAlteracao?: Date
  modificadoPor?: string
}

export interface RelatorioDocumentosAlunoFiltros {
  nomeAluno?: string
  modalidades?: ModalidadeCurso
  cursosId?: string[]
  turmasId?: string[]
  dataEnvio?: Date
  dataConferencia?: Date
  situacoes?: SituacaoUpload[]
  situacoesFinanceiras?: SituacaoFinanceiraFiltro[]
}

export interface RelatorioDocumentosAlunoResponse {
  nomeAluno?: string
  emailAluno?: string
  cpf?: string
  nacionalidade?: string
  tipoArquivo?: string
  nomeArquivo?: string
  dataEnvio?: Date
  dataConferencia?: Date
  turmas?: string
  motivoRejeicao?: string
  situacao?: SituacaoFinanceira
}

export interface RelatorioProrrogacaoFiltros {
  nomeAluno?: string
  modalidade?: ModalidadeCurso
  cursosId?: string[]
  turmasId?: string[]
  dataInicioMatricula?: Date
  dataFimMatricula?: Date
  dataInicioConclusao?: Date
  dataFimConclusao?: Date
  dataInicioProrrogacao?: Date
  dataFimProrrogacao?: Date
  situacoes?: SituacaoFinanceiraFiltro[]
}

export interface RelatorioProrrogacaoResponse {
  nomeAluno?: string
  modalidade?: ModalidadeCurso
  curso?: string
  turma?: string
  situacao?: SituacaoFinanceira
  dataInicio?: Date
  dataFimOriginal?: Date
  dataFimProrrogada?: Date
  prorrogacao?: string
  dataSolicitacao?: string
}

export enum ModoAvaliacao {
  PorDisciplina = 'Por Disciplina',
  PorModulo = 'Por Módulo'
}

export enum NotaPorAproveitamento {
  Nao = 'Não',
  Sim = 'Sim'
}

export interface RelatorioHistoricoEscolarFiltros {
  nomeAluno?: string
  cursosId?: string[]
  turmasId?: string[]
  nomeModulo?: string
  modalidade?: ModalidadeCurso
  situacoes?: SituacaoFinanceiraFiltro[]
  dataInicioMatricula?: Date
  dataFimMatricula?: Date
  dataInicioConclusao?: string
  dataFimConclusao?: string
}

export interface RelatorioHistoricoEscolarResponse {
  nomeAluno?: string
  emailAluno?: string
  cpf?: string
  // eslint-disable-next-line
  docIdentidade_OrgaoEmissor?: string
  celular?: string
  curso?: string
  turma?: string
  modalidade?: ModalidadeCurso
  situacao?: SituacaoFinanceira
  dataMatricula?: Date
  dataInicio?: Date
  dataConclusao?: Date
  prorrogacaoMatricula?: string
  dataPrimeiraVisualizacao?: Date
  dataUltimoAcesso?: Date
  progressoCurso?: string
  aprovadoCurso?: string
  dataAprovacao?: Date
  dataReprovacao?: Date
  modoAvaliacao?: ModoAvaliacao
  moduloOrdemNome?: string
  disciplinaOrdemNome?: string
  nota?: number
  novaPorAproveitamento?: string
  tipoAvaliacao?: string
  nomeAvaliacao?: string
  tentativasRestantes?: number
  totalTentativas?: number
  professoresTCC?: string
}

export interface RelatorioFinalizacaoFiltros {
  nomeAluno?: string
  cursosId?: string[]
  turmasId?: string[]
  dataInicioMatricula?: Date
  dataFimMatricula?: Date
  dataInicioRecuperacao?: Date
  dataFimRecuperacao?: Date
  situacoes?: SituacaoFinanceiraFiltro[]
  dataInicioOpcaoTcc?: Date
  dataFimOpcaoTcc?: Date
  opcaoTcc?: string
  dataInicioConclusao?: Date
  dataFimConclusao?: Date
}

export interface RelatorioFinalizacaoResponse {
  nomeAluno?: string
  emailAluno?: string
  cpf?: string
  turma?: string
  modalidade?: ModalidadeCurso
  situacao?: SituacaoFinanceira
  tipoMatricula?: TipoMatriculaPorExtenso
  dataColacaoGrau?: Date
  tipoAluno?: string
  dataMatricula?: Date
  dataInicio?: Date
  dataConclusao?: Date
  nacionalidade?: NacionalidadePorExtenso
  documentosAguardandoAnalise?: string
  documentosAprovados?: string
  documentosRecusados?: string
  documentosPendentesEnvio?: string
  notas?: string
  disciplinasAproveitadas?: string
  prorrogacaoMatricula?: string
  inicioPeriodoInscricaoTCC?: string
  fimPeriodoInscricaoTCC?: string
  opcaoTCC?: OpcaoTCC
  dataOpcaoTCC?: string
  inicioOrientacaoTCC?: Date
  negativadoFinanceiro?: string
}

export interface RelatorioEnvioNotasTCCFiltros {
  nomeAluno?: string
  cursosId?: string[]
  turmasId?: string[]
  situacoes?: SituacaoFinanceiraFiltro[]
  dataInicioInscricaoTCC?: Date
  dataFimInscricaoTCC?: Date
  dataInicioEnvio?: Date
  dataFimEnvio?: Date
}

export interface RelatorioEnvioNotasTCCResponse {
  nomeAluno?: string
  email?: string
  celular?: string
  turma?: string
  situacao?: SituacaoFinanceira
  dataInscricaoTCC?: Date
  dataInicioOrientacao?: Date
  mediaFinal?: number
  dataEnvioAvaliacao?: Date
  professorTCC?: string
  tipoProfessor?: TipoProfessorModuloTCC
  notaProfessor?: number
  feedBackProfessor?: string
}

export interface RelatorioRecorrenciaFiltros {
  inicioPagamento?: Date
  fimPagamento?: Date
  inicioVencimento?: Date
  fimVencimento?: Date
  formaPagamento?: null
  modalidade?: null
  turmaId?: string[]
  cursoId?: string[]
  statusRecorrencia?: string[]
  situacaoCobranca?: Array<SituacaoParcelaFiltro>
  situacaoFinanceira?: Array<SituacaoFinanceiraFiltro>
  tipoRecorrencia?: Array<TipoRecorrencia>
  nome?: string
  inicioMatricula?: Date
  fimMatricula?: Date
}

export interface RelatorioRecorrenciaResponse {
  celular?: string
  cpf?: string
  curso?: string
  dataMatricula?: Date
  dataPagamento?: Date
  dataVencimento?: Date
  email?: string
  formaPagamento?: MetodosPagamento
  idRecorrencia?: string
  modalidade?: ModalidadeCurso
  motivoTentativas?: string
  nomeAluno?: string
  observacao?: string
  observacaoInterna?: string
  quantidadeTentativas?: number
  situacaoCobranca?: SituacaoParcela
  situacaoFinanceira?: SituacaoFinanceira
  statusRecorrencia?: StatusRecorrencia
  tid?: string
  tipoRecorrencia?: TipoRecorrencia
  turma?: string
  valorEmAberto?: number
  valorPago?: number
}

export interface RelatorioFinanceiroGeralFiltros {
  modalidade?: null
  turmaId?: string[]
  cursoId?: string[]
  situacaoFinanceira?: Array<SituacaoFinanceiraFiltro>
  nome?: string
  inicioMatricula?: Date
  fimMatricula?: Date
  statusFinanceiro?: Array<StatusFinanceiro>
  pagamentoRecorrente?: boolean
  anosFaturamento?: Array<number>
}

export interface RelatorioFinanceiroGeralResponse {
  nomeAluno?: string
  cpf?: string
  email?: string
  celular?: string
  endereco?: string
  turma?: string
  modalidade?: ModalidadeCurso
  situacaoFinanceira?: SituacaoFinanceira
  dataFinalizacaoMatricula?: Date
  dataUltimoAcessoPortal?: Date
  pagamentoRecorrente?: string
  statusFinanceiro?: StatusFinanceiro
  planoSelecionadoMatricula?: string
  descontoCupomMatricula?: string
  descontoCupomPlanoSelecionadoMatricula?: string
  valorParcelaTaxaMatricula?: string
  valorParcelaTaxaMatriculaComDesconto?: string
  valorParcelaCurso?: string
  valorParcelaCursoComDesconto?: string
  valorParcelaTCC?: string
  valorParcelaTCCComDesconto?: string
  valorParcelaProrrogacao?: string
  valorParcelaProrrogacaoMatriculaComDesconto?: string
  qtdCobrancas?: number
  qtdCobrancasPagas?: number
  qtdCobrancasNaoPagas?: number
  totalPago?: string
  totalAVencer?: string
  totalDevido?: string
}

export interface RelatorioNotasFiltros {
  nome?: string
  cursoId?: string[]
  turmaId?: string[]
  cpf?: string
  situacaoFinanceira?: SituacaoFinanceiraFiltro[]
  tipoMatricula?: TipoMatriculaPorExtenso[]
  dataMatriculaInicio?: Date
  dataMatriculaFim?: Date
  statusMatricula?: SituacaoFinanceiraFiltro[]
  dataInicioInicio?: Date
  dataInicioFim?: Date
  dataFimInicio?: Date
  dataFimFim?: Date
}

export interface RelatorioNotasResponse {
  acertou?: string
  alternativa?: string
  celular?: string
  correta?: string
  cpf?: string
  curso?: string
  dataFim?: string
  dataInicio?: string
  dataMatricula?: string
  dataPrimeiraVisualizacao?: string
  dataSituacaoFinanceiraMatricula?: string
  dataTentativa?: string
  dataUltimoAcessoPortal?: string
  dataUltimoAcessoSala?: string
  explicacao?: string
  marcou?: string
  modalidade?: string
  modoAvaliacao?: ModalidadeCurso
  nomeAluno?: string
  nomeDisciplina?: string
  nomeModulo?: string
  novaDataFinal?: string
  pergunta?: string
  possuiProrrogacao?: string
  progresso?: number
  situacaoMatricula?: SituacaoFinanceira
  statusMatricula?: SituacaoFinanceira
  tentativaMaisRecente?: string
  tentativaNumero?: string
  tentativasRealizadas?: string
  tipoMatricula?: string
  totalTentativas?: string
  turma?: string
}

export interface RelatorioPesquisaSatisfacaoFiltros {
  tipoPesquisa?: TipoPesquisaSatisfacao
  codigoPesquisa?: string
  dataInicioResposta?: Date
  dataFimResposta?: Date
  dataInicioPesquisaInstitucional?: Date
  dataFimPesquisaInstitucional?: Date
  turma?: string[]
  curso?: string[]
  areaCurso?: string
  modalidade?: Array<ModalidadeCurso>
  professorId?: string
}

export interface RelatorioPesquisaSatisfacaoResponse {
  codigoCursoTurma?: string
  codigoPesquisa?: string
  codigoTodasTurmaAtivas?: string
  dataFimPesquisa?: string
  dataInicioPesquisa?: string
  dataResposta?: string
  modalidadePesquisa?: ModalidadeCurso
  nomeAluno?: string
  nomeCursoTurma?: string
  nomePesquisa?: string
  ordemNomeModulo?: string
  pergunta?: string
  resposta?: string
  tipoPesquisa?: TipoPesquisaSatisfacao
  tipoResposta?: string
}
