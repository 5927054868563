import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  AutenticacaoContainer,
  Botao,
  CheckboxUnform,
  FormUnform,
  ModalBase,
  NumeroInputUnform,
  TextAreaUnform,
  Visao
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
import { ModalidadeCurso } from 'src/tipos'

import {
  Dados,
  ModalNotaDisciplinaProps,
  ModalNotaDisciplinaRef,
  NotaDisciplinaProps,
  schemaPresencial
} from '.'
import { TipoArquivo } from '../../tipos'
import { dataFormatadaComBarras } from '../turma-presencial'
import {
  ContainerAcoes,
  ContainerModal,
  CorpoModal,
  ContainerDadosGrande,
  ContainerInformacoes,
  ContainerCheckbox
} from './styles'

const ModalDisciplina: React.ForwardRefRenderFunction<
  ModalNotaDisciplinaRef,
  ModalNotaDisciplinaProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [carregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [pronto, definirPronto] = useState(false)
  const [informacoes, definirInformacoes] = useState<NotaDisciplinaProps>({
    avaliacaoId: null,
    nome: '',
    disciplina: ''
  })
  const [encontro, definirEncontro] = useState(null)
  const ehPresencial =
    informacoes.modalidade === ModalidadeCurso.PosGraduacaoPresencial ||
    informacoes.modalidade === ModalidadeCurso.Graduacao
  const { visao } = AutenticacaoContainer.useContainer()
  const visaoIPGS = useMemo(() => visao === Visao.IPGS, [visao])

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }
  const avaliacaoUpload = informacoes.tipoAvaliacao === TipoArquivo.Upload

  const abrir = (dados: NotaDisciplinaProps) => {
    definirInformacoes(dados)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalNotaDisciplinaRef, ModalNotaDisciplinaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const dadosIniciais = useMemo(() => {
    return {
      nota: informacoes.nota,
      quantidadeTentativas: informacoes.tentativas,
      feedback: informacoes.feedback,
      observacao: informacoes.observacao
    }
  }, [informacoes])

  const acaoSucessoForm = async (dados: Dados) => {
    if (ehPresencial) {
      acaoPrimaria({
        avaliacaoId: dados.avaliacaoId,
        encontros: encontro,
        feedback: dados.feedback,
        nota: dados.nota
      })
    } else {
      acaoPrimaria(dados)
    }
    fechar()
  }
  return (
    <ModalBase id="modal-nota-disciplina" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          {pronto && (
            <FormUnform
              acaoSucesso={acaoSucessoForm}
              ref={formRef}
              dadosIniciais={dadosIniciais}
              schema={schemaPresencial}
            >
              <h5>Nota da Disciplina e Presença</h5>
              <ContainerInformacoes>
                <h4>
                  {'Aluno(a): '}
                  {informacoes.nome}
                </h4>
                <h4>Disciplina: {informacoes.disciplina}</h4>
              </ContainerInformacoes>
              <ContainerDadosGrande>
                <NumeroInputUnform
                  id="ipt_tentativas_restantes"
                  name="quantidadeTentativas"
                  label="Tentativas Restantes"
                  disabled={!visaoIPGS}
                  isDecimal
                />
              </ContainerDadosGrande>
              <ContainerDadosGrande>
                <NumeroInputUnform
                  id="ipt_nota"
                  name="nota"
                  label={
                    avaliacaoUpload
                      ? 'Informe a nota da avaliação (de 0 a 10)'
                      : 'Nota na Avaliação Optativa'
                  }
                  isDecimal
                />
              </ContainerDadosGrande>
              {avaliacaoUpload && (
                <ContainerDadosGrande>
                  <TextAreaUnform
                    id="ipt_feedback"
                    name="feedback"
                    label="Preencha o feedback da avaliação"
                  />
                </ContainerDadosGrande>
              )}
              <ContainerDadosGrande>
                <TextAreaUnform
                  id="ipt_observacao"
                  name="observacao"
                  label="Observação"
                  disabled={!visaoIPGS}
                />
              </ContainerDadosGrande>
              {ehPresencial && (
                <>
                  <CampoLabel>
                    Marque os encontros em que o aluno esteve presente
                  </CampoLabel>
                  <ContainerCheckbox>
                    {informacoes.encontros.map(m => (
                      <div key={m.dataEncontro}>
                        <CampoLabel>
                          {dataFormatadaComBarras(new Date(m.dataEncontro))}
                        </CampoLabel>
                        <CheckboxUnform
                          id={`data-${m.dataEncontro}`}
                          name={`data-${m.dataEncontro}`}
                          checked={m.presenca}
                          onChange={() => {
                            definirEncontro([
                              {
                                dataEncontro: m.dataEncontro,
                                presente: !m.presenca
                              }
                            ])
                            m.presenca = !m.presenca
                          }}
                        />
                      </div>
                    ))}
                  </ContainerCheckbox>
                </>
              )}
              <ContainerAcoes>
                <Botao
                  texto="Cancelar"
                  tema="Secundario"
                  type="button"
                  disabled={carregando}
                  onClick={() => fechar()}
                />
                <Botao texto="Salvar" carregando={carregando} />
              </ContainerAcoes>
            </FormUnform>
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalNotaDisciplina = forwardRef(ModalDisciplina)
