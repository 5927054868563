import React, { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useControleDeRequisicao } from 'src/compartilhados'
import {
  AutenticacaoContainer,
  SaudacoesDoMural,
  AgendaDoMural,
  TipoDaAgendaDoMural,
  OcultarFuncionalidade,
  useBarraSuperior,
  NotificacoesContainer,
  Notificacao
} from 'src/componentes'
import { Api } from 'src/servicos'
import { PainelMatriculasDoAluno } from 'src/tipos'

import { Cursos } from './components'
import { agendaMock } from './mocks'
import { Container, Conteudo } from './styles'

export const PaginaInicialAluno: React.FC = () => {
  const { usuario } = AutenticacaoContainer.useContainer()
  const barraSuperiora = useBarraSuperior()
  const notificacoes = NotificacoesContainer.useContainer()

  const requisicao = useControleDeRequisicao<PainelMatriculasDoAluno, string>()

  const adicionarNotificacao = (
    documentosPendentes: boolean,
    atualizacaoNotaTCC: boolean
  ): void => {
    const novasNotificacoes: Notificacao[] = []

    if (documentosPendentes) {
      novasNotificacoes.push({
        titulo: 'Documentos pendentes',
        data: new Date(),
        descricao: 'Você possui documentos pendentes.',
        lida: false
      } as Notificacao)
    }

    if (atualizacaoNotaTCC) {
      novasNotificacoes.push({
        titulo: 'Atualização nota TCC',
        data: new Date(),
        descricao: 'Você possui atualizações na nota do TCC.',
        lida: false
      } as Notificacao)
    }
    notificacoes.atualizar(novasNotificacoes)
  }

  const obterPainelInicialDoAluno = async () => {
    try {
      requisicao.carregador.alterarParaIniciado()
      const resposta = await Api.ObterPainelInicialDoAluno(usuario.id)
      requisicao.resposta.adicionarResposta({
        matriculas: resposta.painelAluno.matriculas,
        carrinhosCompra: resposta.painelAluno.carrinhosCompra
      })
      adicionarNotificacao(
        resposta.existeDocumentoPendente,
        resposta.existeAtualizacaoNotaTCC
      )
    } catch (erro) {
      requisicao.erros.adicionarErro(erro)
    } finally {
      requisicao.carregador.alterarParaConcluido()
    }
  }

  const obterFotoUsuario = async () => {
    try {
      const foto = await Api.ObterFotoUsuario(usuario.id)
      if (foto) {
        barraSuperiora.atualizarFoto(foto)
      }
    } catch (erro) {
      toast('Erro ao carregar foto do usuário', { type: 'info' })
    }
  }

  const configDaAgenda = {
    eventos: agendaMock,
    configuracao: {
      tipo: TipoDaAgendaDoMural.Compacto
    }
  }

  useEffect(() => {
    obterPainelInicialDoAluno()
  }, [])

  useEffect(() => {
    obterFotoUsuario()
  }, [])

  return (
    <Container>
      <SaudacoesDoMural usuario={usuario} />
      <Conteudo>
        <Cursos
          controleDeRequisicao={requisicao}
          acaoAtualizacao={obterPainelInicialDoAluno}
        />
        <OcultarFuncionalidade>
          <AgendaDoMural {...configDaAgenda} />
        </OcultarFuncionalidade>
      </Conteudo>
    </Container>
  )
}
