import React, { FC, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { toast, ToastOptions } from 'react-toastify'

import {
  ResumoDeDocumentosDeEntradaMapper,
  ResumoDeDocumentosDeSaidaMapper,
  ResumoDeDocumentosAnalisados,
  UploadDeDocumentos,
  UploadDocumentos,
  useRelacaoDeDocumentos,
  selecaoDeDocumentosParaEnvio,
  RelacaoDocumentosProfessorBrasileiro
} from 'src/compartilhados'
import {
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  formatarCpf,
  IconeBack,
  Link,
  Modal,
  ModalRef
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { RotasAcademico } from 'src/rotas/academico'
import { Api } from 'src/servicos'
import { SituacaoUpload } from 'src/tipos'
import { Usuario } from 'src/tipos/usuario'

import {
  ModalMotivoRejeicao,
  ModalVisualizacaoDocumento,
  ModalVisualizacaoDocumentoRef
} from './componentes'
import { ModalMotivoRejeicaoDados } from './componentes/modal-motivo-rejeicao/modal-motivo-rejeicao.component'
import {
  BotaoDeSalvar,
  ContainerBreadcrumbs,
  ContainerDaPagina,
  ContainerDosBotoes,
  ContainerLink,
  DescricaoContainer,
  ParagrafoDaNacionalidade,
  TituloComplementar
} from './style'
import { RejeicaoDocumento } from './tipos'

export type DocumentosProfessorBrasileiroProps = RouteComponentProps<{
  email: string
}>

const DocumentosProfessorBrasileiro: FC<DocumentosProfessorBrasileiroProps> = ({
  match
}) => {
  const history = useHistory()
  const { documentos, atualizar, reiniciar } = useRelacaoDeDocumentos(
    RelacaoDocumentosProfessorBrasileiro
  )

  const [pronto, definirPronto] = useState<boolean>()
  const [carregando, definirCarregando] = useState<boolean>()
  const [dadosProfessor, definirDadosProfessor] = useState<Usuario>()
  const [
    rejeicaoDeDocumento,
    definirRejeicaoDeDocumento
  ] = useState<RejeicaoDocumento>()

  const modalDeCancelamentoRef = useRef<ModalRef>(null)
  const modalMotivoRejeicaoRef = useRef<ModalRef>(null)
  const modalVisualizacaoRef = useRef<ModalVisualizacaoDocumentoRef>(null)
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const obterProfessorPorEmail = async (email: string): Promise<Usuario> => {
    try {
      return await Api.RequisitarUsuarioPorEmail(email)
    } catch (erro) {
      throw new Error('Erro ao obter os dados do professor.')
    }
  }

  const obterResumoDocumentosProfessor = async (idUsuario: string) => {
    try {
      const resposta = await Api.ObterResumoDocumentosProfessor(idUsuario)
      const resultado = ResumoDeDocumentosDeEntradaMapper(documentos, resposta)
      reiniciar(resultado)
    } catch (erro) {
      throw new Error('Erro ao obter os documentos do professor.')
    }
  }

  const salvarDocumentosProfessor = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EnviarDocumentosProfessor(
        dadosProfessor.id,
        formulario
      )

      if (resposta) {
        history.push(RotasAcademico.Professores)
      } else {
        toast('Erro ao salvar documento(s) do professor.', {
          type: 'error'
        } as ToastOptions)
      }
    } catch (erro) {
      throw new Error('Erro ao enviar documento(s) do professor.')
    }
  }

  const editarDocumentosProfessor = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EditarDocumentoProfessor(
        dadosProfessor.id,
        formulario
      )

      if (resposta) {
        history.push(RotasAcademico.Professores)
      } else {
        toast('Erro ao atualizar documento(s) do professor.', {
          type: 'error'
        } as ToastOptions)
      }
    } catch (erro) {
      throw new Error('Erro ao atualizar documento(s) do professor.')
    }
  }

  const envioAnaliseDocumentoProfessor = async (
    analises: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const resultado = ResumoDeDocumentosAnalisados(analises)

      if (resultado && resultado.documentos.length > 0) {
        const resposta = await Api.AprovarDocumentoProfessor(resultado)

        if (resposta) {
          history.push(RotasAcademico.Professores)
        } else {
          toast('Erro ao enviar análise do documento.', {
            type: 'error'
          } as ToastOptions)
        }
      }
    } catch (erro) {
      throw new Error('Erro ao enviar análise do documento.')
    }
  }

  const uploadDocumentosProfessor = async (
    edicoes: UploadDocumentos[],
    criacoes: UploadDocumentos[],
    analises: UploadDocumentos[]
  ): Promise<void> => {
    try {
      definirCarregando(true)
      if (edicoes.length > 0) await editarDocumentosProfessor(edicoes)
      if (criacoes.length > 0) await salvarDocumentosProfessor(criacoes)
      if (analises.length > 0) await envioAnaliseDocumentoProfessor(analises)
    } catch (erro) {
      toast('Erro ao processar documento(s) do professor.', {
        type: 'error'
      } as ToastOptions)
    } finally {
      definirCarregando(false)
    }
  }

  const eventoUploadDocumentosProfessor = async (): Promise<void> => {
    const {
      edicoes,
      criacoes,
      analises,
      contador
    } = selecaoDeDocumentosParaEnvio(documentos)

    if (contador === 0) history.push(RotasAcademico.Professores)
    else await uploadDocumentosProfessor(edicoes, criacoes, analises)
  }

  const obterDownloadDocumentoProfessor = async (
    documentoId: string
  ): Promise<void> => {
    try {
      await Api.ObterDownloadDocumentoProfessor(dadosProfessor.id, documentoId)
    } catch (erro) {
      toast('Erro ao baixar documento.', { type: 'error' } as ToastOptions)
    }
  }

  const visualizarDocumento = async (documentoId: string) => {
    try {
      const documento = await Api.ObterArquivoDocumentoProfessor(
        dadosProfessor.id,
        documentoId
      )
      if (documento) {
        modalVisualizacaoRef?.current?.abrir(documento)
      }
    } catch (error) {
      toast('Erro ao obter documento.', { type: 'error' } as ToastOptions)
    }
  }

  const obterDocumentacao = async (): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const usuario = await obterProfessorPorEmail(match.params.email)

      if (usuario) {
        definirDadosProfessor(usuario)
        await obterResumoDocumentosProfessor(usuario.id)
      } else {
        throw new Error('Erro ao carregar dados.')
      }
    } catch (error) {
      const mensagens = 'Erro ao carregar dados.'
      const acaoVoltar = () => history.push(RotasAcademico.Alunos)
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const aprovacao = (id: string): void => {
    const documento = documentos.find(documento => documento.id === id)
    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Aprovado
    } as UploadDocumentos

    atualizar(modificacao)
  }

  const rejeicao = (id: string): void => {
    definirRejeicaoDeDocumento({ id })
    modalMotivoRejeicaoRef?.current?.abrir()
  }

  const obterMotivoDaRejeicao = (dados: ModalMotivoRejeicaoDados): void => {
    const documento = documentos.find(
      documento => documento.id === rejeicaoDeDocumento.id
    )

    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Recusado,
      motivoDaRejeicao: dados.motivoDaRejeicao
    } as UploadDocumentos

    atualizar(modificacao)
    definirRejeicaoDeDocumento(null)
  }

  useEffect(() => {
    obterDocumentacao()
  }, [])

  return pronto && !erro ? (
    <ContainerDaPagina>
      <ContainerLink>
        <Link
          texto="Voltar"
          href={RotasAcademico.Professores}
          icone={IconeBack}
        />
      </ContainerLink>
      <ContainerBreadcrumbs>
        <Breadcrumb
          titulo="Documentos do Professor"
          atalhos={[
            {
              texto: 'Acadêmico',
              acao: () => history.goBack()
            },
            {
              texto: 'Professores',
              acao: () => history.goBack()
            },
            {
              texto: 'Documentos do Professor'
            }
          ]}
        />
      </ContainerBreadcrumbs>
      <TituloComplementar>Documentos do Professor</TituloComplementar>
      <DescricaoContainer>
        <p>Nome: {dadosProfessor.nomeApresentacao}</p>
        <ParagrafoDaNacionalidade>
          Nacionalidade: {dadosProfessor.nacionalidade}
        </ParagrafoDaNacionalidade>
        <p>CPF: {formatarCpf(dadosProfessor.cpf)}</p>
        <p>Documento de Identidade: {dadosProfessor.documentoIdentidade}</p>
        <p>E-mail: {dadosProfessor.email}</p>
      </DescricaoContainer>
      {documentos
        .sort((doc1, doc2) => doc1.ordemExibicao - doc2.ordemExibicao)
        .map(documento => (
          <UploadDeDocumentos
            key={documento.id}
            documento={documento}
            eventoDeMudanca={atualizar}
            eventoDeDownload={obterDownloadDocumentoProfessor}
            eventoDeVisualizacao={visualizarDocumento}
            controleDeAprovacao={{
              ativo: true,
              eventoDeAprovacao: aprovacao,
              eventoDeRejeicao: rejeicao
            }}
          />
        ))}
      <ContainerDosBotoes>
        <Botao
          texto="Cancelar"
          tema="Secundario"
          type="button"
          onClick={() => modalDeCancelamentoRef?.current?.abrir()}
        />
        <BotaoDeSalvar
          texto="Salvar"
          type="button"
          onClick={eventoUploadDocumentosProfessor}
          carregando={carregando}
        />
      </ContainerDosBotoes>
      <Modal
        ref={modalDeCancelamentoRef}
        backdrop
        id="modal-de-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.push(RotasAcademico.Professores)
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamentoRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
      <Modal
        ref={modalDeCancelamentoRef}
        backdrop
        id="modal-de-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.push(RotasAcademico.Professores)
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamentoRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
      <ModalMotivoRejeicao
        ref={modalMotivoRejeicaoRef}
        backdrop
        id="modal-motivo-rejeicao"
        acaoPrimaria={obterMotivoDaRejeicao}
      />
      <ModalVisualizacaoDocumento
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento-aluno"
      />
    </ContainerDaPagina>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados do professor..." />
  ) : (
    <ErroContainerLayout />
  )
}

const Container: FC<DocumentosProfessorBrasileiroProps> = (
  props: DocumentosProfessorBrasileiroProps
) => (
  <ErroLayoutContainer.Provider>
    <DocumentosProfessorBrasileiro {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
