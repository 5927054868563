import { Cores, Medias } from 'src/componentes/styles'
import styled from 'styled-components'

export const IconeLabel = styled.div`
  position: absolute;
  left: 90%;
  top: 0;

  @media ${Medias.MOBILE} {
    left: 95%;
  }

  svg {
    min-width: 12px;
    min-height: 12px;
  }
`

export const CorpoModal = styled.div`
  width: 471px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media ${Medias.MOBILE} {
    width: 90%;
  }

  @media ${Medias.MOBILE} {
    height: 600px;
  }

  &:first-child {
    margin-top: 20px;

    @media ${Medias.MOBILE} {
      margin-top: 0px;
    }
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;

    @media ${Medias.MOBILE} {
      font-size: 20px;
    }
  }

  form {
    width: 100%;
  }
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;

  @media ${Medias.MOBILE} {
    min-width: 90%;
  }

  p {
    font-size: 15px;
    text-align: center;
    padding-bottom: 10px;
  }
`

export const ContainerDadosMedio = styled.div`
  width: 50%;

  @media ${Medias.MOBILE} {
    min-width: 100%;
  }

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;

  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
  }
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
  }

  & > button:last-child {
    margin-left: 20px;
    @media ${Medias.MOBILE} {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  button {
    @media ${Medias.MOBILE} {
      width: 100%;
    }
  }
`
