import { Breakpoints } from 'src/componentes/styles/breakpoints'
import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

export const ContainerPDFViewer = styled.div`
  margin: 28px 0px 36px;

  @media (max-width: ${Breakpoints.MOBILE}px) {
    margin: 10px 0;
  }
`
export const ContainerCheckbox = styled.div`
  margin-bottom: 27px;

  label,
  a {
    font-family: 'Saira', sans-serif;
    font-size: 14px;
    line-height: 100%;

    cursor: pointer;
  }

  label {
    color: ${Cores.CINZA_2};
  }
`
