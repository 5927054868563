import { Cores } from 'src/componentes/styles'
import styled from 'styled-components'

export const Container = styled.div`
  & > div {
    display: flex;

    border-top: 1px solid ${Cores.CINZA_3_CLARO};
    margin: 0px 16px 0px 20px;
    padding: 16px 0px;

    :last-child {
      border-bottom: 1px solid ${Cores.CINZA_3_CLARO};
    }
  }
`

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;

  & > h4 {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2bb8d3;
    margin-bottom: 3px;
  }
`

export const ContainarStatus = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 9px;

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.02em;
    color: ${Cores.CINZA_2_MAIS_ESCURO};

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:not(:first-child) {
      margin-left: 6px;
    }
  }
`

export const TextoComentario = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 140.62%;
  display: flex;
  align-items: center;
  color: ${Cores.PRETO};

  ul li {
    list-style: square;
  }

  ol li {
    list-style: decimal;
  }
`

export const ContainerChildren = styled.div`
  & > div {
    display: flex;
    flex: 1;
    margin-top: 16px;
    padding: 16px 0 0;
    border-top: 1px solid ${Cores.CINZA_3_CLARO};
  }
`
export const ContainerDownloadArquivo = styled.div`
  padding-top: 5px;

  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: ${Cores.CINZA_2};
    }

    &:hover {
      background: ${Cores.CINZA_3_MAIS_CLARO};

      svg {
        fill: ${Cores.SECUNDARIA_1};

        path {
          fill: ${Cores.SECUNDARIA_1};
        }
      }
    }
  }
`
export const TituloComentario = styled.h4`
  display: flex;
`
export const ContainerResponder = styled.div`
  margin-top: 15px;
  border-top: 1px solid ${Cores.CINZA_3_CLARO};
  padding: 16px 0 0;
  display: flex;
  align-items: center;

  & > form {
    width: 100%;
  }
`
export const Linha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
  & > .textarea-container {
    margin-bottom: 0px;
    width: 100%;
  }
`
export const ImagemUsuario = styled.div`
  margin-right: 7px;

  & > img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`

export const TextoResponder = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 20px;
  color: ${Cores.SECUNDARIA_2_ESCURO};
  cursor: pointer;
`
