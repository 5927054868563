import React from 'react'

import { IconeCarregando, IconeSetaStep } from '../icones'
import { Tooltip } from '../tooltip'
import {
  Container,
  ContainerTexto,
  ContainerIcone,
  IconeLoader
} from './styles'
import { BotaoStepProps } from './tipos'

export const BotaoStep: React.FC<BotaoStepProps> = ({
  texto,
  textoNegrito,
  tema = 'Padrao',
  invertido,
  carregando,
  disabled,
  tamanho = 'M',
  textoTooltip,
  ...rest
}) => {
  return (
    <div
      data-for={textoTooltip ? 'tooltip-botao-step' : undefined}
      data-tip={textoTooltip}
    >
      <Container
        tema={tema}
        disabled={disabled}
        carregando={carregando}
        tamanho={tamanho}
        {...rest}
      >
        <IconeLoader>{IconeCarregando}</IconeLoader>
        <ContainerTexto invertido={invertido}>
          <p>{texto}</p>
          <strong>{textoNegrito}</strong>
        </ContainerTexto>
        <ContainerIcone invertido={invertido}>{IconeSetaStep}</ContainerIcone>
      </Container>
      {textoTooltip && <Tooltip id="tooltip-botao-step" />}
    </div>
  )
}
