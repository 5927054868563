import React, { FC } from 'react'

import {
  IconeDeStatusDeEnvioComSucesso,
  IconeDeStatusDeEnvioComAdvertencia,
  IconeDeStatusDeEnvioComErro,
  Cores,
  Informacao
} from 'src/componentes'
import { SituacaoUpload } from 'src/tipos/resumo-de-documentos'

import { Container, Icone, Status, TooltipComEstilo } from './style'

const DOCUMENTO_RECUSADO_ID = 'DOCUMENTO_RECUSADO_ID'

export interface StatusDeEnvioProps {
  id: number | string
  motivoDaRejeicao: string
  tipo: SituacaoUpload
}

export const StatusDeEnvio: FC<StatusDeEnvioProps> = ({
  id,
  motivoDaRejeicao,
  tipo
}) => {
  const tooltipId = `${DOCUMENTO_RECUSADO_ID}/${id}`

  switch (tipo) {
    case SituacaoUpload.PendenteEnvio:
      return (
        <Container>
          <Icone>{IconeDeStatusDeEnvioComAdvertencia}</Icone>
          <Status>Pendente de envio</Status>
        </Container>
      )
    case SituacaoUpload.AguardandoAnalise:
      return (
        <Container>
          <Icone>{IconeDeStatusDeEnvioComAdvertencia}</Icone>
          <Status>Aguardando análise</Status>
          {motivoDaRejeicao && (
            <>
              <div data-tip={motivoDaRejeicao} data-for={tooltipId}>
                <Informacao />
              </div>
              <TooltipComEstilo
                id={tooltipId}
                place={'top'}
                textColor={Cores.PRETO}
                backgroundColor={Cores.CINZA_1_CLARO}
              />
            </>
          )}
        </Container>
      )
    case SituacaoUpload.Recusado:
      return (
        <Container>
          <Icone>{IconeDeStatusDeEnvioComErro}</Icone>
          <Status>Documento recusado</Status>
          <div data-tip={motivoDaRejeicao} data-for={tooltipId}>
            <Informacao />
          </div>
          <TooltipComEstilo
            id={tooltipId}
            place={'top'}
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
          />
        </Container>
      )
    default:
      return (
        <Container>
          <Icone>{IconeDeStatusDeEnvioComSucesso}</Icone>
          <Status>Documento aceito</Status>
        </Container>
      )
  }
}
