import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#34ACAC" />
    <path
      d="M19.6803 8.09465V15.0269C19.6803 15.9862 18.9099 16.764 17.9596 16.764L12.0003 16.789L8.5094 19.3175V16.789H6.04098C5.09076 16.789 4.32031 16.0112 4.32031 15.0519V8.11967C4.32031 7.16037 5.09076 6.38257 6.04098 6.38257L17.9593 6.35754C18.9099 6.35754 19.6803 7.13535 19.6803 8.09465Z"
      fill="#006969"
    />
    <circle cx="8.60891" cy="11.5034" r="1.04348" fill="white" />
    <circle cx="12.0005" cy="11.5034" r="1.04348" fill="white" />
    <circle cx="15.3911" cy="11.5034" r="1.04348" fill="white" />
  </svg>
)
