import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  nome: Yup.string().required(mensagemPadrao),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required(mensagemPadrao),
  telefone: Yup.string().telefone().required('Informe um telefone válido'),
  cnpj: Yup.string().required(mensagemPadrao),
  repassInfo: Yup.array().of(
    Yup.object({
      repass: Yup.string().required('Informe um valor válido'),
      turmaId: Yup.string().required(mensagemPadrao)
    })
  ),
  banco: Yup.string().required(mensagemPadrao),
  tipoConta: Yup.string().required(mensagemPadrao),
  agencia: Yup.string().required(mensagemPadrao),
  conta: Yup.string().required(mensagemPadrao),
  endereco: Yup.object().shape({
    pais: Yup.string().required(mensagemPadrao),
    cep: Yup.string()
      .nullable()
      .when('pais', {
        is: 'Brasil',
        then: field => field.required(mensagemPadrao)
      }),
    logradouro: Yup.string().required(mensagemPadrao),
    numero: Yup.string().required(mensagemPadrao),
    bairro: Yup.string().required(mensagemPadrao),
    cidade: Yup.string().required(mensagemPadrao),
    uf: Yup.string().required(mensagemPadrao)
  })
})
