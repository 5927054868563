import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import {
  AutenticacaoContainer,
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  IconePagamentoMatricula,
  IconePlanoPagamento,
  IconeTermosCondicoes,
  Wizard
} from 'src/componentes'
import { RotasApp } from 'src/rotas'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { ModalidadeCursoPorExtenso } from 'src/tipos'
import { EtapaReingresso, SituacaoReingresso } from 'src/tipos/reingresso'

import ReingressoContainer from './container'
import { InformacoesReingresso } from './informacoes-reingresso'
import { ModalReingressoInadimplente } from './modal-inadimplente'
import { ModalReingressoInadimplenteRef } from './modal-inadimplente/tipos'
import { PagamentoReingresso } from './pagamento-taxa'
import { PlanoPagamento } from './plano-pagamento'
import { RegulamentoReingresso } from './regulamento-reingresso'
import { Cabecalho, Container, Icone } from './style'
import { TermosCondicoes } from './termos-condicoes'
import { PaginaReingressoProps } from './tipos'

const ComponenteMatricula: React.FC<Omit<PaginaReingressoProps, 'modo'>> = ({
  match
}) => {
  const { usuario } = AutenticacaoContainer.useContainer()
  const {
    dados,
    passo,
    definirPasso,
    definirTermosCondicoes,
    definirDados
  } = ReingressoContainer.useContainer()
  const [pronto, definirPronto] = useState(false)
  const {
    limparErro,
    definirErro,
    getApiError,
    erro
  } = ErroLayoutContainer.useContainer()
  const modalInadimplenteRef = useRef<ModalReingressoInadimplenteRef>(null)
  const history = useHistory()

  const rotaFinanceiro = useMemo(() => {
    return `${RotasAluno.FinanceiroCursoOutro}/${dados?.matriculaOrigem?.id}/${dados?.matriculaOrigem?.modalidade}/${dados?.matriculaOrigem?.nomeCurso}`
  }, [dados.matriculaOrigem])

  const listaWizarReingresso = useMemo(() => {
    return [
      {
        id: 'etapa-regulamento-reingresso',
        titulo: 'Regulamento do Reingresso',
        icone: <Icone>{IconePlanoPagamento}</Icone>,
        corpo: <RegulamentoReingresso />,
        desabilitado: !!dados?.matriculaDestino
      },
      {
        id: 'etapa-informacoes-reingresso',
        titulo: 'Informações do Reingresso',
        icone: <Icone>{IconeTermosCondicoes}</Icone>,
        corpo: <InformacoesReingresso />,
        desabilitado: !!dados?.matriculaDestino
      },
      {
        id: 'etapa-plano-pagamento',
        titulo: 'Plano de Pagamento',
        icone: <Icone>{IconePlanoPagamento}</Icone>,
        corpo: <PlanoPagamento />
      },
      {
        id: 'etapa-termos-condicoes',
        titulo: 'Termos e Condições',
        icone: <Icone>{IconeTermosCondicoes}</Icone>,
        corpo: <TermosCondicoes />
      },
      {
        id: 'etapa-pagamento-taxa-reingresso',
        titulo: 'Pagamento da Taxa de Reingresso',
        icone: <Icone>{IconePagamentoMatricula}</Icone>,
        corpo: <PagamentoReingresso />
      }
    ]
  }, [dados?.matriculaDestino])

  const carregarCurso = async () => {
    try {
      definirPronto(false)
      limparErro()
      const dados = await Api.RequisitarReingresso(match.params.id)

      const novoPasso = dados
        ? EtapaReingresso[dados.etapa]
        : EtapaReingresso.Regulamento

      if (novoPasso > EtapaReingresso.DadosPagamento) {
        throw new Error('Você já está matriculado nesse curso.')
      }

      definirPasso(novoPasso)
      definirDados(dados)
      definirTermosCondicoes({
        liAceitoTermos:
          EtapaReingresso[dados.etapa] > EtapaReingresso.TermosECondicoes,
        estouCiente:
          EtapaReingresso[dados.etapa] > EtapaReingresso.TermosECondicoes
      })

      if (
        dados.situacao === SituacaoReingresso.PendenciaFinanceira &&
        dados.protocolo
      ) {
        modalInadimplenteRef?.current?.abrir(dados.protocolo)
        return
      }
    } catch (error) {
      getApiError(error).then(result => {
        const mensagens = result.join('\n')

        definirErro({
          mensagens: `Ops! Houve algum problema no carregamento dos dados.\n${mensagens}`,
          acaoVoltar: () => history.push(RotasApp.Home)
        })
      })
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    carregarCurso()
  }, [usuario?.token, match.params.id])

  return (
    <>
      {pronto && !erro ? (
        <Container>
          <Cabecalho>
            <Breadcrumb
              titulo={`Reingresso de ${
                ModalidadeCursoPorExtenso[dados?.curso?.modalidade]
              }`}
              atalhos={[
                {
                  texto: 'Reingresso'
                }
              ]}
            />
          </Cabecalho>
          <Wizard
            passo={passo}
            listaWizard={listaWizarReingresso}
            name="wizard-reingresso"
          />
        </Container>
      ) : !pronto && !erro ? (
        <>
          <Carregando texto="Carregando reingresso ..." />
        </>
      ) : (
        <ErroContainerLayout />
      )}
      <ModalReingressoInadimplente
        ref={modalInadimplenteRef}
        backdrop
        acaoPrimaria={() => {
          history.push(rotaFinanceiro)
        }}
      />
    </>
  )
}

const Reingresso: React.FC<PaginaReingressoProps> = ({ modo, ...props }) => {
  return (
    <ReingressoContainer.Provider initialState={{ passo: 1, modo }}>
      <ErroLayoutContainer.Provider>
        <ComponenteMatricula {...props} />
      </ErroLayoutContainer.Provider>
    </ReingressoContainer.Provider>
  )
}

export default withRouter(Reingresso)
