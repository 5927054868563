import React, { useState, useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Cores,
  CpfInputUnform,
  DataInputUnform,
  ErroLayoutContainer,
  Filtro,
  InputUnform,
  SelectAsyncUnform,
  SelectUnform,
  Tooltip
} from 'src/componentes'
import { dataFormatadaComBarras } from 'src/componentes/funcoes/data-hora'
import { Tabela, TabelaRef, TabelaResposta } from 'src/componentes/tabela'
import { SelectOpcaoCursos } from 'src/dto'
import { Api } from 'src/servicos'
import { RequisitarListaTurmasPorTexto } from 'src/servicos/api'
import { TipoOrdenacao } from 'src/tipos'

import { COLUNAS_ENVIOS_NOTAS_TCC } from './componentes'
import { COLUNAS_EXPORTACAO_PDF } from './componentes/colunas_exportacao_pdf'
import {
  OPCOES_SITUACAO_FINANCEIRA,
  OPCOES_STATUS_MATRICULA,
  OPCOES_TIPO_MATRICULA
} from './opcoes'
import {
  Form,
  Container,
  Campo,
  ContainerExportar,
  Dados,
  CabecalhoRelatorioPdf,
  ContainerInfoCabecalhoExportacaoPdf,
  IconeLabel,
  ContainerExportacaoPdf,
  ConteudoExportacaoPdf,
  TituloExportacaoPdf
} from './styles'
import { DadosPesquisa } from './tipos'
export const PaginaNotas: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const tabelaRefPdf = useRef<TabelaRef>()
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const [paginas, definirPaginas] = useState<number>(null)
  const [carregando, definirCarregando] = useState(false)
  const [turmaSelecionada, definirTurmaSelecionada] = useState([])
  const [nomeAlunoFiltro, definirNomeAlunoFiltro] = useState('')
  const [bloquearBotaoExportar, definirBloquearBotaoExportar] = useState(true)
  const [bloquearBotaoExportarPdf, definirBloquearBotaoExportarPdf] = useState(
    true
  )
  const [ehUnicoAluno, definirEhUnicoAluno] = useState(false)
  const [dadosParaOCabecalhoPdf, definirDadosParaOCabecalhoPdf] = useState({
    nome: null,
    turma: null,
    curso: null
  })

  const exportacaoPdfRef = useRef()

  const acaoPrint = useReactToPrint({
    content: () => exportacaoPdfRef.current,
    documentTitle: 'Relatório-Notas'
  })

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }

    definirDadosPesquisa(dados)

    dados?.turmaId?.length > 0 || dados?.nome?.length > 0
      ? definirBloquearBotaoExportar(false)
      : definirBloquearBotaoExportar(true)

    dados?.turmaId?.length === 1 && dados?.nome?.length > 0
      ? definirBloquearBotaoExportarPdf(false)
      : definirBloquearBotaoExportarPdf(true)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    let dados = null

    if (turmaSelecionada.length > 0 || nomeAlunoFiltro.length > 0) {
      dados = await Api.ObterRelatorioNotas(
        {
          nome: dadosPesquisa?.nome,
          cursoId: dadosPesquisa?.cursoId,
          turmaId: dadosPesquisa?.turmaId,
          cpf: dadosPesquisa?.cpf,
          situacaoFinanceira: dadosPesquisa?.situacaoFinanceira,
          tipoMatricula: dadosPesquisa?.tipoMatricula,
          dataMatriculaInicio: dadosPesquisa?.dataMatriculaInicio,
          dataMatriculaFim: dadosPesquisa?.dataMatriculaFim,
          statusMatricula: dadosPesquisa?.statusMatricula,
          dataInicioInicio: dadosPesquisa?.dataInicioInicio,
          dataInicioFim: dadosPesquisa?.dataInicioFim,
          dataFimInicio: dadosPesquisa?.dataFimInicio,
          dataFimFim: dadosPesquisa?.dataFimFim
        },
        numeroPaginas,
        quantidadePorPagina,
        chaveOrdenacao,
        ordem
      )
      if (!dados) return null
      definirPaginas(null)
      return {
        Dados: dados.registros,
        Paginacao: {
          Pagina:
            dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
              ? 1
              : dados.opcoesPaginacao.pagina,
          TotalRegistros: dados.opcoesPaginacao.totalRegistros
        }
      } as TabelaResposta
    } else {
      toast(
        'Para visualizar os dados, é necessário adicionar o nome do aluno ou selecionar uma turma no filtro!',
        {
          type: 'error'
        }
      )
    }
  }

  const obterDadosPdf = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    quantidadePorPagina = 999
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    let dados = null

    if (turmaSelecionada.length === 1 && nomeAlunoFiltro.length > 0) {
      dados = await Api.ObterRelatorioNotas(
        {
          nome: dadosPesquisa?.nome,
          cursoId: dadosPesquisa?.cursoId,
          turmaId: dadosPesquisa?.turmaId,
          cpf: dadosPesquisa?.cpf,
          situacaoFinanceira: dadosPesquisa?.situacaoFinanceira,
          tipoMatricula: dadosPesquisa?.tipoMatricula,
          dataMatriculaInicio: dadosPesquisa?.dataMatriculaInicio,
          dataMatriculaFim: dadosPesquisa?.dataMatriculaFim,
          statusMatricula: dadosPesquisa?.statusMatricula,
          dataInicioInicio: dadosPesquisa?.dataInicioInicio,
          dataInicioFim: dadosPesquisa?.dataInicioFim,
          dataFimInicio: dadosPesquisa?.dataFimInicio,
          dataFimFim: dadosPesquisa?.dataFimFim
        },
        numeroPaginas,
        quantidadePorPagina,
        chaveOrdenacao,
        ordem
      )

      if (!dados) return null

      let conferirAluno = false

      if (dados?.registros[0]?.nomeAluno !== undefined) {
        conferirAluno = dados?.registros.every(
          e => e?.nomeAluno === dados?.registros[0]?.nomeAluno
        )
      }

      definirEhUnicoAluno(conferirAluno)

      definirDadosParaOCabecalhoPdf({
        nome: dados?.registros[0]?.nomeAluno,
        turma: dados?.registros[0]?.turma,
        curso: dados?.registros[0]?.nomeCurso
      })

      definirPaginas(null)

      return {
        Dados: dados.registros,
        Paginacao: {
          Pagina:
            dados.opcoesPaginacao.totalPaginas < dados.opcoesPaginacao.pagina
              ? 1
              : dados.opcoesPaginacao.pagina,
          TotalRegistros: dados.opcoesPaginacao.totalRegistros
        }
      } as TabelaResposta
    }
  }

  const downloadDados = async () => {
    try {
      definirCarregando(true)
      const download = await Api.ObterDownloadRelatorioNotas({
        nome: dadosPesquisa?.nome,
        cursoId: dadosPesquisa?.cursoId,
        turmaId: dadosPesquisa?.turmaId,
        cpf: dadosPesquisa?.cpf,
        situacaoFinanceira: dadosPesquisa?.situacaoFinanceira,
        tipoMatricula: dadosPesquisa?.tipoMatricula,
        dataMatriculaInicio: dadosPesquisa?.dataMatriculaInicio,
        dataMatriculaFim: dadosPesquisa?.dataMatriculaFim,
        statusMatricula: dadosPesquisa?.statusMatricula,
        dataInicioInicio: dadosPesquisa?.dataInicioInicio,
        dataInicioFim: dadosPesquisa?.dataInicioFim,
        dataFimInicio: dadosPesquisa?.dataFimInicio,
        dataFimFim: dadosPesquisa?.dataFimFim
      })
      if (download === false) {
        toast('Nenhum resultado encontrado com filtro atual!', {
          type: 'info'
        })
      }
    } catch (error) {
      toast('Erro ao efetuar download do arquivo!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }
  const requisitarListaCursosOutrosPorTextoFiltro = async (
    nome: string
  ): Promise<SelectOpcaoCursos[]> => {
    const result = await Api.RequisitarListaCursoOutros(nome)
    return result.map(x => ({ id: x.id, texto: x.nome }))
  }

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.CarregarDados()
    tabelaRefPdf.current.CarregarDados()
  }, [dadosPesquisa])

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Notas dos Alunos"
          atalhos={[
            {
              texto: 'Relatórios Acadêmicos'
            },
            {
              texto: 'Notas'
            }
          ]}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo mr>
              <InputUnform
                type="text"
                name="nome"
                label="Nome do Aluno"
                maxLength={200}
                onChange={e => definirNomeAlunoFiltro(e.target.value)}
              />
            </Campo>
            <Campo mr>
              <CpfInputUnform id="ipt_cpf" name="cpf" label="CPF" />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_situacao_financeira"
                name="situacaoFinanceira"
                label="Situação Matricula"
                iconeFechar
                multiplo
                opcoes={OPCOES_SITUACAO_FINANCEIRA}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_curso"
                name="cursoId"
                label="Curso"
                multiplo
                buscarPorTexto={requisitarListaCursosOutrosPorTextoFiltro}
                buscarPorId={requisitarListaCursosOutrosPorTextoFiltro}
              />
            </Campo>
            <Campo style={{ marginBottom: '25px' }}>
              <SelectAsyncUnform
                id="slct_turma"
                name="turmaId"
                label="Turma"
                multiplo
                valorAlteradoMulti={e => definirTurmaSelecionada(e)}
                buscarPorTexto={RequisitarListaTurmasPorTexto}
                buscarPorId={RequisitarListaTurmasPorTexto}
              />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform
                name="dataMatriculaInicio"
                label="Data Matrícula de"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform
                name="dataMatriculaFim"
                label="Data Matrícula até"
              />
            </Campo>
            <Campo mr>
              <DataInputUnform name="dataInicioInicio" label="Data Início de" />
            </Campo>
            <Campo>
              <DataInputUnform name="dataInicioFim" label="Data Início até" />
            </Campo>
          </Container>
          <Container>
            <Campo mr>
              <DataInputUnform name="dataFimInicio" label="Data Fim de" />
            </Campo>
            <Campo mr>
              <DataInputUnform name="dataFimFim" label="Data Fim até" />
            </Campo>
            <Campo mr>
              <SelectUnform
                id="slct_tipo_matricula"
                name="tipoMatricula"
                label="Tipo Matricula"
                iconeFechar
                multiplo
                opcoes={OPCOES_TIPO_MATRICULA}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="slct_status_matricula"
                name="statusMatricula"
                label="Status da Matrícula"
                iconeFechar
                multiplo
                opcoes={OPCOES_STATUS_MATRICULA}
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            disabled={
              turmaSelecionada.length === 0 && nomeAlunoFiltro.length === 0
            }
            tema="Link"
          />
          {turmaSelecionada.length === 0 && nomeAlunoFiltro.length === 0 && (
            <p style={{ color: 'red' }}>
              Para aplicar o filtro é necessário adicionar o nome do aluno ou
              selecionar uma turma.
            </p>
          )}
        </Form>
      </Filtro>
      <ContainerExportar>
        <IconeLabel
          data-tip={
            'É necessário adicionar pelo menos uma turma ou nome do aluno para exportar por Excel'
          }
          data-for="tooltip-bloqueio-botao-exportar"
        >
          <Botao
            style={{ color: 'white' }}
            type="button"
            id="btn-download-relatorio"
            texto="Exportar Excel"
            disabled={bloquearBotaoExportar}
            onClick={downloadDados}
            carregando={carregando}
          />
        </IconeLabel>

        <IconeLabel
          data-tip={
            'É necessário adicionar uma turma e o nome do aluno corretamente para exportar por PDF'
          }
          data-for="tooltip-bloqueio-pdf"
        >
          <Botao
            style={{ color: 'white' }}
            type="button"
            id="btn-download-relatorio-pdf"
            texto="Exportar PDF"
            disabled={bloquearBotaoExportarPdf || !ehUnicoAluno}
            onClick={acaoPrint}
          />
        </IconeLabel>
        {(bloquearBotaoExportarPdf || !ehUnicoAluno) && (
          <Tooltip
            id="tooltip-bloqueio-pdf"
            place="right"
            className="tooltip-ipgs"
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
          />
        )}
        {bloquearBotaoExportar && (
          <Tooltip
            id="tooltip-bloqueio-botao-exportar"
            place="right"
            className="tooltip-ipgs"
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
          />
        )}
      </ContainerExportar>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={COLUNAS_ENVIOS_NOTAS_TCC}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
      <ContainerExportacaoPdf ref={exportacaoPdfRef}>
        <ConteudoExportacaoPdf>
          <TituloExportacaoPdf>Relatório de Notas</TituloExportacaoPdf>
          <CabecalhoRelatorioPdf>
            <ContainerInfoCabecalhoExportacaoPdf>
              <p>
                Nome: <span>{dadosParaOCabecalhoPdf?.nome}</span>
              </p>
              <p>
                Curso: <span>{dadosParaOCabecalhoPdf?.curso}</span>
              </p>
            </ContainerInfoCabecalhoExportacaoPdf>
            <ContainerInfoCabecalhoExportacaoPdf>
              <p>
                Data Exportação: <span>{dataFormatadaComBarras()}</span>
              </p>
              <p>
                Turma: <span>{dadosParaOCabecalhoPdf?.turma}</span>
              </p>
            </ContainerInfoCabecalhoExportacaoPdf>
          </CabecalhoRelatorioPdf>
          <Tabela
            ref={tabelaRefPdf}
            Colunas={COLUNAS_EXPORTACAO_PDF}
            ObterDados={obterDadosPdf}
          />
        </ConteudoExportacaoPdf>
      </ContainerExportacaoPdf>
    </>
  )
}

export default (): JSX.Element => (
  <ErroLayoutContainer.Provider>
    <PaginaNotas />
  </ErroLayoutContainer.Provider>
)
