import styled, { css } from 'styled-components'

import { Cores } from '../styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Cabecalho = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  h2 {
    padding-left: 4px;
  }
`

export const Conteudo = styled.div``

export const Filtros = styled.div<{ aberto: boolean }>`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 0.5px solid ${Cores.PRIMARIA_1};
  border-top: 4px solid ${Cores.PRIMARIA_1};
  height: 4px;
  overflow: hidden;
  transition: height 0.2s ease-in;

  ${Conteudo} {
    display: none;
  }

  ${({ aberto }) =>
    aberto &&
    css`
      border-bottom: 2px solid ${Cores.PRIMARIA_1};
      padding: 28px 32px;
      height: auto;

      ${Conteudo} {
        display: block;
      }
    `}
`

export const Botao = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: ${Cores.PRIMARIA_1};
  padding: 15px 7px;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`

export const BotaoIcone = styled.i<{ aberto: boolean }>`
  margin-left: 2px;
  transition: all 0.2s ease-in;
  transform: rotate(-180deg);
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ aberto }) =>
    aberto &&
    css`
      transform: rotate(0deg);
    `}
`
