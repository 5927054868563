export enum GeneroOutros {
  NaoBinario = 'Não-Binário',
  GeneroFluido = 'Gênero Fluido',
  Agenero = 'Agênero',
  Bigenero = 'Bigênero',
  Androgino = 'Andrógino',
  Demigenero = 'Demigênero',
  Poligenero = 'Poligênero',
  Neutrois = 'Neutrois',
  Pangenero = 'Pangênero',
  Questionando = 'Questionando'
}

export enum GeneroPorExtenso {
  NaoBinario = 'NaoBinario',
  GeneroFluido = 'GeneroFluido',
  Agenero = 'Agenero',
  Bigenero = 'Bigenero',
  Androgino = 'Androgino',
  Demigenero = 'Demigenero',
  Poligenero = 'Poligenero',
  Neutrois = 'Neutrois',
  Pangenero = 'Pangenero',
  Questionando = 'Questionando'
}
