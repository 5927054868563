import { Cabecalho } from 'src/componentes/cabecalho'
import { Tabela } from 'src/componentes/tabela'
import { FormUnform } from 'src/componentes/unform'
import styled from 'styled-components'

export const ContainerIcone = styled.span`
  display: flex;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 10px;
    path {
      fill: ${props => props.color};
    }
  }
`

export const ContainerData = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  margin-left: 5px;
  margin-right: 10px;
`

export const ContainerIcones = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const ContainerLink = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const CabecalhoComEstilos = styled(Cabecalho)`
  flex-direction: column;
`

export const TabelaComEstilos = styled(Tabela)`
  & > table {
    table-layout: auto;
    white-space: normal;

    & > tbody > tr {
      & button:disabled {
        opacity: 0.3;
      }
    }
  }
`

export const FormStyled = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Voltar = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: #009b9b;
  width: 100%;
  line-height: 30px;
  font-size: 12px;
  font-weight: 400;
  align-items: center;

  & > svg {
    margin-right: 4px;
  }
`
