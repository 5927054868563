import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  ContainerAcoes,
  Filtro,
  IconeEditar,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  Tooltip,
  IconeDiscussao
} from 'src/componentes'
import {
  Container,
  Form,
  ContainerDados25,
  ContainerDados75,
  Dados
} from 'src/componentes/styles/filtro'
import { InputUnform, SelectUnform } from 'src/componentes/unform'
import { RotasAcademico, RotasCursos } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  TipoOrdenacao,
  DadosPesquisaCurso,
  CursoSituacao,
  ModalidadeCursoPorExtenso,
  validaEhModalidadeLivreOuEvento
} from 'src/tipos'

import { OPCOES_MODALIDADE, OPCOES_SITUACAO } from './opcoes-select'
import { TabelaComEstilos } from './styles'

export const PaginaCursos: React.FC = () => {
  const history = useHistory()
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisaCurso>(
    null
  )
  const tabelaRef = useRef<TabelaRef>()
  const [paginas, definirPaginas] = useState(null)

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.IrParaPagina()
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisaCurso) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }
    definirDadosPesquisa(dados)
  }

  const validarExibicaoGrupoDiscussao = (dados: TabelaDados) => {
    return (
      validaEhModalidadeLivreOuEvento(dados.modalidade) &&
      dados.temGrupoDiscussao
    )
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }
    const dados = await Api.RequisitarListaCursos(
      dadosPesquisa?.sigla,
      dadosPesquisa?.nome,
      dadosPesquisa?.modalidade,
      dadosPesquisa?.situacao,
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Cursos"
          atalhos={[
            {
              texto: 'Acadêmico'
            },
            {
              texto: 'Cursos'
            }
          ]}
        />
        <Botao
          type="button"
          texto="Incluir"
          tema="Secundario"
          onClick={() => history.push(RotasCursos.Cadastro)}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: CursoSituacao[CursoSituacao.Todos],
            modalidade: 'Todas'
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <ContainerDados25>
              <InputUnform type="text" name="sigla" label="Sigla" />
            </ContainerDados25>
            <ContainerDados75>
              <InputUnform type="text" name="nome" label="Nome do Curso" />
            </ContainerDados75>
          </Container>
          <Container>
            <ContainerDados25>
              <SelectUnform
                id="slct_situacao"
                name="situacao"
                label="Situação"
                placeholder="Selecione"
                iconeFechar={false}
                opcoes={OPCOES_SITUACAO}
              />
            </ContainerDados25>
            <ContainerDados75>
              <SelectUnform
                id="slct_modalidade"
                name="modalidade"
                label="Modalidade do Curso"
                placeholder="Selecione"
                opcoes={OPCOES_MODALIDADE}
              />
            </ContainerDados75>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <TabelaComEstilos
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'sigla',
              Texto: 'Sigla',
              ComOrdenacao: true
            },
            {
              Chave: 'nome',
              Texto: 'Nome',
              ComOrdenacao: true
            },
            {
              Chave: 'modalidade',
              Texto: 'Modalidade',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) =>
                ModalidadeCursoPorExtenso[linha.modalidade]
            },
            {
              Chave: 'cargaHoraria',
              Texto: 'Carga horária',
              ComOrdenacao: true
            },
            {
              Chave: 'situacao',
              Texto: 'Situação',
              ComOrdenacao: true
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    data-tip="Editar"
                    data-for={`${linha.id}-tooltip-editar`}
                    onClick={() =>
                      history.push(`${RotasCursos.Edicao}/${linha.id}`, {
                        modalidade: linha.modalidade
                      })
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasCursos.Edicao}/${linha.id}/${linha.modalidade}`
                      )
                      rota.focus()
                    }}
                  >
                    {IconeEditar}
                  </button>
                  <Tooltip
                    id={`${linha.id}-tooltip-editar`}
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                  {validarExibicaoGrupoDiscussao(linha) && (
                    <>
                      <button
                        type="button"
                        data-tip="Fórum de dúvidas"
                        data-for="tooltip-discussao"
                        onClick={() =>
                          history.push(
                            `${RotasAcademico.GrupoDiscussaoLivre}/${linha.id}`
                          )
                        }
                        onContextMenu={() => {
                          const rota = window.open(
                            `${RotasAcademico.GrupoDiscussaoLivre}/${linha.id}`
                          )
                          rota.focus()
                        }}
                      >
                        {IconeDiscussao}
                      </button>
                      <Tooltip
                        id="tooltip-discussao"
                        place="bottom"
                        offset={{ top: 10 }}
                      />
                    </>
                  )}
                </ContainerAcoes>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export { default as PaginaCadastroCursos } from './cadastro'
