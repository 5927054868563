import React from 'react'

export default (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8839 21.8837C10.3958 21.3955 10.3958 20.6041 10.8839 20.1159L20.1162 10.8837C20.6043 10.3955 21.3958 10.3955 21.8839 10.8837C22.3721 11.3718 22.3721 12.1633 21.8839 12.6515L12.6517 21.8837C12.1636 22.3718 11.3721 22.3718 10.8839 21.8837Z"
      fill="#E6414B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1161 10.8837C11.6042 10.3955 12.3957 10.3955 12.8838 10.8837L22.1161 20.1159C22.6042 20.6041 22.6042 21.3955 22.1161 21.8837C21.6279 22.3718 20.8364 22.3718 20.3483 21.8837L11.1161 12.6515C10.6279 12.1633 10.6279 11.3719 11.1161 10.8837Z"
      fill="#E6414B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29.5C23.4558 29.5 29.5 23.4558 29.5 16C29.5 8.54416 23.4558 2.5 16 2.5C8.54416 2.5 2.5 8.54416 2.5 16C2.5 23.4558 8.54416 29.5 16 29.5ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#E6414B"
    />
  </svg>
)
