import { createGlobalStyle } from 'styled-components'

import { Cores } from './cores'
import { Tipografia } from './tipografia'

export const Estilo = createGlobalStyle`
    ${Tipografia}

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        outline: none;
    }

    html,
    body {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        margin: 0;
        padding: 0;
    }

    a {
      text-decoration: none;
      color: ${Cores.SECUNDARIA_1};
    }

    li {
      list-style: none;
    }

    sup {
      font-weight: 500;
      font-size: 8px;
      color: ${Cores.SECUNDARIA_2};
    }
`
