import React, { useState } from 'react'

import { IconeFiltro, IconeSetaVertical } from '../icones'
import { Tooltip } from '../tooltip'
import {
  Botao,
  BotaoIcone,
  Cabecalho,
  Container,
  Conteudo,
  Filtros
} from './styles'
import { FiltroProps } from './tipos'

export const Filtro: React.FC<FiltroProps> = ({ iniciarAberto, children }) => {
  const [aberto, definirAberto] = useState(iniciarAberto ?? false)

  const alternarAberto = () => definirAberto(old => !old)

  return (
    <Container>
      <Cabecalho>
        <h2>Filtro</h2>
        <Botao
          type="button"
          onClick={alternarAberto}
          data-tip={aberto ? 'Fechar filtro' : 'Expandir filtro'}
        >
          {IconeFiltro}
          <BotaoIcone aberto={aberto}>{IconeSetaVertical}</BotaoIcone>
        </Botao>
        <Tooltip place="top" offset={{ top: -5 }} />
      </Cabecalho>
      <Filtros aberto={aberto}>
        <Conteudo>{children}</Conteudo>
      </Filtros>
    </Container>
  )
}
