import React, { FC } from 'react'

import { Usuario } from 'src/componentes/autenticacao/tipos'

import { Titulo } from './styles'

export interface SaudacoesDoMuralProps {
  usuario: Usuario
}

export const SaudacoesDoMural: FC<SaudacoesDoMuralProps> = ({
  usuario
}: SaudacoesDoMuralProps) => (
  <Titulo>Olá, {usuario.nomeApresentacao}! Este é o seu mural:</Titulo>
)
