import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'

export const colunaNomeAvaliacao = (): TabelaColuna => ({
  Chave: 'nomeAvaliacao',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => <span>{linha.nomeAvaliacao}</span>,
  RenderizarCabecalho: () => <div style={{ width: 150 }}>Nome Avaliação</div>
})
