import { useContext } from 'react'

import {
  TabelaPaginacaoEstadoContext,
  TabelaPaginacaoAcoesContext
} from './paginacao-context'
import { TabelaPaginacaoEstado, TabelaPaginacaoDispatch } from './tipos'

export const usePaginacaoState = (): TabelaPaginacaoEstado => {
  const context = useContext(TabelaPaginacaoEstadoContext)
  if (context === undefined) {
    throw new Error(
      'usePaginacaoState deve ser usado dentro de um TabelaPaginacaoProvider'
    )
  }
  return context
}

export const usePaginacaoDispatch = (): TabelaPaginacaoDispatch => {
  const context = useContext(TabelaPaginacaoAcoesContext)
  if (context === undefined) {
    throw new Error(
      'usePaginacaoDispatch deve ser usado dentro de um TabelaPaginacaoProvider'
    )
  }
  return context
}

export const usePaginacao = (): [
  TabelaPaginacaoEstado,
  TabelaPaginacaoDispatch
] => [usePaginacaoState(), usePaginacaoDispatch()]
