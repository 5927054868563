import React from 'react'

export default (
  <svg
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2435 30.8H4.05632C2.36847 30.8 1 29.4407 1 27.7641V4.03604C1 2.35917 2.36847 1 4.05647 1H44.2435C45.9317 1 47.3 2.35917 47.3 4.03604V27.7639C47.3 29.4407 45.9317 30.8 44.2435 30.8Z"
      fill="#F9F9F9"
    />
    <path
      d="M11.2967 12.5872C11.7406 12.439 12.2141 12.3601 12.7053 12.3601C14.8556 12.3601 16.6508 13.8885 17.0611 15.9206L20.109 15.2985C19.4106 11.8466 16.3608 9.24779 12.7053 9.24779C11.8669 9.24779 11.064 9.38405 10.3104 9.63682L11.2967 12.5872Z"
      fill="#FFCB05"
    />
    <path
      d="M7.70043 22.4729L9.76194 20.1407C8.84265 19.3251 8.26266 18.1343 8.26266 16.8073C8.26266 15.4822 8.84265 14.2914 9.76194 13.4778L7.70043 11.1436C6.13801 12.5299 5.15164 14.554 5.15164 16.8073C5.15164 19.0625 6.13801 21.0866 7.70043 22.4729Z"
      fill="#00A4E0"
    />
    <path
      d="M17.0611 17.6999C16.6488 19.73 14.8556 21.2565 12.7073 21.2565C12.2141 21.2565 11.7386 21.1775 11.2967 21.0274L10.3104 23.9817C11.064 24.2325 11.8688 24.3687 12.7073 24.3687C16.3588 24.3687 19.4067 21.7739 20.109 18.3259L17.0611 17.6999Z"
      fill="#EF4123"
    />
    <path
      d="M22.7742 20.2375C22.6736 20.0756 22.5394 19.8169 22.4566 19.6253C21.9732 18.5036 21.9515 17.3425 22.3579 16.2287C22.8057 15.0063 23.6619 14.0722 24.7686 13.5943C26.1594 12.9959 27.6962 13.1125 29.0297 13.9043C29.876 14.3901 30.4758 15.1406 30.9315 16.203C30.9887 16.3393 31.04 16.4834 31.0913 16.6078L22.7742 20.2375ZM25.5518 15.3973C24.5634 15.8219 24.0545 16.75 24.161 17.8361L28.3432 16.0332C27.6232 15.188 26.6881 14.9075 25.5518 15.3973ZM28.864 19.3192C28.8621 19.3192 28.8621 19.3212 28.8621 19.3212L28.7753 19.2619C28.5247 19.6668 28.1361 19.9966 27.6449 20.2098C26.7098 20.6166 25.8438 20.512 25.2204 19.965L25.1632 20.0519C25.1632 20.0519 25.1632 20.0499 25.1612 20.0499L24.0998 21.6396C24.3642 21.8232 24.6463 21.9773 24.9422 22.1017C26.114 22.5895 27.3134 22.5658 28.4951 22.0523C29.3493 21.6811 30.0201 21.1163 30.4777 20.3994L28.864 19.3192Z"
      fill="black"
    />
    <path
      d="M33.9971 11.2561V20.0913L35.3701 20.6482L34.5909 22.469L33.0758 21.837C32.7365 21.6889 32.5037 21.4638 32.3301 21.2091C32.1624 20.9504 32.0381 20.5929 32.0381 20.115V11.2561H33.9971Z"
      fill="black"
    />
    <path
      d="M37.552 17.842C37.552 17.0897 37.8854 16.4143 38.4101 15.9561L37.0016 14.3842C36.0468 15.2294 35.4451 16.4636 35.4451 17.8381C35.4431 19.2145 36.0448 20.4507 36.9976 21.2959L38.4042 19.724C37.8814 19.2639 37.552 18.5925 37.552 17.842Z"
      fill="black"
    />
    <path
      d="M40.0534 20.3481C39.7772 20.3481 39.5089 20.3007 39.2603 20.2177L38.5876 22.2182C39.0473 22.3722 39.5385 22.4571 40.0514 22.4571C42.2806 22.4591 44.1409 20.8753 44.573 18.7722L42.5055 18.3496C42.2708 19.493 41.2627 20.35 40.0534 20.3481Z"
      fill="black"
    />
    <path
      d="M40.0593 13.227C39.5484 13.227 39.0572 13.3099 38.5975 13.462L39.2643 15.4644C39.5148 15.3815 39.7812 15.336 40.0573 15.336C41.2686 15.338 42.2787 16.199 42.5075 17.3444L44.5749 16.9257C44.1508 14.8187 42.2905 13.2289 40.0593 13.227Z"
      fill="black"
    />
    <path
      d="M44.2435 30.8H4.05632C2.36847 30.8 1 29.4407 1 27.7641V4.03604C1 2.35917 2.36847 1 4.05647 1H44.2435C45.9317 1 47.3 2.35917 47.3 4.03604V27.7639C47.3 29.4407 45.9317 30.8 44.2435 30.8Z"
      stroke="#DDDDDD"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
