import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'

import { ModalBase, Botao, IconeModalSucesso } from 'src/componentes'

import { Corpo, Texto, Titulo } from './styles'
import {
  ModalPagamentoReingressoProps,
  ModalPagamentoReingressoRef
} from './tipos'

const ModalPagamento: React.ForwardRefRenderFunction<
  ModalPagamentoReingressoRef,
  ModalPagamentoReingressoProps
> = ({ backdrop, ehBoleto }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const history = useHistory()

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<ModalPagamentoReingressoRef, ModalPagamentoReingressoRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const acaoSucesso = () => {
    fechar()
    history.push('/')
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        {IconeModalSucesso}
        <Titulo>Matrícula realizada com sucesso!</Titulo>
        {ehBoleto ? (
          <>
            <Texto>Obrigada por nos escolher nesta linda jornada! </Texto>
            <Texto>
              Você finalizou sua matrícula, escolhendo pagar a Taxa de
              Reingresso através de boleto bancário, e por isso, você terá
              acesso liberado à página do curso em sua tela inicial do Education
              em até 24h após o pagamento deste boleto.
            </Texto>
            <Texto>
              Também enviaremos para o e-mail cadastrado algumas orientações
              complementares de acesso. Caso não encontre este e-mail na caixa
              de entrada, confira também o spam e lixo eletrônico.
            </Texto>
            <Texto>
              OBS: Atenção aos cronogramas e datas de início do curso adquirido.
              Alguns cursos da Faculdade iPGS estão em pré-lançamento e o
              conteúdo estará disponível a partir da data de início informada
              nas páginas de divulgação
            </Texto>
            <Texto>Bons estudos e conte conosco.</Texto>
          </>
        ) : (
          <>
            <Texto>Obrigada por nos escolher nesta linda jornada! </Texto>
            <Texto>
              Você finalizou sua matrícula e com esta forma de pagamento você já
              está com acesso liberado à página do curso em sua tela inicial do
              Education.
            </Texto>
            <Texto>
              OBS: Atenção aos cronogramas e datas de início do curso adquirido.
              Alguns cursos da Faculdade iPGS estão em pré-lançamento e o
              conteúdo estará disponível a partir da data de início informada
              nas páginas de divulgação
            </Texto>
            <Texto>Bons estudos e conte conosco.</Texto>
          </>
        )}
        <Botao texto="OK" type="button" onClick={() => acaoSucesso()} />
      </Corpo>
    </ModalBase>
  )
}

export const ModalPagamentoReingresso = forwardRef(ModalPagamento)
