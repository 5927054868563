import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { Grade, ModalCriarProps, ModalIncluirGradeRef } from '..'

import {
  Botao,
  FormUnform,
  IconeAtencao,
  InputUnform,
  Modal,
  ModalRef,
  Spacer
} from '../../../../../../componentes'
import { ModalBase } from '../../../../../../componentes/modal-base'
import { GradeCurricularSituacao } from '../../../../../../tipos'
import { Corpo, ContainerBotoes } from '../../styles'
import { schema } from './schema'

const ModalIncluir: React.ForwardRefRenderFunction<
  ModalIncluirGradeRef,
  ModalCriarProps
> = ({ backdrop, acaoPrimaria, decisao }, ref) => {
  const [pronto, definirPronto] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const modalDecisaoRef = useRef<ModalRef>(null)
  const [dados, definirDados] = useState<Grade>(null)
  const [nomeGradeAtiva, definirNomeGradeAtiva] = useState<string>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = nomeGradeAtiva => {
    definirNomeGradeAtiva(nomeGradeAtiva)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalIncluirGradeRef, ModalIncluirGradeRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = async (dados: Grade) => {
    definirDados(dados)
    if (!decisao) {
      acaoPrimaria({
        ...dados,
        situacao: GradeCurricularSituacao[GradeCurricularSituacao.Ativo]
      })
    } else {
      modalDecisaoRef?.current?.abrir()
    }
    fechar()
  }

  const dadosIniciais = useMemo(() => {
    return { situacao: GradeCurricularSituacao[GradeCurricularSituacao.Ativo] }
  }, [])

  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo>
          <h5> Incluir Grade Curricular </h5>
          <Spacer padding="10px 0px" />
          {pronto && (
            <FormUnform
              schema={schema}
              dadosIniciais={dadosIniciais}
              acaoSucesso={acaoSucesso}
            >
              <InputUnform
                name="nome"
                label="Nome da Grade Curricular"
                obrigatorio
                maxLength={200}
              />
              <Spacer padding="10px 0px" />
              <ContainerBotoes>
                <Botao
                  type="button"
                  id="btn-cancelar"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                />
                <Botao id="btn-salvar" texto="Salvar" type="submit" />
              </ContainerBotoes>
            </FormUnform>
          )}
        </Corpo>
      </ModalBase>
      <Modal
        ref={modalDecisaoRef}
        backdrop
        id="modal-confirmacao"
        icone={IconeAtencao}
        titulo={'Já existe uma grade curricular ativa para este curso'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'submit',
          acao: () => {
            acaoPrimaria({
              ...dados,
              situacao: GradeCurricularSituacao[GradeCurricularSituacao.Ativo]
            })
            modalDecisaoRef.current?.fechar()
          }
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDecisaoRef.current?.fechar()
        }}
      >
        <p>{`Deseja desativar a grade "${nomeGradeAtiva}"?`}</p>
      </Modal>
    </>
  )
}
export const ModalIncluirGrade = forwardRef(ModalIncluir)
