import React from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import { PaginaSalaAulaCursoLivre, PaginaSalaAulaOutros } from 'src/paginas'
import { PaginaRecuperacao } from 'src/paginas/aluno'
import { PaginaSelecaoModulosFlex } from 'src/paginas/sala-aula/selecao-modulos-flex'
import { PaginaTrocaModuloFlex } from 'src/paginas/sala-aula/troca-modulo-flex'

export const SalaAulaRotas: React.FC = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/curso-livre/:id`}>
        <PaginaSalaAulaCursoLivre />
      </Route>
      <Route path={`${url}/outros-cursos/:id/recuperacao`}>
        <PaginaRecuperacao />
      </Route>
      <Route path={`${url}/outros-cursos/:id`}>
        <PaginaSalaAulaOutros />
      </Route>
      <Route path={`${url}/selecao-modulos/:id`}>
        <PaginaSelecaoModulosFlex />
      </Route>
      <Route path={`${url}/troca-modulo/:idModulo/:idSala`}>
        <PaginaTrocaModuloFlex />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}
