import React from 'react'

export default (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.93347 12.0001C7.93347 11.4478 8.38119 11.0001 8.93347 11.0001H18.9335C19.4858 11.0001 19.9335 11.4478 19.9335 12.0001C19.9335 12.5523 19.4858 13.0001 18.9335 13.0001H8.93347C8.38119 13.0001 7.93347 12.5523 7.93347 12.0001Z"
      fill="#009B9B"
      fillOpacity="0.8"
    />
    <path
      d="M13.9335 18C13.3812 18 12.9335 17.5523 12.9335 17L12.9335 7C12.9335 6.44771 13.3812 6 13.9335 6C14.4858 6 14.9335 6.44772 14.9335 7L14.9335 17C14.9335 17.5523 14.4858 18 13.9335 18Z"
      fill="#009B9B"
      fillOpacity="0.8"
    />
  </svg>
)
