import { FormUnform } from 'src/componentes/unform/form'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ pb?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;

  ${({ pb }) =>
    pb &&
    css`
      padding-bottom: 20px;
    `}
`

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Campo = styled.div<{ mr?: boolean }>`
  flex: 1;

  ${({ mr }) =>
    mr &&
    css`
      margin-right: 10px;
    `}
`
export const ContainerExportar = styled.div`
  display: flex;
  > div {
    margin: 10px;
  }
`
export const Dados = styled.div`
  margin-top: 25px;
  display: flex;
  overflow-x: auto;
  scroll-behavior: auto;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  #rodape_paginacao {
    float: left;
  }
`

export const CabecalhoRelatorioPdf = styled.div`
  display: flex;
  width: 96%;
  padding: 0px 2%;
  margin-bottom: 20px;
`
export const ContainerInfoCabecalhoExportacaoPdf = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 5px;

  p {
    font-size: 18px;
  }
  span {
    font-size: 16px;
  }
`
export const ContainerExportacaoPdf = styled.div`
  display: none;
  justify-content: center;

  @media print {
    height: 100%;
    display: flex;

    @page {
      size: landscape;
    }
  }
`

export const ConteudoExportacaoPdf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TituloExportacaoPdf = styled.h1`
  font-weight: 500;
  font-size: 30px;
  margin: 25px 0px;
`

export const IconeLabel = styled.div`
  display: flex;
  align-items: center;
`
