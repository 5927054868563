import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle
} from 'react'

import classNames from 'classnames'
import { TipoOrdenacao as TipoOrdenacaoGenerico } from 'src/tipos'

import { Checkbox } from '../checkbox'
import { IconeCarregando, IconeLimpar, IconeOrdenacao } from '../icones'
import Linha from './linha'
import { Paginacao } from './paginacao'
import { usePaginacao } from './paginacao-hooks'
import { TabelaPaginacaoProvider } from './paginacao-provider'
import {
  Componente,
  Tabela as ComponenteTabela,
  DadoFake,
  CheckboxFake,
  ModalCarregamento,
  SemDados,
  Cabecalho,
  BotaoOrdenacao,
  BotaoLimpar,
  Informacao
} from './styles'
import { TabelaProps, TabelaDados, TabelaRef } from './tipos'

const InstanciaTabela: React.ForwardRefExoticComponent<TabelaProps> = forwardRef<
  TabelaRef,
  TabelaProps
>(
  (
    {
      Id,
      ObterDados,
      Colunas,
      RenderizarSemDados,
      ComMarcacao,
      ComMarcacaoCondicao,
      OnChangeMarcacao,
      LinhaPreSelecionada,
      ComPaginacao,
      MostrarCarregandoInstantaneo,
      TextoSemResultados,
      TemaLinha,
      className,
      desmarcarTodos
    },
    ref: any
  ) => {
    const cancelado = React.useRef(false)
    const [carregando, definirCarregando] = useState<boolean>(true)
    const [dados, definirDados] = useState<TabelaDados[]>([])
    const [todos, definirTodos] = useState<boolean>(false)
    const [
      { Chave: ChaveOrdenacao, TipoOrdenacao },
      definirOrdenacao
    ] = useState<{ Chave?: string; TipoOrdenacao?: TipoOrdenacaoGenerico }>({
      Chave: undefined,
      TipoOrdenacao: undefined
    })

    const [{ Pagina, TamanhoPagina }, paginacaoDispatch] = usePaginacao()

    const irParaPagina = (pagina = 1) => {
      paginacaoDispatch({ tipo: 'ir-para-pagina', pagina })
    }

    const carregarDados = async (carregandoInstantaneo?: boolean) => {
      definirTodos(false)

      let carregandoId: number

      if (!carregandoInstantaneo) {
        carregandoId = window.setTimeout(() => {
          if (!cancelado.current) definirCarregando(true)
        }, 3000)
      } else {
        if (!cancelado.current) definirCarregando(true)
      }

      if (ComPaginacao) {
        paginacaoDispatch({ tipo: 'alterar-totais', totalRegistros: undefined })
      }

      try {
        const resposta = await ObterDados(
          Pagina,
          TamanhoPagina,
          ChaveOrdenacao,
          TipoOrdenacao as TipoOrdenacaoGenerico
        )
        if (TemaLinha && !!resposta?.Dados) {
          definirDados(
            resposta.Dados.map(i => {
              i.tema = TemaLinha(i)
              return i
            })
          )
        }
        if (cancelado.current) return

        if (!carregandoInstantaneo) {
          clearTimeout(carregandoId)
        }

        if (!resposta?.Dados || resposta.Dados.length === 0) {
          definirDados([])
          return
        }

        if (ComMarcacao) {
          definirDados(
            resposta.Dados.map(i => {
              if (LinhaPreSelecionada) i.marcado = LinhaPreSelecionada(i)
              else i.marcado = false

              return i
            })
          )

          if (resposta.Dados.every(i => i.marcado)) {
            definirTodos(true)
          }
        } else {
          definirDados(resposta.Dados)
        }

        if (ComPaginacao) {
          paginacaoDispatch({
            tipo: 'alterar-totais',
            totalRegistros: resposta.Paginacao.TotalRegistros
          })
          paginacaoDispatch({
            tipo: 'ir-para-pagina',
            pagina: resposta.Paginacao.Pagina
          })
        }
      } finally {
        if (!cancelado.current) definirCarregando(false)
      }
    }

    useEffect(() => {
      return () => {
        cancelado.current = true
      }
    }, [])

    useEffect(() => {
      carregarDados(MostrarCarregandoInstantaneo)
    }, [Pagina, TamanhoPagina, ChaveOrdenacao, TipoOrdenacao])

    useEffect(() => {
      if (OnChangeMarcacao) {
        OnChangeMarcacao(dados.filter(d => d.marcado))
      }
    }, [dados])

    const desmarcarTodosItens = () => {
      definirDados(
        dados.map(d => ({
          ...d,
          marcado: false
        }))
      )
      definirTodos(false)
    }

    const desmarcarItens = () => {
      definirDados(old => {
        return old.map(d => {
          return {
            ...d,
            marcado: false
          }
        })
      })
      definirTodos(false)
    }

    useEffect(() => {
      if (desmarcarTodos) desmarcarItens()
    }, [desmarcarTodos])

    const manipularMarcarDesmarcarTodos = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      definirDados(old => {
        return old.map(d => {
          const condicao = ComMarcacaoCondicao ? ComMarcacaoCondicao(d) : true

          if (!condicao) return d

          return {
            ...d,
            marcado: e.target.checked
          }
        })
      })
      definirTodos(e.target.checked)
    }

    const manipularMarcarDesmarcarItem = (marcado: boolean, indice: number) => {
      if (ComMarcacao) {
        definirDados(oldDados => {
          const novosDados = oldDados.map((d, i) =>
            i === indice ? { ...d, marcado } : d
          )

          definirTodos(
            novosDados.filter((p: TabelaDados) => !p.marcado).length === 0
          )

          return novosDados
        })
      }
    }

    const manipularOrdenacao = (Chave: string) => {
      if (Chave === ChaveOrdenacao) {
        definirOrdenacao(old => ({
          Chave,
          TipoOrdenacao: old.TipoOrdenacao === 1 ? 0 : 1
        }))
        return
      }

      definirOrdenacao({
        Chave,
        TipoOrdenacao: 1
      })
    }

    const renderizarCabecalho = () => (
      <tr>
        {ComMarcacao && (carregando || dados.length > 0) && (
          <th key="coluna-marcacao" align="center">
            <Checkbox
              htmlFor={Id ? `checkbox-todos-${Id}` : 'checkbox-todos'}
              tema="Secundario"
              tamanho="S"
            >
              <input
                name={Id ? `checkbox-todos-${Id}` : 'checkbox-todos'}
                id={Id ? `checkbox-todos-${Id}` : 'checkbox-todos'}
                type="checkbox"
                checked={todos}
                onChange={manipularMarcarDesmarcarTodos}
              />
            </Checkbox>
          </th>
        )}
        {Colunas.map(
          (
            {
              Chave,
              Texto,
              Alinhamento,
              ComOrdenacao,
              RenderizarCabecalho,
              StyleColuna,
              ComLimparTodosFiltros
            },
            indiceColuna: number
          ) => (
            <th
              key={`coluna-${indiceColuna}`}
              align={Alinhamento || 'left'}
              style={StyleColuna}
            >
              <Cabecalho>
                <Informacao
                  comOrdenacao={!!ComOrdenacao}
                  comLimparTodosFiltros={!!ComLimparTodosFiltros}
                >
                  {RenderizarCabecalho ? (
                    RenderizarCabecalho()
                  ) : (
                    <label>{Texto}</label>
                  )}
                </Informacao>
                {!carregando && ComOrdenacao && (
                  <BotaoOrdenacao
                    type="button"
                    className={classNames({
                      asc: ChaveOrdenacao === Chave && TipoOrdenacao === 0,
                      desc: ChaveOrdenacao === Chave && TipoOrdenacao === 1,
                      comLimparFiltros: ComLimparTodosFiltros,
                      customizado: !!RenderizarCabecalho
                    })}
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.preventDefault()
                      manipularOrdenacao(Chave)
                    }}
                  >
                    {IconeOrdenacao}
                  </BotaoOrdenacao>
                )}
                {!carregando && ComLimparTodosFiltros ? (
                  <BotaoLimpar
                    type="button"
                    className={classNames({
                      limpar_filtros: true,
                      com_renderizar_cabecalho: !!RenderizarCabecalho,
                      desabilitado: ComLimparTodosFiltros.Disabled()
                    })}
                    onClick={async () => {
                      await ComLimparTodosFiltros.Acao()
                    }}
                  >
                    {IconeLimpar}
                  </BotaoLimpar>
                ) : (
                  <></>
                )}
              </Cabecalho>
            </th>
          )
        )}
      </tr>
    )
    const renderizarCarregando = useCallback(
      () =>
        [...Array(TamanhoPagina)].map((_, indiceLinha: number) => (
          <tr
            key={`linha-${indiceLinha}`}
            className={classNames({
              par: (indiceLinha + 1) % 2 === 0
            })}
          >
            {ComMarcacao && (
              <td key={`linha-${indiceLinha}-coluna-marcacao`}>
                <CheckboxFake />
              </td>
            )}
            {Colunas.map((Coluna, indiceColuna: number) => (
              <td
                key={`linha-${indiceLinha}-coluna-${indiceColuna}`}
                style={Coluna.StyleColuna}
              >
                <DadoFake />
              </td>
            ))}
          </tr>
        )),
      [ComMarcacao, Colunas, TamanhoPagina]
    )

    const renderizarModalCarregamento = () => (
      <ModalCarregamento>
        {IconeCarregando}
        <p>Carregando ...</p>
      </ModalCarregamento>
    )

    useImperativeHandle<unknown, TabelaRef>(ref, () => ({
      ObterDados: () => dados,
      CarregarDados: carregarDados,
      DesmarcarTodos: desmarcarTodosItens,
      IrParaPagina: irParaPagina
    }))

    const renderizarTabelaVazia = () =>
      RenderizarSemDados ? (
        RenderizarSemDados()
      ) : (
        <SemDados>
          {TextoSemResultados ??
            'Nenhum resultado encontrado para este filtro.'}
        </SemDados>
      )

    return (
      <Componente className={className}>
        <ComponenteTabela id={Id}>
          <thead>{renderizarCabecalho()}</thead>
          <tbody>
            {!carregando &&
              dados.map((linha: any, indiceLinha: number) => (
                <Linha
                  key={`linha-${indiceLinha}`}
                  Id={Id}
                  Indice={indiceLinha}
                  Dados={linha}
                  ComMarcacao={ComMarcacao}
                  Colunas={Colunas}
                  TemaLinha={linha.tema}
                  ComMarcacaoCondicao={ComMarcacaoCondicao}
                  MarcarDesmarcar={manipularMarcarDesmarcarItem}
                />
              ))}
            {carregando && renderizarCarregando()}
          </tbody>
        </ComponenteTabela>
        {!carregando && dados.length === 0 && renderizarTabelaVazia()}
        {carregando && renderizarModalCarregamento()}
        {ComPaginacao && <Paginacao />}
      </Componente>
    )
  }
)

export const Tabela = forwardRef<TabelaRef, TabelaProps>(
  ({ TamanhoPagina, EstiloPaginacao, ...rest }, ref: any) => (
    <TabelaPaginacaoProvider
      TamanhoPagina={TamanhoPagina || 10}
      Estilo={EstiloPaginacao || 'Simples'}
    >
      <InstanciaTabela {...rest} ref={ref} />
    </TabelaPaginacaoProvider>
  )
)

export * from './tipos'
