import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useUIDSeed, uid } from 'react-uid'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Modal } from '../modal'
import { ModalRef } from '../modal/tipos'
import WizardContainer from './container'
import { Componente, Box, Circle, Icone, Lista, Barra, Corpo } from './styles'
import { WizardProps, DadosWizard, SituacaoWizard } from './tipos'

export const WizardComponente: React.FC<Omit<WizardProps, 'passoCompleto'>> = ({
  passo: passoProp,
  listaWizard,
  ativarPrePasso,
  ModalPrePasso,
  finalizado,
  name
}) => {
  const {
    passo,
    passoCompleto,
    preEtapa,
    visibilidadePrePasso,
    definirTamanhoWizard,
    acaoConfirmarPrePasso,
    acaoCancelarPrePasso,
    mudarVisibilidadePrePasso,
    mudarEtapaWizard,
    salvarPreEtapa,
    mudarEtapa
  } = WizardContainer.useContainer()
  const seed = useUIDSeed()
  const primeiroRender = useRef<boolean>(true)
  const modalRef = useRef<ModalRef>(null)
  const [carregando, definirCarregando] = useState(false)

  useEffect(() => {
    if (!primeiroRender.current) {
      mudarEtapa(passoProp)
    } else {
      mudarEtapaWizard(passoProp)
      definirTamanhoWizard(listaWizard.length)
    }

    primeiroRender.current = false
  }, [passoProp])

  const obterSituacaoAtual = useCallback(
    (index: number, desabilitado?: boolean) => {
      if (desabilitado) {
        return SituacaoWizard.BLOQUEADO
      }

      if (passoCompleto > index || finalizado) {
        return SituacaoWizard.PREENCHIDO
      }
      if (passoCompleto < index) {
        return SituacaoWizard.BLOQUEADO
      }
      return SituacaoWizard.DISPONIVEL
    },
    [passoCompleto, finalizado]
  )

  const confirmarPrePasso = async () => {
    try {
      definirCarregando(true)
      const sucessoPrePasso = await acaoConfirmarPrePasso()

      if (sucessoPrePasso) {
        mudarEtapaWizard(preEtapa)
      } else {
        mudarVisibilidadePrePasso(false)
      }
    } finally {
      definirCarregando(false)
    }
  }

  const cancelarPrePasso = async () => {
    if (acaoCancelarPrePasso) {
      await acaoCancelarPrePasso(preEtapa)
    }

    mudarVisibilidadePrePasso(false)
    salvarPreEtapa(undefined)
  }

  useEffect(() => {
    if (visibilidadePrePasso) {
      modalRef?.current?.abrir()
    } else {
      modalRef?.current?.fechar()
    }
  }, [visibilidadePrePasso])

  return (
    <>
      <Componente>
        <Lista>
          {listaWizard.map((item: DadosWizard, index) => {
            const indiceEm1 = index + 1
            const id = seed(item)
            return (
              <li
                key={uid(indiceEm1)}
                id={item.id}
                value={String(passo === indiceEm1)}
              >
                <input
                  id={id}
                  type="radio"
                  name={name}
                  onChange={e => {
                    if (e.target.checked) {
                      mudarEtapa(indiceEm1)
                    }
                  }}
                  disabled={
                    (passoCompleto < indiceEm1 && !finalizado) ||
                    item.desabilitado
                  }
                  checked={passo === indiceEm1}
                />
                <Box
                  situacao={obterSituacaoAtual(indiceEm1, item.desabilitado)}
                  className={classNames({
                    checked: passo === indiceEm1
                  })}
                >
                  <Circle htmlFor={id}>
                    <Icone>{item.icone}</Icone>
                  </Circle>
                  <span>{item.titulo}</span>
                  {index !== listaWizard.length - 1 && <Barra />}
                </Box>
              </li>
            )
          })}
        </Lista>
      </Componente>
      <Corpo>{listaWizard[passo - 1].corpo}</Corpo>
      {ativarPrePasso && ModalPrePasso && (
        <Modal
          ref={modalRef}
          backdrop={true}
          icone={<></>}
          id="modal-dados-nao-salvos"
          titulo="Suas alterações não foram salvas"
          acaoPrimaria={{
            titulo: 'SALVAR',
            acao: confirmarPrePasso,
            carregando
          }}
          acaoSecundario={{
            titulo: 'Descartar alterações',
            acao: cancelarPrePasso,
            desabilitado: carregando
          }}
        >
          {ModalPrePasso}
        </Modal>
      )}
    </>
  )
}

const tiposDasPropriedades = {
  listaWizard: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      titulo: PropTypes.string.isRequired,
      icone: PropTypes.element.isRequired,
      corpo: PropTypes.element.isRequired
    })
  ),
  name: PropTypes.string.isRequired,
  passo: PropTypes.number.isRequired
}

export const Wizard: React.FC<WizardProps> = ({ passoCompleto, ...rest }) => (
  <WizardContainer.Provider initialState={{ passoCompleto }}>
    <WizardComponente {...rest} />
  </WizardContainer.Provider>
)

WizardComponente.propTypes = {
  ...tiposDasPropriedades
}

Wizard.propTypes = {
  ...tiposDasPropriedades
}
