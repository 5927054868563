import styled from 'styled-components'

const proporcao = 1.7

const proporcional = (tamanho: string) => `calc(${tamanho} / ${proporcao})`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 80px 60px 80px 60px;
`
export const Header = styled.img`
  margin-top: 10px;
  width: '100%';
`

export const ContainerDeDescricao = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 1;
`

export const Titulo = styled.h1`
  font-size: 16px;
  text-decoration: underline;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: ${proporcional('230px')};
  margin-bottom: ${proporcional('30px')};
`

export const Paragrafo = styled.p`
  font-size: 14px;
  font-weight: normal;
`

export const ParagrafoPrincipal = styled(Paragrafo)`
  text-align: justify;
  margin-bottom: ${proporcional('70px')};
`

export const ParagrafoDaColuna = styled(Paragrafo)`
  color: gray;
`

export const ParagrafoDaDataAtual = styled(Paragrafo)`
  text-align: right;
`

export const ContainerAssinatura = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  align-items: flex-end;
  margin-bottom: ${proporcional('550px')};
`

export const AssinaturaImg = styled.img`
  width: ${proporcional('455px')};
`

export const AssinaturaText = styled.div`
  width: ${proporcional('455px')};
  font-size: 12px;
  border-top: 2px solid gray;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 7px;
  margin: 5px 5px;
`

export const PrimeiraColuna = styled(Coluna)``

export const SegundaColuna = styled(Coluna)`
  border-left: 2px solid gray;
  border-right: 2px solid gray;
  padding: 0px 15px;
`

export const TerceiraColuna = styled(Coluna)``

export const Linha = styled.div`
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 70%;
  opacity: 0.3;
`
