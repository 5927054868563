export interface DadoStorage {
  [key: string]: unknown
}

export function obter(chave: Chaves | string): any | null {
  try {
    const dados = localStorage.getItem(chave)
    return dados && JSON.parse(dados)
  } catch {
    localStorage.removeItem(chave)
    return null
  }
}

export function definir(chave: Chaves | string, valor: DadoStorage): void {
  localStorage.setItem(chave, JSON.stringify(valor))
}

export function remover(chave: Chaves | string): void {
  localStorage.removeItem(chave)
}

export type Chaves = '@ipgs/estadoMenu'
