import { ButtonHTMLAttributes } from 'react'

import { Botao } from 'src/componentes/botao'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const ContainerMain = styled.div`
  width: 100%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const ContainerInformacoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Coluna = styled.div`
  margin-top: 20px;
  p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
  }
`

export const ContainerDados = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    margin-bottom: 0px;
  }
`

export const ContainerImagem = styled.div`
  margin-right: 100px;
  div {
    & > div:last-child {
      display: none;
    }
  }
`

export const ContainerBreadcrumbs = styled.div`
  display: flex;
  margin-bottom: 22px;
`

export const BotaoSalvar = styled(Botao)`
  padding: 14px 60px;
  margin-left: 20px;
`

export const Secao = styled.section`
  margin-top: 40px;
`

export const ContainerSituacao = styled.span`
  form {
    width: 130px;
    div {
      margin-bottom: 0px;
    }
  }
`

export const TituloPrincipal = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 18px;
`

export const TituloComplementar = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 30px;
`

export const ParagrafoDaNacionalidade = styled.p`
  text-transform: capitalize;
`

export const DescricaoContainer = styled.div`
  margin-bottom: 30px;

  & > p,
  & > ${ParagrafoDaNacionalidade} {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }
`

export const Dados = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  scroll-behavior: auto;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const BotaoDeAcao = styled.button<
  ButtonHTMLAttributes<HTMLButtonElement>
>`
  cursor: pointer;
  background: transparent;
  border: 0;
  align-self: center;
`
