import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaDataPostagemNota = (): TabelaColuna => ({
  Chave: 'dataPostagemNota',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>{formatadores.dataLocaleString(linha.dataPostagemNota)}</span>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 120 }}>Data Postagem Nota Professor</div>
  )
})
