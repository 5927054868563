import { Place } from 'react-tooltip'

import styled, { css } from 'styled-components'

export const ContainerIconeBloqueado = styled.div<{ place: Place }>`
  position: absolute;
  z-index: 9999;

  ${props => {
    switch (props.place) {
      case 'right':
        return css`
          right: 99%;
          transform: translateY(-50%);
          top: 45%;
          z-index: 9999;
        `
      case 'left':
        return css`
          left: 99%;
          transform: translateY(-50%);
          top: 45%;
        `
      case 'top':
        return css`
          left: 51%;
          transform: translateX(-50%);
          top: 90%;
        `
      case 'bottom':
        return css`
          left: 49%;
          transform: translateX(-50%);
          bottom: 90%;
        `
    }
  }}
`
