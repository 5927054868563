import styled, { css } from 'styled-components'

import { Medias } from '../styles/breakpoints'
import { Cores } from '../styles/cores'

export interface BarraProps {
  expandido: boolean
}

export const Barra = styled.div<BarraProps>`
  background: ${Cores.PRIMARIA_1};
  box-sizing: content-box;
  border-right: 1px solid ${Cores.CINZA_3_ESCURO};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  z-index: 8;
  opacity: 0;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  ${({ expandido }) =>
    expandido &&
    css`
      width: 240px;
      opacity: 1;
      transition: all 0.1s ease-in;

      @media ${Medias.NOTEBOOK.MIN} {
        position: fixed;
      }
    `}

  ${({ expandido }) =>
    !expandido &&
    css`
      @media ${Medias.NOTEBOOK.MIN} {
        position: fixed;
        width: 72px;
        opacity: 1;
      }
    `}

  @media ${Medias.TABLET.MAX} {
    background: ${Cores.BRANCO};
  }
`

export const Fundo = styled.div<BarraProps>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(25, 20, 20, 0.64);
  opacity: 0;
  z-index: -1;

  ${({ expandido }) =>
    expandido &&
    css`
      opacity: 1;
      z-index: 6;
      transition: opacity 0.2s;

      @media ${Medias.NOTEBOOK.MIN} {
        display: none;
      }
    `}
`
