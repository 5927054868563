import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { SituacaoFinanceiraPorExtenso } from 'src/tipos'

export const colunaSituacaoFinanceira = (): TabelaColuna => ({
  Chave: 'situacaoFinanceira',
  StyleColuna: { width: 140 },
  Renderizar: (linha: TabelaDados) => (
    <span>{SituacaoFinanceiraPorExtenso[linha.situacaoFinanceira]}</span>
  ),
  RenderizarCabecalho: () => <div style={{ width: 140 }}>Situação</div>
})
