import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  FormUnform,
  ModalBase,
  ModalBaseProps,
  ModalRef,
  TextAreaUnform
} from 'src/componentes'
import { AlterarCobranca } from 'src/tipos'

import { schema } from './schema'
import {
  ContainerDoConteudo,
  ContainerModal,
  TituloPrincipal,
  ContainerDosBotoes
} from './styles'
export type ModalReembolsoCobrancaProps = Omit<
  ModalBaseProps,
  'acaoPrimaria'
> & {
  acaoPrimaria: (dados: AlterarCobranca) => Promise<void>
}
const Modal: React.ForwardRefRenderFunction<
  ModalRef,
  ModalReembolsoCobrancaProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const fechar = () => definirAberta(false)
  const abrir = () => definirAberta(true)

  const acaoSucessoForm = async (dados: AlterarCobranca) => {
    await acaoPrimaria(dados)
    fechar()
    formRef.current?.reset()
  }

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase id="modal-reembolso-parcela" aberta={aberta} backdrop={backdrop}>
      <ContainerModal>
        <TituloPrincipal>Reembolso Cobranças</TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm} schema={schema}>
          <ContainerDoConteudo>
            <TextAreaUnform name="observacao" label="Observação" />
          </ContainerDoConteudo>
          <ContainerDosBotoes>
            <Botao
              texto="Voltar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Enviar" />
          </ContainerDosBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}
export const ModalReembolsoCobranca = forwardRef(Modal)
