import React from 'react'

export default (
  <svg
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2435 30.8H4.05631C2.36846 30.8 1 29.4407 1 27.7641V4.03604C1 2.35917 2.36846 1 4.05647 1H44.2435C45.9317 1 47.3 2.35917 47.3 4.03604V27.7639C47.3 29.4407 45.9317 30.8 44.2435 30.8Z"
      fill="#F9F9F9"
    />
    <path
      d="M44.2435 30.8H4.05631C2.36846 30.8 1 29.4407 1 27.7641V4.03604C1 2.35917 2.36846 1 4.05647 1H44.2435C45.9317 1 47.3 2.35917 47.3 4.03604V27.7639C47.3 29.4407 45.9317 30.8 44.2435 30.8Z"
      stroke="#DDDDDD"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5994 11.6632C12.5994 11.7464 12.5994 11.8131 12.5994 11.88C12.5993 13.5207 12.6042 15.1618 12.5967 16.8026C12.594 17.4394 12.39 18.0031 11.8863 18.4276C11.4589 18.7878 10.9481 18.9346 10.3996 18.9526C9.16172 18.994 7.99968 18.6765 6.87464 18.1944C6.75533 18.1431 6.64039 18.0802 6.51808 18.037C6.41021 17.9989 6.38734 17.9333 6.38734 17.8269C6.39006 14.2603 6.38675 10.6941 6.39065 7.12753C6.39292 4.9859 7.8065 3.2331 9.90899 2.77462C10.2516 2.70005 10.6111 2.67464 10.963 2.67405C13.016 2.67047 15.0693 2.68167 17.1224 2.68556C17.2438 2.68586 17.3233 2.68706 17.3233 2.85339C17.3186 10.1726 17.3225 17.4917 17.3147 24.811C17.3135 26.0589 16.8158 27.1121 15.9008 27.9612C15.0533 28.7476 14.035 29.1303 12.8783 29.1345C10.7837 29.1423 8.68872 29.1393 6.5939 29.1408C6.53237 29.1408 6.47099 29.1408 6.39952 29.1408C6.39952 26.0451 6.39952 22.9626 6.39952 19.8624C6.66311 19.9186 6.92383 19.9774 7.18561 20.0291C8.04947 20.199 8.92011 20.3305 9.80067 20.3584C10.5546 20.382 11.3115 20.3931 12.0641 20.3551C12.9987 20.3081 13.9124 20.1307 14.7529 19.6907C15.8277 19.1281 16.4069 18.2367 16.4281 17.0346C16.4587 15.2907 16.4468 13.5463 16.4539 11.8022C16.4542 11.7103 16.4451 11.6468 16.3212 11.6471C15.1122 11.652 13.903 11.6516 12.6942 11.6532C12.6692 11.6532 12.6442 11.6583 12.5994 11.6632Z"
      fill="#0152CC"
    />
    <path
      d="M18.689 18.983C18.828 19.0834 18.9502 19.1783 19.0785 19.2632C19.8464 19.7704 20.705 20.0401 21.603 20.2028C22.8359 20.4258 24.0798 20.4388 25.324 20.3512C26.3567 20.2788 27.3744 20.0961 28.3916 19.852C28.3916 19.2372 28.3916 18.6235 28.3916 17.9947C28.3517 18.0081 28.3137 18.0168 28.2795 18.0334C27.44 18.4429 26.5655 18.7471 25.6374 18.8798C24.7272 19.0099 23.8202 19.0345 22.9465 18.6827C21.9379 18.2764 21.3871 17.5149 21.2379 16.4568C21.1609 15.913 21.1816 15.3716 21.3471 14.8443C21.6915 13.7494 22.4865 13.1618 23.5814 12.9397C24.615 12.7301 25.6365 12.8565 26.6329 13.1494C27.1778 13.3096 27.6993 13.5496 28.2308 13.7546C28.2824 13.7743 28.3318 13.7999 28.3933 13.8278C28.3978 13.7793 28.4026 13.7492 28.4026 13.7193C28.4032 13.1758 28.4017 12.6322 28.4043 12.0888C28.4049 11.9996 28.3873 11.9543 28.2848 11.9304C26.744 11.5723 25.1859 11.3688 23.6008 11.4154C22.4522 11.4492 21.3174 11.5756 20.2292 11.9731C19.6782 12.1743 19.1672 12.4476 18.6943 12.8324C18.6882 12.7761 18.6805 12.7389 18.6805 12.7018C18.68 10.8332 18.6727 8.96472 18.6822 7.09627C18.6927 4.97227 20.1102 3.22636 22.1954 2.77056C22.528 2.69763 22.8766 2.66953 23.218 2.66788C25.2968 2.65847 27.376 2.66399 29.4552 2.65847C29.6101 2.65788 29.642 2.70659 29.642 2.85125C29.6386 10.1027 29.6395 17.3543 29.6384 24.6059C29.6383 25.8911 29.2072 27.0072 28.2707 27.9068C27.5365 28.612 26.6537 29.0194 25.6325 29.1147C25.4309 29.1335 25.2273 29.1404 25.0248 29.1404C22.9769 29.1424 20.9291 29.1416 18.8812 29.1416C18.8201 29.1416 18.7589 29.1416 18.6891 29.1416C18.689 25.7526 18.689 22.3797 18.689 18.983Z"
      fill="#D22F2F"
    />
    <path
      d="M30.9815 11.6533C30.9787 11.5859 30.9739 11.5305 30.9739 11.4752C30.9734 10.0261 30.9704 8.57679 30.9742 7.12751C30.9802 4.98992 32.3932 3.23503 34.4921 2.77654C34.8346 2.70182 35.1937 2.67433 35.5455 2.67359C37.5983 2.6685 39.6512 2.67792 41.7042 2.68255C41.9059 2.68329 41.9065 2.68374 41.9065 2.89162C41.9066 10.1428 41.897 17.3941 41.9127 24.6453C41.917 26.6893 40.6062 28.3348 38.9037 28.9025C38.4513 29.0532 37.9865 29.1343 37.5111 29.1354C35.3904 29.1391 33.2695 29.1373 31.1488 29.1375C31.0978 29.1375 31.0468 29.1375 30.982 29.1375C30.9788 29.068 30.9738 29.0128 30.9738 28.9574C30.9734 26.0848 30.9747 23.2123 30.9702 20.3397C30.9701 20.1891 31.0124 20.1493 31.1625 20.1501C33.3353 20.1574 35.508 20.1582 37.6808 20.1617C38.2362 20.1626 38.7826 20.1141 39.2994 19.8926C39.9362 19.6199 40.4142 19.1931 40.6187 18.5142C40.865 17.6961 40.571 16.8682 39.8642 16.3659C39.3947 16.0322 38.8622 15.875 38.2955 15.8149C38.2604 15.8114 38.2257 15.8067 38.1869 15.778C38.3271 15.7428 38.4691 15.7142 38.6068 15.6712C39.3217 15.4469 39.8776 15.0349 40.0902 14.2929C40.3772 13.291 39.9533 12.3012 38.7801 11.8745C38.2997 11.6998 37.7993 11.6526 37.2922 11.6527C35.2548 11.6533 33.2175 11.6532 31.1803 11.6533C31.1186 11.6533 31.0569 11.6533 30.9815 11.6533Z"
      fill="#25BA2F"
    />
    <path
      d="M33.1603 18.7449C33.1603 17.9811 33.1603 17.2268 33.1603 16.4628C33.2056 16.4583 33.2453 16.451 33.285 16.4509C34.0979 16.4503 34.9106 16.4473 35.7233 16.4516C36.2812 16.4543 36.7248 16.7787 36.8631 17.271C37.0761 18.0295 36.572 18.7336 35.7651 18.7557C34.9166 18.779 34.0669 18.7609 33.2179 18.7605C33.2032 18.7605 33.1886 18.7527 33.1603 18.7449Z"
      fill="#25BA2F"
    />
    <path
      d="M33.1603 15.0975C33.1603 14.3947 33.1603 13.7086 33.1603 13.0118C33.2047 13.0059 33.2441 12.9964 33.2838 12.9964C34.033 12.9958 34.7824 12.991 35.5318 12.9992C36.0469 13.005 36.4298 13.2905 36.5627 13.7427C36.7629 14.4264 36.3049 15.085 35.59 15.1032C34.8152 15.1231 34.0395 15.1104 33.2641 15.1111C33.234 15.1111 33.2039 15.1033 33.1603 15.0975Z"
      fill="#25BA2F"
    />
  </svg>
)
