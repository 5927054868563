import { Responsivo } from 'src/compartilhados'
import styled from 'styled-components'

export const Titulo = styled.h2`
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;

  ${props => Responsivo.Mobile(props)`
    font-size: 16px;
    line-height: 19px;
  `}

  ${props => Responsivo.Tablet(props)`
    font-style: italic;
    font-size: 25px;
    line-height: 29px;
  `}
`
