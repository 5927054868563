import React from 'react'

import PropTypes from 'prop-types'

import { IconeCheck } from '../icones'
import { Container } from './styles'
import { CheckboxProps } from './tipos'

export const Checkbox: React.FC<
  CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ texto, tema, tamanho, children, disabled, onClick, ...rest }) => {
  const { readOnly } = rest

  return (
    <Container
      id={`Checkbox_${rest.htmlFor}`}
      tema={tema}
      tamanho={tamanho}
      readOnly={readOnly}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <label htmlFor={rest.htmlFor}>
        <span className="seleciona-checkbox">
          {tema === 'Secundario' ? (
            <span className="check"></span>
          ) : (
            <i className="icone-check">{IconeCheck}</i>
          )}
        </span>
      </label>
      {texto && <label htmlFor={rest.htmlFor}>{texto}</label>}
    </Container>
  )
}

Checkbox.defaultProps = {
  texto: '',
  tema: 'Padrao',
  tamanho: 'L'
}

Checkbox.propTypes = {
  texto: PropTypes.string,
  tema: PropTypes.oneOf(['Padrao', 'Secundario', 'Outro']),
  tamanho: PropTypes.oneOf(['L', 'M', 'S']),
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func
}
