export default {
  // Generic
  'generic.add': 'Adicionar',
  'generic.cancel': 'Cancelar',

  // BlockType
  'components.controls.blocktype.h1': 'Título 1',
  'components.controls.blocktype.h2': 'Título 2',
  'components.controls.blocktype.h3': 'Título 3',
  'components.controls.blocktype.h4': 'Título 4',
  'components.controls.blocktype.h5': 'Título 5',
  'components.controls.blocktype.h6': 'Título 6',
  'components.controls.blocktype.blockquote': 'Citação',
  'components.controls.blocktype.code': 'Código',
  'components.controls.blocktype.blocktype': 'Estilo',
  'components.controls.blocktype.normal': 'Normal',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Cor',
  'components.controls.colorpicker.text': 'Fonte',
  'components.controls.colorpicker.background': 'Fundo',

  // Embedded
  'components.controls.embedded.embedded': 'Embedded',
  'components.controls.embedded.embeddedlink': 'Embedded Link',
  'components.controls.embedded.enterlink': 'Enter link',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Fonte',

  // FontSize
  'components.controls.fontsize.fontsize': 'Tamanho da fonte',

  // History
  'components.controls.history.history': 'Histórico',
  'components.controls.history.undo': 'Desfazer',
  'components.controls.history.redo': 'Refazer',

  // Image
  'components.controls.image.image': 'Imagem',
  'components.controls.image.fileUpload': 'Upload de imagem',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText':
    'Largue o arquivo aqui ou clique para fazer upload',

  // Inline
  'components.controls.inline.bold': 'Negrito',
  'components.controls.inline.italic': 'Itálico',
  'components.controls.inline.underline': 'Sublinhado',
  'components.controls.inline.strikethrough': 'Riscado',
  'components.controls.inline.monospace': 'Mono espaçada',
  'components.controls.inline.superscript': 'Sobrescrito',
  'components.controls.inline.subscript': 'Subscrito',

  // Link
  'components.controls.link.linkTitle': 'Texto do link',
  'components.controls.link.linkTarget': 'URL do link',
  'components.controls.link.linkTargetOption': 'Abrir em uma nova janela',
  'components.controls.link.link': 'Link',
  'components.controls.link.unlink': 'Remover link',

  // List
  'components.controls.list.list': 'Lista',
  'components.controls.list.unordered': 'Lista com marcadores',
  'components.controls.list.ordered': 'Lista numerada',
  'components.controls.list.indent': 'Aumentar indentação',
  'components.controls.list.outdent': 'Diminuir indentação',

  // Remove
  'components.controls.remove.remove': 'Remover estilo da fonte',

  // TextAlign
  'components.controls.textalign.textalign': 'Alinhamento',
  'components.controls.textalign.left': 'Alinhar à esquerda',
  'components.controls.textalign.center': 'Alinhar ao centro',
  'components.controls.textalign.right': 'Alinhar à direita',
  'components.controls.textalign.justify': 'Justificar'
}
