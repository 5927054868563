import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  CheckboxUnform,
  FormUnform,
  ModalBase,
  TextAreaUnform
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
import { AlterarCobranca } from 'src/tipos'

import { schema } from './schema'
import {
  ContainerConteudo,
  ContainerModal,
  TituloPrincipal,
  ContainerBotoes
} from './styles'
import { ModalAlterarObservacaoProps, ModalAlterarObservacaoRef } from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalAlterarObservacaoRef,
  ModalAlterarObservacaoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const [aberta, definirAberta] = useState(false)
  const [observacaoInterna, definirObservacaoInterna] = useState(false)
  const [obsInterna, definirObsInterna] = useState('-')
  const [obs, definirObs] = useState('-')

  const atualizarValorFormulario = async (campo: string, valor: any) => {
    if (!valor) return
    formRef?.current.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const fechar = () => {
    definirAberta(false)
    definirObservacaoInterna(false)
    formRef.current?.reset()
  }

  const abrir = (obs?: string, obsInterna?: string) => {
    definirAberta(true)
    definirObs(obs)
    definirObsInterna(obsInterna)
    atualizarValorFormulario('observacao', obs)
  }

  const acaoSucessoForm = async (dados: AlterarCobranca) => {
    await acaoPrimaria(dados)
    fechar()
    formRef.current?.reset()
  }

  useImperativeHandle<ModalAlterarObservacaoRef, ModalAlterarObservacaoRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  useEffect(() => {
    if (observacaoInterna) {
      atualizarValorFormulario('observacao', obsInterna ?? '-')
    } else {
      atualizarValorFormulario('observacao', obs ?? '-')
    }
  }, [observacaoInterna])

  return (
    <ModalBase
      id="modal-alterar-observacao"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Alterar Observação</TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm} schema={schema}>
          <ContainerConteudo>
            <TextAreaUnform
              name="observacao"
              label="Observação"
              maxLength={200}
            />
            <CampoLabel>Interna</CampoLabel>
            <CheckboxUnform
              id="chk_observacao_interna"
              name="observacaoInterna"
              onChange={() => definirObservacaoInterna(old => !old)}
              checked={observacaoInterna}
            />
          </ContainerConteudo>
          <ContainerBotoes>
            <Botao
              texto="Voltar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Enviar" />
          </ContainerBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalAlterarObservacao = forwardRef(Modal)
