import React, { useState, forwardRef, useEffect, useMemo } from 'react'
import ReactInputMask from 'react-input-mask'
import MaskedInput from 'react-text-mask'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { Botao } from '../botao'
import { IconeOcultarSenha, IconeRevelarSenha } from '../icones'
import { Tooltip } from '../tooltip'
import { inputTextoNaoInformado } from '../utils/constants'
import { decimalOpcoes, moedaOpcoes } from './moeda'
import {
  Componente,
  CampoLabel,
  CampoInput,
  CampoIcone,
  BotaoContainer,
  CampoDescricao,
  CampoReadOnly,
  Obrigatorio,
  Validacoes
} from './styles'
import { InputProps } from './tipos'
import { Validacao } from './validacao'

export const Input: React.ForwardRefExoticComponent<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      icone,
      textoTooltip,
      erro,
      id,
      label,
      tamanho,
      descricao,
      maxWidthInput,
      mascara,
      moeda,
      ladoIcone,
      onClickIcone,
      obrigatorio,
      maxLength,
      sucesso,
      semValidacaoSenha,
      onChange,
      bloquearCaracteresEspeciais,
      ...rest
    },
    ref?: any
  ) => {
    const { disabled, type, readOnly, children, ...props } = rest

    const mascaraMoeda = createNumberMask({
      ...moedaOpcoes
    })

    const mascaraDecOp = createNumberMask({
      ...decimalOpcoes
    })

    const [ocultarRevelar, setOcultarRevelar] = useState<JSX.Element>(
      IconeRevelarSenha
    )
    const [textoTooltipSenha, setTextoToolTipSenha] = useState('Mostrar senha')
    const [tipoDoInput, setTipoInput] = useState<string | undefined>(type)
    const [valor, definirValor] = useState('')

    const mostrarSenha = () => {
      if (disabled) return

      if (tipoDoInput === 'password') {
        setTipoInput('text')
        setOcultarRevelar(IconeOcultarSenha)
        setTextoToolTipSenha('Esconder senha')
      } else {
        setTipoInput('password')
        setOcultarRevelar(IconeRevelarSenha)
        setTextoToolTipSenha('Mostrar senha')
      }
    }

    useEffect(() => {
      if ((props.value && props.value !== valor) || props.value === '') {
        definirValor(`${props.value}`)
      }
    }, [props.value])

    const mascaraSemCararcteresEspeciais = useMemo(() => {
      if (bloquearCaracteresEspeciais) {
        let mascara = ''

        let i = 0
        while (i < maxLength) {
          mascara += '*'
          i += 1
        }

        return mascara
      }
    }, [bloquearCaracteresEspeciais, maxLength])

    return (
      <Componente
        maxWidthInput={maxWidthInput}
        tamanho={tamanho}
        readOnly={readOnly}
      >
        {label && (
          <CampoLabel
            temDescricao={!!descricao}
            htmlFor={id}
            readOnly={readOnly}
          >
            {obrigatorio ? <Obrigatorio>*</Obrigatorio> : <></>}
            {label}
          </CampoLabel>
        )}
        {descricao && (
          <CampoDescricao readOnly={readOnly}>{descricao}</CampoDescricao>
        )}
        <CampoInput
          maxWidthInput={maxWidthInput}
          comIcone={!!icone}
          tamanho={tamanho}
          ladoIcone={ladoIcone}
          theme={{
            Desabilitado: disabled,
            Icone: icone,
            Erro: erro,
            ModoVisualizacao: readOnly,
            Sucesso: sucesso
          }}
        >
          {mascara || bloquearCaracteresEspeciais ? (
            <ReactInputMask
              id={id}
              mask={
                bloquearCaracteresEspeciais
                  ? mascaraSemCararcteresEspeciais
                  : mascara
              }
              maskChar={bloquearCaracteresEspeciais ? '' : undefined}
              alwaysShowMask={false}
              ref={ref}
              disabled={disabled}
              readOnly={readOnly}
              type={type}
              {...props}
              placeholder={
                readOnly ? inputTextoNaoInformado : props.placeholder
              }
              onChange={e => {
                if (onChange) onChange(e)
              }}
            />
          ) : moeda && !mascara ? (
            <MaskedInput
              id={id}
              mask={type === 'espec' ? mascaraDecOp : mascaraMoeda}
              ref={ref}
              disabled={disabled}
              readOnly={readOnly}
              type={type}
              {...props}
              placeholder={
                readOnly ? inputTextoNaoInformado : props.placeholder
              }
              onChange={e => {
                if (onChange) onChange(e)
              }}
            />
          ) : !mascara && !moeda ? (
            <input
              id={id}
              ref={ref}
              maxLength={maxLength}
              disabled={disabled}
              readOnly={readOnly}
              style={readOnly ? { display: 'contents' } : undefined}
              onChange={e => {
                if (type !== 'password') {
                  if (bloquearCaracteresEspeciais) {
                    const regex = new RegExp(/^[a-zA-Z0-9]+$/)
                    if (!regex.test(e.target.value)) return
                  }
                }
                definirValor(e.target.value)

                if (onChange) onChange(e)
              }}
              {...props}
              type={tipoDoInput}
            />
          ) : (
            <></>
          )}
          {children}
          {readOnly && !mascara && !moeda && (
            <CampoReadOnly
              id={`sp-${id}`}
              temValor={
                !!ref.current?.value || !!props.value || !!props.defaultValue
              }
            >
              {ref.current?.value ||
                props.value ||
                props.defaultValue ||
                inputTextoNaoInformado}
            </CampoReadOnly>
          )}

          {icone && !readOnly && (
            <div>
              <CampoIcone
                temAcaoClick={!disabled && !!onClickIcone}
                onClick={!disabled ? onClickIcone : undefined}
                desabilitado={disabled}
                tamanho={tamanho}
                data-tip={textoTooltip}
                data-for={`${id}-tooltip-icone`}
                className="icone"
              >
                {icone}
              </CampoIcone>
              {!!textoTooltip && (
                <Tooltip id={`${id}-tooltip-icone`} place="bottom" />
              )}
            </div>
          )}
          {type === 'password' && !readOnly && (
            <BotaoContainer desabilitado={disabled} tamanho={tamanho}>
              <Botao
                type="button"
                disabled={disabled}
                tema="Outro"
                elemento={ocultarRevelar}
                tooltip={{
                  id: `${id}-tooltip-senha`,
                  texto: textoTooltipSenha,
                  lado: 'bottom'
                }}
                onClick={mostrarSenha}
                onKeyDown={mostrarSenha}
                tabIndex={-1}
              />
            </BotaoContainer>
          )}
        </CampoInput>
        {type === 'password' && !readOnly && !semValidacaoSenha && (
          <Validacoes>
            <Validacao valor={valor} regex={/.{8,}/}>
              A senha deve possuir ao menos 8 caracteres.
            </Validacao>
            <Validacao valor={valor} regex={/(?=.*[a-z])/}>
              Deve possuir ao menos uma letra minúscula.
            </Validacao>
            <Validacao valor={valor} regex={/(?=.*?[0-9])/}>
              Deve possuir ao menos um número.
            </Validacao>
            <Validacao valor={valor} regex={/(?=.*[A-Z])/}>
              Deve possuir ao menos uma letra maiúscula.
            </Validacao>
          </Validacoes>
        )}
      </Componente>
    )
  }
)

export * from './cartao-credito'
export * from './cpf'
export * from './cnpj'
export * from './cpf-cnpj'
export * from './input-mes-ano'
export * from './textarea'
export * from './tipos'
