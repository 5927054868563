import { Endereco } from './endereco'
import {
  Escolaridade,
  EstadoCivil,
  Raca,
  Sexo,
  TipoUsuario,
  UsuarioSituacao
} from './enums'

export enum UsuarioPerfil {
  Aluno = 0,
  Academico = 1,
  Professor = 2,
  Gerencial = 3,
  Financeiro = 4,
  Comercial = 5,
  Parceiro = 6,
  ResponsavelFinanceiro = 7
}

export interface TurmaParceiro {
  turmaId: string
  usuarioId: string
  turmaNome?: string
}

export interface Usuario {
  id?: string
  nome: string
  nomeSocial?: string
  nomeApresentacao: string
  foto?: string
  senha: string
  email: string
  situacao: UsuarioSituacao | string
  nacionalidade?: string
  cpf?: string
  documentoIdentidade?: string
  codigoConselho?: string
  orgaoEmissor?: string
  dataNascimento?: string
  sexo?: Sexo
  generoOutros?: string
  estadoCivil?: EstadoCivil
  escolaridade?: Escolaridade
  profissao?: string
  profissaoOutros?: string
  telefone?: string
  celular?: string
  corRaca?: keyof typeof Raca
  perfis: Array<keyof typeof UsuarioPerfil>
  mesAnoColacaoGrau?: string
  tipoUsuario: TipoUsuario
  cnpj?: string
  inscricaoEstadual?: string
  inscricaoMunicipal?: string
  endereco?: Endereco
  turmasParceiro?: TurmaParceiro[]
  matriculaPos?: boolean
  alunoId?: string
  habilitarReenvioToken?: boolean
}

export interface RespostaCriarUsuarioAPI {
  success: boolean
  data: string
}

export interface RespostaCarregarFoto {
  foto: string
}

export interface CpfCnpjUsuario {
  cpf: string
  cnpj: string
  senha: string
}

export enum IntegracaoOMIE {
  Existente = 'Existente',
  Criado = 'Criado',
  Invalido = 'Invalido',
  Erro = 'Erro',
  TipoContaInvalido = 'TipoContaInvalido'
}
