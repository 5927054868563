import React, { useEffect, useRef } from 'react'

import { useField } from '@unform/core'

import { Seletor } from '../../seletor'
import { SeletorOpcoes } from '../../seletor/tipos'
import { Componente, Erro } from './styles'
import { SeletorUnformProps, SeletorUnformValor } from './tipos'

export function SeletorUnform<T extends SeletorUnformValor>({
  id,
  tipo,
  seletores,
  name,
  label,
  disabled,
  obrigatorio,
  readOnly,
  converterParaNumero,
  valorAlterado
}: SeletorUnformProps<T>): JSX.Element {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField<T[] | T>({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        if (tipo === 'checkbox') {
          return refs
            .filter(ref => ref.checked)
            .map(
              ref => (converterParaNumero ? Number(ref.value) : ref.value) as T
            )
        }

        const valor = refs.filter(ref => ref?.checked)[0]?.value
        return (converterParaNumero ? Number(valor) : valor) as T
      },
      clearValue: (refs: HTMLInputElement[]) => {
        return refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], values: T[] | T) => {
        refs.forEach(ref => {
          if (tipo === 'checkbox') {
            if ((values as T[]).includes(ref.id as T)) {
              ref.checked = true
            }
          } else if (ref.id === values) {
            ref.checked = true
          }
        })
      }
    })
  }, [defaultValue, fieldName, registerField])

  return (
    <Componente id={id} theme={{ ComErro: !!error }}>
      <Seletor
        name={name}
        tipo={tipo}
        seletores={seletores as SeletorOpcoes<T>[]}
        inputRefs={inputRefs}
        valores={defaultValue}
        label={label}
        disabled={disabled}
        obrigatorio={obrigatorio}
        readOnly={readOnly}
        valorAlterado={(
          itensMarcados: SeletorUnformValor[] | SeletorUnformValor
        ) => {
          clearError()
          valorAlterado && valorAlterado(itensMarcados)
        }}
      />
      <Erro id={`erro-${id}`}>{error}</Erro>
    </Componente>
  )
}
