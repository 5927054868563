export enum UF {
  Acre = 'AC',
  Alogas = 'AL',
  Amapa = 'AP',
  Amazonas = 'AM',
  Bahia = 'BA',
  Ceara = 'CE',
  EspiritoSanto = 'ES',
  Goias = 'GO',
  Maranhao = 'MA',
  MatoGrosso = 'MT',
  MatoGrossoDoSul = 'MS',
  MinasGerais = 'MG',
  Para = 'PA',
  Paraiba = 'PB',
  Parana = 'PR',
  Pernambuco = 'PE',
  Piaui = 'PI',
  RioDeJaneiro = 'RJ',
  RioGrandeDoNorte = 'RN',
  RioGrandeDoSul = 'RS',
  Rondonia = 'RO',
  Roraima = 'RR',
  SantaCatarina = 'SC',
  SaoPaulo = 'SP',
  Sergipe = 'SE',
  Tocantins = 'TO',
  DistritoFederal = 'DF'
}

export const selectUfOptions = Object.entries(UF).map(x => {
  const [id, texto] = x
  return {
    id,
    texto
  }
})
