import styled, { css } from 'styled-components'

import { Cores } from '../../styles'
import { ContainerExplicacaoProps } from './tipos'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.CINZA_1_CLARO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 11px 7px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 25px;

  .radio-input {
    margin-bottom: 11px;
  }

  .radio-input:last-of-type {
    margin: 0px;
  }

  ${({ theme: { ComErro } }) =>
    ComErro &&
    css`
      border: 1px solid ${Cores.PERIGO_ERRO};

      span {
        opacity: 1;
        bottom: -20px;
      }
    `}
`

export const Enunciado = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  white-space: pre-line;
  color: ${Cores.CINZA_2};
  margin-bottom: 10px;
`

export const Conteudo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Erro = styled.span`
  color: ${Cores.PERIGO_ERRO};
  font-size: 10px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  white-space: pre-line;
  opacity: 0;
  transition: opacity 0.2s ease-in, bottom 0.3s ease-in;
`

export const ObservacaoAnulada = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  white-space: pre-line;
  color: #06d15b;
  margin-bottom: 10px;
`
export const Atencao = styled.span`
  color: ${Cores.PERIGO_ERRO};
  margin-right: 5px;
`

export const ContainerExplicacao = styled.div<ContainerExplicacaoProps>`
  width: 100%;
  padding: 14px;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid
    ${props => (props.tema === 'SUCESSO' ? Cores.SUCESSO : Cores.PERIGO_ERRO)};
  background-color: ${props =>
    props.tema === 'SUCESSO'
      ? Cores.PRORROGACAO_CORRETA
      : Cores.PRORROGACAO_INCORRETA};
  p {
    display: flex;
  }
`

export const ContainerIcone = styled.span`
  display: flex;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 10px;
    path {
      fill: ${props => props.color};
    }
  }
`
