import styled from 'styled-components'

import { Cores, Medias } from '../styles'

export const Corpo = styled.div`
  max-width: 574px;
  min-width: unset !important;
  width: 90%;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 41px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Conteudo = styled.div`
  width: 70%;

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 60px;
  }

  form {
    width: 100%;
  }

  @media ${Medias.MOBILE} {
    width: 90%;

    h5 {
      font-size: 16px;
      line-height: 16px;
    }
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 23px;

  & > #btn-enviar {
    margin-left: 19px;
    font-weight: 500;
    width: 180px;
  }

  @media ${Medias.MOBILE} {
    flex-direction: column;

    & > #btn-enviar {
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
    }
  }
`

export const Link = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 27px;

  button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
  }
`
