import { Medias } from 'src/componentes/styles/breakpoints'
import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 2px 0px;
  width: 100%;
`

export const Titulo = styled.h1`
  font-weight: 300;
  font-size: 30px;
  line-height: 35.16px;

  @media ${Medias.MOBILE} {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
`

export const Conteudo = styled.div`
  display: flex;
  margin-top: 23px;
`

export const ContainerCursos = styled.div`
  width: 100%;
`
export const ListaCarrinhoCompra = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & > div:last-child {
    margin-right: 0px;
  }
`

export const ListaCursos = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & > div:last-child {
    margin-right: 0px;
  }
`

export const SubTituloContainer = styled.div`
  display: flex;
  margin-bottom: 9px;

  @media ${Medias.MOBILE} {
    margin-bottom: 20px;
  }
`

export const SubTituloIcone = styled.div`
  display: flex;
  margin-right: 4px;
`

export const SubTitulo = styled.h3`
  font-size: 18px;
  font-weight: 300;

  @media ${Medias.MOBILE} {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }
`
export const CursoExpandido = styled.div`
  width: 100%;
  border-radius: 3px;
  padding: 25px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  position: relative;
  padding-bottom: 30px;

  & > div {
    border-left: 4px solid;
    padding-left: 17px;

    h4 {
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    h5 {
      height: 70px;
      line-height: 27px;
      font-size: 18px;
      font-weight: 300;
    }

    a {
      font-size: 16px;
      font-weight: 500;
      color: ${Cores.SECUNDARIA_2_ESCURO};
    }
  }

  b {
    font-weight: 500;
  }

  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  svg path {
    fill: #009b9b;
  }
  div {
    border-color: ${Cores.SECUNDARIA_2};
    h4 {
      color: ${Cores.SECUNDARIA_2};
    }
  }
`
export const ContainerIcones = styled.span`
  display: flex;
  flex-direction: column;
  color: #009b9b;
  margin-top: 20px;
  padding-left: 21px;
  div {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 12px;
  }
`
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
  p {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
    b {
      opacity: 1;
      color: #3d3d3d;
      font-weight: 400;
    }
  }
  span {
    margin-right: 5px;
    margin-top: 2px;
  }
`
export const ConteudoBotao = styled.div`
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
`
export const ConteudoIcone = styled.span`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`
export const ContainerBotao = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  @media ${Medias.MOBILE} {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`

export const ContainerNovaCompra = styled.div``

export const ContainerBotaoAlteracaoCobranca = styled.div`
  &:not(:first-child) {
    margin-left: 10px;
  }
`

export const ContainerBotaoVoltar = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media ${Medias.MOBILE} {
    margin-top: 5px;
  }
`

export const CursoContainer = styled.div`
  width: 100%;
  border-radius: 3px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px 25px 20px 25px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;

  @media ${Medias.MOBILE} {
    min-height: 320px;
    padding: 15px;
  }

  & > div {
    border-left: 4px solid;
    padding-left: 17px;

    h4 {
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    h5 {
      height: 70px;
      line-height: 27px;
      font-size: 18px;
      font-weight: 300;
    }

    a {
      font-size: 16px;
      font-weight: 500;
      color: ${Cores.SECUNDARIA_2_ESCURO};
    }
  }

  b {
    font-weight: 500;
    margin-right: 5px;
  }

  p {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
  }

  svg path {
    fill: #009b9b;
  }

  div {
    border-color: ${Cores.SECUNDARIA_2};

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media ${Medias.MOBILE} {
        flex-direction: column;
      }

      span {
        padding-right: 20px;
        flex: 1;

        @media ${Medias.MOBILE} {
          padding: 0;
        }

        & > p:last-child {
          margin-bottom: 0px;

          @media ${Medias.MOBILE} {
            margin-bottom: 10px;
          }
        }

        button {
          display: flex;
          align-self: flex-start;
        }

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 280px;
        }

        p:hover {
          overflow: visible;
          white-space: normal;
          height: auto;
        }
      }
    }

    h4 {
      color: ${Cores.SECUNDARIA_2};
    }
  }
`

export const ContainerResponsavel = styled.div`
  width: 100%;
  border-radius: 3px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px 25px 20px 25px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
`

export const ContainerBotoesResponsavel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export const IconeTabela = styled.span`
  svg path {
    fill: #666666;
  }
`

export const AvisoResponsavel = styled.p`
  color: #e6414b;
  text-align: center;
`
