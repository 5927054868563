import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { hotjar } from 'react-hotjar'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  IconeGerarBoleto,
  IconeInformacao,
  IconeModalSucesso,
  IconePagamentoMatricula,
  IconeRecibo,
  Modal,
  ModalRef,
  AutenticacaoContainer,
  ModalVisualizacaoPdfRef,
  ModalVisualizacaoPdf,
  Visao
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { getApiError } from 'src/servicos/instancias-api'
import {
  BaixaManualTaxaMatricula,
  DadosFinanceirosDoCursoLivre,
  MetodosPagamento,
  ModalidadeCurso,
  SituacaoDaCompraComCartaoDeCredito
} from 'src/tipos'

import { ModalPagamentoCartao } from '../componentes'
import {
  ContainerBotao,
  ContainerIcones,
  ContainerInfo,
  ConteudoBotao,
  CursoExpandido
} from '../styles'
import { FinanceiroProps } from '../tipos'
import {
  ModalBaixaManualTaxaMatricula,
  ModalBaixaManualTaxaMatriculaRef
} from './componentes/modal-baixa-manual-taxa-matricula'
import { formatadores } from './formatadores'
import { FormularioDePagamentoMapper } from './formulario-de-pagamento-mapper'
import { ContainerTitulo, ParagrafoDeAviso } from './styles'

const mensagemDeErroDoCartaoDeCredito =
  'Não foi possível efeturar o pagamento, tente novamente.'

const mensagemDeErroBoleto = 'Erro ao obter boleto'

const mensagemDeErroRecibo = 'Erro ao obter recibo'

const Livre: FC<FinanceiroProps> = ({ match }) => {
  const [
    cenarioFinanceiro,
    definirCenarioFinanceiro
  ] = useState<DadosFinanceirosDoCursoLivre>()
  const { perfil, visao } = AutenticacaoContainer.useContainer()
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState<boolean>(false)
  const history = useHistory()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()
  const modalRef = useRef<ModalRef>(null)
  const modalSucesso = useRef<ModalRef>(null)
  const { matricula, modalidade, nomeDoCurso, usuarioId } = match.params
  const modalBaixaManualTaxaMatriculaRef = useRef<ModalBaixaManualTaxaMatriculaRef>(
    null
  )
  const modalVisualizacaoRef = useRef<ModalVisualizacaoPdfRef>(null)
  const ehPerfilFinanceiro = useMemo(
    () => [...perfil?.perfis].findIndex(p => p === 'Financeiro') > -1,
    [perfil?.perfis]
  )

  const visaoIPGS = useMemo(() => visao === Visao.IPGS, [visao])

  const obterCenarioFinanceiro = async (
    idDaMatricula: string
  ): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const resposta = await Api.ObterDadosFinanceirosDoCursoLivre(
        idDaMatricula
      )
      definirCenarioFinanceiro(resposta)
    } catch (erro) {
      const mensagens = 'Ops! Houve algum problema no carregamento dos dados.'
      let acaoVoltar = () => history.push(RotasAluno.PainelFinanceiro)

      if (visaoIPGS) {
        acaoVoltar = () =>
          history.push(`${RotasAluno.PainelFinanceiro}/${usuarioId}`)
      }

      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const gerarBoleto = async (): Promise<void> => {
    try {
      definirPronto(false)
      const resposta = await Api.ObterBoletoParaPagamentoDoCurso(
        matricula,
        cenarioFinanceiro.cobrancaId
      )

      if (resposta) {
        window.open(resposta.linkBoleto)
      }
      definirPronto(true)
    } catch (erro) {
      definirPronto(true)
      toast(mensagemDeErroBoleto, { type: 'error' })
    }
  }

  const abrirModalBaixaManualTaxaMatricula = async () => {
    const valorTotal = cenarioFinanceiro.saldoDevedor
    modalBaixaManualTaxaMatriculaRef.current?.abrir(valorTotal, matricula)
  }

  const baixaManualTaxaMatricula = async (
    dados: BaixaManualTaxaMatricula
  ): Promise<void> => {
    try {
      const resposta = await Api.BaixaManualMatricula(
        dados,
        cenarioFinanceiro.cobrancaId
      )

      if (!resposta) {
        throw new Error(
          'Não foi possível realizar a baixa da taxa da matrícula'
        )
      }

      await obterCenarioFinanceiro(matricula)
      toast('Baixa da taxa da matrícula realizada com sucesso.', {
        type: 'success'
      })
    } catch (erro) {
      const mensagem =
        getApiError(erro) ||
        'Não foi possível realizar a baixa da taxa da matrícula.'

      toast(mensagem, { type: 'error' })
    }
  }

  const pagamentoComCartaoDeCredito = async (dadosDoCartao: {
    [key: string]: string
  }) => {
    try {
      definirPronto(false)
      const pagamento = FormularioDePagamentoMapper(
        matricula,
        cenarioFinanceiro.cobrancaId,
        dadosDoCartao
      )

      const {
        situacao,
        valorPago
      } = await Api.EfetuarPagamentoDoCursoComCartaoDeCredito(pagamento)

      const pagamentoAprovado =
        situacao === SituacaoDaCompraComCartaoDeCredito.Ativo && valorPago > 0

      pagamentoAprovado
        ? modalSucesso?.current?.abrir()
        : toast(mensagemDeErroDoCartaoDeCredito, { type: 'error' })

      definirPronto(true)
      obterCenarioFinanceiro(matricula)
    } catch (erro) {
      definirPronto(true)
      toast(mensagemDeErroDoCartaoDeCredito, { type: 'error' })
    }
  }

  const obterRecibo = async () => {
    try {
      definirCarregando(true)
      const arquivo = await Api.ObterArquivoRecibo(
        matricula,
        cenarioFinanceiro.cobrancaId
      )
      if (arquivo) {
        modalVisualizacaoRef?.current?.abrir(arquivo)
      }
    } catch (erro) {
      toast(mensagemDeErroRecibo, { type: 'error' })
      const acaoVoltar = () => history.push(RotasAluno.FinanceiroCursoLivre)
      definirErro({ mensagens: mensagemDeErroRecibo, acaoVoltar })
    } finally {
      definirCarregando(false)
    }
  }

  const atalhos = [
    {
      texto: 'Financeiro',
      acao: () => history.goBack()
    },
    {
      texto: 'Painel Financeiro'
    }
  ]

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR, 6)
    }
    obterCenarioFinanceiro(matricula)
  }, [match])

  return pronto && !erro ? (
    <>
      {!carregando ? (
        <>
          {' '}
          <ContainerTitulo>
            <Breadcrumb
              titulo={`${formatadores.curso(modalidade)} - ${nomeDoCurso}`}
              atalhos={atalhos}
            />
          </ContainerTitulo>
          <CursoExpandido>
            <div>
              <h4>
                {modalidade === ModalidadeCurso.Livre ? 'Curso' : 'Evento'}
              </h4>
              <p>
                <b>Valor: </b>{' '}
                {formatadores.moeda(cenarioFinanceiro.valorTotalCurso)}
              </p>
              <p>
                <b>Cupom de desconto: </b>{' '}
                {formatadores.moeda(cenarioFinanceiro.valorCupomDesconto)}
              </p>
              <p>
                <b> Saldo devedor: </b>
                {formatadores.moeda(cenarioFinanceiro.saldoDevedor)}
              </p>
              <p>
                <b>Quantidade de parcelas: </b>{' '}
                {cenarioFinanceiro.quantidadeParcelas}
              </p>
              {cenarioFinanceiro.saldoDevedor === 0 ? (
                <p>
                  <b>Data de pagamento: </b>{' '}
                  {formatadores.data(cenarioFinanceiro.dataPagamento)}
                </p>
              ) : (
                <p>
                  <b>Data de vencimento: </b>{' '}
                  {formatadores.data(cenarioFinanceiro.dataVencimento)}
                </p>
              )}
              <p>
                <b>Forma de pagamento: </b>{' '}
                {formatadores.pagamento(cenarioFinanceiro.formaPagamento)}
              </p>
            </div>
            <ContainerIcones>
              {cenarioFinanceiro.saldoDevedor === 0 ? (
                <div>
                  {cenarioFinanceiro.formaPagamento !==
                    MetodosPagamento.Isencao && (
                    <Botao
                      type="button"
                      tema="Link"
                      elemento={
                        <>
                          {IconeRecibo}
                          <ConteudoBotao>Imprimir Recibo</ConteudoBotao>
                        </>
                      }
                      onClick={obterRecibo}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div>
                    {ehPerfilFinanceiro && (
                      <Botao
                        type="button"
                        tema="Link"
                        elemento={
                          <>
                            {IconeGerarBoleto}
                            <ConteudoBotao>
                              Baixa manual da cobrança
                            </ConteudoBotao>
                          </>
                        }
                        onClick={abrirModalBaixaManualTaxaMatricula}
                      />
                    )}
                  </div>
                  <div>
                    <Botao
                      type="button"
                      tema="Link"
                      elemento={
                        <>
                          {IconeGerarBoleto}
                          <ConteudoBotao>Gerar o Boleto</ConteudoBotao>
                        </>
                      }
                      onClick={gerarBoleto}
                    />
                  </div>
                  <div>
                    <Botao
                      type="button"
                      tema="Link"
                      elemento={
                        <>
                          {IconePagamentoMatricula}
                          <ConteudoBotao>
                            Pagar com cartão de crédito
                          </ConteudoBotao>
                        </>
                      }
                      onClick={() => {
                        modalRef?.current?.abrir()
                      }}
                    />
                  </div>
                </>
              )}
            </ContainerIcones>
          </CursoExpandido>
          <ContainerInfo>
            <span>{IconeInformacao}</span>
            <ParagrafoDeAviso>
              Caso o pagamento fique duplicado, será necessário entrar em
              contato com o setor financeiro através do e-mail{' '}
              <a href="mailto:financeiro@ipgs.com.br">financeiro@ipgs.com.br</a>
            </ParagrafoDeAviso>
          </ContainerInfo>
          <ContainerBotao>
            <Botao
              type="button"
              texto="Voltar"
              tema="Secundario"
              onClick={() => {
                if (visaoIPGS) {
                  history.push(`${RotasAluno.PainelFinanceiro}/${usuarioId}`)
                } else {
                  history.push(RotasAluno.PainelFinanceiro)
                }
              }}
            />
          </ContainerBotao>
        </>
      ) : (
        <Carregando texto="Carregando dados financeiros..." />
      )}
      <ModalPagamentoCartao
        ref={modalRef}
        backdrop
        id="modal-pagamento"
        acaoPrimaria={pagamentoComCartaoDeCredito}
      />
      <ModalBaixaManualTaxaMatricula
        ref={modalBaixaManualTaxaMatriculaRef}
        backdrop
        id="modal-baixa-manual-taxa-matricula"
        acaoPrimaria={baixaManualTaxaMatricula}
      />
      <Modal
        ref={modalSucesso}
        backdrop
        id="modal-sucesso"
        icone={IconeModalSucesso}
        titulo={'Pagamento realizado.'}
        acaoPrimaria={{
          titulo: 'Ok',
          tipo: 'button',
          acao: () => modalSucesso?.current?.fechar()
        }}
      >
        <p>Aguarde a confirmação do pagamento</p>
      </Modal>
      <ModalVisualizacaoPdf
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento-aluno"
      />
    </>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados financeiros..." />
  ) : (
    <ErroContainerLayout />
  )
}

const CursoLivre: FC<FinanceiroProps> = (props: FinanceiroProps) => (
  <ErroLayoutContainer.Provider>
    <Livre {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(CursoLivre)
