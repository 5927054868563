import React, { InputHTMLAttributes } from 'react'

import { Checkbox } from 'src/componentes/checkbox'

import { CheckboxInputUnform } from '../input/checkbox'
import { CheckboxUnformProps } from './tipos'

export const CheckboxUnform: React.FC<
  CheckboxUnformProps & InputHTMLAttributes<HTMLInputElement>
> = ({ tema, tamanho, texto, ...rest }) => (
  <Checkbox tema={tema} tamanho={tamanho} texto={texto} htmlFor={rest.id}>
    <CheckboxInputUnform {...rest} />
  </Checkbox>
)
