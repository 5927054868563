export function redutor<TProps>(
  variaveis: any[],
  props: TProps
): (estilos: string[], proximo: string, indice: number) => string[] {
  return function (estilos: string[], proximo: string, indice: number) {
    estilos.push(proximo)
    const variavel = variaveis[indice]

    if (variavel) {
      typeof variavel === 'function'
        ? estilos.push(variavel(props))
        : estilos.push(variavel)
    }

    return estilos
  }
}
