import { SituacaoFinanceiraFiltro } from 'src/tipos'

export const OPCOES_SITUACAO_FINANCEIRA = [
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Cancelado],
    texto: 'Cancelado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.ContratoRescindido],
    texto: 'Contrato Rescindido'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Finalizado],
    texto: 'Finalizado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Inativo],
    texto: 'Inativo'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Matriculado],
    texto: 'Matriculado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reprovado],
    texto: 'Reprovado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Reservado],
    texto: 'Reservado'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Transferido],
    texto: 'Transferido'
  },
  {
    id: SituacaoFinanceiraFiltro[SituacaoFinanceiraFiltro.Recuperacao],
    texto: 'Em Recuperação'
  },
  {
    id:
      SituacaoFinanceiraFiltro[
        SituacaoFinanceiraFiltro.AguardandoCartaoPagamentoRecorrente
      ],
    texto: 'Aguardando Pag. Recorrente'
  }
]
