import React from 'react'

export default (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0636 8.44643C12.5658 7.6529 12.8571 6.71875 12.8571 5.71429C12.8571 2.8817 10.5469 0.571427 7.71429 0.571427C4.8817 0.571427 2.57143 2.8817 2.57143 5.71429C2.57143 6.71875 2.86272 7.6529 3.36496 8.44643C1.94866 8.85826 0 10.2545 0 14.8248C0 16.894 1.53683 18.5714 3.42522 18.5714H12.0033C13.8917 18.5714 15.4286 16.894 15.4286 14.8248C15.4286 10.2545 13.4799 8.85826 12.0636 8.44643ZM7.71429 1.85714C9.84375 1.85714 11.5714 3.58482 11.5714 5.71429C11.5714 7.84375 9.84375 9.57143 7.71429 9.57143C5.58482 9.57143 3.85714 7.84375 3.85714 5.71429C3.85714 3.58482 5.58482 1.85714 7.71429 1.85714ZM12.0033 17.2857H3.42522C2.25 17.2857 1.28571 16.1908 1.28571 14.8248C1.28571 11.6607 2.35045 9.68192 4.33929 9.58147C5.2433 10.375 6.41853 10.8571 7.71429 10.8571C9.01005 10.8571 10.1853 10.375 11.0893 9.58147C13.0781 9.68192 14.1429 11.6607 14.1429 14.8248C14.1429 16.1908 13.1786 17.2857 12.0033 17.2857Z"
      fill="white"
    />
  </svg>
)
