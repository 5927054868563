import { Cores, Medias } from 'src/componentes/styles'
import styled from 'styled-components'

export const ContainerConteudo = styled.div`
  margin-top: 13px;
  margin-left: 30px;
  width: 95%;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;

  h2 {
    font-size: 25px;
    color: ${Cores.PRIMARIA_1};
    font-weight: bold;
    margin-bottom: 22px;
  }

  h3 {
    font-size: 22px;
    color: ${Cores.PRIMARIA_1};
    font-weight: bold;
    margin: 22px 0;
  }

  h4 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }

  span {
    font-weight: bold;
  }

  @media ${Medias.MOBILE} {
    margin: 0;

    h2 {
      font-size: 1.6em;
    }

    p {
      text-align: justify;
    }
  }
`

export const ModulosFixos = styled.div`
  background-color: ${Cores.PRIMARIA_1};
  color: white;
  width: 180px;
  height: 110px;
  display: flex;
  word-wrap: normal;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  border-radius: 30px;
  padding: 0px 5px;
`

export const ContainerModulosFixos = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  gap: 10px;
  margin-bottom: 30px;

  @media ${Medias.MOBILE} {
    flex-direction: column;
    align-items: center;
  }
`

export const ContainerBotao = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 20px 0;
  gap: 20px;

  button {
    font-size: 16px;
    padding: 18px;
  }

  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 5px;
    margin: 20px 0 10px 0;
  }
`

export const ContainerBotaoConfirmar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 20px 0;
  gap: 20px;

  button {
    font-size: 16px;
    padding: 18px;
  }

  @media ${Medias.MOBILE} {
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`

export const ContainerConteudoDois = styled.div`
  margin-top: 13px;
  margin-left: 30px;
  width: 95%;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;

  h2 {
    font-size: 22px;
    color: ${Cores.PRIMARIA_1};
    font-weight: bold;
    margin-bottom: 22px;
    margin-top: 12px;
  }

  h3 {
    font-size: 18px;
    color: ${Cores.PRIMARIA_1};
    font-weight: bold;
    margin: 22px 0 10px 0;
  }

  h4 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    margin: 0;

    h2 {
      font-size: 1.6em;
    }

    p {
      text-align: justify;
    }
  }
`

export const ModulosFlex = styled.label<{
  selecionado?: boolean
  bloqueado?: boolean
}>`
  background-color: ${Cores.PRIMARIA_1};
  width: 250px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  cursor: ${({ bloqueado }) => (bloqueado ? 'not-allowed' : 'pointer')};
  opacity: ${({ selecionado }) => (selecionado ? 1 : 0.6)};

  input {
    cursor: ${({ bloqueado }) => (bloqueado ? 'not-allowed' : 'pointer')};
    width: 18px;
    height: 18px;
  }
`

export const InformacoesSuperior = styled.div`
  width: 100%;
  margin: 15px 0 10px 0;
  padding-left: 20px;
`

export const NomeModulo = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;

  p {
    color: white;
    text-align: center;
  }
`

export const ContainerModulosFlex = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 30px;

  @media ${Medias.MOBILE} {
    flex-direction: column;
    align-items: center;
  }
`

export const Corpo = styled.div`
  width: 600px;
  max-height: 600px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  @media ${Medias.TABLET} {
    max-width: 50%;
  }

  @media ${Medias.MOBILE} {
    max-width: 90%;
  }
`
export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  button {
    width: 100px;
  }

  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`
export const ContainerInfo = styled.div`
  background-color: ${Cores.BRANCO};
  border: 1px solid ${Cores.SECUNDARIA_2_ESCURO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 19px 31px;
  margin-bottom: 40px;
  width: 100%;

  a {
    font-size: 16px;
    text-decoration: underline;
  }

  p {
    text-align: justify;
  }

  ul li {
    list-style: square;
  }

  ol li {
    list-style: decimal;
  }
`

export const ContainerBotaoFecharModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`

export const BotaoFecharModal = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  border: none;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }

  @media ${Medias.MOBILE} {
    margin-right: 16px;
  }
`

export const ContainerListaModulos = styled.div`
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const ListaOrdenacaoModulos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 5px 10px 0px;
  padding: 5px 5px 5px 15px;
  border-radius: 3px;
  border: 1px solid ${Cores.SECUNDARIA_2_ESCURO};

  p {
    color: black;
    font-size: 20px;
  }
`
export const ContainerBotoesOrdenacao = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    background-color: ${Cores.SECUNDARIA_2_ESCURO};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    rotate: 90deg;
    padding: 5px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${Cores.BRANCO};
        stroke: ${Cores.BRANCO};
      }
    }
  }

  & > span:first-child {
    rotate: -90deg;
  }
`

export const BotaoVerMais = styled.button`
  color: white;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 7px;
  border-radius: 20px;
  background-color: ${Cores.SECUNDARIA_2_ESCURO};
  position: absolute;
  top: 15px;
  right: 20px;

  svg {
    color: white;
    width: 20px;
    height: 20px;

    path {
      fill: ${Cores.BRANCO};
      stroke: ${Cores.BRANCO};
    }
  }
`
export const InformacaoVerMaisAviso = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  p {
    font-size: 15px;
    margin-left: 5px;
  }

  span {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: ${Cores.CINZA_2};
  }
`

export const ContainerModalTrocaModulo = styled.div`
  padding: 19px 31px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  span {
    font-size: 50px;
  }

  @media ${Medias.MOBILE} {
    span {
      transform: rotate(90deg);
    }
    flex-direction: column;
  }
`

export const ContainerBotoesModalTrocaModulo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  & > .btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`
