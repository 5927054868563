import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { TipoProfessorModuloTCC } from 'src/tipos'

export const colunaTipoProfessor = (): TabelaColuna => ({
  Chave: 'tipoProfessor',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <>{TipoProfessorModuloTCC[linha.tipoProfessor]}</>
  ),
  RenderizarCabecalho: () => <div style={{ width: 110 }}>Tipo</div>
})
