import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'

export const colunaInicioPeriodoInscricaoTCC = (): TabelaColuna => ({
  Chave: 'inicioPeriodoInscricaoTCC',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <span>
      {formatadores.dataLocaleString(linha.inicioPeriodoInscricaoTCC)}
    </span>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 90 }}>Início Período Insc. TCC</div>
  )
})
