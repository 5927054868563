import { colunaAnoFaturamento } from './ano-faturamento'
import { colunaCelular } from './celular'
import { colunaCpf } from './cpf'
import { colunaDataFinalizacaoMatricula } from './data-finalizacao-matricula'
import { colunaDataUltimoAcesso } from './data-ultimo-acesso-portal'
import { colunaDescontoCupomMatricula } from './desconto-cupom-matricula'
import { colunaDescontoCupomPlanoSelecionadoMatricula } from './desconto-cupom-plano-selecionado-matricula'
import { colunaEmail } from './email'
import { colunaEndereco } from './endereco'
import { colunaModalidade } from './modalidade'
import { colunaNomeAluno } from './nome-aluno'
import { colunaPagamentoRecorrente } from './pagamento-recorrente'
import { colunaPlanoSelecionadoMatricula } from './plano-selecionado-matricula'
import { colunaQuantidadeCobrancas } from './quantidade-cobrancas'
import { colunaQuantidadeCobrancasNaoPagas } from './quantidade-cobrancas-nao-pagas'
import { colunaQuantidadeCobrancasPagas } from './quantidade-cobrancas-pagas'
import { colunaSituacaoFinanceira } from './situacao-financeira'
import { colunaStatusFinanceiro } from './status-financeiro'
import { colunaTotalAVencer } from './total-a-vencer'
import { colunaTotalDevido } from './total-devido'
import { colunaTotalPago } from './total-pago'
import { colunaTurma } from './turma'
import { colunaValorParcelaCurso } from './valor-parcela-curso'
import { colunaValorParcelaCursoComDesconto } from './valor-parcela-curso-com-desconto'
import { colunaValorParcelaProrrogacao } from './valor-parcela-prorrogacao'
import { colunaValorParcelaProrrogacaoComDesconto } from './valor-parcela-prorrogacao-com-desconto'
import { colunaValorParcelaTaxaMatricula } from './valor-parcela-taxa-matricula'
import { colunaValorParcelaTaxaMatriculaComDesconto } from './valor-parcela-taxa-matricula-com-desconto'
import { colunaValorParcelaTCC } from './valor-parcela-tcc'
import { colunaValorParcelaTCCComDesconto } from './valor-parcela-tcc-com-desconto'
export const COLUNAS_FIXAS = [
  colunaNomeAluno(),
  colunaCpf(),
  colunaEmail(),
  colunaCelular(),
  colunaEndereco(),
  colunaTurma(),
  colunaModalidade(),
  colunaSituacaoFinanceira(),
  colunaDataFinalizacaoMatricula(),
  colunaDataUltimoAcesso(),
  colunaPagamentoRecorrente(),
  colunaStatusFinanceiro(),
  colunaPlanoSelecionadoMatricula(),
  colunaDescontoCupomMatricula(),
  colunaDescontoCupomPlanoSelecionadoMatricula(),
  colunaValorParcelaTaxaMatricula(),
  colunaValorParcelaTaxaMatriculaComDesconto(),
  colunaValorParcelaCurso(),
  colunaValorParcelaCursoComDesconto(),
  colunaValorParcelaTCC(),
  colunaValorParcelaTCCComDesconto(),
  colunaValorParcelaProrrogacao(),
  colunaValorParcelaProrrogacaoComDesconto(),
  colunaQuantidadeCobrancas(),
  colunaQuantidadeCobrancasPagas(),
  colunaQuantidadeCobrancasNaoPagas(),
  colunaTotalPago(),
  colunaTotalAVencer(),
  colunaTotalDevido()
]
export { colunaAnoFaturamento }
