import { Cores } from 'src/componentes/styles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const ContainerMain = styled.div`
  width: 100%;
`

export const ContainerLink = styled.div`
  margin-bottom: 28px;
`

export const SecaoPrimaria = styled.section`
  margin-top: 0px;
`

export const Secao = styled.section`
  margin-top: 50px;
`

export const Conteudo = styled.main`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  word-break: break-word;
  margin-top: 18px;
  margin-left: 30px;
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`

export const ContainerDadosMedio = styled.div`
  width: 50%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosPequeno = styled.div`
  min-width: 25%;

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 19px;
  }
`
export const ContainerNavegacao = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 58px;
`
export const FormContainer = styled.div`
  flex: 2;

  .campos-recuperacao label {
    display: flex;
    min-height: 33.5px;
  }

  .accordion_valores {
    padding-bottom: 20px;
  }
`

export const Corpo = styled.div`
  width: 700px;
  max-height: 600px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 40px 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-top: 20px;
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  form {
    width: 100%;
  }
  margin-top: 58px;
`
export const ContainerBotaoIcone = styled.div`
  button {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: ${Cores.CINZA_2};
    }

    &:hover {
      background: ${Cores.CINZA_3_MAIS_CLARO};

      svg {
        fill: ${Cores.SECUNDARIA_1};

        path {
          fill: ${Cores.SECUNDARIA_1};
        }
      }
    }
  }
`
export const ContainerDisciplinas = styled.div`
  display: flex;
  flex-direction: column;

  & h2 {
    margin-bottom: 30px;
  }
`
export const SemDados = styled.p`
  padding: 54px;
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  color: ${Cores.CINZA_2_CLARO};
  text-align: center;
`
export const BotaoIcone = styled.button`
  color: ${Cores.SECUNDARIA_2_ESCURO};
  border: 2px solid ${Cores.SECUNDARIA_2_ESCURO};
  background-color: white;
  min-width: 160px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 12px 0 12px;
  cursor: pointer;
  transition: all 0.15s ease-in;

  &:hover {
    color: ${Cores.BRANCO};
    background: ${Cores.SECUNDARIA_2_ESCURO};
    border: 2px solid ${Cores.SECUNDARIA_2_ESCURO};
    outline: 0;
  }

  &:hover svg path {
    fill: ${Cores.BRANCO};
  }

  svg path {
    fill: ${Cores.SECUNDARIA_2_ESCURO};
  }
`
export const CorpoModal = styled(Corpo)`
  padding: 41px 0px;
  display: flex;
  justify-content: center;
`

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  button {
    width: 100px;
  }
  & > #btn-salvar {
    margin-left: 19px;
    font-weight: 500;
  }
`
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ContainerIcone = styled.span`
  margin-right: 5px;
`

export const ConteudoGrade = styled.div`
  display: flex;
  flex-direction: row;
`
export const ContainerBotao = styled.div`
  margin-left: 10px;
`
export const ContainerGrade = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`
export const TituloModulo = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`
export const ListaModulos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SeloFlex = styled.div`
  background-color: ${Cores.SECUNDARIA_1_ESCURO};
  font-size: 14px;
  padding: 6px 7px;
  border-radius: 10px 0 10px 0;
  font-weight: 500;
  color: white;
  letter-spacing: 0.1em;
  white-space: nowrap;
`

export const Tabela = styled.table`
  margin: 20px 0 27px 0;
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  color: #191414;
  border-collapse: collapse;
  border: 1px solid ${Cores.CINZA_3_CLARO};
  width: 100%;

  th {
    background: ${Cores.CINZA_HEAD_TABLE};
    color: ${Cores.PRETO};
    font-family: 'Saira-Semi-Bold', sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    border-bottom: none;
    text-transform: uppercase;
  }

  th:first-child {
    border-top-left-radius: 8px;
  }

  th:last-child {
    border-top-right-radius: 8px;
  }

  tbody {
    line-height: 125%;

    tr:first-child {
      border-top: 2px solid ${Cores.CINZA_3_CLARO};
    }

    tr {
      height: 48px;
      border: 1px solid ${Cores.FUNDO};
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid ${Cores.FUNDO};
      }

      &.par {
        background: ${Cores.FUNDO};
      }

      &.marcado {
        background: #afc2ed;
        border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
      }

      &.comMarcacao {
        cursor: pointer;
      }

      td,
      th {
        padding: 0 12px;
      }

      &:hover {
        border: 1px solid ${Cores.SECUNDARIA_1};
      }
    }
  }
`

export const Opcao = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const OpcaoDados = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0;
`
export const Linha = styled.div<{ centralizado?: boolean }>`
  display: flex;

  & > div:not(:first-child) {
    margin-left: 10px;
  }

  .sm {
    flex: 1;
  }

  .md {
    flex: 2;
  }

  .lg {
    flex: 3;
  }

  ${props =>
    props.centralizado &&
    css`
      align-items: center;
      justify-content: center;
    `}
`

export const LinhaComponenteDraggable = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .subtitulo {
    margin-bottom: 20px;
  }

  span:first-child {
    margin-right: 10px;
    margin-bottom: 12px;
  }

  .span:last-child {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 10px;
    color: ${Cores.CINZA_2};
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 480px;
  }

  .button:last-child {
    margin-right: 10px;
  }
`

export const LinhaInformacoesDraggable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p:last-child {
    text-align: center;
    width: 40px;
    margin-right: 60px;
  }
`
export const ContainerLinha = styled.div`
  margin-bottom: 20px;
`

export const ContainerListaMaterial = styled.div`
  margin-bottom: 60px;
`

export const LinhaInformacoesTituloDraggable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;

  p {
    font-weight: bold;
    font-size: 14px;
  }

  p:first-child {
    margin-left: 52px;
  }

  p:last-child {
    margin-right: 90px;
  }
`

export const ConteudoBotaoEditarModulo = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    width: 18px;
    height: 18px;
  }

  svg path {
    fill: ${Cores.SECUNDARIA_2_ESCURO};
  }
`

export const InformacaoVerMaisAviso = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2px;

  span:first-child {
    margin-right: 5px;
  }

  span:last-child {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    color: ${Cores.CINZA_2};
  }
`
