import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { Aviso, AvisoContainer } from 'src/compartilhados'

import { BarraLateralLayout } from '../barra-lateral-layout'
import { BarraSuperiorLayout } from '../barra-superior-layout'
import { useTemaState } from '../contextos'
import {
  Centralizado,
  Container,
  Conteudo,
  Layout as ComponenteLayout
} from './styles'

export const Layout: React.FC<{ centralizado?: boolean }> = ({
  children,
  centralizado = true
}) => {
  const { menuExpandido } = useTemaState()

  return (
    <ComponenteLayout>
      <BarraSuperiorLayout />
      <BarraLateralLayout />
      <AvisoContainer.Provider>
        <Container expandido={menuExpandido}>
          <Scrollbars style={{ height: 'calc(100vh - 72px)' }}>
            <Conteudo>
              <Aviso />
              {centralizado ? (
                <Centralizado>{children}</Centralizado>
              ) : (
                <>{children}</>
              )}
            </Conteudo>
          </Scrollbars>
        </Container>
      </AvisoContainer.Provider>
    </ComponenteLayout>
  )
}
