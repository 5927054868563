import React, { useRef, useEffect, useMemo } from 'react'

import {
  IconeAdicionar,
  TabelaRef,
  Tabela,
  TabelaDados,
  ContainerAcoes,
  IconeLixeira,
  Tooltip,
  FuncoesDataHora,
  Botao
} from 'src/componentes'
import { Linha, Titulo } from 'src/paginas/ipgs/turmas/styles'

import PaginaCadastroTurmaContainer from '../../../container'
import { EncontroPresencialTurma } from '../../../tipos'
import { ModalRemoverEncontro } from '../modal-remover-encontro'
import { ModalRemoverEncontroRef } from '../modal-remover-encontro/tipos'

export const ListaEncontrosPresencial: React.FC<{ idDisciplina: string }> = ({
  idDisciplina
}) => {
  const tabelaRef = useRef<TabelaRef>(null)
  const modalRemoverRef = useRef<ModalRemoverEncontroRef>(null)

  const {
    modalAdicionarEncontroRef,
    encontros,
    bloquearCampo,
    ehModoCadastro
  } = PaginaCadastroTurmaContainer.useContainer()

  const encontrosAtuais = useMemo(() => {
    return encontros.filter(x => x.idDisciplina === idDisciplina)
  }, [encontros, idDisciplina])

  const obterDados = () => Promise.resolve({ Dados: encontrosAtuais })

  const BotoesAcao = (linha: TabelaDados) => {
    if (bloquearCampo) {
      return <></>
    }

    if ((!linha.data || new Date(linha.data) < new Date()) && !ehModoCadastro) {
      return <></>
    }

    return (
      <ContainerAcoes>
        <button
          type="button"
          data-tip="Remover"
          data-for={`${linha.id}-tooltip-excluir`}
          onClick={() =>
            modalRemoverRef?.current?.abrir(linha as EncontroPresencialTurma)
          }
        >
          {IconeLixeira}
        </button>
        <Tooltip
          id={`${linha.id}-tooltip-excluir`}
          place="bottom"
          offset={{ top: 10 }}
        />
      </ContainerAcoes>
    )
  }

  useEffect(() => {
    tabelaRef?.current?.CarregarDados(true)
  }, [encontros])

  return (
    <>
      <Linha>
        <Titulo>Encontros Presenciais</Titulo>
        {!bloquearCampo && (
          <Botao
            type="button"
            onClick={() =>
              modalAdicionarEncontroRef.current?.abrir(idDisciplina)
            }
            elemento={<>{IconeAdicionar} Incluir Encontro</>}
            tema="Secundario"
          />
        )}
      </Linha>
      <div>
        <Tabela
          ObterDados={obterDados}
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'data',
              Texto: 'Data',
              Renderizar: (linha: TabelaDados) => (
                <>
                  {linha.data &&
                    FuncoesDataHora.dataFormatadaComBarras(
                      new Date(linha.data)
                    )}
                </>
              )
            },
            {
              Chave: 'cargaHoraria',
              Texto: 'Carga Horária',
              Renderizar: (linha: TabelaDados) => (
                <>{linha.cargaHoraria && `${linha.cargaHoraria}h`}</>
              )
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => BotoesAcao(linha)
            }
          ]}
        />
        <ModalRemoverEncontro ref={modalRemoverRef} backdrop />
      </div>
    </>
  )
}
