import { FormUnform } from 'src/componentes/unform/form'
import styled from 'styled-components'

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

export const Campo = styled.div`
  flex: 1;
  margin-right: 10px;
`

export const Dados = styled.div`
  margin-top: 25px;
`
