import styled from 'styled-components'

export const TituloPrincipal = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
`

export const ContainerAnexo = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 260px;
  position: relative;
`

export const NomeDocumento = styled.div`
  width: 300px;
  font-style: normal;
  font-weight: 300;
  color: black;
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      margin-bottom: 6px;
    }
  }
`

export const StatusDocumento = styled.div`
  display: flex;
  flex-direction: column;
  align-items: inherit;
  width: 240px;

  p {
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const BotaoAcao = styled.button`
  background: transparent;
  border: none;
  opacity: 1;
  cursor: pointer;
  pointer-events: initial;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
  }
`

export const OpcoesAcao = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;

  ${BotaoAcao} + ${BotaoAcao} {
    margin-left: 6px;
  }
`

export const IconeAnexo = styled.div`
  margin-right: 8px;
`

export const ContainerAviso = styled.div`
  display: flex;
  align-items: center;
`

export const Icone = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

export const Status = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin-right: 8px;
`

export const ContainerIP = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 140px;
  position: relative;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SubTitulo = styled.h3`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 20px;
`
