import React, { useEffect, useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import {
  Breadcrumb,
  Cabecalho,
  ErroLayoutContainer,
  IconeBack,
  Link
} from 'src/componentes'
import {
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta
} from 'src/componentes/tabela'
import { PesquisaTicketDTO } from 'src/dto'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { Api } from 'src/servicos'
import { SetorTicketPorExtenso } from 'src/tipos'

import { Dados, ContainerLink } from './styles'
import { HistoricoTicketProps } from './tipos'

export const PaginaHistoricoTicket: React.FC<HistoricoTicketProps> = ({
  match
}) => {
  const tabelaRef = useRef<TabelaRef>()
  const history = useHistory()
  const [ticket, definirTicket] = useState<PesquisaTicketDTO>(null)

  const obterDados = async (): Promise<TabelaResposta | null> => {
    const id = match.params.idTicket
    const dados = await Api.ObterHistoricoTicket(id)
    if (!dados) return null

    definirTicket(dados)
    return {
      Dados: dados.eventos
    } as TabelaResposta
  }

  useEffect(() => {
    obterDados()
  }, [])

  return (
    <>
      <ContainerLink>
        <Link
          texto="Voltar"
          acaoVoltar={() => history.goBack()}
          icone={IconeBack}
        />
      </ContainerLink>
      <Cabecalho>
        <Breadcrumb
          titulo={`Histórico do Ticket - ${ticket?.numeroProtocolo ?? ''}`}
          atalhos={[
            {
              texto: 'Acadêmico',
              acao: () => history.goBack()
            },
            {
              texto: 'Filas de Trabalho',
              acao: () => history.goBack()
            },
            {
              texto: 'Histórico do Ticket'
            }
          ]}
        />
      </Cabecalho>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nomeUsuario',
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Usuário que Alterou</div>
              )
            },
            {
              Chave: 'setorAnterior',
              Renderizar: (linha: TabelaDados) => (
                <>{SetorTicketPorExtenso[linha.setorAnterior]}</>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 110 }}>Setor Anterior</div>
              )
            },
            {
              Chave: 'setorAtual',
              Renderizar: (linha: TabelaDados) => (
                <>{SetorTicketPorExtenso[linha.setorAtual]}</>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 110 }}>Setor Atual</div>
              )
            },
            {
              Chave: 'dataCriacao',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <span>
                  {formatadores.dataHoraLocaleString(linha.dataCriacao)}
                </span>
              ),
              RenderizarCabecalho: () => (
                <div style={{ width: 100 }}>Data Alteração</div>
              )
            },
            {
              Chave: 'observacao',
              RenderizarCabecalho: () => (
                <div style={{ width: 150 }}>Observação</div>
              )
            }
          ]}
          ObterDados={obterDados}
        />
      </Dados>
    </>
  )
}

const Container: React.FC<HistoricoTicketProps> = (
  props: HistoricoTicketProps
) => (
  <ErroLayoutContainer.Provider>
    <PaginaHistoricoTicket {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
