import { RouteComponentProps } from 'react-router-dom'

import {
  DadosMatriculasReingresso,
  DadosReingresso
} from 'src/servicos/api/tipos'
import {
  Curso,
  CursosCarrinho,
  DadosPessoaisMatricula,
  Matricula,
  PlanoPagamentoMatricula,
  ResponsavelFinanceiro,
  TermosCondicoesMatricula
} from 'src/tipos'
import {
  PagamentoReingresso,
  Reingresso,
  TermosCondicoesReingresso
} from 'src/tipos/reingresso'

export enum ModoReingresso {
  'Livre',
  'Pos',
  'Evento'
}

export interface ReingressoContainerEstado {
  dados: DadosReingresso
  passo: number
  modo: ModoReingresso
  matricula?: Matricula
  termosCondicoes?: TermosCondicoesMatricula
}

export interface ReingressoContainerProps {
  passo: number
  modo: ModoReingresso
}

export interface ReingressoContainer {
  passo: number
  curso?: Curso
  carrinhoCurso?: CursosCarrinho
  modo: ModoReingresso
  reingresso?: Reingresso
  dados?: DadosReingresso
  termosCondicoes?: TermosCondicoesMatricula
  definirPasso: (passo: number) => void
  definirCurso: (curso: Curso) => void
  definirDados: (dados: DadosReingresso) => void
  definirReingresso: (reingresso: Reingresso) => void
  definirPagamento: (pagamento: PagamentoReingresso) => void
  definirPlanoPagamento: (planoPagamento: PlanoPagamentoMatricula) => void
  definirTermosCondicoes: (
    termosCondicoes: Omit<TermosCondicoesReingresso, 'urlTermosCondicoes'>
  ) => void
  definirResponsavelFinanceiro: (responsavel: ResponsavelFinanceiro) => void
  definirMatriculaDestino: (matriculaDestino: DadosMatriculasReingresso) => void
}

export type PaginaReingressoProps = RouteComponentProps<{
  id?: string
}> & {
  modo: ModoReingresso
}

export interface DadosPessoaisForm extends DadosPessoaisMatricula {
  novaSenha: string
  repetirSenha: string
}
