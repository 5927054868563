import React, { useEffect, useState } from 'react'
import { hotjar } from 'react-hotjar'
import { useHistory, useParams } from 'react-router-dom'

import {
  StepperContainer,
  ErroLayoutContainer,
  Carregando,
  ErroContainerLayout
} from 'src/componentes'
import { RotasApp } from 'src/rotas'
import { Api } from 'src/servicos'

import SalaAulaContainer from './container'
import { ComponenteStepper } from './stepper'

const SalaAula: React.FC = () => {
  const {
    idMatricula,
    definirSalaAula,
    definirItensMarcados,
    atualizarSala,
    definirAtualizarSala
  } = SalaAulaContainer.useContainer()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()
  const history = useHistory()

  const [pronto, definirPronto] = useState(false)

  const carregarSalaAula = async () => {
    try {
      definirPronto(false)
      limparErro()

      const salaAula = await Api.SalaAulaCursoLivre.RequisitarSalaAulaPorId(
        idMatricula
      )

      const materiasLidos: string[] = []

      if (salaAula.disciplinas.length > 0) {
        salaAula.disciplinas.forEach(disc => {
          disc.materiais.forEach(mat => {
            if (mat.lido) materiasLidos.push(mat.id)
          })
        })
      }

      definirItensMarcados(materiasLidos)
      definirSalaAula(salaAula)
      definirPronto(true)
    } catch {
      definirErro({
        mensagens: 'Ops! Houve algum problema ao carregar a sala de aula',
        textoConfirmar: 'Tentar de novo',
        acaoConfirmar: carregarSalaAula,
        acaoVoltar: () => history.push(RotasApp.Home)
      })
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR, 6)
    }
    carregarSalaAula()
  }, [idMatricula])

  useEffect(() => {
    if (atualizarSala) carregarSalaAula()
    definirAtualizarSala(false)
  }, [atualizarSala])

  return pronto && !erro ? (
    <ComponenteStepper />
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando sala de aula ..." />
  ) : (
    <ErroContainerLayout />
  )
}

export const PaginaSalaAulaCursoLivre: React.FC = props => {
  const { id } = useParams<{ id: string }>()

  return (
    <StepperContainer.Provider>
      <SalaAulaContainer.Provider initialState={{ idMatricula: id }}>
        <ErroLayoutContainer.Provider>
          <SalaAula {...props} />
        </ErroLayoutContainer.Provider>
      </SalaAulaContainer.Provider>
    </StepperContainer.Provider>
  )
}
