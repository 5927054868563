import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Filtro,
  FormRow,
  InputUnform,
  SelectUnform,
  Spacer,
  TabelaRef,
  Tabela,
  TabelaResposta,
  Tooltip,
  ContainerAcoes,
  TabelaDados,
  IconeEditar,
  IconeExcel,
  IconeCopiar,
  IconeNotas,
  IconeDiscussao,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  AutenticacaoContainer,
  Visao,
  IconeDeListaDeDocumentos
} from 'src/componentes'
import { RotasAcademico, RotasTurmas } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  DadosPesquisaTurma,
  TipoOrdenacao,
  TurmaSituacao,
  TurmaSituacaoPorExtenso
} from 'src/tipos'

import { CopiarTurma } from './componentes'
import {
  ModalInserirRelatorio,
  ModalInserirRelatorioRef,
  NovoRelatorio
} from './componentes/modal-inserir-relatorio'
import { Dados, Form } from './styles'
import { ModalCopiaTurmaRef } from './tipos'

const Turmas: React.FC = () => {
  const history = useHistory()

  const tabelaRef = useRef<TabelaRef>()
  const modalRef = useRef<ModalCopiaTurmaRef>(null)

  const [paginas, definirPaginas] = useState(null)
  const [pronto, definirPronto] = useState<boolean>(true)
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisaTurma>()
  const modalInserirRelatorio = useRef<ModalInserirRelatorioRef>(null)
  const { limparErro, erro } = ErroLayoutContainer.useContainer()
  const { visao } = AutenticacaoContainer.useContainer()
  const visaoProfessor = 2
  const perfilParceiro = useMemo(() => visao === Visao.Parceiro, [visao])

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.IrParaPagina()
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisaTurma) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }
    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    qtdRegistros: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    let dados

    if (visao === visaoProfessor) {
      dados = await Api.ObterTurmasQueProfessorEhTutor(
        dadosPesquisa?.nome,
        dadosPesquisa?.codigo,
        dadosPesquisa?.uf,
        dadosPesquisa?.cidade,
        dadosPesquisa?.situacao,
        numeroPaginas,
        qtdRegistros,
        chaveOrdenacao,
        ordem
      )
    } else {
      dados = await Api.RequisitarListaTurmas(
        dadosPesquisa?.nome,
        dadosPesquisa?.codigo,
        dadosPesquisa?.uf,
        dadosPesquisa?.cidade,
        dadosPesquisa?.situacao,
        numeroPaginas,
        qtdRegistros,
        chaveOrdenacao,
        ordem
      )
    }

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const formatarEndereco = (linha: TabelaDados) => {
    if (linha.uf && linha.municipio) {
      return `${linha.uf} - ${linha.municipio}`
    }
  }

  const enviarNovoRelatorio = async (dados: NovoRelatorio) => {
    try {
      await Api.PostNovoRelatorio(dados)
      toast('Relatório inserido com sucesso.', { type: 'success' })
    } catch (e) {
      toast('Erro ao inserir relatório', { type: 'error' })
    }
  }

  const copiarTurma = async (
    turmaId: string,
    codigoCopia: string
  ): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const id = await Api.CriarCopiaTurma(turmaId, codigoCopia)
      history.push(`${RotasTurmas.Edicao}/${id}`)
    } catch (erro) {
      toast('Erro ao copiar a turma.', { type: 'error' })
    } finally {
      definirPronto(true)
    }
  }

  return pronto && !erro ? (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Turmas"
          atalhos={[
            {
              texto: 'Acadêmico'
            },
            { texto: 'Turmas' }
          ]}
        />
        {!perfilParceiro && (
          <Botao
            type="button"
            texto="Incluir"
            tema="Secundario"
            onClick={() => history.push(RotasTurmas.Cadastro)}
          />
        )}
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            situacao: TurmaSituacao[TurmaSituacao.Todos]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <FormRow>
            <InputUnform
              className="sm"
              type="text"
              name="codigo"
              label="Código"
            />
            <InputUnform className="sm" type="text" name="nome" label="Nome" />
            <SelectUnform
              className="sm"
              id="slct_situacao"
              name="situacao"
              label="Situação"
              placeholder="Selecione"
              iconeFechar={false}
              opcoes={[
                {
                  id: TurmaSituacao[TurmaSituacao.Aberta],
                  texto: TurmaSituacaoPorExtenso.Aberta
                },
                {
                  id: TurmaSituacao[TurmaSituacao.AguardandoInicio],
                  texto: TurmaSituacaoPorExtenso.AguardandoInicio
                },
                {
                  id: TurmaSituacao[TurmaSituacao.EmAndamento],
                  texto: TurmaSituacaoPorExtenso.EmAndamento
                },
                {
                  id: TurmaSituacao[TurmaSituacao.Cancelada],
                  texto: TurmaSituacaoPorExtenso.Cancelada
                },
                {
                  id: TurmaSituacao[TurmaSituacao.Finalizada],
                  texto: TurmaSituacaoPorExtenso.Finalizada
                },
                {
                  id: TurmaSituacao[TurmaSituacao.Todos],
                  texto: TurmaSituacaoPorExtenso.Todos
                }
              ]}
            />
          </FormRow>
          <FormRow>
            <InputUnform className="sm" type="text" name="uf" label="UF" />
            <InputUnform
              className="sm"
              type="text"
              name="cidade"
              label="Município"
            />
            <Spacer className="sm" />
          </FormRow>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'codigo',
              Texto: 'Código',
              ComOrdenacao: true
            },
            {
              Chave: 'nome',
              Texto: 'Nome',
              ComOrdenacao: true
            },
            {
              Chave: 'uf',
              Texto: 'UF - Município',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <> {formatarEndereco(linha)} </>
              )
            },
            {
              Chave: 'situacao',
              Texto: 'Situação',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) =>
                TurmaSituacaoPorExtenso[linha.situacao]
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    data-tip="Editar"
                    data-for="tooltip-editar"
                    onClick={() =>
                      history.push(`${RotasTurmas.Edicao}/${linha.id}`)
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasTurmas.Edicao}/${linha.id}`
                      )
                      rota.focus()
                    }}
                  >
                    {IconeEditar}
                  </button>
                  <Tooltip
                    id="tooltip-editar"
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                  {visao !== visaoProfessor && (
                    <button
                      type="button"
                      data-tip="Criar cópia"
                      data-for="tooltip-copiar"
                      onClick={() => modalRef.current?.abrir(linha.id)}
                      disabled={perfilParceiro}
                    >
                      {IconeCopiar}
                    </button>
                  )}
                  <button
                    type="button"
                    data-tip="Ver Notas e Presença"
                    data-for="tooltip-copiar"
                    onClick={() =>
                      history.push(`${RotasTurmas.NotasPresenca}/${linha.id}`)
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasTurmas.NotasPresenca}/${linha.id}`
                      )
                      rota.focus()
                    }}
                  >
                    {IconeNotas}
                  </button>
                  <Tooltip
                    id="tooltip-copiar"
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                  <button
                    type="button"
                    data-tip="Fórum de dúvidas"
                    data-for="tooltip-discussao"
                    onClick={() =>
                      history.push(
                        `${RotasAcademico.GrupoDiscussao}/${linha.id}`
                      )
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasAcademico.GrupoDiscussao}/${linha.id}`
                      )
                      rota.focus()
                    }}
                  >
                    {IconeDiscussao}
                  </button>
                  <Tooltip
                    id="tooltip-discussao"
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                  <button
                    type="button"
                    data-tip="Finalização"
                    data-for="tooltip-finalizacao"
                    onClick={() =>
                      history.push(
                        `${RotasTurmas.AcoesFinalizacao}/${linha.id}`
                      )
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasTurmas.AcoesFinalizacao}/${linha.id}`
                      )
                      rota.focus()
                    }}
                    disabled={perfilParceiro}
                  >
                    {IconeDeListaDeDocumentos}
                  </button>
                  <Tooltip
                    id="tooltip-finalizacao"
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                  <button
                    type="button"
                    data-tip="Processar Arquivo"
                    data-for="tooltip-arquivo"
                    onClick={() => {
                      modalInserirRelatorio?.current?.abrir(linha.id)
                    }}
                  >
                    {IconeExcel}
                  </button>
                  <Tooltip
                    id="tooltip-arquivo"
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                </ContainerAcoes>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
        <CopiarTurma
          ref={modalRef}
          backdrop
          id="modal-confirmacao-cancelamento"
          acaoPrimaria={copiarTurma}
          acaoSecundaria={() => modalRef.current?.fechar()}
        ></CopiarTurma>
        <ModalInserirRelatorio
          id="modal-inserirRelatorio"
          ref={modalInserirRelatorio}
          backdrop
          acaoPrimaria={enviarNovoRelatorio}
        />
      </Dados>
    </>
  ) : !pronto && !erro ? (
    <Carregando texto="Copiando dados da turma..." />
  ) : (
    <ErroContainerLayout />
  )
}

export const PaginaTurmas: FC = () => (
  <ErroLayoutContainer.Provider>
    <Turmas />
  </ErroLayoutContainer.Provider>
)

export { PaginaCadastroTurma } from './cadastro'
export { default as PaginaNotasPresenca } from './notas-presenca/disciplinas'
export { default as PaginaVerAlunos } from './notas-presenca/alunos/turma-ead'
export { default as PaginaVerAlunosPresencial } from './notas-presenca/alunos/turma-presencial'
export { default as PaginaVerAlunosTCC } from './notas-presenca/alunos/turma-ead-tcc'
export { default as PaginaVerAlunosPresencialTCC } from './notas-presenca/alunos/turma-presencial-tcc'
export { default as PaginaAproveitamentoDisciplina } from './notas-presenca/alunos/aproveitamento-disciplina'
export { default as PaginaAcoesFinalizacao } from './acoes-finalizacao'
