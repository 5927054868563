import React from 'react'

import Bowser from 'bowser'
import FileSaver from 'file-saver'

import { Botao } from '../botao'
import { useResponsivoState } from '../contextos'
import { IconeTermosCondicoes } from '../icones'
import { ContainerMobile, BotaoMobile, Container } from './styles'

const PdfViewer: React.FC<{
  url: string
  semToolbar?: boolean
  descricaoMobile?: string
  nomeArquivo?: string
  altura?: string
}> = ({ url, semToolbar, descricaoMobile, nomeArquivo, altura }) => {
  const { mobile } = useResponsivoState()
  const browser = Bowser.getParser(window.navigator.userAgent)
  const baixar = () => {
    FileSaver.saveAs(url, `${nomeArquivo ?? 'arquivo'}.pdf`)
  }

  if (mobile) {
    return (
      <ContainerMobile>
        {descricaoMobile && <p>{descricaoMobile}</p>}
        <BotaoMobile onClick={baixar} type="button">
          <div>{IconeTermosCondicoes}</div>
        </BotaoMobile>
      </ContainerMobile>
    )
  }

  return (
    <Container>
      <iframe
        src={`${url}#${semToolbar ? 'toolbar=0&' : ''}navpanes=0&scrollbar=0`}
        width="100%"
        height={altura ?? '534px'}
      />
      {browser.getBrowserName() === 'Safari' && (
        <Botao
          id="btn-baixar-pdf"
          onClick={baixar}
          texto="Baixar PDF"
          tema="Secundario"
        />
      )}
    </Container>
  )
}

export default PdfViewer
