import styled from 'styled-components'

export const ContainerConteudo = styled.div`
  margin-top: 13px;
  margin-left: 31px;
  width: 100%;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;

  h2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 23px;
  }

  h3 {
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
  }
`

export const ContainerAcoes = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`

export const ContainerCheckboxs = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 10px;
  margin-bottom: 20px;
`

export const TextoRegulamento = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 10px;
  margin: 0 10px;

  p {
    text-align: justify;
    font-size: 14px;
  }
  h4 {
    text-align: center;
    font-weight: bold;
  }
  span {
    font-weight: bold;
  }
`

export const ContainerRegulamento = styled.div`
  margin: 20px auto 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 400px;
  width: 90%;

  overflow-y: auto;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`
