import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  ModalBase,
  Botao,
  FormUnform,
  NumeroInputUnform,
  SelectOpcao,
  SelectUnform,
  InputUnform,
  MoedaInputUnform,
  Carregando
} from 'src/componentes'
import { SELECT_DIAS } from 'src/dados-estaticos'
import { Api } from 'src/servicos'
import { OpcaoAlteracaoTCC, TipoProfessorModuloTCC } from 'src/tipos'

import { schema } from './schema'
import {
  ContainerAcoes,
  ContainerGrande,
  ContainerMedio,
  ContainerModal,
  CorpoModal,
  Linha,
  TextoSemOpcoes,
  Titulo
} from './styles'
import {
  AlteracaoTCC,
  ModalAlteracaoTCCProps,
  ModalAlteracaoTCCRef
} from './tipos'

const ModalAlteracaoTCCComponent: React.ForwardRefRenderFunction<
  ModalAlteracaoTCCRef,
  ModalAlteracaoTCCProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [matriculaId, definirMatriculaId] = useState('')
  const [opcoesProfessores, definirOpcoesProfessores] = useState<SelectOpcao[]>(
    []
  )
  const [carregando, definirCarregando] = useState(false)
  const [pronto, definirPronto] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [opcoes, definirOpcoes] = useState<SelectOpcao[]>([])
  const [alteracao, definirAlteracao] = useState<OpcaoAlteracaoTCC>(null)

  const carregarOpcoes = async (matriculaId: string, salaDeAulaId: string) => {
    try {
      definirPronto(false)
      const resultado = await Api.ObterOpcoesAlteracaoTCC(matriculaId)
      const professores = await Api.ObterProfessoresSalaAula(
        salaDeAulaId,
        TipoProfessorModuloTCC.Orientador
      )

      const professoresSelect = professores.map(e => {
        return {
          id: e.id,
          texto: e.nome
        }
      })

      definirOpcoesProfessores(professoresSelect)

      definirOpcoes(resultado)
    } catch {
      toast('Erro ao carregar opções de alteração', { type: 'error' })
    } finally {
      definirPronto(true)
    }
  }

  const abrir = async (matriculaId: string, salaAulaId: string) => {
    definirAberta(true)
    definirMatriculaId(matriculaId)
    await carregarOpcoes(matriculaId, salaAulaId)
  }

  const fechar = () => {
    definirMatriculaId(null)
    definirOpcoes([])
    definirAlteracao(null)
    definirAberta(false)
  }

  useImperativeHandle<ModalAlteracaoTCCRef, ModalAlteracaoTCCRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: AlteracaoTCC) => {
    try {
      definirCarregando(true)
      const payload = { ...dados, matriculaId }
      await acaoPrimaria(payload)
    } finally {
      definirCarregando(false)
      fechar()
    }
  }

  return (
    <ModalBase id="modal-alteracao-tcc" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <Titulo>Alterações no TCC</Titulo>
          {pronto ? (
            opcoes && opcoes?.length > 0 ? (
              <FormUnform
                acaoSucesso={acaoSucessoForm}
                schema={schema}
                ref={formRef}
              >
                <Linha>
                  <ContainerGrande>
                    <SelectUnform
                      id="slct_alteracao"
                      name="alteracao"
                      label="Selecione a opção"
                      opcoes={opcoes}
                      valorAlterado={valor => {
                        definirAlteracao(OpcaoAlteracaoTCC[valor?.id])
                      }}
                      obrigatorio
                    />
                  </ContainerGrande>
                </Linha>

                {alteracao ===
                  OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Aproveitamento] && (
                  <>
                    <Linha>
                      <ContainerGrande>
                        <InputUnform
                          id="ipt_instituicao_origem"
                          name="instituicao"
                          label="Instituição de Origem"
                          obrigatorio
                        />
                      </ContainerGrande>
                    </Linha>
                    <Linha>
                      <ContainerGrande>
                        <InputUnform
                          id="ipt_turma_origem"
                          name="turma"
                          label="Turma de Origem"
                          obrigatorio
                        />
                      </ContainerGrande>
                    </Linha>
                    <Linha>
                      <ContainerMedio>
                        <NumeroInputUnform
                          id="ipt_nota"
                          name="nota"
                          obrigatorio
                          label={'Nota aproveitada'}
                          isDecimal
                        />
                      </ContainerMedio>
                    </Linha>
                  </>
                )}
                {alteracao ===
                  OpcaoAlteracaoTCC[OpcaoAlteracaoTCC.Inclusao] && (
                  <>
                    <Linha>
                      <ContainerMedio>
                        <MoedaInputUnform
                          name="valor"
                          label="Valor do TCC"
                          obrigatorio
                        />
                      </ContainerMedio>
                      <ContainerMedio>
                        <InputUnform
                          name="quantidadeParcelas"
                          label="Quantidade de Parcelas"
                          obrigatorio
                          id={'ipt_parcelas_tcc'}
                        />
                      </ContainerMedio>
                    </Linha>
                    <Linha>
                      <ContainerMedio>
                        <SelectUnform
                          name="diaVencimento"
                          label="Dia do Vencimento"
                          obrigatorio
                          id={'slct_dia_vencimento_tcc'}
                          opcoes={SELECT_DIAS}
                          className={'slcMaior'}
                        />
                      </ContainerMedio>
                      <ContainerMedio>
                        <SelectUnform
                          name="professorOrientadorId"
                          label="Selecione professor orientador"
                          opcoes={opcoesProfessores}
                          obrigatorio
                          id={'slct_professor_orientador'}
                          className={'slcMaior'}
                        />
                      </ContainerMedio>
                    </Linha>
                  </>
                )}

                <ContainerAcoes>
                  <Botao
                    texto="Fechar"
                    tema="Secundario"
                    type="button"
                    disabled={carregando}
                    onClick={() => fechar()}
                  />
                  <Botao texto="Salvar" carregando={carregando} />
                </ContainerAcoes>
              </FormUnform>
            ) : (
              <>
                <TextoSemOpcoes>
                  Não existem opções de alteração para a matrícula
                </TextoSemOpcoes>
                <ContainerAcoes>
                  <Botao
                    texto="Fechar"
                    tema="Secundario"
                    type="button"
                    disabled={carregando}
                    onClick={() => fechar()}
                  />
                </ContainerAcoes>
              </>
            )
          ) : (
            <Carregando texto="Carregando opções de alteração..." />
          )}
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalAlteracaoTCC = forwardRef(ModalAlteracaoTCCComponent)
