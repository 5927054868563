import { SituacaoUpload, TipoDocumentoProfessor } from 'src/tipos'

import { UploadDocumentos } from '../tipos'

export const RelacaoDocumentosProfessorBrasileiro = new Array<UploadDocumentos>(
  {
    id: 1,
    tipo: TipoDocumentoProfessor.RgCnh,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 1,
    obrigatorio: true,
    dica: 'Documento obrigatório.'
  } as UploadDocumentos,
  {
    id: 2,
    tipo: TipoDocumentoProfessor.RgCnhVerso,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 2,
    obrigatorio: true,
    dica: 'Documento obrigatório.'
  } as UploadDocumentos,
  {
    id: 3,
    tipo: TipoDocumentoProfessor.DiplomaGraduacao,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 3,
    obrigatorio: true,
    dica: 'Documento obrigatório.'
  } as UploadDocumentos,
  {
    id: 4,
    tipo: TipoDocumentoProfessor.DiplomaGraduacaoVerso,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 4,
    obrigatorio: true,
    dica: 'Documento obrigatório.'
  } as UploadDocumentos,
  {
    id: 5,
    tipo: TipoDocumentoProfessor.DiplomaTitulacaoMaxima,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 5,
    obrigatorio: true,
    dica: 'Documento obrigatório.'
  } as UploadDocumentos,
  {
    id: 6,
    tipo: TipoDocumentoProfessor.DiplomaTitulacaoMaximaVerso,
    status: SituacaoUpload.PendenteEnvio,
    ordemExibicao: 6,
    obrigatorio: true,
    dica: 'Documento obrigatório.'
  } as UploadDocumentos
)
