import { FormUnform } from 'src/componentes/unform/form'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`
export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Campo = styled.div<{ mr?: boolean }>`
  flex: 1;

  ${({ mr }) =>
    mr &&
    css`
      margin-right: 10px;
    `}
`

export const Dados = styled.div`
  margin-top: 25px;
  overflow-x: auto;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const ContainerAcoes = styled.div`
  display: flex;
  align-items: center;
`

export const IconeAcao = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-right: 10px;

  svg {
    width: 23px;
    height: 23px;
  }

  :not(:last-child) {
    svg path {
      fill: rgb(102, 102, 102);
    }
  }
`
