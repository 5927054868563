/** Calcula diferença entre data passada e data atual
 * @param data data que deve ser comparada
 * @returns menor que zero caso data passada seja maior que data atual
 * @returns zero caso data passada seja igual a data atual
 * @returns maior que zero caso data passada seja menor que data atual
 */
export const calcularDistanciaData = (data: string): number => {
  const distanciaMiliSegundos = Date.now() - +new Date(data)
  const distanciaDias = Math.ceil(distanciaMiliSegundos / (1000 * 60 * 60 * 24))

  return distanciaDias
}

export const subtrairDias = (data: string, diasADiminuir: number): string => {
  const novaData = new Date(data)
  novaData.setDate(novaData.getDate() - diasADiminuir)

  return novaData.toISOString()
}

export const somarDias = (data: string | Date, diasASomar: number): string => {
  const novaData = new Date(data)
  novaData.setDate(novaData.getDate() + diasASomar)

  return novaData.toISOString()
}

export const somarDiasComMeses = (
  data: string,
  mesesASomar: number
): string => {
  const novaData = new Date(data)
  novaData.setMonth(novaData.getMonth() + mesesASomar)

  return novaData.toISOString()
}

export const finalDoDia = (data: string): string => {
  return new Date(new Date(data)?.setHours(23, 59, 59, 999) ?? data).toString()
}

export const subtrairDatas = (dataInicio: Date, dataFim: Date): number => {
  const data1utc = Date.UTC(
    dataInicio.getFullYear(),
    dataInicio.getMonth(),
    dataInicio.getDate()
  )
  const data2utc = Date.UTC(
    dataFim.getFullYear(),
    dataFim.getMonth(),
    dataFim.getDate()
  )

  const dia = 1000 * 60 * 60 * 24
  return (data2utc - data1utc) / dia
}
