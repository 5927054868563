import { isEmpty } from 'lodash'
import queryString from 'query-string'
import { OpcaoSelect } from 'src/componentes/select-async/tipos'
import { SelectOpcao } from 'src/componentes/unform/select/tipos'
import {
  Avaliacao,
  CursoLivre,
  CursoPayload,
  PesquisaCursoDTO,
  PesquisaCursosResposta,
  CursoDisicplinaDto,
  GradeCurricularDto,
  Pergunta,
  GrupoDiscussao,
  PesquisaCursosFiltroResposta,
  SelectOpcaoCursos,
  AreaCurso,
  RequerimentoResponse
} from 'src/dto'
import {
  CursoSituacao,
  OpcoesPaginacao,
  RespostaPadraoAPI,
  TipoOrdenacao,
  ModalidadeCurso,
  TCCSituacao,
  GradeCurricularDisciplina
  // RelatorioDocumentosAlunoResponse
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'
import { RequisitarListaCursoOutrosPorIdResposta } from './tipos'

const urlBase = '/Curso'

export interface CursoPayloadGet extends Omit<CursoPayload, 'tcc'> {
  possuiTCC: boolean
}

export const RequisitarCurso = async (id: string): Promise<CursoPayload> => {
  const { data } = await gatewayApi.get<CursoPayloadGet>(
    `${urlBase}/${id}/outro`
  )

  return {
    ...data,
    tcc: data.possuiTCC ? TCCSituacao.Sim : TCCSituacao.Nao
  }
}

export const ObterOpcoesDeCurso = async (): Promise<Array<OpcaoSelect>> => {
  const {
    data: { itens }
  } = await gatewayApi.get<PesquisaCursosResposta>('urlCurso')

  return itens.map(r => ({
    id: r.id,
    texto: `${r.sigla} - ${r.nome}`,
    dadosAdicionais: r
  }))
}

export const RequisitarListaCursosPorTexto = async (
  texto: string
): Promise<SelectOpcaoCursos[]> => {
  try {
    const filtros = queryString.stringify({
      nome: texto,
      modalidade: null,
      situacao: null
    })
    const {
      data: { itens }
    } = await gatewayApi.get<PesquisaCursosResposta>(`${urlBase}?${filtros}`)
    return itens.map(r => ({
      id: r.id,
      texto: `${r.sigla} - ${r.nome}`,
      dadosAdicionais: r
    }))
  } catch {
    return null
  }
}

export const RequisitarListaCursosPorTextoFiltro = async (
  texto: string
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({
      nome: texto
    })
    const { data } = await gatewayApi.get<Array<PesquisaCursosFiltroResposta>>(
      `${urlBase}/filtro?${filtros}`
    )
    return data.map(r => ({
      id: r.id,
      texto: `${r.sigla} - ${r.nome}`,
      dadosAdicionais: r
    }))
  } catch {
    return null
  }
}

export const RequisitarListaCursosLivrePorTextoFiltro = async (
  texto: string
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({
      nome: texto
    })
    const { data } = await gatewayApi.get<Array<PesquisaCursosFiltroResposta>>(
      `${urlBase}/livre/filtro?${filtros}`
    )
    return data.map(r => ({
      id: r.id,
      texto: `${r.sigla} - ${r.nome}`,
      dadosAdicionais: r
    }))
  } catch {
    return null
  }
}

export const RequisitarListaCursos = async (
  sigla?: string,
  nome?: string,
  modalidade?: keyof typeof ModalidadeCurso | 'Todas',
  situacao?: keyof typeof CursoSituacao,
  pagina?: number,
  quantidadeRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaCursoDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      sigla,
      nome,
      modalidade: modalidade === 'Todas' ? null : modalidade,
      situacao: situacao === 'Todos' ? null : situacao,
      quantidadeRegistros,
      pagina,
      ordem
    })

    const {
      data: { itens, quantidade }
    } = await gatewayApi.get<PesquisaCursosResposta>(`${urlBase}?${filtros}`)

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }

    return new Promise(resolve =>
      resolve({ registros: itens, opcoesPaginacao })
    )
  } catch {
    return null
  }
}

export const InserirCurso = async (payload: CursoPayload): Promise<boolean> => {
  const {
    data: { cursoId }
  } = await gatewayApi.post<{ cursoId: string }>(`${urlBase}/outro/criar`, {
    dadosBasicos: payload,
    possuiTCC: payload.tcc === TCCSituacao.Sim,
    tipoAvaliacao: payload.tipoAvaliacao,
    gradesCurriculares: payload.gradesCurriculares,
    opcoesProrrogacao: payload.opcoesProrrogacao,
    planosPagamentoReingresso: payload.planosPagamentoReingresso
  })

  if (payload.contrato && payload.contrato instanceof File) {
    const formData = new FormData()
    formData.append('contrato', payload.contrato, payload.contrato.name)

    await gatewayApi.post<RespostaPadraoAPI>(
      `${urlBase}/${cursoId}/upload-contrato`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
  return true
}

export const EditarCurso = async (
  id: string,
  payload: CursoPayload
): Promise<boolean> => {
  await gatewayApi.put<RespostaPadraoAPI>(`${urlBase}/${id}/outro/editar`, {
    dadosBasicos: payload,
    possuiTCC: payload.tcc === TCCSituacao.Sim,
    tipoAvaliacao: payload.tipoAvaliacao,
    gradesCurriculares: payload.gradesCurriculares,
    opcoesProrrogacao: payload.opcoesProrrogacao,
    planosPagamentoReingresso: payload.planosPagamentoReingresso
  })

  if (payload.contrato && payload.contrato instanceof File) {
    const formData = new FormData()
    formData.append('contrato', payload.contrato, payload.contrato.name)

    await gatewayApi.post<RespostaPadraoAPI>(
      `${urlBase}/${id}/upload-contrato`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  return true
}

interface AvaliacaoGet extends Omit<Avaliacao, 'perguntas'> {
  perguntas: {
    texto: string
    id: string
    respostas: { texto: string; correta: boolean; id: string }[]
    explicacao: string
  }[]
}
interface CursolivreGet extends Omit<CursoLivre, 'avaliacao' | 'disciplinas'> {
  avaliacao: AvaliacaoGet
  disciplinas: CursoDisicplinaDto[]
}

export const RequisitarCursoLivre = async (id: string): Promise<CursoLivre> => {
  const { data } = await gatewayApi.get<CursolivreGet>(`${urlBase}/${id}/livre`)

  const retorno: CursoLivre = ({ ...data } as unknown) as CursoLivre

  if (data.avaliacao && data.avaliacao.perguntas) {
    retorno.avaliacao.perguntas = data.avaliacao.perguntas.map<Pergunta>(x => {
      const respostas = x.respostas.map(r => {
        return { id: r.id, texto: r.texto, correta: r.correta }
      })

      return {
        id: x.id,
        pergunta: x.texto,
        respostas: respostas,
        explicacao: x.explicacao
      }
    })
  }

  return retorno
}

export const EditarCursoLivre = async (
  id: string,
  payload: CursoLivre,
  disciplinas?: GradeCurricularDisciplina[],
  avaliacao?: Avaliacao,
  grupoDiscussao?: GrupoDiscussao
): Promise<boolean> => {
  let avaliacaoPayload = {
    ...avaliacao,
    perguntas:
      avaliacao.perguntas?.map(p => ({
        id: p.id,
        texto: p.pergunta,
        respostas: [...p.respostas],
        explicacao: p.explicacao
      })) ?? []
  }

  if (Object.values(avaliacaoPayload).every(x => isEmpty(x))) {
    avaliacaoPayload = null
  }

  await gatewayApi.put<RespostaPadraoAPI>(`${urlBase}/${id}/livre/editar`, {
    dadosBasicos: payload,
    versoCertificado: payload.versoCertificado,
    disciplinas: disciplinas,
    avaliacao: avaliacaoPayload,
    grupoDiscussao: grupoDiscussao,
    possuiAvaliacao: payload.possuiAvaliacao,
    sincronizarCrm: payload.sincronizarCrm
  })

  return true
}

export const RequisitarListaCursoOutros = async (
  texto: string
): Promise<PesquisaCursoDTO[]> => {
  try {
    const filtros = queryString.stringify({
      nome: texto
    })
    const { data } = await gatewayApi.get<PesquisaCursoDTO[]>(
      `${urlBase}/outros?${filtros}`
    )

    return data
  } catch {
    return null
  }
}

export const RequisitarListaCursoOutrosPorId = async (
  id: string
): Promise<RequisitarListaCursoOutrosPorIdResposta> => {
  try {
    const {
      data
    } = await gatewayApi.get<RequisitarListaCursoOutrosPorIdResposta>(
      `${urlBase}/${id}/outro`
    )

    return data
  } catch {
    return null
  }
}

export const RequisitarGradePorIdCurso = async (
  id: string
): Promise<PesquisaCursoDTO[]> => {
  try {
    const filtros = queryString.stringify({
      cursoId: id
    })
    const { data } = await gatewayApi.get<PesquisaCursoDTO[]>(
      `${urlBase}/grades?${filtros}`
    )

    return data
  } catch {
    return null
  }
}

export const RequisitarGradePorId = async (
  id: string
): Promise<GradeCurricularDto> => {
  try {
    const { data } = await gatewayApi.get<GradeCurricularDto>(
      `${urlBase}/grade/${id}`
    )

    return data
  } catch {
    return null
  }
}

export const InserirCursoLivre = async (
  payload: CursoLivre,
  disciplinas?: GradeCurricularDisciplina[],
  avaliacao?: Avaliacao,
  grupoDiscussao?: GrupoDiscussao
): Promise<boolean> => {
  let avaliacaoPayload = {
    ...avaliacao,
    perguntas: avaliacao.perguntas.map(p => ({
      id: p.id,
      texto: p.pergunta,
      respostas: [...p.respostas],
      explicacao: p.explicacao
    }))
  }

  if (Object.values(avaliacaoPayload).every(x => isEmpty(x))) {
    avaliacaoPayload = null
  }

  const result = await gatewayApi.post<{ cursoId: string }>(
    `${urlBase}/livre/criar`,
    {
      dadosBasicos: payload,
      versoCertificado: payload.versoCertificado,
      disciplinas: disciplinas,
      avaliacao: avaliacaoPayload,
      grupoDiscussao: grupoDiscussao,
      possuiAvaliacao: payload.possuiAvaliacao,
      sincronizarCrm: payload.sincronizarCrm
    }
  )

  return !!result.data.cursoId
}

export const RequisitarGradePorTexto = async (
  texto: string
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({
      nome: texto
    })
    const { data } = await gatewayApi.get<PesquisaCursoDTO[]>(
      `${urlBase}/grades?${filtros}`
    )
    return data.map(r => ({
      id: r.id,
      texto: r.nome,
      dadosAdicionais: r
    }))
  } catch {
    return null
  }
}

export const RequisitarAreas = async (texto: string): Promise<AreaCurso[]> => {
  try {
    const filtros = queryString.stringify({ nome: texto })

    const { data } = await gatewayApi.get<AreaCurso[]>(
      `${urlBase}/area?${filtros}`
    )
    return data
  } catch {
    return null
  }
}

export const RequisitarAreaPorId = async (id: string): Promise<AreaCurso> => {
  try {
    const { data } = await gatewayApi.get<AreaCurso>(`${urlBase}/area/${id}`)
    return data
  } catch {
    return null
  }
}

export const RequisitarCursosPorModalidade = async (
  modalidade?: ModalidadeCurso
): Promise<PesquisaCursoDTO[]> => {
  try {
    const { data } = await gatewayApi.get<PesquisaCursoDTO[]>(
      `${urlBase}/modalidade/${modalidade}`
    )
    return data
  } catch {
    return null
  }
}

export const ObterCursosAluno = async (): Promise<any> => {
  const url = `${urlBase}/cursos-usuario`
  const data = await gatewayApi.get(url)

  return data
}

export const ObterDisciplinasAluno = async (id: string): Promise<any> => {
  const url = `${urlBase}/${id}/disciplinas`
  const data = await gatewayApi.get(url)

  return data
}

export const SolicitarEmentaDisciplina = async (): Promise<any> => {
  const url = `${urlBase}/solicitar/ementa-disciplinas`
  const data = await gatewayApi.get(url)

  return data
}

export const CriarRequerimentos = async (
  modalidade: ModalidadeCurso,
  cursoId: string,
  observacao: string,
  disciplinas: string[]
): Promise<any> => {
  const url = '/Requerimento/solicitar/ementa-disciplinas'
  const data = await gatewayApi.post(url, {
    modalidade: modalidade,
    cursoId: cursoId,
    observacao: observacao,
    disciplinas: disciplinas
  })

  return data
}

export const ObterRequerimentos = async (
  tipoRequerimento?: string,
  situacaoRequerimento?: string,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<RequerimentoResponse> => {
  const url = `Requerimento?TipoRequerimento=${tipoRequerimento}&SituacaoRequerimento=${situacaoRequerimento}`
  const { data } = await gatewayApi.get<RequerimentoResponse>(url)

  return data
}

export const CancelarRequerimentoAluno = async (
  requerimentoId: string
): Promise<any> => {
  const url = `Requerimento/${requerimentoId}/cancelar`
  const { data } = await gatewayApi.put(url)

  return data
}
