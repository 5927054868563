import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ModalBase,
  Botao,
  AutenticacaoContainer,
  formatarNome
} from 'src/componentes'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { RotasAluno } from 'src/rotas/aluno'

import { ContainerAcoes, ContainerModal, CorpoModal } from './styles'
import { ModalConclusaoRef, ModalConclusaoProps } from './tipos'

const ModalConclusaoCurso: React.ForwardRefRenderFunction<
  ModalConclusaoRef,
  ModalConclusaoProps
> = ({ backdrop, dataFinalizacao }, ref) => {
  const { usuario } = AutenticacaoContainer.useContainer()
  const [aberta, definirAberta] = useState(false)
  const [carregando] = useState(false)
  const history = useHistory()

  const nomeUsuario = usuario.nome.split(' ')[0]

  const fechar = () => {
    definirAberta(false)
  }

  const abrir = () => {
    definirAberta(true)
  }

  useImperativeHandle<ModalConclusaoRef, ModalConclusaoRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase id="modal-pagamento" aberta={aberta} backdrop={backdrop}>
      <CorpoModal>
        <ContainerModal>
          <h5>Parabéns {formatarNome(nomeUsuario)}!</h5>
          <h5>Você concluiu todas as suas atividades com sucesso!</h5>
          <h3>
            Para garantir a emissão do seu certificado, verifique se todos os
            documentos obrigatórios estão anexados à sua conta.
          </h3>
          <h3>
            Importante: seu certificado será enviado para o seu e-mail em até
            120 dias após a data de finalização da sua turma, que é{' '}
            {formatadores.dataLocaleString(dataFinalizacao)}.
          </h3>

          <ContainerAcoes>
            <Botao
              texto="Fechar"
              tema="Secundario"
              type="button"
              disabled={carregando}
              onClick={() => fechar()}
            />
            <Botao
              texto="Ver documentos"
              onClick={() => history.push(RotasAluno.MeusDocumentosBrasileiro)}
            />
          </ContainerAcoes>
        </ContainerModal>
      </CorpoModal>
    </ModalBase>
  )
}

export const ModalConclusao = forwardRef(ModalConclusaoCurso)
