import { Cores } from 'src/componentes/styles'
import styled, { css } from 'styled-components'

export const CorpoModal = styled.div`
  width: 650px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 78%;
`

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  padding: 40px 46px;
  height: 100%;
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0px 10px 0px 0px;

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  p {
    margin-bottom: 20px;
  }
`

export const TituloPrincipal = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 36px;
`

export const ContainerBotoes = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`

export const Linha = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;
`

export const ContainerPequeno = styled.div`
  width: 30%;
`

export const ContainerMedio = styled.div`
  width: 50%;
`
export const LinhaBandeiras = styled(Linha)`
  margin-bottom: 25px;
`

export const ContainerBandeiras = styled(Linha)<{ selecionado?: boolean }>`
  width: 25%;
  transition: filter 0.2s;
  svg {
    box-shadow: 1px 1px 10px ${Cores.CINZA_1_CLARO};
    transition: box-shadow 0.2s;
  }

  ${({ selecionado }) =>
    !selecionado &&
    css`
      filter: grayscale(100%);

      svg {
        box-shadow: none;
      }
    `}
`

export const IconeLabel = styled.div`
  position: absolute;
  left: 90%;
  top: 0;

  svg {
    min-width: 12px;
    min-height: 12px;
  }
`

export const Aviso = styled.p`
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: ${Cores.PERIGO_ERRO};
`
