import React, { FC, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { toast, ToastOptions } from 'react-toastify'

import {
  RelacaoDocumentosProfessorEstrangeiro,
  ResumoDeDocumentosAnalisados,
  ResumoDeDocumentosDeEntradaMapper,
  ResumoDeDocumentosDeSaidaMapper,
  selecaoDeDocumentosParaEnvio,
  UploadDeDocumentos,
  UploadDocumentos,
  useRelacaoDeDocumentos
} from 'src/compartilhados'
import {
  Breadcrumb,
  Carregando,
  ErroContainerLayout,
  ErroLayoutContainer,
  IconeBack,
  Link,
  Modal,
  ModalRef
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import { SituacaoUpload } from 'src/tipos'
import { Usuario } from 'src/tipos/usuario'

import {
  ModalMotivoRejeicao,
  ModalVisualizacaoDocumento,
  ModalVisualizacaoDocumentoRef
} from './componentes'
import { ModalMotivoRejeicaoDados } from './componentes/modal-motivo-rejeicao/modal-motivo-rejeicao.component'
import {
  BotaoDeSalvar,
  ContainerBreadcrumbs,
  ContainerDaPagina,
  ContainerDosBotoes,
  ContainerLink,
  DescricaoContainer,
  Dicas,
  ParagrafoDaNacionalidade,
  TituloComplementar
} from './style'
import { RejeicaoDocumento } from './tipos'

const erroAoCarregarDados =
  'Ops! Houve algum problema no carregamento dos dados.'
const erroAoEnviarDocumentos = 'Erro ao enviar documentos.'
const erroAoBaixarDocumentos = 'Erro ao baixar documentos.'
const erroAoAtualizarDocumentos = 'Erro ao atualizar documentos.'
const erroAoProcessarDocumentos = 'Erro ao processar documentos.'
const erroAoObterDocumentos =
  'Não foi possível obter os documentos do professor.'
const erroAoObterOsDadosDoUsuario = 'Erro ao obter os dados do usuario.'
const ErroAoEnviarAnalises =
  'Erro ao enviar análise de aprovação de documentos.'
const toastOptions = { type: 'error' } as ToastOptions

export type DocumentosProfessorEstrangeiroProps = RouteComponentProps<{
  email: string
}>

const DocumentosProfessorEstrangeiro: FC<DocumentosProfessorEstrangeiroProps> = ({
  match
}) => {
  const history = useHistory()
  const { documentos, atualizar, reiniciar } = useRelacaoDeDocumentos(
    RelacaoDocumentosProfessorEstrangeiro
  )

  const [pronto, definirPronto] = useState<boolean>()
  const [carregando, definirCarregando] = useState<boolean>()
  const [dadosProfessor, definirDadosProfessor] = useState<Usuario>()
  const [
    rejeicaoDeDocumento,
    definirRejeicaoDeDocumento
  ] = useState<RejeicaoDocumento>()

  const modalDeCancelamento = useRef<ModalRef>(null)
  const modalMotivoRejeicaoRef = useRef<ModalRef>(null)
  const modalVisualizacaoRef = useRef<ModalVisualizacaoDocumentoRef>(null)
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()

  const obterProfessorPorEmail = async (email: string): Promise<Usuario> => {
    try {
      return await Api.RequisitarUsuarioPorEmail(email)
    } catch (erro) {
      throw new Error(erroAoObterOsDadosDoUsuario)
    }
  }

  const obterResumoDeDocumentos = async (idDoUsuario: string) => {
    try {
      const resposta = await Api.ObterResumoDocumentosProfessor(idDoUsuario)
      const resultado = ResumoDeDocumentosDeEntradaMapper(documentos, resposta)
      reiniciar(resultado)
    } catch (erro) {
      throw new Error(erroAoObterDocumentos)
    }
  }

  const uploadDeCriacao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EnviarDocumentosProfessor(
        dadosProfessor.id,
        formulario
      )

      if (resposta) {
        history.push(RotasAcademico.Professores)
      } else {
        toast(erroAoEnviarDocumentos, toastOptions)
      }
    } catch (erro) {
      throw new Error(erroAoEnviarDocumentos)
    }
  }

  const uploadDeEdicao = async (
    documentos: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const formulario = ResumoDeDocumentosDeSaidaMapper(documentos)
      const resposta = await Api.EditarDocumentoProfessor(
        dadosProfessor.id,
        formulario
      )

      if (resposta) {
        history.push(RotasAcademico.Professores)
      } else {
        toast(erroAoAtualizarDocumentos, toastOptions)
      }
    } catch (erro) {
      throw new Error(erroAoAtualizarDocumentos)
    }
  }

  const uploadDeAnalises = async (
    analises: UploadDocumentos[]
  ): Promise<void> => {
    try {
      const resultado = ResumoDeDocumentosAnalisados(analises)

      if (resultado && resultado.documentos.length > 0) {
        const resposta = await Api.AprovarDocumentoProfessor(resultado)

        if (resposta) {
          history.push(RotasAcademico.Professores)
        } else {
          toast(ErroAoEnviarAnalises, toastOptions)
        }
      }
    } catch (erro) {
      throw new Error(ErroAoEnviarAnalises)
    }
  }

  const uploadDeDocumentos = async (
    edicoes: UploadDocumentos[],
    criacoes: UploadDocumentos[],
    analises: UploadDocumentos[]
  ): Promise<void> => {
    try {
      definirCarregando(true)
      if (edicoes.length > 0) await uploadDeEdicao(edicoes)
      if (criacoes.length > 0) await uploadDeCriacao(criacoes)
      if (analises.length > 0) await uploadDeAnalises(analises)
    } catch (erro) {
      toast(erroAoProcessarDocumentos, toastOptions)
    } finally {
      definirCarregando(false)
    }
  }

  const eventoDeUpload = async (): Promise<void> => {
    const {
      edicoes,
      criacoes,
      analises,
      contador
    } = selecaoDeDocumentosParaEnvio(documentos)

    if (contador === 0) history.push(RotasAcademico.Professores)
    else await uploadDeDocumentos(edicoes, criacoes, analises)
  }

  const obterDownloadDeDocumentos = async (
    documentoId: string
  ): Promise<void> => {
    try {
      await Api.ObterDownloadDeDocumentos(dadosProfessor.id, documentoId)
    } catch (erro) {
      toast(erroAoBaixarDocumentos, toastOptions)
    }
  }

  const visualizarDocumento = async (documentoId: string) => {
    try {
      const documento = await Api.ObterArquivoDocumentoProfessor(
        dadosProfessor.id,
        documentoId
      )
      if (documento) {
        modalVisualizacaoRef?.current?.abrir(documento)
      }
    } catch (error) {
      toast(erroAoObterDocumentos, toastOptions)
    }
  }

  const obterDocumentacao = async (): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const usuario = await obterProfessorPorEmail(match.params.email)

      if (usuario) {
        definirDadosProfessor(usuario)
        await obterResumoDeDocumentos(usuario.id)
      } else {
        throw new Error(erroAoCarregarDados)
      }
    } catch (error) {
      const mensagens = erroAoCarregarDados
      const acaoVoltar = () => history.push(RotasAcademico.Professores)
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const aprovacao = (id: string): void => {
    const documento = documentos.find(documento => documento.id === id)
    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Aprovado
    } as UploadDocumentos

    atualizar(modificacao)
  }

  const rejeicao = (id: string): void => {
    definirRejeicaoDeDocumento({ id })
    modalMotivoRejeicaoRef?.current?.abrir()
  }

  const obterMotivoDaRejeicao = (dados: ModalMotivoRejeicaoDados): void => {
    const documento = documentos.find(
      documento => documento.id === rejeicaoDeDocumento.id
    )

    const modificacao = {
      ...documento,
      analisado: true,
      status: SituacaoUpload.Recusado,
      motivoDaRejeicao: dados.motivoDaRejeicao
    } as UploadDocumentos

    atualizar(modificacao)
    definirRejeicaoDeDocumento(null)
  }

  useEffect(() => {
    obterDocumentacao()
  }, [])

  return pronto && !erro ? (
    <ContainerDaPagina>
      <ContainerLink>
        <Link
          texto="Voltar"
          href={RotasAcademico.Professores}
          icone={IconeBack}
        />
      </ContainerLink>
      <ContainerBreadcrumbs>
        <Breadcrumb
          titulo="Documentos do Professor"
          atalhos={[
            {
              texto: 'Acadêmico',
              acao: () => history.goBack()
            },
            {
              texto: 'Professores',
              acao: () => history.goBack()
            },
            {
              texto: 'Documentos do Professor'
            }
          ]}
        />
      </ContainerBreadcrumbs>
      <TituloComplementar>Documentos do Professor</TituloComplementar>
      <DescricaoContainer>
        <p>Nome: {dadosProfessor.nomeApresentacao}</p>
        <ParagrafoDaNacionalidade>
          Nacionalidade: {dadosProfessor.nacionalidade}
        </ParagrafoDaNacionalidade>
        <p>Documento de Identidade: {dadosProfessor.documentoIdentidade}</p>
        <p>E-mail: {dadosProfessor.email}</p>
      </DescricaoContainer>
      <Dicas>
        O envio dos documentos é obrigatório para emissão do certificado do(s)
        curso(s)
      </Dicas>
      {documentos.map(documento => (
        <UploadDeDocumentos
          key={documento.id}
          documento={documento}
          eventoDeMudanca={atualizar}
          eventoDeDownload={obterDownloadDeDocumentos}
          eventoDeVisualizacao={visualizarDocumento}
          controleDeAprovacao={{
            ativo: true,
            eventoDeAprovacao: aprovacao,
            eventoDeRejeicao: rejeicao
          }}
        />
      ))}
      <ContainerDosBotoes>
        <Botao
          texto="Cancelar"
          tema="Secundario"
          type="button"
          onClick={() => modalDeCancelamento?.current?.abrir()}
        />
        <BotaoDeSalvar
          texto="Salvar"
          type="button"
          onClick={eventoDeUpload}
          carregando={carregando}
        />
      </ContainerDosBotoes>
      <Modal
        ref={modalDeCancelamento}
        backdrop
        id="modal-de-cancelamento"
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.push(RotasAcademico.Professores)
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalDeCancelamento?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </Modal>
      <ModalMotivoRejeicao
        ref={modalMotivoRejeicaoRef}
        backdrop
        id="modal-motivo-rejeicao"
        acaoPrimaria={obterMotivoDaRejeicao}
      />
      <ModalVisualizacaoDocumento
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento-aluno"
      />
    </ContainerDaPagina>
  ) : !pronto && !erro ? (
    <Carregando texto="Carregando dados do professor..." />
  ) : (
    <ErroContainerLayout />
  )
}

const Container: FC<DocumentosProfessorEstrangeiroProps> = (
  props: DocumentosProfessorEstrangeiroProps
) => (
  <ErroLayoutContainer.Provider>
    <DocumentosProfessorEstrangeiro {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(Container)
