import styled from 'styled-components'

import { Cores } from '../styles/cores'

export const Componente = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`

export const Tabela = styled.table`
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  color: #191414;
  border-collapse: collapse;
  width: 100%;

  thead {
    tr {
      th {
        background: ${Cores.CINZA_HEAD_TABLE};
        color: ${Cores.PRETO};
        font-family: 'Saira-Semi-Bold', sans-serif;
        font-weight: 600;
        font-size: 14px;
        padding: 24px 10px 24px 24px;
        border-bottom: none;
      }

      th:first-child {
        border-top-left-radius: 8px;
      }

      th:last-child {
        border-top-right-radius: 8px;
      }
    }
  }

  tbody {
    line-height: 125%;

    tr:first-child {
      border-top: 1px solid ${Cores.CINZA_3_CLARO};
    }

    tr {
      height: 48px;
      border: 1px solid ${Cores.FUNDO};
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid ${Cores.FUNDO};
      }

      &.par {
        background: ${Cores.FUNDO};
      }

      &.marcado {
        background: #afc2ed;
        border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
      }

      &.comMarcacao {
        cursor: pointer;
      }

      td,
      th {
        padding: 0 24px;
      }

      td > .situacao-ativo {
        color: ${Cores.SUCESSO_CLARO};
      }

      td > .situacao-inativo {
        color: ${Cores.CINZA_1_CLARO};
      }

      &:hover {
        border: 1px solid ${Cores.SECUNDARIA_1};
      }
    }
  }
`

export const DadoFake = styled.div`
  @keyframes backgroundAnimation {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 15% 100%;
    }
  }

  background: linear-gradient(
    45deg,
    ${Cores.CINZA_3_CLARO} 0%,
    ${Cores.CINZA_3} 33%,
    ${Cores.CINZA_3_CLARO} 66%,
    ${Cores.CINZA_3} 100%
  );
  background-size: 400% 400%;
  animation: backgroundAnimation 1s linear infinite;
  border-radius: 8px;
  height: 12px;
  width: 100%;
`

export const CheckboxFake = styled.div`
  @keyframes backgroundAnimation {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 15% 100%;
    }
  }

  background: linear-gradient(
    45deg,
    ${Cores.CINZA_3_CLARO} 0%,
    ${Cores.CINZA_3} 33%,
    ${Cores.CINZA_3_CLARO} 66%,
    ${Cores.CINZA_3} 100%
  );
  background-size: 400% 400%;
  animation: backgroundAnimation 1s linear infinite;
  border-radius: 8px;
  height: 24px;
  width: 24px;
`

export const ModalCarregamento = styled.div`
  position: absolute;
  background: ${Cores.PRIMARIA_2};
  padding: 25px 0;
  z-index: 1;
  box-shadow: 4px 8px 16px rgba(1, 59, 154, 0.24);
  border: 1px solid ${Cores.CINZA_3_CLARO};
  border-radius: 8px;
  white-space: nowrap;
  width: 170px;
  top: 128px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  color: ${Cores.BRANCO};

  svg {
    circle {
      stroke: ${Cores.BRANCO};
    }
  }

  p {
    font-size: 12px;
    margin-top: 12px;
    color: ${Cores.BRANCO};
  }
`

export const SemDados = styled.p`
  padding: 54px;
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  border: 1px solid ${Cores.CINZA_3_CLARO};
  color: ${Cores.CINZA_2_CLARO};
  text-align: center;
`

export const Informacao = styled.div<{
  comOrdenacao: boolean
  comLimparTodosFiltros: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;

  label {
    font-family: 'Saira-Semi-Bold', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${Cores.PRETO};
  }

  ${({ comOrdenacao, comLimparTodosFiltros }) => {
    if ((comOrdenacao && comLimparTodosFiltros) || comLimparTodosFiltros) {
      return `
        padding-right: 30px;
      `
    }

    if (comOrdenacao) {
      return `
        > label {
          margin-right: 30px;
        }
      `
    }

    return ''
  }}
`

export const Cabecalho = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

export const BotaoOrdenacao = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
  }

  &:hover {
    svg path {
      fill: ${Cores.PRIMARIA_1};
    }
  }

  &.asc {
    svg {
      transition: rotate 0.2s ease-in;

      path {
        fill: ${Cores.CINZA_1};
      }
    }
  }

  &.desc {
    svg {
      transform: rotate(-180deg);
      transition: rotate 0.2s ease-in;

      path {
        fill: ${Cores.CINZA_1};
      }
    }
  }

  &.customizado {
    position: absolute;
    top: 2px;
    right: 0px;
  }

  &.comLimparFiltros {
    right: 30px;
  }
`

export const Paginacao = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  height: 48px;

  select,
  label,
  p {
    font-family: 'Saira', sans-serif;
    font-size: 14px;
    color: ${Cores.CINZA_2};
  }

  select {
    border: none;
    outline: none;
    scroll-behavior: smooth;
    margin-left: 7px;
  }
`

export const TotalRegistorPorPagina = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Botoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;

  .numeros-pagina {
    margin: 0px 11px;
  }
`

export const BotaoPagina = styled.button`
  width: 24px;
  height: 24px;
  color: ${Cores.CINZA_2};
  border: none;
  border-radius: 8px;
  background: transparent;
  transition: 0.1s;
  cursor: pointer;

  svg {
    path {
      stroke: ${Cores.CINZA_2};
    }
  }

  &:hover {
    color: ${Cores.PRIMARIA_1};

    svg {
      path {
        stroke: ${Cores.PRIMARIA_1};
      }
    }
  }

  &.ativo {
    color: ${Cores.PRIMARIA_2};
    background-color: ${Cores.CINZA_3_MAIS_CLARO};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.36;
  }

  &.invertido {
    transform: rotateY(-180deg);
  }
`

export const Acoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const BotaoLimpar = styled.button`
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: all 0.1s ease-in;
  position: absolute;
  right: 0;

  &.com_renderizar_cabecalho {
    bottom: 50%;
    transform: translateY(100%);
  }

  &.desabilitado {
    pointer-events: none;
    svg {
      path,
      rect {
        fill: ${Cores.CINZA_3_ESCURO};
      }
    }
  }
`
