import { toast } from 'react-toastify'

import { RespostaPadraoAPI } from 'src/tipos'

import { gatewayApi, getApiError } from '../instancias-api'
import { UsuarioAuth } from './tipos'

const urlBase = '/Auth'

export const Entrar = async (
  usuario: string,
  senha: string
): Promise<string> => {
  let email = ''
  let cpf = ''
  let cnpj = ''

  if (
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi.test(
      usuario
    )
  ) {
    email = usuario
  } else {
    if (usuario?.replace(/\D/g, '').length === 11) {
      cpf = usuario
    }

    if (usuario?.replace(/\D/g, '').length === 14) {
      cnpj = usuario
    }
  }

  try {
    const {
      data: { token }
    } = await gatewayApi.post<{ token: string }>(`${urlBase}/conta/entrar`, {
      email,
      senha,
      cpf,
      cnpj
    })

    return token
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }
    return null
  }
}

export const RecuperarSenha = async (usuario: string): Promise<string> => {
  let email = ''
  let cpf = ''
  let cnpj

  if (
    /^(([^<>()[\]\\.,;:\s@"`´]+(\.[^<>()[\]\\.,;:\s@"`´]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      usuario
    )
  ) {
    email = usuario
  } else {
    if (usuario?.replace(/\D/g, '').length === 11) {
      cpf = usuario
    }

    if (usuario?.replace(/\D/g, '').length === 14) {
      cnpj = usuario
    }
  }

  try {
    const {
      data: { resetToken }
    } = await gatewayApi.post<{ resetToken: string }>(
      `${urlBase}/conta/recuperar-senha`,
      {
        email,
        cpf,
        cnpj
      }
    )

    return resetToken
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }
    return null
  }
}

export const RequisitarUsuarioAuth = async (
  id: string
): Promise<UsuarioAuth> => {
  const { data } = await gatewayApi.get<UsuarioAuth>(`${urlBase}/usuario/${id}`)

  return data
}

export const RedefinirSenha = async (
  usuarioId: string,
  senha: string,
  token: string
): Promise<boolean> => {
  try {
    await gatewayApi.post(`${urlBase}/usuario/${usuarioId}/redefinir-senha`, {
      senha,
      token
    })

    return true
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }
    return false
  }
}

export const PrimeiroAcesso = async (
  usuarioId: string,
  senha: string,
  token: string
): Promise<boolean> => {
  try {
    await gatewayApi.post(
      `${urlBase}/usuario/${usuarioId}/primeiro-acesso/confirmar`,
      {
        senha,
        token
      }
    )

    return true
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }
    return false
  }
}

export const NotificarUsuario = async (id: string): Promise<boolean> => {
  const resposta = await gatewayApi.post<RespostaPadraoAPI>(
    `${urlBase}/usuario/${id}/primeiro-acesso/notificar`
  )
  return resposta.status === 204
}

export const RecuperarSenhaUrl = async (usuario: string): Promise<string> => {
  let email = ''
  let cpf = ''

  if (/^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/i.test(usuario)) {
    email = usuario
  } else {
    cpf = usuario
  }

  try {
    const {
      data: { resetUrl }
    } = await gatewayApi.post<{ resetUrl: string }>(
      `${urlBase}/conta/recuperar-senha`,
      {
        email,
        cpf
      }
    )

    return resetUrl.substring(22, resetUrl.length)
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }
    return null
  }
}
