import React from 'react'

import { FuncoesDataHora } from 'src/componentes'
import { ModalidadeCursoPorExtenso, OpcaoTCC } from 'src/tipos'

import {
  MatriculaAluno,
  SituacaoFinanceiraExtenso,
  TipoMatriculaPorExtenso,
  TipoProrrogacaoPorExtenso
} from '../../tipos'
import { ContainerAbaDivisao, DivisorAba } from './styles'

interface AbaMatriculaProps {
  informacoes: MatriculaAluno
}

export const AbaMatricula: React.FC<AbaMatriculaProps> = ({ informacoes }) => {
  return (
    <ContainerAbaDivisao>
      <DivisorAba>
        <h5>Detalhes</h5>
        <p>Curso: {informacoes.nomeCurso}</p>
        <p>Modalidade: {ModalidadeCursoPorExtenso[informacoes.modalidade]} </p>
        <p>
          Data Início:{' '}
          {informacoes.dataInicio
            ? FuncoesDataHora.dataToLocaleString(informacoes.dataInicio)
            : 'Não existe data de início.'}
        </p>
        <p>
          Data Finalização:{' '}
          {informacoes.dataFimProrrogacao || informacoes.dataFimTurma
            ? FuncoesDataHora.dataToLocaleString(
                informacoes.dataFimProrrogacao ?? informacoes.dataFimTurma
              )
            : 'Não existe data de finalização'}
        </p>

        {SituacaoFinanceiraExtenso[informacoes.situacaoFinanceira] ===
          SituacaoFinanceiraExtenso.Finalizado && (
          <p>
            Data da Aprovação automática:{' '}
            {informacoes.dataAprovacao
              ? FuncoesDataHora.dataToLocaleString(informacoes.dataAprovacao)
              : '-'}
          </p>
        )}

        {SituacaoFinanceiraExtenso[informacoes.situacaoFinanceira] ===
          SituacaoFinanceiraExtenso.Reprovado && (
          <p>
            Data da Reprovação automática:{' '}
            {informacoes.dataReprovacao
              ? FuncoesDataHora.dataToLocaleString(informacoes.dataReprovacao)
              : '-'}
          </p>
        )}

        {informacoes.possuiProrrogacao && (
          <>
            <p>Prorrogação: </p>
            <li>
              {informacoes.prorrogacoes?.map((x, index) => (
                <ul key={`prorrogacao_${index}`}>
                  <p>
                    {TipoProrrogacaoPorExtenso[x.tipoProrrogacao]} -{' '}
                    {x.quantidadeMeses} dias - Solicitado em{' '}
                    {FuncoesDataHora.dataToLocaleString(x.dataCriacao)}
                  </p>
                </ul>
              ))}
            </li>
          </>
        )}

        <p>Situação da Matrícula: {informacoes.situacaoMatricula}</p>
        {informacoes.possuiTransferencia && (
          <>
            {' '}
            <p>Turma Destino: {informacoes.turmaDestino}</p>
            <p>Transferido por: {informacoes.transferidoPor}</p>
          </>
        )}

        <p>
          Tipo de Aluno: {TipoMatriculaPorExtenso[informacoes.tipoMatricula]}
        </p>
      </DivisorAba>
      <DivisorAba>
        <h5>TCC</h5>
        <p>Opção: {informacoes.opcaoTCC ?? 'Não existe opção escolhida.'}</p>
        <p>
          Data da Opção:{' '}
          {informacoes.dataOpcaoTCC
            ? FuncoesDataHora.dataToLocaleString(informacoes.dataOpcaoTCC)
            : 'Não existe data de opção.'}
        </p>
        {OpcaoTCC[informacoes.opcaoTCC] === OpcaoTCC[OpcaoTCC.Cancelamento] && (
          <p>
            Data do cancelamento do TCC:{' '}
            {informacoes.dataHoraAlteracaoTCC
              ? FuncoesDataHora.dataToLocaleString(
                  informacoes.dataHoraAlteracaoTCC
                )
              : 'Não existe data de opção.'}
          </p>
        )}
        <p>Título do TCC: {informacoes.tituloTCC ?? '-'} </p>
        <p>
          Professor Orientador:{' '}
          {OpcaoTCC[informacoes.opcaoTCC] === OpcaoTCC[OpcaoTCC.Aproveitamento]
            ? '-'
            : informacoes.professorOrientador ?? '-'}
        </p>
        <p>
          Professor Banca:{' '}
          {OpcaoTCC[informacoes.opcaoTCC] === OpcaoTCC[OpcaoTCC.Aproveitamento]
            ? '-'
            : informacoes.professorBanca ?? '-'}
        </p>
      </DivisorAba>
    </ContainerAbaDivisao>
  )
}
