import queryString from 'query-string'
import { PesquisaDisciplinaDTO, PesquisaDisciplinasResposta } from 'src/dto'
import {
  Disciplina,
  DisciplinaSituacao,
  ModalidadeCurso,
  OpcoesPaginacao,
  RespostaPadraoAPI,
  RespostaPadraoDadosAPI,
  TipoDisciplina,
  TipoOrdenacao
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'
import { materiaisParaFormData } from './comum'

interface RespostaInserirDisciplia extends RespostaPadraoAPI {
  data: {
    id: string
  }
}

const urlBase = '/disciplina'

export const RequisitarDisciplina = async (id: string): Promise<Disciplina> => {
  const {
    data: { data }
  } = await gatewayApi.get<RespostaPadraoDadosAPI<Disciplina>>(
    `${urlBase}/${id}`
  )

  return { ...data }
}

export const RequisitarListaDisciplinas = async (
  codigo?: string,
  nome?: string,
  curso?: number,
  situacao?: keyof typeof DisciplinaSituacao,
  pagina?: number,
  quantidadeRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaDisciplinaDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      codigo,
      nome,
      curso,
      situacao: situacao === 'Todas' ? null : situacao,
      quantidadeRegistros,
      pagina,
      ordem
    })

    const {
      data: {
        data: { itens, quantidade }
      }
    } = await gatewayApi.get<PesquisaDisciplinasResposta>(
      `${urlBase}?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }

    return new Promise(resolve =>
      resolve({ registros: itens, opcoesPaginacao })
    )
  } catch {
    return null
  }
}

export const InserirDisciplina = async (
  disciplinas: Disciplina,
  preRequisitos: string[]
): Promise<boolean> => {
  const {
    data: {
      success,
      data: { id }
    }
  } = await gatewayApi.post<RespostaInserirDisciplia>(`${urlBase}`, {
    disciplina: disciplinas,
    preRequisito: preRequisitos.map(p => ({
      Id: p
    }))
  })

  if (disciplinas.materiais && id) {
    const bodyFormData = materiaisParaFormData(disciplinas.materiais)
    await gatewayApi.post<RespostaPadraoAPI>(
      `${urlBase}/${id}/materiais`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  return success
}

export const EditarDisciplina = async (
  id: string,
  payload: Disciplina,
  preRequisitos: string[]
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.put<RespostaPadraoAPI>(`${urlBase}/${id}`, {
    disciplina: payload,
    preRequisito: preRequisitos.map(p => ({
      Id: p
    }))
  })

  if (payload.modalidadeCurso !== ModalidadeCurso.Outra) {
    const bodyFormData = materiaisParaFormData(payload.materiais)
    await gatewayApi.put<RespostaPadraoAPI>(
      `${urlBase}/${id}/materiais`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  return success
}

export const ObterDisciplinaListaCombo = async (
  modalidade: ModalidadeCurso,
  tipo: TipoDisciplina
): Promise<Disciplina[]> => {
  const { data } = await gatewayApi.get<Disciplina[]>(
    `${urlBase}/lista-combo?${queryString.stringify({
      modalidade: modalidade,
      tipo: tipo
    })}`
  )

  return data
}
export const ObterDisciplinaPorTexto = async (
  modalidade: ModalidadeCurso,
  texto: string,
  tipo: TipoDisciplina
): Promise<{
  registros: PesquisaDisciplinaDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  const {
    data: {
      data: { itens }
    }
  } = await gatewayApi.get<PesquisaDisciplinasResposta>(
    `${urlBase}?${queryString.stringify({
      modalidadeCurso: modalidade,
      codigoOuNome: texto,
      tipo: tipo
    })}`
  )

  return new Promise(resolve =>
    resolve({ registros: itens, opcoesPaginacao: null })
  )
}
