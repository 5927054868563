import { ControladorDeConteudosConfiguracao } from 'src/compartilhados'
import { MatriculaDoPainelDoAluno } from 'src/tipos'

import { PadraoDeDisposicao } from './padrao-de-disposicao.interface'
import { padroes } from './padroes'

const obterPadronizacao = (
  padrao: PadraoDeDisposicao,
  matriculas?: Array<MatriculaDoPainelDoAluno>
) => {
  if (!matriculas) {
    return false
  }

  const manipulador = (matricula: MatriculaDoPainelDoAluno, indice: number) =>
    matricula.curso.modalidade === padrao.condicao[indice]

  return matriculas.slice(0, padrao.disposicao).every(manipulador)
}

export const controleDeDisposicao = (
  matriculas: Array<MatriculaDoPainelDoAluno>,
  configuracao: ControladorDeConteudosConfiguracao
): ControladorDeConteudosConfiguracao => {
  if (!matriculas) {
    return configuracao
  }

  for (const padrao of padroes) {
    const possuiPadronizacao = obterPadronizacao(padrao, matriculas)

    if (possuiPadronizacao) {
      return {
        disposicaoInicial: padrao.disposicao,
        passos: padrao.disposicao
      }
    }
  }

  return configuracao
}

export function useControleDeDisposicao(
  matriculas: Array<MatriculaDoPainelDoAluno>,
  configuracao: ControladorDeConteudosConfiguracao
): ControladorDeConteudosConfiguracao {
  return controleDeDisposicao(matriculas, configuracao)
}
