export enum SituacaoResponsavelFinanceiroPorExtenso {
  AguardandoFinalizacao = 'AguardandoFinalizacao',
  EmailEnviado = 'EmailEnviado',
  Aceito = 'Aceito',
  Recusado = 'Recusado',
  RecusadoAutomaticamente = 'RecusadoAutomaticamente'
}

export enum SituacaoResponsavelFinanceiro {
  AguardandoFinalizacao = 0,
  EmailEnviado = 1,
  Aceito = 2,
  Recusado = 3,
  RecusadoAutomaticamente = 4
}
