import React from 'react'

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <path
            d="M19.625 -0.0400391H5.375C4.06875 -0.0400391 3 0.996761 3 2.26396V20.696C3 21.9632 4.06875 23 5.375 23H19.625C20.9312 23 22 21.9632 22 20.696V2.26396C22 0.996761 20.9312 -0.0400391 19.625 -0.0400391ZM8.9375 2.26396H11.3125V8.02396L10.125 7.15996L8.9375 8.02396V2.26396ZM19.625 20.696H5.375V2.26396H6.5625V12.632L10.125 10.04L13.6875 12.632V2.26396H19.625V20.696Z"
            fill="#666666"
            fillOpacity="0.9"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 -0.230469)"
        />
      </clipPath>
      <clipPath id="clip2">
        <rect width="21" height="24" fill="white" transform="translate(2 -1)" />
      </clipPath>
    </defs>
  </svg>
)
