import styled from 'styled-components'

import { Cores } from '../styles/cores'

export const ContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const BotaoMobile = styled.button`
  border: none;
  outline: none;
  background: ${Cores.PRIMARIA_1};
  text-align: center;
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 20px 10px;

  div {
    margin-bottom: 0;

    svg {
      width: 48px;
      height: 48px;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > button {
    width: 120px;
    margin-top: 8px;
  }
`
