export enum SituacaoFinanceira {
  Reservado,
  Inativo,
  Matriculado,
  ContratoRescindido,
  Cancelado,
  Transferido,
  Finalizado,
  Reprovado,
  AguardandoCartaoPagamentoRecorrente,
  Recuperacao
}

export enum SituacaoFinanceiraFiltro {
  Reservado = 'Reservado',
  Inativo = 'Inativo',
  Matriculado = 'Matriculado',
  ContratoRescindido = 'ContratoRescindido',
  Cancelado = 'Cancelado',
  Transferido = 'Transferido',
  Finalizado = 'Finalizado',
  Reprovado = 'Reprovado',
  AguardandoCartaoPagamentoRecorrente = 'AguardandoCartaoPagamentoRecorrente',
  Recuperacao = 'Recuperacao'
}
