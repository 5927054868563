import React from 'react'

export default (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7689 21.1805C15.7689 21.4567 15.5533 21.6868 15.2761 21.6868H12.3191C12.0419 21.6868 11.8263 21.4567 11.8263 21.1805V18.2658C11.8263 17.9897 12.0419 17.7595 12.3191 17.7595H15.2761C15.5533 17.7595 15.7689 17.9897 15.7689 18.2658V21.1805ZM15.7381 15.443C15.7227 15.6425 15.4917 15.7959 15.2145 15.7959H12.3653C12.0727 15.7959 11.8417 15.6425 11.8417 15.443L11.5798 8.43223C11.5798 8.34021 11.6261 8.1868 11.7338 8.11013C11.8263 8.03339 11.9649 7.94137 12.1035 7.94137H15.4917C15.6303 7.94137 15.7689 8.03339 15.8613 8.11013C15.9691 8.1868 16.0154 8.30952 16.0154 8.40154L15.7381 15.443ZM15.5225 1.11464C15.1836 0.485656 14.5214 0.086792 13.7976 0.086792C13.0737 0.086792 12.4115 0.485656 12.0727 1.11464L0.244779 22.7147C-0.0940423 23.3129 -0.0786408 24.0493 0.27558 24.6476C0.629802 25.2459 1.27664 25.6141 1.96968 25.6141H25.6255C26.3186 25.6141 26.9654 25.2459 27.3196 24.6476C27.6738 24.0493 27.6892 23.3129 27.3504 22.7147L15.5225 1.11464Z"
      fill="#E6B617"
    />
  </svg>
)
