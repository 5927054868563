import { mensagemPadrao } from 'src/dados-estaticos'
import * as Yup from 'yup'
export const schema = Yup.object().shape({
  pergunta: Yup.string().required(mensagemPadrao),
  respostas: Yup.array(
    Yup.object().shape({
      texto: Yup.string().required(),
      correta: Yup.bool()
    })
  )
    .min(2, 'Ao menos duas respostas devem ser informadadas.')
    .max(5, 'Apenas cinco respostas podem ser cadastradas.')
    .test(
      'umaRespostaCorreta',
      'Uma alternativa deve estar correta',
      val => val?.length > 0 && val.some(i => i.correta)
    )
    .test(
      'apenasUmaRespostaCorreta',
      'Apenas uma alternativa deve estar correta',
      val =>
        val?.length > 0 && val.filter(x => x.correta === true)?.length === 1
    )
    .test(
      'alternativasComTexto',
      'Todas alternativas devem ter texto cadastrado',
      val => val?.length > 0 && val.every(x => !!x.texto)
    )
})
