import styled from 'styled-components'

export const ComponenteLayout = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const ContainerBarra = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const Conteudo = styled.main`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  word-break: break-word;
  padding: 24px;
  justify-content: center;
`
