import React from 'react'

import { TabelaColuna, TabelaDados } from 'src/componentes'
import { SituacaoFinanceiraPorExtenso } from 'src/tipos'

export const colunaSituacaoFinanceira = (): TabelaColuna => ({
  Chave: 'situacao',
  ComOrdenacao: true,
  Renderizar: (linha: TabelaDados) => (
    <>{SituacaoFinanceiraPorExtenso[linha.situacao]}</>
  ),
  RenderizarCabecalho: () => (
    <div style={{ width: 100 }}>Situação Matrícula</div>
  )
})
