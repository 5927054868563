import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react'
import { Editor as EditorDraft } from 'react-draft-wysiwyg'

import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Cores } from '../styles/cores'
import ptBR from './ptBR'
import { Container } from './styles'
import { EditorRef, EditorProps } from './tipos'

export const Editor: React.ForwardRefExoticComponent<EditorProps> = forwardRef<
  EditorRef,
  EditorProps
>(
  (
    {
      id,
      defaultValue,
      urlUploadImage,
      minHeight,
      erro,
      readOnly,
      maxHeight,
      placeholder
    },
    ref: any
  ) => {
    const contentState = useMemo(() => {
      const { contentBlocks, entityMap } = htmlToDraft(defaultValue || '')

      return ContentState.createFromBlockArray(contentBlocks, entityMap)
    }, [defaultValue])

    const [valor, definirValor] = useState(
      defaultValue
        ? EditorState.createWithContent(contentState)
        : EditorState.createEmpty()
    )

    useEffect(() => {
      definirValor(EditorState.createWithContent(contentState))
    }, [contentState])

    const uploadCallback = () =>
      new Promise(resolve =>
        resolve({
          data: {
            link: urlUploadImage
          }
        })
      )

    useImperativeHandle<unknown, EditorRef>(ref, () => ({
      definirValor: value => {
        const { contentBlocks, entityMap } = htmlToDraft(value || '')

        definirValor(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlocks, entityMap)
          )
        )
      },
      valorHTML: () => {
        return valor.getCurrentContent().hasText()
          ? draftToHtml(convertToRaw(valor.getCurrentContent()))
          : ''
      },
      limpar: () => definirValor(EditorState.createEmpty())
    }))

    return (
      <Container id={id}>
        <EditorDraft
          editorState={valor}
          onEditorStateChange={content => {
            definirValor(content)
          }}
          editorStyle={{
            width: '100%',
            minHeight: minHeight || '100px',
            maxHeight: maxHeight || '100px',
            border: readOnly
              ? undefined
              : `1px solid ${erro ? Cores.PERIGO_ERRO : Cores.CINZA_3_ESCURO}`,
            borderRadius: '0 0 4px 4px',
            background: 'white'
          }}
          toolbar={{
            options: [
              'blockType',
              'fontFamily',
              'inline',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'image',
              'remove',
              'history'
            ],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough']
            },
            image: {
              uploadEnabled: true,
              uploadCallback: urlUploadImage ? uploadCallback : undefined,
              defaultSize: {
                height: 158,
                width: 610
              }
            }
          }}
          localization={{ translations: ptBR }}
          toolbarHidden={readOnly}
          readOnly={readOnly}
          placeholder={placeholder}
        />
      </Container>
    )
  }
)
