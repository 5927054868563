import React from 'react'

export default (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4006 8.45455C21.6369 7.90909 20.8732 7.90909 20.2369 8.18182C19.4732 8.31818 18.8368 8.86364 18.3277 9.68182L14.0003 17.7273L13.8731 17.8636V18L13.7458 18.1364V22.9091C13.7458 23.4545 14.1276 24 14.764 24H15.0186C15.2731 24 15.2731 24 15.4004 23.8636L19.0914 21.2727C19.2187 21.2727 19.3459 21.1364 19.3459 21C19.3459 21 19.3459 21 19.3459 20.8636L23.6733 12.6818C24.437 11.3182 23.8006 9.27273 22.4006 8.45455ZM22.0187 10.9091C22.0187 11.1818 22.0187 11.4545 21.8914 11.5909L17.5641 19.5L15.7822 20.8636L15.9095 18.6818L20.2369 10.9091C20.4914 10.3636 21.1278 10.3636 21.6369 10.5C21.6369 10.5 21.8914 10.7727 22.0187 10.9091Z"
      fill="#0accb9"
    />
    <path
      d="M10.6912 13.0909C13.8731 13.0909 16.5459 10.2273 16.5459 6.54546C16.5459 2.86364 13.8731 0 10.6912 0C7.50928 0 4.83648 2.86364 4.83648 6.54546C4.83648 10.2273 7.50928 13.0909 10.6912 13.0909ZM10.6912 2.18182C12.7276 2.18182 14.5095 4.09091 14.5095 6.54546C14.5095 9 12.7276 10.9091 10.6912 10.9091C8.65476 10.9091 6.8729 9 6.8729 6.54546C6.8729 4.09091 8.65476 2.18182 10.6912 2.18182Z"
      fill="#0accb9"
    />
    <path
      d="M20.3641 21.9545C20.3641 22.2273 20.4914 22.6364 20.4914 22.9091C20.4914 23.4545 20.8732 24 21.5096 24C22.0187 24 22.5278 23.5909 22.5278 22.9091C22.5278 21.6818 22.2733 20.4545 21.7642 19.3636L21.0005 20.8636C20.6187 21.2727 20.4914 21.6818 20.3641 21.9545Z"
      fill="#0accb9"
    />
    <path
      d="M13.2367 15.2727H7.382C3.30917 15.2727 0 18.6818 0 22.9091C0 23.4545 0.381828 24 1.01821 24C1.65459 24 2.03641 23.5909 2.03641 22.9091C2.03641 19.7727 4.45466 17.3182 7.382 17.3182H12.2185L13.2367 15.2727Z"
      fill="#0accb9"
    />
  </svg>
)
