import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  DataInputUnform,
  FormRef,
  FormUnform,
  InputUnform,
  ModalBase,
  ModalRef,
  MoedaInputUnform,
  SelectOpcao,
  SelectUnform
} from 'src/componentes'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'
import { Api } from 'src/servicos'
import {
  FormaPagamentoCobranca,
  BaixaManualTaxaTrocaCurso,
  CupomValido
} from 'src/tipos'

import { schema } from './schema'
import {
  ContainerDoConteudo,
  ContainerDosBotoes,
  ContainerModal,
  TituloPrincipal
} from './styles'
import { ModalBaixaManualTaxaTrocaCursoProps } from './tipos'

export interface ModalBaixaManualTaxaTrocaCursoRef
  extends Omit<ModalRef, 'abrir'> {
  abrir: (valorPagamento: number, matriculaId: string) => void
}

const Modal: React.ForwardRefRenderFunction<
  ModalBaixaManualTaxaTrocaCursoRef,
  ModalBaixaManualTaxaTrocaCursoProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [carregando, definirCarregando] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const [matriculaId, definirMatriculaId] = useState('')
  const [
    formaPagamentoEscolhida,
    definirFormaPagamentoEscolhida
  ] = useState<FormaPagamentoCobranca>(null)
  const formRef = useRef<FormHandles>(null)
  const formCupomRef = useRef<FormRef>(null)
  const [cupom, definirCupom] = useState<CupomValido>({
    cupomId: '',
    codigoCupom: '',
    valorDesconto: null,
    valorFinal: null
  })
  const [valorPagamentoInicial, definirValorPagamentoInicial] = useState(null)

  const atualizarValorFormulario = async (campo: string, valor: any) => {
    if (!valor) return
    formRef?.current.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const tipoFormaPagamento = (opcao: SelectOpcao) => {
    if (!opcao?.id) return
    const escolhido = FormaPagamentoCobranca[opcao?.id]
    definirFormaPagamentoEscolhida(escolhido)

    if (escolhido === FormaPagamentoCobranca.CupomDesconto) {
      atualizarValorFormulario('valorPagamento', valorPagamentoInicial)
    }
  }
  const fechar = () => definirAberta(false)
  const abrir = (valorPagamento: number, matriculaId: string) => {
    definirMatriculaId(matriculaId)
    atualizarValorFormulario('valorPagamento', valorPagamento)
    definirValorPagamentoInicial(valorPagamento)
    definirAberta(true)
  }

  const formasPagamento = useMemo(() => {
    return [
      { id: FormaPagamentoCobranca.Boleto, texto: 'Boleto' },
      { id: FormaPagamentoCobranca.CartaoCredito, texto: 'Cartão de Crédito' },
      { id: FormaPagamentoCobranca.Cheque, texto: 'Cheque' },
      { id: FormaPagamentoCobranca.CupomDesconto, texto: 'Cupom de Desconto' },
      { id: FormaPagamentoCobranca.Deposito, texto: 'Depósito' },
      { id: FormaPagamentoCobranca.Dinheiro, texto: 'Dinheiro' },
      { id: FormaPagamentoCobranca.PagSeguro, texto: 'PagSeguro' },
      { id: FormaPagamentoCobranca.PayPal, texto: 'PayPal' },
      { id: FormaPagamentoCobranca.Pix, texto: 'Pix' },
      { id: FormaPagamentoCobranca.TED, texto: 'TED' }
    ] as SelectOpcao[]
  }, [])

  useImperativeHandle<
    ModalBaixaManualTaxaTrocaCursoRef,
    ModalBaixaManualTaxaTrocaCursoRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucessoForm = async (dados: BaixaManualTaxaTrocaCurso) => {
    try {
      definirCarregando(true)
      if (formaPagamentoEscolhida === FormaPagamentoCobranca.CupomDesconto) {
        dados.valorPagamento = valorPagamentoInicial
      }
      const dadosBaixa = { ...dados, codigoCupom: cupom?.codigoCupom }
      await acaoPrimaria(dadosBaixa)
    } finally {
      fechar()
      formRef.current?.reset()
      definirCarregando(false)
    }
  }

  const validarCupom = async ({ codigoCupom }: { codigoCupom?: string }) => {
    if (codigoCupom) {
      const novoCupom = await Api.RequisitarValidarCupomTaxaTrocaDeCurso(
        matriculaId,
        codigoCupom,
        valorPagamentoInicial
      )

      if (novoCupom) {
        definirCupom({ ...novoCupom, codigoCupom })
        atualizarValorFormulario('valorPagamento', novoCupom.valorDesconto)
      } else {
        definirCupom({} as CupomValido)
        atualizarValorFormulario('valorPagamento', valorPagamentoInicial)
      }

      formCupomRef.current?.setFieldError(
        'codigoCupom',
        novoCupom ? 'Cupom válido' : 'Cupom inválido'
      )
    } else {
      definirCupom({} as CupomValido)
      formCupomRef.current?.setFieldError('codigoCupom', '')
    }
  }
  const focarPrimeiroCampoComErro = (idContexto?: string): void => {
    setTimeout(() => {
      const campo = document
        .querySelectorAll(`${idContexto ? `#${idContexto} ` : ''}.error`)
        .item(0) as HTMLElement

      if (campo) {
        campo.scrollIntoView({ block: 'start', behavior: 'smooth' })
        campo.focus()
      }
    }, 0)
  }

  return (
    <ModalBase
      id="modal-atualizar-data-cobranca"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>
          Realizar baixa manual de taxa troca de curso
        </TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm} schema={schema}>
          <ContainerDoConteudo>
            <SelectUnform
              id="slct-forma-pagamento"
              label="Forma de Pagamento"
              name="formaPagamento"
              opcoes={formasPagamento}
              valorAlterado={tipoFormaPagamento}
            />
            {formaPagamentoEscolhida ===
              FormaPagamentoCobranca.CupomDesconto && (
              <FormUnform
                dadosIniciais={{
                  codigoCupom: cupom?.codigoCupom || ''
                }}
                ref={formCupomRef}
                acaoSucesso={validarCupom}
                acaoFalha={focarPrimeiroCampoComErro}
              >
                <InputUnform
                  id="ipt_cupom"
                  label="Código do cupom"
                  name="codigoCupom"
                  sucesso={!!cupom.cupomId}
                  onBlur={() => {
                    formCupomRef.current?.submitForm()
                  }}
                />
              </FormUnform>
            )}
            <DataInputUnform label="Data de Pagamento" name="dataPagamento" />
            <MoedaInputUnform
              label="Valor de Pagamento"
              name="valorPagamento"
            />
            <ArquivoInputUnform
              label="Comprovante de Pagamento"
              name="comprovante"
              tiposAceitos={['.pdf', '.jpg', '.png']}
            />
          </ContainerDoConteudo>
          <ContainerDosBotoes>
            <Botao
              texto="Cancelar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Salvar" carregando={carregando} />
          </ContainerDosBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}

export const ModalBaixaManualTaxaTrocaCurso = forwardRef(Modal)
