import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  ModalBase,
  Botao,
  IconeDeAvisoDeAdvertencia,
  FuncoesDataHora
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import { StatusCarrinhoCompra, CarrinhoCompraExistenteUsuario } from 'src/tipos'

import { Corpo, Texto, Titulo, ContainerBotoes, TextoMarginTop } from './styles'
import {
  ModalCarrinhoCompraExistenteProps,
  ModalCarrinhoCompraExistenteRef
} from './tipos'

const ModalCarrinho: React.ForwardRefRenderFunction<
  ModalCarrinhoCompraExistenteRef,
  ModalCarrinhoCompraExistenteProps
> = ({ backdrop, acaoFinalizar }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const history = useHistory()
  const [carrinhosExistentes, definirCarrinhosExistentes] = useState<
    CarrinhoCompraExistenteUsuario[]
  >([] as CarrinhoCompraExistenteUsuario[])

  const fechar = () => {
    definirAberta(false)
    definirCarrinhosExistentes([] as CarrinhoCompraExistenteUsuario[])
  }

  const abrir = (carrinhosExistentes: CarrinhoCompraExistenteUsuario[]) => {
    definirCarrinhosExistentes(carrinhosExistentes)
    definirAberta(true)
  }

  useImperativeHandle<
    ModalCarrinhoCompraExistenteRef,
    ModalCarrinhoCompraExistenteRef
  >(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = () => {
    fechar()
    history.push('/')
  }

  const acaoFinalizarPedido = (
    idCarrinhoCompraCursoLivre: string,
    aguardandoPagamento: boolean
  ) => {
    let url = '/'

    if (aguardandoPagamento) {
      url = `${RotasAluno.FinanceiroCursoLivre}/carrinho-compra/${idCarrinhoCompraCursoLivre}`
    }

    history.push(url)
  }

  const acaoCancelarPedido = async (idCarrinhoCompraCursoLivre: string) => {
    try {
      await Api.CancelarCarrinhoCompraCursoLivre(idCarrinhoCompraCursoLivre)
      const carrinhosComConflito = carrinhosExistentes?.filter(
        c => c.carrinhoCompraCursoLivreId !== idCarrinhoCompraCursoLivre
      )
      if (carrinhosComConflito?.length <= 1) {
        if (acaoFinalizar) {
          acaoFinalizar()
        } else {
          fechar()
        }
      } else {
        definirCarrinhosExistentes(carrinhosComConflito)
      }
    } catch (erro) {
      toast('Houve um erro ao cancelar pedido. Tente novamente!')
    }
  }

  return (
    <ModalBase aberta={aberta} backdrop={backdrop}>
      <Corpo>
        {IconeDeAvisoDeAdvertencia}
        <Titulo>Aviso</Titulo>
        <Texto>Você já possui pedidos para o curso selecionado</Texto>
        <Texto> Pedidos:</Texto>
        {carrinhosExistentes?.map(c => {
          return (
            <>
              <TextoMarginTop>
                Status: {c.statusCarrinho} - Data Criação{' '}
                {FuncoesDataHora.dataFormatadaComBarras(
                  new Date(c.dataCriacao)
                )}
              </TextoMarginTop>
              <Texto>
                Cursos: {c.cursosCarrinhoCompra?.map(c => c.nome).join('; ')}
              </Texto>
              {c.statusCarrinho === StatusCarrinhoCompra.Aberto ? (
                <ContainerBotoes>
                  <Botao
                    texto="Finalizar Pedido"
                    onClick={() =>
                      acaoFinalizarPedido(
                        c.carrinhoCompraCursoLivreId,
                        c.aguardandoPagamento
                      )
                    }
                  />
                  <Botao
                    texto="Cancelar Pedido"
                    onClick={() =>
                      acaoCancelarPedido(c.carrinhoCompraCursoLivreId)
                    }
                  />
                </ContainerBotoes>
              ) : (
                <Botao texto="Ir para Home" onClick={acaoSucesso} />
              )}
            </>
          )
        })}
      </Corpo>
    </ModalBase>
  )
}

export const ModalCarrinhoCompraExistente = forwardRef(ModalCarrinho)
