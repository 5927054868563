import React from 'react'

export default (
  <svg
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="help">
      <g id="Group">
        <circle
          id="Ellipse 12"
          opacity="0.5"
          cx="23.5"
          cy="23.5"
          r="23.5"
          fill="#80CDCD"
        />
        <g id="Group_2">
          <path
            id="Vector"
            d="M36.64 10.8839L18.5404 10.8462C17.0607 10.8462 15.8615 12.0339 15.8615 13.4989V23.0198C15.5803 28.0193 16.6967 28.0193 18.5498 28.0193H36.64C38.1191 28.0193 39.3184 26.8315 39.3184 25.3666V13.5371C39.3184 12.0716 38.1191 10.8839 36.64 10.8839Z"
            fill="#006969"
          />
          <path
            id="Vector_2"
            d="M30.9739 19.8721V31.4482C30.9739 32.9386 29.7603 34.1471 28.2635 34.1471L18.8766 34.186L13.3778 38.1146V34.186H9.48964C7.99287 34.186 6.7793 32.9775 6.7793 31.487V19.9109C6.7793 18.4205 7.99287 17.212 9.48964 17.212L28.263 17.1731C29.7603 17.1731 30.9739 18.3816 30.9739 19.8721Z"
            fill="#009B9B"
          />
        </g>
        <g id="Group_3">
          <path
            id="Default button"
            d="M15.5918 22.8319C15.5918 22.4538 15.7087 22.0717 15.9427 21.6856C16.1766 21.2956 16.5178 20.9732 16.9665 20.7185C17.4151 20.4638 17.9385 20.3364 18.5367 20.3364C19.0927 20.3364 19.5835 20.4439 20.0091 20.6588C20.4348 20.8697 20.7626 21.1583 20.9927 21.5244C21.2266 21.8906 21.3435 22.2886 21.3435 22.7184C21.3435 23.0567 21.2764 23.3532 21.1422 23.6079C21.0119 23.8627 20.8546 24.0835 20.6706 24.2706C20.4904 24.4537 20.1644 24.7641 19.6928 25.2019C19.5624 25.3253 19.457 25.4347 19.3764 25.5303C19.2998 25.6218 19.2422 25.7074 19.2039 25.787C19.1655 25.8626 19.1349 25.9402 19.1119 26.0198C19.0927 26.0954 19.062 26.2307 19.0198 26.4257C18.947 26.8397 18.7188 27.0466 18.3354 27.0466C18.136 27.0466 17.9673 26.979 17.8292 26.8436C17.695 26.7083 17.6279 26.5073 17.6279 26.2407C17.6279 25.9064 17.6778 25.6178 17.7775 25.375C17.8772 25.1283 18.0094 24.9134 18.1743 24.7303C18.3392 24.5432 18.5616 24.3223 18.8415 24.0676C19.0869 23.8448 19.2633 23.6776 19.3707 23.5662C19.4819 23.4507 19.5739 23.3234 19.6468 23.1841C19.7235 23.0448 19.7618 22.8935 19.7618 22.7304C19.7618 22.412 19.6468 22.1433 19.4167 21.9244C19.1905 21.7055 18.8971 21.5961 18.5367 21.5961C18.1149 21.5961 17.8043 21.7075 17.6049 21.9304C17.4055 22.1493 17.2368 22.4737 17.0988 22.9035C16.9684 23.3532 16.7211 23.5781 16.3568 23.5781C16.142 23.5781 15.9599 23.5005 15.8104 23.3453C15.6647 23.1861 15.5918 23.0149 15.5918 22.8319ZM18.3986 29.3749C18.1647 29.3749 17.9596 29.2973 17.7832 29.1421C17.6107 28.9829 17.5244 28.762 17.5244 28.4794C17.5244 28.2287 17.6087 28.0177 17.7775 27.8466C17.9462 27.6754 18.1532 27.5899 18.3986 27.5899C18.6402 27.5899 18.8435 27.6754 19.0083 27.8466C19.1732 28.0177 19.2557 28.2287 19.2557 28.4794C19.2557 28.758 19.1694 28.9769 18.9968 29.1361C18.8243 29.2953 18.6249 29.3749 18.3986 29.3749Z"
            fill="white"
          />
          <g id="Group_4">
            <path
              id="Default button_2"
              d="M11.1892 25.8939C11.1167 25.6235 11.1271 25.3278 11.2204 25.0069C11.313 24.6831 11.4953 24.3871 11.7673 24.119C12.0394 23.8508 12.3893 23.6594 12.8172 23.5448C13.2148 23.4382 13.5865 23.421 13.9321 23.4932C14.2769 23.5625 14.5667 23.706 14.8014 23.9238C15.0389 24.1408 15.1988 24.4031 15.2811 24.7105C15.346 24.9525 15.3548 25.1774 15.3076 25.3853C15.2632 25.5924 15.1931 25.7805 15.0973 25.9496C15.0035 26.1151 14.8299 26.3996 14.5764 26.8031C14.5068 26.9163 14.4524 27.0148 14.4131 27.0985C14.3758 27.1787 14.3511 27.2509 14.3389 27.3152C14.326 27.3766 14.3189 27.438 14.3177 27.4994C14.3185 27.5571 14.3225 27.6598 14.3297 27.8073C14.3569 28.1173 14.2334 28.3091 13.9591 28.3826C13.8165 28.4208 13.6829 28.4047 13.5582 28.3344C13.4363 28.2633 13.3498 28.1324 13.2987 27.9417C13.2346 27.7026 13.215 27.4867 13.2398 27.2939C13.2638 27.0984 13.3172 26.9193 13.4 26.7568C13.4821 26.5914 13.5989 26.3908 13.7502 26.155C13.883 25.9485 13.9772 25.7952 14.0326 25.6949C14.09 25.591 14.1314 25.4823 14.1568 25.3687C14.185 25.2544 14.1834 25.1389 14.1522 25.0222C14.0911 24.7944 13.9574 24.6243 13.7509 24.5119C13.5471 24.3987 13.3164 24.3766 13.0586 24.4457C12.7569 24.5265 12.5561 24.6657 12.4562 24.8634C12.3555 25.0581 12.297 25.3225 12.2807 25.6563C12.2736 26.003 12.1398 26.2112 11.8793 26.281C11.7257 26.3222 11.5806 26.3016 11.4439 26.2192C11.3092 26.1333 11.2242 26.0248 11.1892 25.8939ZM14.4506 30.0356C14.2833 30.0805 14.1217 30.0643 13.9658 29.9871C13.8119 29.9063 13.7078 29.7648 13.6537 29.5627C13.6056 29.3834 13.6255 29.2164 13.7134 29.0616C13.8013 28.9069 13.933 28.806 14.1085 28.759C14.2813 28.7127 14.443 28.7349 14.5937 28.8257C14.7445 28.9165 14.8439 29.0516 14.8919 29.2309C14.9453 29.4302 14.9255 29.6033 14.8326 29.7502C14.7397 29.8971 14.6124 29.9923 14.4506 30.0356Z"
              fill="white"
            />
            <path
              id="Default button_3"
              d="M21.9358 24.7916C22.0083 24.5212 22.1652 24.2703 22.4064 24.039C22.6485 23.8049 22.9543 23.6397 23.324 23.5435C23.6937 23.4473 24.0925 23.4566 24.5203 23.5712C24.9179 23.6778 25.2484 23.8487 25.5116 24.0839C25.7756 24.3164 25.9548 24.5856 26.0492 24.8915C26.1463 25.1983 26.1537 25.5053 26.0713 25.8127C26.0065 26.0547 25.9016 26.2539 25.7568 26.4104C25.6148 26.5675 25.46 26.6954 25.2925 26.7939C25.1285 26.8903 24.8359 27.0499 24.4147 27.2726C24.2978 27.3359 24.2014 27.3939 24.1255 27.4468C24.0532 27.4976 23.9956 27.5478 23.9529 27.5974C23.911 27.6441 23.8742 27.6937 23.8425 27.7462C23.8143 27.7966 23.7664 27.8876 23.6989 28.0189C23.5674 28.301 23.3646 28.4053 23.0904 28.3318C22.9478 28.2936 22.8401 28.2129 22.7673 28.0897C22.6972 27.9672 22.6877 27.8106 22.7388 27.6198C22.8029 27.3807 22.8938 27.1839 23.0117 27.0294C23.1303 26.872 23.2661 26.7436 23.4191 26.6443C23.5729 26.5421 23.7743 26.4268 24.0233 26.2982C24.2415 26.1858 24.3997 26.1001 24.4978 26.041C24.5995 25.9797 24.6897 25.9063 24.7685 25.8206C24.8501 25.7357 24.9065 25.6349 24.9377 25.5182C24.9988 25.2904 24.968 25.0762 24.8454 24.8756C24.7255 24.6757 24.5367 24.5412 24.2789 24.4721C23.9772 24.3913 23.7337 24.4115 23.5484 24.5327C23.3638 24.651 23.181 24.8507 22.9999 25.1316C22.8205 25.4283 22.6005 25.5417 22.34 25.4719C22.1864 25.4308 22.071 25.3404 21.9938 25.2007C21.9201 25.0589 21.9008 24.9225 21.9358 24.7916ZM22.6894 30.0092C22.5221 29.9643 22.3903 29.8695 22.2939 29.7247C22.201 29.5778 22.1816 29.4033 22.2357 29.2012C22.2838 29.0218 22.3846 28.8871 22.538 28.7971C22.6915 28.707 22.856 28.6855 23.0315 28.7325C23.2043 28.7788 23.3332 28.8789 23.4184 29.0329C23.5035 29.1869 23.522 29.3536 23.474 29.5329C23.4206 29.7322 23.3169 29.8722 23.163 29.953C23.0091 30.0338 22.8512 30.0525 22.6894 30.0092Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
