import { Medias } from 'src/componentes/styles'
import styled from 'styled-components'

export const ContainerAcoes = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${Medias.MOBILE} {
    display: block;

    button {
      width: 100%;
    }
  }
`
