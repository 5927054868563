import React, { useMemo, useEffect, useRef } from 'react'

import { BarraProgresso } from '../barra-progresso'
import { Tooltip } from '../tooltip'
import StepperContainer from './container'
import { Item } from './item'
import { ModalNotificacaoMotivoBloqueio } from './modal-notificacao-bloqueado'
import { Container, Conteudo, Aside, ContainerProgresso } from './styles'
import { StepperItem, StepperProps } from './tipos'

export const Stepper: React.FC<StepperProps> = ({
  idItemSelecionado,
  progresso = 0,
  itens,
  onClickConcluir,
  onClickDownload,
  onClickGetBlob,
  children,
  itensMarcados
}) => {
  const {
    passo,
    definirOnClickConcluir,
    definirOnClickDownload,
    definirOnClickGetBlob,
    definirPassoStepper,
    definirItensMarcados,
    definirPassosBloqueados,
    modalRef
  } = StepperContainer.useContainer()
  const primeiroRender = useRef(true)

  const buscarComponentePasso = (
    passoSelecionado: number,
    itensSelecionados: StepperItem[]
  ): JSX.Element => {
    for (let i = 0; i < itensSelecionados.length; i++) {
      if (itensSelecionados[i].passo === passoSelecionado) {
        return itensSelecionados[i].componente
      } else if (
        itensSelecionados[i].subItens &&
        itensSelecionados[i].subItens.length
      ) {
        const retorno = buscarComponentePasso(
          passoSelecionado,
          itensSelecionados[i].subItens
        )

        if (retorno) return retorno
      }
    }
  }

  const itemAtivo = useMemo(() => {
    const item = buscarComponentePasso(passo, itens)
    return item ?? <></>
  }, [itens, passo])

  const buscarAlgumItemBloqueado = (itensSelecionados: StepperItem[] = []) => {
    let retorno: number[] = []
    for (let i = 0; i < itensSelecionados.length; i++) {
      if (itensSelecionados[i].bloqueado) {
        retorno.push(itensSelecionados[i].passo)
      }

      if (
        itensSelecionados[i].subItens &&
        itensSelecionados[i].subItens.length > 0
      ) {
        retorno = retorno.concat(
          buscarAlgumItemBloqueado(itensSelecionados[i].subItens)
        )
      }
    }

    return retorno
  }

  const passosBloqueados = useMemo(() => {
    return buscarAlgumItemBloqueado(itens)
  }, [itens])

  const algumItemBloqueado = useMemo(() => {
    return passosBloqueados.length > 0
  }, [passosBloqueados])

  useEffect(() => {
    definirPassosBloqueados(passosBloqueados)
  }, [passosBloqueados])

  useEffect(() => {
    if (onClickConcluir) {
      definirOnClickConcluir(onClickConcluir)
    }

    if (onClickDownload) {
      definirOnClickDownload(onClickDownload)
    }

    if (onClickGetBlob) {
      definirOnClickGetBlob(onClickGetBlob)
    }
  }, [])

  useEffect(() => {
    definirItensMarcados(itensMarcados)
  }, [itensMarcados])

  const buscarItemPorIdItemSelecionado = (
    idItemSelecionadoSelecionado: string,
    itensSelecionados: StepperItem[]
  ): number => {
    for (let i = 0; i < itensSelecionados.length; i++) {
      if (itensSelecionados[i].id === idItemSelecionadoSelecionado) {
        return itensSelecionados[i].passo
      } else if (
        itensSelecionados[i].subItens &&
        itensSelecionados[i].subItens.length
      ) {
        const retorno = buscarItemPorIdItemSelecionado(
          idItemSelecionadoSelecionado,
          itensSelecionados[i].subItens
        )

        if (retorno) return retorno
      }
    }
  }

  useEffect(() => {
    if (primeiroRender.current && idItemSelecionado) {
      const novoPasso = buscarItemPorIdItemSelecionado(idItemSelecionado, itens)
      definirPassoStepper(novoPasso)
    }

    primeiroRender.current = false
  }, [idItemSelecionado])

  return (
    <>
      <Container>
        <Conteudo>
          {children}
          {itemAtivo}
        </Conteudo>
        <Aside>
          <ContainerProgresso>
            <span>Progresso</span>
            <BarraProgresso
              className="stepper-barra-progresso"
              width="100%"
              percent={`${progresso}`}
            />
          </ContainerProgresso>
          {itens.map(item => (
            <Item key={`${item.id}${item.bloqueado}`} item={item} />
          ))}
        </Aside>
      </Container>
      {algumItemBloqueado && (
        <Tooltip bloqueado id="tooltip-stepper" place="left" />
      )}
      <ModalNotificacaoMotivoBloqueio ref={modalRef} backdrop />
    </>
  )
}
