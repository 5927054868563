import { useState } from 'react'

import { RelacaoDocumentosAlunoBrasileiro } from '../data'
import { UploadDocumentos } from '../tipos'

export interface TipoUseRelacaoDeDocumentos {
  documentos: UploadDocumentos[]
  atualizar: (upload: UploadDocumentos) => void
  reiniciar: (novosDocumentos: UploadDocumentos[]) => void
}

export const useRelacaoDeDocumentos = (
  documentosIniciais: UploadDocumentos[] = RelacaoDocumentosAlunoBrasileiro
): TipoUseRelacaoDeDocumentos => {
  const [documentos, definirDocumentos] = useState<UploadDocumentos[]>(
    documentosIniciais
  )

  const reiniciar = (novosDocumentos: UploadDocumentos[]) =>
    definirDocumentos(novosDocumentos)

  const atualizar = (upload: UploadDocumentos): void => {
    const novosDocumentos = documentos.map(documento =>
      upload.id === documento.id ? upload : documento
    )

    definirDocumentos(novosDocumentos)
  }

  return {
    documentos,
    atualizar,
    reiniciar
  }
}
