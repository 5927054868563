import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Tooltip,
  Botao,
  Breadcrumb,
  Cabecalho,
  ContainerAcoes,
  Filtro,
  FuncoesDataHora,
  IconeEditar,
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  InputUnform,
  SelectUnform
} from 'src/componentes'
import { RotasCupons } from 'src/rotas/academico'
import { Api } from 'src/servicos'
import { TipoOrdenacao, TipoDescontoCupom } from 'src/tipos'

import { ContainerDadosPequeno } from '../cupons/cadastro/styles'
import { Campo, Container, Dados, Form } from './styles'

interface DadosPesquisa {
  nome: string
  codigoCupom: string
  tipoDescontoCupom: keyof typeof TipoDescontoCupom
}

export const PaginaCupons: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const [dadosPesquisa, definirDadosPesquisa] = useState<DadosPesquisa>(null)
  const history = useHistory()
  const [paginas, definirPaginas] = useState(null)

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current.IrParaPagina()
    tabelaRef.current.CarregarDados()
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      definirPaginas(1)
    }
    definirDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    quantidadePorPagina: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }
    const dados = await Api.RequisitarListaCupons(
      dadosPesquisa?.nome,
      dadosPesquisa?.codigoCupom,
      dadosPesquisa?.tipoDescontoCupom,
      numeroPaginas,
      quantidadePorPagina,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null
    definirPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados.opcoesPaginacao.pagina,
        TotalRegistros: dados.opcoesPaginacao.totalRegistros
      }
    } as TabelaResposta
  }

  const formatarValor = (linha: TabelaDados) => {
    if (linha.tipoDescontoCupom === 'Porcentagem') {
      return `${linha.valorDesconto}%`
    }

    return linha.valorDesconto.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
  }

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Cupons"
          atalhos={[
            {
              texto: 'Financeiro'
            },
            {
              texto: 'Cupons'
            }
          ]}
        />
        <Botao
          type="button"
          texto="Incluir"
          tema="Secundario"
          onClick={() => history.push(RotasCupons.Cadastro)}
        />
      </Cabecalho>
      <Filtro>
        <Form
          dadosIniciais={{
            tipoDescontoCupom: TipoDescontoCupom[TipoDescontoCupom.Todos]
          }}
          acaoSucesso={acaoPesquisa}
        >
          <Container>
            <Campo>
              <InputUnform
                type="text"
                name="nome"
                label="Nome"
                maxLength={200}
              />
            </Campo>
            <Campo>
              <InputUnform
                type="text"
                name="codigoCupom"
                label="Código"
                maxLength={100}
              />
            </Campo>
            <ContainerDadosPequeno>
              <SelectUnform
                id="slct_tipo"
                name="tipoDescontoCupom"
                label="Tipo do Cupom"
                placeholder="Selecione"
                iconeFechar={false}
                opcoes={[
                  {
                    id: TipoDescontoCupom[TipoDescontoCupom.Porcentagem],
                    texto: 'Porcentagem'
                  },
                  {
                    id: TipoDescontoCupom[TipoDescontoCupom.Valor],
                    texto: 'Valor'
                  },
                  {
                    id: TipoDescontoCupom[TipoDescontoCupom.Todos],
                    texto: 'Todos'
                  }
                ]}
              />
            </ContainerDadosPequeno>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nome',
              Texto: 'Nome',
              ComOrdenacao: true
            },
            {
              Chave: 'codigo',
              Texto: 'Código',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => <>{linha.codigoCupom}</>
            },
            {
              Chave: 'tipo',
              Texto: 'Tipo',
              Alinhamento: 'left',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => <>{linha.tipoDescontoCupom}</>
            },
            {
              Chave: 'valor',
              Texto: 'Valor',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => <>{formatarValor(linha)}</>
            },
            {
              Chave: 'dataInicio',
              Texto: 'Data de Início',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>
                  {linha.dataInicio !== null
                    ? FuncoesDataHora.dataFormatadaComBarras(
                        new Date(linha.dataInicio)
                      )
                    : ''}
                </>
              )
            },
            {
              Chave: 'dataFim',
              Texto: 'Data de Fim',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>
                  {linha.dataFim !== null
                    ? FuncoesDataHora.dataFormatadaComBarras(
                        new Date(linha.dataFim)
                      )
                    : ''}
                </>
              )
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    data-tip="Editar"
                    data-for={`${linha.id}-tooltip-editar`}
                    onClick={() =>
                      history.push(`${RotasCupons.Edicao}/${linha.id}`)
                    }
                    onContextMenu={() => {
                      const rota = window.open(
                        `${RotasCupons.Edicao}/${linha.id}`
                      )
                      rota.focus()
                    }}
                  >
                    {IconeEditar}
                  </button>
                  <Tooltip
                    id={`${linha.id}-tooltip-editar`}
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                </ContainerAcoes>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export { default as PaginaCadastroCupons } from './cadastro'
