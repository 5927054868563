import { Botao } from 'src/componentes/botao'
import { Informacao } from 'src/componentes/informacao'
import { Cores } from 'src/componentes/styles/cores'
import styled from 'styled-components'

export const ContainerDaPagina = styled.div`
  padding-top: 14px;
`

export const TituloPrincipal = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 18px;
`
export const TituloComplementar = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 30px;
`

export const Dicas = styled(Informacao)`
  margin-bottom: 20px;
`

export const ContainerDosBotoes = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`

export const BotaoDeSalvar = styled(Botao)`
  padding: 14px 60px;
  margin-left: 20px;
`

export const MensagemDeErro = styled.span`
  display: flex;
  color: ${Cores.PERIGO_ERRO};
  font-size: 12px;
  margin-top: 30px;
`

export const ContainerLink = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const ContainerBreadcrumbs = styled.div`
  display: flex;
  margin-bottom: 22px;
`

export const ParagrafoDaNacionalidade = styled.p`
  text-transform: capitalize;
`

export const DescricaoContainer = styled.div`
  margin-bottom: 30px;

  p {
    margin-bottom: 6px;
  }

  & > p,
  & > ${ParagrafoDaNacionalidade} {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }
`
