import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { FormHandles } from '@unform/core'
import {
  Botao,
  CheckboxUnform,
  FormUnform,
  ModalBase,
  ModalRef
} from 'src/componentes'

import {
  ContainerConteudo,
  ContainerModal,
  TituloPrincipal,
  ContainerBotoes,
  ContainerCheckbox,
  Opcao,
  OpcaoDados
} from './styles'
import { DadosAceite, ModalAceiteResponsavelProps } from './tipos'

const Modal: React.ForwardRefRenderFunction<
  ModalRef,
  ModalAceiteResponsavelProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [aceiteResponsavel, definirAceiteResponsavel] = useState(false)
  const formRef = useRef<FormHandles>(null)

  const fechar = () => {
    definirAberta(false)
    definirAceiteResponsavel(false)
    formRef.current?.reset()
  }

  const abrir = () => {
    definirAberta(true)
  }

  const acaoSucessoForm = async (dados: DadosAceite) => {
    await acaoPrimaria(dados.arquivo)
    fechar()
    formRef.current?.reset()
  }

  useImperativeHandle<ModalRef, ModalRef>(ref, () => ({
    fechar,
    abrir
  }))

  return (
    <ModalBase
      id="modal-aceite-responsavel"
      aberta={aberta}
      backdrop={backdrop}
    >
      <ContainerModal>
        <TituloPrincipal>Aceite - Responsável Financeiro</TituloPrincipal>
        <FormUnform ref={formRef} acaoSucesso={acaoSucessoForm}>
          <ContainerConteudo>
            <ContainerCheckbox>
              <Opcao>
                <OpcaoDados>
                  <CheckboxUnform
                    id="chk_aceite_responsavel"
                    name="aceiteResponsavel"
                    onChange={() => definirAceiteResponsavel(old => !old)}
                  />
                  <label htmlFor="chk_aceite_responsavel">
                    Aceito ser Responsável Financeiro de acordo com os
                    parâmetros combinados. Confirmo que quero aceitar esta
                    função.
                  </label>
                </OpcaoDados>
              </Opcao>
            </ContainerCheckbox>
          </ContainerConteudo>
          <ContainerBotoes>
            <Botao
              texto="Voltar"
              tema="Secundario"
              type="button"
              onClick={() => fechar()}
            />
            <Botao texto="Enviar" disabled={!aceiteResponsavel} />
          </ContainerBotoes>
        </FormUnform>
      </ContainerModal>
    </ModalBase>
  )
}
export const ModalAceiteResponsavel = forwardRef(Modal)
